import React, { useEffect, useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  deviceIncrement,
  getDeviceView,
  updateDeviceActionData,
} from "../../../actions/managamentActions";
import ToggleCard from "../../../../Home/components/Wizard/ToggleCard";
import { AppState } from "../../../../../rootReducer";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndPoint, doHttpCall } from "../../../../../common/restApi";
import LoaderWithMessage from "../../../../../common/customLoader/LoaderWithMessage";

const Inventory = ({
  deviceData,
  showCreateDevice,
  orgDevicData,
}: {
  deviceData: any;
  showCreateDevice: boolean;
  orgDevicData: any;
}) => {
  const [macAddressOnchange, setMacAddressOnchange] = useState("");
  const [serialNumberOnchange, setSerialNumberOnchange] = useState("");
  const [ModelNumberOnchange, setModelNumberOnchange] = useState("");
  const [osOnchange, setOsOnchange] = useState("");
  const [osVersionOnchange, setOsVersionOnchange] = useState("");

  const isMacAddressChanged = deviceData?.mac_address
    ? deviceData?.mac_address
    : "" !== macAddressOnchange;
  const isSerialNumberChanged = deviceData?.serial_number
    ? deviceData?.serial_number
    : "" !== serialNumberOnchange;

  const macAddress = RegExp(
    /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/
  );
  const validationSchema = Yup.object().shape({
    serial_number: Yup.string()
      .required("Serial number is required")
      .test({
        message: "This serial number is already taken",
        test: async (value) => {
          if (
            serialNumberOnchange !== "" &&
            isSerialNumberChanged &&
            value !== orgDevicData?.serial_number
          ) {
            const checkFieldName = `/devices/validate?serialnumber=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    model: Yup.string().required("model number is required"),
    mac_address: Yup.string()
      .required("Mac address is required")
      .matches(macAddress, "Invalid mac address")
      .test({
        message: "This mac address is already taken",
        test: async (value) => {
          if (macAddressOnchange !== "" && isMacAddressChanged) {
            const checkFieldName = `/devices/validate?macaddress=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    operating_system: Yup.string().required("os is required"),
    os_version: Yup.string().required("os version is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm(formOptions);
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    const updateDevice = { ...deviceData, ...data };
    // debugger;
    dispatch(updateDeviceActionData(updateDevice));
    dispatch(deviceIncrement());
  };
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageDeviceView;
  });
  return (
    <Stack>
      {(deviceData || showCreateDevice) && (
        <form id="deviceForm" onSubmit={handleSubmit(onSubmit)}>
          <ToggleCard heading="Inventory Details" index="" removeFormFields="">
            <Stack className="deviceInputSection ms-Grid-row">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3 ">
                      <TextField
                        autoComplete="off"
                        label="Serial Number*"
                        {...register("serial_number")}
                        defaultValue={deviceData?.serial_number}
                        onChange={(e: any) => {
                          setSerialNumberOnchange(e?.target?.value);
                          errors.serial_number = "";
                        }}
                        // defaultValue="sadsadd"
                        errorMessage={
                          errors?.serial_number &&
                          errors?.serial_number?.message
                        }
                      ></TextField>
                      <TextField
                        autoComplete="off"
                        label="Model Number*"
                        {...register("model")}
                        onChange={(e: any) => {
                          //errors.model.message = ""
                          setModelNumberOnchange(e?.target?.value);
                          errors.model = "";
                        }}
                        defaultValue={deviceData?.model}
                        errorMessage={errors?.model && errors?.model?.message}
                      ></TextField>
                      <TextField
                        autoComplete="off"
                        label="MAC Address*"
                        {...register("mac_address")}
                        defaultValue={deviceData?.mac_address}
                        onChange={(e: any) => {
                          setMacAddressOnchange(e?.target?.value);
                          errors.mac_address = "";
                        }}
                        errorMessage={
                          errors?.mac_address && errors?.mac_address?.message
                        }
                      ></TextField>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row">
                    {/* <Stack.Item className="ms-Grid-col ms-lg1"> </Stack.Item> */}
                    <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset1">
                      <TextField
                        autoComplete="off"
                        label="OS*"
                        {...register("operating_system")}
                        onChange={(e: any) => {
                          setOsOnchange(e?.target?.value);
                          errors.operating_system = "";
                        }}
                        defaultValue={deviceData?.operating_system}
                        errorMessage={
                          errors?.operating_system &&
                          errors?.operating_system?.message
                        }
                      ></TextField>
                      <TextField
                        autoComplete="off"
                        label="OS Version*"
                        {...register("os_version")}
                        onChange={(e: any) => {
                          setOsVersionOnchange(e?.target?.value);
                          errors.os_version = "";
                        }}
                        defaultValue={deviceData?.os_version}
                        errorMessage={
                          errors?.os_version && errors?.os_version?.message
                        }
                      ></TextField>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack>
          </ToggleCard>
        </form>
      )}
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
    </Stack>
  );
};

export default Inventory;
