import React from "react";
import {
  FontIcon,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { fonts, ThemeColorPalette } from "../../../theme";
import { currentDate, getDateWithSlashInBetween } from "../../../common/util";
import CheckPermission from "../../../common/permissions/rolePermissions";
import * as constants from "../../../Layout/constants/constants";

interface InprogressVideoDetailsProps {
  handleEdit?: any;
  data: any;
  userProfile: any;
  procedureName?: string;
  primarySurgeonName?: string;
  secondaryProcedureName?: string;
  userData: any;
  screen?: string;
}

const InprogressVideoDetails: React.SFC<InprogressVideoDetailsProps> = ({
  handleEdit,
  data,
  userProfile,
  procedureName,
  primarySurgeonName,
  secondaryProcedureName,
  userData,
  screen,
}) => {
  const date =
    data && !data.videoData
      ? data.surgery_date
        ? new Date(data.surgery_date)
        : null
      : data.videoData.surgeryDate
      ? new Date(data.videoData.surgeryDate)
      : null;

  let uploadedUser = "";
  userData.results &&
    userData.results.forEach((val: any) => {
      if (data && data.uploaded_by && data.uploaded_by === val.id)
        uploadedUser = val.displayName;
    });

  return (
    <Stack
      styles={{
        root: {
          minHeight: "100vh",
          maxHeight: "100%",
          minWidth: "80vh",
          maxWidth: "100%",
          paddingBottom: "2rem",
          paddingTop: "2rem",
          paddingLeft: "0rem",
        },
      }}
      className="no-root-padding-required"
    >
      <StackItem styles={{ root: { paddingBottom: "3rem" } }}>
        <Text
          variant="xLarge"
          styles={{
            root: {
              fontFamily: fonts.DINPro_Regular,
              color: ThemeColorPalette.dashboardHeadingTxt,
              opacity: 0.8,
            },
          }}
        >
          Video Details
        </Text>
      </StackItem>
      <Stack tokens={{ childrenGap: 30 }}>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Primary Procedure Name
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {data && data.procedure_name
                ? data.procedure_name
                : procedureName
                ? procedureName
                  ? procedureName
                  : "N/A"
                : data.primaryProcedureName
                ? data.primaryProcedureName
                : "N/A"}
            </Text>
          </Stack>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Primary Surgeon Name
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {primarySurgeonName
                ? primarySurgeonName
                  ? primarySurgeonName
                  : "N/A"
                : data.primarySurgeonName
                ? data.primarySurgeonName
                : "N/A"}
            </Text>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Secondary Procedure Name
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {secondaryProcedureName
                ? secondaryProcedureName
                  ? secondaryProcedureName
                  : "N/A"
                : data.secondaryProcedureName
                ? data.secondaryProcedureName
                : "N/A"}
            </Text>
          </Stack>
          <Stack tokens={{ childrenGap: 5 }}>
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Date of Procedure
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {date
                ? date.getMonth() +
                  1 +
                  "-" +
                  date.getDate() +
                  "-" +
                  date.getFullYear()
                : "N/A"}
            </Text>
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 5 }}>
          <Text
            variant="smallPlus"
            styles={{
              root: {
                color: ThemeColorPalette.changePasswordTxt,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            Case Description
          </Text>
          <Text
            variant="medium"
            styles={{
              root: {
                color: ThemeColorPalette.changePasswordLabelTxt,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            {data && data.notes
              ? data.notes
              : (data.videoData && data.videoData.notes) || "N/A"}
          </Text>
        </Stack>
        <Stack tokens={{ childrenGap: 5 }}>
          <Text
            variant="smallPlus"
            styles={{
              root: {
                color: ThemeColorPalette.changePasswordTxt,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            Patient Consent Form
          </Text>
          <Text
            variant="medium"
            styles={{
              root: {
                color: ThemeColorPalette.themePrimary,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            <FontIcon
              iconName="attach"
              style={{ color: ThemeColorPalette.themePrimary }}
            />
            {data.patient_concent_forms
              ? data.patient_concent_forms
              : data.patientConsentForm
              ? data.patientConsentForm.name
              : "File not attached"}
          </Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Uploaded by
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {data.videoData || screen === "processing"
                ? userProfile && userProfile.displayName
                : data.is_uploaded_by_user
                ? uploadedUser
                  ? uploadedUser
                  : "N/A"
                : data.uploaded_by}
            </Text>
          </Stack>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Uploaded Date
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {data.videoData
                ? currentDate()
                : data.uploaded_date
                ? getDateWithSlashInBetween(data.uploaded_date)
                : "N/A"}
            </Text>
          </Stack>
        </Stack>
        <CheckPermission {...constants.SCOPES.VIDEO_EDIT_ALL_MODULES}>
          {screen !== "viewOnly" && (
            <PrimaryButton
              text="Edit Details"
              allowDisabledFocus
              styles={{
                root: {
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  fontFamily: fonts.HelveticaNeue_Bold,
                  height: "2rem",
                  width: "7rem",
                },
              }}
              onClick={() => handleEdit()}
            />
          )}
        </CheckPermission>
      </Stack>
    </Stack>
  );
};

export default InprogressVideoDetails;
