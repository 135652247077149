import {
  Checkbox,
  DatePicker,
  Dropdown,
  DropdownMenuItemType,
  IDatePickerStrings,
  IDropdownOption,
  IDropdown,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { ThemeColorPalette } from "../../theme";
import {
  getProcedureVideos,
  setProcedureDateCustomFilterDateStorage,
  setProcedureDateFilterType,
} from "../actions/landingPageAction";
export interface DateRangeDropdownProps {
  getProcedureVideos: any;
  procedureVideosData: any;
  setProcedureDateFilterType: any;
  setProcedureDateCustomFilterDateStorage: any;
  customSelectedDates: any;
  procedureDateFilterType: any;
}
export interface DateRangeDropdownState {
  isChecked: boolean;
  displayCustomDate: boolean;
}
const DayPickerStrings: IDatePickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shortDays: ["S", "M", "T", "W", "T", "F", "S"],
  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker",
  monthPickerHeaderAriaLabel: "{0}, select to change the year",
  yearPickerHeaderAriaLabel: "{0}, select to change the month",
};
class DateRangeDropdown extends React.Component<
  DateRangeDropdownProps,
  DateRangeDropdownState
> {
  state = {
    dropdownRef: React.createRef<IDropdown>(),
    isChecked:
      this.props.procedureDateFilterType === "customDate" ? true : false,
    displayCustomDate: false,
  };
  datePickerDropdownOption: IDropdownOption[] = [
    // { key: "", text: "Select Time Period" },
    { key: "last1Month", text: "Last 1 Month" },
    { key: "last3Months", text: "Last 3 Months" },
    { key: "last6Months", text: "Last 6 Months" },
    { key: "last1Year", text: "Last 1 Year" },
    {
      key: "customDate",
      text: "Custom Date",
      itemType: DropdownMenuItemType.Header,
    },
  ];
  onRenderOption = (option: any): JSX.Element => {
    const currentDate = moment().toDate();
    return (
      <div>
        {option.key && option.key === "customDate" ? (
          <Stack
            verticalFill
            tokens={{ childrenGap: 15 }}
            styles={{
              root: {
                background: ThemeColorPalette.gridBG,
              },
            }}
          >
            <StackItem
              styles={{
                root: {
                  marginBottom: "-0.2rem",
                  marginTop: "0.7rem",
                },
              }}
            >
              <Checkbox
                label="Custom Date Range"
                checked={this.state.isChecked}
                onChange={() =>
                  this.dropDownCheckBoxOnChange(!this.state.isChecked)
                }
              />
            </StackItem>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <StackItem>
                <Text
                  variant="small"
                  styles={{
                    root: { color: ThemeColorPalette.uploadVideoBtnTxt },
                  }}
                >
                  From
                </Text>
                <DatePicker
                  maxDate={
                    this.props.customSelectedDates.toDate
                      ? this.props.customSelectedDates.toDate
                      : currentDate
                  }
                  strings={DayPickerStrings}
                  showWeekNumbers={false}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay={true}
                  placeholder="Select A Date"
                  value={
                    this.state.isChecked ||
                    (this.props.procedureDateFilterType === "customDate" &&
                      this.props.customSelectedDates.fromDate)
                      ? this.props.customSelectedDates.fromDate
                      : undefined
                  }
                  styles={{ root: { width: "8rem" } }}
                  formatDate={(date: any) => {
                    return (
                      date.getDate() +
                      "/" +
                      (date.getMonth() + 1) +
                      "/" +
                      date.getFullYear()
                    );
                  }}
                  onSelectDate={(date: any) => {
                    this.handleDatePickerSelection(date, "fromDate");
                  }}
                  disabled={!this.state.isChecked}
                />
              </StackItem>
              <StackItem>
                <Text
                  variant="small"
                  styles={{
                    root: { color: ThemeColorPalette.uploadVideoBtnTxt },
                  }}
                >
                  To
                </Text>
                <DatePicker
                  maxDate={currentDate}
                  strings={DayPickerStrings}
                  showWeekNumbers={false}
                  firstWeekOfYear={1}
                  showMonthPickerAsOverlay={true}
                  placeholder="Select A Date"
                  styles={{ root: { width: "8rem" } }}
                  value={
                    this.state.isChecked ||
                    this.props.procedureDateFilterType === "customDate"
                      ? this.props.customSelectedDates.toDate
                      : undefined
                  }
                  formatDate={(date: any) => {
                    return (
                      date.getDate() +
                      "/" +
                      (date.getMonth() + 1) +
                      "/" +
                      date.getFullYear()
                    );
                  }}
                  onSelectDate={(date: any) => {
                    this.handleDatePickerSelection(date, "toDate");
                  }}
                  disabled={!this.state.isChecked}
                  minDate={this.props.customSelectedDates.fromDate}
                />
              </StackItem>
            </Stack>
          </Stack>
        ) : (
          <span>{option.text}</span>
        )}
      </div>
    );
  };
  dropDownCheckBoxOnChange = (e: any) => {
    if (e) {
      this.props.setProcedureDateFilterType("customDate");
      this.handleDropDownSelection("", { key: "customDate" });
    } else {
      this.props.setProcedureDateFilterType("");
      this.props.customSelectedDates.fromDate = "";
      this.props.customSelectedDates.toDate = "";
      this.props.setProcedureDateCustomFilterDateStorage(null, "fromDate");
      this.props.setProcedureDateCustomFilterDateStorage(null, "toDate");
    }
    this.setState({ ...this.state, isChecked: e });
  };
  handleDatePickerSelection = (option: any, dateSelectType: any) => {
    this.props.setProcedureDateCustomFilterDateStorage(option, dateSelectType);
  };
  static getDerivedStateFromProps(props: any, state: any) {
    if (
      props.customSelectedDates.fromDate &&
      props.customSelectedDates.toDate &&
      state.isChecked
    ) {
      props.setProcedureDateFilterType("customDate");
    }
    return null;
  }
  handleDropDownSelection = (e: any, selectedOption: any) => {
    this.props.setProcedureDateFilterType(selectedOption.key);
    selectedOption.key !== "customDate" && this.setState({ isChecked: false });
  };
  getSelected = () => {
    const displayName: any = this.datePickerDropdownOption
      .filter((val: any) => val.key === this.props.procedureDateFilterType)
      .map((val: any) => val.text);
    return displayName[0] ? displayName[0] : "Select Time Period";
  };
  render() {
    return (
      <Dropdown
        componentRef={this.state.dropdownRef}
        options={this.datePickerDropdownOption}
        placeholder={this.getSelected()}
        styles={{
          dropdown: { width: 155 },
          dropdownItems: {
            padding: "0 1rem 1rem 1rem",
            height: "18.5rem",
            color: ThemeColorPalette.uploadMsgTxt,
            backgroundColor: ThemeColorPalette.gridBG,
          },
        }}
        dropdownWidth={310}
        onRenderOption={this.onRenderOption}
        calloutProps={{
          directionalHintFixed: true,
          calloutMaxHeight: 600,
        }}
        onChange={(e: React.FormEvent, selectedOption: any) => {
          this.handleDropDownSelection(e, selectedOption);
        }}
        // selectedKeys={this.props.procedureDateFilterType}
        defaultSelectedKey={this.getSelected()}
        onRenderTitle={(props: any) => {
          const displayName: any = this.datePickerDropdownOption
            .filter(
              (val: any) => val.key === this.props.procedureDateFilterType
            )
            .map((val: any) => val.text);
          return displayName[0] ? displayName[0] : "Select Time Period";
          // if (this.props.procedureDateFilterType === "customDate") {
          //   props[0] = { key: "customDate", text: "Custom Date" };
          //   return "Custom Date";
          // }
          // return props[0].text;
        }}
      />
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    procedureVideosData: state.landingPageReducer.procedureVideos,
    procedureDateFilterType: state.landingPageReducer.procedureDateFilterType,
    customSelectedDates: state.landingPageReducer.customDate,
  };
};
const dispatchActionToProps = (dispatch: any) => {
  return {
    getProcedureVideos: () => dispatch(getProcedureVideos()),
    setProcedureDateFilterType: (selectedOption: string) =>
      dispatch(setProcedureDateFilterType(selectedOption)),
    setProcedureDateCustomFilterDateStorage: (
      option: any,
      dateSelectType: string
    ) =>
      dispatch(setProcedureDateCustomFilterDateStorage(option, dateSelectType)),
  };
};
export default connect(
  mapStateToProps,
  dispatchActionToProps
)(DateRangeDropdown);
