import {
  DEVICE_DECREMENT,
  DEVICE_INCREMENT,
  DEVICE_DATA,
  GET_DEVICES_LIST,
  CREATE_DEVICES,
  ROLE_RESET,
  ROLE_DECREMENT,
  ROLE_INCREMENT,
  GET_ROLES_LIST,
  GET_PERMISSION_LIST,
  ROLESPERMISSION_DATA,
  SPINNER_ROLE,
  CREATE_ROLESPERMISSION,
  SPINNER_DEVICE,
  CLOSE_DEVICE_MODAL,
  DELETE_DEVICES,
  CLOSE_ROLE_MODAL,
  GET_PROCEDURE_LIST,
  CREATE_PROCEDURE,
  GET_SPECIALTY_LIST,
  CREATE_SPECIALTY,
  SPECIALTY_DATA,
  GET_ALGORITHM_LIST,
  GET_ALGORITHM_VERSION_LIST,
  CREATE_ALGORITHM,
  PROCEDURE_DATA,
  ERROR_IN_PROCEDURE,
  CLOSE_ERRORS,
  ERROR_IN_SPECIALTY,
  CREATE_ALGORITHM_VERSION,
  DELETE_PROCEDURE,
  DELETE_ALGORITHAM,
  DELETE_ALGORITHAM_VERSION,
  DELETE_ROLE_PERMISSION,
  GET_ALGORITHM_MODEL_IMAGE_LIST,
  GET_DEVICE_VIEW,
  SHOW_CREATE_DEVICES,
  EDIT_DEVICES,
  GET_WORKFLOW_LIST,
  PROCEDURE_DATA_ID,
  GET_BUSINESS_ALGORITHM_LIST,
  REFRESH_BUSINESS_ALGORITHM_LIST,
  GET_ALGORITHM_TYPE_LIST,
  ALGORITHAM_VIEW,
  UPDATE_INPUT_PARAMETER_DATA,
  GET_ALGORITHM_OUTPUT_LIST,
  GET_ROLES_VIEW,
  SHOW_CREATE_ROLE,
  EDIT_ROLES,
  CLOSE_ALGORITHM_MODAL,
  CLOSE_ALGORITHM_VERSION_MODAL,
  GET_ALGORITHM_VIEW,
  GET_ALGORITHM_VERSION_VIEW,
  SHOW_CREATE_ALGORITHM_VERSION,
  SHOW_CREATE_ALGORITHM,
  EDIT_ALOGRITHM,
  EDIT_ALOGRITHM_VERSION,
  CLOSE_WORKFLOW_MODAL,
  SHOW_CREATE_WORKFLOW,
  WORKFLOW_DATA,
  CREATE_WORKFLOW,
  GET_WORKFLOW_INPUT_TYPE_LIST,
  GET_WORKFLOW_TYPE_LIST,
  CREATE_WORKFLOW_MAPPING,
  DELETE_WORKFLOW,
  GET_WORKFLOW_VIEW,
  GET_PROCEDURE_VIEW,
  CLEAR_PROCEDURE_DATA,
  EDIT_PROCEDURE,
  UPDATE_OUTPUT_ARGUMENTS,
  LOADER,
  STOPLOADER,
  GET_LOGIN_USER_PROFILE_MANAGAMENT,
  EDIT_WORKFLOW,
  CREATE_SINGLE_PROCEDURE,
  GET_PROCEDURE,
  CREATE_PROCEDURE_SPACIALTY,
  CLEAR_BUSINESS_ALGORITHM_LIST,
  GET_ROLE_LOCATIONS,
  SELECTED_ROLE_NAME,
  SELECTED_ROLE_LOCATION,
  SELECTED_PERMISSION_USER_TYPE,
  SELECTED_ROLE_COMMENTS,
  ERROR_WORKFLOW,
  SAVE_FIRST_STEP_DATA,
  ERROR_ALG,
  IS_PERMISSION_LIST_FETCHED,
  ERROR_IN_PROCEDURE_CREATE,
  CLEAR_PROCEDURE,
} from "../actions/actionConstants";

const initialState: any = {
  deviceCount: 0,
  showSpinner: false,
  deviceData: [],
  deviceList: [],
  deviceView: [],
  check: [],

  rolesList: [],
  roleCount: 0,
  permissionList: [],
  rolesPermissionData: [],
  showRoleSpinner: false,
  closeRoles: false,
  closeDevices: false,
  showDeviceSpinner: false,
  deleteDevice: "",
  procedureList: [],
  procedureData: [],
  specialtyList: {},
  specialtyData: [],
  specialtySpinner: false,
  procedureSpinner: false,
  algorithmSpinner: false,
  algorithmVersionSpinner: false,
  algorithmList: [],
  algorithmVersionList: [],
  errorResponse: "",
  errorSpecialty: false,
  deleteProcedure: "",
  deleteAlgorithm: "",
  deleteAlgorithmVersion: "",
  deleteRolePersmission: "",
  algorithmModelImageList: "",
  showCreateDevice: false,
  showCreateRole: false,
  workflowList: [],
  ProcedureResultsByid: [],
  businessAlgorithmList: [],
  algorithmTypeList: [],
  algorithmView: [],
  inputParameter: [],
  inputParameterValue: [],
  roleView: [],
  algorithmVersionData: [],
  algorithmData: [],
  showCreateAlgorithm: false,
  showCreateAlgorithmVerison: false,
  showCreateWorkflow: false,
  closeWhenErrors: false,
  workflowData: [],
  closeWorkflow: false,
  closeWorkflowMapping: false,
  workflowInputType: [],
  workflowType: [],
  workflowMappingData: [],
  deleteWorkflow: "",
  WorkflowView: [],
  procedureView: [],
  outputArguments: [],
  loadingState: false,
  loginUserProfileForManagement: {},
  procedure: [],
  singleProcedureSpinner: false,
  procedureSingleList: [],
  closeProcedure: false,
  roleLocations: [
    { key: "CLIENT", text: "CLIENT" },
    { key: "ACTIVSURGICAL", text: "ACTIVSURGICAL" },
  ],
  permissionsUserTypes: [
    { key: "ActivAdmin", text: "Activ Admin" },
    { key: "Developer", text: "Developer" },
    { key: "Dual", text: "Dual" },
  ],
  permissionsUserTypesClient: [
    { key: "HospitalAdmin", text: "Hospital Admin" },
  ],
  selectedNameForRole: "",
  selectedRoleLocation: "",
  selectedPermissionUserType: "",
  selectedCommentsForRole: "",
  isPermissionListFetched: false,
  loaderWithMessageRoles: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageDevices: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageDeviceView: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageAlgorithms: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageProcedures: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageWorkflow: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageProceduresDropDown: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },

  loaderWithMessageAlgorithmsVersion: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  procedureError: "",
};

export const managementReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case ERROR_WORKFLOW:
      return {
        ...state,
        errorResponse: action.payload,
      };
    case ERROR_ALG:
      return {
        ...state,
        errorResponse: action.payload,
      };
    case DEVICE_INCREMENT:
      return {
        ...state,
        deviceCount: state.deviceCount + 1,
      };
    case DEVICE_DECREMENT:
      return {
        ...state,
        deviceCount: state.deviceCount - 1,
      };
    case ROLE_RESET:
      return {
        ...state,
        roleCount: 0,
      };
    case ROLE_INCREMENT:
      return {
        ...state,
        roleCount: state.roleCount + 1,
      };
    case ROLE_DECREMENT:
      return {
        ...state,
        roleCount: state.roleCount - 1,
      };
    case GET_ROLES_LIST:
      return {
        ...state,
        rolesList: action.payload,
      };
    case GET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.payload,
      };
    case GET_DEVICES_LIST:
      return {
        ...state,
        deviceList: action.payload,
      };
    case GET_DEVICE_VIEW:
      return {
        ...state,
        deviceView: action.payload,
        deviceData: {
          ...action.payload,
        },
      };
    case GET_ROLES_VIEW:
      return {
        ...state,
        roleView: action.payload,
        rolesPermissionData: {
          ...action.payload,
        },
      };
    case GET_ALGORITHM_VIEW:
      const input_parameters = action.payload.data.input_parameters.split(",");
      const output_parameters =
        action.payload.data.output_parameters.split(",");
      const data = {
        ...action.payload.data,
        output_parameters,
        input_parameters,
      };
      return {
        ...state,
        algorithmView: action.payload,

        algorithmData: {
          data,
        },
      };
    case GET_ALGORITHM_VERSION_VIEW:
      return {
        ...state,
        algorithmVersionView: action.payload,
        algorithmVersionData: {
          ...action.payload,
        },
      };
    case SHOW_CREATE_DEVICES:
      return {
        ...state,
        showCreateDevice: true,
      };
    case SHOW_CREATE_ROLE:
      return {
        ...state,
        showCreateRole: true,
      };
    case SHOW_CREATE_ALGORITHM:
      return {
        ...state,
        showCreateAlgorithm: true,
      };
    case SHOW_CREATE_ALGORITHM_VERSION:
      return {
        ...state,
        showCreateAlgorithmVerison: true,
      };
    case SHOW_CREATE_WORKFLOW:
      return {
        ...state,
        showCreateWorkflow: true,
      };
    case CREATE_DEVICES:
      return {
        ...state,
        deviceList: {
          data: {
            results: [
              {
                ...action.payload.data,
                assigned_by: state?.loginUserProfileForManagement?.displayName,
              },
              ...state.deviceList.data.results,
            ],
          },
        },
        showDeviceSpinner: false,
        closeDevices: true,
        showCreateDevice: true,
        deviceCount: 0,
        deviceData: [],
      };
    case EDIT_DEVICES:
      // console.log(
      //   action.payload.data,
      //   [...state.deviceList.data.results, {...action.payload.data}],

      //   "EDIT_DEVICES"
      // );
      // const myArrayFiltered = state.deviceList.data.results.filter((el:any) => {
      //   return myFilter.some((f:any) => {
      //     return f.userid === el.userid && f.projectid === el.projectid;
      //   });
      // });
      // debugger;
      return {
        ...state,
        // deviceList: {
        //   data: {
        //     results: [
        //       {
        //         ...action.payload.data,
        //       },
        //       ...state.deviceList.data.results,
        //     ],
        //   },
        // },
        showDeviceSpinner: false,
        closeDevices: true,
        deviceCount: 0,
        deviceData: [],
      };
    case DEVICE_DATA:
      return {
        ...state,
        deviceData: action.payload,
      };
    case SPINNER_ROLE:
      return {
        ...state,
        closeRoles: false,
        specialtySpinner: false,
        procedureSpinner: false,
        algorithmSpinner: false,
        algorithmVersionSpinner: false,
        closeWorkflow: false,
        closeWorkflowMapping: false,
        singleProcedureSpinner: false,
        closeProcedure: false,

        roleCount: 0,
        rolesPermissionData: [],
        closeWhenErrors: false,
        deviceCount: 0,
        deviceData: [],
        showCreateDevice: false,
        algorithmData: [],
        showCreateAlgorithm: false,
        workflowData: [],
        showCreateWorkflow: false,
      };
    case SPINNER_DEVICE:
      return {
        ...state,
        closeDevices: false,
        algorithmVersionSpinner: false,
        deviceCount: 0,
        deviceData: [],
        // showCreateDevice: false,
        closeWhenErrors: false,
      };

    case ROLESPERMISSION_DATA:
      return {
        ...state,
        rolesPermissionData: action.payload,
      };
    case SAVE_FIRST_STEP_DATA:
      return {
        ...state,
        roleDetailsFirstStep: action.payload,
      };
    case CREATE_ROLESPERMISSION:
      return {
        ...state,
        rolesList: {
          data: {
            results: [
              {
                ...action.payload.data,
                assigned_by: state?.loginUserProfileForManagement?.displayName,
              },
              ...state.rolesList.data.results,
            ],
          },
        },
        showRoleSpinner: false,
        closeRoles: true,
        selectedNameForRole: "",
        selectedRoleLocation: "",
        selectedPermissionUserType: "",
        selectedCommentsForRole: "",
      };
    case EDIT_ROLES:
      return {
        ...state,
        // rolesList: {
        //   data: {
        //     results: [
        //       {
        //         ...action.payload.data,
        //       },
        //       ...state.rolesList.data.results,
        //     ],
        //   },
        // },
        showRoleSpinner: false,
        closeRoles: true,
        roleCount: 0,
        rolesPermissionData: [],
        // showCreateRole: false,
      };

    case CLOSE_DEVICE_MODAL:
      return {
        ...state,
        deviceCount: 0,
        deviceData: [],
        showCreateDevice: false,
      };
    case DELETE_DEVICES:
      return {
        ...state,
        deleteDevice: action.payload,
      };
    case CLOSE_ROLE_MODAL:
      return {
        ...state,
        roleCount: 0,
        rolesPermissionData: [],
        showCreateRole: false,
        selectedNameForRole: "",
        selectedRoleLocation: "",
        selectedPermissionUserType: "",
        selectedCommentsForRole: "",
        permissionList: [],
      };
    case CLOSE_ALGORITHM_MODAL:
      return {
        ...state,
        algorithmData: [],
        showCreateAlgorithm: false,
      };
    case CLOSE_ALGORITHM_VERSION_MODAL:
      return {
        ...state,
        algorithmVersionData: [],
        showCreateAlgorithmVerison: false,
      };
    case GET_PROCEDURE_LIST: {
      return {
        ...state,
        procedureList: action.payload,
      };
    }
    case CREATE_PROCEDURE: {
      // debugger;
      return {
        ...state,
        procedureData: [],
        procedureSpinner: true,
        procedureList: {
          data: {
            results: [
              {
                ...action.payload.data[0],
                assigned_by: state?.loginUserProfileForManagement?.displayName,
              },
              ...state?.procedureList?.data?.results,
            ],
          },
        },
      };
    }
    case CREATE_SPECIALTY: {
      return {
        ...state,
        // specialtyList: {
        //   data: [
        //     ...state.specialtyList.data,
        //     {
        //       ...action.payload.data,
        //       assigned_by: state?.loginUserProfileForManagement?.displayName,
        //     },
        //   ],
        // },
        specialtyData: [],
        specialtySpinner: true,
      };
    }
    case GET_SPECIALTY_LIST: {
      return {
        ...state,
        specialtyList: action.payload,
      };
    }
    case SPECIALTY_DATA:
      return {
        ...state,
        specialtyData: action.payload,
      };
    case GET_ALGORITHM_LIST:
      return {
        ...state,
        algorithmList: action.payload,
      };

    case CREATE_ALGORITHM:
      return {
        ...state,
        algorithmList: {
          data: {
            results: [
              {
                ...action.payload.data,
                assigned_by: state?.loginUserProfileForManagement?.displayName,
              },
              ...state?.algorithmList?.data?.results,
            ],
          },
        },
        algorithmData: [],
        algorithmSpinner: true,
      };
    case EDIT_ALOGRITHM:
      return {
        ...state,
        // algorithmList: {
        //   data: {
        //     results: [
        //       {
        //         ...action.payload.data,
        //       },
        //       ...state?.algorithmList?.data?.results,
        //     ],
        //   },
        // },
        algorithmSpinner: true,
      };
    case PROCEDURE_DATA:
      return {
        ...state,
        procedureData: action.payload,
      };
    case ERROR_IN_PROCEDURE:
      return {
        ...state,
        errorProcedure: true,
        errorResponse: action.payload,
        closeRoles: false,
        closeWhenErrors: true,
        // procedureError:action?.payload?.data[0]?.details
        // -----------role---------
      };
    case ERROR_IN_PROCEDURE_CREATE:
      return {
        ...state,
        procedureError: action?.payload?.data[0]?.details,
      };

    case ERROR_IN_SPECIALTY:
      return {
        ...state,
        errorSpecialty: true,
        // errorResponse: action.payload,
        specialityError: action?.payload?.data[0]?.details,
      };
    case CLOSE_ERRORS:
      return {
        ...state,
        errorProcedure: false,
        errorResponse: "",
        procedureError: "",
        specialityError: "",
      };
    case CREATE_ALGORITHM_VERSION:
      return {
        ...state,
        algorithmVersionList: {
          data: {
            results: [
              {
                ...action.payload.data,
                assigned_by: state?.loginUserProfileForManagement?.displayName,
              },
              ...state?.algorithmVersionList?.data?.results,
            ],
          },
        },
        algorithmVersionSpinner: true,
        algorithmVersionData: [],
      };
    case EDIT_ALOGRITHM_VERSION:
      return {
        ...state,
        algorithmVersionSpinner: true,
        algorithmVersionData: [],
      };
    case GET_ALGORITHM_VERSION_LIST:
      return {
        ...state,
        algorithmVersionList: action.payload,
      };
    case DELETE_PROCEDURE:
      return {
        ...state,
        deleteProcedure: action.payload,
      };
    case DELETE_ALGORITHAM:
      return {
        ...state,
        deleteAlgorithm: action.payload,
      };
    case DELETE_ALGORITHAM_VERSION:
      return {
        ...state,
        deleteAlgorithmVersion: action.payload,
      };
    case DELETE_ROLE_PERMISSION:
      return {
        ...state,
        deleteRolePersmission: action.payload,
      };
    case GET_ALGORITHM_MODEL_IMAGE_LIST:
      return {
        ...state,
        algorithmModelImageList: action.payload,
      };

    case GET_ALGORITHM_TYPE_LIST:
      return {
        ...state,
        algorithmTypeList: action.payload,
      };
    case GET_BUSINESS_ALGORITHM_LIST:
      const previousData = state.businessAlgorithmList?.data?.results || [];
      state.businessAlgorithmList = action.payload;
      state.businessAlgorithmList.data.results = [
        ...previousData,
        ...state.businessAlgorithmList.data.results,
      ];

      return {
        ...state,
      };
    case REFRESH_BUSINESS_ALGORITHM_LIST:
      return {
        ...state,
        businessAlgorithmList: action.payload,
      };
    case ALGORITHAM_VIEW:
      return {
        ...state,
        algorithmView: action.payload,
      };
    case UPDATE_INPUT_PARAMETER_DATA:
      return {
        ...state,
        inputParameter: action.payload,
      };
    case GET_ALGORITHM_OUTPUT_LIST:
      return {
        ...state,
        inputParameterValue: action.payload,
      };
    case WORKFLOW_DATA:
      return {
        ...state,
        workflowData: action.payload,
      };
    case CLOSE_WORKFLOW_MODAL:
      return {
        ...state,
        workflowData: [],
        showCreateWorkflow: false,
      };
    case GET_WORKFLOW_LIST:
      return {
        ...state,
        workflowList: action.payload,
      };
    case PROCEDURE_DATA_ID:
      return {
        ...state,
        ProcedureResultsByid: action.payload,
      };

    case CREATE_WORKFLOW:
      return {
        ...state,
        workflowList: {
          data: {
            results: [
              {
                ...action.payload.data?.workflow,
                assigned_by: state?.loginUserProfileForManagement?.displayName,
              },
              ...state?.workflowList?.data?.results,
            ],
          },
        },
        closeWorkflow: true,
        workflowData: [],
      };
    case GET_WORKFLOW_INPUT_TYPE_LIST:
      return {
        ...state,
        workflowInputType: action.payload,
      };
    case GET_WORKFLOW_TYPE_LIST:
      return {
        ...state,
        workflowType: action.payload,
      };
    case CREATE_WORKFLOW_MAPPING:
      return {
        ...state,
        // workflowList: {
        //   data: {
        //     results: [
        //       {
        //         ...action.payload.data?.workflow,
        //       },
        //       ...state?.workflowList?.data?.results,
        //     ],
        //   },
        // },
        closeWorkflowMapping: true,
        workflowMappingData: [],
      };
    case DELETE_WORKFLOW:
      return {
        ...state,
        deleteWorkflow: action.payload,
      };
    case GET_WORKFLOW_VIEW:
      return {
        ...state,
        WorkflowView: action.payload,
        workflowData: {
          ...action.payload,
        },
      };
    case GET_PROCEDURE_VIEW:
      return {
        ...state,
        procedureView: action.payload,
        procedureData: {
          ...action.payload,
        },
      };
    case CLEAR_PROCEDURE_DATA:
      return {
        ...state,
        procedureData: null,
      };
    case EDIT_PROCEDURE:
      const EditProcedureResults = state.procedureList?.data?.results?.map(
        (result: any) => {
          if (result?.name === action.payload?.data?.name) {
            // debugger;
            result = {
              ...action.payload.data,
              assigned_by: state?.loginUserProfileForManagement?.displayName,
            };
            return result;
          }
          return result;
        }
      );
      return {
        ...state,
        procedureData: [],
        procedureSpinner: true,
        procedureList: {
          data: {
            results: EditProcedureResults,
          },
        },
        closeProcedure: true,
      };
    case UPDATE_OUTPUT_ARGUMENTS:
      return {
        ...state,
        outputArguments: action.payload,
      };
    case LOADER:
      if (action.payload.calledFrom === "GET_ALGORITHM_LIST") {
        return {
          ...state,
          loaderWithMessageAlgorithms: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_ALGORITHM_VERSION_LIST") {
        return {
          ...state,
          loaderWithMessageAlgorithmsVersion: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_ROLES_LIST") {
        return {
          ...state,
          loaderWithMessageRoles: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_DEVICES_LIST") {
        return {
          ...state,
          loaderWithMessageDevices: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_DEVICE_VIEW") {
        return {
          ...state,
          loaderWithMessageDeviceView: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_PROCEDURE_LIST") {
        return {
          ...state,
          loaderWithMessageProcedures: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_WORKFLOW_LIST") {
        return {
          ...state,
          loaderWithMessageWorkflow: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_PROCEDURE") {
        return {
          ...state,
          loaderWithMessageProceduresDropDown: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
    case STOPLOADER:
      if (action.payload.calledFrom === "GET_ALGORITHM_LIST") {
        return {
          ...state,
          loaderWithMessageAlgorithms: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_ALGORITHM_VERSION_LIST") {
        return {
          ...state,
          loaderWithMessageAlgorithmsVersion: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_ROLES_LIST") {
        return {
          ...state,
          loaderWithMessageRoles: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_DEVICES_LIST") {
        return {
          ...state,
          loaderWithMessageDevices: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_DEVICE_VIEW") {
        return {
          ...state,
          loaderWithMessageDeviceView: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_PROCEDURE_LIST") {
        return {
          ...state,
          loaderWithMessageProcedures: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_WORKFLOW_LIST") {
        return {
          ...state,
          loaderWithMessageWorkflow: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_PROCEDURE") {
        return {
          ...state,
          loaderWithMessageProceduresDropDown: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      }
    case GET_LOGIN_USER_PROFILE_MANAGAMENT:
      return {
        ...state,
        loginUserProfileForManagement: action.payload,
      };
    case EDIT_WORKFLOW:
      // debugger;
      const results = state.workflowList?.data?.results?.map((result: any) => {
        if (result?.id === action.payload?.data?.workflow?.id) {
          // debugger;
          result = {
            ...action.payload.data?.workflow,
            assigned_by: state?.loginUserProfileForManagement?.displayName,
          };
          return result;
        }
        return result;
      });
      return {
        ...state,
        workflowList: {
          data: {
            results: results,
          },
        },
        closeWorkflow: true,
      };
    case CREATE_SINGLE_PROCEDURE:
      // debugger;
      return {
        ...state,
        singleProcedureSpinner: true,
        procedureSingleList: {
          data: {
            results: [
              {
                name: action.payload?.data?.name,
              },
              ...state.procedureSingleList.data.results,
            ],
          },
        },
      };
    case GET_PROCEDURE:
      return {
        ...state,
        procedureSingleList: action.payload,
      };
    case CREATE_PROCEDURE_SPACIALTY:
      const ProcedureResults = state.procedureList?.data?.results?.map(
        (result: any) => {
          if (result?.name === action.payload?.data?.name) {
            // debugger;
            result = {
              ...action.payload.data,
              assigned_by: state?.loginUserProfileForManagement?.displayName,
            };
            return result;
          }
          return result;
        }
      );
      return {
        ...state,
        procedureList: {
          data: {
            results: ProcedureResults,
          },
        },
        procedureSpinner: true,
        closeProcedure: true,

        // procedureSingleList: action.payload,
      };
    case CLEAR_BUSINESS_ALGORITHM_LIST:
      return {
        ...state,
        businessAlgorithmList: [],
      };
    case GET_ROLE_LOCATIONS:
      return {
        ...state,
        roleLocations: action.payload?.roleLocations,
      };
    case SELECTED_ROLE_NAME:
      return {
        ...state,
        selectedNameForRole: action.payload?.selectedNameForRole,
      };
    case SELECTED_ROLE_LOCATION:
      return {
        ...state,
        selectedRoleLocation: action.payload?.selectedRoleLocation,
      };
    case SELECTED_PERMISSION_USER_TYPE:
      return {
        ...state,
        selectedPermissionUserType: action.payload?.selectedPermissionUserType,
      };
    case SELECTED_ROLE_COMMENTS:
      return {
        ...state,
        selectedCommentsForRole: action.payload?.selectedCommentsForRole,
      };
    case IS_PERMISSION_LIST_FETCHED:
      return {
        ...state,
        isPermissionListFetched: action.payload?.isPermissionListFetched,
      };
    case CLEAR_PROCEDURE:
      return {
        ...state,
        procedureList: [],
      };
    default:
      return state;
  }
};
