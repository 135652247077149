import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
  TextField,
  StackItem,
  Label,
  SearchBox,
  FontIcon,
  MessageBar,
  MessageBarType,
  Image,
  TooltipHost,
} from "@fluentui/react";
import Select from "react-select";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import { ThemeColorPalette } from "../../../../theme";
import errorIcon from "../../../../../src/assets/model_warning.svg";
import inprogressIcon from "../../../../../src/assets/model_inprogress.svg";

import "./Models.scss";
import {
  deleteModel,
  getModelName,
  disableDeleteModel,
  showModelSpinner,
} from "../../actions/managementAction";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import Paging from "../../../../common/paging/Paging";
import { useLocation, useNavigate } from "react-router-dom";
import AddModel from "./AddModel/AddModel";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import { debounce } from "lodash";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const menuItems = [
  {
    key: "edit",
    text: "Edit",
  },
  {
    key: "delete",
    text: "Delete",
    scope: [SCOPES.DEVELOPER_DELETE_MODEL],
  },
];

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Models = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: {},
  } = useForm();

  const [showRole, setShowRole] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [modelId, setModelId] = useState<string>("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [messageBar, setMessageBar] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showPage, setshowPage] = useState<any>(true);
  const { state }: { state: any } = useLocation();
  const closeRoles = useSelector((state: AppState) => {
    return state.developerManagementReducerData.closeRoles;
  });

  const toggleModal = () => {
    setShowRole(true);
  };

  const dispatch = useDispatch();

  const modelsList = useSelector((state: AppState) => {
    return state.developerManagementReducerData.modelNames;
  });

  const deleteModelsData = useSelector((state: AppState) => {
    return state.developerManagementReducerData.deleteModel;
  });
  const deleteModelError = useSelector((state: AppState) => {
    return state.developerManagementReducerData.deleteModelError;
  });
  const deleteModelSuccess = useSelector((state: AppState) => {
    return state.developerManagementReducerData.deleteModelSuccess;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.loaderWithMessageModel;
  });

  const [visibleModelList, setVisibleModelList] = useState(false);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_MODEL_NAME" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleModelList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_MODEL_NAME" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleModelList(false);
    }
  }, [loaderWithMessage]);

  useEffect(() => {
    !state && dispatch(getModelName(currentPage, searchText));
  }, [deleteModelsData]);

  useEffect(() => {
    if (state !== null) {
      const { page } = state;
      setCurrentPage(page);
      dispatch(getModelName(page));
    }
  }, []);
  useEffect(() => {
    if (deleteModelError) {
      setErrors({ ...errors, deleteModel: true });
    }
  }, [deleteModelError]);

  useEffect(() => {
    setshowPage(true);
  }, [modelsList]);

  useEffect(() => {
    if (deleteModelSuccess) {
      setErrors({ ...{} });
      setMessageBar(true);
    }
  }, [deleteModelSuccess]);

  const onPageChange = (pageNo: number, searchData?: any) => {
    setCurrentPage(pageNo);
    dispatch(
      getModelName(pageNo, searchData !== undefined ? searchData : searchText)
    );
  };

  const deleteIconClick = (model: any, evt: any) => {
    setModelId(model.uuid);
    showDeleteModal();
  };

  const handleDeleteModel = () => {
    setDisabledCreate(true);
    dispatch(deleteModel(modelId));
    setOpenDeleteModal((current) => !current);
  };

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);

  const _onMenuClick = useCallback((text: string, id: string | number) => {
    if (text.toLowerCase() === "delete") {
      dispatch(deleteModel(id));
    }
    if (text.toLowerCase() === "edit") {
      // dispatch(getFacilityById(id));
    }
  }, []);

  const resetChoice = React.useCallback(() => {
    setErrors({});
    dispatch({
      type: "RESET_DELETE_MODEL_ERROR",
    });
  }, []);

  const navigate = useNavigate();

  const viewModel = (
    algId: any,
    id: any,
    version: any,
    name: any,
    alg: any
  ) => {
    navigate(`/${algId}/${id}/${version}/${name}/${alg}`, {
      state: { page: currentPage },
    });
  };

  const searchChangeHandler = useCallback(
    debounce((evt) => {
      dispatch(getModelName(1, evt));
    }, 2000),
    []
  );

  const handleSearch = (e: any) => {
    const value = e?.target.value || "";
    setSearchText(value);
    if (value.length > 3 || value.length === 0) {
      //   searchChangeHandler(value);
      setshowPage(false);
      onPageChange(1, value);
    }
  };

  return (
    <Stack className="deviceManagement">
      {errors.deleteModel && (
        <ShowErrorMessageBar
          resetChoice={resetChoice}
          errorResponse={deleteModelError ? deleteModelError : []}
        ></ShowErrorMessageBar>
      )}
      {messageBar && (
        <MessageBar
          delayedRender={false}
          isMultiline={false}
          messageBarType={MessageBarType.success}
          dismissButtonAriaLabel="Close"
          onDismiss={() => setMessageBar(false)}
          className="errorMessages"
        >
          Model Deleted Successfully!
        </MessageBar>
      )}
      {messageBar &&
        setTimeout(() => {
          setMessageBar(false);
          dispatch(disableDeleteModel(false));
        }, 3000) && <></>}
      {deleteModelError &&
        setTimeout(() => {
          resetChoice();
        }, 3000) && <></>}

      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item className="heading">
          <Text>Model Management</Text>
        </Stack.Item>
        <SearchBox
          className="searchBox activitySearch"
          placeholder="Search"
          underlined={true}
          value={searchText}
          onChange={handleSearch}
        />
      </Stack>
      <Stack.Item className="addDevice">
        <CheckPermission {...SCOPES.DEVELOPER_CREATE_MODEL}>
          <DefaultButton
            onClick={toggleModal}
            text="Add Model"
            iconProps={addIcon}
            allowDisabledFocus
          />
        </CheckPermission>
      </Stack.Item>
      {showRole && (
        <AddModel
          isModalOpen={true}
          hideModal={() => {
            setSearchText("");
            setShowRole(false);
          }}
        ></AddModel>
      )}
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">All Models</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text>Name of the Model</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1">
              <Text variant="smallPlus">Version</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1">
              <Text variant="smallPlus">Rating</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Mapped Algorithm</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Created Date</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Status</Text>
            </Stack.Item>
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
          {visibleModelList &&
            modelsList?.results?.map((model: any, index: any) => (
              <Stack.Item key={index} className="ms-Grid-row tableText ">
                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text title={model?.name} variant="smallPlus">
                    {model?.name}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1 textEllipses">
                  <Text title={model?.version} variant="smallPlus">
                    {model?.version}
                  </Text>
                </Stack.Item>
                <Stack.Item
                  className="ms-Grid-col ms-lg1 pointer"
                  onClick={() => {
                    if (model?.avg_rating) {
                      viewModel(
                        model?.algorithm,
                        model?.id,
                        model?.version,
                        model?.name,
                        model?.mapped_algorithm
                      );
                    }
                  }}
                >
                  <Text variant="smallPlus" style={{ width: "10px" }}>
                    {model?.avg_rating || "NA"}
                  </Text>
                  {model?.avg_rating && model?.comment?.length > 0 && (
                    <Text
                      style={{
                        background: "#7E7E81",
                        padding: "8px",
                        borderRadius: "10px",
                        display: "inline-block",
                        height: "3px",
                        lineHeight: "2px",
                        color: "#000000",
                        margin: "auto",
                      }}
                    >
                      {" "}
                      <TooltipHost content={model?.comment}>i</TooltipHost>
                    </Text>
                  )}
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1 "></Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text title={model?.mapped_algorithm} variant="smallPlus">
                    {model?.mapped_algorithm}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2 ">
                  <Text variant="smallPlus">
                    {moment(model?.created_date).format("MM/DD/YY HH:mm")}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">{model?.status}</Text>
                </Stack.Item>
                <StackItem className="ms-Grid-col ms-lg1">
                  <TooltipHost content={model?.status_message}>
                    {model?.status === "ERROR" && (
                      <Image src={errorIcon} alt="Activ Edge logo" />
                    )}
                    {model?.status === "IN_PROGRESS" && (
                      <Image src={inprogressIcon} alt="Activ Edge logo" />
                    )}
                  </TooltipHost>
                </StackItem>

                <Stack.Item className="ms-Grid-col ms-lg1">
                  <Text variant="smallPlus">
                    <CheckPermission {...SCOPES.DEVELOPER_DELETE_MODEL}>
                      <FontIcon
                        iconName="Delete"
                        style={{
                          padding: "8px",
                          color: ThemeColorPalette.themePrimary,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          deleteIconClick(model, e);
                        }}
                      />
                    </CheckPermission>
                  </Text>
                </Stack.Item>
              </Stack.Item>
            ))}
        </Stack.Item>
        <DeleteModal
          isModalOpen={openDeleteModal}
          deleteHandle={handleDeleteModel}
          showModal={showDeleteModal}
          id={modelId}
          name="Model"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        {visibleModelList &&
        modelsList?.count > 10 &&
        modelsList?.page_size &&
        showPage ? (
          <Paging
            currentPage={currentPage}
            totalPageCount={Math.ceil(modelsList?.count / 10)}
            handlePageChange={onPageChange}
          />
        ) : null}
      </Stack>
      <Modal isOpen={closeRoles} containerClassName={"modalStyle"}>
        <SuccessMessage
          title="Model Creation"
          subText="Model related files are being uploaded. After the successful upload, model will be available for use!"
        />
      </Modal>
      {closeRoles &&
        setTimeout(() => {
          // dispatch(getModelName());
          setShowRole(false);
          dispatch(showModelSpinner());
        }, 5000) && <></>}
      {useEffect(() => {
        if (closeRoles) {
          dispatch(getModelName(currentPage));
        }
      }, [closeRoles])}
    </Stack>
  );
};

export default Models;
