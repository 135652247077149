import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { getSurgeonById, getVideosById } from "../../actions/homeAction";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import SurgeonFullView from "./SurgeonFullView";
import { useParams } from "react-router-dom";
import Loader from "../../../../ActiveAdminModule/Logs/components/Common/Loader";

const SurgeonView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const surgeonById = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.surgeonById;
  });

  const videosById = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.videosById;
  });

  const loadingState = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loadingState;
  });

  useEffect(() => {
    dispatch(getSurgeonById(id));
    dispatch(getVideosById(id, 1));
  }, []);
  return (
    <Stack>
      <SurgeonFullView
        id={id}
        surgeon={surgeonById}
        videos={videosById}
      ></SurgeonFullView>
      {loadingState && (
        <>
          <Loader></Loader>
        </>
      )}
    </Stack>
  );
};

export default SurgeonView;
