import React, { useState, useEffect } from "react";
import {
  Stack,
  Modal,
  IconButton,
  IIconProps,
  Image,
  Link,
  Label,
} from "@fluentui/react";
import Papa from "papaparse";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import "./BulkSurgeonUpload.scss";
import { StackItem, Sticky } from "office-ui-fabric-react";
import { bulkOnboardSurgeon, getAllSurgeons } from "../../actions/homeAction";
import BulkImport from "../../../../assets/Home/bulkImg.png";
import { ReactComponent as UploadCloud } from "../../../../assets/Home/cloud_upload.svg";
import successMessageImage from "../../../../assets/Home/success_message.gif";
import { useCSVDownloader } from "react-papaparse";
import {
  BULK_ONBOARD_SURGEON,
  ERROR_IN_BULK_ONBOARD,
  LOADER,
} from "../../actions/actionConstants";
import Loader from "../../../../ActiveAdminModule/Logs/components/Common/Loader";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const BulkSurgeonUpload = ({
  isModalOpen,
  hideModal,
}: {
  isModalOpen: any;
  hideModal: any;
}) => {
  const hiddenFileInputRef: any = React.useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const { CSVDownloader, Type } = useCSVDownloader();
  const [showError, setShowError] = useState(false);
  const [isSuccessMessageImageHidden, setIsSuccessMessageImageHidden] =
    useState(true);
  const [isSuccessMessageHidden, setIsSuccessMessageHidden] = useState(true);

  const closeModal = () => {
    if (uploadedFiles) {
      dispatch(getAllSurgeons());
    }
    setUploadedFiles([]);
    closeFields();
    hideModal();
  };

  const dispatch = useDispatch();

  const closeFields = () => {
    setShowError(false);
    dispatch({
      type: ERROR_IN_BULK_ONBOARD,
      payload: null,
    });
    dispatch({
      type: BULK_ONBOARD_SURGEON,
      payload: null,
    });
  };

  const templateData = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.templateData?.data;
  });
  const bulkSurgeonOnboardError = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.bulkSurgeonOnboardError;
  });
  const bulkSurgeonData = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.bulkSurgeonOnboard;
  });
  const loadingState = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loadingState;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loaderWithMessage;
  });

  useEffect(() => {
    if (bulkSurgeonOnboardError) {
      setShowError(true);
      // setTimeout(() => {
      //   setShowError(false);
      // }, 10000);
    }
  }, [bulkSurgeonOnboardError]);

  useEffect(() => {
    return () => {
      setUploadedFiles([]);
      closeFields();
    };
  }, []);

  useEffect(() => {
    if (bulkSurgeonData) {
      setShowError(false);
      setIsSuccessMessageImageHidden(false);
      setIsSuccessMessageHidden(false);
      // setTimeout(() => {
      //   setIsSuccessMessageImageHidden(true);
      //   setIsSuccessMessageHidden(true);
      // }, 3000);
    }
  }, [bulkSurgeonData]);

  const handleFileChange = (e: any) => {
    closeFields();
    const files = e.target.files;
    setUploadedFiles(files);
    if (files) {
      try {
        parseFile(files[0]);
      } catch (e) {
        alert("file not supported");
      }
    }
  };

  const parseFile = (file: any) => {
    const config = {
      header: true,
      complete: (results: any) => {
        const resultsData = results.data;
        if (resultsData.length > 0) {
          const validResult = validateNameAndNumber(resultsData);
          if (validResult.length > 0) {
            dispatchBulkOnboardSurgeon(validResult);
          } else {
            setShowError(true);
          }
        }
      },
    };
    Papa.parse(file, config);
  };

  const validateNameAndNumber = (data: any) => {
    return data.filter(
      (r: any) => !!r.first_name
      // (r: any) => !!r.first_name && validatePhoneNumber(r.phone_number)
    );
    // return data;
  };

  const validatePhoneNumber = (input_str: any) => {
    // var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    // return re.test(input_str);
    return input_str?.match(/\d/g).length > 7;
  };
  const dispatchBulkOnboardSurgeon = (data: any) => {
    // dispatch({
    //   type: LOADER_HOSPITAL,
    // });
    dispatch(bulkOnboardSurgeon(data));
  };

  const renderBulkSurgeonErrors = (data: any) => {
    return (
      <Stack>
        <table>
          <tr>
            <th style={{ paddingRight: "65px" }}>Field</th>
            <th>Error</th>
          </tr>
          {data?.map((row: any, index: any) => {
            return (
              <>
                <tr key={index}>
                  <td>{row?.field}</td>
                  <td>{row?.details}</td>
                </tr>
              </>
            );
          })}
        </table>
      </Stack>
    );
  };
  return (
    <Modal
      className="bulkSurgeonUpload"
      isModeless
      isOpen={isModalOpen}
      onDismiss={closeModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack className="bulkSurgeonUploadSection" verticalFill>
        <Stack.Item align="end" styles={{ root: { marginBottom: "1rem" } }}>
          <IconButton
            className=""
            iconProps={cancelIcon}
            onClick={closeModal}
          />
        </Stack.Item>
        <Stack.Item
          align="center"
          styles={{
            root: {
              // width: "100%",
              height: 130,
              // background:
              //   "linear-gradient(89.94deg, #262641 30.02%, rgba(57, 56, 106, 0) 85.82%)",
              marginBottom: "1rem",
            },
          }}
        >
          <CSVDownloader
            type={Type.Link}
            filename={"bulk_surgeon"}
            bom={true}
            data={templateData}
          >
            <Link>
              <Image
                src={BulkImport}
                // width="1187px"
                // width= "100%"
                height="130px"
                alt="user image"
              ></Image>
            </Link>
          </CSVDownloader>
          {/* <BulkImport /> */}
        </Stack.Item>
        <Stack.Item
          verticalFill
          align="center"
          styles={{
            root: {
              width: "1187px",
              background: "#151515",
              marginBottom: "1rem",
              height: "calc(100vh - 264px)",
            },
          }}
        >
          {" "}
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
          <Stack
            verticalFill
            verticalAlign="center"
            horizontalAlign="center"
            tokens={{ padding: "1rem" }}
            styles={{ root: { width: "100%" } }}
          >
            {bulkSurgeonData && !showError ? (
              <Stack
                verticalAlign="center"
                horizontalAlign="center"
                verticalFill
                styles={{ root: { width: "100%" } }}
              >
                <StackItem>
                  {/* <SuccessMessage title=""/> */}
                  <img
                    src={successMessageImage}
                    alt="Success Image Gif"
                    width="250"
                    height="250"
                    hidden={isSuccessMessageImageHidden}
                  ></img>
                </StackItem>
                <StackItem
                  hidden={isSuccessMessageHidden}
                  className="bulkUploadSuccess"
                >
                  Successful
                </StackItem>
              </Stack>
            ) : (
              <Stack
                verticalAlign="center"
                horizontalAlign="center"
                tokens={{ childrenGap: 0 }}
                styles={{
                  root: {
                    border: "2px dashed #4D4D4D",
                    width: "100%",
                    display: "grid",
                    overflow: "auto",
                  },
                }}
                onClick={() =>
                  hiddenFileInputRef.current &&
                  hiddenFileInputRef.current.click()
                }
                verticalFill
              >
                <input
                  type="file"
                  accept=".csv,.xlsx,.xls"
                  ref={hiddenFileInputRef}
                  contentEditable={false}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <StackItem
                  styles={{
                    root: {
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                    },
                  }}
                >
                  {/* <FontIcon
                    iconName="CloudUpload"
                    aria-label="upload"
                    className="uploadIcon"
                  /> */}
                  <UploadCloud />
                </StackItem>
                <StackItem
                  styles={{
                    root: {
                      color: "#0FB3FF",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      marginTop: "0px",
                    },
                  }}
                >
                  Click here to upload .csv file!
                </StackItem>
                <StackItem
                  styles={{
                    root: {
                      color: "#a4262c",
                      padding: "2px",
                      margin: "0px",
                      position: "relative",
                    },
                  }}
                >
                  {bulkSurgeonOnboardError &&
                    renderBulkSurgeonErrors(
                      bulkSurgeonOnboardError && bulkSurgeonOnboardError?.data
                    )}
                </StackItem>
                <Stack
                  styles={{ root: { marginLeft: "250px", marginTop: "0px" } }}
                  verticalAlign="center"
                  horizontalAlign="center"
                ></Stack>
                {showError && (
                  <Label
                    styles={{
                      root: {
                        marginBottom: "15px",
                        fontSize: "18px",
                        color: "#a4262c",
                      },
                    }}
                  >
                    Error while uploading record(s)
                  </Label>
                )}
              </Stack>
            )}
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default BulkSurgeonUpload;
