import {
  Stack,
  StackItem,
  Panel,
  Text,
  Icon,
  Pivot,
  PivotItem,
  Label,
  TextField,
  PrimaryButton,
  DefaultButton,
  CommandBarButton,
  IIconProps,
  IPivotItemProps,
  Modal,
} from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { fonts, ThemeColorPalette } from "../../theme";
import Papa from "papaparse";
import { useDispatch, useSelector } from "react-redux";
import {
  createLogProcessing,
  createVideoProcessing,
} from "../../ActiveAdminModule/Home/actions/homeAction";
import { AppState } from "../../rootReducer";
import DownloadExampleCSVFile from "./DownloadExampleCSVFile";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { setWebWorker } from "../../MainWrapper/MainWrapper";
import SuccessMessage from "../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import { showSpinner } from "../../ActiveAdminModule/Home/actions/homeAction";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

const ActivSightDataProcess = ({
  isPanelOpen,
  dismiss,
  activeTab,
}: {
  isPanelOpen: any;
  dismiss: any;
  activeTab: any;
}) => {
  const [lastHeader, setLastHeader] = useState<
    { props: IPivotItemProps } | undefined
  >(undefined);
  //   const validationSchema = Yup.object().shape({
  //     uploadLogsFile: Yup.mixed().when(["id"], {
  //       is:
  //         lastHeader?.props.headerText === "Video Data" ||
  //         typeof lastHeader?.props.headerText === "undefined",
  //       then: Yup.mixed().required("Logs File is required"),
  //     }),
  //     uploadVideoFile: Yup.string().when(["id"], {
  //       is: lastHeader?.props.headerText === "Logs Data",
  //       then: Yup.string().required("Video File is required"),
  //     }),
  //   });

  //   const formOptions = { resolver: yupResolver(validationSchema) };
  //   const {
  //     register,
  //     handleSubmit,
  //     control,
  //     getValues,
  //     setError,
  //     formState: { errors },
  //   } = useForm(formOptions);

  //   console.log(errors,'errors');
  const [activeSelectedTab, setSelectedActiveTab] = useState(activeTab);
  const videoUpload = useRef<HTMLInputElement | null>(null);
  const logUpload = useRef<HTMLInputElement | null>(null);

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [disabledCreate, setDisabledCreate] = useState(false);

  const [errors, setErrors] = useState("");

  const dataProcessSpinner = useSelector((state: AppState) => {
    return state.homeReducerData.dataProcessSpinner;
  });
  const loginUserProfile = useSelector((state: AppState) => {
    return state.dashboardReducerData.loginUserProfile;
  });
  const errorDataProcess = useSelector((state: AppState) => {
    return state.homeReducerData.errorDataProcess;
  });

  const dispatch = useDispatch();
  const onChangeVideoUpload = (e: any) => {
    setErrors("");
    // console.log(e.target.files[0]);
    const file = e.target.files[0];
    file && setFile(file);
    file && setFileName(file?.name);
  };
  const handleFileChange = (e: any) => {
    setDisabledCreate(true);
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: function (results) {
          if (activeSelectedTab === "Video Data") {
            // setWebWorker(loginUserProfile?.userId)
            dispatch(createVideoProcessing(results.data));
            setErrors("");
          } else if (activeSelectedTab === "Logs Data") {
            dispatch(createLogProcessing(results.data));
            setErrors("");
          }
        },
      });
    } else {
      setErrors("File is required");
      setDisabledCreate(false);
    }
  };

  useEffect(() => {
    setFileName("");
    // dismiss();
  }, [dataProcessSpinner]);

  useEffect(() => {
    setFileName("");
    //   dismiss();
  }, [errorDataProcess]);

  useEffect(() => {
    setFileName("");
    setErrors("");
  }, [lastHeader?.props.headerText]);

  return (
    <Panel
      isOpen={isPanelOpen}
      onDismiss={dismiss}
      headerText="ActivSight Data Process"
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      styles={{
        footerInner: {
          padding: 0,
        },
      }}
      onRenderFooterContent={() => {
        return (
          <Stack
            horizontal
            horizontalAlign="start"
            tokens={{ childrenGap: 10 }}
            styles={{
              root: {
                borderTop: "1px solid " + ThemeColorPalette.panelBorder,
                textAlign: "right",
                paddingTop: "0.7rem",
                paddingBottom: "0.7rem",
                paddingLeft: "1.5rem",
              },
            }}
          >
            <PrimaryButton
              onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                handleFileChange(evt)
              }
              disabled={disabledCreate}
            >
              Initiate Process
              <Text className="spinnerRight">
                {disabledCreate && <Spinner size={SpinnerSize.small} />}
              </Text>
            </PrimaryButton>
            <DefaultButton onClick={dismiss}>Cancel</DefaultButton>
          </Stack>
        );
      }}
    >
      <Pivot
        aria-label="Basic Pivot Example"
        onLinkClick={(evt: any) => {
          setSelectedActiveTab(evt.props.headerText);
        }}
      >
        <PivotItem
          headerText="Video Data"
          headerButtonProps={{
            "data-order": 1,
            "data-title": "My Files Title",
          }}
        >
          <Stack.Item
            onClick={() => videoUpload.current && videoUpload.current.click()}
            className="ms-mt-5"
          >
            <input
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              ref={videoUpload}
              onChange={onChangeVideoUpload}
            />
            <TextField
              name="uploadVideoFile "
              label="Upload videos CSV file here"
              value={fileName}
              readOnly
              suffix="Choose File"
              className="pointer"
              errorMessage={errors}
            ></TextField>
          </Stack.Item>

          <DownloadExampleCSVFile dataProcess="videos" />
        </PivotItem>
        <PivotItem headerText="Logs Data">
          <Stack.Item
            onClick={() => logUpload.current && logUpload.current.click()}
            className="ms-mt-5"
          >
            <input
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              ref={logUpload}
              onChange={onChangeVideoUpload}
            />
            <TextField
              name="uploadLogsFile"
              label="Upload logs CSV file here"
              value={fileName}
              readOnly
              suffix="Choose File"
              className="pointer"
              errorMessage={errors}
            ></TextField>
          </Stack.Item>

          <DownloadExampleCSVFile dataProcess="logs" />
        </PivotItem>
      </Pivot>
      <Modal isOpen={dataProcessSpinner} containerClassName={"modalStyle"}>
        <SuccessMessage title="Initiated process" />
      </Modal>

      {(dataProcessSpinner || errorDataProcess) &&
        setTimeout(() => {
          dismiss();
          dispatch(showSpinner());
        }, 2000) && <></>}
    </Panel>
  );
};

export default ActivSightDataProcess;
