import {
  Stack,
  Text,
  TextField,
  defaultDatePickerStrings,
  DatePicker,
  addMonths,
  addYears,
  addDays,
  Label,
  IconButton,
  IIconProps,
} from "@fluentui/react";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useConst } from "@fluentui/react-hooks";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { AppState } from "../../../../../../rootReducer";
import { getDevices } from "../../../../actions/homeAction";
import { debounce } from "lodash";

const options: IDropdownOption[] = [
  {
    key: "fruitsHeader",
    text: "Fruits",
    itemType: DropdownMenuItemType.Header,
  },
  { key: "apple", text: "Apple" },
  { key: "banana", text: "Banana" },
  { key: "orange", text: "Orange" },
  { key: "grape", text: "Grape" },
  { key: "broccoli", text: "Broccoli" },
  { key: "carrot", text: "Carrot" },
  { key: "lettuce", text: "Lettuce" },
];

const optionsDevices = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
];
const AddDeviceItem = ({
  serialNumbers,
  getValues,
  watch,
  register,
  control,
  devices,
  allocationType,
  deviceIndex,
  errors,
  hospitalData,
  showCreateHospital,
  setValue,
  selectedDevicesList,
  setSelectedDevices,
}: {
  serialNumbers: any;
  getValues: any;
  watch: any;
  register: any;
  control: any;
  devices: any;
  allocationType: any;
  deviceIndex: any;
  errors: any;
  hospitalData: any;
  showCreateHospital: any;
  setValue: any;
  selectedDevicesList: any;
  setSelectedDevices: any;
}) => {
  const dispatch = useDispatch();
  const allocations = allocationType?.map((allocation: any) => ({
    value: allocation?.name,
    label: allocation?.name,
  }));
  const hospitalById = useSelector((state: AppState) => {
    return state.homeReducerData.hospitalById?.data;
  });
  const deleteIcon: IIconProps = { iconName: "Delete" };
  const [deviceIds, setDeviceID] = useState<number[]>([]);
  const [seriallNumbersSet, setSerialNumbersSet] =
    useState<{ value: number; label: string }[]>(serialNumbers);
  const [searchText, setsearchText] = useState("");

  // const hospitalData = useSelector((state: AppState) => {
  //   return state.homeReducerData.hospitalData?.data;
  // });

  const watchDevices = watch(`devices[${deviceIndex}].add_devices_count`);
  // console.log(watchDevices, deviceIndex, "watchDevices--");
  // debugger;
  function noOfDevices() {
    return [...Array(parseInt(watchDevices || 1))];
  }
  // useEffect(() => {
  //   dispatch(getDevices());
  // }, []);

  const handleRemove = (i: any) => {
    const values = getValues();
    values?.devices?.splice(i, 1);
    hospitalData?.hospitals[deviceIndex]?.devices?.splice(i, 1);
    // debugger;
  };

  useEffect(() => {
    if (serialNumbers) {
      if (deviceIds.length) {
        const selectedDeviceIdCollection: any = [...deviceIds];
        const unique = deviceIds.filter(
          (e: number, i: number, a: number[]) => a.indexOf(e) === i
        );
        const updatedSerialNumbers = serialNumbers.filter(
          (s: { value: number; label: string }) => !unique.includes(s.value)
        );
        setSerialNumbersSet(updatedSerialNumbers);
      } else {
        setSerialNumbersSet(serialNumbers);
      }
    }
  }, [serialNumbers]);

  const updateDeviceList = (value: number, isRemoved = false) => {
    const selectedDeviceIdCollection: any = [...deviceIds];
    if (!isRemoved) {
      selectedDeviceIdCollection.push(value);
    } else {
      selectedDeviceIdCollection.splice(
        selectedDeviceIdCollection.indexOf(value),
        1
      );
    }
    const unique = selectedDeviceIdCollection.filter(
      (e: number, i: number, a: number[]) => a.indexOf(e) === i
    );
    const updatedSerialNumbers = serialNumbers.filter(
      (s: any) => !unique.includes(s.value)
    );
    setTimeout(() => setSerialNumbersSet(updatedSerialNumbers), 100);
    setDeviceID(selectedDeviceIdCollection);
  };
  const deviceInputChangeHandler = useCallback(
    debounce((evt: any) => {
      // activityTypeInputHandler(evt);
      setsearchText(evt);
      dispatch(getDevices(evt));
    }, 1000),
    []
  );

  useEffect(() => {
    const check = hospitalData?.hospitals
      ? hospitalData?.hospitals[deviceIndex]?.devices?.map(
          (device: any, i: any) => {
            const fieldName = `devices[${deviceIndex}].facilities[${i}]`;
            setValue(`${fieldName}.serial_number`, device?.serial_number);
            setValue(`${fieldName}.device_id`, device?.device_id);
            setValue(
              `${fieldName}.device_allocation_id`,
              device?.device_allocation_id
                ? device?.device_allocation_id
                : device?.id
            ); //? device?.device_id : device?.id,
          }
        )
      : "";
  }, []);
  const filterOptions = (option: any, input: any) => {
    return selectedDevicesList.includes(option.value) ? false : true;
  };
  return (
    <Stack className="ms-Grid-row toggleHideBg addDeviceItem">
      <Stack.Item className="ms-Grid-row addDeviceHeading">
        <Stack.Item className=" ms-Grid-col ms-lg1">
          <Text>Devices</Text>
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
          <Text>
            Select Device*
            {/* {hospitalData?.hospitals[deviceIndex]?.devices
              ? typeof hospitalData?.hospitals[deviceIndex]?.devices[0]?.id
              : "sdsad"} */}
          </Text>
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
          <Text className="label">Device Location*</Text>
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
          <Text className="label">Allocation Type*</Text>
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
          <Text className="label">Allocation Period*</Text>
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg2-point-5"></Stack.Item>
      </Stack.Item>
      <Stack.Item className="ms-Grid-row addDeviceSection">
        <Stack.Item className=" ms-Grid-col ms-lg1">
          <Controller
            control={control}
            defaultValue={
              hospitalData?.hospitals &&
              hospitalData?.hospitals[deviceIndex]?.devices
                ? hospitalData?.hospitals[deviceIndex]?.devices?.length
                : 1
            }
            name={`devices[${deviceIndex}].add_devices_count`}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                // isDisabled
                defaultValue={optionsDevices[0]}
                classNamePrefix="addl-class"
                options={optionsDevices}
                value={optionsDevices.find((c: any) => c.value === value)}
                onChange={(val) => {
                  onChange(val?.value);
                }}
              />
            )}
          />
        </Stack.Item>
        {noOfDevices().map((n: any, i: any) => {
          const fieldName = `devices[${deviceIndex}].facilities[${i}]`;
          const watchAllocationStart = watch(`${fieldName}.allocation_start`);
          const watchAllocationEnd = watch(`${fieldName}.allocation_end`);
          const totalDevices = noOfDevices()?.length;
          const formValues = getValues();
          const facilities = formValues?.devices
            ? formValues?.devices[deviceIndex]?.facilities
            : "";
          facilities &&
            facilities?.map((facility: any, index: any) => {
              if (totalDevices - 1 < index) {
                facilities?.splice(index, 1);
              }
            });
          const indexDev = deviceIndex ? deviceIndex : 0;
          // hospitalById?.devices?.map((device: any, did: any) => {
          //   Object.assign(hospitalData?.hospitals[0]?.devices[did], { id: device?.device_id });
          // })

          const hospitalCheck =
            hospitalData?.hospitals &&
            hospitalData?.hospitals[deviceIndex]?.devices;
          // console.log(
          //   hospitalCheck &&
          //     hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id
          //     ? hospitalData?.length > 0
          //       ? hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id
          //       : 0
          //     : hospitalData?.length > 0
          //     ? hospitalData?.hospitals[deviceIndex]?.devices[i]?.id
          //     : hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id,
          //   "fieldNamefieldNamefieldName",
          //   hospitalData
          // );

          const deviceIdCheck =
            hospitalCheck &&
            hospitalData?.hospitals[deviceIndex]?.devices[i]?.serial_number
              ? hospitalCheck &&
                (isNaN(
                  +hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id
                )
                  ? hospitalData?.hospitals[deviceIndex]?.devices[i]?.id
                  : hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id)
              : hospitalData?.hospitals &&
                hospitalData?.hospitals[deviceIndex]?.devices &&
                hospitalData?.hospitals[deviceIndex]?.devices[i] &&
                +hospitalData?.hospitals[deviceIndex]?.devices[i]?.id;
          // const deviceId =
          //   hospitalData?.length > 0
          //     ? hospitalData?.hospitals[deviceIndex]?.devices &&
          //       hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id
          //       ? hospitalData?.length > 0
          //         ? +hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id
          //         : 0
          //       : hospitalData?.length > 0
          //       ? +hospitalData?.hospitals[deviceIndex]?.devices[i]?.id
          //       : +hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id
          //     : !showCreateHospital
          //     ? hospitalData?.hospitals &&
          //       hospitalData?.hospitals[deviceIndex]?.devices &&
          //       +hospitalData?.hospitals[deviceIndex]?.devices[i]?.id
          //     : hospitalData?.hospitals &&
          //       hospitalData?.hospitals[deviceIndex]?.devices &&
          //       +hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id;
          // console.log(
          //   hospitalCheck &&
          //     hospitalData?.hospitals[deviceIndex]?.devices[i]?.serial_number,
          //   hospitalData?.length > 0,
          //   hospitalData,
          //   deviceIndex,
          //   showCreateHospital,
          //   "deviceId=====",
          //   // deviceId,
          //   "---",
          //   deviceIdCheck
          // );

          // console.log(deviceId,hospitalData?.hospitals &&
          //   hospitalData?.hospitals[deviceIndex]?.devices &&
          //   hospitalData?.hospitals[deviceIndex]?.devices[i]?.device_id,"--------");

          // console.log(hospitalData, "device--", deviceId, serialNumbers);
          // useEffect(() => {
          //   // setValue(`${fieldName}.serial_number`, );

          // }, []);
          return (
            <>
              {seriallNumbersSet && (
                <Stack.Item key={i} className=" ms-Grid-row">
                  <Stack.Item
                    key={deviceIdCheck}
                    className={`ms-Grid-col ms-lg2-point-5 ${
                      i >= 1 ? "ms-lgOffset1 ml-9" : ""
                    }`}
                  >
                    {/* {console.log(deviceId,'deviceId',serialNumbers)} */}
                    <Controller
                      control={control}
                      defaultValue={deviceIdCheck}
                      name={`${fieldName}.id`}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          classNamePrefix="addl-class"
                          options={
                            !searchText
                              ? seriallNumbersSet
                              : seriallNumbersSet.filter(
                                  (item: any) =>
                                    (item?.label)
                                      .toLowerCase()
                                      .indexOf(searchText.toLowerCase()) !== -1
                                )
                          }
                          isClearable
                          value={seriallNumbersSet?.find(
                            (c: any) => c?.value === value
                          )}
                          onChange={(val, actionMeta) => {
                            onChange(val?.value);
                            const isRemoved = actionMeta.action === "clear";
                            let updatedVal: any = val?.value;
                            const selectedDevices: any = [
                              ...selectedDevicesList,
                            ];
                            if (isRemoved) {
                              updatedVal = actionMeta.removedValues[0].value;
                              const ind = selectedDevices.findIndex(
                                (x: number) => x == updatedVal
                              );
                              if (ind > -1) {
                                selectedDevices.splice(ind, 1);
                              }
                            } else {
                              if (value) {
                                const ind = selectedDevices.findIndex(
                                  (x: number) => x == value
                                );
                                if (ind > -1) {
                                  selectedDevices[ind] = updatedVal;
                                }
                              } else {
                                selectedDevices.push(val?.value);
                              }
                            }
                            //    setSelectedDevices(selectedDevices);
                            //    updateDeviceList(updatedVal, isRemoved);
                          }}
                          onInputChange={(e: any) => {
                            const searchInput = e;
                            deviceInputChangeHandler(searchInput);
                          }}
                          filterOption={filterOptions}
                          onBlur={() => deviceInputChangeHandler("")}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {errors?.devices
                        ? errors?.devices[deviceIndex]?.facilities[i]?.id
                            ?.message
                        : ""}
                    </Label>
                    <Label className="errorMessage">
                      {errors?.devices
                        ? errors?.devices[deviceIndex]?.facilities[i]?.message
                        : ""}
                    </Label>
                  </Stack.Item>
                  {/* <Stack.Item
                    className=" ms-Grid-col ms-lg2-point-5"
                    key={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[deviceIndex]?.devices &&
                      hospitalData?.hospitals[deviceIndex]?.devices[i]?.room
                    }
                  >
                    <TextField
                      autoComplete="off"
                      defaultValue={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[deviceIndex]?.devices
                          ? hospitalData?.hospitals[deviceIndex]?.devices[i]
                              ?.room
                          : ""
                      }
                      {...register(`${fieldName}.room`)}
                      errorMessage={
                        errors?.devices
                          ? errors?.devices[deviceIndex]?.facilities[i]?.room
                              ?.message
                          : ""
                      }
                    />

                  </Stack.Item> */}
                  <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
                    <Controller
                      control={control}
                      defaultValue={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[deviceIndex]?.devices &&
                        hospitalData?.hospitals[deviceIndex]?.devices.length
                          ? hospitalData?.hospitals[deviceIndex]?.devices[i]
                              ?.room
                          : ""
                      }
                      name={`${fieldName}.room`}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <TextField
                          autoComplete="off"
                          defaultValue={
                            hospitalData?.hospitals &&
                            hospitalData?.hospitals[deviceIndex]?.devices &&
                            hospitalData?.hospitals[deviceIndex]?.devices.length
                              ? hospitalData?.hospitals[deviceIndex]?.devices[i]
                                  ?.room
                              : ""
                          }
                          value={value}
                          onChange={(e: any) => onChange(e.target.value)}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {errors?.devices
                        ? errors?.devices[deviceIndex]?.facilities[i]?.room
                            ?.message
                        : ""}
                    </Label>
                    <input
                      type="hidden"
                      {...register(`${fieldName}.room`)}
                      value="true"
                    />
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
                    <Controller
                      control={control}
                      defaultValue={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[deviceIndex]?.devices
                          ? hospitalData?.hospitals[deviceIndex]?.devices[i]
                              ?.allocation_type
                          : ""
                      }
                      name={`${fieldName}.allocation_type`}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          classNamePrefix="addl-class"
                          options={allocations}
                          isClearable
                          value={allocations?.find(
                            (c: any) => c?.value === value
                          )}
                          onChange={(val) => {
                            onChange(val?.value);
                          }}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {errors?.devices
                        ? errors?.devices[deviceIndex]?.facilities[i]
                            ?.allocation_type?.message
                        : ""}
                    </Label>
                    <input
                      type="hidden"
                      {...register(`${fieldName}.current_allocation`)}
                      value="true"
                    />
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
                    <Controller
                      control={control}
                      name={`${fieldName}.allocation_start`}
                      defaultValue={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[deviceIndex]?.devices &&
                        hospitalData?.hospitals[deviceIndex]?.devices[i]
                          ?.allocation_start
                          ? new Date(
                              hospitalData?.hospitals[deviceIndex]?.devices[
                                i
                              ]?.allocation_start
                            )
                          : ""
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          onBlur={onBlur}
                          value={value}
                          onSelectDate={(date: any) => onChange(date)}
                          maxDate={watchAllocationEnd}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {errors?.devices
                        ? errors?.devices[deviceIndex]?.facilities[i]
                            ?.allocation_start?.message
                        : ""}
                    </Label>
                  </Stack.Item>
                  <Stack.Item
                    className=" ms-Grid-col ms-lg2-point-5"
                    key={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[deviceIndex]?.devices &&
                      hospitalData?.hospitals[deviceIndex]?.devices[i]
                        ?.allocation_end
                    }
                  >
                    <Controller
                      control={control}
                      shouldUnregister
                      name={`${fieldName}.allocation_end`}
                      defaultValue={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[deviceIndex]?.devices &&
                        hospitalData?.hospitals[deviceIndex]?.devices[i]
                          ?.allocation_end
                          ? new Date(
                              hospitalData?.hospitals[deviceIndex]?.devices[
                                i
                              ]?.allocation_end
                            )
                          : ""
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          onBlur={onBlur}
                          value={value}
                          onSelectDate={(date: any) => onChange(date)}
                          minDate={watchAllocationStart}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {errors?.devices
                        ? errors?.devices[deviceIndex]?.facilities[i]
                            ?.allocation_end?.message
                        : ""}
                    </Label>
                  </Stack.Item>
                  {/* <Stack.Item className="ms-Grid-col width-20 deleteSection">
                    <IconButton
                      iconProps={deleteIcon}
                      aria-label="Delete"
                      onClick={() => handleRemove(i)}
                    />
                  </Stack.Item> */}
                  {/* <Label className="errorMessage">
                    {errors?.devices
                      ? errors?.devices[deviceIndex]?.facilities[i]?.message
                      : ""}
                  </Label> */}
                </Stack.Item>
              )}
            </>
          );
        })}
      </Stack.Item>
    </Stack>
  );
};

export default AddDeviceItem;
