import React, { useState, useEffect } from "react";
import { Stack, StackItem, Text, IStackItemStyles } from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
// import { geoCentroid } from "d3-geo";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Label,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import "./Logs.scss";
import no_data from "../../../assets/no_data.svg";
import { useSelector, useDispatch } from "react-redux";
import { getSalesLogs } from "../actions/logsActions";
import moment from "moment";
import { AppState } from "../../../rootReducer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getHospitalId } from "../../Activity/actions/activityAction";
import MapChart from "../../../ActiveAdminModule/Logs/components/Common/MapChart";
import ReactTooltip from "react-tooltip";
import LoaderWithMessage from "../../../common/customLoader/LoaderWithMessage";
import * as constants from "../../../Layout/constants/constants";

const data = [
  { name: "ICG", value: 400 },
  { name: "Perfusion", value: 300 },
  { name: "Workflow Interruptions", value: 300 },
  { name: "Setup Time", value: 200 },
];
const data3 = [
  { name: "ICG", value: 80 },
  { name: "Perfusion", value: 20 },
];
const data2 = [
  {
    name: "Page A",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Page B",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Page C",
    uv: 1397,
    pv: 1098,
    amt: 989,
  },
  {
    name: "Page D",
    uv: 1480,
    pv: 1200,
    amt: 1228,
  },
];
const roomHours = [
  {
    name: "R1",
    pv: 20,
    amt: 1400,
  },
  {
    name: "R2",
    pv: 50,
    amt: 1506,
  },
  {
    name: "R3",
    pv: 40,
    amt: 989,
  },
  {
    name: "R4",
    pv: 60,
    amt: 1228,
  },
];

const specialties = [
  {
    name: "Sp1",
    pv: 20,
    amt: 1400,
  },
  {
    name: "Sp2",
    pv: 50,
    amt: 1506,
  },
  {
    name: "Sp3",
    pv: 40,
    amt: 989,
  },
  {
    name: "Sp4",
    pv: 60,
    amt: 1228,
  },
];

const options1 = [
  { value: "1", label: "Northeast" },
  { value: "2", label: "Ohio Valley" },
  { value: "3", label: "Southeast" },
  { value: "4", label: "South Central" },
  { value: "5", label: "Upper Midwest" },
  { value: "6", label: "West" },
  { value: "7", label: "Mountain" },
  { value: "8", label: "Pacific Northwest" },
];

const options2 = [
  { value: "1", label: "01" },
  { value: "2", label: "02" },
  { value: "3", label: "03" },
];

const markers = [
  {
    markerOffset: -30,
    name: "",
    coordinates: [-100.0, 31.0],
  },
  {
    markerOffset: 15,
    name: "",
    coordinates: [-100.0, 31.0],
  },
];

// const optionsFacility = [
//   { value: "Apollo-South", label: "Apollo-South" },
//   { value: "Apollo-South Branch 2", label: "Apollo-South Branch 2" },
// ];

// const optionsRoom = [
//   { value: "room1", label: "room1" },
//   { value: "room1 Branch 2", label: "room1 Branch 2" },
// ];

// const optionsSpecialty = [
//   { value: "Apollo", label: "Apollo" },
//   { value: "Apollo Branch 2", label: "Apollo Branch 2" },
// ];

const Logs = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
  const oneMonthBack = moment().subtract(1, "months").toDate();
  const currentDate = moment().toDate();
  const [startDate, setStartDate] = useState(oneMonthBack);
  const [endDate, setEndDate] = useState(currentDate);
  const [facilityFilter, setFacilityFilter] = useState<any | null>(null);
  const [content, setContent] = useState("");
  const [mapPointerSearch, setMapPointerSearch] = useState<Array<string>>([]);
  const [regionFilterName, setRegionFilterName] = useState<any | null>(null);
  const [facilityFilterName, setFacilityFilterName] = useState<any | null>(
    null
  );
  const [specialtyFilterName, setSpecialtyFilterName] = useState<any | null>(
    null
  );
  const [roomFilterName, setRoomFilterName] = useState<any | null>(null);
  const [specialtyList, setSpecialtyList] = useState<any>([]);
  const [logsPermission, setLogsPermission] = React.useState<any>(false);
  const hospitalFilter = "Apollo";
  const dispatch = useDispatch();

  const startDateFormat = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : "";
  const endDateFormat = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
  // const onChangeDateRangePicker = (dates: any) => {
  //   const [start, end] = dates;

  //   if (startDate && endDate) {
  //     dispatch(getSalesLogs(startDateFormat, endDateFormat));
  //   }
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  const logs = useSelector((state: AppState) => {
    return state.logsReducerData?.salesLogs?.data;
  });
  const allLogs = useSelector((state: AppState) => {
    return state.logsReducerData?.initialSalesLogs;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalLogsReducerData?.loaderWithMessage;
  });

  const uniqueRegionFilter = allLogs?.locations && [
    ...allLogs?.locations
      .reduce((map: any, obj: any) => map.set(obj.region, obj), new Map())
      .values(),
  ];
  const uniqueFacilityFilter = allLogs?.locations && [
    ...allLogs?.locations
      .reduce((map: any, obj: any) => map.set(obj.facility, obj), new Map())
      .values(),
  ];

  // const uniqueRoomFilter = allLogs?.locations && [
  //   ...allLogs?.locations
  //     .reduce((map: any, obj: any) => map.set(obj.room, obj), new Map())
  //     .values(),
  // ];

  const deviceCount = logs?.locations?.length;
  const uniqueFacilityFilter1 = uniqueFacilityFilter
    ?.map((logFacility: any) => {
      if (regionFilterName) {
        if (logFacility.region === regionFilterName) {
          return {
            label: logFacility.facility,
            value: logFacility.facilityID,
          };
        }
      } else {
        return {
          label: logFacility.facility,
          value: logFacility.facilityID,
        };
      }
    })
    .filter((facility: any) => facility?.value && facility?.value !== " ");

  // const optionsFacility = uniqueFacilityFilter1?.map((logFacilitie: any) => ({
  //   label: logFacilitie.facility,
  //   value: logFacilitie.facilityID,
  // }));
  const optionsFacility = uniqueFacilityFilter1 && [
    ...uniqueFacilityFilter1
      .reduce((map: any, obj: any) => map.set(obj.value, obj), new Map())
      .values(),
  ];

  const uniqueRoomFilter1 = allLogs?.locations
    ?.map((logRoom: any) => {
      if (facilityFilterName) {
        if (logRoom.facilityID === facilityFilterName) {
          return {
            label: logRoom.room,
            value: logRoom.room,
          };
        }
      } else {
        return {
          label: logRoom.room,
          value: logRoom.room,
        };
      }
    })
    .filter((room: any) => room?.value && room?.value !== " ");

  // const optionsRoom = uniqueRoomFilter?.reverse().map((logRoom: any) => ({
  //   label: logRoom.room,
  //   value: logRoom.room,
  // }));
  const optionsRoom = uniqueRoomFilter1 && [
    ...uniqueRoomFilter1
      .reduce((map: any, obj: any) => map.set(obj.value, obj), new Map())
      .values(),
  ];

  const optionsRegion = uniqueRegionFilter?.map((logRegion: any) => ({
    label: logRegion.region,
    value: logRegion.region,
  }));

  const optionsSpecialty = specialtyFilterName
    ? specialtyList
    : logs?.speciality?.map((spacilitie: any) => ({
        label: spacilitie,
        value: spacilitie,
      }));

  const hospitalName = useSelector((state: AppState) => {
    return state.hospitalActivityReducerData?.hospitalUniqueIdentifier;
  });

  const rooms = logs?.widgets?.rooms?.map((room: any) => ({
    name: room.name,
    value: room.value,
  }));
  const deviceClickHandler = (
    hospital = "",
    facility = "",
    room = "",
    region = "",
    country = "",
    speciality = "",
    startDate = "",
    endDate = ""
  ) => {
    setMapPointerSearch([
      hospital,
      facility,
      room,
      region,
      country,
      speciality,
      startDate,
      endDate,
    ]);
  };
  useEffect(() => {
    const permissionLogs = constants.getPermissionsbyItem("logs.analytics");
    setLogsPermission(permissionLogs);
    dispatch(getHospitalId());
  }, []);

  useEffect(() => {
    if (hospitalName) {
      dispatch(getSalesLogs("", "", hospitalName, "", "", "", "", true));
    }
  }, [hospitalName]);

  useEffect(() => {
    // dispatch(getHospitalId());
    if (hospitalName) {
      dispatch(
        getSalesLogs(
          startDateFormat,
          endDateFormat,
          hospitalName,
          facilityFilterName || "",
          roomFilterName || "",
          regionFilterName || ""
        )
      );
    }
  }, [startDateFormat, endDateFormat, hospitalName]);

  const selectedSpecialty = specialtyFilterName ? specialtyFilterName : "";

  const selectedRegion = regionFilterName
    ? regionFilterName
    : mapPointerSearch?.length > 0
    ? mapPointerSearch[3]
    : "";

  const selectedFacility = facilityFilterName
    ? facilityFilterName
    : mapPointerSearch?.length > 0
    ? mapPointerSearch[1]
    : "";
  const selectedRoom = roomFilterName
    ? roomFilterName
    : mapPointerSearch?.length > 0
    ? mapPointerSearch[2]
    : "";
  const getFacityName = (list: any) => {
    const value = list?.find((c: any) => c.value === facilityFilterName);
    return value || null;
  };
  const getSpecialtyName = (list: any) => {
    const value = list?.find((c: any) => c.value === specialtyFilterName);
    return value || null;
  };

  return (
    <Stack className="salesDashboard">
      <Stack.Item className="totalSection">
        <Text>Total Devices {deviceCount || 0}</Text>
        <Text>Total Cases {logs?.widgets?.proceduresCount || 0}</Text>
      </Stack.Item>
      {/* ---------------filter section------------------- */}
      <Stack.Item className="ms-Grid" dir="ltr">
        <Stack.Item className="filterSection ms-Grid-row">
          <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
            <Stack.Item className="label">
              <Text>Region</Text>
            </Stack.Item>
            {mapPointerSearch[3] && mapPointerSearch[3] !== "" ? (
              <>
                <Controller
                  control={control}
                  defaultValue={mapPointerSearch[3]}
                  name="region"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      classNamePrefix="addl-class"
                      options={optionsRegion}
                      value={optionsRegion?.find(
                        (c: any) => c.value === regionFilterName
                      )}
                      isClearable
                      onChange={(val) => {
                        setRegionFilterName(val?.value);
                        setFacilityFilter(null);
                        setFacilityFilterName(null);
                        setRoomFilterName(null);
                        setSpecialtyFilterName(null);
                        setMapPointerSearch([
                          hospitalName,
                          "",
                          "",
                          val?.value,
                          "",
                          "",
                          startDateFormat,
                          endDateFormat,
                        ]);
                        dispatch(
                          getSalesLogs(
                            startDateFormat,
                            endDateFormat,
                            hospitalName,
                            "",
                            "",
                            val?.value,
                            ""
                          )
                        );
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <Controller
                control={control}
                defaultValue=""
                name="region1"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    classNamePrefix="addl-class"
                    options={optionsRegion}
                    isDisabled={!logsPermission}
                    isClearable
                    value={optionsRegion?.find(
                      (c: any) => c.value === regionFilterName
                    )}
                    onChange={(val) => {
                      setRegionFilterName(val?.value);
                      setFacilityFilter(null);
                      setFacilityFilterName(null);
                      setRoomFilterName(null);
                      setSpecialtyFilterName(null);
                      if (val?.value) {
                        dispatch(
                          getSalesLogs(
                            startDateFormat,
                            endDateFormat,
                            hospitalName,
                            "",
                            "",
                            val?.value,
                            ""
                          )
                        );
                      } else {
                        dispatch(
                          getSalesLogs(
                            startDateFormat,
                            endDateFormat,
                            hospitalName,
                            "",
                            "",
                            val?.value,
                            ""
                          )
                        );
                      }
                    }}
                  />
                )}
              />
            )}
          </Stack.Item>

          <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
            <Stack.Item className="label">
              <Text>Facility</Text>
            </Stack.Item>
            {mapPointerSearch?.length > 0 ? (
              <>
                <Controller
                  control={control}
                  defaultValue={mapPointerSearch[1]}
                  name="facility"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      classNamePrefix="addl-class"
                      options={optionsFacility}
                      isDisabled={!logsPermission}
                      isClearable
                      value={getFacityName(optionsFacility)}
                      onChange={(val) => {
                        onChange(val?.value);
                        setFacilityFilterName(val?.value);
                        setRoomFilterName(null);
                        setSpecialtyFilterName(null);
                        setMapPointerSearch([
                          hospitalName,
                          val?.value,
                          "",
                          selectedRegion,
                          "",
                          "",
                          startDateFormat,
                          endDateFormat,
                        ]);
                        // dispatch(
                        //   getSalesLogs(
                        //     startDateFormat,
                        //     endDateFormat,
                        //     hospitalName,
                        //     val?.value,
                        //     val?.value ? selectedRoom : "",
                        //     selectedRegion,
                        //     selectedSpecialty
                        //   )
                        // );
                      }}
                    />
                  )}
                />
              </>
            ) : (
              <Controller
                control={control}
                defaultValue={facilityFilterName}
                name="facility"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    classNamePrefix="addl-class"
                    options={optionsFacility}
                    isClearable
                    isDisabled={!logsPermission}
                    value={getFacityName(optionsFacility)}
                    onChange={(val) => {
                      onChange(val?.value);
                      setFacilityFilterName(val?.value);
                      setRoomFilterName(null);
                      setSpecialtyFilterName(null);
                      dispatch(
                        getSalesLogs(
                          startDateFormat,
                          endDateFormat,
                          hospitalName,
                          val?.value,
                          "",
                          selectedRegion,
                          ""
                        )
                      );
                    }}
                  />
                )}
              />
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
            <Stack.Item className="label">
              <Text>Room</Text>
            </Stack.Item>
            {mapPointerSearch[2] && mapPointerSearch[2] !== "" ? (
              <>
                <Controller
                  control={control}
                  //defaultValue={mapPointerSearch[2]}
                  defaultValue={selectedRoom}
                  name="room"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      classNamePrefix="addl-class"
                      options={optionsRoom}
                      isClearable
                      value={optionsRoom?.find(
                        (c: any) => c.value === selectedRoom
                      )}
                      onChange={(val) => {
                        onChange(val?.value);
                        setRoomFilterName(val?.value);
                        setSpecialtyFilterName(null);
                        dispatch(
                          getSalesLogs(
                            startDateFormat,
                            endDateFormat,
                            hospitalName,
                            selectedFacility,
                            val?.value,
                            selectedRegion,
                            ""
                          )
                        );
                      }}
                      isDisabled={
                        !logsPermission || selectedFacility ? false : true
                      }
                    />
                  )}
                />
              </>
            ) : (
              <>
                {selectedRoom ? (
                  <Controller
                    control={control}
                    defaultValue={selectedRoom}
                    name="room1"
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        classNamePrefix="addl-class"
                        options={optionsRoom}
                        value={optionsRoom?.find(
                          (c: any) => c.value === selectedRoom
                        )}
                        isClearable
                        onChange={(val) => {
                          onChange(val?.value);
                          setRoomFilterName(val?.value);
                          setSpecialtyFilterName(null);
                          dispatch(
                            getSalesLogs(
                              startDateFormat,
                              endDateFormat,
                              hospitalName,
                              selectedFacility,
                              val?.value,
                              selectedRegion,
                              ""
                            )
                          );
                        }}
                        isDisabled={selectedFacility ? false : true}
                      />
                    )}
                  />
                ) : (
                  <Select
                    classNamePrefix="addl-class"
                    options={optionsRoom}
                    defaultValue={selectedRoom}
                    isClearable
                    onChange={(val: any) => {
                      setRoomFilterName(val?.value);
                      setSpecialtyFilterName(null);
                      dispatch(
                        getSalesLogs(
                          startDateFormat,
                          endDateFormat,
                          hospitalName,
                          selectedFacility,
                          val?.value,
                          selectedRegion,
                          ""
                        )
                      );
                    }}
                    isDisabled={selectedFacility ? false : true}
                  />
                )}
              </>
            )}
          </Stack.Item>

          <Stack.Item className=" ms-Grid-col ms-lg1-point-5 ms-lgOffset1">
            <Stack.Item className="label">
              <Text>Start Date</Text>
            </Stack.Item>
            <DatePicker
              selected={startDate}
              disabled={!logsPermission}
              onChange={(date: any) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate ? endDate : currentDate}
              isClearable={startDate ? true : false}
              // onChange={onChangeDateRangePicker}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
            <Stack.Item className="label">
              <Text>End Date</Text>
            </Stack.Item>
            <DatePicker
              selected={endDate}
              disabled={!logsPermission}
              onChange={(date: any) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={currentDate}
              isClearable={endDate ? true : false}
              // onChange={onChangeDateRangePicker}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
            <Stack.Item className="label">
              <Text>Specialty</Text>
            </Stack.Item>
            <Controller
              control={control}
              defaultValue={specialtyFilterName}
              name="specialty"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  options={optionsSpecialty}
                  isDisabled={!logsPermission}
                  value={getSpecialtyName(optionsSpecialty)}
                  onChange={(val) => {
                    onChange(val?.value);
                    setSpecialtyFilterName(val?.value);
                    setSpecialtyList(optionsSpecialty);
                    dispatch(
                      getSalesLogs(
                        startDateFormat,
                        endDateFormat,
                        hospitalName,
                        selectedFacility,
                        selectedRoom,
                        selectedRegion,
                        val?.value
                      )
                    );
                  }}
                />
              )}
            />
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
      {logsPermission ? (
        // getSalesLogs?.length === 0 ? (
        logs?.speciality?.length === 0 &&
        logs?.widgets?.proceduresCount <= 0 ? (
          <div className="no-data-img">
            <img style={{ height: "320px", width: "280px" }} src={no_data} />
            <Text className="no-data-img">
              “Data is not available for the selected duration”
            </Text>
          </div>
        ) : (
          <>
            <Stack.Item className="ms-Grid" dir="ltr">
              <Stack.Item className="chartSection ms-Grid-row">
                {/* ---------------map section------------------- */}
                <Stack.Item className=" ms-Grid-col ms-lg6"></Stack.Item>
                <Stack.Item className=" ms-Grid-row">
                  {/* ---------------Procedure and Overlay section------------------- */}

                  <Stack.Item className=" ms-Grid-row">
                    {rooms?.length > 0 ? (
                      <>
                        <Stack.Item className="ms-Grid-col ms-lg6 btmHeight bgColor">
                          <Text className="heading">
                            Average Usage Across Rooms (Hours)
                          </Text>
                          <Stack.Item className="inheritHeight">
                            <ResponsiveContainer
                              width="100%"
                              height="100%"
                              className="placement"
                            >
                              <BarChart
                                width={500}
                                height={200}
                                data={rooms}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                                barSize={20}
                              >
                                <XAxis
                                  dataKey="name"
                                  // scale="point"
                                  padding={{ left: 10, right: 10 }}
                                />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" fill="#FFDB8599" />
                              </BarChart>
                            </ResponsiveContainer>
                          </Stack.Item>
                        </Stack.Item>
                      </>
                    ) : (
                      <Text> </Text>
                    )}
                    <Stack.Item className=" ms-Grid-col ms-lg6">
                      {logs?.widgets?.specialities ? (
                        <>
                          <Stack.Item className="btmHeight bgColor">
                            <Text className="heading">
                              Average Usage Across Specialties (Hours)
                            </Text>
                            <Stack.Item className="inheritHeight">
                              <ResponsiveContainer
                                width="100%"
                                height="100%"
                                className="placement"
                              >
                                <BarChart
                                  width={500}
                                  height={300}
                                  data={logs?.widgets?.specialities}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                  barSize={20}
                                >
                                  <XAxis
                                    dataKey="name"
                                    interval={0}
                                    angle={7}
                                    // scale="point"
                                    padding={{ left: 20, right: 20 }}
                                  />
                                  <YAxis />
                                  <Tooltip />
                                  <Bar dataKey="value" fill="#C9F0E299" />
                                </BarChart>
                              </ResponsiveContainer>
                            </Stack.Item>
                          </Stack.Item>
                        </>
                      ) : (
                        <Text> </Text>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                  {/* ---------------Workflow  section------------------- */}
                  <Stack.Item
                    className="ms-Grid-row"
                    style={{ marginTop: "16px" }}
                  >
                    <Stack.Item className=" ms-Grid-col ms-lg6">
                      {logs?.widgets?.proceduresCount ? (
                        <Stack.Item className="workflow bgColor">
                          <Text className="heading">
                            Procedures per Time Period
                          </Text>
                          <Stack
                            horizontal
                            horizontalAlign="start"
                            verticalAlign="center"
                            className="inheritHeight"
                          >
                            <Stack.Item>
                              <Text className="number" block variant="large">
                                {logs?.widgets?.proceduresCount}
                              </Text>
                            </Stack.Item>
                          </Stack>
                        </Stack.Item>
                      ) : (
                        <Text> </Text>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </>
        )
      ) : (
        ""
      )}
    </Stack>
  );
};
export default Logs;
