import {
  Stack,
  Text,
  PrimaryButton,
  Icon,
  ActionButton,
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../rootReducer";
import { showSpinner } from "../../Home/actions/homeAction";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
const Wizard = ({
  children,
  counts,
  form,
  names,
  back,
  // spinner,
  disabledCreate,
  isCreate,
  showModal,
}: any) => {
  const [activePageIndex, setActivePageIndex] = useState(0);

  const dispatch = useDispatch();

  const pages = React.Children.toArray(children);

  const currentPage = pages[counts];

  const goPrevPage = () => {
    dispatch(back());
    setActivePageIndex(counts);
  };
  // console.log(spinner, "spinnerspinnerspinnerspinner");

  const ButtonPrev = () =>
    counts > 0 ? (
      <ActionButton
        type="button"
        onClick={goPrevPage}
        allowDisabledFocus
        className="btn-c"
      >
        Back
      </ActionButton>
    ) : null;
  const ButtonNext = () =>
    counts < pages.length - 1 ? (
      <PrimaryButton type="submit" form={form} className="btn-next">
        Next
      </PrimaryButton>
    ) : (
      <PrimaryButton
        type="submit"
        form={form}
        className="btn-next"
        disabled={disabledCreate}
        // onClick={handleDevice}
      >
        {isCreate ? "Create" : "Update"}
        <Text className="spinnerRight">
          {disabledCreate && <Spinner size={SpinnerSize.small} />}
        </Text>
      </PrimaryButton>
    );

  useEffect(() => {}, []);
  return (
    <Stack>
      <Stack className="createHospitalMain">
        <Stack.Item className="createHospitalWizard">
          <Stack horizontal horizontalAlign="start">
            <Stack.Item
              align="center"
              className={`stepBar ${counts == 0 ? "activeSidebar" : ""}`}
            >
              {/* {hospitalData?.hospitals.length > 1 ? */}
              <Text
                className="circleNumber fontHelveticaNeueMedium"
                variant="small"
              >
                1
              </Text>
              {/* : (
                                    <Text className=" fontHelveticaNeueMedium completed" variant="small">
                                        <Icon iconName="Completed" className="ms-fontSize-20 iconCompleted" />
                                    </Text>
                                )} */}
              <Text className="fontHelveticaNeueMedium">{names[0]}</Text>
            </Stack.Item>
            <Stack.Item
              align="center"
              className={`stepBar stepBar1 ${
                counts == 1 ? "activeSidebar" : ""
              }`}
            >
              {/* {hospitalData?.hospitals ? */}
              <Text className="circleNumber" variant="small">
                2
              </Text>
              {/* : (
                                    <Text className=" fontHelveticaNeueMedium completed" variant="small">
                                        <Icon iconName="Completed" className=" ms-fontSize-20 iconCompleted " />
                                    </Text>
                                )} */}
              <Text className="fontHelveticaNeueMedium">{names[1]}</Text>
            </Stack.Item>
            <Stack.Item align="center" className="stepBar stepBar1">
              {" "}
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {/* first step and second step ternary operation */}
        {/* {this.state.stepNavigation ? ( */}
        <Stack className="wizardHeight">{currentPage}</Stack>
      </Stack>
      <Stack
        className="createHospitalWizardFooter"
        horizontal
        horizontalAlign="space-between"
      >
        <Stack className="btn-back">
          <ButtonPrev />
        </Stack>
        <Stack.Item className="nextCancel">
          <ButtonNext />
          <ActionButton
            text="Cancel"
            className="btn-c"
            allowDisabledFocus
            onClick={showModal}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default Wizard;
