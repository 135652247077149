import React, { FormEvent, useCallback, useEffect, useState } from "react";
import {
  Stack,
  StackItem,
  Text,
  Modal,
  IconButton,
  IIconProps,
  TextField,
  Label,
  PrimaryButton,
  ActionButton,
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { ReactComponent as AddFacilityIcon } from "../../../../../assets/Home/add_facility.svg";
import {
  createAlgorithm,
  createWorkflowMapping,
  createWorkflowProcedureMapping,
  getProcedureDetailsById,
  getProcedureList,
  getWorkflowInputTypeList,
  getWorkflowList,
  getWorkflowTypeList,
} from "../../../actions/managamentActions";
import ToggleCard from "../../../../Home/components/Wizard/ToggleCard";
import "../Workflow.scss";
import { AppState } from "../../../../../rootReducer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import LoaderWithMessage from "../../../../../common/customLoader/LoaderWithMessage";
import Loader from "../../../../Logs/components/Common/Loader";
import * as allActions from "../../../actions/actionConstants";

const deleteIcon: IIconProps = { iconName: "Delete" };

const cancelIcon: IIconProps = { iconName: "Cancel" };
const DropdownControlledMultiExampleOptions = [
  { key: "1", text: "ActivSight Manual Upload" },
  { key: "2", text: "ActivSight Device Upload" },
  { key: "3", text: "Non-ActivSingle file" },
  { key: "4", text: "Non-ActivSingle file" },
];
const options1 = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
const WorkflowMapping = ({
  isModalOpen,
  showModal,
}: {
  isModalOpen: any;
  showModal: any;
}) => {
  const formAdminSchema = {
    workflow_type: Yup.string().required("Workflow Type is required"),
    workflow: Yup.string().required("Workflow Name is required"),
    workflow_input_type: Yup.array().when(["workflow_type"], {
      is: "NONBUSINESS",
      then: Yup.array().required("Workflow Input Type is required").nullable(),
    }),
    workflow_procedure_type: Yup.array().when(["workflow_type"], {
      is: "BUSINESS",
      then: Yup.array()
        .required("Workflow procedure Type is required")
        .nullable(),
    }),
  };
  const validationSchema = Yup.object().shape({
    workflow: Yup.object().shape(formAdminSchema),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    setValue,
    clearErrors,
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);

  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [workflowType, setWorkflowType] = useState<any>();
  const [workflowValue, setworkflowValue] = useState("");
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [showDropDownLoader, setShowDropDownLoader] = useState(false);

  const dispatch = useDispatch();
  const workflowLists = useSelector((state: AppState) => {
    return state.managementReducerData.workflowList.data;
  });
  const procedureListById = useSelector((state: AppState) => {
    return state.managementReducerData.ProcedureResultsByid.data;
  });
  const workflowTypes = useSelector((state: AppState) => {
    return state.managementReducerData.workflowType.data;
  });
  const workflowInputTypes = useSelector((state: AppState) => {
    return state.managementReducerData.workflowInputType.data;
  });
  const procedures = useSelector((state: AppState) => {
    return state.managementReducerData.procedureList.data;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessage;
  });

  const workflowTypeOptions = workflowTypes?.data?.map((workflowType: any) => ({
    value: workflowType,
    label: workflowType === "NONBUSINESS" ? "Non-Business" : "Business",
  }));

  const workflowInputTypeOptions = workflowInputTypes?.results?.map(
    (workflowInputType: any, index: any) => ({
      key: workflowInputType.id,
      text: workflowInputType.name,
    })
  );

  const procedureOptions = procedures?.results?.map((procedure: any) => ({
    key: procedure?.id,
    text: procedure?.name,
  }));
  const procedurecount = procedures?.count;
  const selectedprocedures = procedureListById;

  const workflowOptions = workflowLists?.results?.map((workflow: any) => ({
    value: workflow.id,
    label: workflow.name,
  }));

  const _alertClicked = useCallback(() => {
    const values = getValues();
    setworkflowValue("");
    setSelectedKeys([]);
    dispatch(getWorkflowList(1, "", "", 10));
    // values.algorithm.splice(0, 1);
    showModal();
  }, []);

  useEffect(() => {
    setDisabledCreate(false);
    setWorkflowType("");
  }, [isModalOpen]);

  useEffect(() => {
    setworkflowValue("");
    dispatch(getWorkflowInputTypeList());
    dispatch(getWorkflowTypeList());
    dispatch(getProcedureList(1, procedurecount));
  }, []);
  useEffect(() => {
    if (selectedprocedures) {
      const arr = selectedprocedures?.workflow_mapping?.split(",");
      const keysSelected: any = [];
      arr.forEach((element: any) => {
        const list =
          workflowType === "BUSINESS"
            ? procedureOptions
            : workflowInputTypeOptions;
        const ele = list?.find((sub: any) => sub.text == element);
        if (ele) {
          keysSelected.push(ele?.key);
        }
      });
      if (
        keysSelected &&
        Object.keys(keysSelected).length &&
        workflowType === "BUSINESS"
      ) {
        setValue(`workflow.workflow_procedure_type`, keysSelected);
        clearErrors(`workflow.workflow_procedure_type`);
      }
      if (
        keysSelected &&
        Object.keys(keysSelected).length &&
        workflowType === "NONBUSINESS"
      ) {
        setValue(`workflow.workflow_input_type`, keysSelected);
        clearErrors(`workflow.workflow_input_type`);
      }
      setSelectedKeys(keysSelected);
    }
  }, [selectedprocedures]);
  const onSubmit = async (data: any) => {
    if (data?.workflow?.workflow_type === "NONBUSINESS") {
      if (!data?.workflow?.workflow_input_type?.length) {
        setValue(`workflow.workflow_input_type`, "");
        setError("workflow.workflow_input_type", {
          type: "required",
          message: "Workflow Input Type is required",
        });
        return false;
      }
    }
    if (data?.workflow?.workflow_type === "BUSINESS") {
      if (!data?.workflow?.workflow_procedure_type?.length) {
        setValue(`workflow.workflow_procedure_type`, "");
        setError("workflow.workflow_procedure_type", {
          type: "required",
          message: "Workflow procedure Type is required",
        });
        return false;
      }
    }
    setDisabledCreate(true);
    delete data.workflow?.workflow_type;
    const res: any = await dispatch(
      workflowType === "NONBUSINESS"
        ? createWorkflowMapping({
            workflow_input_type: data.workflow?.workflow_input_type,
            workflow: +data.workflow?.workflow,
          })
        : createWorkflowProcedureMapping({
            proceduretype: data.workflow?.workflow_procedure_type,
            workflow: +data.workflow?.workflow,
          })
    );
    if (res && (res.status === 200 || res.status === 201)) {
      setworkflowValue("");
      setSelectedKeys([]);
      setWorkflowType(undefined);
    } else {
      return false;
    }
  };

  const getWorkFlowDataList = async (value: any) => {
    setShowDropDownLoader(true);
    if (value === "NONBUSINESS") {
      await dispatch(getWorkflowList(1, false, "", 500));
      setShowDropDownLoader(false);
    } else {
      await dispatch(getWorkflowList(1, true, "", 500));
      setShowDropDownLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: allActions.CLEAR_PROCEDURE,
      });
    };
  }, []);

  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={showModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          {showDropDownLoader && <Loader></Loader>}
          {loaderWithMessage &&
            loaderWithMessage?.calledFrom === "CREATE_WORKFLOW_MAPPING" && (
              <LoaderWithMessage
                loaderWithMessage={loaderWithMessage}
              ></LoaderWithMessage>
            )}
          <Text variant="large" className="muted pointer" onClick={showModal}>
            Workflow Management
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">Workflow Mapping</Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton
            className={showDropDownLoader ? "point-events-none" : ""}
            iconProps={cancelIcon}
            onClick={() => _alertClicked()}
          />
        </Stack.Item>
      </Stack>
      <form
        id="algorithm"
        className={showDropDownLoader ? "point-events-none" : ""}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack.Item className="algorithm ms-Grid" dir="ltr">
          <Stack.Item
            className="ms-Grid-row"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack.Item
              className="ms-Grid-col ms-lg10"
              style={{
                padding: "48px 73px",
                backgroundColor: "#101112",
              }}
            >
              <Stack.Item className="ms-Grid-row ">
                <Stack.Item className="ms-Grid-col ms-lg5">
                  <Label>Workflow Type*</Label>
                  <Controller
                    control={control}
                    defaultValue=""
                    shouldUnregister
                    name={"workflow.workflow_type"}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        classNamePrefix="addl-class"
                        options={workflowTypeOptions}
                        value={workflowTypeOptions?.find(
                          (c: any) => c.value === value
                        )}
                        onChange={(val) => {
                          onChange(val?.value);
                          setworkflowValue("");
                          setWorkflowType(val?.value);
                          setSelectedKeys([]);
                          if (procedurecount !== procedureOptions.length) {
                            dispatch(getProcedureList(1, procedurecount));
                          }
                          getWorkFlowDataList(val?.value);
                        }}
                      />
                    )}
                  />
                  <Label className="errorMessage">
                    {errors?.workflow
                      ? errors?.workflow?.workflow_type?.message
                      : ""}
                  </Label>
                  <Stack.Item>
                    <Label>Workflow Name*</Label>
                    <Controller
                      control={control}
                      defaultValue=""
                      shouldUnregister
                      name={"workflow.workflow"}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          isDisabled={showDropDownLoader}
                          classNamePrefix="addl-class"
                          options={workflowOptions}
                          value={workflowValue}
                          onInputChange={(val) => {
                            if (!workflowType)
                              dispatch(getWorkflowList(1, "", val, 500));
                          }}
                          onChange={(value: any) => {
                            setworkflowValue(value);
                            onChange(value?.value);
                            dispatch(getProcedureDetailsById(value?.value));
                          }}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {errors?.workflow
                        ? errors?.workflow?.workflow?.message
                        : ""}
                    </Label>
                  </Stack.Item>
                  {workflowType && (
                    <>
                      {workflowType === "NONBUSINESS" ? (
                        <Stack.Item>
                          <Controller
                            control={control}
                            defaultValue=""
                            shouldUnregister
                            name={"workflow.workflow_input_type"}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Dropdown
                                placeholder="Select from the list"
                                label="Input Source Type*"
                                selectedKeys={selectedKeys}
                                onChange={(
                                  evt: FormEvent,
                                  option?: IDropdownOption
                                ) => {
                                  const value: any = option;
                                  const valuesSelected = value.selected
                                    ? [...selectedKeys, value.key]
                                    : selectedKeys.filter(
                                        (key) => key !== value.key
                                      );
                                  setSelectedKeys(valuesSelected);
                                  onChange(valuesSelected);
                                }}
                                multiSelect
                                options={workflowInputTypeOptions}
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors?.workflow
                              ? errors?.workflow?.workflow_input_type?.message
                              : ""}
                          </Label>
                        </Stack.Item>
                      ) : (
                        <Stack.Item>
                          <Controller
                            control={control}
                            defaultValue=""
                            shouldUnregister
                            name={"workflow.workflow_procedure_type"}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Dropdown
                                title={
                                  !procedureOptions?.length
                                    ? "You do not have permission to perform this action."
                                    : ""
                                }
                                disabled={
                                  !procedureOptions?.length ? true : false
                                }
                                placeholder="Select from the list"
                                label="Procedure Type*"
                                selectedKeys={selectedKeys}
                                onChange={(
                                  evt: FormEvent,
                                  option?: IDropdownOption
                                ) => {
                                  const value: any = option;
                                  const valuesSelected = value.selected
                                    ? [...selectedKeys, value.key as string]
                                    : selectedKeys.filter(
                                        (key) => key !== value.key
                                      );
                                  setSelectedKeys(valuesSelected);
                                  onChange(valuesSelected);
                                }}
                                multiSelect
                                options={procedureOptions}
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors?.workflow
                              ? errors?.workflow?.workflow_procedure_type
                                  ?.message
                              : ""}
                          </Label>
                        </Stack.Item>
                      )}
                    </>
                  )}
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack.Item>
      </form>
      <Stack
        className="createHospitalWizardFooter"
        horizontal
        horizontalAlign="end"
      >
        <Stack.Item className="nextCancel">
          <PrimaryButton
            disabled={disabledCreate}
            type="submit"
            form="algorithm"
            className="btn-next"
          >
            Save
            <Text className="spinnerRight">
              {disabledCreate && <Spinner size={SpinnerSize.small} />}
            </Text>
          </PrimaryButton>
          <ActionButton
            text="Cancel"
            className={showDropDownLoader ? "point-events-none btn-c" : "btn-c"}
            allowDisabledFocus
            onClick={() => _alertClicked()}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default WorkflowMapping;
