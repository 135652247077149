import React from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useDispatch } from "react-redux";
import { hideDeleteWarningMessageBar } from "../../actions/datasetAction";

const DeleteWarningMessageBar = () => {
  const dispatch = useDispatch();

  return (
    <MessageBar
      messageBarType={MessageBarType.severeWarning}
      isMultiline={false}
      onDismiss={() => dispatch(hideDeleteWarningMessageBar())}
      dismissButtonAriaLabel="Close"
      styles={{
        content: {
          backgroundColor: "#450b0b",
        },
        innerText: {
          color: "white",
        },
        dismissal: {
          ".ms-Button-icon": {
            color: "#f2a14e",
          },
        },
      }}
    >
      Warning! The dataset will be deleted if there are no algorithms mapped.
    </MessageBar>
  );
};

export default DeleteWarningMessageBar;
