import { FontIcon, Stack, Text } from "@fluentui/react";
import React from "react";
import { timeSince } from "../../common/util";
import { fonts, ThemeColorPalette } from "../../theme";

export interface NotificationsTabProps {
  notificationData: any;
}

export interface NotificationsTabState {}

class NotificationsTab extends React.Component<
  NotificationsTabProps,
  NotificationsTabState
> {
  state = {};
  render() {
    // const sentNotificationTime: any = new Date(
    //   this.props.notificationData?.data["sent_at"]
    // );
    // const notificationTime: any = new Date(
    //   Date.UTC(
    //     sentNotificationTime.getFullYear(),
    //     sentNotificationTime.getMonth(),
    //     sentNotificationTime.getDate(),
    //     sentNotificationTime.getHours(),
    //     sentNotificationTime.getMinutes(),
    //     sentNotificationTime.getSeconds(),
    //     sentNotificationTime.getMilliseconds()
    //   )
    // );
    const { notificationData } = this.props;
    return (
      <Stack
        verticalFill
        tokens={{ childrenGap: 15 }}
        styles={{
          root: {
            backgroundColor: ThemeColorPalette.pureBlack,
            padding: "1rem",
            borderRadius: "5%",
          },
        }}
      >
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FontIcon
            iconName={
              notificationData.data.status === "failed" ||
              notificationData.data.status === "FAILED" ||
              notificationData.data.status === "FAILURE"
                ? "Warning"
                : "Accept"
            }
            style={{
              color:
                notificationData.data.status === "failed" ||
                notificationData.data.status === "FAILED" ||
                notificationData.data.status === "FAILURE"
                  ? "red"
                  : ThemeColorPalette.ribbon,
              fontSize: "1rem",
            }}
          />
          <Text
            variant="medium"
            styles={{
              root: {
                fontFamily: fonts.HelveticaNeue_Medium,
                width: "17rem",
                overflowWrap: "break-word",
              },
            }}
          >
            {notificationData && notificationData.data.message}
          </Text>
        </Stack>
        <Stack tokens={{ childrenGap: 9 }}>
          <Text
            variant="smallPlus"
            styles={{
              root: {
                fontFamily: fonts.HelveticaNeue_Medium,
                color: ThemeColorPalette.uploadVideoBtnTxt,
              },
            }}
          >
            {notificationData && notificationData.data.name}
          </Text>

          <Text
            variant="small"
            styles={{
              root: {
                fontFamily: fonts.HelveticaNeue_Light,
                color: ThemeColorPalette.uploadVideoBtnTxt,
              },
            }}
          >
            {timeSince(this.props.notificationData?.data["sent_at"])}
          </Text>
        </Stack>
      </Stack>
    );
  }
}

export default NotificationsTab;
