export const FETCH_VIDEO_DETAILS = "FETCH_VIDEO_DETAILS";
export const GET_ANNOTATION_BLOB_URLS = "GET_ANNOTATION_BLOB_URLS";

export const FETCH_ANNOTATION_DATA = "FETCH_ANNOTATION_DATA";
export const FETCH_SEGMENTATION_DATA = "FETCH_SEGMENTATION_DATA";

export const GET_VIDEO_PHASES = "GET_VIDEO_PHASES";

export const GET_ACTIV_INSIGHTS_DATA = "GET_ACTIV_INSIGHTS_DATA";

export const SET_CURRENT_VIDEO_PLAYER_TIME = "SET_CURRENT_VIDEO_PLAYER_TIME";

export const SHOW_SPINNER = "SHOW_SPINNER";

export const SHOW_NO_DATA = "SHOW_NO_DATA";

export const CLEAR_VIDEO_DATA = "CLEAR_VIDEO_DATA";

export const GET_VIDEO_COMMENTS = "GET_VIDEO_COMMENTS";
export const GET_VIDEOS_BY_PROCEDURE = "GET_VIDEOS_BY_PROCEDURE";

export const RESET_VIDEO_COMMENTS = "RESET_VIDEO_COMMENTS";

export const ADD_VIDEO_COMMENT = "ADD_VIDEO_COMMENT";

export const ADD_REPLYTO_VIDEO_COMMENT = "ADD_REPLYTO_VIDEO_COMMENT;";

export const DELETE_VIDEO_COMMENT = "DELETE_VIDEO_COMMENT";

export const DELETE_COMMENT_FROM_COMMENTS = "DELETE_COMMENT_FROM_COMMENTS";

export const EDIT_VIDEO_COMMENT = "EDIT_VIDEO_COMMENT";
export const ERROR_IN_MANAGEMENT = "ERROR_IN_MANAGEMENT";
export const SHOW_SPINNER_VIDEO_DETAILS = "SHOW_SPINNER_VIDEO_DETAILS";
export const SHOW_COMMENT_LOADER = "SHOW_COMMENT_LOADER";
export const HIDE_COMMENT_LOADER = "HIDE_COMMENT_LOADER";
