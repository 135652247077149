import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  Image,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../../theme";
import videoImg from "../../../assets/icon_4.svg";

export interface InprogressCardProps {
  inProgressVideosData: any;
  handleVideoSelect?: any;
  handleDelete?: any;
  handleDeletePopup?: any;
  shouldDelete?: boolean;
}

const dialogStyles = { main: { maxWidth: 450, margin: "0 auto" } };

const dialogContentProps = {
  type: DialogType.largeHeader,
  title: "Cancel Upload",
  closeButtonAriaLabel: "Close",
  subText: "Progress made cannot be restored. Are you sure?",
};

const InprogressCard: React.SFC<InprogressCardProps> = ({
  inProgressVideosData,
  handleVideoSelect,
  handleDeletePopup,
  handleDelete,
  shouldDelete,
}) => {
  const modalProps = React.useMemo(
    () => ({
      isBlocking: false,
      styles: dialogStyles,
    }),
    []
  );
  return (
    <Stack
      verticalFill
      verticalAlign="start"
      tokens={{ childrenGap: 12 }}
      styles={{
        root: {
          width: "90%",
          overflowX: "hidden",
        },
      }}
    >
      {inProgressVideosData.map((data: any, i: any) => {
        return (
          <Stack
            key={data.id}
            horizontal
            horizontalAlign="space-between"
            styles={{
              root: {
                backgroundColor: data.isSelected
                  ? ThemeColorPalette.selectedGridBG
                  : ThemeColorPalette.gridBG,
                padding: "0.5rem 0.5rem",
                marginRight:
                  inProgressVideosData.length > 5 ? "1rem" : "0.5rem",
                marginLeft: inProgressVideosData.length < 5 ? "0.5rem" : "",
                flex: 1,
                boxShadow: "7px 7px " + ThemeColorPalette.pureBlack,
                maxHeight: "5.5rem",
                minHeight: "5.5rem",
                cursor: "pointer",
              },
            }}
            onClick={() => handleVideoSelect(data)}
          >
            <StackItem
              align="start"
              styles={{
                root: {
                  flex: 1,
                  cursor: "pointer",
                },
              }}
            >
              <FontIcon
                iconName="SkypeCircleCheck"
                style={{
                  color: ThemeColorPalette.themePrimary,
                  visibility: data.isSelected ? "visible" : "hidden",
                }}
              />
            </StackItem>
            <StackItem align="center" styles={{ root: { flex: 1 } }}>
              <Image src={videoImg} width={"2rem"} height={"2rem"} />
            </StackItem>
            <StackItem
              align="center"
              styles={{ root: { flex: 8, paddingLeft: "1rem" } }}
            >
              <TooltipHost content={data.videoData.mainVideos.mainFileName}>
                <StackItem
                  styles={{
                    root: { width: "15rem", overflowWrap: "break-word" },
                  }}
                >
                  <Text variant="smallPlus">
                    {data.videoData.mainVideos.mainFileName.slice(0, 30)}
                  </Text>
                </StackItem>
              </TooltipHost>
              <ProgressIndicator
                percentComplete={data.percentageComplete / 100}
              />
              {data.error ? (
                <Stack
                  horizontal
                  horizontalAlign="center"
                  tokens={{ childrenGap: 7 }}
                >
                  <FontIcon iconName="Info" style={{ color: "red" }} />
                  <Text
                    variant="tiny"
                    styles={{
                      root: {
                        font: fonts.HelveticaNeue_Medium,
                        color: ThemeColorPalette.pureWhite,
                        opacity: 0.5,
                      },
                    }}
                  >
                    {data.error}.
                  </Text>
                </Stack>
              ) : (
                <Text variant="tiny" styles={{ root: { opacity: 0.5 } }}>
                  {`${(data.fileUploadedSize / 1073741824).toFixed(2)} GB/${(
                    data.fileSize / 1073741824
                  ).toFixed(2)} GB`}
                </Text>
              )}
            </StackItem>
            <StackItem
              align="start"
              styles={{
                root: {
                  opacity: 0.3,
                  paddingTop: "0.5rem",
                  paddingLeft: "1.2rem",
                  paddingRight: "3rem",
                  cursor: "pointer",
                },
              }}
            >
              <FontIcon
                iconName="Cancel"
                onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                  handleDeletePopup(evt, data.id)
                }
              />
              <Dialog
                hidden={!shouldDelete}
                onDismiss={handleDeletePopup}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
                styles={{
                  root: {
                    background: "blur",
                  },
                }}
              >
                <DialogFooter>
                  <PrimaryButton onClick={handleDelete} text="Delete" />
                  <DefaultButton
                    onClick={handleDeletePopup}
                    text="Don't Delete"
                  />
                </DialogFooter>
              </Dialog>
            </StackItem>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default InprogressCard;
