import React, { useCallback } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../Wizard";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import { useState, useEffect } from "react";
import {
  createContainer,
  updateContainer,
} from "../../../actions/managementAction";

import ContainerCreation from "./ContainerCreation";
import "../../../../../HospitalAdminModule/Home/components/CreateSurgeon/CreateSurgeon.scss";
const cancelIcon: IIconProps = { iconName: "Cancel" };

const AddContainer = ({
  isModalOpen,
  hideModal,
  selectedContainer,
  newOrEdit,
}: {
  isModalOpen: any;
  hideModal: any;
  selectedContainer?: any;
  newOrEdit?: string;
}) => {
  const [disabledCreate, setDisabledCreate] = useState(false);
  const dispatch = useDispatch();

  const spinner = useSelector((state: AppState) => {
    return state.managementReducerData.showRoleSpinner;
  });

  useEffect(() => {
    setData({ ...data, ...selectedContainer });
  }, []);

  const [errors, setErrors] = useState<{
    name?: string;
    tag?: string;
    description?: string;
    algorithm?: any;
    model_name?: any;
    model_version?: any;
  }>({});

  const [data, setData] = useState<{
    name: string;
    tag: string;
    description: string;
    algorithm: any;
    model_name: any;
    model_version: any;
    is_developer: boolean;
  }>({
    name: "",
    tag: "",
    description: "",
    algorithm: null,
    model_name: "",
    model_version: "",
    is_developer: true,
  });

  useEffect(() => {
    setDisabledCreate(false);
    setErrors({});
  }, [isModalOpen]);

  const createSurgeonError = useSelector((state: AppState) => {
    return state.developerManagementReducerData.createContainerError;
  });

  const onCreate = useCallback(() => {
    const { name, tag, description, algorithm, model_name, model_version } =
      data;
    const errors: any = {};
    if (!name || name.trim().length === 0) {
      errors.name = "Docker Image should not be empty.";
    }
    if (!algorithm || algorithm.toString().trim().length === 0) {
      errors.algorithm = "Algorithm should not be empty.";
    }

    if (model_name && !model_version) {
      errors.model_version = "Model version required with model name.";
    }

    if (Object.keys(errors).length === 0) {
      setDisabledCreate(true);
      if (createSurgeonError?.length !== 0) {
        setDisabledCreate(false);
      }
      if (newOrEdit === "new") {
        dispatch(createContainer(data));
      } else if (newOrEdit === "edit") {
        dispatch(updateContainer(data));
      }
    }

    setErrors(errors);
  }, [data]);

  useEffect(() => {
    if (!!createSurgeonError) {
      const newErrors: any = {};
      Object.keys(createSurgeonError)?.forEach((key: string) => {
        newErrors[key] = createSurgeonError[key][0];
      });
      if (
        Object.keys(newErrors).every(
          (key: any) => newErrors[key] !== (errors as any)[key]
        )
      ) {
        setErrors({ ...errors, ...newErrors });
      }
    }
  }, [createSurgeonError, errors]);

  return (
    <Modal
      className="createHospital addModel"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted pointer" onClick={hideModal}>
            Model Management
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          {newOrEdit === "new" && <Text variant="large">Add Container</Text>}
          {newOrEdit === "edit" && <Text variant="large">Edit Container</Text>}
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={hideModal} />
        </Stack.Item>
      </Stack>

      <Wizard
        // spinner={spinner}
        disabledCreate={disabledCreate}
        onCreate={onCreate}
        newOrEdit={newOrEdit}
        onCancel={hideModal}
      >
        <ContainerCreation
          updateData={setData}
          newOrEdit={newOrEdit}
          data={data}
          errors={errors}
        />
      </Wizard>
    </Modal>
  );
};

export default AddContainer;
