import {
  Stack,
  StackItem,
  Image,
  Nav,
  IStackItemStyles,
  IStackProps,
  INavStyles,
  INavLinkGroup,
  INavLink,
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { ThemeColorPalette } from "../../../theme";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ReactAlgorithm } from "../../../assets/Management/Comparison.svg";
import { ReactComponent as ReactDevices } from "../../../assets/Management/AddContainer.svg";
import { ReactComponent as ReactProcedures } from "../../../assets/Management/Procedures.svg";
import { ReactComponent as ReactUser } from "../../../assets/Management/AddModel.svg";
import { ReactComponent as ReactWorkFlow } from "../../../assets/Management/Workflow-Icon.svg";

const containerStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.sideNavBg,
    color: ThemeColorPalette.pureWhite,
  },
};
const leftColumnProps: Partial<IStackProps> = {
  tokens: { padding: "30", maxHeight: "" },
};
const navStyles: Partial<INavStyles> = {
  root: {
    // fontFamily: fonts.HelveticaNeue_Medium,
    boxSizing: "border-box",
    color: ThemeColorPalette.pureWhite,
  },
};
const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      { name: "", url: "/management/model", key: "model" },
      { name: "", url: "/management/container", key: "container" },
      { name: "", url: "/management/comparison", key: "comparison" },
    ],
  },
];
const navOptions = [
  { key: "model", imgsrc: ReactUser, text: "Add Model" },
  { key: "container", imgsrc: ReactDevices, text: "Add Container" },
  { key: "comparison", imgsrc: ReactAlgorithm, text: "Comparison" },
];
const ManagementNavBar = ({ location }: { location: any }) => {
  const [selectedNavLink, setSelectedNavLink] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedNavLink(location);
  }, [location]);
  const onRenderLink = (props?: any): JSX.Element | null => {
    return (
      <>
        {navOptions.length
          ? navOptions.map((navItem: any) => {
              if (props.key === navItem.key)
                return (
                  <div key={navItem.key} className="nav-item">
                    <Stack horizontal verticalAlign="center">
                      <Stack horizontal className="navIcon">
                        <navItem.imgsrc></navItem.imgsrc>
                      </Stack>
                      <Stack.Item className="navText">
                        {navItem.text}
                      </Stack.Item>
                    </Stack>
                  </div>
                );
              else return null;
            })
          : null}
      </>
    );
  };
  const handleSidebarClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ): any => {
    ev?.preventDefault();
    if (item && item.key) {
      setSelectedNavLink(item.key);
      navigate(item.url);
      localStorage.setItem("sideBar", "true");
      setTimeout(() => {
        localStorage.setItem("sideBar", "false");
      }, 2000);
      navigate(item.url);
    }
  };
  return (
    <Stack verticalFill styles={containerStyles} className="managementSubLinks">
      <Stack
        {...leftColumnProps}
        styles={{ root: { position: "fixed", width: "148px" } }}
      >
        <Nav
          onLinkClick={handleSidebarClick}
          selectedKey={selectedNavLink}
          styles={navStyles}
          className="nav-cls1"
          groups={navLinkGroups}
          onRenderLink={onRenderLink}
        />
      </Stack>
    </Stack>
  );
};
export default ManagementNavBar;
