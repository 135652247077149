import {
  DefaultButton,
  ILabelStyles,
  ITextFieldStyles,
  Label,
  Panel,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
  Modal,
  Spinner,
  SpinnerSize,
  Text,
  IIconProps,
  IconButton,
} from "office-ui-fabric-react";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ThemeColorPalette } from "../../theme";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelChangePassword,
  doChangePassword,
} from "../../auth/actions/authAction";
import { AppState } from "../../rootReducer";
import SuccessMessage from "../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import { EyeFill, EyeOffFill } from "@fluent-ui/icons";
const changePasswordStyles: Partial<ITextFieldStyles> = {
  root: {
    color: ThemeColorPalette.changePasswordTxt,
    opacity: 0.7,
  },
};
const labelStyles: Partial<ILabelStyles> = {
  root: {
    color: ThemeColorPalette.changePasswordLabelTxt,
    opacity: 0.7,
  },
};
const iconProps: Partial<IIconProps> = {
  iconName: "EyeFill",
};
const ChangePassword = ({ isPanelOpen, dismiss }: any) => {
  const dispatch = useDispatch();
  const [isOldPasswordRevealed, setOldPasswordReveal] = useState(false);
  const [isNewPasswordRevealed, setNewPasswordReveal] = useState(false);
  const [isConfirmPasswordRevealed, setConfirmPasswordReveal] = useState(false);
  const togglePasswordReveal = (updateMethod: Function, value: Boolean) => {
    updateMethod(!value);
  };
  const changePasswordStatus = useSelector((state: AppState) => {
    return state?.authReducerData?.changePasswordStatus;
  });
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const passwordValidationRegex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  );
  // const formAdminSchema = Yup.object().shape({
  //   firstName: Yup.string().required("firstName is required"),
  //   lastName: Yup.string().required("lastName is required"),
  //   username: Yup.string().required("username is required"),
  // });
  // // const validationSchema = Yup.object().shape({
  // //      passwords: Yup.object().shape(formAdminSchema),
  // //    });
  //  const formOptions = { resolver: yupResolver(formAdminSchema) };
  const formAdminSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Password is required"),
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        passwordValidationRegex,
        "New Password that is at least 8 to 64 characters. It requires minimum 1 lowercase, uppercase, numbers and symbols (!@$%&*?)."
      ),
    confirmPassword: Yup.string()
      .required("confirm new password is required")
      .when("newPassword", {
        // this should match with input field name
        is: (newPassword: any) =>
          newPassword && newPassword.length > 0 ? true : false,
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Passwords must match"
        ),
      }),
    // confirmPassword: Yup.string().required("confirm new password is required")
    // .test('passwords-match', 'Passwords must match', function(value){
    //   return this.parent.newPassword === value
    // })
    // confirmPassword:Yup.string().required("confirm new password is required")
    // .test('passwords-match', 'Passwords must match', function(value){
    //        return this.parent.newPassword === value
    //    })
    // Yup.string().required("confirm new password is required") &&
    // Yup.string().oneOf([Yup.ref("newPassword")], "Passwords must match111"),
  });
  // const validationSchema = Yup.object().shape({
  //   passwords: Yup.object().shape(formAdminSchema),
  // });
  const formOptions = { resolver: yupResolver(formAdminSchema) };
  const {
    clearErrors,
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);
  // const {
  //   getValues,
  //   reset,
  //   register,
  //   formState: { errors },
  //   handleSubmit,
  //   clearErrors
  // } = useForm();
  const [disabledCreate, setDisabledCreate] = useState(false);
  const ChangePasswordSpinner = useSelector((state: AppState) => {
    return state.authReducerData.ChangePasswordSpinner;
  });
  const errorResponsePassword = useSelector((state: AppState) => {
    return state.authReducerData.errorResponsePassword;
  });
  const handleFieldChange = () => {
    setLocalErrorMessage("");
  };
  // useEffect(() => {
  //   setDisabledCreate(false);
  //   var values = getValues();
  //   console.log(values, "values");
  //   if (!isPanelOpen) {
  //     for (let prop in values) {
  //       delete values[prop];
  //     }
  //   }
  //   setLocalErrorMessage("");
  // }, [isPanelOpen]);
  useEffect(() => {
    setLocalErrorMessage(
      changePasswordStatus ? changePasswordStatus[0]?.details : ""
    );
  }, [changePasswordStatus]);
  const onSubmit = (data: any) => {
    localStorage.setItem("panelNavigation", "true");
    const { newPassword, confirmPassword, currentPassword } = data;
    if (confirmPassword === newPassword) {
      dispatch(doChangePassword(data));
    }
    setLocalErrorMessage("");
  };
  const handleReset = () => {
    reset();
  };
  useEffect(() => {
    dismiss();
  }, [errorResponsePassword, ChangePasswordSpinner]);
  // useEffect(() => {
  //   return () => {
  //     clearErrors()
  //   };
  // });
  const cancelHandler = () => {
    dismiss();
    handleReset();
    localStorage.setItem("panelNavigation", "true");
    dispatch(cancelChangePassword({}));
  };
  useEffect(() => {
    if (ChangePasswordSpinner) {
      setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("loggedInProfile");
        window.location.href = "/login";
      }, 2000);
    }
  }, [ChangePasswordSpinner]);
  return (
    <Panel
      isOpen={isPanelOpen}
      // onDismiss={() => dismiss()}
      onDismiss={() => cancelHandler()}
      headerText="Change Password"
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      styles={{
        footerInner: {
          padding: 0,
        },
      }}
      onRenderFooterContent={() => {
        return (
          <Stack
            horizontal
            horizontalAlign="start"
            tokens={{ childrenGap: 10 }}
            styles={{
              root: {
                borderTop: "1px solid " + ThemeColorPalette.panelBorder,
                textAlign: "right",
                paddingTop: "0.7rem",
                paddingBottom: "0.7rem",
                paddingLeft: "1.5rem",
              },
            }}
          >
            <PrimaryButton
              type="submit"
              form="passwordChange"
              className="btn-next"
              disabled={disabledCreate}
            >
              Save
              <Text className="spinnerRight">
                {disabledCreate && <Spinner size={SpinnerSize.small} />}
              </Text>
            </PrimaryButton>
            {/* <DefaultButton onClick={() => dismiss()}>Cancel</DefaultButton> */}
            <DefaultButton onClick={() => cancelHandler()}>
              Cancel
            </DefaultButton>
          </Stack>
        );
      }}
    >
      <Stack
        verticalFill
        verticalAlign="start"
        tokens={{ childrenGap: 15, padding: "1rem 0 0 0" }}
      >
        <form id="passwordChange" onSubmit={handleSubmit(onSubmit)}>
          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
            <label className="custom-labal">Current Password</label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom border-opacity">
              <input
                className="ms-TextField-field field-custom"
                {...register(`currentPassword`)}
                type={isOldPasswordRevealed ? "text" : "password"}
                id="currentPassword"
                name="currentPassword"
                onKeyPress={() => setLocalErrorMessage("")}
              />
              <div
                className="ms-TextField-suffix1 suffix-338"
                onClick={() => {
                  togglePasswordReveal(
                    setOldPasswordReveal,
                    isOldPasswordRevealed
                  );
                }}
              >
                <button
                  type="button"
                  className="ms-TextField-reveal ms-Button ms-Button--icon custom-button-eye"
                >
                  <span className="custom-eyeFill">
                    {isOldPasswordRevealed ? <EyeFill /> : <EyeOffFill />}
                  </span>
                </button>
              </div>
            </Stack>
            {
              <label className="errorMessage">
                {errors && errors?.currentPassword
                  ? errors?.currentPassword?.message
                  : localErrorMessage
                  ? localErrorMessage
                  : ""}
              </label>
            }
          </Stack>
          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
            <label className="custom-labal">New Password</label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom border-opacity">
              <input
                className="ms-TextField-field field-custom"
                {...register(`newPassword`)}
                type={isNewPasswordRevealed ? "text" : "password"}
                id="newPassword"
                name="newPassword"
              />
              <div
                className="ms-TextField-suffix1 suffix-338"
                onClick={() => {
                  togglePasswordReveal(
                    setNewPasswordReveal,
                    isNewPasswordRevealed
                  );
                }}
              >
                <button
                  type="button"
                  className="ms-TextField-reveal ms-Button ms-Button--icon custom-button-eye"
                >
                  <span>
                    {isNewPasswordRevealed ? <EyeFill /> : <EyeOffFill />}
                  </span>
                </button>
              </div>
            </Stack>
            {
              <label className="errorMessage">
                {errors ? errors?.newPassword?.message : ""}
              </label>
            }
          </Stack>
          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
            <label className="custom-labal">Confirm New Password</label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom border-opacity">
              <input
                className="ms-TextField-field field-custom"
                {...register(`confirmPassword`)}
                type={isConfirmPasswordRevealed ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
              />
              <div
                className="ms-TextField-suffix1 suffix-338"
                onClick={() => {
                  togglePasswordReveal(
                    setConfirmPasswordReveal,
                    isConfirmPasswordRevealed
                  );
                }}
              >
                <button
                  type="button"
                  className="ms-TextField-reveal ms-Button ms-Button--icon custom-button-eye"
                >
                  <span>
                    {isConfirmPasswordRevealed ? <EyeFill /> : <EyeOffFill />}
                  </span>
                </button>
              </div>
            </Stack>
            {
              <label className="errorMessage">
                {errors ? errors?.confirmPassword?.message : ""}
              </label>
            }
          </Stack>
        </form>
        <Modal isOpen={ChangePasswordSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage title={`Password Updated`} />
        </Modal>
      </Stack>
    </Panel>
  );
};
export default ChangePassword;
