import axios from "axios";
import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getSalesLogs =
  (
    startDate = "",
    endDate = "",
    hospital = "",
    facility = "",
    room = "",
    country = "",
    region = "",
    speciality = "",
    isInit = false,
    filteredLogs = ""
  ) =>
  async (dispatch: any) => {
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/logs/sales?start_date=${startDate}&end_date=${endDate}&hospital=${hospital}&facility=${facility}&room=${room}&country=${country}&region=${region}&speciality=${speciality}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_SALES_LOGS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_SALES_LOGS,
          payload: { data: response.data, isInit: isInit === true },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_SALES_LOGS, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_SALES_LOGS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getServiceLogs =
  (
    startDate = "",
    endDate = "",
    hospital = "",
    facility = "",
    room = "",
    country = "",
    region = "",
    speciality = "",
    deviceID = "",
    isInit = false,
    filteredLogs = ""
  ) =>
  async (dispatch: any) => {
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/logs/service?start_date=${startDate}&end_date=${endDate}&hospital=${hospital}&facility=${facility}&room=${room}&country=${country}&region=${region}&speciality=${speciality}&deviceID=${deviceID}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_SERVICE_LOGS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_SERVICE_LOGS,
          payload: { data: response.data, isInit: isInit === true },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_SERVICE_LOGS,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_SERVICE_LOGS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getViewLogs =
  (searchText = "", currentPage = 1) =>
  async (dispatch: any) => {
    const search = searchText ? `&search=${searchText}` : "";
    const role = localStorage.getItem("role");
    let isDeveloper = false;
    if (role === "Developer") {
      isDeveloper = true;
    }
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/devices/logs?page=${currentPage}&page_size=20${search}&developer=${isDeveloper}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_VIEW_LOGS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIEW_LOGS,
          payload: { data: response.data, currentPage: currentPage },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_VIEW_LOGS, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_VIEW_LOGS,
            403
          )
        );
      }
      console.error(response);
    }
  };

// const downloadfiles = (urls: string[]) => {
//   if (urls.length === 0) {
//     return;
//   }
//   let url: any = urls.pop();

//   let link = document.createElement("a");
//  link.href = url;
// document.body.appendChild(link);
//  link.click();
// document.body.removeChild(link);
//   link.addEventListener("click", (e) => {
//     e.preventDefault();
//     window.open(url);
//   });
//   link.click();
//   setTimeout(() => {
//     downloadfiles(urls);
//   }, 500);
// };
const downloadfiles = (urls: string[]) => {
  if (urls.length === 0) {
    return;
  }
  const url: any = urls.pop();
  getDateFromUrlAsync(url);
  setTimeout(() => {
    downloadfiles(urls);
  }, 500);
};
const getDateFromUrlAsync = async (url: any) => {
  let fileName = "";
  const fileNameAry = url.split("/");
  const role = localStorage.getItem("role");
  // console.log(url,'urlllllllllll', fileNameAry)

  if (fileNameAry.length && role === "ActivAdmin") {
    const getIndex = fileNameAry.findIndex((name: any) => {
      return name.includes("Analytics");
    });
    if (getIndex > -1) {
      fileName = fileNameAry[getIndex].split(".")[0];
    }
  } else if (fileNameAry.length && role === "Developer") {
    fileName = fileNameAry[6].split(".")[0];
  } else {
    fileName = "log.txt";
  }
  // let fileName =
  //   url && url.split("/").length > 6
  //     ? url.split("/")[6].split(".")[0] + ".txt"
  //     : "log.txt";

  try {
    const response = await fetch(url);
    const responseJson = await response.text();
    const hiddenElement = document.createElement("a");
    hiddenElement.href = "data:attachment/text," + encodeURI(responseJson);
    hiddenElement.target = "_blank";
    hiddenElement.download = fileName;
    hiddenElement.click();
    return responseJson;
  } catch (error) {
    console.error(error);
  }
};
export const downloadMultipleLogs = (logs: any) => async (dispatch: any) => {
  const role = localStorage.getItem("role");
  let isDeveloper = false;
  if (role === "Developer") {
    isDeveloper = true;
  }
  const responses: any[] = await Promise.allSettled(
    logs.map(async (log: any) => {
      const payload = {
        method: "GET",
        url: apiEndPoint + `/devices/logs/${log.id}?developer=${isDeveloper}`,
      };
      return doHttpCall(payload);
    })
  );
  const downloadURLs: string[] = [];
  responses.forEach((res: any, index: number) => {
    if (res.value && res.value.status === 200) {
      downloadURLs.push(res.value.data.log_file);
    }
  });
  downloadfiles(downloadURLs);
};

export const getViewLogById =
  (id = 0) =>
  async (dispatch: any) => {
    const role = localStorage.getItem("role");
    let isDeveloper = false;
    if (role === "Developer") {
      isDeveloper = true;
    }
    const payload = {
      method: "GET",
      url: apiEndPoint + `/devices/logs/${id}?developer=${isDeveloper}`,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIEW_LOG_BY_ID,
          payload: { data: response.data },
        });
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.GET_VIEW_LOG_BY_ID,
          payload: { data: "" },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getDataFromBlobURL = (url: any) => async (dispatch: any) => {
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_DATA_FROM_BLOB_URL));
    const response = await axios.get(url);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_DATA_FROM_BLOB_URL,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_DATA_FROM_BLOB_URL,
          response.status
        )
      );
    } else if (response && response.status === 404) {
      dispatch({
        type: allActions.GET_DATA_FROM_BLOB_URL,
        payload: { data: "" },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_DATA_FROM_BLOB_URL,
          response.status
        )
      );
    }
  } catch (error: any) {
    if (error && error?.request?.status === 404) {
      dispatch(
        hideLoaderWithMessage(
          "emptyRecord",
          allActions.GET_DATA_FROM_BLOB_URL,
          error?.request?.status
        )
      );
      dispatch({
        type: allActions.GET_DATA_FROM_BLOB_URL,
        payload: { data: "" },
      });
    } else {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_DATA_FROM_BLOB_URL,
          403
        )
      );
    }
    // console.log(error);
  }
};

export const getLogHospitals =
  (count?: any, hospital_name = "") =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : ``;
    const hospitalSearch = hospital_name ? `&search=${hospital_name}` : "";
    const payload = {
      method: "GET",
      url: apiEndPoint + `/logs/hospital?page=1${hospitalSearch}${count}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_LOG_HOSPITALS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_LOG_HOSPITALS,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOG_HOSPITALS,
            response.status
          )
        );
      }
    } catch (response) {
      console.error(response);
    }
  };

export const getLogFacilities =
  (hospital_id = 0, facility_name = "", pageCount?: any) =>
  async (dispatch: any) => {
    const page = pageCount ? `page_size=${pageCount}` : `page_size=10`;
    const facilitySearch = facility_name
      ? `?search=${facility_name}&${page}`
      : `?${page}`;
    const payload = {
      method: "GET",
      url:
        apiEndPoint + `/logs/hospital/${hospital_id}/facility${facilitySearch}`,
    };
    try {
      // dispatch(showLoaderWithMessage("", allActions.GET_LOG_FACILITIES));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_LOG_FACILITIES,
          payload: { data: response.data },
        });
        // dispatch(
        //   hideLoaderWithMessage("", allActions.GET_LOG_FACILITIES, response.status)
        // );
      } else if (response && response.status === 404) {
        dispatch({
          type: allActions.GET_LOG_FACILITIES,
          payload: { data: "" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOG_FACILITIES,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_LOG_FACILITIES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getLogRooms =
  (hospital_id = 0, facility_id = "", room_name = "") =>
  async (dispatch: any) => {
    const roomSearch = room_name ? `?search=${room_name}` : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/logs/hospital/${hospital_id}/facility/${facility_id}/room${roomSearch}`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_LOG_ROOMS,
        payload: { data: response.data },
      });
    } else if (response && response.status === 404) {
      dispatch({
        type: allActions.GET_LOG_ROOMS,
        payload: { data: "" },
      });
    }
  };

export const getSpacility = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/hospitals/activ-speciality",
  };
  const response = await doHttpCall(payload);
  if (response && response.status === 200) {
    dispatch({
      type: allActions.GET_SPACILITY,
      payload: { data: response.data },
    });
  }
};

export const getActivity =
  (
    currentPage = 1,
    activity_type_id = "",
    hospital_id = "",
    facility_id = "",
    activityDate = ""
  ) =>
  async (dispatch: any) => {
    const activityTypeSearch = activity_type_id
      ? `&activity_type=${activity_type_id}`
      : "";
    const hospitalSearch = hospital_id
      ? activity_type_id
        ? `&hospital=${hospital_id}`
        : `&hospital=${hospital_id}`
      : "";
    const facilitySearch = facility_id ? `&facility=${facility_id}` : "";
    const activitySearchFilter = activityDate
      ? `&from_date=${activityDate}`
      : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/activities/?page=${currentPage}&page_size=20${activityTypeSearch}${hospitalSearch}${facilitySearch}${activitySearchFilter}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ACTIVITY));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ACTIVITY,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ACTIVITY, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ACTIVITY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const resetActivity = () => (dispatch: any) => {
  dispatch({ type: allActions.RESET_ACTIVITY });
};

export const getActivityType =
  (count?: any, activity_type_name = "") =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : ``;
    const role = localStorage.getItem("role");
    let isDeveloper = false;
    if (role === "Developer") {
      isDeveloper = true;
    }
    const activitytypeSearch = activity_type_name
      ? `&search=${activity_type_name}`
      : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/activities/activitylogtypes?page=1${activitytypeSearch}&developer=false${count}`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_ACTIVITY_TYPES,
        payload: { data: response.data },
      });
    }
  };

export const defaultValueFilterSection = () => {
  return {
    type: allActions.FILTERS_SELECTION,
  };
};

export const templateDownload =
  (type = "") =>
  async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/templates/${type}/download`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.TEMPLATE_DOWNLOAD,
        payload: { data: response.data },
      });
    }
  };

export const downloadActivity =
  (type = "", start_date = "", end_date = "") =>
  async (dispatch: any) => {
    type =
      type === "custom"
        ? `custom&start_date=${start_date}&end_date=${end_date}`
        : type;
    const payload = {
      method: "GET",
      url: apiEndPoint + `/activities?sort=${type}`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.DOWNLOAD_ACTIVITY,
        payload: { data: response.data },
      });
    }
  };

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
