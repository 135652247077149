import React, { useCallback } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import { useState, useEffect } from "react";
import Wizard from "../Wizard";
import EditDataSetForm from "./EditDataSetForm";
import {
  getAlgList,
  getDataSets,
  showDeleteWarningMessageBar,
  hideDeleteWarningMessageBar,
  getAllAlgorithamsListBaseOnDataSet,
  updateAndDeleteEditedDataset,
  getDataSetAlg,
} from "../../actions/datasetAction";
import "../../../../HospitalAdminModule/Home/components/CreateSurgeon/CreateSurgeon.scss";
import { values } from "idb-keyval";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import * as constants from "../../../../Layout/constants/constants";

const cancelIcon: IIconProps = { iconName: "Cancel" };
const EditDataSet = ({
  isModalOpen,
  hideModal,
  editDataSetHospitalId,
}: {
  isModalOpen: any;
  hideModal: any;
  editDataSetHospitalId?: any;
}) => {
  const dispatch = useDispatch();
  const permissionDeleteDataset = constants.getPermissionsbyItem(
    "algorithm.datasetmapping.delete"
  );
  //setDeleteDatasetPermission(permissionDeleteDataset);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const enableDeleteWarningMessageBar = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.enableDeleteWarningMessageBar;
  });
  const getEditedDataSetDetails = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.editedDataSetDetails;
  });
  const [disabledEdit, setDisabledEdit] = useState<any>();
  const editSuccess = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.editDataSetSuccess;
  });
  const enableDelete = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.enableDeleteWarningMessageBar;
  });
  const [data, setData] = useState<{
    dataset_name: string;
    algorithms: any;
    dataset_uuid: any;
  }>({
    dataset_name: getEditedDataSetDetails?.dataset_name,
    dataset_uuid: getEditedDataSetDetails?.dataset_uuid,
    algorithms: [getEditedDataSetDetails?.algorithm],
  });
  useEffect(() => {
    setData({
      dataset_name: getEditedDataSetDetails?.dataset_name,
      dataset_uuid: getEditedDataSetDetails?.dataset_uuid,
      algorithms: [getEditedDataSetDetails?.algorithm],
    });
    dispatch(getDataSetAlg(editDataSetHospitalId));
  }, [isModalOpen]);
  const showDeleteModal = useCallback(() => {
    setDisabledEdit(false);
    setOpenDeleteModal((current) => !current);
  }, []);
  const onCreate = useCallback(async () => {
    if (openDeleteModal) {
      setOpenDeleteModal(false);
    }
    setDisabledEdit(true);
    const { algorithms } = data;
    const algorithmsData = {
      algorithms: algorithms,
    };
    const responce: any = await dispatch(
      updateAndDeleteEditedDataset(
        getEditedDataSetDetails?.dataset_uuid,
        algorithmsData
      )
    );
    if (responce && responce.status === 200) {
      setDisabledEdit(false);
      hideModal();
      setOpenDeleteModal(false);
    }
    setDisabledEdit(false);
  }, [data, values]);
  const manageUpdateDelete = () => {
    // if(permissionDeleteDataset){
    if (enableDeleteWarningMessageBar) {
      permissionDeleteDataset && showDeleteModal();
    } else {
      onCreate();
    }
    // }
    // return false;
  };
  useEffect(() => {
    if (!openDeleteModal) {
      getAlgorithamData();
    }
  }, [openDeleteModal]);
  const getAlgorithamData = async () => {
    const responce: any = await dispatch(
      getAllAlgorithamsListBaseOnDataSet(data?.dataset_uuid)
    );
    if (responce && responce.status === 200) {
      responce?.data?.length
        ? dispatch(hideDeleteWarningMessageBar())
        : dispatch(showDeleteWarningMessageBar());
    }
  };

  return (
    <>
      <Modal
        className="createHospital"
        isModeless={true}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        containerClassName={"modalStyle"}
        isBlocking={false}
      >
        <Stack
          className="createHospitalSection"
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack.Item className="createHospitalHeading">
            <Text variant="large" className="muted">
              Dataset Mapping
            </Text>
            <Text variant="large" className="lessthan muted">
              &gt;
            </Text>
            <Text variant="large">Edit Test Dataset</Text>
          </Stack.Item>
          <Stack.Item align="end">
            <IconButton iconProps={cancelIcon} onClick={hideModal} />
          </Stack.Item>
        </Stack>
        <Wizard
          disabledCreate={disabledEdit}
          onCreate={manageUpdateDelete}
          onCancel={hideModal}
        >
          <EditDataSetForm updateData={setData} data={data} />
        </Wizard>
      </Modal>
      <DeleteModal
        isModalOpen={openDeleteModal}
        deleteHandle={onCreate}
        showModal={showDeleteModal}
        id={1}
        name="Data Set"
        disabledCreate={disabledEdit}
        zIndex={true}
      ></DeleteModal>
      {/* <DeleteModal
      isModalOpen={enableDeleteWarningMessageBar}
      deleteHandle={onCreate}
      showModal={enableDeleteWarningMessageBar}
      id={1}
      name="Model"
      disabledCreate={disabledEdit}
      zIndex={true}
    ></DeleteModal> */}
      {!enableDeleteWarningMessageBar && (
        <Modal isOpen={editSuccess} containerClassName={"modalStyle"}>
          <SuccessMessage
            title={`Dataset ${enableDelete ? "Delete" : "Update"}`}
          />
        </Modal>
      )}
    </>
  );
};
export default EditDataSet;
