import {
  Stack,
  Text,
  TextField,
  Label,
  Modal,
  IconButton,
  IIconProps,
  DefaultButton,
  PrimaryButton,
} from "@fluentui/react";

import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import React, { useState, useEffect, useCallback } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ToggleCard from "../../../Wizard/ToggleCard";
import { ReactComponent as AddFacilityIcon } from "../../../../../../assets/Home/add_facility.svg";
import {
  updateActionData,
  getHospitalValidations,
  validateForm,
  validateFormGet,
  getLocationWithZipcode,
} from "../../../../actions/homeAction";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../../rootReducer";
import { increment } from "../../../../actions/homeAction";
import "./AddHospital.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AddFacility from "./AddFacility";
import { apiEndPoint, doHttpCall } from "../../../../../../common/restApi";
import { StackItem } from "office-ui-fabric-react";
import { debounce } from "lodash";
import DeleteModal from "../../../Common/DeleteModal";
import ShowErrorMessageBar from "../../../Common/ShowErrorMessageBar";
import { getRolesList } from "../../../../../Management/actions/managamentActions";
import LoaderWithMessage from "../../../../../../common/customLoader/LoaderWithMessage";

import * as constants from "./../../../../../../Layout/constants/constants";
import Loader from "../../../../../Logs/components/Common/Loader";
const regions = [
  { value: "Northeast", label: "Northeast" },
  { value: "Ohio", label: "Ohio Valley" },
  { value: "Southeast", label: "Southeast" },
  { value: "South Central", label: "South Central" },
  { value: "Upper Midwest", label: "Upper Midwest" },
  { value: "West", label: "West" },
  { value: "Mountain", label: "Mountain" },
  { value: "Pacific Northwest", label: "Pacific Northwest" },
];
const AddHospital = ({
  hospitalData,
  showCreateHospital,
}: {
  hospitalData: any;
  showCreateHospital: any;
}) => {
  const [indexes, setIndexes] = useState<Array<number>>([]);
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const [gridLayout, setgridLayout] = useState(false);
  const [deleteHospitalId, setDeleteHospitalId] = useState([]);
  const [hospitalName, setHospitalName] = useState("");
  const [address, setAddress] = useState("");
  const [zipcodeOnchange, setZipcodeOnchange] = useState("");
  const [uniqueIdentiferOnchange, setUniqueIdentiferOnchange] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailOnchange, setEmailOnchange] = useState("");
  const [phoneNumberchange, setPhoneNumberchange] = useState("");
  const [newHospitals, setHospitals] = useState<Array<any>>([]);
  const [existingFacilities, setExistingFacilities] = useState(false);
  const [isChangeFacilities, setIsChangeFacilities] = useState(false);
  const [notFound, setNotFound] = useState("");
  const [isDeleteFacilitiesModalOpen, setDeleteFacilitiesModalOpen] =
    useState(false);
  const [facilityDeleteId, setFacilityDeleteId] = useState("");
  const [errorFacilityDelete, setErrorFacilityDelete] = useState({});
  const [deleteErrorIndex, setDeleteErrorIndex] = useState("");
  const [deleteFacilityLoader, setDeleteFacilityLoader] = useState(false);
  const [zipDataAvailable, setZipDataAvailable] = useState(false);
  const [checkValidZipCode, setCheckValidZipCode] = useState<any>();
  const [visibleRoleList, setVisibleRoleList] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const permissionEdit1 = constants.getPermissionsbyItem(
    "management.roles.list"
  );
  // const hospitalData = useSelector((state: AppState) => {
  //   return state.homeReducerData.hospitalData?.data;
  // });
  // console.log('on next ');
  const validationCheck = useSelector((state: AppState) => {
    return state.homeReducerData.checkValidation;
  });
  // console.log(validationCheck, 'validationCheck');
  const phoneValidationRegex = RegExp(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  );
  const emailValidationRegex = RegExp(
    /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  );

  const previousHospitalName =
    hospitalData?.hospitals && hospitalData?.hospitals[0]?.name;
  const previousZipcode =
    hospitalData?.hospitals && hospitalData?.hospitals[0]?.zipcode;
  const previousEmail = hospitalData?.admin?.email;
  // console.log(hospitalData, "previousEmail", hospitalData?.admin?.email);
  const previousUniqueIdentifier =
    hospitalData?.hospitals && hospitalData?.hospitals[0]?.unique_identifier;
  const previousPhoneNumber = hospitalData?.admin?.phone_number;
  const previousAssignedRole =
    hospitalData?.admin && hospitalData?.admin?.role
      ? hospitalData?.admin?.role
      : hospitalData?.hospitals
      ? hospitalData?.hospitals[0]?.assigned_role
      : "";
  const isHosptialNameChanged = previousHospitalName
    ? previousHospitalName
    : "" !== hospitalName;
  const isZipcodeChanged = previousZipcode
    ? previousZipcode
    : "" !== zipcodeOnchange;
  const isEmailChanged = previousEmail ? previousEmail : "" !== emailOnchange;
  const isUniqueIdentifierChanged = previousUniqueIdentifier
    ? previousUniqueIdentifier
    : "" !== uniqueIdentiferOnchange;
  const isPhoneChanged = previousPhoneNumber
    ? previousPhoneNumber
    : "" !== phoneNumberchange;
  // useEffect(() => {
  // //  dispatch()
  // }, [isHosptialNameChanged,isZipcodeChanged,isEmailChanged,isUniqueIdentifierChanged,isPhoneChanged]);
  const formSchema = {
    name: Yup.string()
      .required("Name is required")
      .test({
        message: "This name is already taken",
        test: async (value) => {
          if (hospitalName !== "" && isHosptialNameChanged) {
            const checkFieldName = `/hospital?name=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    region: Yup.string(),
    id: Yup.string(),
    fid: Yup.string(),
    // region: Yup.string(),
    // region: Yup.string(),
    address: Yup.string().required("Address is required"),
    //locationData: Yup.string().required("Location data is required"),
    unique_identifier: Yup.string()
      .required("NetSuite Customer Number is required")
      .test({
        message: "This NetSuite Customer Number is already taken",
        test: async (value) => {
          if (uniqueIdentiferOnchange !== "" && isUniqueIdentifierChanged) {
            const checkFieldName = `/hospital?unique_identifier=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    zipcode: Yup.string()
      .required("Zipcode is required")
      .test({
        message: "This zipcode do not exist",
        test: async (value) => {
          if (value) {
            // console.log("zipcode-----", zipcodeOnchange, isZipcodeChanged);
            // const checkFieldName = `/hospital?zipcode=${value}`;
            // const payload = {
            //   method: "GET",
            //   url: `${apiEndPoint}${checkFieldName}`,
            // };
            // const response = await doHttpCall(payload);
            // const returnStatus = response.status === 200 ? true : false;
            return true;
          } else {
            return true;
          }
        },
      }),
  };
  const formAdminSchema = {
    firstname: Yup.string()
      .nullable(true)
      .trim()
      .required("First Name is required"),
    lastname: Yup.string()
      .nullable(true)
      .trim()
      .required("Last Name is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(emailValidationRegex, "Invalid email")
      .test({
        message: "This email is already taken",
        test: async (value) => {
          if (emailOnchange !== "" && isEmailChanged) {
            const checkFieldName = `/user/validate?mail=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(phoneValidationRegex, "Invalid phone number")
      .test({
        message: "This phone number is already taken",
        test: async (value: any) => {
          const phoneNum: any = value.replace(/\+/g, "%2B");
          if (phoneNumberchange !== "" && isPhoneChanged) {
            const checkFieldName = `/user/validate?phone_number=${phoneNum}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    role: Yup.string().required("Assigned role is required"),
  };
  const validationSchema = Yup.object().shape({
    hospitals: Yup.array()
      .of(Yup.object().shape(formSchema))
      .required("Must have fields")
      .min(1, "Minimum of 1 field"),
    admin: Yup.object().shape(formAdminSchema),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    clearErrors,
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm(formOptions);
  // } = useForm();
  const { fields, append, remove } = useFieldArray({
    name: "hospitals",
    control,
  });
  const prefixZero = (num: Number) => {
    return ("0" + num).slice(-2);
  };
  const hopsitalValidate = useSelector((state: AppState) => {
    return state?.homeReducerData?.hopsitalValidate?.errorData;
  });
  const locationData = useSelector((state: AppState) => {
    return state?.homeReducerData?.locationData?.data;
  });
  // console.log(locationData, "locationData----");
  const addFacility = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
    const copyHospitals: any = newHospitals;
    copyHospitals.push("");
    setHospitals(copyHospitals);
  };
  const deleteFacility = async (facility_id: any, index: any) => {
    setDeleteFacilityLoader(true);
    const payload = {
      method: "DELETE",
      url: apiEndPoint + `/hospitals/facilities/${facility_id}`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      removeFromAryList(index);
      setDeleteFacilityLoader(false);
      setDeleteFacilitiesModalOpen(false);
    } else {
      setDeleteFacilityLoader(false);
      setDeleteFacilitiesModalOpen(false);
      setErrorFacilityDelete(response);
      setDeleteErrorIndex(index);
      setTimeout(() => {
        setErrorFacilityDelete({});
        setDeleteErrorIndex("");
      }, 4000);
    }
  };
  const removeFromAryList = (index: any) => {
    // setIndexes((prevIndexes) => [
    //   ...prevIndexes.filter((item) => item !== index),
    // ]);
    // setCounter((prevCounter) => prevCounter - 1);
    const values = getValues();
    const newValues: any = [...values.hospitals];
    newValues.splice(index + 1, 1);
    if (errors && errors.hospitals && errors.hospitals.length) {
      errors.hospitals.splice(index + 1, 1);
    }
    values?.hospitals.splice(index + 1, 1);
    showCreateHospital && hospitalData?.hospitals.splice(index + 1, 1);
    const newIndex = indexes.splice(index + 1, 1);
    setIndexes(newIndex);
    setHospitals(newValues);
    for (let i = 0; i < newValues.length; i++) {
      setValue(`hospitals[${i}].name`, newValues[i].name);
      setValue(`hospitals[${i}].address`, newValues[i].address);
      setValue(`hospitals[${i}].address2`, newValues[i].address2);
      setValue(
        `hospitals[${i}].unique_identifier`,
        newValues[i].unique_identifier
      );
      setValue(`hospitals[${i}].zipcode`, newValues[i].zipcode);
      setValue(`hospitals[${i}].city`, newValues[i].city);
      setValue(`hospitals[${i}].state`, newValues[i].state);
      setValue(`hospitals[${i}].country`, newValues[i].country);
      setValue(`hospitals[${i}].region`, newValues[i].region);
    }
    if (newValues.length < getValues().hospitals.length) {
      for (let i = newValues.length; getValues().hospitals.length > i; i++) {
        !getValues()?.hospitals[i]?.name && remove(i);
      }
    }
    showCreateHospital &&
      setExistingFacilities(hospitalData?.hospitals?.length > 1 ? true : false);
    setIsChangeFacilities(true);
  };
  const removeFacility =
    (index: any = null) =>
    async () => {
      const values = getValues();
      const getDeletedRecord = values.hospitals.find(
        (hospital: any, i: any) => {
          return index + 1 === i;
        }
      );
      if (getDeletedRecord && getDeletedRecord.fid) {
        setDeleteFacilityLoader(true);
        await deleteFacility(getDeletedRecord.fid, index);
      } else {
        setDeleteFacilityLoader(false);
        setDeleteFacilitiesModalOpen(false);
        removeFromAryList(index);
      }
    };
  const showFacilityFn = (event: any) => {
    // console.log(gridLayout && gridLayout, indexes?.length, "gridLayout");
    setgridLayout(true);
    const forms = getValues();
    // console.log(indexes, forms, indexes?.length, "index");
    forms?.hospitals?.map((hospital: any, index: any) => {
      // console.log(
      //   index,
      //   index !== 0 && forms?.hospitals?.splice(index, 1),
      //   "hospital-index"
      // );
      // console.log(forms, "hospital-index-----");
      index !== 0 && forms?.hospitals?.splice(index, 1);
    });
    // showCreateHospital && console.log(hospitalData?.hospitals?.splice(1),"hospitalData");
    hospitalData?.hospitals?.splice(1, hospitalData?.hospitals?.length);
    // if (event.target.checked == true) {
    //   let hospitals: any = getValues();
    //   hospitals && hospitals.hospitals.push("");
    //   setHospitals(hospitals.hospitals);
    // }
    if (event.target.checked == true) {
      const ary = [];
      // console.log(getValues(),'getValues21')
      if (showCreateHospital) {
        for (
          let index = 0;
          index < hospitalData?.hospitals?.length + 1;
          index++
        ) {
          ary.push("");
        }
        setHospitals(ary);
      } else {
        for (let index = 0; index < 2; index++) {
          ary.push("");
        }
        setHospitals(ary);
      }
      setValue(`hospitals[${1}].name`, "");
      setValue(`hospitals[${1}].address`, "");
      setValue(`hospitals[${1}].address2`, "");
      setValue(`hospitals[${1}].unique_identifier`, "");
      setValue(`hospitals[${1}].zipcode`, "");
      setValue(`hospitals[${1}].city`, "");
      setValue(`hospitals[${1}].state`, "");
      setValue(`hospitals[${1}].country`, "");
      setValue(`hospitals[${1}].region`, "");
    }
    if (event.target.checked == false && !existingFacilities) {
      setHospitals([""]);
    }
    if (indexes?.length === 0) {
      setIndexes([0]);
      // let ary: any = newHospitals;
      // ary.push('');
      // setHospitals(ary);
    }
  };
  // const getLocationWithDebounce = useCallback(
  //   debounce((e: any) => {
  //     dispatch(getLocationWithZipcode(e));
  //   }, 2000),
  //   []
  // );
  const getLocationWithDebounce = useCallback(
    debounce(async (e: any, index: any) => {
      setIsChangeFacilities(true);
      //  dispatch(validateForm(e,'zipcode'))
      const response: any = await dispatch(getLocationWithZipcode(e));
      if (response && response.status === 200) {
        setValue(`hospitals.${0}.region`, response?.data?.region_name);
        setValue(`hospitals.${0}.state`, response?.data?.state_name);
        setValue(`hospitals.${0}.country`, response?.data?.country_name);
        setValue(`hospitals.${0}.city`, response?.data?.city_name);
        const obj: any = checkValidZipCode || {};
        obj[index] = { isvalid: true, message: "" };
        setCheckValidZipCode(obj);
      } else if ((response && response.status === 400) || 401) {
        const obj: any = checkValidZipCode || {};
        obj[index] = {
          isvalid: false,
          message: `${response?.data[0]?.details}`,
        };
        setCheckValidZipCode(obj);
        setValue(`hospitals.${0}.region`, "");
        setValue(`hospitals.${0}.state`, "");
        setValue(`hospitals.${0}.country`, "");
        setValue(`hospitals.${0}.city`, "");
      }
    }, 2000),
    []
  );

  function checkZipcodevalid() {
    const zipcodeData = checkValidZipCode;
    let count = 0;
    zipcodeData &&
      (Object.keys(zipcodeData) as (keyof typeof zipcodeData)[]).forEach(
        (key, index) => {
          if (!zipcodeData[key].isvalid) {
            count = count + 1;
          }
        }
      );
    return count;
  }
  useEffect(() => {
    dispatch(getRolesList(1, "CLIENT", "", 1000, "HospitalAdmin"));
  }, []);
  let hospitalPermissionOptions: any = [{ value: "", label: "" }];
  const roles = useSelector((state: AppState) => {
    return state.managementReducerData.rolesList.data;
  });
  const rolesOptions =
    roles &&
    roles?.results?.map((role: any) => ({
      value: role?.name,
      label: role?.name,
    }));
  hospitalPermissionOptions = rolesOptions && rolesOptions;
  const onSubmit = (data: any) => {
    // handleValidateForm();
    // if (!zipDataAvailable) return;
    if (checkZipcodevalid() > 0) {
      return;
    }
    const facilities: any[] = [];
    data?.hospitals.map((hospital: any, i: any) => {
      const one =
        i == 0 && hospital?.is_main_branch
          ? ""
          : Object.assign(hospital, {
              is_main_branch: true,
            });
      const two =
        i >= 1
          ? !hospital?.is_main_branch
            ? ""
            : Object.assign(hospital, { is_main_branch: false })
          : "";
      if (!hospital.is_main_branch) {
        facilities.push(hospital);
      } else {
        hospital.facilities = facilities;
      }
      i === 0
        ? Object.assign(hospital, {
            address: hospital?.address + "--" + hospital?.address2,
            state: stateNameHospitalDefault,
            city: cityNameHospitalDefault,
            country: countryNameHospitalDefault,
          })
        : Object.assign(hospital, {
            address: hospital?.address + "--" + hospital?.address2,
            state: hospital.state,
            city: hospital.city,
            country: hospital.country,
          });
    });
    data["indexes"] = indexes;
    // data.hospitals[0].is_main_branch = true;
    hospitalData?.hospitals?.map((hospital: any, i: any) => {
      if (hospital?.devices?.length > 0 && i < data.hospitals.length) {
        data.hospitals[i].devices = hospital?.devices;
      }
      if (i < data.hospitals.length && data.hospitals[i].is_main_branch) {
        data.hospitals[i].facilities = facilities;
      }
    });
    if (data && data.hospitals) {
      data.hospitals.map((facility: any) => {
        if (facility.is_main_branch) {
          delete facility.id;
          delete facility.fid;
        } else {
          delete facility.id;
        }
        return facility;
      });
    }
    const updateHospital = { ...hospitalData, ...data };
    // console.log(locationData, updateHospital,'final');
    dispatch(increment());
    dispatch(getHospitalValidations());
    dispatch(updateActionData(updateHospital));
  };
  useEffect(() => {
    setNotFound(locationData && locationData[0]?.details);
  });
  // console.log(hospitalData?.hospitals, showCreateHospital, '(hospitalData?.hospitals || showCreateHospital)');
  useEffect(() => {
    let adminValidate;
    Object.entries(hopsitalValidate ? hopsitalValidate : {}).forEach(
      ([key, value]) => {
        if (key === "admin") {
          adminValidate = value;
        }
      }
    );
    Object.entries(adminValidate ? adminValidate : {}).forEach(
      ([key, value]: [key: any, value: any]) => {
        setError(key, {
          type: "server",
          message: value[0],
        });
      }
    );
    // console.log(adminValidate, errors, 'adminValidate');
    const showFacility =
      hospitalData?.hospitals &&
      hospitalData?.hospitals[0]?.facilities?.length > 0
        ? true
        : false;
    // console.log(showFacility, showCreateHospital, "showFacility");
    showCreateHospital && setgridLayout(showFacility);
    // console.log(hospitalData, hospitalData?.indexes)
    setIndexes(hospitalData?.indexes || []);
    dispatch(validateFormGet());
  }, []);
  useEffect(() => {
    const hospitalcount: Array<number> = [];
    const ary: any = [];
    hospitalData?.hospitals?.map((hospital: any, index: any) => {
      ary.push("");
      index >= 1 && hospitalcount.push(index - 1);
    });
    if (showCreateHospital || !hospitalData?.id) {
      if (
        hospitalData?.hospitals &&
        hospitalData?.hospitals[0]?.facilities?.length
      ) {
        setgridLayout(true);
      }
      setHospitals(ary);
      setIndexes(hospitalcount);
      // console.log(hospitalcount,hospitalData, indexes, showCreateHospital, 'showCreateHospital');
    } else if (
      hospitalData?.hospitals &&
      hospitalData?.hospitals.length &&
      hospitalData?.hospitals[0].facilities
    ) {
      setIndexes(hospitalcount);
    }
    hospitalData?.hospitals &&
      setValue("hospitals[0].region", hospitalData?.hospitals[0]?.region);
    hospitalData?.hospitals &&
      setValue(
        "hospitals[0].unique_identifier",
        hospitalData?.hospitals[0]?.unique_identifier
      );
    if (showCreateHospital && hospitalData?.hospitals?.length >= 2) {
      setExistingFacilities(true);
    }
    if (showCreateHospital && permissionEdit1 && previousAssignedRole) {
      setValue("admin.role", previousAssignedRole);
    }
  }, [hospitalData]);
  useEffect(() => {
    locationData && setValue("hospitals[0].region", locationData?.region_name);
    locationData && setValue("hospitals[0].city", locationData?.city_name);
    locationData && setValue("hospitals[0].state", locationData?.state_name);
    locationData &&
      setValue("hospitals[0].country", locationData?.country_name);
  }, [locationData]);
  const addressName =
    hospitalData?.hospitals && hospitalData?.hospitals[0]?.address?.split("--");
  const addressNameSplit = addressName && addressName[0];
  const addressNameSplit2 = addressName && addressName[1];
  // console.log(addressNameSplit, "addressNameSplitaddressNameSplit");
  // const stateNameHospitalDefault = locationData?.state_name
  //   ? locationData?.state_name
  //   : hospitalData?.hospitals && hospitalData?.hospitals[0]?.state;
  // const cityNameHospitalDefault = locationData?.city_name
  //   ? locationData?.city_name
  //   : hospitalData?.hospitals && hospitalData?.hospitals[0]?.city;
  // const regionNameHospitalDefault = locationData?.region_name
  //   ? locationData?.region_name
  //   : hospitalData?.hospitals && hospitalData?.hospitals[0]?.region;
  // const countryNameHospitalDefault = locationData?.country_name
  //   ? locationData?.country_name
  //   : hospitalData?.hospitals && hospitalData?.hospitals[0]?.country;

  const latestData = isChangeFacilities
    ? getValues().hospitals[0]
    : hospitalData?.hospitals && hospitalData?.hospitals[0];
  const stateNameHospitalDefault = latestData?.state;
  const cityNameHospitalDefault = latestData?.city;
  const regionNameHospitalDefault = latestData?.region;
  const countryNameHospitalDefault = latestData?.country;

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessage;
  });
  const loadingState = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessage?.loadingState;
  });
  useEffect(() => {
    if (!showCreateHospital && !hospitalData?.hospitals?.length) {
      const ary: any = newHospitals;
      ary.push("");
      setHospitals(ary);
    }
  }, [showCreateHospital]);
  const cancelIcon: IIconProps = { iconName: "Cancel" };

  const showDeleteHospitalFacilityModal =
    (index: any = null) =>
    () => {
      setFacilityDeleteId(index);
      setDeleteFacilitiesModalOpen(!isDeleteFacilitiesModalOpen);
    };
  function checkForDisabled() {
    if (
      existingFacilities &&
      showCreateHospital &&
      hospitalData?.hospitals?.length > 1
    ) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_ROLES_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleRoleList(true);
    }

    if (
      loaderWithMessage?.calledFrom === "GET_ROLES_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleRoleList(false);
    }
  }, [loaderWithMessage]);

  const getAssignedRolePlaceHolderValue = (previousAssignedRole: any) => {
    // console.log(previousAssignedRole, "previousAssignedRole");
    if (previousAssignedRole) {
      return previousAssignedRole;
    } else if (!previousAssignedRole && visibleRoleList) {
      return "Select from the list";
    } else if (!previousAssignedRole && !visibleRoleList) {
      return "You do not have permission";
    } else {
      return "Select from the list";
    }
  };

  useEffect(() => {
    setValue(`admin.role`, previousAssignedRole);
  }, [previousAssignedRole]);

  {
    // console.log(showCreateHospital, "dffdfdf", visibleRoleList);
  }

  return (
    <Stack className="addHospital">
      {(hospitalData?.hospitals || !showCreateHospital) && (
        <form
          id="myForm"
          className={loaderWithMessage?.loadingState ? "point-events-none" : ""}
          onSubmit={handleSubmit(onSubmit)}
        >
          <>
            <ToggleCard
              heading="Main Hospital Details"
              index=""
              removeFormFields=""
            >
              <Stack className="InputSection ms-Grid-row">
                <Stack.Item className="ms-Grid-row">
                  <Stack.Item className="ms-Grid-col ms-lg6">
                    <Stack.Item className="ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3 firstSection">
                        {/* <TextField
                          autoComplete="off"
                          label="Name of the Hospital*"
                          errorMessage={
                            errors?.hospitals
                              ? errors?.hospitals[0]?.name?.message
                              : ""
                          }
                          {...register(`hospitals[0].name`)}
                          onChange={(e: any) => {
                            setHospitalName(e?.target?.value);
                          }}
                          defaultValue={previousHospitalName}
                        /> */}
                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom display-none">
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register(`hospitals[0].id`)}
                              defaultValue={
                                hospitalData?.hospitals &&
                                hospitalData?.hospitals[0]?.id
                              }
                            />
                          </Stack>
                        </Stack>

                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom display-none">
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register(`hospitals[0].fid`)}
                              defaultValue={
                                hospitalData?.hospitals &&
                                hospitalData?.hospitals[0]?.fid
                              }
                            />
                          </Stack>
                        </Stack>
                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                          <label className="ms-Label root-custom">
                            Name of the Hospital*
                          </label>
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register(`hospitals[0].name`)}
                              onChange={(e: any) => {
                                setHospitalName(e?.target?.value);
                                // errors.hospitals[0].name.message = "";
                                if (e?.target.value?.trim().length) {
                                  clearErrors(`hospitals[0].name`);
                                }
                              }}
                              defaultValue={previousHospitalName}
                            />
                          </Stack>
                          <Label className="errorMessage">
                            {errors?.hospitals
                              ? errors?.hospitals[0]?.name?.message
                              : ""}
                          </Label>
                        </Stack>
                        {/* <div >{errors?.hospitals ? errors?.hospitals[0]?.name?.message : "dfdsf"}</div> */}
                        {/* <input
                      type="hidden"
                      {...register("hospitals[0].is_main_branch")}
                      value="true"
                    /> */}
                        {/* <TextField
                          autoComplete="off"
                          label="Address 1*"
                          errorMessage={
                            errors?.hospitals
                              ? errors?.hospitals[0]?.address?.message
                              : ""
                          }
                          {...register("hospitals[0].address")}
                          defaultValue={addressNameSplit}
                        /> */}
                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                          <label className="ms-Label root-custom">
                            Address 1*
                          </label>
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register("hospitals[0].address")}
                              onChange={(e) => {
                                setAddress(e?.target?.value);
                                // errors.hospitals[0].address.message = "";
                                if (e?.target.value?.trim().length) {
                                  clearErrors(`hospitals[0].address`);
                                }
                              }}
                              defaultValue={addressNameSplit}
                            />
                          </Stack>
                          <Label className="errorMessage">
                            {errors?.hospitals
                              ? errors?.hospitals[0]?.address?.message
                              : ""}
                          </Label>
                        </Stack>
                        {/* <TextField
                          autoComplete="off"
                          label="Address 2"
                          errorMessage={
                            errors?.hospitals
                              ? errors?.hospitals[0]?.address2?.message
                              : ""
                          }
                          {...register("hospitals[0].address2")}
                          defaultValue={
                            hospitalData?.hospitals &&
                            hospitalData?.hospitals[0]?.address2
                          }
                        /> */}
                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                          <label className="ms-Label root-custom">
                            Address 2
                          </label>
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register("hospitals[0].address2")}
                              defaultValue={addressNameSplit2}
                            />
                          </Stack>
                          <Label className="errorMessage">
                            {errors?.hospitals
                              ? errors?.hospitals[0]?.address2?.message
                              : ""}
                          </Label>
                        </Stack>
                        <Stack.Item key={cityNameHospitalDefault}>
                          {/* <TextField
                            autoComplete="off"
                            label="City"
                            disabled
                            errorMessage={
                              errors?.hospitals
                                ? errors?.hospitals[0]?.Country?.message
                                : ""
                            }
                            name="city"
                            // onChange={(e: any) => {
                            //   setCountryOnchange(e?.target?.value);
                            // }}
                            // onChange={(e) => handleValidateForm(e, "Country")}
                            defaultValue={cityNameHospitalDefault}
                            value={cityNameHospitalDefault}
                          /> */}
                          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                            <label className="ms-Label root-custom">City</label>
                            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                              <input
                                className="ms-TextField-field field-custom"
                                type="text"
                                autoComplete="off"
                                disabled
                                name="city"
                                // onChange={(e: any) => {
                                //   setCountryOnchange(e?.target?.value);
                                // }}
                                // onChange={(e) => handleValidateForm(e, "Country")}
                                // defaultValue={cityNameHospitalDefault}
                                defaultValue={cityNameHospitalDefault || ""}
                              />
                            </Stack>
                            <Label className="errorMessage">
                              {errors?.hospitals
                                ? errors?.hospitals[0]?.Country?.message
                                : ""}
                            </Label>
                          </Stack>
                        </Stack.Item>
                        <Stack.Item key={locationData?.state_name}>
                          {/* <TextField
                            autoComplete="off"
                            label="State"
                            disabled
                            errorMessage={
                              errors?.hospitals
                                ? errors?.hospitals[0]?.Country?.message
                                : ""
                            }
                            name="state"
                            // onChange={(e: any) => {
                            //   setCountryOnchange(e?.target?.value);
                            // }}
                            // onChange={(e) => handleValidateForm(e, "Country")}
                            defaultValue={stateNameHospitalDefault}
                          /> */}
                          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                            <label className="ms-Label root-custom">
                              State
                            </label>
                            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                              <input
                                className="ms-TextField-field field-custom"
                                type="text"
                                autoComplete="off"
                                disabled
                                name="state"
                                // onChange={(e: any) => {
                                //   setCountryOnchange(e?.target?.value);
                                // }}
                                // onChange={(e) => handleValidateForm(e, "Country")}
                                value={stateNameHospitalDefault || ""}
                              />
                            </Stack>
                            <Label className="errorMessage">
                              {errors?.hospitals
                                ? errors?.hospitals[0]?.Country?.message
                                : ""}
                            </Label>
                          </Stack>
                        </Stack.Item>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="ms-Grid-col ms-lg6">
                    <Stack.Item className="ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset1">
                        {/* <TextField
                          autoComplete="off"
                          label="NetSuite Customer Number*"
                          errorMessage={
                            errors?.hospitals
                              ? errors?.hospitals[0]?.unique_identifier?.message
                              : ""
                          }
                          {...register("hospitals[0].unique_identifier")}
                          onChange={(e: any) => {
                            setUniqueIdentiferOnchange(e?.target?.value);
                          }}
                          // onChange={(e) =>
                          //   handleValidateForm(e, "unique_identifier")
                          // }
                          defaultValue={previousUniqueIdentifier}
                          disabled={showCreateHospital}
                        /> */}
                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                          <label className="ms-Label root-custom">
                            NetSuite Customer Number*
                          </label>
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register("hospitals[0].unique_identifier")}
                              onChange={(e: any) => {
                                setUniqueIdentiferOnchange(e?.target?.value);
                                // errors.hospitals[0].unique_identifier.message =
                                //   "";
                                if (e?.target.value?.trim().length) {
                                  clearErrors(`hospitals[0].unique_identifier`);
                                }
                              }}
                              // onChange={(e) =>
                              //   handleValidateForm(e, "unique_identifier")
                              // }
                              //value={previousUniqueIdentifier}
                              defaultValue={previousUniqueIdentifier}
                              disabled={showCreateHospital}
                            />
                          </Stack>
                          <Label className="errorMessage">
                            {errors?.hospitals
                              ? errors?.hospitals[0]?.unique_identifier?.message
                              : ""}
                          </Label>
                        </Stack>
                        <Stack.Item key={regionNameHospitalDefault}>
                          {/* <TextField
                            autoComplete="off"
                            label="Region"
                            disabled
                            errorMessage={
                              errors?.hospitals
                                ? errors?.hospitals[0]?.region?.message
                                : ""
                            }
                            // {...register("hospitals[0].region")}
                            name="region"
                            // onChange={(e: any) => {
                            //   setCountryOnchange(e?.target?.value);
                            // }}
                            // onChange={(e) => handleValidateForm(e, "Country")}
                            defaultValue={regionNameHospitalDefault}
                          /> */}
                          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                            <label className="ms-Label root-custom">
                              Region
                            </label>
                            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                              <input
                                className="ms-TextField-field field-custom"
                                type="text"
                                autoComplete="off"
                                disabled
                                // {...register("hospitals[0].region")}
                                name="region"
                                // onChange={(e: any) => {
                                //   setCountryOnchange(e?.target?.value);
                                // }}
                                // onChange={(e) => handleValidateForm(e, "Country")}
                                value={regionNameHospitalDefault || ""}
                              />
                            </Stack>
                            <Label className="errorMessage">
                              {errors?.hospitals
                                ? errors?.hospitals[0]?.region?.message
                                : ""}
                            </Label>
                          </Stack>
                        </Stack.Item>
                        {/* <TextField
                          autoComplete="off"
                          label="Zipcode*"
                          errorMessage={
                            errors?.hospitals
                              ? errors?.hospitals[0]?.zipcode?.message
                              : ""
                          }
                          {...register("hospitals[0].zipcode")}
                          onChange={(e: any) => {
                            const zipcodeVal = e?.target?.value;
                            setZipcodeOnchange(zipcodeVal);
                            e?.target?.value?.length >= 2 &&
                              getLocationWithDebounce(zipcodeVal);
                          }}
                          // onChange={(e) => handleValidateForm(e, "zipcode")}
                          defaultValue={previousZipcode}
                        /> */}
                        <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                          <label className="ms-Label root-custom">
                            Zipcode*
                          </label>
                          <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                            <input
                              className="ms-TextField-field field-custom"
                              type="text"
                              autoComplete="off"
                              {...register("hospitals[0].zipcode")}
                              onChange={(e: any) => {
                                const zipcodeVal = e?.target?.value;
                                setZipcodeOnchange(zipcodeVal);
                                e?.target?.value?.length >= 3 &&
                                  getLocationWithDebounce(zipcodeVal, 0);
                                // errors.hospitals[0].zipcode.message = "";
                                if (e?.target.value?.trim().length) {
                                  clearErrors(`hospitals[0].zipcode`);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (event.key.replace(/[0-9]/, "")) {
                                  event.preventDefault();
                                }
                              }}
                              // onChange={(e) => handleValidateForm(e, "zipcode")}
                              defaultValue={previousZipcode}
                            />
                          </Stack>
                          <Label className="errorMessage">
                            {errors?.hospitals
                              ? errors?.hospitals[0]?.zipcode?.message
                              : ""}
                          </Label>
                          {/* <Label className="errorMessage">{notFound}</Label> */}
                          <Label className="errorMessage">
                            {checkValidZipCode &&
                            Object.keys(checkValidZipCode).length &&
                            !checkValidZipCode[0]?.isValid
                              ? checkValidZipCode[0]?.message
                              : " "}
                          </Label>
                        </Stack>
                        <Stack.Item key={locationData?.country_name}>
                          {/* <TextField
                            autoComplete="off"
                            label="Country"
                            disabled
                            errorMessage={
                              errors?.hospitals
                                ? errors?.hospitals[0]?.Country?.message
                                : ""
                            }
                            // {...register("hospitals[0].country")}
                            name="country"
                            // onChange={(e: any) => {
                            //   setCountryOnchange(e?.target?.value);
                            // }}
                            // onChange={(e) => handleValidateForm(e, "Country")}
                            defaultValue={countryNameHospitalDefault}
                          /> */}
                          <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                            <label className="ms-Label root-custom">
                              Country
                            </label>
                            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                              <input
                                className="ms-TextField-field field-custom"
                                type="text"
                                autoComplete="off"
                                disabled
                                // {...register("hospitals[0].country")}
                                name="country"
                                // onChange={(e: any) => {
                                //   setCountryOnchange(e?.target?.value);
                                // }}
                                // onChange={(e) => handleValidateForm(e, "Country")}
                                value={countryNameHospitalDefault || ""}
                              />
                            </Stack>
                            <Label className="errorMessage">
                              {errors?.hospitals
                                ? errors?.hospitals[0]?.Country?.message
                                : ""}
                            </Label>
                          </Stack>
                        </Stack.Item>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack>
              <Stack.Item className="InputSection ms-Grid-row">
                <Text className="InputSection sectionHeading sectionSubHeading">
                  Hospital Admin Details
                </Text>
                <Stack.Item className="ms-Grid-row">
                  <Stack.Item className="ms-Grid-col ms-lg6">
                    <Stack.Item className="ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3">
                        <TextField
                          autoComplete="off"
                          label="First Name of the User*"
                          errorMessage={
                            errors?.admin
                              ? errors?.admin.firstname?.message
                              : ""
                          }
                          {...register("admin.firstname")}
                          onChange={(e: any) => {
                            // errors.admin.firstname.message = "";
                            if (e?.target.value?.trim().length) {
                              clearErrors(`admin.firstname`);
                            }
                          }}
                          defaultValue={hospitalData?.admin?.firstname}
                          disabled={showCreateHospital}
                        />
                        <TextField
                          autoComplete="off"
                          errorMessage={
                            errors?.admin ? errors?.admin.lastname?.message : ""
                          }
                          label="Last Name*"
                          {...register("admin.lastname")}
                          onChange={(e: any) => {
                            // errors.admin.lastname.message = "";
                            if (e?.target.value?.trim().length) {
                              clearErrors(`admin.lastname`);
                            }
                          }}
                          defaultValue={hospitalData?.admin?.lastname}
                          disabled={showCreateHospital}
                        />
                        <TextField
                          autoComplete="off"
                          label="Email address*"
                          errorMessage={
                            errors?.admin ? errors?.admin.email?.message : ""
                          }
                          {...register("admin.email")}
                          onChange={(e: any) => {
                            // errors.admin.email.message = "";
                            setEmailOnchange(e?.target.value);
                            if (e?.target.value?.trim().length) {
                              clearErrors(`admin.email`);
                            }
                          }}
                          // onChange={(e) => handleValidateForm(e, "mail")}
                          defaultValue={previousEmail}
                          disabled={showCreateHospital}
                        />
                        {/* {validationCheck.phone_number ? "true" : "fasle---"} */}
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="ms-Grid-col ms-lg6">
                    <Stack.Item className="ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset1">
                        <TextField
                          autoComplete="off"
                          label="Phone Number*"
                          onKeyPress={(event) => {
                            if (!/[0-9\d\-]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          errorMessage={
                            errors?.admin
                              ? errors?.admin.phone_number?.message
                              : ""
                          }
                          {...register("admin.phone_number")}
                          onChange={(e: any) => {
                            // errors.admin.phone_number.message = "";
                            setPhoneNumberchange(e?.target.value);
                            if (e?.target.value?.trim().length) {
                              clearErrors(`admin.phone_number`);
                            }
                          }}
                          // onChange={(e) =>
                          //   handleValidateForm(e, "phone_number")
                          // }
                          defaultValue={previousPhoneNumber}
                          disabled={showCreateHospital}
                        />
                        {/* <TextField
                          autoComplete="off"
                          label="Assigned Role"
                          disabled
                          defaultValue="HospitalAdmin"
                          errorMessage={
                            errors?.admin ? errors?.admin.role?.message : ""
                          }
                          {...register("admin.role")}
                        /> */}

                        <Stack.Item
                          className="label"
                          style={{ marginTop: "10px" }}
                          // key={}
                        >
                          <Text>Assigned Role*</Text>
                        </Stack.Item>

                        <Controller
                          control={control}
                          defaultValue={
                            permissionEdit1 ? previousAssignedRole : ""
                          }
                          {...register("admin.role")}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              {...register("admin.role")}
                              classNamePrefix="addl-class"
                              options={
                                hospitalPermissionOptions &&
                                hospitalPermissionOptions
                              }
                              isDisabled={
                                showCreateHospital
                                  ? true
                                  : !visibleRoleList
                                  ? true
                                  : false
                                // showCreateHospital ||
                                // showCreateHospital == null ||
                                // showCreateHospital === "" ||
                                // !visibleRoleList
                              }
                              // defaultValue={
                              //   hospitalPermissionOptions &&
                              //   hospitalPermissionOptions[0]?.label
                              // }
                              value={
                                hospitalPermissionOptions &&
                                hospitalPermissionOptions?.find(
                                  (c: any) => c.value === previousAssignedRole
                                )
                              }
                              placeholder={getAssignedRolePlaceHolderValue(
                                previousAssignedRole
                              )}
                              onChange={(val) => {
                                onChange(val?.value);
                              }}
                            />
                          )}
                        />
                        <Label className="errorMessage">
                          {errors?.admin ? errors?.admin.role?.message : ""}
                        </Label>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </ToggleCard>
            <Stack.Item className="ms-Grid" dir="ltr">
              <Stack.Item className="ms-Grid-col ms-lg6">
                <Stack.Item className="ms-Grid-row">
                  <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3">
                    <Stack
                      horizontal
                      verticalAlign="center"
                      className="addFacility"
                    >
                      <Text className="addFacilityHeading">
                        Add Other facility of the same hospital
                      </Text>
                      <Controller
                        control={control}
                        defaultValue={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[0]?.facilities?.length
                            ? true
                            : false
                        }
                        name="show_facility"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={
                                hospitalData?.hospitals?.length > 1
                                  ? true
                                  : newHospitals.length > 1
                                  ? true
                                  : false
                              }
                              onChange={onChange}
                              onClick={(event: any) => {
                                showFacilityFn(event);
                              }}
                              disabled={checkForDisabled()}
                            />
                            <div
                              className={
                                checkForDisabled()
                                  ? "slider disabled-slider"
                                  : "slider"
                              }
                            ></div>
                          </label>
                        )}
                      />
                    </Stack>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
            {/* <Stack className="InputSection ms-Grid-row"></Stack> */}
            {(gridLayout ||
              (hospitalData?.hospitals &&
                hospitalData?.hospitals[0]?.facilities?.length > 0)) && (
              <Stack.Item>
                {newHospitals.length &&
                  newHospitals
                    .slice(1, newHospitals.length)
                    .map((i: any, index: any) => {
                      const fieldName = `hospitals[${index + 1}]`;
                      // var responseLocationFacility: any = {};
                      return (
                        <ToggleCard
                          heading="Facility"
                          key={index}
                          index={index}
                          removeFormFields={showDeleteHospitalFacilityModal}
                        >
                          <fieldset
                            name={fieldName}
                            key={fieldName}
                            className="fieldsetInputSection"
                          >
                            {Object.keys(errorFacilityDelete).length > 0 &&
                              deleteErrorIndex == index && (
                                <ShowErrorMessageBar
                                  errorResponse={errorFacilityDelete}
                                ></ShowErrorMessageBar>
                              )}
                            <AddFacility
                              key={hospitalData}
                              errors={errors}
                              fieldName={fieldName}
                              register={register}
                              i={index}
                              hospitalData={hospitalData}
                              control={control}
                              setValue={setValue}
                              getValues={getValues}
                              isChangeFacilities={isChangeFacilities}
                              setZipDataAvailable={setZipDataAvailable}
                              setIsChangeFacilities={setIsChangeFacilities}
                              setCheckValidZipCode={setCheckValidZipCode}
                              checkValidZipCode={checkValidZipCode}
                              clearErrors={clearErrors}
                              showCreateHospital={showCreateHospital}
                            ></AddFacility>
                          </fieldset>
                        </ToggleCard>
                      );
                    })}
                {newHospitals.length && newHospitals.length > 1 && (
                  <Stack
                    horizontal
                    horizontalAlign="end"
                    verticalAlign="center"
                    className="pointer addAnotherFacility"
                  >
                    <Text onClick={addFacility}>Add Another Facility </Text>

                    <AddFacilityIcon onClick={addFacility} />
                  </Stack>
                )}
              </Stack.Item>
            )}
          </>
        </form>
      )}
      {loadingState && <Loader></Loader>}
      {/* <LoaderWithMessage
        // loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage> */}
      <DeleteModal
        disabledCreate={deleteFacilityLoader}
        isModalOpen={isDeleteFacilitiesModalOpen}
        showModal={showDeleteHospitalFacilityModal()}
        deleteHandle={removeFacility(facilityDeleteId)}
        id={facilityDeleteId}
        name="Hospital-Facility"
        zIndex={true}
      ></DeleteModal>
    </Stack>
  );
};
export default AddHospital;
