import * as React from "react";
import { Route, Routes } from "react-router-dom";
import HospitalAdminActivity from "../../HospitalAdminModule/Activity/containers/HospitalAdminActivity";
import Activity from "../../HospitalAdminModule/Activity/containers/Activity";
import Home from "../../HospitalAdminModule/Home/containers/Home";
import InProgress from "../../HospitalAdminModule/InProgress/containers/InProgress";
import { Favorites } from "../../HospitalAdminModule/Favorites/containers/Favorites";
import Management from "../../HospitalAdminModule/Management/containers/Management";
import Logs from "../../HospitalAdminModule/Logs/containers/Logs";
import FAQ from "../../HospitalAdminModule/FAQ/containers/FAQ";
import Privileges from "../../HospitalAdminModule/Management/components/Privileges/Privileges";
import Speciality from "../../HospitalAdminModule/Management/components/Speciality/Speciality";
import Facility from "../../HospitalAdminModule/Management/components/Facility/Facility";
import Devices from "../../HospitalAdminModule/Management/components/Devices/Devices";
import VideoPlayerNew1 from "../../VideoPlayer/containers/VideoPlayerNew1";
import SurgeonView from "../../HospitalAdminModule/Home/components/SurgeonView/SurgeonView";
import AdminView from "../../HospitalAdminModule/Home/components/AdminView/AdminView";

export interface Props {}
const HospitalAdminRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<SurgeonView />} />
      <Route path="/admin/:id" element={<AdminView />} />
      <Route path="/inprogress" element={<InProgress />} />
      <Route path="video/:id/:surgeonId" element={<VideoPlayerNew1 />} />

      <Route path="/management" element={<Management />} />
      <Route path="/management/privileges" element={<Privileges />} />
      <Route path="/management/speciality" element={<Speciality />} />
      <Route path="/management/facilities" element={<Facility />} />
      <Route path="/management/devices" element={<Devices />} />
      <Route path="/logs" element={<Logs />} />
      <Route path="/activity" element={<HospitalAdminActivity />} />
      <Route path="/help" element={<FAQ />} />
    </Routes>
  );
};

export default HospitalAdminRoutes;
