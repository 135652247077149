import { Stack, Text, IContextualMenuProps, IIconProps } from "@fluentui/react";
import * as React from "react";
import { ReactComponent as BookmarkIcon } from "../../../../assets/Home/bookmark.svg";
import { useSelector } from "react-redux";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import "./HospitalCard.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as constants from "../../../../Layout/constants/constants";
import CheckPermission from "../../../../common/permissions/rolePermissions";

function _onMenuClick(ev?: React.SyntheticEvent<any>) {}
const addIcon: IIconProps = { iconName: "MoreVertical" };
const HospitalCard = ({
  hospital,
  showDeleteHospitalModal,
  showEditModal,
  _onMenuClickSetId,
}: {
  hospital: any;
  showDeleteHospitalModal: any;
  showEditModal: any;
  _onMenuClickSetId: any;
}) => {
  const menuProps: IContextualMenuProps = {
    items: [
      // {
      //   key: "deviceAllocation",
      //   text: "Device Allocation",
      //   onClick: _onMenuClick,
      // },
      {
        key: "edit",
        text: "Edit",
        onClick: constants.getPermissionsbyItem("management.hospital.edit")
          ? showEditModal
          : null,
        title: constants.getPermissionsbyItem("management.hospital.edit")
          ? ""
          : constants.permission,
      },
      {
        key: "delete",
        text: "Delete",
        onClick: constants.getPermissionsbyItem("management.hospital.delete")
          ? showDeleteHospitalModal
          : null,
        title: constants.getPermissionsbyItem("management.hospital.delete")
          ? ""
          : constants.permission,
      },
    ],
  };
  // console.log(hospital,'hospitalhospitalhospital');
  // const HospitalCard = () => {
  // debugger;
  const {
    hid,
    name,
    unique_identifier,
    branch_hospitals,
    hospital_admin,
    total_devices,
    address,
    created_by,
    devices,
    facilities,
  } = hospital;

  const navigate = useNavigate();

  const allDevices = useSelector((state: AppState) => {
    return state?.homeReducerData?.devices?.data;
  });

  const getSerialNuber = (id: any) => {
    const device = allDevices?.results.find((device: any) => device.id === id);
    return device?.serial_number;
  };
  devices?.sort(
    (a: any, b: any) =>
      new Date(a.allocation_end).getTime() -
      new Date(b.allocation_end).getTime()
  );
  const defaultNoOfDevices = React.useCallback((devicelist: any) => {
    const totalWizardSections = 3;
    if (devicelist)
      return [
        ...Array(
          totalWizardSections -
            (devicelist?.length >= totalWizardSections
              ? totalWizardSections
              : devicelist?.length)
        ),
      ];
    return [...Array(0)];
  }, []);
  const viewHospital = () => {
    navigate(`/${hid}`);
  };
  const splitAddress1 = address.split("--");
  const splitAddress = splitAddress1.filter(function (el: any) {
    return el != "";
  });
  return (
    <Stack.Item id="hospitalCard" onClick={viewHospital}>
      <Stack.Item className="ms-Grid-row" tokens={{ padding: "0 0 28px 0" }}>
        <Stack.Item className="ms-Grid-col ms-lg9 textNoWrap">
          <Text className="hospitalCardName">
            <abbr className="ms-bb-0" title={name}>
              {name}
            </abbr>
          </Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid-col ms-lg3 hospitalCardIcon">
          <Text>
            <IconButton
              className="buttonIcon"
              menuIconProps={addIcon}
              menuProps={menuProps}
              ariaLabel="New item"
              onMenuClick={() => _onMenuClickSetId(hid)}
            />
          </Text>
        </Stack.Item>
      </Stack.Item>
      <Stack.Item className="ms-Grid-row" tokens={{ padding: "0 0 16px 0" }}>
        <Stack.Item className="ms-Grid-col ms-lg5">
          <Text block className="hospitalCardHeading textNoWrap">
            <abbr className="ms-bb-0" title="NetSuite Customer Number">
              NetSuite Customer Number
            </abbr>
          </Text>
          <Text
            block
            className="hospitalCardText hospitalCardHeading textNoWrap"
          >
            <abbr className="ms-bb-0" title={unique_identifier}>
              {unique_identifier}
            </abbr>
          </Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid-col ms-lg7">
          <Text block className="hospitalCardHeading">
            Address
          </Text>
          <Text block className="hospitalCardText textNoWrap">
            <abbr className="ms-bb-0" title={splitAddress[0]}>
              {address?.length >= 0 && splitAddress[0]}
              {splitAddress?.length > 1 && "," + splitAddress[1]}
            </abbr>
          </Text>
        </Stack.Item>
      </Stack.Item>
      <Stack.Item className="ms-Grid-row" tokens={{ padding: "0 0 16px 0" }}>
        <Stack.Item className="ms-Grid-col ms-lg5">
          <Text block className="hospitalCardHeading">
            Hospital Admin
          </Text>
          <Text block className="hospitalCardText textNoWrap">
            <abbr className="ms-bb-0" title={hospital_admin}>
              {hospital_admin}
            </abbr>
          </Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid-col ms-lg4">
          <Text block className="hospitalCardHeading">
            Other Facilities
          </Text>
          <Text block className="hospitalCardText">
            {branch_hospitals}
          </Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid-col ms-lg3">
          <Text block className="hospitalCardHeading">
            Devices
          </Text>
          <Text block className="hospitalCardText">
            {total_devices}
          </Text>
        </Stack.Item>
      </Stack.Item>
      <Stack
        tokens={{ padding: "0 0 8px 0" }}
        horizontalAlign="start"
        className="hospitalCardHeading"
      >
        Device Information
      </Stack>
      <Stack
        horizontal
        tokens={{ padding: "0 0 4px 0" }}
        className="ms-Grid-row"
      >
        <Stack.Item className="ms-Grid-col ms-lg5">
          <Text block className="hospitalCardHeading">
            Serial no
          </Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid-col ms-lg4">
          <Text block className="hospitalCardHeading">
            Allocation type
          </Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid-col ms-lg3">
          <Text block className="hospitalCardHeading">
            Date
          </Text>
        </Stack.Item>
      </Stack>
      {/* {devices.length ? devices.length : "dsfdsfdffdsfs"} */}
      {/* {devices.length ? ( */}
      <Stack>
        {devices?.slice(0, 3).map((device: any, index: any) => {
          const tempkey = device?.id + index;
          return (
            <Stack
              key={tempkey}
              horizontal
              tokens={{ padding: "0 0 4px 0" }}
              className="ms-Grid-row hospitalCardDeviceInfo "
            >
              <Stack.Item className="ms-Grid-col ms-lg5 textNoWrap">
                <Text block>
                  {device?.serial_number
                    ? device?.serial_number
                    : getSerialNuber(device?.id)}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg4 textNoWrap">
                <Text block>{device?.allocation_type}</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg3 ms-pr-0">
                <Text block>
                  {moment(device?.allocation_end).format("MM-DD-YYYY")}
                </Text>
              </Stack.Item>
            </Stack>
          );
        })}

        {defaultNoOfDevices(devices).map((defaultDevices: any, i: any) => {
          return (
            <Stack
              horizontal
              tokens={{ padding: "0 0 4px 0" }}
              className="ms-Grid-row hospitalCardDeviceInfo"
              key={i + 1}
            >
              <Stack.Item className="ms-Grid-col ms-lg5">
                <Text block>----- {defaultDevices}</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg4">
                <Text block>----</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg3 ms-pr-0">
                <Text block>-----</Text>
              </Stack.Item>
            </Stack>
          );
        })}
      </Stack>
      <Stack
        tokens={{ padding: "12px 0 0 0" }}
        horizontalAlign="start"
        className="hospitalCardHeading"
        style={{ width: "100%" }}
      >
        Created by {created_by}
      </Stack>
    </Stack.Item>
  );
};

export default HospitalCard;
