import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getLogFacilities,
  getLogHospitals,
  getLogRooms,
  getSalesLogs,
  getServiceLogs,
  getSpacility,
} from "../../actions/logsActions";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppState } from "../../../../rootReducer";
const FilterDropdown = ({
  action,
  roomsCheck,
  logs,
  deviceId,
  selectFilters,
  setMapPointerSearch,
  filteredLogs,
  dateCheck,
  loaderWithMessageService,
  loaderWithMessageSales,
}: {
  action: any;
  loaderWithMessageSales?: any;
  loaderWithMessageService?: any;
  roomsCheck: any;
  logs: any;
  deviceId: any;
  selectFilters: any;
  setMapPointerSearch: any;
  filteredLogs: any;
  dateCheck: any;
}) => {
  const {
    control,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [newHospitals, setNewHospitals] = useState([]);
  const [newCountry, setNewCountry] = useState([]);
  const [newRegion, setNewRegion] = useState([]);
  const [newFacility, setNewFacility] = useState([]);
  const [newRoom, setNewRoom] = useState([]);
  const [newSpecialty, setNewSpecialty] = useState([]);

  // const [startDate, setStartDate] = useState(new Date(2021, 9, 31));
  // const [endDate, setEndDate] = useState(new Date(2021, 10, 30));
  const dateObj = new Date();
  // console.log(month,day,year,'yearyearyear');
  // Set it to one month ago
  const oneMonthBack = moment().subtract(1, "months").toDate();
  const currentDate = moment().toDate();
  const [startDate, setStartDate] = useState(oneMonthBack);
  const [endDate, setEndDate] = useState(currentDate);
  const [startDatePick, setStartDatePick] = useState(oneMonthBack);
  const [endDatePick, setEndDatePick] = useState(currentDate);
  const [ismapPointSerach, setismapPointSerach] = useState(true);
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  const [hospitalFilter, setHospitalFilter] = useState<any | null>(null);
  const [facilityFilter, setFacilityFilter] = useState<any | null>(null);
  const [hospitalFilterName, setHospitalFilterName] = useState<any | null>(
    null
  );
  const [facilityFilterName, setFacilityFilterName] = useState<any | null>(
    null
  );
  const [VisibleDropdownsList, setVisibleDropdownsList] = useState(false);
  const [VisibleDropdownListService, setVisibleDropdownsListService] =
    useState(false);
  const [roomFilterName, setRoomFilterName] = useState<any | null>(null);
  const [countryFilterName, setCountryFilterName] = useState<any | null>(null);
  const [regionFilterName, setRegionFilterName] = useState<any | null>(null);
  const [specialtyFilterName, setSpecialtyFilterName] = useState<any | null>(
    null
  );
  const [specialityBaseOnFacility, setSpecialityBaseOnFacility] = useState<
    any | null
  >(null);

  const [lastfacilityOption, setLastFacilityOption] = useState<any>([]);
  const [lastRoomOption, setLastRoomOption] = useState<any>([]);

  const logHospitals = useSelector((state: AppState) => {
    return state.logsReducerData?.hospitals?.data;
  });
  const logFacilities = useSelector((state: AppState) => {
    return state.logsReducerData?.facilities?.data;
  });
  const logRooms = useSelector((state: AppState) => {
    return state.logsReducerData?.rooms?.data;
  });
  const uniqueHospitalFilter = logs?.locations && [
    ...logs?.locations
      .reduce((map: any, obj: any) => map.set(obj.hospital, obj), new Map())
      .values(),
  ];
  const uniqueFacilityFilter = logs?.locations && [
    ...logs?.locations
      .reduce((map: any, obj: any) => map.set(obj.facility, obj), new Map())
      .values(),
  ];
  const uniqueRoomFilter = logs?.locations && [
    ...logs?.locations
      .reduce((map: any, obj: any) => map.set(obj.room, obj), new Map())
      .values(),
  ];
  const uniqueCountryFilter = logs?.locations && [
    ...logs?.locations
      .reduce((map: any, obj: any) => map.set(obj.country, obj), new Map())
      .values(),
  ];
  const uniqueRegionFilter = logs?.locations && [
    ...logs?.locations
      .reduce((map: any, obj: any) => map.set(obj.region, obj), new Map())
      .values(),
  ];
  const logCountryFilter = uniqueCountryFilter?.map((logRoom: any) => ({
    label: logRoom.country,
    value: logRoom.country,
  }));
  const logRegionFilter1 = uniqueRegionFilter
    ?.map((logRoom: any) => {
      if (countryFilterName) {
        if (logRoom.country === countryFilterName) {
          return {
            label: logRoom.region,
            value: logRoom.region,
          };
        }
      } else {
        return {
          label: logRoom.region,
          value: logRoom.region,
        };
      }
    })
    .filter((region: any) => region?.value && region?.value !== " ");
  const logRegionFilter = logRegionFilter1 && [
    ...logRegionFilter1
      .reduce((map: any, obj: any) => map.set(obj.value, obj), new Map())
      .values(),
  ];
  const hasHospitalInRegion = (logHospital: any) => {
    return regionFilterName ? logHospital.region === regionFilterName : true;
  };
  const hasHospitalInCountry = (logHospital: any) => {
    return countryFilterName ? logHospital.country === countryFilterName : true;
  };
  let logHospitalFilter = logs?.locations
    ?.map((logHospital: any) => {
      if (countryFilterName || regionFilterName) {
        if (
          hasHospitalInCountry(logHospital) &&
          hasHospitalInRegion(logHospital)
        ) {
          console.warn("inside region", logHospital.region);
          return {
            label: logHospital.hospital,
            value: logHospital.hospitalID,
          };
        }
      } else {
        console.warn("outside region", logHospital.region);
        return {
          label: logHospital.hospital,
          value: logHospital.hospitalID,
        };
      }
    })
    .filter((hospital: any) => hospital?.value);
  logHospitalFilter = logHospitalFilter
    ? [
        ...logHospitalFilter
          ?.reduce((map: any, obj: any) => map.set(obj.value, obj), new Map())
          .values(),
      ]
    : [];

  // const logFacilityFilter = logFacilities
  // const logFacilityFilter = logs?.locations
  //   ?.map((logFacilitie: any) => {
  //     console.warn(hospitalFilterName, "hospitalfilter");
  //     if (hospitalFilterName) {
  //       if (logFacilitie.hospitalID === hospitalFilterName) {
  //         console.warn("inside");
  //         return {
  //           label: logFacilitie.facility,
  //           value: logFacilitie.facilityID,
  //         };
  //       }
  //     } else {
  //       console.warn("outside");
  //       return {
  //         label: logFacilitie.facility,
  //         value: logFacilitie.facilityID,
  //       };
  //     }
  //   })
  //   .filter((facility: any) => facility?.value);

  let tempFacilities: any = logs?.locations;
  if (countryFilterName) {
    tempFacilities =
      tempFacilities &&
      tempFacilities.filter((fac: any) => {
        return fac.country === countryFilterName;
      });
  }
  if (regionFilterName) {
    tempFacilities =
      tempFacilities &&
      tempFacilities.filter((fac: any) => {
        return fac.region === regionFilterName;
      });
  }
  if (hospitalFilterName) {
    tempFacilities =
      tempFacilities &&
      tempFacilities?.length &&
      tempFacilities.filter((fac: any) => {
        return fac.hospitalID === hospitalFilterName;
      });
  }
  // tempFacilities =
  //   tempFacilities &&
  //   tempFacilities.map((fac: any) => {
  //     return {
  //       label: fac.facility,
  //       value: fac.facilityID,
  //     };
  //   });
  // const logFacilityFilter = tempFacilities || [];

  const logFacilityFilter1 = logs?.locations
    ?.map((fac: any) => {
      if (hospitalFilterName) {
        if (fac.hospitalID === hospitalFilterName) {
          // let item = filteredLogs?.locations?.find(
          //   (c: any) => c.facilityID === fac.facilityID
          // )
          //  if (item) {
          if (regionFilterName) {
            if (fac.region === regionFilterName) {
              return {
                label: fac.facility,
                value: fac.facilityID,
              };
            }
          } else {
            return {
              label: fac.facility,
              value: fac.facilityID,
            };
          }

          //    }
        }
      } else {
        return {
          label: fac.facility,
          value: fac.facilityID,
        };
      }
    })
    .filter((hospital: any) => hospital?.value);

  const logRoomFilter1: any =
    filteredLogs &&
    filteredLogs?.locations.map((logRoom: any) => {
      return {
        label: logRoom.room,
        value: logRoom.room,
      };
    });

  // const logRoomFilter: any = logs?.locations
  //   ?.map((logRoom: any) => {
  //     if (facilityFilterName) {

  //       if (logRoom.facilityID === facilityFilterName) {
  //         console.log(logRoom,'logRoom')
  //         return {
  //           label: logRoom.room,
  //           value: logRoom.room,
  //         };
  //       }
  //     } else {
  //       return {
  //         label: logRoom.room,
  //         value: logRoom.room,
  //       };
  //     }
  //   })
  //   .filter((facility: any) => facility?.value);

  const logRoomFilter = logRoomFilter1 && [
    ...logRoomFilter1
      .reduce((map: any, obj: any) => map.set(obj?.value, obj), new Map())
      .values(),
  ];

  const logFacilityFilter = logFacilityFilter1 && [
    ...logFacilityFilter1
      .reduce((map: any, obj: any) => map.set(obj?.value, obj), new Map())
      .values(),
  ];

  // console.log(logs?.locations,'logs?.locations', logFacilities)
  // console.log(logRoomFilter,'logRoomFilter1',filteredLogs?.locations )

  const spacilities = useSelector((state: AppState) => {
    return state.logsReducerData?.spacilities?.data;
  });
  // const spacilitiesFilter = logs?.speciality?.map((spacilitie: any) => ({
  //   label: spacilitie,
  //   value: spacilitie,
  // }));
  const startDateFormat = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : "";
  const endDateFormat = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
  };
  // const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;
  const [, updateState] = React.useState<object>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  // useEffect(() => {
  //   selectFilters?.length > 0 && forceUpdate();
  // }, [selectFilters]);
  const selectedHospital = hospitalFilterName
    ? hospitalFilterName
    : selectFilters?.length > 0
    ? selectFilters[0]
    : "";
  const selectedFacility = facilityFilterName
    ? facilityFilterName
    : selectFilters?.length > 0
    ? selectFilters[1]
    : "";
  const selectedRoom = roomFilterName
    ? roomFilterName
    : selectFilters?.length > 0
    ? selectFilters[2]
    : "";
  const selectedCountry = countryFilterName
    ? countryFilterName
    : selectFilters?.length > 0
    ? selectFilters[4]
    : "";
  const selectedRegion = regionFilterName
    ? regionFilterName
    : selectFilters?.length > 0
    ? selectFilters[3]
    : "";
  const selectedSpecialty = specialtyFilterName
    ? specialtyFilterName
    : selectFilters?.length > 0
    ? selectFilters[5]
    : "";
  const [value, setValue] = useState<object>();
  useEffect(() => {
    // dispatch(
    //   action(
    //     startDateFormat,
    //     endDateFormat,
    //     selectedHospital,
    //     selectedFacility,
    //     selectedRoom,
    //     selectedCountry,
    //     selectedRegion,
    //     selectedSpecialty,
    //     deviceId
    //   )
    // );
  }, []);
  useEffect(() => {
    roomsCheck(roomFilterName);
  }, [roomFilterName]);
  useEffect(() => {
    if (deviceId?.length > 3 || deviceId.length === 0) {
      dispatch(
        action(
          startDateFormat,
          endDateFormat,
          selectedHospital,
          selectedFacility,
          selectedRoom,
          selectedCountry,
          selectedRegion,
          selectedSpecialty,
          deviceId
        )
      );
    }
    // dispatch(getServiceLogs("", "", "", "", "", "", "", "", "", true));
  }, [deviceId]);
  useEffect(() => {
    const startDateFormat = startDatePick
      ? moment(startDatePick).format("YYYY-MM-DD")
      : "";
    const endDateFormat = endDatePick
      ? moment(endDatePick).format("YYYY-MM-DD")
      : "";
    dispatch(
      getSalesLogs(startDateFormat, endDateFormat, "", "", "", "", "", "", true)
    );
    dispatch(
      getServiceLogs(
        startDateFormat,
        endDateFormat,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true
      )
    );
  }, []);
  useEffect(() => {
    setTimeout(() => {
      const startDateFormat = startDatePick
        ? moment(startDatePick).format("YYYY-MM-DD")
        : "";
      const endDateFormat = endDatePick
        ? moment(endDatePick).format("YYYY-MM-DD")
        : "";
      dateCheck(startDateFormat, endDateFormat);
      // console.log(startDateFormat, endDateFormat, "startDate && endDate");
      setStartDate(startDatePick);
      setEndDate(endDatePick);
      dispatch(
        getSalesLogs(
          startDateFormat,
          endDateFormat,
          "",
          "",
          "",
          "",
          "",
          "",
          true
        )
      );
      dispatch(
        getServiceLogs(
          startDateFormat,
          endDateFormat,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          true
        )
      );
      dispatch(
        action(
          startDateFormat,
          endDateFormat,
          selectedHospital,
          selectedFacility,
          selectedRoom,
          selectedCountry,
          selectedRegion,
          selectedSpecialty,
          deviceId
        )
      );
    }, 1000);
  }, [startDatePick, endDatePick]);
  // const refresh = () => {
  //   // re-renders the component
  //   setValue({});
  // };
  const handleChange = (val: any) => {
    // onChange(val?.value);
    setLastFacilityOption([]);
    setLastRoomOption([]);
    setHospitalFilter(val?.value);
    setHospitalFilterName(val?.value);
    setFacilityFilterName(null);
    setSpecialtyFilterName(null);
    setRoomFilterName(null);
    // refresh();
    // setMapPointerSearch([val?.label,
    //   selectedFacility, selectedRoom, selectedRegion,
    //   selectedCountry]);
    setMapPointerSearch([
      val?.value,
      "",
      "",
      selectedRegion,
      selectedCountry,
      "",
      startDateFormat,
      endDateFormat,
    ]);
    // dispatch(
    //   action(
    //     startDateFormat,
    //     endDateFormat,
    //     val?.value,
    //     "",
    //     "",
    //     selectedCountry,
    //     selectedRegion,
    //     val?.label ? selectedSpecialty : "",
    //     deviceId
    //   )
    // );
  };
  useEffect(() => {
    if (
      loaderWithMessageSales &&
      loaderWithMessageSales?.calledFrom === "GET_SALES_LOGS" &&
      loaderWithMessageSales?.statusCode !== 403 &&
      !loaderWithMessageSales?.loadingState
    ) {
      setVisibleDropdownsList(true);
    }
    if (
      loaderWithMessageSales?.calledFrom === "GET_SALES_LOGS" &&
      loaderWithMessageSales?.statusCode == 403
    ) {
      setVisibleDropdownsList(false);
    }
  }, [loaderWithMessageSales]);
  useEffect(() => {
    if (
      loaderWithMessageService &&
      loaderWithMessageService?.calledFrom === "GET_SERVICE_LOGS" &&
      loaderWithMessageService?.statusCode !== 403 &&
      !loaderWithMessageService?.loadingState
    ) {
      setVisibleDropdownsListService(true);
    }
    if (
      loaderWithMessageService?.calledFrom === "GET_SERVICE_LOGS" &&
      loaderWithMessageService?.statusCode == 403
    ) {
      setVisibleDropdownsListService(false);
    }
  }, [loaderWithMessageService]);
  useEffect(() => {
    if (selectFilters && selectFilters.length && ismapPointSerach) {
      setCountryFilterName(selectFilters[4]);
      setRegionFilterName(selectFilters[3]);
      setHospitalFilterName(selectFilters[0]);
      setFacilityFilterName(selectFilters[1]);
      setRoomFilterName(selectFilters[2]);
      dispatch(
        action(
          startDateFormat,
          endDateFormat,
          selectedHospital,
          selectedFacility,
          selectedRoom,
          selectedCountry,
          selectedRegion,
          selectedSpecialty,
          deviceId
        )
      );
      // let isDate =
      //   !isNaN(new Date(selectFilters[6]).valueOf()) &&
      //   !isNaN(new Date(selectFilters[7]).valueOf());
      // if (isDate) {
      //   setStartDate(new Date(selectFilters[6]));
      //   setStartDatePick(new Date(selectFilters[6]));
      //   setEndDatePick(new Date(selectFilters[7]));
      //   setEndDate(new Date(selectFilters[7]));
      // }
    }
  }, [selectFilters]);
  const getHospitalFilterName = (value: any) => {
    const obj = logHospitalFilter?.find(
      (c: any) => c.value === hospitalFilterName
    );
    return obj || null;
  };
  const getspecialtyFilterName = (value: any) => {
    const obj = spacilitiesFilter?.find((c: any) => c.value === value);
    return obj || null;
  };
  useEffect(() => {
    if (filteredLogs?.speciality && !selectedSpecialty) {
      setSpecialityBaseOnFacility(filteredLogs.speciality);
    }
  }, [filteredLogs]);
  const spacilitiesFilter =
    facilityFilterName && !selectedSpecialty
      ? filteredLogs?.speciality?.map((spacilitie: any) => ({
          label: spacilitie,
          value: spacilitie,
        }))
      : specialityBaseOnFacility?.map((spacilitie: any) => ({
          label: spacilitie,
          value: spacilitie,
        }));

  function getUniqueListBy(arr: any, key: any) {
    return (
      arr &&
      arr.filter(
        (ele: any, ind: any) =>
          ind === arr.findIndex((elem: any) => elem[key] === ele[key])
      )
    );
  }

  useEffect(() => {
    // console.log(logs, 'logsssss')
    let getCountry: any = getUniqueListBy(logs?.locations, "country");
    getCountry =
      getCountry &&
      getCountry.map((v: any) => ({
        ...v,
        label: v?.country,
        value: v?.country,
      }));
    let getRegion: any = getUniqueListBy(logs?.locations, "region");
    getRegion =
      getRegion &&
      getRegion.map((v: any) => ({ ...v, label: v?.region, value: v?.region }));
    let getHospitals: any = getUniqueListBy(logs?.locations, "hospitalID");
    getHospitals =
      getHospitals &&
      getHospitals.map((v: any) => ({
        ...v,
        label: v?.hospital,
        value: v?.hospitalID,
      }));
    let getFacilities: any = getUniqueListBy(logs?.locations, "facilityID");
    getFacilities =
      getFacilities &&
      getFacilities.map((v: any) => ({
        ...v,
        label: v?.facility,
        value: v?.facilityID,
      }));
    let getRooms = getUniqueListBy(logs?.locations, "room");
    getRooms =
      getRooms &&
      getRooms.map((v: any) => ({ ...v, label: v?.room, value: v?.room }));
    let getSpecialty: any = logs?.speciality;
    getSpecialty =
      getSpecialty &&
      getSpecialty.map((v: any) => ({ ...v, label: v, value: v }));
    setNewCountry(getCountry);
    setNewRegion(getRegion);
    setNewHospitals(getHospitals);
    setNewFacility(getFacilities);
    setNewRoom(getRooms);
    setNewSpecialty(getSpecialty);
  }, [logs]);

  return (
    <Stack.Item className="ms-Grid" dir="ltr">
      <Stack.Item className="filterSection ms-Grid-row">
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Country</Text>
          </Stack.Item>
          {selectFilters[4] && selectFilters[4] !== "" ? (
            <>
              <Stack.Item className="label"></Stack.Item>
              <Controller
                control={control}
                defaultValue={selectFilters[4]}
                name="country"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isClearable
                    classNamePrefix="addl-class"
                    // isDisabled={true}
                    options={logCountryFilter}
                    value={logCountryFilter?.find(
                      (c: any) => c.value === countryFilterName
                    )}
                    onChange={(val) => {
                      onChange(val?.value);
                      // setMapPointerSearch([]);
                      setCountryFilterName(val?.value);
                      setRegionFilterName(null);
                      setHospitalFilter("");
                      setHospitalFilterName(null);
                      setFacilityFilterName(null);
                      setSpecialtyFilterName(null);
                      // setFacilityFilter("");
                      setRoomFilterName("");
                      setismapPointSerach(false);
                      setMapPointerSearch([
                        "",
                        "",
                        "",
                        "",
                        val?.value,
                        "",
                        startDateFormat,
                        endDateFormat,
                      ]);
                      dispatch(
                        action(
                          startDateFormat,
                          endDateFormat,
                          "",
                          "",
                          "",
                          val?.value,
                          "",
                          "",
                          deviceId
                        )
                      );
                      setTimeout(() => {
                        setismapPointSerach(true);
                      }, 2000);
                    }}
                  />
                )}
              />
            </>
          ) : (
            <Controller
              control={control}
              defaultValue=""
              name="country1"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  // isDisabled={true}
                  options={logCountryFilter}
                  isDisabled={
                    !VisibleDropdownsList && !VisibleDropdownListService
                  }
                  placeholder={
                    !VisibleDropdownsList && !VisibleDropdownListService
                      ? "No options"
                      : "select"
                  }
                  value={logCountryFilter?.find(
                    (c: any) => c.value === countryFilterName
                  )}
                  onChange={(val) => {
                    dispatch(
                      action(
                        startDateFormat,
                        endDateFormat,
                        "",
                        "",
                        "",
                        val?.value,
                        "",
                        "",
                        deviceId
                      )
                    );
                    onChange(val?.value);
                    setCountryFilterName(val?.value);
                    setRegionFilterName(null);
                    setHospitalFilter("");
                    setHospitalFilterName(null);
                    setFacilityFilterName(null);
                    setSpecialtyFilterName(null);
                    // setFacilityFilter("");
                    setismapPointSerach(false);
                    setRoomFilterName("");
                    setMapPointerSearch([
                      "",
                      "",
                      "",
                      "",
                      val?.value,
                      "",
                      startDateFormat,
                      endDateFormat,
                    ]);
                    setTimeout(() => {
                      setismapPointSerach(true);
                    }, 2000);
                  }}
                />
              )}
            />
          )}
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Region</Text>
          </Stack.Item>
          {selectFilters[3] && selectFilters[3] !== "" ? (
            <>
              <Stack.Item className="label"></Stack.Item>
              <Controller
                control={control}
                defaultValue={selectFilters[3]}
                name="region"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isClearable
                    classNamePrefix="addl-class"
                    options={logRegionFilter}
                    value={logRegionFilter?.find(
                      (c: any) => c.value === regionFilterName
                    )}
                    onChange={(val) => {
                      onChange(val?.value);
                      setRegionFilterName(val?.value);
                      setHospitalFilterName(null);
                      setFacilityFilterName(null);
                      setRoomFilterName(null);
                      setSpecialtyFilterName(null);
                      setMapPointerSearch([
                        "",
                        "",
                        "",
                        val?.value,
                        selectedCountry,
                        "",
                        startDateFormat,
                        endDateFormat,
                      ]);
                      // dispatch(
                      //   action(
                      //     startDateFormat,
                      //     endDateFormat,
                      //     selectedHospital,
                      //     selectedFacility,
                      //     selectedRoom,
                      //     selectedCountry,
                      //     val?.value,
                      //     selectedSpecialty,
                      //     deviceId
                      //   )
                      // );
                    }}
                  />
                )}
              />
            </>
          ) : selectedRegion ? (
            <Controller
              control={control}
              defaultValue={selectedRegion}
              name="region1"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  options={logRegionFilter}
                  value={logRegionFilter?.find(
                    (c: any) => c.value === regionFilterName
                  )}
                  onChange={(val) => {
                    onChange(val?.value);
                    setRegionFilterName(val?.value);
                    setHospitalFilterName(null);
                    setFacilityFilterName(null);
                    setRoomFilterName(null);
                    setSpecialtyFilterName(null);
                    // setMapPointerSearch([
                    //   selectedHospital,
                    //   selectedFacility,
                    //   selectedRoom,
                    //   val?.value,
                    //   selectedCountry,
                    //   selectedSpecialty,
                    // ]);
                    dispatch(
                      action(
                        startDateFormat,
                        endDateFormat,
                        "",
                        "",
                        "",
                        selectedCountry,
                        val?.value,
                        "",
                        deviceId
                      )
                    );
                  }}
                />
              )}
            />
          ) : (
            <Select
              isClearable
              classNamePrefix="addl-class"
              isDisabled={!VisibleDropdownsList && !VisibleDropdownListService}
              placeholder={
                !VisibleDropdownsList && !VisibleDropdownListService
                  ? "No options"
                  : "select"
              }
              options={logRegionFilter}
              defaultValue={selectedRegion}
              onInputChange={(val: any) => {
                // dispatch(getLogFacilities(hospitalFilter, val));
              }}
              onChange={(val) => {
                setRegionFilterName(val?.value);
                setHospitalFilterName(null);
                setFacilityFilterName(null);
                setRoomFilterName(null);
                setSpecialtyFilterName(null);
                setMapPointerSearch([
                  "",
                  "",
                  "",
                  val?.value,
                  selectedCountry,
                  "",
                ]);
                dispatch(
                  action(
                    startDateFormat,
                    endDateFormat,
                    "",
                    "",
                    "",
                    selectedCountry,
                    val?.value,
                    "",
                    deviceId
                  )
                );
              }}
            />
          )}
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Hospital</Text>
          </Stack.Item>
          {/* {console.log(selectFilters,'selectFilters')} */}
          {selectFilters[0] && selectFilters[0] !== "" ? (
            <>
              <Stack.Item className="label"></Stack.Item>
              <Controller
                control={control}
                defaultValue={selectFilters[0]}
                name={`Hospital${selectFilters?.length}`}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isClearable
                    classNamePrefix="addl-class"
                    options={logHospitalFilter}
                    value={getHospitalFilterName(value)}
                    // onInputChange={(val) => {
                    //   dispatch(getLogHospitals(val));
                    // }}
                    onChange={(val) => {
                      setLastFacilityOption([]);
                      setLastRoomOption([]);
                      onChange(val?.value);
                      setHospitalFilter(val?.value);
                      setHospitalFilterName(val?.value);
                      // setMapPointerSearch([]);
                      setFacilityFilterName(null);
                      setRoomFilterName(null);
                      setSpecialtyFilterName(null);
                      setMapPointerSearch([
                        val?.value,
                        "",
                        "",
                        selectedRegion,
                        selectedCountry,
                        "",
                        startDateFormat,
                        endDateFormat,
                      ]);
                      // dispatch(
                      //   action(
                      //     startDateFormat,
                      //     endDateFormat,
                      //     val?.label,
                      //     selectedFacility,
                      //     selectedRoom,
                      //     selectedCountry,
                      //     selectedRegion,
                      //     selectedSpecialty,
                      //     deviceId
                      //   )
                      // );
                      // setMapPointerSearch([]);
                      // dispatch(getLogFacilities(val?.value));
                    }}
                  />
                )}
              />
            </>
          ) : selectedHospital ? (
            <Controller
              control={control}
              name={`Hospital1`}
              defaultValue={selectedHospital}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  options={logHospitalFilter}
                  value={getHospitalFilterName(value)}
                  onChange={handleChange}
                />
              )}
            />
          ) : (
            <Select
              isDisabled={!VisibleDropdownsList && !VisibleDropdownListService}
              placeholder={
                !VisibleDropdownsList && !VisibleDropdownListService
                  ? "No options"
                  : "select"
              }
              isClearable
              classNamePrefix="addl-class"
              defaultValue={selectedHospital}
              options={logHospitalFilter}
              onInputChange={(val: any) => {
                // dispatch(getLogFacilities(hospitalFilter, val));
              }}
              onChange={handleChange}
            />
          )}
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Facility</Text>
          </Stack.Item>
          {selectFilters[1] && selectFilters[1] !== "" ? (
            <>
              {/* <Stack.Item className="label">
                <>dsfff</>
              </Stack.Item> */}
              <Controller
                control={control}
                defaultValue={selectFilters[1]}
                name="Facility"
                shouldUnregister
                // clearValue={}
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isClearable
                    classNamePrefix="addl-class"
                    options={logFacilityFilter}
                    value={logFacilityFilter?.find(
                      (c: any) => c.value === selectedFacility
                    )}
                    onInputChange={(val) => {
                      // dispatch(getLogFacilities(hospitalFilter, val));
                    }}
                    onChange={(val) => {
                      !lastfacilityOption?.length &&
                        setLastFacilityOption(logFacilityFilter);
                      setLastRoomOption([]);
                      onChange(val?.value);
                      setFacilityFilter(val?.value);
                      setFacilityFilterName(val?.value);
                      setRoomFilterName(null);
                      setSpecialtyFilterName(null);
                      setMapPointerSearch([
                        selectedHospital,
                        val?.value,
                        "",
                        selectedRegion,
                        selectedCountry,
                        val?.label ? selectedSpecialty : "",
                        startDateFormat,
                        endDateFormat,
                      ]);
                      // dispatch(
                      //   action(
                      //     startDateFormat,
                      //     endDateFormat,
                      //     selectedHospital,
                      //     val?.label,
                      //     selectedRoom,
                      //     selectedCountry,
                      //     selectedRegion,
                      //     selectedSpecialty,
                      //     deviceId
                      //   )
                      // );
                      // dispatch(getLogRooms(hospitalFilter, val?.value));
                    }}
                    // isDisabled={hospitalFilterName || (selectFilters?.length > 0 && selectFilters[1]) ? false : true}
                  />
                )}
              />
            </>
          ) : (
            <>
              {selectedFacility ? (
                <Controller
                  control={control}
                  defaultValue={selectedFacility}
                  name="Facility"
                  // clearValue={}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      isClearable
                      classNamePrefix="addl-class"
                      options={logFacilityFilter}
                      value={logFacilityFilter?.find(
                        (c: any) => c.value === selectedFacility
                      )}
                      onInputChange={(val) => {
                        // dispatch(getLogFacilities(hospitalFilter, val));
                      }}
                      onChange={(val) => {
                        !lastfacilityOption?.length &&
                          setLastFacilityOption(logFacilityFilter);
                        setLastRoomOption([]);
                        onChange(val?.value);
                        setFacilityFilter(val?.value);
                        setFacilityFilterName(val?.value);
                        setRoomFilterName(null);
                        setSpecialtyFilterName(null);
                        dispatch(
                          action(
                            startDateFormat,
                            endDateFormat,
                            selectedHospital,
                            val?.value,
                            "",
                            selectedCountry,
                            selectedRegion,
                            "",
                            deviceId
                          )
                        );
                        // dispatch(getLogRooms(hospitalFilter, val?.value));
                      }}
                    />
                  )}
                />
              ) : (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  options={logFacilityFilter}
                  defaultValue={selectedFacility}
                  onInputChange={(val: any) => {
                    // dispatch(getLogFacilities(hospitalFilter, val));
                  }}
                  onChange={(val: any) => {
                    !lastfacilityOption?.length &&
                      setLastFacilityOption(logFacilityFilter);
                    setLastRoomOption([]);
                    // onChange(val?.value);
                    setFacilityFilter(val?.value);
                    setFacilityFilterName(val?.value);
                    setRoomFilterName(null);
                    // setMapPointerSearch([selectedHospital,
                    //   val?.label, selectedRoom, selectedRegion,
                    //   selectedCountry,selectedSpecialty]);
                    dispatch(
                      action(
                        startDateFormat,
                        endDateFormat,
                        selectedHospital,
                        val?.value,
                        "",
                        selectedCountry,
                        selectedRegion,
                        selectedSpecialty,
                        deviceId
                      )
                    );
                    // dispatch(getLogRooms(hospitalFilter, val?.value));
                  }}
                  isDisabled={selectedHospital ? false : true}
                />
              )}
            </>
          )}
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Room </Text>
          </Stack.Item>
          {selectFilters[2] && selectFilters[2] !== "" ? (
            <>
              <Stack.Item className="label"></Stack.Item>
              <Controller
                control={control}
                defaultValue={selectFilters[2]}
                name="Room"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isClearable
                    classNamePrefix="addl-class"
                    options={
                      lastRoomOption && lastRoomOption.length
                        ? lastRoomOption
                        : logRoomFilter
                    }
                    value={logRoomFilter?.find(
                      (c: any) => c.value === selectedRoom
                    )}
                    onInputChange={(val) => {
                      // dispatch(getLogRooms(hospitalFilter, facilityFilter, val));
                      // console.log(roomFilterName, "roomFilterName");
                      // dispatch(
                      //   getLogRooms(hospitalFilter, facilityFilter, roomFilterName)
                      // );
                    }}
                    onChange={(val) => {
                      !lastRoomOption?.length &&
                        setLastRoomOption(logRoomFilter);
                      onChange(val?.value);
                      setRoomFilterName(val?.label);
                      setMapPointerSearch([
                        selectedHospital,
                        selectedFacility,
                        val?.value,
                        selectedRegion,
                        selectedCountry,
                        selectedSpecialty,
                        startDateFormat,
                        endDateFormat,
                      ]);
                      // dispatch(
                      //   action(
                      //     startDateFormat,
                      //     endDateFormat,
                      //     selectedHospital,
                      //     selectedFacility,
                      //     val?.label,
                      //     selectedCountry,
                      //     selectedRegion,
                      //     selectedSpecialty,
                      //     deviceId
                      //   )
                      // );
                    }}
                    // isDisabled={facilityFilterName || (selectFilters?.length > 0 && selectFilters[2]) ? false : true}
                  />
                )}
              />
            </>
          ) : (
            <>
              {selectedRoom ? (
                <Controller
                  control={control}
                  defaultValue={selectedRoom}
                  name="Room1"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      isClearable
                      classNamePrefix="addl-class"
                      options={
                        lastRoomOption && lastRoomOption.length
                          ? lastRoomOption
                          : logRoomFilter
                      }
                      value={logRoomFilter?.find(
                        (c: any) => c.value === selectedRoom
                      )}
                      onInputChange={(val) => {
                        // dispatch(getLogRooms(hospitalFilter, facilityFilter, val));
                        // console.log(roomFilterName, "roomFilterName");
                        // dispatch(
                        //   getLogRooms(hospitalFilter, facilityFilter, roomFilterName)
                        // );
                      }}
                      onChange={(val) => {
                        !lastRoomOption?.length &&
                          setLastRoomOption(logRoomFilter);
                        onChange(val?.value);
                        setRoomFilterName(val?.label);
                        dispatch(
                          action(
                            startDateFormat,
                            endDateFormat,
                            selectedHospital,
                            selectedFacility,
                            val?.label,
                            selectedCountry,
                            selectedRegion,
                            selectedSpecialty,
                            deviceId
                          )
                        );
                        // const sd = roomsCheck ? roomsCheck(val?.label) : "";
                      }}
                      isDisabled={selectedFacility ? false : true}
                    />
                  )}
                />
              ) : (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  defaultValue={selectedRoom}
                  options={
                    lastRoomOption && lastRoomOption.length
                      ? lastRoomOption
                      : logRoomFilter
                  }
                  onInputChange={(val) => {
                    // dispatch(getLogRooms(hospitalFilter, facilityFilter, val));
                    // console.log(roomFilterName, "roomFilterName");
                    // dispatch(
                    //   getLogRooms(hospitalFilter, facilityFilter, roomFilterName)
                    // );
                  }}
                  onChange={(val) => {
                    !lastRoomOption?.length && setLastRoomOption(logRoomFilter);
                    setRoomFilterName(val?.label);
                    dispatch(
                      action(
                        startDateFormat,
                        endDateFormat,
                        selectedHospital,
                        selectedFacility,
                        val?.label,
                        selectedCountry,
                        selectedRegion,
                        selectedSpecialty,
                        deviceId
                      )
                    );
                    // const sd = roomsCheck ? roomsCheck(val?.label) : "";
                  }}
                  isDisabled={selectedFacility ? false : true}
                />
              )}
            </>
          )}
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Start Date</Text>
          </Stack.Item>
          <DatePicker
            selected={startDate}
            onChange={(date: any) => setStartDatePick(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate ? endDate : currentDate}
            isClearable={startDate ? true : false}
            // onChange={onChangeDateRangePicker}
          />
          {/* <DatePicker
            selected={startDate}
            onChange={onChangeDateRangePicker}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            isClearable
            placeholderText="Select Date"
          // className="addl-class"
          /> */}
          {/* <Controller
              control={control}
              defaultValue=""
              {...register("operating_system")}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                isClearable
                  classNamePrefix="addl-class"
                  options={options1}
                  value={options1.find((c) => c.value === value)}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                />
              )}
            /> */}
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>End Date</Text>
          </Stack.Item>
          <DatePicker
            selected={endDate}
            onChange={(date: any) => setEndDatePick(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={currentDate}
            isClearable={endDate ? true : false}
            // onChange={onChangeDateRangePicker}
          />
        </Stack.Item>
        <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
          <Stack.Item className="label">
            <Text>Specialty</Text>
          </Stack.Item>
          {selectFilters[5] && selectFilters[5] !== "" ? (
            <>
              <Stack.Item className="label"></Stack.Item>
              <Controller
                control={control}
                defaultValue={specialtyFilterName}
                name="Specialty"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    isClearable
                    classNamePrefix="addl-class"
                    options={spacilitiesFilter}
                    value={getspecialtyFilterName(specialtyFilterName)}
                    onChange={(val: any) => {
                      setSpecialtyFilterName(val?.value);
                      onChange(val?.value);
                      dispatch(
                        action(
                          startDateFormat,
                          endDateFormat,
                          selectedHospital,
                          selectedFacility,
                          selectedRoom,
                          selectedCountry,
                          selectedRegion,
                          val?.value,
                          deviceId
                        )
                      );
                    }}
                  />
                )}
              />
            </>
          ) : (
            <>
              {specialtyFilterName ? (
                <Controller
                  control={control}
                  defaultValue={specialtyFilterName}
                  name="Specialty1"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      isClearable
                      classNamePrefix="addl-class"
                      options={spacilitiesFilter}
                      value={getspecialtyFilterName(specialtyFilterName)}
                      onChange={(val) => {
                        setSpecialtyFilterName(val?.value);
                        onChange(val?.value);
                        dispatch(
                          action(
                            startDateFormat,
                            endDateFormat,
                            selectedHospital,
                            selectedFacility,
                            selectedRoom,
                            selectedCountry,
                            selectedRegion,
                            val?.value,
                            deviceId
                          )
                        );
                        // setMapPointerSearch([
                        //   selectedHospital,
                        //   selectedFacility,
                        //   selectedRoom,
                        //   selectedRegion,
                        //   selectedCountry,
                        //   startDateFormat,
                        //   endDateFormat,
                        //   val?.value ? val.value : "",
                        // ]);
                      }}
                    />
                  )}
                />
              ) : (
                <Select
                  isDisabled={
                    !VisibleDropdownsList && !VisibleDropdownListService
                  }
                  placeholder={
                    !VisibleDropdownsList && !VisibleDropdownListService
                      ? "No options"
                      : "select"
                  }
                  isClearable
                  classNamePrefix="addl-class"
                  defaultValue={specialtyFilterName}
                  options={spacilitiesFilter}
                  value={getspecialtyFilterName(specialtyFilterName)}
                  onInputChange={(val) => {
                    // dispatch(getLogRooms(hospitalFilter, facilityFilter, val));
                    // console.log(roomFilterName, "roomFilterName");
                    // dispatch(
                    //   getLogRooms(hospitalFilter, facilityFilter, roomFilterName)
                    // );
                  }}
                  onChange={(val) => {
                    setSpecialtyFilterName(val?.label);
                    dispatch(
                      action(
                        startDateFormat,
                        endDateFormat,
                        selectedHospital,
                        selectedFacility,
                        selectedRoom,
                        selectedCountry,
                        selectedRegion,
                        val?.value,
                        deviceId
                      )
                    );
                    // const sd = roomsCheck ? roomsCheck(val?.label) : "";
                  }}
                />
              )}
            </>
          )}
        </Stack.Item>
      </Stack.Item>
    </Stack.Item>
  );
};
export default React.memo(FilterDropdown);
