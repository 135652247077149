import { Stack } from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import ProcedureVideos from "../../common/procedureVideos/ProcedureVideos";
import { getVideoDetailsById } from "../../Layout/actions/layoutActions";
import { withRouter } from "../../withRouter";
import {
  getVideos,
  setSelectedSharedVideo,
  setSelectedVideoUnfavourite,
  editSharedVideoDetails,
  postSharedDetails,
  clearSharedVideoData,
  showSpinnerOnLoad,
  resetEditedDataonCancel,
} from "../actions/sharedVideosAction";

export interface SharedVideosProps {
  videosData: any;
  history: any;
  getVideosAction: any;
  setSelectedSharedVideo: any;
  setSelectedVideoUnfavourite: any;
  proceduresData: Array<any>;
  userData: any;
  userProfile: string;
  editSharedVideoDetails: any;
  sharedVideoEditedFields: object;
  getVideoDetailsById: any;
  postSharedDetails: any;
  sharedVideos: any;
  showSpinnerOnLoad: any;
  showSpinner: boolean;
  showData: boolean;
  clearSharedVideoData: any;
  currentPage: number;
  resetEditedDataonCancel: any;
}

export interface SharedVideosState {
  sharedRadioButton: boolean;
  currentVideoPage: any;
}
class SharedVideos extends React.Component<
  SharedVideosProps,
  SharedVideosState
> {
  state = {
    sharedRadioButton: true,
    currentVideoPage: 1,
  };
  breadcrumbItems = [
    {
      text: "Home",
      key: "Home",
      onClick: () => this.props.history("/"),
    },
    {
      text: "Shared",
      key: "d2",
      isCurrentItem: true,
      as: "h4",
    },
  ];
  componentDidMount() {
    this.props.showSpinnerOnLoad();
    this.props.getVideosAction("sharedByMe", this.state.currentVideoPage);
  }
  handleFavouiteVideoClick = () => {
    const [data] = this.props.videosData.results.filter(
      (item: any) => item.isSelected
    );
    this.props.setSelectedVideoUnfavourite(data);
  };

  handleRadioButtonChange = () => {
    this.props.clearSharedVideoData();
    this.props.showSpinnerOnLoad();
    this.setState({
      ...this.state,
      sharedRadioButton: !this.state.sharedRadioButton,
    });
    !this.state.sharedRadioButton
      ? this.props.getVideosAction("sharedByMe", 1)
      : this.props.getVideosAction("sharedWithMe", 1);
  };

  handleClick = (evt: React.MouseEvent, item: any) => {
    this.props.setSelectedSharedVideo(item);
  };
  componentWillMount(): void {
    const currentVideoPage = localStorage.getItem("currentVideoPage") || 1;
    this.setState({ currentVideoPage: currentVideoPage });
  }
  componentWillUnmount() {
    this.props.clearSharedVideoData();
  }

  render() {
    return (
      <Stack>
        <ProcedureVideos
          history={this.props.history}
          breadcrumbItems={this.breadcrumbItems}
          videosData={this.props.videosData}
          setSelectedVideo={this.handleClick}
          setSelectedVideoUnfavourite={this.props.setSelectedVideoUnfavourite}
          selectedVideoEditedFields={this.props.sharedVideoEditedFields}
          editVideoDetails={this.props.editSharedVideoDetails}
          proceduresData={this.props.proceduresData}
          userData={this.props.userData}
          userProfile={this.props.userProfile}
          postSharedDetails={this.props.postSharedDetails}
          handleFavouiteVideoClick={this.handleFavouiteVideoClick}
          getVideosAction={this.props.getVideosAction}
          screen={this.state.sharedRadioButton ? "sharedByMe" : "sharedWithMe"}
          handleRadioButtonChange={this.handleRadioButtonChange}
          clearDataOnComponentUnmount={this.props.clearSharedVideoData}
          showSpinner={this.props.showSpinner}
          showData={this.props.showData}
          currentPage={this.state.currentVideoPage}
          resetEditedDataonCancel={this.props.resetEditedDataonCancel}
          showSpinnerOnload={this.props.showSpinnerOnLoad}
        />
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    videosData: state.sharedVideosReducerData.videosData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
    sharedVideoEditedFields:
      state.sharedVideosReducerData.sharedVideoEditedFields,
    sharedVideos: state.sharedVideosReducerData.sharedBy,
    showSpinner: state.sharedVideosReducerData.showSpinner,
    showData: state.sharedVideosReducerData.showData,
    currentPage: state.sharedVideosReducerData.currentPage,
    tempVideosData: state.sharedVideosReducerData.tempVideosData,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    getVideosAction: (videoData: string, currentPage: number) =>
      dispatch(getVideos(videoData, currentPage)),
    setSelectedSharedVideo: (data: any) =>
      dispatch(setSelectedSharedVideo(data)),
    setSelectedVideoUnfavourite: (data: any) =>
      dispatch(setSelectedVideoUnfavourite(data)),
    editSharedVideoDetails: (data: any, videoId: number) =>
      dispatch(editSharedVideoDetails(data, videoId)),
    getVideoDetailsById: (id: number) => dispatch(getVideoDetailsById(id)),
    postSharedDetails: (data: any) => dispatch(postSharedDetails(data)),
    showSpinnerOnLoad: () => dispatch(showSpinnerOnLoad()),
    clearSharedVideoData: () => dispatch(clearSharedVideoData()),
    resetEditedDataonCancel: (type: string) =>
      dispatch(resetEditedDataonCancel(type)),
  };
};

export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(SharedVideos)
);
