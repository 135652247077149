import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
  TextField,
  StackItem,
  Label,
  SearchBox,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import Select from "react-select";
import Loader from "../common/loader";
import { debounce } from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import "./Containers.scss";
import {
  getAllContainers,
  deleteContainer,
  showContainerSpinner,
  eraseDeleteContainerData,
} from "../../actions/managementAction";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import Paging from "../../../../common/paging/Paging";
import AddContainer from "./AddContainer/AddContainer";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const getMenuItems = () => {
  return [
    {
      key: "edit",
      text: "Edit",
      title: constants.getPermissionsbyItem("algorithm.container.edit")
        ? ""
        : constants.permission,
      // scope: [SCOPES.DEVELOPER_EDIT_CONTAINER],
    },
    {
      key: "delete",
      text: "Delete",
      title: constants.getPermissionsbyItem("algorithm.container.delete")
        ? ""
        : constants.permission,
      // scope: [SCOPES.DEVELOPER_DELETE_CONTAINER],
    },
  ];
};

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Containers = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: {},
  } = useForm();
  const [data, setData] = useState<{
    name: string;
    region: string;
    address: string;
    zipcode: string;
    unique_identifier: string;
  }>({
    name: "",
    region: "",
    address: "",
    zipcode: "",
    unique_identifier: "KS23453387",
  });

  const [errors, setErrors] = useState<{
    name?: string;
    region?: string;
    address?: string;
    zipcode?: string;
    unique_identifier?: string;
  }>({});

  const [visibleContainerList, setVisibleContainerList] = useState(false);

  const [showRole, setShowRole] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [selectedContainerId, setSelectedContainerId] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [searchText, setSearchText] = useState<string>("");
  const [showPage, setshowPage] = useState<any>(true);

  const closeContainer = useSelector((state: AppState) => {
    return state.developerManagementReducerData.closeContainer;
  });
  const createOrUpdate = useSelector((state: AppState) => {
    return state.developerManagementReducerData.createOrUpdate;
  });

  const toggleModal = (addorEdit: string) => {
    if (addorEdit !== "edit") {
      setSelectedContainer(null);
    }
    setShowRole(true);
  };

  const dispatch = useDispatch();

  const containersList = useSelector((state: AppState) => {
    return state.developerManagementReducerData.containersList;
  });

  const deleteContainersData = useSelector((state: AppState) => {
    return state.developerManagementReducerData.deleteContainer;
  });
  const deleteContainerErr = useSelector((state: AppState) => {
    return state.developerManagementReducerData.deleteContainerError;
  });

  useEffect(() => {
    dispatch(showContainerSpinner());
    dispatch(getAllContainers(currentPage, searchText));
    setErrors({});
  }, [deleteContainersData]);

  useEffect(() => {
    setshowPage(true);
  }, [containersList]);

  const onPageChange = (pageNo: number, searchData?: any) => {
    dispatch(showContainerSpinner());
    setCurrentPage(pageNo);
    dispatch(
      getAllContainers(
        pageNo,
        searchData !== undefined ? searchData : searchText
      )
    );
  };

  const _onMenuClick = (text: string, container: any) => {
    if (
      constants.getPermissionsbyItem("algorithm.container.delete") &&
      text.toLowerCase() === "delete"
    ) {
      setSelectedContainerId(container.uuid);
      showDeleteContainer();
    }
    if (
      constants.getPermissionsbyItem("algorithm.container.edit") &&
      text.toLowerCase() === "edit"
    ) {
      setSelectedContainer(container);
      toggleModal("edit");
    }
  };

  const showDeleteContainer = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);

  const handleDeleteContainer = () => {
    setDisabledCreate(true);
    dispatch(deleteContainer(selectedContainerId));
    setOpenDeleteModal((current) => !current);
  };

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.loaderWithMessageContainer;
  });
  const searchChangeHandler = useCallback(
    debounce((evt) => {
      dispatch(getAllContainers(1, evt));
    }, 2000),
    []
  );

  const handleContainerSearch = (evt: any) => {
    const value = evt?.target?.value || "";
    setSearchText(value);
    if (value.length > 3 || value.length === 0) {
      dispatch(showContainerSpinner());
      //  searchChangeHandler(value);
      setshowPage(false);
      onPageChange(1, value);
    }
  };

  const resetChoice = React.useCallback(() => {
    dispatch(eraseDeleteContainerData());
  }, []);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_CONTAINERS_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleContainerList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_CONTAINERS_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleContainerList(false);
    }
  }, [loaderWithMessage]);

  return (
    <Stack className="deviceManagement">
      {deleteContainerErr && (
        <ShowErrorMessageBar
          resetChoice={resetChoice}
          errorResponse={deleteContainerErr}
        ></ShowErrorMessageBar>
      )}
      {deleteContainersData && (
        <MessageBar
          delayedRender={false}
          isMultiline={false}
          messageBarType={MessageBarType.success}
          dismissButtonAriaLabel="Close"
          onDismiss={resetChoice}
          className="errorMessages"
        >
          Container Deleted Successfully!
        </MessageBar>
      )}
      {deleteContainersData &&
        setTimeout(() => {
          resetChoice();
        }, 3000) && <></>}
      {deleteContainerErr &&
        setTimeout(() => {
          resetChoice();
        }, 3000) && <></>}
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item className="heading">
          <Text>Add Container</Text>
        </Stack.Item>
        <SearchBox
          className="searchBox activitySearch"
          placeholder="Search"
          underlined={true}
          onChange={(e) => handleContainerSearch(e)}
        />
      </Stack>
      <Stack.Item className="addDevice">
        <CheckPermission {...constants.SCOPES.DEVELOPER_CREATE_CONTAINER}>
          <DefaultButton
            onClick={(e) => toggleModal("add")}
            text="Add Container"
            iconProps={addIcon}
            allowDisabledFocus
          />
        </CheckPermission>
      </Stack.Item>
      {showRole && (
        <AddContainer
          isModalOpen={true}
          selectedContainer={selectedContainer}
          newOrEdit={selectedContainer ? "edit" : "new"}
          hideModal={() => setShowRole(false)}
        ></AddContainer>
      )}
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">All Containers</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text>Algorithms</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1">
              <Text variant="smallPlus">Models</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1">
              <Text variant="smallPlus">Version</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text variant="smallPlus">Docker Image</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1">
              <Text variant="smallPlus">Is Developer</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Created Date</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Created By</Text>
            </Stack.Item>
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
          {visibleContainerList &&
            containersList?.results?.map((container: any, index: any) => (
              <Stack.Item key={index} className="ms-Grid-row tableText">
                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text
                    block
                    variant="smallPlus"
                    title={container?.algorithm_name}
                  >
                    {container?.algorithm_name}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1 textEllipses">
                  <Text block variant="smallPlus" title={container?.model_name}>
                    {container?.model_name}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1 ">
                  <Text variant="smallPlus" title={container?.model_version}>
                    {container?.model_version}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                  <Text
                    variant="smallPlus"
                    title={`${container?.name} : ${container?.tag}`}
                  >
                    {container?.name} : {container?.tag}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1 textEllipses">
                  <Text
                    variant="smallPlus"
                    title={String(container?.is_developer)}
                  >
                    {String(container?.is_developer)}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">
                    {moment(container?.created_date).format("MM/DD/YY HH:mm")}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1 textEllipses">
                  <Text variant="smallPlus" title={container?.created_by}>
                    {container?.created_by}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1">
                  <Text variant="smallPlus">
                    <IconButton
                      className="buttonIcon"
                      menuIconProps={moreIcon}
                      menuProps={{
                        items: getMenuItems(),
                        onItemClick: (e: any) =>
                          _onMenuClick(e?.target?.innerText, container),
                      }}
                      ariaLabel="New item"
                    />
                  </Text>
                </Stack.Item>
              </Stack.Item>
            ))}
        </Stack.Item>
        <DeleteModal
          isModalOpen={openDeleteModal}
          deleteHandle={handleDeleteContainer}
          showModal={showDeleteContainer}
          id={selectedContainerId}
          name="Container"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        {visibleContainerList &&
        containersList?.count > 10 &&
        containersList?.page_size &&
        showPage ? (
          <Paging
            currentPage={currentPage}
            totalPageCount={Math.ceil(containersList?.count / 10)}
            handlePageChange={onPageChange}
          />
        ) : null}
      </Stack>
      <Modal isOpen={closeContainer} containerClassName={"modalStyle"}>
        <SuccessMessage title={createOrUpdate} />
      </Modal>
      {closeContainer &&
        setTimeout(() => {
          dispatch(getAllContainers(currentPage, searchText));
          setShowRole(false);
          dispatch(showContainerSpinner());
        }, 3000) && <></>}
    </Stack>
  );
};

export default Containers;
