import {
  CommandBarButton,
  MessageBar,
  MessageBarType,
  Stack,
} from "@fluentui/react";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../rootReducer";
import { downloadActivity } from "../../Logs/actions/logsActions";
import moment from "moment";
import { apiEndPoint, doHttpCall } from "../../../common/restApi";
const switch_account = localStorage.getItem("switch_account");
const userRole = localStorage.getItem("role") === "Developer" ? true : false;
interface IExampleProps {
  resetChoice?: () => void;
}
const DownloadReport = ({
  type,
  name,
  startDate = "",
  endDate = "",
  clearDatePickValues,
  getMessageBar,
}: any) => {
  const activityDownload = async () => {
    if (type === "custom" && !startDate && !endDate) {
      alert("please select start and end date.");
      return;
    }
    type =
      type === "custom"
        ? `custom&start_date=${moment(startDate).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(endDate).format("YYYY-MM-DD")}`
        : type;

    let url: any;
    if (localStorage.getItem("switch_account") == "true") {
      url =
        apiEndPoint +
        `/activities/download?sort=${type}` +
        "&developer=" +
        userRole;
    } else {
      url = apiEndPoint + `/activities/download?sort=${type}`;
    }

    const payload = {
      method: "GET",
      url: url,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      getMessageBar(true);
      // var csv =
      //   response?.data?.results &&
      //   Papa.unparse(response?.data?.results, { header: true });
      // const downloadBlobUrl = window.URL.createObjectURL(
      //   new Blob([csv], { type: "text/csv" })
      // );
      // const link = document.createElement("a");
      // link.href = downloadBlobUrl;
      // link.setAttribute("download", downloadBlobUrl);
      // link.download = `activity${+new Date()}.csv`;
      // document.body.appendChild(link);
      // link.click();
      // clearDatePickValues();
    }
  };
  return (
    <>
      <CommandBarButton
        onClick={activityDownload}
        className=" ms-fon ms-fontWeight-bold"
        text={name}
        // disabled={!startDate || !endDate}
      />
    </>
  );
};
export default DownloadReport;
