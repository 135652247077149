import { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useModulePermissions() {
  const [permissions, setPermissions] = useState<any>([]);
  const userPermissions =
    useSelector(
      (state: any) => state.authReducerData?.loggedInUserData?.permissions
    ) || "";
  const permissionsArr = userPermissions?.split(",");
  useEffect(() => {
    setPermissions(permissionsArr);
  }, [permissionsArr.length]);

  return [permissions];
}

export default useModulePermissions;
