// npm dependencies
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Stylesheets
import "./index.scss";

// Internal dependencies
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { storeInstance } from "./store";
import "./theme";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={storeInstance}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
