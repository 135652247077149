import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
  TextField,
  StackItem,
  Label,
} from "@fluentui/react";
import Select from "react-select";

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import "../Devices/Devices.scss";
import {
  getFacilitiesList,
  createFacility,
  getFacilityById,
  editFacilityById,
  deleteFacility,
  cancelFacilityError,
} from "../../actions/managementAction";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import {
  getLocationWithZipcode,
  showSpinner,
} from "../../../../ActiveAdminModule/Home/actions/homeAction";
import Paging from "../../../../common/paging/Paging";
import {
  DELETE_FACILITY_ERROR,
  GET_FACILITY_BY_ID,
} from "../../actions/actionConstants";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import ToggleCard from "../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import { debounce } from "lodash";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
import { object } from "prop-types";

const getMenuItems = () => {
  return [
    {
      key: "edit",
      text: "Edit",
      title: constants.getPermissionsbyItem("management.facility.edit")
        ? ""
        : constants.permission,
      // scope: [SCOPES.EDIT_FACILITY],
    },
    {
      key: "delete",
      text: "Delete",
      title: constants.getPermissionsbyItem("management.facility.delete")
        ? ""
        : constants.permission,
      // scope: [SCOPES.DELETE_FACILITY],
    },
  ];
};

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };
const options1 = [
  { value: "1", label: "Northeast" },
  { value: "2", label: "Ohio Valley" },
  { value: "3", label: "Southeast" },
  { value: "4", label: "South Central" },
  { value: "5", label: "Upper Midwest" },
  { value: "6", label: "West" },
  { value: "7", label: "Mountain" },
  { value: "8", label: "Pacific Northwest" },
];

const Facility = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: {},
  } = useForm();
  const [data, setData] = useState<{
    name: string;
    region: string;
    address1: string;
    address2: string;
    zipcode: string;
    city: string;
    country: string;
    state: string;
    unique_identifier: string;
  }>({
    name: "",
    region: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    zipcode: "",
    state: "",
    unique_identifier: "",
  });

  const [errors, setErrors] = useState<{
    name?: string;
    region?: string;
    address1?: string;
    address2?: string;
    zipcode?: string;
    city?: string;
    country?: string;
    state?: string;
    unique_identifier?: string;
  }>({});

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showFaciltiyDeleteError, setshowFacilityDeleteError] =
    useState<boolean>(false);
  const [notFound, setNotFound] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [facilityId, setFacilityId] = useState<string | any>("1");
  const [facilityCreationPermission, setfacilityCreationPermission] =
    React.useState<any>(false);
  const closeRoles = useSelector((state: AppState) => {
    return state.managementReducerData.closeRoles;
  });

  const dispatch = useDispatch();

  const facilitiesList = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.facilitiesList;
  });

  const facilityData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.facilityData;
  });

  const deleteFacilityData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.deleteFacility;
  });

  const deleteFacilityError = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.deleteFacilityError;
  });

  const editFacilityData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.editFacilityById;
  });
  const facilityById = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.facilityById;
  });
  const createFacilityError = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.createFacilityError;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.loaderWithMessageFacility;
  });

  const locationData = useSelector((state: AppState) => {
    return state?.homeReducerData?.locationData?.data;
  });
  useEffect(() => {
    if (locationData) {
      setNotFound(locationData && locationData[0]?.details);
    }
  });
  useEffect(() => {
    const permission = constants.getPermissionsbyItem(
      "management.facility.create"
    );
    setfacilityCreationPermission(permission);
  }, [disabledCreate]);
  useEffect(() => {
    setDisabledCreate(false);
    let interval: any = null;
    if (deleteFacilityError) {
      setshowFacilityDeleteError(true);
      interval = setTimeout(() => clearFacilityError(), 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [deleteFacilityError, disabledCreate]);

  const clearFacilityError = () => {
    setshowFacilityDeleteError(false);
    dispatch({
      type: DELETE_FACILITY_ERROR,
      payload: null,
    });
  };

  useEffect(() => {
    if (locationData) {
      setData((prevValues) => {
        return {
          ...prevValues,
          state: locationData.state_name,
          city: locationData.city_name,
          country: locationData.country_name,
          region: locationData.region_name,
        };
      });
    }
  }, [locationData]);

  useEffect(() => {
    if (createFacilityError && showMessage) {
      setShowError(true);
      setShowSuccess(false);
      setShowUpdate(false);
      setTimeout(() => {
        setShowError(false);
        setShowMessage(false);
      }, 3000);
    }
  }, [createFacilityError, showMessage]);
  useEffect(() => {
    if (facilityData && showMessage && !Object.keys(errors).length) {
      setShowError(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setShowMessage(false);
      }, 3000);
    }
  }, [facilityData]);

  useEffect(() => {
    if (facilityData && showMessage) {
      setShowError(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setShowMessage(false);
      }, 3000);
    }
    dispatch(getFacilitiesList());
    // }, [deleteFacilityData, facilityData]);
  }, [facilityData]);

  useEffect(() => {
    if (editFacilityData && showMessage) {
      setShowError(false);
      setShowUpdate(true);
      setTimeout(() => {
        setShowUpdate(false);
        setShowMessage(false);
      }, 3000);
    }
    dispatch(getFacilitiesList());
  }, [editFacilityData]);

  useEffect(() => {
    const { address = "", ...otherFields } = facilityById || {};
    const addressArr = address?.split("--");
    const formFeildsData = {
      ...otherFields,
      address1: addressArr[0],
      address2: addressArr[1],
    };
    setData({ ...formFeildsData });
  }, [facilityById]);

  const onPageChange = useCallback((pageNo) => {
    setCurrentPage(pageNo);
    dispatch(getFacilitiesList(pageNo));
  }, []);

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);

  const deleteHandle = () => {
    setDisabledCreate(true);
    // dispatch(deleteFacility(facilityId));
    dispatch(
      deleteFacility(
        facilityId,
        facilitiesList?.results?.length === 1 ? currentPage - 1 : currentPage
      )
    );
    setOpenDeleteModal((current) => !current);
  };

  const _onMenuClick = useCallback((text: string, id: string | any) => {
    setErrors({});
    if (
      constants.getPermissionsbyItem("management.facility.delete") &&
      text.toLowerCase() === "delete"
    ) {
      // dispatch(deleteFacility(id));
      setFacilityId(id);
      showDeleteModal();
    }
    if (
      constants.getPermissionsbyItem("management.facility.edit") &&
      text.toLowerCase() === "edit"
    ) {
      dispatch(getFacilityById(id));
      setfacilityCreationPermission(true);
    }
  }, []);
  const clearFields = () => {
    setData({
      name: "",
      region: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      zipcode: "",
      state: "",
      unique_identifier: "",
    });
  };

  useEffect(() => {
    clearFields();
    dispatch(getFacilitiesList());
    clearFacilityError();
    // }, [deleteFacilityData, facilityData]);
  }, [facilityData]);
  useEffect(() => {
    return () => {
      clearFields();
      dispatch({
        type: GET_FACILITY_BY_ID,
        payload: { data: null },
      });
    };
  }, []);

  const onCreate = useCallback(() => {
    setDisabledCreate(true);
    const { name, region, address1, zipcode, unique_identifier } = data;
    const errors: any = {};
    if (!name || name?.trim()?.length === 0) {
      errors.name = "Name should not be empty.";
    }
    if (!address1 || address1?.trim()?.length === 0) {
      errors.address1 = "Address1 should not be empty.";
    }
    if (!zipcode || zipcode?.trim()?.length === 0) {
      errors.zipcode = "Zipcode should not be empty.";
    }
    if (!unique_identifier || unique_identifier?.trim()?.length === 0) {
      errors.unique_identifier = "Net Suite Number should not be empty.";
    }

    if (Object.keys(errors).length === 0) {
      const updatedData: any = {
        ...data,
        address: data.address1 + "--" + (data.address2 || ""),
        name: data?.name?.trim(),
      };
      delete updatedData.address1;
      delete updatedData.address2;
      facilityById
        ? dispatch(editFacilityById(facilityById?.fid, updatedData))
        : dispatch(createFacility(updatedData));
      // ( createFacilityError===null&&  facilityById&&  clearFields())
    }
    setErrors({ ...errors });
  }, [data]);

  const getFormattedAddress = (address: string | undefined) => {
    if (typeof address?.replace === "function") {
      return address.replace("--", ",");
    }
    return address;
  };

  const getLocationWithDebounce = useCallback(
    debounce((e: any) => {
      dispatch(getLocationWithZipcode(e));
    }, 1500),
    []
  );

  const zipCodeChangeHandler = (e: any, data: any) => {
    const zipValue = e?.target?.value;
    data.zipcode = zipValue;
    setErrors((prevErrors) => {
      prevErrors.zipcode = "";
      return prevErrors;
    });
    setData({ ...data });
    getLocationWithDebounce(zipValue);
  };
  // useEffect(() => {
  //   createFacilityError = false;
  // }, []);
  return (
    <Stack className="deviceManagement" style={{ width: 1030 }}>
      <Stack.Item className="heading">
        <Text>Hospital Other Facilities</Text>
      </Stack.Item>
      <ToggleCard
        heading={
          facilityById ? "Update Hospital Facility" : "Create Hospital Facility"
        }
        index=""
        removeFormFields=""
      >
        <Stack.Item className="ms-Grid-row">
          <Stack>
            <Stack.Item className="specialityForm">
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Facility Name*"
                    disabled={!facilityCreationPermission}
                    onChange={(e: any) => {
                      dispatch(cancelFacilityError(null));
                      data.name = e?.target?.value;
                      setData({ ...data });
                      setErrors((prevErrors) => {
                        prevErrors.name = "";
                        return prevErrors;
                      });
                    }}
                    value={data.name}
                  />
                  {console.log(errors)}
                  <Label className="errorMessage">{errors?.name}</Label>
                  {createFacilityError ===
                    "Facility name already exist for the hospital" &&
                    showError && (
                      <Label className="errorMessage">
                        {createFacilityError}
                      </Label>
                    )}
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Net Suite Number*"
                    disabled={!facilityCreationPermission}
                    onChange={(e: any) => {
                      !facilityById?.fid && dispatch(cancelFacilityError(null));
                      data.unique_identifier = e?.target?.value;
                      setErrors((prevErrors) => {
                        prevErrors.unique_identifier = "";
                        return prevErrors;
                      });
                      setData({ ...data });
                    }}
                    value={data.unique_identifier}
                  />
                  <Label className="errorMessage">
                    {errors?.unique_identifier}
                  </Label>
                  {createFacilityError ===
                    "facilities with this unique identifier already exists." &&
                    showError && (
                      <Label className="errorMessage">
                        {/* {createFacilityError} */}
                        Net suite number already exist
                      </Label>
                    )}
                  {/* {showError && (
                    <Label className="errorMessage">
                      {createFacilityError}
                    </Label>
                  )} */}
                </StackItem>
              </Stack>

              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Address1*"
                    disabled={!facilityCreationPermission}
                    onChange={(e: any) => {
                      data.address1 = e?.target?.value;
                      setErrors((prevErrors) => {
                        prevErrors.address1 = "";
                        return prevErrors;
                      });
                      setData({ ...data });
                    }}
                    value={data.address1}
                  />
                  <Label className="errorMessage">{errors?.address1}</Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    autoComplete="off"
                    label="Region"
                    disabled
                    value={data.region}
                    onChange={(e: any) => {
                      data.region = e?.target?.value;
                      setData({ ...data });
                      setErrors({});
                    }}
                    name="region"
                  />
                  <Label className="errorMessage">{errors?.region}</Label>
                </StackItem>
              </Stack>
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Address2"
                    disabled={!facilityCreationPermission}
                    onChange={(e: any) => {
                      data.address2 = e?.target?.value;
                      setErrors({});
                      setData({ ...data });
                    }}
                    value={data.address2}
                  />
                  <Label className="errorMessage">{errors?.address2}</Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Zip Code*"
                    disabled={!facilityCreationPermission}
                    onChange={(e: any) => zipCodeChangeHandler(e, data)}
                    value={data.zipcode}
                    onKeyPress={(event) => {
                      if (event.key.replace(/[0-9]/, "")) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <Label className="errorMessage">{errors?.zipcode}</Label>
                  <Label className="errorMessage">
                    {data?.zipcode ? notFound : ""}
                  </Label>
                </StackItem>
              </Stack>
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="City"
                    disabled
                    onChange={(e: any) => {
                      data.city = e?.target?.value;
                      setErrors({});
                      setData({ ...data });
                    }}
                    value={data.city}
                  />
                  <Label className="errorMessage">{errors?.city}</Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Country"
                    disabled
                    onChange={(e: any) => {
                      data.zipcode = e?.target?.value;
                      setErrors({});
                      setData({ ...data });
                    }}
                    value={data.country}
                  />
                  <Label className="errorMessage">{errors?.country}</Label>
                </StackItem>
              </Stack>
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="State"
                    disabled
                    onChange={(e: any) => {
                      data.state = e?.target?.value;
                      setErrors({});
                      setData({ ...data });
                    }}
                    value={data.state}
                  />
                  <Label className="errorMessage">{errors?.state}</Label>
                </StackItem>
              </Stack>
              {/* {showError && (
              <Label className="errorMessage">{createFacilityError}</Label>
            )} */}

              {showSuccess && (
                // <Label className="errorMessage">
                //   Facility created successfully
                // </Label>
                <Modal isOpen={showSuccess} containerClassName={"modalStyle"}>
                  <SuccessMessage title="Facility Creation" />
                </Modal>
              )}
              {showUpdate && (
                // <Label className="errorMessage">
                //   Facility created successfully
                // </Label>
                <Modal isOpen={showUpdate} containerClassName={"modalStyle"}>
                  <SuccessMessage title="Facility Updation" />
                </Modal>
              )}
              <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="end"
                tokens={{ childrenGap: 50 }}
                styles={{ root: { display: "flex", padding: "10px 30px" } }}
              >
                {/* <StackItem>
                <Text>Cancel</Text>
              </StackItem> */}
                <StackItem>
                  {facilityById !== null ? (
                    <Stack
                      horizontal
                      horizontalAlign="end"
                      tokens={{ childrenGap: 10 }}
                    >
                      <StackItem align="auto">
                        <DefaultButton
                          disabled={disabledCreate}
                          className="createActiveBtn"
                          text="Update"
                          onClick={() => {
                            onCreate();
                            setShowMessage(true);
                          }}
                        >
                          {/* <Text className="spinnerRight">
                            {disabledCreate && (
                              <Spinner size={SpinnerSize.small} />
                            )}
                          </Text> */}
                        </DefaultButton>
                      </StackItem>
                      <StackItem align="auto">
                        <DefaultButton
                          className="createActiveBtn"
                          text="Cancel"
                          onClick={() => {
                            clearFields();
                            setShowMessage(true);
                            dispatch({
                              type: GET_FACILITY_BY_ID,
                              payload: { data: null },
                            });
                          }}
                        />
                      </StackItem>
                    </Stack>
                  ) : (
                    // <DefaultButton
                    //   className="createActiveBtn"
                    //   text="Update"
                    //   onClick={() => {
                    //     onCreate();
                    //     setShowMessage(true);
                    //   }}
                    // />

                    <Stack
                      horizontal
                      horizontalAlign="end"
                      tokens={{ childrenGap: 10 }}
                    >
                      <StackItem align="auto">
                        <DefaultButton
                          disabled={disabledCreate}
                          className="createActiveBtn"
                          text="Create"
                          title={
                            constants.getPermissionsbyItem(
                              "management.facility.create"
                            )
                              ? ""
                              : constants.permission
                          }
                          onClick={() => {
                            if (
                              constants.getPermissionsbyItem(
                                "management.facility.create"
                              )
                            ) {
                              onCreate();
                              setShowMessage(true);
                            }
                          }}
                        >
                          {/* <Text className="spinnerRight">
                            {disabledCreate && (
                              <Spinner size={SpinnerSize.small} />
                            )}
                          </Text> */}
                        </DefaultButton>
                      </StackItem>
                      <StackItem align="auto">
                        <DefaultButton
                          className="createActiveBtn"
                          text="Cancel"
                          onClick={() => {
                            clearFields();
                            setShowMessage(true);
                          }}
                        />
                      </StackItem>
                    </Stack>
                  )}
                </StackItem>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </ToggleCard>
      {showFaciltiyDeleteError && (
        <ShowErrorMessageBar
          resetChoice={() => clearFacilityError()}
          errorResponse={deleteFacilityError}
        ></ShowErrorMessageBar>
      )}
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">Available Facilities List</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text>Facility Name</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Address</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Region</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Zip Code</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Created Date</Text>
            </Stack.Item>
          </Stack.Item>
          {facilitiesList?.results?.map((facility: any, index: any) => (
            <Stack.Item key={index} className="ms-Grid-row tableText">
              <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                <Text variant="smallPlus">{facility?.name}</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                <Text variant="smallPlus">
                  {/* {getFormattedAddress(facility?.address)} */}
                  {facility?.address?.split("--")?.[0]}
                  {facility?.address?.split("--")?.[1] !== "" &&
                    ", " + facility?.address?.split("--")?.[1]}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text variant="smallPlus">{facility?.region}</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text variant="smallPlus">{facility?.zipcode}</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text variant="smallPlus">
                  {moment(facility?.created_date).format("MM/DD/YY HH:mm")}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg1">
                <Text variant="smallPlus">
                  <IconButton
                    className="buttonIcon"
                    menuIconProps={moreIcon}
                    menuProps={{
                      items: getMenuItems(),
                      onItemClick: (e: any) =>
                        _onMenuClick(e?.target?.innerText, facility?.fid),
                    }}
                    ariaLabel="New item"
                  />
                </Text>
              </Stack.Item>
            </Stack.Item>
          ))}
        </Stack.Item>
        <DeleteModal
          isModalOpen={openDeleteModal}
          deleteHandle={deleteHandle}
          showModal={showDeleteModal}
          id={facilityId}
          name="Facility"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        {facilitiesList?.count > 10 && facilitiesList?.page_size ? (
          <Paging
            currentPage={currentPage}
            totalPageCount={Math.ceil(facilitiesList?.count / 10)}
            handlePageChange={onPageChange}
          />
        ) : null}
      </Stack>
      {useEffect(() => {
        onPageChange(currentPage);
      }, [showSuccess || showUpdate])}
    </Stack>
  );
};

export default Facility;
