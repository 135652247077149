import React, { useEffect } from "react";
import { Stack } from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import AdminFullView from "./AdminFullView";
import { useParams } from "react-router-dom";
import { getHospitalAdminDetailsByUserID } from "../../actions/homeAction";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const AdminView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const hospitalAdminById = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.hospitalAdminById;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loaderWithMessage;
  });

  useEffect(() => {
    dispatch(getHospitalAdminDetailsByUserID(id));
  }, []);

  return (
    <Stack>
      <AdminFullView id={id} admin={hospitalAdminById}></AdminFullView>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
    </Stack>
  );
};

export default AdminView;
