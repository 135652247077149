import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  Icon,
  CommandBarButton,
  SearchBox,
  ActionButton,
  Toggle,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../rootReducer";
import Paging from "../../../common/paging/Paging";
import {
  downloadActivity,
  getActivity,
  getActivityType,
  getLogFacilities,
  getLogHospitals,
  resetActivity,
} from "../../Logs/actions/logsActions";
import { getHospitals } from "../../../ActiveAdminModule/Home/actions/homeAction";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useBoolean } from "@fluentui/react-hooks";
import DownloadReport from "./DownloadReport";
import { debounce } from "lodash";
import CheckPermission from "../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../Layout/constants/constants";
import LoaderWithMessage from "../../../common/customLoader/LoaderWithMessage";

const ActiveAdminActivity = () => {
  const { register, handleSubmit, control } = useForm();
  const dispatch = useDispatch();
  const [currentLogsPage, setCurrentLogsPage] = useState<number>(1);
  const [hospitalFilter, setHospitalFilter] = useState<any | null>(null);
  const [activityType, setActivityType] = useState<any | null>(null);
  const [sortOptions, setSortOptions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [startDatePick, setStartDatePick] = useState<any>();
  const [endDatePick, setEndDatePick] = useState<any>();
  const [messageBar, setMessageBar] = useState(false);
  const innerRef = useRef<any>();
  const [dateValue, setdateValue] = useState<any>();
  const [dateString, setDateString] = useState<string>();
  const [facilityId, setFacilityId] = useState<any>();
  const [showPage, setshowPage] = useState<any>(true);
  const [visibleActivityList, setVisibleActivityList] = useState(false);
  const [visibleHospitalList, setVisibleHospitalList] = useState(false);

  const hospitalid = useRef();

  useEffect(() => {
    dispatch(getHospitals());
  }, []);

  const toggleShowError = () => {
    setShowError(!showError);
    setStartDatePick(null);
    setEndDatePick(null);
  };
  const activityTypes = useSelector((state: AppState) => {
    return state.logsReducerData?.activityTypes?.data;
  });

  const logFacilities = useSelector((state: AppState) => {
    return state.logsReducerData?.facilities?.data;
  });

  const logHospitals = useSelector((state: AppState) => {
    return state.logsReducerData?.hospitals?.data;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessageActivity;
  });

  const activityTypesCount = activityTypes?.count;
  const logHospitalsCount = logHospitals?.count;

  const logFacilityFilter = logFacilities?.results?.map(
    (logFacilitie: any) => ({
      label: logFacilitie.name,
      value: logFacilitie.id,
    })
  );
  const logHospitalFilter = logHospitals?.results?.map((logHospital: any) => ({
    label: logHospital.name,
    value: logHospital.id,
  }));

  const uniqueHospitalOptions = Array.from(
    new Set(logHospitalFilter?.map((eachHospital: any) => eachHospital.value))
  ).map((value) => {
    return logHospitalFilter?.find(
      (eachHospital: any) => eachHospital.value === value
    );
  });

  const activityTypeFilterlist = activityTypes?.results?.map(
    (activityType: any) => ({
      label: activityType.activity_log_type,
      value: activityType.id,
    })
  );
  const activityTypeFilter = activityTypeFilterlist?.filter(
    (ele: any, ind: any) =>
      ind ===
      activityTypeFilterlist.findIndex((elem: any) => elem.value === ele.value)
  );
  const activities = useSelector((state: AppState) => {
    return state.logsReducerData.activities.data;
  });
  const clearDatePickValues = () => {
    setStartDatePick("");
    setEndDatePick("");
  };
  const handleSortOptions = () => {
    setSortOptions(!sortOptions);
    setShowError(false);
  };
  // const onViewLogsPageChange = useCallback(
  //   (pageNo) => {
  //     setCurrentLogsPage(pageNo);
  //     // let dt = dateValue ? moment.utc(dateValue).format("YYYY-MM-DD") : "";
  //     let dt = dateValue
  //       ? moment.utc(dateValue).format("YYYY-MM-DDTHH:mm:ss")
  //       : "";
  //     // dispatch(getActivity(pageNo, "", "", "", dt));
  //     // dispatch(getActivity(pageNo, "", hospitalid.current, "", dt));
  //     dispatch(
  //       getActivity(pageNo, activityType, hospitalid.current, facilityId, dt)
  //     );
  //   },
  //   [dateValue]
  // );

  const onViewLogsPageChange = (pageNo: any) => {
    setCurrentLogsPage(pageNo);
    // let dt = dateValue ? moment.utc(dateValue).format("YYYY-MM-DD") : "";
    const dt = dateValue
      ? moment.utc(dateValue).format("YYYY-MM-DDTHH:mm:ss")
      : ""; // dispatch(getActivity(pageNo, "", "", "", dt)); // dispatch(getActivity(pageNo, "", hospitalid.current, "", dt));
    dispatch(
      getActivity(pageNo, activityType, hospitalid.current, facilityId, dt)
    );
  };

  useEffect(() => {
    dispatch(getActivity());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetActivity());
    };
  }, []);

  useEffect(() => {
    dispatch(getActivityType(activityTypesCount));
    dispatch(getLogHospitals(logHospitalsCount));
  }, [activityTypesCount, logHospitalsCount]);

  const getMessageBar = (value: any) => {
    setMessageBar(value);
    setSortOptions(!sortOptions);
  };

  const handleDateChange = (date: any) => {
    // let dt = date ? moment.utc(date).format("YYYY-MM-DD") : "";
    setshowPage(false);
    const dt = date ? moment.utc(date).format("YYYY-MM-DDTHH:mm:ss") : "";
    setdateValue(date);
    setDateString(dt);
    setCurrentLogsPage(1);
    dispatch(getActivity(1, activityType, hospitalFilter, facilityId, dt));
    setTimeout(() => {
      setshowPage(true);
    }, 1000);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        sortOptions &&
        innerRef?.current &&
        !innerRef?.current?.contains(e?.target)
      ) {
        setSortOptions(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      // debugger;
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [sortOptions]);

  const activityTypeInputHandler = (evt: any) => {
    debounce(() => {
      dispatch(getActivityType("", evt?.target.value));
    }, 2000);
  };
  const actityTypeInputChangeHandler = (evt: any) => {
    // activityTypeInputHandler(evt);
    if (evt?.target?.value?.length > 2 || !evt?.target?.value) {
      dispatch(getActivityType("", evt?.target.value));
    }
  };

  // const handleScrollActivityType = (evt: any) => {
  //   if (activityTypes?.next) {
  //     dispatch(getActivityType(activityTypes.next));
  //   }
  // };

  const hospitalInputHandler = useCallback(
    debounce((evt: any) => {
      dispatch(getLogHospitals("", evt));
    }, 2000),
    []
  );
  const hospitalInputChangeHandler = (evt: any) => {
    // hospitalInputHandler(evt);
    const value = evt?.target.value;
    if (evt?.target.value.length > 2 || evt?.target.value === 0) {
      dispatch(getLogHospitals("", value));
    }
  };

  const hospitalInputChangeHandler1 = (e: any) => {
    // hospitalInputHandler(evt);

    dispatch(getLogHospitals("", ""));
  };

  // const handleScrollHospital = (evt: any) => {
  //   if (logHospitals?.next) {
  //     dispatch(getLogHospitals(logHospitals.next));
  //   }
  // };

  const loaderWithMessageHospitals = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessageHospitals;
  });

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_ACTIVITY" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleActivityList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_ACTIVITY" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleActivityList(false);
    }

    if (
      loaderWithMessageHospitals &&
      loaderWithMessageHospitals?.calledFrom === "GET_HOSPITALS" &&
      loaderWithMessageHospitals?.statusCode !== 403 &&
      !loaderWithMessageHospitals?.loadingState
    ) {
      setVisibleHospitalList(true);
    }

    if (
      loaderWithMessageHospitals?.calledFrom === "GET_HOSPITALS" &&
      loaderWithMessageHospitals?.statusCode == 403
    ) {
      setVisibleHospitalList(false);
    }
  }, [loaderWithMessage, loaderWithMessageHospitals]);
  //console.log(loaderWithMessageHospitals,'loaderWithMessageHospitals')

  return (
    <Stack className="viewLogFiles deviceManagement activitySectionMain">
      {messageBar && (
        <MessageBar
          delayedRender={false}
          isMultiline={false}
          messageBarType={MessageBarType.success}
          dismissButtonAriaLabel="Close"
          onDismiss={() => setMessageBar(false)}
          className="errorMessages"
        >
          You will get a mail shortly
        </MessageBar>
      )}
      {messageBar &&
        setTimeout(() => {
          setMessageBar(false);
        }, 5000) && <></>}
      <Stack.Item className="totalSection  ms-Grid" dir="ltr">
        <Stack.Item className="chartSection ms-Grid-row">
          {/* ---------------map section------------------- */}
          <Stack.Item className=" ms-Grid-col ms-lg3">
            <Stack.Item className="label">
              <Text>Activity Type</Text>
            </Stack.Item>
            <Controller
              control={control}
              name="algorithmVersion[0].algorithm_id"
              shouldUnregister
              render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                  <Select
                    classNamePrefix="addl-class"
                    isClearable
                    options={activityTypeFilter}
                    value={activityTypeFilter?.find(
                      (c: any) => c.value === value
                    )}
                    onKeyDown={(e) => actityTypeInputChangeHandler(e)}
                    onChange={(val) => {
                      onChange(val?.value);
                      setActivityType(val?.label);
                      setCurrentLogsPage(1);
                      dispatch(
                        getActivity(
                          1,
                          val?.label,
                          hospitalFilter,
                          facilityId,
                          dateString
                        )
                      );
                      // setHideDropdownValue(true);
                    }}
                  />
                );
              }}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg3">
            <Stack.Item className="label">
              <Text>Hospital</Text>
            </Stack.Item>
            <Controller
              control={control}
              defaultValue=""
              shouldUnregister
              name="Hospital"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isDisabled={!visibleHospitalList}
                  placeholder={
                    !visibleHospitalList
                      ? "You do not have permission"
                      : "Select"
                  }
                  isClearable
                  classNamePrefix="addl-class"
                  options={uniqueHospitalOptions}
                  value={logHospitalFilter?.find((c: any) => c.value === value)}
                  onKeyDown={(e) => hospitalInputChangeHandler(e)}
                  // onMenuScrollToBottom={(e) => handleScrollHospital(e)}
                  onBlur={(e) => hospitalInputChangeHandler1(e)}
                  onChange={(val) => {
                    hospitalid.current = val?.value;
                    onChange(val?.value);
                    setHospitalFilter(val?.value);
                    setCurrentLogsPage(1);
                    setFacilityId(undefined);
                    if (!val?.value) {
                      dispatch(
                        getActivity(
                          1,
                          activityType,
                          val?.value,
                          undefined,
                          dateString
                        )
                      );
                    } else {
                      setCurrentLogsPage(1);
                      dispatch(
                        getActivity(
                          1,
                          activityType,
                          val?.value,
                          undefined,
                          dateString
                        )
                      );
                    }
                    dispatch(getLogFacilities(val?.value, "", 500));
                  }}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg2">
            <Stack.Item className="label">
              <Text>Facility</Text>
            </Stack.Item>
            <Controller
              control={control}
              defaultValue=""
              shouldUnregister
              name="Facility"
              // clearValue={}
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  isClearable
                  classNamePrefix="addl-class"
                  options={logFacilityFilter}
                  value={
                    facilityId
                      ? logFacilityFilter?.find((c: any) => c.value === value)
                      : null
                  }
                  onInputChange={(val) => {
                    dispatch(getLogFacilities(hospitalFilter, val, 500));
                  }}
                  onChange={(val) => {
                    onChange(val?.value);
                    setFacilityId(val?.value);
                    setCurrentLogsPage(1);
                    dispatch(
                      getActivity(
                        1,
                        activityType,
                        hospitalFilter,
                        val?.value,
                        dateString
                      )
                    );
                  }}
                  isDisabled={!logFacilityFilter?.length || !hospitalFilter}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item className=" ms-Grid-col">
            <Stack.Item className="activitydatelabel">
              <Text>From Date</Text>
            </Stack.Item>
            <DatePicker
              selected={dateValue}
              onChange={handleDateChange}
              selectsStart
              isClearable={true}
              maxDate={new Date()}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col displayFlex  float-right downloadBtn searchBoxDownloadSection">
            <Stack.Item className="btnDropdownWidth">
              <div ref={innerRef}>
                <CheckPermission {...SCOPES.DOWNLOAD_ACTIVITIES}>
                  <Text block onClick={handleSortOptions}>
                    <Icon
                      iconName="download"
                      className=" ms-fontSize-16 ms-fontWeight-bold"
                    />
                    Download Report
                  </Text>
                </CheckPermission>
                {sortOptions ? (
                  <Stack className="dropdownDownload btnDropdownWidth">
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"lw"}
                        name={"Last Week"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"1m"}
                        name={"Last 1 Month"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"3m"}
                        name={"Last 3 Months"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"6m"}
                        name={"Last 6 Months"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Toggle
                      label=""
                      onText="Custom"
                      offText="Custom"
                      checked={showError}
                      onChange={toggleShowError}
                    />
                    <Stack.Item key={startDatePick}>
                      {showError && (
                        <>
                          <DatePicker
                            onChange={(date: any) => setStartDatePick(date)}
                            selected={startDatePick}
                            isClearable
                            placeholderText="Start Date"
                            className="ms-mb-10"
                            maxDate={
                              endDatePick ? endDatePick : moment().toDate()
                            }
                          />
                          <DatePicker
                            selected={endDatePick}
                            onChange={(date: any) => setEndDatePick(date)}
                            isClearable
                            placeholderText="End Date"
                            maxDate={moment().toDate()}
                            minDate={startDatePick}
                          />
                          <ActionButton allowDisabledFocus>
                            <DownloadReport
                              type={"custom"}
                              startDate={startDatePick}
                              endDate={endDatePick}
                              clearDatePickValues={clearDatePickValues}
                              name={"Submit"}
                              getMessageBar={getMessageBar}
                            />
                          </ActionButton>
                        </>
                      )}
                    </Stack.Item>
                  </Stack>
                ) : (
                  ""
                )}
              </div>
            </Stack.Item>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
      <Stack>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text>Activity</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Activity by</Text>
            </Stack.Item>
            <Stack.Item
              className=" ms-Grid-col ms-lg5"
              style={{
                width: "400px",
                marginRight: "20px",
              }}
            >
              <Text variant="smallPlus">Hospital / Facility</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg2">
              <Text variant="smallPlus">Activity Time</Text>
            </Stack.Item>
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
          {visibleActivityList &&
            activities?.results?.map((activity: any, index: any) => (
              <Stack.Item key={index} className="ms-Grid-row tableText">
                <Stack.Item></Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                  {/* <Checkbox onChange={_onChange} /> */}
                  <Text block title={activity?.log_type} variant="smallPlus">
                    {activity?.log_type}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text block title={activity?.activity_by} variant="smallPlus">
                    {activity?.activity_by}
                  </Text>
                </Stack.Item>

                <Stack.Item
                  className="ms-Grid-col ms-lg5 textNoWrap"
                  style={{
                    width: "400px",
                    marginRight: "20px",
                  }}
                >
                  <Text block className="hospitalCardText">
                    <span>
                      <abbr
                        className="ms-bb-0"
                        title={activity?.hospital_facility}
                      >
                        {activity?.hospital_facility
                          ? activity?.hospital_facility
                          : "-----"}
                      </abbr>
                    </span>
                  </Text>

                  {/* <Text variant="smallPlus">{activity?.hospital_facility}</Text> */}
                </Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">
                    {/* {moment(activity?.date).format("DD/MM/YY HH:mm")} */}
                    {moment
                      .utc(activity?.date)
                      .local()
                      .format("MM/DD/YY HH:mm")}
                  </Text>
                </Stack.Item>
              </Stack.Item>
            ))}
          {visibleActivityList &&
          activities?.count > 20 &&
          activities?.page_size &&
          showPage ? (
            <Paging
              currentPage={currentLogsPage}
              totalPageCount={Math.ceil(
                activities?.count / activities?.page_size
              )}
              handlePageChange={onViewLogsPageChange}
            />
          ) : null}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default ActiveAdminActivity;
