import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Stack,
  SearchBox,
  Text,
  IconButton,
  IIconProps,
  IContextualMenuProps,
  Icon,
  Checkbox,
  ActionButton,
} from "@fluentui/react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import {
  getViewLogById,
  getViewLogs,
  downloadMultipleLogs,
} from "../../actions/logsActions";
import { useNavigate } from "react-router-dom";
import ViewLogDetails from "./ViewLogDetails";
import { Controller } from "react-hook-form";
import Paging from "../../../../common/paging/Paging";
import { debounce } from "lodash";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const moreIcon: IIconProps = { iconName: "MoreVertical" };
const menuProps: IContextualMenuProps = {
  items: [
    {
      key: "edit",
      text: "Edit",
    },
    {
      key: "delete",
      text: "Delete",
    },
  ],
};
const ViewLogFiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibleActivityList, setVisibleActivityList] = useState(false);

  const [viewLogsModel, setViewLogsModel] = useState(false);
  const [logId, setLogId] = useState<number>();
  const [headerCheckboxChecked, setHeaderCheckboxChecked] = useState(false);
  const [logs, setLogs] = useState<any>([]);
  const [currentLogsPage, setCurrentLogsPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [showPage, setshowPage] = useState<any>(true);
  const [selectedLogs, setSelectedLogs] = useState<any>([]);
  const [maintainHeaderCheckbox, setMaintainHeaderCheckBox] = useState<any>({});

  const viewLogs = useSelector((state: AppState) => {
    return state.logsReducerData.viewLogs.data;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessageLogs;
  });
  useEffect(() => {
    if (viewLogs && viewLogs.results) {
      viewLogs.results.forEach((item: any) => {
        if (!item.hasOwnProperty("checked")) {
          item.checked = false;
        }
        return item;
      });
      setLogs(viewLogs.results);
    } else {
      setLogs([]);
    }
  }, [viewLogs]);
  const viewLogDetails = useSelector((state: AppState) => {
    return state.logsReducerData.viewLogDetails.data;
  });

  const onViewLogsPageChange = (pageNo: number, search?: any) => {
    setCurrentLogsPage(pageNo);
    setHeaderCheckboxChecked(false);
    dispatch(getViewLogs(search === undefined ? searchText : search, pageNo));
  };

  const handleViewLogModel = useCallback((logsId: number) => {
    // console.log(logsId,'logsId');
    dispatch(getViewLogById(logsId));

    setLogId(logsId);
    setViewLogsModel((current) => !current);
  }, []);
  // const openViewModel = useCallback(() => {
  //   setViewLogsModel((current) => !current);
  //   debugger;
  // },[logId]);

  const searchChangeHandler = useCallback(
    debounce((evt) => {
      dispatch(getViewLogs(evt, 1));
    }, 2000),
    []
  );

  const handleSearchViewLogs = (e: any) => {
    setshowPage(false);
    const searchValue = e?.target?.value || "";
    setSearchText(searchValue);

    if (searchValue?.length > 3 || searchValue?.length == 0) {
      searchChangeHandler(searchValue);
    }
    setCurrentLogsPage(1);
    setTimeout(() => {
      setshowPage(true);
    }, 1000);
  };
  function _onChange(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) {}

  useEffect(() => {
    dispatch(getViewLogs());
  }, []);

  const headerCheckboxClick = (e: any) => {
    e.stopPropagation();
    setHeaderCheckboxChecked(e.target.checked);
    const tempLogs = logs;
    tempLogs.map((item: any) => (item.checked = e.target.checked));
    setLogs([...tempLogs]);
    manageSelectedHeaderCheckBoxEveryPage(e.target.checked);
  };
  const rowCheckboxClick = (logItem: any, e: any) => {
    e.stopPropagation();
    const checkVal = e.target.checked;
    const tempList = logs;
    tempList.map((item: any) => {
      if (item.id === logItem.id) {
        item.checked = checkVal;
      }
      return item;
    });
    setLogs([...tempList]);
    manageSelectedLogsEveryPage(checkVal, logItem);
    if (!checkVal) {
      setHeaderCheckboxChecked(false);
      setMaintainHeaderCheckBox({ [currentLogsPage]: false });
    }
  };
  const manageSelectedHeaderCheckBoxEveryPage = (checkValue: any) => {
    const tempLogs = logs;
    tempLogs.map((item: any) => (item.checked = checkValue));
    const primaryRemoved = selectedLogs;
    tempLogs.forEach((element: any) => {
      const getRemoveIndex: any = primaryRemoved.findIndex(
        (index: any) => index.id === element.id
      );
      getRemoveIndex >= 0 && primaryRemoved.splice(getRemoveIndex, 1);
    });
    if (checkValue) {
      setSelectedLogs(primaryRemoved.concat(tempLogs));
      const obj = maintainHeaderCheckbox;
      obj[currentLogsPage] = checkValue;
      setMaintainHeaderCheckBox(obj);
    } else {
      setSelectedLogs(primaryRemoved);
      const obj = maintainHeaderCheckbox;
      obj[currentLogsPage] = checkValue;
      setMaintainHeaderCheckBox(obj);
    }
  };
  const manageSelectedLogsEveryPage = (checkval: any, item: any) => {
    const getExistingSelected: any = selectedLogs;
    const itemId = item?.id;
    if (checkval) {
      getExistingSelected.push(item);
    } else {
      const unSelected: any = getExistingSelected.findIndex(
        (index: any) => index.id === itemId
      );
      getExistingSelected.splice(unSelected, 1);
    }
    setSelectedLogs(getExistingSelected);
  };
  const getSelectedRowCheckbox = (id: any) => {
    const getExistingSelected: any = selectedLogs;
    return getExistingSelected.some((e: any) => e.id === id);
  };
  const getSelectedHeaderCheckbox = () => {
    const page = currentLogsPage;
    return maintainHeaderCheckbox[page] ? maintainHeaderCheckbox[page] : false;
  };
  const handleMultiLogsDownload = () => {
    // let selectedLogs = logs.filter((log: any) => log.checked) || [];
    const selectedLogsAllPage =
      selectedLogs.filter((log: any) => log.checked) || [];
    if (selectedLogsAllPage.length) {
      setshowPage(false);
      // setSearchText("");
      // dispatch(downloadMultipleLogs(selectedLogs));
      dispatch(downloadMultipleLogs(selectedLogsAllPage));
      // setHeaderCheckboxChecked(false);
      // setCurrentLogsPage(1);
      // onViewLogsPageChange(1, "");
    }
    setSelectedLogs([]);
    const tempLogs = [...logs];
    setTimeout(() => {
      tempLogs.forEach((t) => (t.checked = false));
      setshowPage(true);
      //   setLogs(tempLogs);
    }, 2000);
  };
  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_VIEW_LOGS" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleActivityList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_VIEW_LOGS" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleActivityList(false);
    }
  }, [loaderWithMessage]);

  return (
    <Stack className="viewLogFiles deviceManagement">
      <Stack.Item className="totalSection  ms-Grid" dir="ltr">
        <Stack.Item className="chartSection ms-Grid-row">
          {/* ---------------map section------------------- */}
          <Stack.Item className=" ms-Grid-col ms-lg4">
            <SearchBox
              placeholder="Search"
              onChange={handleSearchViewLogs}
              value={searchText}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col float-right downloadBtn">
            {
              <CheckPermission {...SCOPES.DEVELOPER_LOGS_DOWNLOAD}>
                <ActionButton onClick={handleMultiLogsDownload}>
                  <Icon
                    iconName="download"
                    className=" ms-fontSize-16 ms-fontWeight-bold"
                  />
                  Download
                </ActionButton>
              </CheckPermission>
            }
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
      <Stack>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item
              className=" ms-Grid-col  ms-lg1"
              style={{ padding: "4px" }}
            >
              <Checkbox
                className="ml-12 log-checkbox"
                checked={getSelectedHeaderCheckbox()}
                onChange={headerCheckboxClick}
              />
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text>Device Serial Number</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg4">
              <Text variant="smallPlus">Log</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Date & Time stamp</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg2">
              <Text variant="smallPlus">Hospital / Facility</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg1">
              <Text variant="smallPlus">Status</Text>
            </Stack.Item>
          </Stack.Item>

          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>

          {visibleActivityList &&
            logs?.map((viewLog: any, index: number) => (
              <Stack.Item key={viewLog?.id} className="ms-Grid-row tableText">
                <Stack.Item
                  className=" ms-Grid-col  ms-lg1"
                  style={{ padding: "4px" }}
                >
                  <Checkbox
                    className="ml-12 log-checkbox"
                    // checked={viewLog.checked}
                    checked={getSelectedRowCheckbox(viewLog?.id)}
                    onChange={(evt: any) => rowCheckboxClick(viewLog, evt)}
                  />
                </Stack.Item>
                <Stack.Item
                  className="ms-Grid-col ms-lg2"
                  onClick={() => handleViewLogModel(viewLog?.id)}
                >
                  {/* <Checkbox onChange={_onChange} /> */}
                  <Text variant="smallPlus">{viewLog?.device_id}</Text>
                </Stack.Item>
                <Stack.Item
                  className="ms-Grid-col ms-lg4"
                  onClick={() => handleViewLogModel(viewLog?.id)}
                >
                  {/* <Text variant="smallPlus">{viewLog?.original_file_name}</Text> */}
                  <Text variant="smallPlus">{viewLog?.original_file_name}</Text>
                </Stack.Item>
                <Stack.Item
                  className="ms-Grid-col ms-lg2"
                  onClick={() => handleViewLogModel(viewLog?.id)}
                >
                  <Text variant="smallPlus">
                    {moment(viewLog?.created_date).format("MM/DD/YY HH:mm")}
                  </Text>
                </Stack.Item>
                <Stack.Item
                  className="ms-Grid-col ms-lg2 textEllipses"
                  onClick={() => handleViewLogModel(viewLog?.id)}
                >
                  <Text title={viewLog?.hospital} variant="smallPlus">
                    {viewLog?.hospital}
                  </Text>
                </Stack.Item>

                <Stack.Item
                  className="ms-Grid-col ms-lg1"
                  onClick={() => handleViewLogModel(viewLog?.id)}
                >
                  <Text variant="smallPlus">{viewLog?.assigned_by} Active</Text>
                </Stack.Item>
              </Stack.Item>
            ))}
          {visibleActivityList &&
          viewLogs?.count > 20 &&
          viewLogs?.page_size &&
          showPage ? (
            <Paging
              currentPage={currentLogsPage}
              totalPageCount={Math.ceil(viewLogs?.count / 20)}
              handlePageChange={onViewLogsPageChange}
            />
          ) : null}
          <ViewLogDetails
            isModalOpen={viewLogsModel}
            showModal={handleViewLogModel}
            viewLogDetails={viewLogDetails}
          ></ViewLogDetails>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default ViewLogFiles;
