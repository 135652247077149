export const DEVICE_INCREMENT = "DEVICE_INCREMENT";
export const DEVICE_DECREMENT = "DEVICE_DECREMENT";
export const DEVICE_DATA = "DEVICE_DATA";
export const GET_DEVICES_LIST = "GET_DEVICES_LIST";
export const CREATE_DEVICES = "CREATE_DEVICES";
export const GET_DEVICE_VIEW = "GET_DEVICE_VIEW";
export const SHOW_CREATE_DEVICES = "SHOW_CREATE_DEVICES";
export const EDIT_DEVICES = "EDIT_DEVICES";

export const ROLE_RESET = "ROLE_RESET";
export const ROLE_INCREMENT = "ROLE_INCREMENT";
export const ROLE_DECREMENT = "ROLE_DECREMENT";
export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_PERMISSION_LIST = "GET_PERMISSION_LIST";
export const ROLESPERMISSION_DATA = "ROLESPERMISSION_DATA";
export const CREATE_ROLESPERMISSION = "CREATE_ROLESPERMISSION";
export const SPINNER_ROLE = "SPINNER_ROLE";
export const SPINNER_DEVICE = "SPINNER_DEVICE";
export const CLOSE_DEVICE_MODAL = "CLOSE_DEVICE_MODAL";
export const DELETE_DEVICES = "DELETE_DEVICES";
export const CLOSE_ROLE_MODAL = "CLOSE_ROLE_MODAL";
export const GET_PROCEDURE_LIST = "GET_PROCEDURE_LIST";
export const CREATE_PROCEDURE = "CREATE_PROCEDURE";
export const GET_SPECIALTY_LIST = "GET_SPECIALTY_LIST";
export const CREATE_SPECIALTY = "CREATE_SPECIALTY";
export const SPECIALTY_DATA = "SPECIALTY_DATA";

export const GET_ALGORITHM_LIST = "GET_ALGORITHM_LIST";
export const GET_ALGORITHM_VERSION_LIST = "GET_ALGORITHM_VERSION_LIST";
export const CREATE_ALGORITHM = "CREATE_ALGORITHM";
export const PROCEDURE_DATA = "PROCEDURE_DATA";
export const ERROR_IN_PROCEDURE = "ERROR_IN_PROCEDURE";
export const CLOSE_ERRORS = "CLOSE_ERRORS";
export const ERROR_IN_SPECIALTY = "ERROR_IN_SPECIALTY";
export const CREATE_ALGORITHM_VERSION = "CREATE_ALGORITHM_VERSION";
export const DELETE_PROCEDURE = "DELETE_PROCEDURE";
export const DELETE_ALGORITHAM = "DELETE_ALGORITHAM";
export const DELETE_ALGORITHAM_VERSION = "DELETE_ALGORITHAM_VERSION";
export const DELETE_ROLE_PERMISSION = "DELETE_ROLE_PERMISSION";
export const GET_ALGORITHM_MODEL_IMAGE_LIST = "GET_ALGORITHM_MODEL_IMAGE_LIST";

export const GET_WORKFLOW_LIST = "GET_WORKFLOW_LIST";
export const PROCEDURE_DATA_ID = "PROCEDURE_DATA_ID";
export const GET_ALGORITHM_TYPE_LIST = "GET_ALGORITHM_TYPE_LIST";
export const GET_BUSINESS_ALGORITHM_LIST = "GET_BUSINESS_ALGORITHM_LIST";
export const REFRESH_BUSINESS_ALGORITHM_LIST =
  "REFRESH_BUSINESS_ALGORITHM_LIST";
export const ALGORITHAM_VIEW = "ALGORITHAM_VIEW";
export const UPDATE_INPUT_PARAMETER_DATA = "UPDATE_INPUT_PARAMETER_DATA";
export const GET_ALGORITHM_OUTPUT_LIST = "GET_ALGORITHM_OUTPUT_LIST";
export const CREATE_WORKFLOW = "CREATE_WORKFLOW";
export const GET_ROLES_VIEW = "GET_ROLES_VIEW";
export const SHOW_CREATE_ROLE = "SHOW_CREATE_ROLE";
export const EDIT_ROLES = "EDIT_ROLES";
export const CLOSE_ALGORITHM_MODAL = "CLOSE_ALGORITHM_MODAL";
export const CLOSE_ALGORITHM_VERSION_MODAL = "CLOSE_ALGORITHM_VERSION_MODAL";
export const GET_ALGORITHM_VIEW = "GET_ALGORITHM_VIEW";
export const GET_ALGORITHM_VERSION_VIEW = "GET_ALGORITHM_VERSION_VIEW";
export const SHOW_CREATE_ALGORITHM = "SHOW_CREATE_ALGORITHM";
export const SHOW_CREATE_ALGORITHM_VERSION = "SHOW_CREATE_ALGORITHM_VERSION";
export const EDIT_ALOGRITHM = "EDIT_ALOGRITHM";
export const EDIT_ALOGRITHM_VERSION = "EDIT_ALOGRITHM_VERSION";
export const CLOSE_WORKFLOW_MODAL = "CLOSE_WORKFLOW_MODAL";
export const SHOW_CREATE_WORKFLOW = "SHOW_CREATE_WORKFLOW";
export const WORKFLOW_DATA = "WORKFLOW_DATA";
export const GET_WORKFLOW_INPUT_TYPE_LIST = "GET_WORKFLOW_INPUT_TYPE_LIST";
export const GET_WORKFLOW_TYPE_LIST = "GET_WORKFLOW_TYPE_LIST";
export const CREATE_WORKFLOW_MAPPING = "CREATE_WORKFLOW_MAPPING";
export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
export const GET_WORKFLOW_VIEW = "GET_WORKFLOW_VIEW";
export const GET_PROCEDURE_VIEW = "GET_PROCEDURE_VIEW";
export const CLEAR_PROCEDURE_DATA = "CLEAR_PROCEDURE_DATA";
export const EDIT_PROCEDURE = "EDIT_PROCEDURE";
export const UPDATE_OUTPUT_ARGUMENTS = "UPDATE_OUTPUT_ARGUMENTS";
export const LOADER = "LOADER";
export const GET_LOGIN_USER_PROFILE_MANAGAMENT =
  "GET_LOGIN_USER_PROFILE_MANAGAMENT";
export const EDIT_WORKFLOW = "EDIT_WORKFLOW";
export const CREATE_SINGLE_PROCEDURE = "CREATE_SINGLE_PROCEDURE";
export const GET_PROCEDURE = "GET_PROCEDURE";
export const CREATE_PROCEDURE_SPACIALTY = "CREATE_PROCEDURE_SPACIALTY";
export const CLEAR_BUSINESS_ALGORITHM_LIST = "CLEAR_BUSINESS_ALGORITHM_LIST";
export const GET_ROLE_LOCATIONS = "GET_ROLE_LOCATIONS";
export const ERROR_WORKFLOW = "ERROR_WORKFLOW";

export const SAVE_FIRST_STEP_DATA = "SAVE_FIRST_STEP_DATA";
export const ERROR_ALG = "ERROR_ALG";

export const SELECTED_ROLE_NAME = "SELECTED_ROLE_NAME";
export const SELECTED_ROLE_LOCATION = "SELECTED_ROLE_LOCATION";
export const SELECTED_PERMISSION_USER_TYPE = "SELECTED_PERMISSION_USER_TYPE";
export const SELECTED_ROLE_COMMENTS = "SELECTED_ROLE_COMMENTS";
export const IS_PERMISSION_LIST_FETCHED = "IS_PERMISSION_LIST_FETCHED";
export const STOPLOADER = "STOPLOADER";
export const ERROR_IN_PROCEDURE_CREATE = "ERROR_IN_PROCEDURE_CREATE";
export const CLEAR_PROCEDURE = "CLEAR_PROCEDURE";
