import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Label,
  Pivot,
  PivotItem,
  IPivotItemProps,
  Modal,
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
// import "../Devices/Devices.scss"
// import { getDeviceList } from "../../../Management/actions/managamentActions";
import {
  algorithmDelete,
  algorithmVersionDelete,
  closeAlgorithmModal,
  closeAlgorithmVersionModal,
  getAlgorithmList,
  getAlgorithmVersionList,
  getAlgorithmVersionView,
  getAlgorithmView,
  getDeviceList,
  showCreateAlgorithm,
  showCreateAlgorithmVersion,
  showRoleSpinner,
} from "../../actions/managamentActions";
import { AppState } from "../../../../rootReducer";
import {
  IconButton,
  ActionButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
import moment from "moment";
import CreateAlgorithm from "./CreateAlgorithm";
import CreateAlogrithmVersion from "./CreateAlogrithmVersion";
import "./Algorithm.scss";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import DeleteModal from "../../../Home/components/Common/DeleteModal";
import Paging from "../../../../common/paging/Paging";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Algorithm = () => {
  const [visibleAlgorithamList, setVisibleAlgorithamList] = useState(false);
  const [visibleAlgorithamVersionList, setVisibleAlgorithamVersionList] =
    useState(false);

  const [showAlgorithm, setShowAlgorithm] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [showAlgorithmVersion, setShowAlgorithmVersion] = useState(false);
  const [lastHeader, setLastHeader] = useState<
    { props: IPivotItemProps } | undefined
  >(undefined);
  const [openDeleteAlgorithmModal, setOpenDeleteAlgorithmModal] =
    useState(false);
  const [openDeleteAlgorithmVersionModal, setOpenDeleteAlgorithmVersionModal] =
    useState(false);
  const [algorithmId, setAlgorithmId] = useState("");
  const [algorithmVersionId, setAlgorithmVersionId] = useState<any>();
  const [currentAlgorithmPage, setCurrentAlgorithmPage] = useState<number>(1);
  const [currentAlgorithmVersionPage, setCurrentAlgorithmVersionPage] =
    useState<number>(1);
  const [algorithmData, setAlgorithmData] = useState({});
  const [showDialogOnEdit, setShowDialogOnEdit] = useState(false);
  const [showDialogOnDelete, setShowDialogOnDelete] = useState(false);

  const onAlgorithmPageChange = useCallback((pageNo) => {
    setCurrentAlgorithmPage(pageNo);
    dispatch(getAlgorithmList("", pageNo));
  }, []);

  const onAlgorithVersionmPageChange = useCallback((pageNo) => {
    setCurrentAlgorithmVersionPage(pageNo);
    dispatch(getAlgorithmVersionList(pageNo));
  }, []);

  const showDeleteAlgorithmModal = useCallback(() => {
    setOpenDeleteAlgorithmModal((current) => !current);
    setDisabledCreate(false);
  }, []);
  const showDeleteAlgorithmVersionModal = useCallback(() => {
    setOpenDeleteAlgorithmVersionModal((current) => !current);
    setDisabledCreate(false);
  }, []);
  const deleteAlgorithmHandle = () => {
    setDisabledCreate(true);
    dispatch(algorithmDelete(algorithmId));
    setOpenDeleteAlgorithmModal((current) => !current);
  };
  const deleteAlgorithmVersionHandle = () => {
    setDisabledCreate(true);
    dispatch(algorithmVersionDelete(algorithmVersionId));
    setOpenDeleteAlgorithmVersionModal((current) => !current);
  };
  const showModal = () => {
    setShowAlgorithm((current) => !current);
    if (!algorithmId) {
      dispatch(closeAlgorithmModal());
    }
  };
  const closeModal = () => {
    setShowAlgorithm(false);
    dispatch(closeAlgorithmModal());
  };
  const showModalVersion = () => {
    setShowAlgorithmVersion((current) => !current);
    // if (!algorithmVersionId) {
    //   dispatch(closeAlgorithmVersionModal());
    // }
    dispatch(closeAlgorithmVersionModal());
  };
  const closeModalVersion = () => {
    setShowAlgorithmVersion(false);
    dispatch(closeAlgorithmVersionModal());
  };
  const showEditModal = (id: string) => {
    dispatch(getAlgorithmView(id));
    dispatch(showCreateAlgorithm());
    setShowAlgorithm((current) => !current);
    if (!id) {
      dispatch(closeAlgorithmModal());
    }
  };

  const showEditVersionModal = () => {
    dispatch(getAlgorithmVersionView(algorithmVersionId));
    dispatch(showCreateAlgorithmVersion());
    setShowAlgorithmVersion((current) => !current);
    if (!algorithmVersionId) {
      dispatch(closeAlgorithmVersionModal());
    }
  };

  const menuPropsfn = (obj: any) => {
    return [
      {
        key: "edit",
        text: "Edit",
        title: constants.getPermissionsbyItem("algorithm.edit")
          ? ""
          : constants.permission,
      },
      {
        key: "delete",
        text: "Delete",
        title: constants.getPermissionsbyItem("algorithm.delete")
          ? obj?.mapped
          : constants.permission,
        disabled: obj?.mapped,
      },
    ];
  };
  const algorithmVersionPropsfn = (obj: any) => {
    return {
      items: [
        {
          key: "edit",
          text: "Edit",
          // onClick: showEditVersionModal,
          onClick: constants.getPermissionsbyItem(
            "algorithm.algorithm_version.edit"
          )
            ? showEditVersionModal
            : void 0,
          title: constants.getPermissionsbyItem(
            "algorithm.algorithm_version.edit"
          )
            ? ""
            : constants.permission,
        },
        {
          key: "delete",
          text: "Delete",
          onClick: constants.getPermissionsbyItem(
            "algorithm.algorithm_version.delete"
          )
            ? showDeleteAlgorithmVersionModal
            : void 0,
          title: obj.is_active
            ? "Active versions can not be deleted"
            : constants.getPermissionsbyItem(
                "algorithm.algorithm_version.delete"
              )
            ? ""
            : constants.permission,
          disabled: obj.is_active,
          // scope: [SCOPES.DELETE_ALGORITHM_VERSION],
          // onClick: constants.getPermissionsbyItem("algorithm.algorithm_version.delete") ? showDeleteAlgorithmVersionModal: null
        },
      ],
    };
  };
  const _onMenuClickSetId = (id: any) => {
    setAlgorithmId(id);
  };
  const _onMenuClickSetAlgorithmVersionId = (id: any) => {
    setAlgorithmVersionId(id);
  };

  const _onMenuClick = (text: string, algorithm: any) => {
    setAlgorithmId(algorithm.id);
    setAlgorithmData(algorithm);
    if (
      text.toLowerCase() === "delete" &&
      constants.getPermissionsbyItem("algorithm.delete")
    ) {
      setShowDialogOnDelete(true);
    } else if (
      text.toLowerCase() === "edit" &&
      constants.getPermissionsbyItem("algorithm.edit")
    ) {
      // showEditModal(algorithm.id);
      setShowDialogOnEdit(true);
    } else {
      return false;
    }
  };

  const onProceedClickDelete = () => {
    setShowDialogOnDelete(false);
    showDeleteAlgorithmModal();
  };

  const onProceedClick = () => {
    setShowDialogOnEdit(false);
    showEditModal(algorithmId);
  };

  const dispatch = useDispatch();
  const algorithms = useSelector((state: AppState) => {
    return state.managementReducerData?.algorithmList?.data;
  });
  const algorithmVersion = useSelector((state: AppState) => {
    return state.managementReducerData?.algorithmVersionList?.data;
  });
  const algorithmSpinner = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmSpinner;
  });
  const algorithmVersionSpinner = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmVersionSpinner;
  });

  const deleteAlgorithm = useSelector((state: AppState) => {
    return state.managementReducerData.deleteAlgorithm?.data;
  });
  const deleteAlgorithmVersion = useSelector((state: AppState) => {
    return state.managementReducerData.deleteAlgorithmVersion?.data;
  });
  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });

  const showCreateAlgorithms = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateAlgorithm;
  });

  const showCreateAlgorithmVerison = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateAlgorithmVerison;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageAlgorithms;
  });

  const loaderWithMessageAlgorithamVersion = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageAlgorithmsVersion;
  });

  useEffect(() => {
    dispatch(getAlgorithmList("", currentAlgorithmPage));
  }, [deleteAlgorithm]);
  useEffect(() => {
    if (algorithmSpinner || closeWhenErrors) {
      dispatch(getAlgorithmList("", currentAlgorithmPage));
    }
  }, [algorithmSpinner, closeWhenErrors]);
  useEffect(() => {
    dispatch(getAlgorithmVersionList(currentAlgorithmVersionPage));
  }, [deleteAlgorithmVersion]);
  useEffect(() => {
    dispatch(getAlgorithmList("", currentAlgorithmPage));
  }, [lastHeader]);
  useEffect(() => {
    if (algorithmSpinner || closeWhenErrors) {
      dispatch(getAlgorithmList("", currentAlgorithmPage));
    }
  }, [algorithmSpinner, closeWhenErrors]);
  useEffect(() => {
    if (algorithmVersionSpinner || closeWhenErrors) {
      dispatch(getAlgorithmVersionList(currentAlgorithmVersionPage));
    }
  }, [algorithmVersionSpinner, closeWhenErrors]);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_ALGORITHM_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleAlgorithamList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_ALGORITHM_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleAlgorithamList(false);
    }
    if (
      loaderWithMessageAlgorithamVersion &&
      loaderWithMessageAlgorithamVersion?.calledFrom ===
        "GET_ALGORITHM_VERSION_LIST" &&
      loaderWithMessageAlgorithamVersion?.statusCode !== 403 &&
      !loaderWithMessageAlgorithamVersion?.loadingState
    ) {
      setVisibleAlgorithamVersionList(true);
    }
    if (
      loaderWithMessageAlgorithamVersion?.calledFrom ===
        "GET_ALGORITHM_VERSION_LIST" &&
      loaderWithMessageAlgorithamVersion?.statusCode == 403
    ) {
      setVisibleAlgorithamVersionList(false);
    }
  }, [loaderWithMessage, loaderWithMessageAlgorithamVersion]);

  return (
    <>
      <Stack className="deviceManagement">
        <Stack.Item className="heading">
          <Text>Algorithm Management</Text>
        </Stack.Item>
        {lastHeader?.props.headerText === "Algorithms" ||
        lastHeader?.props.headerText === undefined ? (
          <Stack.Item className="addDevice">
            <CheckPermission {...constants.SCOPES.ACTIVADMIN_ALGORITHM_CREATE}>
              <DefaultButton
                onClick={(e) => {
                  setAlgorithmId("");
                  showModal();
                }}
                text="Add New Algorithm"
                iconProps={addIcon}
                allowDisabledFocus
              />
            </CheckPermission>
          </Stack.Item>
        ) : (
          <Stack.Item className="addDevice">
            <CheckPermission {...constants.SCOPES.CREATE_ALGORITHM_VERSION}>
              <DefaultButton
                onClick={showModalVersion}
                text="Add Algorithm Version"
                iconProps={addIcon}
                allowDisabledFocus
              />
            </CheckPermission>
          </Stack.Item>
        )}
        {showAlgorithm && (
          <CreateAlgorithm
            isModalOpen={showAlgorithm}
            showModal={() => closeModal()}
            algorithmId={algorithmId}
            // algData={algorithmData}
          ></CreateAlgorithm>
        )}
        {showDialogOnEdit && (
          <Modal
            className="warning-dialog"
            isModeless={true}
            isOpen={showDialogOnEdit}
            containerClassName={"smallModalStyle"}
            isBlocking={false}
          >
            <Stack className="deleteSection">
              <Stack.Item className="heading pb-20">
                <Text variant="xLarge">Warning</Text>
              </Stack.Item>
              <div className="pb-20">
                <Text>
                  Editing An Algorithm Needs Change To All The Workflows That
                  used It As Well.
                </Text>
              </div>
              <Text className="pb-20">Still Want To Proceed?</Text>
              <Stack dir="ltr" horizontal verticalAlign="center">
                <PrimaryButton
                  onClick={() => setShowDialogOnEdit(false)}
                  className="btn-next"
                >
                  Cancel
                </PrimaryButton>
                <ActionButton
                  text="Proceed"
                  className="btn-proceed btn-c"
                  allowDisabledFocus
                  onClick={onProceedClick}
                />
              </Stack>
            </Stack>
          </Modal>
        )}

        {showDialogOnDelete && (
          <Modal
            className="warning-dialog"
            isModeless={true}
            isOpen={showDialogOnDelete}
            containerClassName={"smallModalStyle"}
            isBlocking={false}
          >
            <Stack className="deleteSection">
              <Stack.Item className="heading pb-20">
                <Text variant="xLarge">Warning</Text>
              </Stack.Item>
              <div className="pb-20">
                <Text>
                  Deleting An Algorithm Needs Change To All The Workflows That
                  used It As Well.
                </Text>
              </div>
              <Text className="pb-20">Still Want To Proceed?</Text>
              <Stack dir="ltr" horizontal verticalAlign="center">
                <PrimaryButton
                  onClick={() => setShowDialogOnDelete(false)}
                  className="btn-next"
                >
                  Cancel
                </PrimaryButton>
                <ActionButton
                  text="Proceed"
                  className="btn-proceed btn-c"
                  allowDisabledFocus
                  onClick={onProceedClickDelete}
                />
              </Stack>
            </Stack>
          </Modal>
        )}

        {showAlgorithmVersion && (
          <CreateAlogrithmVersion
            isModalOpen={showAlgorithmVersion}
            showModal={() => closeModalVersion()}
            algorithmVersionId={algorithmVersionId}
          ></CreateAlogrithmVersion>
        )}

        <Pivot aria-label="Basic Pivot Example" onLinkClick={setLastHeader}>
          {/* ------------------Algorithms---------------------- */}
          <PivotItem
            headerText="Algorithms"
            headerButtonProps={{
              "data-order": 1,
              "data-title": "My Files Title",
            }}
          >
            <Stack className="availableDeviceList ">
              <Stack.Item className="deviceSubHeading">
                <Text variant="xLarge">Available Algorithms</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid" dir="ltr">
                <Stack.Item className="ms-Grid-row tableHeading">
                  <Stack.Item className=" ms-Grid-col ms-lg2">
                    <Text>Algorithm Name</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg3">
                    <Text variant="smallPlus">Description</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg2">
                    <Text variant="smallPlus">Is Business Algorithm</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg2">
                    <Text variant="smallPlus">Created Date</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col  ms-lg1-point-5">
                    <Text variant="smallPlus">Created by</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col  ms-lg1-point-5">
                    <Text variant="smallPlus"></Text>
                  </Stack.Item>
                </Stack.Item>
                {visibleAlgorithamList &&
                  algorithms?.results?.map((algorithm: any) => (
                    <Stack.Item
                      key={algorithm?.id}
                      className="ms-Grid-row tableText"
                    >
                      <Stack.Item className="ms-Grid-col ms-lg2 textEllipses_name">
                        <Text block title={algorithm?.name} variant="smallPlus">
                          {algorithm?.name}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                        <Text
                          block
                          title={algorithm?.description}
                          variant="smallPlus"
                        >
                          {algorithm?.description}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg2">
                        <Text variant="smallPlus">
                          {algorithm?.is_core_business ? "Yes" : "No"}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg2">
                        <Text variant="smallPlus">
                          {moment(algorithm?.published_on).format(
                            "MMM D YYYY HH:mm"
                          )}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg1-point-5 textEllipses">
                        <Text
                          variant="smallPlus"
                          block
                          title={algorithm?.created_by}
                        >
                          {algorithm?.created_by}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg1">
                        <Text variant="smallPlus">
                          <IconButton
                            className="buttonIcon"
                            menuIconProps={moreIcon}
                            menuProps={{
                              items: menuPropsfn(algorithm),
                              onItemClick: (e: any) =>
                                _onMenuClick(e?.target?.innerText, algorithm),
                            }}
                            // onMenuClick={() => _onMenuClickSetId(algorithm?.id)}
                          />
                        </Text>
                      </Stack.Item>
                    </Stack.Item>
                  ))}
              </Stack.Item>
              {visibleAlgorithamList &&
              algorithms?.count > 10 &&
              algorithms?.page_size ? (
                <Paging
                  currentPage={currentAlgorithmPage}
                  totalPageCount={Math.ceil(algorithms?.count / 10)}
                  handlePageChange={onAlgorithmPageChange}
                />
              ) : null}
            </Stack>
          </PivotItem>
          {/* ------------------Algorithm Versions ---------------------- */}
          <PivotItem headerText="Algorithm Versions">
            <Stack className="availableDeviceList ">
              <Stack.Item className="deviceSubHeading">
                <Text variant="xLarge">Algorithm Versions</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid" dir="ltr">
                <Stack.Item className="ms-Grid-row tableHeading">
                  <Stack.Item className=" ms-Grid-col ms-lg2">
                    <Text>Algorithm Name</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg1">
                    <Text variant="smallPlus">Version</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg1">
                    <Text variant="smallPlus">Model</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg2-point-5">
                    <Text variant="smallPlus">Docker Image</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col  ms-lg2">
                    <Text variant="smallPlus">Created Date</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col  ms-lg1-point-5">
                    <Text variant="smallPlus">Created by</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col  ms-lg1">
                    <Text variant="smallPlus">Status</Text>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg1">
                    <Text variant="smallPlus"> </Text>
                  </Stack.Item>
                </Stack.Item>
                {visibleAlgorithamVersionList &&
                  algorithmVersion?.results?.map((algorithm: any) => (
                    <Stack.Item
                      key={algorithm?.id}
                      className="ms-Grid-row tableText"
                    >
                      <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                        <Text
                          variant="smallPlus"
                          block
                          title={algorithm?.algorithm_name}
                        >
                          {algorithm?.algorithm_name}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg1">
                        <Text variant="smallPlus">{algorithm?.version}</Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg1 textEllipses">
                        <Text
                          variant="smallPlus"
                          block
                          title={algorithm?.model_name}
                        >
                          {algorithm?.model_name}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg2-point-5">
                        <Text
                          variant="smallPlus"
                          block
                          className="hospitalCardText textNoWrap"
                        >
                          <abbr
                            className="ms-bb-0"
                            title={`${algorithm?.docker_image_name} : ${algorithm?.docker_image_tag}`}
                          >
                            {algorithm?.docker_image_name} :{" "}
                            {algorithm?.docker_image_tag}
                          </abbr>
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg2">
                        <Text variant="smallPlus">
                          {moment(algorithm?.published_on).format(
                            "D MMM YYYY HH:mm"
                          )}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg1-point-5 textEllipses">
                        <Text
                          variant="smallPlus"
                          block
                          title={algorithm?.created_by}
                        >
                          {algorithm?.created_by}
                        </Text>
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg1">
                        <Text variant="smallPlus">
                          {algorithm?.is_active ? "Active" : "Inactive"}
                        </Text>
                      </Stack.Item>

                      <Stack.Item className="ms-Grid-col ms-lg1">
                        <Text variant="smallPlus">
                          <IconButton
                            className="buttonIcon"
                            menuIconProps={moreIcon}
                            menuProps={algorithmVersionPropsfn(algorithm)}
                            ariaLabel="New item"
                            onMenuClick={() =>
                              _onMenuClickSetAlgorithmVersionId(algorithm?.id)
                            }
                          />
                        </Text>
                      </Stack.Item>
                    </Stack.Item>
                  ))}
              </Stack.Item>
              {visibleAlgorithamVersionList &&
              algorithmVersion?.count > 10 &&
              algorithmVersion?.page_size ? (
                <Paging
                  currentPage={currentAlgorithmVersionPage}
                  totalPageCount={Math.ceil(algorithmVersion?.count / 10)}
                  handlePageChange={onAlgorithVersionmPageChange}
                />
              ) : null}
            </Stack>
          </PivotItem>
        </Pivot>
        <Modal isOpen={algorithmSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage
            title={`Algorithm ${
              !showCreateAlgorithms && !algorithmId ? "Creation" : "Updated"
            } `}
          />
        </Modal>
        <Modal
          isOpen={algorithmVersionSpinner}
          containerClassName={"modalStyle"}
        >
          <SuccessMessage
            title={`Algorithm Version ${
              !algorithmVersionId ? "Creation" : "Updated"
            } `}
          />
        </Modal>

        {(algorithmVersionSpinner || closeWhenErrors) &&
          setTimeout(() => {
            setShowAlgorithmVersion(false);
            // dispatch(getAlgorithmVersionList(currentAlgorithmVersionPage));
            dispatch(showRoleSpinner());
          }, 1000) &&
          setTimeout(() => {
            setAlgorithmVersionId(null);
          }, 1200) && <></>}
        {useEffect(() => {
          dispatch(getAlgorithmVersionList(currentAlgorithmVersionPage));
        }, [algorithmVersionSpinner || closeWhenErrors])}
        {(algorithmSpinner || closeWhenErrors) &&
          setTimeout(() => {
            setShowAlgorithm(false);
            setShowAlgorithmVersion(false);
            // dispatch(getAlgorithmList("", currentAlgorithmPage));
            dispatch(showRoleSpinner());
          }, 1000) && <></>}
        <DeleteModal
          isModalOpen={openDeleteAlgorithmModal}
          deleteHandle={deleteAlgorithmHandle}
          showModal={showDeleteAlgorithmModal}
          id={algorithmId}
          name="Algorithm"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        <DeleteModal
          isModalOpen={openDeleteAlgorithmVersionModal}
          deleteHandle={deleteAlgorithmVersionHandle}
          showModal={showDeleteAlgorithmVersionModal}
          id={algorithmVersionId}
          name="Algorithm Version"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        {lastHeader?.props?.headerText != "Algorithm Versions" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
        )}
        {lastHeader?.props?.headerText === "Algorithm Versions" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageAlgorithamVersion}
          ></LoaderWithMessage>
        )}
      </Stack>
    </>
  );
};

export default Algorithm;
