import {
  Stack,
  StackItem,
  Image,
  Text,
  TextField,
  Checkbox,
  Link,
  PrimaryButton,
  IStackProps,
  ITextStyles,
  IButtonStyles,
  ITextFieldStyles,
  ICheckboxStyles,
  ILinkStyles,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { ThemeColorPalette, fonts } from "../../../theme";
import { doLogin, showLogin } from "../../actions/authAction";
import activEdgeLogo from "../../../assets/activeEdge_logo.svg";
import warningIcon from "../../../assets/warning.svg";
import { withRouter } from "../../../withRouter";

const rightColumnProps: Partial<IStackProps> = {
  tokens: { padding: "0 15%", childrenGap: 10 },
};

const warningTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
  },
};

const headerTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    fontFamily: fonts.DINPro_Bold,
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    letterSpacing: "0.5px",
    fontFamily: fonts.HelveticaNeue_Medium,
    color: ThemeColorPalette.neutralPrimary,
    opacity: 0.5,
  },
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    width: "100%",
    fontFamily: fonts.HelveticaNeue_Bold,
  },
  rootHovered: { backgroundColor: ThemeColorPalette.themePrimary },
};

const footerStyles: Partial<ITextStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Light,
    color: ThemeColorPalette.neutralPrimary,
    opacity: "0.5",
  },
};

const checkboxStyles: Partial<ICheckboxStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Medium,
  },
};

const linkStyles: Partial<ILinkStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Medium,
  },
};

interface LoginProps {
  doLoginAction: any;
  loginPageAction: any;
  loggedInUserData: any;
  history: any;
  loggedInError: boolean;
  location: any;
  loggedInErrorData: string;
}
interface LoginState {
  email: string;
  password: string;
  isChecked: boolean;
}

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = { email: "", password: "", isChecked: false };
  }

  componentDidMount() {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const isChecked = localStorage.getItem("isChecked");
    username !== null &&
      password !== null &&
      isChecked !== null &&
      this.setState({
        ...this.state,
        email: username,
        password: password,
        isChecked: isChecked === "true",
      });
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>): any => {
    const { name, value } = e.target as HTMLInputElement;
    this.setState({ [name]: value } as any);
  };

  handleClick = () => {
    localStorage.setItem("isChecked", JSON.stringify(!this.state.isChecked));
    if (localStorage.getItem("isChecked") === "false") {
      this.setState({
        ...this.state,
        password: "",
        isChecked: !this.state.isChecked,
      });
      const remmberme = localStorage.getItem("isremember") === "true";
      const role1: any = localStorage.getItem("role");
      const isSwitchRole = localStorage.getItem("switch_account") === "true";
      localStorage.clear();
      localStorage.setItem("isremember", JSON.stringify(remmberme));
      localStorage.setItem("role", role1);
      localStorage.setItem("dualRole", JSON.stringify(isSwitchRole));
    } else {
      this.setState({
        ...this.state,
        isChecked: !this.state.isChecked,
      });
    }
  };

  handleFormSubmit = (event: any) => {
    event.preventDefault();
    const { email, password } = this.state;
    const data = {
      username: email,
      password: password,
    };
    this.props.doLoginAction(data, this.props);
    if (this.state.isChecked) {
      localStorage.setItem("username", this.state.email);
      localStorage.setItem("password", this.state.password);
      localStorage.setItem("isChecked", JSON.stringify(this.state.isChecked));
    } else {
      const remmberme = localStorage.getItem("isremember") === "true";
      const role: any = localStorage.getItem("role");
      const isSwitchRole = localStorage.getItem("switch_account") === "true";
      localStorage.clear();
      localStorage.setItem("isremember", JSON.stringify(remmberme));
      localStorage.setItem("role", role);
      localStorage.setItem("dualRole", JSON.stringify(isSwitchRole));
    }
  };

  getYear() {
    return new Date().getFullYear();
  }

  public render(): JSX.Element {
    const { email, password, isChecked } = this.state;
    const { loggedInError, loginPageAction, loggedInErrorData } = this.props;
    return (
      <Stack verticalFill {...rightColumnProps} verticalAlign="center">
        <StackItem tokens={{ padding: "10rem 0 0 0" }}>
          <Image
            src={activEdgeLogo}
            alt="Activ Edge logo"
            width={100}
            height={78}
          />
        </StackItem>
        <StackItem tokens={{ padding: "2rem 0 1rem" }}>
          <Text styles={headerTextStyles} variant="xLarge" nowrap block>
            Please enter login details
          </Text>
        </StackItem>
        {loggedInError ? (
          <Stack horizontal horizontalAlign="start" className="warningStyles">
            <StackItem tokens={{ padding: "0 0 0 5px" }}>
              <Image src={warningIcon} alt="warning icon" />
            </StackItem>
            <StackItem tokens={{ padding: "0 0 0 5px" }}>
              <Text variant="small" pre-wrap block styles={warningTextStyles}>
                {loggedInErrorData || "Incorrect email id or password"}
              </Text>
            </StackItem>
          </Stack>
        ) : null}
        <form id="loginForm" onSubmit={this.handleFormSubmit}>
          <StackItem tokens={{ padding: "0 0 1rem 0" }}>
            <TextField
              inputClassName="textFieldStyles"
              styles={textFieldStyles}
              placeholder="Enter email"
              name="email"
              type="email"
              value={email}
              underlined
              onChange={(e: any) => this.handleChange(e)}
            />
          </StackItem>
          <StackItem tokens={{ padding: "0 0 1rem 0" }}>
            <TextField
              inputClassName="textFieldStyles"
              styles={textFieldStyles}
              placeholder="Enter password"
              underlined
              type="password"
              name="password"
              value={password}
              onChange={(e: any) => this.handleChange(e)}
            />
          </StackItem>
          <Stack horizontal horizontalAlign="space-between">
            <StackItem tokens={{ padding: "1rem 0" }}>
              <Checkbox
                styles={checkboxStyles}
                label="Remember me"
                checked={isChecked}
                onChange={this.handleClick}
              />
            </StackItem>
            <StackItem tokens={{ padding: "1rem 0" }}>
              <Link
                target="_blank"
                styles={linkStyles}
                onClick={() => loginPageAction(false)}
              >
                Forgot Password ?
              </Link>
            </StackItem>
          </Stack>
          <StackItem tokens={{ padding: "1rem 0 0 0" }}>
            <PrimaryButton
              type="submit"
              styles={buttonStyles}
              text="Login"
              onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                this.handleFormSubmit(evt)
              }
            />
          </StackItem>
        </form>
        <Stack verticalFill verticalAlign="end" horizontalAlign="center">
          <StackItem tokens={{ padding: "0 0 1rem 0" }}>
            <Text styles={footerStyles} variant="xSmall" block nowrap>
              Copyright © {this.getYear()} Activ Surgical - All Rights Reserved.
            </Text>
          </StackItem>
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loggedInError: state.authReducerData.loggedInError,
    loggedInUserData: state.authReducerData.loggedInUserData,
    loggedInErrorData: state.authReducerData.loggedInErrorData,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    loginPageAction: (value: boolean) => dispatch(showLogin(value)),
    doLoginAction: (userDetails: any, context: any) =>
      dispatch(doLogin(userDetails, context)),
  };
};

export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(Login)
);
