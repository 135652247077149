import {
  Breadcrumb,
  IStackItemStyles,
  Stack,
  StackItem,
  Text,
  ActionButton,
  Panel,
  PanelType,
  PrimaryButton,
  DefaultButton,
  Modal,
  IconButton,
  PersonaPresence,
  ChoiceGroup,
  IChoiceGroupOption,
  Spinner,
  SpinnerSize,
  FontIcon,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../theme";
import "./ProcedureVideos.scss";
import InprogressVideoEdit from "../../InProgress/components/InprogressVideoEdit";
import { apiEndPoint, doHttpCall } from "../restApi";
import SharedPeoplePicker from "../SharedPeoplePicker";
import { TestImages } from "@uifabric/example-data";
import ProcedureVideoCard from "../ProcedureVideoCard/ProcedureVideoCard";
import InprogressVideoDetails from "../../InProgress/components/InProgressVideoDetails";
import Paging from "../paging/Paging";

const containerStyles: IStackItemStyles = {
  root: {
    minHeight: "100vh",
    maxHeight: "100%",
    minWidth: "100vh",
    maxWidth: "100%",
  },
};

interface ProcedureVideosProps {
  breadcrumbItems: Array<any>;
  videosData: any;
  setSelectedVideo: any;
  setSelectedVideoUnfavourite: any;
  proceduresData: any;
  userData: any;
  userProfile: any;
  editVideoDetails: any;
  selectedVideoEditedFields: any;
  postSharedDetails: any;
  handleFavouiteVideoClick: any;
  getVideosAction: any;
  screen?: string;
  handleRadioButtonChange?: any;
  clearDataOnComponentUnmount: any;
  history: any;
  showSpinner: boolean;
  showData: boolean;
  currentPage: number;
  procedureId?: any;
  resetEditedDataonCancel: any;
  showSpinnerOnload?: any;
}

interface ProcedureVideosState {
  isPanelOpen: boolean;
  isModalOpen: boolean;
  enableSave: boolean;
  shared_with: Array<any>;
  sharedDetailsToPost: any;
  shareErrors: Array<any>;
  showSelected: boolean;
  shareButtonEnable: boolean;
  videosData: any;
  currentVideoPage: any;
  isUploadConsentFormError: any;
  largePdfFileError: any;
}

class ProcedureVideos extends React.Component<
  ProcedureVideosProps,
  ProcedureVideosState
> {
  constructor(props: ProcedureVideosProps) {
    super(props);
    this.state = {
      isPanelOpen: false,
      isModalOpen: false,
      enableSave: false,
      shared_with: [],
      sharedDetailsToPost: {},
      shareErrors: [],
      showSelected: true,
      shareButtonEnable: true,
      videosData: {},
      currentVideoPage: this.props.currentPage,
      isUploadConsentFormError: undefined,
      largePdfFileError: undefined,
    };
  }

  componentDidMount = () => {};

  openPanel = () => {
    this.setState({
      ...this.state,
      isPanelOpen: true,
      isUploadConsentFormError: null,
      largePdfFileError: null,
    });
  };
  componentDidUpdate(): void {
    if (this.state.currentVideoPage) {
      localStorage.setItem("currentVideoPage", this.state.currentVideoPage);
    }
  }
  dismissPanel = () => {
    this.props.resetEditedDataonCancel("cancel");
    this.setState({
      ...this.state,
      isPanelOpen: false,
      enableSave: false,
    });
  };

  handlePageChange = (currentPage: number) => {
    this.props.showSpinnerOnload();
    this.setState({ currentVideoPage: currentPage });
    currentPage = Number(currentPage);
    this.props.screen !== "favourite"
      ? this.props.screen === "sharedWithMe"
        ? this.props.getVideosAction("sharedWithMe", currentPage)
        : this.props.screen === "procedures"
        ? this.props.getVideosAction(this.props.procedureId, currentPage)
        : this.props.getVideosAction("sharedByMe", currentPage)
      : this.props.getVideosAction(currentPage);
  };

  handleChange = (
    e: any,
    data: any,
    selectedOption?: any,
    selectedField?: string,
    selectType?: string
  ) => {
    this.setState({
      ...this.state,
      enableSave: true,
    });
    if (selectedField === "procedureName") {
      const value = {
        ...this.props.selectedVideoEditedFields,
        primary_procedure_type: selectedOption.key,
      };
      this.props.editVideoDetails(value, data.id);
    } else if (selectedField === "procedureName2") {
      const value = {
        ...this.props.selectedVideoEditedFields,
        secondary_procedure_type: selectedOption.key,
      };
      this.props.editVideoDetails(value, data.id);
    } else if (selectedField === "surgeonName") {
      const value = {
        ...this.props.selectedVideoEditedFields,
        primary_surgeon: selectedOption.key,
      };
      this.props.editVideoDetails(value, data.id);
    } else if (selectedOption === "date") {
      const value = {
        ...this.props.selectedVideoEditedFields,
        surgery_date: e,
      };
      this.props.editVideoDetails(value, data.id);
    } else {
      const value = {
        ...this.props.selectedVideoEditedFields,
        notes: e.target.value,
      };
      this.props.editVideoDetails(value, data.id);
    }
  };

  onRenderPanelBody = () => {
    const [data] = this.props.videosData.results.filter(
      (item: any) => item.isSelected
    );
    if (
      (data && data.primary_surgeon === this.props.userProfile.id) ||
      this.state.enableSave
    ) {
      const handlePatientConsentFormFileChange = (evt: any, data: any) => {
        this.setState({ isUploadConsentFormError: null });
        this.setState({ largePdfFileError: null });
        const fileObj = evt.target.files[0];
        const fileType = fileObj.type;
        const fileSize: any = fileObj.size
          ? (fileObj.size / (1024 * 1024)).toFixed(2)
          : 0;
        if (parseFloat(fileSize) > 5) {
          this.setState({
            largePdfFileError: "Please Upload File less than 5 Mb",
          });
          return true;
        }
        if (
          fileType === "image/png" ||
          fileType === "image/PNG" ||
          fileType.includes("pdf")
        ) {
          this.setState({
            ...this.state,
            enableSave: true,
          });
          const val = {
            ...this.props.selectedVideoEditedFields,
            patient_concent_forms: evt.target.files[0].name,
            patientConcentForm: evt.target.files[0],
          };
          this.props.editVideoDetails(val, data.id);
        } else {
          const val = {
            ...this.props.selectedVideoEditedFields,
            patient_concent_forms: null,
            patientConcentForm: null,
          };
          this.props.editVideoDetails(val, data.id);
          this.setState({
            isUploadConsentFormError: "File type not supported",
          });
        }
      };
      //   this.setState({
      //     ...this.state,
      //     enableSave: true,
      //   });
      //   const val = {
      //     ...this.props.selectedVideoEditedFields,
      //     patient_concent_forms: evt.target.files[0].name,
      //     patientConcentForm: evt.target.files[0],
      //   };
      //   this.props.editVideoDetails(val, data.id);
      //   evt.target.value = "";
      // };

      return (
        <Stack
          horizontal
          horizontalAlign="start"
          tokens={{ childrenGap: 10 }}
          styles={{
            root: {
              padding: "0rem 1.2rem",
            },
          }}
        >
          <InprogressVideoEdit
            data={data}
            dropdownVals={this.props.proceduresData.results}
            userData={this.props.userData}
            userProfile={this.props.userProfile}
            handlePatientConsentFormFileChange={
              handlePatientConsentFormFileChange
            }
            handleChange={this.handleChange}
            isUploadConsentFormError={this.state.isUploadConsentFormError}
            largePdfFileError={this.state.largePdfFileError}
          />
        </Stack>
      );
    } else {
      const primarySurgeonName =
        data &&
        this.props.userData.results &&
        this.props.userData.results
          .map((item: any) => {
            if (item.id === data.primary_surgeon) return item.displayName;
            else return null;
          })
          .filter((val: any) => val);
      const [secondaryProcedureName] =
        this.props.proceduresData && data
          ? this.props.proceduresData?.results?.filter(
              (item: any) => item.id === data.secondary_procedure_type
            )
          : [""];

      return (
        <Stack
          horizontal
          horizontalAlign="start"
          tokens={{ childrenGap: 30 }}
          styles={{
            root: {
              padding: "0rem 1.2rem",
            },
          }}
        >
          <InprogressVideoDetails
            data={data}
            primarySurgeonName={primarySurgeonName && primarySurgeonName[0]}
            secondaryProcedureName={
              secondaryProcedureName && secondaryProcedureName.name
            }
            userData={this.props.userData}
            userProfile={this.props.userProfile}
            screen={"viewOnly"}
            handleEdit={() =>
              this.setState({
                ...this.state,
                enableSave: true,
              })
            }
          />
        </Stack>
      );
    }
  };

  onRenderPanelFooter = () => {
    return (
      <Stack
        className="custom-footer"
        horizontal
        horizontalAlign="start"
        tokens={{ childrenGap: 10 }}
        styles={{
          root: {
            padding: "1rem 3rem",
            borderTop: "0.5px solid " + ThemeColorPalette.panelBorder,
          },
        }}
      >
        <PrimaryButton
          disabled={!this.state.enableSave}
          onClick={this.handleFavouiteVideoDetailsSave}
        >
          Save
        </PrimaryButton>
        <DefaultButton onClick={this.dismissPanel}>Cancel</DefaultButton>
      </Stack>
    );
  };

  handleFavouiteVideoDetailsSave = async () => {
    const [data] = this.props.videosData.results.filter(
      (item: any) => item.isSelected
    );
    const formData: any = new FormData();

    Object.keys(this.props.selectedVideoEditedFields).forEach((val: any) => {
      val !== "note" &&
        formData.append(val, this.props.selectedVideoEditedFields[val]);
    });

    try {
      const payload = {
        method: "PATCH",
        url: apiEndPoint + "/videos/" + data.id + "/",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      const responce = await doHttpCall(payload);
      if (responce && responce.status === 200) {
        this.setState({
          ...this.state,
          isPanelOpen: false,
          enableSave: false,
        });
        this.handlePageChange(this.state.currentVideoPage);
        this.props.resetEditedDataonCancel("save");
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleShareButtonClick = async () => {
    const data = {
      video_id: this.state.sharedDetailsToPost.video_id,
      shared_by: this.state.sharedDetailsToPost.shared_by,
      shared_with: [...this.state.shared_with],
      created_by: this.state.sharedDetailsToPost.created_by,
    };

    this.setState({ ...this.state, shareButtonEnable: true });

    const response = await this.props.postSharedDetails(data);
    const responseData: any = response.data;
    if (
      responseData &&
      responseData.previously_shared &&
      responseData.previously_shared.length
    ) {
      for (let i = 0; i < responseData.previously_shared.length; i++) {
        if (response.data.previously_shared[i].status === "ACCEPTED") {
          this.setState({
            ...this.state,
            sharedDetailsToPost: {},
            shareErrors: [
              response.data.previously_shared[i],
              ...this.state.shareErrors,
            ],
            shared_with: [],
            showSelected: false,
          });
        } else {
          this.setState({
            ...this.state,
            sharedDetailsToPost: {},
            shareErrors: [
              response.data.previously_shared[i],
              ...this.state.shareErrors,
            ],
            shared_with: [],
            showSelected: false,
          });
        }
      }
    }
  };

  onItemSelect = (selectedItem1: any) => {
    const selectedItems =
      selectedItem1 && selectedItem1.length
        ? selectedItem1.map((item: any) => item.shared_with)
        : [];

    this.setState({
      ...this.state,
      shareErrors: [],
      showSelected: true,
      sharedDetailsToPost: selectedItem1 && selectedItem1[0],
      shared_with: selectedItems,
      shareButtonEnable: selectedItems && selectedItems.length ? false : true,
    });
    // return selectedItem1 ? selectedItem1 : null;
  };

  componentWillUnmount() {
    this.props.clearDataOnComponentUnmount();
  }

  render() {
    const { breadcrumbItems, videosData }: any = this.props;

    const [selectedFavouritesData] =
      videosData && videosData.results
        ? videosData.results.filter((item: any) => item.isSelected)
        : [""];
    const [data] =
      videosData && videosData.results
        ? videosData.results.filter((item: any) => item.isSelected)
        : [""];

    const userListNames =
      this.props.userData.results &&
      data &&
      this.props.userData.results
        .map((item: any, i: number) => {
          if (item.id !== this.props.userProfile.id) {
            return {
              key: i,
              imageUrl:
                i % 2 === 0 ? TestImages.personaMale : TestImages.personaFemale,
              imageInitials: "",
              text: item.displayName,
              presence:
                i % 2 === 0 ? PersonaPresence.online : PersonaPresence.away,
              video_id: data && data.id,
              shared_by: this.props.userProfile.id,
              shared_with: item.id,
              created_by: data.created_by
                ? data.created_by
                : this.props.userProfile.id,
            };
          }
          return undefined;
        })
        .filter((val: any) => val);

    const procedure_names: any = [];
    videosData &&
      videosData.results &&
      videosData.results.forEach((item: any) =>
        procedure_names.push(item.procedure_name)
      );

    const options: IChoiceGroupOption[] = [
      {
        key: "A",
        text: "Shared By Me",
        styles: { root: { paddingRight: "1rem" } },
      },
      {
        key: "B",
        text: "Shared With Me",
      },
    ];

    return videosData ? (
      <Stack
        verticalFill
        styles={containerStyles}
        tokens={{ padding: "0rem 2rem" }}
      >
        {this.props.showSpinner && <div className="view-details-loader"></div>}
        <StackItem
          styles={{
            root: {
              padding: this.props.screen === "procedures" ? "1.5rem 0" : "",
            },
          }}
        >
          {this.props.showData && this.props.screen === "procedures" ? (
            <Breadcrumb
              items={breadcrumbItems}
              ariaLabel="With last item rendered as heading"
              overflowAriaLabel="More links"
            />
          ) : (
            this.props.screen !== "procedures" && (
              <Breadcrumb
                items={breadcrumbItems}
                ariaLabel="With last item rendered as heading"
                overflowAriaLabel="More links"
              />
            )
          )}
          {(this.props.screen === "sharedWithMe" ||
            this.props.screen === "sharedByMe") && (
            <ChoiceGroup
              styles={{
                flexContainer: { display: "flex" },
                root: {
                  paddingBottom: "2rem",
                  paddingLeft: "0.5rem",
                },
              }}
              defaultSelectedKey={
                this.props.screen === "sharedByMe" ? "A" : "B"
              }
              options={options}
              onChange={this.props.handleRadioButtonChange}
            />
          )}
        </StackItem>
        {this.props.showData ? (
          <StackItem
            styles={{
              root: {
                backgroundColor: ThemeColorPalette.metrixbg,
                width: "97%",
                padding: "1rem",
              },
            }}
          >
            {selectedFavouritesData ? (
              <StackItem styles={{ root: { marginLeft: "1rem" } }}>
                {this.props.screen !== "sharedWithMe" &&
                selectedFavouritesData &&
                selectedFavouritesData.primary_surgeon ===
                  this.props.userProfile.id ? (
                  <ActionButton
                    styles={{
                      root: {
                        color: ThemeColorPalette.uploadVideoBtnTxt,
                      },
                    }}
                    iconProps={{ iconName: "Edit" }}
                    allowDisabledFocus
                    onClick={this.openPanel}
                  >
                    Edit
                  </ActionButton>
                ) : (
                  <ActionButton
                    styles={{
                      root: {
                        color: ThemeColorPalette.uploadVideoBtnTxt,
                      },
                    }}
                    iconProps={{ iconName: "Edit" }}
                    allowDisabledFocus
                    onClick={this.openPanel}
                  >
                    View Details
                  </ActionButton>
                )}{" "}
                <Panel
                  isOpen={this.state.isPanelOpen}
                  onDismiss={this.dismissPanel}
                  closeButtonAriaLabel="Close"
                  isFooterAtBottom={true}
                  type={PanelType.custom}
                  customWidth={"45rem"}
                  styles={{
                    footerInner: {
                      padding: 0,
                    },
                  }}
                  onRenderBody={this.onRenderPanelBody}
                  onRenderFooterContent={this.onRenderPanelFooter}
                ></Panel>
                {this.props.screen !== "sharedWithMe" &&
                  selectedFavouritesData &&
                  selectedFavouritesData.primary_surgeon ===
                    this.props.userProfile.id && (
                    <ActionButton
                      styles={{
                        root: {
                          color: ThemeColorPalette.uploadVideoBtnTxt,
                        },
                      }}
                      iconProps={{ iconName: "Share" }}
                      allowDisabledFocus
                      onClick={() =>
                        this.setState({ ...this.state, isModalOpen: true })
                      }
                    >
                      Share
                    </ActionButton>
                  )}
                <Modal
                  styles={{ root: ThemeColorPalette.pureBlack }}
                  isModeless={true}
                  isOpen={this.state.isModalOpen}
                  containerClassName={"modalStyle1"}
                >
                  <Stack grow>
                    <StackItem
                      align="end"
                      styles={{ root: { padding: "1rem 1rem 0 1rem" } }}
                    >
                      <IconButton
                        iconProps={{
                          iconName: "Cancel",
                          styles: {
                            root: {
                              fontWeight: "Bold",
                              color: ThemeColorPalette.pureWhite,
                              textAlign: "end",
                            },
                          },
                        }}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            isModalOpen: false,
                            shareErrors: [],
                          })
                        }
                      />
                    </StackItem>
                    <Stack
                      verticalFill
                      verticalAlign="space-between"
                      tokens={{ childrenGap: 10 }}
                      styles={{ root: { height: "63vh" } }}
                    >
                      <StackItem styles={{ root: { padding: "1rem 3rem" } }}>
                        <Stack
                          verticalAlign="start"
                          tokens={{ childrenGap: 10, padding: "0 0 1rem 0" }}
                        >
                          <Text
                            variant="xLargePlus"
                            styles={{
                              root: {
                                font: fonts.DINPro_Medium,
                                color: ThemeColorPalette.sharedHeaderText,
                              },
                            }}
                          >
                            Share video
                          </Text>
                          <Text
                            variant="medium"
                            styles={{
                              root: {
                                font: fonts.HelveticaNeue_Regular,
                                color: ThemeColorPalette.changePasswordLabelTxt,
                              },
                            }}
                          >
                            Only De-Identification video will be shared
                          </Text>
                        </Stack>
                        {userListNames && userListNames.length && (
                          <SharedPeoplePicker
                            userListNames={userListNames}
                            onItemSelect={this.onItemSelect}
                            showSelected={this.state.showSelected}
                          />
                        )}
                        {this.state.shareErrors && this.state.shareErrors.length
                          ? this.state.shareErrors.map(
                              (val: any, i: number) => (
                                <Stack
                                  key={i}
                                  horizontal
                                  verticalAlign="center"
                                  tokens={{
                                    childrenGap: 7,
                                  }}
                                  styles={{ root: { margin: "0.5rem 0" } }}
                                >
                                  <FontIcon
                                    iconName="Info"
                                    style={{
                                      color:
                                        val.status === "ACCEPTED"
                                          ? "red"
                                          : ThemeColorPalette.ribbon,
                                    }}
                                  />
                                  <Text
                                    variant="smallPlus"
                                    styles={{
                                      root: {
                                        font: fonts.HelveticaNeue_Medium,
                                      },
                                    }}
                                  >
                                    {val.message}
                                  </Text>
                                </Stack>
                              )
                            )
                          : null}
                      </StackItem>
                      <StackItem
                        styles={{
                          root: {
                            borderTop:
                              "1px solid " + ThemeColorPalette.panelBorder,
                          },
                        }}
                      >
                        <Stack
                          horizontal
                          tokens={{ childrenGap: 10 }}
                          styles={{
                            root: { margin: "1rem 0.5rem 1rem 3rem" },
                          }}
                        >
                          <PrimaryButton
                            styles={{
                              root: {
                                fontFamily: fonts.HelveticaNeue_Bold,
                              },
                            }}
                            disabled={this.state.shareButtonEnable}
                            onClick={this.handleShareButtonClick}
                          >
                            Share
                          </PrimaryButton>
                          <PrimaryButton
                            styles={{
                              root: {
                                backgroundColor: ThemeColorPalette.pureBlack,
                                color: ThemeColorPalette.pureWhite,
                                fontFamily: fonts.HelveticaNeue_Bold,
                              },
                            }}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                isModalOpen: false,
                                shareErrors: [],
                              })
                            }
                          >
                            Cancel
                          </PrimaryButton>
                        </Stack>
                      </StackItem>
                    </Stack>
                  </Stack>
                </Modal>
                <ActionButton
                  styles={{
                    root: {
                      color: ThemeColorPalette.uploadVideoBtnTxt,
                    },
                  }}
                  iconProps={{
                    iconName: selectedFavouritesData.favorite
                      ? "HeartFill"
                      : "Heart",
                  }}
                  allowDisabledFocus
                  onClick={this.props.handleFavouiteVideoClick}
                >
                  Add to Favorites
                </ActionButton>
              </StackItem>
            ) : null}

            <div className="parentGrid">
              <div className="childGrid">
                {videosData.results && videosData.results.length
                  ? videosData.results.map((item: any, index: number) => {
                      return (
                        <StackItem className="allVideosVideoCard all-videos-card-width">
                          <ProcedureVideoCard
                            key={index}
                            item={item}
                            handleClick={this.props.setSelectedVideo}
                            procedure_names={procedure_names[index]}
                            history={this.props.history}
                            screenType={this.props.screen}
                          />
                        </StackItem>
                      );
                    })
                  : null}
              </div>
            </div>
            {videosData.count > 8 ? (
              <Paging
                currentPage={this.state.currentVideoPage}
                totalPageCount={Math.ceil(videosData.count / 8)}
                handlePageChange={this.handlePageChange}
              />
            ) : null}
          </StackItem>
        ) : this.props.showSpinner ? (
          // <Spinner
          //   styles={{
          //     root: { position: "absolute", left: "50%", top: "50%" },
          //   }}
          //   size={SpinnerSize.large}
          // />
          <></>
        ) : (
          <Text
            styles={{
              root: { position: "absolute", left: "50%", top: "50%" },
            }}
          >
            No videos available
          </Text>
        )}
      </Stack>
    ) : this.props.showSpinner ? (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { marginTop: "25%" } }}
      >
        <Spinner size={SpinnerSize.large} />
      </Stack>
    ) : (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { marginTop: "25%" } }}
      >
        <Text>No videos available</Text>
      </Stack>
    );
  }
}

export default React.memo(ProcedureVideos);
