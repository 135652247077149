import {
  ActionButton,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  IconButton,
  Modal,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { fonts, ThemeColorPalette } from "../../../theme";
import {
  clearUploadingVideosMetada,
  deleteSelectedItem,
  setSelectedUploadingVideosList,
  updateUploadingVideosList,
} from "../../actions/uploadVideoMetaDataActions";
import VideoCard from "../../components/VideoCard";
import VideoDetailsUpdate from "../../components/videoDetailsUpdate/VideoDetailsUpdate";
import { uuid } from "uuidv4";
import "../MyProcedures/MyProcedures.scss";
import { withRouter } from "../../../withRouter";
import { ROLE_TYPES } from "../../../Layout/constants/constants";

interface uploadVideoPassedPropsProps {
  isModalOpen: boolean;
  hideModal: () => void;
}

interface uploadVideoProps {
  uploadingVideosData: Array<any>;
  userProfile: any;
}

interface uploadVideosDispatches {
  updateUploadingVideosList: (data: any) => void;
  setSelectedUploadingVideosList: (data: any) => void;
  deleteSelectedItem: (data: any) => void;
  clearUploadingVideosMetada: () => void;
}

interface uploadVideoState {
  stepNavigation: boolean;
  uploadAlerts: boolean;
  uploadError: string;
  sequenceCheck: boolean;
  dragActive: boolean;
}
const dialogStyles: any = {
  main: { maxWidth: 400, margin: "0 auto" },
};

type uploadVideosFullProps = uploadVideoPassedPropsProps &
  uploadVideoProps &
  uploadVideosDispatches;

class UploadVideoModel extends React.Component<
  uploadVideosFullProps,
  uploadVideoState
> {
  private hiddenFileInputRef: any;

  constructor(props: uploadVideosFullProps) {
    super(props);
    this.hiddenFileInputRef = React.createRef();
    this.state = {
      stepNavigation: true,
      uploadAlerts: false,
      uploadError: "",
      sequenceCheck: false,
      dragActive: false,
    };
  }

  handleFileClick = (event?: any) => {
    this.hiddenFileInputRef.current.click();
  };

  clearVideosAndCloseModal = () => {
    this.props.clearUploadingVideosMetada();
    this.props.hideModal();
    this.setState({ stepNavigation: true });
  };
  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      this.setState({ dragActive: true });
    } else if (e.type === "dragleave") {
      this.setState({ dragActive: false });
    }
  };
  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.handleFileChange({ target: { files: e.dataTransfer.files } });
    }
  };
  handleFileChange = (event: any) => {
    const { userProfile } = this.props;
    const isSurgeon = userProfile?.role_name === ROLE_TYPES.ACTIVE_SURGEON;
    const fileUploaded = event.target.files;
    const tempArray: any = [];
    for (let i = 0; i < fileUploaded.length; i++) {
      if (
        fileUploaded.length !== undefined &&
        tempArray.length < 5 &&
        fileUploaded.length <= 5
      ) {
        const obj = {
          id: uuid(),
          videoData: {
            uploaderId: userProfile.userId,
            primaryProcedureId: null,
            primarySurgeonId: isSurgeon ? userProfile.userId : "",
            mainVideos: {
              mainFileName: fileUploaded[i].name,
              encodeFormat: "mp4",
              isDeIdentified: false,
              logFileName: "mainLog.txt",
              videoList: [
                { fileName: fileUploaded[i].name, chunkSequenceNumber: 1 },
              ],
            },
            activVideos: {},
          },
          primarySurgeonName: isSurgeon ? userProfile.displayName : undefined,
          file: fileUploaded[i],
          fileSize: fileUploaded[i].size,
          isSelected: false,
          shouldSelect: false,
          upload: false,
          fileUploadedSize: null,
          percentageComplete: null,
          uploadUrl: "",
          correlationId: "",
          error: "",
        };

        tempArray.push(obj);
      } else {
        this.setState({
          ...this.state,
          uploadAlerts: true,
          uploadError: "User can only select max of 5 videos",
        });
        // alert("User can only select max of 5 videos");
        break;
      }
    }
    if (
      this.props.uploadingVideosData.length > 0 &&
      this.props.uploadingVideosData.length + tempArray.length <= 5
    ) {
      const newResult = tempArray
        .filter(
          (item1: any) =>
            !this.props.uploadingVideosData.some(
              (item2: any) => item1.file.name === item2.file.name
            )
        )
        .map((val: any) => val);
      this.props.updateUploadingVideosList(newResult);
      tempArray
        .filter((item1: any) =>
          this.props.uploadingVideosData.some(
            (item2) => item1.file.name === item2.file.name
          )
        )
        .forEach((val: any) => {
          if (val)
            this.setState({
              ...this.state,
              uploadAlerts: true,
              uploadError: val.file.name + " already exists",
            });
          // alert(val.file.name + " already exists");
        });
    } else if (
      this.props.uploadingVideosData.length === 0 &&
      tempArray.length <= 5
    ) {
      this.props.updateUploadingVideosList(tempArray);
    } else {
      this.setState({
        ...this.state,
        uploadAlerts: true,
        uploadError: "User can only select max of 5 videos",
      });
      // alert("User can only select max of 5 videos");
    }
    event.target.value = "";
  };

  handleFirstStep = (event: any) => {
    this.setState({
      ...this.state,
      stepNavigation: !this.state.stepNavigation,
    });
  };

  handleSelectedData = (data: any) => {
    this.props.setSelectedUploadingVideosList(data);
  };

  handleDelete = () => {
    const tempArray = [...this.props.uploadingVideosData];
    tempArray.forEach(
      (item) => item.selectToUpload && this.props.deleteSelectedItem(item)
    );
  };

  handlePopup = () => {
    this.setState({ ...this.state, uploadError: "", uploadAlerts: false });
  };

  render() {
    const { isModalOpen, uploadingVideosData } = this.props;

    const modalProps = () => ({
      isBlocking: false,
      styles: dialogStyles,
    });

    const dialogContentProps = {
      type: DialogType.largeHeader,
      title: (
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <FontIcon iconName="Warning" style={{ color: "Red" }}></FontIcon>
          <Text
            styles={{
              root: { font: fonts.HelveticaNeue_Bold, color: "Red" },
            }}
          >
            Error
          </Text>
        </Stack>
      ),

      closeButtonAriaLabel: "Close",
      subText: this.state.uploadError,
    };

    return (
      <Modal
        styles={{ root: ThemeColorPalette.pureBlack }}
        isModeless={true}
        isOpen={isModalOpen}
        containerClassName={"modalStyle"}
        isBlocking={false}
      >
        <Stack verticalFill verticalAlign="start" tokens={{ childrenGap: 10 }}>
          <StackItem
            align="end"
            styles={{ root: { padding: "1rem 1rem 0 1rem" } }}
          >
            <IconButton
              iconProps={{
                iconName: "Cancel",
                styles: {
                  root: {
                    fontWeight: "Bold",
                    color: ThemeColorPalette.pureWhite,
                    textAlign: "end",
                  },
                },
              }}
              onClick={this.clearVideosAndCloseModal}
            />
          </StackItem>
          <StackItem styles={{ root: { padding: "0 5rem" } }}>
            <Text variant="xLarge">
              Welcome to ActivEdge video upload wizard!
            </Text>
          </StackItem>
          <StackItem
            styles={{
              root: {
                backgroundColor: ThemeColorPalette.metrixbg,
                margin: "0 5rem",
              },
            }}
          >
            <Stack
              horizontal
              horizontalAlign="start"
              styles={{ root: { height: "3.1rem" } }}
            >
              <StackItem
                styles={{
                  root: {
                    backgroundColor: ThemeColorPalette.gridBG,
                  },
                }}
                align="center"
                className="stepBar"
              >
                <Text
                  styles={{
                    root: {
                      border: this.state.stepNavigation
                        ? "1px solid " + ThemeColorPalette.themePrimary
                        : "1px solid " + ThemeColorPalette.ribbon,
                      borderRadius: "50%",
                      padding: "0.1rem 0.35rem",
                      marginRight: "0.5rem",
                      fontFamily: fonts.HelveticaNeue_Bold,
                      color: this.state.stepNavigation
                        ? ThemeColorPalette.themePrimary
                        : ThemeColorPalette.ribbon,
                    },
                  }}
                  variant="small"
                >
                  1
                </Text>{" "}
                <Text
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Medium,
                    },
                  }}
                  variant="small"
                >
                  Select Videos
                </Text>
              </StackItem>
              <StackItem
                styles={{
                  root: {
                    backgroundColor: ThemeColorPalette.gridBG,
                    opacity: this.state.stepNavigation ? 0.5 : 1,
                  },
                }}
                align="center"
                className="stepBar stepBar1"
              >
                <Text
                  styles={{
                    root: {
                      border: "1px solid",
                      borderRadius: "50%",
                      padding: "0.1rem 0.35rem",
                      marginRight: "0.5rem",
                      fontFamily: fonts.HelveticaNeue_Bold,
                      color: !this.state.stepNavigation
                        ? ThemeColorPalette.themePrimary
                        : ThemeColorPalette.pureWhite,
                    },
                  }}
                  variant="small"
                >
                  2
                </Text>
                <Text
                  variant="small"
                  styles={{
                    root: { fontFamily: fonts.HelveticaNeue_Medium },
                  }}
                >
                  Add Details
                </Text>
              </StackItem>
            </Stack>
          </StackItem>
          {/* first step and second step ternary operation */}
          {this.state.stepNavigation ? (
            <Stack tokens={{ childrenGap: 15 }}>
              <StackItem
                styles={{
                  root: {
                    backgroundColor: ThemeColorPalette.metrixbg,
                    margin: "0 5rem",
                  },
                }}
              >
                {uploadingVideosData.length === 0 ? (
                  <Stack
                    verticalFill
                    verticalAlign="start"
                    tokens={{ childrenGap: 10 }}
                    styles={{ root: { height: "80vh" } }}
                  >
                    <StackItem
                      align="center"
                      styles={{ root: { paddingTop: "2rem" } }}
                    >
                      <Text
                        variant="small"
                        styles={{
                          root: {
                            padding: "0 0 0 1rem",
                            color: ThemeColorPalette.uploadMsgTxt,
                            opacity: "0.4",
                          },
                        }}
                      >
                        Videos Added (0), You can select upto 5 videos
                      </Text>
                    </StackItem>
                    <Stack>
                      <StackItem
                        align="center"
                        tokens={{
                          margin: "6.6rem 0",
                          padding: "0.7rem",
                        }}
                        styles={{
                          root: {
                            height: "15rem",
                            width: "25rem",
                            backgroundColor: ThemeColorPalette.gridBG,
                            boxShadow:
                              "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
                          },
                        }}
                      >
                        <form
                          id="form-file-upload"
                          onDragEnter={this.handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                        >
                          <Stack
                            onDragEnter={this.handleDrag}
                            verticalFill
                            verticalAlign="center"
                            horizontalAlign="center"
                            tokens={{ childrenGap: 5 }}
                            styles={{
                              root: {
                                border: "1px dashed #363636",
                                borderRadius: "1rem",
                              },
                            }}
                          >
                            <StackItem>
                              <FontIcon
                                iconName="CloudUpload"
                                style={{
                                  fontSize: "2rem",
                                  color: ThemeColorPalette.uploadMsgTxt,
                                  opacity: 0.2,
                                }}
                              />
                            </StackItem>
                            <StackItem tokens={{ padding: "0" }}>
                              <PrimaryButton onClick={this.handleFileClick}>
                                Select Files
                              </PrimaryButton>
                              <input
                                type="file"
                                accept="video/mp4,video/x-m4v,video/*"
                                multiple
                                ref={this.hiddenFileInputRef}
                                onChange={this.handleFileChange}
                                style={{ display: "none" }}
                              />
                            </StackItem>
                            {this.state.dragActive && (
                              <div
                                id="drag-file-element"
                                onDragEnter={this.handleDrag}
                                onDragLeave={this.handleDrag}
                                onDragOver={this.handleDrag}
                                onDrop={this.handleDrop}
                              ></div>
                            )}
                          </Stack>
                        </form>
                      </StackItem>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack
                    tokens={{ childrenGap: 10 }}
                    styles={{ root: { height: "80vh" } }}
                  >
                    <div className="uploadparentGrid">
                      <Stack horizontal>
                        <StackItem
                          styles={{
                            root: {
                              display:
                                uploadingVideosData.length < 5
                                  ? "block"
                                  : "none",
                              padding: "0.2rem 0 0.2rem 0.8rem",
                            },
                          }}
                        >
                          <input
                            type="file"
                            accept="video/mp4,video/x-m4v,video/*"
                            multiple
                            ref={this.hiddenFileInputRef}
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                          />
                          <ActionButton
                            styles={{
                              root: {
                                color: ThemeColorPalette.uploadVideoBtnTxt,
                              },
                            }}
                            iconProps={{ iconName: "Add" }}
                            allowDisabledFocus
                            onClick={this.handleFileClick}
                          >
                            Add Videos
                          </ActionButton>
                        </StackItem>
                        <StackItem
                          styles={{
                            root: {
                              visibility:
                                uploadingVideosData.length > 0
                                  ? "none"
                                  : "hidden",
                              padding: "0.2rem 0 0.2rem 1rem",
                            },
                          }}
                        >
                          <ActionButton
                            styles={{
                              root: {
                                color: ThemeColorPalette.uploadVideoBtnTxt,
                              },
                            }}
                            iconProps={{ iconName: "Delete" }}
                            allowDisabledFocus
                            onClick={this.handleDelete}
                            disabled={
                              uploadingVideosData.filter(
                                (item) => item.selectToUpload === true
                              ).length > 0
                                ? false
                                : true
                            }
                          >
                            Delete
                          </ActionButton>
                        </StackItem>
                      </Stack>
                      <div className="uploadchildGrid">
                        {uploadingVideosData.length
                          ? uploadingVideosData.map(
                              (item: any, index: number) => {
                                return (
                                  <VideoCard
                                    key={index}
                                    data={item}
                                    index={index}
                                    handleSelectedData={this.handleSelectedData}
                                  />
                                );
                              }
                            )
                          : null}
                      </div>
                    </div>
                  </Stack>
                )}
              </StackItem>
              <StackItem
                styles={{
                  root: {
                    backgroundColor: ThemeColorPalette.sidebar,
                    padding: "1rem 3rem",
                    position: "fixed",
                    bottom: 0,
                    width: "100%",
                  },
                }}
              >
                <PrimaryButton
                  type="submit"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Bold,
                      fontWeight: "Bold",
                    },
                  }}
                  text="Add Details"
                  disabled={
                    this.props.uploadingVideosData.length > 0 ? false : true
                  }
                  onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                    this.handleFirstStep(evt)
                  }
                />
                <Dialog
                  hidden={!this.state.uploadAlerts}
                  onDismiss={this.handlePopup}
                  dialogContentProps={dialogContentProps}
                  modalProps={modalProps()}
                  styles={{
                    root: {
                      background: "blur",
                    },
                  }}
                >
                  <DialogFooter>
                    <PrimaryButton text="Ok" onClick={this.handlePopup} />
                  </DialogFooter>
                </Dialog>
              </StackItem>
            </Stack>
          ) : (
            <VideoDetailsUpdate
              handleFileChange={this.handleFileChange}
              hiddenFileInputRef={this.hiddenFileInputRef}
              uploadAlerts={this.state.uploadAlerts}
              handlePopup={this.handlePopup}
              dialogContentProps={dialogContentProps}
              sequenceCheck={this.state.sequenceCheck}
            />
          )}
        </Stack>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any): uploadVideoProps => {
  return {
    uploadingVideosData: state.uploadVideoMetaDataReducer.uploadingVideosData,
    userProfile: state.dashboardReducerData.loginUserProfile,
  };
};
const dispatchActionToProps = (dispatch: any): uploadVideosDispatches => {
  return {
    updateUploadingVideosList: (data: any) =>
      dispatch(updateUploadingVideosList(data)),
    setSelectedUploadingVideosList: (data: any) =>
      dispatch(setSelectedUploadingVideosList(data)),
    deleteSelectedItem: (data: any) => dispatch(deleteSelectedItem(data)),
    clearUploadingVideosMetada: () => dispatch(clearUploadingVideosMetada()),
  };
};

export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(UploadVideoModel)
);
