import {
  Stack,
  Text,
  PrimaryButton,
  Icon,
  ActionButton,
  StackItem,
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../rootReducer";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
const Wizard = ({
  // spinner,
  disabledCreate,
  children,
  onCreate,
  onCancel,
  isEdit = false,
}: {
  // spinner: boolean;
  disabledCreate: any;
  children: React.ReactElement;
  onCreate: any;
  onCancel: any;
  isEdit: boolean;
}) => {
  useEffect(() => {}, []);
  return (
    <Stack>
      <Stack className="createHospitalMain">{children}</Stack>
      <Stack
        className="createHospitalWizardFooter"
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: "m" }}
      >
        <StackItem align="auto">
          <PrimaryButton
            // type="submit"
            className="btn-next"
            disabled={disabledCreate}
            onClick={onCreate}
            form="surgeonForm"
          >
            {isEdit ? "Update" : "Create"}
            <Text className="spinnerRight">
              {disabledCreate && <Spinner size={SpinnerSize.small} />}
            </Text>
          </PrimaryButton>
        </StackItem>
        <StackItem align="auto">
          <ActionButton
            text="Cancel"
            className="btn-c"
            allowDisabledFocus
            onClick={onCancel}
          />
        </StackItem>
      </Stack>
    </Stack>
  );
};

export default Wizard;
