import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import * as React from "react";
import ReactApexChart from "react-apexcharts";
import {
  convertDurationOfVideoToSec,
  formatDate,
  getDateWithSlashInBetween,
  getTime,
  secToHMS,
} from "../../common/util";
import { fonts, ThemeColorPalette } from "../../theme";
import "../containers/MyProcedures/MyProcedures.scss";

export interface ProceduresChartDisplayProps {
  data: any;
  date: any;
  location: any;
  history: any;
  selectedDateFilter: string;
  showSpinner: boolean;
  hospitalAvg: any;
}

export interface ProceduresChartDisplayState {
  data: any;
  series: Array<any>;
  options: any;
}

class ProceduresChartDisplay extends React.Component<
  ProceduresChartDisplayProps,
  ProceduresChartDisplayState
> {
  selectedSeries: any = null;
  state = {
    selectedDateFilter: "",
    data: null,
    series: [
      {
        data: [],
      },
    ],
    options: {},
  };
  static getDerivedStateFromProps(props: any, state: any) {
    const getVideosDuration: any = [];
    const durationSum =
      props.data &&
      props.data?.videosList?.reduce((acc: any, item: any) => {
        getVideosDuration.push(convertDurationOfVideoToSec(item.duration) / 60);
        return (
          acc + (item.duration && convertDurationOfVideoToSec(item.duration))
        );
      }, 0);
    const yourAverage =
      props.data && durationSum / props.data?.videosList?.length;
    const hospitalAvg = props.hospitalAvg;
    const xaxisValue: any = [];
    props?.date &&
      props?.date.filter((date: any) => {
        xaxisValue.push(formatDate(date.x));
      });
    const yaxisValue: any = [];
    props?.date &&
      props?.date.filter((date: any) => {
        yaxisValue.push(date.y);
      });
    const xLabels = xaxisValue
      .reduce((acc: any, v: any) => (acc.includes(v) ? acc : acc.concat(v)), [])
      .sort();
    const tempAry: any = [];
    const count: any = 0;
    const getMaxDurationOfVideo: any = Math.max(...getVideosDuration);
    const maxYaxis = Math.max(
      getMaxDurationOfVideo,
      hospitalAvg * 60,
      yourAverage / 60
    );
    //console.log(getVideosDuration, "avg",hospitalAvg * 60,yourAverage / 60, maxYaxis);

    // const findMaxMinutes = () => {
    //   let hospital = hospitalAvg * 60;
    //   let yourAvg = yourAverage / 60;

    //   console.log(hospital, yourAvg, "avarage this.state.options");
    //   if (hospital > yourAvg) {
    //     return hospital;
    //   } else {
    //     return yourAvg;
    //   }
    // };
    if (!props.data || !state.data) {
      return {
        ...state,
        data: props.data,
        selectedDateFilter: props.selectedDateFilter,
        series: [
          {
            // data:yaxisValue
            data: props.date,
            //data: [{x:'2022-11-07',y:11},{x:'2022-11-07',y:20},{x:'2022-11-07',y:30},],
          },
        ],
        options: {
          chart: {
            id: "area-datetime",
            type: "area",
            stacked: true,
            foreColor: "#A9A9A9",
            background: "transparent",
            // height: 300,
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },

          stroke: {
            curve: "straight",
            show: true,
            lineCap: "butt",
            colors: undefined,
            width: 1,
            dashArray: 0,
          },
          annotations: {
            yaxis: [
              {
                y: hospitalAvg * 60,
                borderColor: "#00B7C3",
                strokeDashArray: 0,
                label: {
                  show: true,
                  name: "test",
                  style: {
                    color: "#fff",
                    background: "#00B7C3",
                  },
                },
              },
              {
                y: yourAverage / 60,
                strokeDashArray: 0,
                borderColor: "#8CBD18",
                offsetX: 0,
                offsetY: -3,
                width: "100%",
                yAxisIndex: 0,
                label: {
                  show: true,
                  style: {
                    color: "#fff",
                    background: "#00E396",
                  },
                },
              },
            ],
          },
          yaxis: [
            {
              min: 0,
              max: maxYaxis,
              title: {
                text: "Duration (in Minutes)",
                style: {
                  color: "#A9A9A9",
                  fontWeight: "normal",
                },
              },
              axisBorder: {
                show: true,
                color: "#78909C",
                height: 1,
              },
              tickAmount: 5,
              labels: {
                formatter: (value: any) => {
                  return value.toFixed(2);
                },
              },
              // labels: {
              //   formatter: (value: any) => {
              //    // console.log(value,'value221')
              //     return Math.floor(value / 60 )
              //       ? Math.floor(value / 60 )
              //       : (value / 60).toFixed(4);
              //   },
              // },
            },
          ],
          dataLabels: {
            enabled: false,
          },
          grid: {
            show: false,
            borderColor: "#E2EBF4",
            strokeDashArray: 2,
            position: "back",
            padding: {
              right: 40,
            },
          },
          markers: {
            size: 3,
            colors: undefined,
            strokeColors: "",
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            showNullDataPoints: false,
            hover: {
              sizeOffset: 3,
            },
            // onClick: (e: React.FormEvent) => {
            //   props.history({
            //     pathname: "/video/" + this.selectedSeries.videoId,
            //     state: { procedureName: this.selectedSeries.procedureName },
            //   });
            // },
          },
          xaxis: {
            type: "datetime",
            categories: [],
            tickAmount: 5,
            tickPlacement: "between",
            range: undefined,
            floating: false,
            decimalsInFloat: undefined,
            overwriteCategories: undefined,
            position: "bottom",
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: false,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: false,
              minHeight: undefined,
              maxHeight: 120,
              offsetX: 0,
              offsetY: 0,
              format: "dd MMM",
              formatter: undefined,
              datetimeUTC: true,
              datetimeFormatter: {
                month: "MMM 'yy",
                day: "dd MMM",
              },
            },
            // group: {
            //   groups: [],
            //   style: {
            //     colors: [],
            //     fontSize: '12px',
            //     fontWeight: 400,
            //     fontFamily: undefined,
            //     cssClass: ''
            //   }
            // },
            // axisBorder: {
            //     show: true,
            //     color: '#78909C',
            //     height: 1,
            //     width: '100%',
            //     offsetX: 0,
            //     offsetY: 0
            // },
            axisTicks: {
              show: false,
              borderType: "solid",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
            // title: {
            //     text: undefined,
            //     offsetX: 0,
            //     offsetY: 0,
            //     style: {
            //         color: undefined,
            //         fontSize: '12px',
            //         fontFamily: 'Helvetica, Arial, sans-serif',
            //         fontWeight: 600,
            //         cssClass: 'apexcharts-xaxis-title',
            //     },
            // },
            // crosshairs: {
            //     show: true,
            //     width: 1,
            //     position: 'back',
            //     opacity: 0.9,
            //     stroke: {
            //         color: '#b6b6b6',
            //         width: 0,
            //         dashArray: 0,
            //     },
            //     fill: {
            //         type: 'solid',
            //         color: '#B1B9C4',
            //         gradient: {
            //             colorFrom: '#D8E3F0',
            //             colorTo: '#BED1E6',
            //             stops: [0, 100],
            //             opacityFrom: 0.4,
            //             opacityTo: 0.5,
            //         },
            //     },
            //     dropShadow: {
            //         enabled: false,
            //         top: 0,
            //         left: 0,
            //         blur: 1,
            //         opacity: 0.4,
            //     },
            // },
            // tooltip: {
            //     enabled: true,
            //     formatter: undefined,
            //     offsetY: 0,
            //     style: {
            //       fontSize: 0,
            //       fontFamily: 0,
            //     },
            // },
          },
          tooltip: {
            theme: "dark",
            custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
              const selectedSeries = props.date[dataPointIndex];
              return (
                "<div class='toolTip_design'>" +
                "<p class='toolTip_upload_details'>Uploaded on " +
                getTime(props.date[dataPointIndex].tooltipDate) +
                ", " +
                getDateWithSlashInBetween(selectedSeries.date) +
                "</p>" +
                "<p class='toolTip_upload_details'>" +
                "Duration: " +
                secToHMS(props.date[dataPointIndex].duration) +
                "</p>" +
                " </div>"
              );
            },
            shared: false,
            intersect: true,
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: ["#1d1d1d"], // optional, if not defined - uses the shades of same color in series
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 70, 100],
            },
          },
        },
      };
    } else if (
      (props.data &&
        state.data &&
        props.data.procedureId !== state.data.procedureId) ||
      props.data?.videosList?.length !== state.data?.videosList?.length ||
      props.selectedDateFilter !== state.selectedDateFilter ||
      props.location.pathname
    ) {
      return {
        ...state,
        data: props.data,
        selectedDateFilter: props.selectedDateFilter,
        series: [
          {
            // data:yaxisValue
            data: props.date,
            // data: [{x:'2022-11-07',y:11},{x:'2022-11-07',y:20},{x:'2022-11-07',y:30},],
          },
        ],
        options: {
          chart: {
            id: "area-datetime",
            type: "area",
            stacked: true,
            foreColor: "#A9A9A9",
            background: "transparent",
            // height: 300,
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: "straight",
            show: true,
            lineCap: "butt",
            colors: undefined,
            width: 1,
            dashArray: 0,
          },
          annotations: {
            yaxis: [
              {
                y: hospitalAvg * 60,
                borderColor: "#00B7C3",
                strokeDashArray: 0,
                label: {
                  show: true,
                  name: "test",
                  style: {
                    color: "#fff",
                    background: "#00B7C3",
                  },
                },
              },
              {
                y: yourAverage / 60,
                strokeDashArray: 0,
                borderColor: "#8CBD18",
                offsetX: 0,
                offsetY: -3,
                width: "100%",
                yAxisIndex: 0,
                label: {
                  show: true,
                  style: {
                    color: "#fff",
                    background: "#00E396",
                  },
                },
              },
            ],
          },
          yaxis: [
            {
              min: 0,
              max: maxYaxis,
              title: {
                text: "Duration (in Minutes)",
                style: {
                  color: "#A9A9A9",
                  fontWeight: "normal",
                },
              },
              axisBorder: {
                show: true,
                color: "#78909C",
                height: 1,
              },
              tickAmount: 5,
              labels: {
                formatter: (value: any) => {
                  return value.toFixed(2);
                },
              },
              // labels: {
              //   formatter: (value: any) => {
              //    // console.log(value,'value221')
              //     return Math.floor(value / 60 )
              //       ? Math.floor(value / 60 )
              //       : (value / 60).toFixed(4);
              //   },
              // },
            },
          ],
          dataLabels: {
            enabled: false,
          },
          grid: {
            show: false,
            borderColor: "#E2EBF4",
            strokeDashArray: 2,
            position: "back",
            padding: {
              right: 40,
            },
          },
          markers: {
            size: 3,
            colors: undefined,
            strokeColors: "",
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            showNullDataPoints: true,
            hover: {
              sizeOffset: 3,
            },
            // onClick: (e: React.FormEvent) => {
            //   props.history({
            //     pathname: "/video/" + this.selectedSeries.videoId,
            //     state: { procedureName: this.selectedSeries.procedureName },
            //   });
            // },
          },
          xaxis: {
            type: "datetime",
            categories: [],
            tickAmount: 5,
            tickPlacement: "between",
            range: undefined,
            floating: false,
            decimalsInFloat: undefined,
            overwriteCategories: undefined,
            position: "bottom",
            labels: {
              show: true,
              rotate: -45,
              rotateAlways: false,
              hideOverlappingLabels: true,
              showDuplicates: false,
              trim: false,
              minHeight: undefined,
              maxHeight: 120,
              offsetX: 0,
              offsetY: 0,
              format: "dd MMM",
              formatter: undefined,
              datetimeUTC: true,
              datetimeFormatter: {
                month: "MMM 'yy",
                day: "dd MMM",
              },
            },
            // group: {
            //   groups: [],
            //   style: {
            //     colors: [],
            //     fontSize: '12px',
            //     fontWeight: 400,
            //     fontFamily: undefined,
            //     cssClass: ''
            //   }
            // },
            // axisBorder: {
            //     show: true,
            //     color: '#78909C',
            //     height: 1,
            //     width: '100%',
            //     offsetX: 0,
            //     offsetY: 0
            // },
            axisTicks: {
              show: false,
              borderType: "solid",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0,
            },
            // title: {
            //     text: undefined,
            //     offsetX: 0,
            //     offsetY: 0,
            //     style: {
            //         color: undefined,
            //         fontSize: '12px',
            //         fontFamily: 'Helvetica, Arial, sans-serif',
            //         fontWeight: 600,
            //         cssClass: 'apexcharts-xaxis-title',
            //     },
            // },
            // crosshairs: {
            //     show: true,
            //     width: 1,
            //     position: 'back',
            //     opacity: 0.9,
            //     stroke: {
            //         color: '#b6b6b6',
            //         width: 0,
            //         dashArray: 0,
            //     },
            //     fill: {
            //         type: 'solid',
            //         color: '#B1B9C4',
            //         gradient: {
            //             colorFrom: '#D8E3F0',
            //             colorTo: '#BED1E6',
            //             stops: [0, 100],
            //             opacityFrom: 0.4,
            //             opacityTo: 0.5,
            //         },
            //     },
            //     dropShadow: {
            //         enabled: false,
            //         top: 0,
            //         left: 0,
            //         blur: 1,
            //         opacity: 0.4,
            //     },
            // },
            // tooltip: {
            //     enabled: true,
            //     formatter: undefined,
            //     offsetY: 0,
            //     style: {
            //       fontSize: 0,
            //       fontFamily: 0,
            //     },
            // },
          },
          tooltip: {
            theme: "dark",
            custom: ({ series, seriesIndex, dataPointIndex, w }: any) => {
              const selectedSeries = props.date[dataPointIndex];
              return (
                "<div class='toolTip_design'>" +
                "<p class='toolTip_upload_details'>Uploaded on " +
                getTime(props.date[dataPointIndex].tooltipDate) +
                ", " +
                getDateWithSlashInBetween(selectedSeries.date) +
                "</p>" +
                "<p class='toolTip_upload_details'>" +
                "Duration: " +
                secToHMS(props.date[dataPointIndex].duration) +
                "</p>" +
                " </div>"
              );
            },
            shared: false,
            intersect: true,
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "vertical",
              gradientToColors: ["#1d1d1d"], // optional, if not defined - uses the shades of same color in series
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 70, 100],
            },
          },
        },
      };
    }
    return null;
  }
  calculateAvarage = () => {
    const durationSum =
      this.props.data &&
      this.props.data?.videosList?.reduce((acc: any, item: any) => {
        return (
          acc + (item.duration && convertDurationOfVideoToSec(item.duration))
        );
      }, 0);
    const yourAvg =
      (this.props.data && durationSum / this.props.data?.videosList?.length) /
      60;
    const hospitalAvg = this.props.hospitalAvg * 60;
    //console.log(yourAvg, "youravg", hospitalAvg);
    return hospitalAvg > yourAvg ? hospitalAvg : yourAvg;
  };
  render() {
    const { data, date } = this.props;
    // if (this.props?.hospitalAvg && this.state?.options?.yaxis[0]) {
    //   this.state.options.yaxis[0].max = Number(
    //     (this.calculateAvarage()).toFixed(2)
    //   );
    // }
    return date && date.length ? (
      <Stack
        styles={{
          root: {
            display: "block",
            minHeight: "100%",
            // maxHeight: "42vh",
            minWidth: "527",
            backgroundColor: ThemeColorPalette.gridBG,
            boxShadow: "0 8px 14px 5px rgba(0,0,0,0.14)",
            padding: "1.5rem",
          },
        }}
        verticalFill
      >
        <Text
          className="textEclipsChart"
          variant="medium"
          styles={{
            root: {
              fontFamily: fonts.DINPro_Medium,
              color: ThemeColorPalette.uploadMsgTxt,
              marginBottom: "0.2rem",
            },
          }}
        >
          {"Showing timeline for " + data.procedureName}
        </Text>
        {this.props.showSpinner ? (
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { paddingTop: "15%" } }}
          >
            <Spinner size={SpinnerSize.large} />
          </Stack>
        ) : (
          <>
            <div id="chart">
              <div id="chart-timeline">
                <ReactApexChart
                  options={this.state.options as any}
                  series={this.state.series}
                  type="area"
                  height={"139%"}
                />
              </div>
            </div>
            <Stack
              horizontal
              tokens={{ childrenGap: 10, padding: "1.5rem 0 0 3rem" }}
            >
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Stack
                  styles={{
                    root: {
                      height: "0.7rem",
                      width: "0.7rem",
                      borderRadius: "15%",
                      backgroundColor: ThemeColorPalette.hospitalAverageColor,
                    },
                  }}
                ></Stack>
                <Text
                  variant="small"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Regular,
                      color: ThemeColorPalette.videoUploadDateTxt,
                    },
                  }}
                >
                  Hospital Average
                </Text>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Stack
                  styles={{
                    root: {
                      height: "0.7rem",
                      width: "0.7rem",
                      borderRadius: "15%",
                      backgroundColor: ThemeColorPalette.yourAverage,
                    },
                  }}
                ></Stack>

                <Text
                  variant="small"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Regular,
                      color: ThemeColorPalette.videoUploadDateTxt,
                    },
                  }}
                >
                  Your Average
                </Text>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    ) : (
      <Stack
        styles={{
          root: {
            minHeight: "42vh",
            maxHeight: "42%",
            minWidth: "75vh",
            maxWidth: "75vh",
            backgroundColor: ThemeColorPalette.gridBG,
            boxShadow: "0 8px 14px 5px rgba(0,0,0,0.14)",
            padding: "1.5rem",
            flex: 1,
          },
        }}
        verticalFill
        verticalAlign="center"
      >
        {this.props.showSpinner ? (
          <Stack horizontal horizontalAlign="center">
            <Spinner size={SpinnerSize.large} />
          </Stack>
        ) : (
          <Stack horizontal horizontalAlign="center">
            <Text
              styles={{
                root: {
                  fontFamily: fonts.DINPro_Medium,
                  color: ThemeColorPalette.uploadVideoBtnTxt,
                  textAlign: "center",
                },
              }}
            >
              No videos available
            </Text>
          </Stack>
        )}
      </Stack>
    );
  }
}

export default ProceduresChartDisplay;
