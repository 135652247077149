import React, { useState, useEffect, useCallback } from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  Icon,
  CommandBarButton,
  ActionButton,
} from "@fluentui/react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getDataFromBlobURL, getViewLogById } from "../../actions/logsActions";
import { AppState } from "../../../../rootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
import { Label } from "recharts";
const cancelIcon: IIconProps = { iconName: "Cancel" };
const download: IIconProps = { iconName: "download" };
const ViewLogDetails = ({
  isModalOpen,
  showModal,
  viewLogDetails,
}: {
  isModalOpen: any;
  showModal: any;
  viewLogDetails: any;
}) => {
  const dispatch = useDispatch();
  const blobData = useSelector((state: AppState) => {
    return state.logsReducerData.blobData.data;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessage;
  });
  const downloadBlobUrl = window.URL.createObjectURL(new Blob([blobData]));

  const downloadLog = () => {
    const blob = new Blob([blobData], { type: "text/plain" });
    const anchor: any = document.createElement("a");
    anchor.download = viewLogDetails?.original_file_name.replace(
      ".log",
      ".txt"
    );
    anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
    anchor.dataset.downloadurl = [
      "text/plain",
      anchor.download,
      anchor.href,
    ].join(":");
    anchor.click();
  };

  useEffect(() => {
    if (viewLogDetails?.log_file)
      dispatch(getDataFromBlobURL(viewLogDetails?.log_file));
  }, [viewLogDetails]);
  const navigate = useNavigate();
  return (
    <Modal
      className="logsView createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={() => showModal()}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          {/* <Text variant="large" className="muted pointer" onClick={() => showModal()}>
            Logs
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text> */}
          <Text
            variant="large"
            className="muted pointer"
            onClick={() => showModal()}
          >
            View Log Files
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">Logs View</Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton
            className=""
            iconProps={cancelIcon}
            onClick={() => showModal()}
          />
        </Stack.Item>
      </Stack>

      <Stack.Item className="userCreate ms-Grid">
        <Stack.Item className="ms-Grid-row">
          <Stack.Item className="ms-Grid-col">
            <Stack.Item className="ms-Grid devicePreview" dir="ltr">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text block className="heading">
                    Device Serial Number
                  </Text>
                  <Text>{viewLogDetails?.device_id}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text block className="heading">
                    Log
                  </Text>
                  <Text>{viewLogDetails?.original_file_name}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text block className="heading">
                    Hospital
                  </Text>
                  <Text>{viewLogDetails?.hospital}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text block className="heading">
                    Date & Time Stamp
                  </Text>
                  <Text>
                    {viewLogDetails?.created_date &&
                      moment(viewLogDetails?.created_date).format(
                        "MM/DD/YY HH:mm"
                      )}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text block className="heading">
                    Active
                  </Text>
                  <Text>Active</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2 downloadSection">
                  <Stack.Item className=" ms-Grid-col float-right downloadBtn">
                    <Text className="download">
                      <CheckPermission
                        {...constants.SCOPES.DEVELOPER_LOGS_DOWNLOAD}
                      >
                        <CommandBarButton
                          onClick={downloadLog}
                          // href={anchor?.downloadurl}
                          iconProps={download}
                          download
                          className=" ms-fontSize-16 ms-fontWeight-bold"
                          text="Download"
                        />
                      </CheckPermission>
                      {/* {viewLogDetails?.analyticslog_filelocation} */}
                    </Text>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
            {blobData && (
              <Stack className="logsViewSection">
                <pre className="logsViewData">{blobData}</pre>
              </Stack>
            )}
            {!blobData && loaderWithMessage?.message === "emptyRecord" && (
              <Stack
                className="logsnotFound"
                style={{
                  display: "flex",
                  flexFlow: "row",
                  cursor: "pointer",
                  textAlign: "center",
                  alignItems: "center",
                  width: "19%",
                  margin: "0 auto",
                }}
              >
                <p>The specified blob does not exist.</p>
              </Stack>
            )}
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
    </Modal>
  );
};
export default React.memo(ViewLogDetails);
