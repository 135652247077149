import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { authReducer } from "./auth/reducers/AuthReducer";
import { videoPlayerReducer } from "./VideoPlayer/reducers/videoPlayerReducer";
import { inProgressReducer } from "./InProgress/reducers/InProgressReducer";
import { layoutReducer } from "./Layout/reducers/layoutReducer";
import { favouritesReducer } from "./Favorites/reducers/favouritesReducer";
import { landingPageReducer } from "./MyProcedures/reducers/landingPageReducer";
import { proceduresReducer } from "./MyProcedures/reducers/proceduresReducer";
import { uploadVideoMetaDataReducer } from "./MyProcedures/reducers/uploadVideoMetaDataReducer";
import { sharedVideosReducer } from "./Shared/reducers/sharedVideosReducer";
import { recentActivitiesData } from "./RecentActivities/reducers/recentActivitiesReducer";

// Active Admin Home reducer
import { homeReducer } from "./ActiveAdminModule/Home/reducers/homeReducer";
// Active Admin Management reducer
import { managementReducer } from "./ActiveAdminModule/Management/reducers/managementReducer";
// Active Admin Logs reducer
import { logsReducer } from "./ActiveAdminModule/Logs/reducers/logsReducer";
// Hospital Admin Home reducer
import { hospitalHomeReducer } from "./HospitalAdminModule/Home/reducers/hospitalHomeReducer";
// Hospital Admin Management reducer
import { hospitalManagementReducer } from "./HospitalAdminModule/Management/reducers/hospitalManagementReducer";
import { hospitallLogsReducer } from "./HospitalAdminModule/Logs/reducers/logsReducer";
// Developer
import { developerHomeReducer } from "./DeveloperModule/Home/reducers/developerHomeReducer";
import { developerActivitiesData } from "./DeveloperModule/Activity/reducers/activityReducer";

import { hospitalActivitiesData } from "./HospitalAdminModule/Activity/reducers/activityReducer";

import { dataSetReducer } from "./DeveloperModule/DatasetMapping/reducers/datasetReducer";

import { developerManagementReducer } from "./DeveloperModule/Management/reducers/DeveloperManagementReducer";

const rootReducer = combineReducers({
  authReducerData: authReducer,
  videoPlayerReducerData: videoPlayerReducer,
  inProgressReducerData: inProgressReducer,
  dashboardReducerData: layoutReducer,
  favouritesReducerData: favouritesReducer,
  sharedVideosReducerData: sharedVideosReducer,
  proceduresReducerData: proceduresReducer,
  landingPageReducer: landingPageReducer,
  recentActivities: recentActivitiesData,
  uploadVideoMetaDataReducer: uploadVideoMetaDataReducer,
  router: routerReducer,

  homeReducerData: homeReducer,
  managementReducerData: managementReducer,
  logsReducerData: logsReducer,

  hospitalHomeReducerData: hospitalHomeReducer,
  hospitalActivityReducerData: hospitalActivitiesData,
  hospitalManagementReducerData: hospitalManagementReducer,
  hospitalLogsReducerData: hospitallLogsReducer,

  developerReducerData: developerHomeReducer,
  developerActivitiesReducerData: developerActivitiesData,
  developerDataSetReducerData: dataSetReducer,
  developerManagementReducerData: developerManagementReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
