import { Stack, Text, CommandBarButton, IIconProps } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { templateDownload } from "../../ActiveAdminModule/Logs/actions/logsActions";
import { AppState } from "../../rootReducer";
const download: IIconProps = { iconName: "download" };

const DownloadExampleCSVFile = ({ dataProcess }: { dataProcess: any }) => {
  const dispatch = useDispatch();

  const templateData = useSelector((state: AppState) => {
    return state.logsReducerData.templateData;
  });

  // const downloadBlobUrl = window.URL.createObjectURL(new Blob([dataProcess]));

  const downloadBlobUrl = window.URL.createObjectURL(
    new Blob([templateData?.data], { type: "text/csv" })
  );
  useEffect(() => {
    (typeof templateData === "undefined" || templateData?.data === "") &&
      dispatch(templateDownload(dataProcess));
    dispatch(templateDownload(dataProcess));
  }, []);
  return (
    <Stack.Item className="ms-Grid ms-mt-25 download_example_page" dir="ltr">
      <Stack className="InputSection procedureType ms-Grid-row">
        <Stack.Item className="ms-Grid-row devicePreview" dir="ltr">
          <Stack horizontal verticalAlign="center">
            <Stack.Item className="ms-Grid-col ms-lg8 ">
              <Text variant="xSmall">
                Downlaod Template from here and Add your Data to the Template
                file.
              </Text>
            </Stack.Item>
            <Stack.Item className="ms-Grid-col ms-lg4 ">
              <Text className="downloadBtn">
                <Text className="download" variant="small">
                  <CommandBarButton
                    // onClick={templateDownload(dataProcess)}
                    href={downloadBlobUrl}
                    iconProps={download}
                    download
                    className=" ms-fon ms-fontWeight-bold"
                    text="Download"
                  />
                </Text>
              </Text>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack.Item>
  );
};

export default React.memo(DownloadExampleCSVFile);
