import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  ListPeoplePicker,
  ValidationState,
} from "@fluentui/react";
import * as React from "react";

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText:
    "Suggested People(User can share videos to max of 10 people)",
  mostRecentlyUsedHeaderText:
    "Suggested Contacts(User can share videos to max of 10 people)",
  noResultsFoundText:
    "No results found / selection limit exceeding more than 10",
  loadingText: "Loading",
  // showRemoveButtons: true,
  suggestionsAvailableAlertText: "People Picker Suggestions available",
  suggestionsContainerAriaLabel: "Suggested contacts",
};

export interface SharedPeoplePickerProps {
  userListNames: Array<any>;
  onItemSelect: any;
  showSelected: boolean;
}

const SharedPeoplePicker: React.FunctionComponent<SharedPeoplePickerProps> = ({
  userListNames,
  onItemSelect,
  showSelected,
}) => {
  const [currentSelectedItems, setCurrentSelectedItems] = React.useState<
    IPersonaProps[]
  >([]);

  const [delayResults] = React.useState(false);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([
    ...(userListNames || []),
  ]);
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<
    IPersonaProps[]
  >([...(userListNames || [])]);

  const onItemsChange = (items: any): void => {
    setCurrentSelectedItems(items);
  };

  const picker = React.useRef(null);
  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText && currentPersonas && currentPersonas.length < 10) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);
      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peopleList.filter((item) =>
      doesTextStartWith(item.text as string, filterText)
    );
  };

  const filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (delayResults) {
      return convertResultsToPromise(personasToReturn);
    } else {
      return personasToReturn;
    }
  };

  const returnMostRecentlyUsed = (
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (currentPersonas && currentPersonas.length < 10) {
      return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    }
    return [];
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };
  return (
    <ListPeoplePicker
      // onItemSelected={(selectedItem) => onItemSelect(selectedItem)}
      onChange={(selectedItem) => {
        onItemsChange(selectedItem);
        onItemSelect(selectedItem);
      }}
      selectedItems={showSelected ? currentSelectedItems : []}
      onResolveSuggestions={onFilterChanged}
      onEmptyInputFocus={returnMostRecentlyUsed}
      getTextFromItem={getTextFromItem}
      className={"ms-PeoplePicker"}
      pickerSuggestionsProps={suggestionProps}
      key={"list"}
      componentRef={picker}
      onRemoveSuggestion={onRemoveSuggestion}
      onValidateInput={validateInput}
      resolveDelay={300}
      itemLimit={10}
      styles={{ root: { paddingBottom: "1rem" } }}
    ></ListPeoplePicker>
  );
};

function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}
function removeDuplicates(
  personas: IPersonaProps[],
  possibleDupes: IPersonaProps[] | undefined
) {
  return personas.filter(
    (persona) => !listContainsPersona(persona, possibleDupes)
  );
}

function listContainsPersona(persona: any, personas: any[] | undefined) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return (
    personas.filter((item) => item.shared_with === persona.shared_with).length >
    0
  );
}

function convertResultsToPromise(
  results: IPersonaProps[]
): Promise<IPersonaProps[]> {
  return new Promise<IPersonaProps[]>((resolve, reject) =>
    setTimeout(() => resolve(results), 2000)
  );
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf("@") !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

export default SharedPeoplePicker;
