import * as allActions from "../actions/actionConstants";

const initialState: any = {
  loginUserName: "",
  loginUserProfile: {},
  userDetails: [],
  procedureDetails: [],
  videoByIdDetails: {},
  notificationActions: [],
  notifications: [],
  sharedWithMe: [],
  UserProfileSpinner: false,
  profileUpdateError: undefined,
  videoUploadFail: false,
  procedureForVideoUpload: null,
};

const checkforDuplicate = (notificationList: any, currentData: any) => {
  return notificationList.length
    ? !!notificationList.find(
        (n: any) =>
          n.data.status === currentData.status &&
          n.data.name === currentData.name &&
          n.data.message === currentData.message &&
          n.data.video_id === currentData.video_id &&
          n.data.sent_at === currentData.sent_at
      )
    : false;
};

const checkForActionDuplicate = (notificationList: any, currentData: any) => {
  return notificationList.length
    ? !!notificationList.find(
        (n: any) =>
          n.data.name === currentData.name &&
          n.data.message === currentData.message &&
          n.data.video_id === currentData.video_id &&
          n.data.sent_at === currentData.sent_at
      )
    : false;
};

const getOflineActionNotification = (
  stateNotification: any,
  offlineData: any
) => {
  if (stateNotification) {
    return [
      ...stateNotification,
      ...offlineData.filter(
        (value: any) =>
          (value.type === "share-video" || value.type === "video-approval") &&
          value
      ),
    ];
  } else {
    return [
      ...offlineData.filter(
        (value: any) =>
          (value.type === "share-video" || value.type === "video-approval") &&
          value
      ),
    ];
  }
};

export const layoutReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case allActions.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case allActions.GET_LOGIN_USER_PROFILE:
      return {
        ...state,
        loginUserProfile: action.payload,
        loginUserName: action.payload.displayName,
      };
    case allActions.GET_SHARED_VIDEOS:
      return {
        ...state,
        sharedWithMe: action.payload,
      };
    // case allActions.GET_PROCEDURE_DETAILS:
    //   return { ...state, procedureDetails: action.payload };
    case allActions.GET_PROCEDURE_DETAILS:
      const previousData = state.procedureDetails?.results || [];
      state.procedureDetails = action.payload;
      state.procedureDetails.results = [
        ...previousData,
        ...state.procedureDetails.results,
      ];
      const resArr: any = [];
      state.procedureDetails.results.forEach(function (item: any) {
        const i = resArr.findIndex((x: any) => x.id == item.id);
        if (i <= -1) {
          resArr.push(item);
        }
      });
      state.procedureDetails.results = resArr;
      return {
        ...state,
        loadingState: false,
      };
    case allActions.GET_PROCEDURE_DETAILS_VIDEO_UPLOAD:
      const currentProcedureDetailsVideoList =
        state.procedureForVideoUpload?.results || [];
      const previousProcedureTypeData = action.isNext
        ? currentProcedureDetailsVideoList || []
        : [];
      const updatedProcuedureVideType = action.payload;
      updatedProcuedureVideType.results = previousProcedureTypeData.concat(
        updatedProcuedureVideType.results
      );
      return {
        ...state,
        procedureForVideoUpload: updatedProcuedureVideType,
      };
    case allActions.GET_SELECTED_VIDEO_INFO:
      return action.payload;
    case allActions.GET_VIDEO_BY_ID:
      return action.payload;
    case allActions.GET_OFFLINE_NOTIFICATIONS:
      const offlineData = action.payload;
      return {
        ...state,
        notificationActions: getOflineActionNotification(
          state.notificationActions,
          offlineData
        ),
        notifications: [
          ...state.notifications,
          ...offlineData.filter((value: any) => {
            return (
              value.type !== "share-video" &&
              value.type !== "video-approval" &&
              value
            );
          }),
        ],
      };
    case allActions.GET_NOTIFICATIONS_FROM_PUSHER:
      const pusherData = action.payload;
      const isDuplicate = state.notifications.length
        ? checkforDuplicate(state.notifications, pusherData)
        : false;
      const isnotificationActionDuplicate = state.notifications.length
        ? checkForActionDuplicate(state.notificationActions, pusherData)
        : false;
      return {
        ...state,
        notificationActions:
          pusherData &&
          !isnotificationActionDuplicate &&
          pusherData.type !== "pusher:subscription_succeeded" &&
          (pusherData.type === "share-video" ||
            pusherData.type === "video-approval")
            ? [...state.notificationActions, pusherData]
            : state.notificationActions,
        notifications:
          pusherData &&
          !isDuplicate &&
          pusherData.type !== "pusher:subscription_succeeded" &&
          pusherData.type !== "share-video" &&
          pusherData.type !== "video-approval"
            ? [...state.notifications, pusherData]
            : state.notifications,
      };
    case allActions.CLEAR_ALL_NOTIFICATIONS:
      return {
        ...state,
        notificationActions:
          action.payload === "action" ? [] : [...state.notificationActions],
        notifications:
          action.payload === "notification" ? [] : [...state.notifications],
      };
    case allActions.ACCEPT_NOTIFICATION:
      return {
        ...state,
        notificationActions:
          state.notificationActions.length &&
          state.notificationActions.filter(
            (val: any) => val.data.video_id !== action.payload
          ),
      };
    case allActions.CLEAR_DASHBOARD_DATA_ON_LOGOUT:
      return {
        loginUserName: "",
        loginUserProfile: {},
        userDetails: [],
        procedureDetails: [],
        procedureForVideoUpload: null,
        videoByIdDetails: {},
        // notificationActions: [],
        notifications: [],
        sharedWithMe: [],
      };

    case allActions.EDIT_USER_PROFILE:
      return {
        ...state,
        UserProfileSpinner: true,
      };
    case allActions.EDIT_USER_ERROR:
      return {
        ...state,
        profileUpdateError: action.payload.data,
        UserProfileSpinner: false,
      };
    case allActions.RESET_USER_ERROR:
      return {
        ...state,
        profileUpdateError: undefined,
        UserProfileSpinner: false,
      };
    case allActions.SPINNER_STOP_LAYOUT:
      return {
        ...state,
        UserProfileSpinner: false,
      };
    case allActions.SHOW_VIDEO_UPLOAD_ERROR:
      return {
        ...state,
        videoUploadFail: action.payload,
      };
    case allActions.HIDE_VIDEO_UPLOAD_ERROR:
      return {
        ...state,
        videoUploadFail: null,
      };
    default:
      return state;
  }
};
