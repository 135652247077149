import { Stack, Text } from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import { ActionButton } from "@fluentui/react";
import {
  getDataSetAlg,
  getAppliedModelIdVideo,
  getEditedDataSetDetails,
  getMappedAlgorithms,
} from "../../actions/datasetAction";
import "./DatasetList.scss";
import * as allActions from "../../actions/actionConstants";
import { useNavigate } from "react-router-dom";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
import { AppState } from "../../../../rootReducer";

const DatasetListItem = (dataList: any) => {
  const {
    hospital: { name, id },
    onToggle,
    active,
    contentData,
    onEditDataSet,
  } = dataList;
  const dispatch = useDispatch();

  useEffect(() => {
    if (active) {
      dispatch(getDataSetAlg(id));
    }
  }, [active]);

  const loadMore = () => {
    if (contentData.next) {
      dispatch(getDataSetAlg(id, -1, contentData.next));
    }
  };

  const navigate = useNavigate();

  const getAccordionContentRow = (data: any, itemNo: number) => {
    const {
      dataset_name,
      video_name,
      status,
      streaming_endpoint,
      video,
      algorithm,
    } = data;

    if (data.algorithm === id) {
      return (
        <Stack.Item
          className="ms-Grid-col"
          key={video}
          styles={{ root: { background: "#00000033", padding: 2 } }}
        >
          <Stack.Item className="ms-Grid-row">
            <Stack.Item
              className="ms-Grid-col"
              styles={{ root: { display: "flex" } }}
            >
              <Text
                block
                className=" ms-fontSize-12 accordionHideListHeading"
                styles={{
                  root: { width: "50%", padding: "0px 8px", cursor: "pointer" },
                }}
                onClick={
                  status === "Ready" || status === "Processing"
                    ? (evt: React.MouseEvent<HTMLBRElement>) => {
                        evt.preventDefault();
                        dispatch(getAppliedModelIdVideo(video, algorithm));
                        dispatch({
                          type: allActions.FETCH_VIDEO_DETAILS,
                          payload: { data, sharedVideo: true },
                        });
                        dispatch(getMappedAlgorithms(data.dataset_uuid));
                        navigate(
                          "/video/" +
                            video +
                            "/" +
                            dataset_name +
                            "/" +
                            video_name,
                          {
                            state: {
                              streaming_endpoint: { ...streaming_endpoint },
                              loadedFrom: "developer_dataset",
                            },
                          }
                        );
                      }
                    : undefined
                }
              >
                {dataset_name}
              </Text>
              <Text
                className=" ms-fontSize-12 accordionHideAddress"
                styles={{
                  root: {
                    width: "50%",
                    padding: "0px 8px",
                    cursor: "pointer",
                    color: "#a8a8a8",
                  },
                }}
              >
                {status}
              </Text>
              {status === "Ready" ? (
                <Stack.Item
                  styles={{
                    root: {
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "-80px",
                    },
                  }}
                >
                  <CheckPermission
                    {...constants.SCOPES.DEVELOPER_EDIT_DATASET_MAPPING}
                  >
                    <DefaultButton
                      text="Edit"
                      onClick={() => {
                        dispatch(getEditedDataSetDetails(data));
                        onEditDataSet(id);
                      }}
                      styles={{
                        root: {
                          color: " rgb(1, 177, 255)",
                          cursor: "pointer",
                          border: "none",
                          fontSize: "13px",
                          background: "black",
                        },
                      }}
                    />
                  </CheckPermission>
                  <Text
                    styles={{
                      root: { padding: "0px 5px", cursor: "pointer" },
                    }}
                  ></Text>
                </Stack.Item>
              ) : undefined}
            </Stack.Item>
          </Stack.Item>
        </Stack.Item>
      );
    }
  };

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.loaderWithMessage;
  });

  return (
    <Stack className={`hospitalListAccordion`}>
      {/* -----------------Accordion Heading----------------- */}
      <Stack.Item
        className="ms-Grid-row ms-textAlignLeft hospitalListAccordionMain"
        key={id}
        onClick={onToggle}
      >
        <Stack.Item
          className="hospitalName"
          styles={{
            root: {
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
              background: "#1F1F1F",
              margin: "1px",
            },
          }}
        >
          <Stack.Item className="ms-Grid-col">
            <Stack.Item className="ms-Grid-row">
              <Stack.Item
                className="ms-Grid-col dataset-name-ellipses"
                title={name}
              >
                <Text className="ms-fontSize-14">{name}</Text>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>

          <Stack.Item className="ms-Grid-col ms-lg1 flex">
            <Stack horizontal horizontalAlign="end">
              <Stack.Item>
                {active ? (
                  <Icon iconName="ChevronUp" className=" ms-fontSize-10" />
                ) : (
                  <Icon iconName="ChevronDown" className=" ms-fontSize-10" />
                )}
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>

      {/* -----------------Accordion Show Hide Content----------------- */}
      {active && (
        <Stack.Item className={`ms-textAlignLeft accordionHideMainList`}>
          <div className="ms-Grid-row accordionHideList">
            <Stack.Item
              className="ms-Grid-col"
              styles={{ root: { background: "#2F2F2F", padding: 10 } }}
            >
              <Stack.Item className="ms-Grid-row">
                <Stack.Item
                  className="ms-Grid-col"
                  styles={{ root: { display: "flex" } }}
                >
                  <Text
                    block
                    className=" ms-fontSize-12 accordionHideListHeading"
                    styles={{ root: { width: "50%", padding: "0px 8px" } }}
                  >
                    DataSet Name
                  </Text>
                  <Text
                    className=" ms-fontSize-12 accordionHideAddress"
                    styles={{
                      root: {
                        width: "50%",
                        padding: "0px 8px",
                        cursor: "pointer",
                      },
                    }}
                  >
                    Status
                  </Text>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
            <LoaderWithMessage
              loaderWithMessage={loaderWithMessage}
            ></LoaderWithMessage>
            {loaderWithMessage?.loadingState === false &&
              loaderWithMessage?.statusCode !== 403 &&
              contentData?.results?.map(getAccordionContentRow)}
            <Stack.Item>
              {contentData?.next && (
                <ActionButton
                  text="Load More Datasets"
                  className="btn-c"
                  allowDisabledFocus
                  onClick={loadMore}
                />
              )}
            </Stack.Item>
          </div>
        </Stack.Item>
      )}
    </Stack>
  );
};
export default DatasetListItem;
