import moment from "moment";
import { Stack } from "office-ui-fabric-react";
import React, { memo, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import {
  defaultValueFilterSection,
  getServiceLogs,
} from "../../actions/logsActions";
import geoURL from "./features.json";

const MapChart = ({
  setTooltipContent,
  logs,
  deviceSearch,
  deviceClickHandler,
  initialData,
}: {
  setTooltipContent: any;
  logs: any;
  deviceSearch: any;
  deviceClickHandler: any;
  initialData?: any;
}) => {
  const dispatch = useDispatch();
  // const markers = [
  //     { markerOffset: -15, name: "La Paz", coordinates: [-74.006, 40.7128] },
  //     { markerOffset: -15, name: "La Paz", coordinates: [-74.006, 40.7128] },
  // ];
  // const mapchart = markers.map((marker: any) => {
  //     console.log(marker.coordinates, 'coordinates');
  // })
  const oneMonthBack = moment().subtract(1, "months").toDate();
  const currentDate = moment().toDate();
  const [startDate, setStartDate] = useState(oneMonthBack);
  const [endDate, setEndDate] = useState(currentDate);
  const [newMarkers, setNewmarkers] = useState([]);

  const startDateFormat = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : "";
  const endDateFormat = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
  // const getAllLongitude:any =  logs.locations.map((loc:any) => {return loc.longitude}).filter(Boolean) || [];
  // console.log(getAllLongitude,"getAllLongitude");
  // let duplicateLongitude: any = [];
  // const getLatitude = (longitude: any) => {
  //   if (duplicateLongitude.includes(Math.trunc(longitude))) {
  //     return longitude + 1
  //   } else {
  //     duplicateLongitude.push(Math.trunc(longitude));
  //     return longitude;
  //   }
  // };

  function getUniqueListBy(arr: any, key: any) {
    return (
      arr &&
      arr.filter(
        (ele: any, ind: any) =>
          ind === arr.findIndex((elem: any) => elem[key] === ele[key])
      )
    );
  }

  const getDuplicatesss = (myArr: any) => {
    if (myArr && myArr.length) {
      const res = Object.values(
        myArr.reduce((groups: any, current: any) => {
          if (!groups[current.longitude]) {
            groups[current.longitude] = [];
          }
          groups[current.longitude].push(current);
          return groups;
        }, {})
      ).flatMap((value: any) => {
        if (value.length > 1) {
          return value.map((current: any, i: any, arr: any) => ({
            ...current,
            duplicate: i + 1,
          }));
        }
        return value;
      });
      return res;
    }
  };

  useEffect(() => {
    const markersCopy = getUniqueListBy(logs?.locations, "facilityID");
    const newMarkersCopy: any = getDuplicatesss(markersCopy);
    setNewmarkers(newMarkersCopy);
  }, [logs]);

  const markers =
    (newMarkers &&
      newMarkers
        ?.map((location: any) => {
          if (location?.longitude && location?.latitude) {
            return {
              coordinates: [
                location?.duplicate
                  ? location?.longitude + location?.duplicate
                  : location?.longitude,
                location?.latitude,
              ],
              hospital: location.hospital,
              facility: location.facility,
              room: location.room,
              region: location.region,
              device: location.deviceID,
              country: location.country,
              location: location,
            };
          }
        })
        .filter(Boolean)) ||
    [];

  const uniqueRoomFilter = initialData?.locations && [
    ...(logs?.locations || []),
  ];

  const uniqueDeviceilter = logs?.locations && [...(logs?.locations || [])];
  const getRooms = (facility: any) => {
    const filteredRooms = uniqueRoomFilter
      ?.map((logRoom: any) => {
        if (facility) {
          if (logRoom.facility === facility) {
            return logRoom.room;
          }
        } else {
          return logRoom.room;
        }
      })
      .filter((room: any) => room);
    const uniqueRooms = filteredRooms?.filter((c: any, index: any) => {
      return filteredRooms.indexOf(c) === index;
    });
    return uniqueRooms?.join(" , ");
  };

  const getDevicesForRegion = (facility: any) => {
    const filtereddeviceIDs = uniqueDeviceilter
      ?.map((logRoom: any) => {
        if (facility) {
          if (logRoom.facility === facility) {
            return logRoom.deviceID;
          }
        } else {
          return logRoom.deviceID;
        }
      })
      .filter((device: any) => device);
    const uniquedevices = filtereddeviceIDs?.filter((c: any, index: any) => {
      return filtereddeviceIDs.indexOf(c) === index;
    });
    return uniquedevices?.join(" , ");
  };

  const rooms = markers?.map((m: any) => m.room).join(", ");
  const devices = markers?.map((m: any) => m.device).join(", ");
  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoAlbers"
        projectionConfig={
          {
            // scale: 400,
            // center:[20, 20]
          }
        }
        style={{
          height: "452",
          width: "95%",
        }}
      >
        <Geographies geography={geoURL}>
          {({ geographies }) => (
            <>
              {geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill="#303030E6"
                  stroke="#101112"
                />
              ))}
            </>
          )}
        </Geographies>
        {markers &&
          markers?.map((marker: any, index: any) => (
            <NavLink to="/logs" key={index}>
              <Marker
                key={index}
                coordinates={marker?.coordinates}
                onMouseEnter={() => {
                  setTooltipContent([
                    marker?.region,
                    marker?.hospital,
                    marker?.facility,
                    getDevicesForRegion(marker?.facility),
                    getRooms(marker?.facility),
                  ]);
                }}
                onMouseLeave={() => {
                  setTooltipContent([]);
                }}
                onClick={() => {
                  const dfdsf = deviceClickHandler
                    ? deviceClickHandler(
                        marker?.location?.hospitalID,
                        marker?.location?.facilityID,
                        marker?.room,
                        marker?.region,
                        marker?.country,
                        "",
                        startDateFormat,
                        endDateFormat
                      )
                    : deviceSearch(marker?.device);
                }}
              >
                <g
                  fill="none"
                  stroke="#01b1ff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  transform="translate(-12, -24)"
                >
                  <circle cx="12" cy="10" r="4" fill="#01b1ff" />
                  <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                </g>
                {/* <circle r={8} fill="#F53" /> */}
              </Marker>
              {/* </Stack> */}
            </NavLink>
          ))}
        {/* <Marker coordinates={[-74.006, 40.7128]}>
                        <circle r={8} fill="#F53" />
                    </Marker> */}
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
