import React from "react";
import Loader from "../../ActiveAdminModule/Logs/components/Common/Loader";
import { Stack, Text } from "@fluentui/react";

const renderLoader = () => {
  return (
    <>
      <Loader></Loader>
    </>
  );
};

const renderMessage = (message: any) => {
  return (
    <>
      <Stack style={{ padding: "10%", alignItems: "center" }}>
        <Text>{message}</Text>
      </Stack>
    </>
  );
};

const LoaderWithMessage = ({ loaderWithMessage }: any) => {
  return (
    <>
      {loaderWithMessage?.loadingState === true && renderLoader()}
      {loaderWithMessage?.loadingState === false &&
        loaderWithMessage?.statusCode === 403 &&
        loaderWithMessage?.calledFrom === loaderWithMessage?.calledFrom &&
        renderMessage(loaderWithMessage.message)}
    </>
  );
};

export default LoaderWithMessage;
