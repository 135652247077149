import { Stack } from "office-ui-fabric-react";
import React, { memo } from "react";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
  const loaderData = {
    Component: TailSpin,
    props: {
      color: "#0ead69",
      secondaryColor: "#0ead69",
      height: 100,
      width: 110,
    },
    name: "MutatingDots",
  };
  return (
    <Stack className="loader">
      <Stack
        data-tip={loaderData.name}
        data-for="happyFace"
        key={loaderData.name}
        className="loaderBox"
      >
        <loaderData.Component {...loaderData.props} />
      </Stack>
    </Stack>
  );
};
export default Loader;
