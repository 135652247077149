import { getRandomColor } from "../../common/util";
import * as allActions from "../actions/actionConstants";

const initialState = {
  procedureVideos: [],
  procedureVideosRowData: [],
  procedureDateFilterType: "",
  customDate: {
    fromDate: null,
    toDate: null,
  },
  color: [...getRandomColor()],
  showSpinner: true,
  showData: false,
};

export const landingPageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_PROCEDURE_VIDEOS:
      const procedureVideos =
        action.payload &&
        action.payload.length &&
        action.payload
          .sort((a: any, b: any) => b.videosList.length - a.videosList.length)
          .map((item: any, i: number) => {
            return {
              ...item,
              color: state.color[i],
            };
          });
      return {
        ...state,
        procedureVideos,
        showData: procedureVideos
          ? procedureVideos[0].videosList.length > 0
          : false,
        showSpinner: false,
      };
    case allActions.FETCH_PROCEDURE_ROW_VIDEOS:
      return {
        ...state,
        procedureVideosRowData: action.payload,
        showSpinner:
          action.payload &&
          action.payload?.results?.length &&
          action.payload?.results?.filter(
            (item: any) => item.videosList.length > 0
          )?.length &&
          false,
      };
    case allActions.SET_SELECTED_PROCEDURE_VALUE:
      let hasVideos = false;
      return {
        ...state,
        procedureVideos: state.procedureVideos.map((item: any) => {
          if (item.procedureId === action.payload.procedureId) {
            hasVideos = item.videosList.length > 0;
            return {
              ...item,
              isSelected: true,
            };
          } else {
            return {
              ...item,
              isSelected: false,
            };
          }
        }),
        showData: hasVideos,
      };
    case allActions.PROCEDURE_DATA_DATE_FILTER_TYPE:
      return {
        ...state,
        procedureDateFilterType: action.payload,
        customDate:
          action.payload !== "customDate"
            ? {
                fromDate: null,
                toDate: null,
              }
            : state.customDate,
      };
    case allActions.PROCEDURE_CUSTOM_DATE_FILTER:
      return {
        ...state,
        customDate: {
          ...state.customDate,
          [action.payload.dateType]: action.payload.date,
        },
      };
    case allActions.CLEAR_DATA_ON_LOGOUT:
      return {
        procedureVideos: [],
        procedureVideosRowData: [],
        procedureDateFilterType: "",
        customDate: {
          fromDate: null,
          toDate: null,
        },
        color: [...getRandomColor()],
      };
    default:
      return state;
  }
};
