export const GET_DATASETS = "GET_DATASETS";
export const GET_DATASET_ALG = "GET_DATASET_ALG";
export const UPDATE_DATASET_ALG = "UPDATE_DATASET_ALG";
export const DATASET_SPINNER = "DATASET_SPINNER";
export const SHOW_LOADER = "SHOW_LOADER";
export const LOADER = "LOADER";
export const STOPLOADER = "STOPLOADER";
export const ALGORITHMS_LIST = "ALGORITHMS_LIST";
export const GET_ALGO_LIST = "GET_ALGO_LIST";
export const REFRESH_ALGO_LIST = "REFRESH_ALGO_LIST";
export const CREATE_DATASET = "CREATE_DATASET";
export const CREATE_DATASET_ERROR = "CREATE_DATASET_ERROR";
export const CLEAR_CREATE_DATASET_ERROR = "CLEAR_CREATE_DATASET_ERROR";
export const APPLIED_MODELID_OF_VIDEO = "APPLIED_MODELID_OF_VIDEO";
export const FETCH_VIDEO_DETAILS = "FETCH_VIDEO_DETAILS";
export const SET_DATASET_VIDEO_METADATA = "SET_DATASET_VIDEO_METADATA";
export const CLEAR_DATASET_VIDEO_METADATA = "CLEAR_DATASET_VIDEO_METADATA";
export const SHOW_DATASET_LOADER = "SHOW_DATASET_LOADER";
export const HIDE_DATASET_LOADER = "HIDE_DATASET_LOADER";
export const CLEAR_DATASETS = "CLEAR_DATASETS";
export const SHOW_DELETE_WARNING_MESSAGE_BAR =
  "SHOW_DELETE_WARNING_MESSAGE_BAR";
export const HIDE_DELETE_WARNING_MESSAGE_BAR =
  "HIDE_DELETE_WARNING_MESSAGE_BAR";
export const SHOW_EDIT_DATASET_MODEL = "SHOW_EDIT_DATASET_MODEL";
export const HIDE_EDIT_DATASET_MODEL = "HIDE_EDIT_DATASET_MODEL";
export const EDITED_DATASET_DETAILS = "EDITED_DATASET_DETAILS";
export const UPDATE_EDITED_DATASET = "UPDATE_EDITED_DATASET";
export const DELETE_DATASET = "DELETE_DATASET";
export const ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET =
  "ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET";
export const EDIT_SUCCESS_MESSAGE = "EDIT_SUCCESS_MESSAGE";
export const CLOSE_SUCCESS_MESSAGE = "CLOSE_SUCCESS_MESSAGE";
export const GET_MAPPED_ALGORITHMS_TO_DATASET =
  "GET_MAPPED_ALGORITHMS_TO_DATASET";
