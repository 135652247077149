import {
  DatePicker,
  Dropdown,
  IDatePickerStrings,
  IDropdownOption,
  IDropdownStyles,
  Label,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import React, { FormEvent, useState } from "react";
import CheckPermission from "../../../common/permissions/rolePermissions";
import { currentDate, getDateWithSlashInBetween } from "../../../common/util";
import { SCOPES } from "../../../Layout/constants/constants";
import { fonts, ThemeColorPalette } from "../../../theme";
import "../containers/InProgress.scss";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";


interface InprogressVideoEditProps {
  userProfile?: any;
  handlePatientConsentFormFileChange?: any;
  handleEdit?: any;
  handleChange?: any;
  data: any;
  dropdownVals: any;
  userData: any;
  screen?: string;
  dropdownSelected?: boolean;
  errorObject?:any
}

interface InprogressVideoEditState {
  value: null;
  selectedProcedure1: number;
  selectedProcedure2: number;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const DayPickerStrings: IDatePickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shortDays: ["S", "M", "T", "W", "T", "F", "S"],
  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker",
  monthPickerHeaderAriaLabel: "{0}, select to change the year",
  yearPickerHeaderAriaLabel: "{0}, select to change the month",
};

const InprogressVideoEdit: React.FunctionComponent<
  InprogressVideoEditProps
> = ({
  userProfile,
  handlePatientConsentFormFileChange,
  handleChange,
  data,
  dropdownVals,
  userData,
  screen,
  dropdownSelected,
  errorObject,
}: InprogressVideoEditProps) => {
    const { control } = useForm();
    const hiddenFileInputRef: any = React.useRef(null);
    const [selectedProcedure1, setSelectedProcedure1] = React.useState(null);
    const [selectedProcedure2, setSelectedProcedure2] = React.useState(null);

    let procedureDropDownOptions1: any =
      screen === "detailsUpdateScreen"
        ? [{ value: 123, label: "Select Procedure Name" }]
        : [];
    dropdownVals &&
      dropdownVals?.results?.forEach((val: any) => {
        procedureDropDownOptions1.push({ value: val.id, label: val.name });
      });

    let procedureDropDownOptions2: any = [
      // { key: 123, text: "Select Procedure Name" },
    ];

    dropdownVals &&
      dropdownVals?.results?.forEach((val: any) => {
        procedureDropDownOptions2.push({ value: val.id, label: val.name });
      });

    procedureDropDownOptions1 = React.useMemo(() => {
      if (screen === "detailsUpdateScreen") {
        return procedureDropDownOptions1.filter(
          (val: any) => val.value !== selectedProcedure2 || val.value === 123
        );
      } else if (screen === "inprogressUpdateScreen") {
        return procedureDropDownOptions1.filter(
          (val: any) =>
            val.value !== selectedProcedure2 &&
            val.value !== data.videoData.secondaryProcedureId
        );
      } else {
        return procedureDropDownOptions1.filter(
          (val: any) =>
            val.value !== selectedProcedure2 &&
            val.value !== data.secondary_procedure_type
        );
      }
    }, [selectedProcedure2, screen, data, procedureDropDownOptions1]);

    procedureDropDownOptions2 = React.useMemo(() => {
      if (screen === "detailsUpdateScreen") {
        return procedureDropDownOptions2.filter(
          (val: any) => val.value !== selectedProcedure1 || val.value === 123
        );
      } else if (screen === "inprogressUpdateScreen") {
        return procedureDropDownOptions2.filter(
          (val: any) =>
            val.value !== selectedProcedure1 &&
            val.value !== data.videoData.primaryProcedureId
        );
      } else {
        return procedureDropDownOptions2.filter(
          (val: any) =>
            val.value !== selectedProcedure1 &&
            val.value !== data.primary_procedure_type
        );
      }
    }, [selectedProcedure1, data, screen, procedureDropDownOptions2]);

    const userVal =
      screen === "detailsUpdateScreen"
        ? [{ value: 123, label: "Enter Surgeon Name" }]
        : [];

    let uploadedUser = "";
    const userDataList = userData.results;
    (userDataList || []).forEach((val: any) => {
      userVal.push({ value: val.id, label: val.displayName });
      if (data && data.uploaded_by && data.uploaded_by === val.id)
        uploadedUser = val.displayName;
    });

    const handleFileClick = (event?: any) => {
      hiddenFileInputRef.current.click();
    };
    const [browseFileError, setBrowseFileError]: any = useState(null);

    const browseFileHandler = (evt: any, data: any) => {
      // setBrowseFileError(null);
      // const fileObj = evt.target.files[0];
      // const fileType = fileObj.type;
      // if (fileType.includes("image") || fileType.includes("pdf")) {
      //   handlePatientConsentFormFileChange(evt, data);
      // } else {
      //   setBrowseFileError("File type not supported");
      // }
      //setBrowseFileError(null);
      // errorObject.isUploadConsentFormError='';
      // errorObject.largePdfFileError='';
      // let  fileTypeSupported = ['image/png','image/PNG','application/pdf']
      //     const fileObj = evt.target.files[0];
      //     const fileType = fileObj.type;
      //     const fileSize :any= fileObj.size
      //       ? (fileObj.size / (1024 * 1024)).toFixed(2)
      //       : 0;
      //     if (Math.trunc(fileSize) > 5) {
      //        errorObject.largePdfFileError='Please Upload File less than 5 Mb';
      //     }
      //     if (fileTypeSupported.includes(fileType)) {
      //       handlePatientConsentFormFileChange(evt, data);
      //     } 
      //     else {
      //       errorObject.isUploadConsentFormError='File type not supported';
      //     }
      // return
    };


    return (
      <Stack
        verticalFill
        styles={{ root: { paddingLeft: "2rem", paddingTop: "0.5rem" } }}
        tokens={{ childrenGap: 50 }}
      >
        <Text
          variant="xLarge"
          styles={{
            root: {
              fontFamily: fonts.DINPro_Regular,
              color: ThemeColorPalette.dashboardHeadingTxt,
              opacity: 0.8,
            },
          }}
        >
          Video Details
        </Text>
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack.Item>
              <Stack.Item className="label">
                <Text>Procedure Name*</Text>
              </Stack.Item>
              <Controller
                control={control}

                name="procedureName"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    placeholder={
                      <div className="text-white">Select Procedure Name</div>
                    }
                    defaultValue={
                      data.videoData
                        ? data.videoData.primaryProcedureId
                        : data.primary_procedure_type
                    }
                    classNamePrefix="addl-class"
                    className="procedure_select"
                    options={procedureDropDownOptions1}
                    isDisabled={screen === "processing" ? true : false}
                    value={procedureDropDownOptions1?.find(
                      (c: any) => c.value === data.videoData.primaryProcedureId
                    )}
                    onChange={(val) => {
                      const option = { key: val.value, text: val.label };
                      handleChange({}, data, option, "procedureName");
                      setSelectedProcedure1(option?.key as any);
                    }}
                  />
                )}
              />
            </Stack.Item>

            <Stack.Item>
              <Stack.Item className="label">
                <Text>Primary Surgeon Name*</Text>
              </Stack.Item>
              <Controller
                control={control}
                name="surgeonName"

                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    placeholder={
                      <div className="text-white">Enter Surgeon Name</div>
                    }

                    classNamePrefix="addl-class"
                    // onMenuScrollToBottom={(e) => handleScrollPrimarySurgeons()}
                    className="procedure_select"
                    options={userVal}
                    value={userVal?.find(
                      (c: any) => c.value === data.videoData.primarySurgeonId
                    )}
                    // isDisabled={mapVideoPermission}
                    defaultValue={
                      data.videoData
                        ? data.videoData.primarySurgeonId
                        : data.primary_surgeon
                    }

                    onChange={(val: any) => {
                      const option = { key: val.value, text: val.label };
                      handleChange({}, data, option, "surgeonName");

                    }}
                  />
                )}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack.Item>
              <Stack.Item className="label">
                <Text>Secondary Procedure Name</Text>
              </Stack.Item>
              <Controller
                control={control}

                name="procedureName2"
                render={({ field: { onChange, value, name, ref } }) => (
                  <Select
                    placeholder={
                      <div className="text-white">Select Procedure Name</div>
                    }
                    defaultValue={
                      data.videoData
                        ? data.videoData.secondaryProcedureId
                        : data.secondary_procedure_type
                    }
                    classNamePrefix="addl-class"
                    className="procedure_select"
                    options={procedureDropDownOptions2}
                    // isDisabled={screen === "processing" ? false : true}
                    value={procedureDropDownOptions2?.find(
                      (c: any) =>
                        c.value === data.videoData.secondaryProcedureId
                    )}
                    onChange={(val) => {
                      const option = { key: val.value, text: val.label };
                      handleChange({}, data, option, "procedureName2");
                      setSelectedProcedure2(option?.key as any);
                    }}
                  />
                )}
              />
            </Stack.Item>

            <DatePicker
              strings={DayPickerStrings}
              showWeekNumbers={false}
              firstWeekOfYear={1}
              showMonthPickerAsOverlay={true}
              placeholder="Select A Date"
              label="Date of Procedure"
              styles={{ root: { width: "18.7rem" } }}
              value={
                data.videoData
                  ? data.videoData.surgeryDate
                    ? new Date(data.videoData.surgeryDate)
                    : undefined
                  : data && data.surgery_date
                    ? new Date(data.surgery_date)
                    : undefined
              }
              maxDate={new Date()}
              onSelectDate={(date: any) => {
                handleChange(date.toISOString(), data, "date");
              }}
              formatDate={(date: any) => {
                return (
                  date.getMonth() +
                  1 +
                  "-" +
                  date.getDate() +
                  "-" +
                  date.getFullYear()
                );
              }}
            />
          </Stack>
          <Stack>
            <TextField
              name="notes"
              label="Notes"
              multiline
              resizable={false}
              placeholder="Enter description"
              styles={{ root: { width: "39.5rem" } }}
              defaultValue={data.videoData ? data.videoData.notes : data.notes}
              onChange={(evt: FormEvent) => handleChange(evt, data)}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 30 }}>
            <Stack horizontal>
              <StackItem
                styles={{
                  root: { border: ThemeColorPalette.changePasswordLabelTxt },
                }}
              >
                <TextField
                  label="Patient Consent Form"
                  resizable={false}
                  placeholder="Upload File"
                  contentEditable={false}
                  disabled={true}
                  value={
                    data.patientConsentForm
                      ? data.patientConsentForm.name
                        ? data.patientConsentForm.name
                        : "File not attached"
                      : data.patient_concent_forms
                        ? data.patient_concent_forms
                        : "File not attached"
                  }
                  styles={{
                    root: {
                      border: ThemeColorPalette.changePasswordLabelTxt,
                    },
                  }}
                />
              </StackItem>

              <input
                type="file"
                accept="image/x-png,image/jpeg,application/pdf,image/tiff,.tif"
                ref={hiddenFileInputRef}
                contentEditable={false}
                 onChange={(evt) => handlePatientConsentFormFileChange(evt, data)}
                //onChange={(evt) => browseFileHandler(evt, data)}
                style={{ display: "none" }}
              />
              <PrimaryButton
                styles={{
                  root: {
                    fontSize: "0.7rem",
                    fontWeight: "bold",
                    fontFamily: fonts.HelveticaNeue_Bold,
                    height: "2rem",
                    marginTop: "1.78rem",
                  },
                }}
                value={"file"}
                allowDisabledFocus
                onClick={handleFileClick}
              >
                Browse
              </PrimaryButton>
            </Stack>
            {errorObject && (
            <Label className="errorMessage consenterrorMessage">
              {errorObject?.largePdfFileError ? errorObject?.largePdfFileError : errorObject?.isUploadConsentFormError }
            </Label>
          )}
            <Label className="errorMessage consenterrorMessage">
              {/* {isUploadConsentFormError} */}
              {browseFileError}
            </Label>
            <Stack horizontal tokens={{ childrenGap: 200 }}>
              <Stack tokens={{ childrenGap: 5 }}>
                <Text
                  variant="smallPlus"
                  styles={{
                    root: {
                      color: ThemeColorPalette.changePasswordTxt,
                      fontFamily: fonts.HelveticaNeue_Medium,
                    },
                  }}
                >
                  Uploaded by
                </Text>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      color: ThemeColorPalette.changePasswordLabelTxt,
                      fontFamily: fonts.HelveticaNeue_Medium,
                    },
                  }}
                >
                  {data.videoData || screen === "processing"
                    ? userProfile && userProfile.displayName
                    : data.is_uploaded_by_user
                      ? uploadedUser
                        ? uploadedUser
                        : "N/A"
                      : data.uploaded_by}
                </Text>
              </Stack>
              <Stack tokens={{ childrenGap: 5 }}>
                <Text
                  variant="smallPlus"
                  styles={{
                    root: {
                      color: ThemeColorPalette.changePasswordTxt,
                      fontFamily: fonts.HelveticaNeue_Medium,
                    },
                  }}
                >
                  Uploaded Date
                </Text>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      color: ThemeColorPalette.changePasswordLabelTxt,
                      fontFamily: fonts.HelveticaNeue_Medium,
                    },
                  }}
                >
                  {data.videoData
                    ? currentDate()
                    : data.uploaded_date
                      ? getDateWithSlashInBetween(data.uploaded_date)
                      : "N/A"}
                </Text>
              </Stack>
            </Stack>
          </Stack>


        </Stack>

      </Stack>
    );
  };

export default InprogressVideoEdit;
