import React, { useState, useCallback, useEffect } from "react";
import {
  Stack,
  Text,
  Modal,
  DefaultButton,
  IconButton,
  IIconProps,
  StackItem,
} from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import "./ModelFullView.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Paging from "../../../../../common/paging/Paging";
import { getModelById } from "../../../actions/managementAction";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const ModelFullView = ({
  algId,
  id,
  version,
  name,
  alg,
  model,
}: {
  algId: any;
  id: any;
  version: any;
  name: any;
  alg: any;
  model: any;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { state }: { state: any } = useLocation();

  const modelById = useSelector((state: AppState) => {
    const data = state.developerManagementReducerData?.modelById;
    if (data?.results) {
      data?.results.sort((a: any, b: any) => {
        const bData: any = new Date(b.modified_date);
        const aData: any = new Date(a.modified_date);
        return bData - aData;
      });
    }
    return data;
  });
  const alertClicked = () => {
    navigate(`/management/model`, { state: { page: state.page } });
  };

  const onPageChange = useCallback((pageNo) => {
    setCurrentPage(pageNo);
    dispatch(getModelById(algId, id, version, pageNo));
  }, []);

  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={true}
      onDismiss={alertClicked}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack className="deviceManagement pos-rel" dir="ltr">
        <Stack
          className="createHospitalHeading"
          dir="ltr"
          horizontal
          horizontalAlign="space-between"
        >
          <Stack verticalAlign="space-between">
            <Text className="fs-small">Model Name</Text>
            <Text className="fs-medium">
              {name} : {version}
            </Text>
          </Stack>
          <Stack verticalAlign="space-between">
            <Text className="fs-small">Algorithm Mapped to</Text>
            <Text className="fs-medium">{alg}</Text>
          </Stack>

          <Stack.Item align="end">
            <IconButton
              style={{
                position: "absolute",
                top: "20px",
                right: "-40px",
                color: "white",
              }}
              iconProps={cancelIcon}
              onClick={alertClicked}
            />
          </Stack.Item>
        </Stack>

        <Stack className="availableDeviceList border-none">
          <Stack.Item className="deviceSubHeading">
            <Text variant="xLarge">Ratings against Data set</Text>
          </Stack.Item>
          <Stack.Item className="ms-Grid" dir="ltr">
            <Stack.Item className="ms-Grid-row tableHeading">
              <Stack.Item className=" ms-Grid-col ms-lg3">
                <Text>Comment By</Text>
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg3">
                <Text>Data set Name</Text>
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg2">
                <Text variant="smallPlus">Rating(avg.)</Text>
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg3">
                <Text variant="smallPlus">Comments</Text>
              </Stack.Item>
            </Stack.Item>
            {modelById?.results?.map((mdl: any, index: any) => (
              <Stack.Item key={index} className="ms-Grid-row tableText">
                <Stack.Item className="ms-Grid-col ms-lg3">
                  <Text variant="smallPlus">{mdl?.user_name}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3">
                  <Text variant="smallPlus">{mdl?.dataset}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">{mdl?.rating}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3">
                  <Text variant="smallPlus">{mdl?.comment}</Text>
                </Stack.Item>
              </Stack.Item>
            ))}
          </Stack.Item>
          {modelById?.count > 10 && modelById?.page_size ? (
            <Paging
              currentPage={currentPage}
              totalPageCount={Math.ceil(modelById?.count / 10)}
              handlePageChange={onPageChange}
            />
          ) : null}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ModelFullView;
