import * as React from "react";
import { useSelector } from "react-redux";
import {
  Route,
  useLocation,
  BrowserRouter as Router,
  Navigate,
  Routes,
} from "react-router-dom";
import AuthContainerComponent from "./auth/containers/authContainer/authContainer";
import ResetPassword from "./auth/containers/reset-password/ResetPassword";
import Layout from "./Layout/components/Layout";

interface RoutesProps {}
const AppRoutes: React.FunctionComponent<RoutesProps> = () => {
  const { isLoggedIn } = useSelector((state: any) => state.authReducerData);

  return (
    <Router>
      <Routes>
        <Route
          path="/reset-password"
          element={!isLoggedIn ? <ResetPassword /> : <Navigate to="/#" />}
        />
        <Route
          path="/login"
          element={
            !isLoggedIn ? <AuthContainerComponent /> : <Navigate to="/#" />
          }
        />
        {/* <Route path="/" element={ !auth ? null: <Navigate to="/login" />}> */}
        <Route
          path="/*"
          element={isLoggedIn ? <Layout /> : <Navigate to="/login" />}
        />
        {/* </Route> */}
        <Route path="*" element={<NoMatch />} />
        {/* <Route path="" element={<Navigate to="/" />} /> */}
      </Routes>
    </Router>
  );
};

export function NoMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

// interface IProps {
//   element: React.ElementType;
//   path: string;
// }

// function PrivateRoute() {
//   const auth = localStorage.getItem("token");
//   return auth ? <Navigate to="/app" />: <Navigate to="/login" />;
// }

// function RedirectRoute({ children }: { children: JSX.Element }) {
//   const auth = localStorage.getItem("token");
//   return auth ? <Navigate to="/app" /> : children
// }

// export const RedirectRoute = ({...rest }: IProps) => {
//   if (!localStorage.getItem("token"))
//     return <Route {...rest}  />;
//   else return <Navigate to="/" />;
// };

export default AppRoutes;
