export const GET_SALES_LOGS = "GET_SALES_LOGS";
export const GET_VIEW_LOGS = "GET_VIEW_LOGS";
export const GET_VIEW_LOG_BY_ID = "GET_VIEW_LOG_BY_ID";
export const GET_DATA_FROM_BLOB_URL = "GET_DATA_FROM_BLOB_URL";
export const GET_LOG_HOSPITALS = "GET_LOG_HOSPITALS";
export const REFRESH_LOG_HOSPITALS = "REFRESH_LOG_HOSPITALS";
export const GET_LOG_FACILITIES = "GET_LOG_FACILITIES";
export const GET_LOG_ROOMS = "GET_LOG_ROOMS";
export const GET_SERVICE_LOGS = "GET_SERVICE_LOGS";
export const GET_SPACILITY = "GET_SPACILITY";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITY_TYPES = "GET_ACTIVITY_TYPES";
export const REFRESH_ACTIVITY_TYPES = "REFRESH_ACTIVITY_TYPES";

export const FILTERS_SELECTION = "FILTERS_SELECTION";
export const LOADER = "LOADER";
export const STOPLOADER = "STOPLOADER";

export const TEMPLATE_DOWNLOAD = "TEMPLATE_DOWNLOAD";
export const DOWNLOAD_ACTIVITY = "DOWNLOAD_ACTIVITY";
export const RESET_ACTIVITY = "RESET_ACTIVITY";
