import React, { useCallback } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../Wizard";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import { useState, useEffect } from "react";
import DatasetCreation from "./DatasetCreation";
import { createDataset } from "../../actions/datasetAction";
import "../../../../HospitalAdminModule/Home/components/CreateSurgeon/CreateSurgeon.scss";
import { values } from "idb-keyval";
import { uuid } from "uuidv4";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import * as allActions from "../../actions/actionConstants";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const CreateDataSet = ({
  isModalOpen,
  hideModal,
}: {
  isModalOpen: any;
  hideModal: any;
}) => {
  const dispatch = useDispatch();

  // const spinner = useSelector((state: AppState) => {
  //   return state.managementReducerData.showRoleSpinner;
  // });

  useEffect(() => {
    // dispatch(showRoleSpinner());
  }, []);

  const [errors, setErrors] = useState<{
    video_name?: string;
    dataset_name?: string;
    algorithms?: any;
  }>({});
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [data, setData] = useState<{
    video_name: string;
    dataset_name: string;
    algorithms: any;
    file: any;
  }>({
    video_name: "",
    dataset_name: "",
    algorithms: [],
    file: {},
  });

  useEffect(() => {
    setData({
      video_name: "",
      dataset_name: "",
      algorithms: [],
      file: {},
    });
    setErrors({});
  }, [isModalOpen]);

  const onCreate = useCallback(async () => {
    setDisabledCreate(true);
    const { video_name, dataset_name, algorithms } = data;
    // const {datasetFile} = values
    const errors: any = {};
    if (video_name.trim().length === 0) {
      errors.video_name = "DataSet should not be empty.";
    }
    if (algorithms.length === 0) {
      errors.algorithms = "Algorithm Name should not be empty.";
    }

    if (dataset_name.trim().length === 0) {
      errors.dataset_name = "Dataset Name should not be empty.";
    }
    if (Object.keys(errors).length === 0) {
      const dataWithId = {
        id: uuid(),
        ...data,
      };
      const response: any = await dispatch(createDataset(dataWithId));
      if (response && (response.status === 200 || response.status === 201)) {
      } else {
        return false;
      }

      // hideModal();
    }
    setErrors(errors);
  }, [data, values]);

  const createDataSetError = useSelector((state: AppState) => {
    return state.developerDataSetReducerData.createDataSetError;
  });

  // const spinnerFunc = (dummySpinner = false) => {
  //   if (dummySpinner) {
  //     setDisabledCreate(true);
  //   }
  //   return dummySpinner;
  // };

  useEffect(() => {
    setDisabledCreate(false);
    // if (!!createDataSetError) {
    //   const newErrors: any = {};
    //   Object.keys(createDataSetError)?.forEach((key: string) => {
    //     newErrors[key] = createDataSetError[key][0];
    //   });
    //   if (
    //     Object.keys(newErrors).every(
    //       (key: any) => newErrors[key] !== (errors as any)[key]
    //     )
    //   ) {
    //     setErrors({ ...errors, ...newErrors });
    //   }
    // }

    if (createDataSetError) {
      const datSetErr = { dataset_name: createDataSetError };
      setErrors({ ...errors, ...datSetErr });
      dispatch({
        type: allActions.CLEAR_CREATE_DATASET_ERROR,
      });
    }
  }, [createDataSetError, errors]);

  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted">
            Dataset Mapping
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">Add New Test Dataset</Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={hideModal} />
        </Stack.Item>
      </Stack>

      <Wizard
        // spinner={spinner}
        disabledCreate={disabledCreate}
        onCreate={onCreate}
        onCancel={hideModal}
      >
        <DatasetCreation
          updateData={setData}
          // spinnerFunc={spinnerFunc}
          data={data}
          errors={errors}
        />
      </Wizard>
    </Modal>
  );
};

export default CreateDataSet;
