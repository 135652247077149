import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  DefaultButton,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  createSpecialty,
  showRoleSpinner,
  updateSpaceialtyActionData,
  closeErrorRsponse,
} from "../../actions/managamentActions";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import { AppState } from "../../../../rootReducer";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndPoint, doHttpCall } from "../../../../common/restApi";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { async } from "q";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const SpacialtyGroup = ({
  isModalOpen,
  showModal,
  closeModal,
}: {
  isModalOpen: any;
  showModal: any;
  closeModal: any;
}) => {
  const specialties = useSelector((state: AppState) => {
    return state.managementReducerData.specialtyList.data?.results;
  });
  const [specialtiesOnChange, setspecialtiesOnChange] = useState("");
  const [disabledCreate, setDisabledCreate] = useState(false);

  const isSpecialtyNameChanged =
    specialtiesOnChange === "" ? specialties?.name : specialtiesOnChange;
  const isSpecialtyChanged = specialties?.name !== isSpecialtyNameChanged;

  const validationSchema = Yup.object().shape({
    specialty: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Specialty is required"),
        // .test({
        //   message: "This Specialty is already taken",
        //   test: async (value) => {
        //     if (specialtiesOnChange !== "" && isSpecialtyChanged) {
        //       const checkFieldName = `/hospitals/activ-speciality?search=${value}`;
        //       const payload = {
        //         method: "GET",
        //         url: `${apiEndPoint}${checkFieldName}`,
        //       };
        //       const response = await doHttpCall(payload);
        //       console.log(response, "response");
        //       const returnStatus =
        //         response.data.results.length > 0 ? false : true;
        //       return returnStatus;
        //     } else {
        //       return true;
        //     }
        //   },
        // }),
        comments: Yup.string(),
      })
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm(formOptions);
  const dispatch = useDispatch();

  const _alertClicked = useCallback(() => {
    const values = getValues();
    values.specialty.splice(0, 1);
    closeModal();
  }, []);

  // const _alertClicked1 = useCallback(() => {
  //   const values = getValues();
  //   values.specialty.splice(0, 1);
  //   closeModal();
  // }, []);

  const specialtySpinner = useSelector((state: AppState) => {
    return state.managementReducerData.specialtySpinner;
  });
  const errorSpecialty = useSelector((state: AppState) => {
    return state.managementReducerData.specialityError;
  });

  const [nameChange, setnameChange] = useState("");
  useEffect(() => {
    errorSpecialty && setnameChange(errorSpecialty);
  }, [errorSpecialty]);

  useEffect(() => {
    if (errors && Object.keys(errors)?.length) {
      setnameChange(errors?.specialty[0]?.name?.message);
    }
  }, [errors]);

  const onSubmit = async (data: any, e: any) => {
    setDisabledCreate(true);
    const res: any = await dispatch(createSpecialty(data?.specialty[0]));
    if (res && (res.status === 200 || res.status === 201)) {
      setDisabledCreate(false);
    } else {
      setDisabledCreate(false);
      setTimeout(() => {
        dispatch(closeErrorRsponse());
      }, 2000);
    }
  };
  return (
    <Modal
      className="deleteModal spacilityModal"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={() => {
        _alertClicked();
      }}
      containerClassName={"smallModalStyle"}
      isBlocking={false}
    >
      <Stack className="deleteSection">
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack.Item className="heading">
            <Text variant="xLarge">Add Specialty Group</Text>
          </Stack.Item>
          <Stack.Item align="end">
            <IconButton
              className=""
              iconProps={cancelIcon}
              onClick={() => {
                _alertClicked();
              }}
            />
          </Stack.Item>
        </Stack>
        <form id="procedure" onSubmit={handleSubmit(onSubmit)}>
          <Stack.Item className="spacialtyForm">
            <Stack.Item className="ms-Grid-row">
              <Stack.Item className="ms-Grid-col" dir="ltr">
                <TextField
                  autoComplete="off"
                  label="Name of the Specialty*"
                  errorMessage={nameChange ? nameChange : ""}
                  {...register(`specialty[0].name`)}
                  onChange={(e: any) => {
                    setspecialtiesOnChange(e?.target?.value);
                    nameChange ? setnameChange("") : setnameChange(nameChange);
                    errors.specialty = {};
                  }}
                  onKeyDown={(e: any) => {
                    const values = getValues();
                    values.specialty[0].name = e?.target?.value;
                  }}
                  defaultValue=""
                />
                <TextField
                  autoComplete="off"
                  label="Comments"
                  errorMessage={
                    errors?.specialty
                      ? errors?.specialty[0]?.comments?.message
                      : ""
                  }
                  {...register(`specialty[0].comments`)}
                  // onChange={(e) => handleValidateForm(e, "name")}
                  defaultValue=""
                />
              </Stack.Item>
              <Stack.Item className="ms-Grid-col footerSection">
                <PrimaryButton
                  disabled={disabledCreate}
                  type="submit"
                  text="Add"
                  className="btnDelete"
                  // onClick={deleteHandle}
                  allowDisabledFocus
                >
                  <Text className="spinnerRight">
                    {disabledCreate && <Spinner size={SpinnerSize.small} />}
                  </Text>
                </PrimaryButton>
                <DefaultButton
                  text="Cancel"
                  className="btnCancel"
                  onClick={() => {
                    _alertClicked();
                  }}
                  allowDisabledFocus
                />
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </form>
        <Modal isOpen={specialtySpinner} containerClassName={"modalStyle"}>
          <SuccessMessage title="Specialty Creation" />
        </Modal>
        {specialtySpinner &&
          setTimeout(() => {
            _alertClicked();
            // showModal();
            // showModal();
            // _alertClicked1();
            dispatch(showRoleSpinner());
          }, 1000) && <></>}
      </Stack>
    </Modal>
  );
};
export default SpacialtyGroup;
