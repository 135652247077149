export const GET_HOSPITALS = "GET_HOSPITALS";
export const GET_HOSPITALS_COUNT = "GET_HOSPITALS_COUNT";
export const CREATE_HOSPITALS = "CREATE_HOSPITALS";
export const HOSPITALS_DATA = "HOSPITALS_DATA";
export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const GET_DEVICES = "GET_DEVICES";
export const GET_ALLOCATION_TYPE = "GET_ALLOCATION_TYPE";
export const GET_HOSPITAL_VALIDATIONS = "GET_HOSPITAL_VALIDATIONS";
export const SPINNER_STOP = "SPINNER_STOP";
export const RESET_COUNT = "RESET_COUNT";
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_USERS = "GET_USERS";
export const CREATE_USERS = "CREATE_USERS";
export const GET_HOSPITALS_WITH_COUNT = "GET_HOSPITALS_WITH_COUNT";
export const REFRESH_HOSPITALS_WITH_COUNT = "REFRESH_HOSPITALS_WITH_COUNT";
export const VALIDATE_FORM = "VALIDATE_FORM";
export const VALIDATE_FORM_GET = "VALIDATE_FORM_GET";
export const GET_HOSPITAL_BY_ID = "GET_HOSPITAL_BY_ID";
export const CLOSE_HOSPITAL_MODAL = "CLOSE_HOSPITAL_MODAL";
export const ERROR_IN_CREATE_HOSPITALS = "ERROR_IN_CREATE_HOSPITALS";
export const CLOSE_DELETE_HOSPITAL = "CLOSE_DELETE_HOSPITAL";
export const SHOW_CREATE_HOSPITAL = "SHOW_CREATE_HOSPITAL";
export const CLOSE_ERRORS = "CLOSE_ERRORS";
export const EDIT_HOSPITALS = "EDIT_HOSPITALS";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const SHOW_CREATE_USER = "SHOW_CREATE_USER";
export const STOP_CREATE_USER = "STOP_CREATE_USER";

export const EDIT_USERS = "EDIT_USERS";
export const CLOSE_USER_MODAL = "CLOSE_USER_MODAL";
export const CREATE_USERS_WITH_TYPES = "CREATE_USERS_WITH_TYPES";

export const CREATE_VIDEO_PROCESSING = "CREATE_VIDEO_PROCESSING";
export const CREATE_LOG_PROCESSING = "CREATE_LOG_PROCESSING";
export const GET_LOCATION_WITH_ZIPCODE = "GET_LOCATION_WITH_ZIPCODE";
export const DELETE_USER = "DELETE_USER";
export const CLEAR_HOSPITAL_DATA = "CLEAR_HOSPITAL_DATA";
export const ERROR_LOCATION_WITH_ZIPCODE = "ERROR_LOCATION_WITH_ZIPCODE";
export const LOADER = "LOADER";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const ERROR_IN_CREATE_USER = "ERROR_IN_CREATE_USER";
export const DELETE_HOSPIATL = "DELETE_HOSPIATL";
export const STOPLOADER = "STOPLOADER";
export const HOSPITALS_WITH_COUNT_LOADER_SHOW =
  "HOSPITALS_WITH_COUNT_LOADER_SHOW";
export const HOSPITALS_WITH_COUNT_LOADER_HIDE =
  "HOSPITALS_WITH_COUNT_LOADER_HIDE";
export const ERROR_USR = "ERROR_USR";
