import { Stack } from "office-ui-fabric-react";
import ReactPaginate from "react-paginate";
import React from "react";
import "./Paging.scss";

function Paging({ totalPageCount, handlePageChange, currentPage }: any) {
  return (
    <Stack horizontal horizontalAlign="center">
      <ReactPaginate
        forcePage={currentPage - 1}
        pageCount={totalPageCount}
        initialPage={currentPage - 1}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={"< Previous"}
        nextLabel={"Next >"}
        nextClassName={"nextClassName"}
        nextLinkClassName={
          currentPage === totalPageCount
            ? "nextLinkClassNameDisable"
            : "nextLinkClassName"
        }
        previousClassName={"previousClassName"}
        previousLinkClassName={
          currentPage === 1
            ? "previousLinkClassNameDisable"
            : "previousLinkClassName"
        }
        breakClassName={"breakClassName"}
        breakLinkClassName={"breakLinkClassName"}
        pageClassName={"pageClassName"}
        pageLinkClassName={"pageLinkClassName"}
        disableInitialCallback={true}
        activeClassName={"activeClassName"}
        onPageChange={(selectedItem: any) => {
          handlePageChange(selectedItem.selected + 1);
        }}
      />
    </Stack>
  );
}

export default Paging;
