import {
  Stack,
  StackItem,
  Image,
  Nav,
  IStackItemStyles,
  IStackProps,
  INavStyles,
  INavLinkGroup,
  INavLink,
} from "@fluentui/react";
import * as React from "react";
import activEdgeLogo from "../../../assets/activeEdge_logo.svg";
import { ThemeColorPalette } from "../../../theme";
import "./Navbar.scss";
import { connect } from "react-redux";
import {
  getProcedureDetails,
  getUserDetails,
  getUserProfile,
} from "../../actions/layoutActions";
import { clearProceduresDataWhenUnmount } from "../../../MyProcedures/actions/proceduresAction";
import { clearFavoritesVideoData } from "../../../Favorites/actions/favouritesAction";
import { clearSharedVideoData } from "../../../Shared/actions/sharedVideosAction";
import { withRouter } from "../../../withRouter";

const containerStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.sideNavBg,
    color: ThemeColorPalette.pureWhite,
  },
};

const leftColumnProps: Partial<IStackProps> = {
  tokens: { padding: "30", maxHeight: "" },
};

const navStyles: Partial<INavStyles> = {
  root: {
    boxSizing: "border-box",
    color: ThemeColorPalette.pureWhite,
  },
};

export interface NavbarProps {
  history: any;
  location: any;
  getUserDetails: any;
  getProcedureDetails: any;
  getUserProfile: any;
  clearSharedVideoData: any;
  clearFavoritesVideoData: any;
  clearProceduresDataWhenUnmount: any;
}

export interface NavbarPassedProps {
  navOptions: any[];
  navLinkGroups: INavLinkGroup[];
}

export interface NavbarState {
  selectedNavLink: string;
}

class Navbar extends React.Component<
  NavbarProps & NavbarPassedProps,
  NavbarState
> {
  constructor(props: NavbarProps & NavbarPassedProps) {
    super(props);
    this.state = {
      selectedNavLink: "",
    };
  }

  setNavLink() {
    const role = localStorage.getItem("role");
    const currentPagePathArray = this.props.location.pathname.split("/");
    const currentPagePath = currentPagePathArray[1]
      ? currentPagePathArray[1]
      : role === "Surgeon"
      ? "myProcedures"
      : "home";
    this.setState({
      ...this.state,
      selectedNavLink: currentPagePath,
    });
  }

  componentDidMount() {
    this.props.getUserDetails();
    this.props.getProcedureDetails();
    // this.props.getUserProfile();
    this.setNavLink();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.selectedNavLink !== this.state.selectedNavLink ||
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.props.clearFavoritesVideoData();
      this.props.clearProceduresDataWhenUnmount();
      this.props.clearSharedVideoData();
      this.setNavLink();
    }
  }

  handleSidebarClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ): any => {
    ev?.preventDefault();
    if (item && item.key) {
      // console.log(item.key, "item.key");
      this.setState({ selectedNavLink: item.key });
      localStorage.setItem("sideBar", "true");
      localStorage.removeItem("currentVideoPage");
      setTimeout(() => {
        localStorage.setItem("sideBar", "false");
      }, 2000);
      this.props.history(item.url);
    }
  };

  onRenderLink = (props?: any): JSX.Element | null => {
    return (
      <div>
        {this.props.navOptions.length
          ? this.props.navOptions.map((navItem: any, index) => {
              if (props.key === navItem.key)
                return (
                  <div key={index} className="nav-item">
                    <img src={navItem.imgsrc} alt="nav-items"></img>
                    <p className="nav-text">{navItem.text}</p>
                  </div>
                );
              else return null;
            })
          : null}
      </div>
    );
  };

  render() {
    const { selectedNavLink } = this.state;
    return (
      <Stack verticalFill styles={containerStyles}>
        <StackItem align="center">
          <Image src={activEdgeLogo} width={"4rem"} height={"3.25rem"} />
        </StackItem>
        <StackItem {...leftColumnProps}>
          <Nav
            onLinkClick={this.handleSidebarClick}
            selectedKey={selectedNavLink}
            styles={navStyles}
            className="nav-cls"
            groups={this.props.navLinkGroups}
            onRenderLink={this.onRenderLink}
          />
        </StackItem>
      </Stack>
    );
  }
}

const dispatchActionToProps = (dispatch: any) => {
  return {
    getUserDetails: () => dispatch(getUserDetails()),
    getProcedureDetails: () => dispatch(getProcedureDetails()),
    getUserProfile: () => dispatch(getUserProfile()),
    clearSharedVideoData: () => dispatch(clearSharedVideoData()),
    clearFavoritesVideoData: () => dispatch(clearFavoritesVideoData()),
    clearProceduresDataWhenUnmount: () =>
      dispatch(clearProceduresDataWhenUnmount()),
  };
};

export default withRouter(connect(null, dispatchActionToProps)(Navbar));
