import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  Icon,
  CommandBarButton,
  SearchBox,
  ActionButton,
  Toggle,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../rootReducer";
import Paging from "../../../common/paging/Paging";
import { debounce } from "lodash";
import {
  getActivity,
  getActivityType,
  getActivityBy,
  resetActivity,
} from "../actions/activityAction";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import DownloadReport from "../../../ActiveAdminModule/Activity/containers/DownloadReport";
import DatePicker from "react-datepicker";
import CheckPermission from "../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../Layout/constants/constants";
import LoaderWithMessage from "../../../common/customLoader/LoaderWithMessage";

const DeveloperActivity = () => {
  const { register, handleSubmit, control } = useForm();
  const dispatch = useDispatch();
  const [currentLogsPage, setCurrentLogsPage] = useState<number>(1);
  const [hospitalFilter, setHospitalFilter] = useState<any | null>(null);
  const [activityType, setActivityType] = useState<any | null>(null);
  const [activityBy, setActivityBy] = useState<any | null>(null);
  const [sortOptions, setSortOptions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [startDatePick, setStartDatePick] = useState<any>();
  const [endDatePick, setEndDatePick] = useState<any>();
  const [messageBar, setMessageBar] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [dateValue, setdateValue] = useState<any>();
  const [dateString, setDateString] = useState<string>();
  const innerRef = useRef<any>();
  const toggleShowError = () => {
    setShowError(!showError);
    setStartDatePick(null);
    setEndDatePick(null);
  };

  const activityTypes = useSelector(
    (state: AppState) => state.developerActivitiesReducerData?.activityTypes
  );

  const activitiesBy = useSelector((state: AppState) => {
    return state.developerActivitiesReducerData?.activitiesBy?.data;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.developerActivitiesReducerData?.loaderWithMessage;
  });

  const allSurgeons = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.allSurgeons;
  });

  const activityTypesCount = activityTypes?.count;
  const activitiesByCount = activitiesBy?.count;

  const activityTypeFilter = activityTypes?.results?.map(
    (activityType: any) => ({
      label: activityType.activity_log_type,
      value: activityType.id,
    })
  );

  const uniqueOptions = Array.from(
    new Set(activityTypeFilter?.map((eachActivity: any) => eachActivity.value))
  ).map((value) => {
    return activityTypeFilter?.find(
      (eachActivity: any) => eachActivity.value === value
    );
  });
  const activityByFilter = activitiesBy?.results?.map((surgeon: any) => ({
    label: surgeon.displayName,
    value: surgeon.userId,
  }));

  const uniqueActivityByOptions = Array.from(
    new Set(
      activityByFilter?.map((eachActivityBy: any) => eachActivityBy.value)
    )
  ).map((value) => {
    return activityByFilter?.find(
      (eachActivityBy: any) => eachActivityBy.value === value
    );
  });

  const activities = useSelector((state: AppState) => {
    return state.logsReducerData.activities.data;
  });

  const onViewLogsPageChange = (pageNo: number) => {
    const dt = dateValue
      ? moment.utc(dateValue).format("YYYY-MM-DDTHH:mm:ss")
      : "";
    setCurrentLogsPage(pageNo);
    dispatch(getActivity(pageNo, activityType, activityBy, dt));
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu

      if (
        sortOptions &&
        innerRef?.current &&
        !innerRef?.current?.contains(e?.target)
      ) {
        setSortOptions(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      // debugger;
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [sortOptions]);
  useEffect(() => {
    dispatch(getActivity());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetActivity());
    };
  }, []);

  useEffect(() => {
    dispatch(getActivityType(activityTypesCount));
    dispatch(getActivityBy(activitiesByCount));
  }, [activityTypesCount, activitiesByCount]);

  const handleSortOptions = () => {
    setSortOptions(!sortOptions);
    setShowError(false);
  };
  const getMessageBar = (value: any) => {
    setMessageBar(value);
    setSortOptions(!sortOptions);
  };
  const clearDatePickValues = () => {
    setStartDatePick("");
    setEndDatePick("");
    setSortOptions(!sortOptions);
  };
  // const handleScrollActivityType = (evt: any) => {
  //   if (activityTypes.next) {
  //     dispatch(getActivityType(activityTypes.next));
  //   }
  // };
  // const handleScrollActivityBy = (evt: any) => {
  //   if (activitiesBy.next) {
  //     dispatch(getActivityBy(activitiesBy.next));
  //   }
  // };
  const activityTypeHandler = useCallback(
    debounce((evt) => {
      dispatch(getActivityType("", evt));
    }, 2000),
    []
  );
  const handleKeyDownActivityType = (evt: any) => {
    const value = evt?.target.value;
    if (evt?.target.value.length > 2 || evt?.target.value === 0) {
      activityTypeHandler(value);
    }
  };

  const activityByHandler = useCallback(
    debounce((evt) => {
      dispatch(getActivityBy("", evt));
    }, 2000),
    []
  );
  const handleKeyDownActivityBy = (evt: any) => {
    const value = evt?.target?.value;
    if (evt?.target.value.length > 2 || evt?.target.value === 0) {
      activityByHandler(value);
    }
  };
  const handleKeyDownActivityBy1 = (evt: any) => {
    activityByHandler("");
  };

  const searchChangeHandler = useCallback(
    debounce((evt, actType, actBy) => {
      dispatch(getActivity(1, actType, actBy, evt));
    }, 2000),
    []
  );

  const handleActivitySearch = (evt: any) => {
    const serarchText = evt?.target?.value;
    setSearchText(serarchText);
    searchChangeHandler(serarchText, activityType, activityBy);
  };
  const handleDateChange = (date: any) => {
    const dt = date ? moment.utc(date).format("YYYY-MM-DDTHH:mm:ss") : "";
    setdateValue(date);
    setDateString(dt);
    setCurrentLogsPage(1);
    dispatch(getActivity(1, activityType, activityBy, dt));
  };
  return (
    <Stack className="viewLogFiles deviceManagement activitySectionMain">
      {" "}
      {messageBar && (
        <MessageBar
          delayedRender={false}
          isMultiline={false}
          messageBarType={MessageBarType.success}
          dismissButtonAriaLabel="Close"
          onDismiss={() => setMessageBar(false)}
          className="errorMessages"
        >
          You will get a mail shortly
        </MessageBar>
      )}
      {messageBar &&
        setTimeout(() => {
          setMessageBar(false);
        }, 5000) && <></>}
      <Stack.Item className="totalSection  ms-Grid" dir="ltr">
        <Stack.Item className="chartSection ms-Grid-row">
          {/* ---------------map section------------------- */}
          <Stack.Item className=" ms-Grid-col ms-lg2">
            <Stack.Item className="label">
              <Text>Activity Type</Text>
            </Stack.Item>
            <Controller
              control={control}
              name="algorithmVersion[0].algorithm_id"
              shouldUnregister
              render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                  <Select
                    classNamePrefix="addl-class"
                    isClearable
                    options={uniqueOptions}
                    // onMenuScrollToBottom={(e) => handleScrollActivityType(e)}
                    onKeyDown={(e) => handleKeyDownActivityType(e)}
                    value={activityTypeFilter?.find(
                      (c: any) => c.value === value
                    )}
                    // loadOptions={}

                    onChange={(val) => {
                      onChange(val?.value);
                      setActivityType(val?.label);
                      setCurrentLogsPage(1);
                      dispatch(
                        getActivity(1, val?.label, activityBy, dateString)
                      );
                      // setHideDropdownValue(true);
                    }}
                  />
                );
              }}
            />
          </Stack.Item>

          <Stack.Item className=" ms-Grid-col ms-lg2">
            <Stack.Item className="label">
              <Text>Activity By</Text>
            </Stack.Item>
            <Controller
              control={control}
              name="activityBy"
              shouldUnregister
              render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                  <Select
                    classNamePrefix="addl-class"
                    isClearable
                    options={uniqueActivityByOptions}
                    value={activityByFilter?.find(
                      (c: any) => c.value === value
                    )}
                    // onMenuScrollToBottom={(e) => handleScrollActivityBy(e)}
                    onKeyDown={(e) => handleKeyDownActivityBy(e)}
                    onBlur={(e) => handleKeyDownActivityBy1(e)}
                    onChange={(val) => {
                      onChange(val?.value);
                      setActivityBy(val?.value);
                      setCurrentLogsPage(1);
                      dispatch(
                        getActivity(1, activityType, val?.value, dateString)
                      );
                      // setHideDropdownValue(true);
                    }}
                  />
                );
              }}
            />
          </Stack.Item>

          <Stack.Item className=" ms-Grid-col">
            <Stack.Item className="activitydatelabel">
              <Text>From Date</Text>
            </Stack.Item>
            <DatePicker
              selected={dateValue}
              onChange={handleDateChange}
              selectsStart
              isClearable={true}
              maxDate={new Date()}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col displayFlex float-right downloadBtn searchBoxDownloadSection">
            <Stack.Item className="btnDropdownWidth">
              <div ref={innerRef}>
                <CheckPermission {...SCOPES.DOWNLOAD_ACTIVITIES}>
                  <Text onClick={handleSortOptions}>
                    <Icon
                      iconName="download"
                      className=" ms-fontSize-16 ms-fontWeight-bold"
                    />
                    Download Report
                  </Text>
                </CheckPermission>
                {sortOptions ? (
                  <Stack className="dropdownDownload btnDropdownWidth">
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"lw"}
                        name={"Last Week"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"1m"}
                        name={"Last 1 Month"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"3m"}
                        name={"Last 3 Months"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Stack.Item className="sortOptionsDropdownHeading">
                      <DownloadReport
                        type={"6m"}
                        name={"Last 6 Months"}
                        getMessageBar={getMessageBar}
                      />
                    </Stack.Item>
                    <Toggle
                      label=""
                      onText="Custom"
                      offText="Custom"
                      checked={showError}
                      onChange={toggleShowError}
                    />
                    <Stack.Item key={startDatePick}>
                      {showError && (
                        <>
                          <DatePicker
                            onChange={(date: any) => setStartDatePick(date)}
                            selected={startDatePick}
                            isClearable
                            placeholderText="Start Date"
                            className="ms-mb-10"
                            maxDate={
                              endDatePick ? endDatePick : moment().toDate()
                            }
                          />
                          <DatePicker
                            selected={endDatePick}
                            onChange={(date: any) => setEndDatePick(date)}
                            isClearable
                            placeholderText="End Date"
                            maxDate={moment().toDate()}
                            minDate={startDatePick}
                          />

                          <ActionButton allowDisabledFocus>
                            <DownloadReport
                              type={"custom"}
                              startDate={startDatePick}
                              endDate={endDatePick}
                              clearDatePickValues={clearDatePickValues}
                              name={"Submit"}
                              getMessageBar={getMessageBar}
                            />
                          </ActionButton>
                        </>
                      )}
                    </Stack.Item>
                  </Stack>
                ) : (
                  ""
                )}
              </div>
            </Stack.Item>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
      <Stack>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg5">
              <Text>Activity</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg5">
              <Text variant="smallPlus">Activity by</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg2">
              <Text variant="smallPlus">Activity Time</Text>
            </Stack.Item>
          </Stack.Item>
          {loaderWithMessage && loaderWithMessage.loadingState ? (
            <LoaderWithMessage
              loaderWithMessage={loaderWithMessage}
            ></LoaderWithMessage>
          ) : (
            ""
          )}
          {activities?.results?.map((activity: any, index: any) => (
            <Stack.Item key={index} className="ms-Grid-row tableText">
              <Stack.Item></Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg5">
                {/* <Checkbox onChange={_onChange} /> */}
                <Text variant="smallPlus">{activity?.log_type}</Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg5">
                <Text variant="smallPlus">{activity?.activity_by}</Text>
              </Stack.Item>

              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text variant="smallPlus">
                  {/* {moment(activity?.date).format("MM/DD/YY HH:mm")} */}
                  {moment.utc(activity?.date).local().format("MM/DD/YY HH:mm")}
                </Text>
              </Stack.Item>
            </Stack.Item>
          ))}
          {activities?.count > 20 && activities?.page_size ? (
            <Paging
              currentPage={currentLogsPage}
              totalPageCount={Math.ceil(
                activities?.count / activities?.page_size
              )}
              handlePageChange={onViewLogsPageChange}
            />
          ) : null}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default DeveloperActivity;
