import React, { useCallback } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../../Wizard";
import AssignPermissions from "./AssignPermissions";
import PrivilegeCreation from "./PrivilegeCreation";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import { roleDecrement } from "../../../../../ActiveAdminModule/Management/actions/managamentActions";
import { useState, useEffect } from "react";
import "./CreatePrivilege.scss";
import {
  clearPrivilageError,
  updateRolesPermissionActionData,
  updateIsPermissionsFetchedForCreatePrivilege,
  updateIsPermissionsFetchedForEditPrivilege,
} from "../../../actions/managementAction";
const cancelIcon: IIconProps = { iconName: "Cancel" };

const CreatePrivilege = ({
  isModalOpen,
  isEdit = localStorage.getItem("previledgeisId") || null,
  closeModal,
  data,
}: {
  isModalOpen: any;
  isEdit: any;
  closeModal: any;
  data: object;
}) => {
  const [disabledCreate, setDisabledCreate] = useState(false);
  const counts = useSelector((state: AppState) => {
    return state.managementReducerData.roleCount;
  });
  const dispatch = useDispatch();

  const spinner = useSelector((state: AppState) => {
    return state.managementReducerData.showRoleSpinner;
  });
  // isModalOpen = hospitalSpinner ? !hospitalSpinner : isModalOpen;
  const names = ["Group Creation", "Assign Privileges"];
  useEffect(() => {
    setDisabledCreate(false);
    // dispatch(showRoleSpinner());
  }, []);

  const closeWizard = useCallback(() => {
    closeModal();
    dispatch(clearPrivilageError());
    dispatch(updateRolesPermissionActionData({}));
    dispatch(updateIsPermissionsFetchedForCreatePrivilege(false));
    dispatch(updateIsPermissionsFetchedForEditPrivilege(false));
  }, [closeModal]);
  const spinnerFunc = (dummySpinner = false) => {
    if (dummySpinner) {
      setDisabledCreate(true);
    } else {
      setDisabledCreate(false);
    }
    return dummySpinner;
  };
  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={closeWizard}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted">
            Privileges
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">
            {isEdit ? "Update Privilege Group" : "Create Privilege Group"}
          </Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton
            className=""
            iconProps={cancelIcon}
            onClick={closeWizard}
          />
        </Stack.Item>
      </Stack>

      <Wizard
        counts={counts}
        form="roleForm"
        names={names}
        back={roleDecrement}
        // spinner={spinner}
        disabledCreate={disabledCreate}
        isEdit={isEdit}
        onCancel={() => {
          dispatch(updateRolesPermissionActionData({}));
          dispatch(clearPrivilageError());
          dispatch(updateIsPermissionsFetchedForCreatePrivilege(false));
          dispatch(updateIsPermissionsFetchedForEditPrivilege(false));
          closeWizard();
        }}
      >
        <PrivilegeCreation
          isEdit={isEdit}
          data={data}
          editMode={Object.keys(data).length ? true : false}
        />
        <AssignPermissions
          closeModal={() => {
            dispatch(updateRolesPermissionActionData({}));
            dispatch(clearPrivilageError());
            closeWizard();
            dispatch(updateIsPermissionsFetchedForCreatePrivilege(false));
            dispatch(updateIsPermissionsFetchedForEditPrivilege(false));
          }}
          isEdit={isEdit}
          activeStep={counts}
          spinnerFunc={spinnerFunc}
        />
      </Wizard>
    </Modal>
  );
};

export default CreatePrivilege;
