import * as React from "react";
import { connect } from "react-redux";
import {
  clearFavoritesVideoData,
  editFavouriteVideoDetails,
  getVideos,
  resetEditedDataonCancel,
  setSelectedFavouriteVideo,
  setSelectedVideoUnfavourite,
  showSpinnerOnLoad,
} from "../actions/favouritesAction";
import ProcedureVideos from "../../common/procedureVideos/ProcedureVideos";
import { postSharedDetails } from "../../Shared/actions/sharedVideosAction";
import { withRouter } from "../../withRouter";

export interface FavouriteVideosProps {
  history: any;
  videosData: any;
  getVideosAction: any;
  setSelectedFavouriteVideo: any;
  setSelectedVideoUnfavourite: any;
  proceduresData: Array<any>;
  userData: any;
  userProfile: string;
  editFavouriteVideoDetails: any;
  favouriteVideoEditedFields: object;
  postSharedDetails: any;
  clearFavoritesVideoData: any;
  showSpinnerOnLoad: any;
  showSpinner: boolean;
  showData: boolean;
  currentPage: number;
  resetEditedDataonCancel: any;
}

export interface FavouriteVideosState {
  breadcrumbItems: Array<any>;
  currentVideoPage: any;
}

class FavouriteVideos extends React.Component<
  FavouriteVideosProps,
  FavouriteVideosState
> {
  constructor(props: FavouriteVideosProps) {
    super(props);
    this.state = {
      breadcrumbItems: [
        {
          text: "Home",
          key: "Home",
          onClick: () => this.props.history("/"),
        },
        {
          text: "Favorites",
          key: "d2",
          isCurrentItem: true,
          as: "h4",
        },
      ],
      currentVideoPage: 1,
    };
  }
  componentWillMount(): void {
    const currentVideoPage = localStorage.getItem("currentVideoPage") || 1;
    this.setState({ currentVideoPage: currentVideoPage });
  }
  componentDidMount() {
    this.props.showSpinnerOnLoad();
    this.props.getVideosAction(this.state.currentVideoPage);
  }

  handleFavouiteVideoClick = async () => {
    const [data] = this.props.videosData.results.filter(
      (item: any) => item.isSelected
    );
    const status = await this.props.setSelectedVideoUnfavourite(data);
    status && this.props.getVideosAction(this.state.currentVideoPage);
  };
  handleClick = (evt: React.MouseEvent, item: any) => {
    this.props.setSelectedFavouriteVideo(item);
  };

  componentWillUnmount() {
    this.props.clearFavoritesVideoData();
  }

  render() {
    return (
      <ProcedureVideos
        history={this.props.history}
        breadcrumbItems={this.state.breadcrumbItems}
        videosData={this.props.videosData}
        setSelectedVideo={this.handleClick}
        setSelectedVideoUnfavourite={this.props.setSelectedVideoUnfavourite}
        selectedVideoEditedFields={this.props.favouriteVideoEditedFields}
        editVideoDetails={this.props.editFavouriteVideoDetails}
        proceduresData={this.props.proceduresData}
        userData={this.props.userData}
        userProfile={this.props.userProfile}
        postSharedDetails={this.props.postSharedDetails}
        handleFavouiteVideoClick={this.handleFavouiteVideoClick}
        getVideosAction={this.props.getVideosAction}
        clearDataOnComponentUnmount={this.props.clearFavoritesVideoData}
        showSpinner={this.props.showSpinner}
        showData={this.props.showData}
        currentPage={this.state.currentVideoPage}
        resetEditedDataonCancel={this.props.resetEditedDataonCancel}
        screen={"favourite"}
        showSpinnerOnload={this.props.showSpinnerOnLoad}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    videosData: state.favouritesReducerData.videosData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
    favouriteVideoEditedFields:
      state.favouritesReducerData.favouriteVideoEditedFields,
    showSpinner: state.favouritesReducerData.showSpinner,
    showData: state.favouritesReducerData.showData,
    currentPage: state.favouritesReducerData.currentPage,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    getVideosAction: (currentPage: number) => dispatch(getVideos(currentPage)),
    setSelectedFavouriteVideo: (data: any) =>
      dispatch(setSelectedFavouriteVideo(data)),
    setSelectedVideoUnfavourite: (data: any) =>
      dispatch(setSelectedVideoUnfavourite(data)),
    editFavouriteVideoDetails: (data: any, videoId: number) =>
      dispatch(editFavouriteVideoDetails(data, videoId)),
    postSharedDetails: (data: any) => dispatch(postSharedDetails(data)),
    showSpinnerOnLoad: () => dispatch(showSpinnerOnLoad()),
    clearFavoritesVideoData: () => dispatch(clearFavoritesVideoData()),
    resetEditedDataonCancel: (type: string) =>
      dispatch(resetEditedDataonCancel(type)),
  };
};

export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(FavouriteVideos)
);
