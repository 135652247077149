import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getSalesLogs =
  (
    startDate: any = "",
    endDate: any = "",
    hospital: any = "",
    facility: any = "",
    room: any = "",
    region = "",
    speciality = "",
    isInit: any = false
  ) =>
  async (dispatch: any) => {
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/logs/sales?start_date=${startDate}&end_date=${endDate}&hospital=${hospital}&facility=${facility}&room=${room}&region=${region}&speciality=${speciality}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_SALES_LOGS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_SALES_LOGS,
          payload: { data: response.data, isInit: isInit },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_SALES_LOGS, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_SALES_LOGS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
