import {
  GET_SALES_LOGS,
  GET_VIEW_LOGS,
  GET_VIEW_LOG_BY_ID,
  GET_DATA_FROM_BLOB_URL,
  GET_LOG_HOSPITALS,
  REFRESH_LOG_HOSPITALS,
  GET_LOG_FACILITIES,
  GET_LOG_ROOMS,
  GET_SERVICE_LOGS,
  GET_SPACILITY,
  GET_ACTIVITY,
  GET_ACTIVITY_TYPES,
  REFRESH_ACTIVITY_TYPES,
  FILTERS_SELECTION,
  LOADER,
  STOPLOADER,
  TEMPLATE_DOWNLOAD,
  DOWNLOAD_ACTIVITY,
  RESET_ACTIVITY,
} from "../actions/actionConstants";

const initialState: any = {
  salesLogs: [],
  viewLogs: [],
  viewLogDetails: [],
  blobData: [],
  hospitals: [],
  facilities: [],
  rooms: [],
  spacilities: [],
  activities: [],
  activityTypes: [],
  showFilters: false,
  loadingState: false,
  templateData: [],
  activityData: [],
  initialSalesLogs: [],
  initialServiceLogs: [],
  loaderWithMessageService: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageLogs: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageSales: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },

  loaderWithMessageActivity: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
};

export const logsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_SALES_LOGS:
      const initialData = !action.payload.isInit
        ? state.initialSalesLogs
        : action.payload.data;
      return {
        ...state,
        salesLogs: action.payload.isInit
          ? state?.salesLogs || []
          : action.payload,
        initialSalesLogs: initialData,
      };

    // case GET_SALES_LOGS:
    //   return {
    //     ...state,
    //     salesLogs : action?.payload,
    //     initialSalesLogs: action?.payload?.data
    //   };

    // case GET_SERVICE_LOGS:
    //   return {
    //     ...state,
    //     serviceLogs: action.payload,
    //     initialServiceLogs: action?.payload?.data,
    //   };
    case GET_SERVICE_LOGS:
      const initialServiceData = action.payload.isInit
        ? action.payload.data
        : state.initialServiceLogs;
      return {
        ...state,
        serviceLogs: action.payload.isInit
          ? state?.serviceLogs || []
          : action.payload,
        initialServiceLogs: initialServiceData,
      };

    case GET_VIEW_LOGS:
      return {
        ...state,
        viewLogs: action.payload,
      };
    case GET_VIEW_LOG_BY_ID:
      return {
        ...state,
        viewLogDetails: action.payload,
      };
    case GET_DATA_FROM_BLOB_URL:
      return {
        ...state,
        blobData: action.payload,
      };
    case GET_LOG_HOSPITALS:
      const previousHospitalsData = state.hospitals?.data?.results || [];
      state.hospitals = action.payload;
      state.hospitals.data.results = [
        ...previousHospitalsData,
        ...state.hospitals.data.results,
      ];
      return {
        ...state,
      };
    case REFRESH_LOG_HOSPITALS:
      return {
        ...state,
        hospitals: action.payload,
      };
    case GET_LOG_FACILITIES:
      return {
        ...state,
        facilities: action.payload,
      };
    case GET_LOG_ROOMS:
      return {
        ...state,
        rooms: action.payload,
      };
    case GET_SPACILITY:
      return {
        ...state,
        spacilities: action.payload,
      };
    case GET_ACTIVITY:
      return {
        ...state,
        activities: action.payload,
      };
    case RESET_ACTIVITY:
      return {
        ...state,
        activities: [],
        activityTypes: [],
      };
    case REFRESH_ACTIVITY_TYPES:
      return {
        ...state,
        activityTypes: action.payload,
      };
    case GET_ACTIVITY_TYPES:
      const previousData = state.activityTypes?.data?.results || [];
      state.activityTypes = action.payload;
      state.activityTypes.data.results = [
        ...previousData,
        ...state.activityTypes.data.results,
      ];
      return {
        ...state,
      };
    case FILTERS_SELECTION:
      return {
        ...state,
        showFilters: true,
      };
    case LOADER:
      if (action.payload.calledFrom === "GET_VIEW_LOGS") {
        return {
          ...state,
          loaderWithMessageLogs: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_ACTIVITY") {
        return {
          ...state,
          loaderWithMessageActivity: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_SERVICE_LOGS") {
        return {
          ...state,
          loaderWithMessageService: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_SALES_LOGS") {
        return {
          ...state,
          loaderWithMessageSales: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
    case STOPLOADER:
      if (action.payload.calledFrom === "GET_VIEW_LOGS") {
        return {
          ...state,
          loaderWithMessageLogs: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_ACTIVITY") {
        return {
          ...state,
          loaderWithMessageActivity: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_SERVICE_LOGS") {
        return {
          ...state,
          loaderWithMessageService: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_SALES_LOGS") {
        return {
          ...state,
          loaderWithMessageSales: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      }
    case TEMPLATE_DOWNLOAD:
      return {
        ...state,
        templateData: action.payload,
      };
    case DOWNLOAD_ACTIVITY:
      return {
        ...state,
        activityData: action.payload,
      };
    default:
      return state;
  }
};
