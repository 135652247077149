import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
  SearchBox,
} from "@fluentui/react";
import { useId, useBoolean } from "@fluentui/react-hooks";

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Devices.scss";
// import { getDeviceList } from "../../../management/actions/managamentActions";
import {
  deleteDeviceAction,
  getDeviceList,
  getDeviceView,
  showCreateDevice,
} from "../../actions/managamentActions";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import CreateDevice from "./CreateDevice/CreateDevice";
import moment from "moment";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import {
  showDeviceSpinner,
  closeDeviceModal,
} from "../../actions/managamentActions";
import DeleteModal from "../../../Home/components/Common/DeleteModal";
import Paging from "../../../../common/paging/Paging";
import device from "../../../../assets/deviceprofile.svg";
import deviceimg from "../../../../assets/deviceimage.svg";
import { StackItem } from "office-ui-fabric-react";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Devices = () => {
  const [visibleDevicesList, setVisibleDevicesList] = useState(false);
  const [showDevice, setShowDevice] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [deviceSerialNumber, setDeviceSerialNumber] = useState();
  const [currentDevicePage, setCurrentDevicePage] = useState<number>(1);
  const [deviceShow, setDeviceShow] = useState<any>(false);
  const [deviceData, setDeviceData] = useState<any>();
  const [search, setSearchData] = useState<any>();
  const [showPage, setshowPage] = useState<any>(true);

  // const [isModalOpen, { setTrue: showDeviceModal, setFalse: hideModal }] = useBoolean(false);
  const cancelIcon: IIconProps = { iconName: "Cancel" };

  const onVideoPageChange = useCallback(
    (pageNo) => {
      setCurrentDevicePage(pageNo);
      dispatch(getDeviceList(pageNo, search));
    },
    [search]
  );

  const showModal = () => {
    setShowDevice((current) => !current);
    dispatch(showCreateDevice());
    if (showDevice) {
      dispatch(closeDeviceModal());
      setDeviceData(null);
    }
  };
  const showDeviceDetail = (device: any) => {
    setDeviceShow(true);
    setDeviceData(device);
  };
  const closeDeviceDetail = () => {
    setDeviceShow(false);
    setDeviceData(null);
  };
  const dispatch = useDispatch();

  const devices = useSelector((state: AppState) => {
    return state.managementReducerData.deviceList.data;
  });

  const deviceView = useSelector((state: AppState) => {
    return state.managementReducerData.deviceView.data;
  });

  const showCreateDeviceSuccess = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateDevice;
  });
  const showEditModal = () => {
    dispatch(getDeviceView(deviceSerialNumber));
    setShowDevice((current) => !current);
    if (!showDevice) {
      dispatch(closeDeviceModal());
    }
  };

  const closeDevices = useSelector((state: AppState) => {
    return state.managementReducerData.closeDevices;
  });

  const deleteDevice = useSelector((state: AppState) => {
    return state.managementReducerData.deleteDevice.data;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageDevices;
  });

  const _onMenuClickSetId = (devicesData: any) => {
    setDeviceSerialNumber(devicesData?.serial_number);
    setDeviceData(devicesData);
  };
  const showDeleteDeviceModal = useCallback(() => {
    setOpenDeleteDeviceModal((current) => !current);
    setDisabledCreate(false);
  }, []);

  const deleteDeviceHandle = () => {
    setDisabledCreate(true);
    dispatch(deleteDeviceAction(deviceSerialNumber));
    setOpenDeleteDeviceModal((current) => !current);
  };
  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });
  const handleSearchDevices = (e: any) => {
    setshowPage(false);
    const searchValue = e?.target?.value || "";
    setSearchData(searchValue);

    if (searchValue?.length > 3 || searchValue?.length == 0) {
      dispatch(getDeviceList(1, searchValue));
    }
    setCurrentDevicePage(1);
    setTimeout(() => {
      setshowPage(true);
    }, 1000);
  };
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "edit",
        text: "Edit",
        onClick: constants.getPermissionsbyItem("management.device.edit")
          ? showEditModal
          : void 0,
        title: constants.getPermissionsbyItem("management.device.edit")
          ? ""
          : constants.permission,
      },
      {
        key: "delete",
        text: "Delete",
        onClick: constants.getPermissionsbyItem("management.device.delete")
          ? showDeleteDeviceModal
          : void 0,
        title: constants.getPermissionsbyItem("management.device.delete")
          ? ""
          : constants.permission,
      },
    ],
  };

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_DEVICES_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleDevicesList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_DEVICES_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleDevicesList(false);
    }
  }, [loaderWithMessage]);

  useEffect(() => {
    dispatch(getDeviceList(currentDevicePage, search));
  }, [deleteDevice]);
  return (
    <Stack className="management deviceManagement">
      {deviceShow && (
        <Stack className="device-profile">
          <Stack.Item className="device-profile-header">
            <Text variant="xLarge">Devices Profile</Text>
            <Stack.Item>
              <IconButton
                className=""
                iconProps={cancelIcon}
                onClick={closeDeviceDetail}
              />
            </Stack.Item>
          </Stack.Item>
          <Stack.Item className="device-align">
            <Stack.Item className="device-profile-container-left">
              <Stack.Item className="device-profile-container">
                <Stack.Item className="device-profile-left">
                  <Stack.Item className="device-item">
                    <label>Serial Number</label>
                    <Stack.Item>{deviceData?.serial_number}</Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="device-item">
                    <label>Model Number</label>
                    <Stack.Item>{deviceData?.model}</Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="device-item">
                    <label>MAC Address</label>
                    <Stack.Item>{deviceData?.mac_address}</Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="device-item">
                    <label>IP Address</label>
                    <Stack.Item>{deviceData?.external_ip}</Stack.Item>
                  </Stack.Item>
                </Stack.Item>
                <Stack.Item className="device-profile-right">
                  <Stack.Item className="device-item">
                    <label>OS</label>
                    <Stack.Item>{deviceData?.operating_system}</Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="device-item">
                    <label>OS Version</label>
                    <Stack.Item>{deviceData?.os_version}</Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
            <Stack.Item className="device-profile-continer-right">
              <img className="device-img-continer" src={device} />
              <img className="device-img" src={deviceimg} />
            </Stack.Item>
          </Stack.Item>
        </Stack>
      )}
      {!deviceShow && (
        <Stack>
          <Stack.Item className="heading">
            <Text>Device Management</Text>
          </Stack.Item>
          <Stack
            className="addDevice"
            dir="ltr"
            horizontal
            style={{ position: "relative" }}
          >
            <CheckPermission {...constants.SCOPES.CREATE_DEVICE}>
              <DefaultButton
                onClick={showModal}
                text="Add Device"
                iconProps={addIcon}
                allowDisabledFocus
              />
            </CheckPermission>
            <Stack
              horizontal
              horizontalAlign="end"
              style={{ position: "absolute", right: 0 }}
            >
              <SearchBox placeholder="Search" onChange={handleSearchDevices} />
            </Stack>
          </Stack>
          {showDevice && (
            <CreateDevice
              deviceSerialNumber={deviceSerialNumber}
              isModalOpen={showDevice}
              showModal={() => showModal()}
              orgDevicData={deviceData}
            ></CreateDevice>
          )}
          <Stack className="availableDeviceList">
            <Stack.Item className="deviceSubHeading">
              <Text variant="xLarge">Available Devices</Text>
            </Stack.Item>
            <Stack.Item className="ms-Grid" dir="ltr">
              <Stack.Item className="ms-Grid-row tableHeading">
                <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
                  <Text>Serial Number</Text>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">Provisioning Date</Text>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
                  <Text variant="smallPlus">Allocated to</Text>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col  ms-lg1-point-5">
                  <Text variant="smallPlus">Allocation Type</Text>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
                  <Text variant="smallPlus">Device Location</Text>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
                  <Text variant="smallPlus">Leasing Period</Text>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col">
                  <Text variant="smallPlus">Region</Text>
                </Stack.Item>
              </Stack.Item>
              {visibleDevicesList &&
                devices &&
                devices?.results.map((device: any) => (
                  <Stack.Item
                    key={device?.id}
                    className="ms-Grid-row tableText"
                    onClick={() => showDeviceDetail(device)}
                  >
                    <Stack.Item className="ms-Grid-col ms-lg1-point-5 textEllipses">
                      <Text
                        variant="smallPlus"
                        block
                        title={device?.serial_number}
                      >
                        {device?.serial_number}
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg2">
                      <Text variant="smallPlus">
                        {moment(device?.created_date).format(
                          "MMM D YYYY HH:mm"
                        )}
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg1-point-5 textEllipses">
                      <Text
                        block
                        variant="smallPlus"
                        title={device?.device_allocation?.hospital_code}
                      >
                        {device?.device_allocation?.hospital_code
                          ? device?.device_allocation?.hospital_code
                          : "NA"}
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg1-point-5">
                      <Text variant="smallPlus">
                        {device?.device_allocation?.allocation_type
                          ? device?.device_allocation?.allocation_type
                          : "NA"}
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg1-point-5">
                      <Text variant="smallPlus">
                        {device?.device_allocation?.room
                          ? device?.device_allocation?.room
                          : "NA"}
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg1-point-5">
                      <div>
                        <Text variant="smallPlus" block>
                          {device?.device_allocation?.allocation_start &&
                            moment(
                              device?.device_allocation?.allocation_start
                            ).format("MMM-DD-YYYY")}{" "}
                          {device?.device_allocation?.allocation_start
                            ? "to"
                            : "NA"}
                        </Text>
                        <Text variant="smallPlus">
                          {device?.device_allocation?.allocation_end &&
                            moment(
                              device?.device_allocation?.allocation_end
                            ).format("MMM-DD-YYYY")}
                        </Text>
                      </div>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg region_devices">
                      <Text
                        variant="smallPlus"
                        block
                        title={device?.device_allocation?.region || "NA"}
                      >
                        {device?.device_allocation?.region || "NA"}
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg">
                      <Text variant="smallPlus">
                        <IconButton
                          className="buttonIcon"
                          menuIconProps={moreIcon}
                          menuProps={menuProps}
                          ariaLabel="New item"
                          onMenuClick={() => _onMenuClickSetId(device)}
                        />
                      </Text>
                    </Stack.Item>
                  </Stack.Item>
                ))}
            </Stack.Item>
            {visibleDevicesList &&
            devices &&
            devices?.count > 10 &&
            devices?.page_size &&
            showPage ? (
              <Paging
                currentPage={currentDevicePage}
                totalPageCount={Math.ceil(devices?.count / 10)}
                handlePageChange={onVideoPageChange}
              />
            ) : null}
          </Stack>
          <Modal isOpen={closeDevices} containerClassName={"modalStyle"}>
            <SuccessMessage
              title={`Device ${
                showCreateDeviceSuccess ? "Creation" : "Updated"
              } `}
            />
          </Modal>
          {(closeDevices || closeWhenErrors) &&
            setTimeout(() => {
              setShowDevice(false);
              showDevice && dispatch(getDeviceList(currentDevicePage, search));
              dispatch(showDeviceSpinner());
            }, 1000) && <></>}
          <DeleteModal
            isModalOpen={openDeleteDeviceModal}
            deleteHandle={
              !devices?.device?.device_allocation?.hospital_code &&
              deleteDeviceHandle
            }
            showModal={showDeleteDeviceModal}
            id={deviceSerialNumber}
            name="Device"
            disabledCreate={disabledCreate}
          ></DeleteModal>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
        </Stack>
      )}
    </Stack>
  );
};
export default Devices;
