import { get, keys, createStore } from "idb-keyval";
import * as React from "react";
import { connect } from "react-redux";
import {
  deleteInprogressItem,
  getProcessingVideos,
  setInprogressVideosListAfterRefresh,
  updateVideoProgress,
} from "../InProgress/actions/InProgressAction";
import { storeInstance } from "../store";
import Pusher from "pusher-js";
import {
  getNotificationsFromPusher,
  getUserProfile,
  getOfflineNotifications,
} from "../Layout/actions/layoutActions";
import { apiEndPoint, doHttpCall } from "../common/restApi";
import { CLEAR_DATASET_VIDEO_METADATA } from "../DeveloperModule/DatasetMapping/actions/actionConstants";
import { PUSHER_KEY } from "../common/configUtils";
export interface MainWrapperProps {
  getUserProfile: any;
  getNotificationsFromPusher: any;
  userProfile: any;
  getProcessingVideos: any;
  setInprogressVideosListAfterRefresh: any;
  inProgressVideoData: any;
  logout: any;
}
export interface MainWrapperState {}
export const customStore = createStore("activeEdge", "Procedures");
export const fileStore = createStore("activeEdgeFiles", "FileObjects");
export let FileUploadWorker: any;
export let DataSetFileUploadWorker: any;
export let _pusher: any;
export function WebWorkerDataSetFileupload() {
  if (typeof Worker !== "undefined") {
    if (typeof DataSetFileUploadWorker == "undefined") {
      DataSetFileUploadWorker = new window.Worker(
        "/workers/fileUpload.worker.js"
      );
      DataSetFileUploadWorker.onmessage = async (e: any) => {
        if (e.data.status === "complete") {
          const data =
            storeInstance.getState().developerDataSetReducerData
              .datasetVideoMetadata;
          uploadSuccessFile(data[e.data.id], e);
        }
      };
    }
  }
}
const uploadSuccessFile = async (data: any, e: any) => {
  const payload = {
    method: "PATCH",
    url: apiEndPoint + "/developer/dataset/" + data.correlationId,
    data: { status: "UPLOADED" },
  };
  try {
    await doHttpCall(payload);
  } catch (e) {
    console.error(e);
  }
};
export function setWebWorker(userProfile?: any) {
  if (typeof Worker !== "undefined") {
    if (typeof FileUploadWorker == "undefined") {
      FileUploadWorker = new window.Worker("/workers/fileUpload.worker.js");
      FileUploadWorker.postMessage({
        type: "resume",
        userProfile: userProfile,
      });
      FileUploadWorker.onmessage = async (e: any) => {
        storeInstance.dispatch(updateVideoProgress(e.data));
        if (e.data.status === "complete") {
          const [data] = storeInstance
            .getState()
            .inProgressReducerData.inProgressVideosData.filter(
              (item: any) => item.id === e.data.id
            );
          const payload = {
            method: "PATCH",
            url: apiEndPoint + "/videos/metadata/" + data.correlationId,
            data: { status: "UPLOADED" },
          };
          setInterval(() => {
            storeInstance.dispatch(getOfflineNotifications());
          }, 10000);
          storeInstance.dispatch(deleteInprogressItem(e.data.id));
          try {
            const response = await doHttpCall(payload);
            if (response && response.status === 200) {
              storeInstance.dispatch(getProcessingVideos(1));
            } else {
              storeInstance.dispatch({
                type: "SHOW_VIDEO_UPLOAD_ERROR",
                payload: response.data,
              });
              setTimeout(
                () =>
                  storeInstance.dispatch({ type: "HIDE_VIDEO_UPLOAD_ERROR" }),
                3000
              );
            }
          } catch (e) {
            console.error(e);
          }
        }
      };
    }
  }
}
export const terminateWebWorker = () => {
  if (FileUploadWorker) {
    FileUploadWorker.terminate();
    if (DataSetFileUploadWorker) {
      DataSetFileUploadWorker.terminate();
      DataSetFileUploadWorker = undefined;
      storeInstance.dispatch({
        type: CLEAR_DATASET_VIDEO_METADATA,
      });
    }
    FileUploadWorker = undefined;
  }
};
window.addEventListener("offline", () => {
  // FileUploadWorker.terminate && FileUploadWorker.terminate();
  FileUploadWorker = undefined;
  DataSetFileUploadWorker = undefined;
});
class MainWrapper extends React.Component<MainWrapperProps, MainWrapperState> {
  componentDidMount() {
    if (localStorage.getItem("token")) {
      setWebWorker();
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props && this.props?.logout && _pusher) {
      _pusher.disconnect();
      return true;
    }
    window.addEventListener("online", () => {
      setWebWorker(
        this.props.userProfile.id
          ? this.props.userProfile.id
          : this.props.userProfile.userId
      );
    });
    if (localStorage.getItem("token")) {
      keys(customStore).then((keys) => {
        keys.forEach((key: any) => {
          get(key, customStore).then((value: any) => {
            if (
              typeof value === "object" &&
              value.videoData &&
              value.videoData["uploaderId"] === this.props.userProfile.id
                ? this.props.userProfile.id
                : this.props.userProfile.userId
            ) {
              setWebWorker(
                this.props.userProfile.id
                  ? this.props.userProfile.id
                  : this.props.userProfile.userId
              );
            }
          });
        });
      });
    }

    const userProfile = this.props.userProfile?.id
      ? this.props.userProfile?.id
      : this.props.userProfile?.userId;
    const prevUserProfile = prevProps.userProfile?.id
      ? prevProps.userProfile?.id
      : prevProps.userProfile?.userId;
    if (userProfile && userProfile !== prevUserProfile) {
      storeInstance.dispatch(
        setInprogressVideosListAfterRefresh(
          this.props.userProfile.id
            ? this.props.userProfile.id
            : this.props.userProfile.userId
        )
      );
      _pusher = new Pusher(PUSHER_KEY, {
        cluster: "ap2",
      });
      const channel = _pusher.subscribe(
        this.props.userProfile.id
          ? this.props.userProfile.id
          : this.props.userProfile.userId
      );
      //  setTimeout(()=>{
      // // _pusher.disconnect()
      //  },5000)
      const callback = (eventName: any, data: any) => {
        const notificationData = {
          type: eventName,
          data: data,
        };
        this.props.getNotificationsFromPusher(notificationData);
      };
      channel.bind_global(callback);
    }
  }
  state = {};
  render() {
    return <span></span>;
  }
}
const mapStateToProps = (state: any) => {
  return {
    userProfile: state.dashboardReducerData.loginUserProfile,
    datasetVideoMetadata: state.dashboardReducerData.datasetVideoMetadata,
    inProgressVideoData: state.inProgressReducerData.inProgressVideosData,
    logout: state.authReducerData.logout,
  };
};
const dispatchActionToProps = (dispatch: any) => {
  return {
    getOfflineNotifications: () => dispatch(getOfflineNotifications()),
    getUserProfile: () => dispatch(getUserProfile()),
    getNotificationsFromPusher: (notificationData: any) =>
      dispatch(getNotificationsFromPusher(notificationData)),
    getProcessingVideos: (currentPage: number) =>
      dispatch(getProcessingVideos(currentPage)),
    setInprogressVideosListAfterRefresh: (userId: any) =>
      dispatch(setInprogressVideosListAfterRefresh(userId)),
  };
};
export default connect(mapStateToProps, dispatchActionToProps)(MainWrapper);
