import React from "react";
import { Stack, Checkbox, Text, Label } from "@fluentui/react";
import ToggleCard from "../../../../Home/components/Wizard/ToggleCard";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { ReactComponent as ReactUser } from "../../../../../assets/Management/User.svg";
import {
  editRole,
  getPermissionsList,
  roleIncrement,
  updateIsPermissionListFetched,
} from "../../../actions/managamentActions";
import { AppState } from "../../../../../rootReducer";
import {
  showRoleSpinner,
  createRolesPermission,
  closeRoleModal,
} from "../../../actions/managamentActions";
import Loader from "../../../../Logs/components/Common/Loader";
const AssignPermissions = ({
  showModal,
  showCreateRole,
  rolesPermissionData,
  isRoleChange,
  roleId,
  spinnerFunc,
}: {
  showModal: any;
  showCreateRole: any;
  rolesPermissionData: any;
  isRoleChange: any;
  roleId: any;
  spinnerFunc: any;
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const permissions = useSelector((state: AppState) => {
    if (showCreateRole) {
      const currentEditedData = { ...rolesPermissionData };
      delete currentEditedData?.name;
      delete currentEditedData?.user_type;
      delete currentEditedData?.role_location;
      delete currentEditedData?.comments;
      return currentEditedData;
    } else {
      return state.managementReducerData.permissionList.data;
    }
  });
  if (permissions && permissions.roles) {
    delete permissions.roles;
  }
  const selectedPermissionUserType = useSelector((state: AppState) => {
    return state.managementReducerData.selectedPermissionUserType;
  });
  const isPermissionListFetched = useSelector((state: AppState) => {
    return state.managementReducerData.isPermissionListFetched;
  });
  const checkAtleastOneRoleSelected = (perms: any) => {
    if (showCreateRole) {
      let groupRolesFlag = false;
      if (
        !perms.hasOwnProperty("algorithm") &&
        !perms.hasOwnProperty("logs") &&
        !perms.hasOwnProperty("management") &&
        !perms.hasOwnProperty("vps")
      ) {
        return false;
      }
      const groupRoles = ["algorithm", "logs", "management", "vps"];
      for (const groupRole of groupRoles) {
        if (perms[groupRole] && perms[groupRole].length) {
          const selectedArr = perms[groupRole].filter(
            (item: any) => item.selected
          );
          if (selectedArr.length) {
            groupRolesFlag = true;
            break;
          }
        }
      }
      return groupRolesFlag;
    } else if (!showCreateRole) {
      if (perms?.algorithm || perms?.logs || perms?.management || perms?.vps) {
        return true;
      } else {
        return false;
      }
    }
  };
  const getCreateRolePayload = () => {
    let createRolePayload: any = {};
    let userTypeObject: any = {};
    permissions &&
      Object.keys(permissions)?.map((key: any) => {
        const permission = permissions[key];
        const permissionOrigional = permissions[key];
        const permissionsRight = permission.map((per: any, index: any) => {
          if (per && per.selected) {
            return { id: permissionOrigional[index].id };
          }
        });
        const removeUndefined =
          permissionsRight &&
          permissionsRight.filter((key: any) => key !== undefined);
        removeUndefined?.length > 0 &&
          Object.assign(createRolePayload, {
            [key]: removeUndefined,
          });
      });
    userTypeObject = {
      user_type: selectedPermissionUserType,
    };
    createRolePayload = {
      ...rolesPermissionData,
      ...createRolePayload,
      ...userTypeObject,
    };
    return createRolePayload;
  };
  const createNewRole = () => {
    const getPayload = getCreateRolePayload();
    const atLeastOneRoleSelected = checkAtleastOneRoleSelected(getPayload);
    if (atLeastOneRoleSelected) {
      setErrorMessage("");
      // dispatch(showRoleSpinner());
      // dispatch(roleIncrement());
      dispatch(createRolesPermission(getPayload));
    } else {
      spinnerFunc(false);
      setErrorMessage("Atleast one permission must be provided");
    }
  };
  const updatedEditedRole = async () => {
    const atLeastOneRoleSelected =
      checkAtleastOneRoleSelected(rolesPermissionData);
    if (atLeastOneRoleSelected) {
      const responce: any = await dispatch(
        editRole(roleId, rolesPermissionData)
      );
      dispatch(closeRoleModal());
    } else {
      spinnerFunc(false);
      setErrorMessage("Atleast one permission must be provided");
    }
  };
  const onSubmit = (data: any) => {
    spinnerFunc(true);
    if (!showCreateRole) {
      createNewRole();
    } else if (showCreateRole) {
      updatedEditedRole();
    }
  };
  useEffect(() => {
    if (errorMessage) {
      spinnerFunc(false);
    }
  }, [errorMessage]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
  }, []);
  useEffect(() => {
    const fetchPermissions = async () => {
      if (!showCreateRole && !isPermissionListFetched) {
        const response: any = await dispatch(
          getPermissionsList(selectedPermissionUserType)
        );
        if (response && response.status === 200) {
          dispatch(updateIsPermissionListFetched(true));
          setIsLoader(false);
        }
      } else if (isPermissionListFetched) {
        setIsLoader(false);
      }
    };
    fetchPermissions();
  }, []);
  const updateObjectChecked = (data: any, isChecked: boolean | undefined) => {
    return {
      ...data,
      selected: isChecked,
    };
  };
  const sortUpdatedPermissiosn = (arrayOfPermissions: any) => {
    arrayOfPermissions &&
      arrayOfPermissions.sort(
        (compareFirstName: any, compareSecondNamae: any) => {
          const firstName = compareFirstName.name.toUpperCase();
          const secondName = compareSecondNamae.name.toUpperCase();
          if (firstName < secondName) {
            return -1;
          }
          if (firstName > secondName) {
            return 1;
          }
          return 0;
        }
      );
  };
  const getUpdatedPermissionsKeyWise = (
    originalPermissions: any,
    selectedKey?: any,
    event?: any
  ) => {
    const updatedPermissionsWithKey: any = [];
    originalPermissions &&
      Object.keys(originalPermissions)?.map((key: any) => {
        if (key === selectedKey) {
          const currentSelectedPermissionKeyWise = originalPermissions[key];
          const remainingPermissionObject: any = [];
          const checkedPermissionsObject: any = [];
          let updatedPermissionObject: any = {};
          for (const currentObject of currentSelectedPermissionKeyWise &&
            currentSelectedPermissionKeyWise) {
            if (
              currentObject.id == event?.currentTarget.id &&
              currentObject.name === event?.currentTarget.title
            ) {
              const obj = updateObjectChecked(
                currentObject,
                !currentObject.selected
              );
              checkedPermissionsObject.push(obj);
            } else {
              remainingPermissionObject.push(currentObject);
            }
          }
          if (checkedPermissionsObject || remainingPermissionObject) {
            updatedPermissionObject = [
              ...checkedPermissionsObject,
              ...remainingPermissionObject,
            ];
          }
          updatedPermissionObject &&
            Object.assign(updatedPermissionsWithKey, {
              [key]: updatedPermissionObject,
            });
        }
      });
    sortUpdatedPermissiosn(updatedPermissionsWithKey[selectedKey]);
    return updatedPermissionsWithKey;
  };
  const onChangeSlection = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    selectedKey?: any
  ) => {
    //Building updated objects
    const updatedPermissionsWithKey = getUpdatedPermissionsKeyWise(
      !!showCreateRole ? rolesPermissionData : permissions,
      selectedKey,
      ev
    );
    (!!showCreateRole ? rolesPermissionData : permissions) &&
      Object.keys(!!showCreateRole ? rolesPermissionData : permissions)?.map(
        (key: any) => {
          if (key === selectedKey) {
            (!!showCreateRole ? rolesPermissionData : permissions)[
              selectedKey
            ] = updatedPermissionsWithKey[selectedKey];
          }
        }
      );
  };
  return (
    <>
      {isLoader ? (
        <Loader></Loader>
      ) : (
        <Stack>
          {(rolesPermissionData || !showCreateRole) && (
            <>
              <Stack horizontal horizontalAlign="center" verticalAlign="center">
                <Stack.Item className="roleNameSection">
                  <Text variant="medium">Role Name</Text>
                  <Stack
                    className="roleName"
                    horizontalAlign="center"
                    verticalAlign="center"
                  >
                    <ReactUser />
                    <Text variant="small">{rolesPermissionData?.name}</Text>
                  </Stack>
                </Stack.Item>
              </Stack>
              <Label className="errorMessage bold-error">{errorMessage}</Label>
              <form id="roleForm" onSubmit={handleSubmit(onSubmit)}>
                {permissions &&
                  Object.keys(permissions)?.map((key: any, index: any) => {
                    sortUpdatedPermissiosn(permissions[key]);
                    const permission = permissions[key];
                    return (
                      <ToggleCard
                        heading={key}
                        index=""
                        removeFormFields=""
                        key={index}
                      >
                        <Stack className="permissionGroupSection ms-Grid-row">
                          <Stack.Item className="ms-Grid-row">
                            <Stack.Item className="ms-Grid-col ms-lg9 ms-lgOffset1">
                              <Stack.Item className="ms-Grid-row">
                                {permission &&
                                  permission?.map(
                                    (permissionValues: any, index: any) => {
                                      return (
                                        <Stack.Item
                                          className="ms-Grid-col ms-lg3 algorithmSection"
                                          key={index}
                                        >
                                          <Controller
                                            name={`permissions.${key}.${index}.id`}
                                            control={control}
                                            defaultValue={
                                              permissionValues?.selected
                                            }
                                            render={({ field }) => {
                                              return (
                                                <Stack horizontal dir="ltr">
                                                  <Checkbox
                                                    defaultChecked={
                                                      permissionValues?.selected
                                                    }
                                                    {...field}
                                                    label={
                                                      permissionValues?.name
                                                    }
                                                    title={
                                                      permissionValues?.name
                                                    }
                                                    onChange={(event) =>
                                                      onChangeSlection(
                                                        event,
                                                        key
                                                      )
                                                    }
                                                    id={permissionValues?.id}
                                                    name={
                                                      permissionValues?.name
                                                    }
                                                    key={permissionValues?.id}
                                                  />
                                                </Stack>
                                              );
                                            }}
                                          />
                                        </Stack.Item>
                                      );
                                    }
                                  )}
                              </Stack.Item>
                            </Stack.Item>
                          </Stack.Item>
                        </Stack>
                      </ToggleCard>
                    );
                  })}
              </form>
            </>
          )}
        </Stack>
      )}
    </>
  );
};
export default AssignPermissions;
