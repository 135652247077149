import { GET_SALES_LOGS, LOADER, STOPLOADER } from "../actions/actionConstants";

const initialState: any = {
  salesLogs: [],
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
};

export const hospitallLogsReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_SALES_LOGS:
      return {
        ...state,
        salesLogs: action.payload,
      };
    case LOADER:
      return {
        ...state,
        loaderWithMessage: {
          loadingState: true,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
        },
      };
    case STOPLOADER:
      return {
        ...state,
        loaderWithMessage: {
          loadingState: false,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
          statusCode: action.payload?.statusCode,
        },
      };

    default:
      return state;
  }
};
