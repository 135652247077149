import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./rootReducer";

export const routerHistory = createBrowserHistory();
const router = routerMiddleware(routerHistory);
const initialState = {};
export const storeInstance = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(router, thunk))
);
