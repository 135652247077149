import React, { useState, useCallback, useEffect } from "react";
import {
  Stack,
  Text,
  Modal,
  DefaultButton,
  IconButton,
  IIconProps,
  StackItem,
  Breadcrumb,
  IBreadcrumbItem,
  Image,
} from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import "../../../../ActiveAdminModule/Home/components/CreateHospital/CreateHospitalWizard/Conformation/Conformation.scss";
import moment from "moment";
import { ReactComponent as ProfileBg } from "../../../../assets/Home/profilebg.svg";
import ProfileImg from "../../../../assets/Home/profilebg.png";
import { ReactComponent as SurgeonImg } from "../../../../assets/Home/surgeonImg.svg";
import { ReactComponent as Email } from "../../../../assets/Home/email_black.svg";
import { ReactComponent as Mobile } from "../../../../assets/Home/mobile.svg";
import { useBoolean } from "@fluentui/react-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Videos from "../VideoSection/Video";
import Paging from "../../../../common/paging/Paging";
import { getVideosById } from "../../actions/homeAction";
import * as allActions from "../../actions/actionConstants";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const SurgeonFullView = ({
  id,
  surgeon,
  videos,
}: {
  id: any;
  surgeon: any;
  videos: any;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state }: any = useLocation();
  const { photo } = state;
  const prefixZero = (num: Number) => {
    return ("0" + num).slice(-2);
  };
  const items: IBreadcrumbItem[] = [
    {
      text: "Home",
      key: "Home",
      onClick: () => navigate(`/`),
    },
    {
      text: "All Surgeons",
      key: "All Surgeons",
      onClick: () => navigate(`/`),
    },
    {
      text: surgeon?.displayName,
      key: surgeon?.displayName,
      isCurrentItem: true,
    },
  ];
  const dateFormat = (date: any) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    } else {
      return "";
    }
  };
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [videoCurrentPage, setVideoCurrentPage] = useState<number>(1);

  const alertClicked = () => {
    dispatch({
      type: allActions.GET_SURGEON_BY_ID,
      payload: { data: [] },
    });
    navigate(`/`);
  };
  useEffect(() => {
    dispatch(getVideosById(id));
  }, []);
  const formatPhoneNumber = (phoneNumberString: any) => {
    const slicedArray = phoneNumberString
      ? phoneNumberString.slice(0, 10)
      : null;
    const cleaned = ("" + slicedArray).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "+91 ", match[2], match[3], match[4]].join("");
    }
    return null;
  };
  const videosById = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.videosById;
  });
  const onVideoPageChange = useCallback((pageNo) => {
    setVideoCurrentPage(pageNo);
    dispatch(getVideosById(id, pageNo));
  }, []);
  // console.log(hospitalData, isModalOpen, 'hospitalDatahospitalDatahospitalData');
  return (
    <Stack className="conformation ms-Grid" dir="ltr">
      <Stack
        className="createHospitalHeading"
        dir="ltr"
        horizontal
        horizontalAlign="space-between"
      >
        {/* <Breadcrumb
        items={items}
        maxDisplayedItems={3}
        ariaLabel="Breadcrumb with items rendered as buttons"
        overflowAriaLabel="More links"
      /> */}
        <Stack.Item>
          <Text variant="large" className="muted" onClick={alertClicked}>
            Home
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large" className="muted" onClick={alertClicked}>
            All Surgeons
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          {/* <Text variant="large" className="muted">
                    All users
                </Text>
                <Text variant="large" className="lessthan muted">
                    &gt;
                </Text> */}
          {/* <Text variant="large">{surgeon?.displayName}</Text> */}
          <Text variant="large">Surgeon Profile</Text>
        </Stack.Item>

        <Stack.Item align="end">
          <IconButton
            styles={{ root: { marginRight: "15px", color: "white" } }}
            className=""
            iconProps={cancelIcon}
            onClick={alertClicked}
          />
        </Stack.Item>
      </Stack>
      <Stack
        tokens={{ childrenGap: 40 }}
        horizontal
        styles={{
          root: {
            // marginLeft: "20px",
            background: `url(${ProfileImg})`,
            height: 219,
            width: "98%",
            padding: 20,
            backgroundPosition: "right",
          },
        }}
        // className='circle'
      >
        {/* <StackItem className="circle"> */}
        {/* {surgeon?.first_name?.charAt(0) + surgeon?.last_name?.charAt(0)} */}
        {/* <SurgeonImg /> */}
        {/* </StackItem> */}
        {photo ? (
          <Image
            style={{ borderRadius: "50px" }}
            src={photo}
            width="77px"
            height="70px"
            alt="user image"
          ></Image>
        ) : (
          <StackItem className="circle" style={{ textTransform: "uppercase" }}>
            {surgeon?.first_name?.charAt(0) + surgeon?.last_name?.charAt(0)}
          </StackItem>
        )}
        <StackItem grow={4}>
          <Stack tokens={{ childrenGap: 25 }}>
            <StackItem>
              <Stack tokens={{ childrenGap: 5 }}>
                <StackItem>{surgeon?.displayName}</StackItem>
                <StackItem>{surgeon?.speciality_name}</StackItem>
              </Stack>
            </StackItem>
            {JSON.parse(surgeon?.facility || "[]").length > 0 && (
              <StackItem>
                {surgeon?.facility_name}
                {/* <Stack>
                  {JSON.parse(surgeon?.facility|| "[]").map(
                    (loc: string, index: number) => (
                      <StackItem key={index}>{loc}</StackItem>
                    )
                  )}
                </Stack> */}
              </StackItem>
            )}
            <StackItem>
              <Stack tokens={{ childrenGap: 5 }}>
                <StackItem>Comments:</StackItem>
                <StackItem>{surgeon?.comments}</StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem grow={2}>
          <Stack tokens={{ childrenGap: 5 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Email />
              <StackItem>{surgeon?.mail}</StackItem>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 14 }}>
              <Mobile />
              <StackItem
                styles={{
                  root: {
                    marginLeft: "10px",
                  },
                }}
              >
                {surgeon?.phone_number}
              </StackItem>
            </Stack>
          </Stack>
        </StackItem>
        {/* <ProfileBg /> */}
      </Stack>
      {videosById?.results?.length && (
        <Stack
          tokens={{ childrenGap: 5 }}
          style={{ marginLeft: "16px", marginTop: "16px" }}
        >
          <StackItem>{"All Videos"}</StackItem>
        </Stack>
      )}
      <Stack
        horizontal
        wrap
        verticalFill
        tokens={{ childrenGap: 10, padding: 10 }}
        styles={{ root: { marginLeft: "20px" } }}
      >
        {videosById?.results?.map((video: any, index: any) => (
          <StackItem key={index} className="allVideosVideoCard">
            <Videos video={video} key={index}></Videos>
          </StackItem>
        ))}
      </Stack>
      {videosById?.count > 10 && videosById?.page_size ? (
        <Paging
          currentPage={videoCurrentPage}
          totalPageCount={Math.ceil(videosById?.count / 10)}
          handlePageChange={onVideoPageChange}
        />
      ) : null}
    </Stack>
  );
};

export default SurgeonFullView;
