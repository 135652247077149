import { Stack } from "@fluentui/react";
import React from "react";
import { useSelector } from "react-redux";
import ViewLogFiles from "../../../ActiveAdminModule/Logs/components/ViewLogsFiles/ViewLogFiles";
import { AppState } from "../../../rootReducer";

const Logs = () => {
  return (
    <Stack>
      <ViewLogFiles />
    </Stack>
  );
};
export default Logs;
