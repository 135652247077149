import {
  DefaultButton,
  IconButton,
  Modal,
  Stack,
  Text,
  IIconProps,
  IContextualMenuProps,
} from "@fluentui/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paging from "../../../../common/paging/Paging";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import { AppState } from "../../../../rootReducer";
import DeleteModal from "../../../Home/components/Common/DeleteModal";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import {
  clearBusinessAlgorithmList,
  closeWorkflowModal,
  getAlgorithmBusinessList,
  getWorkflowList,
  getWorkflowView,
  showCreateWorkflow,
  showRoleSpinner,
  updateInputParams,
  workflowDelete,
} from "../../actions/managamentActions";
import CreateWorkflow from "./CreateWorkflow/CreateWorkflow";
import WorkflowMapping from "./CreateWorkflow/WorkflowMapping";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };
const Workflow = () => {
  const [visibleWorkflowList, setVisibleWorkflowList] = useState(false);

  const [showWorkflow, setShowWorkflow] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [showWorkflowMap, setShowWorkflowMap] = useState(false);
  const [openDeleteWorkflowModal, setOpenDeleteWorkflowModal] = useState(false);
  const [editWorkflow, setEditWorkflow] = useState(false);

  const [workflowId, setWorkflowId] = useState("");

  const [currentWorkflowPage, setCurrentWorkflowPage] = useState<number>(1);
  const dispatch = useDispatch();

  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });

  const workflowLists = useSelector((state: AppState) => {
    return state.managementReducerData.workflowList.data;
  });
  const closeWorkflow = useSelector((state: AppState) => {
    return state.managementReducerData.closeWorkflow;
  });

  const closeWorkflowMapping = useSelector((state: AppState) => {
    return state.managementReducerData.closeWorkflowMapping;
  });

  const showCreateWorkflowSuccess = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateWorkflow;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageWorkflow;
  });

  const _onMenuClickSetId = (id: any) => {
    setWorkflowId(id);
  };

  const onWorkflowPageChange = useCallback((pageNo) => {
    setCurrentWorkflowPage(pageNo);
    dispatch(getWorkflowList(pageNo, ""));
  }, []);

  const showModal = useCallback(() => {
    setShowWorkflow((current) => !current);
    dispatch(clearBusinessAlgorithmList());
    if (showWorkflow || !workflowId) {
      dispatch(closeWorkflowModal());
    }
  }, []);
  const closeModal = () => {
    setShowWorkflow(false);
    dispatch(closeWorkflowModal());
    dispatch(clearBusinessAlgorithmList());
    dispatch(getWorkflowList(currentWorkflowPage, ""));
  };
  const showWorkflowMapModal = () => {
    //dispatch(getWorkflowList(1, "", "", 10));
    setShowWorkflowMap((current) => !current);
  };

  const showDeleteWorkflowModal = useCallback(() => {
    setOpenDeleteWorkflowModal((current) => !current);
    setDisabledCreate(false);
  }, []);

  const showEditWorkflowModal = () => {
    setEditWorkflow(true);
    dispatch(getWorkflowView(workflowId));
    dispatch(showCreateWorkflow());
    setShowWorkflow((current) => !current);
    if (showWorkflow) {
      dispatch(closeWorkflowModal());
    }
  };

  const menuPropsfn = (obj: any) => {
    return {
      items: [
        {
          key: "edit",
          text: "Edit",
          onClick: constants.getPermissionsbyItem("algorithm.workflow.edit")
            ? showEditWorkflowModal
            : void 0,
          title: constants.getPermissionsbyItem("algorithm.workflow.edit")
            ? ""
            : constants.permission,
        },
        {
          key: "delete",
          text: "Delete",
          onClick: constants.getPermissionsbyItem("algorithm.workflow.delete")
            ? showDeleteWorkflowModal
            : void 0,
          title: constants.getPermissionsbyItem("algorithm.workflow.delete")
            ? obj.workflow_mapping
              ? "if you want to delete the workflow please remove mappings"
              : ""
            : constants.permission,
          disabled: obj.workflow_mapping,
        },
      ],
    };
  };

  const deleteWorkflowHandle = () => {
    setDisabledCreate(true);
    dispatch(workflowDelete(workflowId));
    setOpenDeleteWorkflowModal((current) => !current);
  };
  const deleteWorkflow = useSelector((state: AppState) => {
    return state.managementReducerData.deleteWorkflow.data;
  });

  useEffect(() => {
    dispatch(getWorkflowList(currentWorkflowPage, ""));
  }, [deleteWorkflow]);
  useEffect(() => {
    if (closeWorkflow || closeWhenErrors || closeWorkflowMapping) {
      dispatch(getWorkflowList(currentWorkflowPage, ""));
    }
  }, [closeWorkflow, closeWhenErrors, closeWorkflowMapping]);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_WORKFLOW_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleWorkflowList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_WORKFLOW_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleWorkflowList(false);
    }
  }, [loaderWithMessage]);

  return (
    <Stack className="deviceManagement Workflow">
      <Stack.Item className="heading">
        <Text>Workflow Management</Text>
      </Stack.Item>
      <Stack className="addDevice" dir="ltr" horizontal>
        <CheckPermission {...constants.SCOPES.CREATE_WORKFLOW}>
          <DefaultButton
            onClick={(e) => {
              showModal();
              setWorkflowId("");
              setEditWorkflow(false);
            }}
            text="Add Workflow"
            iconProps={addIcon}
            allowDisabledFocus
          />
        </CheckPermission>
        <CheckPermission {...constants.SCOPES.CREATE_WORKFLOW_MAPPING}>
          <DefaultButton
            onClick={showWorkflowMapModal}
            text="Workflow Mapping"
            allowDisabledFocus
            className="workflowMap defaultButton"
          />
        </CheckPermission>
      </Stack>
      {showWorkflow && (
        <CreateWorkflow
          isModalOpen={showWorkflow}
          showModal={closeModal}
          workflowId={workflowId}
        ></CreateWorkflow>
      )}
      <WorkflowMapping
        isModalOpen={showWorkflowMap}
        showModal={showWorkflowMapModal}
      ></WorkflowMapping>
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">Available Workflows</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text>Workflow Name</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
              <Text variant="smallPlus">Workflow Type</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Description</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg1-point-5">
              <Text variant="smallPlus">Workflow Mapping</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Created Date</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg1-point-5">
              <Text variant="smallPlus">Created by</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col"></Stack.Item>
          </Stack.Item>
          {visibleWorkflowList &&
            workflowLists?.results.map((device: any) => (
              <Stack.Item key={device?.id} className="ms-Grid-row tableText">
                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text variant="smallPlus" block title={device?.name}>
                    {device?.name}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1-point-5">
                  <Text variant="smallPlus">{device?.type}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text variant="smallPlus" block title={device?.description}>
                    {device?.description?.slice(0, 25)}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1-point-5">
                  {/* <Text variant="smallPlus">{device?.workflow_mapping}</Text> */}
                  {/* <abbr className="ms-bb-0" title={device?.workflow_mapping}>
                  {device?.workflow_mapping}
                </abbr> */}
                  <Text
                    variant="smallPlus"
                    block
                    className="hospitalCardText textNoWrap"
                  >
                    <abbr className="ms-bb-0" title={device?.workflow_mapping}>
                      {device?.workflow_mapping}
                    </abbr>
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">
                    {device?.created_date &&
                      moment(device?.created_date).format("MMM D YYYY HH:mm")}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1-point-5 textEllipses_create">
                  <div>
                    <Text variant="smallPlus" block title={device?.created_by}>
                      {device?.created_by}
                    </Text>
                  </div>
                </Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg">
                  <Text variant="smallPlus">
                    <IconButton
                      className="buttonIcon"
                      menuIconProps={moreIcon}
                      menuProps={menuPropsfn(device)}
                      ariaLabel="New item"
                      onMenuClick={() => _onMenuClickSetId(device?.id)}
                    />
                  </Text>
                </Stack.Item>
              </Stack.Item>
            ))}
        </Stack.Item>
        {visibleWorkflowList &&
        workflowLists?.count > 10 &&
        workflowLists?.page_size ? (
          <Paging
            currentPage={currentWorkflowPage}
            totalPageCount={Math.ceil(workflowLists?.count / 10)}
            handlePageChange={onWorkflowPageChange}
          />
        ) : null}
      </Stack>
      <Modal
        isOpen={closeWorkflow || closeWorkflowMapping}
        containerClassName={"modalStyle"}
      >
        {closeWorkflow && (
          <SuccessMessage
            title={`Workflow ${!editWorkflow ? "Creation" : "Updated"}`}
          />
        )}
        {closeWorkflowMapping && (
          <SuccessMessage title={`Workflow Mapping Creation`} />
        )}
      </Modal>

      {(closeWorkflow || closeWhenErrors || closeWorkflowMapping) &&
        setTimeout(() => {
          setShowWorkflow(false);
          setShowWorkflowMap(false);
          dispatch(updateInputParams([]));
          // dispatch(getWorkflowList(currentWorkflowPage, ""));
          dispatch(showRoleSpinner());
        }, 1000) && <></>}
      <DeleteModal
        isModalOpen={openDeleteWorkflowModal}
        deleteHandle={deleteWorkflowHandle}
        showModal={showDeleteWorkflowModal}
        id={workflowId}
        name="Workflow"
        disabledCreate={disabledCreate}
      ></DeleteModal>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
    </Stack>
  );
};
export default Workflow;
