import { doHttpCall, apiEndPoint } from "../../common/restApi";
import * as allActions from "./actionConstants";

export const doLogin =
  (userDetails: object, context: any) => async (dispatch: any) => {
    const options = {
      method: "POST",
      url: apiEndPoint + "/auth/login",
      data: userDetails,
    };
    const response: any = await doHttpCall(options);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.LOGIN_SUCCESS,
        payload: response.data,
      });
      localStorage.setItem("loggedInProfile", JSON.stringify(response.data));
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const isSwitchRole = localStorage.getItem("dualRole") === "true";
      //    const role = localStorage.getItem("role");
      if (!isSwitchRole || !response.data.switch_account) {
        localStorage.setItem("role", response.data.role);
        localStorage.removeItem("dualRole");
      }

      localStorage.setItem(
        "switch_account",
        JSON.stringify(response.data.switch_account)
      );
      const { history } = context;
      if (response.data.access_token) {
        history("/");
      }
    } else {
      dispatch({
        type: allActions.LOGIN_FAILURE,
        payload: response?.data?.username?.Detail,
      });
    }
  };

export const doChangePassword =
  (userDetails: object) => async (dispatch: any) => {
    const options = {
      method: "POST",
      url: apiEndPoint + "/user/password",
      data: userDetails,
    };
    // debugger;
    const response: any = await doHttpCall(options);
    // const response = {
    //   status:200,
    //   data: {
    //     data: ""
    //   }
    // }
    if (response && (response.status === 200 || response.status === 204)) {
      dispatch({
        type: allActions.CHANGE_PASSWORD_SUCCESS,
        payload: response.data,
      });
      return response;
    } else {
      dispatch({
        type: allActions.CHANGE_PASSWORD_FAILURE,
        payload: response.data,
      });
      return response;
    }
  };

export const cancelChangePassword = (value: any) => (dispatch: any) => {
  dispatch({
    type: allActions.CHANGE_PASSWORD_FAILURE,
    payload: value,
  });
};
export const showLogin = (value: boolean) => (dispatch: any) => {
  dispatch({
    type: allActions.SHOW_LOGIN_PAGE,
    payload: value,
  });
};

export const setLoginAlertFalse = () => (dispatch: any) => {
  dispatch({
    type: allActions.SET_LOGIN_ALERT_FALSE,
  });
};

export const showChangePassword = () => {
  return {
    type: allActions.SHOW_CHANGE_PASSWORD,
  };
};

export const closeErrorPassword = () => {
  return {
    type: allActions.CLOSE_ERROR_PASSWORD,
  };
};
