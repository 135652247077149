import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Devices/Devices.scss";
import {
  getAllPrivileges,
  getPermissionsList,
  updateIsPermissionsFetchedForCreatePrivilege,
  updateRolesPermissionActionData,
} from "../../actions/managementAction";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import CreatePrivilege from "./CreatePrivilege/CreatePrivilege";
import moment from "moment";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import {
  showPrivilegeSpinner,
  deletePrivileges,
  getPriviligeById,
} from "../../actions/managementAction";
import Paging from "../../../../common/paging/Paging";
import { roleReset } from "../../../../ActiveAdminModule/Management/actions/managamentActions";
import PrivilegeCreation from "./CreatePrivilege/PrivilegeCreation";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import {
  DELETE_PRIVILEGE_ERROR,
  GET_PRIVILEGE_BY_ID,
} from "../../actions/actionConstants";
import { GET_PERMISSION_LIST } from "../../../../ActiveAdminModule/Management/actions/actionConstants";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const getMenuItems = () => {
  return [
    {
      key: "edit",
      text: "Edit",
      // scope: [SCOPES.EDIT_PRIVILEGE_GROUP],
      title: constants.getPermissionsbyItem("management.previlage_group.edit")
        ? ""
        : constants.permission,
    },
    {
      key: "delete",
      text: "Delete",
      // scope: [SCOPES.DELETE_PRIVILEGE_GROUP],
      // onClick: constants.getPermissionsbyItem("management.previlage_group.delete") ? showProcedureEditGroupModal: void 0,
      title: constants.getPermissionsbyItem("management.previlage_group.delete")
        ? ""
        : constants.permission,
    },
  ];
};

const Privileges = () => {
  const [showRole, setShowRole] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [privilegeId, setPrivilegeId] = useState<string | any>("1");
  const [isEdit, setIsEdit] = useState<any>(null);
  const [indivisualPrivilege, setIndivisualPrivilege] = useState<object>({});
  const [showPrivilegeDeleteError, setshowPrivilegeDeleteError] =
    useState(false);

  const closeRoles = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.closeRoles;
  });
  const toggleModal = () => {
    localStorage.removeItem("previledgeisId");
    const res: any = dispatch(getPermissionsList());
    if (res && res.status === 200) {
      dispatch(updateIsPermissionsFetchedForCreatePrivilege(false));
    }
    setIsEdit(null);
    setIndivisualPrivilege({});
    setShowRole((current) => !current);
  };

  const dispatch = useDispatch();

  const closeModal = () => {
    // setIsEdit(null);
    setShowRole(false);
    setIndivisualPrivilege({});
    dispatch(roleReset());
    dispatch({
      type: GET_PERMISSION_LIST,
      payload: { data: [] },
    });
    dispatch({
      type: GET_PRIVILEGE_BY_ID,
      payload: { data: null },
    });
  };

  const privilegesList = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.privilegesList;
  });

  const deletePrivilegesData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.deletePrivilege;
  });

  const priviligeById = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.privilegeById;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.loaderWithMessagePrivileges;
  });

  const deletePrivilegeError = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.deletePrivilegeError;
  });

  useEffect(() => {
    let interval: any = null;
    if (deletePrivilegeError) {
      setshowPrivilegeDeleteError(true);
      interval = setTimeout(() => clearPrivilegeError(), 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [deletePrivilegeError]);

  const clearPrivilegeError = () => {
    setshowPrivilegeDeleteError(false);
    dispatch({
      type: DELETE_PRIVILEGE_ERROR,
      payload: null,
    });
  };

  useEffect(() => {
    dispatch(getAllPrivileges());
    clearPrivilegeError();
  }, []);

  // }, [deletePrivilegesData]);

  const onPageChange = useCallback((pageNo) => {
    setCurrentPage(pageNo);
    dispatch(getAllPrivileges(pageNo));
  }, []);

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);

  const deleteHandle = () => {
    setDisabledCreate(true);
    // dispatch(deletePrivileges(privilegeId));
    dispatch(
      deletePrivileges(
        privilegeId,
        privilegesList?.results?.length === 1 ? currentPage - 1 : currentPage
      )
    );
    setOpenDeleteModal((current) => !current);
  };

  const _onMenuClick = useCallback((text: string, privilegeData: any) => {
    localStorage.removeItem("previledgeisId");
    const { uuid, name, comments } = privilegeData;
    if (
      constants.getPermissionsbyItem("management.previlage_group.delete") &&
      text.toLowerCase() === "delete"
    ) {
      // dispatch(deletePrivileges(uuid));
      setPrivilegeId(uuid);
      showDeleteModal();
    }
    if (
      constants.getPermissionsbyItem("management.previlage_group.edit") &&
      text.toLowerCase() === "edit"
    ) {
      localStorage.setItem("previledgeisId", uuid);
      openEditModal(uuid, name, comments);
      // setIsEdit(uuid);
      // await dispatch(getPriviligeById(uuid));
      // setShowRole(true);
      // setIndivisualPrivilege({ name, comments });
    }
  }, []);

  const openEditModal = async (uuid: any, name: any, comments: any) => {
    setIsEdit(uuid);
    await dispatch(getPriviligeById(uuid));
    setShowRole(true);
    setIndivisualPrivilege({ name, comments });
  };

  return (
    <Stack className="deviceManagement">
      <Stack.Item className="heading">
        <Text>Privileges</Text>
      </Stack.Item>
      <Stack.Item className="addDevice">
        <CheckPermission {...constants.SCOPES.CREATE_PRIVILEGE_GROUP}>
          <DefaultButton
            onClick={toggleModal}
            text="Create Privilege Group"
            iconProps={addIcon}
            allowDisabledFocus
          />
        </CheckPermission>
      </Stack.Item>
      {showRole && (
        <CreatePrivilege
          isModalOpen={true}
          closeModal={closeModal}
          isEdit={localStorage.getItem("previledgeisId")}
          data={indivisualPrivilege}
        ></CreatePrivilege>
      )}
      {showPrivilegeDeleteError && (
        <ShowErrorMessageBar
          resetChoice={() => clearPrivilegeError()}
          errorResponse={deletePrivilegeError}
        ></ShowErrorMessageBar>
      )}
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">Available Privilege Groups</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg4">
              <Text>Privilege Groups</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg4">
              <Text variant="smallPlus">Date of Creation</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text variant="smallPlus">Comments</Text>
            </Stack.Item>
          </Stack.Item>
          {privilegesList?.results?.map((privilege: any, index: any) => (
            <Stack.Item key={index} className="ms-Grid-row tableText">
              <Stack.Item className="ms-Grid-col ms-lg4 textEllipses">
                <Text block variant="smallPlus" title={privilege?.name}>
                  {privilege?.name}
                </Text>
              </Stack.Item>

              <Stack.Item className="ms-Grid-col ms-lg4">
                <Text variant="smallPlus">
                  {moment(privilege?.created_date).format("MM/DD/YY HH:mm")}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                <Text block variant="smallPlus" title={privilege?.comments}>
                  {privilege?.comments}
                </Text>
              </Stack.Item>

              <Stack.Item align="end" className="ms-Grid-col ms-lg1">
                <Text variant="smallPlus">
                  <IconButton
                    className="buttonIcon"
                    menuIconProps={moreIcon}
                    menuProps={{
                      items: getMenuItems(),
                      onItemClick: (e: any) =>
                        _onMenuClick(e?.target?.innerText, privilege),
                    }}
                    ariaLabel="New item"
                  />
                </Text>
              </Stack.Item>
            </Stack.Item>
          ))}
        </Stack.Item>
        <DeleteModal
          isModalOpen={openDeleteModal}
          deleteHandle={deleteHandle}
          showModal={showDeleteModal}
          id={privilegeId}
          name="Privilege"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        <LoaderWithMessage
          loaderWithMessage={loaderWithMessage}
        ></LoaderWithMessage>
        {privilegesList?.count > 10 && privilegesList?.page_size ? (
          <Paging
            currentPage={currentPage}
            totalPageCount={Math.ceil(privilegesList?.count / 10)}
            handlePageChange={onPageChange}
          />
        ) : null}
      </Stack>
      <Modal isOpen={closeRoles} containerClassName={"modalStyle"}>
        <SuccessMessage
          title={
            !!localStorage.getItem("previledgeisId")
              ? "Privilege Updation"
              : "Privilege Creation"
          }
        />
      </Modal>
      {useEffect(() => {
        onPageChange(currentPage);
      }, [closeRoles])}
      {closeRoles &&
        setTimeout(() => {
          // dispatch(getAllPrivileges());
          setShowRole(false);

          dispatch(updateRolesPermissionActionData({}));
          dispatch(roleReset());
          setIsEdit(null);
          dispatch({
            type: GET_PRIVILEGE_BY_ID,
            payload: { data: null },
          });
          dispatch(showPrivilegeSpinner());
        }, 2000) && <></>}
    </Stack>
  );
};

export default Privileges;
