export const GET_HOSPITAL_DEVICES_LIST = "GET_HOSPITAL_DEVICES_LIST";
export const GET_HOSPITAL_DEVICE_BY_ID = "GET_HOSPITAL_DEVICE_BY_ID";
export const EDIT_HOSPITAL_DEVICE_BY_ID = "EDIT_HOSPITAL_DEVICE_BY_ID";
export const UPDATE_HOSPITAL_DEVICES_LIST = "UPDATE_HOSPITAL_DEVICES_LIST";
export const GET_PRIVILEGES_LIST = "GET_PRIVILEGES_LIST";
export const GET_PERMISSION_LIST = "GET_PERMISSION_LIST";
export const ROLESPERMISSION_DATA = "ROLESPERMISSION_DATA";
export const GET_PRIVILEGE_BY_ID = "GET_PRIVILEGE_BY_ID";
export const EDIT_PRIVILEGE_BY_ID = "EDIT_PRIVILEGE_BY_ID";
export const CREATE_PRIVILEGES = "CREATE_PRIVILEGES";
export const PRIVILEGE_SPINNER = "PRIVILEGE_SPINNER";
export const DELETE_PRIVILEGE = "DELETE_PRIVILEGE";
export const GET_SPECIALITY_LIST = "GET_SPECIALITY_LIST";
export const GET_ALL_SPECIALITIES_LIST = "GET_ALL_SPECIALITIES_LIST";
export const CANCEL_PREVIOUS_ERROR_MESSAGE = "CANCEL_PREVIOUS_ERROR_MESSAGE";
export const GET_SPECIALITY_BY_ID = "GET_SPECIALITY_BY_ID";
export const GET_SPECIALITY_HOSPITAL_LIST = "GET_SPECIALITY_HOSPITAL_LIST";
export const EDIT_SPECIALITY_BY_ID = "EDIT_SPECIALITY_BY_ID";
export const CREATE_SPECIALITY = "CREATE_SPECIALITY";
export const GET_FACILITIES_LIST = "GET_FACILITIES_LIST";
export const GET_FACILITY_BY_ID = "GET_FACILITY_BY_ID";
export const EDIT_FACILITY_BY_ID = "EDIT_FACILITY_BY_ID";
export const CREATE_FACILITY = "CREATE_FACILITY";
export const ERROR_IN_MANAGEMENT = "ERROR_IN_MANAGEMENT";
export const DELETE_SPECIALITY = "DELETE_SPECIALITY";
export const DELETE_SPECIALITY_ERROR = "DELETE_SPECIALITY_ERROR";
export const DELETE_FACILITY_ERROR = "DELETE_FACILITY_ERROR";
export const DELETE_PRIVILEGE_ERROR = "DELETE_PRIVILEGE_ERROR";
export const DELETE_FACILITY = "DELETE_FACILITY";
export const CREATE_FACILITY_ERROR = "CREATE_FACILITY_ERROR";
export const CANCEL_FACILITY_ERROR = "CANCEL_FACILITY_ERROR";
export const CREATE_SPECIALITY_ERROR = "CREATE_SPECIALITY_ERROR";
export const LOADER = "LOADER";
export const STOPLOADER = "STOPLOADER";
export const CREATE_PRIVILEGE_ERROR = "CREATE_PRIVILEGE_ERROR";
export const CLEAR_PRIVILAGE_ERROR = "CLEAR_PRIVILAGE_ERROR";
export const IS_PERMISSIONS_FETCHED_FOR_EDIT_PRIVILEGE =
  "IS_PERMISSIONS_FETCHED_FOR_EDIT_PRIVILEGE";
export const IS_PERMISSIONS_FETCHED_FOR_CREATE_PRIVILEGE =
  "IS_PERMISSIONS_FETCHED_FOR_CREATE_PRIVILEGE";
