import React, { useState, useEffect, useCallback } from "react";
import {
  Stack,
  DefaultButton,
  Modal,
  Text,
  IIconProps,
  ActionButton,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../rootReducer";
import {
  getDataSets,
  showDatasetSpinner,
  showDatasetLoader,
  cleanDatasets,
  showEditDataSetModel,
  hideEditDataSetModel,
} from "../actions/datasetAction";
import "./DatasetMapping.scss";
import CreateDataSet from "../components/CreateDataSet/CreateDataSet";
import EditDataSet from "../components/EditDataSet/EditDataSet";
import SuccessMessage from "../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import DatasetListItem from "../components/DatasetList/DatasetListItem";
import CheckPermission from "../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../Layout/constants/constants";
import LoaderWithMessage from "../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };

const DatasetMapping = () => {
  const [showRole, setShowRole] = useState(false);
  const [editDataSetHospitalId, setEditDataSetHospitalId] = useState<number>(1);
  const [toggleState, setToggleState] = useState(-1);
  const dispatch = useDispatch();

  const showModal = () => {
    setShowRole(true);
  };

  const closeRoles = useSelector((state: AppState) => {
    return state.developerDataSetReducerData.closeRoles;
  });
  useEffect(() => {
    setShowRole(closeRoles);
  }, [closeRoles]);
  const dataSets = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.dataSets;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.loaderWithMessage;
  });
  if (dataSets?.results) {
    dataSets.results =
      (dataSets?.results && [
        ...dataSets?.results
          .reduce((map: any, obj: any) => map.set(obj.id, obj), new Map())
          .values(),
      ]) ||
      [];
  }

  useEffect(() => {
    dispatch(showDatasetLoader());
    dispatch(getDataSets());
    return () => {
      dispatch(cleanDatasets());
    };
  }, []);

  const dataSetsAlg = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.dataSetsAlg;
  });

  const isEditDataSetModelOpen = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.enableEditDataSetModel;
  });

  const loadMore = () => {
    if (dataSets.next) {
      dispatch(showDatasetLoader());
      dispatch(getDataSets(dataSets.next));
    }
  };

  const handleToggle = (index: number) => {
    if (toggleState === index) {
      setToggleState(-1);
    } else {
      setToggleState(index);
    }
  };

  const handleEditDataSet = (editDataSetHospitalId: any) => {
    setEditDataSetHospitalId(editDataSetHospitalId);
    dispatch(showEditDataSetModel());
  };

  const [visibleDataSetList, setVisibleDataSetList] = useState(false);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_DATASETS" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleDataSetList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_DATASETS" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleDataSetList(false);
    }
  }, [loaderWithMessage]);

  return (
    <Stack className="datasetMapping">
      <Stack.Item className="heading">
        <Text>Algorithm Test Dataset Mapping</Text>
      </Stack.Item>
      <Stack.Item className="addDataSet">
        <CheckPermission {...SCOPES.DEVELOPER_ADD_DATASET_MAPPING}>
          <DefaultButton
            onClick={showModal}
            text="Add New Test Dataset"
            iconProps={addIcon}
            allowDisabledFocus
          />
        </CheckPermission>
      </Stack.Item>
      <CreateDataSet
        isModalOpen={showRole}
        hideModal={() => setShowRole(false)}
      />
      <EditDataSet
        isModalOpen={isEditDataSetModelOpen}
        hideModal={() => dispatch(hideEditDataSetModel())}
        editDataSetHospitalId={editDataSetHospitalId}
      />
      <Stack style={{ marginBottom: "20px" }}>
        <Stack.Item
          className="ms-Grid-col ms-lg3 defaultRow"
          styles={{
            root: {
              padding: 10,
            },
          }}
        >
          <Text className="ms-fontSize-14">Algorithms</Text>
        </Stack.Item>
        <Stack.Item>
          {visibleDataSetList &&
            dataSets?.results?.map((hospital: any, index: any) => {
              return (
                <DatasetListItem
                  key={index}
                  hospital={hospital}
                  onToggle={() => handleToggle(index)}
                  active={toggleState === index}
                  contentData={dataSetsAlg[hospital.id]}
                  onEditDataSet={() => handleEditDataSet(hospital.id)}
                />
              );
            })}
        </Stack.Item>
        <Stack.Item>
          {visibleDataSetList && dataSets.next && (
            <ActionButton
              text="Load More"
              className="btn-c"
              allowDisabledFocus
              onClick={loadMore}
            />
          )}
        </Stack.Item>
      </Stack>
      <Modal isOpen={closeRoles} containerClassName={"modalStyle"}>
        <SuccessMessage
          title="Algorithm Mapping to Testdata is"
          subText="Now, Models are running against to Testdata set. You can see status in Notification section!"
        />
      </Modal>
      {loaderWithMessage?.calledFrom === "GET_DATASETS" && (
        <LoaderWithMessage
          loaderWithMessage={loaderWithMessage}
        ></LoaderWithMessage>
      )}
      {closeRoles &&
        setTimeout(() => {
          setShowRole(false);
          dispatch(showDatasetSpinner());
        }, 5000) && <></>}
      {useEffect(() => {
        dispatch(getDataSets());
      }, [closeRoles])}
    </Stack>
  );
};

export default DatasetMapping;
