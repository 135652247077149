import {
  Stack,
  StackItem,
  Icon,
  Pivot,
  PivotItem,
  TextField,
  Link,
  Text,
  IIconProps,
} from "@fluentui/react";
import videojs from "video.js";
import React, {
  useState,
  useEffect,
  useCallback,
  useDebugValue,
  useMemo,
  useRef,
} from "react";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../rootReducer";
import {
  addVideoComment,
  getVideoComments,
  deleteVideoComment,
  editVideoCommentById,
  addReplyToComment,
} from "../actions/videoPlayerAction";
import moment from "moment";
import { IconButton, IButtonProps } from "@fluentui/react/lib/Button";
import { values } from "idb-keyval";
import { Marker } from "../../common/videoPlayer/RawVideoPlayer";
import "./VideoCommenting.scss";
import * as constants from "../../Layout/constants/constants";
import CheckPermission from "../../common/permissions/rolePermissions";
const convertHMS = (value: any) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours: any = Math.floor(sec / 3600); // get hours
  let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ":" + seconds; // Return is HH : MM : SS
};

const moreIcon: IIconProps = { iconName: "MoreVertical" };
interface VideoCommentingProps {
  videoId: String | number;
  permission: any;
  player: videojs.Player;
  markers: Marker[];
  setMarkersForComment: (start: number, end: number) => void;
  onPause: boolean;
  userProfile?: any;
  doNotShowTitle?: any;
}
const VideoCommenting: React.FC<VideoCommentingProps> = ({
  videoId,
  permission,
  player,
  markers,
  setMarkersForComment,
  onPause,
  userProfile,
  doNotShowTitle,
}) => {
  const dispatch = useDispatch();
  let interval: any = null;
  let currentTime = undefined;
  const [comment, setComment] = useState<string | undefined | null>();
  const [startTime, setStartTime] = useState<string | undefined>();
  const [endTime, setEndTime] = useState<string | undefined>();
  const [selectedStartTime, setSelectedStartTime] = useState<
    string | undefined
  >();
  const [selectedEndTime, setSelectedEndTime] = useState<string | undefined>();
  const [isEdit, setIsEdit] = useState<string | number | null>(null);
  const [isInital, setIsInitial] = useState<boolean>(true);
  const [selectedComment, setSelectedComment] = useState<any>({});
  const [displayReplyInput, setDisplayReplyInput] = useState<boolean>(false);
  const [reply, setReply] = useState<string | undefined>("");
  const [titleShowComment, setTitleShowComment] = useState(
    doNotShowTitle ? false : true
  );

  const replyMenuItems = [
    {
      key: "delete",
      text: "Delete",
      title: !constants.getPermissionsbyItem("vps.comment.delete")
        ? constants.permission
        : "",
      // scope: [SCOPES.SURGEON_COMMENT_DELETE],
    },
  ];
  const menuItems = [
    {
      key: "edit",
      text: "Edit",
      title: !constants.getPermissionsbyItem("vps.comment.edit")
        ? constants.permission
        : "",
      // scope: [SCOPES.SURGEON_COMMENT_EDIT],
    },
    {
      key: "delete",
      text: "Delete",
      title: !constants.getPermissionsbyItem("vps.comment.delete")
        ? constants.permission
        : "",
      // scope: [SCOPES.SURGEON_COMMENT_DELETE],
    },
  ];

  // const [displayReplies, setDisplayReplies] = useState<boolean>(false);
  // const [selectedViewRepliesCommentID, setSelectedViewRepliesCommentID] =
  // useState<string | undefined>("");
  const [selectedViewReplesCommentIDs, setSelectedViewReplesCommentIDs] =
    useState<string[]>([]);
  useEffect(() => {
    if (interval === null) {
      interval = setInterval(() => {
        if (player && player?.currentTime && player?.currentTime()) {
          currentTime = convertHMS(player?.currentTime() || 0);
          const duration = player?.duration();
          if (!selectedStartTime) {
            setStartTime(currentTime);
          }
          if (selectedStartTime) {
            setEndTime(currentTime);
          }
          if (currentTime === duration.toString()) {
            setStartTime(convertHMS(0));
            setEndTime(convertHMS(0));
          }
        }
      });
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
  // const videoComments: any = useSelector((state: AppState) => {
  //   console.log(
  //     "state.videoPlayerReducerData.videoComments",
  //     state.videoPlayerReducerData.videoComments
  //   );
  //   return state.videoPlayerReducerData.videoComments || [];
  // });
  const videoComments: any = useSelector((state: AppState) => {
    const sortComments =
      state &&
      state.videoPlayerReducerData &&
      state.videoPlayerReducerData.videoComments &&
      state.videoPlayerReducerData.videoComments.sort((a: any, b: any) =>
        a.created_date > b.created_date
          ? 1
          : b.created_date > a.created_date
          ? -1
          : 0
      );

    return sortComments || [];
  });
  const totalCommentCount: any = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.totalCommentCount || [];
  });
  const observer = useRef<IntersectionObserver | null>(null);
  const lastCommentRef = useCallback(
    (node) => {
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          videoComments.length < totalCommentCount
        ) {
          dispatch(
            getVideoComments(videoId, undefined, videoComments.length + 10)
          );
        }
      });
      if (node) observer.current.observe(node);
    },
    [videoComments]
  );
  const nextPageUrl = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.nextPageUrl;
  });
  const adddedVideoComment = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.addVideoComment || {};
  });
  const deleteVideoCommentData = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.deleteVideoComment || {};
  });
  const displayName = useSelector((state: AppState) => {
    return state.dashboardReducerData.loginUserName || {};
  });
  const givenName = useSelector((state: AppState) => {
    return state.dashboardReducerData.loginUserProfile.givenName || {};
  });
  const editedVideoCommentData = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.editedVideoComment || {};
  });
  const commentLoader = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.commentLoader;
  });
  const getTimeInSec = (time: string) => {
    const timeArray = time.split(":");
    return timeArray.reduce((prev: number, val: string, index: number) => {
      if (index === 0) {
        return prev + Number(val) * 60;
      }
      return prev + Number(val);
    }, 0);
  };
  useEffect(() => {
    // if (Object.keys(adddedVideoComment).length > 0 && videoId) {
    if (videoId) {
      dispatch(getVideoComments(videoId));
    }
  }, [videoId]);
  const toHHMMSS = function (sec: any) {
    const sec_num = parseInt(sec, 10); // don't forget the second param
    let hours: any = Math.floor(sec_num / 3600);
    let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: any = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const getProperTime = (sec: any) => {
    const sec_num = parseInt(sec, 10);
    let hours: any = Math.floor(sec_num / 3600);
    let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
    let seconds: any = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
    // return `${String("0" + Math.floor(sec / 60)).slice(-2)}:${sec % 60}`;
  };

  const createComment = useCallback(() => {
    const startTime: any = markers ? markers[0].time : 0;
    const endTime: any = markers ? markers[1].time : player?.duration();

    const modifiedComment = comment?.replace(
      /([0-9][0-9]):([0-9][0-9]) - ([0-9][0-9]):([0-9][0-9])/g,
      ""
    );
    if (player && !!player.currentTime && markers.length === 2) {
      if (isEdit) {
        // dispatch(editVideoCommentById(videoId, id, ""));
        // debugger;
        dispatch(
          editVideoCommentById(videoId, isEdit, {
            message: modifiedComment,
            start_time: getProperTime(Math.ceil(parseInt(startTime))),
            end_time: getProperTime(
              endTime ? Math.floor(parseInt(endTime)) : player?.duration()
            ),
          })
        );
        setTimeout(() => {
          dispatch(getVideoComments(videoId));
        }, 2000);
        setIsEdit(null);
      } else {
        dispatch(
          addVideoComment(
            {
              message: modifiedComment,
              start_time: getProperTime(Math.ceil(parseInt(startTime))),
              end_time: getProperTime(
                endTime ? Math.floor(parseInt(endTime)) : player?.duration()
              ),
            },
            videoId
          )
        );
        setTimeout(() => {
          dispatch(
            getVideoComments(videoId, undefined, videoComments.length + 1)
          );
          setIsInitial(true);
        }, 1000);
        setTimeout(() => {
          setScrollToBittom();
        }, 1400);
      }
      setComment(null);
    }
    setComment(undefined);
    // setSelectedStartTime(undefined);
    // setSelectedEndTime(undefined);
  }, [comment, startTime, endTime, videoId, markers, isEdit]);
  const onTimeSelection = (timeType: string) => {
    let comment = "";
    if (timeType === "startTime") {
      setSelectedStartTime(startTime);
    }
    if (timeType === "endTime") {
      setSelectedEndTime(endTime);
      comment = `${selectedStartTime} - ${endTime} :`;
    }
    setComment(comment);
  };
  const goToTime = (ev: any, startTime: string, endTime: string) => {
    setMarkersForComment(getTimeInSec(startTime), getTimeInSec(endTime));
  };
  const onClickReplyToComment = (comment: any) => {
    if (displayReplyInput) {
      onCloseReplySection();
      return;
    }
    setSelectedComment(comment);
    setDisplayReplyInput(true);
  };
  const onCloseReplySection = () => {
    setSelectedComment({});
    setDisplayReplyInput(false);
    setReply("");
  };
  const addCustomClass = () => {
    document.body.classList.add("video-commenting-page-comment-section");
  };
  const removeCustomClass = () => {
    document.body.classList.remove("video-commenting-page-comment-section");
  };
  const saveReply = () => {
    dispatch(
      addReplyToComment(
        {
          message: reply,
          start_time: getProperTime(Math.ceil(markers[0].time)),
          end_time: getProperTime(Math.ceil(markers[1].time)),
          comment_id: selectedComment.id,
        },
        videoId,
        selectedComment.id
      )
    );
    setTimeout(() => {
      dispatch(getVideoComments(videoId));
    }, 1000);
    onCloseReplySection();
  };
  const onClickViewComment = (commentID: any) => {
    // if (displayReplies) {
    //   setDisplayReplies(false);
    //   return;
    // }
    // setDisplayReplies(true);
    // setSelectedViewRepliesCommentID(commentID);
    setSelectedViewReplesCommentIDs((prevIds) => {
      if (prevIds.includes(commentID)) {
        return prevIds.filter((item) => commentID !== item);
      }
      return [...prevIds, commentID];
    });
  };
  // const sortedComments = useMemo(() => {
  //   if (videoComments && Array.isArray(videoComments)) {
  //     return videoComments.sort(
  //       (cmt1: any, cmt2: any) =>
  //         moment(cmt1.created_date).unix() - moment(cmt2.created_date).unix()
  //     );
  //   }
  //   return [];
  // }, [videoComments]);
  const isSurgeon = localStorage.getItem("role")?.toLowerCase() === "surgeon";
  const loggedUserID = localStorage.getItem("userProfileId");
  const _onMenuClick = useCallback(
    (text: string, id: string | number, replayId = null) => {
      if (
        constants.getPermissionsbyItem("vps.comment.delete") &&
        text.toLowerCase() === "delete"
      ) {
        // dispatch(deleteVideoComment(videoId, id));
        dispatch(deleteVideoComment(videoId, replayId ? replayId : id));
        // setTimeout(() => {
        //   dispatch(getVideoComments(videoId));
        // }, 1000);
      } else if (
        constants.getPermissionsbyItem("vps.comment.edit") &&
        (text.toLowerCase() === "edit" || text.toLowerCase() === "reply")
      ) {
        if (replayId) {
          const [{ message, start_time = "00:00:00", end_time = "00:00:00" }] =
            videoComments
              .filter((cmt: any) => {
                if (cmt.id === id) return cmt.reply_message;
              })[0]
              .reply_message.filter(({ id: cmtId }: any) => cmtId === replayId);
          setComment(message || "");
          setMarkersForComment(
            getTimeInSec(start_time),
            getTimeInSec(end_time)
          );
          const commentTextBoxRef = document.querySelector("#commentTextBox");
          if (commentTextBoxRef) {
            setTimeout(() => (commentTextBoxRef as any).focus(), 800);
          }
          setIsEdit(replayId);
        } else {
          const [{ message, start_time = "00:00:00", end_time = "00:00:00" }] =
            videoComments.filter(({ id: cmtId }: any) => cmtId === id);
          setComment(message || "");
          setMarkersForComment(
            getTimeInSec(start_time),
            getTimeInSec(end_time)
          );
          const commentTextBoxRef = document.querySelector("#commentTextBox");
          if (commentTextBoxRef) {
            setTimeout(() => (commentTextBoxRef as any).focus(), 800);
          }
          setIsEdit(id);
        }
      }
    },
    [videoComments]
  );
  useEffect(() => {
    setScrollToBittom();
  }, [videoComments]);
  const setScrollToBittom = () => {
    if (videoComments && videoComments.length > 0 && isInital) {
      const lastCommnetRef = document.querySelector("#lastVideoCommnet");
      if (videoComments.length > 0 && lastCommnetRef) {
        lastCommnetRef.scrollIntoView({ behavior: "smooth" });
      }
      setIsInitial(false);
    }
  };
  return (
    <Stack
      verticalFill
      styles={{ root: { position: "relative" } }}
      className="videoCommentsSection"
    >
      <Pivot
        styles={{
          root: { width: "100%" },
          itemContainer: {
            overflow: "auto",
            maxHeight: titleShowComment ? 456 : 750,
          },
        }}
      >
        <PivotItem headerText={titleShowComment ? "Comments" : ""}>
          {commentLoader && <div className="view-details-loader"></div>}
          <Stack verticalFill>
            <StackItem
              className={
                !titleShowComment
                  ? "scroll-properties-insight-comments"
                  : "scroll-properties-regular-comments"
              }
              align="auto"
              styles={{
                root: {
                  overflow: "auto",
                  height: isSurgeon ? (titleShowComment ? 355 : 542) : "100%",
                },
              }}
            >
              <Stack
                tokens={{ childrenGap: 16, padding: 16 }}
                // styles={{
                //   root: { overflow: "auto" },
                // }}
              >
                {permission
                  ? videoComments.map((comment: any, index: number) => {
                      const newComment = comment?.message.replaceAll(
                        "\n",
                        "<br />"
                      );
                      const name = comment?.created_by_user;
                      const matches = name.match(/\b(\w)/g);
                      const joinChar = matches.join("");
                      const ago = moment(comment.created_date).fromNow();
                      const postedTime = ago.includes("day")
                        ? moment(comment.created_date).format(
                            "MM/DD/YYYY HH:mm"
                          )
                        : ago;
                      const getTimeInMinNSec = (time: string | any) => {
                        if (time) {
                          const timeArr = time.split(":");
                          timeArr.shift();
                          return timeArr.join(":");
                        }
                        return "00:00";
                      };
                      const addPorps =
                        videoComments.length - 1 === index
                          ? {
                              id: "lastVideoCommnet",
                            }
                          : {};
                      return (
                        <div
                          ref={
                            videoComments.length === index + 1
                              ? lastCommentRef
                              : null
                          }
                        >
                          <StackItem>
                            <Stack
                              {...addPorps}
                              horizontal
                              tokens={{ childrenGap: 12 }}
                              styles={{ root: { position: "relative" } }}
                            >
                              <StackItem
                                styles={{ root: { width: 40, height: 40 } }}
                              >
                                {/* <Icon iconName="FollowUser" className="ms-font-xxl" /> */}
                                <StackItem className="imageIcon">
                                  {joinChar}
                                </StackItem>
                              </StackItem>
                              <StackItem style={{ width: 100 + "%" }}>
                                <Stack>
                                  <StackItem>
                                    <span style={{ marginRight: 30 }}>
                                      {comment.created_by_user}
                                    </span>
                                    <span className="ms-font-s">
                                      {postedTime}
                                    </span>
                                  </StackItem>
                                  <StackItem
                                    styles={{
                                      root: { overflowWrap: "anywhere" },
                                    }}
                                  >
                                    <a
                                      href="#"
                                      className="custm-anchor-time-frame"
                                      onClick={(ev) =>
                                        goToTime(
                                          ev,
                                          getTimeInMinNSec(comment.start_time),
                                          getTimeInMinNSec(comment.end_time)
                                        )
                                      }
                                    >{`${getTimeInMinNSec(
                                      comment.start_time
                                    )} - ${getTimeInMinNSec(
                                      comment.end_time
                                    )}  `}</a>
                                    &nbsp;
                                    {/* {comment.message || ""} */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: newComment,
                                      }}
                                    ></div>
                                  </StackItem>
                                  <StackItem>
                                    <StackItem>
                                      {comment.created_by !== loggedUserID &&
                                        localStorage.getItem("role") !==
                                          "HospitalAdmin" && (
                                          <CheckPermission
                                            {...constants.SCOPES
                                              .SURGEON_COMMENT_ADD}
                                          >
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                color: "rgb(1, 177, 255)",
                                              }}
                                              onClick={() =>
                                                onClickReplyToComment(comment)
                                              }
                                            >
                                              {"Reply" || " "} &nbsp;
                                            </span>
                                          </CheckPermission>
                                        )}
                                      <span>
                                        {comment.reply_message.length > 0 && (
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              color: "rgb(1, 177, 255)",
                                            }}
                                            onClick={() =>
                                              onClickViewComment(comment.id)
                                            }
                                          >
                                            {" "}
                                            View {
                                              comment.reply_message.length
                                            }{" "}
                                            {comment.reply_message.length === 1
                                              ? "Reply"
                                              : "Replies"}
                                          </span>
                                        )}
                                      </span>
                                    </StackItem>
                                  </StackItem>
                                  {
                                    // displayReplies &&
                                    //   comment.id === selectedViewRepliesCommentID &&
                                    selectedViewReplesCommentIDs.includes(
                                      comment.id
                                    ) &&
                                      comment.reply_message.map(
                                        (replyItem: any) => {
                                          const name =
                                            replyItem?.created_by_user;
                                          const matches: any =
                                            name.match(/\b(\w)/g);
                                          const joinChar = matches.join("");
                                          return (
                                            <Stack
                                              {...addPorps}
                                              horizontal
                                              tokens={{ childrenGap: 12 }}
                                              styles={{
                                                root: {
                                                  position: "relative",
                                                  justifyContent:
                                                    "space-between",
                                                },
                                              }}
                                            >
                                              <StackItem
                                                styles={{
                                                  root: {
                                                    width: 40,
                                                    height: 40,
                                                  },
                                                }}
                                              >
                                                <StackItem className="imageIcon">
                                                  {joinChar}
                                                  {/* {replyItem.created_by_user.split(
                                                " "
                                              ).length > 0
                                                ? replyItem.created_by_user.split(
                                                    " "
                                                  )[0][0]
                                                : ""}
                                              {replyItem.created_by_user.split(
                                                " "
                                              ).length > 1
                                                ? replyItem.created_by_user.split(
                                                    " "
                                                  )[1][0]
                                                : ""} */}
                                                </StackItem>
                                              </StackItem>
                                              <StackItem
                                                styles={{
                                                  root: {
                                                    padding: "5px",
                                                    flex: 1,
                                                  },
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginRight: 30,
                                                    width: "100%",
                                                  }}
                                                >
                                                  {replyItem.created_by_user}
                                                  <span
                                                    className="ms-font-s"
                                                    style={{
                                                      marginLeft: 5,
                                                    }}
                                                  >
                                                    {moment(
                                                      replyItem.created_date
                                                    )
                                                      .fromNow()
                                                      .includes("day")
                                                      ? moment(
                                                          replyItem.created_date
                                                        ).format(
                                                          "MM/DD/YYYY HH:mm"
                                                        )
                                                      : moment(
                                                          replyItem.created_date
                                                        ).fromNow()}
                                                  </span>
                                                </div>
                                                <div
                                                  style={{
                                                    overflowWrap: "anywhere",
                                                  }}
                                                >
                                                  {replyItem.message || ""}
                                                </div>
                                              </StackItem>
                                              <Text variant="smallPlus">
                                                {(replyItem.created_by_user ===
                                                  userProfile.displayName ||
                                                  replyItem.created_by_user ===
                                                    userProfile.givenName) && (
                                                  <IconButton
                                                    className="buttonIcon test-123"
                                                    menuIconProps={moreIcon}
                                                    menuProps={{
                                                      items:
                                                        replyItem.created_by_user ===
                                                          userProfile.displayName ||
                                                        replyItem.created_by_user ===
                                                          userProfile.givenName
                                                          ? menuItems
                                                          : replyMenuItems,
                                                      onItemClick: (e: any) => {
                                                        if (
                                                          e.target.innerText !==
                                                          "Reply"
                                                        ) {
                                                          _onMenuClick(
                                                            e?.target
                                                              ?.innerText,
                                                            comment.id,
                                                            replyItem?.id
                                                          );
                                                        }
                                                      },
                                                    }}
                                                    onAfterMenuDismiss={
                                                      removeCustomClass
                                                    }
                                                    onMenuClick={addCustomClass}
                                                    ariaLabel="New item"
                                                  />
                                                )}
                                              </Text>
                                            </Stack>
                                          );
                                        }
                                      )
                                  }
                                  {displayReplyInput &&
                                    selectedComment.id === comment.id && (
                                      <StackItem
                                        align="end"
                                        styles={{
                                          root: { width: "100%" },
                                        }}
                                      >
                                        <Stack>
                                          <StackItem
                                            styles={{
                                              root: {
                                                background: "#262626",
                                                padding: 12,
                                                position: "relative",
                                              },
                                            }}
                                          >
                                            <TextField
                                              value={reply || ""}
                                              underlined
                                              borderless
                                              multiline
                                              onKeyPress={(e) => {
                                                if (e.key === "Enter")
                                                  saveReply();
                                              }}
                                              resizable={false}
                                              id="replyTextBox"
                                              onChange={(
                                                _: any,
                                                newVal?: string
                                              ) =>
                                                setReply(newVal?.slice(0, 300))
                                              }
                                              styles={{
                                                root: {
                                                  marginBottom: 16,
                                                  cursor: "text",
                                                },
                                              }}
                                              placeholder="Add Reply"
                                            />
                                            <Icon
                                              onClick={() => saveReply()}
                                              styles={{
                                                root: {
                                                  color: "#0fb3ff",
                                                  cursor: "pointer",
                                                  position: "absolute",
                                                  bottom: 32,
                                                  right: 18,
                                                },
                                              }}
                                              iconName="Send"
                                              className="ms-font-xl"
                                            />
                                            <span
                                              style={{
                                                position: "absolute",
                                                right: 12,
                                                bottom: 10,
                                                color: "#6A6868",
                                              }}
                                            >{`${
                                              comment?.length || 0
                                            }/300`}</span>
                                          </StackItem>
                                        </Stack>
                                      </StackItem>
                                    )}
                                </Stack>
                              </StackItem>

                              {isSurgeon && (
                                <StackItem
                                  styles={{
                                    root: { position: "absolute", right: 0 },
                                  }}
                                >
                                  <Text variant="smallPlus">
                                    {userProfile &&
                                      comment.created_by ===
                                        userProfile.userId && (
                                        <IconButton
                                          className="buttonIcon test-234"
                                          menuIconProps={moreIcon}
                                          menuProps={{
                                            items:
                                              userProfile &&
                                              comment.created_by ===
                                                userProfile.userId
                                                ? menuItems
                                                : replyMenuItems,
                                            onItemClick: (e: any) => {
                                              if (
                                                e.target.innerText !== "Reply"
                                              ) {
                                                _onMenuClick(
                                                  e?.target?.innerText,
                                                  comment?.id
                                                );
                                              }
                                            },
                                          }}
                                          onAfterMenuDismiss={removeCustomClass}
                                          onMenuClick={addCustomClass}
                                          ariaLabel="New item"
                                        />
                                      )}
                                  </Text>
                                </StackItem>
                              )}
                            </Stack>
                          </StackItem>
                        </div>
                      );
                    })
                  : "You do not have permission"}
              </Stack>
            </StackItem>
            {isSurgeon && (
              <StackItem
                align="end"
                styles={{
                  root: { width: "100%" },
                }}
              >
                <Stack>
                  <StackItem
                    styles={{
                      root: {
                        background: "#262626",
                        padding: 12,
                        position: "relative",
                      },
                    }}
                  >
                    <CheckPermission {...constants.SCOPES.SURGEON_COMMENT_ADD}>
                      <TextField
                        value={comment || ""}
                        underlined
                        borderless
                        multiline
                        // onKeyPress={(e) => {
                        //   if (e.key === "Enter") createComment();
                        // }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            if (e.shiftKey) {
                            } else {
                              createComment();
                            }
                          }
                        }}
                        resizable={false}
                        onFocus={(e: any) =>
                          e.currentTarget?.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                        id="commentTextBox"
                        onChange={(_: any, newVal?: string) =>
                          setComment(newVal?.slice(0, 300))
                        }
                        styles={{ root: { marginBottom: 16, cursor: "text" } }}
                        placeholder="Add Comments"
                      />
                    </CheckPermission>
                    <Icon
                      onClick={() => createComment()}
                      styles={{
                        root: {
                          color: "#0fb3ff",
                          cursor: "pointer",
                          position: "absolute",
                          bottom: 32,
                          right: 18,
                        },
                      }}
                      iconName="Send"
                      className="ms-font-xl"
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 12,
                        bottom: 10,
                        color: "#6A6868",
                      }}
                    >{`${comment?.length || 0}/300`}</span>
                  </StackItem>
                  {/* <StackItem
                    styles={{
                      root: { background: "#292929", height: 44, padding: 12 },
                    }}
                  >
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                      <StackItem styles={{ root: {} }}>
                        <Label
                          className="timeinterval-font"
                          styles={{ root: { width: 108, margin: 0 } }}
                        >
                          Video Clip Duration Selector
                        </Label>
                      </StackItem>
                      <StackItem>
                        <MaskedTextField
                          className="timeinterval-font"
                          label="Start Time"
                          mask="**:**"
                          maskFormat={{ "*": /[0-9]/ }}
                          maskChar="0"
                          value={
                            selectedStartTime ? selectedStartTime : startTime
                          }
                          onChange={(_: any, newVal?: string) =>
                            setStartTime(newVal)
                          }
                          iconProps={{
                            iconName: "LocationDot",
                            onClick: () => {
                              onTimeSelection("startTime");
                            },
                            styles: {
                              root: {
                                pointerEvents: "auto",
                                cursor: "pointer",
                                color: "#a90000",
                                top: 4,
                                right: 4,
                                fontSize: 25,
                              },
                            },
                          }}
                        />
                      </StackItem>
                      <StackItem>
                        <MaskedTextField
                          className="timeinterval-font"
                          label="End Time"
                          mask="**:**"
                          maskFormat={{ "*": /[0-9]/ }}
                          maskChar="0"
                          value={selectedEndTime ? selectedEndTime : endTime}
                          onChange={(_: any, newVal?: string) =>
                            setEndTime(newVal)
                          }
                          iconProps={{
                            iconName: "LocationDot",
                            onClick: () => {
                              onTimeSelection("endTime");
                            },
                            styles: {
                              root: {
                                pointerEvents: "auto",
                                cursor: "pointer",
                                color: "#7e7e81",
                                top: 4,
                                right: 4,
                                fontSize: 25,
                              },
                            },
                          }}
                        />
                      </StackItem>
                    </Stack>
                  </StackItem> */}
                </Stack>
              </StackItem>
            )}
          </Stack>
        </PivotItem>
        {/* <PivotItem headerText="Insights">
          <Stack
            horizontal
            wrap
            verticalFill
            tokens={{ childrenGap: 10, padding: 10 }}
          >
            Insights
          </Stack>
        </PivotItem> */}
      </Pivot>
    </Stack>
  );
};
export default VideoCommenting;
