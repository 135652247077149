import React, { FormEvent } from "react";
import {
  Stack,
  TextField,
  Text,
  Label,
  StackItem,
  PrimaryButton,
  Dropdown,
  DefaultButton,
  IDropdownOption,
} from "@fluentui/react";
import ToggleCard from "../../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CreateHospitalIcon } from "../../../../assets/Home/create_hospital.svg";
import { fonts, ThemeColorPalette } from "../../../../../theme";
import Select from "react-select";
import { useState, useCallback, useEffect } from "react";
import { AppState } from "../../../../../rootReducer";
import {
  algListWithPagination,
  getAlgList,
} from "../../../../DatasetMapping/actions/datasetAction";
import {
  getModelWithAlgId,
  getModelVersionsByAlg,
  eraseContainerError,
} from "../../../actions/managementAction";
import { debounce } from "lodash";
import ShowErrorMessageBar from "../../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";

const options = [
  { value: "CLIENT", label: "CLIENT" },
  { value: "ACTIVSURGICAL", label: "ACTIVSURGICAL" },
];

const ContainerCreation: React.FC<any> = ({
  updateData,
  data,
  errors: formError,
  newOrEdit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [algName, setAlgName] = useState<any | null>(null);
  const [mdlName, setMdlName] = useState<any | null>(null);
  const [mdlVersion, setMdlVersion] = useState<any | null>(null);
  const [description, setDesc] = useState<any | string>("");
  const [dockerImg, setDockerImg] = useState<any | string>("");
  const [selectedAlg, setSelectedAlg] = useState<any>(null);
  const [selectedModel, setSelectedModel] = useState<any>(null);
  const [selectedVersion, setSelectedVersion] = useState<any>(null);
  const [isDeveloper, setIsDeveloper] = useState(true);

  const dispatch = useDispatch();

  const algList = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.algorithmList?.data;
  });

  const models = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.modelsForSelectedAlg?.data;
  });

  const versions = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.versionsForSelectedModel;
  });

  const createContainerError = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.createContainerError;
  });

  const algListCount = algList?.count;

  useEffect(() => {
    dispatch(eraseContainerError());
    if (newOrEdit === "new") {
      dispatch(algListWithPagination());
    } else {
      const {
        algorithm,
        algorithm_name,
        description,
        is_developer,
        model_name,
        model_version,
        name,
        tag,
      } = data;
      setSelectedAlg({ label: algorithm_name, value: algorithm });
      setSelectedModel({ label: model_name, value: model_name });
      setSelectedVersion({ label: model_version, value: model_version });
      setDockerImg(`${name}:${tag}`);
      setDesc(description);
      setIsDeveloper(is_developer);
      // setIsDeveloper(is_developer);
    }
  }, []);

  const algUniqueList =
    algList &&
    algList?.results &&
    algList?.results?.filter(
      (ele: any, ind: any) =>
        ind === algList?.results?.findIndex((elem: any) => elem.id === ele.id)
    );

  const algOptions =
    algUniqueList &&
    algUniqueList?.map((alg: any) => ({
      label: alg?.name,
      value: alg?.id,
    }));

  const mdlOptions = models?.results?.map((mdl: any) => ({
    label: mdl?.name,
    value: mdl?.id,
  }));
  const modelNamesArr = mdlOptions?.map((item: any) => item.label);
  const uniqueModelOptions = mdlOptions?.filter(
    (item: any, index: number) => !modelNamesArr.includes(item.label, index + 1)
  );

  const mdlVersionOptions = versions?.map((mdl: any) => ({
    label: mdl?.version,
    value: mdl?.id,
  }));

  const algInputHandler = useCallback(
    debounce((evt: any) => {
      dispatch(algListWithPagination("", evt));
    }, 2000),
    []
  );
  const algInputChangeHandler = (evt: any) => {
    const value = evt;
    if (value.length >= 3) algInputHandler(value);
  };

  // const handleScrollAlg = (evt: any) => {
  //   if (algList?.next) {
  //     dispatch(algListWithPagination(algList.next));
  //   }
  // };
  const handleScrollAlg = useCallback(
    debounce((evt: any) => {
      dispatch(algListWithPagination(evt));
    }, 1000),
    []
  );

  const modelInputHandler = useCallback(
    debounce((evt: any) => {
      if (data.algorithm) {
        dispatch(getModelWithAlgId(data.algorithm, evt));
      }
    }, 2000),
    []
  );
  const modelInputChangeHandler = (evt: any) => {
    const value = evt?.target.value;
    modelInputHandler(value);
  };

  const handleScrollModel = (evt: any) => {
    if (models?.next) {
      dispatch(getModelWithAlgId(data.algorithm, "", models.next));
    }
  };

  const resetChoice = useCallback(() => {
    dispatch(eraseContainerError());
  }, []);

  useEffect(() => {
    localStorage.setItem("nextAlgorithamUrl", algList?.next);
  }, [algList?.next]);

  const trackScrolling = () => {
    handleScrollAlg(localStorage.getItem("nextAlgorithamUrl"));
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("nextAlgorithamUrl");
    };
  }, []);

  return (
    <form id="surgeonForm" className="h-100">
      {createContainerError && (
        <ShowErrorMessageBar
          resetChoice={resetChoice}
          errorResponse={createContainerError}
        ></ShowErrorMessageBar>
      )}
      {createContainerError &&
        setTimeout(() => {
          resetChoice();
        }, 3000) && <></>}
      <ToggleCard heading="" index="" removeFormFields="">
        <Stack
          className="InputSection ms-Grid-row"
          style={{ overflow: "auto", height: "calc(100vh - 200px)" }}
        >
          <Stack>
            <Stack.Item className="specialityForm">
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Stack.Item className="label">
                    <Text>Algorithm Name*</Text>
                  </Stack.Item>
                  <Controller
                    control={control}
                    name="algorithmVersion[0].algorithm_id"
                    shouldUnregister
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => {
                      return (
                        <Select
                          classNamePrefix="addl-class"
                          isClearable
                          options={algOptions}
                          value={selectedAlg}
                          isDisabled={newOrEdit === "edit"}
                          onInputChange={(e) => {
                            algInputChangeHandler(e);
                          }}
                          onMenuClose={() => {
                            if (algOptions.length === 0) {
                              dispatch(algListWithPagination());
                            }
                          }}
                          onMenuOpen={() => {
                            function getNextPage() {
                              const da = document.getElementsByClassName(
                                "addl-class__menu-list"
                              )[0];
                              da &&
                                da.addEventListener("scroll", (e: any) => {
                                  const bottom =
                                    Math.round(e.target.scrollHeight) -
                                      Math.round(e.target.scrollTop) ===
                                    e.target.clientHeight;
                                  if (bottom) {
                                    // console.log("Reached bottom");
                                    trackScrolling();
                                  }
                                });
                            }
                            setTimeout(() => {
                              getNextPage();
                            }, 1000);
                          }}
                          onMenuScrollToBottom={(e) =>
                            handleScrollAlg(algList?.next)
                          }
                          onChange={(val: any) => {
                            data.algorithm = val?.value as any;
                            data.model_name = "";
                            updateData({ ...data });
                            setSelectedModel(null);
                            setSelectedAlg(val);
                            formError.algorithm = "";
                            if (val?.value) {
                              dispatch(getModelWithAlgId(val?.value));
                            }
                            if (!val?.value) {
                              dispatch(algListWithPagination());
                            }
                          }}
                        />
                      );
                    }}
                  />
                  <Label className="errorMessage">{formError?.algorithm}</Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Stack.Item className="label">
                    <Text>Model Name</Text>
                  </Stack.Item>
                  <Controller
                    control={control}
                    name="algorithmVersion[0].algorithm_id"
                    shouldUnregister
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => {
                      return (
                        <Select
                          classNamePrefix="addl-class"
                          isClearable
                          isDisabled={newOrEdit === "edit" || !selectedAlg}
                          options={uniqueModelOptions}
                          value={selectedModel}
                          onKeyDown={(e) => modelInputChangeHandler(e)}
                          onMenuScrollToBottom={(e) => handleScrollModel(e)}
                          onChange={(val: any) => {
                            data.model_name = val?.label as string;
                            data.model_version = null;
                            setSelectedVersion(null);
                            setSelectedModel(val);
                            updateData({ ...data });
                            if (val?.label) {
                              dispatch(
                                getModelVersionsByAlg(data.algorithm, val.label)
                              );
                            }
                          }}
                        />
                      );
                    }}
                  />
                  <Label className="errorMessage">{formError?.tag}</Label>
                </StackItem>
              </Stack>
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Text>Description</Text>
                  <TextField
                    onChange={(e: any) => {
                      data.description = e?.target?.value;
                      updateData({ ...data });

                      setDesc(e?.target?.value);
                    }}
                    value={description}
                  />
                  <Label className="errorMessage">
                    {formError?.description}
                  </Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Stack.Item className="label">
                    <Text>Model Version</Text>
                  </Stack.Item>
                  <Controller
                    control={control}
                    name="algorithmVersion[0].algorithm_id"
                    shouldUnregister
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => {
                      return (
                        <Select
                          classNamePrefix="addl-class"
                          isClearable
                          isDisabled={
                            newOrEdit === "edit" ||
                            !selectedAlg ||
                            !selectedModel
                          }
                          options={mdlVersionOptions}
                          value={selectedVersion}
                          onChange={(val: any) => {
                            data.model_version = val?.label as string;
                            setSelectedVersion(val);
                            updateData({ ...data });
                          }}
                        />
                      );
                    }}
                  />
                  <Label className="errorMessage">
                    {formError?.model_version}
                  </Label>
                </StackItem>
              </Stack>
              <Stack
                horizontal
                tokens={{ childrenGap: 50 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Text>Docker Image*</Text>
                  <TextField
                    onChange={(e: any) => {
                      data.name = e?.target?.value?.split(":")[0];
                      data.tag = e?.target?.value?.split(":")[1];
                      updateData({ ...data });
                      formError.name = "";
                      setDockerImg(e?.target?.value);
                    }}
                    value={dockerImg}
                  />
                  <Label className="errorMessage">{formError?.name}</Label>
                </StackItem>
              </Stack>
              <Stack.Item className="ms-Grid" dir="ltr">
                <Stack.Item className="ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg7 ">
                      <Stack
                        horizontal
                        // verticalAlign="center"
                        className="addFacility"
                      >
                        <Text
                          className="addFacilityHeading"
                          styles={{ root: { padding: "0px 10px 0px 14px" } }}
                        >
                          Is Developer*
                        </Text>
                        <Controller
                          control={control}
                          defaultValue={true}
                          name="show_facility"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                          }) => (
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={isDeveloper}
                                // disabled={newOrEdit === "edit"}
                                onChange={(e) => {
                                  data.is_developer = e?.target?.checked;
                                  updateData({ ...data });
                                  setIsDeveloper((current) => !current);
                                }}
                              />
                              <div
                                className="slider"
                                style={{
                                  cursor:
                                    newOrEdit !== "edit"
                                      ? "pointer"
                                      : "allowed",
                                }}
                              ></div>
                            </label>
                          )}
                        />
                      </Stack>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack>
        </Stack>
      </ToggleCard>
    </form>
  );
};

export default ContainerCreation;
