import {
  Stack,
  Image,
  Text,
  IconButton,
  IIconProps,
  IContextualMenuProps,
  FontIcon,
} from "@fluentui/react";
import React, { useCallback, useState } from "react";
import "./Video.scss";
import thumbnail from "../../../../assets/thumbnail.png";
import playImage from "../../../../assets/Home/play_icon.svg";
import { ThemeColorPalette } from "../../../../theme";
import { withRouter } from "../../../../withRouter";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { deleteVideo } from "../../actions/homeAction";
import { AppState } from "../../../../rootReducer";
import { getVideoDetails } from "../../../../VideoPlayer/actions/videoPlayerAction";
import ViewEditVideoDetailsPanel from "../../../../VideoPlayer/components/ViewEditVideoDetailsPanel";
import { FETCH_VIDEO_DETAILS } from "../../../../VideoPlayer/actions/actionConstants";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";

const addIcon: IIconProps = { iconName: "MoreVertical" };

const Videos = ({
  searchValue,
  videoCurrentPage,
  sortVideoValue,
  video,
  history,
  isMenuEnabled = true,
}: {
  searchValue: any;
  videoCurrentPage: any;
  sortVideoValue: any;
  video: any;
  history: any;
  isMenuEnabled: boolean;
}) => {
  const now = moment();
  // const days = now.diff(video.uploaded_date, "days");
  // const uploadedDate = video.uploaded_date;
  const date = video.uploaded_date
    ? moment(video?.uploaded_date).format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY");
  const dispatch = useDispatch();

  const [detailsClicked, setDetailsClicked] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [videoId, setVideoId] = useState<string | any>("1");
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [selectedVideoDetails, setSelectedVideoDetails] = useState<any>();
  const proceduresData = useSelector((state: AppState) => {
    return state.dashboardReducerData?.procedureDetails;
  });

  const userData = useSelector((state: AppState) => {
    return state.dashboardReducerData?.userDetails;
  });

  const userProfile = useSelector((state: AppState) => {
    return state.dashboardReducerData?.loginUserProfile;
  });

  const videoData = useSelector((state: AppState) => {
    return state.videoPlayerReducerData?.videoData;
  });
  const videoMenuItems =
    localStorage.getItem("role") === "Developer"
      ? [
          {
            key: "details",
            text: "Details",
          },
        ]
      : [
          {
            key: "details",
            text: "Details",
          },
          {
            key: "delete",
            title: constants.getPermissionsbyItem("vps.video.delete")
              ? ""
              : constants.permission,
            text: "Delete",
          },
        ];
  const closePanel = () => {
    setDetailsClicked(false);
  };

  const displayDetails =
    (videoData &&
      userData.results &&
      userData.results
        .map((item: any) => {
          if (item.id === videoData.primary_surgeon)
            return {
              primarySurgeon: item.displayName,
              uploadedDate: videoData.uploaded_date,
              notes: videoData.notes,
            };
          return null;
        })
        .filter((data: any) => data && data)) ||
    [];

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);
  const deleteHandle = () => {
    setDisabledCreate(true);
    dispatch(
      deleteVideo(videoId, searchValue, videoCurrentPage, sortVideoValue)
    );
    setOpenDeleteModal((current) => !current);
  };
  const _onMenuClick = useCallback(
    (text: string, uuid: string | number, videoDetails: any) => {
      if (
        constants.getPermissionsbyItem("vps.video.delete") &&
        text.toLowerCase() === "delete"
      ) {
        // dispatch(deleteVideo(uuid));
        setVideoId(uuid);
        showDeleteModal();
        setDeleteClicked(true);
      }

      if (text.toLowerCase() === "details") {
        setSelectedVideoDetails(videoDetails);
        setDetailsClicked(true);
      }
      if (text.toLowerCase() === "play") {
        if (window.location.href.includes("video")) {
          dispatch(
            getVideoDetails(
              uuid,
              { uploadedByUser: video.is_uploaded_by_user },
              userProfile.role_name != "Developer"
                ? video?.primary_surgeon
                : "",
              "other"
            )
          );
        } else {
          history(
            "/video/" +
              videoDetails?.id +
              "/" +
              (userProfile.role_name != "Developer"
                ? videoDetails?.primary_surgeon
                : ""),
            {
              state: {
                showDoctorVideos: true,
                is_uploaded_by_user: videoDetails.is_uploaded_by_user,
              },
            }
          );
        }
      }
    },
    [detailsClicked, deleteClicked]
  );
  return (
    <Stack>
      <Stack.Item className="videoCard">
        <Stack.Item>
          <Stack.Item className="videoCardImageSection">
            {video.thumbnail ? (
              <Image
                src={"data:image/png;base64," + video.thumbnail}
                width="100%"
                height="100px"
                style={{ objectFit: "contain" }}
              ></Image>
            ) : (
              <Image
                src={thumbnail}
                width="100%"
                height="100px"
                style={{ objectFit: "contain" }}
              ></Image>
            )}
            {userProfile?.role_name === "HospitalAdmin" ? (
              <CheckPermission {...constants.SCOPES.PLAY_VIDEO}>
                <Image
                  src={playImage}
                  className="playIcon"
                  width="50px"
                  height="50px"
                  onClick={(evt: React.MouseEvent) => {
                    _onMenuClick("play", video?.id, video);
                    // history("/video/" + video?.id, {
                    //   state: {
                    //     showDoctorVideos: true,
                    //     is_uploaded_by_user: video.is_uploaded_by_user,
                    //     location:'other'
                    //   },
                    // });
                    evt.preventDefault();
                  }}
                ></Image>
              </CheckPermission>
            ) : (
              <Image
                src={playImage}
                className="playIcon"
                width="50px"
                height="50px"
                onClick={(evt: React.MouseEvent) => {
                  _onMenuClick("play", video?.id, video);
                  // history("/video/" + video?.id, {
                  //   state: {
                  //     showDoctorVideos: true,
                  //     is_uploaded_by_user: video.is_uploaded_by_user,
                  //     location:'other'
                  //   },
                  // });
                  evt.preventDefault();
                }}
              ></Image>
            )}
            <Text variant="small" className="duration">
              {video && video.duration ? video.duration.slice(0, 8) : ""}
            </Text>
          </Stack.Item>

          <Stack.Item className="videoCardTextSection">
            <Stack horizontal horizontalAlign="space-between" dir="ltr">
              <Text
                block
                title={video?.procedure_name}
                className="textEllipses_procedures"
              >
                {video ? video?.procedure_name?.slice(0, 45) : "lorem ipsum"}
              </Text>
              {isMenuEnabled && (
                <IconButton
                  menuIconProps={addIcon}
                  menuProps={{
                    items: videoMenuItems,
                    onItemClick: (e: any) =>
                      _onMenuClick(e?.target?.innerText, video?.id, video),
                  }}
                  ariaLabel="New item"
                />
              )}
            </Stack>
            <Stack className="videoCardFooter">
              <Text block variant="small">
                By {video ? video?.primary_surgeon_name : ""}
              </Text>
              <Text block variant="small">
                {video ? video?.speciality : ""}
              </Text>
              <Text block variant="xSmall">
                {/* {days > 1 ? days + " days ago" : days + " day ago"}{" "} */}
                {date}
              </Text>
            </Stack>
          </Stack.Item>
        </Stack.Item>
        {detailsClicked && (
          <ViewEditVideoDetailsPanel
            displayDetails={displayDetails[0]}
            videoData={selectedVideoDetails ? selectedVideoDetails : videoData}
            userProfile={userProfile}
            userData={userData}
            proceduresData={proceduresData}
            getVideoDetails={videoData}
            match={{ id: video?.id }}
            openPanel={true}
            onPanelClose={closePanel}
            greenLoader={true}
          />
        )}
      </Stack.Item>
      <DeleteModal
        disabledCreate={disabledCreate}
        isModalOpen={openDeleteModal}
        deleteHandle={deleteHandle}
        showModal={showDeleteModal}
        id={videoId}
        name="Video"
      ></DeleteModal>
    </Stack>
  );
};
export default withRouter(Videos);
