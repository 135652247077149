import * as allActions from "./actionConstants";
import { FileUploadWorker } from "../../MainWrapper/MainWrapper";

export const updateUploadingVideosList = (data: object) => (dispatch: any) => {
  dispatch({
    type: allActions.UPDATE_UPLOADING_VIDEOS_LIST,
    payload: data,
  });
};

export const clearUploadingVideosMetada = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_UPLOAD_VIDEOS_METADATA,
  });
};

export const setSelectedUploadingVideosList =
  (data: any) => (dispatch: any) => {
    dispatch({
      type: allActions.SET_SELECTED_UPLOADING_VIDEOS_LIST,
      payload: data,
    });
  };

export const updateSingleSelectData = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.SINGLE_SELECT_OPTION,
    payload: data,
  });
};

export const updateSingleMultiData = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.MULTI_SELECT_OPTION,
    payload: data,
  });
};

export const deleteSelectedItem = (data: any) => (dispatch: any) => {
  FileUploadWorker &&
    FileUploadWorker.terminate &&
    FileUploadWorker.terminate();
  dispatch({
    type: allActions.DELETE_UPLOAD_ITEM,
    payload: data,
  });
};

export const uploadMetaDataAction = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.UPLOAD_METADATA_ACTION,
    payload: data,
  });
};

export const uploadMultiMetaDataAction = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.UPLOAD_METADATA_MULTI_ACTION,
    payload: data,
  });
};

export const handleMultiDataSave = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.HANDLE_MULTI_DATA_SAVE,
    payload: data,
  });
};

export const updatePatientConsentForm = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.UPDATE_PATIENT_CONSENT_FORM,
    payload: data,
  });
};

export const updateUploadVideosListAfterUploadButtonClick =
  (id: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.UPDATE_UPLOAD_VIDEOS_LIST_AFTER_UPLOAD_BUTTON_CLICK,
      payload: id,
    });
  };
