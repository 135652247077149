export const FETCH_VIDEOS = "FETCH_VIDEOS";
export const SET_SELECTED_PROCEDURE_VIDEO = "SET_SELECTED_PROCEDURE_VIDEO";
export const SET_SELECTED_VIDEO_UNFAVOURITE = "SET_SELECTED_VIDEO_UNFAVOURITE";
export const EDIT_VIDEO_DETAILS = "EDIT_VIDEO_DETAILS";

export const RESET_EDITED_DATA_ON_CANCEL_PROCEDURES =
  "RESET_EDITED_DATA_ON_CANCEL_PROCEDURES";

export const SHOW_SPINNER = "SHOW_SPINNER";
export const CLEAR_PROCEDURES_DATA_WHEN_UNMOUNT =
  "CLEAR_PROCEDURES_DATA_WHEN_UNMOUNT";
export const GET_HOSPITAL_AVG = "GET_HOSPITAL_AVG";
export const SHOW_SPINNER_VIDEO = "SHOW_SPINNER_VIDEO";
export const DELETE_VIDEO_FROM_PROCEDURE = "DELETE_VIDEO_FROM_PROCEDURE";
