import { DefaultButton } from "office-ui-fabric-react";
import React from "react";

export function AddToFavoritesButton({
  setSelectedVideoUnfavourite,
  data,
}: any) {
  const [favorite, setFavorite] = React.useState(data.favorite);
  return (
    <DefaultButton
      text="Add to Favorites"
      iconProps={{
        iconName: favorite ? "HeartFill" : "Heart",
      }}
      allowDisabledFocus
      onClick={() => {
        setFavorite(!favorite);
        setSelectedVideoUnfavourite({ ...data, favorite: favorite });
      }}
    />
  );
}

export default AddToFavoritesButton;
