import React from "react";
import { Stack, Text, IStackItemStyles } from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
  ZoomableGroup,
} from "react-simple-maps";
// import { geoCentroid } from "d3-geo";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Label,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { AppState } from "../../../../rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  getLogFacilities,
  getLogHospitals,
  getLogRooms,
  getSalesLogs,
} from "../../actions/logsActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { OverlayBase } from "office-ui-fabric-react";
import FilterDropdown from "../Common/FilterDropdown";
import MapChart from "../Common/MapChart";
import no_data from "./../../../../assets/no_data.svg";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
const COLORS = [
  "#01B1FF",
  "#8AC8FE",
  "#CCE7FF",
  "#FDB913",
  "#4EAE4D",
  "#A8E7D0",
  "#33E1DB",
  "#7E7E81",
  "#EB4747",
  "#F198F6",
  "#A020F0",
];
const COLORS2 = ["#7E7E81", "#8AC8FE"];

const SalesDashboard = () => {
  const dispatch = useDispatch();
  const [content, setContent] = useState([]);
  const [dateDefault, setDateDefault] = useState<Array<string>>([]);
  const [mapPointerSearch, setMapPointerSearch] = useState<Array<string>>([]);

  const roomsCheck = (startDate = "", endDate = "") => {
    //   setDateDefault([startDate, endDate]);
  };
  const dateCheck = (startDate = "", endDate = "") => {
    setDateDefault([startDate, endDate]);
    //  setRoom(val);
  };
  const deviceClickHandler = (
    hospital = "",
    facility = "",
    room = "",
    region = "",
    country = "",
    speciality = "",
    startDate = "",
    endDate = ""
  ) => {
    setMapPointerSearch([
      hospital,
      facility,
      room,
      region,
      country,
      speciality,
      startDate,
      endDate,
    ]);
  };
  const serviceLogs = useSelector((state: AppState) => {
    return state.logsReducerData?.serviceLogs?.data;
  });
  const salesLogs = useSelector((state: AppState) => {
    return state.logsReducerData?.salesLogs?.data;
  });
  const initialSalesLogs = useSelector((state: AppState) => {
    return state.logsReducerData?.initialSalesLogs;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessageSales;
  });
  // console.log(
  //   salesLogs,
  //   salesLogs?.procedureDuration?.callibrations,
  //   "salesLogs"
  // );
  const allProcedureDurationValues = [
    // salesLogs?.procedureDuration?.calibrations,
    salesLogs?.widgets?.procedureDuration?.setupTime,
    // ...salesLogs?.procedureDuration?.overlay
  ];
  const overlay = salesLogs?.widgets?.procedureDuration?.overlay?.map(
    (overlay: any) => ({
      name: overlay.name,
      Min: overlay.value,
    })
  );
  const specialities = salesLogs?.widgets?.specialities?.map(
    (specialitie: any) => ({
      name: specialitie.name,
      Min: specialitie.value,
    })
  );
  const rooms = salesLogs?.widgets?.rooms?.map((room: any) => ({
    name: room.name,
    Min: room.value,
  }));
  const workflow = salesLogs?.widgets?.procedureDuration?.callibrations?.map(
    (callibration: any) => ({
      name: callibration.name,
      Min: callibration.value,
    })
  );
  const checknoData = () => {
    if (
      !salesLogs?.widgets?.totalDuration &&
      !salesLogs?.widgets?.overlayTotalDuration &&
      !(
        salesLogs?.widgets?.calibrationscount ||
        salesLogs?.widgets?.calibrationsTotalDuration
      ) &&
      !salesLogs?.widgets?.proceduresCount &&
      !salesLogs?.widgets?.diskUsagePercentage &&
      !(specialities?.length > 0) &&
      !(rooms?.length > 0)
    ) {
      return true;
    }
    return false;
  };
  const diskUsagePercentage = [
    { name: "Utilized", value: salesLogs?.widgets?.diskUsagePercentage },
    { name: "Available", value: 100 - salesLogs?.widgets?.diskUsagePercentage },
  ];
  Object.entries(
    salesLogs?.widgets?.procedureDuration
      ? salesLogs?.widgets?.procedureDuration
      : {}
  ).forEach(([key, value]: [key: any, value: any]) => {
    if (key === "overlay" || key == "callibrations") {
      allProcedureDurationValues.push(...value);
    }
  });
  const gbValue = (
    salesLogs?.widgets?.procedureSize /
    (1000 * 1000 * 1000)
  ).toFixed(2);
  // console.log(mapPointerSearch,'mapPointerSearch');

  useEffect(() => {
    // debugger;
    // if (dateDefault?.length && mapPointerSearch?.length) {
    //   dispatch(
    //     getSalesLogs(
    //       dateDefault[0] ? dateDefault[0] : mapPointerSearch[6],
    //       dateDefault[1] ? dateDefault[1] : mapPointerSearch[7],
    //       mapPointerSearch[0],
    //       mapPointerSearch[1],
    //       mapPointerSearch[2],
    //       mapPointerSearch[4],
    //       mapPointerSearch[3],
    //       mapPointerSearch[5],
    //       initialSalesLogs?.locations?.length,
    //       ""
    //     )
    //   );
    // }
  }, [dateDefault, mapPointerSearch]);

  // console.log(loaderWithMessage,'loaderWithMessage---logs')

  return (
    <Stack className="salesDashboard">
      <Stack.Item className="totalSection">
        <Text>Total Devices {salesLogs?.widgets?.deviceCount}</Text>
        <Text>Total Cases {salesLogs?.widgets?.proceduresCount}</Text>
      </Stack.Item>
      {/* ---------------filter section------------------- */}
      <FilterDropdown
        action={getSalesLogs}
        logs={initialSalesLogs}
        roomsCheck={roomsCheck}
        deviceId=""
        selectFilters={mapPointerSearch}
        setMapPointerSearch={setMapPointerSearch}
        filteredLogs={salesLogs}
        dateCheck={dateCheck}
        loaderWithMessageSales={loaderWithMessage}
      />
      <Stack.Item grow className="ms-Grid" dir="ltr">
        <Stack.Item grow align="auto" className="chartSection ms-Grid-row">
          {/* ---------------map section------------------- */}
          <Stack.Item className=" ms-Grid-col ms-sm6 ms-lg6">
            {loaderWithMessage?.statusCode != 403 && (
              <Stack.Item className="map start bgColor">
                <MapChart
                  logs={salesLogs}
                  initialData={initialSalesLogs}
                  setTooltipContent={setContent}
                  deviceSearch=""
                  deviceClickHandler={deviceClickHandler}
                />
                {content.length > 0 && (
                  <ReactTooltip multiline={true}>
                    <div className="chartToolTip">
                      <Text block>
                        Hospital:{" "}
                        <span className="toolTipContent">{content[1]}</span>
                      </Text>
                      <Text block>
                        Facility:{" "}
                        <span className="toolTipContent">{content[2]}</span>
                      </Text>
                      <Text block>
                        Region:{" "}
                        <span className="toolTipContent">{content[0]}</span>
                      </Text>
                      <Text block>
                        Room:{" "}
                        <span className="toolTipContent">{content[4]}</span>
                      </Text>
                      <Text block>
                        Device:{" "}
                        <span className="toolTipContent">{content[3]}</span>
                      </Text>
                    </div>
                  </ReactTooltip>
                )}
              </Stack.Item>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-sm6 ms-lg6">
            {/* ---------------Procedure and Overlay section------------------- */}
            <Stack.Item className="ms-Grid-row">
              <Stack.Item className=" ms-Grid-col ms-lg6">
                {loaderWithMessage?.statusCode != 403 &&
                salesLogs?.widgets?.totalDuration ? (
                  <Stack.Item className="procedureDuration bgColor">
                    <Text className="heading">
                      Average Procedure Duration (Min)
                    </Text>
                    <Stack.Item className="ms-Grid-row flex-center inheritHeight">
                      <Stack.Item className=" ms-Grid-col ms-lg4">
                        {allProcedureDurationValues.map((entry, index) => (
                          <Stack
                            className="colorCircleMain"
                            horizontal
                            dir="ltr"
                            horizontalAlign="center"
                            verticalAlign="center"
                            key={index}
                          >
                            <Stack.Item
                              className="colorCircle flex-center"
                              style={{
                                backgroundColor: COLORS[index % COLORS.length],
                              }}
                            >
                              {" "}
                            </Stack.Item>
                            <Text className="text">
                              {entry?.name ? entry?.name : "d"}
                            </Text>
                          </Stack>
                        ))}
                      </Stack.Item>
                      <Stack.Item className=" ms-Grid-col ms-lg8">
                        <PieChart width={166} height={166}>
                          <Pie
                            stroke="#101112"
                            data={allProcedureDurationValues}
                            innerRadius={50}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            <Label
                              // value="Procedure Duration"
                              position="centerBottom"
                              fill="#7E7E81"
                              className="centerLabel"
                              fontSize="10px"
                            />

                            <Label
                              value={`${salesLogs?.widgets?.totalDuration?.toFixed(
                                2
                              )} Min`}
                              position="centerTop"
                              fill="#7E7E81"
                              className="centerLabel"
                              fontSize="14px"
                            />
                            {allProcedureDurationValues.map((entry, index) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                ) : (
                  <Text> </Text>
                )}
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg6">
                {loaderWithMessage?.statusCode != 403 &&
                salesLogs?.widgets?.overlayTotalDuration ? (
                  <Stack.Item className="overlayMode bgColor">
                    <Text className="heading">
                      Average Overlay Mode Usage (Min)
                    </Text>
                    <ResponsiveContainer
                      width="100%"
                      height="80%"
                      className="placement"
                    >
                      <BarChart
                        layout="vertical"
                        width={350}
                        height={300}
                        data={overlay}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={25}
                      >
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" scale="band" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Min" barSize={20} fill="#D9EDFF" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Stack.Item>
                ) : (
                  <Text> </Text>
                )}
              </Stack.Item>
            </Stack.Item>

            {/* ---------------Workflow  section------------------- */}
            <Stack.Item className="ms-Grid-row">
              <Stack.Item className=" ms-Grid-col ms-lg12">
                {(loaderWithMessage?.statusCode != 403 &&
                  salesLogs?.widgets?.calibrationscount) ||
                (loaderWithMessage?.statusCode != 403 &&
                  salesLogs?.widgets?.calibrationsTotalDuration) ? (
                  <Stack.Item className="workflow bgColor">
                    {workflow ? (
                      <Stack.Item className=" ms-Grid-col ms-lg6">
                        <Text className="heading">
                          Average Workflow Interruptions (Min)
                        </Text>
                        <Stack horizontal horizontalAlign="space-around">
                          <Stack.Item>
                            <Text block variant="small">
                              # Recalibration
                            </Text>
                            <Text className="number">
                              {salesLogs?.widgets?.calibrationscount}
                            </Text>
                          </Stack.Item>
                          <Stack.Item>
                            <Text block variant="small">
                              Avg. Recalibration Time
                            </Text>
                            <Text className="number">
                              {salesLogs?.widgets?.calibrationsTotalDuration}min
                            </Text>
                          </Stack.Item>
                        </Stack>
                      </Stack.Item>
                    ) : (
                      <Text></Text>
                    )}
                    <Stack.Item className="workflow ms-Grid-col ms-lg6">
                      {workflow ? (
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            layout="vertical"
                            width={500}
                            height={300}
                            data={workflow}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                            barSize={25}
                          >
                            <XAxis type="number" />
                            <YAxis
                              dataKey="name"
                              type="category"
                              scale="band"
                            />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Min" barSize={20} fill="#D77879" />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <Text>NA</Text>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                ) : (
                  <Text> </Text>
                )}
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
          {loaderWithMessage?.statusCode != 403 && checknoData() && (
            <Stack.Item className=" ms-Grid-col ms-sm6 ms-lg6">
              <div className="no-data-img">
                <img
                  style={{ height: "320px", width: "280px" }}
                  src={no_data}
                />
                <Text className="no-data-img">No Data Available.</Text>
              </div>
            </Stack.Item>
          )}
        </Stack.Item>
      </Stack.Item>

      {/* ---------------bottom  section------------------- */}
      <Stack.Item className="ms-Grid bottomSection" dir="ltr">
        <Stack.Item className=" ms-Grid-row">
          <Stack.Item className=" ms-Grid-col ms-lg3">
            {loaderWithMessage?.statusCode != 403 &&
            salesLogs?.widgets?.proceduresCount ? (
              <Stack.Item className="btmHeight bgColor start">
                <Stack horizontal horizontalAlign="space-between">
                  <Text className="heading">Procedures</Text>
                  {/* <Controller
                     control={control}
                     defaultValue=""
                     {...register("operating_system")}
                     render={({ field: { onChange, value, name, ref } }) => (
                       <Select
                       isClearable
                         classNamePrefix="addl-class"
                         options={options1}
                         value={options1.find((c) => c.value === value)}
                         onChange={(val) => {
                           onChange(val?.value);
                         }}
                         placeholder="Selected Period"
                       />
                     )}
                   /> */}
                </Stack>
                <Stack
                  horizontal
                  horizontalAlign="space-around"
                  verticalAlign="center"
                  className="inheritHeight"
                >
                  <Stack.Item>
                    <Text className="number" block variant="small">
                      {salesLogs?.widgets?.proceduresCount}
                    </Text>
                  </Stack.Item>
                  <Stack.Item
                    className="ms-Grid"
                    style={{
                      overflowY: "auto",
                      maxHeight: 210,
                      marginBottom: 50,
                      marginTop: 30,
                      marginLeft: 30,
                    }}
                  >
                    {salesLogs?.widgets?.procedureTypes.map(
                      (procedureType: any, index: number) => (
                        <Stack key={index}>
                          <Text block variant="small">
                            {procedureType?.name} - {procedureType?.value}
                          </Text>
                        </Stack>
                      )
                    )}
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg3">
            {loaderWithMessage?.statusCode != 403 &&
            salesLogs?.widgets?.diskUsagePercentage ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">Procedure Size & Disk Info</Text>
                <Stack
                  horizontal
                  horizontalAlign="space-around"
                  verticalAlign="center"
                  className="inheritHeight"
                >
                  <Stack.Item className=" ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg6">
                      <Text className="number" variant="small">
                        {gbValue}
                      </Text>
                      <Text variant="large">GB</Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg6">
                      <PieChart width={126} height={106}>
                        <Tooltip formatter={(value: any) => value.toFixed(2)} />
                        <Pie
                          cx={50}
                          stroke="#101112"
                          data={diskUsagePercentage}
                          innerRadius={25}
                          outerRadius={45}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          <Label
                            value={`${diskUsagePercentage[1].value.toFixed(
                              2
                            )}%`}
                            position="centerTop"
                            fill="#7E7E81"
                            className="centerLabel"
                            fontSize="10px"
                          />
                          {diskUsagePercentage.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS2[index % COLORS2.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                      <Stack horizontal className="squareBoxMain">
                        <Stack.Item>
                          <Text className="squareBox utilized"> </Text>
                          <Text variant="xSmall">Utilized</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <Text className="squareBox available"> </Text>
                          <Text variant="xSmall">Available</Text>
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg3">
            {loaderWithMessage?.statusCode != 403 &&
            specialities?.length > 0 ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">
                  Average Usage Across Specialties (Min)
                </Text>
                <Stack.Item className="inheritHeight-specialties">
                  <ResponsiveContainer
                    width="100%"
                    height="80%"
                    className="placement"
                  >
                    <BarChart
                      width={500}
                      height={250}
                      data={specialities}
                      margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        // scale="point"
                        padding={{ left: 0, right: 0 }}
                        interval={0}
                        angle={12}
                        dx={15}
                        dy={6}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="Min" fill="#C9F0E299" />
                    </BarChart>
                  </ResponsiveContainer>
                </Stack.Item>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg3">
            {loaderWithMessage?.statusCode != 403 && rooms?.length > 0 ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">
                  Average Usage Across Rooms (Min)
                </Text>
                <Stack.Item className="inheritHeight-specialties">
                  <ResponsiveContainer
                    width="70%"
                    height="80%"
                    className="placement"
                  >
                    <BarChart
                      width={500}
                      height={200}
                      data={rooms}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        scale="point"
                        padding={{ left: 10, right: 10 }}
                        angle={25}
                        dx={5}
                        dy={5}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="Min" fill="#FFDB8599" />
                    </BarChart>
                  </ResponsiveContainer>
                </Stack.Item>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};
export default SalesDashboard;
