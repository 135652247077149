import React, { useState } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../../Wizard";
import Inventory from "./Inventory";
import Provisioning from "./Provisioning";
import { useSelector, useDispatch } from "react-redux";
import {
  StateMachineProvider,
  createStore,
  useStateMachine,
} from "little-state-machine";
import { AppState } from "../../../../../rootReducer";
import "./CreateDevice.scss";
import { deviceDecrement } from "../../../actions/managamentActions";
const cancelIcon: IIconProps = { iconName: "Cancel" };

createStore(
  {
    data: {},
  },
  {}
);
const CreateDevice = ({
  deviceSerialNumber,
  isModalOpen,
  showModal,
  orgDevicData,
}: {
  deviceSerialNumber: any;
  isModalOpen: any;
  showModal: any;
  orgDevicData: any;
}) => {
  const [disabledCreate, setDisabledCreate] = useState(false);
  const counts = useSelector((state: AppState) => {
    return state.managementReducerData.deviceCount;
  });
  const names = ["Inventory", "Provisioning"];
  // isModalOpen = hospitalSpinner ? !hospitalSpinner : isModalOpen;
  // const spinner = useSelector((state: AppState) => {
  //   return state.managementReducerData.showDeviceSpinner;
  // });

  const deviceData = useSelector((state: AppState) => {
    return state.managementReducerData.deviceData.data;
  });

  const showCreateDevice = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateDevice;
  });

  const spinnerFunc = (dummySpinner = false) => {
    if (dummySpinner) {
      setDisabledCreate(true);
    }
    return dummySpinner;
  };
  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={showModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted pointer" onClick={showModal}>
            Device Management
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">
            {showCreateDevice ? "Add" : "Edit"} Device
          </Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={showModal} />
        </Stack.Item>
      </Stack>

      {/* <StateMachineProvider> */}
      <Wizard
        counts={counts}
        form="deviceForm"
        names={names}
        back={deviceDecrement}
        // spinner={spinner}
        disabledCreate={disabledCreate}
        isCreate={showCreateDevice}
        showModal={showModal}
      >
        <Inventory
          deviceData={deviceData}
          showCreateDevice={showCreateDevice}
          orgDevicData={orgDevicData}
        />
        <Provisioning
          deviceViewData={deviceData}
          showCreateDevice={showCreateDevice}
          deviceSerialNumber={deviceSerialNumber}
          spinnerFunc={spinnerFunc}
        />
      </Wizard>
      {/* </StateMachineProvider> */}
    </Modal>
  );
};

export default CreateDevice;
