import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  DefaultButton,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  createSingleProcedure,
  createSpecialty,
  getProcedureList,
  getProcedures,
  showRoleSpinner,
  updateSpaceialtyActionData,
  closeErrorRsponse,
} from "../../actions/managamentActions";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import { AppState } from "../../../../rootReducer";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndPoint, doHttpCall } from "../../../../common/restApi";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const ProcedureCreate = ({
  isModalOpen,
  showModal,
  closeModal,
  currentPage,
}: {
  isModalOpen: any;
  showModal: any;
  closeModal: any;
  currentPage: any;
}) => {
  const procedureData = useSelector((state: AppState) => {
    return state.managementReducerData.procedureData?.data;
  });
  const [procedureOnChange, setprocedureOnChange] = useState("");
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [procedureSubmitted, setProcedureSubmitted] = useState(false);

  const isprocedureNameChanged =
    procedureOnChange === "" ? procedureData?.name : procedureOnChange;
  const isprocedureChanged = procedureData?.name !== isprocedureNameChanged;

  const validationSchema = Yup.object().shape({
    procedure: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Procedure name is required"),
        // .test({
        //   message: "This Procedure is already taken",
        //   test: async (value) => {
        //     if (procedureOnChange !== "" && isprocedureChanged) {
        //       const checkFieldName = `/procedures/types?search=${value}`;
        //       const payload = {
        //         method: "GET",
        //         url: `${apiEndPoint}${checkFieldName}`,
        //       };
        //       const response = await doHttpCall(payload);
        //       console.log(response, "response");
        //       const returnStatus =
        //         response.data.results.length > 0 ? false : true;
        //       return returnStatus;
        //     } else {
        //       return true;
        //     }
        //   },
        // }),
        comments: Yup.string(),
      })
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm(formOptions);
  const dispatch = useDispatch();

  const _alertClicked = useCallback(() => {
    setnameChange("");
    const values = getValues();
    values.procedure.splice(0, 1);
    closeModal();
  }, []);

  // const _alertClicked1 = useCallback(() => {
  //   const values = getValues();
  //   values.specialty.splice(0, 1);
  //   closeModal();
  // }, []);

  const singleProcedureSpinner = useSelector((state: AppState) => {
    return state.managementReducerData.singleProcedureSpinner;
  });
  // console.log(singleProcedureSpinner, "singleProcedureSpinner");

  const errorProcedure = useSelector((state: AppState) => {
    return state.managementReducerData.procedureError;
  });

  const onSubmit = async (data: any, e: any) => {
    setDisabledCreate(true);
    const responce: any = await dispatch(
      createSingleProcedure(data?.procedure[0])
    );
    if (responce.status === 200) {
      dispatch(getProcedures(1000));
      dispatch(getProcedureList(currentPage));
      setProcedureSubmitted(true);
      setDisabledCreate(false);
      dispatch(showRoleSpinner());
      setTimeout(() => {
        dispatch(showRoleSpinner());
        setProcedureSubmitted(false);
      }, 1000);
    } else {
      setTimeout(() => {
        setDisabledCreate(false);
        dispatch(showRoleSpinner());
        setProcedureSubmitted(false);
        dispatch(closeErrorRsponse());
      }, 1000);
    }
  };
  const [nameChange, setnameChange] = useState("");
  useEffect(() => {
    errorProcedure && setnameChange(errorProcedure);
  }, [errorProcedure]);

  useEffect(() => {
    setDisabledCreate(false);
  }, [isModalOpen]);

  useEffect(() => {
    if (errors && Object.keys(errors)?.length) {
      setnameChange(errors?.procedure[0]?.name?.message);
    }
  }, [errors]);

  useEffect(() => {
    return () => {
      delete errors?.procedure;
    };
  });
  return (
    <Modal
      className="deleteModal spacilityModal"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={() => {
        _alertClicked();
      }}
      containerClassName={"smallModalStyle"}
      isBlocking={false}
    >
      <Stack className="deleteSection">
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack.Item className="heading">
            <Text variant="xLarge">Add Procedure Name</Text>
          </Stack.Item>
          <Stack.Item align="end">
            <IconButton
              className=""
              iconProps={cancelIcon}
              onClick={() => {
                _alertClicked();
              }}
            />
          </Stack.Item>
        </Stack>
        <form id="procedureAdd" onSubmit={handleSubmit(onSubmit)}>
          <Stack.Item className="spacialtyForm">
            <Stack.Item className="ms-Grid-row">
              <Stack.Item className="ms-Grid-col" dir="ltr">
                <TextField
                  autoComplete="off"
                  label="Name of the Procedure*"
                  errorMessage={nameChange ? nameChange : ""}
                  {...register(`procedure[0].name`)}
                  onChange={(e: any) => {
                    setprocedureOnChange(e?.target?.value);
                    nameChange ? setnameChange("") : setnameChange(nameChange);
                  }}
                  onKeyDown={(e: any) => {
                    const values = getValues();
                    values.procedure[0].name = e?.target?.value;
                  }}
                  defaultValue=""
                />
                {/* <TextField
                  label="Comments"
                  errorMessage={
                    errors?.specialty
                      ? errors?.specialty[0]?.comments?.message
                      : ""
                  }
                  {...register(`specialty[0].comments`)}
                  // onChange={(e) => handleValidateForm(e, "name")}
                  defaultValue=""
                /> */}
              </Stack.Item>
              <Stack.Item className="ms-Grid-col footerSection">
                <PrimaryButton
                  disabled={disabledCreate}
                  type="submit"
                  text="Add"
                  className="btnDelete"
                  // onClick={deleteHandle}
                  allowDisabledFocus
                >
                  <Text className="spinnerRight">
                    {disabledCreate && <Spinner size={SpinnerSize.small} />}
                  </Text>
                </PrimaryButton>
                <DefaultButton
                  text="Cancel"
                  className="btnCancel"
                  onClick={() => {
                    _alertClicked();
                  }}
                  allowDisabledFocus
                />
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </form>
        <Modal isOpen={procedureSubmitted} containerClassName={"modalStyle"}>
          <SuccessMessage title="Procedure Creation" />
        </Modal>
        {procedureSubmitted &&
          setTimeout(() => {
            _alertClicked();
            // showModal();
            // showModal();
            // _alertClicked1();
            // dispatch(getProcedures(1000));
            // dispatch(getProcedureList());
            // dispatch(showRoleSpinner());
            //setProcedureSubmitted(false);
          }, 1000) && <></>}
        {/* {
          useEffect(()=>{
            dispatch(getProcedures(1000));
            dispatch(getProcedureList());
          },[procedureSubmitted])
        } */}
      </Stack>
    </Modal>
  );
};
export default ProcedureCreate;
