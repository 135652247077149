import {
  Stack,
  Text,
  PrimaryButton,
  Icon,
  ActionButton,
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import {
  decrement,
  createHospital,
  editHospital,
} from "../../actions/homeAction";
import { showSpinner } from "../../actions/homeAction";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
const Wizard = ({
  children,
  showModal,
  showCreateHospital,
  hospitalId,
}: {
  children: any;
  showModal: any;
  showCreateHospital: any;
  hospitalId: any;
}) => {
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [disabledCreate, setDisabledCreate] = useState(false);

  const dispatch = useDispatch();

  const pages = React.Children.toArray(children);
  const counts = useSelector((state: AppState) => {
    return state.homeReducerData.count;
  });
  const currentPage = pages[counts];

  const goNextPage = () => {
    setActivePageIndex(counts);
  };

  const goPrevPage = () => {
    dispatch(decrement());
    setActivePageIndex(counts);
  };
  const hospitalById = useSelector((state: AppState) => {
    return state.homeReducerData.hospitalById?.data;
  });

  const hospitalData = useSelector((state: AppState) => {
    return state.homeReducerData.hospitalData;
  });
  const hospitalSpinner = useSelector((state: AppState) => {
    return state.homeReducerData.showSpinner;
  });

  const handleCreateHospital = (e: any) => {
    // console.log(hospitalSpinner, 'handleCreateHospital');
    // console.log(hospitalData,"hospitalData");
    setDisabledCreate(true);

    dispatch({ type: "CLEAR_HOSPITALS" });

    if (showCreateHospital) {
      // hospitalData?.hospitals?.map((hospital:any) => {
      //   hospital?.devices?.map((device:any) => {
      //     delete device.id
      //   })
      // })
      // debugger;
      const editHospitalData = "";
      hospitalData?.hospitals?.map((hospital: any, index: any) => {
        if (!hospital?.devices) hospital.devices = [];
        hospital?.is_main_branch === false &&
          Object.assign(hospital, {
            //  fid: hospitalById?.facilities[index - 1]?.fid || null,
            fid: hospital?.fid || null,
          });
        hospital?.devices.length === 0 && delete hospital?.devices;
        hospital?.is_main_branch === true && delete hospital?.facilities;
        !hospital?.id && delete hospital?.id;
      });
      Object.assign(hospitalData, {
        id: hospitalById?.id,
      });
      // console.log(hospitalData,hospitalById, "hospitalData");
      // debugger;
      dispatch(editHospital(hospitalId, hospitalData));
    } else {
      hospitalData?.hospitals?.map((hospital: any, index: any) => {
        hospital?.is_main_branch === true && delete hospital?.facilities;
      });
    }
    !showCreateHospital && dispatch(createHospital(hospitalData));
    // console.log(hospitalSpinner, 'handleCreateHospital');
  };

  const ButtonPrev = () =>
    counts > 0 ? (
      <ActionButton
        type="button"
        onClick={goPrevPage}
        allowDisabledFocus
        className="btn-c"
      >
        Back
      </ActionButton>
    ) : null;
  const ButtonNext = () =>
    counts < pages.length - 1 ? (
      <PrimaryButton type="submit" form="myForm" className="btn-next">
        Next
      </PrimaryButton>
    ) : (
      <PrimaryButton
        type="submit"
        className="btn-next"
        disabled={disabledCreate}
        onClick={handleCreateHospital}
      >
        {!showCreateHospital ? "Create" : "Update"}
        <Text className="spinnerRight">
          {disabledCreate && <Spinner size={SpinnerSize.small} />}
        </Text>
      </PrimaryButton>
    );

  useEffect(() => {}, []);
  return (
    <Stack>
      <Stack className="createHospitalMain">
        <Stack.Item className="createHospitalWizard">
          <Stack horizontal horizontalAlign="start">
            <Stack.Item
              align="center"
              className={`stepBar ${counts == 0 ? "activeSidebar" : ""}`}
            >
              {/* {hospitalData?.hospitals.length > 1 ? */}
              <Text
                className={`circleNumber fontHelveticaNeueMedium ${
                  counts > 0 ? "step-completed" : ""
                }`}
                variant="small"
              >
                <span>1</span>
              </Text>
              {/* : (
                                    <Text className=" fontHelveticaNeueMedium completed" variant="small">
                                        <Icon iconName="Completed" className="ms-fontSize-20 iconCompleted" />
                                    </Text>
                                )} */}
              <Text className="fontHelveticaNeueMedium">Add Hospital</Text>
            </Stack.Item>
            <Stack.Item
              align="center"
              className={`stepBar stepBar1 ${
                counts == 1 ? "activeSidebar" : ""
              }`}
            >
              {/* {hospitalData?.hospitals ? */}
              <Text
                className={`circleNumber ${counts > 1 ? "step-completed" : ""}`}
                variant="small"
              >
                <span>2</span>
              </Text>
              {/* : (
                                    <Text className=" fontHelveticaNeueMedium completed" variant="small">
                                        <Icon iconName="Completed" className=" ms-fontSize-20 iconCompleted " />
                                    </Text>
                                )} */}
              <Text className="fontHelveticaNeueMedium">Add Device</Text>
            </Stack.Item>
            <Stack.Item
              align="center"
              className={`stepBar stepBar1 ${
                counts == 2 ? "activeSidebar" : ""
              }`}
            >
              <Text className="circleNumber" variant="small">
                3
              </Text>
              <Text className="fontHelveticaNeueMedium">Confirmation</Text>
            </Stack.Item>
            <Stack.Item align="center" className="stepBar stepBar1">
              {" "}
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {/* first step and second step ternary operation */}
        {/* {this.state.stepNavigation ? ( */}
        <Stack className="wizardHeight">{currentPage}</Stack>
      </Stack>
      <Stack
        className="createHospitalWizardFooter"
        horizontal
        horizontalAlign="space-between"
      >
        <Stack className="btn-back">
          <ButtonPrev />
        </Stack>
        <Stack.Item className="nextCancel">
          <ButtonNext />
          <ActionButton
            text="Cancel"
            className="btn-c"
            allowDisabledFocus
            onClick={showModal}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default Wizard;
