import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  StackItem,
  Text,
  Modal,
  IconButton,
  IIconProps,
  TextField,
  Label,
  PrimaryButton,
  ActionButton,
} from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { ReactComponent as AddFacilityIcon } from "../../../../../assets/Home/add_facility.svg";
import {
  algorithmView,
  createAlgorithm,
  createWorkflow,
  editWorkflow,
  getAlgorithmBusinessList,
  getAlgorithmList,
  getAlgorithmTypesList,
  getInputParameterList,
  getWorkflowList,
  updateInputParams,
  updateWorkflowActionData,
  removeErrorWorkflow,
  getWorkflowView,
} from "../../../actions/managamentActions";
import ToggleCard from "../../../../Home/components/Wizard/ToggleCard";
import "../Workflow.scss";
import { AppState } from "../../../../../rootReducer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { apiEndPoint, doHttpCall } from "../../../../../common/restApi";
import { debounce } from "lodash";
import { clear } from "console";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import Loader from "../../../../Logs/components/Common/Loader";
// import { createAlgorithm } from "../../actions/managamentActions";
// import { AppState } from "../../../../rootReducer";
const deleteIcon: IIconProps = { iconName: "Delete" };

const cancelIcon: IIconProps = { iconName: "Cancel" };

const options1 = [{ value: "", label: "" }];
const outputRequired = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const CreateWorkflow = ({ isModalOpen, showModal, workflowId }: any) => {
  const formSchema = {
    algorithm: Yup.string().required("Algorithm is required"),
    algorithm_inputsArr: Yup.array().of(
      Yup.object().shape({
        input_parameter_value: Yup.string()
          .nullable()
          .required("Input Parameter value is required"),
      })
    ),
  };
  const formAdminSchema = {
    description: Yup.string(),
    name: Yup.string().required("Name is required"),
    streaming_point: Yup.string().when(["type"], {
      is: "NONBUSINESS",
      then: Yup.string().required("Main Video Parameter is required"),
    }),
    type: Yup.string().required("Type is required"),
  };
  const validationSchema = Yup.object().shape({
    algorithms: Yup.array()
      .of(Yup.object().shape(formSchema))
      .required("Must have fields"),
    workflow: Yup.object().shape(formAdminSchema),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    clearErrors,
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);
  const values = getValues();

  const [showOutPut, setShowOutPut] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [indexes, setIndexes] = useState<Array<number>>([0]);
  const [counter, setCounter] = useState(1);
  const [v1Options, setv1Options] = useState([]);
  const [v2Options, setv2Options] = useState([]);
  const dispatch = useDispatch();
  const [algorihmChanage, setalgorihmChanage] = useState(0);
  const [apiCall, setApiCall] = useState(true);
  const [pageLoadder, setPageLoader] = useState(false);
  const [workflowLists, setWorkflowList] = useState<any>();
  // console.log(indexes, 'indexes', counter, 'counter');
  function findByIndex(position: any, index: any) {
    return position === counter - 1;
  }

  const myPosition: number =
    indexes.find((position: any, index: any) => findByIndex(position, index)) ||
    0;

  const inputParameterIndex = indexes.indexOf(myPosition);
  // console.log(
  //   indexes?.length - 1,
  //   counter,
  //   "myPositionmyPosition",
  //   myPosition,
  //   inputParameterIndex
  // );

  const businessAlgorithmLists = useSelector((state: AppState) => {
    return state.managementReducerData.businessAlgorithmList.data;
  });

  // const workflowLists = useSelector((state: AppState) => {
  //   return state.managementReducerData.workflowList.data;
  // });

  const algorithmTypeList = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmTypeList.data;
  });
  const algorithmViews = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmView.data;
  });
  const workflowData = useSelector((state: AppState) => {
    return state.managementReducerData.workflowData.data;
  });

  const showCreateWorkflow = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateWorkflow;
  });
  const closeWorkflow = useSelector((state: AppState) => {
    return state.managementReducerData.closeWorkflow;
  });
  const errorResponse = useSelector((state: AppState) => {
    return state.managementReducerData.errorResponse;
  });

  // console.log(workflowData, showCreateWorkflow, "workflowData");
  // var algorithmIds: Array<number> = [];

  const inputParameters = useSelector((state: AppState) => {
    return state.managementReducerData.inputParameter;
  });
  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });
  const inputParameterValue = useSelector((state: AppState) => {
    return state.managementReducerData.inputParameterValue.data;
  });

  const loadingState = useSelector((state: AppState) => {
    return state.managementReducerData?.loadingState;
  });

  // console.log(
  //   workflowData,
  //   inputParameterValue,
  //   "workflowData-state-111111111"
  // );
  const getEditPageData = async () => {
    let promise1: any;
    if (workflowId) {
      promise1 = dispatch(getWorkflowView(workflowId));
    }
    const promise2 = dispatch(getAlgorithmList());
    const promise3 = dispatch(getWorkflowList(1, ""));
    const promise4 = dispatch(getAlgorithmTypesList());
    const promise5 = dispatch(getInputParameterList(previousAlgorithmIds));
    setPageLoader(true);
    Promise.all([promise1, promise2, promise3, promise4, promise5]).then(
      (values: any) => {
        setWorkflowList(values && values[2]?.data);
        setTimeout(() => {
          setPageLoader(false);
        }, 2300);
      }
    );
  };

  const inputParameterValueOptions = inputParameterValue?.outputs.map(
    (input: any) => ({
      value: input,
      label: input,
    })
  );
  const activSightoptions = inputParameterValue?.outputs?.filter(
    (x: any) => x !== "rootInputFolder"
  );
  const mainVideoPathOptions = activSightoptions?.map((input: any) => ({
    value: input,
    label: input,
  }));
  const getmainVideoPathOptions = (values: any) => {
    const selectedVideoParameter =
      values?.workflow?.activ_sight_overlay_path || "";
    const list = inputParameterValueOptions?.filter(
      (x: any) => x?.value !== selectedVideoParameter
    );
    // setv1Options(list);
    return list;
  };
  const getMainvideoParams = (value: any) => {
    const list = getmainVideoPathOptions(values);
    if (!value) return "";
    const item = list?.filter((c: any) => c.value === value);
    return item;
  };
  const getMainvideoParamsSight = (value: any) => {
    const list = activSightOverlayoptions(values);
    if (!value) return "";
    const item = list?.filter((c: any) => c.value === value);
    return item;
  };

  const activSightOverlayoptions = (values: any) => {
    const activSightOverlayopt = values?.workflow?.streaming_point || "";
    const list = inputParameterValueOptions?.filter(
      (x: any) =>
        x?.value !== activSightOverlayopt && x?.value !== "rootInputFolder"
    );
    //  setv2Options(list);
    return list;
  };
  const setInputParameter = useCallback(
    (
      index: any,
      algorithm_inputs: any,
      algorithm_id: any,
      input_Parameter_value = ""
    ) => {
      // console.log(
      //   inputParameterValueOptions,
      //   "inputParameterValueOptions",
      //   index,
      //   algorithm_id,
      //   algorithm_inputs
      // );
      // debugger;

      // console.log(algorithm_inputs, 'algorithm_inputs');
      if (algorithm_inputs !== "") {
        const algorithms = inputParameters;

        // algorithms.map((algorithm: any, algorithmIndex: any) =>
        //   console.log(
        //     index,
        //     algorithmIndex,
        //     index <= algorithmIndex,
        //     "algorithmIndex"
        //   )
        // );
        // debugger;
        // dispatch(getInputParameterList(algorithm_id, false));

        algorithms[index] = {
          inputParameter: Array.isArray(algorithm_inputs)
            ? algorithm_inputs
            : algorithm_inputs?.split(","),
          inputParameterValue:
            input_Parameter_value !== ""
              ? input_Parameter_value
              : inputParameterValueOptions,
          algorithmId: algorithm_id,
        };
        for (let xyz = 1; xyz < indexes.length; xyz++) {
          if (algorithms[xyz].inputParameterValue.length == 0)
            algorithms[xyz].inputParameterValue = inputParameterValueOptions;
        }
        // debugger;
        dispatch(updateInputParams(algorithms));
      }
    },
    [inputParameterValueOptions, indexes]
  );

  const algorithmIds = inputParameters?.map(
    (algorithm: any, algorithmIndex: any) => algorithm?.algorithmId
  );
  const previousAlgorithmIds = algorithmIds?.join(",").replace(/,(?=\s*$)/, "");

  // console.log(inputParameters,'inputParameters');
  const algorithmOptions = businessAlgorithmLists?.results?.map(
    (businessAlgorithmList: any) => ({
      value: businessAlgorithmList.id,
      label: businessAlgorithmList.name,
      inputParams: businessAlgorithmList.input_parameters,
    })
  );

  const algorithmInputsSting = workflowData?.workflow_algorithm_mapping.map(
    (mapping: any) => {
      algorithmOptions?.push({
        value: mapping?.algorithm,
        label: mapping?.algorithm_name,
      });
    }
  );
  // console.log(algorithmOptions, "algorithmOptions");

  const workflowOptions = workflowLists?.results?.map((workflow: any) => ({
    value: workflow.id,
    label: workflow.name,
  }));

  const algorithmTypeOptions = algorithmTypeList?.data?.map(
    (algorithmType: any) => ({
      value: algorithmType,
      label: algorithmType === "NONBUSINESS" ? "Non-Business" : "Business",
    })
  );

  // console.log(algorithmTypeOptions, "algorithmTypeOptions");

  const handleRemove = (i: any) => {
    const allValues = getValues();

    // setValue(`algorithms[`+i+`].algorithm`,"");
    const values = allValues.algorithms?.filter(function (el: any, index: any) {
      if (index > i && el != null) {
        setValue(
          `algorithms[${index}].algorithm_inputsArr[0].input_parameter_value`,
          null
        );
      }
      return el != null;
    });
    values.splice(i, 1);
    setValue("algorithms", values);
    workflowData?.workflow_algorithm_mapping.splice(i, 1);
    indexes.splice(i, 1);
    // values?.algorithm[0]?.input_parameters.splice(i, 1);
    // setIndexes((prevIndexes) => [...prevIndexes?.filter((item) => item !== i)]);
    // setCounter((prevCounter) => prevCounter - 1);
    const removeInputParameter = inputParameters?.splice(i, 1);
    // console.log(inputParameters,removeInputParameter,'inputParameterValue');
    dispatch(updateInputParams(inputParameters));
    inputParameters.forEach((element: any, index: any) => {
      if (index >= i && element) {
        inputParameters[index].inputParameterValue = [];
        setInputParameter(index, "", element.algorithmId, []);
      }
    });
    // setInputParameter(i + 1, "", "");
    if (showOutPut) {
      setValue(`workflow.streaming_point`, "");
      setValue(`workflow.activ_sight_overlay_path`, "");

      if (workflowData) {
        workflowData.activ_sight_overlay_path = "";
        workflowData.streaming_point = "";
      }
    }
    setv1Options([]);
    setv2Options([]);
  };
  const addAlgorithmSequence = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
    // debugger;
    setInputParameter(inputParameterIndex + 1, "", "");
  };
  const clearIndexes = () => {
    setPageLoader(true);
    setIndexes([]);
    // setCounter(1)
    // dispatch(updateInputParams([]));
    setTimeout(() => {
      setPageLoader(false);
      addAlgorithmSequence();
    }, 1000);
  };

  // console.log(indexes, "indexes---------");

  async function getAlgorithmValuesList(isBusiness: any, id: any, index?: any) {
    const withAlgorithm = id ? `&algorithms=${id}` : "";

    const checkFieldName = `/algorithms/outputs?business=${isBusiness}${withAlgorithm}`;
    const payload = {
      method: "GET",
      url: `${apiEndPoint}${checkFieldName}`,
    };
    const response = await doHttpCall(payload);
    const algorithmValuesOptions = response?.data?.outputs?.map(
      (algorithmValue: any) => ({
        value: algorithmValue,
        label: algorithmValue,
      })
    );
    inputParameters[index].inputParameterValue = algorithmValuesOptions;
    setApiCall(true);
    //  return response;
  }

  useEffect(() => {
    setIndexes([0]);
    const mappingCount: Array<number> = [];
    workflowData?.workflow_algorithm_mapping?.map(
      (mapping: any, index: any) => {
        mappingCount?.push(index);
      }
    );

    showCreateWorkflow && setIndexes(mappingCount);
    setCounter(workflowData?.workflow_algorithm_mapping?.length);
    const check: any = [];
    const algorithmInputsSting = workflowData?.workflow_algorithm_mapping.map(
      (mapping: any, index: any) => {
        async function getAlgorithmValues123() {
          const algorithm_inputs = JSON.parse(
            mapping.algorithm_inputs.replace(/'/g, '"')
          );
          const algorithmInputs = Object.keys(algorithm_inputs);
          const algorithmValues = Object.values(algorithm_inputs);

          const isBusiness =
            workflowData?.type !== "NONBUSINESS" ? false : true;
          dispatch(getInputParameterList(mapping.algorithm, !isBusiness));
          // debugger;

          async function getAlgorithmValues(isBusiness: any, id: any) {
            const withAlgorithm = id ? `&algorithms=${id}` : "";

            const checkFieldName = `/algorithms/outputs?business=${isBusiness}${withAlgorithm}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            return response;
          }
          const algorithmIds = inputParameters?.map(
            (algorithm: any, algorithmIndex: any) => algorithm?.algorithmId
          );
          const previousAlgorithmIds = algorithmIds?.join(",");
          check.push(mapping.algorithm);

          const idsAlgorithm = check.slice(0, index).join(",");
          const values = await getAlgorithmValues(!isBusiness, idsAlgorithm);
          const algorithmValuesOptions = values?.data?.outputs?.map(
            (algorithmValue: any) => ({
              value: algorithmValue,
              label: algorithmValue,
            })
          );
          setInputParameter(
            index,
            algorithmInputs,
            mapping.algorithm,
            algorithmValuesOptions
          );
          // values.then(function (response: any) {
          //   // debugger;

          //   console.log(
          //     index,
          //     algorithmInputs,
          //     mapping.algorithm,
          //     response,
          //     "(from then)"
          //   );
          //   debugger;

          // });
          // console.log(algorithmValuesOptions, "algorithmValuesOptions");

          setShowOutPut(isBusiness);

          algorithmOptions?.push({
            value: mapping?.algorithm,
            label: mapping?.algorithm_name,
          });

          // console.log(algorithmOptions, "algorithmOptions");

          dispatch(getAlgorithmBusinessList(!isBusiness));
        }
        getAlgorithmValues123();
      }
    );
    // console.log(algorithmInputsSting, 'algorithmInputsSting');
    // setInputParameter()
    // const algorithmInputsStingToObject = JSON.parse(algorithmInputsSting.replace(/'/g, '"'));
    // for (const [key, value] of Object.entries(algorithmInputsStingToObject)) {

    //   console.log(`${key}: ${value}`);
    // }
    setValue(`workflow.id`, workflowData?.id);
    setValue(`workflow.workflow_mapping`, workflowData?.workflow_mapping);
    setValue(`workflow.created_by`, workflowData?.created_by);
    setValue(`workflow.created_date`, workflowData?.created_date);
  }, [workflowData]);

  useEffect(() => {
    if (disabledCreate) {
      setDisabledCreate(false);
    } else {
      dispatch(removeErrorWorkflow());
    }
  }, [errorResponse]);

  useEffect(() => {
    const values = getValues();
    for (const prop in values?.workflow) {
      delete values?.workflow[prop];
    }
    values?.algorithms?.splice(0, 1);
    if (values?.algorithms?.length) {
      values.algorithms.length = 0;
    }

    setIndexes([0]);
    setCounter(1);
    // dispatch(updateInputParams([]));
  }, [closeWorkflow, isSubmitSuccessful]);
  // console.log(isSubmitSuccessful,closeWorkflow,'isSubmitSuccessfulisSubmitSuccessful');

  useEffect(() => {
    getEditPageData();

    // dispatch(getAlgorithmList());
    // dispatch(getWorkflowList(1, ""));
    // dispatch(getAlgorithmTypesList());
    // dispatch(getInputParameterList(previousAlgorithmIds));
  }, []);
  useEffect(() => {
    const algorithmIds = inputParameters?.map(
      (algorithm: any, algorithmIndex: any) => algorithm?.algorithmId
    );
    const previousAlgorithmIds = algorithmIds?.join(",");
    dispatch(getInputParameterList(previousAlgorithmIds, !showOutPut));
    // dispatch(getAlgorithmList());
    // dispatch(getWorkflowList(1, ""));
    // dispatch(getAlgorithmTypesList());
    // dispatch(getInputParameterList(previousAlgorithmIds));
  }, [inputParameters]);

  const algInputHandler = useCallback(
    debounce((evt: any) => {
      dispatch(getAlgorithmBusinessList(!showOutPut, evt));
    }, 2000),
    []
  );
  const algInputChangeHandler = (evt: any) => {
    const value = evt?.target.value;
    algInputHandler(value);
  };

  const handleScrollAlg = (evt: any) => {
    if (businessAlgorithmLists?.next) {
      dispatch(
        getAlgorithmBusinessList(!showOutPut, "", businessAlgorithmLists?.next)
      );
    }
  };

  const onSubmit = (data: any) => {
    setDisabledCreate(true);
    for (let i = 0; i < data.algorithms.length; i++) {
      if (!data.algorithms[i] || !data.algorithms[i].algorithm) {
        data.algorithms.splice(i, 1);
        i--;
      }
    }
    // debugger;
    const algorithm_inputs = data.algorithms.map(
      (algorithm: any, outindex: any) => ({
        sequence_num: algorithm.sequence_num || outindex + 1,
        algorithm_inputs: algorithm?.algorithm_inputsArr?.reduce(
          (obj: any, item: any, index: any) => ({
            ...obj,
            [inputParameters[outindex].inputParameter[index]]:
              item.input_parameter_value,
          }),
          {}
        ),
      })
    );

    data.algorithms.map((algorithm: any, index: any) => {
      delete algorithm?.algorithm_inputsArr;
      Object.assign(data.algorithms[index], algorithm_inputs[index]);
    });
    if (!data.workflow?.activ_sight_overlay_path) {
      data.workflow.activ_sight_overlay_path = "";
    }

    !showCreateWorkflow && dispatch(createWorkflow(data));
    showCreateWorkflow && dispatch(editWorkflow(workflowId, data));

    // dispatch(updateWorkflowActionData(data));
  };

  // const _alertClicked = useCallback(() => {
  //   let values = getValues();
  //   values.workflow = {};
  //   values.algorithms = [];
  //   console.log(values, "values");
  //   showModal();
  // }, []);
  const getInputparametervalue = (i: any, value: any) => {
    const item = inputParameters[i]?.inputParameterValue?.find(
      (c: any) => c.value === value
    );
    return item || null;
  };
  const getInputParameterlistValues = (ind: any) => {
    return inputParameters[ind]?.inputParameterValue;
  };
  const _alertClicked = useCallback(() => {
    const values = getValues();
    for (const prop in values?.workflow) {
      delete values?.workflow[prop];
    }
    values?.algorithms?.splice(0, 1);
    if (values?.algorithms?.length) {
      values.algorithms.length = 0;
    }
    setIndexes([0]);
    setCounter(1);
    dispatch(updateInputParams([]));

    showModal();
  }, []);
  return (
    <>
      {(workflowData || !showCreateWorkflow) && (
        <Modal
          className={
            pageLoadder
              ? "createHospital Workflow point-events-none"
              : "createHospital Workflow"
          }
          isModeless={true}
          isOpen={isModalOpen}
          onDismiss={showModal}
          containerClassName={"modalStyle"}
          isBlocking={false}
        >
          <Stack
            className="createHospitalSection"
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack.Item className="createHospitalHeading">
              <Text
                variant="large"
                className="muted pointer"
                onClick={showModal}
              >
                WorkFlow
              </Text>
              <Text variant="large" className="lessthan muted">
                &gt;
              </Text>
              <Text variant="large">
                {!showCreateWorkflow ? "Add" : "Edit"} Workflow
              </Text>
            </Stack.Item>
            <Stack.Item align="end">
              <IconButton
                className=""
                iconProps={cancelIcon}
                onClick={() => _alertClicked()}
              />
            </Stack.Item>
          </Stack>
          <form id="workflow" onSubmit={handleSubmit(onSubmit)}>
            {pageLoadder && (
              <>
                <Loader></Loader>
              </>
            )}
            <Stack.Item className="algorithm workflowForm ms-Grid" dir="ltr">
              <Stack.Item
                className="ms-Grid-row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Stack.Item
                  className="ms-Grid-col ms-lg9"
                  style={{
                    padding: "48px 73px",
                    backgroundColor: "#101112",
                  }}
                >
                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg5 ">
                      <TextField
                        autoComplete="off"
                        label="Workflow Name*"
                        {...register(`workflow.name`)}
                        errorMessage={
                          errors?.workflow
                            ? errors?.workflow?.name?.message
                            : ""
                        }
                        onChange={() => {
                          dispatch(removeErrorWorkflow());
                          clearErrors(`workflow.name`);
                        }}
                        defaultValue={workflowData && workflowData?.name}
                      ></TextField>
                      <Label className="errorMessage">
                        {errorResponse?.details}
                      </Label>
                      <TextField
                        autoComplete="off"
                        label="Description"
                        {...register(`workflow.description`)}
                        multiline
                        rows={3}
                        errorMessage={
                          errors?.workflow
                            ? errors?.workflow.description?.message
                            : ""
                        }
                        // onChange={(e) => handleValidateForm(e, "name")}
                        defaultValue={workflowData && workflowData?.description}
                      ></TextField>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg5 ms-lgOffset1">
                      <Label>Is Business Workflow*</Label>
                      <Controller
                        control={control}
                        defaultValue={workflowData && workflowData?.type}
                        shouldUnregister
                        name={"workflow.type"}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            classNamePrefix="addl-class"
                            options={algorithmTypeOptions}
                            value={algorithmTypeOptions?.find(
                              (c: any) => c.value === value
                            )}
                            placeholder="Yes/No"
                            onChange={(val) => {
                              onChange(val?.value);
                              if (val?.value === "NONBUSINESS") {
                                setShowOutPut(true);
                                dispatch(getAlgorithmBusinessList(false));
                                clearIndexes();
                                if (workflowData?.workflow_algorithm_mapping)
                                  workflowData.workflow_algorithm_mapping = [];
                                //  dispatch(updateWorkflowActionData(workflowData));
                                dispatch(updateInputParams([]));
                              } else {
                                setShowOutPut(false);
                                dispatch(getAlgorithmBusinessList(true));
                                clearIndexes();
                                if (workflowData?.workflow_algorithm_mapping)
                                  workflowData.workflow_algorithm_mapping = [];
                                //dispatch(updateWorkflowActionData(workflowData));
                                dispatch(updateInputParams([]));
                              }
                            }}
                          />
                        )}
                      />
                      <Label className="errorMessage">
                        {errors?.workflow
                          ? errors?.workflow?.type?.message
                          : ""}
                      </Label>
                    </Stack.Item>
                  </Stack.Item>

                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg11  ">
                      <ToggleCard
                        heading="Algorithm Sequence*"
                        index=""
                        removeFormFields=""
                      >
                        <Stack.Item className="algorithmSequence deviceInputSection workflowSection">
                          <Stack.Item className="ms-Grid-row ">
                            <Stack.Item className="ms-Grid-col ms-lg1">
                              <Text>Seq No.</Text>
                            </Stack.Item>
                            <Stack.Item className="ms-Grid-col ms-lg2-point-5">
                              <Text>Algorithm Name</Text>
                            </Stack.Item>
                            <Stack.Item className="ms-Grid-col ms-lg2-point-5">
                              <Text>Input Parameter</Text>
                            </Stack.Item>
                            <Stack.Item className="ms-Grid-col ms-lg2-point-5">
                              <Text>Input Parameter Value</Text>
                            </Stack.Item>
                            {showOutPut && (
                              <Stack.Item className="ms-Grid-col ms-lg2-point-5">
                                <Text>Output Required</Text>
                              </Stack.Item>
                            )}
                          </Stack.Item>
                          {indexes.map((i: any, index: any) => {
                            const fieldName = `algorithms[${index}]`;
                            const seqNo = i + 1;
                            const algorithmIds = inputParameters
                              ?.map((algorithm: any, algorithmIndex: any) =>
                                index > algorithmIndex
                                  ? algorithm?.algorithmId
                                  : null
                              )
                              ?.filter(
                                (algorithmId: any) => algorithmId !== null
                              );
                            return (
                              <fieldset
                                name={fieldName}
                                key={fieldName}
                                className="fieldsetInputSection algorithmSequence"
                              >
                                <Stack.Item className="ms-Grid-row displayFlex">
                                  <Stack.Item className="ms-Grid-col ms-lg1">
                                    <TextField
                                      autoComplete="off"
                                      value={index + 1}
                                      defaultValue={index + 1}
                                      // {...register(
                                      //   `${fieldName}.sequence_num`,
                                      //   {
                                      //     valueAsNumber: true,
                                      //   }
                                      // )}
                                      disabled
                                    ></TextField>
                                  </Stack.Item>
                                  <Stack.Item className="ms-Grid-col ms-lg2-point-5 algDropdown">
                                    <Stack.Item>
                                      <Controller
                                        control={control}
                                        defaultValue={
                                          workflowData &&
                                          workflowData
                                            ?.workflow_algorithm_mapping[index]
                                            ?.algorithm
                                        }
                                        name={`${fieldName}.algorithm`}
                                        shouldUnregister
                                        render={({
                                          field: { onChange, value, name, ref },
                                        }) => (
                                          <Select
                                            classNamePrefix="addl-class"
                                            menuPlacement="auto"
                                            onKeyDown={(e) =>
                                              algInputChangeHandler(e)
                                            }
                                            onMenuScrollToBottom={(e) =>
                                              handleScrollAlg(e)
                                            }
                                            options={algorithmOptions?.filter(
                                              (obj: any) =>
                                                inputParameters.findIndex(
                                                  (object: any) => {
                                                    return (
                                                      object?.algorithmId ===
                                                      obj?.value
                                                    );
                                                  }
                                                ) < 0
                                            )}
                                            value={algorithmOptions?.find(
                                              (c: any) => c.value === value
                                            )}
                                            onInputChange={(val) => {
                                              dispatch(
                                                getAlgorithmBusinessList(
                                                  !showOutPut,
                                                  val
                                                )
                                              );
                                            }}
                                            onChange={(val) => {
                                              onChange(val?.value);
                                              dispatch(
                                                algorithmView(val?.value)
                                              );
                                              //     setalgorihmChanage(index);
                                              for (
                                                let klm = index;
                                                klm < inputParameters.length;
                                                klm++
                                              ) {
                                                inputParameters[
                                                  klm
                                                ].inputParameterValue = [];
                                              }

                                              if (index === 0) {
                                                dispatch(
                                                  getInputParameterList(
                                                    "",
                                                    !showOutPut
                                                  )
                                                );
                                              } else {
                                                dispatch(
                                                  getInputParameterList(
                                                    val?.value,
                                                    !showOutPut
                                                  )
                                                );
                                              }
                                              setInputParameter(
                                                index,
                                                val?.inputParams,
                                                val?.value
                                              );
                                            }}
                                            onMenuOpen={() => {
                                              if (index === 0) {
                                                dispatch(
                                                  getInputParameterList(
                                                    "",
                                                    !showOutPut
                                                  )
                                                );
                                              } else {
                                                const previousAlgorithmIds =
                                                  algorithmIds?.join(",");
                                                dispatch(
                                                  getInputParameterList(
                                                    previousAlgorithmIds,
                                                    !showOutPut
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        )}
                                      />
                                    </Stack.Item>
                                    <Label className="errorMessage">
                                      {errors?.algorithms?.length
                                        ? errors?.algorithms[index]?.algorithm
                                            ?.message
                                        : ""}
                                    </Label>
                                  </Stack.Item>
                                  <Stack.Item className="ms-Grid-col ms-lg5-point-0">
                                    {inputParameters[index] ? (
                                      inputParameters[
                                        index
                                      ]?.inputParameter?.map(
                                        (
                                          inputParameter: any,
                                          mainIndex: any
                                        ) => {
                                          const InputAlogrithmString =
                                            workflowData &&
                                            workflowData
                                              ?.workflow_algorithm_mapping[
                                              index
                                            ]?.algorithm_inputs;

                                          const inputParameterValue =
                                            typeof InputAlogrithmString ===
                                              "string" &&
                                            JSON.parse(
                                              InputAlogrithmString.replace(
                                                /'/g,
                                                '"'
                                              )
                                            );
                                          const algorithmValues =
                                            Object.values(inputParameterValue);

                                          return (
                                            <Stack.Item className="ms-Grid-row workflowInput">
                                              <Stack.Item
                                                className={`ms-Grid-col ms-lg6 ${
                                                  mainIndex ? "" : ""
                                                } `}
                                              >
                                                <TextField
                                                  autoComplete="off"
                                                  // {...register(
                                                  //   `${fieldName}.algorithm_inputsArr[${mainIndex}].input_parameter`
                                                  // )}
                                                  value={
                                                    inputParameter
                                                    // ""
                                                  }
                                                  defaultValue=""
                                                  disabled
                                                ></TextField>
                                              </Stack.Item>
                                              <Stack.Item className="ms-Grid-col ms-lg6 algDropdown">
                                                <Stack.Item>
                                                  <Controller
                                                    control={control}
                                                    defaultValue={
                                                      algorithmValues[mainIndex]
                                                    }
                                                    shouldUnregister
                                                    name={`${fieldName}.algorithm_inputsArr[${mainIndex}].input_parameter_value`}
                                                    render={({
                                                      field: {
                                                        onChange,
                                                        value,
                                                        name,
                                                        ref,
                                                      },
                                                    }) => (
                                                      <Select
                                                        classNamePrefix="addl-class"
                                                        menuPlacement="auto"
                                                        isLoading={loadingState}
                                                        options={
                                                          apiCall
                                                            ? inputParameters[
                                                                index
                                                              ]
                                                                ?.inputParameterValue
                                                            : getInputParameterlistValues(
                                                                index
                                                              )
                                                        }
                                                        value={getInputparametervalue(
                                                          index,
                                                          value
                                                        )}
                                                        onChange={(val) => {
                                                          onChange(val?.value);
                                                        }}
                                                        onMenuScrollToBottom={getInputParameterlistValues(
                                                          index
                                                        )}
                                                        onMenuOpen={() => {
                                                          // console.log(algorithmIds, 'algorithmIds');
                                                          setApiCall(false);
                                                          const previousAlgorithmIds =
                                                            algorithmIds?.join(
                                                              ","
                                                            );
                                                          getAlgorithmValuesList(
                                                            !showOutPut,
                                                            previousAlgorithmIds,
                                                            index
                                                          );
                                                          //  dispatch(
                                                          //     getInputParameterList(
                                                          //       previousAlgorithmIds,
                                                          //       !showOutPut
                                                          //     )
                                                          //   );
                                                        }}
                                                      />
                                                    )}
                                                  />
                                                </Stack.Item>
                                                <Label className="errorMessage">
                                                  {errors?.algorithms &&
                                                  errors?.algorithms[index]
                                                    ?.algorithm_inputsArr
                                                    ? errors?.algorithms[index]
                                                        ?.algorithm_inputsArr[
                                                        mainIndex
                                                      ]?.input_parameter_value
                                                        ?.message
                                                    : ""}
                                                </Label>
                                              </Stack.Item>
                                            </Stack.Item>
                                          );
                                        }
                                      )
                                    ) : (
                                      <>
                                        <Stack.Item className="ms-Grid-col ms-lg6">
                                          <TextField
                                            autoComplete="off"
                                            // {...register(`${fieldName}.inp`)}
                                            defaultValue=""
                                            disabled
                                          ></TextField>
                                        </Stack.Item>
                                        <Stack.Item className="ms-Grid-col ms-lg6 algDropdown">
                                          <Stack.Item>
                                            <Select
                                              classNamePrefix="addl-class"
                                              options={options1}
                                              onChange={(val) => {}}
                                            />
                                          </Stack.Item>
                                        </Stack.Item>
                                      </>
                                    )}
                                  </Stack.Item>

                                  {showOutPut && (
                                    <Stack.Item className="ms-Grid-col ms-lg2-point-5 algDropdown">
                                      <Stack.Item>
                                        <Controller
                                          control={control}
                                          defaultValue={
                                            workflowData &&
                                            workflowData
                                              ?.workflow_algorithm_mapping[
                                              index
                                            ]?.save_output
                                          }
                                          shouldUnregister
                                          name={`${fieldName}.save_output`}
                                          render={({
                                            field: {
                                              onChange,
                                              value,
                                              name,
                                              ref,
                                            },
                                          }) => (
                                            <Select
                                              classNamePrefix="addl-class"
                                              options={outputRequired}
                                              value={outputRequired.find(
                                                (c: any) => c.value === value
                                              )}
                                              onChange={(val) => {
                                                onChange(val?.value);
                                              }}
                                            />
                                          )}
                                        />
                                      </Stack.Item>
                                      <Label className="errorMessage">
                                        {errors?.algorithms &&
                                        errors?.algorithms[i]
                                          ? errors?.algorithms[i]?.save_output
                                              ?.message
                                          : ""}
                                      </Label>
                                    </Stack.Item>
                                  )}
                                  {index !== 0 ? (
                                    <Stack.Item className="ms-Grid-col">
                                      <IconButton
                                        iconProps={deleteIcon}
                                        aria-label="Delete"
                                        onClick={() => handleRemove(index)}
                                      />
                                    </Stack.Item>
                                  ) : (
                                    <Stack.Item className="ms-Grid-col width-20">
                                      {" "}
                                    </Stack.Item>
                                  )}
                                </Stack.Item>
                              </fieldset>
                            );
                          })}
                          <Stack.Item className="ms-Grid-row ">
                            <Stack
                              horizontal
                              horizontalAlign="end"
                              verticalAlign="center"
                              className="addProcedure"
                            >
                              <Stack.Item
                                className="pointer"
                                onClick={addAlgorithmSequence}
                              >
                                <Text>Add Another Field</Text>
                                <AddFacilityIcon />
                              </Stack.Item>
                            </Stack>
                          </Stack.Item>
                        </Stack.Item>
                      </ToggleCard>
                      {showOutPut && (
                        <>
                          <Stack.Item className="ms-Grid-row algDropdown">
                            <Stack.Item
                              className="ms-Grid-col ms-lg5"
                              style={{
                                position: "relative",
                                bottom: "10px",
                                float: "left",
                              }}
                            >
                              <Label>Main Video Parameter*</Label>
                              <Controller
                                control={control}
                                defaultValue={
                                  workflowData && workflowData?.streaming_point
                                }
                                shouldUnregister
                                name={"workflow.streaming_point"}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => (
                                  <Select
                                    classNamePrefix="addl-class"
                                    menuPlacement="auto"
                                    options={
                                      !v1Options?.length
                                        ? inputParameterValueOptions
                                        : v1Options
                                    }
                                    // options={
                                    //   !v1Options?.length
                                    //     ? getmainVideoPathOptions(values)
                                    //     : v1Options
                                    // }
                                    // value={mainVideoPathOptions?.find(
                                    //   (c: any) => c.value === value
                                    // )}
                                    value={getMainvideoParams(value)}
                                    onChange={(val) => {
                                      onChange(val?.value);
                                      setv2Options(
                                        activSightOverlayoptions(values)
                                      );
                                    }}
                                    onMenuOpen={() => {
                                      const algorithmIds = inputParameters?.map(
                                        (algorithm: any, algorithmIndex: any) =>
                                          algorithm?.algorithmId
                                      );
                                      const previousAlgorithmIds =
                                        algorithmIds?.join(",");
                                      dispatch(
                                        getInputParameterList(
                                          previousAlgorithmIds,
                                          !showOutPut
                                        )
                                      );
                                    }}
                                  />
                                )}
                              />
                              <Label className="errorMessage">
                                {errors?.workflow
                                  ? errors?.workflow?.streaming_point?.message
                                  : ""}
                              </Label>
                            </Stack.Item>
                            {/* </Stack.Item> */}

                            {/* <Stack.Item > */}
                            <Stack.Item
                              className="ms-Grid-col ms-lg5"
                              style={{
                                position: "relative",
                                bottom: "10px",
                                float: "right",
                              }}
                            >
                              <Label>ActivSight Overlay Path</Label>
                              <Controller
                                control={control}
                                defaultValue={
                                  workflowData &&
                                  workflowData?.activ_sight_overlay_path
                                }
                                shouldUnregister
                                name={"workflow.activ_sight_overlay_path"}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => (
                                  <Select
                                    classNamePrefix="addl-class"
                                    menuPlacement="auto"
                                    options={
                                      !v2Options?.length
                                        ? activSightOverlayoptions(values)
                                        : v2Options
                                    }
                                    // value={
                                    //  mainVideoPathOptions?.find(
                                    //         (c: any) => c.value === value
                                    //       )

                                    // }
                                    value={getMainvideoParamsSight(value)}
                                    onChange={(val) => {
                                      onChange(val?.value);
                                      setv1Options(
                                        getmainVideoPathOptions(values)
                                      );
                                    }}
                                    onMenuOpen={() => {
                                      const algorithmIds = inputParameters?.map(
                                        (algorithm: any, algorithmIndex: any) =>
                                          algorithm?.algorithmId
                                      );
                                      const previousAlgorithmIds =
                                        algorithmIds?.join(",");
                                      dispatch(
                                        getInputParameterList(
                                          previousAlgorithmIds,
                                          !showOutPut
                                        )
                                      );
                                    }}
                                  />
                                )}
                              />
                            </Stack.Item>
                            {/* </Stack.Item> */}
                          </Stack.Item>
                        </>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </form>
          <Stack
            className="createHospitalWizardFooter"
            horizontal
            horizontalAlign="end"
          >
            <Stack.Item className="nextCancel">
              <PrimaryButton
                disabled={disabledCreate}
                type="submit"
                form="workflow"
                className="btn-next"
              >
                {!showCreateWorkflow ? "Add" : "Update"}
                <Text className="spinnerRight">
                  {disabledCreate && <Spinner size={SpinnerSize.small} />}
                </Text>
              </PrimaryButton>
              <ActionButton
                text="Cancel"
                className="btn-c"
                allowDisabledFocus
                onClick={showModal}
              />
            </Stack.Item>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CreateWorkflow;
