import * as allActions from "../actions/actionConstants";

const initialState: any = {
  videosData: {},
  tempVideosData: {},
  favouriteVideoEditedFields: {},
  showSpinner: true,
  showData: false,
  currentPage: 1,
};

export const favouritesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_FAVORITE_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_VIDEOS_FAVORITES:
      return {
        ...state,
        videosData: action.payload.data,
        showSpinner:
          action.payload &&
          action.payload.data &&
          action.payload.data.results.length &&
          false,
        showData:
          action.payload &&
          action.payload.data &&
          action.payload.data.results.length &&
          true,
        currentPage: action.payload.currentPage,
      };
    case allActions.SELECT_FAVOURITE_VIDEO:
      const videosData = {
        ...state.videosData,
        results: state.videosData.results.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          } else {
            return {
              ...item,
              isSelected: false,
            };
          }
        }),
      };
      return {
        ...state,
        videosData: videosData,
        tempVideosData: videosData,
      };
    case allActions.SET_SELECTED_FAVORITE_VIDEO_UNFAVOURITE:
      return {
        ...state,
        videosData: {
          ...state.videosData,
          results: state.videosData.results.map((item: any) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                favorite: !item.favorite,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
      };
    case allActions.EDIT_FAVORITE_VIDEO_DETAILS:
      return {
        ...state,
        videosData: {
          ...state.videosData,
          results: state.videosData.results.map((item: any) => {
            if (item.id === action.payload.videoId) {
              return {
                ...item,
                ...state.favouriteVideoEditedFields,
                ...action.payload.data,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
        favouriteVideoEditedFields: {
          ...state.favouriteVideoEditedFields,
          ...action.payload.data,
        },
      };
    case allActions.RESET_EDITED_DATA_ON_CANCEL_FAVORITES:
      return {
        ...state,
        videosData:
          action.payload === "cancel" ? state.tempVideosData : state.videosData,
        tempVideosData:
          action.payload === "cancel" ? state.tempVideosData : state.videosData,
        favouriteVideoEditedFields: {},
      };
    case allActions.CLEAR_FAVORITES_DATA:
      return {
        videosData: {},
        favouriteVideoEditedFields: {},
        showSpinner: true,
        showData: false,
        currentPage: 1,
      };
    default:
      return state;
  }
};
