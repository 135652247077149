import { IStackItemStyles, Modal, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ThemeColorPalette } from "../../theme";
import {
  NAV_LINK_GROUPS,
  NAV_OPTIONS,
  ROLE_TYPES,
} from "../constants/constants";
import Header from "../containers/header/Header";
import Navbar from "../containers/navbar/Navbar";
import ActiveAdminRoutes from "./ActiveAdminRoutes";
import HospitalAdminRoutes from "./HospitalAdminRoutes";
import SurgeonRoutes from "./SurgeonRoutes";
import ManagementNavBar from "../../ActiveAdminModule/Management/containers/ManagementNavBar";
import HospitalManagementNavBar from "../../HospitalAdminModule/Management/containers/HospitalManagementNavBar";
import ShowErrorMessageBar from "../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import { closeErrorRsponse } from "../../ActiveAdminModule/Management/actions/managamentActions";
import { AppState } from "../../rootReducer";
import DeveloperRoutes from "./DeveloperRoutes";
import DeveloperManagementNavBar from "../../DeveloperModule/Management/containers/DeveloperManagementNavBar";
import ActivHeader from "../containers/header/ActivHeader";
import {
  closeErrorPassword,
  showChangePassword,
} from "../../auth/actions/authAction";
import SuccessMessage from "../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import MultiRoleConfirm from "./multiRoleConfirm";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export interface LayoutProps {}

export interface LayoutState {}
const containerStyles: IStackItemStyles = {
  root: {
    backgroundColor: "black",
    minHeight: "100vh",
    maxHeight: "100%",
    minWidth: "100vh",
    maxWidth: "100%",
  },
};

const stackItemRightStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.pureBlack,
    color: ThemeColorPalette.pureWhite,
    flex: 1,
  },
};

function Layout() {
  const dispatch = useDispatch();
  const { loggedInUserData } = useSelector(
    (state: any) => state.authReducerData
  );
  const showVideoUploadMessage = useSelector(
    (state: any) => state.dashboardReducerData.videoUploadFail
  );
  const role = localStorage.getItem("role")
    ? localStorage.getItem("role")
    : loggedInUserData?.data;
  const isSwitchRole = localStorage.getItem("switch_account") === "true";
  const navOptions = (NAV_OPTIONS as any)[role];
  const navLinkGroups = (NAV_LINK_GROUPS as any)[role];
  const location = useLocation();
  const management = location?.pathname.split("/");
  const [choice, setChoice] = React.useState<boolean | undefined>(false);
  const [isSwitchAccount, setSwitchAccount] =
    React.useState<boolean>(isSwitchRole);
  const remmberme = localStorage.getItem("isremember") === "true";
  const tabClick = localStorage.getItem("sideBar") === "true";
  const panelNavigation = localStorage.getItem("panelNavigation") === "true";
  const refreshToken = localStorage.getItem("refreshToken");
  const isEditAdminAccessOpen =
    localStorage.getItem("isEditAdminAccessOpen") === "true";

  const errorResponse = useSelector((state: AppState) => {
    return state.managementReducerData?.errorResponse?.data;
  });
  const errorResponseInHome = useSelector((state: AppState) => {
    return state.homeReducerData.createHospitalError;
  });
  const errorResponsePassword = useSelector((state: AppState) => {
    return state.authReducerData.errorResponsePassword;
  });
  const ChangePasswordSpinner = useSelector((state: AppState) => {
    return state.authReducerData.ChangePasswordSpinner;
  });

  React.useEffect(() => {
    ChangePasswordSpinner &&
      setTimeout(() => {
        dispatch(showChangePassword());
      }, 2000) && <></>;
  }, [ChangePasswordSpinner]);

  const resetChoice = React.useCallback(() => {
    setChoice((current: any) => !current);
    dispatch(closeErrorRsponse());
    dispatch(closeErrorPassword());
  }, []);
  const clearroles = () => {
    setTimeout(() => {
      localStorage.setItem("panelNavigation", "false");
      localStorage.setItem("isEditAdminAccessOpen", JSON.stringify(false));
      //     setSwitchAccount(false);
    }, 2000);

    return true;
  };
  const navigate = useNavigate();
  const pathname = window?.location?.pathname?.length > 1;

  const selectRole = (roleName: string) => {
    localStorage.setItem("role", roleName);
    setSwitchAccount(false);
    navigate(`/`);
  };
  // console.log(management, management[1], management[2], location, "management");
  return (
    <>
      {showVideoUploadMessage && (
        <ShowErrorMessageBar
          resetChoice={() => dispatch({ type: "HIDE_VIDEO_UPLOAD_ERROR" })}
          errorResponse={showVideoUploadMessage}
        ></ShowErrorMessageBar>
      )}
      {!panelNavigation &&
      isSwitchAccount &&
      !remmberme &&
      !tabClick &&
      refreshToken &&
      !pathname ? (
        <MultiRoleConfirm onRoleSelect={selectRole} />
      ) : (
        clearroles() && (
          <Stack verticalFill>
            <Stack horizontal styles={containerStyles}>
              <StackItem
                styles={{
                  root: {
                    width: "88px",
                    position: "fixed",
                    height: "100vh",
                    zIndex: 99,
                  },
                }}
              >
                <Navbar
                  navOptions={navOptions}
                  navLinkGroups={navLinkGroups}
                ></Navbar>
              </StackItem>
              <StackItem
                className="main-content-overflow"
                styles={{
                  root: { width: "100%", display: "flex", paddingLeft: "90px" },
                }}
              >
                <Stack verticalFill styles={stackItemRightStyles}>
                  <StackItem
                    styles={{
                      root: {
                        position: "sticky",
                        width: "100%",
                        top: 0,
                        zIndex: 99999,
                      },
                    }}
                  >
                    {role === ROLE_TYPES.ACTIVE_ADMIN ||
                    role === ROLE_TYPES.ACTIVE_HOSPITAL_ADMIN ||
                    role === ROLE_TYPES.ACTIVE_DEVELOPER ? (
                      <ActivHeader></ActivHeader>
                    ) : (
                      // role === ROLE_TYPES.ACTIVE_DEVELOPER ||
                      role === ROLE_TYPES.ACTIVE_SURGEON && <Header></Header>
                    )}
                    {(errorResponse ||
                      errorResponseInHome ||
                      errorResponsePassword) && (
                      <ShowErrorMessageBar
                        resetChoice={resetChoice}
                        errorResponse={
                          errorResponse ||
                          errorResponseInHome ||
                          errorResponsePassword
                        }
                      ></ShowErrorMessageBar>
                    )}
                    {(errorResponse ||
                      errorResponseInHome ||
                      errorResponsePassword) &&
                      setTimeout(() => {
                        resetChoice();
                      }, 5000) && <></>}
                  </StackItem>
                  <StackItem styles={{ root: { height: "100%" } }}>
                    {role === ROLE_TYPES.ACTIVE_SURGEON && <SurgeonRoutes />}
                    {role === ROLE_TYPES.ACTIVE_HOSPITAL_ADMIN && (
                      // <HospitalAdminRoutes />
                      <Stack.Item
                        className={`${
                          management[1] === "management"
                            ? "dashboardManagementRoute"
                            : "dashboardRoute"
                        } `}
                        styles={{
                          root: {
                            childrenGap: 20,
                          },
                        }}
                      >
                        {management[1] === "management" ? (
                          <HospitalManagementNavBar
                            location={management[2]}
                          ></HospitalManagementNavBar>
                        ) : (
                          ""
                        )}
                        <HospitalAdminRoutes></HospitalAdminRoutes>
                      </Stack.Item>
                    )}
                    {role === ROLE_TYPES.ACTIVE_ADMIN && (
                      // <ActiveAdminRoutes />
                      <Stack.Item
                        className={`${
                          management[1] === "management"
                            ? "dashboardManagementRoute"
                            : "dashboardRoute"
                        } `}
                        styles={{
                          root: {
                            childrenGap: 20,
                          },
                        }}
                      >
                        {management[1] === "management" ? (
                          <ManagementNavBar
                            location={management[2]}
                          ></ManagementNavBar>
                        ) : (
                          ""
                        )}
                        <ActiveAdminRoutes></ActiveAdminRoutes>
                      </Stack.Item>
                    )}
                    {role === ROLE_TYPES.ACTIVE_DEVELOPER && (
                      // <ActiveAdminRoutes />
                      <Stack.Item
                        id={
                          window?.location?.href.split("/").length >= 7
                            ? "datasetpage"
                            : ""
                        }
                        className={`${
                          management[1] === "management"
                            ? "dashboardManagementRoute"
                            : "dashboardRoute"
                        } `}
                        styles={{
                          root: {
                            childrenGap: 20,
                          },
                        }}
                      >
                        {management[1] === "management" ? (
                          <DeveloperManagementNavBar
                            location={management[2]}
                          ></DeveloperManagementNavBar>
                        ) : (
                          ""
                        )}
                        <DeveloperRoutes></DeveloperRoutes>
                      </Stack.Item>
                    )}
                  </StackItem>
                </Stack>
              </StackItem>
            </Stack>
          </Stack>
        )
      )}
      <Modal isOpen={ChangePasswordSpinner} containerClassName={"modalStyle"}>
        <SuccessMessage
          title="Password Changed"
          subText="To Continue Please Login Again..."
        />
      </Modal>
    </>
  );
}

export default Layout;
