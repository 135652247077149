import {
  FontIcon,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../theme";
import "../containers/MyProcedures/MyProcedures.scss";
import DateRangeDropDown from "./DateRangeDropdown";

export interface ProceduresDataCardProps {
  data: Array<any>;
  handleProcedureClick: any;
  showData: boolean;
  showSpinner: boolean;
}

export interface ProceduresDataCardState {}

class ProceduresDataCard extends React.Component<
  ProceduresDataCardProps,
  ProceduresDataCardState
> {
  state: any = {
    procedureMaxLength: [],
    proceduresListAvailable: 0,
    selectedData: 0,
  };

  componentDidMount() {
    this.props.handleProcedureClick(this.props.data[0]);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.data !== prevProps.data) {
      const { data } = this.props;
      const proceduresListAvailable =
        data &&
        data.length &&
        data.filter((item: any) => item.videosList.length >= 0).length;
      const procedureMaxLength =
        data && data.length && data.map((item: any) => item.videosList.length);
      const selectedData =
        data &&
        data.length &&
        data.filter((item: any) => item.isSelected).length;

      this.setState({
        ...this.state,
        proceduresListAvailable: proceduresListAvailable,
        procedureMaxLength: procedureMaxLength,
        selectedData: selectedData,
      });
    }
  }

  render() {
    const { handleProcedureClick, data } = this.props;

    const { proceduresListAvailable, procedureMaxLength, selectedData } =
      this.state;

    return (
      <Stack
        styles={{
          root: {
            minHeight: "42vh",
            maxHeight: "320px",
            minWidth: "100%",
            maxWidth: "100vh",
            backgroundColor: ThemeColorPalette.gridBG,
            boxShadow: "0 8px 14px 5px rgba(0,0,0,0.14)",
            padding: "1.2rem 1.8rem",
            overflowY: "scroll",
          },
        }}
        tokens={{ childrenGap: 10 }}
        className="scroll"
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          styles={{ root: { marginBottom: "0.5rem" } }}
        >
          <StackItem align="center">
            <Text
              variant="large"
              styles={{
                root: {
                  fontFamily: fonts.DINPro_Medium,
                  color: ThemeColorPalette.uploadMsgTxt,
                },
              }}
            >
              Procedures
            </Text>
          </StackItem>

          <Stack horizontal verticalAlign="center">
            <StackItem align="center">
              <Text
                variant="small"
                styles={{
                  root: {
                    marginRight: "0.5rem",
                    color: ThemeColorPalette.videoUploadDateTxt,
                    fontFamily: fonts.HelveticaNeue_Medium,
                  },
                }}
              >
                Date Range
              </Text>
            </StackItem>
            <DateRangeDropDown />
          </Stack>
        </Stack>

        {this.props.showSpinner ? (
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { paddingTop: "10%" } }}
          >
            <Spinner size={SpinnerSize.large} />
          </Stack>
        ) : proceduresListAvailable > 0 ? (
          data.map((item: any, i: number) => (
            <Stack
              key={i}
              horizontal
              styles={{
                root: {
                  padding: "0.5rem",
                  border: "1px solid " + ThemeColorPalette.LandingPageBorder,
                  backgroundColor:
                    selectedData === 0 && i === 0
                      ? ThemeColorPalette.selectedGridBG
                      : item.isSelected
                      ? ThemeColorPalette.selectedGridBG
                      : "",
                  cursor: "pointer",
                },
              }}
              tokens={{ childrenGap: 10 }}
              onClick={() => handleProcedureClick(item)}
            >
              <StackItem
                align="center"
                styles={{
                  root: {
                    visibility:
                      selectedData === 0 && i === 0
                        ? ""
                        : item.isSelected
                        ? ""
                        : "hidden",
                  },
                }}
              >
                <FontIcon
                  iconName="SkypeCircleCheck"
                  style={{ color: ThemeColorPalette.themePrimary }}
                />
              </StackItem>
              <StackItem
                align="center"
                styles={{ root: { flex: 1, padding: "0 2rem 0 0.2rem" } }}
                className="textEllipses"
              >
                <Stack horizontal horizontalAlign="space-between">
                  <Text
                    variant="smallPlus"
                    styles={{
                      root: {
                        fontFamily: fonts.HelveticaNeue_Medium,
                        color: ThemeColorPalette.uploadMsgTxt,
                      },
                    }}
                    title={item.procedureName}
                    block
                  >
                    {item.procedureName?.slice(0, 45)}
                  </Text>
                  <Text
                    variant="tiny"
                    styles={{
                      root: {
                        fontFamily: fonts.HelveticaNeue_Medium,
                        color: ThemeColorPalette.videoUploadDateTxt,
                      },
                    }}
                    style={{ textAlign: "right" }}
                  >
                    {item.videosList.length} Videos
                  </Text>
                </Stack>
                <ProgressIndicator
                  barHeight={4}
                  percentComplete={
                    item.videosList.length &&
                    item.videosList.length / Math.max(...procedureMaxLength)
                  }
                  styles={{
                    progressBar: {
                      backgroundColor: item.color,
                    },
                  }}
                  className="progressBar"
                />
              </StackItem>
            </Stack>
          ))
        ) : this.props.showSpinner ? (
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { paddingTop: "15%" } }}
          >
            <Spinner size={SpinnerSize.large} />
          </Stack>
        ) : (
          proceduresListAvailable?.length === 0 &&
          data?.length === 0 && (
            <Stack
              horizontal
              horizontalAlign="center"
              styles={{ root: { paddingTop: "15%" } }}
            >
              <Text
                styles={{
                  root: {
                    fontFamily: fonts.DINPro_Medium,
                    color: ThemeColorPalette.uploadVideoBtnTxt,
                    textAlign: "center",
                  },
                }}
              >
                No videos available
              </Text>
            </Stack>
          )
        )}
      </Stack>
    );
  }
}

export default React.memo(ProceduresDataCard);
