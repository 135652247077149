import { Stack, TextField, Text } from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  createDevice,
  editDevice,
  deviceIncrement,
  updateDeviceActionData,
  showDeviceSpinner,
} from "../../../actions/managamentActions";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ToggleCard from "../../../../Home/components/Wizard/ToggleCard";
import { AppState } from "../../../../../rootReducer";
import React, { useRef, useState } from "react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const Provisioning = ({
  deviceViewData,
  showCreateDevice,
  deviceSerialNumber,
  spinnerFunc,
}: {
  deviceViewData: any;
  showCreateDevice: boolean;
  deviceSerialNumber: any;
  spinnerFunc: any;
}) => {
  const dispatch = useDispatch();
  const ipAddress = RegExp(
    /^(((1?[1-9]?|10|2[0-4])\d|25[0-5])($|\.(?!$))){4}$/
  );
  const validationSchema = Yup.object().shape({
    enrollment_mechanism: Yup.string().required(
      "enrollment mechanism is required"
    ),
    external_ip: Yup.string()
      .required("ip address is required")
      .matches(ipAddress, "Invalid ip address"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm(formOptions);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");

  const [ipAddressOnchange, setIpAddressOnchange] = useState("");
  const [enrollmentOnchange, setEnrollmentOnchange] = useState("");

  const [fileSec, setFileSec] = useState("");
  const [fileSecName, setFileSecName] = useState("");

  const primary_certificate = useRef<HTMLInputElement | null>(null);
  const secondary_certificate = useRef<HTMLInputElement | null>(null);

  const deviceData = useSelector((state: AppState) => {
    return state.managementReducerData.deviceData.data;
  });
  const onChangePrimary = (e: any) => {
    // console.log(e.target.files[0]);
    const file = e.target.files[0];
    file && setFile(file);
    file && setFileName(file?.name);
  };
  const onChangeSecondary = (e: any) => {
    const file = e.target.files[0];
    file && setFileSec(file);
    file && setFileSecName(file?.name);
  };
  const onSubmit = (data: any) => {
    spinnerFunc(true);
    // console.log(data, "data--");
    // const formData = new FormData();
    // formData.append("primary_certificate", file);
    // formData.append("secondary_certificate", fileSec);
    // formData.append("secondary_certificate", data.external_ip);
    // formData.append("secondary_certificate", data.enrollment_mechanism);
    // formData.append("serial_number", deviceData.serial_number);
    // formData.append("model", deviceData.model);
    // formData.append("mac_address", deviceData.mac_address);
    // formData.append("operating_system", deviceData.operating_system);
    // formData.append("os_version", deviceData.os_version);
    // data.primary_certificate = formData;
    // console.log(formData, "formData", deviceData, "deviceData", data, "data");
    const device = { ...deviceData, ...data };
    dispatch(showDeviceSpinner());
    dispatch(updateDeviceActionData(device));
    dispatch(deviceIncrement());
    showCreateDevice && dispatch(createDevice(device));
    !showCreateDevice && dispatch(editDevice(deviceSerialNumber, device));
  };
  // const { sadds, ...rest1 } =register("secondary_certificate")

  return (
    <Stack>
      <Stack.Item className="ms-Grid devicePreview" dir="ltr">
        <Stack.Item className="ms-Grid-row">
          <Stack.Item className="ms-Grid-col ms-lg2 ">
            <Text>Device Details</Text>
          </Stack.Item>
          <Stack.Item className="ms-Grid-col ms-lg2">
            <Text block className="heading">
              Serial Number
            </Text>
            <Text>{deviceData.serial_number}</Text>
          </Stack.Item>
          <Stack.Item className="ms-Grid-col ms-lg2">
            <Text block className="heading">
              Model Number
            </Text>
            <Text>{deviceData.model}</Text>
          </Stack.Item>
          <Stack.Item className="ms-Grid-col ms-lg2">
            <Text block className="heading">
              MAC Address
            </Text>
            <Text>{deviceData.mac_address}</Text>
          </Stack.Item>
          <Stack.Item className="ms-Grid-col ms-lg2">
            <Text block className="heading">
              OS
            </Text>
            <Text>{deviceData.operating_system}</Text>
          </Stack.Item>
          <Stack.Item className="ms-Grid-col ms-lg2">
            <Text block className="heading">
              OS Version
            </Text>
            <Text>{deviceData.os_version}</Text>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
      {(deviceData || showCreateDevice) && (
        <form id="deviceForm" onSubmit={handleSubmit(onSubmit)}>
          <ToggleCard
            heading="Device Provisioning Details"
            index=""
            removeFormFields=""
          >
            <Stack className="deviceInputSection ms-Grid-row">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3 ">
                      <TextField
                        autoComplete="off"
                        label="IP Address*"
                        {...register("external_ip")}
                        onChange={(e: any) => {
                          setIpAddressOnchange(e?.target?.value);
                          errors.external_ip = "";
                        }}
                        defaultValue={deviceData?.external_ip}
                        errorMessage={
                          errors?.external_ip && errors?.external_ip?.message
                        }
                      ></TextField>
                      <TextField
                        autoComplete="off"
                        label="Enrollment Mechanism*"
                        {...register("enrollment_mechanism")}
                        onChange={(e: any) => {
                          setEnrollmentOnchange(e?.target?.value);
                          errors.enrollment_mechanism = "";
                        }}
                        defaultValue={deviceData?.enrollment_mechanism}
                        errorMessage={
                          errors?.enrollment_mechanism &&
                          errors?.enrollment_mechanism?.message
                        }
                      ></TextField>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row">
                    {/* <Stack.Item className="ms-Grid-col ms-lg1"> </Stack.Item> */}
                    <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset1">
                      {/* <Stack.Item
                      onClick={() =>
                        primary_certificate.current &&
                        primary_certificate.current.click()
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={primary_certificate}
                        onChange={onChangePrimary}
                      />
                      <TextField
                        label="Primary Certificate(.pem/.ver file)"
                        value={fileName}
                        readOnly
                        suffix="Choose File"
                      ></TextField>
                    </Stack.Item>
                    <Stack.Item
                      onClick={() =>
                        secondary_certificate.current &&
                        secondary_certificate.current.click()
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={secondary_certificate}
                        onChange={onChangeSecondary}
                      />
                      <TextField
                        value={fileSecName}
                        label="Secondary Certificate(.pem/.ver file)"
                        readOnly
                        suffix="Choose File"
                      ></TextField>
                    </Stack.Item> */}
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack>
          </ToggleCard>
        </form>
      )}
    </Stack>
  );
};

export default Provisioning;
