import * as allActions from "../actions/actionConstants";

const initialState: any = {
  recentActivitiesList: [],
  nextPage: "",
  showSpinner: true,
  showData: false,
  hospitalId: "",
  hospitalName: "",
  facilities: [],
  activities: [],
  activityTypes: [],
  loadingState: false,
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
};

export const hospitalActivitiesData = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_RECENT_ACTIVITIES_DATA:
      return {
        ...state,
        recentActivitiesList: [
          ...state.recentActivitiesList,
          ...action.payload.currentPageData,
        ],
        nextPage: action.payload.next,
        showSpinner:
          action.payload &&
          action.payload.currentPageData &&
          action.payload.currentPageData.length &&
          false,
        showData:
          action.payload &&
          action.payload.currentPageData &&
          action.payload.currentPageData.length &&
          true,
      };
    case allActions.LOADER:
      return {
        ...state,
        loaderWithMessage: {
          loadingState: true,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
        },
      };
    case allActions.STOPLOADER:
      return {
        ...state,
        loaderWithMessage: {
          loadingState: false,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
          statusCode: action.payload?.statusCode,
        },
      };
    case allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT:
      return {
        ...state,
        recentActivitiesList: action.payload,
      };
    case allActions.CLEAR_ALL_RECENT_ACTIVITES:
      return {
        recentActivitiesList: [],
        nextPage: "",
      };

    case allActions.GET_HOSPITAL_ID:
      return {
        ...state,
        hospitalName: action.payload?.data?.results[0].name,
        hospitalId: action.payload?.data?.results[0].id,
        hospitalUniqueIdentifier:
          action.payload?.data?.results[0].unique_identifier,
      };

    case allActions.GET_LOG_FACILITIES:
      const currentList = state.facilities?.results || [];
      const previousData = action.payload.isNext ? currentList || [] : [];
      const newData = action.payload.data;
      newData.results = previousData.concat(newData.results);
      return {
        ...state,
        facilities: newData,
      };

    case allActions.GET_ACTIVITY:
      return {
        ...state,
        activities: action.payload,
      };
    case allActions.GET_HOSPITAL_ADMIN_ACTIVITY_TYPES:
      const currentAcivitiesTypesList = state.activityTypes?.results || [];
      const previousActivitiesTypeData = action.payload.isNext
        ? currentAcivitiesTypesList || []
        : [];
      const updatedActivitiesType = action.payload.data;
      updatedActivitiesType.results = previousActivitiesTypeData.concat(
        updatedActivitiesType.results
      );
      return {
        ...state,
        activityTypes: updatedActivitiesType,
      };

    default:
      return state;
  }
};
