import React, { FormEvent, useCallback, useEffect, useState } from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  DefaultButton,
  PrimaryButton,
  TextField,
  Label,
  Dropdown,
  IDropdownOption,
  ActionButton,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  createSingleProcedure,
  createSpecialty,
  editProcedure,
  getProcedureList,
  getProcedures,
  showRoleSpinner,
  updateSpaceialtyActionData,
} from "../../actions/managamentActions";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import { AppState } from "../../../../rootReducer";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndPoint, doHttpCall } from "../../../../common/restApi";
import ToggleCard from "../../../Home/components/Wizard/ToggleCard";
import Select from "react-select";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const ProcedureTypeEdit = ({
  isModalOpen,
  showModal,
  closeModal,
}: {
  isModalOpen: any;
  showModal: any;
  closeModal: any;
}) => {
  const dispatch = useDispatch();
  const procedureData = useSelector((state: AppState) => {
    return state.managementReducerData.procedureData?.data;
  });
  const [showCreateProcedure, setShowCreateProcedure] = useState(true);
  const [procedureOnChange, setprocedureOnChange] = useState("");
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [flagValue, setFlag] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const isprocedureNameChanged =
    procedureOnChange === "" ? procedureData?.name : procedureOnChange;
  const isprocedureChanged = procedureData?.name !== isprocedureNameChanged;

  const validationSchema = Yup.object().shape({
    procedure: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Procedure is required")
          .required("Name is required")
          .test({
            message: "This Procedure is already taken",
            test: async (value) => {
              if (procedureOnChange !== "" && isprocedureChanged) {
                const checkFieldName = `/procedures/types?search=${value}`;
                const payload = {
                  method: "GET",
                  url: `${apiEndPoint}${checkFieldName}`,
                };
                const response = await doHttpCall(payload);
                const returnStatus =
                  // response.data.results.length > 0 ? false : true;
                  response.count > 0 ? false : true;
                // debugger;
                return returnStatus;
              } else {
                return true;
              }
            },
          }),
        speciality: Yup.array().required("Specialty is required"),
      })
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    resetField,
    reset,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);

  const _alertClicked = useCallback(() => {
    const values = getValues();
    values && values.procedure.splice(0, 1);
    closeModal();
  }, []);

  // const _alertClicked1 = useCallback(() => {
  //   const values = getValues();
  //   values.specialty.splice(0, 1);
  //   closeModal();
  // }, []);

  const procedures = useSelector((state: AppState) => {
    return state.managementReducerData.procedureList.data;
  });
  const specialties = useSelector((state: AppState) => {
    return state.managementReducerData.specialtyList.data?.results;
  });
  const procedureSpinner = useSelector((state: AppState) => {
    return state.managementReducerData.procedureSpinner;
  });

  const specialtiesOption = specialties?.map((specialtie: any) => ({
    key: specialtie.name,
    text: specialtie.name,
  }));

  const procedureSingleList = useSelector((state: AppState) => {
    return state.managementReducerData.procedureSingleList?.data;
  });

  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });

  // console.log(procedureSingleList, "procedureSingleList");

  const proceduresOption = procedureSingleList?.results?.map(
    (procedure: any) => ({
      label: procedure?.name,
      value: procedure?.name,
    })
  );

  const onSubmit = (data: any, e: any) => {
    // console.log(isprocedureChanged,'isprocedureChanged');
    // debugger;
    if (selectedKeys.length === 0) {
      const flag = true;
      setFlag(flag);
      return false;
    }
    if (Object.keys(errors).length === 0) {
      setDisabledCreate(true);
    }
    !isprocedureChanged && delete data?.procedure[0]?.name;
    Object.assign(data.procedure[0], {
      speciality: data.procedure[0]?.speciality?.join(","),
    });
    // debugger;
    dispatch(editProcedure(procedureData?.id, data.procedure[0]));
    setTimeout(() => {
      setDisabledCreate(false);
    }, 1000);
  };
  // const specialityData = procedureData?.speciality?.split(",");
  const spacialitydefaultValue =
    procedureData && Array.isArray(procedureData?.speciality)
      ? procedureData?.speciality
      : [procedureData?.speciality];
  useEffect(() => {
    // dispatch(getProcedures());
  }, []);

  useEffect(() => {
    // setValue(`procedure[0].id`, device?.serial_number);
    // console.log("values-----------333333333333333333333");
    // debugger;
    const specialityData =
      procedureData?.speciality && procedureData?.speciality?.split(",");
    specialityData?.length > 0 && setSelectedKeys(specialityData);
  }, [procedureData]);
  return (
    <Modal
      className="deleteModal ProcedureEditModal"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={() => {
        _alertClicked();
      }}
      containerClassName={"smallModalStyle"}
      isBlocking={false}
    >
      <Stack className="deleteSection">
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack.Item className="heading">
            <Text variant="xLarge">Edit Procedure Type</Text>
          </Stack.Item>
          <Stack.Item align="end">
            <IconButton
              className=""
              iconProps={cancelIcon}
              onClick={() => {
                _alertClicked();
              }}
            />
          </Stack.Item>
        </Stack>

        <form
          id="procedure"
          onSubmit={handleSubmit(onSubmit)}
          key={procedureData}
        >
          {procedureData && Object.keys(procedureData).length >= 0 && (
            <Stack className="InputSection procedureType ms-Grid-row">
              <Stack.Item
                className="ms-Grid-row"
                key={proceduresOption && procedureData && procedureData?.name}
              >
                <Stack.Item className="ms-Grid-col ms-lg12 " dir="ltr">
                  <Stack
                    className="procedureSection1"
                    key={
                      proceduresOption && procedureData && procedureData?.name
                    }
                  >
                    <Stack.Item className="label">
                      <Text> Procedure Name*</Text>
                    </Stack.Item>
                    {/* <Controller
                      control={control}
                      defaultValue={procedureData && procedureData?.name}
                      // name={`${fieldName}.name`}
                      {...register(`procedure[0].name`)}
                      shouldUnregister
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          {console.log(
                            procedureData && procedureData?.name,
                            value,
                            "value----"
                          )}
                          <Select
                            classNamePrefix="addl-class"
                            options={proceduresOption}
                            isClearable
                            value={proceduresOption?.find(
                              (c: any) => c.value === value
                            )}
                            // placeholder="Yes/No"
                            onChange={(val) => {
                              onChange(val?.value);
                              // setIsBussiness(val?.value);
                            }}
                          />
                        </>
                      )}
                    /> */}
                    <TextField
                      autoComplete="off"
                      key={procedureData && procedureData?.name}
                      errorMessage={
                        errors?.procedure
                          ? errors?.procedure[0]?.name?.message
                          : ""
                      }
                      {...register(`procedure[0].name`)}
                      onChange={(e: any) =>
                        setprocedureOnChange(e?.target?.value)
                      }
                      onKeyDown={(event: any) => {
                        if (event.keyCode == 13) {
                          event.preventDefault();
                          return false;
                        }
                      }}
                      defaultValue={procedureData && procedureData?.name}
                    />
                  </Stack>

                  {/* <Label className="errorMessage">
                    {errors?.procedure
                      ? errors?.procedure[0]?.name?.message
                      : ""}
                  </Label> */}
                </Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg12" dir="ltr">
                  <Stack
                    className=""
                    key={specialtiesOption && spacialitydefaultValue}
                  >
                    <Stack.Item className="label">
                      <Text> Map Specialty*</Text>
                    </Stack.Item>

                    <Controller
                      control={control}
                      defaultValue={
                        procedureData &&
                        Array.isArray(procedureData?.speciality)
                          ? procedureData?.speciality
                          : [procedureData?.speciality]
                      }
                      shouldUnregister
                      name={`procedure[0].speciality`}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <Dropdown
                            placeholder="Select from the list"
                            selectedKeys={selectedKeys}
                            onChange={(
                              evt: FormEvent,
                              option?: IDropdownOption
                            ) => {
                              const value: any = option;
                              // console.log(value.key, option);
                              const valuesSelected = value.selected
                                ? [...selectedKeys, value.key as string]
                                : selectedKeys.filter(
                                    (key) => key !== value.key
                                  );
                              setSelectedKeys(valuesSelected);
                              onChange(valuesSelected);
                            }}
                            multiSelect
                            options={specialtiesOption}
                          />
                        </>
                      )}
                    />
                  </Stack>
                  {flagValue == true ? (
                    <span className="errorMessage">Specialty is required</span>
                  ) : (
                    <div style={{ marginLeft: "16px" }}></div>
                  )}
                  <Label className="errorMessage">
                    {errors?.procedure
                      ? errors?.procedure[0]?.speciality?.message
                      : ""}
                  </Label>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col  ms-lg12" dir="ltr">
                  <Stack.Item className="label">
                    <Text>Comments</Text>
                  </Stack.Item>
                  <Stack
                    className="procedureSection1"
                    key={procedureData && procedureData?.description}
                  >
                    <TextField
                      autoComplete="off"
                      key={procedureData && procedureData?.description}
                      // errorMessage={
                      //   errors?.procedure
                      //     ? errors?.procedure[i]?.description?.message
                      //     : ""
                      // }
                      {...register(`procedure[0].description`)}
                      // onChange={(e) => handleValidateForm(e, "name")}
                      defaultValue={procedureData && procedureData?.description}
                    />
                  </Stack>
                  <Label className="errorMessage">
                    {errors?.hospitals
                      ? errors?.hospitals[0]?.name?.message
                      : ""}
                  </Label>
                </Stack.Item>
              </Stack.Item>

              <Stack dir="ltr" horizontal verticalAlign="center">
                <PrimaryButton
                  disabled={disabledCreate}
                  type="submit"
                  form="procedure"
                  className="btn-next"
                >
                  Update
                  <Text className="spinnerRight">
                    {disabledCreate && <Spinner size={SpinnerSize.small} />}
                  </Text>
                </PrimaryButton>
                <ActionButton
                  text="Cancel"
                  className="btn-c"
                  allowDisabledFocus
                  onClick={closeModal}
                />
              </Stack>
            </Stack>
          )}
        </form>

        {/* <Modal isOpen={procedureSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage title={`Procedure Mapping Updated`} />
        </Modal> */}
        {(procedureSpinner || closeWhenErrors) &&
          setTimeout(() => {
            _alertClicked();
            // showModal();
            // showModal();
            // _alertClicked1();
            // dispatch(getProcedureList());
            // dispatch(getProcedures());
            // setShowCreateProcedure(true);
            // dispatch(showRoleSpinner());
          }, 1000) && <></>}
      </Stack>
    </Modal>
  );
};
export default ProcedureTypeEdit;
