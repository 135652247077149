import {
  Stack,
  StackItem,
  Text,
  Image,
  FontIcon,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../theme";
import videoIcon from "../../assets/icon_4.svg";

export interface VideoCardState {
  hoverCheck: boolean;
}

export interface VideoCardProps {
  data: any;
  index: any;
  handleSelectedData: any;
}

export interface VideoCardState {}
const containerStyles: React.CSSProperties = {
  height: "13.75rem",
  width: "17.3rem",
  color: ThemeColorPalette.pureWhite,
  backgroundColor: ThemeColorPalette.gridBG,
  boxShadow: "7px 7px " + ThemeColorPalette.pureBlack,
  padding: "0.1rem 1rem 0.1rem 0.1rem",
  margin: "0 0 1.5rem 1.5rem",
  flex: "0 0 19%",
  overflowWrap: "break-word",
  cursor: "pointer",
};

class VideoCard extends React.Component<VideoCardProps, VideoCardState> {
  constructor(props: VideoCardProps) {
    super(props);
    this.state = { hoverCheck: false };
  }

  handleMouseOver = () => {
    this.setState({ hoverCheck: !this.state.hoverCheck });
  };

  render() {
    const { hoverCheck } = this.state;
    const { data, index, handleSelectedData } = this.props;
    return (
      <Stack
        verticalFill
        style={containerStyles}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseOver}
        onClick={() => handleSelectedData(data)}
      >
        {data !== undefined ? (
          <Stack>
            <Stack horizontal horizontalAlign="start">
              <StackItem styles={{ root: { flex: 6 } }}>
                <Text
                  variant="small"
                  styles={{
                    root: {
                      backgroundColor: ThemeColorPalette.pureBlack,
                      padding: "0.2rem 0.4rem",
                    },
                  }}
                >
                  {index + 1}
                </Text>
              </StackItem>
              <Stack
                styles={{
                  root: {
                    height: "1rem",
                    width: "1rem",
                    border: hoverCheck
                      ? "1px solid " + ThemeColorPalette.pureWhite
                      : "0px",
                    borderRadius: "50%",
                    margin: "0.4rem",
                  },
                }}
              >
                {data.selectToUpload && (
                  <FontIcon
                    iconName="SkypeCircleCheck"
                    style={{
                      color: ThemeColorPalette.themePrimary,
                    }}
                  ></FontIcon>
                )}
              </Stack>
            </Stack>
            <Stack
              styles={{
                root: {
                  height: "100%",
                  padding: "1rem 0.5rem",
                  flex: 1,
                },
              }}
              tokens={{ childrenGap: 25 }}
            >
              <StackItem styles={{ root: { height: "6rem" } }}>
                <StackItem
                  styles={{
                    root: {
                      padding: "0.5rem 0 0.5rem 1rem",
                      marginBottom: "0.3rem",
                    },
                  }}
                >
                  <Image src={videoIcon} />
                </StackItem>
                <TooltipHost content={data.file.name}>
                  <StackItem styles={{ root: { padding: "0rem 0 0rem 1rem" } }}>
                    <Text
                      variant="medium"
                      styles={{
                        root: {
                          fontFamily: fonts.HelveticaNeue_Medium,
                        },
                      }}
                    >
                      {data.file.name.length > 50
                        ? data.file.name.slice(0, 50) +
                          "..." +
                          data.file.name.slice(data.file.name.length - 3)
                        : data.file.name}
                    </Text>
                  </StackItem>
                </TooltipHost>
              </StackItem>
              <StackItem styles={{ root: { padding: "0.5rem 0 0.8rem 1rem" } }}>
                <Text variant="tiny">
                  {data.file.uploaded_date || new Date().toLocaleString()}
                </Text>
              </StackItem>
            </Stack>
          </Stack>
        ) : null}
      </Stack>
    );
  }
}

export default VideoCard;
