import {
  Stack,
  Text,
  Modal,
  IconButton,
  IIconProps,
  PrimaryButton,
  ActionButton,
} from "@fluentui/react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React, { useState, useEffect, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../rootReducer";
import ToggleCard from "../Wizard/ToggleCard";
import UserForm from "./UserForm";
import * as constants from "../../../../Layout/constants/constants";

const cancelIcon: IIconProps = { iconName: "Cancel" };
interface CreateUserModalProps {
  isModalOpen: any;
  showModal: any;
  userId: any;
  userType: any;
  visibleHospitalList?: any;
}
const CreateUserModal: React.FC<CreateUserModalProps> = ({
  isModalOpen,
  showModal,
  userId,
  userType,
  visibleHospitalList,
}) => {
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [permissionUserCreation, setPermissionUserCreation] = useState<any>("");
  const showCreateUser = useSelector((state: AppState) => {
    return state.homeReducerData.showCreateUser;
  });

  const disabledCreateButton = () => {
    if (permissionUserCreation === "ActivAdmin")
      return !constants.getPermissionsbyItem("management.activadmin.create");
    else if (permissionUserCreation === "Developer")
      return !constants.getPermissionsbyItem("management.developer.create");
    else if (permissionUserCreation === "HospitalAdmin")
      return !constants.getPermissionsbyItem("management.hospitaladmin.create");
    else if (permissionUserCreation === "Surgeon")
      return !constants.getPermissionsbyItem("management.surgeon.create");
    else if (permissionUserCreation === "Dual")
      return !constants.getPermissionsbyItem("management.dual_role.create");
    else return true;
  };

  const getUserType = (userType: any) => {
    setPermissionUserCreation(userType);
  };
  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={showModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted">
            Home
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large" className="muted">
            All users
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">{!showCreateUser ? "New" : "Edit"} User </Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={showModal} />
        </Stack.Item>
      </Stack>
      {/* <UserForm userId={userId} userTypeEdit={userType} /> */}
      <UserForm
        userId={userId}
        userCreateType={getUserType}
        userTypeEdit={userType}
        setDisabledCreate={setDisabledCreate}
        isModalOpen={isModalOpen}
        visibleHospitalList={visibleHospitalList}
      />
      <Stack
        className="createHospitalWizardFooter"
        horizontal
        horizontalAlign="end"
      >
        <Stack.Item className="nextCancel">
          {showCreateUser ? (
            <PrimaryButton
              disabled={disabledCreate}
              type="submit"
              form="userForm"
              className="btn-next"
            >
              Update
              <Text className="spinnerRight">
                {disabledCreate && <Spinner size={SpinnerSize.small} />}
              </Text>
            </PrimaryButton>
          ) : (
            <PrimaryButton
              disabled={disabledCreate || disabledCreateButton()}
              title={disabledCreateButton() ? "You do not have permission" : ""}
              type="submit"
              form="userForm"
              className="btn-next"
            >
              Create
              <Text className="spinnerRight">
                {disabledCreate && <Spinner size={SpinnerSize.small} />}
              </Text>
            </PrimaryButton>
          )}
          <ActionButton
            text="Cancel"
            className="btn-c"
            allowDisabledFocus
            onClick={showModal}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};

export default memo(CreateUserModal);
