import * as React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../../DeveloperModule/Home/containers/Home";
import Logs from "../../DeveloperModule/Logs/containers/Logs";
import DeveloperActivity from "../../DeveloperModule/Activity/containers/DeveloperActivity";
import VideoPlayerNew1 from "../../VideoPlayer/containers/VideoPlayerNew1";
import DatasetMapping from "../../DeveloperModule/DatasetMapping/containers/DatasetMapping";
import Management from "../../DeveloperModule/Management/containers/Management";
import Containers from "../../DeveloperModule/Management/components/Containers/Containers";
import Models from "../../DeveloperModule/Management/components/Models/Models";
import ModelView from "../../DeveloperModule/Management/components/Models/ModelView/ModelView";
import Comparison from "../../DeveloperModule/Management/components/Comparison/Comparison";

export interface Props {}
const DeveloperRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      {/* <Route path="/:id" element={<HospitalView />} /> */}
      <Route path="video/:id/:alg/:video" element={<VideoPlayerNew1 />} />
      <Route path="video/:id" element={<VideoPlayerNew1 />} />
      <Route path="testdataset" element={<DatasetMapping />} />
      <Route path="/management" element={<Management />} />
      <Route path="/management/container" element={<Containers />} />
      <Route path="/management/model" element={<Models />} />
      <Route path="/management/comparison" element={<Comparison />} />
      <Route path="/:algId/:id/:version/:name/:alg" element={<ModelView />} />

      <Route path="logs" element={<Logs />} />
      <Route path="activity" element={<DeveloperActivity />} />
    </Routes>
  );
};

export default DeveloperRoutes;
