import {
  Image,
  Text,
  ContextualMenu,
  DefaultButton,
  FontIcon,
  Label,
  Panel,
  Persona,
  PersonaPresence,
  PersonaSize,
  PrimaryButton,
  SearchBox,
  Separator,
  Stack,
  StackItem,
  TextField,
  IPersonaSharedProps,
  ISearchBoxStyles,
  IContextualMenuItem,
  ILabelStyles,
  ITextFieldStyles,
  PanelType,
  Dialog,
  DialogFooter,
  DialogType,
  IDropdownOption,
  Dropdown,
  Icon,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../../theme";
import progressIcon from "../../../assets/progressImg.png";
import { TestImages } from "@uifabric/example-data";
import { Link } from "react-router-dom";
import {
  doChangePassword,
  setLoginAlertFalse,
} from "../../../auth/actions/authAction";
import { connect } from "react-redux";
import PanelInprogressCard from "../../components/PanelInprogresscard";
import "./Header.scss";
import {
  clearInprogressDataOnLogout,
  deleteInprogressItem,
  getProcessingVideos,
} from "../../../InProgress/actions/InProgressAction";
import NotificationActionsTab from "../../components/NotificationsActionsTab";
import NotificationsTab from "../../components/NotificationsTab";
import {
  acceptNotification,
  approveTaggedVideo,
  clearAllNotifications,
  clearDashboardDataOnLogout,
  createUserProfile,
  getOfflineNotifications,
  getUserProfile,
  getVideoDetailsById,
  logoutFromCurrentUser,
  rejectNotification,
  rejectTaggedVideo,
} from "../../actions/layoutActions";

import { getHospitalInfo } from "../../../../src/HospitalAdminModule/Home/actions/homeAction";

import { clearLogoutAdminAlert } from "../../../../src/ActiveAdminModule/Home/actions/homeAction";
import {
  clearSharedVideoData,
  getVideos,
} from "../../../Shared/actions/sharedVideosAction";
import { terminateWebWorker } from "../../../MainWrapper/MainWrapper";
import {
  clearMyProceduresDataOnLogout,
  getProcedureVideos,
} from "../../../MyProcedures/actions/landingPageAction";
import { clearFavoritesVideoData } from "../../../Favorites/actions/favouritesAction";
import { clearProceduresDataWhenUnmount } from "../../../MyProcedures/actions/proceduresAction";
import { clearRecentActivitiesOnlogout } from "../../../RecentActivities/actions/recentActivitiesAction";
import { withRouter } from "../../../withRouter";
import ActivSightDataProcess from "../../components/ActivSightDataProcess";
import UserProfile from "../../components/UserProfile";
import * as constants from "../../constants/constants";
import ChangePassword from "../../components/ChangePassword";
import SwitchAccounts from "../../components/SwitchAccounts";
import { clearAllSurgeons } from "../../../HospitalAdminModule/Activity/actions/activityAction";
import userImage from "../../../assets/Home/userProfile_1.png";
import State from "pusher-js/types/src/core/http/state";

export interface HeaderProps {
  doChangePasswordAction: any;
  getUserProfile: any;
  doUserProfileAction: any;
  changePasswordStatus: any;
  history: any;
  inProgressVideosData: any;
  deleteInprogressItem: any;
  notifications: any;
  notificationActions: any;
  clearAllNotifications: any;
  acceptNotification: any;
  getVideoDetailsById: any;
  procedureDetails: any;
  rejectNotification: any;
  getVideosAction: any;
  getProcessingVideos: any;
  processingData: any;
  getOfflineNotifications: any;
  clearMyProceduresDataOnLogout: any;
  clearInprogressDataOnLogout: any;
  clearDashboardDataOnLogout: any;
  showLoginAlert: any;
  setLoginAlertFalse: any;
  getProcedureVideos: any;
  approveTaggedVideo: any;
  rejectTaggedVideo: any;
  logoutFromCurrentUser: any;
  clearSharedVideoData: any;
  clearFavoritesVideoData: any;
  clearProceduresDataWhenUnmount: any;
  clearRecentActivitiesOnlogout: any;
  loginUserName: string;
  inprocessCurrentPage: any;
  userProfile: any;
  clearAllSurgeons: any;
  logoutDeveloperAlertForModalManagment: any;
  logoutDeveloperAlertForDataSet: any;
  logoutActivAdminAlert: any;
  clearLogoutAlertActiveAdmin: any;
  getHospitalInfo: any;
}

export interface HeaderState {
  menuItems: IContextualMenuItem[];
  hospitalMenuItems: IContextualMenuItem[];
  developerMenuItems: IContextualMenuItem[];
  isPanelOpen: boolean;
  isEditAdminAccessOpen: boolean;
  isEditActivSightDataProcessOpen: boolean;
  isUserProfileOpen: boolean;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  showContextualMenu: boolean;
  isInprogressPanelOpen: boolean;
  isNotificationPanelOpen: boolean;
  notificationActionSelect: boolean;
  isModalOpen: boolean;
  shouldLogout: boolean;
  logoutAlert: boolean;
  loginAlert: boolean;
  name: string;
  role: string;
  email: string;
  phone: string;
  address: string;
  activeSightDataTab: string;
}

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: {
    width: "20rem",
    backgroundColor: ThemeColorPalette.sideNavBg,
    color: ThemeColorPalette.radioBg,
    border: 0,
  },
};

const changePasswordStyles: Partial<ITextFieldStyles> = {
  root: {
    color: ThemeColorPalette.changePasswordTxt,
    opacity: 0.7,
  },
};
const labelStyles: Partial<ILabelStyles> = {
  root: {
    color: ThemeColorPalette.changePasswordLabelTxt,
    opacity: 0.7,
  },
};

class ActivHeader extends React.Component<HeaderProps, HeaderState> {
  private linkRef: any;
  private updatedSwitch = false;

  constructor(props: HeaderProps) {
    super(props);
    this.linkRef = React.createRef();
    this.state = {
      activeSightDataTab: "Video Data",
      isModalOpen: false,
      notificationActionSelect: true,
      isPanelOpen: false,
      isEditAdminAccessOpen: false,
      isEditActivSightDataProcessOpen: false,
      isInprogressPanelOpen: false,
      isUserProfileOpen: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      showContextualMenu: false,
      isNotificationPanelOpen: false,
      logoutAlert: false,
      name: "",
      role: "",
      email: "",
      phone: "",
      address: "",
      menuItems: [
        {
          key: "UserProfile",
          text: "User Profile",
          onClick: () => this.openUserProfilePanel(),
          // onClick: () => {
          //   constants.getPermissionsbyItem("management.profile.edit")
          //     ? this.openUserProfilePanel()
          //     : void 0;
          // },
          // title: constants.getPermissionsbyItem("management.profile.edit")
          //   ? ""
          //   : constants.permission,
        },
        {
          key: "changePassword",
          text: "Change Password",
          onClick: () => this.openPanel(),
        },
        // {
        //   key: "SwitchAccount",
        //   text: "Switch Account",
        //   // onClick: () => this.openPanel(),
        //   onClick: () => this.openAdminAccessPanel(),
        // },
        {
          key: "ActivSightDataProcess",
          text: "ActivSight Data Process",
          // onClick: () => this.openPanel(),
          // onClick: () => this.openActivSightDataProcessPanel(),
          onClick: () => {
            constants.getPermissionsbyItem("vps.activsight_video_processing")
              ? this.openActivSightDataProcessPanel()
              : void 0;
          },
          title: constants.getPermissionsbyItem(
            "vps.activsight_video_processing"
          )
            ? ""
            : constants.permission,
        },
        {
          key: "logout",
          text: "Logout",
          onClick: () =>
            this.props.logoutActivAdminAlert ||
            this.props.logoutDeveloperAlertForModalManagment ||
            this.props.logoutDeveloperAlertForDataSet
              ? this.handlePopup()
              : this.handleLogout(),
        },
      ],
      hospitalMenuItems: [
        {
          key: "UserProfile",
          text: "User Profile",
          onClick: () => this.openUserProfilePanel(),
          // onClick: () => {
          //   constants.getPermissionsbyItem("management.profile.edit")
          //     ? this.openUserProfilePanel()
          //     : void 0;
          // },
          // title: constants.getPermissionsbyItem("management.profile.edit")
          //   ? ""
          //   : constants.permission,
        },
        {
          key: "changePassword",
          text: "Change Password",
          onClick: () => this.openPanel(),
        },
        {
          key: "logout",
          text: "Logout",
          onClick: () =>
            this.state.logoutAlert ? this.handlePopup() : this.handleLogout(),
        },
      ],
      developerMenuItems: [
        {
          key: "UserProfile",
          text: "User Profile",
          onClick: () => this.openUserProfilePanel(),
          //   onClick: () => {
          //     constants.getPermissionsbyItem("management.profile.edit")
          //       ? this.openUserProfilePanel()
          //       : void 0;
          //   },
          //   title: constants.getPermissionsbyItem("management.profile.edit")
          //     ? ""
          //     : constants.permission,
        },
        {
          key: "changePassword",
          text: "Change Password",
          onClick: () => this.openPanel(),
        },
        // {
        //   key: "SwitchAccount",
        //   text: "Switch Account",
        //   // onClick: () => this.openPanel(),
        //   onClick: () => this.openAdminAccessPanel(),
        // },
        {
          key: "logout",
          text: "Logout",
          onClick: () =>
            this.props.logoutDeveloperAlertForModalManagment ||
            this.props.logoutDeveloperAlertForDataSet
              ? this.handlePopup()
              : this.handleLogout(),
        },
      ],
      shouldLogout: false,
      loginAlert: false,
    };
  }

  componentDidMount() {
    this.props.getProcessingVideos();
    this.props.getOfflineNotifications();
    this.props.getUserProfile();
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.inProgressVideosData &&
      prevProps.inProgressVideosData &&
      prevProps.inProgressVideosData.length !==
        this.props.inProgressVideosData.length
    ) {
      this.setState({
        ...this.state,
        loginAlert:
          this.props.inProgressVideosData.length && this.props.showLoginAlert
            ? true
            : false,
        logoutAlert: this.props.inProgressVideosData.length ? true : false,
      });
    }

    const rolesToCheck = [
      constants.ROLE_TYPES.ACTIVE_ADMIN,
      constants.ROLE_TYPES.ACTIVE_DEVELOPER,
    ];
    const isSwitchRole = localStorage.getItem("switch_account") === "true";
    if (
      !this.updatedSwitch &&
      this.props.userProfile?.permissions_name &&
      // rolesToCheck.every((role) =>
      //   this.props.userProfile.permissions_name.includes(role)
      // )
      isSwitchRole
    ) {
      this.updatedSwitch = true;
      const menuItemsArr = this.state.menuItems.slice();
      const devMenuItemsArr = this.state.developerMenuItems.slice();
      const switchAccountMenu = {
        key: "SwitchAccount",
        text: "Switch Account",
        onClick: () => this.openAdminAccessPanel(),
      };
      menuItemsArr.splice(2, 0, switchAccountMenu);
      devMenuItemsArr.splice(2, 0, switchAccountMenu);
      this.setState({
        ...this.state,
        menuItems: menuItemsArr,
        developerMenuItems: devMenuItemsArr,
      });
    }

    if (
      JSON.stringify(this.props.notifications) !==
      JSON.stringify(prevProps.notifications)
    ) {
      if (this.props.notifications?.length) {
        const getCount = this.props.notifications.find((item: any) => {
          if (
            item.data.message.includes(
              "Overlay Video is available for streaming or playback"
            )
          ) {
            setTimeout(() => {
              this.props.getHospitalInfo();
            }, 2000);
          }
        });
      }
    }
  }

  handleLogout = () => {
    this.props.clearInprogressDataOnLogout();
    this.props.clearMyProceduresDataOnLogout();
    this.props.clearSharedVideoData();
    this.props.clearFavoritesVideoData();
    this.props.clearProceduresDataWhenUnmount();
    this.props.clearRecentActivitiesOnlogout();
    this.props.logoutFromCurrentUser();
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loggedInProfile");
    this.props.clearDashboardDataOnLogout();
    this.props.clearAllSurgeons();
    this.props.history("/login");
  };

  handleLogoutPopup = () => {
    terminateWebWorker();
    //this.props.clearLogoutAlertActiveAdmin();
    this.props.clearInprogressDataOnLogout();
    this.props.clearMyProceduresDataOnLogout();
    this.props.clearDashboardDataOnLogout();
    this.props.clearSharedVideoData();
    this.props.clearFavoritesVideoData();
    this.props.clearProceduresDataWhenUnmount();
    this.props.clearRecentActivitiesOnlogout();
    this.props.logoutFromCurrentUser();
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loggedInProfile");
    this.props.clearAllSurgeons();
    this.props.history("/login");
  };

  handleLoginPopup = () => {
    this.props.setLoginAlertFalse();
    this.setState({ ...this.state, loginAlert: false });
  };

  handlePopup = () => {
    this.setState({ ...this.state, shouldLogout: !this.state.shouldLogout });
  };

  openPanel = () => {
    this.setState({ isPanelOpen: true });
  };

  openAdminAccessPanel = () => {
    this.setState({ isEditAdminAccessOpen: true });
    localStorage.setItem("isEditAdminAccessOpen", JSON.stringify(true));
  };

  openActivSightDataProcessPanel = () => {
    this.setState({ activeSightDataTab: "Video Data" });
    this.setState({ isEditActivSightDataProcessOpen: true });
  };
  openUserProfilePanel = () => {
    this.setState({ isUserProfileOpen: true });
  };

  inprogressOpenPanel = () => {
    this.setState({ isInprogressPanelOpen: true });
  };

  dismissPanel = () => {
    this.setState({
      isInprogressPanelOpen: false,
      isEditAdminAccessOpen: false,
      isEditActivSightDataProcessOpen: false,
      isUserProfileOpen: false,
      isPanelOpen: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      phone: "",
      address: "",
      activeSightDataTab: "Video Data",
    });
    // this.props.getProcessingVideos();
  };

  // dismissAdminAccessPanel = () => {
  //     this.setState({
  //         isInprogressPanelOpen: false,
  //         isPanelOpen: false,
  //         currentPassword: "",
  //         newPassword: "",
  //         confirmPassword: "",
  //     });
  //     this.props.getProcessingVideos();
  // };

  dismissNotificationPanel = () => {
    this.setState({
      ...this.state,
      isNotificationPanelOpen: false,
    });
  };

  handleChange = (e: React.FormEvent<HTMLInputElement>): any => {
    const { name, value } = e.target as HTMLInputElement;
    this.setState({ [name]: value } as any);
  };

  handleDelete = (data: any) => {
    this.props.deleteInprogressItem(data.id);
  };

  changePassword = (event: React.MouseEvent<HTMLAnchorElement>): any => {
    event.preventDefault();
    const { newPassword, confirmPassword, currentPassword } = this.state;
    if (!newPassword || !confirmPassword || !currentPassword) {
      alert("please enter the details");
      return;
    }
    if (confirmPassword === newPassword) {
      const data = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };
      this.props.doChangePasswordAction(data);
      setTimeout(() => {
        this.setState({
          isPanelOpen: false,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }, 1000);
    } else {
      alert("new password and confirm password doesn't match");
    }
  };

  editUserProfile = (event: React.MouseEvent<HTMLAnchorElement>): any => {
    event.preventDefault();
    const { name, role, email, phone, address } = this.state;
    if (!name || !role || !email) {
      alert("please enter the details");
      return;
    }
    const data = {
      name: name,
      role: role,
      email: email,
      phone: phone,
      address: address,
    };
    this.props.doUserProfileAction(data);
  };

  hideMenu = () => {
    this.setState({ showContextualMenu: false });
  };

  showMenu = () => {
    const isSwitchRole = localStorage.getItem("switch_account") === "true";
    if (!isSwitchRole && !this.state.showContextualMenu) {
      const menuItemsArr = this.state.menuItems.slice();
      const devMenuItemsArr = this.state.developerMenuItems.slice();
      if (menuItemsArr[2]?.key === "SwitchAccount") {
        menuItemsArr.splice(2, 1);
        devMenuItemsArr.splice(2, 1);
        this.setState({
          ...this.state,
          menuItems: menuItemsArr,
          developerMenuItems: devMenuItemsArr,
        });
      }
    }

    this.setState({ showContextualMenu: !this.state.showContextualMenu });
  };

  onRenderFooterContent = () => (
    <Stack
      styles={{
        root: {
          borderTop: "1px solid " + ThemeColorPalette.panelBorder,
          textAlign: "right",
          paddingTop: "0.7rem",
          paddingBottom: "0.7rem",
        },
      }}
      onClick={() =>
        this.setState({ ...this.state, isInprogressPanelOpen: false })
      }
    >
      <Link
        to="/inprogress"
        style={{
          fontFamily: fonts.HelveticaNeue_Regular,
          color: ThemeColorPalette.themePrimary,
          textDecoration: "none",
          fontWeight: "bold",
          paddingRight: "2rem",
        }}
      >
        View all videos in progress
      </Link>
    </Stack>
  );

  handleNotificationClick = () => {
    this.setState({
      ...this.state,
      isNotificationPanelOpen: true,
      isModalOpen: false,
    });
  };

  handleNotificationTabChangeClick = (selectValue: string) => {
    this.setState({
      ...this.state,
      notificationActionSelect: selectValue ? true : false,
    });
  };

  handleNotificationModalOpen = () => {
    this.setState({
      ...this.state,
      isNotificationPanelOpen: !this.state.isModalOpen,
      isModalOpen: !this.state.isModalOpen,
    });
  };

  handleNotificationAccept = (videoId: any, type: string) => {
    if (type === "video-approval") {
      this.props.approveTaggedVideo(videoId);
      this.props.getProcedureVideos();
    } else if (type === "share-video") {
      this.props.acceptNotification(videoId);
      this.props.getVideosAction("sharedWithMe", 1);
    }
  };

  handleNotificationReject = (videoId: any, type: string) => {
    if (type === "video-approval") {
      this.props.rejectTaggedVideo(videoId);
    } else if (type === "share-video") {
      this.props.rejectNotification(videoId);
    }
  };

  handleLoadMoreProcessingVideos = (currentPage: number) => {
    this.props.getProcessingVideos(currentPage);
  };

  render() {
    const {
      isPanelOpen,
      isEditAdminAccessOpen,
      isEditActivSightDataProcessOpen,
      isUserProfileOpen,
      currentPassword,
      newPassword,
      confirmPassword,
      showContextualMenu,
      menuItems,
      hospitalMenuItems,
      developerMenuItems,
      name,
      role,
      email,
      phone,
      address,
    } = this.state;
    const { inProgressVideosData } = this.props;
    const dialogStyles: any = {
      main: { maxWidth: 450, margin: "0 auto" },
    };
    const loginDialogStyles: any = {
      main: { maxWidth: 350, margin: "0 auto" },
    };

    const loginModalProps = () => ({
      isBlocking: false,
      styles: loginDialogStyles,
    });

    const modalProps = () => ({
      isBlocking: false,
      styles: dialogStyles,
    });

    const dialogContentProps = {
      type: DialogType.largeHeader,
      title: (
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <FontIcon iconName="Warning" style={{ color: "orange" }}></FontIcon>
          <Text
            styles={{
              root: { font: fonts.HelveticaNeue_Medium, color: "orange" },
            }}
          >
            Warning
          </Text>
        </Stack>
      ),

      closeButtonAriaLabel: "Close",
      subText:
        "Uploads in progress! On logout upload progress may be lost. Are you sure?",
    };

    const loginDialogContentProps = {
      type: DialogType.largeHeader,
      title: (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <FontIcon
            iconName="Message"
            style={{ color: ThemeColorPalette.themePrimary }}
          ></FontIcon>
          <Text
            styles={{
              root: {
                font: fonts.HelveticaNeue_Medium,
                color: ThemeColorPalette.themePrimary,
              },
            }}
          >
            Info
          </Text>
        </Stack>
      ),
      closeButtonAriaLabel: "Close",
      subText:
        "Uploads are in progress. Please check Inprogress screen for more details!",
    };

    this.props.notificationActions &&
      this.props.notificationActions.length &&
      this.props.notificationActions.sort((a: any, b: any) => {
        const dateA = new Date(a.data?.["sent_at"]).getTime();
        const dateB = new Date(b.data?.["sent_at"]).getTime();
        return dateA < dateB ? 1 : -1;
      });

    this.props.notifications &&
      this.props.notifications.length &&
      this.props.notifications.sort((a: any, b: any) => {
        const dateA = new Date(a["sent_at"]).getTime();
        const dateB = new Date(b["sent_at"]).getTime();
        return dateA < dateB ? 1 : -1;
      });
    const optionsUser: IDropdownOption[] = [
      { key: "admin", text: "Co-admin" },
      { key: "general", text: "General admin" },
    ];
    return (
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: 20 }}
        styles={{
          root: {
            backgroundColor: ThemeColorPalette.sideNavBg,
            padding: "0.6rem 0.3rem",
            // marginLeft: "0",
            // marginRight:"0"
          },
        }}
      >
        <StackItem
          styles={{
            root: { flex: 6, paddingLeft: "2rem", visibility: "hidden" },
          }}
        >
          <SearchBox
            styles={searchBoxStyles}
            placeholder="Search"
            onEscape={(ev: any) => {}}
            onClear={(ev: any) => {}}
            onChange={(_: any, newValue: any) => {}}
            onSearch={(newValue: any) => {}}
          />
          <Dialog
            hidden={!this.state.loginAlert}
            onDismiss={this.handleLoginPopup}
            dialogContentProps={loginDialogContentProps}
            modalProps={loginModalProps()}
            styles={{
              root: {
                background: "blur",
              },
            }}
          >
            <DialogFooter>
              <PrimaryButton text="Ok" onClick={this.handleLoginPopup} />
            </DialogFooter>
          </Dialog>
        </StackItem>
        <StackItem
          align="center"
          styles={{
            root: {
              backgroundColor: ThemeColorPalette.radioBg,
              boxShadow: "0 0 2px 0 rgba(0,0,0,0.5)",
              borderRadius: "1rem",
            },
          }}
        >
          {/* <Stack
              horizontal
              styles={{ root: { cursor: "pointer" } }}
              onClick={this.inprogressOpenPanel}
            >
              <StackItem>
                <Image src={progressIcon} width={"1.2rem"}></Image>
              </StackItem>
              <StackItem tokens={{ padding: "0 0.5rem 0.2rem 0.3rem" }}>
                <Text variant="smallPlus" styles={{ root: { opacity: 0.5 } }}>
                  {this.props.processingData &&
                    inProgressVideosData.length === 0 &&
                    (this.props.processingData.count === undefined ||
                      this.props.processingData.count === 0) &&
                    "No in progress data"}
                  {this.props.processingData &&
                    this.props.processingData.count +
                      inProgressVideosData.length ===
                      1 &&
                    inProgressVideosData.length + " video is in progress"}
                  {this.props.processingData &&
                    (inProgressVideosData.length > 1 ||
                      this.props.processingData.count > 1) &&
                    this.props.processingData.count +
                      inProgressVideosData.length +
                      " videos are in progress"}
                </Text>
              </StackItem>
              <div>
                <Panel
                  headerText="Videos in progress"
                  isOpen={this.state.isInprogressPanelOpen}
                  onDismiss={this.dismissPanel}
                  isFooterAtBottom={true}
                  onRenderFooterContent={this.onRenderFooterContent}
                  closeButtonAriaLabel="Close"
                  styles={{
                    footerInner: {
                      padding: 0,
                    },
                  }}
                >
                  <PanelInprogressCard
                    inProgressVideosData={this.props.inProgressVideosData}
                    handleDelete={this.handleDelete}
                    processingData={this.props.processingData}
                    handleLoadMoreProcessingVideos={
                      this.handleLoadMoreProcessingVideos
                    }
                    currentPage={this.props.inprocessCurrentPage}
                  />
                </Panel>
              </div>
            </Stack> */}
        </StackItem>
        <StackItem>
          <Separator vertical></Separator>
        </StackItem>
        <StackItem align="center">
          <Stack horizontal>
            <FontIcon
              iconName="Ringer"
              style={{
                fontSize: "1.2rem",
                color: ThemeColorPalette.themePrimary,
                cursor: "pointer",
              }}
              onClick={this.handleNotificationClick}
            />
            <Text
              variant="small"
              styles={{
                root: {
                  fontFamily: fonts.HelveticaNeue_Bold,
                  color: ThemeColorPalette.uploadVideoBtnTxt,
                },
              }}
            >
              {(this.props.notificationActions &&
                this.props.notificationActions.length) ||
              (this.props.notifications && this.props.notifications.length)
                ? `(${
                    this.props.notificationActions?.length ||
                    0 + this.props.notifications?.length ||
                    0
                  })`
                : "(0)"}
            </Text>
          </Stack>
          <div>
            <Panel
              isOpen={this.state.isNotificationPanelOpen}
              onDismiss={this.dismissNotificationPanel}
              closeButtonAriaLabel="Close"
              styles={{
                closeButton: {
                  marginLeft: "90%",
                },
                footerInner: {
                  padding: 0,
                },
              }}
              type={PanelType.custom}
              customWidth={"24.5rem"}
            >
              <Stack verticalFill tokens={{ childrenGap: 30 }}>
                <StackItem>
                  <Text
                    variant="medium"
                    styles={{
                      root: {
                        color: ThemeColorPalette.dashboardHeadingTxt,
                        margin: "1rem",
                        paddingBottom: "0.5rem",
                        borderBottom: this.state.notificationActionSelect
                          ? "2px solid #01B1FF"
                          : "none",
                        cursor: "pointer",
                        opacity: this.state.notificationActionSelect
                          ? "1"
                          : "0.4",
                      },
                    }}
                    onClick={() =>
                      this.handleNotificationTabChangeClick("true")
                    }
                  >
                    Actions{" "}
                    {this.props.notificationActions &&
                    this.props.notificationActions.length
                      ? `(${this.props.notificationActions.length})`
                      : "(0)"}
                  </Text>
                  <Text
                    variant="medium"
                    styles={{
                      root: {
                        color: ThemeColorPalette.dashboardHeadingTxt,
                        margin: "1rem",
                        paddingBottom: "0.5rem",
                        borderBottom: !this.state.notificationActionSelect
                          ? "2px solid #01B1FF"
                          : "none",
                        cursor: "pointer",
                        opacity: !this.state.notificationActionSelect
                          ? "1"
                          : "0.4",
                      },
                    }}
                    onClick={() => this.handleNotificationTabChangeClick("")}
                  >
                    Notifications
                    {this.props.notifications && this.props.notifications.length
                      ? `(${this.props.notifications.length})`
                      : "(0)"}
                  </Text>
                </StackItem>
                {this.state.notificationActionSelect ? (
                  this.props.notificationActions &&
                  this.props.notificationActions.length ? (
                    <StackItem>
                      <Stack tokens={{ childrenGap: 20 }}>
                        {this.props.notificationActions &&
                          this.props.notificationActions.map(
                            (val: any, i: number) => (
                              <NotificationActionsTab
                                key={i}
                                notificationData={val}
                                isModalOpen={this.state.isModalOpen}
                                handleNotificationsModalOpen={
                                  this.handleNotificationModalOpen
                                }
                                dismissNotificationPanel={
                                  this.dismissNotificationPanel
                                }
                                handleNotificationAccept={
                                  this.handleNotificationAccept
                                }
                                handleNotificationReject={
                                  this.handleNotificationReject
                                }
                                getVideoDetailsById={
                                  this.props.getVideoDetailsById
                                }
                                procedureDetails={this.props.procedureDetails}
                              />
                            )
                          )}
                      </Stack>
                    </StackItem>
                  ) : (
                    <Stack horizontal horizontalAlign="center">
                      <Text
                        variant="medium"
                        styles={{
                          root: {
                            color: ThemeColorPalette.uploadVideoBtnTxt,
                            fontFamily: fonts.HelveticaNeue_Medium,
                            opacity: 0.5,
                            marginTop: "85%",
                          },
                        }}
                      >
                        No actions available
                      </Text>
                    </Stack>
                  )
                ) : this.props.notifications &&
                  this.props.notifications.length ? (
                  <StackItem>
                    <Stack horizontalAlign="end">
                      <Text
                        variant="small"
                        styles={{
                          root: {
                            padding: "0 0.5rem 0.5rem 0",
                            fontFamily: fonts.DINPro_Light,
                            color: ThemeColorPalette.uploadVideoBtnTxt,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() =>
                          this.props.clearAllNotifications("notification")
                        }
                      >
                        Clear All
                      </Text>
                    </Stack>
                    <Stack tokens={{ childrenGap: 20 }}>
                      {this.props.notifications
                        .sort(
                          (a: any, b: any) =>
                            new Date(b.data?.sent_at).getTime() -
                            new Date(a.data?.sent_at).getTime()
                        )
                        .map((val: any, i: number) => (
                          <NotificationsTab
                            key={i}
                            notificationData={
                              val && val.data ? val : { data: {} }
                            }
                          />
                        ))}
                    </Stack>
                  </StackItem>
                ) : (
                  <Stack horizontal horizontalAlign="center">
                    <Text
                      variant="medium"
                      styles={{
                        root: {
                          color: ThemeColorPalette.uploadVideoBtnTxt,
                          fontFamily: fonts.HelveticaNeue_Medium,
                          opacity: 0.5,
                          marginTop: "85%",
                        },
                      }}
                    >
                      No notifications available
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Panel>
          </div>
        </StackItem>
        <StackItem align="center">
          <Stack horizontal verticalAlign="center">
            <Text
              variant="medium"
              styles={{
                root: {
                  font: fonts.HelveticaNeue_Bold,
                  opacity: "0.7",
                  marginBottom: "0.12rem",
                },
              }}
            >
              {this.props.loginUserName}
            </Text>
            <div ref={this.linkRef}>
              <Stack horizontal>
                <Persona
                  imageUrl={this.props.userProfile?.photo || userImage}
                  size={PersonaSize.size24}
                  presence={PersonaPresence.online}
                  onClick={() => this.showMenu()}
                  styles={{
                    root: { cursor: "pointer", marginBottom: "0.12rem" },
                  }}
                >
                  {(this.props.userProfile.role_name ===
                    constants.ROLE_TYPES.ACTIVE_ADMIN ||
                    this.props.userProfile.role_name ===
                      constants.ROLE_TYPES.ACTIVE_DUAL) && (
                    <ContextualMenu
                      className={"menuStyles"}
                      items={menuItems}
                      target={this.linkRef}
                      hidden={!showContextualMenu}
                      onItemClick={() => {
                        this.hideMenu();
                      }}
                      onDismiss={() => {
                        this.hideMenu();
                      }}
                    />
                  )}
                  {this.props.userProfile.role_name ===
                    constants.ROLE_TYPES.ACTIVE_HOSPITAL_ADMIN && (
                    <ContextualMenu
                      className={"menuStyles"}
                      items={hospitalMenuItems}
                      target={this.linkRef}
                      hidden={!showContextualMenu}
                      onItemClick={() => {
                        this.hideMenu();
                      }}
                      onDismiss={() => {
                        this.hideMenu();
                      }}
                    />
                  )}

                  {this.props.userProfile.role_name ===
                    constants.ROLE_TYPES.ACTIVE_DEVELOPER && (
                    <ContextualMenu
                      className={"menuStyles"}
                      items={developerMenuItems}
                      target={this.linkRef}
                      hidden={!showContextualMenu}
                      onItemClick={() => {
                        this.hideMenu();
                      }}
                      onDismiss={() => {
                        this.hideMenu();
                      }}
                    />
                  )}
                  <Dialog
                    hidden={!this.state.shouldLogout}
                    onDismiss={this.handlePopup}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps()}
                    styles={{
                      root: {
                        background: "blur",
                      },
                    }}
                  >
                    <DialogFooter>
                      <PrimaryButton text="Cancel" onClick={this.handlePopup} />
                      <DefaultButton
                        styles={{ root: { font: fonts.HelveticaNeue_Bold } }}
                        onClick={this.handleLogoutPopup}
                        text="Logout"
                      />
                    </DialogFooter>
                  </Dialog>
                </Persona>
              </Stack>
            </div>

            {/* -------------------------------Change password -----------------------------*/}
            {/* <Panel
              isOpen={isPanelOpen}
              onDismiss={this.dismissPanel}
              headerText="Change Password"
              closeButtonAriaLabel="Close"
              isFooterAtBottom={true}
              styles={{
                footerInner: {
                  padding: 0,
                },
              }}
              onRenderFooterContent={() => {
                return (
                  <Stack
                    horizontal
                    horizontalAlign="start"
                    tokens={{ childrenGap: 10 }}
                    styles={{
                      root: {
                        borderTop: "1px solid " + ThemeColorPalette.panelBorder,
                        textAlign: "right",
                        paddingTop: "0.7rem",
                        paddingBottom: "0.7rem",
                        paddingLeft: "1.5rem",
                      },
                    }}
                  >
                    <PrimaryButton
                      onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                        this.changePassword(evt)
                      }
                    >
                      Save
                    </PrimaryButton>
                    <DefaultButton onClick={this.dismissPanel}>
                      Cancel
                    </DefaultButton>
                  </Stack>
                );
              }}
            >
              <Stack
                verticalFill
                verticalAlign="start"
                tokens={{ childrenGap: 15, padding: "1rem 0 0 0" }}
              >
                <StackItem>
                  <Label htmlFor={"currentPassword"} styles={labelStyles}>
                    Current Password
                  </Label>
                  <TextField
                    id="currentPassword"
                    // placeholder="Enter Current Password"
                    styles={changePasswordStyles}
                    type="password"
                    name="currentPassword"
                    defaultValue={currentPassword}
                    onChange={(e: any) => this.handleChange(e)}
                  />
                </StackItem>
                <StackItem>
                  <Label htmlFor={"newPassword"} styles={labelStyles}>
                    New Password
                  </Label>
                  <TextField
                    id="newPassword"
                    // placeholder="Enter New Password"
                    styles={changePasswordStyles}
                    type="password"
                    name="newPassword"
                    defaultValue={newPassword}
                    onChange={(e: any) => this.handleChange(e)}
                  />
                </StackItem>
                <StackItem>
                  <Label htmlFor={"confirmPassword"} styles={labelStyles}>
                    Confirm New Password
                  </Label>
                  <TextField
                    id="confirmPassword"
                    // placeholder="Enter Confirm New Password"
                    styles={changePasswordStyles}
                    type="password"
                    name="confirmPassword"
                    defaultValue={confirmPassword}
                    onChange={(e: any) => this.handleChange(e)}
                  />
                </StackItem>
              </Stack>
            </Panel> */}
            <ChangePassword
              isPanelOpen={isPanelOpen}
              dismiss={() => this.dismissPanel()}
            />
            {/* -------------------------------Edit admin access -----------------------------*/}
            <Panel
              isOpen={isEditAdminAccessOpen}
              onDismiss={this.dismissPanel}
              headerText="Switch Accounts"
              closeButtonAriaLabel="Close"
              isFooterAtBottom={true}
              styles={{
                footerInner: {
                  padding: 0,
                },
              }}
              onRenderFooterContent={() => {
                return (
                  <Stack
                    horizontal
                    horizontalAlign="start"
                    tokens={{ childrenGap: 10 }}
                    styles={{
                      root: {
                        borderTop: "1px solid " + ThemeColorPalette.panelBorder,
                        textAlign: "right",
                        paddingTop: "0.7rem",
                        paddingBottom: "0.7rem",
                        paddingLeft: "1.5rem",
                      },
                    }}
                  >
                    {/* <PrimaryButton
                    // onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                    //     this.changePassword(evt)
                    // }
                    >
                      Save
                    </PrimaryButton>
                    <DefaultButton onClick={this.dismissPanel}>
                      Cancel
                    </DefaultButton> */}
                  </Stack>
                );
              }}
            >
              <SwitchAccounts />
            </Panel>
            {/* -------------------------------User profile -----------------------------*/}
            <UserProfile
              isPanelOpen={isUserProfileOpen}
              dismiss={() => this.dismissPanel()}
            />
            {/* -------------------------------ActivSight Data Process -----------------------------*/}
            {isEditActivSightDataProcessOpen && (
              <ActivSightDataProcess
                activeTab={this.state.activeSightDataTab}
                isPanelOpen={isEditActivSightDataProcessOpen}
                dismiss={() => this.dismissPanel()}
              ></ActivSightDataProcess>
            )}
          </Stack>
        </StackItem>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    changePasswordStatus: state.authReducerData.changePasswordStatus,
    inProgressVideosData: state.inProgressReducerData.inProgressVideosData,
    notifications: state.dashboardReducerData.notifications,
    notificationActions: state.dashboardReducerData.notificationActions,
    procedureDetails: state.dashboardReducerData.procedureDetails,
    processingData: state.inProgressReducerData.processingData,
    showLoginAlert: state.authReducerData.showLoginAlert,
    currentPage: state.sharedVideosReducerData.currentPage,
    inprocessCurrentPage: state.inProgressReducerData.currentPage,
    loginUserName: state.dashboardReducerData.loginUserName,
    userProfile: state.dashboardReducerData.loginUserProfile,
    logoutDeveloperAlertForModalManagment:
      state.developerManagementReducerData.logoutDeveloperModalAlert,
    logoutDeveloperAlertForDataSet:
      state.developerDataSetReducerData.logoutDeveloperDataSetAlert,
    logoutActivAdminAlert: state.homeReducerData.logoutActivAdminAlert,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    getHospitalInfo: () => dispatch(getHospitalInfo()),
    clearAllSurgeons: () => dispatch(clearAllSurgeons()),
    doChangePasswordAction: (userDetails: any) =>
      dispatch(doChangePassword(userDetails)),
    doUserProfileAction: (userDetails: any) =>
      dispatch(createUserProfile(userDetails)),
    deleteInprogressItem: (videoId: any) =>
      dispatch(deleteInprogressItem(videoId)),
    clearAllNotifications: (notificationType: string) =>
      dispatch(clearAllNotifications(notificationType)),
    acceptNotification: (videoId: any) => dispatch(acceptNotification(videoId)),
    rejectNotification: (videoId: any) => dispatch(rejectNotification(videoId)),
    getVideoDetailsById: (videoId: any) =>
      dispatch(getVideoDetailsById(videoId)),
    getVideosAction: (videoData: string, currentPage: number) =>
      dispatch(getVideos(videoData, currentPage)),
    getProcessingVideos: (currentPage: any) =>
      dispatch(getProcessingVideos(currentPage)),
    getOfflineNotifications: () => dispatch(getOfflineNotifications()),
    clearInprogressDataOnLogout: () => dispatch(clearInprogressDataOnLogout()),
    clearMyProceduresDataOnLogout: () =>
      dispatch(clearMyProceduresDataOnLogout()),
    setLoginAlertFalse: () => dispatch(setLoginAlertFalse()),
    getProcedureVideos: () => dispatch(getProcedureVideos()),
    approveTaggedVideo: (videoId: number) =>
      dispatch(approveTaggedVideo(videoId)),
    rejectTaggedVideo: (videoId: number) =>
      dispatch(rejectTaggedVideo(videoId)),
    clearDashboardDataOnLogout: () => dispatch(clearDashboardDataOnLogout()),
    logoutFromCurrentUser: () => dispatch(logoutFromCurrentUser()),
    clearSharedVideoData: () => dispatch(clearSharedVideoData()),
    clearFavoritesVideoData: () => dispatch(clearFavoritesVideoData()),
    clearProceduresDataWhenUnmount: () =>
      dispatch(clearProceduresDataWhenUnmount()),
    clearRecentActivitiesOnlogout: () =>
      dispatch(clearRecentActivitiesOnlogout()),
    getUserProfile: () => dispatch(getUserProfile()),
    clearLogoutAlertActiveAdmin: () => dispatch(clearLogoutAdminAlert()),
  };
};

export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(ActivHeader)
);
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
