import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  StackItem,
  Text,
  Modal,
  IconButton,
  IIconProps,
  TextField,
  Label,
  PrimaryButton,
  ActionButton,
} from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import { AppState } from "../../../../rootReducer";
import {
  createAlgorithmVersion,
  editAlogrithmVersion,
  getAlgorithmList,
  getAlgorithmModelImageList,
} from "../../actions/managamentActions";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
import Loader from "../../../Logs/components/Common/Loader";

const options1 = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const cancelIcon: IIconProps = { iconName: "Cancel" };

const CreateAlogrithmVersion = ({
  isModalOpen,
  showModal,
  algorithmVersionId,
}: {
  isModalOpen: any;
  showModal: any;
  algorithmVersionId: any;
}) => {
  const validationSchema = Yup.object().shape({
    algorithmVersion: Yup.array().of(
      Yup.object().shape({
        algorithm_id: Yup.string().required("Algorithm Name is required"),
        version: Yup.string().required("Version is required"),
        dockerimage: Yup.string().required("Docker Image is required"),
        is_active: Yup.string().required("Status is required"),
      })
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    setValue,
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);

  const dispatch = useDispatch();
  useEffect(() => {
    const values = getValues();
    values?.algorithmVersion?.splice(0, 1);
  }, [isSubmitSuccessful]);

  const [algorithmName, setAlgorithmName] = useState("");
  const [dockValue, setdockValue] = useState<any>();
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [dockerImageOpt, setDockerImageOpt] = useState([]);
  const [visibleAlgoList, setVisibleAlgoList] = useState(false);
  // const [hideDropdownValue, setHideDropdownValue] = useState(false);
  const algorithms = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmList.data;
  });

  const algorithmModelImageLists = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmModelImageList.data;
  });

  const algorithmOptions = algorithms?.results?.map((algorithm: any) => ({
    value: algorithm.id,
    label: algorithm.name,
  }));

  const algorithmsCount = algorithms?.count;

  const dockerImageOptions = algorithmModelImageLists?.results?.length
    ? algorithmModelImageLists?.results?.map(
        (algorithmModelImageList: any) => ({
          value: algorithmModelImageList.id,
          label:
            algorithmModelImageList.name + ":" + algorithmModelImageList.tag,
        })
      )
    : [];

  const showCreateAlgorithmVerisons = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateAlgorithmVerison;
  });

  const algorithmVersionData = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmVersionData.data;
  });

  // useEffect(() => {
  //   setAlgorithmName(algorithmVersionData?.algorithm_id);
  // },[algorithmVersionData])
  useEffect(() => {
    dispatch(getAlgorithmList("", 1, algorithmsCount));
  }, []);

  const onSubmit = async (data: any) => {
    setDisabledCreate(true);
    let algorithmVersionData;

    if (!showCreateAlgorithmVerisons) {
      const res: any = await dispatch(
        createAlgorithmVersion(data?.algorithmVersion[0])
      );
      if (res && (res.status === 200 || res.status === 201)) {
        setTimeout(() => {
          showModal();
        }, 1500);
      } else {
        return false;
      }
    }

    Object.assign(data?.algorithmVersion[0], {
      is_active: data?.algorithmVersion[0]?.is_active === "true" ? true : false,
    });

    if (
      data?.algorithmVersion[0]?.algorithm_id == algorithmName ||
      algorithmName === ""
    ) {
      delete data?.algorithmVersion[0]?.algorithm_id;
    }

    if (showCreateAlgorithmVerisons) {
      const res: any = await dispatch(
        editAlogrithmVersion(algorithmVersionId, data?.algorithmVersion[0])
      );

      if (res && (res.status === 200 || res.status === 201)) {
        setTimeout(() => {
          showModal();
        }, 1500);
      } else {
        return false;
      }
    }
    // Object.assign(data, { input_parameters: data?.input_parameters.join(",").replace(/\s+/g, '') });
    // Object.assign(data, { output_parameters: data?.output_parameters.join(",").replace(/\s+/g, '') });
    // dispatch(createAlgorithm(data))
  };
  const _alertClicked = useCallback(() => {
    const values = getValues();
    values.algorithmVersion.splice(0, 1);
    showModal();
  }, []);

  const getDockValue = (opts: any) => {
    if (opts && !opts.length) return null;
    return opts?.find(
      (c: any) => c.value === (dockValue || algorithmVersionData?.dockerimage)
    );
  };
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageAlgorithms;
  });
  const loadingState = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageAlgorithms
      ?.loadingState;
  });

  useEffect(() => {
    if (algorithmVersionData?.algorithm_id) {
      dispatch(getAlgorithmModelImageList(algorithmVersionData?.algorithm_id));
    }
  }, [algorithmVersionData?.algorithm_id]);

  // useEffect(() => {
  //   if (showCreateAlgorithmVerisons) {
  //     const algorithmVersionName = {
  //       value: algorithmVersionData?.algorithm_id,
  //       label: algorithmVersionData?.algorithm_name,
  //     };
  //     algorithmOptions?.push(algorithmVersionName);

  //     const algorithmVersionImage = [
  //       {
  //         value: algorithmVersionData?.dockerimage,
  //         label:
  //           algorithmVersionData?.docker_image_name +
  //           ":" +
  //           algorithmVersionData?.docker_image_tag,
  //       },
  //     ];
  //     dockerImageOptions = algorithmVersionImage;
  //   }
  // }, [algorithmOptions, algorithmVersionData, showCreateAlgorithmVerisons]);
  // useEffect(() => {
  //   // reset({
  //   //   algorithmVersionData: {
  //   //     algorithm_id: "33",
  //   //     dockerimage: "24",
  //   //     is_active: "true",
  //   //     version: "1.2",
  //   //   },
  //   // });
  // }, []);

  useEffect(() => {
    if (algorithmModelImageLists?.results && showCreateAlgorithmVerisons) {
      const algorithmVersionImage: any = [
        {
          value: algorithmVersionData?.dockerimage,
          label:
            algorithmVersionData?.docker_image_name +
            ":" +
            algorithmVersionData?.docker_image_tag,
        },
      ];
      // dockerImageOptions = algorithmVersionImage;
      const ary: any = [...dockerImageOptions, ...algorithmVersionImage];
      setDockerImageOpt(ary);
    } else {
      setDockerImageOpt(dockerImageOptions);
    }
  }, [algorithmModelImageLists?.results]);
  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_ALGORITHM_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleAlgoList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_ALGORITHM_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleAlgoList(false);
    }
  }, [loaderWithMessage]);

  const getAlgorithmPlaceholderValue = (algorithmVersionData: any) => {
    if (algorithmVersionData && algorithmVersionData?.id) {
      return algorithmVersionData?.algorithm_name;
    } else if (!algorithmVersionData && visibleAlgoList) {
      return "Select from the list";
    } else if (!algorithmVersionData && !visibleAlgoList) {
      return "You do not have permission";
    } else {
      return "Select from the list";
    }
  };

  return (
    <>
      {(algorithmVersionData || !showCreateAlgorithmVerisons) && (
        <Modal
          className="createHospital"
          isModeless={true}
          isOpen={isModalOpen}
          onDismiss={showModal}
          containerClassName={"modalStyle"}
          isBlocking={false}
        >
          {loadingState && <Loader></Loader>}
          {/* <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage> */}
          <Stack
            className="createHospitalSection"
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack.Item className="createHospitalHeading">
              <Text
                variant="large"
                className="muted pointer"
                onClick={showModal}
              >
                Algorithm Management
              </Text>
              <Text variant="large" className="lessthan muted">
                &gt;
              </Text>
              <Text variant="large">
                {!showCreateAlgorithmVerisons ? "Add" : "Edit"} Algorithm
                Version
              </Text>
            </Stack.Item>
            <Stack.Item align="end">
              <IconButton
                className=""
                iconProps={cancelIcon}
                onClick={_alertClicked}
              />
            </Stack.Item>
          </Stack>
          <form
            id="algorithmVersion"
            className={
              loaderWithMessage?.loadingState ? "point-events-none" : ""
            }
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack.Item
              className="algorithm deviceInputSection ms-Grid"
              dir="ltr"
            >
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg10">
                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg4 ms-lgOffset2">
                      <Label>Algorithm Name*</Label>
                      <Controller
                        control={control}
                        defaultValue={algorithmVersionData?.algorithm_id}
                        name="algorithmVersion[0].algorithm_id"
                        shouldUnregister
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => {
                          return (
                            <Select
                              placeholder={getAlgorithmPlaceholderValue(
                                algorithmVersionData
                              )}
                              classNamePrefix="addl-class"
                              options={algorithmOptions}
                              isDisabled={
                                !visibleAlgoList ||
                                showCreateAlgorithmVerisons ||
                                showCreateAlgorithmVerisons == null ||
                                showCreateAlgorithmVerisons === ""
                              }
                              value={algorithmOptions?.find(
                                (c: any) => c.value === value
                              )}
                              // loadOptions={}
                              // onInputChange={(val) => {
                              //   dispatch(getAlgorithmList(val));
                              // }}
                              onChange={(val) => {
                                onChange(val?.value);
                                dispatch(
                                  getAlgorithmModelImageList(val?.value)
                                );
                                setAlgorithmName(val?.value);
                                setdockValue(null);
                                // setHideDropdownValue(true);
                              }}
                            />
                          );
                        }}
                      />
                      <Label className="errorMessage">
                        {errors?.algorithmVersion
                          ? errors?.algorithmVersion[0]?.algorithm_id?.message
                          : ""}
                      </Label>
                      <TextField
                        autoComplete="off"
                        label="Version*"
                        key="version"
                        disabled={showCreateAlgorithmVerisons}
                        readOnly={false}
                        {...register(`algorithmVersion[0].version`)}
                        errorMessage={
                          errors?.algorithmVersion
                            ? errors?.algorithmVersion[0]?.version?.message
                            : ""
                        }
                        value={algorithmVersionData?.version}
                        // onChange={(e) => handleValidateForm(e, "name")}
                        defaultValue={algorithmVersionData?.version}
                      ></TextField>
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg9 ms-lgOffset2 except">
                      <Label>Docker Image*</Label>
                      <Controller
                        control={control}
                        defaultValue={algorithmVersionData?.dockerimage}
                        shouldUnregister
                        name="algorithmVersion[0].dockerimage"
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <Select
                              classNamePrefix="addl-class"
                              options={dockerImageOpt || []}
                              //value={getDockValue(dockerImageOptions)}
                              value={dockerImageOpt.find(
                                (c: any) => c.value === value
                              )}
                              // onMenuOpen={() => {
                              //   setHideDropdownValue(false);
                              // }}
                              onChange={(val: any) => {
                                onChange(val?.value);
                                setdockValue(val?.value);
                              }}
                              // controlShouldRenderValue={!hideDropdownValue}
                            />
                          );
                        }}
                      />
                      <Label className="errorMessage">
                        {errors?.algorithmVersion
                          ? errors?.algorithmVersion[0]?.dockerimage?.message
                          : ""}
                      </Label>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg4 ms-lgOffset2">
                      <Label>Status*</Label>
                      <Controller
                        control={control}
                        defaultValue={algorithmVersionData?.is_active}
                        name="algorithmVersion[0].is_active"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            classNamePrefix="addl-class"
                            options={options1}
                            value={options1.find((c) => c.value === value)}
                            onChange={(val) => {
                              onChange(val?.value);
                            }}
                          />
                        )}
                      />
                      <Label className="errorMessage">
                        {errors?.algorithmVersion
                          ? errors?.algorithmVersion[0]?.is_active?.message
                          : ""}
                      </Label>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </form>
          <Stack
            className="createHospitalWizardFooter"
            horizontal
            horizontalAlign="end"
          >
            <Stack.Item className="nextCancel">
              <PrimaryButton
                disabled={disabledCreate}
                type="submit"
                form="algorithmVersion"
                className="btn-next"
              >
                {showCreateAlgorithmVerisons ? "Update" : "Add"}
                <Text className="spinnerRight">
                  {disabledCreate && <Spinner size={SpinnerSize.small} />}
                </Text>
              </PrimaryButton>
              <ActionButton
                text="Cancel"
                className="btn-c"
                allowDisabledFocus
                onClick={showModal}
              />
            </Stack.Item>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CreateAlogrithmVersion;
