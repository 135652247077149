import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-markers";
import "videojs-markers/dist/videojs.markers.css";
import "./videoPlayer.scss";
import "@videojs/themes/dist/city/index.css";
import {
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  Toggle,
} from "@fluentui/react";
import { fonts, ThemeColorPalette } from "../../theme";
import { ROLE_TYPES } from "../../Layout/constants/constants";
import fullScreenIcon from "../../../src/assets/full-scr.png";
import ExitfullScreenIcon from "../../../src/assets/exit-full-screen.png";
const Controls: any = {
  play: "playToggle",
  volume: "volumePanel",
  seekbar: "progressControl",
  timer: "remainingTimeDisplay",
  playbackrates: "playbackRateMenuButton",
  fullscreen: "fullscreenToggle",
  pictureInPicture: "pictureInPictureToggle",
};
export interface Marker {
  time: number;
  text: any;
}
interface IRawVideoPlayerProps {
  userProfile?: any;
  params?: any;
  containerref?: any;
  src: any;
  poster: string;
  controls: boolean;
  autoplay: boolean;
  preload: "auto" | "none" | "metadata";
  width?: string | number;
  height?: string | number;
  responsive?: boolean;
  hideControls: string[];
  bigPlayButton: boolean;
  bigPlayButtonCentered: boolean;
  onReady?: (player: videojs.Player, videoRef: any) => void;
  onPlay?: (time: number) => void;
  onPause?: (time: number) => void;
  onTimeUpdate?: (time: number) => void;
  onSeeking?: (time: number) => void;
  onSeeked?: (position: number, completeTime: number) => void;
  onEnd?: () => void;
  playbackRates: number[];
  hidePlaybackRates: boolean;
  className: string;
  playerId?: string;
  handleActivEdgeVideoChange?: any;
  appliedAlgorithms?: any;
  modules?: any;
  match?: any;
  handleAnnotationSelectDropDown?: any;
  handleModelSelectDropDown?: any;
  activInsightVideo?: boolean;
  activVideoCheck?: boolean;
  sharedVideo?: boolean;
  activInsightDataLength?: number;
  videoData?: any;
  markers?: Marker[];
  loadedFrom?: string;
  isUploadedByUser?: boolean;
  mappedAlgorithmsToDataSet?: any;
  fullScreenEnble?: any;
  roleName?: any;
  markersSet?: any;
  fullScreenToggleOnOff?: any;
}

class RawVideoPlayer extends React.Component<IRawVideoPlayerProps> {
  playerId = `video-player`;
  player: videojs.Player = {} as videojs.Player;
  interval: any;
  containerRef: React.RefObject<any>;
  videoRef: React.RefObject<HTMLVideoElement>;
  fullscreenToggle: boolean;
  state = {
    handleProcessingButton: false,
    currentSelectedAnnotation: "",
    fullScreenClassVisible: false,
  };
  constructor(props: IRawVideoPlayerProps) {
    super(props);
    this.containerRef = props.containerref;
    this.fullscreenToggle = false;
    this.escToFullscreen = this.escToFullscreen.bind(this);
    this.videoRef = React.createRef();
    // this.escFunction = this.escFunction.bind(this);
  }

  public static defaultProps = {
    src: "",
    poster: "",
    controls: true,
    autoplay: false,
    preload: "auto",
    playbackRates: [0.5, 1, 1.5, 2, 3],
    hidePlaybackRates: false,
    className: "",
    hideControls: ["volume", "pictureInPicture"],
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    responsive: true,
    height: "500px",
    playerId: "video-player",
  };
  escToFullscreen(event: any) {
    // this.setState({
    //   fullScreenClassVisible: !this.state.fullScreenClassVisible,
    // });

    //this.handleFullScreen(!this.state.fullScreenClassVisible);
    this.setState({
      fullScreenClassVisible: !this.state.fullScreenClassVisible,
    });
    //this.handleFullScreen(!this.fullscreenToggle)
  }
  // escFunction(event:any){
  //   if (event.key === "Escape") {
  //     this.handleFullScreen(!this.fullscreenToggle)
  //   }
  // }
  componentDidMount() {
    document.addEventListener("fullscreenchange", this.escToFullscreen, false);
    // const body:any = document.getElementById('video-container')
    // body.addEventListener("keydown", this.escFunction, false);

    // document.addEventListener("keyup",(e:any)=> {
    //     if(e.keyCode === 27){
    //       this.handleFullScreen(!this.fullscreenToggle)
    //     }
    // })

    //   document.addEventListener("visibilitychange", ()=> {
    //     if (document.hidden){
    //         console.log("Browser tab is hidden")
    //         this.props.onPause && this.props.onPause(this.player?.currentTime() || 0);
    //     } else {
    //         console.log("Browser tab is visible")
    //    }
    // });

    this.init_player(this.props);
    this.init_player_events(this.props);
    const video = videojs(`${this.props.playerId}`) as any;
    video.tech_.off("dblclick");
    video.markers({
      markerStyle: {
        height: "12px",
        width: "8px",
        "background-color": "#01b3ff",
      },
      markerTip: {
        display: false,
        text: function (marker: { time: number; text: any }) {
          return marker.text;
        },
      },
      breakOverlay: {
        display: true,
        displayTime: 3,
        text: function (marker: { time: number; text: any }) {
          return marker.text;
        },
      },
      markers: this.props.markers || [],
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      (this.props.match && this.props.match.id !== prevProps.match.id) ||
      (prevProps.videoData &&
        (prevProps.videoData.primary_procedure_type !==
          this.props.videoData.primary_procedure_type ||
          prevProps.videoData.primary_surgeon !==
            this.props.videoData.primary_surgeon))
    ) {
      this.setState({
        ...this.state,
        currentSelectedAnnotation: "",
        handleProcessingButton: false,
      });
    }
    if (JSON.stringify(this.props.src) !== JSON.stringify(prevProps.src)) {
      this.init_player(this.props);
      this.init_player_events(this.props);
    }
    if (
      this.props?.modules &&
      this.props.modules?.length &&
      JSON.stringify(this.props.modules) !== JSON.stringify(prevProps.modules)
    ) {
      this.props.handleModelSelectDropDown(
        Number(this.props.modules[0].modelId)
      );
      this.setState({
        ...this.state,
        handleProcessingButton: false,
        currentSelectedAnnotation: Number(this.props.modules[0].modelId),
      });
      setTimeout(() => {
        this.player.currentTime(0);
      }, 2000);
    }
    // setTimeout(() => {
    //   if (
    //     localStorage.getItem("role") === "Developer" ||
    //     localStorage.getItem("role") === "ActivAdmin"
    //   ) {
    //     const video = videojs(`${this.props.playerId}`) as any;
    //     video.markers.removeAll();
    //   }
    // }, 1000);

    if (
      localStorage.getItem("role") === "Surgeon" ||
      localStorage.getItem("role") === "HospitalAdmin"
    ) {
      const video = videojs(`${this.props.playerId}`) as any;
      video.markers.removeAll();
      video.markers.add(this.props.markers);
      //video.markers.updateTime();
      // console.log(this.props.markers, "markers raw video file");
    }
  }
  componentWillReceiveProps(nextProps: IRawVideoPlayerProps) {
    this.set_controls_visibility(this.player, nextProps.hideControls);
    if (this.props.src.src !== nextProps.src.src) {
      this.init_player(nextProps);
    }
    // const oldMarkTimes = this.props.markers
    //   ?.map((marker: Marker) => marker.time)
    //   .sort()
    //   .toString();
    // const newMarkTimes = nextProps.markers
    //   ?.map((marker: Marker) => marker.time)
    //   .sort()
    //   .toString();
    // if (
    //   localStorage.getItem("role") === "Surgeon" ||
    //   localStorage.getItem("role") === "HospitalAdmin"
    // ) {
    //   setTimeout(()=>{
    //     console.log(nextProps?.markers, "markers",this.player.duration());
    //     const video = videojs(`${this.props.playerId}`) as any;
    //    // video.markers.removeAll();
    //     const markersDetails: any = nextProps?.markers;
    //     const durationTime: any = this.player.duration();
    //     console.log(durationTime, "durationTime markers");
    //     if (markersDetails?.length && Object.keys(markersDetails[1])?.length) {
    //       console.log(durationTime, "if markers");
    //       markersDetails[1].time = markersDetails[1].time
    //         ? this.correctDigitvalue(markersDetails[1].time)
    //         : this.correctDigitvalue(durationTime)
    //     }
    //     console.log(markersDetails, "final markers");
    //     video.markers.add(markersDetails);
    //   },4000)

    // }

    // if (oldMarkTimes !== newMarkTimes) {
    //   console.log(oldMarkTimes,'--', newMarkTimes,'newMarkTimes')
    //   const video = videojs(`${this.props.playerId}`) as any;
    //   setTimeout(() => {
    //     video.markers.removeAll();
    //     video.markers.add(nextProps.markers);
    //   }, 1000);
    //   if (
    //     localStorage.getItem("role") === "Developer" ||
    //     localStorage.getItem("role") === "ActivAdmin"
    //   ) {
    //     video.markers.removeAll();
    //   }
    // }
  }

  // correctDigitvalue (value:any) {
  //   if(value){
  //     console.log(value,'value')
  //     return value
  //   }
  // }

  componentWillUnmount() {
    if (this.player) this.player.dispose();
  }
  init_player(props: IRawVideoPlayerProps) {
    const playerOptions = this.generate_player_options(props);
    this.player = videojs(
      document.querySelector(`#${this.props.playerId}` as any),
      playerOptions
    );
    this.player.src(props.src);
    this.player.poster(props.poster);
    console.log(this.player.canPlayType("application/dash+xml"));
    this.set_controls_visibility(this.player, props.hideControls);
  }
  generate_player_options(props: IRawVideoPlayerProps) {
    const playerOptions: any = {};
    playerOptions.controls = props.controls;
    playerOptions.autoplay = props.autoplay;
    playerOptions.preload = props.preload;
    playerOptions.height = props.height;
    playerOptions.resposiveness = props.responsive;
    playerOptions.bigPlayButton = props.bigPlayButton;
    playerOptions.inactivityTimeout = 0;
    const hidePlaybackRates =
      props.hidePlaybackRates || props.hideControls.includes("playbackrates");
    if (!hidePlaybackRates) playerOptions.playbackRates = props.playbackRates;
    return playerOptions;
  }
  set_controls_visibility(player: any, hidden_controls: any) {
    Object.keys(Controls).forEach((x) => {
      player.controlBar[Controls[x]].show();
    });
    hidden_controls.map((x: string | number) =>
      player.controlBar[Controls[x]].hide()
    );
  }
  init_player_events(props: IRawVideoPlayerProps) {
    let currentTime = 0;
    let previousTime = 0;
    let position = 0;
    this.player.ready(() => {
      props.onReady && props.onReady(this.player, this.videoRef);
      (window as any).player = this.player;
    });
    this.player.on("play", () => {
      if (
        localStorage.getItem("role") === "Surgeon" ||
        localStorage.getItem("role") === "HospitalAdmin"
      ) {
        const video = videojs(`${this.props.playerId}`) as any;
        video.markers.removeAll();
        video.markers.add(this.props.markers);
        video.markers.updateTime();
        //console.log(this.props.markers, "markers play");
      }

      this.setState({ ...this.state, handleProcessingButton: true });
      props.onPlay && props.onPlay(this.player?.currentTime() || 0);
      setTimeout(() => {
        document
          .getElementsByClassName("vjs-play-control")[0]
          .addEventListener("click", () => {
            props.onPause && props.onPause(this.player?.currentTime() || 0);
          });
      }, 1000);
    });
    this.player.on("pause", (e: any) => {
      //  this.props.onPause && this.props.onPause(this.player?.currentTime() || 0);
    });
    this.player.on("timeupdate", (e) => {
      if (
        localStorage.getItem("role") === "Surgeon" ||
        localStorage.getItem("role") === "HospitalAdmin"
      ) {
        const video = videojs(`${this.props.playerId}`) as any;
        video.markers.reset(this.props.markers);
      }
      props.onTimeUpdate && props.onTimeUpdate(this.player?.currentTime() || 0);
      previousTime = currentTime;
      currentTime = this.player?.currentTime() || 0;
      if (previousTime < currentTime) {
        position = previousTime;
        previousTime = currentTime;
      }
    });
    this.player.on("seeking", () => {
      this.player.off("timeupdate", () => {});
      this.player.one("seeked", () => {});
      props.onSeeking && props.onSeeking(this.player?.currentTime() || 0);
    });
    this.player.on("seeked", () => {
      const completeTime = Math.floor(this.player?.currentTime() || 0);
      // props.onPause && props.onPause(completeTime);

      props.onSeeked && props.onSeeked(position, completeTime);
    });
    this.player.on("ended", () => {
      props.onEnd && props.onEnd();
    });
    this.player.on("click", () => {
      props.onPause && props.onPause(this.player?.currentTime() || 0);
    });
  }

  onRenderOption = (option: any): JSX.Element => {
    return (
      <div>{option.key === "button" && <PrimaryButton>Go</PrimaryButton>}</div>
    );
  };
  moduleDetails =
    this.props.modules?.map((model: any) => {
      if (this.props.loadedFrom === "developer_dataset") {
        let trimmedText;
        if (model.modelName && model.modelName.length > 20) {
          trimmedText = `${model.modelName.slice(0, 20)}... : ${
            model.modelVersion
          }`;
        } else {
          trimmedText = `${model.modelName} : ${model.modelVersion}`;
        }
        return {
          key: model.modelId,
          text: trimmedText,
          title: `${model.modelName} : ${model.modelVersion}`,
        };
      } else {
        return {
          key: model.modelId,
          text: model.modelName,
          title: model.modelName,
        };
      }
    }) || [];
  handleFullScreen = (fullscreenToggle: boolean) => {
    if (fullscreenToggle) {
      this.requestFullscreen();
      this.props.fullScreenToggleOnOff(true);
    } else {
      this.closeFullscreen();
      this.props.fullScreenToggleOnOff(false);
    }
    this.fullscreenToggle = fullscreenToggle;
  };
  requestFullscreen = () => {
    const elem = this.containerRef.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    // } else if (document.webkitExitFullscreen) { /* Safari */
    //   document.webkitExitFullscreen();
    // } else if (document.msExitFullscreen) { /* IE11 */
    //   document.msExitFullscreen();
    // }
  };

  render() {
    const options: IDropdownOption[] =
      this.props.appliedAlgorithms &&
      this.props.appliedAlgorithms.length &&
      localStorage.getItem("role") !== "ActivAdmin"
        ? [
            { key: "", text: "De-identified video" },
            ...this.props.appliedAlgorithms
              .filter((item: any) => item.AlgorithmType === "overlay")
              .map((item: any) => {
                return {
                  key: item.AlgorithmId,
                  text: item.AlgorithmName,
                };
              }),
          ]
        : [];
    const modelOptions: IDropdownOption[] = [
      { key: "", text: "Select Model" },
      ...this.moduleDetails,
    ];

    return (
      <>
        <div
          id="video-container"
          className={this.state.fullScreenClassVisible ? "full-screen" : ""}
          style={{ marginBottom: 10 }}
        >
          <video
            id={this.props.playerId}
            ref={this.videoRef}
            className={`video-js vjs-theme-city ${
              this.props.bigPlayButtonCentered ? "vjs-big-play-centered" : ""
            } ${this.props.className} ${this.props.loadedFrom}`}
          ></video>

          <div className="additional-stuff">
            {this.props.playerId === "video-player" &&
              // this.props.userProfile?.role_name === ROLE_TYPES.ACTIVE_DEVELOPER &&
              // this.props.loadedFrom === "developer-home" &&
              this.props.videoData?.status_name === "PROCESSED" && (
                // <Dropdown
                //   options={
                //     localStorage.getItem("role") !== "ActivAdmin" ? options : []
                //   }
                //   placeholder="Select Processing"
                //   calloutProps={{
                //     directionalHintFixed: true,
                //     calloutMaxHeight: 600,
                //   }}
                //   defaultSelectedKey={this.state.currentSelectedAnnotation}
                //   disabled={options.length ? false : true}
                //   styles={{
                //     dropdown: { width: 155 },
                //     dropdownItems: {
                //       color: ThemeColorPalette.uploadMsgTxt,
                //       backgroundColor: ThemeColorPalette.gridBG,
                //     },
                //     root: {
                //       right: 130,
                //       position: "absolute",
                //       top: -24,
                //     },
                //     title: {
                //       backgroundColor: "transparent",
                //       border: "none",
                //       fontFamily: fonts.HelveticaNeue_Medium,
                //     },
                //     dropdownItem: {
                //       color: ThemeColorPalette.pureWhite,
                //       fontFamily: fonts.DINPro_Bold,
                //     },
                //   }}
                //   onChange={(e, option: any) => {
                //     this.props.handleAnnotationSelectDropDown(option.key);
                //     this.setState({
                //       ...this.state,
                //       handleProcessingButton: false,
                //       currentSelectedAnnotation: option.key,
                //     });
                //   }}
                //   dropdownWidth={170}
                // />

                <select
                  className="custm-select-box-video-page"
                  placeholder="Select Processing"
                  defaultValue={this.state.currentSelectedAnnotation}
                  disabled={options.length ? false : true}
                  onChange={(e: any) => {
                    this.props.handleAnnotationSelectDropDown(e.target.value);
                    this.setState({
                      ...this.state,
                      handleProcessingButton: false,
                      currentSelectedAnnotation: e.target.value,
                    });
                  }}
                >
                  {options &&
                    options?.length &&
                    options.map((item: any) => (
                      <option
                        key={item.key}
                        style={{ background: "#1f1f1f" }}
                        value={item.key}
                      >
                        {item.text}
                      </option>
                    ))}
                  {options?.length == 0 && (
                    <option style={{ background: "#1f1f1f" }} value="">
                      Select Processing
                    </option>
                  )}
                </select>
              )}
            {this.props.fullScreenEnble && (
              <div>
                <img
                  width={18}
                  height={18}
                  className={"full-screen-icon-custom " + this.props.roleName}
                  // className={
                  //   this.props.roleName === "Developer"
                  //     ? "normal-screen-develpor"
                  //     : "xyz-patch normal-screen"
                  // }
                  onClick={(e) => this.handleFullScreen(!this.fullscreenToggle)}
                  src={
                    !this.state.fullScreenClassVisible
                      ? fullScreenIcon
                      : ExitfullScreenIcon
                  }
                />
                {/* <img
              width={15}
              height={15}
              className={'full-screen-icon-custom ' + this.props.roleName}
              // className={
              //   this.props.roleName === "Developer"
              //     ? "normal-screen-develpor"
              //     : "xyz-patch normal-screen"
              // }
              onClick={(e) => this.handleFullScreen(!this.fullscreenToggle)}
              src={ExitfullScreenIcon}
            /> */}
              </div>
            )}
            {/* {!this.props.fullScreenEnble && !this.state.fullScreenClassVisible && (
                <img
                  width={15}
                  height={15}
                  className={'full-screen-icon-custom ' + this.props.roleName}
                  // className={
                  //   this.props.roleName === "Developer"
                  //     ? "normal-screen-develpor"
                  //     : "xyz-patch normal-screen"
                  // }
                  onClick={(e) => this.handleFullScreen(!this.fullscreenToggle)}
                  src={ExitfullScreenIcon}
                />
              )} */}
          </div>
        </div>
        <div className="additional-stuff-2">
          {localStorage.getItem("role") === ROLE_TYPES.ACTIVE_DEVELOPER &&
            this.props.loadedFrom !== "developer-home" &&
            this.props.params.alg !== "" &&
            this.props.mappedAlgorithmsToDataSet &&
            this.props.mappedAlgorithmsToDataSet.length > 0 && (
              <Stack
                style={{
                  marginTop: "20px",
                  color: "#D7D7D7",
                  background: "#1f1f1f",
                  padding: 20,
                  maxHeight: 218,
                  overflow: "auto",
                }}
              >
                <StackItem>
                  <Text style={{ fontSize: "18px" }}>
                    {this.props.params.alg}
                  </Text>
                </StackItem>
                <StackItem
                  style={{
                    marginTop: "15px",
                    marginBottom: 8,
                    color: "#686868",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Other Algorithms Mapped with the video
                </StackItem>
                {this.props.mappedAlgorithmsToDataSet?.map(
                  (model: any, index: any) => (
                    <StackItem key={index} style={{ marginLeft: "5px" }}>
                      <Text style={{ fontSize: "14px", color: "#7E7E81" }}>
                        {++index}
                        {". "}
                        {model.algorithmName}
                      </Text>
                    </StackItem>
                  )
                )}
                {this.props?.modules?.length && (
                  <div>
                    <select
                      name="model0"
                      id="model0"
                      style={{
                        width: 190,
                        background: "transparent",
                        color: "rgb(110, 107, 107)",
                        height: 30,
                        border: 0,
                        textAlign: "center",
                        position: "absolute",
                        right: 105,
                        top: -45,
                        zIndex: 99,
                        fontSize: 12,
                      }}
                      onChange={(e: any) => {
                        this.props.handleModelSelectDropDown(
                          Number(e.target.value)
                        );
                        this.setState({
                          ...this.state,
                          handleProcessingButton: false,
                          currentSelectedAnnotation: Number(e.target.value),
                        });
                        setTimeout(() => {
                          this.player.currentTime(0);
                        }, 2000);
                      }}
                    >
                      {this.props &&
                        this.props.modules &&
                        this.props.modules.map((model: any) => (
                          <option
                            style={{ background: "#1f1f1f" }}
                            value={model.modelId}
                          >
                            {model?.modelName} {model?.modelVersion}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </Stack>
            )}
          {localStorage.getItem("role") === ROLE_TYPES.ACTIVE_DEVELOPER &&
            this.props.loadedFrom === "developer-home" &&
            this.props.params.alg !== "" &&
            this.props.mappedAlgorithmsToDataSet &&
            this.props.mappedAlgorithmsToDataSet.length > 0 && (
              <Stack
                style={{
                  marginTop: "20px",
                  color: "#D7D7D7",
                  background: "#1f1f1f",
                  padding: 20,
                  maxHeight: 218,
                  overflow: "auto",
                }}
              >
                <StackItem>
                  <Text style={{ fontSize: "18px" }}>
                    {this.props.params.alg}
                  </Text>
                </StackItem>
                <StackItem
                  style={{
                    marginTop: "15px",
                    marginBottom: 8,
                    color: "#686868",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Other Algorithms Mapped with the video
                </StackItem>
                {this.props.mappedAlgorithmsToDataSet?.map(
                  (model: any, index: any) => (
                    <StackItem key={index} style={{ marginLeft: "5px" }}>
                      <Text style={{ fontSize: "14px", color: "#7E7E81" }}>
                        {++index}
                        {". "}
                        {model.algorithmName}
                      </Text>
                    </StackItem>
                  )
                )}
                {this.props?.modules?.length && (
                  <div>
                    <select
                      name="model0"
                      id="model0"
                      style={{
                        width: 250,
                        background: "transparent",
                        color: "rgb(110, 107, 107)",
                        height: 30,
                        border: 0,
                        textAlign: "center",
                        position: "absolute",
                        right: 126,
                        top: -45,
                        zIndex: 9,
                      }}
                      onChange={(e: any) => {
                        this.props.handleModelSelectDropDown(
                          Number(e.target.value)
                        );
                        this.setState({
                          ...this.state,
                          handleProcessingButton: false,
                          currentSelectedAnnotation: Number(e.target.value),
                        });
                        setTimeout(() => {
                          this.player.currentTime(0);
                        }, 2000);
                      }}
                    >
                      {this.props &&
                        this.props.modules &&
                        this.props.modules.map((model: any) => (
                          <option
                            style={{ background: "#1f1f1f" }}
                            value={model.modelId}
                          >
                            {model?.modelName} {model?.modelVersion}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </Stack>
            )}
        </div>
      </>
    );
  }
}
export default React.memo(RawVideoPlayer);
