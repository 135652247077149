import {
  FontIcon,
  Image,
  Link,
  ProgressIndicator,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { ThemeColorPalette } from "../../theme";
import videoImg from "../../assets/icon_4.svg";

export interface ProcessingCardProps {
  screen?: string;
  processingData: any;
  handleProcessTabSelect?: any;
  handleLoadMoreProcessingVideos?: any;
  currentPage: string;
}

const ProcessingCard: React.SFC<ProcessingCardProps> = ({
  screen,
  processingData,
  handleProcessTabSelect,
  handleLoadMoreProcessingVideos,
  currentPage,
}) => {
  return (
    <Stack
      verticalFill
      verticalAlign="start"
      tokens={{ childrenGap: 12 }}
      styles={{
        root: {
          width: "100%",
          overflowX: "hidden",
        },
      }}
    >
      {processingData.results.map((data: any) => {
        return (
          <Stack
            key={data.id}
            horizontal
            horizontalAlign="space-between"
            styles={{
              root: {
                backgroundColor: data.isSelected
                  ? ThemeColorPalette.selectedGridBG
                  : ThemeColorPalette.gridBG,
                paddingLeft: screen ? "1rem" : "",
                marginRight: screen ? "" : "1.5rem",
                marginLeft: screen ? "" : "1.5rem",
                boxShadow: "7px 7px " + ThemeColorPalette.pureBlack,
                maxHeight: screen ? "4.5rem" : "5.5rem",
                minHeight: screen ? "4.5rem" : "5.5rem",
                cursor: screen ? "" : "pointer",
              },
            }}
            onClick={() => handleProcessTabSelect(data.id)}
          >
            {!screen && (
              <StackItem
                align="start"
                styles={{
                  root: {
                    padding: "0.5rem",
                  },
                }}
              >
                <FontIcon
                  iconName="SkypeCircleCheck"
                  style={{
                    color: ThemeColorPalette.themePrimary,
                    visibility: data.isSelected ? "visible" : "hidden",
                  }}
                />
              </StackItem>
            )}
            <StackItem
              align="center"
              styles={{ root: { flex: 1, marginLeft: "" } }}
            >
              <Image src={videoImg} width={"2rem"} height={"2rem"} />
            </StackItem>
            <StackItem
              align="center"
              styles={{
                root: { flex: 8, paddingLeft: "1rem" },
              }}
            >
              <TooltipHost content={data.procedure_name}>
                <StackItem
                  styles={{
                    root: { width: "15rem", overflowWrap: "break-word" },
                  }}
                >
                  <Text variant="smallPlus">
                    {data.procedure_name.slice(0, 30)}
                  </Text>
                </StackItem>
              </TooltipHost>
              <StackItem
                styles={{ root: { marginRight: screen ? "3.2rem" : "2rem" } }}
              >
                <ProgressIndicator />
              </StackItem>
              <Text variant="tiny" styles={{ root: { opacity: 0.5 } }}>
                {data.status}
              </Text>
            </StackItem>
          </Stack>
        );
      })}
      {currentPage ? (
        <StackItem
          styles={{
            root: {
              marginLeft: screen === "panel" ? "0.5rem" : "2rem",
              paddingTop: "0.5rem",
            },
          }}
          align="start"
        >
          <Link
            onClick={() => handleLoadMoreProcessingVideos(processingData.next)}
          >
            Load more+
          </Link>
        </StackItem>
      ) : null}
    </Stack>
  );
};

export default ProcessingCard;
