export const FETCH_PROCEDURE_VIDEOS = "FETCH_PROCEDURE_VIDEOS";
export const FETCH_PROCEDURE_ROW_VIDEOS = "FETCH_PROCEDURE_ROW_VIDEOS";
export const SET_SELECTED_PROCEDURE_VALUE = "SET_SELECTED_PROCEDURE_VALUE";
export const PROCEDURE_DATA_DATE_FILTER_TYPE =
  "PROCEDURE_DATA_DATE_FILTER_TYPE";
export const PROCEDURE_CUSTOM_DATE_FILTER = "PROCEDURE_CUSTOM_DATE_FILTER";

export const UPDATE_UPLOADING_VIDEOS_LIST = "UPDATE_UPLOADING_VIDEOS_LIST";
export const SET_SELECTED_UPLOADING_VIDEOS_LIST =
  "SET_SELECTED_UPLOADING_VIDEOS_LIST";
export const SINGLE_SELECT_OPTION = "SINGLE_SELECT_OPTION";
export const MULTI_SELECT_OPTION = "MULTI_SELECT_OPTION";
export const HANDLE_MULTI_DATA_SAVE = "HANDLE_MULTI_DATA_SAVE";
export const DELETE_UPLOAD_ITEM = "DELETE_UPLOAD_ITEM";
export const CLEAR_UPLOAD_VIDEOS_METADATA = "CLEAR_UPLOAD_VIDEOS_METADATA";
export const UPLOAD_METADATA_ACTION = "UPLOAD_METADATA_ACTION";
export const UPLOAD_METADATA_MULTI_ACTION = "UPLOAD_METADATA_MULTI_ACTION";
export const UPDATE_PATIENT_CONSENT_FORM = "UPDATE_PATIENT_CONSENT_FORM";

export const UPDATE_UPLOAD_VIDEOS_LIST_AFTER_UPLOAD_BUTTON_CLICK =
  "UPDATE_UPLOAD_VIDEOS_LIST_AFTER_UPLOAD_BUTTON_CLICK";

export const CLEAR_DATA_ON_LOGOUT = "CLEAR_DATA_ON_LOGOUT";

export const SHOW_SPINNER = "SHOW_SPINNER";
