export const GET_CONTAINERS_LIST = "GET_CONTAINERS_LIST";
export const GET_MODEL_NAME = "GET_MODEL_NAME";
export const CREATE_CONTAINER = "CREATE_CONTAINER";
export const CREATE_CONTAINER_ERROR = "CREATE_CONTAINER_ERROR";
export const DELETE_CONTAINER = "DELETE_CONTAINER";
export const DELETE_CONTAINER_ERROR = "DELETE_CONTAINER_ERROR";
export const DELETE_MODEL = "DELETE_MODEL";
export const DELETE_MODEL_ERROR = "DELETE_MODEL_ERROR";
export const ERASE_MODEL_ERROR = "ERASE_MODEL_ERROR";
export const ERASE_CONTAINER_ERROR = "ERASE_CONTAINER_ERROR";
export const MODEL_VIEW = "MODEL_VIEW";
export const CREATE_MODEL = "CREATE_MODEL";
export const CREATE_MODEL_ERROR = "CREATE_MODEL_ERROR";
export const SHOW_MODEL_SPINNER = "SHOW_MODEL_SPINNER";
export const SHOW_CONTAINER_SPINNER = "SHOW_CONTAINER_SPINNER";
export const SHOW_CREATE_CONTAINER = "SHOW_CREATE_CONTAINER";
export const EDIT_CONTAINER = "EDIT_CONTAINER";
export const CLOSE_CONTAINER_MODAL = "CLOSE_CONTAINER_MODAL";
export const GET_CONTAINER_VIEW = "GET_CONTAINER_VIEW";
export const GET_DATASETS_BY_ID = "GET_DATASETS_BY_ID";
export const REFRESH_DATASETS_BY_ID = "REFRESH_DATASETS_BY_ID";
export const SHOW_DATASET_SPINNER = "SHOW_DATASET_SPINNER";
export const LOADER = "LOADER";
export const STOPLOADER = "STOPLOADER";
export const GET_MODEL_NAMES_BY_ALG_ID = "GET_MODEL_NAMES_BY_ALG_ID";
export const GET_MODELS_BY_ALG_ID = "GET_MODELS_BY_ALG_ID";
export const GET_MODEL_VERSION = "GET_MODEL_VERSION";
export const GET_MODEL_COMPARISON_PARAMETERS =
  "GET_MODEL_COMPARISON_PARAMETERS";
export const SUBMIT_MODEL_COMPARISON = "SUBMIT_MODEL_COMPARISON";
export const SUBMIT_COMMENT_ERROR = "SUBMIT_COMMENT_ERROR";
export const CLEAR_COMMENT_ERROR = "CLEAR_COMMENT_ERROR";
export const GET_FIRST_VIDEO = "GET_FIRST_VIDEO";
export const GET_SECOND_VIDEO = "GET_SECOND_VIDEO";
export const GET_FIRST_VIDEO_ANNOTATION = "GET_FIRST_VIDEO_ANNOTATION";
export const GET_SECOND_VIDEO_ANNOTATION = "GET_SECOND_VIDEO_ANNOTATION";
export const CLEAR_VIDEO_COMPARISON_DATA = "CLEAR_VIDEO_COMPARISON_DATA";
export const GET_MODELS_SELECTED_ALGID = "GET_MODELS_SELECTED_ALGID";
export const REFRESH_MODELS_SELECTED_ALGID = "REFRESH_MODELS_SELECTED_ALGID";
export const GET_VERSIONS_SELECTED_MODEL = "GET_VERSIONS_SELECTED_MODEL";
export const ERASE_VERSIONS_DATA = "ERASE_VERSIONS_DATA";
export const ERASE_DELETE_CONTAINER_RESPONSE =
  "ERASE_DELETE_CONTAINER_RESPONSE";
export type AnnotationType =
  | "GET_FIRST_VIDEO_ANNOTATION"
  | "GET_SECOND_VIDEO_ANNOTATION";
export type VideoOverLayType = "GET_FIRST_VIDEO" | "GET_SECOND_VIDEO";
export const DELETE_MODEL_DISABLE = "DELETE_MODEL_DISABLE";
export const COMMENTS_LOADER = "COMMENTS_LOADER";
export const LOGOUT_DEVELOPER_ALERT = "LOGOUT_DEVELOPER_ALERT";
export const RESET_DELETE_MODEL_ERROR = "RESET_DELETE_MODEL_ERROR";
