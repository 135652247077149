import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  Link,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { apiEndPoint } from "../../common/restApi";
import { fonts, ThemeColorPalette } from "../../theme";
import moment from "moment";
import {
  clearRecentActivitiesOnlogout,
  getRecentActivitiesOfUser,
  showSpinnerOnLoad,
  sortRecentActivities,
} from "../actions/recentActivitiesAction";

export interface RecentActivitiesProps {
  getRecentActivitiesOfUser: any;
  sortRecentActivities: any;
  recentActivities: any;
  nextPage: string;
  showSpinnerOnLoad: any;
  showSpinner: boolean;
  showData: boolean;
  clearRecentActivitiesOnlogout: any;
}

export interface RecentActivitiesState {
  sort: boolean;
}

class RecentActivities extends React.Component<
  RecentActivitiesProps,
  RecentActivitiesState
> {
  state = { sort: false };

  onColumnClick = (typeOfSort: any) => {
    if (typeOfSort === "name") {
      this.props.sortRecentActivities(typeOfSort, this.state.sort);
      this.setState({ ...this.state, sort: !this.state.sort });
    } else {
      this.props.sortRecentActivities(typeOfSort, this.state.sort);
      this.setState({ ...this.state, sort: !this.state.sort });
    }
  };

  columns: IColumn[] = [
    {
      key: "column1",
      name: "Recent Activity",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 500,
      isPadded: true,
      onColumnClick: () => this.onColumnClick("name"),
    },
    {
      key: "column2",
      name: "Activity Time",
      fieldName: "value",
      minWidth: 100,
      maxWidth: 500,
      isPadded: true,
      onColumnClick: () => this.onColumnClick("value"),
    },
  ];

  componentDidMount() {
    this.props.showSpinnerOnLoad();
    const url = `${apiEndPoint}/activities/${localStorage.getItem(
      "userProfileId"
    )}/user?page=1&page_size=15`;
    this.props.getRecentActivitiesOfUser(url);
  }

  componentDidUpdate() {
    // this.props.recentActivities.forEach((element: any) => {
    //   element.value = moment(element.value).local().format("MM/DD/YY HH:mm");
    // });
  }

  componentWillUnmount() {
    this.props.clearRecentActivitiesOnlogout();
  }
  _renderItemColumn(item: any, index: any, column: any) {
    const fieldContent = item[column.fieldName as keyof any] as string;

    switch (column.key) {
      case "column2":
        return (
          <span>
            {moment(fieldContent).local().format("MM/DD/YY HH:mm A") ===
            "Invalid date"
              ? fieldContent
              : moment(fieldContent).local().format("MM/DD/YY HH:mm A")}
          </span>
        );

      default:
        return <span>{fieldContent}</span>;
    }
  }
  render() {
    return this.props.showData ? (
      <Stack
        styles={{
          root: {
            minHeight: "100vh",
            maxHeight: "100%",
            minWidth: "100vh",
            maxWidth: "100%",
          },
        }}
        tokens={{ padding: "2rem 3.5rem", childrenGap: 20 }}
      >
        <StackItem>
          <Text
            variant="xLarge"
            styles={{
              root: {
                fontFamily: fonts.DINPro_Medium,
                color: ThemeColorPalette.uploadMsgTxt,
                fontWeight: "normal",
                fontSize: "24px",
              },
            }}
          >
            Recent Activities
          </Text>
        </StackItem>
        <StackItem
          styles={{
            root: {
              backgroundColor: ThemeColorPalette.metrixbg,
            },
          }}
        >
          {
            <DetailsList
              items={this.props.recentActivities}
              columns={this.columns}
              selectionMode={SelectionMode.none}
              setKey="set"
              onRenderItemColumn={this._renderItemColumn}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onShouldVirtualize={() => false}
            />
          }
        </StackItem>
        <Link
          styles={{
            root: {
              display: this.props.nextPage ? "block" : "none",
              width: "5rem",
            },
          }}
          onClick={() =>
            this.props.getRecentActivitiesOfUser(this.props.nextPage)
          }
        >
          Load more +
        </Link>
      </Stack>
    ) : this.props.showSpinner ? (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { marginTop: "25%" } }}
      >
        <Spinner size={SpinnerSize.large} />
      </Stack>
    ) : (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { marginTop: "25%" } }}
      >
        <Text>No Recent Activities available</Text>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    recentActivities: state.recentActivities.recentActivitiesList,
    nextPage: state.recentActivities.nextPage,
    showSpinner: state.recentActivities.showSpinner,
    showData: state.recentActivities.showData,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    getRecentActivitiesOfUser: (url: string) =>
      dispatch(getRecentActivitiesOfUser(url)),
    sortRecentActivities: (sortElement: string, sortType: boolean) =>
      dispatch(sortRecentActivities(sortElement, sortType)),
    showSpinnerOnLoad: () => dispatch(showSpinnerOnLoad()),
    clearRecentActivitiesOnlogout: () =>
      dispatch(clearRecentActivitiesOnlogout()),
  };
};

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(RecentActivities);
