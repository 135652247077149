import React from "react";
import { Stack, Checkbox, Text } from "@fluentui/react";
import ToggleCard from "../../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useState, useCallback, useEffect } from "react";
import { ReactComponent as ReactUser } from "../../../../../assets/Management/User.svg";
import {
  clearPrivilageError,
  editPriviligeById,
  getPermissionsList,
  updateRolesPermissionActionData,
  updateIsPermissionsFetchedForCreatePrivilege,
  updateIsPermissionsFetchedForEditPrivilege,
  getPriviligeById,
} from "../../../actions/managementAction";
import { AppState } from "../../../../../rootReducer";
import {
  showRoleSpinner,
  createRolesPermission,
  roleReset,
} from "../../../../../ActiveAdminModule/Management/actions/managamentActions";
import {
  createPrivileges,
  showPrivilegeSpinner,
} from "../../../actions/managementAction";
import { Label, StackItem } from "office-ui-fabric-react";
import { cloneDeep } from "lodash";
import Loader from "../../../../../ActiveAdminModule/Logs/components/Common/Loader";
import LoaderWithMessage from "../../../../../common/customLoader/LoaderWithMessage";

const AssignPermissions = ({
  closeModal,
  activeStep,
  isEdit = localStorage.getItem("previledgeisId") || null,
  spinnerFunc,
}: {
  closeModal: any;
  activeStep: number;
  isEdit: any;
  spinnerFunc: any;
}) => {
  const dispatch = useDispatch();

  const [createError, setErrors] = useState<{ [key: string]: boolean }>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [submitOngoing, setSubmitOngoing] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
    watch,
  } = useForm();

  const watchFields = watch(["showAge", "number"]);

  useEffect(() => {
    setErrors({});
  }, [activeStep]);

  const permissions = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.permissionList.data;
  });
  const closeRoles = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.closeRoles;
  });
  const rolesPermissionData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.rolesPermissionData;
  });

  const priviligeById = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.privilegeById;
  });

  const createPrivilegeError = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.createPrivilegeError;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.loaderWithMessage;
  });

  const isPermissionsFetchedForCreatePrivilege = useSelector(
    (state: AppState) => {
      return state.hospitalManagementReducerData
        .isPermissionsFetchedForCreatePrivilege;
    }
  );

  const isPermissionsFetchedForEditPrivilege = useSelector(
    (state: AppState) => {
      return state.hospitalManagementReducerData
        .isPermissionsFetchedForEditPrivilege;
    }
  );

  const [permissionsList, setPermissionsList] = useState(permissions);

  // const formChanged = (e: any, key: string, id: number) => {
  //   const updatedPermissions: any = cloneDeep(permissionsList);
  //   const updatedObj = updatedPermissions[key].find((p: any) => p.id === id);
  //   if (updatedObj) {
  //     updatedObj.selected = e.target.checked;
  //     // setPermissionsList(updatedPermissions);
  //     const rolesPermissionData1 = {
  //       ...rolesPermissionData,
  //       [key]: updatedPermissions[key],
  //     };
  //     dispatch(updateRolesPermissionActionData(rolesPermissionData1));
  //     dispatch(clearPrivilageError());
  //   }
  // };

  // useEffect(() => {
  //   isEdit &&  dispatch(
  //     updateRolesPermissionActionData({
  //       ...rolesPermissionData,
  //       ...permissions,
  //     })
  //   );
  // }, [permissions]);

  //Utility functions
  const updateObjectChecked = (data: any, isChecked: boolean | undefined) => {
    return {
      ...data,
      selected: isChecked,
    };
  };
  const sortUpdatedPermissiosn = (arrayOfPermissions: any) => {
    arrayOfPermissions &&
      arrayOfPermissions.sort(
        (compareFirstName: any, compareSecondNamae: any) => {
          const firstName = compareFirstName.name.toUpperCase();
          const secondName = compareSecondNamae.name.toUpperCase();
          if (firstName < secondName) {
            return -1;
          }
          if (firstName > secondName) {
            return 1;
          }
          return 0;
        }
      );
  };

  const getUpdatedPermissionsKeyWiseWhenChecked = (
    originalPermissions: any,
    selectedKey?: any,
    event?: any
  ) => {
    const updatedPermissionsWithKey: any = [];
    originalPermissions &&
      Object.keys(originalPermissions)?.map((key: any) => {
        if (key === selectedKey) {
          const currentSelectedPermissionKeyWise = originalPermissions[key];
          const remainingPermissionObject: any = [];
          const checkedPermissionsObject: any = [];
          let updatedPermissionObject: any = {};
          for (const currentObject of currentSelectedPermissionKeyWise &&
            currentSelectedPermissionKeyWise) {
            if (currentObject.name === event?.currentTarget.title) {
              const obj = updateObjectChecked(
                currentObject,
                !currentObject.selected
              );
              // let results = permissionsList[selectedKey].map((task: any) => {
              //   if (task.id === obj.id) {
              //     task.selected = obj.selected
              //   }
              //   return false;
              // });
              permissionsList[selectedKey].map(function (task: any) {
                if (task.id === obj.id) {
                  task.selected = obj.selected;
                }
              });
              // setPermissionsList(res);
              checkedPermissionsObject.push(obj);
            } else {
              remainingPermissionObject.push(currentObject);
            }
          }

          if (checkedPermissionsObject || remainingPermissionObject) {
            updatedPermissionObject = [
              ...checkedPermissionsObject,
              ...remainingPermissionObject,
            ];
          }

          updatedPermissionObject &&
            Object.assign(updatedPermissionsWithKey, {
              [key]: updatedPermissionObject,
            });
        }
      });
    sortUpdatedPermissiosn(updatedPermissionsWithKey[selectedKey]);
    return updatedPermissionsWithKey;
  };

  const getUpdatedPermissionsKeyWiseWithoutChecked = (
    originalPermissions: any
  ) => {
    const updatedPermissionsWithKey: any = [];
    originalPermissions &&
      Object.keys(originalPermissions)?.map((key: any) => {
        if (key) {
          const currentSelectedPermissionKeyWise = originalPermissions[key];
          const updatedPermissionObject: any = [];
          for (const currentObject of currentSelectedPermissionKeyWise &&
            currentSelectedPermissionKeyWise) {
            updatedPermissionObject.push(currentObject);
          }

          updatedPermissionObject &&
            Object.assign(updatedPermissionsWithKey, {
              [key]: updatedPermissionObject,
            });
        }
      });
    sortUpdatedPermissiosn(updatedPermissionsWithKey);
    return updatedPermissionsWithKey;
  };

  const onChangeSlection = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    selectedKey?: any
  ) => {
    //Building updated objects
    const updatedPermissionsWithKey = getUpdatedPermissionsKeyWiseWhenChecked(
      !isEdit ? permissions : priviligeById,
      selectedKey,
      ev
    );

    // (!isEdit ? rolesPermissionData : permissions) &&
    //   Object.keys(!!showCreateRole ? rolesPermissionData : permissions)?.map(
    //     (key: any) => {
    //       if (key === selectedKey) {
    //         (!!showCreateRole ? rolesPermissionData : permissions)[
    //           selectedKey
    //         ] = updatedPermissionsWithKey[selectedKey];
    //       }
    //     }
    //   );
  };

  //UseEffect to build Create/Edit PermissionsList Object
  useEffect(() => {
    setErrors({});
    const fetchPermissionsList = async () => {
      if (!isEdit && !isPermissionsFetchedForCreatePrivilege) {
        // For Creating Privilege
        const response: any = await dispatch(getPermissionsList());
        if (response && response.status === 200) {
          dispatch(updateIsPermissionsFetchedForCreatePrivilege(true));
          const updatedPermissions = Object.assign({}, response.data);
          const createdPermissions =
            getUpdatedPermissionsKeyWiseWithoutChecked(updatedPermissions);
          setPermissionsList(createdPermissions);
        }
      } else if (isEdit && !isPermissionsFetchedForEditPrivilege) {
        // For Editing Privilege
        const response: any = await dispatch(getPriviligeById(isEdit));
        if (response && response.status === 200) {
          dispatch(updateIsPermissionsFetchedForEditPrivilege(true));
          const fetchedPrivilages: any = Object.assign({}, response.data);
          delete fetchedPrivilages?.name;
          delete fetchedPrivilages?.role_location;
          delete fetchedPrivilages?.user_type;
          delete fetchedPrivilages?.comments;
          delete fetchedPrivilages?.logs;
          const editedPermissions =
            getUpdatedPermissionsKeyWiseWithoutChecked(fetchedPrivilages);
          setPermissionsList(editedPermissions);
        }
      }
    };
    fetchPermissionsList();
  }, []);

  const onSubmit = (data: any) => {
    setSubmitOngoing(true);
    spinnerFunc(true);

    // const formPermission = data.permissions as any;

    const formPermissionUpdated: any = {
      management: permissionsList.management || [],
      vps: permissionsList.vps || [],
    };
    let permissionsRightParent: any = {};
    const prviligesForEdit = {};
    let atleastOneneSelected = false;
    Object.keys(formPermissionUpdated)?.map((key: any) => {
      const permission1 = formPermissionUpdated[key];
      const permissionOrigional = priviligeById[key] || permissionsList[key];
      const permissionsRight = permission1
        ?.filter((per: any) => per.selected === true)
        .map((per: any, index: any) => ({
          id: permissionOrigional.find((per1: any) => per1.id === per.id)?.id,
        }));
      if (formPermissionUpdated[key]?.length > 0) {
        const keySelected = formPermissionUpdated[key]?.filter(
          (per: any) => per.selected === true
        );
        if (keySelected?.length > 0) {
          atleastOneneSelected = true;
        }
      }
      const selectedList = permissionOrigional.filter(
        (per2: any) => per2.selected
      );
      let removedList = selectedList.filter(
        (per3: any) =>
          !permission1.find((per4: any) => per3.id === per4.id)?.selected
      );
      removedList = removedList.map((per8: any) => ({
        ...per8,
        selected: false,
      }));
      const formSelectedList = permission1.filter((per5: any) => per5.selected);
      let permissionForEditSection = formSelectedList.map((per6: any) => {
        const permissionObj =
          permissionOrigional.find((per11: any) => per11.id === per6.id) || {};
        return {
          ...permissionObj,
          selected: true,
        };
      });
      permissionForEditSection = [...permissionForEditSection, ...removedList];
      Object.assign(permissionsRightParent, { [key]: permissionsRight });
      Object.assign(prviligesForEdit, { [key]: permissionForEditSection });
    });

    // dispatch(showPrivilegeSpinner());
    if (isEdit) {
      permissionsRightParent = {
        ...rolesPermissionData,
        ...formPermissionUpdated,
      };
      const { role_location } = permissionsRightParent as any;
      const vpsPerCheck: any = permissionsRightParent.vps;
      const mangementPerCheck: any = permissionsRightParent.management;
      if (role_location) {
        permissionsRightParent = {
          ...permissionsRightParent,
          role_location: role_location.toUpperCase(),
        };
        if (vpsPerCheck.length === 0 && mangementPerCheck.length !== 0) {
          mangementPerCheck?.map((lengcheck: any) => {
            if (lengcheck.selected !== false) {
              delete permissionsRightParent.vps;
            }
          });
        }

        if (mangementPerCheck.length === 0 && vpsPerCheck.length !== 0)
          vpsPerCheck?.map((lengcheck: any) => {
            if (lengcheck.selected !== false) {
              delete permissionsRightParent.management;
            }
          });
      }
      delete permissionsRightParent.role_location;
      if (atleastOneneSelected) {
        setErrorMessage("");
        dispatch(editPriviligeById(isEdit, permissionsRightParent, closeModal));
      } else {
        spinnerFunc(false);
        setErrorMessage("Atleast one permission must be provided");
      }
    } else {
      const mangementPer: any = permissionsRightParent.management;
      if (mangementPer.length === 0) {
        delete permissionsRightParent.management;
      }
      const vpsPer: any = permissionsRightParent.vps;
      if (vpsPer.length === 0) {
        delete permissionsRightParent.vps;
      }
      permissionsRightParent = {
        ...rolesPermissionData,
        ...permissionsRightParent,
      };
      delete permissionsRightParent.role_location;

      dispatch(createPrivileges(permissionsRightParent));
    }
  };

  // useEffect(() => {
  //   if(isEdit){ onSubmit("data")}
  // }, []);

  useEffect(() => {
    if (createPrivilegeError) {
      spinnerFunc(false);
    }
  }, [createPrivilegeError]);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoader(false);
  //   }, 1000);
  // }, []);

  // // Update Privilege Group Permissions
  // useEffect(() => {
  //   if (!permissions?.length && !isPermissionsFetchedForEditPrivilege && isEdit) {
  //     //dispatch(getPermissionsList());
  //     dispatch(updateIsPermissionsFetchedForEditPrivilege(true));
  //   }
  //   setErrors({});
  // }, [isEdit, isPermissionsFetchedForEditPrivilege]);

  // // Create Privilege Group Permissions
  // useEffect(() => {
  //   if(!isEdit && !isPermissionsFetchedForCreatePrivilege){
  //     dispatch(getPermissionsList());
  //   }
  // }, [isEdit, isPermissionsFetchedForCreatePrivilege])

  return (
    <>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
      <Stack>
        <Stack horizontal horizontalAlign="center" verticalAlign="center">
          <Stack.Item className="roleNameSection">
            <Text variant="medium">Privilege Group</Text>
            <Stack
              className="roleName"
              horizontalAlign="center"
              verticalAlign="center"
            >
              <ReactUser />
              <Text variant="small">{rolesPermissionData.name}</Text>
            </Stack>
          </Stack.Item>
        </Stack>
        {createPrivilegeError && (
          <Label className="errorMessage">{createPrivilegeError}</Label>
        )}
        {submitOngoing && (
          <Label className="errorMessage bold-error">{errorMessage}</Label>
        )}
        <form id="roleForm" onSubmit={handleSubmit(onSubmit)}>
          {permissionsList &&
            Object.keys(permissionsList)?.map((key: any, index: any) => {
              const permission = permissionsList[key];
              return (
                <ToggleCard
                  heading={key}
                  index=""
                  removeFormFields=""
                  key={index}
                >
                  <Stack className="permissionGroupSection ms-Grid-row">
                    <Stack.Item className="ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg9 ms-lgOffset1">
                        <Stack.Item className="ms-Grid-row">
                          {permission &&
                            permission?.map(
                              (permissionValues: any, index: any) => (
                                <Stack.Item
                                  className="ms-Grid-col ms-lg3 algorithmSection"
                                  key={index}
                                >
                                  <Controller
                                    name={`permissions.${key}.${permissionValues.id}`}
                                    control={control}
                                    defaultValue={permissionValues?.selected}
                                    render={({ field }: any) => {
                                      return (
                                        <Stack horizontal dir="ltr">
                                          <Checkbox
                                            defaultChecked={
                                              permissionValues?.selected
                                            }
                                            {...field}
                                            onChange={(event) => {
                                              setSubmitOngoing(false);
                                              setErrorMessage("");
                                              onChangeSlection(event, key);
                                            }}
                                            ref={null}
                                            label={permissionValues?.name}
                                            title={permissionValues?.name}
                                          />
                                        </Stack>
                                      );
                                    }}
                                  />
                                </Stack.Item>
                              )
                            )}
                        </Stack.Item>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack>
                </ToggleCard>
              );
            })}
        </form>
      </Stack>
    </>
  );
};

export default AssignPermissions;
