import {
  Stack,
  SearchBox,
  Modal,
  ActionButton,
  Label,
  Icon,
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Videos from "../../../ActiveAdminModule/Home/components/VideoSection/Video";
import Paging from "../../../common/paging/Paging";
import Select, { components } from "react-select";
import { getVideoCountList, getVideoList } from "../actions/developerAction";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../rootReducer";
import { getHospitalsCount } from "../../../ActiveAdminModule/Home/actions/homeAction";
import Loader from "../../Management/components/common/loader";
import { debounce } from "lodash";
import { showSpinnerLayout } from "../../../Layout/actions/layoutActions";
import SuccessMessage from "../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
const devices = [
  { value: 1, label: "Device" },
  { value: 2, label: "Non-Device" },
];
const options: IChoiceGroupOption[] = [
  { key: "-created_date", text: "Last Created" },
  { key: "-modified_date", text: "Last Modified" },
  { key: "hospital__name", text: "A-Z" },
  { key: "-hospital__name", text: "Z-A" },
];
const Home = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useForm();
  const [currentVideoPage, setCurrentVideoPage] = useState<number>(1);
  const [selectedDevice, setSelectedDevice] = useState<any>(devices[1]);
  const [loadingVideos, setLoadingVideos] = useState<boolean>(false);
  const [sortOptions, setSortOptions] = useState(false);
  const [sortVideoValue, setSortVideoValue] = useState("-created_date");
  const [showPage, setshowPage] = useState<any>(true);
  const [isInitialLanding, setIsInitialLanding] = React.useState(false);
  const [selectedHospitalWithCount, setSelectedHospitalWithCount] =
    useState<any>();
  const mainInnerRef = useRef<any>();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (sortOptions) {
            setSortOptions(false);
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, sortOptions]);
  }
  const [searchValue, setSearchValue] = useState("");

  const UserProfileSpinner = useSelector((state: AppState) => {
    return state.dashboardReducerData.UserProfileSpinner;
  });

  const videoProcedureWithCounts = useSelector((state: AppState) => {
    return state?.developerReducerData?.videosCount?.data;
  });

  const videoProcedureWithCountOptions1 =
    videoProcedureWithCounts?.results?.map((videoProcedureWithCount: any) => ({
      value: videoProcedureWithCount.id,
      label: videoProcedureWithCount.name,
      count: `(${videoProcedureWithCount.total_videos})`,
    }));

  const videoProcedureWithCountOptions = videoProcedureWithCountOptions1 && [
    ...videoProcedureWithCountOptions1
      .reduce((map: any, obj: any) => map.set(obj.value, obj), new Map())
      .values(),
  ];

  const dispatch = useDispatch();

  const videos = useSelector((state: AppState) => {
    return state.developerReducerData.videos.data;
  });
  const videoLoading = useSelector((state: AppState) => {
    return state.developerReducerData.loadingVideos;
  });
  const handleSortOptions = () => {
    setSortOptions((prevValue) => !prevValue);
  };
  const onVideoPageChange = (pageNo: number) => {
    setCurrentVideoPage(pageNo);
    dispatch(
      getVideoList(
        selectedDevice.value,
        selectedHospitalWithCount,
        pageNo,
        "",
        searchValue,
        sortVideoValue
      )
    );
  };
  // useEffect(() => {
  //   setCurrentVideoPage(1);
  //   setshowPage(false);
  //   dispatch(
  //     getVideoList(
  //       selectedDevice.value,
  //       selectedHospitalWithCount,
  //       1,
  //       "",
  //       "",
  //       sortVideoValue
  //     )
  //   );
  //   setTimeout(() => {
  //     setshowPage(true);
  //   }, 1000);
  // }, [selectedHospitalWithCount]);

  useEffect(() => {
    setLoadingVideos(videoLoading);
  }, [videoLoading]);

  const algInputHandler = useCallback(
    debounce((evt: any) => {
      dispatch(getVideoCountList(selectedDevice.value, evt));
    }, 2000),
    []
  );
  const algInputChangeHandler = (evt: any) => {
    const value = evt?.target.value;
    algInputHandler(value);
  };

  const handleScrollAlg = (evt: any) => {
    if (videoProcedureWithCounts?.next) {
      dispatch(
        getVideoCountList(
          selectedDevice.value,
          "",
          videoProcedureWithCounts?.next
        )
      );
    }
  };
  // React.useEffect(() => {
  //   if (
  //     !isInitialLanding &&
  //     (searchValue.length >= 3 || searchValue.length == 0)
  //   ) {
  //     const getSearchData = setTimeout(() => {
  //       dispatch(
  //         getVideoList(
  //           selectedDevice?.value,
  //           selectedHospitalWithCount,
  //           1,
  //           "",
  //           searchValue,
  //           sortVideoValue
  //         )
  //       );
  //       setSearchValue(searchValue);
  //       setCurrentVideoPage(1);
  //   }, 2000);
  //     return () => clearTimeout(getSearchData);
  //   }
  // }, [searchValue]);
  useEffect(() => {
    const currentVideoPage: any = localStorage.getItem("currentVideoPage") || 1;
    const searchValue: any = localStorage.getItem("searchValue") || "";
    const sortVideoValue: any =
      localStorage.getItem("sortVideoValue") || "-created_date";
    const selectedDevice: any =
      localStorage.getItem("selectedDevice") || JSON.stringify(devices[1]);
    const selectedHospitalWithCount: any =
      Number(localStorage.getItem("selectedHospitalWithCount")) || null;
    setSelectedHospitalWithCount(selectedHospitalWithCount);
    setSearchValue(searchValue);
    setIsInitialLanding(true);
    setSortVideoValue(sortVideoValue);
    setSelectedDevice(JSON.parse(selectedDevice));
    setCurrentVideoPage(Number(currentVideoPage) || 1);
    dispatch(
      getVideoList(
        JSON.parse(selectedDevice)?.value,
        selectedHospitalWithCount,
        currentVideoPage,
        "",
        searchValue,
        sortVideoValue
      )
    );
    dispatch(getVideoCountList(JSON.parse(selectedDevice)?.value));
    setTimeout(() => {
      setIsInitialLanding(false);
    }, 3000);
  }, []);
  useEffect(() => {
    localStorage.setItem("currentVideoPage", String(currentVideoPage));
    localStorage.setItem("sortVideoValue", String(sortVideoValue));
    localStorage.setItem("searchValue", String(searchValue));
    localStorage.setItem("selectedDevice", JSON.stringify(selectedDevice));
    localStorage.setItem(
      "selectedHospitalWithCount",
      String(selectedHospitalWithCount)
    );
  }, [
    currentVideoPage,
    sortVideoValue,
    searchValue,
    selectedDevice,
    selectedHospitalWithCount,
  ]);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        mainInnerRef?.current &&
        !mainInnerRef?.current?.contains(e?.target)
      ) {
        localStorage.removeItem("currentVideoPage");
        localStorage.removeItem("sortVideoValue");
        localStorage.removeItem("searchValue");
        localStorage.removeItem("selectedDevice");
        localStorage.removeItem("selectedHospitalWithCount");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
  function handleDebounceFn(
    inputValue: any,
    selectedDevice: any,
    selectedHospitalWithCount: any,
    sortVideoValue: any
  ) {
    setCurrentVideoPage(1);
    dispatch(
      getVideoList(
        selectedDevice?.value,
        selectedHospitalWithCount,
        1,
        "",
        inputValue,
        sortVideoValue
      )
    );
  }
  // const handleChange = (event: any) => {
  //   if (event.length > 3 || event.length === 0) debounceFn(event);
  // };
  const handleChange = (
    event: any,
    selectedDevice: any,
    selectedHospitalWithCount: any,
    sortVideoValue: any
  ) => {
    if (event.length > 3 || event.length === 0)
      debounceFn(
        event,
        selectedDevice,
        selectedHospitalWithCount,
        sortVideoValue
      );
  };
  const VideoOption = (props: any) => {
    return (
      <components.Option {...props} className="justify-space-between">
        <span>{props.data.label}</span>
        <span>{props.data.count}</span>
      </components.Option>
    );
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    localStorage.removeItem("currentVideoPage");
    localStorage.removeItem("sortVideoValue");
    localStorage.removeItem("searchValue");
    localStorage.removeItem("selectedDevice");
    localStorage.removeItem("selectedHospitalWithCount");
  };

  return (
    <div ref={mainInnerRef}>
      <Stack>
        <Stack.Item className="allHospitalsSectionHeading">
          {loadingVideos && (
            <>
              <Loader></Loader>
            </>
          )}
          <Stack
            tokens={{ padding: "7.5px 24px" }}
            className="positionRelative"
          >
            <Stack className="ms-Grid" dir="ltr">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg3">
                  <Select
                    classNamePrefix="addl-class"
                    options={devices}
                    value={selectedDevice}
                    onChange={(val) => {
                      setSelectedDevice(val);
                      setCurrentVideoPage(1);
                      // setSelectedHospitalWithCount("");
                      dispatch(
                        // getVideoList(val?.value, "", 1, "", "", sortVideoValue)
                        getVideoList(
                          val?.value,
                          selectedHospitalWithCount,
                          1,
                          "",
                          searchValue,
                          sortVideoValue
                        )
                      );
                      dispatch(getVideoCountList(val?.value));
                    }}
                  />
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3">
                  <Controller
                    control={control}
                    // defaultValue={selectedHospitalWithCount}
                    {...register("videos")}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        components={{ Option: VideoOption }}
                        // clearValue={valueClear}
                        isClearable
                        isSearchable
                        classNamePrefix="addl-class"
                        options={videoProcedureWithCountOptions}
                        placeholder="All Videos"
                        value={
                          selectedHospitalWithCount &&
                          videoProcedureWithCountOptions?.find(
                            (c: any) => c.value === selectedHospitalWithCount
                          )
                        }
                        onKeyDown={(e) => algInputChangeHandler(e)}
                        onMenuScrollToBottom={(e) => handleScrollAlg(e)}
                        onChange={(val) => {
                          setSelectedHospitalWithCount(val?.value);
                          setCurrentVideoPage(1);
                          // onVideoPageChange(1);
                          // dispatch(getVideoList(selectedDevice, val?.value));
                          dispatch(
                            // getVideoList(
                            //   selectedDevice.value,
                            //   val?.value,
                            //   1,
                            //   "",
                            //   searchValue,
                            //   sortVideoValue
                            // )
                            getVideoList(
                              selectedDevice.value,
                              val?.value,
                              1,
                              "",
                              searchValue,
                              sortVideoValue
                            )
                          );
                        }}
                      />
                    )}
                  />
                  {/* <Dropdown
                      
                        placeholder="Select an option"
                        label="Custom label"
                        ariaLabel="Custom dropdown label example"
                        options={hospitalsWithCountOptions1}
                        onChange={(evt: FormEvent, option?: IDropdownOption) => {
                          // handleChange(evt, data, option, "procedureName");
                          let value: any = option;
                          setSelectedHospitalWithCount(value.key);
                          dispatch(getVideos(value.key));
                          console.log(value.key,option);
                        }}
                      /> */}
                </Stack.Item>
                <Stack.Item
                  className="searchBoxSection"
                  styles={{
                    root: { display: "flex", justifyContent: "flex-end" },
                  }}
                >
                  {/* <SearchBox
                  styles={{
                    root: {
                      width: "200px",
                      backgroundColor: "none",
                      marginRight: "10px",
                    },
                  }}
                  className="searchBox"
                  placeholder="Search"
                  underlined={true}
                  // onSearch={(newValue: any) => {
                  //   console.log("asdsadsada");
                  // }}
                  value={searchValue}
                  // onClear={(ev:any) => {
                  //   console.log(ev);
                  //   // setSearchValue(newValue);

                  // }}
                  onChange={(_, newValue: any) => {
                    setSearchValue(newValue);
                  }}
                /> */}
                  <SearchBox
                    styles={{
                      root: {
                        width: "200px",

                        backgroundColor: "none",

                        marginRight: "10px",
                      },
                    }}
                    className="searchBox"
                    placeholder="Search"
                    underlined={true}
                    onSearch={(newValue) => newValue}
                    value={searchValue}
                    onChange={(_, newValue: any) => {
                      setSearchValue(newValue);
                      setshowPage(false);
                      // handleChange(newValue);
                      handleChange(
                        newValue,
                        selectedDevice,
                        selectedHospitalWithCount,
                        sortVideoValue
                      );
                      setTimeout(() => {
                        setshowPage(true);
                      }, 1000);
                    }}
                  />
                  <Stack.Item className="allSectionButtons">
                    <div ref={wrapperRef}>
                      <Stack styles={{ root: { height: 32 } }}>
                        <ActionButton
                          className="sortAction"
                          onClick={handleSortOptions}
                          allowDisabledFocus
                          id="sortButton"
                          styles={{
                            root: { height: 32 },
                            flexContainer: {
                              justifyContent: "space-between",
                              width: 100,
                            },
                          }}
                        >
                          <Label className="sortDropdownHeading">Sort</Label>
                          {sortOptions ? (
                            <Icon
                              iconName="ChevronUp"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          ) : (
                            <Icon
                              iconName="ChevronDown"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          )}
                        </ActionButton>
                      </Stack>
                      {sortOptions && (
                        <Stack
                          className="sortOptionsDropdown sortOptionsUserDropdown"
                          styles={{ root: { bacground: "#121212" } }}
                        >
                          <Stack.Item className="sortOptionsDropdownHeading">
                            Sort By
                          </Stack.Item>
                          <ChoiceGroup
                            defaultSelectedKey={sortVideoValue}
                            // selectedKey={selectedKey}
                            options={options}
                            // onChange={onChangeSortBy}
                            onChange={(
                              evt: any,
                              option?: IChoiceGroupOption
                            ) => {
                              setCurrentVideoPage(1);
                              // handleChange(evt, data, option, "procedureName");
                              const value: any = option;
                              dispatch(
                                // getVideoList(
                                //   selectedDevice,
                                //   "",
                                //   1,
                                //   "",
                                //   "",
                                //   value.key
                                // )
                                getVideoList(
                                  selectedDevice.value,
                                  selectedHospitalWithCount,
                                  1,
                                  "",
                                  searchValue,
                                  value.key
                                )
                              );
                              setSortVideoValue(value.key);
                            }}
                          />
                        </Stack>
                      )}
                    </div>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 24 }}>
              <Stack.Item className="ms-Grid width-100" dir="ltr">
                <Stack.Item className="ms-Grid-row">
                  {videos?.results.map((video: any) => (
                    <Stack.Item key={video.id} className="ms-Grid-col ms-lg3">
                      <Videos
                        video={video}
                        key={video.id}
                        videoId={video.id}
                        loadedFrom="developer-home"
                        showProcedureVideos="true"
                        is_uploaded_by_user={
                          selectedDevice.value === 2 ? true : false
                        }
                      ></Videos>
                    </Stack.Item>
                  ))}
                  {/* {videos?.results.map((video: any) => (
                  <Stack.Item key={video.id} className="ms-Grid-col ms-lg3">
                    <Videos
                      video={video}
                      key={video.id}
                      videoId={video.id}
                      loadedFrom="developer-home"
                    ></Videos>
                  </Stack.Item>
                ))} */}
                </Stack.Item>
                {videos?.count > 12 && videos?.page_size && showPage ? (
                  <Paging
                    currentPage={currentVideoPage}
                    totalPageCount={Math.ceil(videos?.count / 12)}
                    handlePageChange={onVideoPageChange}
                  />
                ) : null}
                <Modal
                  isOpen={UserProfileSpinner}
                  containerClassName={"modalStyle"}
                >
                  <SuccessMessage title="User Profile Updated" />
                </Modal>

                {UserProfileSpinner &&
                  setTimeout(() => {
                    dispatch(showSpinnerLayout());
                  }, 2000) && <></>}
              </Stack.Item>
            </Stack>
          </Stack>
        </Stack.Item>
      </Stack>
    </div>
  );
};
export default Home;
