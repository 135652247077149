import axios from "axios";
import { doHttpCall, apiEndPoint } from "../../common/restApi";
import {
  convertDurationOfVideoToSec,
  getRandomColor,
  secToHMS,
  unZipFile,
} from "../../common/util";
import { ROLE_TYPES } from "../../Layout/constants/constants";
import * as allActions from "./actionConstants";

export const getActivInsightsData =
  (algorithmId: number, videoId: number) => async (dispatch: any) => {
    try {
      const payload = {
        method: "GET",
        url:
          apiEndPoint +
          "/videos/" +
          videoId +
          "/algorithms/" +
          algorithmId +
          "/activinsights",
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        let activSightData: any = unZipFile(response.data);
        activSightData = JSON.parse(activSightData);
        dispatch({
          type: allActions.GET_ACTIV_INSIGHTS_DATA,
          payload: activSightData,
        });
      } else if (response && response.status === 404) {
        dispatch({
          type: allActions.GET_ACTIV_INSIGHTS_DATA,
          payload: {},
        });
      }
    } catch (e) {
      dispatch({
        type: allActions.GET_ACTIV_INSIGHTS_DATA,
        payload: {},
      });
      console.log(e);
    }
  };

export const getVideoPhases =
  (algorithmId: number, videoId: number) =>
  async (dispatch: any, getState: any) => {
    try {
      const payload = {
        method: "GET",
        url:
          apiEndPoint +
          "/videos/" +
          videoId +
          "/algorithms/" +
          algorithmId +
          "/phases",
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        let newArr: any;
        const phasesAfterColorAdd: any = [];
        if (
          response.data &&
          response.data.phases &&
          response.data.phases.length
        ) {
          const phases = response.data.phases;
          const phasesObj: any = {};
          phases &&
            phases.forEach((data: any, i: number) => {
              Object.keys(data).forEach((key: any) => {
                if (phasesObj[key]) {
                  const color = phasesObj[key][key]["color"];
                  phasesObj[key] = data;
                  phasesObj[key][key]["color"] = color;
                  getState().videoPlayerReducerData.totalPhasesTime +=
                    convertDurationOfVideoToSec(phasesObj[key][key].duration);
                  phasesAfterColorAdd.push(phasesObj[key]);
                } else {
                  phasesObj[key] = data;
                  phasesObj[key][key]["color"] = getRandomColor()[i];
                  getState().videoPlayerReducerData.totalPhasesTime +=
                    convertDurationOfVideoToSec(phasesObj[key][key].duration);
                  phasesAfterColorAdd.push(phasesObj[key]);
                }
              });
            });

          // phasesAfterColorAdd &&
          //   phasesAfterColorAdd.forEach((data: any) => {
          //     const key = Object.keys(data)[0];
          //     if (newArr[key]) {
          //       const time = newArr[key]["totalTime"];
          //       newArr[key]["totalTime"] =
          //         convertDurationOfVideoToSec(data[key]["from"]) + time;
          //     } else {
          //       newArr[key] = {
          //         totalTime: convertDurationOfVideoToSec(
          //           data[key]["from"].slice(0, 8)
          //         ),
          //         color: data[key]["color"],
          //         name: key,
          //       };
          //     }
          //   });

          //  phasesAfterColorAdd && phasesAfterColorAdd.map((data:any)=>{
          //     data['totalTime'] = convertDurationOfVideoToSec(data["from"].slice(0, 8))
          // })

          phasesAfterColorAdd &&
            phasesAfterColorAdd.map((data: any) => {
              const key = Object.keys(data)[0];
              (data[key]["totalTime"] = secToHMS(
                convertDurationOfVideoToSec(data[key]["from"].slice(0, 8))
              )),
                (data[key]["name"] = key);
              // newArr.push({key:{
              //   totalTime: secToHMS(convertDurationOfVideoToSec(
              //     data[key]["from"].slice(0, 8)
              //   )),
              //   color: data[key]["color"],
              //   name: key,
              // }})
            });

          // newArr = Object.keys(newArr).map((data: any) => {
          //   return {
          //     ...newArr[data],
          //     totalTime: secToHMS(newArr[data].totalTime),
          //   };
          // });
        }
        dispatch({
          type: allActions.GET_VIDEO_PHASES,
          payload: {
            phases: phasesAfterColorAdd,
            phasesLegendData: phasesAfterColorAdd,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getOverlayUrls =
  (videoId: number, annotationType: string) => async (dispatch: any) => {
    try {
      const payload = {
        method: "GET",
        url:
          apiEndPoint +
          "/videos/" +
          videoId +
          "/algorithms/" +
          annotationType +
          "/jsons",
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        const overlayUrls = response.data.files;
        let data = {};
        overlayUrls.forEach((val: any, index: any) => {
          data = {
            ...data,
            [index]: {
              url: val.path,
              downloadStatus: false,
              duration: val.duration,
            },
          };
        });
        dispatch({
          type: allActions.GET_ANNOTATION_BLOB_URLS,
          payload: data,
        });
        return response.status;
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getModelOverlayUrls =
  (videoId: number, modelId: number) => async (dispatch: any) => {
    try {
      const payload = {
        method: "GET",
        url: `${apiEndPoint}/developer/videos/${videoId}/models/${modelId}/outputs`,
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        const overlayUrls = response.data;
        let data = {};
        overlayUrls.forEach((val: any, index: any) => {
          data = {
            ...data,
            [index]: {
              url: val.path,
              downloadStatus: false,
              duration: val.duration,
            },
          };
        });
        dispatch({
          type: allActions.GET_ANNOTATION_BLOB_URLS,
          payload: data,
        });
        return response.status;
      }
    } catch (e) {
      console.log(e);
    }
  };

const API_worker = new Worker("/workers/worker.js");
export const getAnnotationData =
  (duration: any) => (dispatch: any, getState: any) => {
    try {
      API_worker.postMessage({
        duration: duration,
        overlayUrls: getState().videoPlayerReducerData.overlayUrls,
      });
      //API_worker.postMessage({duration: duration, state: getState()})

      API_worker.onmessage = (event: any) => {
        // console.log("anno callback")
        if (event.data) {
          // console.time("anno data");
          dispatch({
            type: allActions.FETCH_ANNOTATION_DATA,
            payload: {
              annotationData: event.data.annotationData,
              duration: event.data.duration,
              index: event.data.index,
            },
          });
          // console.timeEnd("anno data")
        }
      };
    } catch (e) {
      console.log(e);
    }
  };

export const setCurrentVideoPlayTime = (duration: any) => (dispatch: any) => {
  dispatch({
    type: allActions.SET_CURRENT_VIDEO_PLAYER_TIME,
    payload: duration,
  });
};

// export const getVideoDetails =
//   (
//     videoId: any,
//     isSurgeon = false,
//     additionalInfo: any = {},
//     primary_surgeon?: any,
//     isFavourite: any = false
//   ) =>
//   async (dispatch: any, getState: any) => {
//     try {
//       if (!isSurgeon && !isFavourite) {
//         const isUploadedByUser =
//           additionalInfo.loadedFrom === ROLE_TYPES.ACTIVE_ADMIN ||
//           ROLE_TYPES.ACTIVE_DEVELOPER
//             ? `&is_uploaded_by_user=${additionalInfo.uploadedByUser}`
//             : "";
//         let urlParams = primary_surgeon
//           ? "?id=" +
//             videoId +
//             isUploadedByUser +
//             "&primary_surgeon=" +
//             primary_surgeon
//           : "?id=" + videoId + isUploadedByUser;
//         const payload1 = {
//           method: "GET",
//           url: apiEndPoint + `/videos/${urlParams}`,
//         };
//         dispatch({
//           type: allActions.SHOW_SPINNER,
//         });
//         dispatch({
//           type: allActions.SHOW_SPINNER_VIDEO_DETAILS,
//         });
//         const response = await doHttpCall(payload1);
//         if (
//           response &&
//           response.status === 200 &&
//           response.data
//           // response.data.length
//         ) {
//           const {
//             results: [data],
//           } = response.data;
//           dispatch({
//             type: allActions.FETCH_VIDEO_DETAILS,
//             payload: { data: data, sharedVideo: true },
//           });
//           if (data && data.status_name === "PROCESSED") {
//             const payload2 = {
//               method: "GET",
//               url: apiEndPoint + "/videos/" + videoId + "/applied-algorithms",
//             };
//             const jsonUrlsResponse = await doHttpCall(payload2);
//             if (jsonUrlsResponse && jsonUrlsResponse.status === 200) {
//               dispatch({
//                 type: allActions.FETCH_SEGMENTATION_DATA,
//                 payload: jsonUrlsResponse.data,
//               });
//             }
//             return true;
//           } else if (!data) {
//             dispatch({
//               type: allActions.SHOW_NO_DATA,
//             });
//             return true;
//           }
//         }
//       } else if (!isSurgeon && isFavourite) {
//         const payload = {
//           method: "GET",
//           url: apiEndPoint + "/videos/favorites?id=" + videoId,
//         };
//         const response = await doHttpCall(payload);
//         if (response && response.status === 200) {
//           const data = response.data;
//           dispatch({
//             type: allActions.FETCH_VIDEO_DETAILS,
//             payload: {
//               data: data && data.results.length ? data.results[0] : {},
//               sharedVideo: true,
//             },
//           });
//         } else {
//           dispatch({
//             type: allActions.SHOW_NO_DATA,
//           });
//         }
//         return false;
//       } else {
//         const payload = {
//           method: "GET",
//           url: apiEndPoint + "/videos/sharedvideo/" + videoId,
//         };
//         const response = await doHttpCall(payload);
//         if (response && response.status === 200) {
//           const data = response.data;
//           dispatch({
//             type: allActions.FETCH_VIDEO_DETAILS,
//             payload: { data: data, sharedVideo: true },
//           });
//         } else {
//           dispatch({
//             type: allActions.SHOW_NO_DATA,
//           });
//         }
//         return false;
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };
export const getVideoDetails =
  (videoId: any, additionalInfo?: any, primary_surgeon?: any, location?: any) =>
  async (dispatch: any, getState: any) => {
    console.log("innnnnn");
    try {
      if (location) {
        const isUploadedByUser =
          additionalInfo.loadedFrom === ROLE_TYPES.ACTIVE_ADMIN ||
          ROLE_TYPES.ACTIVE_DEVELOPER
            ? additionalInfo.uploadedByUser
              ? `&is_uploaded_by_user=${additionalInfo.uploadedByUser}`
              : "&is_uploaded_by_user=false"
            : "";
        let urlParams;
        if (location === "favourite") {
          urlParams = "/videos/favorites?id=" + videoId;
        } else if (location === "sharedWithMe") {
          urlParams = "/videos/sharedvideo/" + videoId;
        } else {
          urlParams = primary_surgeon
            ? "/videos/?id=" +
              videoId +
              isUploadedByUser +
              "&primary_surgeon=" +
              primary_surgeon
            : "/videos/?id=" + videoId + isUploadedByUser;
        }
        console.log(urlParams, "location323", location);
        const payload1 = {
          method: "GET",
          url: apiEndPoint + urlParams,
        };
        dispatch({
          type: allActions.SHOW_SPINNER,
        });
        dispatch({
          type: allActions.SHOW_SPINNER_VIDEO_DETAILS,
        });
        const response = await doHttpCall(payload1);
        console.log(response.data, "responce22");
        if (
          response &&
          response.status === 200 &&
          response.data
          // response.data.length
        ) {
          // const {results: [data]} = response.data;
          const data = response.data;
          dispatch({
            type: allActions.FETCH_VIDEO_DETAILS,
            payload: {
              data:
                data && data.results && data.results.length
                  ? data.results[0]
                  : data,
              sharedVideo: true,
            },
          });
          if (
            data &&
            data?.results?.length &&
            data?.results[0]?.status_name === "PROCESSED"
          ) {
            const payload2 = {
              method: "GET",
              url: apiEndPoint + "/videos/" + videoId + "/applied-algorithms",
            };
            const jsonUrlsResponse = await doHttpCall(payload2);
            if (jsonUrlsResponse && jsonUrlsResponse.status === 200) {
              dispatch({
                type: allActions.FETCH_SEGMENTATION_DATA,
                payload: jsonUrlsResponse.data,
              });
            }
            return true;
          } else if (!data) {
            dispatch({
              type: allActions.SHOW_NO_DATA,
            });
            return true;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
export const setSelectedVideoUnfavourite =
  (data: any) => async (dispatch: any) => {
    if (data.favorite) {
      const payload = {
        method: "DELETE",
        url: apiEndPoint + "/videos/" + data.id + "/favorite",
      };
      await doHttpCall(payload);
    } else {
      const payload = {
        method: "POST",
        url: apiEndPoint + "/videos/" + data.id + "/favorite",
        data: {
          user_id: data.primary_surgeon,
          video_id: data.id,
        },
      };
      await doHttpCall(payload);
    }
  };

export const showSpinnerOnLoad = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER });
};

export const clearVideoData = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_VIDEO_DATA,
  });
};

export const getVideosByProcedure =
  (procedureId: number, pageNo = 1, isUploadedByUser = true) =>
  async (dispatch: any) => {
    const currentUserPage = pageNo > 1 ? "&page=" + pageNo : "&page=1";

    const payload = {
      method: "GET",
      url: `${apiEndPoint}/videos/?primary_procedure_type=${procedureId}${currentUserPage}&page_size=12&is_uploaded_by_user=${isUploadedByUser}`,
    };
    try {
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIDEOS_BY_PROCEDURE,
          payload: { data: response.data },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getVideoComments =
  (videoId: any, nextUrl?: string, limit = 50) =>
  async (dispatch: any) => {
    try {
      const url =
        nextUrl ||
        `${apiEndPoint}/videos/${videoId}/comments?page=1&page_size=${limit}`;
      const payload = {
        method: "GET",
        url,
      };
      // if (!!nextUrl) {
      //   dispatch({
      //     type: allActions.RESET_VIDEO_COMMENTS,
      //   });
      // }
      dispatch({ type: allActions.SHOW_COMMENT_LOADER });
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIDEO_COMMENTS,
          payload: { data: response.data },
        });
        dispatch({ type: allActions.HIDE_COMMENT_LOADER });
      }
    } catch (e) {
      dispatch({ type: allActions.HIDE_COMMENT_LOADER });
      console.log(e);
    }
  };

export const addVideoComment =
  (videoCommentData: any, videoId: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/" + videoId + "/comments",
      data: videoCommentData,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.ADD_VIDEO_COMMENT,
          payload: { data: videoCommentData },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const addReplyToComment =
  (replyCommentData: any, videoId: any, commentID: any) =>
  async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/" + videoId + "/comments",
      data: replyCommentData,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.ADD_REPLYTO_VIDEO_COMMENT,
          payload: { data: replyCommentData },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const deleteVideoComment =
  (video_id: any, comment_id: any) => async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + `/videos/${video_id}/comments/${comment_id}`,
    };
    dispatch({
      type: allActions.DELETE_COMMENT_FROM_COMMENTS,
      id: comment_id,
    });
    dispatch({ type: allActions.SHOW_COMMENT_LOADER });
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.DELETE_VIDEO_COMMENT,
        payload: { data: response.data },
      });
      dispatch(getVideoComments(video_id));
      dispatch({ type: allActions.HIDE_COMMENT_LOADER });
    } else {
      dispatch(getVideoComments(video_id));
      dispatch({ type: allActions.HIDE_COMMENT_LOADER });
    }
  };

export const editVideoCommentById =
  (videoId: any, commentId: any, commentData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/videos/${videoId}/comments/${commentId}`,
      data: commentData,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_VIDEO_COMMENT,
          payload: { data: commentData },
        });
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Bad Request" },
        });
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Internal Server Error" },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
