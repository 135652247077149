import * as allActions from "../actions/actionConstants";

const initialState = {
  uploadingVideosData: [],
};

export const uploadVideoMetaDataReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case allActions.UPDATE_UPLOADING_VIDEOS_LIST:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.concat(action.payload),
      };
    case allActions.SET_SELECTED_UPLOADING_VIDEOS_LIST:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (data.file.name === action.payload.file.name) {
            return { ...data, selectToUpload: !data.selectToUpload };
          } else {
            return { ...data };
          }
        }),
      };
    case allActions.SINGLE_SELECT_OPTION:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (data.file.name === action.payload.file.name) {
            return {
              ...data,
              isSelected: !data.isSelected,
            };
          } else {
            return {
              ...data,
              isSelected: false,
            };
          }
        }),
        uploadingVideosMetaData: action.payload,
      };
    case allActions.MULTI_SELECT_OPTION:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (data.file.name === action.payload.file.name) {
            return {
              ...data,
              isSelected: !data.isSelected,
            };
          } else {
            return { ...data };
          }
        }),
      };
    case allActions.CLEAR_UPLOAD_VIDEOS_METADATA:
      return {
        ...state,
        uploadingVideosData: [],
      };
    case allActions.DELETE_UPLOAD_ITEM:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.filter(
          (data: any) => data.file.name !== action.payload.file.name
        ),
      };
    case allActions.UPDATE_PATIENT_CONSENT_FORM:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (data.id === action.payload.id) {
            return {
              ...data,
              patientConsentForm: action.payload.patientConsentForm,
            };
          } else {
            return {
              ...data,
            };
          }
        }),
        uploadingVideosMetaData: action.payload,
      };

    case allActions.UPLOAD_METADATA_ACTION:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (
            data.id === action.payload.id &&
            action.payload.primaryProcedureName !== undefined &&
            action.payload.primarySurgeonName !== undefined &&
            action.payload.primaryProcedureName !== "Select Procedure Name" &&
            action.payload.primarySurgeonName !== "Enter Surgeon Name"
          ) {
            return { ...action.payload, upload: true };
          } else if (data.id === action.payload.id) {
            return {
              ...action.payload,
              upload: false,
              isSelected: true,
            };
          } else {
            return {
              ...data,
              isSelected: false,
            };
          }
        }),
      };
    case allActions.UPLOAD_METADATA_MULTI_ACTION:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (
            data.id === action.payload.id &&
            action.payload.primaryProcedureName !== undefined &&
            action.payload.primarySurgeonName !== undefined &&
            action.payload.primaryProcedureName !== "Select Procedure Name" &&
            action.payload.primarySurgeonName !== "Enter Surgeon Name"
          ) {
            return { ...action.payload, upload: true };
          } else if (data.id === action.payload.id) {
            return { ...action.payload, isSelected: true, upload: false };
          } else {
            return { ...data };
          }
        }),
      };
    case allActions.HANDLE_MULTI_DATA_SAVE:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.map((data: any) => {
          if (data.isSelected) {
            return {
              ...data,
              primaryProcedureName: action.payload.primaryProcedureName,
              primarySurgeonName: action.payload.primarySurgeonName,
              upload:
                action.payload.primaryProcedureId !== 123 &&
                action.payload.primarySurgeonId !== 123 &&
                action.payload.primaryProcedureId &&
                action.payload.primarySurgeonId
                  ? true
                  : false,
              isSelected: false,
              videoData: {
                ...data.videoData,
                primaryProcedureId: action.payload.primaryProcedureId,
                primarySurgeonId: action.payload.primarySurgeonId,
              },
            };
          } else {
            return {
              ...data,
            };
          }
        }),
      };
    case allActions.UPDATE_UPLOAD_VIDEOS_LIST_AFTER_UPLOAD_BUTTON_CLICK:
      return {
        ...state,
        uploadingVideosData: state.uploadingVideosData.filter(
          (data: any) => data.id !== action.payload
        ),
      };

    default:
      return state;
  }
};
