import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getAllDevices =
  (currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/hospitals/devices-allocated${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_HOSPITAL_DEVICES_LIST));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_HOSPITAL_DEVICES_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_HOSPITAL_DEVICES_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_HOSPITAL_DEVICES_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getDeviceById = (id: number | string) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/hospitals/devices/" + id,
  };
  // debugger;
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_HOSPITAL_DEVICE_BY_ID));
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_HOSPITAL_DEVICE_BY_ID,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_HOSPITAL_DEVICE_BY_ID,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_HOSPITAL_DEVICE_BY_ID,
          403
        )
      );
    }
    console.error(response);
  }
};

export const editDeviceById =
  (deviceId: any, deviceData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/hospitals/devices/${deviceId}`,
      data: deviceData,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.EDIT_HOSPITAL_DEVICE_BY_ID)
      );
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_HOSPITAL_DEVICE_BY_ID,
          payload: { data: deviceData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.EDIT_HOSPITAL_DEVICE_BY_ID,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Bad Request" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_MANAGEMENT,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Internal Server Error" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_MANAGEMENT,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_HOSPITAL_DEVICE_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateDevicesList =
  (data: any) => async (dispatch: any, getState: any) => {
    const obj = getState().inProgressReducerData.editedMetaDataFields;
    const formData: any = new FormData();

    Object.keys(obj).forEach((val: any) => {
      val !== "note" && formData.append(val, obj[val]);
    });
    try {
      const payload = {
        method: "PATCH",
        url: apiEndPoint + "/hospitals/roles" + data.correlationId,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          // authorization: localStorage.getItem("token"),
        },
      };
      dispatch(
        showLoaderWithMessage("", allActions.UPDATE_HOSPITAL_DEVICES_LIST)
      );
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        data.isSelected = false;
        // set(data.id, data, customStore);
      }
      dispatch({ type: allActions.UPDATE_HOSPITAL_DEVICES_LIST });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.UPDATE_HOSPITAL_DEVICES_LIST,
          response.status
        )
      );
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.UPDATE_HOSPITAL_DEVICES_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getAllPrivileges =
  (currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/hospitals/roles${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_PRIVILEGES_LIST));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_PRIVILEGES_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_PRIVILEGES_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_PRIVILEGES_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getPermissionsList = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/hospitals/permissions",
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_PERMISSION_LIST));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_PERMISSION_LIST,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_PERMISSION_LIST,
          response.status
        )
      );
      return response;
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_PERMISSION_LIST,
          403
        )
      );
    }
    console.error(response);
  }
};

export const updateRolesPermissionActionData =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.ROLESPERMISSION_DATA,
      payload: { data: { ...payload } },
    });
  };

export const createPrivileges =
  (rolesPermissionData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/hospitals/roles",
      data: rolesPermissionData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_PRIVILEGES));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_PRIVILEGES,
          payload: { data: rolesPermissionData },
        });
        dispatch({
          type: allActions.ROLESPERMISSION_DATA,
          payload: { data: [] },
        });
        dispatch(updateRolesPermissionActionData(null));
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_PRIVILEGES,
            response.status
          )
        );
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ROLESPERMISSION_DATA,
            response.status
          )
        );
      } else if (response && response.status === 400) {
        dispatch({
          type: allActions.CREATE_PRIVILEGE_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_PRIVILEGE_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_PRIVILEGES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const clearPrivilageError = () => {
  return {
    type: allActions.CLEAR_PRIVILAGE_ERROR,
  };
};

export const showPrivilegeSpinner = () => {
  return {
    type: allActions.PRIVILEGE_SPINNER,
  };
};

export const deletePrivileges =
  (id: number | string, currentPage = 1) =>
  async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + "/hospitals/roles/" + id,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_PRIVILEGE));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.DELETE_PRIVILEGE,
          payload: { data: response.data },
        });
        dispatch(getAllPrivileges(currentPage));
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_PRIVILEGE,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.DELETE_PRIVILEGE_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_PRIVILEGE_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_PRIVILEGE,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getPriviligeById =
  (id: number | string) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + "/hospitals/roles/" + id,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_PRIVILEGE_BY_ID));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_PRIVILEGE_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_PRIVILEGE_BY_ID,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_PRIVILEGE_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editPriviligeById =
  (priviligeId: any, priviligeData: any, closeModal: () => void) =>
  async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/hospitals/roles/${priviligeId}`,
      data: priviligeData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_PRIVILEGE_BY_ID));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_PRIVILEGE_BY_ID,
          payload: { data: {} },
        });
        dispatch({
          type: allActions.ROLESPERMISSION_DATA,
          payload: { data: [] },
        });
        dispatch({
          type: allActions.GET_PRIVILEGE_BY_ID,
          payload: { data: null },
        });
        dispatch(updateRolesPermissionActionData(null));
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.EDIT_PRIVILEGE_BY_ID,
            response.status
          )
        );
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ROLESPERMISSION_DATA,
            response.status
          )
        );
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_PRIVILEGE_BY_ID,
            response.status
          )
        );
        closeModal();
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Bad Request" },
        });
        dispatch({
          type: allActions.CREATE_PRIVILEGE_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_MANAGEMENT,
            response.status
          )
        );
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_PRIVILEGE_ERROR,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Internal Server Error" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_MANAGEMENT,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_PRIVILEGE_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getSpecialityList =
  (currentPage = 1, count?: any) =>
  async (dispatch: any) => {
    count = count ? `?page_size=${count}` : ``;
    const url = `${apiEndPoint}/hospitals/speciality${
      currentPage > 1 ? "?page=" + currentPage : ""
    }${count}`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_SPECIALITY_LIST));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: count
            ? allActions.GET_ALL_SPECIALITIES_LIST
            : allActions.GET_SPECIALITY_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_SPECIALITY_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_SPECIALITY_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };
export const getSpecialityListByHospital =
  (nxturl = " ", hospId = 1, callUrl: boolean, count?: any) =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : "";
    const url = `${apiEndPoint}/hospitals/speciality?hospital=${hospId}${count}`;
    const payload = {
      method: "GET",
      url: nxturl || url,
    };
    // debugger;
    if (callUrl) {
      try {
        dispatch(
          showLoaderWithMessage("", allActions.GET_SPECIALITY_HOSPITAL_LIST)
        );
        const response = await doHttpCall(payload);
        // debugger;
        if (response && response.status === 200) {
          dispatch({
            type: allActions.GET_SPECIALITY_HOSPITAL_LIST,
            payload: { data: response.data },
          });
          dispatch(
            hideLoaderWithMessage(
              "",
              allActions.GET_SPECIALITY_HOSPITAL_LIST,
              response.status
            )
          );
        }
      } catch (response) {
        if (response) {
          dispatch(
            hideLoaderWithMessage(
              permissionIssueMessage,
              allActions.GET_SPECIALITY_HOSPITAL_LIST,
              403
            )
          );
        }
        console.error(response);
      }
    } else {
      dispatch({
        type: allActions.GET_SPECIALITY_HOSPITAL_LIST,
        payload: { data: [] },
      });
    }
  };

export const createSpeciality =
  (specialityData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/hospitals/speciality",
      data: specialityData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_SPECIALITY));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_SPECIALITY,
          payload: { data: specialityData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_SPECIALITY,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 403)
      ) {
        dispatch({
          type: allActions.CREATE_SPECIALITY_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_SPECIALITY_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_SPECIALITY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const cancelPreviousErrorMessage = (value: any) => (dispatch: any) => {
  dispatch({
    type: allActions.CANCEL_PREVIOUS_ERROR_MESSAGE,
    payload: value,
  });
};

export const getSpecialityById =
  (id: number | string) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + "/hospitals/speciality/" + id,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_SPECIALITY_BY_ID));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_SPECIALITY_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_SPECIALITY_BY_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_SPECIALITY_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editSpecialityById =
  (specialityId: any, specialityData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/hospitals/speciality/${specialityId}`,
      data: specialityData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_SPECIALITY_BY_ID));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_SPECIALITY_BY_ID,
          payload: { data: specialityData },
        });
        dispatch({
          type: allActions.GET_SPECIALITY_BY_ID,
          payload: { data: null },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.EDIT_SPECIALITY_BY_ID,
            response.status
          )
        );
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_SPECIALITY_BY_ID,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        if (response.data) {
          dispatch({
            type: allActions.CREATE_SPECIALITY_ERROR,
            payload: response.data,
          });
          dispatch(
            hideLoaderWithMessage(
              "",
              allActions.CREATE_SPECIALITY_ERROR,
              response.status
            )
          );
          //Refresh the specialityById to null
          // dispatch({
          //   type: allActions.GET_SPECIALITY_BY_ID,
          //   payload: { data: null },
          // });
        } else {
          dispatch({
            type: allActions.ERROR_IN_MANAGEMENT,
            payload: { data: "Bad Request" },
          });
          dispatch(
            hideLoaderWithMessage(
              "",
              allActions.ERROR_IN_MANAGEMENT,
              response.status
            )
          );
        }
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Internal Server Error" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_MANAGEMENT,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_SPECIALITY_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getFacilitiesList =
  (currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/hospitals/facilities${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_FACILITIES_LIST));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_FACILITIES_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_FACILITIES_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_FACILITIES_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getFacilityById =
  (id: number | string) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + "/hospitals/facilities/" + id,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_FACILITY_BY_ID));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_FACILITY_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_FACILITY_BY_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_FACILITY_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editFacilityById =
  (facilityId: any, facilityData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/hospitals/facilities/${facilityId}`,
      data: facilityData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_FACILITY_BY_ID));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_FACILITY_BY_ID,
          payload: { data: facilityData },
        });
        dispatch({
          type: allActions.GET_FACILITY_BY_ID,
          payload: { data: null },
        });
        dispatch(getFacilitiesList());
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.EDIT_FACILITY_BY_ID,
            response.status
          )
        );
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_FACILITY_BY_ID,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.CREATE_FACILITY_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_FACILITY_ERROR,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_MANAGEMENT,
          payload: { data: "Internal Server Error" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_MANAGEMENT,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_FACILITY_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const createFacility = (facilityData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/hospitals/facilities",
    data: facilityData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_FACILITY));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_FACILITY,
        payload: { data: facilityData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_FACILITY, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.CREATE_FACILITY_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.CREATE_FACILITY_ERROR,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_FACILITY,
          403
        )
      );
    }
    console.error(response);
  }
};

export const cancelFacilityError = (value: any) => (dispatch: any) => {
  dispatch({
    type: allActions.CANCEL_FACILITY_ERROR,
    payload: value,
  });
};

export const deleteSpeciality =
  (id: number | string, currentPage = 1) =>
  async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + "/hospitals/speciality/" + id,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_SPECIALITY));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.DELETE_SPECIALITY,
          payload: { data: response.data },
        });
        dispatch(getSpecialityList(currentPage));
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_SPECIALITY,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.DELETE_SPECIALITY_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_SPECIALITY_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_SPECIALITY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const deleteFacility =
  (id: number | string, currentPage = 1) =>
  async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + "/hospitals/facilities/" + id,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_FACILITY));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.DELETE_FACILITY,
          payload: { data: response.data.details },
        });
        // dispatch(getFacilitiesList());
        dispatch(getFacilitiesList(currentPage));
        dispatch(
          hideLoaderWithMessage("", allActions.DELETE_FACILITY, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.DELETE_FACILITY_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_FACILITY_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_FACILITY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateIsPermissionsFetchedForCreatePrivilege = (
  isFetched: boolean
) => {
  return {
    type: allActions.IS_PERMISSIONS_FETCHED_FOR_CREATE_PRIVILEGE,
    payload: { isPermissionsFetchedForCreatePrivilege: isFetched },
  };
};

export const updateIsPermissionsFetchedForEditPrivilege = (
  isFetched: boolean
) => {
  return {
    type: allActions.IS_PERMISSIONS_FETCHED_FOR_EDIT_PRIVILEGE,
    payload: { isPermissionsFetchedForEditPrivilege: isFetched },
  };
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
