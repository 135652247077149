import { Image, Stack, StackItem, Text } from "@fluentui/react";
import thumbnail from "../../assets/thumbnail.png";
import lacroscope_thumnail from "../../assets/lacroscope_thumbnail.png";
import React from "react";
import { fonts, ThemeColorPalette } from "../../theme";

export interface ActivInsightPanelProps {
  activInsightData: any;
  handleInsightClick: any;
  procedureName: any;
}

export interface ActivInsightPanelState {}

class ActivInsightPanel extends React.Component<
  ActivInsightPanelProps,
  ActivInsightPanelState
> {
  state = {
    insightToggle: false,
    commentToggle: false,
  };
  render() {
    return (
      // <Stack
      //   verticalFill
      //   styles={{
      //     root: {
      //       padding: "1.5rem",
      //       maxHeight: "100%",
      //     },
      //   }}
      // >
      //   <Text
      //     variant="large"
      //     styles={{
      //       root: {
      //         fontFamily: fonts.HelveticaNeue_Medium,
      //         padding: "0 0 1.5rem 0",
      //       },
      //     }}
      //   >
      //     {Object.keys(this.props.activInsightData)[0]}
      //   </Text>
      <Stack tokens={{ childrenGap: 15 }} verticalFill>
        {this.props.activInsightData &&
          this.props.activInsightData["Insights"] &&
          this.props.activInsightData["Insights"].length &&
          this.props.activInsightData["Insights"].map((val: any, i: number) => (
            <Stack
              key={i}
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: 10 }}
              styles={{
                root: {
                  display: "flex",
                  padding: "1rem",
                  backgroundColor: "rgb(24, 24, 24)",
                  marginTop: 10,
                },
              }}
            >
              {
                <Image
                  key={i}
                  styles={{ root: { flex: 6, cursor: "pointer" } }}
                  src={thumbnail}
                  width="100%"
                  height="100%"
                  onClick={() =>
                    this.props.handleInsightClick(val[Object.keys(val)[0]].time)
                  }
                ></Image>
              }
              {/* {Object.keys(val).map((item: any, i: number) => {
                    return val[item].thumbnail ? (
                      <Image
                        key={i}
                        styles={{ root: { flex: 6, cursor: "pointer" } }}
                        src={"data:image/png;base64," + val[item].thumbnail}
                        width="100%"
                        height="100%"
                        onClick={() =>
                          this.props.handleInsightClick(
                            val[Object.keys(val)[0]].time
                          )
                        }
                      ></Image>
                    ) : (
                      <Image
                        key={i}
                        styles={{ root: { flex: 6, cursor: "pointer" } }}
                        src={thumbnail}
                        width="100%"
                        height="100%"
                        onClick={() =>
                          this.props.handleInsightClick(
                            val[Object.keys(val)[0]].time
                          )
                        }
                      ></Image>
                    );
                  })} */}
              <Stack
                tokens={{ childrenGap: 5, maxWidth: "155px" }}
                verticalAlign="start"
                styles={{ root: { flex: 6, justifyContent: "start" } }}
              >
                <Text
                  variant="smallPlus"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Light,
                      wordWrap: "break-word",
                    },
                  }}
                >
                  {Object.keys(val)}
                </Text>
                <StackItem>
                  <Text
                    variant="small"
                    styles={{
                      root: {
                        padding: "0.2rem",
                        fontFamily: fonts.HelveticaNeue_Bold,
                        color: ThemeColorPalette.themePrimary,
                        backgroundColor: ThemeColorPalette.gridBG,
                      },
                    }}
                  >
                    {val[Object.keys(val)[0]].time.slice(0, 8)}
                  </Text>
                </StackItem>
              </Stack>
            </Stack>
          ))}
      </Stack>
      // </Stack>
    );
  }
}

export default ActivInsightPanel;
