import { loadTheme } from "@fluentui/react";

export const ThemeColorPalette = {
  themePrimary: "#01b1ff",
  themeLighterAlt: "#f5fcff",
  themeLighter: "#d6f3ff",
  themeLight: "#b3e8ff",
  themeTertiary: "#66d1ff",
  themeSecondary: "#1fbcff",
  themeDarkAlt: "#00a1e6",
  themeDark: "#0088c2",
  themeDarker: "#00648f",
  neutralLighterAlt: "#1e1e1e",
  neutralLighter: "#282828",
  neutralLight: "#363636",
  neutralQuaternaryAlt: "#404040",
  neutralQuaternary: "#474747",
  neutralTertiaryAlt: "#666666",
  neutralTertiary: "#c8c8c8",
  neutralSecondary: "#d0d0d0",
  neutralPrimaryAlt: "#dadada",
  neutralPrimary: "#ffffff",
  neutralDark: "#f4f4f4",
  black: "#f8f8f8",
  white: "#151515",
  loginBg: "#151515",
  pureBlack: "#000000",
  pureWhite: "#ffffff",
  sidebar: "#202022",
  searchBar: "#343434",
  searchBoxText: "#8C8C8C",
  metrixbg: "#121212",
  metrixTxt: "#A5A5A5",
  dashboardHeadingTxt: "#EAEAEA",
  dateTxt: "#B2B2B2",
  dateUploadedTxt: "#89898B",
  videoCountTxt: "#D7D7D7",
  dashboardBg: "#0d0e11",
  themeDashboard: "#101112",
  gridBG: "#1D1D1D",
  selectedGridBG: "#181818",
  radioBg: "#242526",
  sideNavBg: "#1F1F1F",
  uploadMsgTxt: "#E8EDEF",
  uploadVideoBtnTxt: "#ADADAE",
  changePasswordTxt: "#7E7E81",
  changePasswordLabelTxt: "#BFC3C4",
  ribbon: "#4EAE4D",
  videoHeadingTxt: "#D8D8D8",
  videoUploadDateTxt: "#A9A9A9",
  scrollColor: "#35373F",
  LandingPageBorder: "#262727",
  hospitalAverageColor: "#00B7C3",
  yourAverage: "#8CBD18",
  sharedHeaderText: "#E2E2E2",
  chartToolTipBg: "#2E2E2F",
  panelBorder: "#515151",
  procedureIconBg: "rgba(168,231,208,0.1)",
  videosIconBg: "rgba(241,152,246,0.1)",
  favouritesIconBg: "rgba(138,200,254,0.1)",
  sharedIconBg: "rgba(255,214,116,0.1)",
};

loadTheme({
  palette: ThemeColorPalette,
});

export const fonts = {
  DINPro_Regular: "DINPro-Regular",
  DINPro_Medium: "DINPro-Medium",
  DINPro_Light: "DINPro-Light",
  DINPro_Bold: "DINPro-Bold",
  DINPro_Black: "DINPro-Black",
  HelveticaNeue_Regular: "HelveticaNeue-Regular",
  HelveticaNeue_Medium: "HelveticaNeue-Medium",
  HelveticaNeue_Light: "HelveticaNeue-Light",
  HelveticaNeue_Bold: "HelveticaNeue-Bold",
  HelveticaNeue_Black: "HelveticaNeue-Black",
  HelveticaNeue_Thin: "HelveticaNeue-Thin",
  HelveticaNeue_Heavy: "HelveticaNeue-Heavy",
  HelveticaNeue_Italic: "HelveticaNeue-Italic",
};
