import moment from "moment";
import { uuid } from "uuidv4";
import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getRecentActivitiesOfUser =
  (url: string) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: url,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.FETCH_RECENT_ACTIVITIES_DATA)
      );
      const response = await doHttpCall(payload);
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.results &&
        response.data.results.length
      ) {
        const items = response.data.results.map((item: any, i: number) => {
          const uniqueKey = uuid();
          const time: any = new Date(item.date);

          const notificationTime: any = new Date(
            Date.UTC(
              time.getFullYear(),
              time.getMonth(),
              time.getDate(),
              time.getHours(),
              time.getMinutes(),
              time.getSeconds(),
              time.getMilliseconds()
            )
          );
          return {
            key: uniqueKey,
            name: item.log_type,
            value: new Date(notificationTime).toLocaleString(),
          };
        });
        dispatch({
          type: allActions.FETCH_RECENT_ACTIVITIES_DATA,
          payload: { currentPageData: items, next: response.data.next },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.FETCH_RECENT_ACTIVITIES_DATA,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.FETCH_RECENT_ACTIVITIES_DATA,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const sortRecentActivities =
  (sortElement: string, sortType: boolean) =>
  (dispatch: any, getState: any) => {
    if (sortElement === "name") {
      const items = [...getState().recentActivities.recentActivitiesList];
      sortType
        ? items.sort((a: any, b: any) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          )
        : items.sort((a: any, b: any) =>
            a.name < b.name ? 1 : b.name < a.name ? -1 : 0
          );
      dispatch({
        type: allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT,
        payload: items,
      });
    } else {
      const items = [...getState().recentActivities.recentActivitiesList];
      sortType
        ? items.sort(
            (a: any, b: any) =>
              new Date(b.value).getTime() - new Date(a.value).getTime()
          )
        : items.sort(
            (a: any, b: any) =>
              new Date(a.value).getTime() - new Date(b.value).getTime()
          );
      dispatch({
        type: allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT,
        payload: items,
      });
    }
  };

export const showSpinnerOnLoad = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER });
};

export const clearRecentActivitiesOnlogout = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_ALL_RECENT_ACTIVITES,
  });
};

export const getHospitalId = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/logs/hospital",
  };
  // debugger;
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_HOSPITAL_ID));
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_HOSPITAL_ID,
        payload: { data: response.data },
      });
      dispatch(getLogFacilities(response.data.results[0].id));
      dispatch(
        hideLoaderWithMessage("", allActions.GET_HOSPITAL_ID, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_HOSPITAL_ID,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getLogFacilities =
  (hospital_id = 0, facility_name = "", count?: any) =>
  async (dispatch: any) => {
    const facilitySearch = facility_name ? `?search=${facility_name}` : "";
    count = count ? `?page_size=${count}` : ``;
    const defaultUrl =
      apiEndPoint +
      `/logs/hospital/${hospital_id}/facility${facilitySearch}${count}`;
    const payload = {
      method: "GET",
      url: defaultUrl,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_LOG_FACILITIES));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_LOG_FACILITIES,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOG_FACILITIES,
            response.status
          )
        );
      } else if (response && response.status === 404) {
        dispatch({
          type: allActions.GET_LOG_FACILITIES,
          payload: { data: { results: [] } },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOG_FACILITIES,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_LOG_FACILITIES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getActivity =
  (
    currentPage = 1,
    hospital_id = "",
    activity_type_id = "",
    activity_by = "",
    facility_id = "",
    activityDate?: any
  ) =>
  async (dispatch: any) => {
    const currentUserPage = `?page=${currentPage}`;
    const hospitalSearch = hospital_id ? `&hospital=${hospital_id}` : "";
    const activityTypeSearch = activity_type_id
      ? `&activity_type=${activity_type_id}`
      : "";
    const activityBySearch = activity_by ? `&activity_by=${activity_by}` : "";
    const facilitySearch = facility_id ? `&facility=${facility_id}` : "";
    const activityDateVal = moment
      .utc(activityDate)
      .format("YYYY-MM-DDTHH:mm:ss");
    const activityDateFilter = activityDate
      ? `&from_date=${activityDateVal}`
      : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/activities${currentUserPage}${hospitalSearch}${activityTypeSearch}${activityBySearch}${facilitySearch}${activityDateFilter}&page_size=20`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ACTIVITY));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ACTIVITY,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ACTIVITY, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ACTIVITY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getActivityType =
  (count?: any, activity_type_name = "") =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : ``;
    let defaultUrl =
      apiEndPoint + `/activities/activitylogtypes?page=1${count}`;
    if (activity_type_name) {
      defaultUrl = `${defaultUrl}&search=${activity_type_name}`;
    }
    const payload = {
      method: "GET",
      url: defaultUrl,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.GET_HOSPITAL_ADMIN_ACTIVITY_TYPES)
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_HOSPITAL_ADMIN_ACTIVITY_TYPES,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_HOSPITAL_ADMIN_ACTIVITY_TYPES,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_HOSPITAL_ADMIN_ACTIVITY_TYPES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const clearAllSurgeons = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_ALL_SURGEONS,
  });
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
