import { IconButton, IIconProps, Persona } from "@fluentui/react";
import {
  Breadcrumb,
  Facepile,
  FontIcon,
  IStackItemStyles,
  ITextStyles,
  PersonaSize,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import { ThemeColorPalette } from "../../theme";
import { TestImages } from "@uifabric/example-data";
import VideoPlayer, { Marker } from "../../common/videoPlayer/RawVideoPlayer";
import VideoCommenting from "../components/VideoCommenting";
import videojs from "video.js";
import { connect } from "react-redux";
import {
  getAnnotationData,
  getVideoPhases,
  setSelectedVideoUnfavourite,
  getOverlayUrls,
  getActivInsightsData,
  clearVideoData,
  setCurrentVideoPlayTime,
  showSpinnerOnLoad,
  getModelOverlayUrls,
} from "../actions/videoPlayerAction";
import {
  convertDurationOfVideoToSec,
  getDateWithSlashInBetween,
  getTime,
} from "../../common/util";
import { Link } from "react-router-dom";
import { postSharedDetails } from "../../Shared/actions/sharedVideosAction";
import { getVideoDetails } from "../actions/videoPlayerAction";
import {
  getVideoComments,
  getVideosByProcedure,
} from "../actions/videoPlayerAction";
import { cleanDatasets } from "../../DeveloperModule/DatasetMapping/actions/datasetAction";
import SurgeryBreakUpChart from "../components/SurgeryBreakUpChart";
import ActivInsightPanel from "../components/ActivInsightPanel";
import AddToFavoritesButton from "../components/AddToFavoritesButton";
import ViewEditVideoDetailsPanel from "../components/ViewEditVideoDetailsPanel";
import VideoPlayerShareModal from "../components/VideoPlayerShareModal";
import { withRouter } from "../../withRouter";
import { ROLE_TYPES } from "../../Layout/constants/constants";
import Paging from "../../common/paging/Paging";
import Videos from "../../HospitalAdminModule/Home/components/VideoSection/Video";
import { getVideosById } from "../../HospitalAdminModule/Home/actions/homeAction";
import { Controller } from "react-hook-form";
import { apiEndPoint, doHttpCall } from "../../common/restApi";
import { InboxLine, LineHeight } from "@fluent-ui/icons";
import { TooltipHost } from "office-ui-fabric-react";
import VideoPlayerUnShareModal from "../components/VideoPlayerUnShareModal";
import { fonts } from "../../theme";
import thumbnail from "../../assets/thumbnail.png";
import lacroscope_thumnail from "../../assets/lacroscope_thumbnail.png";
import { round } from "lodash";
import * as constants from "../../Layout/constants/constants";
const cancelIcon: IIconProps = { iconName: "Cancel" };
interface VideoPlayerNew1Props {
  params: any;
  history: any;
  loginData: any;
  userData: any;
  videoData: any;
  proceduresData: any;
  userProfile: any;
  getVideoDetails: any;
  setSelectedVideoUnfavourite: any;
  postSharedDetails: any;
  annotations: any;
  getVideoPhases: any;
  videoPhases: any;
  appliedAlgorithms: any;
  getAnnotationData: any;
  overlayUrls: any;
  getOverlayUrls: any;
  getModelOverlayUrls: any;
  getActivInsightsData: any;
  activInsightData: any;
  clearVideoData: any;
  sharedVideo: boolean;
  totalPhasesTime: any;
  phasesLegendData: any;
  setCurrentVideoPlayTime: any;
  currentPlayTime: any;
  showSpinnerOnLoad: any;
  showSpinner: boolean;
  showData: boolean;
  // videoComments: any;
  getVideoComments: any;
  location: any;
  modules: any;
  videosById: any;
  getVideosById: (id: number, pageNo: number) => void;
  videosByProcedure: any;
  getVideosByProcedure: (
    procedureId: number,
    pageNo?: number,
    isUploadedByUser?: boolean
  ) => void;
  isSurgeon?: boolean;
  showSpinnerVideoDetails?: boolean;
  mappedAlgorithmsToDataSet: any;
  cleanDatasets: any;
}

interface VideoPlayerNew1State {
  isPanelOpen: boolean;
  enableSave: boolean;
  procedureName: string;
  shared_with: Array<any>;
  sharedDetailsToPost: any;
  handleEditDetails: boolean;
  activInsightVideo: boolean;
  videoSrcUrl: string;
  annotationSelect: boolean;
  currentVideoId: number;
  activVideoCheck: boolean;
  shareErrors: any;
  showSelected: boolean;
  shareButtonEnable: boolean;
  markers: Marker[];
  videoCurrentPage: number;
  procedureVideoCurrentPage: number;
  isActivStreaming: boolean;
  isPaused: boolean;
  imagesList: any;
  isImages: any;
  isSurgeonLogin: boolean;
  commentToggle: boolean;
  insightToggle: boolean;
  hideCommentsPermissions: boolean;
  markersSet: boolean;
  fullScreenEnble: boolean;
  fullScreenMode: boolean;
  manualPause: boolean;
  videoEnd: boolean;
}

const containerStyles: IStackItemStyles = {
  root: {
    minHeight: "100vh",
    maxHeight: "100%",
    minWidth: "100vh",
    maxWidth: "100%",
    paddingBottom: "2rem",
  },
};

const tinyTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.videoUploadDateTxt,
  },
};

class VideoPlayerNew1 extends React.Component<
  VideoPlayerNew1Props,
  VideoPlayerNew1State
> {
  player: videojs.Player = {} as videojs.Player;
  canvasElement: any = React.createRef();
  interval: any;
  isStreamingEnabled: boolean;
  containerRef: React.RefObject<any>;
  videoRef: React.RefObject<HTMLVideoElement>;

  constructor(props: VideoPlayerNew1Props) {
    super(props);
    this.containerRef = React.createRef();
    this.videoRef = React.createRef();
    this.state = {
      handleEditDetails: false,
      shared_with: [],
      sharedDetailsToPost: {},
      enableSave: false,
      isPanelOpen: false,
      procedureName: "",
      activInsightVideo: false,
      videoSrcUrl: "",
      annotationSelect: false,
      currentVideoId: 0,
      activVideoCheck: false,
      showSelected: true,
      shareErrors: [],
      shareButtonEnable: true,
      markers: [],
      videoCurrentPage: 1,
      procedureVideoCurrentPage: 1,
      isActivStreaming: false,
      isPaused: false,
      imagesList: [],
      isImages: false,
      isSurgeonLogin: false,
      commentToggle: false,
      insightToggle: false,
      hideCommentsPermissions: false,
      markersSet: false,
      fullScreenEnble: false,
      fullScreenMode: false,
      manualPause: false,
      videoEnd: false,
    };
    const { activ_streaming_endpoint } =
      this.props.videoData || this.props?.location?.state || {};

    this.isStreamingEnabled = !!(
      activ_streaming_endpoint && Object.keys(activ_streaming_endpoint).length
    );
    this.clickMe = this.clickMe.bind(this);
    // setTimeout(() => {
    //   this.setState({
    //     isSurgeonLogin: ,
    //   });
    // }, 2000);
  }

  fullScreenToggle = (evt: any) => {
    this.setState({
      fullScreenMode: evt,
    });
  };

  clickMe() {
    const { userProfile } = this.props;
    this.setState({
      isSurgeonLogin: userProfile?.role_name === ROLE_TYPES.ACTIVE_SURGEON,
    });
  }

  shouldComponentUpdate(prevProps: any, prevState: any) {
    return prevProps.annotations !== this.props.annotations ||
      prevProps.overlayUrls !== this.props.overlayUrls
      ? false
      : true;
  }

  setMarkersForComment = (start = 0, end: any) => {
    if (
      localStorage.getItem("role") === "Surgeon" ||
      localStorage.getItem("role") === "HospitalAdmin"
    ) {
      const intValue: any = Math.trunc(this.player?.duration());
      const newEndTime: any = intValue === end ? this.player?.duration() : end;
      this.setState({
        markers: [
          { text: "Start", time: start },
          { text: "End", time: newEndTime },
        ],
        markersSet: true,
      });
      this.player?.currentTime(start);
      setTimeout(() => {
        this.setState({ markersSet: false });
      }, 1000);
    }
  };
  // getVideoDetailsById = async () => {
  //   let uploadedByUser = this.props?.location?.state?.is_uploaded_by_user;
  //   const loadedFrom = this.props?.location?.state?.loadedFrom;
  //   let location = this.props?.location?.state?.location;
  //   console.log(this.props.videoData, "videoData21");
  //   let responce = await this.props.getVideoDetails(
  //     this.props.params.id,
  //     { loadedFrom, uploadedByUser },
  //     this.props.params.surgeonId,
  //     location ? location : "other"
  //   );
  //   //return true
  //   return responce;
  // };
  getVideoDetailsById = async (
    videoIdD: any = null,
    uploadeUser: any = null
  ) => {
    const videoId = videoIdD ? videoIdD : this.props.params.id;
    const loadedFrom = this.props?.location?.state?.loadedFrom;
    const location = this.props?.location?.state?.location;
    let uploadedByUser = uploadeUser
      ? uploadeUser
      : this.props?.location?.state?.is_uploaded_by_user;
    this.setState({
      ...this.state,
      currentVideoId: videoId,
    });
    if (
      typeof uploadedByUser === "undefined" ||
      typeof uploadedByUser === undefined
    ) {
      let url = !this.props.params.surgeonId
        ? "/videos/?id=" + videoId
        : "/videos/?id=" +
          videoId +
          "&primary_surgeon=" +
          this.props.params.surgeonId;
      url = !uploadeUser
        ? url + "&is_uploaded_by_user=false"
        : url + "&is_uploaded_by_user=true";
      const payload = {
        method: "GET",
        url: apiEndPoint + url,
      };
      const responce = await doHttpCall(payload);
      if (
        responce &&
        responce.status === 200 &&
        Object.keys(responce?.data).length &&
        responce?.data?.results.length
      ) {
        uploadedByUser = responce?.data?.results[0]?.is_uploaded_by_user;
        const responceVideo = await this.props.getVideoDetails(
          videoId,
          { loadedFrom, uploadedByUser },
          this.props.params.surgeonId,
          location ? location : "other"
        );
        this.getOthersVideo();
        this.getOthersVideoForHospitalAdmin();
        return responceVideo;
      } else if (
        responce &&
        responce.status === 200 &&
        Object.keys(responce?.data).length &&
        !responce?.data?.results.length
      ) {
        this.getVideoDetailsById("", true);
      } else {
        return true;
      }
    } else {
      const responceVideo = await this.props.getVideoDetails(
        videoId,
        { loadedFrom, uploadedByUser },
        this.props.params.surgeonId,
        location ? location : "other"
      );
      this.getOthersVideo();
      this.getOthersVideoForHospitalAdmin();
      //return true
      return responceVideo;
    }
  };

  getOthersVideo = () => {
    if (localStorage.getItem("role") === ROLE_TYPES.ACTIVE_DEVELOPER) {
      if (this.props.videoData?.primary_procedure_type) {
        this.props.getVideosByProcedure(
          this.props.videoData?.primary_procedure_type,
          1,
          this.props.videoData?.is_uploaded_by_user
        );
      }
    }
  };

  getOthersVideoForHospitalAdmin = () => {
    if (
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_DEVELOPER &&
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_ADMIN &&
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_SURGEON
    ) {
      this.props.getVideosById(this.props.videoData?.primary_surgeon, 1);
    }
  };

  componentDidMount = async () => {
    const permission = constants.getPermissionsbyItem(
      "vps.video_comments.list"
    );
    // this.setState({})
    this.setState({
      ...this.state,
      currentVideoId: this.props.params.id,
      hideCommentsPermissions: permission,
    });
    const isSharedVideo = this.props?.location?.state?.sharedWithMe;
    const isFavourite = this.props?.location?.state?.favourite;
    const loadedFrom = this.props?.location?.state?.loadedFrom;
    let uploadedByUser = false;
    if (loadedFrom === ROLE_TYPES.ACTIVE_ADMIN || ROLE_TYPES.ACTIVE_DEVELOPER) {
      uploadedByUser = this.props?.location?.state?.is_uploaded_by_user;
    }
    this.props.showSpinnerOnLoad();
    if (this.props.params?.id && !this.props.params?.alg) {
      const status = await this.getVideoDetailsById();
      const algoMap = this.props.appliedAlgorithms;

      let phasesData;
      let insightsData;
      if (algoMap && algoMap?.length) {
        phasesData = algoMap.filter((item: any) => {
          if (item && item.AlgorithmType.toLowerCase() === "phases") {
            return item;
          }
        });
        insightsData = algoMap.filter((item: any) => {
          if (item && item.AlgorithmType.toLowerCase() === "insights") {
            return item;
          }
        });
      }
      if (status) {
        if (phasesData && phasesData?.length && phasesData[0]?.AlgorithmType) {
          const val = phasesData[0]?.AlgorithmId;
          this.props.getVideoPhases(val, this.props.params.id);
        }
        if (
          insightsData &&
          insightsData?.length &&
          insightsData[0]?.AlgorithmType
        ) {
          const val = insightsData[0]?.AlgorithmId;
          this.props.getActivInsightsData(val, this.props.params.id);
        }

        if (
          this.props.userProfile?.role_name !== ROLE_TYPES.ACTIVE_DEVELOPER &&
          this.props.userProfile?.role_name !== ROLE_TYPES.ACTIVE_ADMIN
        ) {
          this.props.getVideoComments(this.props.params.id);
        }
      }
    }

    if (this.props?.location?.state?.streaming_endpoint) {
      this.setStreamingEndpoint({
        ...this.props?.location?.state?.streaming_endpoint,
      });
    }
    if (
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_DEVELOPER &&
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_ADMIN &&
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_SURGEON
    ) {
      this.props.getVideosById(this.props.videoData?.primary_surgeon, 1);
    }
    // Developer module - Get other videos of same procedure
    if (localStorage.getItem("role") === ROLE_TYPES.ACTIVE_DEVELOPER) {
      if (this.props.videoData?.primary_procedure_type) {
        this.props.getVideosByProcedure(
          this.props.videoData?.primary_procedure_type,
          1,
          this.props.videoData?.is_uploaded_by_user
        );
      }
    }
  };
  checkVideoDataIsExist = () => {
    if (
      this.props.videoData &&
      this.props.videoData.results &&
      this.props.videoData.results.length
    ) {
      return true;
    } else if (this.props.videoData && this.props.videoData?.id) {
      return true;
    }
    return false;
  };

  getVideoUrl = () => {
    if (this.checkVideoDataIsExist()) {
      const { streaming_endpoint, activ_streaming_endpoint } =
        this.props.videoData || this.props?.location?.state || {};
      let { dash, cdn } =
        this.state.isActivStreaming &&
        (activ_streaming_endpoint.dash || activ_streaming_endpoint.cdn)
          ? activ_streaming_endpoint
          : streaming_endpoint;
      if (!cdn) {
        ({ dash, cdn } = streaming_endpoint || {});
      }
      return (dash ? dash : cdn?.dash) || "";
    }
    return "";
  };

  videoPlay = (canvasId: string) => {
    // this.setState({videoEnd:false})
    const video = this.videoRef.current;
    const canvas = document.getElementById(canvasId) as any;
    const ctx = canvas && canvas.getContext("2d");

    let animationId: any;
    const renderFrame = (now: any, metadata: any) => {
      if (video) {
        this.pauseVideoIfOverlayNotAvailable(video?.currentTime);
      }

      if (
        this.props.annotations &&
        canvas.width != this.props.annotations?.width
      ) {
        canvas.width = this.props.annotations?.width;
        canvas.height = this.props.annotations?.height;
      }

      // Draw the polygon on top of the video frame
      this.drawCanvas(canvas, ctx, video);

      // Request the next frame
      animationId = video?.requestVideoFrameCallback(renderFrame);
    };
    // Start rendering frames when the video is playing
    //video?.addEventListener("play", renderFrame);
    video?.addEventListener("pause", () => {
      // To stop the animation loop:
      //cancelAnimationFrame(animationId);
      video.cancelVideoFrameCallback(animationId);
    });
    video?.addEventListener("ended", () => {
      // To stop the animation loop:
      video.cancelVideoFrameCallback(animationId);
      //cancelAnimationFrame(animationId);
    });
    if (this.state.annotationSelect) {
      video?.requestVideoFrameCallback(renderFrame);
    } else {
      video?.cancelVideoFrameCallback(animationId);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    video?.play();
  };

  drawCanvas = (canvas: any, ctx: any, video: any) => {
    {
      let annotationsObj: any = null;
      if (!this.state.annotationSelect || !this.props.annotations) {
        return;
      }
      // console.time("draw Canvas");

      annotationsObj = this.props.annotations;
      //  console.log("draw canvas");
      //console.time('draw Canvas');
      //console.dir(annotationsObj);
      if (this.player && annotationsObj) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // ctx.clearRect(0, 0, canvas.width, canvas.height);
        const currentTime = this.player?.currentTime();
        const frame = Math.floor(currentTime * annotationsObj.fps);
        const frameData = annotationsObj.annotations[frame] || [];
        const width = canvas.width;
        const height = canvas.height;
        frameData.forEach((element: any) => {
          ctx.beginPath();
          element.forEach((cords: any) => {
            if (ctx) {
              let x = cords.x;
              let y = cords.y;
              x = (cords.x / annotationsObj.width) * width;
              y = (cords.y / annotationsObj.height) * height;
              ctx.lineTo(x, y);
            }
          });
          ctx.closePath();
          ctx.globalAlpha = 0.6;
          ctx.strokeStyle = "#009933";
          ctx.stroke();
          ctx.fillStyle = "#009933";
          ctx.fill();
        });
      }
      //  console.timeEnd("draw Canvas");
    }
  };

  setStreamingEndpoint = (streamingEndPoint: any) => {
    const dash = this.getVideoUrl();
    if (dash && dash.length) {
      this.setState(
        {
          activVideoCheck:
            this.props.videoData &&
            this.props.videoData.activ_streaming_endpoint &&
            this.props.videoData.activ_streaming_endpoint.dash
              ? true
              : false,
          videoSrcUrl: dash,
        },
        () => {
          if (
            localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_DEVELOPER &&
            localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_ADMIN
          ) {
            setTimeout(() => {
              if (
                localStorage.getItem("role") === "Surgeon" ||
                localStorage.getItem("role") === "HospitalAdmin"
              ) {
                this.setState({
                  markers: [
                    { text: "Start", time: 0 },
                    { text: "End", time: this.player?.duration() },
                  ],
                });
              }
            }, 3000);
          }
        }
      );
    }
  };

  componentDidUpdate = async (prevProps: any, prevState: any) => {
    if (
      this.props.videoData.shared_with &&
      this.props.videoData.shared_with.length &&
      !this.state.isImages
    ) {
      this.setState({ isImages: true });
      this.getImages(this.props.videoData.shared_with);
    }

    if (
      this.props.videoData?.id &&
      prevProps.videoData?.id &&
      this.props.videoData?.id !== prevProps.videoData?.id
    ) {
      this.props.clearVideoData();
      this.setState({
        ...this.state,
        currentVideoId: this.props.videoData?.id,
        activInsightVideo: false,
      });
      this.props.params.id = this.props.videoData?.id;

      const canvasElement = document.getElementById("active-canvas") as any;
      const ctx = canvasElement && canvasElement.getContext("2d");
      ctx && ctx.clearRect(0, 0, canvasElement.width, canvasElement.height);
      if (this.props.params?.id && !this.props.params?.alg) {
        const isSharedVideo = this.props?.location?.state?.sharedWithMe;
        const isFavourite = this.props?.location?.state?.favourite;
        // const status = await this.props.getVideoDetails(
        //   this.props.params.id,
        //   isSharedVideo,
        //   {},
        //   this.props.params.surgeonId,
        //   isFavourite
        // );
        const status = await this.getVideoDetailsById(this.props.videoData?.id);
        const algoMap = this.props.appliedAlgorithms;
        let phasesData;
        let insightsData;
        if (algoMap && algoMap?.length) {
          phasesData = algoMap.filter((item: any) => {
            if (item && item.AlgorithmType.toLowerCase() === "phases") {
              return item;
            }
          });
          insightsData = algoMap.filter((item: any) => {
            if (item && item.AlgorithmType.toLowerCase() === "insights") {
              return item;
            }
          });
        }
        if (status) {
          if (
            phasesData &&
            phasesData?.length &&
            phasesData[0]?.AlgorithmType
          ) {
            const val = phasesData[0]?.AlgorithmId;
            this.props.getVideoPhases(val, this.props.params.id);
          }
          if (
            insightsData &&
            insightsData?.length &&
            insightsData[0]?.AlgorithmType
          ) {
            const val = insightsData[0]?.AlgorithmId;
            this.props.getActivInsightsData(val, this.props.params.id);
          }

          if (
            this.props.userProfile?.role_name !== ROLE_TYPES.ACTIVE_DEVELOPER &&
            this.props.userProfile?.role_name !== ROLE_TYPES.ACTIVE_ADMIN
          ) {
            this.props.getVideoComments(this.props.params.id);
          }
        }
      }
    }

    if (
      (this.props.videoData &&
        prevProps.videoData &&
        this.props.videoData.id !== prevProps.videoData.id) ||
      prevState.isActivStreaming !== this.state.isActivStreaming
    ) {
      if (
        this.props.videoData.streaming_endpoint ||
        this.props.videoData.activ_streaming_endpoint
      ) {
        this.setStreamingEndpoint(this.props.videoData.streaming_endpoint);
      }
    }
    const activ_streaming_endpoint =
      this.props.videoData.activ_streaming_endpoint;
    this.isStreamingEnabled = !!(
      activ_streaming_endpoint && Object.keys(activ_streaming_endpoint).length
    );
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    if (this.player) {
      this.player = {} as videojs.Player;
    }
    this.setState({ isImages: false });
    this.props.clearVideoData();
    this.props.cleanDatasets();
  }

  _buttonClicked = () => {
    this.setState({ ...this.state, isPanelOpen: true });
  };

  onCanvasClick = () => {
    if (this.player.paused()) {
      // this.player.play();
      this.videoPlay("active-canvas");
    } else {
      this.player.pause();
    }
  };

  onPlayerReady = (player: videojs.Player, videoRef: any) => {
    this.player = player;
    this.videoRef = videoRef;
  };
  onVideoPlay = (duration: number) => {
    this.setState({ fullScreenEnble: true });
    this.videoPlay("active-canvas");
    // this.setMarkersForComment()
  };
  onVideoPause = (duration: number) => {
    const totalDuration = this.player.duration();
    this.setState({ isPaused: true });

    if (
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_DEVELOPER &&
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_ADMIN &&
      localStorage.getItem("role") !== ROLE_TYPES.ACTIVE_HOSPITAL_ADMIN &&
      this.player.paused() &&
      !this.player.seeking() &&
      duration !== this.player.duration()
    ) {
      this.setState({
        markers: [
          { text: "Start", time: duration },
          {
            text: "End",
            time: totalDuration > duration + 10 ? duration + 10 : totalDuration,
          },
        ],
        markersSet: true,
      });
    }
  };

  // updatemarkers = (totalDuration: any, duration: any) => {
  //   document.addEventListener("visibilitychange", () => {
  //     if (document.hidden) {
  //     } else {
  //       this.setState({
  //         markers: [
  //           { text: "Start", time: duration },
  //           {
  //             text: "End",
  //             time:
  //               totalDuration > duration + 10 ? duration + 10 : totalDuration,
  //           },
  //         ],
  //         markersSet: true,
  //       });
  //     }
  //   });
  // };

  pauseVideoIfOverlayNotAvailable = (duration: number) => {
    //const val = duration % 10;
    //const result = duration - val;
    const result = Math.floor(duration / 10);
    const overlayUrls: any = this.props.overlayUrls;
    if (
      this.state.annotationSelect &&
      overlayUrls[result] &&
      !overlayUrls[result]["downloadStatus"]
    ) {
      this.player.pause();
      this.player.addClass("vjs-waiting");
      // set timer to check if overlay available and then play
      const pauseTimer = setInterval(() => {
        const overlayUrls: any = this.props.overlayUrls;
        if (
          this.state.annotationSelect &&
          overlayUrls[result] &&
          overlayUrls[result]["downloadStatus"]
        ) {
          this.videoPlay("active-canvas");
          if (
            document.getElementsByClassName("vjs-waiting") &&
            document.getElementsByClassName("vjs-waiting")?.length
          ) {
            this.player.removeClass("vjs-waiting");
          }
          clearTimeout(pauseTimer);
        } else {
          this.player.addClass("vjs-waiting");
        }
      }, 500);
    }
  };

  // onVideoTimeUpdate = (duration: number) => {
  //   if (!this.state.isPaused) {
  //     this.setState({ manualPause: false });
  //     const val = duration % 10;
  //     const result = duration - val;
  //     const overlayUrls: any = this.props.overlayUrls;
  //     if (
  //       this.state.annotationSelect &&
  //       overlayUrls[result] &&
  //       !overlayUrls[result]["downloadStatus"]
  //     ) {
  //       this.player.pause();
  //       this.player.addClass("vjs-waiting");
  //     } else {
  //       if (
  //         this.player.paused() &&
  //         this.player.duration() !== this.player.currentTime()
  //       ) {
  //         this.player.play();
  //         if (
  //           document.getElementsByClassName("vjs-waiting") &&
  //           document.getElementsByClassName("vjs-waiting")?.length
  //         ) {
  //           this.player.removeClass("vjs-waiting");
  //         }
  //       }
  //     }
  //   }
  // };

  videoPauseManually = () => {
    this.player.pause();
  };

  onVideoSeeking = (duration: number) => {
    const val = duration % 10;
    const result = duration - val;
    const endMarkerPosition = duration + 10;

    //this.state.annotationSelect &&
    // setTimeout(() => {
    //   this.props.getAnnotationData(result);
    // }, 10);
    // this.pauseVideoIfOverlayNotAvailable(duration);
    // this.props.getAnnotationData(result);
  };

  onVideoSeeked = (from: number, to: number) => {
    if (
      this.props.userProfile?.role_name !== ROLE_TYPES.ACTIVE_DEVELOPER &&
      this.props.userProfile?.role_name !== ROLE_TYPES.ACTIVE_ADMIN
    ) {
      const { markers } = this.state;
      const allMarkedtimes = markers.map((marker: Marker) => marker.time);
      if (Math.abs(allMarkedtimes[0] - to) < Math.abs(allMarkedtimes[1] - to)) {
        allMarkedtimes.splice(0, 1, to);
      } else {
        allMarkedtimes.splice(1, 1, to);
      }

      if (to === 0) {
        allMarkedtimes[1] = this.player.duration();
      }

      this.setState({
        markers: allMarkedtimes
          .sort((a, b) => a - b)
          .map((time: number, index: number) => ({
            time,
            text: `Marker: ${index + 1}`,
          })),
      });
    }
  };

  onVideoEnd = () => {
    if (
      localStorage.getItem("role") === "Surgeon" ||
      localStorage.getItem("role") === "HospitalAdmin"
    ) {
      this.setState({
        markers: [
          { text: "Start", time: 0 },
          { text: "End", time: this.player?.duration() },
        ],
      });
    }
    // this.setState({videoEnd:true})
    //this.player.dispose();
    //this.setState({ isPaused: true });
  };

  dismissPanel = () => {
    // this.props.getVideoDetails(this.props.params.id);
    this.getVideoDetailsById();
    this.setState({
      ...this.state,
      isPanelOpen: false,
      enableSave: false,
      handleEditDetails: false,
    });
  };

  handleShareButtonClick = async () => {
    const data = {
      video_id: this.state.sharedDetailsToPost.video_id,
      shared_by: this.state.sharedDetailsToPost.shared_by,
      shared_with: [...this.state.shared_with],
      created_by: this.state.sharedDetailsToPost.created_by,
    };
    this.setState({ ...this.state, shareButtonEnable: true });

    const response = await this.props.postSharedDetails(data);
    const responseData: any = response.data;
    if (
      responseData &&
      responseData.previously_shared &&
      responseData.previously_shared.length
    ) {
      for (let i = 0; i < responseData.previously_shared.length; i++) {
        if (response.data.previously_shared[i].status === "ACCEPTED") {
          this.setState({
            ...this.state,
            sharedDetailsToPost: {},
            shareErrors: [
              response.data.previously_shared[i],
              ...this.state.shareErrors,
            ],
            shared_with: [],
            showSelected: false,
          });
        } else {
          this.setState({
            ...this.state,
            shareErrors: [
              response.data.previously_shared[i],
              ...this.state.shareErrors,
            ],
            sharedDetailsToPost: {},
            shared_with: [],
            showSelected: false,
          });
        }
      }
    }
  };

  onItemSelect = (selectedItem1: any) => {
    const selectedItems =
      selectedItem1 && selectedItem1.length
        ? selectedItem1.map((item: any) => item.shared_with)
        : [];
    this.setState({
      ...this.state,
      shareErrors: [],
      showSelected: true,
      sharedDetailsToPost: selectedItem1 && selectedItem1[0],
      shared_with: selectedItems,
      shareButtonEnable: selectedItems && selectedItems.length ? false : true,
    });
    // return selectedItem ? selectedItem : null;
  };

  onSurgeryBarClick = (duration: any) => {
    if (this.player) {
      this.player.ready(() => {
        (window as any).player = this.player;
      });
      this.player?.currentTime &&
        this.player?.currentTime(convertDurationOfVideoToSec(duration));
    }
  };

  handleActivEdgeVideoChange = (checked: boolean) => {
    const { videoData } = this.props;
    const streamingEndPoints = videoData.streaming_endpoint;
    const srcUrl = checked
      ? videoData.activ_streaming_endpoint?.dash ||
        videoData.activ_streaming_endpoint?.cdn?.dash
      : streamingEndPoints?.dash || streamingEndPoints?.cdn?.dash;

    if (srcUrl && srcUrl.length) {
      this.setState({
        ...this.state,
        activInsightVideo: !this.state.activInsightVideo,
        videoSrcUrl: srcUrl,
        isActivStreaming: checked,
      });
      this.props.setCurrentVideoPlayTime(this.player?.currentTime() || 0);
      setTimeout(() => {
        if (this.player) {
          this.player?.currentTime(this.props.currentPlayTime);
          // this.player?.play();
          this.videoPlay("active-canvas");
        }
      }, 200);
    }
  };

  handleAnnotationSelectDropDown = async (value: string) => {
    this.setState({
      ...this.state,
      annotationSelect: value !== "" ? true : false,
    });
    const duration = this.player?.currentTime();
    const val = duration % 10;
    const result = duration - val;
    this.props.setCurrentVideoPlayTime(duration || 0);

    if (value !== "") {
      const response = await this.props.getOverlayUrls(
        this.props.params.id,
        value
      );
      if (response === 200) {
        this.props.getAnnotationData(result);
        // this.props.getAnnotationData(0);
      }
    } else {
      // this.player?.currentTime(this.props.currentPlayTime);
      // this.player?.play();
    }
    setTimeout(() => {
      this.videoPlay("active-canvas");
    }, 1000);
  };

  getImages = async (iDs: any) => {
    try {
      const payload = {
        method: "GET",
        url: apiEndPoint + "/user/profile?users=" + iDs.join(),
      };
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          const id = response?.data[i].userId;
          const obj = {
            photo: response.data[i].photo,
            name: response.data[i].displayName || response.data[i].givenName,
          };
          this.state.imagesList.push(obj);
        }
      }
    } catch (e) {}
  };

  handleModelSelectDropDown = async (modelId: string) => {
    this.setState({
      ...this.state,
      annotationSelect: modelId !== "" ? true : false,
    });
    this.props.setCurrentVideoPlayTime(this.player?.currentTime() || 0);

    if (modelId !== "") {
      const response = await this.props.getModelOverlayUrls(
        this.props.params.id,
        modelId
      );
      response === 200 && this.props.getAnnotationData(0);
      setTimeout(() => {
        if (this.player) {
          this.player?.currentTime(0);
          // this.player?.play();
          this.videoPlay("active-canvas");
        }
      }, 100);
    } else {
      setTimeout(() => {
        if (this.player) {
          this.player?.currentTime(this.props.currentPlayTime);
          //this.player?.play();
          this.videoPlay("active-canvas");
        }
      }, 100);
    }
  };

  handleInsightClick = (duration: any) => {
    duration = convertDurationOfVideoToSec(duration);
    this.player?.currentTime && this.player?.currentTime(duration);
  };

  onVideoPageChange = (pageNo: number) => {
    this.setState({ videoCurrentPage: pageNo });
    this.props.getVideosById(this.props.videoData?.primary_surgeon, pageNo);
  };

  onProcedureVideoPageChange = (pageNo: number) => {
    this.setState({ procedureVideoCurrentPage: pageNo });
    this.props.getVideosByProcedure(
      this.props.videoData?.primary_procedure_type,
      pageNo,
      this.props.videoData?.is_uploaded_by_user
    );
  };

  render() {
    const breadcrumbItems: any = [
      {
        text: "Home",
        key: "Home",
        onClick: () => this.props.history("/"),
      },
      {
        text: this.props.videoData && this.props.videoData.procedure_name,
        key: "d2",
      },
      {
        text: this.props.videoData && this.props.videoData.hospital_name,
        key: "d3",
        isCurrentItem: true,
        as: "h4",
      },
    ];

    const datasetItems: any = [
      {
        text: "Data Set",
        key: "DataSet",
        onClick: () => this.props.history("/testdataset"),
      },
      {
        text: this.props.params.alg,
        key: "d2",
      },
      {
        text: this.props.params.video,
        key: "d3",
        isCurrentItem: true,
        as: "h4",
      },
    ];

    const [video_uploaded_by] =
      this.props.userData && this.props.userData.results
        ? this.props.userData.results
            .map((item: any) => {
              if (
                this.props.videoData &&
                this.props.videoData.uploaded_by === item.id
              ) {
                return item.displayName;
              } else return null;
            })
            .filter((item: any) => item)
        : [""];

    const displayDetails =
      (this.props.videoData &&
        this.props.userData.results &&
        this.props.userData.results
          .map((item: any) => {
            if (item.id === this.props.videoData.primary_surgeon)
              return {
                primarySurgeon: item.displayName,
                uploadedDate: this.props.videoData.uploaded_date,
                notes: this.props.videoData.notes,
              };
            return null;
          })
          .filter((data: any) => data && data)) ||
      [];
    const permission12 =
      localStorage.getItem("role") == "Surgeon"
        ? true
        : constants.getPermissionsbyItem("vps.video_comments.list");

    return this.props.showData && this.checkVideoDataIsExist() ? (
      <>
        <Stack
          className="no-root-padding-required"
          verticalFill
          styles={containerStyles}
          tokens={{ padding: "0rem 2rem" }}
        >
          {/* <Link
            to="/"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textDecoration: "none",
            }}
          > */}
          <Stack.Item
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textDecoration: "none",
              // marginTop:"25px"
            }}
          >
            <IconButton
              style={{ color: "white" }}
              iconProps={cancelIcon}
              onClick={() => {
                localStorage.setItem("panelNavigation", "true");
                window.history.back();
              }}
            />
          </Stack.Item>
          {/* </Link> */}
          <StackItem>
            <Breadcrumb
              items={
                this.props?.videoData?.status_name
                  ? breadcrumbItems
                  : this.props.params?.alg
                  ? datasetItems
                  : ""
              }
            />
          </StackItem>
          {(localStorage.getItem("role") === "Surgeon" ||
            localStorage.getItem("role") === "Developer" ||
            localStorage.getItem("role") === "HospitalAdmin") &&
            this.props.activInsightData &&
            this.props.videoPhases &&
            this.props.videoPhases.length &&
            this.props.videoData.status_name === "PROCESSED" && (
              <StackItem
                styles={{
                  root: {
                    marginBottom: "1.5rem",
                  },
                }}
              >
                <Stack
                  horizontal
                  styles={{
                    root: {
                      backgroundColor: ThemeColorPalette.selectedGridBG,
                      flex: "100%",
                    },
                  }}
                >
                  <SurgeryBreakUpChart
                    videoPhases={this.props.videoPhases}
                    onSurgeryBarClick={this.onSurgeryBarClick}
                    totalPhasesTime={this.props.totalPhasesTime}
                    phasesLegendData={this.props.phasesLegendData}
                  />
                </Stack>
              </StackItem>
            )}
          <Stack horizontal tokens={{ childrenGap: 15, maxHeight: "700px" }}>
            <StackItem
              styles={{
                root: {
                  backgroundColor: ThemeColorPalette.metrixbg,
                  width: "800px",
                  flex: 9,
                  flexGrow: 1,
                  flexShrink: 1,
                  margin: "0 auto",
                },
              }}
            >
              <Stack
                styles={{
                  root: {
                    flex: 12,
                  },
                }}
              >
                <StackItem
                  styles={{
                    root: {
                      position: "relative",
                    },
                  }}
                  className="pop-up-video-player"
                >
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 15, padding: "0px 0px 0px 0px" }}
                    styles={{ root: { background: "#000" } }}
                  >
                    <StackItem
                      styles={{
                        root: {
                          position: "relative",
                          width: "800px",
                          height: "500px",
                          margin: "auto",
                        },
                      }}
                      grow
                    >
                      <div
                        id="video-container"
                        //className={this.state.fullScreenClassVisible ? "full-screen" : ""}
                        ref={this.containerRef}
                        style={{
                          width: this.state.fullScreenMode ? "100%" : "800px",
                          margin: "auto",
                        }}
                      >
                        <VideoPlayer
                          containerref={this.containerRef}
                          userProfile={this.props.userProfile}
                          params={this.props.params}
                          controls={true}
                          src={{
                            src: this.getVideoUrl(),
                            type: "application/dash+xml",
                          }}
                          onReady={this.onPlayerReady}
                          onPlay={this.onVideoPlay}
                          onPause={this.onVideoPause}
                          //onTimeUpdate={this.onVideoTimeUpdate}
                          onSeeking={this.onVideoSeeking}
                          onSeeked={this.onVideoSeeked}
                          onEnd={this.onVideoEnd}
                          handleActivEdgeVideoChange={
                            this.handleActivEdgeVideoChange
                          }
                          appliedAlgorithms={this.props.appliedAlgorithms}
                          modules={this.props.modules}
                          handleAnnotationSelectDropDown={
                            this.handleAnnotationSelectDropDown
                          }
                          handleModelSelectDropDown={
                            this.handleModelSelectDropDown
                          }
                          match={this.props.params}
                          activInsightVideo={this.state.activInsightVideo}
                          activVideoCheck={this.state.activVideoCheck}
                          sharedVideo={this.props.sharedVideo}
                          activInsightDataLength={
                            this.props.activInsightData &&
                            this.props.activInsightData.Insights &&
                            this.props.activInsightData.Insights.length
                          }
                          videoData={{
                            primary_procedure_type:
                              this.props?.videoData?.primary_procedure_type,
                            primary_surgeon:
                              this.props?.videoData?.primary_surgeon,
                            status_name: this.props?.videoData?.status_name,
                          }}
                          isUploadedByUser={
                            this.props?.loginData?.role === "Developer"
                              ? this.props?.location?.state?.is_uploaded_by_user
                              : this.props?.videoData?.is_uploaded_by_user
                          }
                          markers={this.state.markers}
                          markersSet={this.state.markersSet}
                          loadedFrom={
                            this.props?.loginData?.role === "Developer"
                              ? "developer-home"
                              : this.props?.location?.state?.loadedFrom
                          }
                          mappedAlgorithmsToDataSet={
                            this.props.mappedAlgorithmsToDataSet
                          }
                          fullScreenEnble={this.state.fullScreenEnble}
                          roleName={this.props.userProfile?.role_name}
                          fullScreenToggleOnOff={this.fullScreenToggle}
                        ></VideoPlayer>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            width: this.state.fullScreenMode ? "100%" : "800px",
                            height: "100%",
                            margin: "auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <canvas
                            id="active-canvas"
                            style={{
                              width: this.canvasElement.width + "px",
                              height: "100%",
                            }}
                            onClick={this.onCanvasClick}
                          ></canvas>
                        </div>
                      </div>
                    </StackItem>
                    {this.props.userProfile?.role_name === "HospitalAdmin" ? (
                      <>
                        {this.props?.videoData?.status_name &&
                          this.props.userProfile?.role_name !==
                            ROLE_TYPES.ACTIVE_DEVELOPER &&
                          this.props.userProfile?.role_name !==
                            ROLE_TYPES.ACTIVE_DUAL &&
                          this.props.userProfile?.role_name !==
                            ROLE_TYPES.ACTIVE_ADMIN &&
                          !this.props?.activInsightData?.Insights?.length && (
                            <StackItem
                              styles={{
                                root: { background: "#121212", width: "30%" },
                              }}
                            >
                              <VideoCommenting
                                videoId={this.props.params.id}
                                permission={permission12}
                                player={this.player}
                                markers={this.state.markers}
                                setMarkersForComment={this.setMarkersForComment}
                                onPause={this.state.isPaused}
                                userProfile={this.props.userProfile}
                              />
                            </StackItem>
                          )}
                      </>
                    ) : (
                      <>
                        {this.props?.videoData?.status_name &&
                          this.props.userProfile?.role_name !==
                            ROLE_TYPES.ACTIVE_DEVELOPER &&
                          this.props.userProfile?.role_name !==
                            ROLE_TYPES.ACTIVE_DUAL &&
                          this.props.userProfile?.role_name !==
                            ROLE_TYPES.ACTIVE_ADMIN &&
                          !this.props?.activInsightData?.Insights?.length && (
                            <StackItem
                              styles={{
                                root: { background: "#121212", width: "30%" },
                              }}
                            >
                              <VideoCommenting
                                videoId={this.props.params.id}
                                permission={permission12}
                                player={this.player}
                                markers={this.state.markers}
                                setMarkersForComment={this.setMarkersForComment}
                                onPause={this.state.isPaused}
                                userProfile={this.props.userProfile}
                              />
                            </StackItem>
                          )}
                      </>
                    )}
                  </Stack>
                </StackItem>
              </Stack>
              {this.props?.videoData?.status_name && (
                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  styles={{ root: { padding: "2%", overflow: "auto" } }}
                >
                  <StackItem styles={{ root: { padding: "0 1rem 0 0" } }}>
                    <Stack tokens={{ childrenGap: 20 }}>
                      <Text
                        variant="mediumPlus"
                        className="textEllipses_procedures"
                        block
                        title={this.props?.videoData?.procedure_name}
                      >
                        {this.props?.videoData?.procedure_name?.slice(0, 65)}
                      </Text>
                      <Stack
                        horizontal
                        horizontalAlign="start"
                        tokens={{ childrenGap: 200 }}
                      >
                        <Stack tokens={{ childrenGap: 5 }}>
                          <Text variant="tiny" styles={tinyTextStyles}>
                            Primary Surgeon
                          </Text>
                          <Text variant="small">
                            {this.props?.videoData?.primary_surgeon_name}
                          </Text>
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }}>
                          <Text variant="tiny" styles={tinyTextStyles}>
                            Uploaded by
                          </Text>
                          <Text variant="small">
                            {this.props.videoData.uploaded_by
                              ? this.props.videoData.uploaded_by + " "
                              : this.props.videoData.secondary_surgeon_name
                              ? this.props.videoData.secondary_surgeon_name +
                                " "
                              : ""}
                            <span>
                              <Text variant="tiny" styles={tinyTextStyles}>
                                on{" "}
                                {this.props.videoData &&
                                this.props.videoData.uploaded_date
                                  ? getTime(
                                      this.props.videoData.uploaded_date
                                    ) +
                                    ", " +
                                    getDateWithSlashInBetween(
                                      this.props.videoData.uploaded_date
                                    )
                                  : ""}
                              </Text>
                            </span>
                          </Text>
                        </Stack>
                      </Stack>
                    </Stack>
                  </StackItem>
                  <StackItem>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <ViewEditVideoDetailsPanel
                          showDetailsButton={true}
                          hideFooter={
                            this.props?.loginData?.role === "Developer"
                              ? "true"
                              : false
                          }
                          displayDetails={displayDetails[0]}
                          videoData={this.props.videoData}
                          userProfile={this.props.userProfile}
                          userData={this.props.userData}
                          proceduresData={this.props.proceduresData}
                          getVideoDetails={this.getVideoDetailsById}
                          match={this.props.params}
                          videoId={this.props?.videoData?.id}
                          spinnerLoader={this.props.showSpinnerVideoDetails}
                          locationDetails={this.props.location}
                        />
                        {this.props.loginData?.role === "Surgeon" && (
                          <>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                              <AddToFavoritesButton
                                setSelectedVideoUnfavourite={
                                  this.props.setSelectedVideoUnfavourite
                                }
                                data={{
                                  id: this.props.videoData.id,
                                  favorite: this.props.videoData.favorite,
                                  primary_surgeon:
                                    this.props.videoData.primary_surgeon,
                                }}
                              />
                            </Stack>
                            <VideoPlayerShareModal
                              videoData={this.props.videoData}
                              userProfileId={this.props.userProfile.userId}
                              userData={this.props.userData}
                              onItemSelect={this.onItemSelect}
                              postSharedDetails={this.props.postSharedDetails}
                              proceduresData={this.props.proceduresData}
                            />
                            <VideoPlayerUnShareModal
                              videoData={this.props.videoData}
                              userProfileId={this.props.userProfile.userId}
                              userData={this.props.userData}
                              onItemSelect={this.onItemSelect}
                              postSharedDetails={this.props.postSharedDetails}
                              proceduresData={this.props.proceduresData}
                              previouslySharedList={
                                this.props.videoData.shared_with
                              }
                              getVideoDetails={this.getVideoDetailsById}
                            />
                          </>
                        )}
                      </Stack>
                      {this.props.userProfile?.role_name !==
                        ROLE_TYPES.ACTIVE_DEVELOPER && (
                        <StackItem>
                          <Stack verticalFill tokens={{ childrenGap: 2 }}>
                            <Text variant="tiny" styles={tinyTextStyles}>
                              Shared with
                            </Text>
                            <StackItem>
                              {this.props.videoData &&
                              this.state.imagesList.length &&
                              this.props.videoData.shared_with &&
                              this.props.videoData.shared_with.length ? (
                                // && this.props.videoData.primary_surgeon ===this.props.userProfile.userId
                                <>
                                  {this.state.imagesList.map((item: any) => {
                                    const matches = item.name.match(/\b(\w)/g);
                                    const joinChar = matches.join("");
                                    // return item.photo ? (
                                    return (
                                      <TooltipHost
                                        content={item.name}
                                        id={"nameToolTip"}
                                      >
                                        {item.photo ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              float: "left",
                                              width: "40px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Persona
                                              imageUrl={item.photo}
                                              size={PersonaSize.size40}
                                              aria-describedby="nameToolTip"
                                            ></Persona>
                                          </div>
                                        ) : (
                                          <StackItem
                                            className="imageIcon"
                                            aria-describedby="nameToolTip"
                                            style={{
                                              display: "inline-block",
                                              lineHeight: 2.5,
                                              cursor: "pointer",
                                            }}
                                          >
                                            {joinChar}
                                          </StackItem>
                                        )}
                                      </TooltipHost>
                                    );
                                  })}
                                </>
                              ) : (
                                // <Facepile
                                //   personaSize={PersonaSize.size32}
                                //   personas={this.props.videoData.shared_with.map(
                                //     (item: any) => {
                                //       return {
                                //         personaName: "",
                                //         imageUrl: TestImages.personaFemale,
                                //       };
                                //     }
                                //   )}
                                // />
                                <Text variant="small">None</Text>
                              )}
                            </StackItem>
                          </Stack>
                        </StackItem>
                      )}
                      <StackItem>
                        <Text variant="small" styles={tinyTextStyles}>
                          Video in compliance
                        </Text>
                        <FontIcon
                          iconName="RibbonSolid"
                          style={{
                            color: ThemeColorPalette.ribbon,
                            fontSize: "80%",
                            marginLeft: "0.2rem",
                          }}
                        />
                      </StackItem>
                      {this.isStreamingEnabled && (
                        <StackItem>
                          <Text
                            variant="small"
                            styles={tinyTextStyles}
                            style={{ marginRight: "8px" }}
                          >
                            ActivSight
                          </Text>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={this.state.isActivStreaming}
                              onChange={(e: any) => {
                                this.setState((prevState: any) => ({
                                  isActivStreaming: !prevState.isActivStreaming,
                                }));
                                this.handleActivEdgeVideoChange(
                                  e.target.checked
                                );
                              }}
                            />
                            <div
                              className="slider"
                              style={{ cursor: "pointer" }}
                            ></div>
                          </label>
                        </StackItem>
                      )}
                    </Stack>
                  </StackItem>
                </Stack>
              )}
            </StackItem>
            {(localStorage.getItem("role") === "Surgeon" ||
              localStorage.getItem("role") === "Developer" ||
              localStorage.getItem("role") === "HospitalAdmin") &&
            this.props.activInsightData &&
            this.props.activInsightData &&
            this.props.activInsightData.Insights &&
            this.props.activInsightData.Insights.length ? (
              <StackItem
                styles={{
                  root: {
                    backgroundColor: ThemeColorPalette.metrixbg,
                    width: "31%",
                    flexShrink: 0,
                  },
                }}
              >
                <Stack
                  verticalFill
                  styles={{
                    root: {
                      maxHeight: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <Pivot
                    aria-label="Basic Pivot Example"
                    className="custom-pivot"
                  >
                    {localStorage.getItem("role") !==
                      ROLE_TYPES.ACTIVE_DEVELOPER && (
                      <PivotItem headerText="Comments">
                        <div style={{ background: "#181818" }}>
                          <VideoCommenting
                            videoId={this.props.params.id}
                            permission={permission12}
                            player={this.player}
                            markers={this.state.markers}
                            setMarkersForComment={this.setMarkersForComment}
                            onPause={this.state.isPaused}
                            doNotShowTitle={true}
                            userProfile={this.props.userProfile}
                          />
                        </div>
                      </PivotItem>
                    )}
                    <PivotItem
                      headerText={Object.keys(this.props.activInsightData)[0]}
                    >
                      <div
                        style={{
                          maxHeight: 636,
                          overflow: "auto",
                        }}
                      >
                        <ActivInsightPanel
                          procedureName={this.props.videoData.procedure_name}
                          handleInsightClick={this.handleInsightClick}
                          activInsightData={this.props.activInsightData}
                        />
                      </div>
                    </PivotItem>
                  </Pivot>
                </Stack>
              </StackItem>
            ) : null}
          </Stack>
          <Stack tokens={{ childrenGap: 15 }}>
            {localStorage.getItem("role") === "HospitalAdmin" &&
              this.props.videosById?.results?.length > 1 && (
                <>
                  <Stack style={{ marginTop: "10px" }}>
                    <Text variant="mediumPlus">
                      Other Videos of{" "}
                      {this.props?.videoData?.primary_surgeon_name}
                    </Text>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    verticalFill
                    tokens={{ childrenGap: 10, padding: 10 }}
                    styles={{ root: { marginLeft: "-10px" } }}
                  >
                    {this.props.videosById?.results?.map(
                      (v: any, index: number) => {
                        if (this.props.videosById?.count >= 12) {
                          return (
                            <StackItem
                              key={v.id}
                              className="allVideosVideoCard all-videos-card-width"
                              style={{ width: "245px" }}
                            >
                              <Videos
                                video={v}
                                key={v.id}
                                isMenuEnabled={
                                  this.props.userProfile &&
                                  this.props.userProfile.role_name ===
                                    "HospitalAdmin"
                                    ? true
                                    : false
                                }
                              ></Videos>
                            </StackItem>
                          );
                        }
                        if (
                          this.props.videosById?.count < 12 &&
                          v.id !== this.props.videoData.id
                        ) {
                          return (
                            <StackItem
                              key={v.id}
                              className="allVideosVideoCard all-videos-card-width"
                              style={{ width: "245px" }}
                            >
                              <Videos
                                video={v}
                                key={v.id}
                                isMenuEnabled={
                                  this.props.userProfile &&
                                  this.props.userProfile.role_name ===
                                    "HospitalAdmin"
                                    ? true
                                    : false
                                }
                              ></Videos>
                            </StackItem>
                          );
                        }
                      }
                    )}
                  </Stack>

                  {this.props.videosById?.count > 13 &&
                  this.props.videosById?.page_size ? (
                    <Stack
                      horizontal
                      horizontalAlign="center"
                      tokens={{ childrenGap: 15, maxHeight: "700px" }}
                    >
                      <Paging
                        currentPage={this.state.videoCurrentPage}
                        totalPageCount={Math.ceil(
                          this.props.videosById?.count / 13
                        )}
                        handlePageChange={this.onVideoPageChange}
                      />
                    </Stack>
                  ) : null}
                </>
              )}

            {localStorage.getItem("role") === "Developer" &&
              this.props.videosByProcedure?.results?.length > 0 && (
                <>
                  <Stack style={{ marginTop: "10px" }}>
                    <Text variant="mediumPlus" className="textEclips">
                      Other Videos of {this.props?.videoData?.procedure_name}
                    </Text>
                  </Stack>
                  <Stack
                    horizontal
                    wrap
                    verticalFill
                    tokens={{ childrenGap: 10, padding: 10 }}
                    styles={{ root: { marginLeft: "-10px" } }}
                  >
                    {this.props.videosByProcedure?.results?.map(
                      (v: any, index: number) => {
                        if (this.props.videosByProcedure.count >= 12) {
                          return (
                            <StackItem
                              key={v.id}
                              className="allVideosVideoCard all-videos-card-width"
                              style={{ width: "266px" }}
                            >
                              <Videos
                                video={v}
                                key={v.id}
                                videoId={v.id}
                                loadedFrom="developer-home"
                                showProcedureVideos="true"
                              ></Videos>
                            </StackItem>
                          );
                        }
                        if (
                          this.props.videosByProcedure.count < 12 &&
                          v.id !== this.props.videoData.id
                        ) {
                          return (
                            <StackItem
                              key={v.id}
                              className="allVideosVideoCard all-videos-card-width"
                              style={{ width: "266px" }}
                            >
                              <Videos
                                video={v}
                                key={v.id}
                                videoId={v.id}
                                loadedFrom="developer-home"
                                showProcedureVideos="true"
                              ></Videos>
                            </StackItem>
                          );
                        }
                      }
                    )}
                  </Stack>

                  {this.props.videosByProcedure?.count > 12 &&
                  this.props.videosByProcedure?.page_size ? (
                    <Stack
                      style={{ margin: "auto" }}
                      horizontal
                      tokens={{ childrenGap: 15, maxHeight: "700px" }}
                    >
                      <Paging
                        currentPage={this.state.procedureVideoCurrentPage}
                        totalPageCount={Math.ceil(
                          this.props.videosByProcedure?.count / 12
                        )}
                        handlePageChange={this.onProcedureVideoPageChange}
                      />
                    </Stack>
                  ) : null}
                </>
              )}
          </Stack>
        </Stack>
      </>
    ) : this.props.showSpinner ? (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { marginTop: "25%" } }}
      >
        <Spinner size={SpinnerSize.large} />
      </Stack>
    ) : (
      <Stack
        horizontal
        horizontalAlign="center"
        styles={{ root: { marginTop: "25%" } }}
      >
        <Text>No videos available</Text>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    loginData: state.authReducerData.loggedInUserData,
    videoData: state.videoPlayerReducerData.videoData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
    annotations: state.videoPlayerReducerData.annotations,
    videoPhases: state.videoPlayerReducerData.videoPhases,
    appliedAlgorithms: state.videoPlayerReducerData.appliedAlgorithms,
    overlayUrls: state.videoPlayerReducerData.overlayUrls,
    activInsightData: state.videoPlayerReducerData.activInsightData,
    sharedVideo: state.videoPlayerReducerData.sharedVideo,
    totalPhasesTime: state.videoPlayerReducerData.totalPhasesTime,
    phasesLegendData: state.videoPlayerReducerData.phasesLegendData,
    currentPlayTime: state.videoPlayerReducerData.currentPlayTime,
    showSpinner: state.videoPlayerReducerData.showSpinner,
    showData: state.videoPlayerReducerData.showData,
    modules: state.developerDataSetReducerData.appliedModelIdForVideo,
    videosById: state.hospitalHomeReducerData?.videosById,
    videosByProcedure: state.videoPlayerReducerData.videosByProcedure,
    // videoComments: state.videoPlayerReducerData.videoComments,
    showSpinnerVideoDetails:
      state.videoPlayerReducerData.showSpinnerVideoDetails,
    mappedAlgorithmsToDataSet:
      state.developerDataSetReducerData.mappedAlgorithmsToDataSet,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    cleanDatasets: () => dispatch(cleanDatasets()),
    getVideoDetails: (
      videoId: any,
      additionalInfo?: any,
      surgeonId?: any,
      location?: any
    ) =>
      dispatch(getVideoDetails(videoId, additionalInfo, surgeonId, location)),
    getVideoComments: (videoId: any) => dispatch(getVideoComments(videoId)),
    setSelectedVideoUnfavourite: (data: any) =>
      dispatch(setSelectedVideoUnfavourite(data)),
    postSharedDetails: (data: any) => dispatch(postSharedDetails(data)),
    getVideoPhases: (algoId: number, videoId: any) =>
      dispatch(getVideoPhases(algoId, videoId)),
    getAnnotationData: (duration: any) => dispatch(getAnnotationData(duration)),
    getOverlayUrls: (videoId: any, annotationType: string) =>
      dispatch(getOverlayUrls(videoId, annotationType)),
    getModelOverlayUrls: (videoId: any, modelId: any) =>
      dispatch(getModelOverlayUrls(videoId, modelId)),
    getActivInsightsData: (algoId: number, videoId: number) =>
      dispatch(getActivInsightsData(algoId, videoId)),
    setCurrentVideoPlayTime: (currentPlayTime: any) =>
      dispatch(setCurrentVideoPlayTime(currentPlayTime)),
    showSpinnerOnLoad: () => dispatch(showSpinnerOnLoad()),
    clearVideoData: () => dispatch(clearVideoData()),
    getVideosById: (id: number, pageNo: number) =>
      dispatch(getVideosById(id, pageNo)),
    getVideosByProcedure: (
      id: number,
      pageNo?: number,
      isUploadedByUser?: boolean
    ) => dispatch(getVideosByProcedure(id, pageNo, isUploadedByUser)),
  };
};
export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(VideoPlayerNew1)
);
