import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getHospitalInfo = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/hospitals/info",
  };
  // debugger;
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_HOSPITAL_INFO));
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_HOSPITAL_INFO,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_HOSPITAL_INFO, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_HOSPITAL_INFO,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getSurgeonsForDropdown =
  (searchValue = "", count?: any) =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : `&page_size=500`;
    const search = searchValue !== "" ? `&search=${searchValue}` : "";
    const defaultUrl = apiEndPoint + `/user/list?page=1${count}${search}`;
    const payload = {
      method: "GET",
      url: defaultUrl,
    };
    // debugger;
    if (
      localStorage.getItem("role") === "Surgeon" ||
      localStorage.getItem("role") === "HospitalAdmin"
    ) {
      try {
        const response = await doHttpCall(payload);
        // debugger;
        if (response && response.status === 200) {
          dispatch({
            type: allActions.GET_SURGEONS_FOR_DROPDOWN,
            payload: { data: response.data, isNext: searchValue },
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

export const getAllSurgeons =
  (searchValue = "", currentPage = 1, sortBy = "") =>
  async (dispatch: any) => {
    const search = searchValue !== "" ? `&search=${searchValue}` : "";
    // const currentUserPage = currentPage > 1 ? "?page=" + currentPage : "";
    const sortVideo = sortBy !== "" ? `&sort=${sortBy}` : "";
    //
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/user/surgeon?page=${currentPage}&page_size=12${search}${sortVideo}`,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ALL_SURGEONS));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALL_SURGEONS,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALL_SURGEONS,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALL_SURGEONS,
            403
          )
        );
      }
      console.error("responsee", response);
    }
  };

export const getAllVideosForPrimarySurgeons =
  (payloadForPrimarySurgeons: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(
        showLoaderWithMessage("", allActions.GET_ALL_VIDEOS_BY_PRIMARY_SURGEON)
      );
      const responseForPrimaySurgeons = await doHttpCall(
        payloadForPrimarySurgeons
      );
      if (
        responseForPrimaySurgeons &&
        responseForPrimaySurgeons.status === 200
      ) {
        dispatch({
          type: allActions.GET_ALL_VIDEOS_BY_PRIMARY_SURGEON,
          payload: { data: responseForPrimaySurgeons.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALL_VIDEOS_BY_PRIMARY_SURGEON,
            responseForPrimaySurgeons.status
          )
        );
      }
      if (
        responseForPrimaySurgeons &&
        responseForPrimaySurgeons.status === 404
      ) {
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALL_VIDEOS,
            responseForPrimaySurgeons.status
          )
        );
        dispatch({
          type: allActions.GET_ALL_VIDEOS_BY_PRIMARY_SURGEON,
          payload: { data: {} },
        });
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALL_VIDEOS_BY_PRIMARY_SURGEON,
            403
          )
        );
      } else {
        dispatch({
          type: allActions.GET_ALL_VIDEOS_BY_PRIMARY_SURGEON,
          payload: { data: {} },
        });
      }
      console.error(response);
    }
  };
export const getAllVideosUplodedByUser =
  (payload: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ALL_VIDEOS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALL_VIDEOS,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ALL_VIDEOS, response.status)
        );
      }
      if (response && response.status === 404) {
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ALL_VIDEOS, response.status)
        );
        dispatch({
          type: allActions.GET_ALL_VIDEOS,
          payload: { data: {} },
        });
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALL_VIDEOS,
            403
          )
        );
      } else {
        dispatch({
          type: allActions.GET_ALL_VIDEOS,
          payload: { data: {} },
        });
      }
      console.error(response);
    }
  };
export const getAllVideos =
  (
    searchValue = "",
    currentPage = 1,
    sortBy = "",
    allVideosPage = true,
    allVideosSurgeonPage = true
  ) =>
  async (dispatch: any, getState: any) => {
    const userProfile = getState().dashboardReducerData.loginUserProfile;
    const userId = userProfile.id || userProfile.userId;
    const search = searchValue !== "" ? `&search=${searchValue}` : "";
    const sortVideo = sortBy !== "" ? `&ordering=${sortBy}` : "";
    const primarySurgeon = `&primary_surgeon=${userId}`;
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/videos/search?page=${currentPage}&page_size=12${search}${sortVideo}`,
    };
    const payloadForPrimarySurgeons = {
      method: "GET",
      url:
        apiEndPoint +
        `/videos/search?page=${currentPage}&page_size=12${search}${sortVideo}${primarySurgeon}`,
    };
    if (allVideosPage) {
      dispatch(getAllVideosUplodedByUser(payload));
    }
    if (allVideosSurgeonPage) {
      dispatch(getAllVideosForPrimarySurgeons(payloadForPrimarySurgeons));
    }
  };

export const getOnboardFacilities = (count?: any) => async (dispatch: any) => {
  count = count ? `?page_size=${count}` : ``;
  const payload = {
    method: "GET",
    url: apiEndPoint + `/hospitals/facilities${count}`,
  };
  // debugger;
  try {
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.ONBOARD_FACILITIES,
        payload: { data: response.data },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const getOnboardSpeciality = (count?: any) => async (dispatch: any) => {
  count = count ? `?page_size=${count}` : ``;
  const payload = {
    method: "GET",
    url: apiEndPoint + `/hospitals/speciality${count}`,
  };
  // debugger;
  try {
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.ONBOARD_SPECIALITY,
        payload: { data: response.data },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const getOnboardPermissions = (count?: any) => async (dispatch: any) => {
  count = count ? `?page_size=${count}` : ``;
  const payload = {
    method: "GET",
    url: apiEndPoint + `/hospitals/roles${count}`,
  };
  // debugger;
  try {
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.ONBOARD_PERMISSIONS,
        payload: { data: response.data },
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const createSurgeon = (surgeonData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/user/surgeon",
    data: surgeonData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_SURGEON));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_SURGEON,
        payload: { data: surgeonData },
      });
      dispatch(getAllSurgeons());
      dispatch(getHospitalInfo());
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_SURGEON, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 403)
    ) {
      dispatch({
        type: allActions.CREATE_SURGEON_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.CREATE_SURGEON_ERROR,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_SURGEON,
          403
        )
      );
    }
    console.error(response);
  }
};

export const showSurgeonSpinner = () => {
  return {
    type: allActions.SURGEON_SPINNER,
  };
};
export const clearCreateSurgeonErrors = (field: any) => {
  return {
    type: allActions.CREATE_SURGEON_CLEAR_ERRORS,
    payload: field,
  };
};
export const deleteSurgeon =
  (id: number | string, searchValue = "", currentPage = 1, sortBy = "") =>
  async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + "/user/surgeon/" + id,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_SURGEON));
      const response = await doHttpCall(payload);
      if ((response && response.status === 200) || 204) {
        dispatch({
          type: allActions.DELETE_SURGEON,
          payload: { data: response.data },
        });
        // dispatch(getAllSurgeons());
        dispatch(getAllSurgeons(searchValue, currentPage || 1, sortBy));
        dispatch(getHospitalInfo());
        dispatch(
          hideLoaderWithMessage("", allActions.DELETE_SURGEON, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_SURGEON,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const deleteVideo =
  (
    id: number | string,
    searchValueVideo: any,
    videoCurrentPageVideo: any,
    sortVideoValueVideo: any
  ) =>
  async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + "/videos/" + id,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_VIDEO));
      const response = await doHttpCall(payload);
      if ((response && response.status === 200) || response.status === 204) {
        dispatch({
          type: allActions.DELETE_VIDEO,
          payload: { data: response.data },
        });
        // dispatch(getAllVideos());
        dispatch(
          getAllVideos(
            searchValueVideo,
            videoCurrentPageVideo,
            sortVideoValueVideo
          )
        );
        dispatch(getHospitalInfo());
        dispatch(
          hideLoaderWithMessage("", allActions.DELETE_VIDEO, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_VIDEO,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const bulkOnboardSurgeon =
  (bulkSurgeonData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/user/surgeon",
      data: bulkSurgeonData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.BULK_ONBOARD_SURGEON));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.BULK_ONBOARD_SURGEON,
          payload: { data: bulkSurgeonData },
        });
        dispatch(getHospitalInfo());
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.BULK_ONBOARD_SURGEON,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_BULK_ONBOARD,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_BULK_ONBOARD,
            response.status
          )
        );
        // dispatch({
        //   type: allActions.ERROR_IN_BULK_ONBOARD,
        //   payload: { data: "Bad Request" },
        // });
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_BULK_ONBOARD,
          payload: { data: "Internal Server Error" },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_BULK_ONBOARD,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.BULK_ONBOARD_SURGEON,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getSurgeonById = (surgeonId: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/user/surgeon/" + surgeonId,
  };
  // debugger;
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_SURGEON_BY_ID));
    const response = await doHttpCall(payload);
    // debugger;
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_SURGEON_BY_ID,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_SURGEON_BY_ID, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_SURGEON_BY_ID,
          403
        )
      );
    }
    console.error(response);
  }
};

export const editSurgeon =
  (surgeonId: number, surgeonData: any, currentPage = 1) =>
  async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/user/surgeon/${surgeonId}`,
      data: surgeonData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_SURGEON));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_SURGEON,
          payload: { data: surgeonData },
        });
        dispatch({
          type: allActions.GET_SURGEON_BY_ID,
          payload: { data: null },
        });
        dispatch(getHospitalInfo());
        // dispatch(getAllSurgeons("",currentPage));
        dispatch(
          hideLoaderWithMessage("", allActions.CREATE_SURGEON, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 403)
      ) {
        dispatch({
          type: allActions.CREATE_SURGEON_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_SURGEON_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_SURGEON,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getVideosById =
  (surgeonId: any, currentPage = 1) =>
  async (dispatch: any) => {
    const currentUserPage = currentPage > 1 ? "&page=" + currentPage : "";
    const pageSize = "&page_size=12";
    const payload = {
      method: "GET",
      url: `${apiEndPoint}/videos/?primary_surgeon=${surgeonId}${currentUserPage}${pageSize}`,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_VIDEOS_BY_ID));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIDEOS_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_VIDEOS_BY_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_VIDEOS_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getDownloadTemplate = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/templates/surgeon/download",
  };
  try {
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.DOWNLOAD_TEMPLATE,
        payload: { data: response.data },
      });
    }
  } catch (response) {
    console.error(response);
  }
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};

export const getAllHospitalAdmins =
  (pageNo?: number) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/user/hospital/admins?page=${pageNo}&page_size=12`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ALL_HOSPITAL_ADMINS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALL_HOSPITAL_ADMINS,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALL_HOSPITAL_ADMINS,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALL_HOSPITAL_ADMINS,
            403
          )
        );
      }
      console.error("GET_ALL_HOSPITAL_ADMINS", response);
    }
  };

export const getHospitalAdminDetailsByUserID =
  (userID?: any) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/user/hospitaladmin/${userID}/details`,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          allActions.GET_HOSPITAL_ADMIN_DETAILS_BY_USERID
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_HOSPITAL_ADMIN_DETAILS_BY_USERID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_HOSPITAL_ADMIN_DETAILS_BY_USERID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_HOSPITAL_ADMIN_DETAILS_BY_USERID,
            403
          )
        );
      }
      console.error("GET_HOSPITAL_ADMIN_DETAILS_BY_USERID", response);
    }
  };
