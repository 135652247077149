import React from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import "./CreateHospital.scss";
import AddHospital from "./CreateHospitalWizard/AddHospital/AddHospital";
import Wizard from "../Wizard/Wizard";
import AddDevice from "./CreateHospitalWizard/AddDevice/AddDevice";
import Conformation from "./CreateHospitalWizard/Conformation/Conformation";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const CreateHospital = ({
  isModalOpen,
  showModal,
  hospitalId,
}: {
  isModalOpen: any;
  showModal: any;
  hospitalId: any;
}) => {
  const hospitalData = useSelector((state: AppState) => {
    return state.homeReducerData.hospitalData;
  });

  const showCreateHospital = useSelector((state: AppState) => {
    return state.homeReducerData.showCreateHospital;
  });

  // isModalOpen = hospitalSpinner ? !hospitalSpinner : isModalOpen;
  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={() => showModal()}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="xLarge">
            Welcome to {!showCreateHospital ? "Create" : "Edit"} Hospital
            wizard!
          </Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton
            className=""
            iconProps={cancelIcon}
            onClick={() => showModal()}
          />
        </Stack.Item>
      </Stack>

      {/* <StateMachineProvider> */}
      <Wizard
        showModal={() => showModal()}
        showCreateHospital={showCreateHospital}
        hospitalId={hospitalId}
      >
        <AddHospital
          hospitalData={hospitalData}
          showCreateHospital={showCreateHospital}
        />
        <AddDevice
          hospitalData={hospitalData}
          showCreateHospital={showCreateHospital}
        />
        <Conformation
          hospitalData={hospitalData}
          showCreateHospital={showCreateHospital}
        />
      </Wizard>
      {/* </StateMachineProvider> */}
    </Modal>
  );
};

export default React.memo(CreateHospital);
