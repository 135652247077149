import * as allActions from "../actions/actionConstants";

const initialState: any = {
  videosData: {},
  tempVideosData: {},
  sharedBy: [],
  sharedVideoEditedFields: {},
  sharedWith: [],
  showSpinner: true,
  showData: false,
  currentPage: 1,
};

export const sharedVideosReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_VIDEOS:
      return {
        ...state,
        videosData: action.payload.data,
        showSpinner:
          action.payload &&
          action.payload.data &&
          action.payload.data.results &&
          action.payload.data.results.length &&
          false,
        showData:
          action.payload &&
          action.payload.data &&
          action.payload.data.results &&
          action.payload.data.results.length &&
          true,
        currentPage: action.payload.currentPage,
      };
    case allActions.FETCH_SHARED_BY_VIDEOS:
      return {
        ...state,
        sharedBy: action.payload,
      };
    case allActions.SET_SELECTED_SHARED_VIDEO:
      const videosData = {
        ...state.videosData,
        results: state.videosData.results.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          } else {
            return {
              ...item,
              isSelected: false,
            };
          }
        }),
      };
      return {
        ...state,
        videosData: videosData,
        tempVideosData: videosData,
      };
    case allActions.SET_SELECTED_VIDEO_UNFAVOURITE:
      return {
        ...state,
        videosData: {
          ...state.videosData,
          results: state.videosData.results.map((item: any) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                favorite: !item.favorite,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
      };
    case allActions.EDIT_VIDEO_DETAILS:
      return {
        ...state,
        videosData: {
          ...state.videosData,
          results: state.videosData.results.map((item: any) => {
            if (item.id === action.payload.videoId) {
              return {
                ...item,
                ...state.sharedVideoEditedFields,
                ...action.payload.data,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
        sharedVideoEditedFields: {
          ...state.sharedVideoEditedFields,
          ...action.payload.data,
        },
      };
    case allActions.RESET_EDITED_DATA_ON_CANCEL_SHARED:
      return {
        ...state,
        videosData:
          action.payload === "cancel" ? state.tempVideosData : state.videosData,
        tempVideosData:
          action.payload === "cancel" ? state.tempVideosData : state.videosData,
        sharedVideoEditedFields: {},
      };
    case allActions.POST_SHARED_DETAILS:
      return {
        ...state,
        sharedWith: action.payload,
      };
    case allActions.CLEAR_SHARED_DATA:
      return {
        ...state,
        currentPage: 1,
        videosData: {},
        tempVideosData: {},
        showSpinner: true,
        showData: false,
      };
    default:
      return state;
  }
};
