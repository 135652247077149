import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  StackItem,
  Text,
  Modal,
  IconButton,
  IIconProps,
  TextField,
  Label,
  PrimaryButton,
  ActionButton,
} from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { ReactComponent as AddFacilityIcon } from "../../../../assets/Home/add_facility.svg";
import {
  createAlgorithm,
  editAlogrithm,
  removeErrorALG,
  updateOutputArguments,
} from "../../actions/managamentActions";
import { AppState } from "../../../../rootReducer";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndPoint, doHttpCall } from "../../../../common/restApi";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
const deleteIcon: IIconProps = { iconName: "Delete" };
const cancelIcon: IIconProps = { iconName: "Cancel" };
const ErrorMessage: any = (errors: any, name: any, type: any) => {
  if (!errors[name] || errors[name].type !== type) {
    return null;
  }
  return <p>{errors[name].message}</p>;
};
const options1 = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const alogrithmTypeOptions = [
  { value: "insights", label: "Insights" },
  { value: "overlay", label: "Overlay" },
  { value: "phases", label: "Phases" },
];
const computeTypeOptions = [
  { value: "CPU", label: "CPU" },
  { value: "GPU", label: "GPU" },
];
const CreateAlgorithm = ({
  isModalOpen,
  showModal,
  algorithmId,
}: {
  isModalOpen: any;
  showModal: any;
  algorithmId: any;
}) => {
  const [algorithmchange, setalgorithmchange] = useState("");
  const [outputParametersChange, setOutputParametersChange] = useState("");
  const [disabledCreate, setDisabledCreate] = useState(false);
  const algorithmData = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmData.data;
  });
  const spaceRegex = RegExp(/^[a-zA-Z0-9_]*$/);
  const isAlgorithmChanged = algorithmData?.name
    ? algorithmData?.name
    : "" !== algorithmchange;
  const validationSchema = Yup.object().shape({
    algorithm: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Algorithm Name is required"),
        // .test({
        //   message: "This name is already taken",
        //   test: async (value) => {
        //     if (algorithmchange !== "" && isAlgorithmChanged) {
        //       const checkFieldName = `/algorithms/?search=${value}`;
        //       const payload = {
        //         method: "GET",
        //         url: `${apiEndPoint}${checkFieldName}`,
        //       };
        //       const response = await doHttpCall(payload);
        //       console.log(response, response.data.results.length, "response");
        //       const returnStatus =
        //         response.data.results.length > 0 ? false : true;
        //       return returnStatus;
        //     } else {
        //       return true;
        //     }
        //   },
        // }),
        input_parameters: Yup.array().of(
          Yup.string()
            .required("Input Arguments is required")
            .matches(
              spaceRegex,
              "Input Arguments cannot have space and special characters"
            )
        ),
        output_parameters: Yup.array().of(
          Yup.string()
            .required("Output Arguments is required")
            .matches(
              spaceRegex,
              "Output Arguments cannot have space and special characters"
            )
        ),
        // output_parameters:
        //   algorithmData?.output_parameters.length > 0
        //     ? Yup.array().of(Yup.string())
        //     : Yup.array().of(
        //         Yup.string().required("Output Arguments  is required")
        //       ),
        compute_type: Yup.string().required("Compute Type is required"),
        algorithm_type: Yup.string().when("is_core_business", {
          is: "true",
          then: Yup.string().required("Algorithm Type is required"),
        }),
        presentation_layer_mapping: Yup.string().when("is_core_business", {
          is: "true",
          then: Yup.string().required("Presentation Layer Mapping is required"),
        }),
        is_core_business: Yup.string().required(
          "Is core business Type is required"
        ),
      })
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);
  const [nameChange, setnameChange] = useState(
    errors?.algorithm ? errors?.algorithm[0]?.name?.message : ""
  );
  const [newInputparameters, setInputParameters] = useState<Array<any>>([]);
  const [newOutputparameters, setOutputParameters] = useState<Array<any>>([]);
  useEffect(() => {
    // if (
    //   algorithmData?.algorithm_type &&
    //   errors?.algorithm &&
    //   errors?.algorithm[0]?.algorithm_type
    // )
    //   delete errors?.algorithm[0]?.algorithm_type;
    // if (
    //   algorithmData?.presentation_layer_mapping &&
    //   errors?.algorithm &&
    //   errors?.algorithm[0]?.presentation_layer_mapping
    // )
    //   delete errors?.algorithm[0]?.presentation_layer_mapping;
    // if (errors?.algorithm && !Object.keys(errors?.algorithm[0]).length)
    //   delete errors?.algorithm;
    setnameChange(errors?.algorithm ? errors?.algorithm[0]?.name?.message : "");
  }, [errors]);
  const [indexes, setIndexes] = useState<Array<number>>([0]);
  const [counter, setCounter] = useState(1);
  const [indexesOutput, setIndexesOutput] = useState<Array<number>>([0]);
  const [counterOutput, setCounterOutput] = useState(1);
  const dispatch = useDispatch();
  const [isBusiness, setIsBussiness] = useState<boolean | undefined>(false);
  const [presentationLayer, setPresentationLayer] = useState<any>("");
  const [outputParametersOptions, setoutputParametersOptions] = useState<
    Array<any>
  >([]);
  // const [outputArgumentsList, setOutputArgumentsList] = useState<Array<string>>(
  //   []
  // );
  const showCreateAlgorithms = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateAlgorithm;
  });
  const outputArguments = useSelector((state: AppState) => {
    return state.managementReducerData.outputArguments;
  });
  const algorithmSpinner = useSelector((state: AppState) => {
    return state.managementReducerData.algorithmSpinner;
  });
  const errorResponse = useSelector((state: AppState) => {
    return state.managementReducerData.errorResponse;
  });
  useEffect(() => {
    const values = getValues();
    values?.algorithm?.splice(0, 1);
  }, [algorithmSpinner]);
  useEffect(() => {
    if (disabledCreate) {
      setDisabledCreate(false);
    } else {
      dispatch(removeErrorALG());
    }
  }, [errorResponse]);

  const handleRemove = (index: any) => {
    const values = getValues();
    const newValues: any = [...values.algorithm[0].input_parameters];
    newValues.splice(index, 1);
    values?.algorithm[0]?.input_parameters.splice(index, 1);
    algorithmData?.input_parameters.splice(index, 1);
    setInputParameters(newValues);
    for (let i = 0; i < newValues.length; i++) {
      setValue(`algorithm[0].input_parameters[${i}]`, newValues[i]);
    }
    // values?.algorithm[0]?.input_parameters.splice(i, 1);
    // algorithmData?.input_parameters.splice(i, 1);
    // indexes.splice(i, 1);
    // setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== i)]);
    // setCounter((prevCounter) => prevCounter - 0);
  };
  const handleRemoveOutput = (i: any, index: any) => {
    const values = getValues();
    const removedValue = values?.algorithm[0]?.output_parameters[index];
    const newValues: any = [...values.algorithm[0].output_parameters];
    newValues.splice(index, 1);
    setOutputParameters(newValues);
    outputParametersOptions.splice(index, 1);
    setoutputParametersOptions(outputParametersOptions);
    values?.algorithm[0]?.output_parameters.splice(index, 1);
    algorithmData?.output_parameters.splice(index, 1);
    for (let j = 0; j < newValues.length; j++) {
      setValue(`algorithm[0].output_parameters[${j}]`, newValues[j]);
    }
    if (removedValue === values.algorithm[0]?.presentation_layer_mapping) {
      setValue(`algorithm[0].presentation_layer_mapping`, "");
    }

    // values?.algorithm[0]?.output_parameters.splice(index, 1);
    // algorithmData?.output_parameters.splice(index, 1);
    // indexesOutput.splice(index, 1);
    // setIndexesOutput((prevIndexes) => [
    //   ...prevIndexes.filter((item) => item !== index),
    // ]);
    // setCounterOutput((prevCounter) => prevCounter - 0);
    // outputParametersOptions.splice(index, 1);
    // setoutputParametersOptions(outputParametersOptions);
  };
  const addInputArguments = () => {
    // setIndexes((prevIndexes) => [...prevIndexes, counter]);
    // setCounter((prevCounter) => prevCounter + 1);
    const copyInputparameters: any = [...newInputparameters];
    copyInputparameters.push("");
    setInputParameters(copyInputparameters);
  };
  const addOutputArguments = () => {
    // setIndexesOutput((prevIndexes) => [...prevIndexes, counterOutput]);
    // setCounterOutput((prevCounter) => prevCounter + 1);
    const copyOutputparameters: any = [...newOutputparameters];
    copyOutputparameters.push("");
    setOutputParameters(copyOutputparameters);
  };
  useEffect(() => {
    const values = getValues();
    //   values?.algorithm?.splice(0, 1);
    if (!showCreateAlgorithms) {
      setInputParameters([""]);
      setOutputParameters([""]);
    }
  }, [isSubmitSuccessful, showCreateAlgorithms]);
  const _alertClicked = useCallback(() => {
    const values = getValues();
    values.algorithm.splice(0, 1);
    showModal();
  }, []);
  useEffect(() => {
    setIndexes([0]);
    setIndexesOutput([0]);
    const inputArgumentscount: Array<number> = [];
    const outputArgumentscount: Array<number> = [];
    algorithmData?.input_parameters?.map((input_parameter: any, index: any) => {
      inputArgumentscount?.push(index);
    });
    if (
      algorithmData?.input_parameters &&
      newInputparameters.length === 0 &&
      newOutputparameters.length === 0
    ) {
      setInputParameters(algorithmData?.input_parameters);
      setOutputParameters(algorithmData?.output_parameters);
    }
    algorithmData?.output_parameters?.map(
      (output_parameter: any, index: any) => {
        outputArgumentscount?.push(index);
      }
    );
    showCreateAlgorithms && setIndexes(inputArgumentscount);
    showCreateAlgorithms && setIndexesOutput(outputArgumentscount);
    setIsBussiness(algorithmData?.is_core_business);
    const optionsVal =
      algorithmData?.output_parameters?.map((param: string) => {
        return {
          value: param,
          label: param,
        };
      }) || [];
    setoutputParametersOptions(optionsVal);
    setPresentationLayer(algorithmData?.presentation_layer_mapping);
    // console.log(hospitalcount,hospitalData, indexes, showCreateHospital, 'showCreateHospital');
  }, [algorithmData]);
  useEffect(() => {
    const values = getValues();
    // if (!isBusiness) {
    //   delete values?.algorithm[0].algorithm_type;
    //   delete values?.algorithm[0].presentation_layer_mapping;
    // }
  }, [isBusiness]);
  const onSubmit = (data: any) => {
    setDisabledCreate(true);
    Object.assign(data?.algorithm[0], {
      input_parameters: data?.algorithm[0]?.input_parameters
        ? data?.algorithm[0]?.input_parameters.join(",").replace(/\s+/g, "")
        : null,
    });
    Object.assign(data?.algorithm[0], {
      output_parameters: data?.algorithm[0]?.output_parameters
        ? data?.algorithm[0]?.output_parameters.join(",").replace(/\s+/g, "")
        : null,
    });
    Object.assign(data?.algorithm[0], {
      is_core_business:
        data?.algorithm[0]?.is_core_business === "true" ? true : false,
    });
    if (data?.algorithm[0]?.is_core_business) {
      Object.assign(data?.algorithm[0], {
        algorithm_type: data?.algorithm[0]?.algorithm_type,
        presentation_layer_mapping:
          data?.algorithm[0]?.presentation_layer_mapping,
      });
    }
    if (!data?.algorithm[0]?.is_core_business) {
      delete data?.algorithm[0]?.presentation_layer_mapping;
    }
    !showCreateAlgorithms && dispatch(createAlgorithm(data?.algorithm[0]));
    const isAlgorithmNameChanged =
      algorithmchange === "" ? algorithmData?.name : algorithmchange;
    const isAlogrithmChanged = algorithmData?.name !== isAlgorithmNameChanged;
    if (showCreateAlgorithms) {
      !isAlogrithmChanged && delete data?.algorithm[0]?.name;
      dispatch(editAlogrithm(algorithmId, data?.algorithm[0]));
    }
  };
  const outputArgumentsList123: Array<string> = [];
  const values = getValues();
  const outputParameters =
    values?.algorithm && values?.algorithm[0]?.output_parameters;
  const getoutputParametersOptions =
    Array.isArray(outputParameters) &&
    outputParameters?.map((outputParameter: any) => ({
      value: outputParameter,
      label: outputParameter,
    }));
  // const outputParametersOptions1 = Array.isArray(getoutputParametersOptions)
  //   ? getoutputParametersOptions
  //   : algorithmData?.output_parameters?.map((opt: any) => ({
  //       value: opt,
  //       label: opt,
  //     }));
  const outputParametersOptionslist = (e: any, index: any) => {
    const values = {
      value: e.target.value,
      label: e.target.value,
    };
    if (outputParametersOptions.length > index) {
      outputParametersOptions[index] = values;
    } else {
      outputParametersOptions.push(values);
    }
    setoutputParametersOptions(outputParametersOptions);
  };
  const getpresentaionvalue = (value: any) => {
    const ele: any =
      outputParametersOptions &&
      outputParametersOptions?.find((c: any) => c.value === value);
    return ele || null;
  };

  return (
    <>
      {(algorithmData || !showCreateAlgorithms) && (
        <Modal
          className="createHospital"
          isModeless={true}
          isOpen={isModalOpen}
          onDismiss={showModal}
          containerClassName={"modalStyle"}
          isBlocking={false}
        >
          <Stack
            className="createHospitalSection"
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack.Item className="createHospitalHeading">
              <Text
                variant="large"
                className="muted pointer"
                onClick={showModal}
              >
                Algorithm Management
              </Text>
              <Text variant="large" className="lessthan muted">
                &gt;
              </Text>
              <Text variant="large">
                {!showCreateAlgorithms ? "Add" : "Edit"} Algorithm
              </Text>
            </Stack.Item>
            <Stack.Item align="end">
              <IconButton
                className=""
                iconProps={cancelIcon}
                onClick={showModal}
              />
            </Stack.Item>
          </Stack>
          <form id="algorithm" onSubmit={handleSubmit(onSubmit)}>
            <Stack.Item className="algorithm ms-Grid" dir="ltr">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg10">
                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg4 ms-lgOffset2">
                      <TextField
                        autoComplete="off"
                        label="Algorithm Name*"
                        errorMessage={
                          nameChange && errorResponse?.field !== "name"
                            ? nameChange
                            : ""
                        }
                        {...register(`algorithm[0].name`)}
                        onChange={(e: any) => {
                          dispatch(removeErrorALG());
                          setalgorithmchange(e?.target?.value);
                          nameChange
                            ? setnameChange("")
                            : setnameChange(nameChange);
                        }}
                        defaultValue={algorithmData && algorithmData?.name}
                      ></TextField>
                      {errorResponse.field === "name" && (
                        <Label className="errorMessage">
                          {errorResponse?.details}
                        </Label>
                      )}
                      <TextField
                        autoComplete="off"
                        label="Description"
                        {...register(`algorithm[0].description`, {
                          // onChange: (e) => {
                          //   setValue("algorithm[0].description", e.target.value);
                          //   console.log(e,'Discription');
                          // },
                        })}
                        multiline
                        rows={3}
                        // onChange={(e) => handleValidateForm(e, "name")}
                        defaultValue={
                          algorithmData && algorithmData?.description
                        }
                      ></TextField>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg4 ms-lgOffset1">
                      <Label>Is Business Algorithm*</Label>
                      <Controller
                        control={control}
                        defaultValue={
                          algorithmData && algorithmData?.is_core_business
                        }
                        shouldUnregister
                        name={"algorithm[0].is_core_business"}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            classNamePrefix="addl-class"
                            options={options1}
                            isClearable
                            value={options1.find((c) => c.value === value)}
                            // placeholder="Yes/No"
                            onChange={(val) => {
                              onChange(val?.value);
                              setIsBussiness(val?.value);
                            }}
                          />
                        )}
                      />
                      <Label className="errorMessage">
                        {errors?.algorithm
                          ? errors?.algorithm[0]?.is_core_business?.message
                          : ""}
                      </Label>
                      {isBusiness && (
                        <>
                          <Label>Algorithm Type*</Label>
                          <Controller
                            control={control}
                            defaultValue={
                              algorithmData && algorithmData?.algorithm_type
                            }
                            shouldUnregister
                            name="algorithm[0].algorithm_type"
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                classNamePrefix="addl-class"
                                isClearable
                                options={alogrithmTypeOptions}
                                value={alogrithmTypeOptions?.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  // algorithmData.algorithm_type = val?.value;
                                  onChange(val?.value);
                                }}
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors?.algorithm
                              ? errors?.algorithm[0]?.algorithm_type?.message
                              : ""}
                          </Label>
                        </>
                      )}
                      <Label>Compute Type*</Label>
                      <Controller
                        control={control}
                        defaultValue={
                          algorithmData && algorithmData?.compute_type
                        }
                        shouldUnregister
                        {...register("algorithm[0].compute_type")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            classNamePrefix="addl-class"
                            placeholder="Select"
                            isClearable
                            options={computeTypeOptions}
                            value={computeTypeOptions?.find(
                              (c: any) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val?.value);
                            }}
                          />
                        )}
                      />
                      <Label className="errorMessage">
                        {errors?.algorithm
                          ? errors?.algorithm[0]?.compute_type?.message
                          : ""}
                      </Label>
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg4 ms-lgOffset2">
                      <Label className="addFacilityHeading">
                        Input Arguments*
                      </Label>
                      {newInputparameters &&
                        newInputparameters.map((i: any, index: any) => {
                          const fieldName = `algorithm[0].input_parameters[${index}]`;
                          return (
                            <fieldset
                              name={fieldName}
                              key={fieldName}
                              className="fieldsetInputSection"
                            >
                              <Stack className="ms-TextField">
                                <Stack className="ms-TextField-wrapper">
                                  <StackItem className="ms-TextField-fieldGroup fieldGroup-custom">
                                    <input
                                      type="text"
                                      className="ms-TextField-field field-custom"
                                      autoComplete="off"
                                      {...register(`${fieldName}`)}
                                      defaultValue={
                                        algorithmData &&
                                        algorithmData?.input_parameters &&
                                        algorithmData?.input_parameters[index]
                                      }
                                    />
                                  </StackItem>
                                  <Label className="errorMessage">
                                    {errors?.algorithm &&
                                    errors?.algorithm[0]?.input_parameters
                                      ? errors?.algorithm[0]?.input_parameters[
                                          index
                                        ]?.message
                                      : ""}
                                  </Label>
                                </Stack>
                              </Stack>
                              {/* <TextField
                              autoComplete="off"
                               errorMessage={
                                errors?.algorithm &&
                                errors?.algorithm[0]?.input_parameters
                                  ? errors?.algorithm[0]?.input_parameters[
                                      index
                                    ]?.message
                                  : ""
                              }
                              {...register(`${fieldName}`)}
                              defaultValue={
                                algorithmData &&
                                algorithmData?.input_parameters &&
                                algorithmData?.input_parameters[index]
                              }
                            ></TextField> */}
                              {index !== 0 ? (
                                <Stack.Item className="ms-Grid-col deleteSection">
                                  <IconButton
                                    iconProps={deleteIcon}
                                    aria-label="Delete"
                                    onClick={() => handleRemove(index)}
                                  />
                                </Stack.Item>
                              ) : (
                                <Stack.Item className="ms-Grid-col width-20">
                                  {" "}
                                </Stack.Item>
                              )}
                            </fieldset>
                          );
                        })}
                      <Stack
                        horizontal
                        horizontalAlign="end"
                        verticalAlign="center"
                        className="pointer addProcedure"
                      >
                        <Text onClick={addInputArguments}>
                          Add Another Field
                          <AddFacilityIcon />
                        </Text>
                      </Stack>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg4 ms-lgOffset1">
                      <Label className="addFacilityHeading">
                        Output Arguments*
                      </Label>
                      {newOutputparameters &&
                        newOutputparameters.map((i: any, index: any) => {
                          const fieldName = `algorithm[0].output_parameters[${index}]`;
                          return (
                            <fieldset
                              name={fieldName}
                              key={fieldName}
                              className="fieldsetInputSection"
                            >
                              <Stack className="ms-TextField">
                                <Stack className="ms-TextField-wrapper">
                                  <StackItem className="ms-TextField-fieldGroup fieldGroup-custom">
                                    <input
                                      type="text"
                                      className="ms-TextField-field field-custom"
                                      autoComplete="off"
                                      {...register(`${fieldName}`)}
                                      onChange={(e) => {
                                        outputParametersOptionslist(e, index);
                                        setValue(
                                          "algorithm[0].presentation_layer_mapping",
                                          ""
                                        );
                                        setOutputParametersChange(
                                          e?.target?.value
                                        );
                                        errors.algorithm[0].output_parameters[
                                          index
                                        ].message = "";
                                      }}
                                      defaultValue={
                                        algorithmData &&
                                        algorithmData?.output_parameters &&
                                        algorithmData?.output_parameters[index]
                                      }
                                    />
                                  </StackItem>
                                  <Label className="errorMessage">
                                    {errors?.algorithm &&
                                    errors?.algorithm[0]?.output_parameters
                                      ? errors?.algorithm[0]?.output_parameters[
                                          index
                                        ]?.message
                                      : ""}
                                  </Label>
                                </Stack>
                              </Stack>
                              {/* <TextField
                              autoComplete="off"
                              errorMessage={
                                errors?.algorithm &&
                                errors?.algorithm[0]?.output_parameters
                                  ? errors?.algorithm[0]?.output_parameters[
                                      index
                                    ]?.message
                                  : ""
                              }
                              {...register(`${fieldName}`)}
                              onChange={(e) =>
                                outputParametersOptionslist(e, index)
                              }
                              // onChange={(e: any) => {
                              //   // const outputArgumentsListClone = Object.assign(
                              //   //   {},
                              //   //   outputArgumentsList
                              //   // );
                              //   // outputArgumentsListClone[index] =
                              //   //   e.target.value;
                              //   // setOutputArgumentsList(
                              //   //   outputArgumentsListClone
                              //   // ); //
                              //   // console.log(outputArgumentsListClone,'outputArgumentsListClone');
                              //   // setOutputArgumentsList(e?.target.value);
                              // }}
                              defaultValue={
                                algorithmData &&
                                algorithmData?.output_parameters &&
                                algorithmData?.output_parameters[index]
                              }
                            ></TextField> */}
                              {index !== 0 ? (
                                <Stack.Item className="ms-Grid-col  deleteSection">
                                  <IconButton
                                    iconProps={deleteIcon}
                                    aria-label="Delete"
                                    onClick={() => {
                                      handleRemoveOutput(i, index);
                                    }}
                                  />
                                </Stack.Item>
                              ) : (
                                <Stack.Item className="ms-Grid-col width-20">
                                  {" "}
                                </Stack.Item>
                              )}
                            </fieldset>
                          );
                        })}
                      <Stack
                        horizontal
                        horizontalAlign="end"
                        verticalAlign="center"
                        className="pointer addProcedure"
                      >
                        <Text onClick={addOutputArguments}>
                          Add Another Field
                          <AddFacilityIcon />
                        </Text>
                      </Stack>
                      {isBusiness && (
                        <>
                          <Label>Presentation Layer Mapping*</Label>
                          <Controller
                            control={control}
                            shouldUnregister
                            defaultValue={
                              algorithmData &&
                              algorithmData?.presentation_layer_mapping
                            }
                            name="algorithm[0].presentation_layer_mapping"
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                classNamePrefix="addl-class"
                                isClearable
                                options={outputParametersOptions}
                                value={getpresentaionvalue(value)}
                                onChange={(val: any) => {
                                  // algorithmData.presentation_layer_mapping =
                                  //   val?.value;
                                  onChange(val?.value);
                                  setPresentationLayer(val?.value);
                                }}
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors?.algorithm
                              ? errors?.algorithm[0]?.presentation_layer_mapping
                                  ?.message
                              : ""}
                          </Label>
                        </>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </form>
          <Stack
            className="createHospitalWizardFooter"
            horizontal
            horizontalAlign="end"
          >
            <Stack.Item className="nextCancel">
              <PrimaryButton
                disabled={disabledCreate}
                type="submit"
                form="algorithm"
                className="btn-next"
              >
                {!showCreateAlgorithms ? "Add" : "Update"}
                <Text className="spinnerRight">
                  {disabledCreate && <Spinner size={SpinnerSize.small} />}
                </Text>
              </PrimaryButton>
              <ActionButton
                onClick={() => _alertClicked()}
                text="Cancel"
                className="btn-c"
                allowDisabledFocus
              />
            </Stack.Item>
          </Stack>
        </Modal>
      )}
    </>
  );
};
export default CreateAlgorithm;
