import { getModelVersion } from "../actions/managementAction";
import {
  GET_CONTAINERS_LIST,
  GET_MODEL_NAME,
  CREATE_CONTAINER,
  CREATE_CONTAINER_ERROR,
  DELETE_CONTAINER,
  DELETE_CONTAINER_ERROR,
  ERASE_DELETE_CONTAINER_RESPONSE,
  DELETE_MODEL,
  MODEL_VIEW,
  CREATE_MODEL,
  CREATE_MODEL_ERROR,
  DELETE_MODEL_ERROR,
  ERASE_MODEL_ERROR,
  ERASE_CONTAINER_ERROR,
  SHOW_MODEL_SPINNER,
  SHOW_CONTAINER_SPINNER,
  GET_DATASETS_BY_ID,
  GET_MODELS_BY_ALG_ID,
  GET_MODEL_NAMES_BY_ALG_ID,
  GET_MODEL_VERSION,
  GET_MODEL_COMPARISON_PARAMETERS,
  SUBMIT_MODEL_COMPARISON,
  SUBMIT_COMMENT_ERROR,
  CLEAR_COMMENT_ERROR,
  GET_FIRST_VIDEO,
  GET_SECOND_VIDEO,
  GET_FIRST_VIDEO_ANNOTATION,
  GET_SECOND_VIDEO_ANNOTATION,
  CLEAR_VIDEO_COMPARISON_DATA,
  REFRESH_DATASETS_BY_ID,
  SHOW_DATASET_SPINNER,
  GET_MODELS_SELECTED_ALGID,
  REFRESH_MODELS_SELECTED_ALGID,
  GET_VERSIONS_SELECTED_MODEL,
  ERASE_VERSIONS_DATA,
  DELETE_MODEL_DISABLE,
  COMMENTS_LOADER,
  LOADER,
  STOPLOADER,
  LOGOUT_DEVELOPER_ALERT,
  RESET_DELETE_MODEL_ERROR,
} from "./../actions/actionConstants";

const initialState: any = {
  containersList: {},
  modelNames: {},
  createContainerError: null,
  containerData: {},
  closeRoles: false,
  closeContainer: false,
  deleteContainer: null,
  deleteModel: null,
  modelById: {},
  createModelError: null,
  modelData: {},
  datasetsById: {},
  modelsByAlgId: {},
  modelVersion: {},
  modelComparisonParameters: {},
  modelComparisonData: {},
  submitCommentError: null,
  firstVideoOverlayUrls: {},
  secondVideoOverlayUrls: {},
  firstVideoAnnotations: null,
  secondVideoAnnotations: null,
  loadingDatasets: false,
  deleteModelSuccess: false,
  versionsForSelectedModel: null,
  commentsLoader: false,
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageModel: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageContainer: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  logoutDeveloperModalAlert: false,
  videoPlayButtonEnable: false,
};

export const developerManagementReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case COMMENTS_LOADER:
      return {
        ...state,
        commentsLoader: true,
      };
    case GET_CONTAINERS_LIST:
      return {
        ...state,
        containersList: action.payload?.data,
        loadingContainers: false,
      };
    case GET_MODEL_NAME:
      const currentLoadingModelsTypesList = state.modelNames?.results || [];
      const previousModelNamesTypeData = action.payload.isNextURL
        ? currentLoadingModelsTypesList || []
        : [];
      const updatedLoadingModelsType = action.payload.data;
      updatedLoadingModelsType.results = previousModelNamesTypeData.concat(
        updatedLoadingModelsType.results
      );
      return {
        ...state,
        loadingModels: false,
        modelNames: updatedLoadingModelsType,
      };

    case CREATE_CONTAINER:
      return {
        ...state,
        containerData: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.containerData?.data?.results || []),
            ],
          },
        },
        showRoleSpinner: false,
        closeContainer: true,
        createOrUpdate: action?.payload?.createOrUpdate,
      };
    case CREATE_CONTAINER_ERROR:
      return {
        ...state,
        createContainerError: action?.payload,
      };

    case ERASE_CONTAINER_ERROR:
      return {
        ...state,
        createContainerError: null,
      };

    case DELETE_CONTAINER:
      return {
        ...state,
        deleteContainer: action.payload?.data,
      };
    case DELETE_CONTAINER_ERROR:
      return {
        ...state,
        deleteContainerError: action.payload,
      };
    case ERASE_DELETE_CONTAINER_RESPONSE:
      return {
        ...state,
        deleteContainer: null,
        deleteContainerError: null,
      };

    case DELETE_MODEL:
      return {
        ...state,
        deleteModelSuccess: true,
        deleteModelError: null,
        deleteModel: action.payload?.data,
      };
    case RESET_DELETE_MODEL_ERROR:
      return {
        ...state,
        deleteModelSuccess: false,
        deleteModelError: false,
      };
    case DELETE_MODEL_DISABLE:
      return {
        ...state,
        deleteModelSuccess: action.payload,
      };

    case MODEL_VIEW:
      return {
        ...state,
        modelById: action.payload?.data,
      };

    case SHOW_MODEL_SPINNER:
      return {
        ...state,
        loadingModels: true,
        showRoleSpinner: true,
        closeRoles: false,
      };
    case SHOW_CONTAINER_SPINNER:
      return {
        ...state,
        loadingContainers: true,
        closeContainer: false,
      };

    case CREATE_MODEL:
      return {
        ...state,
        createModelError: null,
        modelData: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.modelData?.data?.results || []),
            ],
          },
        },
        showRoleSpinner: false,
        closeRoles: true,
      };
    case CREATE_MODEL_ERROR:
      return {
        ...state,
        showRoleSpinner: false,
        createModelError: action?.payload,
      };
    case ERASE_MODEL_ERROR:
      return {
        ...state,
        createModelError: null,
      };
    case DELETE_MODEL_ERROR:
      return {
        ...state,
        deleteModelSuccess: false,
        deleteModel: null,
        deleteModelError: action?.payload,
      };

    case GET_DATASETS_BY_ID:
      const previousData = state.datasetsById?.data?.results || [];
      state.datasetsById = action.payload;
      state.datasetsById.data.results = [
        ...previousData,
        ...state.datasetsById.data.results,
      ];

      return {
        ...state,
        loadingDatasets: false,
      };
    case REFRESH_DATASETS_BY_ID:
      return {
        ...state,
        loadingDatasets: false,
        datasetsById: action.payload,
      };
    case SHOW_DATASET_SPINNER:
      return {
        ...state,
        loadingDatasets: true,
      };

    case LOADER:
      if (action.payload.calledFrom === "GET_MODEL_NAME") {
        return {
          ...state,
          loaderWithMessageModel: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_CONTAINERS_LIST") {
        return {
          ...state,
          loaderWithMessageContainer: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
    case STOPLOADER:
      if (action.payload.calledFrom === "GET_MODEL_NAME") {
        return {
          ...state,
          loaderWithMessageModel: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
          closeContainer: false,
        };
      } else if (action.payload.calledFrom === "GET_CONTAINERS_LIST") {
        return {
          ...state,
          loaderWithMessageContainer: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
          closeContainer: false,
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
          closeContainer: false,
        };
      }
    case GET_MODELS_BY_ALG_ID:
      return {
        ...state,
        modelsByAlgId: action.payload?.data,
      };
    case GET_MODELS_SELECTED_ALGID:
      const modelsForSelectedAlg =
        state.modelsForSelectedAlg?.data?.results || [];
      state.modelsForSelectedAlg = action.payload;
      state.modelsForSelectedAlg.data.results = [
        ...modelsForSelectedAlg,
        ...state.modelsForSelectedAlg.data.results,
      ];
      return {
        ...state,
      };
    case REFRESH_MODELS_SELECTED_ALGID:
      return {
        ...state,
        modelsForSelectedAlg: action.payload,
      };
    case GET_VERSIONS_SELECTED_MODEL:
      return {
        ...state,
        versionsForSelectedModel: action.payload?.data,
      };
    case ERASE_VERSIONS_DATA:
      return {
        ...state,
        versionsForSelectedModel: null,
      };
    case GET_MODEL_NAMES_BY_ALG_ID:
      return {
        ...state,
        modelNamesByAlgId: action.payload?.data,
      };

    case GET_MODEL_VERSION:
      return {
        ...state,
        modelVersion: { ...state.modelVersion, ...action.payload?.data },
      };

    case GET_MODEL_COMPARISON_PARAMETERS:
      return {
        ...state,
        modelComparisonParameters: action.payload?.data,
        commentsLoader: false,
      };
    case SUBMIT_MODEL_COMPARISON:
      return {
        ...state,
        modelComparisonData: action.payload?.data,
        closeRoles: true,
      };

    case SUBMIT_COMMENT_ERROR:
      return {
        ...state,
        submitCommentError: action.payload[0].details,
      };
    case CLEAR_COMMENT_ERROR:
      return {
        ...state,
        submitCommentError: null,
      };

    case GET_FIRST_VIDEO:
      return {
        ...state,
        firstVideoOverlayUrls: action.payload,
      };

    case GET_FIRST_VIDEO_ANNOTATION:
      const data: any = action.payload.annotationData;
      // console.log("annot data");
      // console.log(data);
      const updatedData: any = state.firstVideoAnnotations
        ? {
            ...data,
            annotations: {
              ...data.annotations,
              ...state.firstVideoAnnotations["annotations"],
            },
          }
        : data;
      return {
        ...state,
        firstVideoAnnotations: updatedData,
        firstAnnotationsLength: Object.keys(updatedData.annotations).length,
        firstVideoOverlayUrls: {
          ...state.firstVideoOverlayUrls,
          [action.payload.index]: {
            url: state.firstVideoOverlayUrls[action.payload.index].url,
            downloadStatus: true,
          },
        },
      };

    case GET_SECOND_VIDEO:
      return {
        ...state,
        secondVideoOverlayUrls: action.payload,
      };

    case GET_SECOND_VIDEO_ANNOTATION:
      const secondData: any = action.payload.annotationData;
      const secondUpdatedData: any = state.secondVideoAnnotations
        ? {
            ...secondData,
            annotations: {
              ...secondData.annotations,
              ...state.secondVideoAnnotations["annotations"],
            },
          }
        : secondData;
      return {
        ...state,
        secondVideoAnnotations: secondUpdatedData,
        secondAnnotationsLength: Object.keys(secondUpdatedData.annotations)
          .length,
        secondVideoOverlayUrls: {
          ...state.secondVideoOverlayUrls,
          [action.payload.index]: {
            url: state.secondVideoOverlayUrls[action.payload.index].url,
            downloadStatus: true,
          },
        },
      };
    case CLEAR_VIDEO_COMPARISON_DATA:
      return {
        ...state,
        firstVideoAnnotations: null,
        secondVideoAnnotations: null,
        videoPlayButtonEnable: false,
      };
    case LOGOUT_DEVELOPER_ALERT:
      return {
        ...state,
        logoutDeveloperModalAlert: !state.logoutDeveloperModalAlert,
      };

    case "VIDEO_PLAY_BUTTON_ENABLE":
      return {
        ...state,
        videoPlayButtonEnable: true,
      };
    default:
      return {
        ...state,
      };
  }
};
