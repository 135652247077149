import {
  DefaultButton,
  ILabelStyles,
  Label,
  Panel,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  Icon,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppState } from "../../rootReducer";
import { getUserProfile } from "../actions/layoutActions";
import userImage from "./../../../src/assets/Home/userProfile_1.png";

const SwitchAccounts = () => {
  const loginUserProfile = useSelector((state: AppState) => {
    return state.dashboardReducerData.loginUserProfile;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);
  const navigate = useNavigate();
  const handleSwitchAccount = (role: any) => {
    localStorage.setItem("role", role);
    localStorage.setItem("panelNavigation", "true");
    navigate(`/`);
    window.location.reload();
  };

  return (
    <Stack
      verticalFill
      verticalAlign="start"
      tokens={{ childrenGap: 15, padding: "1rem 0 0 0" }}
    >
      <StackItem>
        <Text>{loginUserProfile?.mail}</Text>
      </StackItem>

      <StackItem
        className="pointer"
        onClick={() => handleSwitchAccount("ActivAdmin")}
      >
        <Stack horizontal dir="ltr" verticalAlign="center">
          <StackItem>
            <img
              src={loginUserProfile?.photo || userImage}
              alt="img-missing"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50px",
                marginRight: "10px",
              }}
            ></img>
          </StackItem>
          <StackItem>
            <Text block>{loginUserProfile?.displayName}</Text>
            <Text block>
              {/* {loginUserProfile?.permissions_name?.length > 0
                ? loginUserProfile?.permissions_name?.find(
                    (role: any) => role === "" 
                  )
                : loginUserProfile?.role_name} */}
              ActivAdmin
            </Text>
          </StackItem>
          {localStorage.getItem("role") === "ActivAdmin" && (
            <Icon
              iconName="CheckMark"
              className=" ms-fontSize-16 ms-fontWeight-bold btn-c"
            />
          )}
        </Stack>
      </StackItem>

      {loginUserProfile?.permissions_name?.length > 0 && (
        <StackItem
          className="pointer"
          onClick={() => handleSwitchAccount("Developer")}
        >
          <Stack horizontal dir="ltr" verticalAlign="center">
            <StackItem>
              <img
                src={loginUserProfile?.photo || userImage}
                alt="img-missing"
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "50px",
                  marginRight: "10px",
                }}
              ></img>
            </StackItem>
            <StackItem>
              <Text block>{loginUserProfile?.displayName}</Text>
              <Text block>
                {/* {loginUserProfile?.permissions_name?.length > 0
                  ? loginUserProfile?.permissions_name?.find(
                      (role: any) => role === "Developer"
                    )
                  : loginUserProfile?.role_name} */}
                Developer
              </Text>
            </StackItem>
            {localStorage.getItem("role") === "Developer" && (
              <Icon
                iconName="CheckMark"
                className=" ms-fontSize-16 ms-fontWeight-bold btn-c"
              />
            )}
          </Stack>
        </StackItem>
      )}
    </Stack>
  );
};
export default SwitchAccounts;
