import { FontIcon, Stack, StackItem, Text } from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../../theme";
import "./FAQ.scss";
import * as data from "../../../assets/json/HospitalFAQ/faq.json";
import { uuid } from "uuidv4";

export interface FAQsProps {}

export interface FAQsState {}

class FAQ extends React.Component<FAQsProps, FAQsState> {
  state = {
    newData: [],
    displayAnswer: false,
  };

  componentDidMount = () => {
    const newData: any = data;
    this.setState({ ...this.state, newData: newData.default });
  };

  handleShowRequiredAnswer = (id: number) => {
    const newData = this.state.newData.map((val: any) => {
      return {
        ...val,
        sectionData: val.sectionData.map((data: any) => {
          if (data.id === id) {
            return {
              ...data,
              display: !data.display,
            };
          } else {
            return { ...data, display: false };
          }
        }),
      };
    });

    this.setState({ ...this.state, newData: newData });
  };

  dangerouslySetHTML = (data: string) => {
    return { __html: data };
  };

  render() {
    return (
      <Stack
        verticalFill
        styles={{
          root: {
            minHeight: "100vh",
            maxHeight: "100%",
            minWidth: "100vh",
            maxWidth: "100%",
          },
        }}
        tokens={{ padding: "2rem 3.5rem", childrenGap: 20 }}
      >
        <Text
          variant="xLarge"
          styles={{
            root: {
              fontFamily: fonts.DINPro_Medium,
              color: ThemeColorPalette.uploadMsgTxt,
              fontWeight: "normal",
              fontSize: "24px",
            },
          }}
        >
          FAQs
        </Text>
        {this.state.newData.map((item: any, i: number) => {
          return (
            <Stack key={uuid()} tokens={{ childrenGap: 10 }}>
              <Text
                variant="mediumPlus"
                styles={{
                  root: {
                    fontFamily: fonts.DINPro_Medium,
                    color: ThemeColorPalette.uploadMsgTxt,
                  },
                }}
              >
                {item.section}
              </Text>
              {item["sectionData"].map((val: any) => (
                <Stack key={uuid()}>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    verticalAlign="center"
                    onClick={() => this.handleShowRequiredAnswer(val.id)}
                    styles={{
                      root: { cursor: "pointer", paddingBottom: "0.5rem" },
                    }}
                  >
                    <FontIcon
                      iconName={val.display ? "ChevronDown" : "ChevronRight"}
                      style={{
                        fontSize: "0.7rem",
                        color: ThemeColorPalette.uploadMsgTxt,
                      }}
                    ></FontIcon>
                    <Text
                      variant="medium"
                      styles={{
                        root: {
                          fontFamily: fonts.HelveticaNeue_Medium,
                          color: ThemeColorPalette.uploadMsgTxt,
                          opacity: "0.9",
                        },
                      }}
                    >
                      {val.question}
                    </Text>
                  </Stack>
                  <StackItem
                    styles={{
                      root: {
                        display: val.display ? "block" : "none",
                        marginLeft: "1.25rem",
                        backgroundColor: ThemeColorPalette.gridBG,
                        padding: "1rem",
                      },
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: val.answer }}></div>
                  </StackItem>
                </Stack>
              ))}
            </Stack>
          );
        })}
      </Stack>
    );
  }
}

export default FAQ;
