import React, { FormEvent, useRef } from "react";
import {
  Stack,
  TextField,
  Text,
  Label,
  StackItem,
  IconButton,
  ActionButton,
  PrimaryButton,
} from "@fluentui/react";
import ToggleCard from "../../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as AddFacilityIcon } from "../../../../../assets/Home/add_facility.svg";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useState, useCallback, useEffect } from "react";
import { AppState } from "../../../../../rootReducer";
import {
  getAlgList,
  showDatasetSpinner,
} from "../../../../DatasetMapping/actions/datasetAction";
import {
  getModelName,
  eraseModelError,
  getModelListForDropdown,
} from "../../../actions/managementAction";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import "../Models.scss";
import ShowErrorMessageBar from "../../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import AsyncCreatableSelect from "react-select/async-creatable";
import { debounce } from "lodash";

const ModelCreation: React.FC<any> = ({
  updateData,
  resetForm,
  data,
  errors: formError,
}) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [algName, setAlgName] = useState<any | null>(null);
  const [mdlName, setMdlName] = useState<any | null>(null);
  const [mdlVersion, setMdlVersion] = useState<any | null>(null);
  const [parameters, setParameters] = useState<any | string[]>([]);
  const [bolbStorage, setBlobStorage] = useState<any | string>("");
  const [configPath, setConfigPath] = useState<any | string>("");
  const [description, setDesc] = useState<any | string>("");
  const [storageType, setStorageType] = useState<string>("localStorage");
  const [configFile, setConfigFile] = useState<any | string>("");
  const [disableAlgField, setDisableAlgField] = useState<any | boolean>(true);
  const [modelFile, setModelFile] = useState<any>({});

  const dispatch = useDispatch();
  const hiddenFileInputRef: any = useRef(null);
  const hiddenConfigFileInputRef: any = useRef(null);
  const emptyData = {
    name: "",
    version: "",
    description: "",
    algorithm: null,
    model_path: "",
    parameters: [],
    config_path: "",
    localBlob: "",
    configFile: "",
  };
  const algList = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.algList;
  });

  const createModelError = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.createModelError;
  });

  const modelList = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.modelNames;
  });
  function getUniqueListBy(arr: any, key: any) {
    return (
      arr &&
      arr.filter(
        (ele: any, ind: any) =>
          ind === arr.findIndex((elem: any) => elem[key] === ele[key])
      )
    );
  }
  const modelDropDownOption =
    (modelList &&
      modelList?.results &&
      getUniqueListBy(modelList?.results, "id")?.map((model: any) => ({
        label: model?.name,
        value: model?.id,
        algorithm: model?.algorithm,
      }))) ||
    [];

  const options: IChoiceGroupOption[] = [
    { key: "localStorage", text: "Upload from Local Storage" },
    { key: "blobStorage", text: "Upload from Activ Surgical Blob Storage" },
  ];

  const handleFileClick = (event?: any) => {
    hiddenFileInputRef.current.click();
  };
  const handleConfigFileClick = (event?: any) => {
    hiddenConfigFileInputRef.current.click();
  };

  useEffect(() => {
    dispatch(eraseModelError());
    dispatch(getAlgList());
    // dispatch(getModelName());
  }, []);

  const algOptions =
    algList?.results?.map((alg: any) => ({
      label: alg?.name,
      value: alg?.id,
    })) || [];

  const [newMdlOptions, setNewMdlOptions] = useState<
    Array<{ label: string; value: string; algorithm?: string }>
  >([]);

  const clearData = () => {
    setAlgName(null);
    setMdlName(null);
    setMdlVersion(null);
    setParameters([...[]]);
    setBlobStorage(null);
    setConfigPath(null);
    setDesc(null);
    setConfigFile(null);
    setModelFile(null);
  };

  const handleScrollModelName = () => {
    if (modelList.next) {
      dispatch(getModelName(0, "", modelList.next));
    }
  };
  useEffect(() => {
    localStorage.setItem("nextModelUrl", modelList?.next);
  }, [modelList?.next]);

  const trackScrolling = () => {
    handleScrollAlg(localStorage.getItem("nextModelUrl"));
  };
  useEffect(() => {
    localStorage.setItem("nextModelUrl", modelList?.next);
  }, [modelList?.next]);

  const handleCreate = useCallback(
    (input: string) => {
      data.model_name = input;
      updateData({ ...data });
      setNewMdlOptions([
        {
          label: input,
          value: input,
        },
      ]);
    },
    [newMdlOptions, data, updateData]
  );

  const getModelNamesAsync = async (searchVal: any) => {
    const modelListAsync = await getModelListForDropdown(searchVal);
    const mdlOptions =
      modelListAsync?.results?.map((model: any) => ({
        label: model?.name,
        value: model?.id,
        algorithm: model?.algorithm,
      })) || [];
    return mdlOptions;
  };

  const loadOptionsAsync = (inputVal: string) => getModelNamesAsync(inputVal);

  const getModelNamesAsyncDebounce = useCallback(
    () => debounce(loadOptionsAsync, 1000),
    []
  );

  const handleModelFileUpload = (evt: any, data: any) => {
    if (evt.target.files[0] !== undefined) {
      setModelFile({ ...data, file: evt.target.files[0] });
      data.modelFile = evt.target.files[0];
      updateData({ ...data });
    }
  };
  const handleConfigFileUpload = (evt: any, data: any) => {
    if (evt.target.files[0] !== undefined) {
      setConfigFile({ ...data, file: evt.target.files[0] });
      data.configFile = evt.target.files[0];
      updateData({ ...data });
    }
  };

  const resetChoice = useCallback(() => {
    dispatch(eraseModelError());
  }, []);

  const handleScrollAlg = useCallback(
    debounce((evt: any) => {
      dispatch(getModelName(0, "", evt));
    }, 1000),
    []
  );
  const inputChangeHandler = (evt: any) => {
    const value = evt;
    if (value.length >= 3 || value?.length == 0) inputHandler(value);
  };
  const inputHandler = useCallback(
    debounce((value: any) => {
      dispatch(getModelName(1, value));
    }, 2000),
    []
  );

  return (
    <form id="modelCreation">
      {createModelError && (
        <ShowErrorMessageBar
          resetChoice={resetChoice}
          errorResponse={createModelError}
        ></ShowErrorMessageBar>
      )}
      {createModelError &&
        setTimeout(() => {
          resetChoice();
        }, 3000) && <></>}
      <ToggleCard heading="" index="" removeFormFields="">
        <Stack
          className="InputSection ms-Grid-row"
          style={{ overflow: "auto" }}
        >
          <Stack>
            <Stack.Item className="specialityForm">
              <StackItem>
                <Stack className="modelCreation">
                  <ChoiceGroup
                    className="ms-ChoiceFieldGroup-flexContainer ms-ChoiceField"
                    defaultSelectedKey={storageType}
                    options={options}
                    onChange={(_: any, option?: IChoiceGroupOption) => {
                      if (option) {
                        resetForm();
                        clearData();
                        data = { ...{}, ...emptyData };
                        updateData({ ...data });
                        setStorageType(option.key);
                        data.storageType = option.key;
                        updateData({ ...data });
                      }
                    }}
                    required={true}
                  />
                </Stack>
              </StackItem>
              <Stack
                horizontal
                tokens={{ childrenGap: 150 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Stack.Item className="label">
                    <Text>Model Name*</Text>
                  </Stack.Item>
                  <Controller
                    control={control}
                    name="algorithmVersion[0].algorithm_id"
                    shouldUnregister
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => {
                      return (
                        <CreatableSelect
                          classNamePrefix="addl-class"
                          isClearable
                          isSearchable
                          defaultValue={mdlName}
                          value={mdlName}
                          options={modelDropDownOption}
                          //defaultOptions={true}
                          onChange={(val: any) => {
                            setMdlName(val);
                            data.name = val?.label as string;
                            data.algorithm = val?.algorithm;
                            data.modelId = Number.isInteger(val?.value);
                            formError.name = "";
                            formError.algorithm = "";
                            updateData({ ...data });
                            if (data.name && !data.algorithm) {
                              setDisableAlgField(false);
                            } else {
                              setDisableAlgField(true);
                            }
                          }}
                          onInputChange={(e) => inputChangeHandler(e)}
                          // onMenuClose={() => {
                          //   if (modelDropDownOption.length === 0) {
                          //     dispatch(getModelName());
                          //   }
                          // }}
                          // onMenuOpen={() => {
                          //   function getNextPage() {
                          //     const da = document.getElementsByClassName(
                          //       "addl-class__menu-list"
                          //     )[0];
                          //     da &&
                          //       da.addEventListener("scroll", (e: any) => {
                          //         const bottom =
                          //           Math.round(e.target.scrollHeight) -
                          //             Math.round(e.target.scrollTop) ===
                          //           e.target.clientHeight;
                          //         if (bottom) {
                          //           // console.log("Reached bottom");
                          //           trackScrolling();
                          //         }
                          //       });
                          //   }
                          //   setTimeout(() => {
                          //     getNextPage();
                          //   }, 1000);
                          // }}
                          onMenuClose={() => {
                            if (modelDropDownOption.length === 0) {
                              dispatch(getModelName());
                            }
                          }}
                          onMenuOpen={() => {
                            function getNextPage() {
                              const da = document.getElementsByClassName(
                                "addl-class__menu-list"
                              )[0];

                              da &&
                                da.addEventListener("scroll", (e: any) => {
                                  const bottom =
                                    Math.round(e.target.scrollHeight) -
                                      Math.round(e.target.scrollTop) ===
                                    e.target.clientHeight;
                                  if (bottom) {
                                    trackScrolling();
                                  }
                                });
                            }

                            setTimeout(() => {
                              getNextPage();
                            }, 1000);
                          }}
                          onMenuScrollToBottom={(e) =>
                            handleScrollAlg(modelList?.next)
                          }
                        />
                      );
                    }}
                  />
                  <Label className="errorMessage">{formError?.name}</Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Stack.Item className="label">
                    <Text>Algorithm Mapped to*</Text>
                  </Stack.Item>
                  <Controller
                    control={control}
                    name="algorithmVersion[0].algorithm_id"
                    shouldUnregister
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => {
                      return (
                        <Select
                          classNamePrefix="addl-class"
                          isClearable
                          options={algOptions}
                          isDisabled={disableAlgField}
                          value={
                            data.algorithm &&
                            algOptions?.find(
                              (c: any) => c.value === data.algorithm
                            )
                          }
                          onChange={(val) => {
                            setAlgName(val?.value);
                            data.algorithm = val?.value as any;
                            updateData({ ...data });
                            formError.algorithm = "";
                          }}
                        />
                      );
                    }}
                  />
                  <Label className="errorMessage">{formError?.algorithm}</Label>
                </StackItem>
              </Stack>
              <Stack
                horizontal
                tokens={{ childrenGap: 150 }}
                verticalAlign="center"
                className="fieldsContainer"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    type="number"
                    label="Model Version*"
                    onChange={(e: any) => {
                      data.version = e?.target?.value;
                      updateData({ ...data });
                      formError.version = "";
                      setMdlVersion(e?.target?.value);
                    }}
                    value={mdlVersion}
                  />
                  <Label className="errorMessage">{formError?.version}</Label>
                </StackItem>
                <StackItem styles={{ root: { flex: 1 } }}>
                  <TextField
                    label="Description"
                    onChange={(e: any) => {
                      data.description = e?.target?.value;
                      updateData({ ...data });

                      setDesc(e?.target?.value);
                    }}
                    value={description}
                  />
                  <Label className="errorMessage">
                    {formError?.description}
                  </Label>
                </StackItem>
              </Stack>
              {storageType === "blobStorage" && (
                <>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 50 }}
                    verticalAlign="center"
                    className="fieldsContainer"
                  >
                    <StackItem styles={{ root: { flex: 1 } }}>
                      <TextField
                        label="Model Path*"
                        onChange={(e: any) => {
                          data.model_path = e?.target?.value;
                          updateData({ ...data });
                          formError.model_path = "";
                          setBlobStorage(e?.target?.value);
                        }}
                        value={bolbStorage}
                      />
                      <Label className="errorMessage">
                        {formError?.model_path}
                      </Label>
                    </StackItem>
                  </Stack>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 50 }}
                    verticalAlign="center"
                    className="fieldsContainer"
                  >
                    <StackItem styles={{ root: { flex: 1 } }}>
                      <TextField
                        label={disableAlgField ? "Config Path" : "Config Path*"}
                        onChange={(e: any) => {
                          data.config_path = e?.target?.value;
                          updateData({ ...data });
                          formError.config_path = "";
                          setConfigPath(e?.target?.value);
                        }}
                        value={configPath}
                        disabled={disableAlgField}
                      />
                      <Label className="errorMessage">
                        {formError?.config_path}
                      </Label>
                    </StackItem>
                  </Stack>
                </>
              )}
              <Stack
                horizontal
                tokens={{ childrenGap: 150 }}
                className="fieldsContainer ms-mt-40"
              >
                <StackItem styles={{ root: { flex: 1 } }}>
                  <Stack>
                    <Stack.Item className="label">
                      <Text> Parameters </Text>
                    </Stack.Item>
                    <StackItem styles={{ root: { flex: 1 } }}>
                      <TextField
                        onChange={(e: any) => {
                          updateData({ ...data });
                        }}
                        value="Overall Rating"
                        disabled
                      />
                    </StackItem>
                    {parameters.map((param: any, i: number) => (
                      <StackItem
                        key={i}
                        style={{ position: "relative" }}
                        styles={{ root: { flex: 1 } }}
                      >
                        <TextField
                          style={{ display: "flex", flexGrow: 1 }}
                          onChange={(e: any) => {
                            data.parameters[i] = e?.target?.value;
                            updateData({ ...data });
                            parameters[i] = e?.target?.value;
                            setParameters([...parameters]);
                          }}
                          value={param}
                        />
                        <IconButton
                          iconProps={{ iconName: "Delete" }}
                          style={{
                            position: "absolute",
                            top: "4px",
                            right: "-30px",
                          }}
                          onClick={() => {
                            parameters.splice(i, 1);
                            setParameters([...parameters]);
                          }}
                        />
                      </StackItem>
                    ))}
                    <Stack
                      horizontal
                      horizontalAlign="end"
                      verticalAlign="center"
                      className="addProcedure"
                    >
                      <Stack.Item
                        className="pointer"
                        onClick={() => {
                          setParameters([...parameters, ""]);
                        }}
                      >
                        <Text style={{ color: "#01b1ff", paddingRight: "5px" }}>
                          Add Another Field
                        </Text>
                        <AddFacilityIcon />
                      </Stack.Item>
                    </Stack>
                    <Label className="errorMessage">
                      {formError?.parameters}
                    </Label>
                  </Stack>
                </StackItem>
                {storageType === "localStorage" && (
                  <StackItem
                    styles={{ root: { flex: 1, alignSelf: "flex-start" } }}
                  >
                    <Stack>
                      <StackItem styles={{ root: { flex: 1 } }}>
                        <Stack horizontal>
                          <Stack.Item
                            onClick={handleFileClick}
                            styles={{ root: { flex: 1 } }}
                          >
                            <input
                              type="file"
                              accept=".onnx"
                              ref={hiddenFileInputRef}
                              contentEditable={false}
                              onChange={(evt) => {
                                handleModelFileUpload(evt, data);
                                formError.localBlob = "";
                              }}
                              style={{ display: "none" }}
                            />

                            <TextField
                              label="Model ONNX File*"
                              value={modelFile?.file?.name || "Select File"}
                              readOnly
                              suffix="Browse"
                              className="pointer"
                              style={{ width: "100%" }}
                            ></TextField>
                            <Label className="errorMessage">
                              {formError?.localBlob}
                            </Label>
                          </Stack.Item>
                        </Stack>
                      </StackItem>
                      <StackItem styles={{ root: { flex: 1 } }}>
                        <Stack horizontal>
                          <Stack.Item
                            onClick={handleConfigFileClick}
                            styles={{ root: { flex: 1 } }}
                          >
                            <input
                              type="file"
                              accept=".pbtxt"
                              ref={hiddenConfigFileInputRef}
                              contentEditable={false}
                              disabled={disableAlgField}
                              onChange={(evt) => {
                                handleConfigFileUpload(evt, data);
                                formError.confingfile = "";
                              }}
                              style={{ display: "none" }}
                            />

                            <TextField
                              label={
                                disableAlgField
                                  ? "Model Config File"
                                  : "Model Config File*"
                              }
                              value={configFile?.file?.name || "Select File"}
                              readOnly
                              suffix="Browse"
                              className="pointer"
                              disabled={disableAlgField}
                              style={{ width: "100%" }}
                            ></TextField>
                            <Label className="errorMessage">
                              {formError?.confingfile}
                            </Label>
                          </Stack.Item>
                        </Stack>
                      </StackItem>
                    </Stack>
                  </StackItem>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack>
      </ToggleCard>
    </form>
  );
};

export default ModelCreation;
