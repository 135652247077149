import * as allActions from "../actions/actionConstants";

const initialState = {
  recentActivitiesList: [],
  nextPage: "",
  showSpinner: true,
  showData: false,
};

export const recentActivitiesData = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_RECENT_ACTIVITIES_DATA:
      return {
        ...state,
        recentActivitiesList: [
          ...state.recentActivitiesList,
          ...action.payload.currentPageData,
        ],
        nextPage: action.payload.next,
        showSpinner:
          action.payload &&
          action.payload.currentPageData &&
          action.payload.currentPageData.length &&
          false,
        showData:
          action.payload &&
          action.payload.currentPageData &&
          action.payload.currentPageData.length &&
          true,
      };
    case allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT:
      return {
        ...state,
        recentActivitiesList: action.payload,
      };
    case allActions.CLEAR_ALL_RECENT_ACTIVITES:
      return {
        recentActivitiesList: [],
        nextPage: "",
      };

    default:
      return state;
  }
};
