import React from "react";
import {
  Stack,
  Text,
  IconButton,
  IIconProps,
  StackItem,
  Image,
} from "@fluentui/react";
import "../../../../ActiveAdminModule/Home/components/CreateHospital/CreateHospitalWizard/Conformation/Conformation.scss";
import ProfileImg from "../../../../assets/Home/profilebg.png";
import { ReactComponent as Email } from "../../../../assets/Home/email_black.svg";
import { ReactComponent as Mobile } from "../../../../assets/Home/mobile.svg";
import { useLocation, useNavigate } from "react-router-dom";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const AdminFullView = ({ id, admin }: { id: any; admin: any }) => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { photo } = state;

  const alertClicked = () => {
    navigate(`/`);
  };

  return (
    <Stack className="conformation ms-Grid" dir="ltr">
      <Stack
        className="createHospitalHeading"
        dir="ltr"
        horizontal
        horizontalAlign="space-between"
      >
        <Stack.Item>
          <Text variant="large" className="muted" onClick={alertClicked}>
            Home
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large" className="muted" onClick={alertClicked}>
            All Admins
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">Admin Profile</Text>
        </Stack.Item>

        <Stack.Item align="end">
          <IconButton
            styles={{ root: { marginRight: "15px", color: "white" } }}
            className=""
            iconProps={cancelIcon}
            onClick={alertClicked}
          />
        </Stack.Item>
      </Stack>

      <Stack
        tokens={{ childrenGap: 40 }}
        horizontal
        styles={{
          root: {
            background: `url(${ProfileImg})`,
            height: 219,
            width: "98%",
            padding: 20,
            backgroundPosition: "right",
          },
        }}
      >
        {photo ? (
          <Image
            style={{ borderRadius: "50px" }}
            src={photo}
            width="77px"
            height="70px"
            alt="user image"
          ></Image>
        ) : (
          <StackItem className="circle" style={{ textTransform: "uppercase" }}>
            {admin &&
              admin?.first_name?.charAt(0) + admin?.last_name?.charAt(0)}
          </StackItem>
        )}
        <StackItem grow={4}>
          <Stack tokens={{ childrenGap: 25 }}>
            <StackItem>
              <Stack tokens={{ childrenGap: 5 }}>
                <StackItem>{admin && admin?.displayName}</StackItem>
                <StackItem>{admin && admin?.hospital_name}</StackItem>
                <StackItem>{admin && admin?.role_name}</StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem grow={2}>
          <Stack tokens={{ childrenGap: 5 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Email />
              <StackItem>{admin && admin?.mail}</StackItem>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 14 }}>
              <Mobile />
              <StackItem
                styles={{
                  root: {
                    marginLeft: "10px",
                  },
                }}
              >
                {admin && admin?.phone_number}
              </StackItem>
            </Stack>
          </Stack>
        </StackItem>
      </Stack>
    </Stack>
  );
};

export default AdminFullView;
