import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import Conformation from "../CreateHospital/CreateHospitalWizard/Conformation/Conformation";
import { getHospitalsById } from "../../actions/homeAction";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import HospitalFullView from "./HospitalFullView";
import { useParams } from "react-router-dom";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const HospitalView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const hospitalById = useSelector((state: AppState) => {
    return state.homeReducerData.hospitalById;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessage;
  });
  useEffect(() => {
    dispatch(getHospitalsById(id));
  }, []);
  return (
    <Stack>
      {/* {!loadingState && (
        <HospitalFullView hospital={hospitalById}></HospitalFullView>
      )} */}
      <HospitalFullView hospital={hospitalById}></HospitalFullView>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
    </Stack>
  );
};
export default HospitalView;
