import {
  IStackItemStyles,
  Stack,
  StackItem,
  Text,
  Breadcrumb,
  IBreadcrumbItem,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { ThemeColorPalette } from "../../../theme";
import { withRouter } from "../../../withRouter";
import {
  getInprogressSelectedData,
  setInprogressData,
  deleteInprogressItem,
  editInprogressUploadingMetaData,
  saveEditedMetaData,
  getProcessingVideos,
  setProcessingVideoSelect,
  saveProcessingData,
  removeSelectAfterUnmount,
} from "../actions/InProgressAction";
import InprogressCard from "../components/InprogressVideoCard";
import ProcessingCard from "../components/ProcessingCard";
import ViewEditVideoData from "../components/ViewEditVideoData";
import "./InProgress.scss";

const { useState, useMemo, useEffect, useCallback } = React;
const errorObject = { largePdfFileError: "", isUploadConsentFormError: "" };

const containerStyles: IStackItemStyles = {
  root: {
    minHeight: "100%",
    maxHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
    padding: "0px",
  },
};

interface InProgressProps {
  inProgressVideosData: any;
  getInprogressVideosAction: any;
  getInprogressSelectedData: any;
  proceduresData: any;
  userData: Array<any>;
  deleteInprogressItem: any;
  userProfile: any;
  history: any;
  editInprogressUploadingMetaData: any;
  saveEditedMetaData: any;
  editedMetaDataFields: any;
  getProcessingVideos: any;
  processingData: any;
  setProcessingVideoSelect: any;
  saveProcessingData: any;
  removeSelectAfterUnmount: any;
  currentPage: any;
}

interface InProgressState {
  isSelected: boolean;
  isEditable: boolean;
  selectedVideo: any;
  shouldDelete: boolean;
  enableSave: boolean;
  deleteVideoId: string;
}

const InProgress: React.FC<InProgressProps> = (props) => {
  const { inProgressVideosData } = props;
  const [isSelected, setIsSelected] = useState<boolean>(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  // const [selectedVideo, setSelectedVideo] = useState<any>();
  const [shouldDelete, setShouldDelete] = useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [deleteVideoId, setDeleteVideoId] = useState<string>();

  useEffect(() => {
    return () => {
      props.removeSelectAfterUnmount();
      props.getProcessingVideos();
    };
  }, []);

  const handleTabChangeClick = (selectValue: string) => {
    setIsSelected(selectValue ? true : false);
    setIsEditable(false);
  };

  const handleVideoSelect = (data: any) => {
    setIsEditable(data.isSelected);
    props.getInprogressSelectedData(data);
  };

  const handleDeletePopup = (
    evt: React.MouseEvent<HTMLAnchorElement>,
    videoId?: any
  ) => {
    setShouldDelete((prev) => !prev);
    setDeleteVideoId(videoId);
    evt.stopPropagation();
  };

  const handleDelete = useCallback(() => {
    props.deleteInprogressItem(deleteVideoId);
    setShouldDelete((prev) => !prev);
  }, [deleteVideoId]);

  const handleChange = useCallback(
    (
      e: any,
      data: any,
      selectedOption?: any,
      selectedField?: string,
      selectType?: string
    ) => {
      let value, editedField;
      if (selectedField === "procedureName") {
        value = {
          ...data,
          primaryProcedureName: selectedOption.text,
          videoData: {
            ...data.videoData,
            primaryProcedureId: selectedOption.key,
          },
        };
        editedField = {
          ...props.editedMetaDataFields,
          primaryProcedureId: selectedOption.key,
        };
      } else if (selectedField === "surgeonName") {
        value = {
          ...data,
          primarySurgeonName: selectedOption.text,
          videoData: {
            ...data.videoData,
            primarySurgeonId: selectedOption.key,
          },
        };
        editedField = {
          ...props.editedMetaDataFields,
          primarySurgeonId: selectedOption.key,
        };
      } else if (selectedField === "procedureName2") {
        value = {
          ...data,
          secondaryProcedureName: selectedOption.text,
          videoData: {
            ...data.videoData,
            secondaryProcedureId: selectedOption.key,
          },
        };
        editedField = {
          ...props.editedMetaDataFields,
          secondaryProcedureId: selectedOption.key,
        };
      } else if (selectedOption === "date") {
        value = {
          ...data,
          videoData: {
            ...data.videoData,
            surgeryDate: e,
          },
        };
        editedField = {
          ...props.editedMetaDataFields,
          surgeryDate: e,
        };
      } else {
        value = {
          ...data,
          videoData: {
            ...data.videoData,
            [e.target.name]: [e.target.value],
          },
        };
        editedField = {
          ...props.editedMetaDataFields,
          [e.target.name]: [e.target.value],
        };
      }
      setEnableSave(true);
      props.editInprogressUploadingMetaData(value, editedField);
    },
    []
  );

  const handleLoadMoreProcessingVideos = (currentPage: number) => {
    props.getProcessingVideos(currentPage);
  };

  const handleSaveClick = useCallback(
    (data: any, id?: number) => {
      const save = async () => {
        if (isSelected) props.saveEditedMetaData(data);
        else {
          const response = await props.saveProcessingData(data, id);
          response === 200 && props.getProcessingVideos(props.currentPage);
        }
        data.isSelected = false;
        setIsEditable(false);
        setEnableSave(false);
        handleVideoSelect(data);
      };
      save();
    },
    [isSelected]
  );

  const manageFiles = (val: any, evt: any, data: any) => {
    setEnableSave(val);
    const editedField = {
      ...props.editedMetaDataFields,
      patientConcentForm: val ? evt.target.files[0] : null,
    };
    const value = {
      ...data,
      patientConsentForm: val ? evt.target.files[0] : null,
    };
    props.editInprogressUploadingMetaData(value, editedField);
  };

  const handlePatientConsentFormFileChange = (evt: any, data: any) => {
    errorObject.isUploadConsentFormError = "";
    errorObject.largePdfFileError = "";
    const fileTypeSupported = ["image/png", "image/PNG", "application/pdf"];
    const fileObj = evt.target.files[0];
    const fileType = fileObj.type;
    const fileSize: any = fileObj.size
      ? (fileObj.size / (1024 * 1024)).toFixed(2)
      : 0;
    if (parseFloat(fileSize) > 5) {
      errorObject.largePdfFileError = "Please Upload File less than 5 Mb";
      setTimeout(() => {
        manageFiles(false, evt, data);
      }, 1500);
    }

    if (fileTypeSupported.includes(fileType)) {
      manageFiles(true, evt, data);
    } else {
      setTimeout(() => {
        manageFiles(false, evt, data);
      }, 1500);
      errorObject.isUploadConsentFormError = "File type not supported";
    }
    return;
  };

  const handleProcessTabSelect = (id: number) => {
    props.setProcessingVideoSelect(id);
    setIsEditable(false);
    setIsSelected(false);
  };

  const selectedVideo = useMemo(() => {
    return props.inProgressVideosData && isSelected
      ? props.inProgressVideosData.filter((video: any) => video.isSelected)
      : props.processingData &&
          props.processingData.count &&
          props.processingData.results.filter((video: any) => video.isSelected);
  }, [isSelected, props]);

  const items: IBreadcrumbItem[] = [
    {
      text: "Home",
      key: "Home",
      onClick: () => props.history("/"),
    },
    {
      text: "In Progress Videos",
      key: "f11",
      isCurrentItem: true,
    },
  ];

  return (
    <Stack
      verticalFill
      styles={containerStyles}
      tokens={{ padding: "1rem 3rem", childrenGap: 20 }}
    >
      <Breadcrumb
        items={items}
        maxDisplayedItems={2}
        ariaLabel="Breadcrumb with items rendered as buttons"
        overflowAriaLabel="More links"
      />
      <Stack
        horizontal
        styles={{
          root: {
            flex: 1,
            backgroundColor: "#121212",
          },
        }}
      >
        <Stack verticalFill styles={{ root: { flex: 4 } }}>
          <StackItem
            styles={{
              root: {
                padding: "1.5rem",
                borderBottom: "1px solid #000000",
              },
            }}
          >
            <Text
              variant="medium"
              styles={{
                root: {
                  margin: "1rem",
                  paddingBottom: "0.6rem",
                  borderBottom: isSelected ? "2px solid #01B1FF" : "none",
                  // cursor: "pointer",
                  opacity: isSelected ? "1" : "0.4",
                  textAlign: "center",
                },
              }}
              // onClick={() => handleTabChangeClick("true")}
            >
              Uploading - ({inProgressVideosData.length})
            </Text>
            {/* <Text
              variant="medium"
              styles={{
                root: {
                  margin: "1rem",
                  paddingBottom: "0.6rem",
                  borderBottom: !isSelected ? "2px solid #01B1FF" : "none",
                  opacity: !isSelected ? "1" : "0.4",
                  cursor: "pointer",
                },
              }}
              onClick={() => handleTabChangeClick("")}
            >
              Processing - (
              {props.processingData && props.processingData.count
                ? props.processingData.count
                : 0}
              )
            </Text> */}
          </StackItem>
          <Stack
            styles={{
              root: {
                padding: "2rem 0",
                height: "80vh",
              },
            }}
            horizontalAlign="center"
          >
            {inProgressVideosData && inProgressVideosData.length ? (
              <InprogressCard
                inProgressVideosData={inProgressVideosData}
                handleVideoSelect={handleVideoSelect}
                handleDeletePopup={handleDeletePopup}
                handleDelete={handleDelete}
                shouldDelete={shouldDelete}
              />
            ) : (
              <Text
                styles={{
                  root: {
                    color: ThemeColorPalette.uploadMsgTxt,
                    opacity: 0.1,
                    marginTop: "15rem",
                  },
                }}
              >
                No Inprogress Data
              </Text>
            )}
            {/* { : props.processingData && props.processingData.count ? (
              <ProcessingCard
                processingData={props.processingData}
                handleProcessTabSelect={handleProcessTabSelect}
                handleLoadMoreProcessingVideos={handleLoadMoreProcessingVideos}
                currentPage={props.currentPage}
              />
            ) : (
              <Text
                styles={{
                  root: {
                    color: ThemeColorPalette.uploadMsgTxt,
                    opacity: 0.1,
                    marginTop: "15rem",
                  },
                }}
              >
                No Processing Data
              </Text>
            )} */}
          </Stack>
        </Stack>
        {isSelected ? (
          <ViewEditVideoData
            inProgressVideosData={selectedVideo[0]}
            isEditable={isEditable}
            handleChange={handleChange}
            handlePatientConsentFormFileChange={
              handlePatientConsentFormFileChange
            }
            proceduresData={props.proceduresData}
            userData={props.userData}
            enableSave={enableSave}
            handleSaveClick={handleSaveClick}
            handleEdit={() => {
              setIsEditable((prev) => !prev);
            }}
            userProfile={props.userProfile}
            isSelected={isSelected}
            screen={"inprogressUpdateScreen"}
            errorObject={errorObject}
          />
        ) : (
          <ViewEditVideoData
            inProgressVideosData={
              selectedVideo && selectedVideo.length && selectedVideo[0]
            }
            isEditable={isEditable}
            handleChange={handleChange}
            handlePatientConsentFormFileChange={
              handlePatientConsentFormFileChange
            }
            proceduresData={props.proceduresData}
            userData={props.userData}
            enableSave={enableSave}
            handleSaveClick={handleSaveClick}
            handleEdit={() => {
              setIsEditable((prev) => !prev);
            }}
            userProfile={props.userProfile}
            isSelected={isSelected}
            screen={"processing"}
            errorObject={errorObject}
          />
        )}
      </Stack>
    </Stack>
  );
};

const mapStateToProps = (state: any) => {
  return {
    processingData: state.inProgressReducerData.processingData,
    inProgressVideosData: state.inProgressReducerData.inProgressVideosData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
    editedMetaDataFields: state.inProgressReducerData.editedMetaDataFields,
    currentPage: state.inProgressReducerData.currentPage,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProcessingVideos: (currentPage: number) =>
      dispatch(getProcessingVideos(currentPage)),
    setProcessingVideoSelect: (videoId: number) =>
      dispatch(setProcessingVideoSelect(videoId)),

    getInprogressVideosAction: (data: any) => dispatch(setInprogressData(data)),
    getInprogressSelectedData: (data: any) =>
      dispatch(getInprogressSelectedData(data)),

    deleteInprogressItem: (videoId: any) =>
      dispatch(deleteInprogressItem(videoId)),
    editInprogressUploadingMetaData: (data: any, editedField: any) =>
      dispatch(editInprogressUploadingMetaData(data, editedField)),
    saveEditedMetaData: (data: any) => dispatch(saveEditedMetaData(data)),
    // saveEditedMetaData: () => dispatch(saveEditedMetaData()),

    saveProcessingData: (editedData: any, id: number) =>
      dispatch(saveProcessingData(editedData, id)),

    removeSelectAfterUnmount: () => dispatch(removeSelectAfterUnmount()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InProgress)
);
