import React, { FormEvent } from "react";
import {
  Stack,
  TextField,
  Label,
  StackItem,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
} from "@fluentui/react";
import ToggleCard from "../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getAlgList,
  getDataSets,
  showDeleteWarningMessageBar,
  hideDeleteWarningMessageBar,
  getAllAlgorithamsListBaseOnDataSet,
  showLoaderWithMessage,
} from "../../actions/datasetAction";
import { useState, useEffect } from "react";
import { AppState } from "../../../../rootReducer";
import DeleteWarningMessageBar from "./DeleteWarningMessageBar";
import Loader from "../../../Management/components/common/loader";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
import * as constants from "../../../../Layout/constants/constants";
import { title } from "process";

const EditDataSetForm: React.FC<any> = ({
  updateData,
  data,
  errors: formError,
}) => {
  const {
    control,
    formState: { errors },
  } = useForm();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [dataSetName, setDataSetName] = useState<string>();
  const [deleteDatasetPermission, setDeleteDatasetPermission] =
    useState<any>(false);

  const dispatch = useDispatch();

  const algList = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.algList;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.loaderWithMessage;
  });
  const allSelectedAlgorithamsList = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.allAlgorithamsListBaseOnDataset;
  });

  const enableDeleteWarningMessageBar = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.enableDeleteWarningMessageBar;
  });

  useEffect(() => {
    dispatch(getAllAlgorithamsListBaseOnDataSet(data?.dataset_uuid));
    dispatch(getAlgList());
    dispatch(getDataSets());
    data.algorithms[0] > 0
      ? dispatch(hideDeleteWarningMessageBar())
      : dispatch(showDeleteWarningMessageBar());
    setDataSetName(data.dataset_name);
    setSelectedKeys([data.algorithms[0]]);
  }, []);
  useEffect(() => {
    const permissionDeleteDataset = constants.getPermissionsbyItem(
      "algorithm.datasetmapping.delete"
    );
    setDeleteDatasetPermission(permissionDeleteDataset);
    const ids: any = [];
    allSelectedAlgorithamsList &&
      allSelectedAlgorithamsList.filter((algo: any) => {
        ids.push(algo.algorithmId);
      });
    setSelectedKeys(ids);
  }, [allSelectedAlgorithamsList]);

  const algOptions = algList?.results?.map((alg: any) => ({
    key: alg?.id,
    text: alg?.name,
  }));

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const renderDataSetNameField = () => {
    return (
      <Stack.Item className="ms-Grid-row">
        <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3">
          <StackItem styles={{ root: { flex: 1 } }}>
            <TextField
              styles={{ root: { width: "310px" } }}
              label="Dataset Name*"
              value={dataSetName}
              disabled
            />
            <Label className="errorMessage">{formError?.dataset_name}</Label>
          </StackItem>
        </Stack.Item>
      </Stack.Item>
    );
  };

  const renderMapAlgorithmsField = () => {
    return (
      <Stack.Item className="ms-Grid-row">
        <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3">
          <StackItem styles={{ root: { flex: 1 } }}>
            <Label>Map Algorithm*</Label>
            <Controller
              control={control}
              defaultValue=""
              shouldUnregister
              name={"workflow.workflow_procedure_type"}
              render={({ field: { onChange } }) => (
                <Dropdown
                  placeholder="Select from the list"
                  onChange={(evt: FormEvent, option?: IDropdownOption) => {
                    const value: any = option;

                    const valuesSelected = value.selected
                      ? [...selectedKeys, value.key as string]
                      : selectedKeys.filter((key) => key !== value.key);

                    setSelectedKeys(valuesSelected);
                    onChange(valuesSelected);
                    valuesSelected.length > 0
                      ? dispatch(hideDeleteWarningMessageBar())
                      : dispatch(showDeleteWarningMessageBar());
                    updateData((prev: any) => ({
                      ...prev,
                      algorithms: valuesSelected,
                    }));
                  }}
                  multiSelect
                  options={algOptions}
                  styles={dropdownStyles}
                  defaultSelectedKeys={selectedKeys}
                />
              )}
            />
            <Label className="errorMessage">{formError?.algorithms}</Label>
          </StackItem>
        </Stack.Item>
      </Stack.Item>
    );
  };

  const renderDeleteWarningMessageBar = () => {
    const deleteElementText = document.querySelector(
      ".createHospital .ms-Button--primary > span"
    );
    if (!deleteDatasetPermission) {
      if (enableDeleteWarningMessageBar) {
        if (deleteElementText) deleteElementText.innerHTML = "Delete";
        const buttonElement: any = document.getElementsByClassName("btn-next");
        if (buttonElement && buttonElement.length && !deleteDatasetPermission) {
          buttonElement[0].classList =
            "disable-opration ms-Button ms-Button--primary btn-next root-349";
          buttonElement[0].setAttribute("title", "You do not have permission");
        }
      } else {
        if (deleteElementText) deleteElementText.innerHTML = "Update";
        const buttonElement: any = document.getElementsByClassName("btn-next");
        if (buttonElement && buttonElement.length) {
          buttonElement[0].classList = "ms-Button-custom btn-next";
          buttonElement[0].removeAttribute("title");
        }
      }
    } else {
      if (enableDeleteWarningMessageBar) {
        if (deleteElementText) deleteElementText.innerHTML = "Delete";
        // let buttonElement: any = document.getElementsByClassName("btn-next");
        // if (buttonElement && buttonElement.length && !deleteDatasetPermission) {
        //   buttonElement[0].classList =
        //     "disable-opration ms-Button ms-Button--primary btn-next root-349";
        //   buttonElement[0].setAttribute("title", "You don't have permission");
        // }
      } else {
        if (deleteElementText) deleteElementText.innerHTML = "Update";
        // let buttonElement: any = document.getElementsByClassName("btn-next");
        // if (buttonElement && buttonElement.length) {
        //   buttonElement[0].classList = "ms-Button-custom btn-next";
        //   buttonElement[0].removeAttribute("title");
        // }
      }
    }
    return enableDeleteWarningMessageBar && <DeleteWarningMessageBar />;
  };

  return (
    <form id="surgeonForm">
      <ToggleCard heading="" index="" removeFormFields="">
        <LoaderWithMessage
          loaderWithMessage={loaderWithMessage}
        ></LoaderWithMessage>
        <Stack
          className="InputSection ms-Grid-row"
          tokens={{ maxHeight: 420 }}
          style={{ overflow: "auto" }}
        >
          {renderDeleteWarningMessageBar()}
          <Stack.Item className="ms-Grid-row" style={{ marginTop: "35px" }}>
            <Stack.Item className="ms-Grid-col ms-lg6">
              {renderDataSetNameField()}
            </Stack.Item>
            <Stack.Item className="ms-Grid-col ms-lg6">
              {renderMapAlgorithmsField()}
            </Stack.Item>
          </Stack.Item>
        </Stack>
      </ToggleCard>
    </form>
  );
};

export default EditDataSetForm;
