import * as allActions from "../actions/actionConstants";

const initialState: any = {
  recentActivitiesList: [],
  nextPage: "",
  showSpinner: true,
  showData: false,
  hospitalId: {},
  facilities: [],
  activities: [],
  activityTypes: [],
  activitiesBy: [],
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
};

export const developerActivitiesData = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_RECENT_ACTIVITIES_DATA:
      return {
        ...state,
        recentActivitiesList: [
          ...state.recentActivitiesList,
          ...action.payload.currentPageData,
        ],
        nextPage: action.payload.next,
        showSpinner:
          action.payload &&
          action.payload.currentPageData &&
          action.payload.currentPageData.length &&
          false,
        showData:
          action.payload &&
          action.payload.currentPageData &&
          action.payload.currentPageData.length &&
          true,
      };
    case allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT:
      return {
        ...state,
        recentActivitiesList: action.payload,
      };
    case allActions.CLEAR_ALL_RECENT_ACTIVITES:
      return {
        recentActivitiesList: [],
        nextPage: "",
      };

    case allActions.GET_HOSPITAL_ID:
      return {
        ...state,
        hospitalId: action.payload?.data?.results[0].id,
      };

    case allActions.GET_LOG_FACILITIES:
      return {
        ...state,
        facilities: action.payload,
      };
    case allActions.GET_ACTIVITY:
      return {
        ...state,
        activities: action.payload,
      };
    case allActions.REFRESH_ACTIVITY_TYPES:
      return {
        ...state,
        activityTypes: action.payload,
      };
    case allActions.GET_ACTIVITY_TYPES:
      const currentAcivitiesTypesList =
        state.activityTypes?.data?.results || [];
      const previousActivitiesTypeData = action.payload.isNext
        ? currentAcivitiesTypesList || []
        : [];
      const updatedActivitiesType = action.payload.data;
      updatedActivitiesType.results = previousActivitiesTypeData.concat(
        updatedActivitiesType.results
      );
      return {
        ...state,
        activityTypes: updatedActivitiesType,
      };

    case allActions.REFRESH_ACTIVITY_BY:
      return {
        ...state,
        activitiesBy: action.payload,
      };
    case allActions.RESET_ACTIVITY:
      return {
        ...state,
        activityTypes: [],
        activitiesBy: [],
        activities: [],
      };

    case allActions.GET_ACTIVITY_BY:
      const activitiesByData = state.activitiesBy?.data?.results || [];
      state.activitiesBy = action.payload;
      state.activitiesBy.data.results = [
        ...activitiesByData,
        ...state.activitiesBy.data.results,
      ];
      return {
        ...state,
      };
    case allActions.LOADER:
      return {
        ...state,
        loaderWithMessage: {
          loadingState: true,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
        },
      };
    case allActions.STOPLOADER:
      return {
        ...state,
        loaderWithMessage: {
          loadingState: false,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
          statusCode: action.payload?.statusCode,
        },
      };
    default:
      return state;
  }
};
