import * as allActions from "../actions/actionConstant";

const initialState: any = {
  inProgressVideosData: [],
  editedMetaDataFields: {},
  processingData: {},
  currentPage: "",
};

export const inProgressReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.CLEAR_EDITED_METADATA_AFTER_SAVE:
      return {
        ...state,
        editedMetaDataFields: {},
      };
    case allActions.GET_PROCESSING_VIDEOS:
      return {
        ...state,
        processingData:
          state.processingData.count &&
          state.processingData.next !== action.payload.data.next &&
          (action.payload.data.next === null ||
            !action.payload.data.next.includes("2"))
            ? {
                ...action.payload.data,
                results: [
                  ...state.processingData.results,
                  ...action.payload.data.results,
                ],
              }
            : action.payload.data,
        currentPage: action.payload.currentPage,
      };
    case allActions.SET_PROCESSING_VIDEO_SELECT:
      return {
        ...state,
        processingData: {
          ...state.processingData,
          results: state.processingData.results.map((data: any) => {
            if (data.id === action.payload) {
              return {
                ...data,
                isSelected: !data.isSelected,
              };
            } else {
              return { ...data, isSelected: false };
            }
          }),
        },
      };
    case allActions.SET_INPROGRESS_VIDEOS:
      return {
        ...state,
        inProgressVideosData: state.inProgressVideosData.concat(action.payload),
      };
    case allActions.FILE_UPLOADING_DETAILS:
      return {
        ...state,
        inProgressVideosData: state.inProgressVideosData.map((data: any) => {
          if (
            data.id === action.payload.id &&
            action.payload.percentComplete &&
            action.payload.bytesUploaded &&
            action.payload.error === ""
          ) {
            return {
              ...data,
              percentageComplete: action.payload.percentComplete,
              fileUploadedSize: action.payload.bytesUploaded,
              error: "",
            };
          } else if (
            data.id === action.payload.id &&
            action.payload.status === "error"
          ) {
            return {
              ...data,
              error:
                "Something went wrong! Please refresh the screen or try uploading again",
            };
          } else {
            return { ...data, error: "" };
          }
        }),
      };
    case allActions.GET_INPROGRESS_SELECTED_DATA:
      return {
        ...state,
        inProgressVideosData: state.inProgressVideosData.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          } else {
            return { ...item, isSelected: false };
          }
        }),
      };
    case allActions.SET_INPROGRESS_VIDEOS_LIST_AFTER_REFRESH:
      return {
        ...state,
        inProgressVideosData: state.inProgressVideosData.concat(action.payload),
      };
    case allActions.DELETE_INPROGRESS_ITEM:
      return {
        ...state,
        inProgressVideosData: state.inProgressVideosData.filter(
          (data: any) => data.id !== action.payload
        ),
      };
    case allActions.EDIT_INPROGRESS_UPLOADING_META_DATA:
      return {
        ...state,
        inProgressVideosData: state.inProgressVideosData.map((data: any) => {
          if (data.id === action.payload.data.id) {
            return {
              ...action.payload.data,
            };
          } else {
            return {
              ...data,
            };
          }
        }),
        editedMetaDataFields: {
          ...state.editedMetaDataFields,
          ...action.payload.editedFields,
        },
      };
    case allActions.UNSELECT_SELECTED_INPROGRESS_DATA:
      return {
        ...state,
        inProgressVideosData: action.payload.updatedInprogressData,
        processingData: action.payload.updatedProcessingData,
      };
    case allActions.CLEAR_INPROGRESS_DATA_ON_LOGOUT:
      return {
        inProgressVideosData: [],
        editedMetaDataFields: {},
        processingData: {},
      };

    default:
      return state;
  }
};
