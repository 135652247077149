import {
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import {
  getProcedureVideos,
  getProcedureVideosRowData,
  setSelectedItem,
  showSpinnerOnLoad,
  clearProceduresVideosDataWhenUnmount,
} from "../../actions/landingPageAction";
import { fonts, ThemeColorPalette } from "../../../theme";
import ProceduresDataCard from "../../components/ProceduresDataCard";
import ProceduresChartDisplay from "../../components/ProceduresChartDisplay";
import UploadVideoModel from "../uploadVideoModel/uploadVideoModel";
import { convertDurationOfVideoToSec, formatDate } from "../../../common/util";
import ProcedureVideoCard from "../../../common/ProcedureVideoCard/ProcedureVideoCard";
import { setLoginAlertFalse } from "../../../auth/actions/authAction";
import { withRouter } from "../../../withRouter";
import Paging from "../../../common/paging/Paging";
import { number } from "yup";
import { Superscript } from "@fluent-ui/icons";
import ProcedureVideos from "../../../common/procedureVideos/ProcedureVideos";
import {
  getVideosForProcedures,
  getHospitalAvg,
  showSpinnerForVideo,
  clearProceduresDataWhenUnmount,
} from "../../actions/proceduresAction";
import { MyProcedures_Constants } from "./Constants";
import CheckPermission from "../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../Layout/constants/constants";
export interface LandingPageProps {
  getProcedureVideos: any;
  getProcedureVideosRowData: any;
  procedureVideosData: any;
  procedureVideosRowData: any;
  procedureDateFilterType: string;
  setSelectedItem: any;
  history: any;
  location: any;
  customSelectedDates: any;
  setLoginAlertFalse: any;
  showSpinner: any;
  showSpinnerOnLoad: any;
  showSpinnerForVideo: any;
  spinnerForVideoLoad: any;
  showData: any;
  getVideosForProcedure: (
    id: number,
    page: number,
    sorting: any,
    sortingOption: any
  ) => void;
  videosDataForaProcedure: any;
  getHospitalAvg: (id: number) => void;
  hospitalAvg: any;
  clearProceduresDataWhenUnmount: any;
  clearProceduresVideosDataWhenUnmount: any;
}
export interface LandingPageState {
  isModalOpen: boolean;
  selectedData: Array<any>;
  proceduresData: any;
  proceduresDateGroupingForChartDisplay: any;
  currentProcedurePage: number;
  videosDataForaProcedure: any;
}
class LandingPage extends React.Component<LandingPageProps, LandingPageState> {
  state: any = {
    isModalOpen: false,
    selectedData: [],
    proceduresData: {},
    proceduresDateGroupingForChartDisplay: [],
    currentProcedurePage: 0,
  };
  componentDidMount() {
    this.props.showSpinnerOnLoad();
    this.props.getProcedureVideos();
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.props.clearProceduresDataWhenUnmount();
    this.props.clearProceduresVideosDataWhenUnmount();
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(this.props.customSelectedDates) !==
        JSON.stringify(prevProps.customSelectedDates) ||
      JSON.stringify(this.props.procedureVideosData) !==
        JSON.stringify(prevProps.procedureVideosData) ||
      JSON.stringify(this.props.videosDataForaProcedure) !==
        JSON.stringify(prevProps.videosDataForaProcedure) ||
      prevProps.procedureDateFilterType !== this.props.procedureDateFilterType
    ) {
      if (
        this.props.procedureDateFilterType === "customDate" &&
        !this.props.customSelectedDates?.toDate
      ) {
        return true;
      }
      let [proceduresData] = this.filterProceduresVideosData()
        ? this.filterProceduresVideosData().filter(
            (item: any) => item.isSelected
          )
        : [{}];
      if (!proceduresData) {
        [proceduresData] = this.filterProceduresVideosData()
          ? this.filterProceduresVideosData().filter(
              (item: any) => item.videosList.length
            )
          : [{}];
      }
      const proceduresDateGroupingForChartDisplay: any = [];
      proceduresData &&
        proceduresData.videosList &&
        proceduresData.videosList.forEach((data: any) => {
          if (data && data.duration && data.uploaded_date) {
            const obj = {
              x: formatDate(data.uploaded_date),
              y:
                data &&
                data.duration &&
                convertDurationOfVideoToSec(data.duration) / 60,
              videoId: data.id,
              procedureName: proceduresData && proceduresData.procedureName,
              date: formatDate(data.uploaded_date),
              tooltipDate: data.uploaded_date,
              duration: convertDurationOfVideoToSec(data.duration),
            };
            proceduresDateGroupingForChartDisplay.push(obj);
          }
        });
      proceduresDateGroupingForChartDisplay.sort(function (a: any, b: any) {
        const dateA = new Date(a.x).getTime();
        const dateB = new Date(b.x).getTime();
        return dateA > dateB ? 1 : -1;
      });
      const getFilterVideoIds: any = [];
      proceduresData &&
        proceduresData.videosList.forEach((video: any) => {
          getFilterVideoIds.push(video.id);
        });
      const videosDataForaProcedure: any = {
        ...this.props.videosDataForaProcedure,
      };
      if (Object.keys(videosDataForaProcedure).length > 0) {
        videosDataForaProcedure.results =
          videosDataForaProcedure.results.filter((video: any) => {
            return getFilterVideoIds.includes(video.id);
          });
      }
      const hospitalAvg = this.props && this.props.hospitalAvg;
      this.setState({
        ...this.state,
        proceduresData: proceduresData
          ? proceduresData
          : this.props && this.props.procedureVideosData.length
          ? this.props.procedureVideosData[0]
          : proceduresData,
        proceduresDateGroupingForChartDisplay:
          proceduresDateGroupingForChartDisplay,
        videosDataForaProcedure: videosDataForaProcedure,
        hospitalAvg: hospitalAvg,
        currentSelectedProcedureId: proceduresData
          ? proceduresData.procedureId
          : null,
      });
      if (
        proceduresData &&
        proceduresData.procedureId &&
        (proceduresData.procedureId !== this.state.currentSelectedProcedureId ||
          JSON.stringify(this.props.customSelectedDates) !==
            JSON.stringify(prevProps.customSelectedDates) ||
          prevProps.procedureDateFilterType !==
            this.props.procedureDateFilterType)
      ) {
        this.getVideoDatabasedOnFilter(proceduresData);
      }
    }
  }
  getVideoDatabasedOnFilter = (proceduresData: any) => {
    // based on date picker get video data
    const sorting = this.props.procedureDateFilterType ? true : false;
    const sortingOption =
      this.props.procedureDateFilterType == "customDate"
        ? {
            sort: "custom",
            sortType: "date",
            start_date: formatDate(this.props.customSelectedDates.fromDate),
            end_date: formatDate(this.props.customSelectedDates.toDate),
          }
        : this.props.procedureDateFilterType == "last1Month"
        ? { sort: "1m", sortType: "month" }
        : this.props.procedureDateFilterType == "last3Months"
        ? { sort: "3m", sortType: "month" }
        : this.props.procedureDateFilterType == "last6Months"
        ? { sort: "6m", sortType: "month" }
        : { sort: "1y", sortType: "month" };
    this.props.showSpinnerForVideo();
    this.props.getVideosForProcedure(
      proceduresData.procedureId,
      1,
      sorting,
      sortingOption
    ); // get video data based on date
    this.props.getHospitalAvg(proceduresData.procedureId); // get the hospital average
  };
  handleProcedureClick = (data: any) => {
    this.props.setSelectedItem(data);
  };
  deleteVideoSuccess = (val: any) => {
    if (val) {
      this.props.showSpinnerOnLoad();
      this.props.getProcedureVideos();
      window.scrollTo(0, 0);
    }
  };
  handleUpload = (event: React.MouseEvent<HTMLAnchorElement>): any => {
    event.preventDefault();
    this.props.setLoginAlertFalse();
    this.setState({ isModalOpen: true });
  };
  hideModal = (ev?: React.MouseEvent<HTMLButtonElement>): any => {
    this.setState({ isModalOpen: false });
  };
  handleViewAll = (data: any) => {
    this.props.history({
      pathname: "/procedures/" + data.procedureId,
    });
  };
  onProcedureViewChange = (pageNo: number) => {
    this.setState({ currentProcedurePage: pageNo });
    this.props.getProcedureVideosRowData(pageNo);
  };
  filterProceduresVideosData = (): any => {
    const selectedDateFilter: string = this.props.procedureDateFilterType;
    const { procedureVideosData } = this.props;
    procedureVideosData.map((videoItem: any) => {
      videoItem.videosList = videoItem.videosList.filter(
        (video: any) => video.duration && video.uploaded_date
      );
      videoItem.videoCount = videoItem.videosList.length;
    });
    let startDate: Date;
    let endDate: Date;
    let data: any;
    switch (selectedDateFilter) {
      case "last1Month":
        startDate = new Date();
        endDate = new Date(startDate.setDate(startDate.getDate() - 30));
        data = this.filterProceduresDataBasedOnDateRange(endDate);
        data = data.sort(
          (a: any, b: any) => b.videosList.length - a.videosList.length
        );
        return data;
      case "last3Months":
        startDate = new Date();
        endDate = new Date(startDate.setDate(startDate.getDate() - 60));
        data = this.filterProceduresDataBasedOnDateRange(endDate);
        data = data.sort(
          (a: any, b: any) => b.videosList.length - a.videosList.length
        );
        return data;
      case "last6Months":
        startDate = new Date();
        endDate = new Date(startDate.setDate(startDate.getDate() - 180));
        data = this.filterProceduresDataBasedOnDateRange(endDate);
        data = data.sort(
          (a: any, b: any) => b.videosList.length - a.videosList.length
        );
        return data;
      case "last1Year":
        startDate = new Date();
        endDate = new Date(startDate.setDate(startDate.getDate() - 365));
        data = this.filterProceduresDataBasedOnDateRange(endDate);
        data = data.sort(
          (a: any, b: any) => b.videosList.length - a.videosList.length
        );
        return data;
      case "customDate":
        startDate = new Date();
        data = this.filterProceduresDataBasedOnDateRange(
          "",
          selectedDateFilter
        );
        data = data.sort(
          (a: any, b: any) => b.videosList.length - a.videosList.length
        );
        return data;
      default:
        return procedureVideosData;
    }
  };
  filterProceduresDataBasedOnDateRange = (endDate?: any, type?: string) => {
    const { procedureVideosData, customSelectedDates } = this.props;
    const count = 0;
    return (
      procedureVideosData &&
      procedureVideosData.map((item: any) => {
        return {
          ...item,
          videosList: item.videosList.filter((videoData: any) => {
            const videoDate = new Date(videoData.uploaded_date);
            if (type !== "customDate")
              return (
                new Date(endDate).getTime() <= new Date(videoDate).getTime()
              );
            else {
              const fromDate = new Date(customSelectedDates.fromDate);
              const toDate = new Date(customSelectedDates.toDate);
              return (
                (formatDate(fromDate) == formatDate(videoDate) &&
                  formatDate(toDate) == formatDate(videoDate)) ||
                (formatDate(fromDate) <= formatDate(videoDate) &&
                  formatDate(toDate) >= formatDate(videoDate))
              );
            }
          }),
        };
      })
    );
  };
  render() {
    const { videosDataForaProcedure } = this.props;
    const {
      isModalOpen,
      proceduresData,
      proceduresDateGroupingForChartDisplay,
      //videosDataForaProcedure,
      hospitalAvg,
    } = this.state;
    const hasVideos = videosDataForaProcedure?.results?.length;
    return (
      <Stack
        verticalFill
        styles={{
          root: {
            minHeight: "100vh",
            maxHeight: "100%",
            minWidth: "100vh",
            maxWidth: "100%",
          },
        }}
        tokens={{ padding: "2rem 2rem", childrenGap: 20 }}
      >
        <StackItem styles={{ root: { marginBottom: "1rem" } }}>
          <Stack horizontal horizontalAlign="space-between">
            <StackItem align="center">
              <Text
                variant="xLarge"
                styles={{
                  root: {
                    fontFamily: fonts.DINPro_Medium,
                    color: ThemeColorPalette.uploadMsgTxt,
                    fontWeight: "normal",
                    fontSize: "24px",
                  },
                }}
              >
                Procedures
              </Text>
            </StackItem>
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              horizontalAlign="center"
            >
              <StackItem align="center">
                <CheckPermission {...SCOPES.VIDEO_UPLOAD}>
                  <PrimaryButton
                    type="submit"
                    styles={{
                      root: {
                        borderRadius: "1rem",
                        fontFamily: fonts.HelveticaNeue_Bold,
                      },
                    }}
                    text="Upload Video"
                    iconProps={{
                      iconName: "CloudUpload",
                      styles: { root: { fontWeight: "Bold" } },
                    }}
                    onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                      this.handleUpload(evt)
                    }
                  ></PrimaryButton>
                </CheckPermission>
                <UploadVideoModel
                  isModalOpen={isModalOpen}
                  hideModal={this.hideModal}
                ></UploadVideoModel>
              </StackItem>
            </Stack>
          </Stack>
        </StackItem>
        <StackItem
          styles={{
            root: {
              backgroundColor: ThemeColorPalette.metrixbg,
              padding: "3rem 1rem",
            },
          }}
        >
          <Stack
            horizontal
            horizontalAlign="center"
            styles={{ root: { boxSizing: "border-box" } }}
            tokens={{ childrenGap: 30 }}
            className="fl-layout"
          >
            <StackItem align="start" styles={{ root: { flex: 1, width: 527 } }}>
              {proceduresData && proceduresData.procedureId ? (
                <ProceduresDataCard
                  data={this.filterProceduresVideosData()}
                  handleProcedureClick={this.handleProcedureClick}
                  showData={this.props.showData}
                  showSpinner={this.props.showSpinner}
                />
              ) : (
                <Stack
                  styles={{
                    root: {
                      minHeight: "42vh",
                      maxHeight: "42%",
                      minWidth: "75vh",
                      maxWidth: "75vh",
                      backgroundColor: ThemeColorPalette.gridBG,
                      boxShadow: "0 8px 14px 5px rgba(0,0,0,0.14)",
                      padding: "1.5rem",
                      flex: 1,
                    },
                  }}
                  verticalFill
                  verticalAlign="center"
                >
                  {this.props.showSpinner ? (
                    <Stack horizontal horizontalAlign="center">
                      <Spinner size={SpinnerSize.large} />
                    </Stack>
                  ) : (
                    typeof hasVideos === "undefined" &&
                    !hasVideos &&
                    proceduresData &&
                    proceduresData.length && (
                      <Stack horizontal horizontalAlign="center">
                        <Text> </Text>
                      </Stack>
                    )
                  )}
                </Stack>
              )}
            </StackItem>
            <StackItem styles={{ root: { flex: 1, width: 527 } }}>
              {proceduresData &&
              proceduresDateGroupingForChartDisplay &&
              proceduresDateGroupingForChartDisplay?.length ? (
                <ProceduresChartDisplay
                  data={proceduresData}
                  date={proceduresDateGroupingForChartDisplay}
                  selectedDateFilter={this.props.procedureDateFilterType}
                  location={this.props.location}
                  history={this.props.history}
                  showSpinner={this.props.showSpinner}
                  hospitalAvg={this.props.hospitalAvg}
                />
              ) : (
                <Stack
                  styles={{
                    root: {
                      minHeight: "42vh",
                      maxHeight: "320px",
                      minWidth: "75vh",
                      maxWidth: "75vh",
                      backgroundColor: ThemeColorPalette.gridBG,
                      boxShadow: "0 8px 14px 5px rgba(0,0,0,0.14)",
                      padding: "1.5rem",
                      flex: 1,
                    },
                  }}
                  verticalFill
                  verticalAlign="center"
                >
                  {this.props.showSpinner ? (
                    <Stack horizontal horizontalAlign="center">
                      <Spinner size={SpinnerSize.large} />
                    </Stack>
                  ) : (
                    proceduresDateGroupingForChartDisplay?.length === 0 &&
                    proceduresData &&
                    !this.props.showSpinner && (
                      <Stack horizontal horizontalAlign="center">
                        <Text>No videos available</Text>
                      </Stack>
                    )
                  )}
                </Stack>
              )}
            </StackItem>
          </Stack>
          <StackItem>
            {proceduresData && (
              <Stack
                verticalFill
                verticalAlign="start"
                styles={{
                  root: {
                    padding: "2rem 0 1rem 0rem",
                    borderBottom: "2px solid " + ThemeColorPalette.gridBG,
                  },
                }}
              >
                <Text
                  className="textEclips"
                  styles={{ root: { padding: "0 0 0.5rem 1.1rem" } }}
                  variant="large"
                >
                  {proceduresData.procedureName}
                </Text>
                <Stack
                  horizontal
                  wrap
                  verticalFill
                  tokens={{ childrenGap: 10 }}
                  className="allVideoStackContainer"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="parentGrid" style={{ width: "100%" }}>
                    <div className="childGrid">
                      {hasVideos &&
                      !this.props.spinnerForVideoLoad &&
                      !this.props.showSpinner ? (
                        videosDataForaProcedure?.results
                          .slice(0, 4)
                          .map((data: any, i: any) => (
                            <StackItem
                              key={i}
                              className="allVideosVideoCard all-videos-card-width"
                              // style={{ flex: "1", maxWidth: "246px" }}
                            >
                              <ProcedureVideoCard
                                key={i}
                                history={this.props.history}
                                item={data}
                                screenType="myProcedures"
                                deleteVideoSuccess={this.deleteVideoSuccess}
                              />
                            </StackItem>
                          ))
                      ) : !this.props.showSpinner &&
                        !this.props.spinnerForVideoLoad &&
                        // !proceduresData.videoCount ? (
                        !hasVideos ? (
                        <Stack horizontal horizontalAlign="center">
                          <Text>No videos available</Text>
                        </Stack>
                      ) : (
                        this.props.spinnerForVideoLoad && (
                          <Stack
                            horizontal
                            horizontalAlign="center"
                            styles={{ root: { marginLeft: "200px" } }}
                          >
                            <Spinner size={SpinnerSize.large} />
                          </Stack>
                        )
                      )}
                    </div>
                  </div>
                </Stack>
                {hasVideos && (
                  <StackItem
                    align="center"
                    styles={{
                      root: { cursor: "pointer", marginTop: 10 },
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: fonts.HelveticaNeue_Bold,
                        color: ThemeColorPalette.themePrimary,
                        marginTop: 10,
                      }}
                      onClick={() => this.handleViewAll(proceduresData)}
                    >
                      {videosDataForaProcedure?.count > 4
                        ? MyProcedures_Constants?.ViewAll +
                          ` (${videosDataForaProcedure?.count})`
                        : null}
                    </Text>
                  </StackItem>
                )}
              </Stack>
            )}
          </StackItem>
        </StackItem>
      </Stack>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    procedureVideosData: state.landingPageReducer.procedureVideos,
    procedureVideosRowData: state.landingPageReducer.procedureVideosRowData,
    procedureDateFilterType: state.landingPageReducer.procedureDateFilterType,
    customSelectedDates: state.landingPageReducer.customDate,
    showSpinner: state.landingPageReducer.showSpinner,
    showData: state.landingPageReducer.showData,
    videosDataForaProcedure: state.proceduresReducerData.videosData,
    hospitalAvg: state.proceduresReducerData.hospitalAvg,
    spinnerForVideoLoad: state.proceduresReducerData.showSpinnerForVideo,
  };
};
const dispatchActionToProps = (dispatch: any) => {
  return {
    getProcedureVideos: () => dispatch(getProcedureVideos()),
    getVideosForProcedure: (
      procedureId: number,
      currentPage: number,
      sorting: any,
      sortingOption: any
    ) =>
      dispatch(
        getVideosForProcedures(procedureId, currentPage, sorting, sortingOption)
      ),
    getProcedureVideosRowData: (pageNo: number) =>
      dispatch(getProcedureVideosRowData(pageNo)),
    setSelectedItem: (data: any) => dispatch(setSelectedItem(data)),
    setLoginAlertFalse: () => dispatch(setLoginAlertFalse()),
    showSpinnerOnLoad: () => dispatch(showSpinnerOnLoad()),
    getHospitalAvg: (procedureId: number) =>
      dispatch(getHospitalAvg(procedureId)),
    showSpinnerForVideo: () => dispatch(showSpinnerForVideo()),
    clearProceduresDataWhenUnmount: () =>
      dispatch(clearProceduresDataWhenUnmount()),
    clearProceduresVideosDataWhenUnmount: () =>
      dispatch(clearProceduresVideosDataWhenUnmount()),
  };
};
export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(LandingPage)
);
