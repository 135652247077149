export const GET_PROCESSING_VIDEOS = "GET_PROCESSING_VIDEOS";
export const SET_PROCESSING_VIDEO_SELECT = "PROCESSING_VIDEO_SELECT";

export const SET_INPROGRESS_VIDEOS = "SET_INPROGRESS_VIDEOS";
export const FILE_UPLOADING_DETAILS = "FILE_UPLOADING_DETAILS";
export const GET_INPROGRESS_SELECTED_DATA = "GET_INPROGRESS_SELECTED_DATA";
export const DELETE_INPROGRESS_ITEM = "DELETE_INPROGRESS_ITEM";
export const SET_INPROGRESS_VIDEOS_LIST_AFTER_REFRESH =
  "SET_INPROGRESS_VIDEOS_LIST_AFTER_REFRESH";
export const EDIT_INPROGRESS_UPLOADING_META_DATA =
  "EDIT_INPROGRESS_UPLOADING_META_DATA";
export const SAVE_EDITED_METADATA = "SAVE_EDITED_METADATA";

export const CLEAR_EDITED_METADATA_AFTER_SAVE =
  "CLEAR_EDITED_METADATA_AFTER_SAVE";

export const UNSELECT_SELECTED_INPROGRESS_DATA =
  "UNSELECT_SELECTED_INPROGRESS_DATA";

export const CLEAR_INPROGRESS_DATA_ON_LOGOUT =
  "CLEAR_INPROGRESS_DATA_ON_LOGOUT";
