import {
  Stack,
  StackItem,
  Image,
  Text,
  TextField,
  PrimaryButton,
  IStackProps,
  ITextStyles,
  IButtonStyles,
  ITextFieldStyles,
  DefaultButton,
  IStackTokens,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { ThemeColorPalette, fonts } from "../../../theme";
import { showLogin } from "../../actions/authAction";
import activEdgeLogo from "../../../assets/activeEdge_logo.svg";
import mailImg from "../../../assets/group.svg";
import axios from "axios";
import warningIcon from "../../../assets/warning.svg";
import { apiEndPoint } from "../../../common/restApi";
import { emailPattern } from "../../../common/util";

const rightColumnProps: Partial<IStackProps> = {
  tokens: { padding: "0 15%", childrenGap: 10 },
};

const headerTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    fontFamily: fonts.DINPro_Bold,
  },
};

const messageText: Partial<ITextStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Medium,
    color: ThemeColorPalette.neutralPrimary,
    letterSpacing: "0.3",
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    letterSpacing: "0.5px",
    fontFamily: fonts.HelveticaNeue_Medium,
    color: ThemeColorPalette.neutralPrimary,
    opacity: 0.5,
  },
};

const footerStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    opacity: "0.5",
  },
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    width: "100%",
    fontFamily: fonts.HelveticaNeue_Bold,
  },
};

const stackTokens: Partial<IStackTokens> = { childrenGap: 15 };

export interface ForgotPasswordProps {
  loginPageAction: any;
}

export interface ForgotPasswordState {
  submitClick: Boolean;
  email: string;
  errorMessage: string;
}
class ForgotPassword extends React.Component<
  ForgotPasswordProps,
  ForgotPasswordState
> {
  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = { submitClick: false, email: "", errorMessage: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ submitClick: false });
  }

  handleChange = (e: React.FormEvent<HTMLInputElement>): any => {
    const { value } = e.target as HTMLInputElement;
    value === "" && this.setState({ ...this.state, errorMessage: "" });
    this.setState({ email: value });
  };

  handleSubmit = (event: React.MouseEvent<HTMLAnchorElement>): any => {
    event.preventDefault();
    if (this.state.email.match(emailPattern)) {
      axios
        .post(apiEndPoint + "/auth/password-reset/", {
          email: this.state.email,
        })
        .then((res) => {
          this.setState({
            ...this.state,
            email: "",
            errorMessage: "",
            submitClick: this.state.errorMessage === "" && true,
          });
        })
        .catch((err) =>
          this.setState({
            ...this.state,
            errorMessage:
              err.response?.data[0]?.details || "Given email doesn't exist",
            email: "",
          })
        );
    } else {
      this.setState({
        ...this.state,
        email: "",
        errorMessage:
          "Please enter registered or valid email id for password-reset",
      });
    }
  };

  public render(): JSX.Element {
    const { submitClick, email } = this.state;
    const { loginPageAction } = this.props;
    return (
      <React.Fragment>
        {!submitClick ? (
          <Stack verticalFill {...rightColumnProps} verticalAlign="start">
            <StackItem tokens={{ padding: "40% 0% 0% 0%" }}>
              <Image
                src={activEdgeLogo}
                alt="Activ Edge logo"
                width={100}
                height={80}
              />
            </StackItem>
            <StackItem tokens={{ padding: "10% 0% 5%" }}>
              <Text styles={headerTextStyles} variant="xLarge" nowrap block>
                Forgot Password
              </Text>
            </StackItem>
            {this.state.errorMessage !== "" && (
              <Stack horizontal className="warningStyles">
                <Image src={warningIcon} alt="warning icon" />
                <Text
                  variant="smallPlus"
                  styles={{
                    root: {
                      color: ThemeColorPalette.neutralPrimary,
                      paddingLeft: "0.5rem",
                    },
                  }}
                >
                  {this.state.errorMessage}
                </Text>
              </Stack>
            )}
            <StackItem tokens={{ padding: "3% 0%" }}>
              <TextField
                inputClassName="textFieldStyles"
                styles={textFieldStyles}
                placeholder="Enter email"
                id="email"
                name="email"
                defaultValue={email}
                underlined
                onChange={(e: any) => this.handleChange(e)}
              />
            </StackItem>
            <Stack horizontal tokens={stackTokens}>
              <DefaultButton
                type="submit"
                text="Cancel"
                styles={buttonStyles}
                onClick={() => loginPageAction(true)}
              />
              <PrimaryButton
                type="submit"
                text="Submit"
                styles={buttonStyles}
                disabled={this.state.email === "" ? true : false}
                onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                  this.handleSubmit(evt)
                }
              />
            </Stack>
            <Stack verticalFill verticalAlign="end" horizontalAlign="center">
              <StackItem tokens={{ padding: "0 0 4% 0" }}>
                <Text styles={footerStyles} variant="xSmall" block nowrap>
                  Copyright © 2020 Activ Surgical - All Rights Reserved.
                </Text>
              </StackItem>
            </Stack>
          </Stack>
        ) : (
          <Stack verticalFill {...rightColumnProps} verticalAlign="start">
            <StackItem tokens={{ padding: "40% 0% 10% 0%" }}>
              <Image
                src={activEdgeLogo}
                alt="Activ Edge logo"
                width={100}
                height={80}
              />
            </StackItem>
            <StackItem>
              <Text styles={headerTextStyles} variant="xLarge" nowrap block>
                Reset Password
              </Text>
            </StackItem>
            <StackItem tokens={{ padding: "8% 0% 4%" }}>
              <Image src={mailImg} alt="Activ Edge logo" />
            </StackItem>
            <StackItem>
              <Text styles={messageText} variant="medium">
                A link to reset your password is sent to your registered
                email-id
              </Text>
            </StackItem>
            <StackItem tokens={{ padding: "3% 0% 0% 0%" }}>
              <PrimaryButton
                type="submit"
                styles={buttonStyles}
                text="Login"
                onClick={() => loginPageAction(true)}
              />
            </StackItem>
            <Stack verticalFill verticalAlign="end" horizontalAlign="center">
              <StackItem tokens={{ padding: "0 0 4% 0" }}>
                <Text styles={footerStyles} variant="xSmall" block nowrap>
                  Copyright © 2020 Activ Surgical - All Rights Reserved.
                </Text>
              </StackItem>
            </Stack>
          </Stack>
        )}
      </React.Fragment>
    );
  }
}

const dispatchActionToProps = (dispatch: any) => {
  return {
    loginPageAction: (value: boolean) => dispatch(showLogin(value)),
  };
};

export default connect(null, dispatchActionToProps)(ForgotPassword);
