import {
  FontIcon,
  IconButton,
  Modal,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import React from "react";
import RawVideoPlayer from "../../common/videoPlayer/RawVideoPlayer";
import { fonts, ThemeColorPalette } from "../../theme";
import videojs from "video.js";
import {
  getDateWithSlashInBetween,
  getTime,
  timeSince,
} from "../../common/util";
import { connect } from "react-redux";
import VideoJS from "./notificationViewVideoPlayer";
import CheckPermission from "../../common/permissions/rolePermissions";
import * as constants from "../constants/constants";

export interface NotificationActionsTabProps {
  isModalOpen: boolean;
  handleNotificationsModalOpen: any;
  dismissNotificationPanel: any;
  handleNotificationAccept: any;
  notificationData: any;
  getVideoDetailsById: any;
  procedureDetails: any;
  handleNotificationReject: any;
  videoByIdDetails?: any;
  index?: any;
  // preventActionLoader?: any;
}

export interface NotificationActionsTabState {
  hover: boolean;
  preventActionLoader: any;
}

class NotificationActionsTab extends React.Component<
  NotificationActionsTabProps,
  NotificationActionsTabState
> {
  constructor(props: NotificationActionsTabProps) {
    super(props);
    this.state = {
      hover: false,
      preventActionLoader: {},
    };
  }

  actionPerform(key: any) {
    const obj = this.state.preventActionLoader;
    obj[key] = true;
    this.setState({
      ...this.state,
      preventActionLoader: obj,
    });
    setTimeout(() => {
      const newObj = this.state.preventActionLoader;
      delete newObj[key];
      this.setState({
        ...this.state,
        preventActionLoader: newObj,
      });
    }, 3000);
  }

  componentDidMount() {
    this.props.getVideoDetailsById(this.props.notificationData.data.video_id);
  }

  render() {
    // let sentNotificationTime: any = new Date(
    //   this.props.notificationData.data["sent_at"]
    // );
    // let notificationTime: any = new Date(
    //   Date.UTC(
    //     sentNotificationTime.getFullYear(),
    //     sentNotificationTime.getMonth(),
    //     sentNotificationTime.getDate(),
    //     sentNotificationTime.getHours(),
    //     sentNotificationTime.getMinutes(),
    //     sentNotificationTime.getSeconds(),
    //     sentNotificationTime.getMilliseconds()
    //   )
    // );

    const { notificationData } = this.props;
    const videoSrc =
      notificationData?.data?.src?.dash ||
      notificationData?.data?.src?.cdn?.dash;
    return (
      <Stack
        verticalFill
        tokens={{ childrenGap: 10 }}
        styles={{
          root: {
            backgroundColor: ThemeColorPalette.pureBlack,
            padding: "1rem",
            borderRadius: "5%",
          },
        }}
      >
        <StackItem>
          <Text
            variant="smallPlus"
            styles={{ root: { fontFamily: fonts.HelveticaNeue_Bold } }}
          >
            {notificationData && notificationData.data.message}
          </Text>
        </StackItem>
        <StackItem>
          <Stack horizontal horizontalAlign="space-between">
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                variant="smallPlus"
                styles={{
                  root: {
                    fontFamily: fonts.HelveticaNeue_Medium,
                    color: ThemeColorPalette.uploadVideoBtnTxt,
                  },
                }}
              >
                Primary Procedure name:
              </Text>
              <StackItem
                styles={{
                  root: { width: "15rem", overflowWrap: "break-word" },
                }}
              >
                <Text
                  variant="small"
                  styles={{
                    root: {
                      flex: "80%",
                      fontFamily: fonts.HelveticaNeue_Bold,
                    },
                  }}
                >
                  {notificationData &&
                  notificationData.data &&
                  notificationData.data.primary_procedure_name &&
                  notificationData.data.primary_procedure_name.length > 36 ? (
                    <TooltipHost
                      content={notificationData.data.primary_procedure_name}
                    >
                      {notificationData.data.primary_procedure_name.slice(
                        0,
                        36
                      ) + "..."}
                    </TooltipHost>
                  ) : (
                    notificationData.data.primary_procedure_name
                  )}
                </Text>
              </StackItem>
            </Stack>
            <Stack
              verticalFill
              verticalAlign="center"
              styles={{
                root: { flex: "20%", paddingTop: "0.8rem", cursor: "pointer" },
              }}
              onClick={this.props.handleNotificationsModalOpen}
            >
              <StackItem align="center">
                <FontIcon
                  iconName="ChevronRight"
                  style={{ color: ThemeColorPalette.themePrimary }}
                />
              </StackItem>
              <StackItem align="center">
                <Text
                  variant="tiny"
                  styles={{
                    root: {
                      fontFamily: fonts.DINPro_Light,
                      borderBottom: this.state.hover
                        ? "1px solid " + ThemeColorPalette.themePrimary
                        : "",
                    },
                  }}
                  onMouseOver={() =>
                    this.setState({ ...this.state, hover: true })
                  }
                  onMouseOut={() =>
                    this.setState({ ...this.state, hover: false })
                  }
                  onClick={() => {
                    this.props.getVideoDetailsById(
                      notificationData.data.video_id
                    );
                  }}
                >
                  View Details
                </Text>
              </StackItem>
            </Stack>
          </Stack>
        </StackItem>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <CheckPermission {...constants.SCOPES.VIDEOS_ACCEPT}>
            <PrimaryButton
              styles={{
                root: { height: "1.6rem", color: ThemeColorPalette.pureWhite },
              }}
              onClick={() => {
                this.props.handleNotificationAccept(
                  notificationData.data.video_id,
                  notificationData.type,
                  this.props.index
                );
                this.actionPerform(this.props.index);
              }}
              disabled={this.state.preventActionLoader[this.props.index]}
            >
              Accept
            </PrimaryButton>
          </CheckPermission>
          <CheckPermission {...constants.SCOPES.VIDEOS_ACCEPT}>
            <PrimaryButton
              styles={{
                root: {
                  height: "1.6rem",
                  color: ThemeColorPalette.pureWhite,
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => {
                this.props.handleNotificationReject(
                  notificationData.data.video_id,
                  notificationData.type
                );
                this.actionPerform(this.props.index);
              }}
              disabled={this.state.preventActionLoader[this.props.index]}
            >
              Decline
            </PrimaryButton>
          </CheckPermission>
        </Stack>
        <Text
          variant="small"
          styles={{
            root: {
              fontFamily: fonts.HelveticaNeue_Light,
              color: ThemeColorPalette.uploadVideoBtnTxt,
              padding: "0.5rem 0 0 0.1rem",
            },
          }}
        >
          {timeSince(this.props.notificationData.data["sent_at"])}
        </Text>
        <Modal
          styles={{ root: ThemeColorPalette.pureBlack }}
          isModeless={false}
          isOpen={this.props.isModalOpen}
          containerClassName={"modalStyle2"}
        >
          <Stack grow styles={{ root: {} }}>
            <Stack
              horizontal
              horizontalAlign="space-between"
              styles={{ root: { padding: "0.5rem 1rem" } }}
            >
              <StackItem align="center">
                <Text
                  variant="mediumPlus"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Bold,
                    },
                  }}
                >
                  {notificationData.type === "share-video"
                    ? "Video shared with you"
                    : "Video tagged to you"}
                </Text>
              </StackItem>
              <StackItem align="center">
                <IconButton
                  iconProps={{
                    iconName: "Cancel",
                    styles: {
                      root: {
                        fontWeight: "Bold",
                        color: ThemeColorPalette.pureWhite,
                        textAlign: "end",
                      },
                    },
                  }}
                  onClick={this.props.handleNotificationsModalOpen}
                />
              </StackItem>
            </Stack>
            <Stack
              tokens={{ childrenGap: 10, padding: "1rem" }}
              styles={{
                root: { backgroundColor: ThemeColorPalette.selectedGridBG },
              }}
            >
              <Text
                variant="large"
                styles={{ root: { fontFamily: fonts.DINPro_Medium } }}
              >
                {notificationData.data.primary_procedure_name}
              </Text>
              <Stack tokens={{ childrenGap: 5 }}>
                <Text
                  variant="smallPlus"
                  styles={{ root: { fontFamily: fonts.DINPro_Light } }}
                >
                  Date of Procedure:{" "}
                  {notificationData.data.surgery_date !== ""
                    ? getDateWithSlashInBetween(
                        notificationData.data.surgery_date
                      )
                    : "N/A"}
                </Text>
              </Stack>
            </Stack>
            <Stack verticalFill>
              <Stack
                styles={{
                  root: {
                    flex: 12,
                    height: "25rem",
                    backgroundColor: ThemeColorPalette.pureBlack,
                  },
                }}
                horizontalAlign="center"
              >
                <VideoJS src={videoSrc ? videoSrc : ""} />
              </Stack>
              <Stack
                horizontal
                horizontalAlign="space-between"
                styles={{ root: { padding: "1rem" } }}
              >
                <StackItem align="center">
                  <Text
                    variant="smallPlus"
                    styles={{
                      root: {
                        fontFamily: fonts.HelveticaNeue_Medium,
                        color: ThemeColorPalette.changePasswordLabelTxt,
                      },
                    }}
                  >
                    Uploaded on:{" "}
                    {notificationData.data.uploaded_date !== "None"
                      ? getTime(notificationData.data.uploaded_date) +
                        ", " +
                        getDateWithSlashInBetween(
                          notificationData.data.uploaded_date
                        )
                      : "N/A"}
                  </Text>
                </StackItem>

                <Stack
                  horizontal
                  horizontalAlign="start"
                  tokens={{ childrenGap: 15 }}
                >
                  <CheckPermission {...constants.SCOPES.VIDEOS_ACCEPT}>
                    <PrimaryButton
                      styles={{
                        root: {
                          color: ThemeColorPalette.pureWhite,
                        },
                      }}
                      onClick={() => {
                        this.props.handleNotificationAccept(
                          notificationData.data.video_id,
                          notificationData.type
                        );
                        this.actionPerform(this.props.index);
                      }}
                      disabled={
                        this.state.preventActionLoader[this.props.index]
                      }
                    >
                      Accept
                    </PrimaryButton>
                  </CheckPermission>
                  <CheckPermission {...constants.SCOPES.VIDEOS_ACCEPT}>
                    <PrimaryButton
                      styles={{
                        root: {
                          backgroundColor: ThemeColorPalette.uploadMsgTxt,
                          color: ThemeColorPalette.pureBlack,
                        },
                      }}
                      onClick={() => {
                        this.props.handleNotificationReject(
                          notificationData.data.video_id,
                          notificationData.type
                        );
                        this.actionPerform(this.props.index);
                      }}
                      disabled={
                        this.state.preventActionLoader[this.props.index]
                      }
                    >
                      Decline
                    </PrimaryButton>
                  </CheckPermission>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Modal>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return { videoByIdDetails: state.dashboardReducerData.videoByIdDetails };
};

export default connect(mapStateToProps, null)(NotificationActionsTab);
