import React, { cloneElement, useCallback } from "react";
import useModulePermissions from "./useModulePermissions";

export const HasPermission = ({ scopes }: any) => {
  const [permissions]: any = useModulePermissions();

  if (!permissions.length) return true;

  const scopesMap: any = {};
  scopes.forEach((scope: any) => {
    scopesMap[scope] = true;
  });
  return permissions.some((permission: any) => scopesMap[permission]);
};

export const withPermissionForClass = (Component: any, fields: any = {}) => {
  return (props: any) => {
    const fieldPermissions: any = {};
    Object.keys(fields).forEach((field: any) => {
      fieldPermissions[field] = HasPermission({ scopes: fields[field] });
    });
    // const hasPermission = HasPermission(scopes);
    return <Component {...props} fieldPermissions={fieldPermissions} />;
  };
};

/**
 * Wrapper class that takes component as children and evaluates the value
 * based on permission and scope set.
 *
 * @param children -  children to be rendered if permission exists
 * @param RenderError - Error to be shown if permission doesnt exists
 * @param childrenProps - Additional props to be added to children if permission doesnt exists
 * @param scopes - array of strings. scope for the component
 *
 * @returns
 */
function CheckPermission({
  children,
  RenderError = null,
  childrenProps = null,
  scopes = [],
}: any) {
  const permissionGranted = HasPermission({ scopes });

  /*
   * Return error component
   */
  if (!permissionGranted && !childrenProps && RenderError)
    return <RenderError />;

  /*
    No permission granted and no child props and no error component. Hide the component
  */
  if (!permissionGranted && !childrenProps && !RenderError) return <></>;

  /*
    No permission granted and Add custom props to children 
  */
  if (!permissionGranted && childrenProps)
    return cloneElement(children, { ...childrenProps, key: scopes[0] });

  /*
    Permision granted show the child
  */
  return <>{children}</>;
}

export default CheckPermission;
