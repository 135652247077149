import React, { FormEvent } from "react";
import {
  Stack,
  TextField,
  Text,
  Label,
  StackItem,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import ToggleCard from "../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CreateHospitalIcon } from "../../../../assets/Home/create_hospital.svg";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../ActiveAdminModule/Logs/components/Common/Loader";

import {
  getOnboardFacilities,
  getOnboardSpeciality,
  getOnboardPermissions,
  createSurgeon,
  clearCreateSurgeonErrors,
} from "../../actions/homeAction";
import { CREATE_SURGEON_ERROR } from "../../actions/actionConstants";

// import { roleIncrement } from "../../../../../ActiveAdminModule/Management/actions/managamentActions";
import Select, { components, InputProps } from "react-select";
import { useState, useCallback, useEffect } from "react";
// import { updateRolesPermissionActionData } from "../../../actions/managementAction";
import { AppState } from "../../../../rootReducer";

const options = [
  { value: "CLIENT", label: "CLIENT" },
  { value: "ACTIVSURGICAL", label: "ACTIVSURGICAL" },
];

const options1 = [
  { value: "1", label: "Northeast" },
  { value: "2", label: "Ohio Valley" },
  { value: "3", label: "Southeast" },
  { value: "4", label: "South Central" },
  { value: "5", label: "Upper Midwest" },
  { value: "6", label: "West" },
  { value: "7", label: "Mountain" },
  { value: "8", label: "Pacific Northwest" },
];

enum SURGEON_FIELD_CONSTANTS {
  COMMENTS = "comments",
  FACILITY = "facility",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  MAIL = "mail",
  PERMISSIONS = "permissions",
  PHONE_NUMBER = "phone_number",
  SPECIALITY = "speciality",
}

const SurgeonCreation: React.FC<any> = ({
  updateData,
  data,
  errors: formError,
  showResMsg,
  setShowResMsg,
  isEdit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const [showError, setShowError] = useState(false);
  const [facilityKeys, setFacilityKeys] = useState<string[]>([]);
  const [clearErrorFlag, setClearErrorFlag] = useState<string>("");

  const dispatch = useDispatch();
  const facilitiesList = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.onboardFacilities;
  });

  const loadingState = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loadingState;
  });

  const specialityList = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.onboardSpeciality;
  });

  const permissionsList = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.onboardPermissions;
  });

  const surgeonData = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.surgeonData;
  });
  const createSurgeonError = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.createSurgeonError;
  });
  const createSurgeonErroFieldName = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.createSurgeonErroFieldName;
  });

  const facilitiesListCount = facilitiesList?.count;
  const specialityListCount = specialityList?.count;
  const permissionsListCount = permissionsList?.count;

  useEffect(() => {
    dispatch(getOnboardFacilities(facilitiesListCount));
    dispatch(getOnboardSpeciality(specialityListCount));
    dispatch(getOnboardPermissions(permissionsListCount));
  }, [facilitiesListCount, specialityListCount, permissionsListCount]);

  useEffect(() => {
    if (createSurgeonError && showResMsg) {
      setTimeout(() => {
        setShowError(true);
      }, 1500);
      // setTimeout(() => {
      //   dispatch({
      //     type: CREATE_SURGEON_ERROR,
      //     payload: [{ details: null, field: null }],
      //   });
      //   setShowError(false);
      //   setShowResMsg(false);
      // }, 5000);
    }
  }, [createSurgeonError, showResMsg]);

  const specialityOptions = React.useMemo(() => {
    const list: any = [];
    const specialityOpt = specialityList?.results?.find(
      (s: any) => s.label === data.speciality
    );
    if (specialityOpt) {
      list.push({ label: data.speciality, value: data.speciality });
    }
    specialityList?.results?.forEach((element: any) => {
      const found = list.find(
        (ele: any) => String(element.name) === String(ele.value)
      );
      if (!found) {
        list.push({ label: element.name, value: element.name });
      }
    });
    // list = list.concat(
    //   specialityList?.results?.map((c: any) => ({
    //     label: c.name,
    //     value: c.name,
    //   })) || []
    // );

    return list;
  }, [specialityList, data.speciality]);

  const facilityOptions = React.useMemo(() => {
    let list: any = [];
    (facilityKeys || []).forEach((f: any) => {
      const facilityOpt = facilitiesList?.results?.find(
        (s: any) => s.name === f.label
      );
      if (!facilityOpt && f) {
        list.push(f);
      }
    });
    list = list.concat(
      facilitiesList?.results?.map((c: any) => ({
        label: c.name,
        value: c.name,
      })) || []
    );
    return list;
  }, [facilitiesList, facilityKeys, data.facility]);

  const permissionOptions = React.useMemo(() => {
    const list: any = [];
    const permissionOpt = permissionsList?.results?.find(
      (s: any) => s.label === data.permissions
    );
    if (permissionOpt) {
      list.push({ label: data.permissions, value: data.permissions });
    }
    permissionsList?.results?.forEach((element: any) => {
      const found = list.find(
        (ele: any) => String(element.name) === String(ele.value)
      );
      if (!found) {
        list.push({ label: element.name, value: element.name });
      }
    });
    // list = list.concat(
    //   permissionsList?.results?.map((c: any) => ({
    //     label: c.name,
    //     value: c.name,
    //   })) || []
    // );
    return list;
  }, [permissionsList, data.permissions]);

  useEffect(() => {
    const faciltiesList = data.facility?.map((f: any) => ({
      label: f,
      value: f,
    }));
    setFacilityKeys(faciltiesList);
  }, [data]);

  useEffect(() => {
    setClearErrorFlag("");
  }, []);

  const navigate = useNavigate();

  const routeSpeciality = () => {
    navigate(`/management/speciality`);
  };
  const routeFacility = () => {
    navigate(`/management/facilities`);
  };

  const isAPIError = (errorField: string) => {
    return (
      showError &&
      createSurgeonError &&
      createSurgeonErroFieldName.indexOf(errorField) > -1
    );
  };
  const Option = (props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label className="">{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const MultiValueContainer = (props: any) => {
    let values = props?.selectProps?.value?.map((v: any) => v.label) || [];
    values = values.join(", ");
    return (
      <span className="textNoWrap" style={{ marginTop: "5px" }}>
        {values}
      </span>
    );
  };

  return (
    <>
      {loadingState && (
        <>
          <Loader></Loader>
        </>
      )}
      <form id="surgeonForm">
        <ToggleCard heading="" index="" removeFormFields="">
          <Stack
            className="InputSection ms-Grid-row"
            tokens={{ maxHeight: 420 }}
            style={{ overflow: "auto", minHeight: "650px" }}
          >
            <Stack.Item className="ms-Grid-row">
              <Stack.Item className="ms-Grid-col ms-lg6">
                <Stack.Item className="ms-Grid-row">
                  <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3 firstSection">
                    <Text className="sectionHeading">Surgeon Details</Text>

                    <TextField
                      label="Name of the Surgeon*(First Name)"
                      errorMessage={
                        isAPIError(SURGEON_FIELD_CONSTANTS.FIRST_NAME)
                          ? createSurgeonError[
                              SURGEON_FIELD_CONSTANTS.FIRST_NAME
                            ]
                          : formError.first_name
                      }
                      {...register(`hospitals[0].name`)}
                      value={data.first_name}
                      onChange={(e: any) => {
                        data.first_name = e?.target?.value;
                        updateData({ ...data });
                        formError.first_name = "";
                      }}
                    />
                    <TextField
                      label="Last Name*"
                      errorMessage={
                        // errors?.hospitals
                        //   ? errors?.hospitals[0]?.name?.message
                        //   : ""
                        formError.last_name
                      }
                      {...register(`hospitals[1].name`)}
                      value={data.last_name}
                      onChange={(e: any) => {
                        data.last_name = e?.target?.value;
                        updateData({ ...data });
                        formError.last_name = "";
                      }}
                    />
                    {/* <div >{errors?.hospitals ? errors?.hospitals[0]?.name?.message : "dfdsf"}</div> */}
                    <Label>Specialty*</Label>
                    <Controller
                      control={control}
                      {...register("hospitals[0].region")}
                      defaultValue={data.speciality}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className="surgeonFacility"
                          placeholder="Select from the list"
                          classNamePrefix="addl-class"
                          maxMenuHeight={250}
                          menuPosition="absolute"
                          options={specialityOptions}
                          value={specialityOptions.find(
                            (c: any) => c.value === data.speciality
                          )}
                          onChange={(val: any) => {
                            data.speciality = val?.label as string;
                            updateData({ ...data });
                            formError.speciality = "";
                          }}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {isAPIError(SURGEON_FIELD_CONSTANTS.SPECIALITY)
                        ? createSurgeonError[SURGEON_FIELD_CONSTANTS.SPECIALITY]
                        : formError.speciality}
                    </Label>
                    <Label>Location/Facility</Label>
                    <Controller
                      control={control}
                      {...register("hospitals[2].region")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className="surgeonFacility"
                          placeholder="Select from the list"
                          classNamePrefix="addl-class"
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                            MultiValueContainer,
                          }}
                          menuPosition="absolute"
                          options={facilityOptions}
                          value={facilityKeys}
                          onChange={(selected: any) => {
                            setFacilityKeys(selected);
                            const facilityValue = selected.map(
                              (s: any) => s.label
                            );
                            updateData({ ...data, facility: facilityValue });
                          }}
                        />
                      )}
                    />
                    <Label className="noteMsg">
                      (Note - In the case of a Surgeon who belongs to the Main
                      hospital, no selection is needed)
                    </Label>
                    <Label className="errorMessage">
                      {/* {errors?.hospitals
                      ? errors?.hospitals[0]?.region?.message
                      : ""} */}
                      {isAPIError(SURGEON_FIELD_CONSTANTS.FACILITY) &&
                        createSurgeonError[SURGEON_FIELD_CONSTANTS.FACILITY]}
                    </Label>
                    <Label>Permissions*</Label>
                    <Controller
                      control={control}
                      {...register("hospitals[2].region")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className="surgeonFacility"
                          placeholder="Select from the list"
                          classNamePrefix="addl-class"
                          maxMenuHeight={190}
                          menuPosition="absolute"
                          options={permissionOptions}
                          value={permissionOptions.find(
                            (c: any) => c.value === data.permissions
                          )}
                          onChange={(val) => {
                            data.permissions = val?.label as string;
                            updateData({ ...data });
                            formError.permissions = "";
                          }}
                        />
                      )}
                    />
                    <Label className="errorMessage">
                      {/* {errors?.hospitals
                      ? errors?.hospitals[0]?.region?.message
                      : ""} */}
                      {isAPIError(SURGEON_FIELD_CONSTANTS.PERMISSIONS)
                        ? createSurgeonError[
                            SURGEON_FIELD_CONSTANTS.PERMISSIONS
                          ]
                        : formError.permissions}
                    </Label>
                    {/* <input
                      type="hidden"
                      {...register("hospitals[0].is_main_branch")}
                      value="true"
                    /> */}
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg6">
                <Stack.Item className="ms-Grid-row">
                  <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset1">
                    {isEdit ? null : (
                      <Stack
                        horizontal
                        verticalAlign="center"
                        dir="ltr"
                        className="createUserButton"
                        tokens={{ childrenGap: "20%" }}
                      >
                        <StackItem
                          align="auto"
                          styles={{
                            root: { display: "flex", marginTop: "10px" },
                          }}
                          onClick={routeSpeciality}
                        >
                          <CreateHospitalIcon />
                          <Text> Add Specialty</Text>
                        </StackItem>
                        <StackItem
                          align="auto"
                          styles={{
                            root: { display: "flex", marginTop: "10px" },
                          }}
                          onClick={routeFacility}
                        >
                          <CreateHospitalIcon />
                          <Text> Add Facility</Text>
                        </StackItem>
                      </Stack>
                    )}
                    <TextField
                      label="Mail Id*"
                      errorMessage={
                        // errors?.admin ? errors?.admin.email?.message : ""
                        isAPIError(SURGEON_FIELD_CONSTANTS.MAIL)
                          ? createSurgeonError &&
                            Object.keys(createSurgeonError).includes(
                              SURGEON_FIELD_CONSTANTS.MAIL
                            ) &&
                            createSurgeonError[SURGEON_FIELD_CONSTANTS.MAIL]
                          : formError.mail
                      }
                      {...register("admin.email")}
                      value={data.mail}
                      onChange={(e: any) => {
                        data.mail = e?.target?.value;
                        updateData({ ...data });
                        formError.mail = "";
                        dispatch(clearCreateSurgeonErrors("mail"));
                      }}
                      disabled={isEdit}
                    />
                    <TextField
                      label="Phone Number*"
                      onKeyPress={(event) => {
                        if (!/[0-9\d\-]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      errorMessage={
                        // errors?.admin ? errors?.admin.phone_number?.message : ""
                        isAPIError(SURGEON_FIELD_CONSTANTS.PHONE_NUMBER)
                          ? createSurgeonError &&
                            Object.keys(createSurgeonError).includes(
                              SURGEON_FIELD_CONSTANTS.PHONE_NUMBER
                            ) &&
                            createSurgeonError[
                              SURGEON_FIELD_CONSTANTS.PHONE_NUMBER
                            ]
                          : formError.phone_number
                      }
                      {...register("admin.phone_number")}
                      onChange={(e: any) => {
                        const newVal = e?.target?.value.replace(
                          /[a-zA-Z]/g,
                          ""
                        );
                        data.phone_number = newVal;
                        updateData({ ...data });
                        formError.phone_number = "";
                        dispatch(clearCreateSurgeonErrors("phone_number"));
                      }}
                      value={data.phone_number}
                      maxLength={16}
                    />

                    <TextField
                      label="Comments"
                      errorMessage={
                        // errors?.hospitals
                        //   ? errors?.hospitals[0]?.name?.message
                        //   : ""
                        isAPIError(SURGEON_FIELD_CONSTANTS.COMMENTS)
                          ? createSurgeonError[SURGEON_FIELD_CONSTANTS.COMMENTS]
                          : formError.comments
                      }
                      {...register(`hospitals[2].name`)}
                      value={data.comments}
                      onChange={(e: any) => {
                        data.comments = e?.target?.value;
                        updateData({ ...data });
                      }}
                    />
                    {/* {createSurgeonError && (
                    <Label styles={{ root: { color: "red" } }}>
                      <Stack>
                        {Object.keys(createSurgeonError).map((key: string) => (
                          <StackItem
                            styles={{ root: { color: "red" } }}
                            key={key}
                          >
                            {createSurgeonError[key]}
                          </StackItem>
                        ))}
                      </Stack>
                    </Label>
                  )} */}
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack>
        </ToggleCard>
      </form>
    </>
  );
};

export default SurgeonCreation;
