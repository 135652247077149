export const FETCH_VIDEOS = "FETCH_VIDEOS";
export const FETCH_SHARED_BY_VIDEOS = "FETCH_SHARED_BY_VIDEOS";
export const SET_SELECTED_SHARED_VIDEO = "SET_SELECTED_SHARED_VIDEO";
export const SET_SELECTED_VIDEO_UNFAVOURITE = "SET_SELECTED_VIDEO_UNFAVOURITE";
export const EDIT_VIDEO_DETAILS = "EDIT_VIDEO_DETAILS";

export const RESET_EDITED_DATA_ON_CANCEL_SHARED =
  "RESET_EDITED_DATA_ON_CANCEL_SHARED";

export const POST_SHARED_DETAILS = "POST_SHARED_DETAILS";
export const CLEAR_SHARED_DATA = "CLEAR_SHARED_DATA";

export const SHOW_SPINNER = "SHOW_SPINNER";
