import React from "react";
import { doHttpCall, apiEndPoint } from "../../common/restApi";
import {
  DefaultButton,
  FontIcon,
  IconButton,
  Modal,
  PersonaPresence,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "office-ui-fabric-react";
import { fonts, ThemeColorPalette } from "../../theme";
import SharedPeoplePicker from "../../common/SharedPeoplePicker";
import { TestImages } from "@uifabric/example-data";

function VideoPlayerUnShareModal({
  videoData,
  userProfileId,
  proceduresData,
  userData,
  postSharedDetails,
  previouslySharedList,
  getVideoDetails,
}: any) {
  const [isModalOpen, setIsModalOpen]: any = React.useState(false);
  const [shareErrors, setShareErrors]: any = React.useState([]);
  const [showSelected, setShowSelected]: any = React.useState(false);
  const [sharedDetailsToPost, setSharedDetailsToPost]: any = React.useState({});
  const [shared_with, setShared_with]: any = React.useState([]);
  const [shareButtonEnable, setShareButtonEnable]: any = React.useState(true);

  const [procedureCreatedByDetails]: any = React.useMemo(() => {
    return videoData && proceduresData.results
      ? proceduresData.results.filter(
          (item: any) => item.id === videoData.primary_procedure_type
        )
      : [];
  }, [videoData, proceduresData.results]);

  const userListNames = React.useMemo(() => {
    return (
      userData.results &&
      userData.results
        .map((item: any, i: number) => {
          if (item.id !== userProfileId) {
            return {
              key: i,
              imageUrl:
                // i % 2 === 0 ? TestImages.personaMale : TestImages.personaFemale,
                item?.photo,
              imageInitials: "",
              text: item.displayName,
              // presence:
              //   i % 2 === 0 ? PersonaPresence.online : PersonaPresence.away,
              video_id: videoData && videoData.id,
              shared_by: userProfileId,
              shared_with: item.id,
              created_by:
                procedureCreatedByDetails &&
                procedureCreatedByDetails.created_by
                  ? procedureCreatedByDetails.created_by
                  : "test",
            };
          }
          return undefined;
        })
        .filter((val: any) => previouslySharedList.includes(val?.shared_with))
    );
  }, [
    userData,
    videoData,
    userProfileId,
    procedureCreatedByDetails,
    previouslySharedList,
  ]);

  const onItemSelect = (selectedItem1: any) => {
    const selectedItems: any =
      selectedItem1 && selectedItem1.length
        ? selectedItem1.map((item: any) => item.shared_with)
        : [];
    setShareErrors([]);
    setShowSelected(true);
    setSharedDetailsToPost(selectedItem1 && selectedItem1[0]);
    setShared_with(selectedItems);
    setShareButtonEnable(selectedItems && selectedItems.length ? false : true);
  };

  const postUnSharedDetails = async (data: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/" + data.video_id + "/unshare",
      data: { users: data.users },
    };
    const response = await doHttpCall(payload);
    if ((response && response.status === 201) || response.status === 200) {
      setShareErrors([
        { message: "Video is successfully unshared", status: "SUCCESS" },
      ]);
      setSharedDetailsToPost({});
      setShared_with([]);
      setShowSelected(false);
      getVideoDetails(data.video_id);
    }
  };

  const handleShareButtonClick = async () => {
    const data = {
      video_id: sharedDetailsToPost.video_id,
      users: [...shared_with],
    };
    setShareButtonEnable(true);
    postUnSharedDetails(data);
  };
  return (
    <StackItem style={{ position: "relative" }}>
      {videoData && videoData.primary_surgeon === userProfileId ? (
        <>
          <div className="custom-unshare-icon"></div>
          <DefaultButton
            disabled={!previouslySharedList.length}
            text="Unshare"
            onClick={() => setIsModalOpen(true)}
            iconProps={{ iconName: "Share" }}
          />
        </>
      ) : null}
      <Modal
        styles={{ root: ThemeColorPalette.pureBlack }}
        isModeless={true}
        isOpen={isModalOpen}
        containerClassName={"modalStyle1"}
      >
        <Stack grow>
          <StackItem
            align="end"
            styles={{ root: { padding: "1rem 1rem 0 1rem" } }}
          >
            <IconButton
              iconProps={{
                iconName: "Cancel",
                styles: {
                  root: {
                    fontWeight: "Bold",
                    color: ThemeColorPalette.pureWhite,
                    textAlign: "end",
                  },
                },
              }}
              onClick={() => {
                setIsModalOpen(false);
                setShareErrors([]);
              }}
            />
          </StackItem>
          <Stack
            verticalFill
            verticalAlign="space-between"
            tokens={{ childrenGap: 10 }}
            styles={{ root: { height: "63vh" } }}
          >
            <StackItem styles={{ root: { padding: "1rem 3rem" } }}>
              <Stack
                verticalAlign="start"
                tokens={{
                  childrenGap: 10,
                  padding: "0 0 1rem 0",
                }}
              >
                <Text
                  variant="xLargePlus"
                  styles={{
                    root: {
                      font: fonts.DINPro_Medium,
                      color: ThemeColorPalette.sharedHeaderText,
                    },
                  }}
                >
                  Unshare video
                </Text>
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      font: fonts.HelveticaNeue_Regular,
                      color: ThemeColorPalette.changePasswordLabelTxt,
                    },
                  }}
                >
                  Only De-identification video will be unshared
                </Text>
              </Stack>
              <SharedPeoplePicker
                userListNames={userListNames}
                onItemSelect={onItemSelect}
                showSelected={showSelected}
              />
              {shareErrors && shareErrors.length
                ? shareErrors.map((val: any, i: number) => (
                    <Stack
                      key={i}
                      horizontal
                      verticalAlign="center"
                      tokens={{
                        childrenGap: 7,
                      }}
                      styles={{ root: { margin: "0.5rem 0" } }}
                    >
                      <FontIcon
                        iconName="Info"
                        style={{
                          color:
                            val.status === "ACCEPTED"
                              ? "red"
                              : ThemeColorPalette.ribbon,
                        }}
                      />
                      <Text
                        variant="smallPlus"
                        styles={{
                          root: {
                            font: fonts.HelveticaNeue_Medium,
                          },
                        }}
                      >
                        {val.message}
                      </Text>
                    </Stack>
                  ))
                : null}
            </StackItem>
            <StackItem
              styles={{
                root: {
                  borderTop: "1px solid " + ThemeColorPalette.panelBorder,
                },
              }}
            >
              <Stack
                horizontal
                tokens={{ childrenGap: 10 }}
                styles={{
                  root: { margin: "1rem 0.5rem 1rem 3rem" },
                }}
              >
                <PrimaryButton
                  disabled={shareButtonEnable}
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Bold,
                    },
                  }}
                  onClick={handleShareButtonClick}
                >
                  Unshare
                </PrimaryButton>
                <PrimaryButton
                  styles={{
                    root: {
                      backgroundColor: ThemeColorPalette.pureBlack,
                      color: ThemeColorPalette.pureWhite,
                      fontFamily: fonts.HelveticaNeue_Bold,
                    },
                  }}
                  onClick={() => {
                    setIsModalOpen(false);
                    setShareErrors([]);
                  }}
                >
                  Cancel
                </PrimaryButton>
              </Stack>
            </StackItem>
          </Stack>
        </Stack>
      </Modal>
    </StackItem>
  );
}

export default VideoPlayerUnShareModal;
