import * as React from "react";
import { Route, Routes } from "react-router-dom";
import InProgress from "../../InProgress/containers/InProgress";
import SharedVideos from "../../Shared/containers/SharedVideos";
import Home from "../../ActiveAdminModule/Home/containers/Home";
import Management from "../../ActiveAdminModule/Management/containers/Management";
import Logs from "../../ActiveAdminModule/Logs/containers/Logs";
import Devices from "../../ActiveAdminModule/Management/components/Devices/Devices";
import Algorithm from "../../ActiveAdminModule/Management/components/Algorithms/Algorithm";
import Role from "../../ActiveAdminModule/Management/components/Roles/Role";
import Procedures from "../../ActiveAdminModule/Management/components/Procedures/Procedures";
import HospitalView from "../../ActiveAdminModule/Home/components/HospitalView/HospitalView";
import Workflow from "../../ActiveAdminModule/Management/components/Workflow/Workflow";
import VideoPlayerNew1 from "../../VideoPlayer/containers/VideoPlayerNew1";
import ActiveAdminActivity from "../../ActiveAdminModule/Activity/containers/ActiveAdminActivity";

export interface Props {}
const ActiveAdminRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="/:id" element={<HospitalView />} />
      <Route path="video/:id" element={<VideoPlayerNew1 />} />
      <Route path="management" element={<Management />} />
      <Route path="/management/devices" element={<Devices />} />
      <Route path="/management/roles" element={<Role />} />
      <Route path="/management/procedures" element={<Procedures />} />
      <Route path="/management/algorithms" element={<Algorithm />} />
      <Route path="/management/workflow" element={<Workflow />} />
      <Route path="/activity" element={<ActiveAdminActivity />} />
      <Route path="/management/procedures/:id" element={<Procedures />} />
      <Route path="logs" element={<Logs />} />
      <Route path="logs/service" element={<Logs />} />
    </Routes>
  );
};

export default ActiveAdminRoutes;
