import { Stack, Label, TextField, Text } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  createUser,
  createUserWithTypes,
  editUserWithType,
  getHospitalsWithCount,
  removeErrorUSR,
} from "../../actions/homeAction";
import { AppState } from "../../../../rootReducer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getLogFacilities } from "../../../Logs/actions/logsActions";
import { apiEndPoint, doHttpCall } from "../../../../common/restApi";
import { getRolesList } from "../../../Management/actions/managamentActions";
import { getSpecialityListByHospital } from "../../../../HospitalAdminModule/Management/actions/managementAction";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";
import Loader from "../../../Logs/components/Common/Loader";
// import {getHospitals} from "../../actions/homeAction";

const userTypeOptions = [
  { value: "HospitalAdmin", label: "Hospital Admin" },
  { value: "Surgeon", label: "Surgeon" },
  { value: "ActivAdmin", label: "Activ Admin" },
  { value: "Developer", label: "Developer" },
  { value: "Dual", label: "Dual" },
];

const userTypesOptions = [
  {
    key: "ActivAdmin",
    text: "Activ Admin",
    disabled: true,
    selected: true,
  },
  { key: "Developer", text: "Developer" },
];

const UserForm = ({
  userId,
  userCreateType,
  userTypeEdit,
  setDisabledCreate,
  isModalOpen,
  visibleHospitalList,
}: {
  userId: any;
  userCreateType: any;
  userTypeEdit: any;
  setDisabledCreate: any;
  isModalOpen: any;
  visibleHospitalList?: any;
}) => {
  // console.log(visibleHospitalList, "visibleHospitalList");
  const userData = useSelector((state: AppState) => {
    return state.homeReducerData.userData.data;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.homeReducerData?.loaderWithMessage;
  });
  const loadingState1 = useSelector((state: AppState) => {
    return state.homeReducerData?.loaderWithMessage.loadingState;
  });

  const loadingState = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessage?.loadingState;
  });

  const userMail = userData?.mail;
  const userPhone = userData?.phone_number;

  const [emailOnchange, setemailOnchange] = useState("");
  const [phoneNumberchange, setPhoneNumberchange] = useState("");
  const [specilityFilterName, setSpecilityFilterName] = useState<any>("");
  const [allHospitalList, setAllHospitalList] = useState([]);

  const isEmailChanged =
    userMail !== (emailOnchange !== "" ? emailOnchange : userMail)
      ? false
      : true;
  const isPhoneChanged =
    userPhone !== (phoneNumberchange !== "" ? phoneNumberchange : userPhone)
      ? false
      : true;

  const emailValidationRegex = RegExp(
    /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  );
  const phoneValidationRegex = RegExp(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  );
  const validationSchema = Yup.object().shape({
    mail: Yup.string()
      .required("Mail is required")
      .matches(emailValidationRegex, "Invalid email")
      .test({
        message: "This email is already taken",
        test: async (value) => {
          if (emailOnchange !== "" && isEmailChanged) {
            const checkFieldName = `/user/validate?mail=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    phone_number: Yup.string()
      .required("Phone number is required")
      .min(6, "Invalid phone number")
      .max(15, "Invalid phone number")
      // .matches(phoneValidationRegex, "Invalid phone number")
      .test({
        message: "This phone number is already taken",
        test: async (value: any) => {
          const phoneNum: any = value.replace(/\+/g, "%2B");
          if (phoneNumberchange !== "" && isPhoneChanged) {
            const checkFieldName = `/user/validate?phone_number=${phoneNum}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.status === 200 ? false : true;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    user_type: Yup.string().required("User type is required"),
    user_permission: Yup.string().when(["user_type"], {
      is: "Surgeon",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("User permission is required"),
    }),
    hospitalSurgeon: Yup.string().when(["user_type"], {
      is: "Surgeon",
      then: Yup.string().required("Hospital Name  is required"),
    }),
    hospital: Yup.string().when(["user_type"], {
      is: "HospitalAdmin",
      then: Yup.string().required("Hospital Name  is required"),
    }),
    // facility: Yup.string().when(["user_type"], {
    //   is: "Surgeon",
    //   then: Yup.string().required("Facility is required"),
    // }),
    speciality: Yup.string().when(["user_type"], {
      is: "Surgeon",
      then: Yup.string().required("Specialty is required"),
    }),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const dispatch = useDispatch();
  const [userType, setUserType] = useState<any | null>(null);
  const [onGoingSubmit, setonGoingSubmit] = useState<any>(false);
  const [hospitalFilter, setHospitalFilter] = useState<any | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loader, setLoader] = useState(false);
  const [clearHospital, setClearHospital] = useState(false);

  useEffect(() => {
    if (userData?.permissions) {
      setSelectedKeys(userData?.permissions);
    }
  }, [userData?.permissions]);

  useEffect(() => {
    if (userData?.hospital) {
      setSpecilityFilterName(userData?.speciality_name);
      setValue("speciality", userData?.speciality_name);
      setValue("facility", userData?.facility);

      dispatch(getLogFacilities(userData?.hospital));
      dispatch(getSpecialityListByHospital("", userData?.hospital, true, 1000));
    }
  }, [userData?.hospital]);

  useEffect(() => {
    if (
      !showSurgeonForm &&
      userType !== null &&
      userType !== "" &&
      isModalOpen === true
    ) {
      // setLoader(true);
      // if (!showHospitalAdminForm) {
      //   await dispatch(getRolesList(1, "ACTIVSURGICAL", "", 1000, userType));
      //   setLoader(false);
      // } else {
      //   await dispatch(getRolesList(1, "CLIENT", "", 1000, userType));
      //   setLoader(false);
      // }
      (async () => {
        setLoader(true);
        if (!showHospitalAdminForm) {
          await dispatch(getRolesList(1, "ACTIVSURGICAL", "", 1000, userType));
          setLoader(false);
        } else {
          await dispatch(getRolesList(1, "CLIENT", "", 1000, userType));
          setLoader(false);
        }
      })();
    }
  }, [isModalOpen, userType]);

  const roles = useSelector((state: AppState) => {
    return state.managementReducerData.rolesList.data;
  });

  const rolesOptions = roles?.results?.map((role: any) => ({
    value: role?.name,
    label: role?.name,
  }));

  const showHospitalAdminForm = userType === "HospitalAdmin";
  const showActivAdminForm = userType === "ActivAdmin";
  const showDeveloperForm = userType === "Developer";
  const showSurgeonForm = userType === "Surgeon";
  const showDualForm = userType === "Dual";
  let permissionOptions: any = [{ value: "", label: "" }];

  if (
    showHospitalAdminForm ||
    showDeveloperForm ||
    showDualForm ||
    showActivAdminForm
  ) {
    permissionOptions = rolesOptions && rolesOptions;
  }

  const userPermission =
    userType === "HospitalAdmin" ? "HospitalAdmin" : userData?.permissions[0];

  const firstName = showSurgeonForm
    ? "Name of the Surgeon*(First Name)"
    : "First Name*";

  const hospitalsWithCounts = useSelector((state: AppState) => {
    return state.homeReducerData.hospitalsWithCount?.data;
  });

  const allHospitalsCount = hospitalsWithCounts?.count;

  const logFacilities = useSelector((state: AppState) => {
    return state.logsReducerData?.facilities?.data;
  });
  const hospitalList = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.hospitalListByHosp;
  });
  const errorResponse = useSelector((state: AppState) => {
    return state.homeReducerData.errorUserAPI;
  });
  const specilitiesFilter = hospitalList?.data?.results?.map(
    (spacilitie: any) => ({
      label: spacilitie.name,
      value: spacilitie.id,
    })
  );
  // useEffect(() => {
  //   setLoader(true);
  //   let obj = {
  //     phone_number: "",
  //     mail: "",
  //   };
  //   dispatch(removeErrorUSR(obj));
  //   dispatch(getSpecialityListByHospital("", 1, false));
  //   let res:any = await dispatch(getHospitalsWithCount(2, "", allHospitalsCount));
  //   if (res && res.status === 200) {
  //     setLoader(false);
  //   }
  // }, []);

  useEffect(() => {
    (async () => {
      setLoader(true);
      const obj = {
        phone_number: "",
        mail: "",
      };
      dispatch(removeErrorUSR(obj));
      dispatch(getSpecialityListByHospital("", 1, false));
      const res: any = await dispatch(
        getHospitalsWithCount(2, "", allHospitalsCount)
      );
      if (res && res.status === 200) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  useEffect(() => {
    setDisabledCreate(false);
    setonGoingSubmit(false);
  }, [errorResponse]);

  const logFacilityFilter = logFacilities?.results?.map(
    (logFacilitie: any) => ({
      label: logFacilitie.name,
      value: logFacilitie.id,
    })
  );

  const showCreateUser = useSelector((state: AppState) => {
    return state.homeReducerData.showCreateUser;
  });

  function getUniqueListBy(arr: any, key: any) {
    return (
      arr &&
      arr.filter(
        (ele: any, ind: any) =>
          ind === arr.findIndex((elem: any) => elem[key] === ele[key])
      )
    );
  }

  useEffect(() => {
    const hospitalsWithCountOptions =
      hospitalsWithCounts &&
      getUniqueListBy(hospitalsWithCounts?.results, "id")?.map(
        (hospitalsWithCount: any) => ({
          value: hospitalsWithCount.id,
          label: hospitalsWithCount.name,
        })
      );
    const tempAry: any = hospitalsWithCountOptions;
    if (
      hospitalsWithCountOptions &&
      hospitalsWithCountOptions.length &&
      userData &&
      userData.hospital
    ) {
      const findIndex =
        hospitalsWithCountOptions &&
        hospitalsWithCountOptions.findIndex((hospital: any) => {
          return hospital.value === userData.hospital;
        });
      if (findIndex === -1) {
        tempAry.splice(0, 0, {
          value: userData.hospital,
          label: userData.hospital_name,
        });
      }
    }
    setAllHospitalList(tempAry);
  }, [hospitalsWithCounts]);
  const setkeys = (keys: any) => {
    const selectedVlaues = keys?.filter((item: any) => item.selected);
    selectedVlaues.map((item: any) => {
      setSelectedKeys([item.key]);
    });
  };
  const onSubmit = (data: any) => {
    if (onGoingSubmit) return false;
    setonGoingSubmit(true);
    setDisabledCreate(true);
    let userData: any;
    const removeUnusedKey = (obj: any, arr: any) =>
      Object.keys(obj)
        .filter((k) => !arr.includes(k))
        .reduce((acc: any, key) => ((acc[key] = obj[key]), acc), {});
    // -------------HospitalAdmin-------------------------

    if (showHospitalAdminForm) {
      userData = removeUnusedKey(data, ["hospital1", "speciality", "facility"]);
      !showCreateUser &&
        Object.assign(userData, { permissions: data.user_permission });
      !showCreateUser && dispatch(createUser(userData));
      if (showCreateUser) {
        Object.assign(userData, { permissions: [data.user_permission] });
        isPhoneChanged && delete userData?.phone_number;
        userData =
          userData?.comments === ""
            ? removeUnusedKey(userData, ["comments"])
            : userData;
        dispatch(editUserWithType(userTypeEdit, userId, userData));
      }
      // -------------Surgeon-------------------------
    } else if (showSurgeonForm) {
      Object.assign(data, { hospital: data.hospitalSurgeon });
      userData = removeUnusedKey(data, ["hospital1"]);
      !showCreateUser && dispatch(createUser(userData));
      if (showCreateUser) {
        isPhoneChanged && delete userData?.phone_number;
        userData =
          userData?.comments === ""
            ? removeUnusedKey(userData, ["comments"])
            : userData;
        dispatch(editUserWithType(userTypeEdit, userId, userData));
      }
      // -------------Developer, Activadmin-------------------------
    } else if (showDeveloperForm || showActivAdminForm) {
      Object.assign(data, { assigned_role: data.user_type });

      userData = removeUnusedKey(data, [
        "hospital1",
        "speciality",
        "facility",
        "hospital",
        "hospitalSurgeon",
        "user_type",
      ]);
      Object.assign(userData, { permissions: [data.user_permission] });
      !showCreateUser && dispatch(createUserWithTypes(userData));
      if (showCreateUser) {
        isPhoneChanged && delete userData?.phone_number;
        userData =
          userData?.comments === ""
            ? removeUnusedKey(userData, ["comments"])
            : userData;
        dispatch(editUserWithType(userTypeEdit, userId, userData));
      }
    }
    //-----------------------dual-----------------
    else if (showDualForm) {
      Object.assign(data, { assigned_role: data.user_type });
      userData = removeUnusedKey(data, [
        "hospital1",
        "speciality",
        "facility",
        "hospital",
        "hospitalSurgeon",
        "user_type",
      ]);
      !showCreateUser &&
        Object.assign(userData, { permissions: [data.user_permission] });
      !showCreateUser && dispatch(createUserWithTypes(userData));
      if (showCreateUser) {
        Object.assign(userData, { permissions: [data.user_permission] });
        isPhoneChanged && delete userData?.phone_number;
        userData =
          userData?.comments === ""
            ? removeUnusedKey(userData, ["comments"])
            : userData;
        dispatch(editUserWithType(userTypeEdit, userId, userData));
      }
    }
  };

  useEffect(() => {
    const values = getValues();
    if (!userType) {
      setHospitalFilter(null);
      for (const prop in values) {
        delete values[prop];
      }
    }
  }, [userType]);

  const getSpecilityFilterName = (value: any) => {
    const obj = specilitiesFilter?.find((c: any) => c.label === value);
    return obj || "";
  };

  useEffect(() => {
    setUserType(userTypeEdit);
    userData && setHospitalFilter(userData?.hospital);
    showCreateUser && setSelectedKeys(userData?.permissions);
  }, [userData]);

  useEffect(() => {
    setUserType("");
    setDisabledCreate(false);
    userCreateType(null);
  }, []);

  // useEffect(()=>{
  //   dispatch(getHospitals());
  // },[])

  const [visibleRoleList, setVisibleRoleList] = useState(false);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_ROLES_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleRoleList(true);
    }

    if (
      loaderWithMessage?.calledFrom === "GET_ROLES_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleRoleList(false);
    }
  }, [loaderWithMessage]);

  // console.log(loadingState, "loadingState", loadingState1);

  const getHospitalPlaceHolderValue = (userData: any) => {
    if (userData && userData?.hospital && !clearHospital) {
      return userData?.hospital_name;
    } else if (!userData && visibleHospitalList) {
      return "Select from the list";
    } else if (!userData && !visibleHospitalList) {
      return "You do not have permission";
    } else {
      return "Select from the list";
    }
  };

  const getUserPermissionPlaceHolderValue = (userData: any) => {
    if (userData && userData?.permissions[0]) {
      return userData?.permissions[0];
    } else if (!userData && visibleRoleList) {
      return "Select from the list";
    } else if (!userData && !visibleRoleList) {
      return "You do not have permission";
    } else {
      return "Select from the list";
    }
  };

  return (
    <Stack>
      {/* {loadingState && <Loader></Loader>}
      {loadingState1 && <Loader></Loader>} */}
      {loader && <Loader></Loader>}
      {/* <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage> */}
      {(userData || !showCreateUser) && (
        <form
          id="userForm"
          className={loaderWithMessage?.loadingState ? "point-events-none" : ""}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack>
            <Stack.Item className="userCreate ms-Grid InputSection " dir="ltr">
              <Stack
                className="ms-Grid-row"
                dir="ltr"
                verticalAlign="center"
                horizontalAlign="center"
                style={{
                  margin: "auto",
                }}
              >
                <Stack.Item
                  className="ms-Grid-col ms-lg9"
                  style={{
                    padding: "48px 73px",
                    backgroundColor: "#101112",
                  }}
                >
                  <Stack.Item className="ms-Grid-row ">
                    <Stack.Item className="ms-Grid-col ms-lg5 ">
                      <Label>User Type*</Label>
                      <Controller
                        control={control}
                        defaultValue={userTypeEdit}
                        name="user_type"
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            isDisabled={showCreateUser}
                            isClearable
                            classNamePrefix="addl-class"
                            options={userTypeOptions}
                            value={userTypeOptions?.find(
                              (c: any) => c.value === value
                            )}
                            onChange={(val) => {
                              const obj = {
                                phone_number: "",
                                mail: "",
                              };
                              dispatch(removeErrorUSR(obj));
                              onChange(val?.value);
                              setUserType(val?.value);
                              setkeys(userTypesOptions);
                              userCreateType(val?.value);
                            }}
                          />
                        )}
                      />
                      <Label className="errorMessage">
                        {errors ? errors?.user_type?.message : ""}
                      </Label>
                      {showSurgeonForm && (
                        <Stack.Item
                          className={`${showSurgeonForm ? "show" : "hide"}`}
                          key={hospitalFilter}
                        >
                          <Label>Hospital Name*</Label>
                          <Controller
                            control={control}
                            defaultValue={userData?.hospital}
                            name="hospitalSurgeon"
                            shouldUnregister
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                isClearable
                                classNamePrefix="addl-class"
                                options={allHospitalList}
                                isLoading={loadingState}
                                value={allHospitalList?.find(
                                  (c: any) => c.value === value
                                )}
                                placeholder={getHospitalPlaceHolderValue(
                                  userData
                                )}
                                onChange={(val: any) => {
                                  onChange(val?.value);
                                  setHospitalFilter(val?.value);
                                  dispatch(getLogFacilities(val?.value));
                                  if (!val?.value) {
                                    setClearHospital(true);
                                    setSpecilityFilterName("");
                                    setValue("facility", "");
                                    setValue("hospitalSurgeon", "");
                                    setValue("speciality", "");
                                  }
                                  if (val?.value) {
                                    setValue("speciality", "");
                                  }
                                  if (val?.value) {
                                    setClearHospital(false);
                                  }
                                  dispatch(
                                    getSpecialityListByHospital(
                                      "",
                                      val?.value,
                                      true,
                                      1000
                                    )
                                  );
                                }}
                                isDisabled={
                                  !visibleHospitalList ||
                                  userType === "" ||
                                  userType == null ||
                                  !showSurgeonForm ||
                                  showCreateUser
                                }
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors ? errors?.hospitalSurgeon?.message : ""}
                          </Label>
                        </Stack.Item>
                      )}
                      {(showHospitalAdminForm ||
                        showDeveloperForm ||
                        showDualForm ||
                        showActivAdminForm) && (
                        <Stack.Item
                          key={userPermission}
                          className={`${
                            showHospitalAdminForm ||
                            showDeveloperForm ||
                            showDualForm ||
                            showActivAdminForm
                              ? "show"
                              : "hide"
                          }`}
                        >
                          <Stack.Item className="label">
                            <Text>User Permissions*</Text>
                          </Stack.Item>
                          <Controller
                            control={control}
                            defaultValue={
                              showCreateUser && userData?.permissions
                                ? userData?.permissions[0]
                                : undefined
                            }
                            name="user_permission"
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                classNamePrefix="addl-class"
                                options={permissionOptions}
                                value={
                                  permissionOptions &&
                                  permissionOptions?.find(
                                    (c: any) => c.value === value
                                  )
                                }
                                placeholder={getUserPermissionPlaceHolderValue(
                                  userData
                                )}
                                //placeholder={userData?.permissions[0] ? userData?.permissions[0] : "Select from the list"}
                                // placeholder={!visibleRoleList ? "You do not have permission": "Select from the list"}

                                onChange={(val) => {
                                  onChange(val?.value);
                                }}
                                isDisabled={
                                  !visibleRoleList ||
                                  userType === "" ||
                                  userType == null
                                }
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors ? errors?.user_permission?.message : ""}
                          </Label>
                        </Stack.Item>
                      )}
                    </Stack.Item>
                    <Stack.Item
                      className="ms-Grid-col ms-lg5 ms-lgOffset1"
                      key={hospitalFilter}
                    >
                      {showHospitalAdminForm && (
                        <>
                          <Stack
                            className={`${
                              showHospitalAdminForm ? "show" : "hide"
                            }`}
                            key={hospitalFilter}
                          >
                            <Label>Hospital Name*</Label>
                            <Controller
                              control={control}
                              defaultValue={userData?.hospital}
                              shouldUnregister
                              name="hospital"
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  isClearable
                                  classNamePrefix="addl-class"
                                  options={allHospitalList}
                                  value={allHospitalList?.find(
                                    (c: any) => c.value === value
                                  )}
                                  placeholder={
                                    getHospitalPlaceHolderValue(userData)
                                    // !visibleHospitalList ? "You do not have permission": "Select from the list"
                                  }
                                  onChange={(val: any) => {
                                    onChange(val?.value);
                                    setHospitalFilter(val?.value);
                                  }}
                                  isDisabled={
                                    !visibleHospitalList ||
                                    userType === "" ||
                                    userType == null ||
                                    showCreateUser
                                  }
                                />
                              )}
                            />
                          </Stack>
                          <Label className="errorMessage">
                            {errors ? errors?.hospital?.message : ""}
                          </Label>
                        </>
                      )}
                      {showSurgeonForm && (
                        <Stack.Item
                          className={`${showSurgeonForm ? "show" : "hide"}`}
                        >
                          <Stack.Item className="label">
                            <Text>Facility</Text>
                          </Stack.Item>
                          <Controller
                            control={control}
                            defaultValue={userData?.facility}
                            shouldUnregister
                            name="facility"
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                isClearable
                                classNamePrefix="addl-class"
                                options={logFacilityFilter}
                                value={logFacilityFilter?.find(
                                  (c: any) => c.label === value
                                )}
                                onInputChange={(val) => {
                                  dispatch(
                                    getLogFacilities(hospitalFilter, val)
                                  );
                                }}
                                onChange={(val) => {
                                  onChange(val?.label);
                                }}
                                isDisabled={
                                  !(
                                    Array.isArray(logFacilityFilter) &&
                                    logFacilityFilter.length > 0
                                  )
                                }
                              />
                            )}
                          />
                        </Stack.Item>
                      )}
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="ms-Grid-row userCreateFormSection2">
                    <Stack.Item className="ms-Grid-col ms-lg5 ">
                      <TextField
                        errorMessage={errors ? errors?.first_name?.message : ""}
                        label={firstName}
                        {...register("first_name")}
                        defaultValue={userData?.first_name}
                        // pattern="[^\s]+"
                      ></TextField>
                      <TextField
                        errorMessage={errors ? errors?.last_name?.message : ""}
                        label="Last Name*"
                        {...register("last_name")}
                        defaultValue={userData?.last_name}
                      ></TextField>
                      {showSurgeonForm && (
                        <Stack.Item
                          className={`${showSurgeonForm ? "show" : "hide"}`}
                        >
                          <Stack.Item className="label">
                            <Text>Specialty*</Text>
                          </Stack.Item>
                          <Controller
                            control={control}
                            defaultValue={getSpecilityFilterName(
                              userData?.speciality_name
                            )}
                            shouldUnregister
                            name="speciality"
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                isClearable
                                classNamePrefix="addl-class"
                                options={specilitiesFilter}
                                value={getSpecilityFilterName(
                                  specilityFilterName
                                )}
                                onChange={(val) => {
                                  onChange(val?.label);
                                  setSpecilityFilterName(val?.label);
                                }}
                              />
                            )}
                          />
                          <Label className="errorMessage">
                            {errors ? errors?.speciality?.message : ""}
                          </Label>
                        </Stack.Item>
                      )}
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg5 ms-lgOffset1">
                      <TextField
                        {...register("mail")}
                        errorMessage={errors ? errors?.mail?.message : ""}
                        label="Mail ID*"
                        defaultValue={userData?.mail}
                        onChange={(e: any) => {
                          setemailOnchange(e?.target?.value);
                          errors.mail = "";
                          const obj = {
                            phone_number: errorResponse?.phone_number || "",
                            mail: "",
                          };
                          dispatch(removeErrorUSR(obj));
                        }}
                        disabled={showCreateUser}
                      ></TextField>
                      <Label className="errorMessage">
                        {errorResponse?.mail}
                      </Label>
                      <TextField
                        errorMessage={
                          errors ? errors?.phone_number?.message : ""
                        }
                        label="Phone Number*"
                        onKeyPress={(event) => {
                          if (!/[0-9\d\-+]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("phone_number")}
                        onChange={(e: any) => {
                          setPhoneNumberchange(e?.target?.value);
                          errors.phone_number = "";
                          const obj = {
                            phone_number: "",
                            mail: errorResponse?.mail || "",
                          };
                          dispatch(removeErrorUSR(obj));
                        }}
                        defaultValue={userData?.phone_number}
                      ></TextField>
                      <Label className="errorMessage">
                        {errorResponse?.phone_number}
                      </Label>
                      <TextField
                        {...register("comments")}
                        defaultValue={userData?.comments}
                        label="Comments"
                      ></TextField>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </form>
      )}
    </Stack>
  );
};

export default UserForm;
