import { createFacility } from "./../actions/managementAction";
import {
  UPDATE_HOSPITAL_DEVICES_LIST,
  GET_PRIVILEGES_LIST,
  GET_PERMISSION_LIST,
  GET_SPECIALITY_LIST,
  GET_ALL_SPECIALITIES_LIST,
  GET_SPECIALITY_BY_ID,
  EDIT_SPECIALITY_BY_ID,
  GET_FACILITIES_LIST,
  CREATE_PRIVILEGES,
  ROLESPERMISSION_DATA,
  PRIVILEGE_SPINNER,
  DELETE_PRIVILEGE,
  CREATE_SPECIALITY,
  CREATE_FACILITY,
  GET_HOSPITAL_DEVICES_LIST,
  GET_HOSPITAL_DEVICE_BY_ID,
  EDIT_HOSPITAL_DEVICE_BY_ID,
  GET_PRIVILEGE_BY_ID,
  EDIT_PRIVILEGE_BY_ID,
  GET_FACILITY_BY_ID,
  EDIT_FACILITY_BY_ID,
  DELETE_SPECIALITY,
  DELETE_SPECIALITY_ERROR,
  DELETE_FACILITY_ERROR,
  DELETE_PRIVILEGE_ERROR,
  DELETE_FACILITY,
  CREATE_FACILITY_ERROR,
  LOADER,
  STOPLOADER,
  CREATE_PRIVILEGE_ERROR,
  CLEAR_PRIVILAGE_ERROR,
  CREATE_SPECIALITY_ERROR,
  CANCEL_PREVIOUS_ERROR_MESSAGE,
  CANCEL_FACILITY_ERROR,
  GET_SPECIALITY_HOSPITAL_LIST,
  IS_PERMISSIONS_FETCHED_FOR_CREATE_PRIVILEGE,
  IS_PERMISSIONS_FETCHED_FOR_EDIT_PRIVILEGE,
} from "./../actions/actionConstants";

const initialState: any = {
  devicesList: [],
  editedDevicesList: {},
  deviceById: {},
  editDeviceById: {},
  privilegesList: [],
  permissionList: [],
  privilegesData: [],
  rolesPermissionData: null,
  closeRoles: false,
  showPrivilegeSpinner: false,
  deletePrivilege: null,
  privilegeById: null,
  editPrivilegeById: {},
  specialityList: [],
  specialityById: null,
  editSpecialityById: {},
  facilitiesList: [],
  specialityData: [],
  facilityData: [],
  facilityById: null,
  editFacilityById: {},
  deleteSpeciality: null,
  deleteFacility: null,
  createFacilityError: null,
  createSpecialityError: null,
  loadingState: false,
  createPrivilegeError: null,
  deleteSpecialityError: null,
  deleteFacilityError: null,
  deletePrivilegeError: null,
  isPermissionsFetchedForCreatePrivilege: false,
  isPermissionsFetchedForEditPrivilege: false,
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageFacility: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessagePrivileges: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageSpeciality: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageDevices: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
};

export const hospitalManagementReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_HOSPITAL_DEVICES_LIST:
      return {
        ...state,
        devicesList: action.payload?.data,
      };

    case GET_HOSPITAL_DEVICE_BY_ID:
      return {
        ...state,
        deviceById: action.payload?.data,
      };
    case EDIT_HOSPITAL_DEVICE_BY_ID:
      return {
        ...state,
        editDeviceById: action.payload?.data,
      };
    case UPDATE_HOSPITAL_DEVICES_LIST:
      return {
        ...state,
        editedDevicesList: {},
      };

    case GET_PRIVILEGES_LIST:
      return {
        ...state,
        privilegesList: action.payload?.data,
      };

    case PRIVILEGE_SPINNER:
      return {
        ...state,
        closeRoles: false,
        permissionList: [],
      };

    case GET_PERMISSION_LIST:
      return {
        ...state,
        permissionList: action.payload,
      };

    case ROLESPERMISSION_DATA:
      return {
        ...state,
        rolesPermissionData: action.payload?.data,
      };

    case CREATE_PRIVILEGES:
      return {
        ...state,
        privilegesList: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.privilegesList?.data?.results || []),
            ],
          },
        },
        showRoleSpinner: false,
        closeRoles: true,
      };

    case DELETE_PRIVILEGE:
      return {
        ...state,
        deletePrivilege: action.payload?.data,
      };
    case GET_PRIVILEGE_BY_ID:
      return {
        ...state,
        privilegeById: action.payload?.data,
      };
    case EDIT_PRIVILEGE_BY_ID:
      return {
        ...state,
        editPrivilegeById: action.payload?.data,
        closeRoles: true,
      };
    case GET_SPECIALITY_LIST:
      return {
        ...state,
        specialityList: action.payload?.data,
        createSpecialityError: null,
      };
    case GET_ALL_SPECIALITIES_LIST:
      return {
        ...state,
        allSpecialitiesList: action.payload?.data,
      };
    case CANCEL_PREVIOUS_ERROR_MESSAGE:
      return {
        ...state,
        createSpecialityError: null,
      };

    case GET_SPECIALITY_BY_ID:
      return {
        ...state,
        specialityById: action.payload?.data,
      };
    case GET_SPECIALITY_HOSPITAL_LIST:
      const previousData = state.hospitalListByHosp?.data?.results || [];
      state.hospitalListByHosp = action.payload;
      state.hospitalListByHosp.data.results = [
        // ...previousData,
        ...(state.hospitalListByHosp.data.results || []),
      ];
      return {
        ...state,
      };
    case EDIT_SPECIALITY_BY_ID:
      return {
        ...state,
        editSpecialityById: action.payload?.data,
      };

    case CREATE_SPECIALITY:
      return {
        ...state,
        specialityData: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.specialityData?.data?.results || []),
            ],
          },
        },
      };

    case GET_FACILITIES_LIST:
      return {
        ...state,
        facilitiesList: action.payload?.data,
      };

    case CREATE_FACILITY:
      return {
        ...state,
        facilityData: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.facilityData?.data?.results || []),
            ],
          },
        },
      };
    case GET_FACILITY_BY_ID:
      return {
        ...state,
        facilityById: action.payload?.data,
      };

    case EDIT_FACILITY_BY_ID:
      return {
        ...state,
        editFacilityById: action.payload?.data,
      };
    case DELETE_SPECIALITY:
      return {
        ...state,
        deleteSpeciality: action.payload,
      };
    case DELETE_SPECIALITY_ERROR:
      return {
        ...state,
        deleteSpecialityError: action.payload,
      };
    case DELETE_FACILITY_ERROR:
      return {
        ...state,
        deleteFacilityError: action.payload,
      };
    case DELETE_PRIVILEGE_ERROR:
      return {
        ...state,
        deletePrivilegeError: action.payload,
      };
    case DELETE_FACILITY:
      return {
        ...state,
        deleteFacility: action.payload,
      };
    case CREATE_FACILITY_ERROR:
      return {
        ...state,
        createFacilityError: action.payload[0].details,
      };
    case CANCEL_FACILITY_ERROR:
      return {
        ...state,
        createFacilityError: null,
      };
    case CREATE_SPECIALITY_ERROR:
      return {
        ...state,
        createSpecialityError: action.payload[0].details,
      };

    case CREATE_PRIVILEGE_ERROR:
      return {
        ...state,
        createPrivilegeError: action.payload[0].details,
      };
    case CLEAR_PRIVILAGE_ERROR:
      return {
        ...state,
        createPrivilegeError: null,
      };
    case LOADER:
      if (action.payload.calledFrom === "GET_FACILITIES_LIST") {
        return {
          ...state,
          loaderWithMessageFacility: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_HOSPITAL_DEVICES_LIST") {
        return {
          ...state,
          loaderWithMessageDevices: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_PRIVILEGES_LIST") {
        return {
          ...state,
          loaderWithMessagePrivileges: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_SPECIALITY_LIST") {
        return {
          ...state,
          loaderWithMessageSpeciality: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
    case STOPLOADER:
      if (action.payload.calledFrom === "GET_FACILITIES_LIST") {
        return {
          ...state,
          loaderWithMessageFacility: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_HOSPITAL_DEVICES_LIST") {
        return {
          ...state,
          loaderWithMessageDevices: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_PRIVILEGES_LIST") {
        return {
          ...state,
          loaderWithMessagePrivileges: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_SPECIALITY_LIST") {
        return {
          ...state,
          loaderWithMessageSpeciality: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      }
    case IS_PERMISSIONS_FETCHED_FOR_CREATE_PRIVILEGE:
      return {
        ...state,
        isPermissionsFetchedForCreatePrivilege:
          action.payload?.isPermissionsFetchedForCreatePrivilege,
      };
    case IS_PERMISSIONS_FETCHED_FOR_EDIT_PRIVILEGE:
      return {
        ...state,
        isPermissionsFetchedForEditPrivilege:
          action.payload?.isPermissionsFetchedForEditPrivilege,
      };
    default:
      return {
        ...state,
      };
  }
};
