import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

export const getVideoList =
  (
    deviceType: number,
    procedure = "",
    page = 1,
    nextUrl = "",
    search?: any,
    sortBy?: any,
    isDropDown?: any
  ) =>
  async (dispatch: any) => {
    const sortVideo = sortBy !== "" ? `&ordering=${sortBy}` : "";
    dispatch(showVideoSpinner());

    let defaultUrl = `${apiEndPoint}/videos/search?page=${page}&page_size=12`;
    if (procedure) {
      defaultUrl = `${defaultUrl}&primary_procedure_type=${procedure}`;
    }
    if (search) {
      defaultUrl = `${defaultUrl}&search=${search}`;
    }
    if (sortVideo) {
      defaultUrl = `${defaultUrl}${sortVideo}`;
    }
    if (deviceType === 2) {
      defaultUrl = `${defaultUrl}&is_uploaded_by_user=true`;
    } else if (deviceType === 1) {
      defaultUrl = `${defaultUrl}&is_uploaded_by_user=false`;
    }

    const payload = {
      method: "GET",
      url: nextUrl ? nextUrl : defaultUrl,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.VIDEOS,
        payload: { data: response.data },
      });
    }
  };

export const showVideoSpinner = () => {
  return {
    type: allActions.SHOW_LOADER,
  };
};

export const getVideoCountList =
  (deviceType = 1, procedure = "", nextUrl = "") =>
  async (dispatch: any) => {
    const deviceTypePayload: boolean = deviceType === 2 ? true : false;
    const defaultUrl = `${apiEndPoint}/developer/videos/procedures?page=1&page_size=1000&user=${deviceTypePayload}`;
    const payload = {
      method: "GET",
      url: nextUrl ? nextUrl : defaultUrl,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: nextUrl
          ? allActions.VIDEOS_PROCEDURE
          : allActions.REFRESH_VIDEOS_PROCEDURE,
        payload: { data: response.data },
      });
    }
  };
