import {
  ActionButton,
  Dialog,
  DialogFooter,
  FontIcon,
  Image,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import React from "react";
import { ThemeColorPalette } from "../../../theme";
import videoImg from "../../../assets/icon_4.svg";
import InprogressVideoEdit from "../../../InProgress/components/InprogressVideoEdit";
import { connect } from "react-redux";
import {
  deleteSelectedItem,
  handleMultiDataSave,
  updateSingleMultiData,
  updateSingleSelectData,
  uploadMetaDataAction,
  uploadMultiMetaDataAction,
  updatePatientConsentForm,
  updateUploadVideosListAfterUploadButtonClick,
} from "../../actions/uploadVideoMetaDataActions";
import MultiSelectVideoDetails from "./MultiSelectVideoDetails";
import { setInprogressData } from "../../../InProgress/actions/InProgressAction";
import { uploadFile } from "../../../HospitalAdminModule/InProgress/actions/InProgressAction";

export interface VideoDetailsUpdateProps {
  updatePatientConsentForm: any;
  uploadingVideosData: Array<any>;
  updateSingleSelectData: any;
  updateMultiSelectData: any;
  deleteSelectedItem: any;
  handleFileChange: any;
  uploadFile: (data: any) => void;
  hiddenFileInputRef: any;
  proceduresData: any;
  userData: Array<any>;
  uploadMetaDataAction: any;
  uploadMultiMetaDataAction: any;
  filterUploadingData: any;
  userProfile: any;
  handleMultiDataSave: any;
  updateUploadVideosListAfterUploadButtonClick: any;
  uploadAlerts: boolean;
  handlePopup: any;
  dialogContentProps: any;
  sequenceCheck?: boolean;
}

export interface VideoDetailsUpdateState {
  videoDetailScreen: any;
  isUploadConsentFormError: any;
  largePdfFileError: any;
}

class VideoDetailsUpdate extends React.Component<
  VideoDetailsUpdateProps,
  VideoDetailsUpdateState
> {
  state = {
    videoDetailScreen: {},
    isUploadConsentFormError: undefined,
    largePdfFileError: undefined,
  };

  handleFileClick = (event?: any) => {
    this.props.hiddenFileInputRef.current.click();
  };

  handleVideoSelect = (data: any) => {
    this.props.updateSingleSelectData(data);
  };

  onSequenceChange = (data: any, val: boolean) => {
    const value = {
      ...data,
      videoData: {
        ...data.videoData,
        mainVideos: { ...data?.videoData?.mainVideos, isDeIdentified: val },
      },
    };
    this.props.uploadMetaDataAction(value);
  };

  handleMultiSelect = (evt: React.MouseEvent, data: any) => {
    this.props.updateMultiSelectData(data);
    evt.stopPropagation();
  };

  handleChange = (
    e: any,
    data: any,
    selectedOption?: any,
    selectedField?: string,
    selectType?: string
  ) => {
    if (selectedField === "procedureName") {
      const value = {
        ...data,
        primaryProcedureName: selectedOption.text,
        videoData: {
          ...data.videoData,
          primaryProcedureId: selectedOption.key,
        },
      };
      selectType === "multi"
        ? this.props.uploadMultiMetaDataAction(value)
        : this.props.uploadMetaDataAction(value);
    } else if (selectedField === "surgeonName") {
      const value = {
        ...data,
        primarySurgeonName: selectedOption.text,
        videoData: {
          ...data.videoData,
          primarySurgeonId: selectedOption.key,
        },
      };
      selectType === "multi"
        ? this.props.uploadMultiMetaDataAction(value)
        : this.props.uploadMetaDataAction(value);
    } else if (selectedField === "procedureName2") {
      const value = {
        ...data,
        secondaryProcedureName: selectedOption.text,
        videoData: {
          ...data.videoData,
          secondaryProcedureId: selectedOption.key,
        },
      };
      selectType === "multi"
        ? this.props.uploadMultiMetaDataAction(value)
        : this.props.uploadMetaDataAction(value);
    } else if (selectedOption === "date") {
      const value = {
        ...data,
        videoData: {
          ...data.videoData,
          surgeryDate: e,
        },
      };
      selectType === "multi"
        ? this.props.uploadMultiMetaDataAction(value)
        : this.props.uploadMetaDataAction(value);
    } else {
      const value = {
        ...data,
        videoData: {
          ...data.videoData,
          [e.target.name]: e.target.value,
        },
      };
      selectType === "multi"
        ? this.props.uploadMultiMetaDataAction(value)
        : this.props.uploadMetaDataAction(value);
    }
  };

  handlePatientConsentFormFileChange = (evt: any, data: any) => {
    this.setState({ isUploadConsentFormError: null });
    this.setState({ largePdfFileError: null });
    const fileObj = evt.target.files[0];
    const fileType = fileObj.type;
    const fileSize: any = fileObj.size
      ? (fileObj.size / (1024 * 1024)).toFixed(2)
      : 0;

    if (parseFloat(fileSize) > 5) {
      this.setState({
        largePdfFileError: "Please Upload File less than 5 Mb",
      });
      return true;
    }
    if (
      fileType === "image/png" ||
      fileType === "image/PNG" ||
      fileType.includes("pdf")
    ) {
      const val = { ...data, patientConsentForm: evt.target.files[0] };
      this.props.updatePatientConsentForm(val);
    } else {
      const val = { ...data, patientConsentForm: null };
      this.props.updatePatientConsentForm(val);
      this.setState({ isUploadConsentFormError: "File type not supported" });
    }
  };

  handleDelete = (evt: React.MouseEvent, data: object) => {
    this.props.deleteSelectedItem(data);
    evt.stopPropagation();
  };

  handleUpload = async (evt: React.FormEvent, data: any) => {
    evt.stopPropagation();
    if (!data.upload) return;
    this.props.updateUploadVideosListAfterUploadButtonClick(data.id);

    const responseStatus = await this.props.filterUploadingData(data);
    if (responseStatus === 200) {
      this.props.uploadFile(data);
    } else if (responseStatus !== 403 && responseStatus !== 401) {
      alert("Please upload the file lessthan 5 mb!");
    }
  };

  uploadAllData = () => {
    this.props.uploadingVideosData.forEach(async (data: any) => {
      this.props.updateUploadVideosListAfterUploadButtonClick(data.id);
      const responseStatus = await this.props.filterUploadingData(data);
      if (responseStatus === 200) {
        this.props.uploadFile(data);
        // this.props.updateUploadVideosListAfterUploadButtonClick(data.id);
      } else {
        alert(
          "Please check all mandatory fields are enetered/valid data is entered!"
        );
      }
    });
  };

  handleMultiDataSave = (data: any) => {
    const val = {
      primaryProcedureId: data.videoData.primaryProcedureId,
      primaryProcedureName: data.primaryProcedureName,
      primarySurgeonId: data.videoData.primarySurgeonId,
      primarySurgeonName: data.primarySurgeonName,
    };
    this.props.handleMultiDataSave(val);
  };

  render() {
    const { uploadingVideosData, handleFileChange, hiddenFileInputRef } =
      this.props;
    const selectedVideos = uploadingVideosData.filter(
      (video: any) => video.isSelected
    );
    const inprogressVideo = uploadingVideosData.filter(
      (data: any) => data.inprogress
    );

    const readyToUploadDataLength = uploadingVideosData.filter(
      (data: any) => data.upload
    ).length;

    return (
      <Stack
        styles={{
          root: {
            height: "100%",
          },
        }}
      >
        <Stack
          verticalFill
          styles={{
            root: {
              width: "89.5%",
              backgroundColor: ThemeColorPalette.metrixbg,
              marginLeft: "5rem",
              marginBottom: "3rem",
              marginTop: "1rem",
            },
          }}
        >
          <StackItem>
            <Stack horizontal>
              <StackItem
                styles={{
                  root: {
                    flex: 4,
                    height: "70vh",
                    paddingBottom: "2rem",
                    borderRight: "1px solid " + ThemeColorPalette.pureBlack,
                  },
                }}
              >
                <Stack verticalFill>
                  <StackItem
                    styles={{
                      root: {
                        margin: "0.2rem 0.2rem 1rem 0.5rem",
                      },
                    }}
                  >
                    {uploadingVideosData.length < 5 && (
                      <StackItem
                        styles={{
                          root: {
                            padding: "0.2rem 0 0.2rem 0.8rem",
                          },
                        }}
                      >
                        <input
                          type="file"
                          accept="video/mp4,video/x-m4v,video/*"
                          multiple
                          ref={hiddenFileInputRef}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <ActionButton
                          styles={{
                            root: {
                              color: ThemeColorPalette.uploadVideoBtnTxt,
                            },
                          }}
                          iconProps={{ iconName: "Add" }}
                          allowDisabledFocus
                          onClick={this.handleFileClick}
                        >
                          Add Videos
                        </ActionButton>
                        <Dialog
                          hidden={!this.props.uploadAlerts}
                          onDismiss={this.props.handlePopup}
                          dialogContentProps={this.props.dialogContentProps}
                          styles={{
                            root: {
                              background: "blur",
                            },
                          }}
                        >
                          <DialogFooter>
                            <PrimaryButton
                              text="Ok"
                              onClick={this.props.handlePopup}
                            />
                          </DialogFooter>
                        </Dialog>
                      </StackItem>
                    )}
                  </StackItem>
                  <StackItem
                    align="center"
                    styles={{
                      root: {
                        margin: "1rem",
                      },
                    }}
                  >
                    <Stack
                      verticalFill
                      verticalAlign="start"
                      tokens={{ childrenGap: 15 }}
                      styles={{ root: { width: "90%" } }}
                    >
                      {uploadingVideosData.length > 0 &&
                        uploadingVideosData.map((data: any, i: any) => {
                          return (
                            i < 5 &&
                            !data.inprogress && (
                              <Stack
                                key={i}
                                horizontal
                                horizontalAlign="space-between"
                                styles={{
                                  root: {
                                    backgroundColor: data.isSelected
                                      ? ThemeColorPalette.selectedGridBG
                                      : ThemeColorPalette.gridBG,
                                    boxShadow:
                                      "7px 7px " + ThemeColorPalette.pureBlack,
                                    padding: "1rem 1rem",
                                    width: "24rem",
                                    flex: 1,
                                    minHeight: "5.5rem",
                                    maxHeight: "5.5rem",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() => this.handleVideoSelect(data)}
                              >
                                <Stack
                                  styles={{
                                    root: {
                                      height: "1rem",
                                      width: "1rem",
                                      border: "1px solid ",
                                      borderRadius: "50%",
                                      margin: "-0.5rem 1rem 0.2rem -0.4rem",
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={(evt) =>
                                    this.handleMultiSelect(evt, data)
                                  }
                                >
                                  {data.isSelected && (
                                    <FontIcon
                                      iconName="SkypeCircleCheck"
                                      style={{
                                        color: ThemeColorPalette.themePrimary,
                                        visibility: data.isSelected
                                          ? "visible"
                                          : "hidden",
                                      }}
                                    />
                                  )}
                                </Stack>
                                <StackItem
                                  align="center"
                                  styles={{ root: { flex: 1 } }}
                                >
                                  <Image
                                    src={videoImg}
                                    width={"2rem"}
                                    height={"2rem"}
                                  />
                                </StackItem>
                                <StackItem
                                  align="center"
                                  styles={{
                                    root: {
                                      flex: 8,
                                      paddingLeft: "1rem",
                                      width: "4.2rem",
                                      overflowWrap: "break-word",
                                    },
                                  }}
                                >
                                  <TooltipHost content={data.file.name}>
                                    <Text variant="smallPlus">
                                      {data.file.name.slice(0, 70)}
                                    </Text>
                                  </TooltipHost>{" "}
                                  <StackItem>
                                    <Text
                                      variant="tiny"
                                      styles={{ root: { opacity: 0.5 } }}
                                    >
                                      Size :{" "}
                                      {(data.file.size / 1073741824).toFixed(2)}
                                      GB
                                    </Text>
                                  </StackItem>
                                </StackItem>
                                <StackItem
                                  styles={{
                                    root: {
                                      paddingTop: "0.4rem",
                                      paddingRight: "0.5rem",
                                      cursor: "pointer",
                                    },
                                  }}
                                  align="center"
                                >
                                  {" "}
                                  <TooltipHost content="Upload">
                                    <FontIcon
                                      iconName="Upload"
                                      style={{
                                        color: data.upload
                                          ? ThemeColorPalette.ribbon
                                          : ThemeColorPalette.uploadVideoBtnTxt,
                                      }}
                                      onClick={(evt: React.FormEvent) =>
                                        this.handleUpload(evt, data)
                                      }
                                    />
                                  </TooltipHost>
                                </StackItem>
                                <StackItem
                                  styles={{
                                    root: {
                                      paddingTop: "0.4rem",
                                      paddingRight: "0.5rem",
                                      paddingLeft: "0.3rem",
                                      cursor: "pointer",
                                    },
                                  }}
                                  align="center"
                                >
                                  <TooltipHost content="Delete">
                                    <FontIcon
                                      iconName="Delete"
                                      style={{
                                        color: ThemeColorPalette.themePrimary,
                                      }}
                                      onClick={(evt) =>
                                        this.handleDelete(evt, data)
                                      }
                                    />
                                  </TooltipHost>
                                </StackItem>
                              </Stack>
                            )
                          );
                        })}
                    </Stack>
                  </StackItem>
                </Stack>
              </StackItem>
              {(selectedVideos.length === 0 ||
                (selectedVideos.length === 0 &&
                  inprogressVideo.length > 0)) && (
                <StackItem
                  styles={{
                    root: {
                      flex: 8,
                      paddingTop: "15rem",
                    },
                  }}
                >
                  <Stack verticalFill horizontalAlign="center">
                    <StackItem align="center">
                      <FontIcon
                        iconName="MSNVideos"
                        style={{
                          fontSize: "3rem",
                          color: ThemeColorPalette.uploadMsgTxt,
                          opacity: 0.25,
                          fontWeight: 1,
                        }}
                      />
                    </StackItem>
                    <StackItem>
                      <Text
                        variant="small"
                        styles={{
                          root: {
                            color: ThemeColorPalette.uploadMsgTxt,
                            opacity: 0.25,
                          },
                        }}
                      >
                        Select any video to add details
                      </Text>
                    </StackItem>
                    <StackItem>
                      <Text
                        variant="small"
                        styles={{
                          root: {
                            color: ThemeColorPalette.uploadMsgTxt,
                            opacity: 0.25,
                          },
                        }}
                      >
                        You can also select multiple to edit details in bulk
                      </Text>
                    </StackItem>
                  </Stack>
                </StackItem>
              )}
              {selectedVideos.length === 1 && (
                <StackItem
                  styles={{
                    root: {
                      flex: 8,
                      paddingTop: "1rem",
                    },
                  }}
                >
                  {uploadingVideosData.map((data) => {
                    return (
                      data.isSelected &&
                      !data.inprogress && (
                        <Stack
                          key={data.id}
                          styles={{ root: { padding: "-2rem 0 1rem 2rem" } }}
                        >
                          <InprogressVideoEdit
                            handlePatientConsentFormFileChange={
                              this.handlePatientConsentFormFileChange
                            }
                            isUploadConsentFormError={
                              this.state.isUploadConsentFormError
                            }
                            largePdfFileError={this.state.largePdfFileError}
                            handleChange={this.handleChange}
                            data={data}
                            dropdownVals={this.props.proceduresData}
                            userData={this.props.userData}
                            userProfile={this.props.userProfile}
                            screen="detailsUpdateScreen"
                            onSequenceChange={(val) =>
                              this.onSequenceChange(data, val)
                            }
                          />
                        </Stack>
                      )
                    );
                  })}
                </StackItem>
              )}
              {selectedVideos.length > 1 && (
                <StackItem
                  styles={{
                    root: {
                      flex: 8,
                      paddingTop: "1rem",
                      paddingLeft: "3rem",
                    },
                  }}
                >
                  <MultiSelectVideoDetails
                    handleChange={this.handleChange}
                    data={selectedVideos[0]}
                    dropdownVals={this.props.proceduresData}
                    userData={this.props.userData}
                    handleSave={this.handleMultiDataSave}
                  />
                </StackItem>
              )}
            </Stack>
          </StackItem>
        </Stack>
        <Stack
          styles={{
            root: {
              height: "4rem",
              backgroundColor: ThemeColorPalette.gridBG,
              position: "fixed",
              bottom: 0,
              width: "100%",
            },
          }}
        >
          <StackItem
            styles={{
              root: {
                padding: "1rem 0 1rem 3rem",
                position: "fixed",
                bottom: 0,
                width: "100%",
              },
            }}
          >
            <PrimaryButton
              type="Submit"
              text="Upload All"
              disabled={
                uploadingVideosData.length > 1 &&
                uploadingVideosData.length === readyToUploadDataLength
                  ? false
                  : true
              }
              onClick={this.uploadAllData}
            ></PrimaryButton>
          </StackItem>
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    uploadingVideosData: state.uploadVideoMetaDataReducer.uploadingVideosData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSingleSelectData: (data: any) =>
      dispatch(updateSingleSelectData(data)),
    updateMultiSelectData: (data: any) => dispatch(updateSingleMultiData(data)),
    deleteSelectedItem: (data: any) => dispatch(deleteSelectedItem(data)),
    uploadMetaDataAction: (data: any) => dispatch(uploadMetaDataAction(data)),
    uploadMultiMetaDataAction: (data: any) =>
      dispatch(uploadMultiMetaDataAction(data)),
    filterUploadingData: (data: any) => dispatch(setInprogressData(data)),
    uploadFile: (data: any) => dispatch(uploadFile(data)),
    updatePatientConsentForm: (data: any) =>
      dispatch(updatePatientConsentForm(data)),
    updateUploadVideosListAfterUploadButtonClick: (videoId: any) =>
      dispatch(updateUploadVideosListAfterUploadButtonClick(videoId)),
    handleMultiDataSave: (data: any) => dispatch(handleMultiDataSave(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetailsUpdate);
