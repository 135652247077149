import {
  Stack,
  Image,
  Text,
  IconButton,
  IIconProps,
  IContextualMenuProps,
} from "@fluentui/react";
import React from "react";
import "./Video.scss";
import thumbnail from "../../../../assets/thumbnail.png";
import playImage from "../../../../assets/play_icon.svg";
import { ThemeColorPalette } from "../../../../theme";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../../Layout/constants/constants";
const addIcon: IIconProps = { iconName: "MoreVertical" };

const Videos = ({
  video,
  videoId,
  loadedFrom = "",
  showProcedureVideos = "",
  _onMenuClickSetVideoId,
  showDeleteVideoModal,
  is_uploaded_by_user = false,
}: any) => {
  const userRole = localStorage.getItem("role");
  const navigate = useNavigate();
  // const menuProps: IContextualMenuProps = {
  //   items: [
  //     {
  //       key: "delete",
  //       text: "Delete",
  //       onClick: showDeleteVideoModal,
  //     },
  //   ],
  // };
  return (
    <Stack>
      <Stack.Item id="videoCard">
        <Stack.Item>
          <Stack.Item className="videoCardImageSection">
            {video.thumbnail ? (
              <Image
                src={"data:image/png;base64," + video.thumbnail}
                width="100%"
                height="100px"
                style={{ objectFit: "contain" }}
              ></Image>
            ) : (
              <Image
                src={thumbnail}
                width="100%"
                height="100px"
                style={{ objectFit: "contain" }}
              ></Image>
            )}
            {userRole === "ActivAdmin" ? (
              <CheckPermission {...SCOPES.PLAY_VIDEO}>
                <Image
                  src={playImage}
                  className="playIcon"
                  width="50px"
                  height="50px"
                  onClick={(evt: React.MouseEvent) => {
                    navigate("/video/" + videoId, {
                      state: {
                        loadedFrom,
                        showProcedureVideos,
                        is_uploaded_by_user,
                      },
                    });
                    evt.preventDefault();
                  }}
                ></Image>
              </CheckPermission>
            ) : (
              <Image
                src={playImage}
                className="playIcon"
                width="50px"
                height="50px"
                onClick={(evt: React.MouseEvent) => {
                  navigate("/video/" + videoId, {
                    state: {
                      loadedFrom,
                      showProcedureVideos,
                      is_uploaded_by_user,
                    },
                  });
                  evt.preventDefault();
                }}
              ></Image>
            )}
            <Text variant="small" className="duration">
              {video && video.duration ? video.duration.slice(0, 8) : ""}
            </Text>
          </Stack.Item>
          <Stack.Item className="videoCardTextSection">
            <Stack horizontal horizontalAlign="space-between" dir="ltr">
              <Text
                block
                className="cardHeading"
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipse",
                  width: "95%",
                  overflow: "hidden",
                }}
              >
                {video.hospital_name ? video.hospital_name : "lorem ipsum"}
              </Text>
              {/* <IconButton
                className="buttonIcon"
                menuIconProps={addIcon}
                // menuProps={menuProps}
                ariaLabel="New item"
                onMenuClick={() => _onMenuClickSetVideoId(video?.id)}
              /> */}
            </Stack>
            <Stack className="videoCardFooter">
              {/* <Text block variant="small">
                {video.hospital_name}
              </Text> */}
              <Text block variant="xSmall">
                {moment(video.uploaded_date).format("MM-DD-YYYY")}
              </Text>
            </Stack>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};
export default Videos;
