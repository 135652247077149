import React from "react";
import { Stack, Text, Image } from "@fluentui/react";
import "./TotalCard.scss";

const TotalCard = ({
  cname,
  Imgsrc,
  hospitalsCount,
  name,
}: {
  cname: string;
  Imgsrc: string;
  hospitalsCount: any;
  name: String;
}) => {
  const prefixZero = (num: Number) => {
    const numLength = String(num).length;
    return num >= 100 ? ("0" + num).slice(-numLength) : ("0" + num).slice(-2);
  };
  const { total, ...otherCounts } = hospitalsCount || {};
  return (
    <Stack.Item styles={{ root: { flex: 4, position: "relative" } }}>
      {/* <Text>harish</Text> */}
      <Stack horizontal className={`totalCard ${cname}`}>
        <Stack.Item>
          <Image
            className="home-img"
            src={Imgsrc}
            alt='Example of the image fit value "center" on an image larger than the frame.'
          />
        </Stack.Item>
        <Stack.Item className="totalCardCenter">
          <Text className="totalCardCenterText">
            <Text block>Total</Text>
            <Text>{name} </Text>
          </Text>
          <Text className="totalCardCenterCount">
            {total ? prefixZero(total) : "00"}
          </Text>
        </Stack.Item>
        {Object.keys(otherCounts).length <= 2 ? (
          <Stack.Item className="totalCardRight">
            <Text block>
              Main Hospitals -{" "}
              {otherCounts?.main_hospitals
                ? prefixZero(otherCounts?.main_hospitals)
                : "00"}
            </Text>
            <Text block>
              Other Facilities -{" "}
              {otherCounts?.facilities
                ? prefixZero(otherCounts?.facilities)
                : "00"}
            </Text>
          </Stack.Item>
        ) : (
          <Stack.Item className="totalCardRight">
            <Text block>
              Consignment -{" "}
              {otherCounts?.consignment
                ? prefixZero(otherCounts?.consignment)
                : "00"}
            </Text>
            <Text block>
              Demo - {otherCounts?.demo ? prefixZero(otherCounts?.demo) : "00"}
            </Text>
            <Text block>
              Trial -
              {otherCounts?.trial ? prefixZero(otherCounts?.trial) : "00"}
            </Text>
            <Text block>
              Unallocated -
              {otherCounts?.unallocated_devices
                ? prefixZero(otherCounts?.unallocated_devices)
                : "00"}
            </Text>
          </Stack.Item>
        )}
      </Stack>
    </Stack.Item>
  );
};

export default TotalCard;
