import { IBreadcrumbItem } from "@fluentui/react";
import * as React from "react";
import "../../../common/procedureVideos/ProcedureVideos.scss";
import { connect } from "react-redux";
import ProcedureVideos from "../../../common/procedureVideos/ProcedureVideos";
import {
  setSelectedVideo,
  setSelectedVideoUnfavourite,
  getVideosForProcedures,
  editVideoDetails,
  clearProceduresDataWhenUnmount,
  showSpinnerOnLoad,
  resetEditedDataonCancel,
} from "../../actions/proceduresAction";
import { postSharedDetails } from "../../../Shared/actions/sharedVideosAction";
import { withRouter } from "../../../withRouter";

interface ProceduresProps {
  getVideosAction: any;
  videosData: any;
  params: any;
  setSelectedVideo: any;
  proceduresData: any;
  userData: any;
  userProfile: any;
  history: any;
  videoEditedFields: any;
  setSelectedVideoUnfavourite: any;
  editVideoDetails: any;
  postSharedDetails: any;
  showSpinnerOnLoad: any;
  showSpinner: boolean;
  showData: boolean;
  clearProceduresDataWhenUnmount: any;
  currentPage: number;
  resetEditedDataonCancel: any;
}

interface ProceduresState {
  selectedNavLink: string;
  selectedDisplayType: string;
  currentVideoPage: any;
}

class Procedures extends React.Component<ProceduresProps, ProceduresState> {
  constructor(props: ProceduresProps) {
    super(props);
    this.state = {
      selectedNavLink: "procedures",
      selectedDisplayType: "gridView",
      currentVideoPage: 1,
    };
  }

  // componentDidMount() {
  //   this.props.showSpinnerOnLoad();
  //   this.props.getVideosAction(this.props.params.id, 1);
  //   window.scrollTo(0, 0);
  // }
  componentWillMount(): void {
    const currentVideoPage = localStorage.getItem("currentVideoPage") || 1;
    this.setState({ currentVideoPage: currentVideoPage });
    this.props.showSpinnerOnLoad();
    this.props.getVideosAction(this.props.params.id, currentVideoPage);
    window.scrollTo(0, 0);
  }

  handleViews = (event: React.MouseEvent<any>): any => {
    const { name } = event.currentTarget as any;
    this.setState({ selectedDisplayType: name });
  };

  handleClick = (evt: React.MouseEvent, item: any) => {
    this.props.setSelectedVideo(item);
  };

  handleFavouiteVideoClick = () => {
    const [data] = this.props.videosData.results.filter(
      (item: any) => item.isSelected
    );
    this.props.setSelectedVideoUnfavourite(data);
  };

  componentWillUnmount() {
    this.props.clearProceduresDataWhenUnmount();
  }

  render() {
    const breadcrumbItems: IBreadcrumbItem[] = [
      {
        text: "Home",
        key: "Home",
        onClick: () => {
          this.props.history("/");
        },
      },
      {
        text:
          this.props.videosData &&
          this.props.videosData.results &&
          this.props.videosData.results.length &&
          this.props.videosData.results[0].procedure_name,
        key: "f1",
      },
    ];
    return (
      <ProcedureVideos
        history={this.props.history}
        breadcrumbItems={breadcrumbItems}
        videosData={this.props.videosData}
        setSelectedVideo={this.handleClick}
        setSelectedVideoUnfavourite={this.props.setSelectedVideoUnfavourite}
        proceduresData={this.props.proceduresData}
        userData={this.props.userData}
        userProfile={this.props.userProfile}
        editVideoDetails={this.props.editVideoDetails}
        selectedVideoEditedFields={this.props.videoEditedFields}
        postSharedDetails={this.props.postSharedDetails}
        handleFavouiteVideoClick={this.handleFavouiteVideoClick}
        getVideosAction={this.props.getVideosAction}
        showSpinner={this.props.showSpinner}
        showData={this.props.showData}
        screen={"procedures"}
        clearDataOnComponentUnmount={this.props.clearProceduresDataWhenUnmount}
        currentPage={this.state.currentVideoPage}
        procedureId={this.props.params.id}
        resetEditedDataonCancel={this.props.resetEditedDataonCancel}
        showSpinnerOnload={this.props.showSpinnerOnLoad}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    videosData: state.proceduresReducerData.videosData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
    videoEditedFields: state.proceduresReducerData.videoEditedFields,
    showSpinner: state.proceduresReducerData.showSpinner,
    showData: state.proceduresReducerData.showData,
    currentPage: state.proceduresReducerData.currentPage,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    getVideosAction: (procedureId: number, currentPage: number) =>
      dispatch(getVideosForProcedures(procedureId, currentPage)),
    setSelectedVideo: (data: any) => dispatch(setSelectedVideo(data)),
    setSelectedVideoUnfavourite: (data: any) =>
      dispatch(setSelectedVideoUnfavourite(data)),
    editVideoDetails: (data: any, videoId: number) =>
      dispatch(editVideoDetails(data, videoId)),
    postSharedDetails: (data: any) => dispatch(postSharedDetails(data)),
    showSpinnerOnLoad: () => dispatch(showSpinnerOnLoad()),
    clearProceduresDataWhenUnmount: () =>
      dispatch(clearProceduresDataWhenUnmount()),
    resetEditedDataonCancel: (type: string) =>
      dispatch(resetEditedDataonCancel(type)),
  };
};

export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(Procedures)
);
