import { UserAddFill } from "@fluent-ui/icons";
import {
  Checkbox,
  DatePicker,
  Dropdown,
  IDatePickerStrings,
  IDropdownOption,
  IDropdownStyles,
  Label,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import React, { FormEvent, memo, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SelectOptionType } from "../../common/contracts/globalTypes";
import { currentDate, getDateWithSlashInBetween } from "../../common/util";
import { getSurgeonsForDropdown } from "../../HospitalAdminModule/Home/actions/homeAction";
import { getProcedureDetailsForVideos } from "../../Layout/actions/layoutActions";
import { ROLE_TYPES } from "../../Layout/constants/constants";
import { AppState } from "../../rootReducer";
import { fonts, ThemeColorPalette } from "../../theme";
import "../containers/InProgress.scss";
import "./InprogressVideo.scss";
import * as constants from "../../../src/Layout/constants/constants";
import CheckPermission from "../../common/permissions/rolePermissions";
import { Tooltip, TooltipHost } from "office-ui-fabric-react";

interface InprogressVideoEditProps {
  userProfile?: any;
  handlePatientConsentFormFileChange?: any;
  handleEdit?: any;
  handleChange?: any;
  data: any;
  dropdownVals: Array<any>;
  userData: any;
  screen?: string;
  dropdownSelected?: boolean;
  onSequenceChange?: (val: boolean) => void;
  isUploadConsentFormError?: string;
  largePdfFileError?: string;
  fileTypeError?: string;
  errorObject?: any;
}

interface InprogressVideoEditState {
  value: null;
  selectedProcedure1: number;
  selectedProcedure2: number;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const DayPickerStrings: IDatePickerStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  shortDays: ["S", "M", "T", "W", "T", "F", "S"],
  goToToday: "Go to today",
  prevMonthAriaLabel: "Go to previous month",
  nextMonthAriaLabel: "Go to next month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
  closeButtonAriaLabel: "Close date picker",
  monthPickerHeaderAriaLabel: "{0}, select to change the year",
  yearPickerHeaderAriaLabel: "{0}, select to change the month",
};

const InprogressVideoEdit = ({
  userProfile,
  handlePatientConsentFormFileChange,
  handleChange,
  data,
  userData,
  screen,
  dropdownSelected,
  onSequenceChange,
  isUploadConsentFormError = "",
  largePdfFileError,
  fileTypeError,
  errorObject,
}: InprogressVideoEditProps) => {
  const hiddenFileInputRef: any = React.useRef(null);
  const [selectedProcedure1, setSelectedProcedure1] = React.useState(null);
  const [selectedProcedure2, setSelectedProcedure2] = React.useState(null);
  const [sequenceCheck, setSequenceCheck] = React.useState(false);
  const [primiaryProcedure, setPrimaryProcedureValue] = useState();
  const [surgeonOptions, setSurgeonOptions] = useState<any>([]);
  const [mapVideoPermission, setMapVideoPermission] =
    React.useState<any>(false);
  const [defaultSurgeonSelected, setDefaultSurgeonSelected] = useState<any>({
    label: "",
    value: "",
  });

  const { control } = useForm();
  const dispatch = useDispatch();

  const procedureForVideoUpload = useSelector((state: AppState) => {
    return state.dashboardReducerData?.procedureForVideoUpload;
  });

  const allSurgeons = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.activitySurgeons;
  });
  const procedureForVideoUploadCount = procedureForVideoUpload?.count;
  const allSurgeonsCount = allSurgeons?.count;

  // useEffect(() => {

  // }, [allSurgeons]);
  useEffect(() => {
    const permission = constants.getPermissionsbyItem("vps.videos.surgeon.map");
    setMapVideoPermission(!permission);
    dispatch(getSurgeonsForDropdown());
  }, []);

  useEffect(() => {
    if (allSurgeons?.next)
      dispatch(getSurgeonsForDropdown("", allSurgeonsCount));
    const surgeonNameOptions: any = [];
    allSurgeons &&
      allSurgeons.results &&
      allSurgeons.results.reverse().forEach((val: any) => {
        surgeonNameOptions.push({
          value: val.id,
          label: val.displayName,
        });
        if (defaultSurgeonName === val.id) {
          setDefaultSurgeonSelected({
            label: val.displayName,
            value: val.id,
          });
        }
        if (data && data.uploaded_by && data.uploaded_by === val.userId)
          uploadedUser = val.displayName;
      });
    setSurgeonOptions(surgeonNameOptions);
  }, [allSurgeons]);

  let procedureDropDownOptions1: any = [];
  // screen === "detailsUpdateScreen"
  //   ? [{ key: 123, text: "Select Procedure Name" }]
  //   : [];

  let procedureDropDownOptions2: any = [
    // { key: 123, text: "Select Procedure Name" },
  ];

  const selectedPrimaryProcedure = data.videoData
    ? data.videoData.primaryProcedureId
    : data.primary_procedure_type;

  const selectedSecondaryProcedure = data.videoData
    ? data.videoData.secondaryProcedureId
    : data.secondary_procedure_type;

  let selectedProcedureInitialValue: any = {};
  let selectedSecondaryProcedureInitialValue: any = {};

  procedureForVideoUpload?.results &&
    procedureForVideoUpload.results.forEach((val: any) => {
      procedureDropDownOptions1.push({ value: val.id, label: val.name });
      procedureDropDownOptions2.push({ value: val.id, label: val.name });
      if (selectedPrimaryProcedure === val.id) {
        selectedProcedureInitialValue = { value: val.id, label: val.name };
      }
      if (selectedSecondaryProcedure === val.id) {
        selectedSecondaryProcedureInitialValue = {
          value: val.id,
          label: val.name,
        };
      }
    });
  if (
    data.primary_procedure_type &&
    !Object.keys(selectedProcedureInitialValue).length
  ) {
    procedureDropDownOptions1.splice(0, 0, {
      value: data.primary_procedure_type,
      label: data.procedure_name,
    });

    selectedProcedureInitialValue = {
      value: data.primary_procedure_type,
      label: data.procedure_name,
    };
  }

  if (
    data.secondary_procedure_type &&
    !Object.keys(selectedSecondaryProcedureInitialValue).length
  ) {
    procedureDropDownOptions2.splice(0, 0, {
      value: data.secondary_procedure_type,
      label: data?.secondary_procedure_name,
    });

    selectedSecondaryProcedureInitialValue = {
      value: data.secondary_procedure_type,
      label: data?.secondary_procedure_name,
    };
  }

  procedureDropDownOptions1 = React.useMemo(() => {
    // console.log('screen', screen);
    if (screen === "detailsUpdateScreen") {
      return procedureDropDownOptions1.filter(
        (val: any) => val.value !== selectedProcedure2 || val.valule === 123
      );
    } else if (screen === "inprogressUpdateScreen") {
      return procedureDropDownOptions1.filter(
        (val: any) =>
          val.value !== selectedProcedure2 &&
          val.value !== data.videoData.secondaryProcedureId
      );
    } else {
      return procedureDropDownOptions1.filter(
        (val: any) =>
          val.value !== selectedProcedure2 &&
          val.value !== data.secondary_procedure_type
      );
    }
  }, [selectedProcedure2, data, screen, procedureDropDownOptions1]);

  procedureDropDownOptions2 = React.useMemo(() => {
    if (screen === "detailsUpdateScreen") {
      return procedureDropDownOptions2.filter(
        (val: any) => val.value !== selectedProcedure1 || val.value === 123
      );
    } else if (screen === "inprogressUpdateScreen") {
      return procedureDropDownOptions2.filter(
        (val: any) =>
          val.value !== selectedProcedure1 &&
          val.value !== data.videoData.primaryProcedureId
      );
    } else {
      return procedureDropDownOptions2.filter(
        (val: any) =>
          val.value !== selectedProcedure1 &&
          val.value !== data.primary_procedure_type
      );
    }
  }, [selectedProcedure1, data, screen, procedureDropDownOptions2]);

  useEffect(() => {
    dispatch(getProcedureDetailsForVideos(procedureForVideoUploadCount));
  }, [procedureForVideoUploadCount]);

  let uploadedUser = "";
  const defaultSurgeonName = data.videoData
    ? data.videoData.primarySurgeonId
    : data.primary_surgeon;

  // const handleScrollPrimarySurgeons = () => {
  //   if (allSurgeons.next) {
  //     dispatch(getSurgeonsForDropdown(allSurgeons.next));
  //   }
  // };

  const handleFileClick = (event?: any) => {
    hiddenFileInputRef.current.click();
  };
  const username = localStorage.getItem("username");

  const PrimaySurgeonDetails = () => {
    const isSurgeon = userProfile?.role_name === ROLE_TYPES.ACTIVE_SURGEON;
    // isSurgeon && handleChange({}, data, { key: userProfile?.userId, value: userProfile?.displayName }, "surgeonName")
    return isSurgeon ? (
      <TextField
        label="Primary Surgeon"
        styles={{ root: { width: "18.7rem" } }}
        disabled
        defaultValue={userProfile?.displayName}
        required={false}
      />
    ) : (
      <Stack.Item>
        <Stack.Item className="label">
          <Text>Primary Surgeon Name*</Text>
        </Stack.Item>
        <Controller
          control={control}
          shouldUnregister
          name="surgeonName"
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              placeholder={
                <div className="text-white">Enter Surgeon Name*</div>
              }
              classNamePrefix="addl-class"
              // onMenuScrollToBottom={(e) => handleScrollPrimarySurgeons()}
              className="procedure_select"
              options={surgeonOptions}
            />
          )}
        />
      </Stack.Item>
    );
  };

  return (
    <Stack
      verticalFill
      styles={{
        root: {
          paddingLeft: "2rem",
          paddingRight: "1rem",
          paddingTop: "0.5rem",
        },
      }}
      tokens={{ childrenGap: 50 }}
    >
      <Text
        variant="xLarge"
        styles={{
          root: {
            fontFamily: fonts.DINPro_Regular,
            color: ThemeColorPalette.dashboardHeadingTxt,
            opacity: 0.8,
          },
        }}
      >
        Video Details
      </Text>
      {/* {console.log("Inprogress video edit ##@!@@#", data)} */}
      <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 30 }}>
          <Stack.Item>
            <Stack.Item className="label">
              <Text>Procedure Name*</Text>
            </Stack.Item>
            <Controller
              control={control}
              shouldUnregister
              name="procedureName"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  placeholder={
                    <div className="text-white">Select Procedure Name</div>
                  }
                  defaultValue={selectedProcedureInitialValue.label}
                  classNamePrefix="addl-class"
                  className="procedure_select"
                  options={procedureDropDownOptions1}
                  isDisabled={screen === "processing" ? true : false}
                  value={procedureDropDownOptions1?.find(
                    (c: any) => c.value === selectedProcedureInitialValue.value
                  )}
                  onChange={(val) => {
                    const option = { key: val.value, text: val.label };
                    handleChange({}, data, option, "procedureName");
                    setSelectedProcedure1(option?.key as any);
                  }}
                />
              )}
            />
          </Stack.Item>
          {userProfile?.role_name === ROLE_TYPES.ACTIVE_SURGEON ? (
            <TextField
              label="Primary Surgeon"
              styles={{ root: { width: "18.7rem" } }}
              disabled
              defaultValue={userProfile?.displayName}
              required={false}
            />
          ) : (
            <Stack.Item>
              <Stack.Item className="label">
                <Text>Primary Surgeon Name*</Text>
              </Stack.Item>
              {/* <Tooltip content="title" /> */}
              {!mapVideoPermission || !data.uploaded_by ? (
                <Controller
                  control={control}
                  shouldUnregister
                  name="surgeonName"
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      placeholder={
                        <div className="text-white">Enter Surgeon Name*</div>
                      }
                      classNamePrefix="addl-class"
                      // onMenuScrollToBottom={(e) => handleScrollPrimarySurgeons()}
                      className="procedure_select"
                      options={surgeonOptions}
                      value={surgeonOptions?.find(
                        (c: any) => c.value === defaultSurgeonSelected.value
                      )}
                      // isDisabled={mapVideoPermission}
                      defaultValue={defaultSurgeonSelected.label}
                      onChange={(val: any) => {
                        const option = { key: val.value, text: val.label };
                        handleChange({}, data, option, "surgeonName");
                        setDefaultSurgeonSelected(val);
                      }}
                    />
                  )}
                />
              ) : (
                <TooltipHost content="You do not have permission">
                  <StackItem
                    className="procedure_select"
                    style={{ width: "165%", marginTop: "8px" }}
                  >
                    <TextField
                      placeholder="Enter Surgeon Name"
                      type="text"
                      disabled={true}
                      value={defaultSurgeonSelected.label}
                    />
                  </StackItem>
                </TooltipHost>
              )}
            </Stack.Item>
          )}
        </Stack>

        <Stack horizontal tokens={{ childrenGap: 30 }}>
          <Stack.Item>
            <Stack.Item className="label">
              <Text>Secondary Procedure Name</Text>
            </Stack.Item>
            <Controller
              control={control}
              shouldUnregister
              name="procedureName2"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  placeholder={
                    <div className="text-white">Select Procedure Name</div>
                  }
                  defaultValue={selectedSecondaryProcedureInitialValue.label}
                  classNamePrefix="addl-class"
                  className="procedure_select"
                  options={procedureDropDownOptions2}
                  // isDisabled={screen === "processing" ? false : true}
                  value={procedureDropDownOptions2?.find(
                    (c: any) =>
                      c.value === selectedSecondaryProcedureInitialValue.value
                  )}
                  onChange={(val) => {
                    const option = { key: val.value, text: val.label };
                    handleChange({}, data, option, "procedureName2");
                    setSelectedProcedure2(option?.key as any);
                  }}
                />
              )}
            />
          </Stack.Item>
          <DatePicker
            strings={DayPickerStrings}
            showWeekNumbers={false}
            firstWeekOfYear={1}
            showMonthPickerAsOverlay={true}
            placeholder="Select A Date"
            label="Date of Procedure"
            styles={{ root: { width: "18.7rem" } }}
            value={
              data.videoData
                ? data.videoData.surgeryDate
                  ? new Date(data.videoData.surgeryDate)
                  : undefined
                : data && data.surgery_date
                ? new Date(data.surgery_date)
                : undefined
            }
            maxDate={new Date()}
            onSelectDate={(date: any) => {
              handleChange(date.toISOString(), data, "date");
            }}
            formatDate={(date: any) => {
              return (
                date.getMonth() +
                1 +
                "-" +
                date.getDate() +
                "-" +
                date.getFullYear()
              );
            }}
          />
        </Stack>
        {screen !== "allvideosedit" && (
          <Checkbox
            label="Out of body sequences removed"
            checked={
              sequenceCheck ||
              data?.videoData?.mainVideos?.isDeIdentified ||
              data?.is_deidentified
            }
            disabled={screen === "processing" ? true : false}
            onChange={(_?: any, checked?: boolean) => {
              // ...do required to setLocalState

              onSequenceChange && onSequenceChange(!!checked);

              setSequenceCheck(!!checked);
            }}
          />
        )}
        <Stack>
          <TextField
            name="notes"
            label="Notes"
            multiline
            resizable={false}
            placeholder="Enter description"
            styles={{ root: { width: "39.5rem" } }}
            defaultValue={data.videoData ? data.videoData.notes : data.notes}
            onChange={(evt: FormEvent) => handleChange(evt, data)}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 30 }}>
          <Stack horizontal>
            <StackItem
              styles={{
                root: { border: ThemeColorPalette.changePasswordLabelTxt },
              }}
            >
              <TextField
                label="Patient Consent Form"
                resizable={false}
                placeholder="Upload File"
                contentEditable={false}
                disabled={true}
                value={
                  data.patientConsentForm
                    ? data.patientConsentForm.name
                      ? data.patientConsentForm.name
                      : "File not attached"
                    : data.patient_concent_forms
                    ? data.patient_concent_forms
                    : data.patient_concent_form
                    ? data.patient_concent_form
                    : "File not attached"
                }
                styles={{
                  root: {
                    border: ThemeColorPalette.changePasswordLabelTxt,
                  },
                }}
              />
            </StackItem>

            <input
              type="file"
              accept="image/png,application/pdf"
              ref={hiddenFileInputRef}
              contentEditable={false}
              onChange={(evt) => handlePatientConsentFormFileChange(evt, data)}
              style={{ display: "none" }}
            />
            <PrimaryButton
              styles={{
                root: {
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  fontFamily: fonts.HelveticaNeue_Bold,
                  height: "2rem",
                  marginTop: "1.78rem",
                },
              }}
              value={"file"}
              allowDisabledFocus
              onClick={handleFileClick}
            >
              Browse
            </PrimaryButton>
          </Stack>
          <Label className="errorMessage consenterrorMessage">
            {isUploadConsentFormError}
          </Label>
          {largePdfFileError && (
            <Label className="errorMessage consenterrorMessage">
              {largePdfFileError}
            </Label>
          )}
          {fileTypeError && (
            <Label className="errorMessage consenterrorMessage">
              {fileTypeError}
            </Label>
          )}
          {errorObject && (
            <Label className="errorMessage consenterrorMessage">
              {errorObject?.largePdfFileError
                ? errorObject?.largePdfFileError
                : errorObject?.isUploadConsentFormError}
            </Label>
          )}

          <Stack horizontal tokens={{ childrenGap: 200 }}>
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                variant="smallPlus"
                styles={{
                  root: {
                    color: ThemeColorPalette.changePasswordTxt,
                    fontFamily: fonts.HelveticaNeue_Medium,
                  },
                }}
              >
                Uploaded by
              </Text>
              <Text
                variant="medium"
                styles={{
                  root: {
                    color: ThemeColorPalette.changePasswordLabelTxt,
                    fontFamily: fonts.HelveticaNeue_Medium,
                  },
                }}
              >
                {data.videoData || screen === "processing"
                  ? userProfile && userProfile.displayName
                  : data.is_uploaded_by_user
                  ? uploadedUser
                    ? uploadedUser
                    : data.uploaded_by || "N/A"
                  : data.uploaded_by}
              </Text>
            </Stack>
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                variant="smallPlus"
                styles={{
                  root: {
                    color: ThemeColorPalette.changePasswordTxt,
                    fontFamily: fonts.HelveticaNeue_Medium,
                  },
                }}
              >
                Uploaded Date
              </Text>
              <Text
                variant="medium"
                styles={{
                  root: {
                    color: ThemeColorPalette.changePasswordLabelTxt,
                    fontFamily: fonts.HelveticaNeue_Medium,
                  },
                }}
              >
                {data.videoData
                  ? currentDate()
                  : data.uploaded_date
                  ? getDateWithSlashInBetween(data.uploaded_date)
                  : "N/A"}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InprogressVideoEdit;
