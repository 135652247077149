import {
  FontIcon,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import React from "react";
import { ThemeColorPalette } from "../../theme";
import InprogressVideoDetails from "./InProgressVideoDetails";
import InprogressVideoEdit from "./InprogressVideoEdit";
const errorObject = { largePdfFileError: "", isUploadConsentFormError: "" };
export interface ViewEditVideoDataProps {
  inProgressVideosData: any;
  selectedVideoLength?: number;
  isEditable: boolean;
  handleChange: any;
  handlePatientConsentFormFileChange: any;
  proceduresData: any;
  userData: any;
  enableSave: boolean;
  handleSaveClick: any;
  handleEdit: any;
  userProfile: any;
  isSelected: boolean;
  screen?: string;
  errorObject?: any;
  fileUploadScreen?: any;
}

export interface ViewEditVideoDataState {
  editedFields: any;
  videoData: any;
  enableSave: boolean;
  largePdfFileError: string;
  isUploadConsentFormError: string;
}

class ViewEditVideoData extends React.Component<
  ViewEditVideoDataProps,
  ViewEditVideoDataState
> {
  state: any = {
    editedFields: {},
    videoData: {},
    enableSave: false,
    largePdfFileError: null,
    isUploadConsentFormError: null,
  };

  handleChange = (
    e: any,
    data: any,
    selectedOption?: any,
    selectedField?: string,
    selectType?: string
  ) => {
    if (selectedField === "procedureName") {
      const value = {
        ...data,
        procedure_name: selectedOption.text,
        primary_procedure_type: selectedOption.key,
      };
      const editedField = {
        ...this.state.editedFields,
        primary_procedure_type: selectedOption.key,
      };
      this.setState({
        ...this.state,
        editedFields: editedField,
        videoData: value,
        enableSave: true,
      });
    } else if (selectedField === "procedureName2") {
      const value = {
        ...data,
        secondary_procedure_name: selectedOption.text,
        secondary_procedure_type: selectedOption.key,
      };
      const editedField = {
        ...this.state.editedFields,
        secondary_procedure_type: selectedOption.key,
      };

      this.setState({
        ...this.state,
        editedFields: editedField,
        videoData: value,
        enableSave: true,
      });
    } else if (selectedOption === "date") {
      const value = {
        ...data,
        surgery_date: e,
      };
      const editedField = {
        ...this.state.editedFields,
        surgery_date: e,
      };
      this.setState({
        ...this.state,
        editedFields: editedField,
        videoData: value,
        enableSave: true,
      });
    } else {
      const value = {
        ...data,
        [e?.target?.name]: [e?.target?.value],
      };
      const editedField = {
        ...this.state.editedFields,
        [e?.target?.name]: [e?.target?.value],
      };
      this.setState({
        ...this.state,
        editedFields: editedField,
        videoData: value,
        enableSave: true,
      });
    }
  };

  manageFiles = (val: any, evt: any, data: any) => {
    const editedField = {
      ...this.state.editedFields,
      patientConcentForm: val ? evt.target.files[0] : null,
    };
    const value = {
      ...data,
      patient_concent_forms: val ? evt.target.files[0].name : null,
    };
    this.setState({
      ...this.state,
      editedFields: editedField,
      videoData: value,
      enableSave: val,
    });
  };

  handlePatientConsentFormFileChange = (evt: any, data: any) => {
    errorObject.isUploadConsentFormError = "";
    errorObject.largePdfFileError = "";
    const fileTypeSupported = ["image/png", "image/PNG", "application/pdf"];
    const fileObj = evt.target.files[0];
    const fileType = fileObj.type;
    const fileSize = fileObj.size
      ? (fileObj.size / (1024 * 1024)).toFixed(2)
      : 0;
    if (fileSize > 5) {
      errorObject.largePdfFileError = "Please Upload File less than 5 Mb";
      setTimeout(() => {
        this.manageFiles(false, evt, data);
      }, 1500);
    }

    if (fileTypeSupported.includes(fileType)) {
      this.manageFiles(true, evt, data);
    } else {
      setTimeout(() => {
        this.manageFiles(false, evt, data);
      }, 1500);
      errorObject.isUploadConsentFormError = "File type not supported";
    }
    return;
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.inProgressVideosData !== this.props.inProgressVideosData) {
      this.setState({
        ...this.state,
        videoData: this.props.inProgressVideosData,
      });
      errorObject.isUploadConsentFormError = "";
      errorObject.largePdfFileError = "";
    }
  }

  render() {
    const primarySurgeonName =
      this.state.videoData &&
      this.props.userData.results &&
      this.props.userData.results
        .map((item: any) => {
          if (item.id === this.state.videoData.primary_surgeon)
            return item.displayName;
          else return null;
        })
        .filter((val: any) => val);
    return !this.props.inProgressVideosData ? (
      <StackItem
        styles={{
          root: {
            flex: 8,
            padding: "1.5rem",
            borderLeft: "1px solid black",
          },
        }}
      >
        <Stack verticalFill verticalAlign="center">
          <StackItem align="center">
            <FontIcon
              iconName="MSNVideos"
              style={{
                fontSize: "3rem",
                color: ThemeColorPalette.uploadMsgTxt,
                opacity: 0.25,
                fontWeight: 1,
              }}
            />
          </StackItem>
          <StackItem align="center">
            <Text
              variant="small"
              styles={{
                root: {
                  color: ThemeColorPalette.uploadMsgTxt,
                  opacity: 0.25,
                },
              }}
            >
              Select any video to view details
            </Text>
          </StackItem>
          <StackItem align="center">
            <Text
              variant="small"
              styles={{
                root: {
                  color: ThemeColorPalette.uploadMsgTxt,
                  opacity: 0.25,
                },
              }}
            >
              You can also select to edit details
            </Text>
          </StackItem>
        </Stack>
      </StackItem>
    ) : (
      <StackItem
        styles={{
          root: {
            flex: 8,
            // padding: "1.5rem",
            borderLeft: "1px solid black",
          },
        }}
      >
        {this.props.isEditable
          ? this.state.videoData &&
            this.state.videoData.id && (
              <StackItem>
                <InprogressVideoEdit
                  errorObject={
                    this.props.fileUploadScreen === "inProgress-screen"
                      ? errorObject
                      : this.props.errorObject
                  }
                  handleEdit={this.props.handleEdit}
                  handlePatientConsentFormFileChange={
                    this.props.fileUploadScreen === "inProgress-screen"
                      ? this.handlePatientConsentFormFileChange
                      : this.props.handlePatientConsentFormFileChange
                  }
                  handleChange={
                    this.props.isSelected
                      ? this.props.handleChange
                      : this.handleChange
                  }
                  data={
                    this.props.isSelected
                      ? this.props.inProgressVideosData
                      : this.state.videoData
                  }
                  dropdownVals={this.props.proceduresData}
                  userData={this.props.userData}
                  userProfile={this.props.userProfile}
                  screen={this.props.screen}
                  isUploadConsentFormError={this.state.isUploadConsentFormError}
                  largePdfFileError={this.state.largePdfFileError}
                />
                <StackItem styles={{ root: { padding: "2rem" } }}>
                  <PrimaryButton
                    disabled={
                      this.props.isSelected
                        ? !this.props.enableSave
                        : !this.state.enableSave
                    }
                    onClick={() =>
                      this.props.screen === "processing"
                        ? this.props.handleSaveClick(
                            this.state.editedFields,
                            this.state.videoData.id
                          )
                        : this.props.handleSaveClick(this.state.videoData)
                    }
                  >
                    Save
                  </PrimaryButton>
                </StackItem>
              </StackItem>
            )
          : this.state.videoData &&
            this.state.videoData.id && (
              <InprogressVideoDetails
                userData={this.props.userData}
                handleEdit={this.props.handleEdit}
                userProfile={this.props.userProfile}
                data={
                  this.props.isSelected
                    ? this.props.inProgressVideosData
                    : this.state.videoData
                }
                primarySurgeonName={primarySurgeonName && primarySurgeonName[0]}
                secondaryProcedureName={
                  this.state.videoData.secondary_procedure_name
                  // this.props.inProgressVideosData.secondary_procedure_name
                }
                screen={this.props.screen}
              />
            )}
      </StackItem>
    );
  }
}

export default React.memo(ViewEditVideoData);
