export const FETCH_RECENT_ACTIVITIES_DATA = "FETCH_RECENT_ACTIVITIES_DATA";

export const UPDATE_RECENT_ACTIVITIES_ON_SORT =
  "UPDATE_RECENT_ACTIVITIES_ON_SORT";

export const SHOW_SPINNER = "SHOW_SPINNER";

export const CLEAR_ALL_RECENT_ACTIVITES = "CLEAR_ALL_RECENT_ACTIVITES";
export const GET_HOSPITAL_ID = "GET_HOSPITAL_ID";
export const GET_LOG_FACILITIES = "GET_LOG_FACILITIES";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_HOSPITAL_ADMIN_ACTIVITY_TYPES =
  "GET_HOSPITAL_ADMIN_ACTIVITY_TYPES";
export const LOADER = "LOADER";
export const STOPLOADER = "STOPLOADER";
export const CLEAR_ALL_SURGEONS = "CLEAR_ALL_SURGEONS";
