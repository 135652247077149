import React, { FormEvent } from "react";
import {
  Stack,
  TextField,
  Text,
  Label,
  StackItem,
  PrimaryButton,
  ActionButton,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
} from "@fluentui/react";
import ToggleCard from "../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CreateHospitalIcon } from "../../../../assets/Home/create_hospital.svg";
import { fonts, ThemeColorPalette } from "../../../../theme";
import {
  createDataset,
  getAlgList,
  getDataSets,
} from "../../actions/datasetAction";
import Select, { NonceProvider } from "react-select";
import { useState, useCallback, useEffect } from "react";
import Papa from "papaparse";
import { AppState } from "../../../../rootReducer";

const DatasetCreation: React.FC<any> = ({
  updateData,
  data,
  errors: formError,
}) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [values, setValues] = useState<any>({});
  const [dataSetName, setDataSetName] = useState<string>();

  const dispatch = useDispatch();
  const hiddenFileInputRef: any = React.useRef(null);

  const algList = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.algList;
  });

  const handleFileClick = (event?: any) => {
    hiddenFileInputRef.current.click();
  };

  const handleSelectDataset = (evt: any, data: any) => {
    setValues({ ...data, datasetFile: evt.target.files[0] });
    // const file =evt.target.files[0];
    updateData((prev: any) => ({
      ...prev,
      video_name: evt?.target?.files[0]?.name,
      file: evt?.target?.files,
    }));
    // if (file) {
    //   Papa.parse(file, {
    //     header: true,
    //     complete: function (results) {

    //         dispatch(createDataset(results.data));

    //     },
    //   });
    // }
  };

  useEffect(() => {
    dispatch(getAlgList());
    dispatch(getDataSets());
  }, []);

  const algOptions = algList?.results?.map((alg: any) => ({
    key: alg?.id,
    text: alg?.name,
  }));
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const datasetContainer = {
    display: "flex",
    color: "#fff",
    fontFamily:
      "Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
  };
  const datasetLabel = { marginBottom: "3px" };
  const datasetInput = {
    backgroundColor: "rgb(21, 21, 21)",
    border: "1px solid rgba(191, 195, 196, 0.4)",
    color: "#7e7e81",
    height: "30px",
    borderRadius: "2px",
    padding: "0px 16px",
    width: "200px",
  };
  const datasetButton = {
    backgroundColor: "rgb(1, 177, 255)",
    color: "rgb(21, 21, 21)",
    border: "1px solid rgb(1, 177, 255)",
    height: "32px",
    fontSize: "14px",
    fontWeight: "400",
    padding: "0px 16px",
    cursor: "pointer",
    borderRadius: "2px",
  };
  return (
    <form id="surgeonForm">
      <ToggleCard heading="" index="" removeFormFields="">
        <Stack
          className="InputSection ms-Grid-row"
          tokens={{ maxHeight: 420 }}
          style={{ overflow: "auto" }}
        >
          <Stack.Item className="ms-Grid-row">
            <Stack.Item className="ms-Grid-col ms-lg6">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3 firstSection">
                  <Text className="sectionHeading">Add New Test Dataset</Text>
                  <Stack.Item className="datasetForm">
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 80 }}
                      verticalAlign="center"
                      className="fieldsContainer"
                    >
                      <StackItem styles={{ root: { flex: 1 } }}>
                        <Stack horizontal>
                          <Stack.Item>
                            <input
                              type="file"
                              accept="video/mp4,video/x-m4v,video/*"
                              ref={hiddenFileInputRef}
                              contentEditable={false}
                              onChange={(evt) => {
                                handleSelectDataset(evt, data);
                                formError.video_name = "";
                              }}
                              style={{ display: "none" }}
                            />
                            {/* <TextField
                              label="Select Dataset*"
                              value={
                                values?.datasetFile?.name || "File not attached"
                              }
                              readOnly
                              suffix="Browse"
                              className="pointer"
                              style={{ width: "auto" }}
                            ></TextField> */}
                            <Label style={datasetLabel}>Select Dataset*</Label>
                            <div style={datasetContainer}>
                              <input
                                style={datasetInput}
                                value={
                                  values?.datasetFile?.name ||
                                  "File not attached"
                                }
                                readOnly
                              ></input>
                              <input
                                type="button"
                                value="Browse"
                                style={datasetButton}
                                onClick={handleFileClick}
                              ></input>
                            </div>
                          </Stack.Item>
                        </Stack>
                        <Label className="errorMessage">
                          {formError?.video_name}
                        </Label>
                      </StackItem>
                      <StackItem styles={{ root: { flex: 1 } }}>
                        <Label>Map Algorithm*</Label>
                        <Controller
                          control={control}
                          defaultValue=""
                          shouldUnregister
                          name={"workflow.workflow_procedure_type"}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Dropdown
                              placeholder="Select from the list"
                              // selectedKeys={selectedKeys}
                              onChange={(
                                evt: FormEvent,
                                option?: IDropdownOption
                              ) => {
                                const value: any = option;
                                const valuesSelected = value.selected
                                  ? [...selectedKeys, value.key as string]
                                  : selectedKeys.filter(
                                      (key) => key !== value.key
                                    );
                                setSelectedKeys(valuesSelected);
                                onChange(valuesSelected);
                                formError.algorithms = "";
                                updateData((prev: any) => ({
                                  ...prev,
                                  algorithms: valuesSelected,
                                }));
                              }}
                              multiSelect
                              options={algOptions}
                              styles={dropdownStyles}
                            />
                          )}
                        />
                        <Label className="errorMessage">
                          {formError?.algorithms}
                        </Label>
                      </StackItem>
                    </Stack>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 50 }}
                      verticalAlign="center"
                      className="fieldsContainer"
                    >
                      <StackItem styles={{ root: { flex: 1 } }}>
                        <TextField
                          styles={{ root: { width: "310px" } }}
                          label="Dataset Name*"
                          onChange={(e: any, dataset_name?: string) => {
                            updateData((prev: any) => ({
                              ...prev,
                              dataset_name,
                            }));
                            setDataSetName(e?.target?.value);
                            formError.dataset_name = "";
                          }}
                          value={dataSetName}
                        />
                        <Label className="errorMessage">
                          {formError?.dataset_name}
                        </Label>
                      </StackItem>
                    </Stack>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack>
      </ToggleCard>
    </form>
  );
};

export default DatasetCreation;
