import { Stack, Text } from "@fluentui/react";
import React, { useState, useEffect } from "react";

import successMessageImage from "../../../../assets/Home/success_message.gif";
import "./SuccessMessage.scss";

const SuccessMessage = ({
  title,
  subText,
}: {
  title: string;
  subText?: string;
}) => {
  return (
    <Stack
      className="successMessage"
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
    >
      <Stack.Item className="successMessageSection" style={{ padding: "12px" }}>
        <img src={successMessageImage} alt=" " width="300" height="300"></img>
        <Stack.Item className="successMessagetext">
          <Text>{title}</Text>
          <Text>&nbsp;Successful!</Text>
        </Stack.Item>
        {subText && (
          <Stack.Item>
            <Text>{subText}</Text>
          </Stack.Item>
        )}
      </Stack.Item>
    </Stack>
  );
};
export default SuccessMessage;
