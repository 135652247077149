import { doHttpCall, apiEndPoint } from "../../common/restApi";
import * as allActions from "./proceduresActionConstants";

export const getVideosForProcedures =
  (
    procedureId: number,
    currentPage: number,
    sorting?: false,
    sortingOption?: any
  ) =>
  async (dispatch: any) => {
    if (!sorting) {
      try {
        const payload = {
          method: "GET",
          url:
            apiEndPoint +
            "/videos/procedures/" +
            procedureId +
            "?page=" +
            currentPage +
            "&page_size=8",
        };
        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.FETCH_VIDEOS,
            payload: { data: response.data, currentPage: currentPage },
          });
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      const customPayload =
        sortingOption.sortType !== "date"
          ? "&sort=" + sortingOption.sort
          : "&sort=custom&start_date=" +
            sortingOption.start_date +
            "&end_date=" +
            sortingOption.end_date;
      try {
        const payload = {
          method: "GET",
          url:
            apiEndPoint +
            "/videos/search?" +
            "primary_procedure_type=" +
            procedureId +
            "&page=" +
            currentPage +
            "&page_size=4" +
            customPayload,
        };
        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.FETCH_VIDEOS,
            payload: { data: response.data, currentPage: currentPage },
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

export const setSelectedVideo = (data: any) => async (dispatch: any) => {
  dispatch({
    type: allActions.SET_SELECTED_PROCEDURE_VIDEO,
    payload: data,
  });
};

export const setSelectedVideoUnfavourite =
  (data: any) => async (dispatch: any) => {
    try {
      if (data.favorite) {
        const payload = {
          method: "DELETE",
          url: apiEndPoint + "/videos/" + data.id + "/favorite",
        };

        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.SET_SELECTED_VIDEO_UNFAVOURITE,
            payload: data,
          });
        }
      } else {
        const payload = {
          method: "POST",
          url: apiEndPoint + "/videos/" + data.id + "/favorite",
          data: {
            user_id: data.primary_surgeon,
            video_id: data.id,
          },
        };

        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.SET_SELECTED_VIDEO_UNFAVOURITE,
            payload: data,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

export const editVideoDetails =
  (data: any, videoId: number) => (dispatch: any) => {
    dispatch({
      type: allActions.EDIT_VIDEO_DETAILS,
      payload: { data: data, videoId: videoId },
    });
  };

export const resetEditedDataonCancel = (type: string) => (dispatch: any) => {
  dispatch({
    type: allActions.RESET_EDITED_DATA_ON_CANCEL_PROCEDURES,
    payload: type,
  });
};

export const showSpinnerOnLoad = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER });
};
export const showSpinnerForVideo = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER_VIDEO });
};
export const clearProceduresDataWhenUnmount = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_PROCEDURES_DATA_WHEN_UNMOUNT,
  });
  // dispatch({
  //   type: "CLEAR_DATA_ON_LOGOUT",
  // });
};
export const getHospitalAvg =
  (procedureId: number) => async (dispatch: any) => {
    try {
      const payload = {
        method: "GET",
        url: apiEndPoint + "/hospitals/average" + "?procedure=" + procedureId,
      };

      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_HOSPITAL_AVG,
          payload: { data: response.data.average },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const deleteProcedureVideo = (id: number) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + "/videos/" + id,
  };
  try {
    const response = await doHttpCall(payload);
    if ((response && response.status === 200) || response.status === 204) {
      dispatch({
        type: allActions.DELETE_VIDEO_FROM_PROCEDURE,
      });
      return response;
    }
    return response;
  } catch (e) {
    console.error(e);
  }
};
