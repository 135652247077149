import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  TextField,
  Label,
  IDropdownOption,
  Dropdown,
  Modal,
  Icon,
} from "@fluentui/react";
import React, {
  useState,
  useEffect,
  FormEvent,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Devices/Devices.scss";
import {
  clearProcedureData,
  closeErrorRsponse,
  createProcedure,
  createProcedureSpecialtyMapping,
  editProcedure,
  getDeviceList,
  getProcedureList,
  getProcedures,
  getProcedureView,
  getSpecialtyList,
  procedureDelete,
  showRoleSpinner,
  updateProcedureActionData,
} from "../../actions/managamentActions";
import { AppState } from "../../../../rootReducer";
import {
  CommandBarButton,
  IconButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";
// import CreateDevice from "./CreateDevice/CreateDevice";
import moment from "moment";
import ToggleCard from "../../../Home/components/Wizard/ToggleCard";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import "./Procedures.scss";
import { ReactComponent as AddFacilityIcon } from "../../../../assets/Home/add_facility.svg";
import SpacialtyGroup from "./SpacialtyGroup";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { CSVReader } from "react-papaparse";
import Papa from "papaparse";
import ShowErrorMessageBar from "../../../Home/components/Common/ShowErrorMessageBar";
import DeleteModal from "../../../Home/components/Common/DeleteModal";
import Paging from "../../../../common/paging/Paging";
import { useNavigate, Navigate } from "react-router-dom";
import { apiEndPoint, doHttpCall } from "../../../../common/restApi";
import ProcedureCreate from "./ProcedureCreate";
import ProcedureTypeEdit from "./ProcedureTypeEdit";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const deleteIcon: IIconProps = { iconName: "Delete" };
// const proceduresOption = [
//   { key: "Lap Chole", text: "Lap Chole" },
//   { key: "Lap Colectomy - Right", text: "Lap Colectomy - Right" },
//   { key: "Lap Colectomy - Left", text: "Lap Colectomy - Left" },
//   { key: "Lap Sigmoid Colectomy", text: "Lap Sigmoid Colectomy" },
//   { key: "Lap Nissen", text: "Lap Nissen" },
//   { key: "Parasophogeal Hernia", text: "Parasophogeal Hernia" },
//   { key: "Lap Colectomy - Transverse", text: "Lap Colectomy - Transverse" },
//   { key: "Low Anterior Resection", text: "Low Anterior Resection" },
//   { key: "APR", text: "APR" },
//   { key: "Esophagectomy", text: "Esophagectomy" },
//   { key: "VATS Lobectomy", text: "VATS Lobectomy" },
//   { key: "Segmentectomy", text: "Segmentectomy" },
//   { key: "Sleeve Gastrectomy", text: "Sleeve Gastrectomy" },
//   { key: "Roux en Y Gastric Bypass", text: "Roux en Y Gastric Bypass" },
//   { key: "Duodenal Switch", text: "Duodenal Switch" },
//   { key: "Revision", text: "Revision" },
// ];

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };
const download: IIconProps = { iconName: "download" };

const Procedures = () => {
  const [visibleProcedureList, setVisibleProcedureList] = useState(false);

  const procedureData = useSelector((state: AppState) => {
    return state.managementReducerData.procedureData?.data;
  });

  const [procedureOnChange, setprocedureOnChange] = useState("");
  const [disabledCreate, setDisabledCreate] = useState(false);

  const isprocedureChanged =
    (procedureData?.name ? procedureData?.name : "") !== procedureOnChange;

  // console.log(
  //   "isprocedureChanged",
  //   (procedureData?.name ? procedureData?.name : "") !== procedureOnChange,
  //   procedureData?.name ? procedureData?.name : ""
  // );

  const validationSchema = Yup.object().shape({
    procedure: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Procedure is required")
          .test({
            message: "This Procedure is already taken",
            test: async (value) => {
              if (procedureOnChange !== "" && isprocedureChanged) {
                const checkFieldName = `/procedures/types?search=${value}`;
                const payload = {
                  method: "GET",
                  url: `${apiEndPoint}${checkFieldName}`,
                };
                const response = await doHttpCall(payload);
                const returnStatus =
                  response.data.results.length > 0 ? false : true;
                return returnStatus;
              } else {
                return true;
              }
            },
          }),
        speciality: Yup.array().required("Map Specialty is required"),
      })
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    clearErrors,
    setValue,
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    resetField,
    reset,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);

  const [indexes, setIndexes] = useState<Array<number>>([0]);
  const [counter, setCounter] = useState(1);
  const [openSpacialtyGroupModal, setOpenSpacialtyGroupModal] = useState(false);
  const [openProcedureGroupModal, setOpenProcedureGroupModal] = useState(false);
  const [openProcedureEditGroupModal, setOpenProcedureEditGroupModal] =
    useState(false);

  // const [procedureData, setProcedureData] = useState("");
  const [files, setFiles] = useState("");
  const templateUpload = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState("");
  const [user, setUser] = useState({});
  const [showDevice, setShowDevice] = useState(false);
  const [choice, setChoice] = React.useState<boolean | undefined>(false);
  const [openDeleteProcedureModal, setOpenDeleteProcedureModal] =
    useState(false);
  const [procedureId, setprocedureId] = useState<number>();
  const [currentProcedurePage, setCurrentProcedurePage] = useState<number>(1);
  const [showCreateProcedure, setShowCreateProcedure] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const procedures = useSelector((state: AppState) => {
    return state.managementReducerData.procedureList.data;
  });
  const specialties = useSelector((state: AppState) => {
    return state.managementReducerData.specialtyList.data;
  });

  const procedureSpinner = useSelector((state: AppState) => {
    return state.managementReducerData.procedureSpinner;
  });

  const specialtiesOption = specialties?.results?.map((specialtie: any) => ({
    key: specialtie.name,
    text: specialtie.name,
  }));
  const specialtiesCount = specialties?.count;
  // const procedureOptions = procedures?.results?.map((procedure: any) => ({
  //   value: procedure?.id,
  //   label: procedure?.name,
  // }));
  // const specialtiesOption = specialties?.map((specialtie: any) => ({
  //   value: specialtie.sid,
  //   label: specialtie.name,
  // }));

  const onAlgorithmPageChange = useCallback((pageNo) => {
    setCurrentProcedurePage(pageNo);
    dispatch(getProcedureList(pageNo));
  }, []);

  const showModal = useCallback(() => {
    setShowDevice((current) => !current);
  }, []);
  const closeModal = useCallback(() => {
    setOpenSpacialtyGroupModal(false);
  }, []);

  const closeProcedureModal = useCallback(() => {
    setOpenProcedureGroupModal(false);
  }, []);

  const closeProcedureEditModal = useCallback(() => {
    dispatch(clearProcedureData());
    setOpenProcedureEditGroupModal(false);
  }, []);

  const addProcedureType = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };
  const showSpacialtyGroupModal = useCallback(() => {
    setOpenSpacialtyGroupModal((current) => !current);
  }, []);

  const showProcedureGroupModal = useCallback(() => {
    setOpenProcedureGroupModal((current) => !current);
  }, []);

  const showProcedureEditGroupModal = () => {
    dispatch(clearProcedureData());
    dispatch(getProcedureView(procedureId));
    setOpenProcedureEditGroupModal((current) => !current);
  };

  const showDeleteProcedureModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteProcedureModal((current) => !current);
  }, []);

  const _onMenuClickSetId = (id: any) => {
    setprocedureId(id);
  };

  const navigateProcedureModal = useCallback(() => {
    navigate(`/management/procedures/${procedureId}`);
  }, [procedureId]);
  const procedureEditUrl = window.location.pathname.split("/");
  const procedureEditId = procedureEditUrl[3];
  const procedureCreate = procedureEditUrl[3] ? "create" : "";

  // const menuProps: IContextualMenuProps = {
  //   items: [
  //     {
  //       key: "edit",
  //       text: `Edit`,
  //       onClick: showProcedureEditGroupModal,
  //     },
  //     {
  //       key: "delete",
  //       text: "Delete",
  //       onClick: showDeleteProcedureModal,
  //     },
  //   ],
  // };
  const menuPropsfn = (obj: any) => {
    return {
      items: [
        {
          key: "edit",
          text: `Edit`,
          onClick: constants.getPermissionsbyItem("management.procedure.edit")
            ? showProcedureEditGroupModal
            : void 0,
          title: constants.getPermissionsbyItem("management.procedure.edit")
            ? ""
            : constants.permission,
        },
        {
          key: "delete",
          text: "Delete",
          onClick: constants.getPermissionsbyItem("management.procedure.delete")
            ? showDeleteProcedureModal
            : void 0,
          title: constants.getPermissionsbyItem("management.procedure.delete")
            ? obj?.mapped
            : constants.permission,
          disabled: obj?.mapped,
        },
      ],
    };
  };
  const deleteDeviceHandle = () => {
    setDisabledCreate(true);
    dispatch(procedureDelete(procedureId));
    setOpenDeleteProcedureModal((current) => !current);
  };

  const handleRemove = (i: any) => {
    const values = getValues();
    values?.procedure?.splice(i, 1);
    setIndexes((prevIndexes) => [...prevIndexes.filter((item) => item !== i)]);
    setCounter((prevCounter) => prevCounter - 0);
  };

  const deleteProcedure = useSelector((state: AppState) => {
    return state.managementReducerData.deleteProcedure.data;
  });
  const downloadBlobUrl = window.URL.createObjectURL(
    new Blob([JSON.stringify(procedures)], { type: "application/json" })
  );

  const procedureSingleList = useSelector((state: AppState) => {
    return state.managementReducerData.procedureSingleList?.data;
  });

  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });
  // console.log(procedureSingleList, "procedureSingleList");

  const proceduresOption = procedureSingleList?.results?.map(
    (procedure: any) => ({
      label: procedure?.name,
      value: procedure?.name,
      id: procedure?.id,
    })
  );
  const procedureCount = procedureSingleList?.count;
  // console.log("procedureEditId123", proceduresOption);

  // console.log(
  //   procedureData,
  //   procedureData?.speciality,
  //   "procedureData?.speciality"
  // );
  // useEffect(() => {
  //   //   // debugger;
  //   if (!procedureEditId) {
  //     // debugger;
  //     // reset({
  //     //   procedure: [
  //     //     {
  //     //       name: "",
  //     //       Spacialty: "",
  //     //       description: "",
  //     //     },
  //     //   ],
  //     // });
  //     const values = getValues();
  //     values?.procedure?.splice(0, 1);
  //     const dd = values?.procedure?.length === 0;
  //     dispatch(clearProcedureData());
  //   }
  //   const values = getValues();
  //   values?.procedure?.splice(0, 1);
  //   const dd = values?.procedure?.length === 0
  //   setShowCreateProcedure(true);
  //   //   console.log('setShowCreateProcedure', showCreateProcedure)
  // }, [procedureEditId]);
  useEffect(() => {
    // values?.procedure?.splice(0, values?.procedure?.length);
    // console.log(isSubmitSuccessful, "isSubmitSuccessful");
    if (isSubmitSuccessful) {
      setTimeout(() => {
        setDisabledCreate(false);
        const values = getValues();
        // console.log(values, "values-----------111111111111111111");
        values?.procedure?.splice(0, 1);
        const dd = values?.procedure?.length === 0;
        reset(
          {
            procedure: [
              {
                name: "",
                speciality: [],
                description: "",
              },
            ],
          },
          { keepDefaultValues: false, keepValues: false }
        );
        // console.log(getValues(), "values3");
        dispatch(clearProcedureData());
        // console.log(procedureData, "procedureData");

        //   reset('', {
        //     keepValues: false,
        // })
      }, 2000);
    }
  }, [isSubmitSuccessful]);
  const specialityData = procedureData?.speciality?.split(",");
  const procedureName = procedureData?.name;
  // useEffect(() => {
  //   dispatch(clearProcedureData());
  //   if (procedureEditId) {
  //     const values = getValues();
  //     values?.procedure?.splice(0, 1);
  //     const dd = values?.procedure?.length === 0;
  //     // console.log(values, "valuesEdit");
  //     setShowCreateProcedure(false);
  //     dispatch(getProcedureView(procedureEditId));
  //     // console.log(procedureData, speciality, "procedureData");
  //     setSelectedKeys(specialityData);
  //   }
  //   // console.log("values-----------22222222222222222222222");
  // }, [procedureEditId]);

  useEffect(() => {
    dispatch(getProcedureList(currentProcedurePage));
    dispatch(getProcedures(1000));
  }, []);
  useEffect(() => {
    setDisabledCreate(false);
  }, [openDeleteProcedureModal]);
  // useEffect(() => {
  //   // setValue(`procedure[0].id`, device?.serial_number);
  //   // console.log("values-----------333333333333333333333");
  //   // debugger;
  //   specialityData?.length > 0 && setSelectedKeys(specialityData);
  // }, [procedureData]);

  // useEffect(() => {
  //   const speciality = Array.isArray(procedureData?.speciality)
  //     ? procedureData?.speciality
  //     : [procedureData?.speciality];
  //   setSelectedKeys(speciality);
  //   // dispatch(getProcedureList());
  // }, [procedureData?.speciality]);

  // console.log(devices, "devices");
  useEffect(() => {
    dispatch(getProcedureList(currentProcedurePage));
    dispatch(getSpecialtyList(specialtiesCount));
  }, [deleteProcedure]);

  // const { readString } = usePapaParse();

  const resetChoice = React.useCallback(() => {
    setChoice((current: any) => !current);
    dispatch(closeErrorRsponse());
  }, []);
  const handleGetSpacialtyList = (): void => {
    dispatch(getSpecialtyList());
  };
  const handleFileChange = (e: any) => {
    // console.log("Make something");
    const files = e.target.files;
    // console.log(files);
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          dispatch(createProcedure(results.data));
          // console.log("Finished:", results.data);
        },
      });
    }
  };
  const onSubmit = (data: any, e: any) => {
    if (!data?.procedure[0]?.name) {
      setError(`procedure[0].name`, {
        type: "required",
        message: "Procedure is required",
      });
      return false;
    }
    if (data?.procedure[0]?.speciality?.length === 0) {
      setError(`procedure[0].speciality`, {
        type: "required",
        message: "Map Specialty is required",
      });
      return false;
    }

    setDisabledCreate(true);
    // debugger;
    // console.log(data, 'data')

    const selectedprocedure = procedureSingleList?.results?.filter(
      (item: any) => item?.name === data.procedure[0].name
    );
    // console.log(selectedprocedure, data.procedure[0], "selectedprocedure");
    dispatch(updateProcedureActionData(data.procedure));
    delete data.procedure[0]?.name;
    Object.assign(data.procedure[0], {
      speciality: data.procedure[0]?.speciality?.join(","),
    });
    // debugger;
    showCreateProcedure &&
      dispatch(
        createProcedureSpecialtyMapping(
          selectedprocedure[0]?.id,
          data.procedure[0]
        )
      );
    const isprocedureNameChanged =
      procedureOnChange === "" ? procedureData?.name : procedureOnChange;
    const isprocedureChanged1 = procedureData?.name !== isprocedureNameChanged;
    if (procedureEditId) {
      !isprocedureChanged1 && delete data?.procedure[0]?.name;
      // console.log(
      //   !isprocedureChanged1,
      //   procedureEditId,
      //   data?.procedure,
      //   data?.procedure[0]?.name,
      //   "data?.procedure"
      // );
      dispatch(editProcedure(procedureEditId, data.procedure[0]));
    }
    setIndexes([0]);
    setCounter(1);
    setSelectedKeys([]);
  };

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageProcedures;
  });

  const loaderWithMessageProceduresDropDown = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageProceduresDropDown;
  });
  // console.log(loaderWithMessageProceduresDropDown, "----loaderWithMessage");

  useEffect(() => {
    if (
      loaderWithMessageProceduresDropDown &&
      loaderWithMessageProceduresDropDown?.calledFrom === "GET_PROCEDURE" &&
      loaderWithMessageProceduresDropDown?.statusCode !== 403 &&
      !loaderWithMessageProceduresDropDown?.loadingState
    ) {
      setVisibleProcedureList(true);
    }
    if (
      loaderWithMessageProceduresDropDown?.calledFrom === "GET_PROCEDURE" &&
      loaderWithMessageProceduresDropDown?.statusCode == 403
    ) {
      setVisibleProcedureList(false);
    }
  }, [loaderWithMessageProceduresDropDown]);

  const onSelectProcedure = async (proc: any) => {
    setValue(`procedure[0].name`, proc?.value);
    if (proc && proc?.id) {
      const res: any = await dispatch(getProcedureView(proc?.id));
      if (res && res.status === 200) {
        if (res?.data?.speciality) {
          const existingSpec = res?.data?.speciality.split(",");
          setSelectedKeys(existingSpec);
          setValue(`procedure[0].name`, proc?.value);
          setValue(`procedure[0].speciality`, existingSpec);
          clearErrors(`procedure[0]`);
        } else {
          setSelectedKeys([]);
          if (proc && proc?.id) {
            setValue(`procedure[0].name`, proc?.value);
          } else {
            setValue(`procedure[0].name`, "");
            setValue(`procedure[0].speciality`, undefined);
          }
        }
      }
    } else {
      setValue(`procedure[0].name`, "");
      setValue(`procedure[0].speciality`, undefined);
      setSelectedKeys([]);
    }
  };
  // console.log(getValues());

  return (
    <Stack className="management procedure">
      <Stack.Item className="heading">
        <Text>{procedureEditId ? "Edit Procedures" : "Procedures"}</Text>
      </Stack.Item>
      <Stack dir="ltr" horizontal horizontalAlign="end">
        <CheckPermission {...constants.SCOPES.CREATE_PROCEDURE}>
          <Stack
            horizontal
            horizontalAlign="end"
            verticalAlign="center"
            className={
              loaderWithMessageProceduresDropDown?.loadingState
                ? "pointer addProcedure point-events-none"
                : "pointer addProcedure"
            }
            onClick={showProcedureGroupModal}
          >
            <AddFacilityIcon />
            <Text className="ms-mr-20">Add Procedure Name</Text>
          </Stack>
        </CheckPermission>
        <CheckPermission {...constants.SCOPES.CREATE_SPECIALTY}>
          <Stack
            horizontal
            horizontalAlign="end"
            verticalAlign="center"
            className={
              loaderWithMessageProceduresDropDown?.loadingState
                ? "pointer addProcedure point-events-none"
                : "pointer addProcedure"
            }
            onClick={showSpacialtyGroupModal}
          >
            <AddFacilityIcon />
            <Text> Add Specialty Name </Text>
          </Stack>
        </CheckPermission>
      </Stack>
      {((procedureData && Object.keys(procedureData).length >= 0) ||
        showCreateProcedure) && (
        <form
          id="procedure"
          className={
            loaderWithMessageProceduresDropDown?.loadingState
              ? "point-events-none"
              : ""
          }
          onSubmit={
            procedureEditId
              ? handleSubmit(onSubmit)
              : constants.getPermissionsbyItem(
                  "management.procedure_speciality.map"
                )
              ? handleSubmit(onSubmit)
              : function (e) {
                  e.preventDefault();
                }
          }
          key={procedureData}
        >
          <ToggleCard
            heading={
              procedureEditId ? "Edit Procedures Type" : "Add Procedure Type"
            }
            index=""
            removeFormFields=""
          >
            <Stack className="InputSection procedureType ms-Grid-row">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg4 " dir="ltr">
                  <Label className="addFacilityHeading">Procedure Name*</Label>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg4 " dir="ltr">
                  <Label className="addFacilityHeading">Map Specialty*</Label>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg4 " dir="ltr">
                  <Label className="addFacilityHeading">Comments</Label>
                </Stack.Item>
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-row"
                key={procedureData && procedureData?.name}
              >
                <Stack.Item className="ms-Grid-col ms-lg4 " dir="ltr">
                  <Stack
                    className="procedureSection1"
                    key={procedureData && procedureData?.name}
                  >
                    <Controller
                      control={control}
                      // defaultValue={procedureData && procedureData?.name}
                      // name={`${fieldName}.name`}
                      {...register(`procedure[0].name`)}
                      shouldUnregister
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <Select
                            classNamePrefix="addl-class"
                            options={proceduresOption}
                            isDisabled={!visibleProcedureList}
                            isClearable
                            value={proceduresOption?.find(
                              (c: any) => c.value === value
                            )}
                            placeholder={
                              !visibleProcedureList
                                ? "You do not have permission"
                                : "Select"
                            }
                            onChange={(val) => {
                              onChange(val?.value);
                              onSelectProcedure(val);
                              // setValue(`procedure[0].name`,val?.value)
                              // setIsBussiness(val?.value);
                            }}
                          />
                        </>
                      )}
                    />
                    {/* <TextField
                            errorMessage={
                              errors?.procedure
                                ? errors?.procedure[i]?.name?.message
                                : ""
                            }
                            {...register(`${fieldName}.name`)}
                            // onChange={(e) => handleValidateForm(e, "name")}
                            defaultValue={procedureData && procedureData?.name}
                          /> */}
                  </Stack>

                  <Label className="errorMessage">
                    {errors?.procedure
                      ? errors?.procedure[0]?.name?.message
                      : ""}
                  </Label>
                </Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg4" dir="ltr">
                  <Stack className="procedureSection1" key={specialityData}>
                    <Controller
                      control={control}
                      // defaultValue={
                      //   procedureData &&
                      //   Array.isArray(procedureData?.speciality)
                      //     ? procedureData?.speciality
                      //     : [procedureData?.speciality]
                      // }
                      shouldUnregister
                      name={`procedure[0].speciality`}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <>
                          <Dropdown
                            placeholder="Select from the list"
                            selectedKeys={selectedKeys}
                            onChange={(
                              evt: FormEvent,
                              option?: IDropdownOption
                            ) => {
                              const value: any = option;
                              // console.log(value.key, option);
                              const valuesSelected = value.selected
                                ? [...selectedKeys, value.key as string]
                                : selectedKeys.filter(
                                    (key) => key !== value.key
                                  );
                              setSelectedKeys(valuesSelected);
                              // console.log(valuesSelected, "valuesSelected");
                              if (!valuesSelected?.length) {
                                setValue(`procedure[0].speciality`, undefined);
                              }
                              onChange(valuesSelected);
                            }}
                            multiSelect
                            options={specialtiesOption}
                          />
                        </>
                      )}
                    />
                  </Stack>

                  {/* <Controller
                          control={control}
                          defaultValue={procedureData && procedureData?.speciality}
                          shouldUnregister
                          // {...register(`${ fieldName }.speciality`)}
                          name={`${fieldName}.speciality`}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Dropdown
                              placeholder="Select an option"
                              defaultValue={procedureData && procedureData?.speciality}
                              // label="Custom label"
                              // ariaLabel="speciality label"
                              options={specialtiesOption}
                              onChange={(evt: FormEvent, option?: IDropdownOption) => {
                                // handleChange(evt, data, option, "procedureName");
                                let value: any = option;
                                console.log(value.key, option);
                                onChange(value?.text);
                              }}
                            />
                          )}
                        /> */}
                  {/* <Controller
                          control={control}
                          defaultValue={procedureData && procedureData?.speciality}
                          shouldUnregister
                          // {...register(`${ fieldName }.speciality`)}
                          name={`${fieldName}.speciality`}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              defaultValue={procedureData && procedureData?.speciality}
                              // onClick={handleGetSpacialtyList}
                              classNamePrefix="addl-class"
                              options={specialtiesOption}
                              value={specialtiesOption?.find((c: any) => c.label === value)}
                              onChange={(val) => {
                                onChange(val?.label);
                              }}
                            />
                          )}
                        /> */}
                  <Label className="errorMessage">
                    {errors?.procedure
                      ? errors?.procedure[0]?.speciality?.message
                      : ""}
                  </Label>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col  ms-lg4" dir="ltr">
                  <Stack
                    className="procedureSection"
                    key={procedureData?.description}
                  >
                    {/* {console.log(
                            procedureData?.description,
                            "procedureData?.description"
                          )} */}
                    <input
                      key={procedureData?.description}
                      className="procedureInput"
                      {...register(`procedure[0].description`)}
                      // defaultValue={procedureData && procedureData?.description}
                    />
                    {/* <TextField
                            errorMessage={
                              errors?.procedure
                                ? errors?.procedure[i]?.description?.message
                                : ""
                            }
                            {...register(`${fieldName}.description`)}
                            // onChange={(e) => handleValidateForm(e, "name")}
                            defaultValue={procedureData && procedureData?.description}
                          /> */}
                  </Stack>
                  <Label className="errorMessage">
                    {errors?.hospitals
                      ? errors?.hospitals[0]?.name?.message
                      : ""}
                  </Label>
                  {/* <TextField
                        className="width-100"
                        // errorMessage={
                        //   errors?.hospitals
                        //     ? errors?.hospitals[0]?.name?.message
                        //     : ""
                        // }
                        {...register(`${ fieldName }.description`)}
                        // onChange={(e) => handleValidateForm(e, "name")}
                        defaultValue=""
                      /> */}
                </Stack.Item>
              </Stack.Item>

              <Stack dir="ltr" horizontal horizontalAlign="end">
                <PrimaryButton
                  disabled={disabledCreate}
                  type="submit"
                  form="procedure"
                  title={
                    procedureEditId
                      ? ""
                      : constants.getPermissionsbyItem(
                          "management.procedure_speciality.map"
                        )
                      ? ""
                      : constants.permission
                  }
                  className="btn-next"
                >
                  {procedureEditId ? "Update" : "Add"}
                  <Text className="spinnerRight">
                    {disabledCreate && <Spinner size={SpinnerSize.small} />}
                  </Text>
                </PrimaryButton>
                {/* {!procedureEditId && (
                    <Stack
                      horizontal
                      horizontalAlign="end"
                      verticalAlign="center"
                      className="pointer addProcedure"
                      onClick={addProcedureType}
                    >
                      <Text>Add Another Field</Text>
                      <AddFacilityIcon />
                    </Stack>
                  )} */}
              </Stack>
            </Stack>
          </ToggleCard>
        </form>
      )}
      <ProcedureCreate
        isModalOpen={openProcedureGroupModal}
        showModal={showProcedureGroupModal}
        closeModal={closeProcedureModal}
        currentPage={currentProcedurePage}
      ></ProcedureCreate>

      {openProcedureEditGroupModal && (
        <ProcedureTypeEdit
          isModalOpen={openProcedureEditGroupModal}
          showModal={showProcedureEditGroupModal}
          closeModal={closeProcedureEditModal}
        ></ProcedureTypeEdit>
      )}

      {openSpacialtyGroupModal && (
        <SpacialtyGroup
          isModalOpen={openSpacialtyGroupModal}
          showModal={showSpacialtyGroupModal}
          closeModal={closeModal}
        ></SpacialtyGroup>
      )}
      {/* 
      <Stack
        className="blukUploadProcedure"
        dir="ltr"
        horizontal
        horizontalAlign="space-between"
      >
        <Stack.Item>
          <Text className="heading">Upload Bulk Procedure list</Text>
          <Text className="downloadBtn">
            <Text className="download">
              <CommandBarButton
                href={downloadBlobUrl}
                iconProps={download}
                download
                className=" ms-fon ms-fontWeight-bold"
                text="Download"
              />
            </Text>
          </Text>
          <Text className="downloadText">
            Template from here and Add your Data to the Template file{" "}
          </Text>
        </Stack.Item>

        <Stack.Item
          className="pointer"
          onClick={() =>
            templateUpload.current && templateUpload.current.click()
          }
        >
          <input
            type="file"
            accept=".csv,.xlsx,.xls"
            style={{ display: "none" }}
            ref={templateUpload}
            onChange={handleFileChange}
          />
          
          <Stack dir="ltr" horizontal className="templateUpload">
            <Icon
              iconName="upload"
              className=" ms-fontSize-12 sortDropdownIcons"
            />
            <Text className=" ms-fontSize-12">
              Upload Filled-in Template here
            </Text>
          </Stack>
        </Stack.Item>
      </Stack> */}
      {/* <CreateDevice isModalOpen={showDevice} showModal={() => showModal()}></CreateDevice> */}
      <Stack className="procedureList ms-mt-20">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">Available Procedures</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text>Procedure Name</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Map Specialty</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Comments</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg2">
              <Text variant="smallPlus">Created Date</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Created by</Text>
            </Stack.Item>
          </Stack.Item>
          {visibleProcedureList &&
            procedures?.results?.map((procedure: any, index: any) => (
              <Stack.Item key={index} className="ms-Grid-row tableText">
                <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                  <Text
                    variant="smallPlus"
                    block
                    className="hospitalCardText textNoWrap"
                  >
                    <abbr className="ms-bb-0">
                      <Text variant="smallPlus" block title={procedure?.name}>
                        {procedure?.name?.slice(0, 60)}
                      </Text>
                    </abbr>
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text
                    variant="smallPlus"
                    block
                    className="hospitalCardText textNoWrap"
                  >
                    <abbr className="ms-bb-0" title={procedure?.speciality}>
                      <Text variant="smallPlus">{procedure?.speciality}</Text>
                    </abbr>
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text
                    variant="smallPlus"
                    block
                    className="hospitalCardText textNoWrap"
                  >
                    <abbr className="ms-bb-0" title={procedure?.description}>
                      <Text variant="smallPlus">{procedure?.description}</Text>
                    </abbr>
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">
                    {moment(procedure?.created_date).format("MMM D YYYY HH:mm")}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2 textEllipses">
                  <Text
                    variant="smallPlus"
                    block
                    title={procedure?.created_name}
                  >
                    {procedure?.created_name}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg1">
                  <Text variant="smallPlus">
                    <IconButton
                      className="buttonIcon"
                      menuIconProps={moreIcon}
                      menuProps={menuPropsfn(procedure)}
                      ariaLabel="New item"
                      // onMenuClick={() => {
                      //   setprocedureId(procedure?.id);
                      // }}
                      onMenuClick={() => _onMenuClickSetId(procedure?.id)}
                    />
                  </Text>
                </Stack.Item>
              </Stack.Item>
            ))}
        </Stack.Item>
        {visibleProcedureList &&
        procedures?.count > 10 &&
        procedures?.page_size ? (
          <Paging
            currentPage={currentProcedurePage}
            totalPageCount={Math.ceil(procedures?.count / 10)}
            handlePageChange={onAlgorithmPageChange}
          />
        ) : null}
      </Stack>
      <Modal isOpen={procedureSpinner} containerClassName={"modalStyle"}>
        <SuccessMessage
          title={`Procedure ${
            showCreateProcedure ? "Mapped" : "Mapping Updated"
          }`}
        />
      </Modal>
      {(procedureSpinner || closeWhenErrors) &&
        setTimeout(() => {
          // const values = getValues();
          // console.log(values);
          // values?.procedure?.splice(0, values?.procedure?.length);
          // console.log(values);
          // setProcedureData("");
          // dispatch(getProcedures(1000));
          // dispatch(getProcedureList(currentProcedurePage));
          setShowCreateProcedure(true);
          setOpenProcedureEditGroupModal(false);
          navigate("/management/procedures");
          dispatch(showRoleSpinner());
          setValue(`procedure[0].name`, "");
          setValue(`procedure[0].speciality`, undefined);
        }, 1000) && <></>}
      {useEffect(() => {
        dispatch(getProcedures(1000));
        dispatch(getProcedureList(currentProcedurePage));
      }, [procedureSpinner])}
      <DeleteModal
        isModalOpen={openDeleteProcedureModal}
        deleteHandle={deleteDeviceHandle}
        showModal={showDeleteProcedureModal}
        id={procedureId}
        name="Procedure"
        disabledCreate={disabledCreate}
      ></DeleteModal>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessageProceduresDropDown}
      ></LoaderWithMessage>
    </Stack>
  );
};

export default Procedures;
