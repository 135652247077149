import {
  GET_HOSPITALS,
  GET_HOSPITALS_COUNT,
  CREATE_HOSPITALS,
  INCREMENT,
  DECREMENT,
  HOSPITALS_DATA,
  GET_DEVICES,
  GET_ALLOCATION_TYPE,
  GET_HOSPITAL_VALIDATIONS,
  SPINNER_STOP,
  GET_VIDEOS,
  GET_USERS,
  CREATE_USERS,
  GET_HOSPITALS_WITH_COUNT,
  REFRESH_HOSPITALS_WITH_COUNT,
  VALIDATE_FORM,
  VALIDATE_FORM_GET,
  GET_HOSPITAL_BY_ID,
  CLOSE_HOSPITAL_MODAL,
  ERROR_IN_CREATE_HOSPITALS,
  CLOSE_DELETE_HOSPITAL,
  SHOW_CREATE_HOSPITAL,
  CLOSE_ERRORS,
  EDIT_HOSPITALS,
  GET_USER_BY_ID,
  SHOW_CREATE_USER,
  STOP_CREATE_USER,
  EDIT_USERS,
  CLOSE_USER_MODAL,
  CREATE_USERS_WITH_TYPES,
  CREATE_VIDEO_PROCESSING,
  CREATE_LOG_PROCESSING,
  GET_LOCATION_WITH_ZIPCODE,
  DELETE_USER,
  CLEAR_HOSPITAL_DATA,
  ERROR_LOCATION_WITH_ZIPCODE,
  LOADER,
  DELETE_VIDEO,
  ERROR_IN_CREATE_USER,
  DELETE_HOSPIATL,
  STOPLOADER,
  HOSPITALS_WITH_COUNT_LOADER_SHOW,
  HOSPITALS_WITH_COUNT_LOADER_HIDE,
  ERROR_USR,
} from "../actions/actionConstants";

const initialState: any = {
  hospitals: [],
  hospitalsCount: [],
  hospitalData: [],
  userData: [],
  devices: [],
  allocationType: [],
  hopsitalValidate: [],
  count: 0,
  showSpinner: false,
  videos: [],
  hospitalsWithCount: [],
  createHospitalError: "",
  users: [],
  showUserSpinner: false,
  checkValidation: [],
  salesLogs: [],
  errorHospital: false,
  successHospital: false,
  showCreateHospital: false,
  showCreateUser: false,
  dataProcessSpinner: false,
  errorDataProcess: false,
  locationData: [],
  deleteUser: "",
  hospitalById: [],
  loadingState: false,
  deleteVideo: "",
  errorUser: false,
  deleteHospital: "",
  hospitalWithCountLoader: false,
  errorUserAPI: {
    mail: "",
    phone_number: "",
  },
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageHospitals: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageVideos: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageUsers: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  logoutActivAdminAlert: false,
};
export const homeReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case "LOGOUT_ACTIV_ADMIN_ALERT":
      return {
        ...state,
        logoutActivAdminAlert: !state.logoutActivAdminAlert,
      };
    case ERROR_USR:
      return {
        ...state,
        errorUserAPI: action.payload,
      };
    case HOSPITALS_WITH_COUNT_LOADER_SHOW:
      return {
        ...state,
        hospitalWithCountLoader: true,
      };
    case HOSPITALS_WITH_COUNT_LOADER_HIDE:
      return {
        ...state,
        hospitalWithCountLoader: false,
      };
    case SPINNER_STOP:
      return {
        ...state,
        showSpinner: false,
        showUserSpinner: false,
        dataProcessSpinner: false,
        count: 0,
        hospitalData: [],
        userData: [],
      };
    case GET_HOSPITALS:
      return {
        ...state,
        hospitals: action.payload,
        hospitalPage: action.payload.currentPage,
      };
    case GET_HOSPITALS_COUNT:
      return {
        ...state,
        hospitalsCount: action.payload,
      };
    case CREATE_HOSPITALS:
      const branchHospitals = action?.payload?.data?.hospitals?.length - 1;
      const totalDevices = action?.payload?.data?.hospitals[0]?.devices?.length;
      const hospitalAdmin =
        action?.payload?.data?.admin?.firstname +
        " " +
        action?.payload?.data?.admin?.lastname;
      return {
        ...state,
        // hospitals: {
        //   data: {
        //     results: [
        //       {
        //         ...action.payload.data.admin,
        //         ...action?.payload?.data.hospitals[0],
        //         branch_hospitals: branchHospitals,
        //         total_devices: totalDevices,
        //         hospital_admin: hospitalAdmin,
        //       },
        //       ...state.hospitals.data.results,
        //     ],
        //   },
        // },
        showSpinner: true,
        hospitalData: [],
        count: 0,
        successHospital: true,
      };
    case EDIT_HOSPITALS:
      const branchHospitals1 = action?.payload?.data?.hospitals?.length - 1;
      const totalDevices1 =
        action?.payload?.data?.hospitals[0]?.devices?.length;
      const hospitalAdmin1 =
        action?.payload?.data?.admin?.firstname +
        " " +
        action?.payload?.data?.admin?.lastname;
      const results = state.hospitals?.data?.results?.map((result: any) => {
        if (result?.id === action.payload?.data?.id) {
          result = {
            ...action.payload.data.admin,
            ...action?.payload?.data.hospitals[0],
            branch_hospitals: branchHospitals1,
            total_devices: totalDevices1,
            hospital_admin: hospitalAdmin1,
            id: action.payload?.data?.id,
          };
          return result;
        }
        return result;
      });
      // debugger;
      return {
        ...state,
        showSpinner: true,
        hospitalData: [],
        hospitals: {
          data: {
            results: results,
          },
        },
      };
    case ERROR_IN_CREATE_HOSPITALS:
      return {
        ...state,
        hospitalData: [],
        count: 0,
        showSpinner: false,
        createHospitalError: action.payload,
        errorHospital: true,
        errorDataProcess: true,
        userData: [],
        showUserSpinner: false,
        errorUser: true,
      };
    case ERROR_IN_CREATE_USER:
      return {
        ...state,
        userData: [],
        showUserSpinner: false,
        errorUser: true,
        createHospitalError: action.payload,
      };
    case CLOSE_HOSPITAL_MODAL:
      return {
        ...state,
        showCreateHospital: false,
        count: 0,
        hospitalData: [],
        locationData: [],
      };
    case CLOSE_USER_MODAL:
      return {
        ...state,
        showCreateUser: false,
        userData: [],
      };
    case HOSPITALS_DATA:
      return {
        ...state,
        hospitalData: action.payload.data,
      };
    case CLOSE_ERRORS:
      return {
        ...state,
        errorProcedure: false,
        createHospitalError: "",
        errorHospital: false,
        errorUser: false,
      };
    case INCREMENT:
      return {
        ...state,
        count: state.count + 1,
      };
    case DECREMENT:
      return {
        ...state,
        count: state.count - 1,
      };
    case GET_DEVICES:
      return {
        ...state,
        devices: action.payload,
      };
    case GET_ALLOCATION_TYPE:
      return {
        ...state,
        allocationType: action.payload,
      };
    case GET_HOSPITAL_VALIDATIONS:
      return {
        ...state,
        hopsitalValidate: state.hopsitalValidate,
      };
    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case CREATE_USERS:
      // console.log(
      //   [
      //     {
      //       ...action.payload.data,
      //     },
      //     ...state.users.data.results,
      //   ],
      //   "CREATE_USERS"
      // );
      const hospital_name =
        typeof action.payload.data.hospital !== "number"
          ? action.payload.data.hospital
          : "";
      const displayName =
        action.payload.data.first_name + " " + action.payload.data.last_name;
      const role_name = action.payload.data.user_type;
      return {
        ...state,
        users: {
          data: {
            results: [
              {
                hospital_name,
                displayName,
                role_name,
              },
              ...state.users.data.results,
            ],
          },
        },
        showUserSpinner: true,
      };
    case GET_HOSPITALS_WITH_COUNT:
      const previousData = state.hospitalsWithCount?.data?.results || [];
      state.hospitalsWithCount = action.payload;
      state.hospitalsWithCount.data.results = [
        ...previousData,
        ...state.hospitalsWithCount.data.results,
      ];
      return {
        ...state,
      };
    case REFRESH_HOSPITALS_WITH_COUNT:
      return {
        ...state,
        hospitalsWithCount: action.payload,
      };
    case VALIDATE_FORM:
      // state.checkValidation
      const validateFieldName = {
        mail: false,
        phone_number: false,
        zipcode: false,
        name: false,
        unique_identifier: false,
      };
      const validateFieldNameState = Object.assign(
        validateFieldName,
        state.checkValidation
      );

      return {
        ...state,
        checkValidation: { ...validateFieldNameState, ...action.payload.field },
      };
    case VALIDATE_FORM_GET:
      return {
        ...state,
        checkValidation: state.checkValidation,
      };
    case GET_HOSPITAL_BY_ID:
      // const hospitalName = action.payload.data.hospital_admin.split(" ");
      const hospitals = {
        id: action.payload.data.id,
        hospitals: [
          action.payload.data,
          ...action.payload.data.facilities.reverse(),
        ],
        admin: {
          firstname: action.payload.data.firstname,
          lastname: action.payload.data.lastname,
          email: action.payload.data.admin_email,
          phone_number: action.payload.data.admin_phone,
        },
      };
      // const
      return {
        ...state,
        hospitalById: action.payload,
        hospitalData: {
          ...hospitals,
        },
      };
    case CLOSE_DELETE_HOSPITAL:
      return {
        ...state,
        errorHospital: false,
      };
    case SHOW_CREATE_HOSPITAL:
      return {
        ...state,
        showCreateHospital: true,
      };
    case SHOW_CREATE_USER:
      return {
        ...state,
        showCreateUser: true,
      };
    case STOP_CREATE_USER:
      return {
        ...state,
        showCreateUser: false,
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        userData: action.payload,
      };
    case EDIT_USERS:
      return {
        ...state,
        userData: [],
        showUserSpinner: true,
      };
    case CREATE_USERS_WITH_TYPES:
      const hospital_name_type =
        typeof action.payload.data.hospital !== "number"
          ? action.payload.data.hospital
          : "";
      const display_name_type =
        action.payload.data.first_name + " " + action.payload.data.last_name;
      const role_name_type = action.payload.data.assigned_role;
      return {
        ...state,
        users: {
          data: {
            results: [
              {
                hospital_name: hospital_name_type,
                displayName: display_name_type,
                role_name: role_name_type,
              },
              ...state.users.data.results,
            ],
          },
        },
        userData: [],
        showUserSpinner: true,
      };
    case CREATE_VIDEO_PROCESSING:
      return {
        ...state,
        dataProcessSpinner: true,
      };
    case CREATE_LOG_PROCESSING:
      return {
        ...state,
        dataProcessSpinner: true,
      };
    case GET_LOCATION_WITH_ZIPCODE:
      return {
        ...state,
        locationData: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        deleteUser: action.payload,
      };
    case CLEAR_HOSPITAL_DATA:
      return {
        ...state,
        hospitalById: [],
      };
    case ERROR_LOCATION_WITH_ZIPCODE:
      return {
        ...state,
        locationData: action.payload,
      };
    case LOADER:
      if (action.payload.calledFrom === "GET_HOSPITALS") {
        return {
          ...state,
          loaderWithMessageHospitals: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_VIDEOS") {
        return {
          ...state,
          loaderWithMessageVideos: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_USERS") {
        return {
          ...state,
          loaderWithMessageUsers: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
    case DELETE_VIDEO:
      return {
        ...state,
        deleteVideo: action.payload,
      };
    case DELETE_HOSPIATL:
      return {
        ...state,
        deleteHospital: action.payload,
      };
    case STOPLOADER:
      if (action.payload.calledFrom === "GET_HOSPITALS") {
        return {
          ...state,
          loaderWithMessageHospitals: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_VIDEOS") {
        return {
          ...state,
          loaderWithMessageVideos: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_USERS") {
        return {
          ...state,
          loaderWithMessageUsers: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      }
    case "CLEAR_HOSPITALS": {
      return {
        ...state,
        hospitals: { results: [] },
      };
    }
    default:
      return state;
  }
};
