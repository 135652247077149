import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
  TextField,
  StackItem,
  Label,
  PrimaryButton,
  ActionButton,
} from "@fluentui/react";
import Select from "react-select";
import * as lodash from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

import {
  getAllContainers,
  getModelName,
  getReadyDatasets,
  getModelsByAlgId,
  getModelVersion,
  showModelSpinner,
  showDatasetSpinner,
  getUniqueModels,
} from "../../actions/managementAction";
import * as allActions from "../../actions/actionConstants";
import { getVideoList } from "../../../Home/actions/developerAction";
import {
  algListWithPagination,
  showAlgSpinner,
} from "../../../DatasetMapping/actions/datasetAction";

import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import CompareModel from "./CompareModel/CompareModel";
import { roleReset } from "../../../../ActiveAdminModule/Management/actions/managamentActions";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import { getVideoOverlays } from "../../actions/managementAction";
import { debounce } from "lodash";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import { SCOPES } from "../../../../Layout/constants/constants";
import * as constants from "../../../../Layout/constants/constants";

const menuItems = [
  {
    key: "edit",
    text: "Edit",
  },
  {
    key: "delete",
    text: "Delete",
  },
];

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Comparison = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: {},
  } = useForm();

  const [data, setData] = useState<{
    name: string;
    tag: string;
    description: string;
    algorithm: any;
    model_name: any;
    model_version: any;
    video: any;
  }>({
    name: "",
    tag: "",
    description: "",
    algorithm: "",
    model_name: "",
    model_version: "",
    video: "",
  });

  const [algId, setAlgId] = useState(null);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [selectedAlg, setSelectedAlg] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [modelsData, setModelsData] = useState<any>([]);
  const [modelVersionOptions, setModelVersionOptions] = useState<any>([]);
  const [modelsVersionsData, setModelsVersionsData] = useState<any>([]);
  const dispatch = useDispatch();
  const [versionOptions, setVersionOptions] = useState<any>({});
  const [showRole, setShowRole] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [versionError, setVersionError] = useState<any>([false, false]);
  const [visibleAlgorithmList, setVisibleAlgorithmList] = useState(false);
  const [customLoadder, setCustomLoadder] = useState({
    calledFrom: "customLoadder",
    loadingState: false,
    message: "",
    statusCode: 200,
  });

  const toggleModal = () => {
    if (
      modelsData[0].value === modelsData[1].value &&
      modelsVersionsData[0].label === modelsVersionsData[1].label
    ) {
      setErrors({ ...errors, sameModel: true });
    } else {
      setShowRole((current) => !current);
    }
  };

  const cancelModelComparison = () => {
    clearComparisonForm();
  };
  const clearSelectedValue = (selectedAlg: any) => {
    if (selectedAlg !== algId) {
      setSelectedVideo(null);
      modelsData[0] = null;
      modelsData[1] = null;
      setModelsData([...modelsData]);
      modelsVersionsData[0] = null;
      modelsVersionsData[1] = null;
      setModelsVersionsData([...modelsVersionsData]);
      setData({
        name: "",
        tag: "",
        description: "",
        algorithm: "",
        model_name: "",
        model_version: "",
        video: "",
      });
      setVersionError([false, false]);
      //setErrors({ ...{} });
    }
    algInputChangeHandler(null);
  };
  const clearComparisonForm = () => {
    setAlgId(null);
    setSelectedAlg(null);
    setSelectedVideo(null);
    modelsData[0] = null;
    modelsData[1] = null;
    setModelsData([...modelsData]);
    modelsVersionsData[0] = null;
    modelsVersionsData[1] = null;
    setModelsVersionsData([...modelsVersionsData]);
    setData({
      name: "",
      tag: "",
      description: "",
      algorithm: "",
      model_name: "",
      model_version: "",
      video: "",
    });
    if (videos && videos.results) {
      videos.results = [];
    }
    setVersionError([false, false]);
    //setErrors({ ...{} });
  };

  const closeModal = (type: any = "") => {
    if (type === "close") {
      setShowRole(false);
      dispatch(roleReset());
      clearComparisonForm();
    }
  };
  const closeRoles = useSelector((state: AppState) => {
    return state.developerManagementReducerData.closeRoles;
  });
  const algList = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.algorithmList?.data;
  });
  const algLoader = useSelector((state: AppState) => {
    return state.developerDataSetReducerData?.loadingState;
  });

  const videos = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.datasetsById?.data;
  });
  const pageLoadder = useSelector((state: AppState) => {
    return state.developerManagementReducerData.commentsLoader;
  });
  const loadingDatasets = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.loadingDatasets;
  });
  const loaderWithMessageAlgorithms = useSelector((state: AppState) => {
    return state.developerDataSetReducerData.loaderWithMessageAlgorithms;
  });
  const modelNames = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.modelNamesByAlgId;
  });

  const flatVersions =
    useSelector((state: AppState) => {
      return state.developerManagementReducerData?.modelsByAlgId;
    }) || [];

  const modelComparisonData = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.modelComparisonData;
  });

  useEffect(() => {
    if (
      loaderWithMessageAlgorithms &&
      loaderWithMessageAlgorithms?.calledFrom === "REFRESH_ALGO_LIST" &&
      loaderWithMessageAlgorithms?.statusCode !== 403 &&
      !loaderWithMessageAlgorithms?.loadingState
    ) {
      setVisibleAlgorithmList(true);
    }
    if (
      loaderWithMessageAlgorithms?.calledFrom === "REFRESH_ALGO_LIST" &&
      loaderWithMessageAlgorithms?.statusCode == 403
    ) {
      setVisibleAlgorithmList(false);
    }
  }, [loaderWithMessageAlgorithms]);

  const algListCount = algList?.count;

  useEffect(() => {
    dispatch(showAlgSpinner());
  }, []);

  const algUniqueList =
    algList &&
    algList?.results &&
    algList?.results?.filter(
      (ele: any, ind: any) =>
        ind === algList?.results?.findIndex((elem: any) => elem.id === ele.id)
    );

  const algOptions =
    algUniqueList &&
    algUniqueList?.map((alg: any) => ({
      label: alg?.name,
      value: alg?.id,
    }));

  const videoOptions = videos?.results?.map((video: any) => ({
    label: video?.dataset_name,
    value: video?.video,
  }));

  const modelOptions =
    modelNames?.map((modelName: string, index: number) => ({
      label: modelName,
      value: index,
    })) || [];

  const algInputHandler = useCallback(
    debounce((evt: any) => {
      dispatch(showAlgSpinner());
      dispatch(algListWithPagination("", evt));
    }, 2000),
    []
  );
  const algInputChangeHandler = (evt: any) => {
    const value = evt;
    algInputHandler(value);
  };

  // const handleScrollAlg = (evt: any) => {
  //   if (algList?.next) {
  //     dispatch(showAlgSpinner());
  //     dispatch(algListWithPagination(algList.next));
  //   }
  // };
  const handleScrollAlg = useCallback(
    debounce((evt: any) => {
      if (evt && evt !== "null" && evt !== null) {
        dispatch(algListWithPagination(evt));
      }
    }, 1000),
    []
  );

  useEffect(() => {
    dispatch(algListWithPagination());
  }, []);

  // useEffect(() => {
  //   if(algListCount){
  //     dispatch(algListWithPagination(algListCount));
  //   }
  // }, [algListCount]);

  const datasetInputHandler = useCallback(
    debounce((evt: any) => {
      if (data.algorithm) {
        dispatch(showDatasetSpinner());
        dispatch(getReadyDatasets(data.algorithm, evt));
      }
    }, 2000),
    []
  );
  const datasetInputChangeHandler = (evt: any) => {
    const value = evt?.target.value;
    datasetInputHandler(value);
  };

  const handleScrollDataset = (evt: any) => {
    if (videos?.next) {
      dispatch(showDatasetSpinner());
      dispatch(getReadyDatasets(selectedAlg, "", videos.next));
    }
  };

  const filterOutVersions = (index: number, selectedModel: any) => {
    modelVersionOptions[index] =
      (flatVersions &&
        flatVersions?.length &&
        flatVersions.filter(
          (model: any) => model.name === selectedModel.label
        )) ||
      [];
    setModelVersionOptions([...modelVersionOptions]);
  };

  const errorMessages = {
    sameModel: {
      message: "Please select different videos to compare!",
    },
  };

  const resetChoice = React.useCallback(() => {
    //  setErrors({ ...{} });
    setVersionError([false, false]);
  }, []);
  const handleChangeModelComparision = async (
    i: any,
    val: any,
    callback: any
  ) => {
    modelsVersionsData[i] = val as any;
    setModelsVersionsData([...modelsVersionsData]);
    if (i == 0 && modelsVersionsData[i]) {
      const responce0: any = await dispatch(
        getVideoOverlays(
          selectedVideo?.value,
          Number(val?.value),
          allActions.GET_FIRST_VIDEO
        )
      );
      if (responce0 === 404 || responce0?.status === 404) {
        setVersionError([true, versionError[1]]);
        // setErrors({ ...errors, sameModel: true });
      } else if (responce0 === 200 || responce0?.status === 200) {
        setVersionError([false, versionError[1]]);
        // setErrors({ ...{} });
        callback();
      }
    }
    if (i == 1 && modelsVersionsData[i]) {
      const responce1: any = await dispatch(
        getVideoOverlays(
          selectedVideo?.value,
          Number(val?.value),
          allActions.GET_SECOND_VIDEO
        )
      );

      if (responce1 === 404 || responce1?.status === 404) {
        setVersionError([versionError[0], true]);
        // setErrors({ ...errors, sameModel: true });
      } else if (responce1 === 200 || responce1?.status === 200) {
        setVersionError([versionError[0], false]);
        //setErrors({ ...{} });
        callback();
      }
    }
  };
  const permission = constants.getPermissionsbyItem("algorithm.model.list");
  const enableComapre = React.useMemo(() => {
    return (
      !!selectedAlg &&
      !!selectedVideo &&
      !!modelsData[0] &&
      !!modelsData[1] &&
      !!modelsVersionsData[0] &&
      !!modelsVersionsData[1] &&
      !Object.keys(errors).length &&
      !versionError[0] &&
      !versionError[1]
    );
  }, [
    selectedAlg,
    selectedVideo,
    modelsData,
    modelsVersionsData,
    errors,
    versionError,
  ]);

  useEffect(() => {
    localStorage.setItem("nextAlgorithamUrl", algList?.next);
  }, [algList?.next]);

  const trackScrolling = () => {
    handleScrollAlg(localStorage.getItem("nextAlgorithamUrl"));
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("nextAlgorithamUrl");
    };
  }, []);

  const handleModalChange = (index: any, val: any) => {
    modelsData[index] = val as any;
    setModelsData([...modelsData]);
    filterOutVersions(index, val);
    modelsVersionsData[index] = null;
    setModelsVersionsData([...modelsVersionsData]);
  };

  return (
    <Stack className="deviceManagement">
      {versionError && versionError.includes(true) && (
        <ShowErrorMessageBar
          //resetChoice={resetChoice}
          errorResponse={errorMessages["sameModel"]}
        ></ShowErrorMessageBar>
      )}
      <Stack.Item className="heading">
        <Text>Model Comparison</Text>
      </Stack.Item>
      <StackItem></StackItem>
      <Stack
        horizontal
        tokens={{ childrenGap: 50 }}
        verticalAlign="center"
        className="fieldsContainer"
      >
        <StackItem styles={{ root: { flex: 1 } }}>
          <Stack.Item className="label">
            <Text>Select Algorithm*</Text>
          </Stack.Item>
          <Controller
            control={control}
            name="algorithmVersion[0].algorithm_id"
            shouldUnregister
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
              return (
                <Select
                  classNamePrefix="addl-class"
                  isDisabled={!visibleAlgorithmList}
                  placeholder={
                    !visibleAlgorithmList
                      ? "You do not have permission"
                      : "Select"
                  }
                  isClearable
                  options={algOptions}
                  value={selectedAlg}
                  // isLoading={algLoader}
                  onInputChange={(e) => {
                    algInputChangeHandler(e);
                  }}
                  onMenuClose={() => {
                    if (algOptions.length === 0) {
                      dispatch(algListWithPagination());
                    }
                  }}
                  onMenuOpen={() => {
                    function getNextPage() {
                      const da = document.getElementsByClassName(
                        "addl-class__menu-list"
                      )[0];
                      da &&
                        da.addEventListener("scroll", (e: any) => {
                          const bottom =
                            Math.round(e.target.scrollHeight) -
                              Math.round(e.target.scrollTop) ===
                            e.target.clientHeight;
                          if (bottom) {
                            // console.log("Reached bottom");
                            trackScrolling();
                          }
                        });
                    }
                    setTimeout(() => {
                      getNextPage();
                    }, 1000);
                  }}
                  onMenuScrollToBottom={(e) => handleScrollAlg(algList?.next)}
                  onChange={(val: any) => {
                    data.algorithm = val?.value as any;
                    // clearSelectedValue(val?.value);
                    setData({ ...data });
                    setSelectedAlg(val);
                    setAlgId(val?.value);
                    dispatch(getReadyDatasets(val?.value));
                    if (!val?.value) {
                      dispatch(algListWithPagination());
                      clearComparisonForm();
                      // setSelectedVideo(null);
                      // setModelsData([]);
                      // setModelsVersionsData([]);
                    }
                  }}
                />
              );
            }}
          />
        </StackItem>
        <StackItem styles={{ root: { flex: 1 } }}>
          <Stack.Item className="label">
            <Text>Select Video*</Text>
          </Stack.Item>
          <Controller
            control={control}
            name="algorithmVersion[0].algorithm_id"
            shouldUnregister
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
              return (
                <Select
                  classNamePrefix="addl-class"
                  isClearable
                  options={videoOptions}
                  value={selectedVideo}
                  isDisabled={!selectedAlg}
                  onKeyDown={(e) => datasetInputChangeHandler(e)}
                  onMenuScrollToBottom={(e) => handleScrollDataset(e)}
                  isLoading={loadingDatasets}
                  onChange={(val: any) => {
                    data.video = val?.value as any;
                    clearSelectedValue(val?.value);
                    setData({ ...data });
                    setSelectedVideo(val);
                    val?.value && dispatch(getUniqueModels(data));
                  }}
                />
              );
            }}
          />
        </StackItem>
      </Stack>
      <StackItem>
        {[{}, {}].map((_: any, i: number) => (
          <Stack
            horizontal
            tokens={{ childrenGap: 5 }}
            styles={{
              root: {
                marginTop: i === 0 ? "40px !important" : "5px",
                marginBottom: 5,
              },
            }}
            verticalAlign="center"
            className="fieldsContainer"
            key={i}
          >
            <CheckPermission {...SCOPES.LIST_ALL_MODELS}>
              <StackItem styles={{ root: { width: "40%" } }}>
                {i === 0 && (
                  <Stack.Item className="label">
                    <Text>Select Models</Text>
                  </Stack.Item>
                )}
                <Controller
                  control={control}
                  name="{i}"
                  shouldUnregister
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => {
                    return (
                      <Select
                        classNamePrefix="addl-class"
                        isClearable
                        options={modelOptions}
                        value={modelsData[i]}
                        isDisabled={
                          !selectedAlg || !selectedVideo || !permission
                        }
                        onChange={(val) => {
                          handleModalChange(i, val);
                          // modelsData[i] = val as any;
                          // setModelsData([...modelsData]);
                          // filterOutVersions(i, val);
                          // dispatch(getModelVersion(algId, val?.label));
                        }}
                      />
                    );
                  }}
                />
              </StackItem>
            </CheckPermission>
            {/* <CheckPermission {...SCOPES.LIST_ALL_MODELS}>
            </CheckPermission> */}
            <CheckPermission {...SCOPES.LIST_ALL_MODELS}>
              <StackItem styles={{ root: { width: 120 } }}>
                {i === 0 && (
                  <Stack.Item className="label">
                    <Text>Version</Text>
                  </Stack.Item>
                )}
                <Controller
                  control={control}
                  name="{modelVersions[i].label}"
                  shouldUnregister
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => {
                    return (
                      <Select
                        classNamePrefix="addl-class"
                        isClearable
                        isDisabled={
                          !selectedAlg || !selectedVideo || !permission
                        }
                        options={
                          modelVersionOptions[i] &&
                          i == 1 &&
                          modelsVersionsData[0]
                            ? modelVersionOptions[i].filter((item: any) => {
                                return item.id !== modelsVersionsData[0].id;
                              })
                            : modelVersionOptions[i]
                        }
                        value={modelsVersionsData[i]}
                        onChange={async (val) => {
                          modelsVersionsData[i] = val as any;
                          if (
                            i == 0 &&
                            modelsVersionsData[1] &&
                            Object.keys(modelsVersionsData[1]).length
                          ) {
                            modelsVersionsData[1] = null;
                          }
                          // console.log(modelsVersionsData, "modelsVersionsData");
                          setModelsVersionsData([...modelsVersionsData]);
                          if (i == 0 && modelsVersionsData[i]) {
                            const responce0: any = await dispatch(
                              getVideoOverlays(
                                selectedVideo?.value,
                                val?.value,
                                allActions.GET_FIRST_VIDEO
                              )
                            );
                            if (
                              (responce0 && responce0 === 404) ||
                              (responce0 && responce0.status === 404)
                            ) {
                              setVersionError([true, versionError[1]]);
                              // setErrors({ ...errors, sameModel: true });
                            } else if (
                              (responce0 && responce0 === 200) ||
                              (responce0 && responce0.status === 200)
                            ) {
                              setVersionError([false, versionError[1]]);
                            }
                            // console.log(responce0, "responce22");
                          }
                          if (i == 1 && modelsVersionsData[i]) {
                            const responce1: any = await dispatch(
                              getVideoOverlays(
                                selectedVideo?.value,
                                val?.value,
                                allActions.GET_SECOND_VIDEO
                              )
                            );
                            if (
                              (responce1 && responce1 === 404) ||
                              (responce1 && responce1.status === 404)
                            ) {
                              setVersionError([versionError[0], true]);
                              // setErrors({ ...errors, sameModel: true });
                            } else if (
                              (responce1 && responce1 === 200) ||
                              (responce1 && responce1.status === 200)
                            ) {
                              setVersionError([versionError[0], false]);
                              // setErrors({ ...{} });
                            }
                          }
                        }}
                      />
                    );
                  }}
                />
              </StackItem>
            </CheckPermission>
          </Stack>
        ))}
      </StackItem>

      <StackItem>
        <Stack
          className="createHospitalWizardFooter"
          horizontal
          horizontalAlign="end"
          verticalAlign="center"
          styles={{ root: { left: 0 } }}
          tokens={{ childrenGap: "m" }}
          // styles={{ root: { background: '#1f1f1f' } }}
        >
          <StackItem align="auto">
            <CheckPermission {...SCOPES.DEVELOPER_ADD_COMPARISION_RATING}>
              <PrimaryButton
                // type="submit"
                className="btn-next"
                form="surgeonForm"
                onClick={toggleModal}
                disabled={!enableComapre || disabledCreate}
              >
                Compare
                <Text className="spinnerRight">
                  {disabledCreate && <Spinner size={SpinnerSize.small} />}
                </Text>
              </PrimaryButton>
            </CheckPermission>
            {showRole && (
              <CompareModel
                videoData={videos?.results.find(
                  (video: any) => video.video === data.video
                )}
                isModalOpen={true}
                hideModal={closeModal}
                algorithm={selectedAlg}
                video={selectedVideo}
                models={modelsData}
                versions={modelsVersionsData}
                modelOptions={modelOptions}
                modelVersionOptions={modelVersionOptions}
                handleChangeModelComparision={handleChangeModelComparision}
                pageLoadder={pageLoadder}
                versionErrors={versionError}
                handleModalChange={handleModalChange}
              />
            )}
          </StackItem>
          <StackItem align="auto">
            <ActionButton
              text="Cancel"
              onClick={cancelModelComparison}
              className="btn-c"
              allowDisabledFocus
            />
          </StackItem>
        </Stack>
      </StackItem>
      <Modal isOpen={closeRoles} containerClassName={"modalStyle"}>
        <SuccessMessage title="Added Comments and Rating" />
      </Modal>
      {closeRoles &&
        setTimeout(() => {
          setShowRole(false);
          dispatch(roleReset());
          dispatch(showModelSpinner());
        }, 3000) && <></>}
    </Stack>
  );
};

export default Comparison;
