import React from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../../Wizard";
import AssignPermissions from "./AssignPermissions";
import RoleCreation from "./RoleCreation";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import {
  roleDecrement,
  updateSelectedRoleName,
  updateSelectedPermissionUserType,
  updateSelectedRoleLocation,
  updateSelectedRoleComments,
  updateIsPermissionListFetched,
} from "../../../actions/managamentActions";
import { useState } from "react";
import "./CreateRole.scss";
import { useEffect } from "react";
import { default as ManagementStrings } from "../../../activeadmin-management.string.json";

const cancelIcon: IIconProps = { iconName: "Cancel" };
const CreateRole = ({
  isModalOpen,
  showModal,
  roleId,
}: {
  isModalOpen: any;
  showModal: any;
  roleId: any;
}) => {
  const [isRoleChange, setIsRoleChange] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [showUserType, setShowUserType] = useState(false);
  const [showUserTypeClient, setShowUserTypeClient] = useState(false);
  const counts = useSelector((state: AppState) => {
    return state.managementReducerData.roleCount;
  });
  const names = [
    ManagementStrings.RoleCreationName,
    ManagementStrings.AssignPermissionsName,
  ];
  const dispatch = useDispatch();
  const showCreateRole = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateRole;
  });
  const rolesPermissionData = useSelector((state: AppState) => {
    return state.managementReducerData.rolesPermissionData.data;
  });
  const roleChangeHandler = (isNamechange: any) => {
    setIsRoleChange(isNamechange);
  };
  const userTypeChangeHandler = (changedUserType: any) => {
    dispatch(updateSelectedPermissionUserType(changedUserType));
    dispatch(updateIsPermissionListFetched(false));
  };
  const roleLocationChangeHandler = (changedRoleLocation: any) => {
    dispatch(updateSelectedRoleLocation(changedRoleLocation));
    setShowUserType(true);
    if (changedRoleLocation === ManagementStrings.ActivSurgicalRoleLocation) {
      setShowUserTypeClient(false);
      dispatch(updateSelectedPermissionUserType(""));
    } else if (changedRoleLocation === ManagementStrings.ClientRoleLocation) {
      setShowUserTypeClient(true);
      dispatch(
        updateSelectedPermissionUserType(
          ManagementStrings.HospitalAdminPermissionUserType
        )
      );
    }
  };
  useEffect(() => {
    if (
      rolesPermissionData &&
      rolesPermissionData?.role_location ===
        ManagementStrings.ActivSurgicalRoleLocation
    ) {
      setShowUserType(true);
      setShowUserTypeClient(false);
    } else if (
      rolesPermissionData &&
      rolesPermissionData?.role_location ===
        ManagementStrings.ClientRoleLocation
    ) {
      setShowUserType(true);
      setShowUserTypeClient(true);
    }
  }, [rolesPermissionData && rolesPermissionData?.role_location]);
  useEffect(() => {
    if (showCreateRole) {
      dispatch(updateSelectedRoleName(rolesPermissionData?.name));
      dispatch(updateSelectedRoleLocation(rolesPermissionData?.role_location));
      dispatch(
        updateSelectedPermissionUserType(rolesPermissionData?.user_type)
      );
      dispatch(updateSelectedRoleComments(rolesPermissionData?.comments));
    }
  }, [showCreateRole]);
  const spinnerFunc = (dummySpinner = false) => {
    if (dummySpinner) {
      setDisabledCreate(true);
    } else {
      setDisabledCreate(false);
    }
    return dummySpinner;
  };
  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={showModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted pointer" onClick={showModal}>
            {ManagementStrings.UserRolesAndPermissionsText}
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">
            {!showCreateRole
              ? ManagementStrings.CreateRoleName
              : ManagementStrings.EditRoleName}
          </Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={showModal} />
        </Stack.Item>
      </Stack>
      <Wizard
        counts={counts}
        form="roleForm"
        names={names}
        back={roleDecrement}
        // spinner={spinner}
        disabledCreate={disabledCreate}
        isCreate={!showCreateRole}
        showModal={showModal}
      >
        <RoleCreation
          showCreateRole={showCreateRole}
          rolesPermissionData={rolesPermissionData}
          roleChangeHandler={roleChangeHandler}
          userTypeChangeHandler={userTypeChangeHandler}
          roleLocationChangeHandler={roleLocationChangeHandler}
          showUserType={showUserType}
          showUserTypeClient={showUserTypeClient}
        />
        <AssignPermissions
          showModal={showModal}
          showCreateRole={showCreateRole}
          rolesPermissionData={rolesPermissionData}
          isRoleChange={isRoleChange}
          roleId={roleId}
          spinnerFunc={spinnerFunc}
        />
      </Wizard>
    </Modal>
  );
};
export default CreateRole;
