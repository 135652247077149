import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { getModelById } from "../../../actions/managementAction";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import ModelFullView from "./ModelFullView";
import { useParams } from "react-router-dom";
const ModelView = () => {
  const { algId, id, version, name, alg } = useParams();
  const dispatch = useDispatch();
  const modelById = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.modelById;
  });

  useEffect(() => {
    dispatch(getModelById(algId, id, version));
  }, []);
  return (
    <Stack>
      <ModelFullView
        algId={algId}
        id={id}
        version={version}
        name={name}
        alg={alg}
        model={modelById}
      ></ModelFullView>
    </Stack>
  );
};

export default ModelView;
