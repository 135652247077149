import moment from "moment";
import * as allActions from "../actions/actionConstants";

const initialState: any = {
  videoData: {},
  annotations: null,
  videoPhases: [],
  phasesLegendData: {},
  appliedAlgorithms: [],
  overlayUrls: {},
  activInsightData: {},
  annotationsLength: 0,
  sharedVideo: false,
  currentPlayTime: 0,
  showSpinner: true,
  showData: false,
  videoComments: [],
  nextPageUrl: null,
  addVideoComment: {},
  replyVideoComment: {},
  deleteVideoComment: {},
  editedVideoComment: {},
  showSpinnerVideoDetails: false,
  commentLoader: false,
};

const getAllUniqueComments = (comments: Array<any>) => {
  const uniqueIds: Array<string | number> = [];
  return comments
    .filter((comment: any) => {
      if (uniqueIds.includes(comment.id)) {
        return false;
      }
      uniqueIds.push(comment.id);
      return true;
    })
    .sort(
      (cmt1: any, cmt2: any) =>
        moment(cmt1.created_date).unix() - moment(cmt2.created_date).unix()
    );
};

export const videoPlayerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.SHOW_NO_DATA:
      return {
        ...state,
        showSpinner: false,
      };
    case allActions.SET_CURRENT_VIDEO_PLAYER_TIME:
      return {
        ...state,
        currentPlayTime: action.payload,
      };
    case allActions.GET_ACTIV_INSIGHTS_DATA:
      return {
        ...state,
        activInsightData: action.payload,
        showSpinner: false,
        showData: true,
      };
    case allActions.GET_VIDEO_PHASES:
      return {
        ...state,
        videoPhases: action.payload.phases,
        phasesLegendData: action.payload.phasesLegendData,
      };
    case allActions.GET_ANNOTATION_BLOB_URLS:
      return {
        ...state,
        overlayUrls: action.payload,
      };
    case allActions.FETCH_VIDEO_DETAILS: {
      if (action.payload.data) {
        return {
          ...state,
          videoData: action.payload.data,
          sharedVideo: action.payload.sharedVideo,
          showSpinner: action.payload.sharedVideo ? false : state.showSpinner,
          showData: action.payload.sharedVideo ? true : state.showData,
          showSpinnerVideoDetails: false,
        };
      }
      return { ...state, videoData: {} };
    }
    case allActions.FETCH_ANNOTATION_DATA:
      const data: any = action.payload.annotationData;
      const updatedData: any = state.annotations
        ? {
            ...data,
            annotations: {
              ...data.annotations,
              ...state.annotations["annotations"],
            },
          }
        : data;
      return {
        ...state,
        annotations: updatedData,
        annotationsLength: Object.keys(updatedData.annotations).length,
        overlayUrls: {
          ...state.overlayUrls,
          [action.payload.index]: {
            url: state.overlayUrls[action.payload.index].url,
            downloadStatus: true,
          },
        },
      };

    case allActions.FETCH_SEGMENTATION_DATA:
      return {
        ...state,
        showSpinner: false,
        appliedAlgorithms: action.payload,
      };

    case allActions.CLEAR_VIDEO_DATA:
      return {
        videoData: {},
        annotations: null,
        videoPhases: [],
        appliedAlgorithms: [],
        overlayUrls: {},
        activInsightData: {},
        annotationsLength: 0,
        currentPlayTime: 0,
        showSpinner: true,
        showData: false,
        sharedVideo: false,
      };

    case allActions.RESET_VIDEO_COMMENTS:
      return {
        ...state,
        videoComments: [],
        totalCommentCount: 0,
      };
    case allActions.GET_VIDEOS_BY_PROCEDURE:
      return {
        ...state,
        videosByProcedure: action.payload?.data,
      };
    case allActions.GET_VIDEO_COMMENTS:
      return {
        ...state,
        // videoComments: getAllUniqueComments([
        //   ...(action.payload?.data?.results || []),
        //   ...(state.videoComments || []),
        // ]),
        videoComments: action.payload?.data?.results,
        totalCommentCount: action.payload?.data?.count,
        nextPageUrl: action.payload?.data?.next,
        commentLoader: false,
      };

    case allActions.ADD_VIDEO_COMMENT:
      return {
        ...state,
        addVideoComment: action.payload?.data,
      };

    case allActions.ADD_REPLYTO_VIDEO_COMMENT:
      return {
        ...state,
        replyVideoComment: action.payload?.data,
      };

    case allActions.DELETE_VIDEO_COMMENT:
      return {
        ...state,
        deleteVideoComment: action.payload?.data,
        commentLoader: false,
      };

    case allActions.EDIT_VIDEO_COMMENT:
      return {
        ...state,
        editedVideoComment: action.payload?.data,
      };
    case allActions.DELETE_COMMENT_FROM_COMMENTS: {
      return {
        ...state,
        videoComments: state.videoComments.filter(
          (comment: any) => comment.id !== action?.id
        ),
      };
    }
    case allActions.SHOW_COMMENT_LOADER:
      return {
        ...state,
        commentLoader: true,
      };
    case allActions.HIDE_COMMENT_LOADER:
      return {
        ...state,
        commentLoader: false,
      };
    case allActions.SHOW_SPINNER_VIDEO_DETAILS:
      return {
        ...state,
        showSpinnerVideoDetails: !state.showSpinnerVideoDetails,
      };
      return {
        ...state,
        editedVideoComment: action.payload?.data,
      };
    default:
      return state;
  }
};
