import * as allActions from "../actions/actionConstants";
import { getDataSetAlg } from "../actions/datasetAction";
const initialState: any = {
  dataSets: [],
  dataSetsAlg: {},
  closeRoles: false,
  dataSetSpinner: false,
  algList: [],
  dataSetData: {},
  createDataSetError: null,
  appliedModelIdForVideo: [],
  datasetVideoMetadata: {},
  enableDeleteWarningMessageBar: true,
  enableEditDataSetModel: false,
  editedDataSetDetails: {},
  allAlgorithamsListBaseOnDataset: [],
  mappedAlgorithmsToDataSet: [],
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageAlgorithms: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  logoutDeveloperDataSetAlert: false,
};

export const dataSetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.GET_DATASETS:
      const previousDatasets = state.dataSets?.results || [];
      state.dataSets = action.payload.data;
      state.dataSets.results = [...previousDatasets, ...state.dataSets.results];
      return {
        ...state,
        loadingDatasets: false,
        mappedAlgorithmsToDataSet: [],
      };
    case allActions.CLEAR_DATASETS:
      return {
        ...state,
        dataSets: [],
        mappedAlgorithmsToDataSet: [],
        appliedModelIdForVideo: [],
      };
    case allActions.SHOW_DATASET_LOADER:
      return {
        ...state,
        loadingDatasets: true,
      };
    case allActions.HIDE_DATASET_LOADER:
      return {
        ...state,
        loadingDatasets: false,
      };
    case allActions.LOADER:
      if (action.payload.calledFrom === "REFRESH_ALGO_LIST") {
        return {
          ...state,
          loaderWithMessageAlgorithms: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
      return {
        ...state,
        loaderWithMessage: {
          loadingState: true,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
        },
      };
    case allActions.STOPLOADER:
      if (action.payload.calledFrom === "REFRESH_ALGO_LIST") {
        return {
          ...state,
          loaderWithMessageAlgorithms: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      }
      return {
        ...state,
        loaderWithMessage: {
          loadingState: false,
          message: action.payload?.message,
          calledFrom: action.payload?.calledFrom,
          statusCode: action.payload?.statusCode,
        },
      };
    case allActions.SET_DATASET_VIDEO_METADATA:
      const { id, value } = action.payload;
      const datasetVideoMetadataClone = Object.assign(
        {},
        state.datasetVideoMetadata
      );
      datasetVideoMetadataClone[id] = value;
      return {
        ...state,
        datasetVideoMetadata: datasetVideoMetadataClone,
      };
    case allActions.CLEAR_DATASET_VIDEO_METADATA:
      return {
        ...state,
        datasetVideoMetadata: {},
      };
    case allActions.GET_DATASET_ALG:
      return {
        ...state,
        dataSetsAlg: {
          ...state.dataSetsAlg,
          [action.algId]: action.payload.data,
        },
      };
    case allActions.SHOW_LOADER:
      return {
        ...state,
        loadingState: true,
      };
    case allActions.UPDATE_DATASET_ALG:
      const previousAlgData = state.dataSetsAlg[action.algId] || {};
      const currentData = action.payload.data;
      if (previousAlgData.results) {
        currentData.results = [
          ...previousAlgData.results,
          ...currentData.results,
        ];
      }
      return {
        ...state,
        dataSetsAlg: { ...state.dataSetsAlg, [action.algId]: currentData },
      };
    case allActions.DATASET_SPINNER:
      return {
        ...state,
        closeRoles: false,
      };
    case allActions.GET_ALGO_LIST:
      const previousData = state.algorithmList?.data?.results || [];
      state.algorithmList = action.payload;
      state.algorithmList.data.results = [
        ...previousData,
        ...state.algorithmList.data.results,
      ];
      return {
        ...state,
      };
    case allActions.REFRESH_ALGO_LIST:
      return {
        ...state,
        algorithmList: action.payload,
      };
    case allActions.ALGORITHMS_LIST:
      return {
        ...state,
        algList: action.payload.data,
      };

    case allActions.CREATE_DATASET:
      return {
        ...state,
        dataSetData: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.dataSetData?.data?.results || []),
            ],
          },
        },
        showRoleSpinner: false,
        closeRoles: true,
      };
    case allActions.CREATE_DATASET_ERROR:
      return {
        ...state,
        createDataSetError: action?.payload?.data[0]?.details,
      };
    case allActions.CLEAR_CREATE_DATASET_ERROR:
      return {
        ...state,
        createDataSetError: null,
      };

    case allActions.EDIT_SUCCESS_MESSAGE:
      return {
        ...state,
        editDataSetSuccess: true,
      };
    case allActions.CLOSE_SUCCESS_MESSAGE:
      return {
        ...state,
        editDataSetSuccess: false,
      };
    case allActions.APPLIED_MODELID_OF_VIDEO:
      return {
        ...state,
        appliedModelIdForVideo: action?.payload?.data,
      };
    case allActions.SHOW_DELETE_WARNING_MESSAGE_BAR:
      return {
        ...state,
        enableDeleteWarningMessageBar: true,
      };
    case allActions.HIDE_DELETE_WARNING_MESSAGE_BAR:
      return {
        ...state,
        enableDeleteWarningMessageBar: false,
      };
    case allActions.SHOW_EDIT_DATASET_MODEL:
      return {
        ...state,
        enableEditDataSetModel: true,
      };
    case allActions.HIDE_EDIT_DATASET_MODEL:
      return {
        ...state,
        enableEditDataSetModel: false,
      };
    case allActions.EDITED_DATASET_DETAILS:
      return {
        ...state,
        editedDataSetDetails: action?.payload?.editedDataSetDetails,
      };
    case allActions.ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET:
      return {
        ...state,
        allAlgorithamsListBaseOnDataset: action?.payload?.data,
      };
    case allActions.GET_MAPPED_ALGORITHMS_TO_DATASET:
      return {
        ...state,
        mappedAlgorithmsToDataSet: action?.payload?.mappedAlgorithmsToDataSet,
      };
    case "LOGOUT_DEVELOPER_ALERT":
      return {
        ...state,
        logoutDeveloperDataSetAlert: !state.logoutDeveloperDataSetAlert,
      };
    default:
      return state;
  }
};
