import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import axios from "axios";
import * as allActions from "./actionConstants";
import { uploadDataSetFile } from "../../../InProgress/actions/InProgressAction";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getDataSets = (nextUrl?: string) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url:
      nextUrl ||
      apiEndPoint + `/developer/algorithms/datasets?page=1&page_size=20`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_DATASETS));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_DATASETS,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_DATASETS, response.status)
      );
      return response;
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_DATASETS,
          403
        )
      );
    }
    console.error(response);
  }
};

export const cleanDatasets = () => {
  return {
    type: allActions.CLEAR_DATASETS,
  };
};

export const getDataSetAlg =
  (algId: any, currentPage = 1, nextUrl = "") =>
  async (dispatch: any) => {
    const currentUserPage = currentPage > 1 ? `?page=${currentPage}` : "";

    const payload = {
      method: "GET",
      url:
        nextUrl ||
        apiEndPoint + `/developer/datasets/${algId}${currentUserPage}`,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          nextUrl ? allActions.UPDATE_DATASET_ALG : allActions.GET_DATASET_ALG
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.data && response.status === 200) {
        dispatch({
          type: nextUrl
            ? allActions.UPDATE_DATASET_ALG
            : allActions.GET_DATASET_ALG,
          payload: { data: response.data },
          algId,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            nextUrl
              ? allActions.UPDATE_DATASET_ALG
              : allActions.GET_DATASET_ALG,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            nextUrl
              ? allActions.UPDATE_DATASET_ALG
              : allActions.GET_DATASET_ALG,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const showDatasetSpinner = () => {
  return {
    type: allActions.DATASET_SPINNER,
  };
};

export const showAlgSpinner = () => {
  return {
    type: allActions.SHOW_LOADER,
  };
};

export const showDatasetLoader = () => {
  return {
    type: allActions.SHOW_DATASET_LOADER,
  };
};
export const hideDatasetLoader = () => {
  return {
    type: allActions.HIDE_DATASET_LOADER,
  };
};

export const algListWithPagination =
  (nextUrl = "", alg_name = "") =>
  async (dispatch: any) => {
    let defaultUrl = apiEndPoint + `/algorithms/?page=1`;
    if (alg_name) {
      defaultUrl = `${defaultUrl}&search=${alg_name}`;
    }
    const payload = {
      method: "GET",
      url: nextUrl || defaultUrl,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          nextUrl ? allActions.GET_ALGO_LIST : allActions.REFRESH_ALGO_LIST
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: nextUrl
            ? allActions.GET_ALGO_LIST
            : allActions.REFRESH_ALGO_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            nextUrl ? allActions.GET_ALGO_LIST : allActions.REFRESH_ALGO_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            nextUrl ? allActions.GET_ALGO_LIST : allActions.REFRESH_ALGO_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getAlgList =
  // () =>


    (searchValue = "", currentPage = 1) =>
    async (dispatch: any) => {
      // const currentUserPage = currentPage > 1 ? `?page=${currentPage}` : "";
      // const url = apiEndPoint + `/algorithms${currentUserPage}`,
      const search = searchValue !== "" ? `&search=${searchValue}` : "";

      const payload = {
        method: "GET",
        url:
          apiEndPoint +
          `/algorithms/?page=${currentPage}&page_size=1000${search}`,
      };
      try {
        dispatch(showLoaderWithMessage("", allActions.ALGORITHMS_LIST));
        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.ALGORITHMS_LIST,
            payload: { data: response.data },
          });
          dispatch(
            hideLoaderWithMessage(
              "",
              allActions.ALGORITHMS_LIST,
              response.status
            )
          );
        }
      } catch (response) {
        if (response) {
          dispatch(
            hideLoaderWithMessage(
              permissionIssueMessage,
              allActions.ALGORITHMS_LIST,
              403
            )
          );
        }
        console.error(response);
      }
    };

export const createDataset = (dataSet: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/developer/dataset",
    data: dataSet,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_DATASET));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dataSet.uploadUrl = response.data.url;
      dataSet.correlationId = response.data.correlationId;
      dispatch(uploadDataSetFile(dataSet));
      dispatch({
        type: allActions.CREATE_DATASET,
        payload: { data: dataSet },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_DATASET, response.status)
      );
      return response;
    } else {
      dispatch({
        type: allActions.CREATE_DATASET_ERROR,
        payload: response,
      });
      return response;
    }

    /*
    if (response && (response.status === 200 || response.status === 201)) {
      const tempFile = URL.createObjectURL(
        new Blob([JSON.stringify(dataSet)], { type: "application/json" })
      );
      console.error(tempFile, "tempFile");
      let config = {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Type": "multipart/form-data",
        },
      };
      // dataSet.uploadUrl = response.data.url;
      // dataSet.correlationId = response.data.correlationId;
      const responseUrl = await axios.put(response?.data?.url, dataSet, config);
      //   dispatch({
      //     type: allActions.CREATE_DATASET,
      //     payload: { data: dataSet },
      //   });
      // } else {
      //   dispatch({
      //     type: allActions.CREATE_DATASET_ERROR,
      //     payload: response.data,
      //   });
      if (responseUrl?.status === 201) {
        console.error(response, "response1");
        const payloadResponseUrl = {
          method: "PATCH",
          url:
            apiEndPoint + `/developer/dataset/${response?.data?.correlationId}`,
          data: { status: "UPLOADED" },
        };

        const responseStatus = await doHttpCall(payloadResponseUrl);
        if (
          responseStatus &&
          (responseStatus.status === 200 || responseStatus.status === 201)
        ) {
          dispatch({
            type: allActions.CREATE_DATASET,
            payload: { data: dataSet },
          });
        }
        if (
          responseStatus &&
          (responseStatus.status === 404 || responseStatus.status === 400)
        ) {
          dispatch({
            type: allActions.CREATE_DATASET_ERROR,
            payload: responseStatus,
          });
        }
        if (responseStatus && responseStatus.status === 500) {
          dispatch({
            type: allActions.CREATE_DATASET_ERROR,
            payload: response,
          });
        }
      }
    }
    */
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_DATASET,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getAppliedModelIdVideo =
  (videoId: any, algId: any) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url:
        apiEndPoint + `/developer/videos/${videoId}/algorithm/${algId}/models`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.APPLIED_MODELID_OF_VIDEO));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.APPLIED_MODELID_OF_VIDEO,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.APPLIED_MODELID_OF_VIDEO,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.APPLIED_MODELID_OF_VIDEO,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const showDeleteWarningMessageBar = () => {
  return {
    type: allActions.SHOW_DELETE_WARNING_MESSAGE_BAR,
  };
};

export const hideDeleteWarningMessageBar = () => {
  return {
    type: allActions.HIDE_DELETE_WARNING_MESSAGE_BAR,
  };
};

export const showEditDataSetModel = () => {
  return {
    type: allActions.SHOW_EDIT_DATASET_MODEL,
  };
};

export const hideEditDataSetModel = () => {
  return {
    type: allActions.HIDE_EDIT_DATASET_MODEL,
  };
};

export const getEditedDataSetDetails = (data: any) => {
  return {
    type: allActions.EDITED_DATASET_DETAILS,
    payload: { editedDataSetDetails: data },
  };
};
export const updateAndDeleteEditedDataset =
  (uuID: any, algorithms: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/developer/dataset/${uuID}/algorithms`,
      data: algorithms,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_SUCCESS_MESSAGE));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_SUCCESS_MESSAGE,
        });
        setTimeout(() => {
          dispatch({
            type: allActions.CLOSE_SUCCESS_MESSAGE,
          });
        }, 2000);
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.EDIT_SUCCESS_MESSAGE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_SUCCESS_MESSAGE,
            403
          )
        );
      }
      console.error(response);
    }
  };
export const getAllAlgorithamsListBaseOnDataSet =
  (uuID: any) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/developer/dataset/${uuID}/algorithms`,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          allActions.ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET
        )
      );
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.ALL_ALGORITHAM_LIST_BASE_ON_DATA_SET,
            403
          )
        );
      }
    }
  };
export const getMappedAlgorithms = (uuID: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",

    url: apiEndPoint + `/developer/dataset/${uuID}/algorithms`,
  };

  try {
    dispatch(
      showLoaderWithMessage("", allActions.GET_MAPPED_ALGORITHMS_TO_DATASET)
    );
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.GET_MAPPED_ALGORITHMS_TO_DATASET,
        payload: { mappedAlgorithmsToDataSet: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_MAPPED_ALGORITHMS_TO_DATASET,
          response.status
        )
      );
    } else {
      dispatch({
        type: allActions.GET_MAPPED_ALGORITHMS_TO_DATASET,
        payload: { mappedAlgorithmsToDataSet: [] },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_MAPPED_ALGORITHMS_TO_DATASET,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_MAPPED_ALGORITHMS_TO_DATASET,
          403
        )
      );
    }
    console.error(response);
  }
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
