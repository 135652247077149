import React, { useCallback } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../Wizard";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import { useState, useEffect } from "react";
import SurgeonCreation from "./SurgeonCreation";
import "./CreateSurgeon.scss";
import { createSurgeon, editSurgeon } from "../../actions/homeAction";
const cancelIcon: IIconProps = { iconName: "Cancel" };

const CreateSurgeon = ({
  isModalOpen,
  hideModal,
  isEdit,
  currentPage,
}: {
  isModalOpen: any;
  hideModal: any;
  isEdit: any;
  currentPage?: any;
}) => {
  const [showResMsg, setShowResMsg] = useState<boolean>(false);
  const [disabledCreate, setDisabledCreate] = useState(false);

  const counts = useSelector((state: AppState) => {
    return state.managementReducerData.roleCount;
  });
  const dispatch = useDispatch();

  const spinner = useSelector((state: AppState) => {
    return state.managementReducerData.showRoleSpinner;
  });
  // isModalOpen = hospitalSpinner ? !hospitalSpinner : isModalOpen;
  const names = ["Group Creation", "Assign Privileges"];
  useEffect(() => {
    // dispatch(showRoleSpinner());
  }, []);

  const [errors, setErrors] = useState<{
    first_name?: string;
    last_name?: string;
    speciality?: string;
    facility?: [];
    permissions?: string;
    mail?: string;
    phone_number?: string;
    comments?: string;
  }>({});

  const [data, setData] = useState<{
    first_name: string;
    last_name: string;
    speciality: string;
    facility?: [];
    permissions: string;
    mail: string;
    phone_number: string;
    comments?: string;
  }>({
    first_name: "",
    last_name: "",
    speciality: "",
    facility: [],
    permissions: "",
    mail: "",
    phone_number: "",
    comments: "",
  });

  useEffect(() => {
    setDisabledCreate(false);
    setErrors({});
  }, [isModalOpen]);
  useEffect(() => {
    // console.log(errors)
    // setDisabledCreate(false);
  }, [errors]);

  const phoneValidationRegex = RegExp(
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  );
  const emailValidationRegex = RegExp(
    /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  );

  const onCreate = useCallback(() => {
    const {
      first_name,
      last_name,
      speciality,
      facility,
      permissions,
      mail,
      phone_number,
      comments,
    } = data;
    const errors: any = {};
    if (first_name.trim().length === 0) {
      errors.first_name = "First Name should not be empty.";
    }
    if (last_name.trim().length === 0) {
      errors.last_name = "Last Name should not be empty.";
    }
    if (speciality.trim().length === 0) {
      errors.speciality = "Specialty should not be empty.";
    }
    if (!permissions || permissions.trim().length === 0) {
      errors.permissions = "Permissions should not be empty.";
    }
    if (mail.trim().length === 0) {
      errors.mail = "Mail should not be empty.";
    }
    if (phone_number.trim().length === 0) {
      errors.phone_number = "Phone Number should not be empty.";
    }

    if (Object.keys(errors).length === 0) {
      setDisabledCreate(true);
      setTimeout(() => {
        setDisabledCreate(false);
      }, 3000);
      setShowResMsg(true);
      const formData = { ...data, facility: data.facility?.join(",") };
      if (surgeonById?.userId && !formData.comments) {
        delete formData.comments;
      }
      if (surgeonById?.userId && !formData.facility) {
        delete formData.facility;
      }

      surgeonById?.userId
        ? dispatch(editSurgeon(surgeonById?.userId, formData, currentPage))
        : dispatch(createSurgeon(formData));
    } else {
      setDisabledCreate(false);
    }
    setErrors(errors);
  }, [data]);

  const createSurgeonError = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.createSurgeonError;
  });

  const surgeonById = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.surgeonById;
  });
  useEffect(() => {
    setDisabledCreate(false);
  }, [createSurgeonError]);

  useEffect(() => {
    if (surgeonById) {
      setData({
        first_name: surgeonById?.first_name,
        last_name: surgeonById?.last_name,
        speciality: surgeonById?.speciality_name,
        facility: surgeonById?.facility_name
          ? surgeonById?.facility_name?.split(",")
          : [],
        permissions: surgeonById?.permissions_name,
        mail: surgeonById?.mail,
        phone_number: surgeonById?.phone_number,
        comments: surgeonById?.comments,
      });
    }
  }, [surgeonById]);

  return (
    <Modal
      className="createHospital"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted">
            Home
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">
            {isEdit ? "Update Surgeon" : "Add Surgeon"}
          </Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={hideModal} />
        </Stack.Item>
      </Stack>

      <Wizard
        // spinner={spinner}
        disabledCreate={disabledCreate}
        onCreate={onCreate}
        onCancel={hideModal}
        isEdit={isEdit}
      >
        <SurgeonCreation
          updateData={setData}
          isEdit={isEdit}
          showResMsg={showResMsg}
          setShowResMsg={setShowResMsg}
          data={data}
          errors={errors}
        />
      </Wizard>
    </Modal>
  );
};

export default CreateSurgeon;
