import {
  IButtonStyles,
  Image,
  IStackItemStyles,
  IStackProps,
  ITextFieldStyles,
  ITextStyles,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import activEdgeLogo from "../../../assets/activeEdge_logo.svg";
import React from "react";
import { fonts, ThemeColorPalette } from "../../../theme";
import axios from "axios";
import { apiEndPoint } from "../../../common/restApi";
import { getParameterByName } from "../../../common/util";
import warningIcon from "../../../assets/warning.svg";
import { showLogin } from "../../actions/authAction";
import { connect } from "react-redux";
import ResetSuccess from "../../components/ResetSuccess";
import HomeLeftScreen from "../../components/HomeLeftScreen";

export interface ResetPasswordProps {
  loginPageAction: any;
}

export interface ResetPasswordState {
  noUidToken: boolean;
  valid: string;
  passwordData: object;
  passwordError: string;
  resetSuccess: boolean;
}

const rightColumnProps: Partial<IStackProps> = {
  tokens: { padding: "0% 15%", childrenGap: 10 },
};
const containerStyles: React.CSSProperties = {
  minHeight: "100vh",
  maxHeight: "100%",
};

const stackItemRightStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.white,
    flex: 1,
  },
};

const headerTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    fontFamily: fonts.DINPro_Bold,
  },
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    letterSpacing: "0.5px",
    fontFamily: fonts.HelveticaNeue_Medium,
    color: ThemeColorPalette.neutralPrimary,
    opacity: 0.5,
  },
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    width: "100%",
    fontFamily: fonts.HelveticaNeue_Bold,
  },
  rootHovered: { backgroundColor: ThemeColorPalette.themePrimary },
};

class ResetPassword extends React.Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  state = {
    noUidToken: false,
    valid: "",
    passwordData: {
      password: "",
      confirm_password: "",
    },
    passwordError: "",
    resetSuccess: false,
  };
  componentDidMount() {
    const uid = getParameterByName("uid");
    const token = getParameterByName("token");

    if (uid !== "" && token !== "") {
      axios
        .get(apiEndPoint + `/auth/password-reset/${uid}/${token}/`)
        .then((res) => {
          this.setState({ ...this.state, noUidToken: false });
        })
        .catch((err) => {
          this.setState({
            ...this.state,
            valid:
              "Link is expired! Please enter valid link to reset the password",
          });
        });
    } else {
      this.setState({ ...this.state, noUidToken: true });
    }
  }

  handleSubmit(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const uid = getParameterByName("uid");
    const token = getParameterByName("token");

    const { password, confirm_password } = this.state.passwordData;

    if (
      password !== "" &&
      confirm_password !== "" &&
      password === confirm_password
    ) {
      password.length < 9
        ? this.setState({
            ...this.state,
            passwordError:
              "Entered Password should be minimum of 8 Characters ",
          })
        : axios
            .post(
              apiEndPoint + `/auth/password-reset/${uid}/${token}/`,
              this.state.passwordData
            )
            .then((res) => {
              this.setState({
                ...this.state,
                passwordError: "",
                valid: res.data,
                resetSuccess: true,
              });
            })
            .catch((err) => {
              this.setState({
                ...this.state,
                valid: "SomeThing went wrong! Please generate link again.",
              });
            });
    } else {
      this.setState({
        ...this.state,
        passwordError:
          "Please check password and confirmation password are matching",
      });
    }
  }

  handleChange(event: any) {
    const nameVal = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      passwordData: {
        ...this.state.passwordData,
        [nameVal]: value,
      },
    });
  }

  render() {
    return !this.state.resetSuccess ? (
      <Stack horizontal style={containerStyles}>
        <HomeLeftScreen />
        <StackItem styles={stackItemRightStyles}>
          <Stack verticalFill {...rightColumnProps} verticalAlign="center">
            <Image
              src={activEdgeLogo}
              alt="Activ Edge logo"
              width={100}
              height={80}
            />
            <StackItem tokens={{ padding: "2rem 0 1rem" }}>
              <Text styles={headerTextStyles} variant="xLarge" nowrap block>
                Password Reset
              </Text>
            </StackItem>
            {!this.state.noUidToken && this.state.valid === "" ? (
              <Stack>
                <Stack horizontal horizontalAlign="start">
                  {this.state.passwordError !== "" ? (
                    <Stack horizontal className="warningStyles">
                      <Image src={warningIcon} alt="warning icon" />
                      <Text
                        variant="smallPlus"
                        styles={{
                          root: {
                            color: ThemeColorPalette.neutralPrimary,
                            paddingLeft: "0.5rem",
                          },
                        }}
                      >
                        {this.state.passwordError}
                      </Text>
                    </Stack>
                  ) : null}
                </Stack>
                <Stack tokens={{ childrenGap: 15 }}>
                  <Stack>
                    <StackItem tokens={{ padding: "1rem 0 1rem 0" }}>
                      <TextField
                        inputClassName="textFieldStyles"
                        styles={textFieldStyles}
                        placeholder="Enter New password"
                        underlined
                        type="password"
                        name="password"
                        onChange={(event: React.FormEvent) =>
                          this.handleChange(event)
                        }
                      />
                    </StackItem>
                    <StackItem tokens={{ padding: "0 0 1rem 0" }}>
                      <TextField
                        inputClassName="textFieldStyles"
                        styles={textFieldStyles}
                        placeholder="Confirm New password"
                        underlined
                        type="password"
                        name="confirm_password"
                        autoComplete="off"
                        onChange={(event: React.FormEvent) =>
                          this.handleChange(event)
                        }
                      />
                    </StackItem>
                  </Stack>
                  <PrimaryButton
                    type="submit"
                    text="Submit"
                    styles={buttonStyles}
                    onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                      this.handleSubmit(event)
                    }
                  />
                </Stack>
              </Stack>
            ) : (
              <Stack tokens={{ childrenGap: 20 }}>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  className="warningStyles"
                >
                  <StackItem tokens={{ padding: "0 0.5rem 0 5px" }}>
                    <Image src={warningIcon} alt="warning icon" />
                  </StackItem>
                  <Text
                    variant="medium"
                    styles={{
                      root: { color: ThemeColorPalette.neutralPrimary },
                    }}
                  >
                    {this.state.noUidToken && this.state.valid === ""
                      ? "Uid and Token is invalid. Please generate link again!"
                      : this.state.valid}
                  </Text>
                </Stack>
                <PrimaryButton
                  text="Back to Login"
                  onClick={() => (window.location.href = "/")}
                />
              </Stack>
            )}
          </Stack>
        </StackItem>
      </Stack>
    ) : (
      <ResetSuccess valid={this.state.valid} />
    );
  }
}

const dispatchActionToProps = (dispatch: any) => {
  return {
    loginPageAction: (value: boolean) => dispatch(showLogin(value)),
  };
};

export default connect(null, dispatchActionToProps)(ResetPassword);
