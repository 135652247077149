import { Stack, IPivotItemProps, Pivot, PivotItem } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../rootReducer";
import SalesDashboard from "../components/SalesDashboard/SalesDashboard";
import ServiceDashboard from "../components/ServiceDashboard/ServiceDashboard";
import ViewLogFiles from "../components/ViewLogsFiles/ViewLogFiles";
import "./Logs.scss";

const Logs = () => {
  return (
    <Stack className="logsSection">
      <Pivot aria-label="Basic Pivot Example">
        {/* ------------------Sales Dashboard---------------------- */}
        <PivotItem headerText="Sales Dashboard">
          <SalesDashboard />
        </PivotItem>
        <PivotItem headerText="Service Dashboard">
          <ServiceDashboard />
        </PivotItem>
        <PivotItem headerText="View Log Files">
          <ViewLogFiles />
        </PivotItem>
      </Pivot>
    </Stack>
  );
};
export default Logs;
