import * as allActions from "../actions/proceduresActionConstants";

const initialState: any = {
  videosData: {},
  tempVideosData: {},
  videoEditedFields: {},
  showSpinner: true,
  showData: false,
  currentPage: 1,
  hospitalAvg: 0.0,
  showSpinnerForVideo: false,
};
export const proceduresReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case allActions.SHOW_SPINNER_VIDEO:
      return {
        ...state,
        showSpinnerForVideo: true,
      };
    case allActions.SHOW_SPINNER:
      return {
        ...state,
        showSpinner: true,
      };
    case allActions.FETCH_VIDEOS:
      return {
        ...state,
        videosData: action.payload.data,
        showSpinner:
          action.payload &&
          action.payload.data &&
          action.payload.data.results &&
          action.payload.data.results.length &&
          false,
        showSpinnerForVideo:
          action.payload &&
          action.payload.data &&
          action.payload.data.results &&
          action.payload.data.results.length &&
          false,
        showData:
          action.payload &&
          action.payload.data &&
          action.payload.data.results &&
          action.payload.data.results.length &&
          true,
        currentPage: action.payload.currentPage,
      };
    case allActions.SET_SELECTED_PROCEDURE_VIDEO:
      const videosData = {
        ...state.videosData,
        results: state.videosData.results.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isSelected: !item.isSelected,
            };
          } else {
            return {
              ...item,
              isSelected: false,
            };
          }
        }),
      };
      return {
        ...state,
        videosData: videosData,
        tempVideosData: videosData,
      };
    case allActions.SET_SELECTED_VIDEO_UNFAVOURITE:
      return {
        ...state,
        videosData: {
          ...state.videosData,
          results: state.videosData.results.map((item: any) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                favorite: !item.favorite,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
      };
    case allActions.EDIT_VIDEO_DETAILS:
      return {
        ...state,
        videosData: {
          ...state.videosData,
          results: state.videosData.results.map((item: any) => {
            if (item.id === action.payload.videoId) {
              return {
                ...item,
                ...state.videoEditedFields,
                ...action.payload.data,
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        },
        videoEditedFields: {
          ...state.videoEditedFields,
          ...action.payload.data,
        },
      };
    case allActions.RESET_EDITED_DATA_ON_CANCEL_PROCEDURES:
      return {
        ...state,
        videosData:
          action.payload === "cancel" ? state.tempVideosData : state.videosData,
        tempVideosData:
          action.payload === "cancel" ? state.tempVideosData : state.videosData,
        videoEditedFields: {},
      };
    case allActions.GET_HOSPITAL_AVG:
      return {
        ...state,
        hospitalAvg: action.payload.data,
      };
    case allActions.CLEAR_PROCEDURES_DATA_WHEN_UNMOUNT:
      return {
        videosData: {},
        videoEditedFields: {},
        showSpinner: true,
        showData: false,
        hospitalAvg: 0.0,
      };
    case allActions.DELETE_VIDEO_FROM_PROCEDURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
