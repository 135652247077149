import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Devices.scss";
import {
  getAllDevices,
  getDeviceById,
  editDeviceById,
} from "../../actions/managementAction";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import { showSpinner } from "../../../../ActiveAdminModule/Home/actions/homeAction";
import Paging from "../../../../common/paging/Paging";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Devices = () => {
  const [showDevics, setShowDevice] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const closeRoles = useSelector((state: AppState) => {
    return state.managementReducerData.closeRoles;
  });
  const showModal = () => {
    setShowDevice((current) => !current);
  };
  const dispatch = useDispatch();

  const devices = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.devicesList;
  });

  const deviceById = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.deviceById;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.loaderWithMessageDevices;
  });

  useEffect(() => {
    dispatch(getAllDevices());
  }, []);

  const onPageChange = useCallback((pageNo) => {
    setCurrentPage(pageNo);
    dispatch(getAllDevices(pageNo));
  }, []);

  const _onMenuClick = useCallback((text: string, id: string | number) => {
    if (text.toLowerCase() === "edit") {
      dispatch(getDeviceById(id));
    }
  }, []);

  return (
    <Stack className="deviceManagement">
      <Stack.Item className="heading">
        <Text>Device List</Text>
      </Stack.Item>
      <Stack className="availableDeviceList">
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text>Device ID</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Location</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text variant="smallPlus">IP Address</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg2">
              <Text variant="smallPlus">Disk Storage</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg3">
              <Text variant="smallPlus">Contract Validity</Text>
            </Stack.Item>
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
          {devices?.results?.map((device: any, index: any) => (
            <Stack.Item key={index} className="ms-Grid-row tableText">
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text
                  variant="smallPlus"
                  className="textNoWrap"
                  title={device?.serial_number}
                >
                  {device?.serial_number}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text
                  variant="smallPlus"
                  className="textNoWrap"
                  title={device?.room}
                >
                  {device?.room}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg3">
                <Text variant="smallPlus">
                  {device?.external_ip ? device?.external_ip : "-"}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text variant="smallPlus">
                  {device?.disk_percentage ? device?.disk_percentage : "0"}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg2">
                <Text variant="smallPlus">
                  {moment(device?.allocation_end).format("MM/DD/YY HH:mm")}
                </Text>
              </Stack.Item>
            </Stack.Item>
          ))}
        </Stack.Item>
        {/* {loadingState && (
          <>
            <Loader></Loader>
          </>
        )} */}
        {devices?.count > 10 && devices?.page_size ? (
          <Paging
            currentPage={currentPage}
            totalPageCount={Math.ceil(devices?.count / 10)}
            handlePageChange={onPageChange}
          />
        ) : null}
      </Stack>
    </Stack>
  );
};

export default Devices;
