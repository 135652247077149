import {
  IStackItemStyles,
  Stack,
  StackItem,
  Text,
  Breadcrumb,
  IBreadcrumbItem,
} from "@fluentui/react";
import * as React from "react";
import { connect } from "react-redux";
import { ThemeColorPalette } from "../../theme";
import { withRouter } from "../../withRouter";
import {
  getInprogressSelectedData,
  setInprogressData,
  deleteInprogressItem,
  editInprogressUploadingMetaData,
  saveEditedMetaData,
  getProcessingVideos,
  setProcessingVideoSelect,
  saveProcessingData,
  removeSelectAfterUnmount,
} from "../actions/InProgressAction";
import InprogressCard from "../components/InprogressVideoCard";
import ProcessingCard from "../components/ProcessingCard";
import ViewEditVideoData from "../components/ViewEditVideoData";
import "./InProgress.scss";

const errorObject = { largePdfFileError: "", isUploadConsentFormError: "" };

const containerStyles: IStackItemStyles = {
  root: {
    minHeight: "100%",
    maxHeight: "100%",
    minWidth: "100%",
    maxWidth: "100%",
  },
};

interface InProgressProps {
  inProgressVideosData: any;
  getInprogressVideosAction: any;
  getInprogressSelectedData: any;
  proceduresData: any;
  userData: Array<any>;
  deleteInprogressItem: any;
  userProfile: any;
  history: any;
  editInprogressUploadingMetaData: any;
  saveEditedMetaData: any;
  editedMetaDataFields: any;
  getProcessingVideos: any;
  processingData: any;
  setProcessingVideoSelect: any;
  saveProcessingData: any;
  removeSelectAfterUnmount: any;
  currentPage: any;
}

interface InProgressState {
  isSelected: boolean;
  isEditable: boolean;
  selectedVideo: any;
  shouldDelete: boolean;
  enableSave: boolean;
  deleteVideoId: string;
  previousCount: any;
  fileUploadScreen: any;
}

class InProgress extends React.Component<InProgressProps, InProgressState> {
  constructor(props: InProgressProps) {
    super(props);
    this.state = {
      isSelected: true,
      isEditable: false,
      selectedVideo: {},
      shouldDelete: false,
      enableSave: false,
      deleteVideoId: "",
      previousCount: 0,
      fileUploadScreen: "upload-screen",
    };
  }

  componentWillMount(): void {
    this.props.getProcessingVideos();
  }

  componentDidMount() {
    const { inProgressVideosData } = this.props;
    this.setState({
      ...this.state,
      previousCount: inProgressVideosData.length,
    });
  }

  componentWillUnmount() {
    this.props.removeSelectAfterUnmount();
  }
  componentDidUpdate() {
    const { previousCount } = this.state;
    if (this.props.inProgressVideosData.length !== previousCount) {
      this.setState({
        ...this.state,
        previousCount: this.props.inProgressVideosData.length,
      });
      setTimeout(() => {
        this.props.getProcessingVideos();
      }, 2500);
    }
  }

  handleTabChangeClick = (selectValue: string) => {
    this.setState({
      ...this.state,
      isSelected: selectValue ? true : false,
      isEditable: false,
      fileUploadScreen: !selectValue ? "inProgress-screen" : "upload-screen",
    });
    errorObject.largePdfFileError = "";
    errorObject.isUploadConsentFormError = "";
    this.props.getProcessingVideos();
  };

  handleVideoSelect = (data: any) => {
    this.setState({ ...this.state, isEditable: data.isSelected });

    this.props.getInprogressSelectedData(data);
  };

  handleDeletePopup = (
    evt: React.MouseEvent<HTMLAnchorElement>,
    videoId?: any
  ) => {
    this.setState({
      ...this.state,
      shouldDelete: !this.state.shouldDelete,
      deleteVideoId: videoId,
    });
    evt.stopPropagation();
  };

  handleDelete = () => {
    this.props.deleteInprogressItem(this.state.deleteVideoId);
    this.setState({ ...this.state, shouldDelete: !this.state.shouldDelete });
  };

  handleChange = (
    e: any,
    data: any,
    selectedOption?: any,
    selectedField?: string,
    selectType?: string
  ) => {
    if (selectedField === "procedureName") {
      const value = {
        ...data,
        primaryProcedureName: selectedOption.text,
        videoData: {
          ...data.videoData,
          primaryProcedureId: selectedOption.key,
        },
      };
      const editedField = {
        ...this.props.editedMetaDataFields,
        primaryProcedureId: selectedOption.key,
      };
      this.setState({ ...this.state, enableSave: true });
      this.props.editInprogressUploadingMetaData(value, editedField);
    } else if (selectedField === "procedureName2") {
      const value = {
        ...data,
        secondaryProcedureName: selectedOption.text,
        videoData: {
          ...data.videoData,
          secondaryProcedureId: selectedOption.key,
        },
      };
      const editedField = {
        ...this.props.editedMetaDataFields,
        secondaryProcedureId: selectedOption.key,
      };

      this.setState({ ...this.state, enableSave: true });
      this.props.editInprogressUploadingMetaData(value, editedField);
    } else if (selectedOption === "date") {
      const value = {
        ...data,
        videoData: {
          ...data.videoData,
          surgeryDate: e,
        },
      };
      this.setState({ ...this.state, enableSave: true });
      const editedField = {
        ...this.props.editedMetaDataFields,
        surgeryDate: e,
      };

      this.props.editInprogressUploadingMetaData(value, editedField);
    } else {
      const value = {
        ...data,
        videoData: {
          ...data.videoData,
          [e.target.name]: [e.target.value],
        },
      };
      this.setState({ ...this.state, enableSave: true });
      const editedField = {
        ...this.props.editedMetaDataFields,
        [e.target.name]: [e.target.value],
      };

      this.props.editInprogressUploadingMetaData(value, editedField);
    }
  };

  handleLoadMoreProcessingVideos = (currentPage: number) => {
    this.props.getProcessingVideos(currentPage);
  };

  handleSaveClick = async (data: any, id?: any) => {
    if (this.state.isSelected) {
      this.props.saveEditedMetaData(data);
    } else {
      const response = await this.props.saveProcessingData(data, id);
      if (response === 200) {
        const response2 = await this.props.getProcessingVideos(
          this.props.currentPage
        );
        response2 &&
          response2?.status === 200 &&
          this.handleProcessTabSelect(id);
      }
    }
    this.setState({ ...this.state, isEditable: false, enableSave: false });
  };

  manageFiles = (val: any, evt: any, data: any) => {
    this.setState({ ...this.state, enableSave: val });
    const editedField = {
      ...this.props.editedMetaDataFields,
      patientConcentForm: val ? evt.target.files[0] : null,
    };
    const value = {
      ...data,
      patientConsentForm: val ? evt.target.files[0] : null,
    };
    this.props.editInprogressUploadingMetaData(value, editedField);
  };

  handlePatientConsentFormFileChange = (evt: any, data: any) => {
    errorObject.isUploadConsentFormError = "";
    errorObject.largePdfFileError = "";
    const fileTypeSupported = ["image/png", "image/PNG", "application/pdf"];
    const fileObj = evt.target.files[0];
    const fileType = fileObj.type;
    const fileSize: any = fileObj.size
      ? (fileObj.size / (1024 * 1024)).toFixed(2)
      : 0;
    if (parseFloat(fileSize) > 5) {
      errorObject.largePdfFileError = "Please Upload File less than 5 Mb";
      setTimeout(() => {
        this.manageFiles(false, evt, data);
      }, 1500);
    }

    if (fileTypeSupported.includes(fileType)) {
      this.manageFiles(true, evt, data);
    } else {
      setTimeout(() => {
        this.manageFiles(false, evt, data);
      }, 1500);
      errorObject.isUploadConsentFormError = "File type not supported";
    }
    return;
  };

  handleProcessTabSelect = (id: number) => {
    this.props.setProcessingVideoSelect(id);
    this.setState({ ...this.state, isEditable: false, isSelected: false });
  };

  render() {
    const { inProgressVideosData } = this.props;
    const { isEditable, isSelected, shouldDelete } = this.state;
    const selectedVideo =
      this.props.inProgressVideosData && isSelected
        ? this.props.inProgressVideosData.filter(
            (video: any) => video.isSelected
          )
        : this.props.processingData &&
          this.props.processingData.count &&
          this.props.processingData.results.filter(
            (video: any) => video.isSelected
          );

    const items: IBreadcrumbItem[] = [
      {
        text: "Home",
        key: "Home",
        onClick: () => this.props.history("/"),
      },
      {
        text: "In Progress Videos",
        key: "f11",
        isCurrentItem: true,
      },
    ];

    return (
      <Stack
        verticalFill
        styles={containerStyles}
        tokens={{ padding: "1rem 3rem", childrenGap: 20 }}
      >
        <Breadcrumb
          items={items}
          maxDisplayedItems={2}
          ariaLabel="Breadcrumb with items rendered as buttons"
          overflowAriaLabel="More links"
        />
        <Stack
          horizontal
          styles={{
            root: {
              flex: 1,
              backgroundColor: "#121212",
            },
          }}
        >
          <Stack verticalFill styles={{ root: { flex: 4 } }}>
            <StackItem
              styles={{
                root: {
                  padding: "1.5rem",
                  borderBottom: "1px solid #000000",
                },
              }}
            >
              <Text
                variant="medium"
                styles={{
                  root: {
                    margin: "1rem",
                    paddingBottom: "0.6rem",
                    borderBottom: isSelected ? "2px solid #01B1FF" : "none",
                    cursor: "pointer",
                    opacity: isSelected ? "1" : "0.4",
                  },
                }}
                onClick={() => this.handleTabChangeClick("true")}
              >
                Uploading - ({inProgressVideosData.length})
              </Text>
              <Text
                variant="medium"
                styles={{
                  root: {
                    margin: "1rem",
                    paddingBottom: "0.6rem",
                    borderBottom: !isSelected ? "2px solid #01B1FF" : "none",
                    opacity: !isSelected ? "1" : "0.4",
                    cursor: "pointer",
                  },
                }}
                onClick={() => this.handleTabChangeClick("")}
              >
                Processing - (
                {this.props.processingData && this.props.processingData.count
                  ? this.props.processingData.count
                  : 0}
                )
              </Text>
            </StackItem>
            <Stack
              styles={{
                root: {
                  padding: "2rem 0",
                  height: "80vh",
                },
              }}
              horizontalAlign="center"
            >
              {isSelected ? (
                inProgressVideosData && inProgressVideosData.length ? (
                  <InprogressCard
                    inProgressVideosData={inProgressVideosData}
                    handleVideoSelect={this.handleVideoSelect}
                    handleDeletePopup={this.handleDeletePopup}
                    handleDelete={this.handleDelete}
                    shouldDelete={shouldDelete}
                  />
                ) : (
                  <Text
                    styles={{
                      root: {
                        color: ThemeColorPalette.uploadMsgTxt,
                        opacity: 0.1,
                        marginTop: "15rem",
                      },
                    }}
                  >
                    No Inprogress Data
                  </Text>
                )
              ) : this.props.processingData &&
                this.props.processingData.count ? (
                <ProcessingCard
                  processingData={this.props.processingData}
                  handleProcessTabSelect={this.handleProcessTabSelect}
                  handleLoadMoreProcessingVideos={
                    this.handleLoadMoreProcessingVideos
                  }
                  currentPage={this.props.currentPage}
                />
              ) : (
                <Text
                  styles={{
                    root: {
                      color: ThemeColorPalette.uploadMsgTxt,
                      opacity: 0.1,
                      marginTop: "15rem",
                    },
                  }}
                >
                  No Processing Data
                </Text>
              )}
            </Stack>
          </Stack>
          {isSelected ? (
            <ViewEditVideoData
              inProgressVideosData={selectedVideo[0]}
              isEditable={this.state.isEditable}
              handleChange={this.handleChange}
              handlePatientConsentFormFileChange={
                this.handlePatientConsentFormFileChange
              }
              proceduresData={this.props.proceduresData}
              userData={this.props.userData}
              enableSave={this.state.enableSave}
              handleSaveClick={this.handleSaveClick}
              handleEdit={() => {
                this.setState({
                  ...this.state,
                  isEditable: !isEditable,
                });
              }}
              userProfile={this.props.userProfile}
              isSelected={isSelected}
              screen={"inprogressUpdateScreen"}
              errorObject={errorObject}
              fileUploadScreen={this.state.fileUploadScreen}
            />
          ) : (
            <ViewEditVideoData
              inProgressVideosData={
                selectedVideo && selectedVideo.length && selectedVideo[0]
              }
              isEditable={this.state.isEditable}
              handleChange={this.handleChange}
              handlePatientConsentFormFileChange={
                this.handlePatientConsentFormFileChange
              }
              proceduresData={this.props.proceduresData}
              userData={this.props.userData}
              enableSave={this.state.enableSave}
              handleSaveClick={this.handleSaveClick}
              handleEdit={() => {
                this.setState({
                  ...this.state,
                  isEditable: !isEditable,
                });
              }}
              userProfile={this.props.userProfile}
              isSelected={isSelected}
              screen={"processing"}
              errorObject={errorObject}
              fileUploadScreen={this.state.fileUploadScreen}
            />
          )}
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    processingData: state.inProgressReducerData.processingData,
    inProgressVideosData: state.inProgressReducerData.inProgressVideosData,
    proceduresData: state.dashboardReducerData.procedureDetails,
    userData: state.dashboardReducerData.userDetails,
    userProfile: state.dashboardReducerData.loginUserProfile,
    editedMetaDataFields: state.inProgressReducerData.editedMetaDataFields,
    currentPage: state.inProgressReducerData.currentPage,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProcessingVideos: (currentPage: number) =>
      dispatch(getProcessingVideos(currentPage)),
    setProcessingVideoSelect: (videoId: number) =>
      dispatch(setProcessingVideoSelect(videoId)),

    getInprogressVideosAction: (data: any) => dispatch(setInprogressData(data)),
    getInprogressSelectedData: (data: any) =>
      dispatch(getInprogressSelectedData(data)),

    deleteInprogressItem: (videoId: any) =>
      dispatch(deleteInprogressItem(videoId)),
    editInprogressUploadingMetaData: (data: any, editedField: any) =>
      dispatch(editInprogressUploadingMetaData(data, editedField)),
    saveEditedMetaData: (data: any) => dispatch(saveEditedMetaData(data)),
    // saveEditedMetaData: () => dispatch(saveEditedMetaData()),

    saveProcessingData: (editedData: any, id: number) =>
      dispatch(saveProcessingData(editedData, id)),

    removeSelectAfterUnmount: () => dispatch(removeSelectAfterUnmount()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InProgress)
);
