import { Stack, Text } from "@fluentui/react";
import { ReactComponent as AddFacilityIcon } from "../../../../../../assets/Home/add_facility.svg";
import ToggleCard from "../../../Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../../rootReducer";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderWithMessage from "../../../../../../common/customLoader/LoaderWithMessage";

import { updateActionData } from "../../../../actions/homeAction";
import {
  increment,
  getDevices,
  getAllocationType,
} from "../../../../actions/homeAction";
import AddDeviceItem from "./AddDeviceItem";
import "./AddDevice.scss";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { rest } from "lodash";
import { apiEndPoint, doHttpCall } from "../../../../../../common/restApi";

const AddDevice = ({
  hospitalData,
  showCreateHospital,
}: {
  hospitalData: any;
  showCreateHospital: any;
}) => {
  const validationSchema = Yup.object().shape({
    devices: Yup.array()
      .of(
        Yup.object().shape({
          facilities: Yup.array().of(
            Yup.object()
              .shape(
                {
                  id: Yup.string()
                    .optional()
                    .nullable()
                    .when(
                      [
                        "room",
                        "allocation_type",
                        "allocation_start",
                        "allocation_end",
                      ],
                      {
                        is: (
                          room: any,
                          allocation_type: any,
                          allocation_start: any,
                          allocation_end: any
                        ) =>
                          room ||
                          allocation_type ||
                          allocation_start ||
                          allocation_end,
                        then: Yup.string().required("Device is required"),
                        otherwise: Yup.string().optional().nullable(),
                      }
                    ),
                  room: Yup.string().when(["id"], {
                    is: (id: any) => id,
                    then: Yup.string().required("Room address is required"),
                    otherwise: Yup.string().optional().nullable(),
                  }),
                  current_allocation: Yup.string().when(["id"], {
                    is: (id: any) => id,
                    then: Yup.string().required(
                      "Current allocation is required"
                    ),
                  }),
                  allocation_type: Yup.string().when(["id"], {
                    is: (id: any) => id,
                    then: Yup.string().required("Allocation type is required"),
                  }),
                  allocation_start: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === "" ? null : curr))
                    .when(["id"], {
                      is: (id: any) => id,
                      then: Yup.date()
                        .nullable()
                        .required("Allocation start is required"),
                    }),
                  allocation_end: Yup.date()
                    .nullable()
                    .transform((curr, orig) => (orig === "" ? null : curr))
                    .when(["id"], {
                      is: (id: any) => id,
                      then: Yup.date()
                        .nullable()
                        .required("Allocation end is required"),
                    }),
                },
                [
                  ["id", "room"],
                  ["room", "id"],
                  ["id", "allocation_type"],
                  ["id", "allocation_start"],
                  ["id", "allocation_end"],
                ]
              )
              .test({
                message: "Device already allocated in the given date range",
                test: async (value: any) => {
                  const obj1 = value;
                  const hospitalId = hospitalData.id
                    ? `&hospital=${hospitalData.id}`
                    : "";
                  if (
                    !obj1?.current_allocation ||
                    !obj1?.id ||
                    !obj1?.allocation_start ||
                    !obj1?.allocation_end ||
                    !obj1?.allocation_type
                  ) {
                    return true;
                  }
                  const payload = {
                    method: "GET",
                    url:
                      apiEndPoint +
                      `/devices/allocation/available?device_id=${
                        obj1?.id
                      }&allocation_start=${moment
                        .utc(obj1?.allocation_start)
                        .format("YYYY-MM-DD")}&allocation_end=${moment
                        .utc(obj1?.allocation_end)
                        .format("YYYY-MM-DD")}${hospitalId}`,
                  };
                  const response = await doHttpCall(payload);
                  if (response && response.status === 200) {
                    return true;
                  } else {
                    return false;
                  }
                },
              })
          ),
        })
      )
      .required("Must have fields")
      .min(1, "Minimum of 1 field"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  // console.log(formOptions, 'formOptions');

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm(formOptions);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDevices());
  }, []);
  const { fields, append, remove } = useFieldArray({
    name: "devices",
    control,
  });
  const navigate = useNavigate();
  const [selectedDevices, setSelectedDevices] = useState<number[]>([]);
  const deviceIndex = 0;

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessage;
  });

  const counts = useSelector((state: AppState) => {
    return state.homeReducerData.count;
  });

  const devices = useSelector((state: AppState) => {
    return state.homeReducerData.devices.data;
  });
  const allocationType = useSelector((state: AppState) => {
    return state.homeReducerData.allocationType.data;
  });

  const noOfFacilities = () => {
    return [...Array(hospitalData?.hospitals?.length - 1 || 0)];
  };

  const hospitalFacilityCount = () => {
    return [...Array(hospitalData?.hospitals?.length || 0)];
  };

  const formValues = getValues();
  const MainHospitalfacilities: Array<String> = [];

  hospitalFacilityCount().map((n: any, i: any) => {
    const facilities = formValues?.devices
      ? formValues?.devices[i]?.facilities
      : "";
    facilities &&
      facilities?.map((facility: any) => {
        MainHospitalfacilities.push(facility);
      });
  });
  const selectedSerialNumbers =
    MainHospitalfacilities &&
    MainHospitalfacilities?.map((facility: any, index: any) => ({
      value: facility.id,
    }));
  const selectedSerialNumberToArray =
    selectedSerialNumbers &&
    selectedSerialNumbers?.map((selectedSerialNumber: any) => {
      return selectedSerialNumber.value;
    });

  const allSerialNumber = devices?.results?.map((device: any) => ({
    value: device?.id,
    label: device?.serial_number,
  }));
  // console.log(allSerialNumber,'allSerialNumber');
  const facillityDevices: Array<object> = [];

  const getEditHospitalDevices = hospitalData?.hospitals?.map(
    (hospital: any) => {
      hospital?.devices?.map((device: any) => {
        const selectedVlaue = allSerialNumber?.filter(
          (item: any) => item.value == device?.id
        );
        !(selectedVlaue?.length > 0) &&
          facillityDevices?.push({
            value: device?.device_id ? device?.device_id : device?.id,
            label: device?.serial_number,
          });
      });
    }
  );
  const allSerialNumbers1 = allSerialNumber && [
    ...allSerialNumber,
    ...facillityDevices,
  ];
  const allSerialNumbers = allSerialNumbers1?.filter(
    (ele: any, ind: any) =>
      ind ===
      allSerialNumbers1?.findIndex((elem: any) => elem.value === ele.value)
  );

  // const allSerialNumbers = devices?.results?.map((device: any) => ({
  //   value: device?.id,
  //   label: device?.serial_number,
  // }));

  const serialNumbers = allSerialNumbers?.filter(
    (serialNumber: any) =>
      !selectedSerialNumberToArray.includes(serialNumber.value)
  );

  const onSubmit = (data: any) => {
    // debugger;
    data?.devices?.map((device: any, index: any) => {
      device?.facilities?.map((facility: any, index: any) => {
        const removeFacilies =
          facility?.room === "" ? device?.facilities?.splice(index, 1) : "";
        const ind = allSerialNumber.findIndex(
          (x: any) => x.value == facility.id
        );
        if (ind > -1) {
          facility.serial_number = allSerialNumber[ind].label;
          facility.device_id = parseInt(facility.id);
        }

        Object.assign(facility, {
          current_allocation:
            facility?.current_allocation === "true" ? true : false,
        });
      });
      if (index < hospitalData.hospitals.length)
        hospitalData.hospitals[index].devices = device?.facilities;
      // Object.assign(updateHospital, {
      //   address: hospital?.address + "--" + hospital?.address2,
      // });
    });
    // debugger;
    const updateHospital = { ...hospitalData, ...data };
    delete updateHospital?.devices;
    delete updateHospital?.indexes;
    delete updateHospital?.show_facility;

    // debugger;
    dispatch(increment());
    dispatch(updateActionData(updateHospital));
    // console.log("hospitalData", hospitalData);
  };
  // console.log(hospitalData, "hospitalData");
  const routeDevices = () => {
    navigate(`/management/devices`);
  };
  useEffect(() => {
    dispatch(getAllocationType());
  }, []);
  const updateSelectedDevices = (list: number[]) => {
    setSelectedDevices(list);
  };

  return (
    <Stack className="addDevices">
      {(hospitalData?.hospitals || !showCreateHospital) && (
        <>
          <Stack.Item>
            <Stack
              horizontal
              horizontalAlign="end"
              verticalAlign="center"
              className="addADevice"
            >
              <Stack.Item>
                <Text>If Devices are not available, Click here to </Text>
              </Stack.Item>
              <Stack.Item className="pointer addADeviceMain">
                <Stack horizontal verticalAlign="center" onClick={routeDevices}>
                  <Text> Add Device</Text>
                  <AddFacilityIcon />
                </Stack>
              </Stack.Item>
            </Stack>
            <form
              id="myForm"
              className={
                loaderWithMessage?.loadingState ? "point-events-none" : ""
              }
              onSubmit={handleSubmit(onSubmit)}
            >
              <ToggleCard
                heading={
                  hospitalData?.hospitals && hospitalData?.hospitals[0]?.name
                }
                index=""
                removeFormFields=""
              >
                <AddDeviceItem
                  getValues={getValues}
                  serialNumbers={allSerialNumbers}
                  register={register}
                  deviceIndex={deviceIndex}
                  watch={watch}
                  control={control}
                  devices={devices}
                  allocationType={allocationType}
                  errors={errors}
                  hospitalData={hospitalData}
                  showCreateHospital={showCreateHospital}
                  setValue={setValue}
                  selectedDevicesList={selectedDevices}
                  setSelectedDevices={updateSelectedDevices}
                ></AddDeviceItem>
              </ToggleCard>
              <>
                {noOfFacilities().map((n: any, i: any) => {
                  const facilityNumber = i + 1;
                  return (
                    <ToggleCard
                      key={i}
                      heading={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[facilityNumber]?.name
                      }
                      index=""
                      removeFormFields=""
                    >
                      <AddDeviceItem
                        getValues={getValues}
                        serialNumbers={allSerialNumbers}
                        key={i}
                        deviceIndex={facilityNumber}
                        watch={watch}
                        register={register}
                        control={control}
                        devices={devices}
                        allocationType={allocationType}
                        errors={errors}
                        hospitalData={hospitalData}
                        showCreateHospital={showCreateHospital}
                        setValue={setValue}
                        selectedDevicesList={selectedDevices}
                        setSelectedDevices={updateSelectedDevices}
                      ></AddDeviceItem>
                    </ToggleCard>
                  );
                })}
              </>
            </form>
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
        </>
      )}
    </Stack>
  );
};

export default AddDevice;
