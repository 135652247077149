import axios from "axios";
import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";
const permissionIssueMessage =
  "You do not have permission to perform this action.";
const invalidPageMessage = "Invalid page.";

export const getHospitals =
  (searchValue = "", currentPage = 1, sortBy = "") =>
  async (dispatch: any) => {
    // debugger;
    const search = searchValue !== "" ? `&search=${searchValue}` : "";
    const sortHospital = sortBy !== "" ? `&sort=${sortBy}` : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/hospitals?page=${currentPage}&page_size=12${search}${sortHospital}`,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_HOSPITALS));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_HOSPITALS,
          payload: { data: response.data, currentPage: currentPage },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_HOSPITALS, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_HOSPITALS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const deleteHospital = (hospital_id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/hospitals/${hospital_id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_HOSPITALS_COUNT));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_HOSPITALS_COUNT,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_HOSPITALS_COUNT,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_HOSPITALS_COUNT,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getHospitalsCount = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/analytics/hospitals/count",
  };
  try {
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_HOSPITALS_COUNT,
        payload: { data: response.data },
      });
    }
  } catch (response) {
    console.error(response);
  }
};

export const createHospital = (hospitalData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/hospitals",
    data: hospitalData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_HOSPITALS));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_HOSPITALS,
        payload: { data: hospitalData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_HOSPITALS, response.status)
      );
    }
    if (response && (response.status === 404 || response.status === 400)) {
      dispatch({
        type: allActions.ERROR_IN_CREATE_HOSPITALS,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_HOSPITALS, response.status)
      );
    }
    if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_CREATE_HOSPITALS,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_HOSPITALS, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_HOSPITALS,
          403
        )
      );
    }
    console.error(response);
  }
};

export const editHospital =
  (id: any, hospitalData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/hospitals/${id}`,
      data: hospitalData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_HOSPITALS));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_HOSPITALS,
          payload: { data: hospitalData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_HOSPITALS, response.status)
        );
      }
      if (response && (response.status === 404 || response.status === 400)) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_USER,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_USER,
            response.status
          )
        );
      }
      if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_USER,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_USER,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_HOSPITALS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateActionData = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: allActions.HOSPITALS_DATA,
    payload: { data: { ...payload } },
  });
};

export const increment = () => {
  return {
    type: allActions.INCREMENT,
  };
};

export const decrement = () => {
  return {
    type: allActions.DECREMENT,
  };
};

export const closeHomeErrorRsponse = () => {
  return {
    type: allActions.CLOSE_ERRORS,
  };
};

// export const resetCount = (payload: any) => async (dispatch: any) => {
//   dispatch({
//     type: allActions.RESET_COUNT,
//     payload: { data: { ...payload } },
//   });
// };

export const getDevices = (search?: any) => async (dispatch: any) => {
  const searchInput = search ? `&search=${search}` : "";
  const payload = {
    method: "GET",
    url:
      apiEndPoint +
      `/hospitals/devices-list?allocation=false&&page_size=1000${searchInput}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_DEVICES));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_DEVICES,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_DEVICES, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_DEVICES,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getAllocationType = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/hospitals/devices-allocationtype",
  };
  const response = await doHttpCall(payload);
  if (response && response.status === 200) {
    dispatch({
      type: allActions.GET_ALLOCATION_TYPE,
      payload: { data: response.data },
    });
  }
};

export const getHospitalValidations = () => {
  return {
    type: allActions.GET_HOSPITAL_VALIDATIONS,
  };
};

export const showSpinner = () => {
  return {
    type: allActions.SPINNER_STOP,
  };
};

export const getVideos =
  (
    deviceNonDevice: number,
    hospitalId = "",
    currentPage = 1,
    searchInput: any = ""
  ) =>
  async (dispatch: any) => {
    const searchWithHospitalId =
      hospitalId != "" ? `&hospital=${hospitalId}` : "";
    const search = searchInput !== "" ? `/search?search=${searchInput}&` : "?";
    let device = "";
    if (deviceNonDevice === 2) {
      device = `&is_uploaded_by_user=true`;
    } else if (deviceNonDevice === 1) {
      device = `&is_uploaded_by_user=false`;
    }
    const payload = {
      method: "GET",
      url: `${apiEndPoint}/videos${search}page=${currentPage}&page_size=12${searchWithHospitalId}${device}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_VIDEOS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VIDEOS,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_VIDEOS, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_VIDEOS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getUsers =
  (currentPage = 1, sortBy = "", searchInput = "", hospitalId: any = "") =>
  async (dispatch: any) => {
    const searchWithHospitalId =
      hospitalId != "" ? `&hospital=${hospitalId}` : "";
    const sortByUser = sortBy !== "" ? `&sort=${sortBy}` : "&sort=all";
    const search = searchInput !== "" ? `&search=${searchInput}` : "";
    const payload = {
      method: "GET",
      url: `${apiEndPoint}/user/hospitals?page=${currentPage}${searchWithHospitalId}&page_size=12${sortByUser}${search}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_USERS));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_USERS,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_USERS, response.status)
        );
      } else if (response && response.status === 404) {
        dispatch(
          hideLoaderWithMessage(
            invalidPageMessage,
            allActions.GET_USERS,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_USERS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const removeErrorUSR = (obj: any) => async (dispatch: any) => {
  dispatch({
    type: allActions.ERROR_USR,
    payload: obj,
  });
};
export const createUser = (userData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/hospitals/user",
    data: userData,
  };
  try {
    // const response = {
    //   status : 200
    // }
    dispatch(showLoaderWithMessage("", allActions.CREATE_USERS));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_USERS,
        payload: { data: userData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_USERS, response.status)
      );
    }
    if (response && (response.status === 404 || response.status === 400)) {
      const obj: any = {};
      response?.data.forEach((element: any) => {
        obj[element.field] = element.details;
      });
      dispatch({
        type: allActions.ERROR_USR,
        payload: obj,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.ERROR_USR, response.status)
      );
    }
    if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_CREATE_HOSPITALS,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_CREATE_HOSPITALS,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_USERS,
          403
        )
      );
    }
    console.error(response);
  }
};

export const createUserWithTypes = (userData: any) => async (dispatch: any) => {
  // debugger;
  let activAdmin;
  if (userData?.assigned_role === "ActivAdmin") {
    activAdmin = "activadmin";
  } else if (userData?.assigned_role === "Developer") {
    activAdmin = "developer";
  } else if (userData?.assigned_role === "Dual") {
    activAdmin = "dual";
  }
  const payload = {
    method: "POST",
    url: apiEndPoint + `/user/${activAdmin}`,
    data: userData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_USERS_WITH_TYPES));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_USERS_WITH_TYPES,
        payload: { data: userData },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.CREATE_USERS_WITH_TYPES,
          response.status
        )
      );
    }
    if (response && (response.status === 404 || response.status === 400)) {
      // dispatch({
      //   type: allActions.ERROR_IN_CREATE_USER,
      //   payload: response,
      // });
      const obj: any = {};
      response?.data.forEach((element: any) => {
        obj[element.field] = element.details;
      });
      dispatch({
        type: allActions.ERROR_USR,
        payload: obj,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.ERROR_USR, response.status)
      );
    }
    if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_CREATE_USER,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_CREATE_USER,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_USERS_WITH_TYPES,
          403
        )
      );
    }
    console.error(response);
  }
};
export const clearHospitalCountData = () => async (dispatch: any) => {
  dispatch({
    type: allActions.REFRESH_HOSPITALS_WITH_COUNT,
    payload: [],
  });
};

export const getHospitalsWithCount =
  (deviceType: number, search?: string, count?: any, nextUrl?: any) =>
  async (dispatch: any) => {
    const deviceTypePayload: boolean = deviceType === 2 ? true : false;
    const searchText = search ? `&search=${search}` : "";
    const pageCount = count ? `&page_size=${count}` : `&page_size=100`;
    const payload = {
      method: "GET",
      url: nextUrl
        ? nextUrl
        : apiEndPoint +
          `/hospitals/videos/count?user=${deviceTypePayload}${searchText}${pageCount}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_HOSPITALS_WITH_COUNT));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_HOSPITALS_WITH_COUNT,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_HOSPITALS_WITH_COUNT,
            response.status
          )
        );
      }
    } catch (response) {
      console.error(response);
    }
  };
export const getLocationWithZipcode =
  (zipcode: any) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/hospitals/location/${zipcode}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_LOCATION_WITH_ZIPCODE));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_LOCATION_WITH_ZIPCODE,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOCATION_WITH_ZIPCODE,
            response.status
          )
        );
        return response;
      }
      if (response && (response.status === 404 || response.status === 400)) {
        dispatch({
          type: allActions.ERROR_LOCATION_WITH_ZIPCODE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOCATION_WITH_ZIPCODE,
            response.status
          )
        );
        return response;
      }
      if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_LOCATION_WITH_ZIPCODE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_LOCATION_WITH_ZIPCODE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_LOCATION_WITH_ZIPCODE,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const validateForm =
  (inputValue: any, fieldName: any) => async (dispatch: any) => {
    let checkFieldName;
    switch (fieldName) {
      case "mail":
        checkFieldName = `/user/validate?mail=${inputValue}`;
        break;
      case "phone_number":
        checkFieldName = `/user/validate?phone_number=${inputValue}`;
        break;
      case "zipcode":
        checkFieldName = `/hospital?zipcode=${inputValue}`;
        break;
      case "name":
        checkFieldName = `/hospital?name=${inputValue}`;
        break;
      case "unique_identifier":
        checkFieldName = `/hospital?unique_identifier=${inputValue}`;
        break;
      default:
        // eslint-disable-next-line no-restricted-syntax
        console.log(`Sorry, not a valid field name`);
    }
    const payload = {
      method: "GET",
      url: `${apiEndPoint}${checkFieldName}`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.VALIDATE_FORM,
        payload: { data: response.data, field: { [fieldName]: false } },
      });
    } else if (response && response.status === 404) {
      dispatch({
        type: allActions.VALIDATE_FORM,
        payload: { field: { [fieldName]: true } },
      });
    }
  };

export const validateFormGet = () => {
  return {
    type: allActions.VALIDATE_FORM_GET,
  };
};

export const getHospitalsById = (hospitalId: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: `${apiEndPoint}/hospitals/${hospitalId}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_HOSPITAL_BY_ID));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_HOSPITAL_BY_ID,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_HOSPITAL_BY_ID,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_HOSPITAL_BY_ID,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getUserById = (userId: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: `${apiEndPoint}/user/surgeon/${userId}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_USER_BY_ID));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_USER_BY_ID,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_USER_BY_ID, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_USER_BY_ID,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getUserTypeById =
  (userType: any, userId: any) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: `${apiEndPoint}/user/${userType}/${userId}/details`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_USER_BY_ID));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_USER_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_USER_BY_ID, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_USER_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editUser = (id: any, userData: any) => async (dispatch: any) => {
  const payload = {
    method: "PATCH",
    url: apiEndPoint + `/user/hospitals/${id}`,
    data: userData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.EDIT_USERS));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.EDIT_USERS,
        payload: { data: userData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.EDIT_USERS, response.status)
      );
    }
    if (response && (response.status === 404 || response.status === 400)) {
      dispatch({
        type: allActions.ERROR_IN_CREATE_HOSPITALS,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_CREATE_HOSPITALS,
          response.status
        )
      );
    }
    if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_CREATE_HOSPITALS,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_CREATE_HOSPITALS,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.EDIT_USERS,
          403
        )
      );
    }
    console.error(response);
  }
};

export const editUserWithType =
  (userType: any, userId: any, userData: any) => async (dispatch: any) => {
    if (userData.facility === "") {
      delete userData.facility;
    }
    const payload = {
      method: "PATCH",
      url: `${apiEndPoint}/user/${userType}/${userId}/details`,
      data: userData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_USERS));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        const userProfileId = localStorage.getItem("userProfileId");
        if (userId === userProfileId) {
          if (userData?.permissions.length > 1) {
            localStorage.setItem("switch_account", JSON.stringify(true));
          } else {
            localStorage.setItem("switch_account", JSON.stringify(false));
          }
        }
        dispatch({
          type: allActions.EDIT_USERS,
          payload: { data: userData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_USERS, response.status)
        );
      }
      if (response && (response.status === 404 || response.status === 400)) {
        const obj: any = {};
        response?.data.forEach((element: any) => {
          obj[element.field] = element.details;
        });
        dispatch({
          type: allActions.ERROR_USR,
          payload: obj,
        });
        dispatch(
          hideLoaderWithMessage("", allActions.ERROR_USR, response.status)
        );
      }
      if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_USER,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_USER,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_USERS,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const closeHospitalModal = () => {
  return {
    type: allActions.CLOSE_HOSPITAL_MODAL,
  };
};

export const closeUserModal = () => {
  return {
    type: allActions.CLOSE_USER_MODAL,
  };
};

export const closeDeleteHospital = () => {
  return {
    type: allActions.CLOSE_DELETE_HOSPITAL,
  };
};

export const showCreateHospital = () => {
  return {
    type: allActions.SHOW_CREATE_HOSPITAL,
  };
};

export const showCreateUser = () => {
  return {
    type: allActions.SHOW_CREATE_USER,
  };
};
export const stopCreateUser = () => {
  return {
    type: allActions.STOP_CREATE_USER,
  };
};

export const clearHospitalData = () => {
  return {
    type: allActions.CLEAR_HOSPITAL_DATA,
  };
};

export const clearLogoutAdminAlert = () => {
  return {
    type: "LOGOUT_ACTIV_ADMIN_ALERT",
  };
};

export const createVideoProcessing =
  (videoData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/bulk/metadata",
      data: videoData,
    };
    // debugger;
    try {
      dispatch(clearLogoutAdminAlert());
      dispatch(showLoaderWithMessage("", allActions.CREATE_VIDEO_PROCESSING));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(clearLogoutAdminAlert());
        dispatch({
          type: allActions.CREATE_VIDEO_PROCESSING,
          payload: { data: videoData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_VIDEO_PROCESSING,
            response.status
          )
        );
      }
      if (response && (response.status === 404 || response.status === 400)) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_HOSPITALS,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_HOSPITALS,
            response.status
          )
        );
      }
      if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_HOSPITALS,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_HOSPITALS,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_VIDEO_PROCESSING,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const createLogProcessing =
  (videoData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/devices/logs/metadata",
    };
    // debugger;
    try {
      dispatch(clearLogoutAdminAlert());
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        const tempFile = URL.createObjectURL(
          new Blob([JSON.stringify(videoData)], { type: "application/json" })
        );
        const config = {
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": "multipart/form-data",
          },
        };
        // const formData = new FormData();
        // formData.append("device_id", videoData?.device_id);
        // formData.append("path", videoData?.path);
        // formData.append("primary_surgeon_name", videoData?.primary_surgeon_name);
        // formData.append("primary_surgeon_email", videoData?.primary_surgeon_email);
        // formData.append("primary_procedure", videoData?.primary_procedure);
        // formData.append("secondary_surgeon_name", videoData?.secondary_surgeon_name);
        // formData.append("secondary_surgeon_email", videoData?.secondary_surgeon_email);
        // formData.append("secondary_procedure", videoData?.secondary_procedure);
        // formData.append("procedure_date", videoData?.procedure_date);
        // formData.append("procedure_start_time", videoData?.procedure_start_time);
        const responseUrl = await axios.put(
          response?.data?.url,
          videoData,
          config
        );
        // debugger;
        if (responseUrl?.status === 201) {
          const payloadResponseUrl = {
            method: "PATCH",
            url:
              apiEndPoint +
              `/devices/logs/metadata/${response?.data?.correlation_id}`,
            data: { status: "UPLOADED" },
          };

          const responseStatus = await doHttpCall(payloadResponseUrl);
          if (
            responseStatus &&
            (responseStatus.status === 200 ||
              responseStatus.status === 201 ||
              responseStatus.status === 204)
          ) {
            dispatch({
              type: allActions.CREATE_LOG_PROCESSING,
              payload: { data: videoData },
            });

            dispatch(clearLogoutAdminAlert());
          }
          if (
            responseStatus &&
            (responseStatus.status === 404 || responseStatus.status === 400)
          ) {
            dispatch({
              type: allActions.ERROR_IN_CREATE_HOSPITALS,
              payload: responseStatus,
            });
          }
          if (responseStatus && responseStatus.status === 500) {
            dispatch({
              type: allActions.ERROR_IN_CREATE_HOSPITALS,
              payload: response,
            });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

export const userDelete =
  (id: any, user_type: any) => async (dispatch: any) => {
    let activAdmin;
    if (user_type === "ActivAdmin") {
      activAdmin = "";
    } else if (user_type === "Developer") {
      activAdmin = "";
    } else if (user_type === "HospitalAdmin") {
      activAdmin = "";
    } else if (user_type === "Dual") {
      activAdmin = "";
    } else {
      activAdmin = user_type;
    }
    const userType = activAdmin ? `/${activAdmin}` : "";
    const payload = {
      method: "DELETE",
      url: apiEndPoint + `/user${userType}/${id}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_USER));
      const response = await doHttpCall(payload);
      if (
        response &&
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204)
      ) {
        dispatch({
          type: allActions.DELETE_USER,
          payload: { data: id },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.DELETE_USER, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_HOSPITALS,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_HOSPITALS,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_CREATE_HOSPITALS,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_CREATE_HOSPITALS,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_USER,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const videoDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/videos/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_VIDEO));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_VIDEO,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.DELETE_VIDEO, response.status)
      );
      // } else if (
      //   response &&
      //   (response.status === 400 || response.status === 404)
      // ) {
      //   dispatch({
      //     type: allActions.ERROR_IN_CREATE_HOSPITALS,
      //     payload: { data: "Bad Request" },
      //   });
      // } else if (response && response.status === 500) {
      //   dispatch({
      //     type: allActions.ERROR_IN_CREATE_HOSPITALS,
      //     payload: { data: "Internal Server Error" },
      //   });
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_VIDEO,
          403
        )
      );
    }
    console.error(response);
  }
};

export const hospitalDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/hospitals/${id}`,
  };
  // debugger;
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_HOSPIATL));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_HOSPIATL,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.DELETE_HOSPIATL, response.status)
      );
      dispatch(getHospitalsCount());
      dispatch(getHospitalsWithCount(2));
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_HOSPIATL,
          403
        )
      );
    }
    console.error(response);
  }
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
