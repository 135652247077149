import {
  Image,
  IStackItemStyles,
  IStackProps,
  ITextStyles,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../theme";
import doctorImg from "../../assets/image.png";
import icon1 from "../../assets/icon1.svg";
import icon2 from "../../assets/icon2.svg";
import icon3 from "../../assets/icon3.svg";
import activSurgicalLogo from "../../assets/activesurgical_logo.svg";

export interface HomeLeftScreenProps {}

const stackItemLeftStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.pureBlack,
    color: ThemeColorPalette.pureWhite,
    flex: 2,
  },
};

const leftColumnProps: Partial<IStackProps> = {
  tokens: { padding: "0", maxHeight: "100%" },
};

const leftFooterItems: Partial<IStackProps> = {
  tokens: {
    childrenGap: "10",
    maxWidth: "90%",
    padding: "0 0 0 10%",
  },
};

const footerItemsTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    fontFamily: fonts.DINPro_Bold,
  },
};

const gradientTextStyles: Partial<ITextStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Bold,
  },
};

const HomeLeftScreen: React.SFC<HomeLeftScreenProps> = () => {
  return (
    <StackItem styles={stackItemLeftStyles}>
      <Stack
        className="leftPanel"
        verticalFill
        {...leftColumnProps}
        verticalAlign="start"
      >
        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
          <StackItem align="start" tokens={{ padding: "0 0 0 3rem " }}>
            <Image src={activSurgicalLogo} alt="Activ Edge logo" />
          </StackItem>
        </Stack>
        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
          <StackItem align="center">
            <Image
              src={doctorImg}
              alt="Doctor logo"
              height={"22rem"}
              width={"19rem"}
            />
          </StackItem>
        </Stack>
        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
          <StackItem align="center" tokens={{ padding: "1rem 0 0" }}>
            <Text
              className="gradientTextStyles"
              styles={gradientTextStyles}
              variant="xxLarge"
            >
              The platform that powers
            </Text>
          </StackItem>
          <StackItem align="center" tokens={{ padding: "0 0 2rem" }}>
            <Text
              className="gradientTextStyles"
              styles={gradientTextStyles}
              variant="xxLarge"
            >
              surgical innovation.
            </Text>
          </StackItem>
        </Stack>
        <Stack verticalFill verticalAlign="center" horizontalAlign="center">
          <StackItem align="center">
            <Stack horizontal {...leftFooterItems}>
              <StackItem>
                <Image src={icon1} alt="Doctor logo" />
              </StackItem>
              <StackItem tokens={{ padding: "0.2rem 0 0 0" }}>
                <Text styles={footerItemsTextStyles} variant="medium">
                  Advanced Computer Vision
                </Text>
              </StackItem>
              <StackItem>
                <Image src={icon2} alt="Doctor logo" />
              </StackItem>
              <StackItem tokens={{ padding: "0.2rem 0 0 0" }}>
                <Text styles={footerItemsTextStyles} variant="medium">
                  Autonomous Robotics
                </Text>
              </StackItem>
              <StackItem>
                <Image src={icon3} alt="Doctor logo" />
              </StackItem>
              <StackItem tokens={{ padding: "0.2rem 0 0 0" }}>
                <Text styles={footerItemsTextStyles} variant="medium">
                  Artificial Intelligence
                </Text>
              </StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </Stack>
    </StackItem>
  );
};

export default HomeLeftScreen;
