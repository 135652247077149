import * as allActions from "./actionConstant";
import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import { del, get, keys, set } from "idb-keyval";
import {
  customStore,
  FileUploadWorker,
  setWebWorker,
  terminateWebWorker,
  fileStore,
} from "../../../MainWrapper/MainWrapper";

export const getProcessingVideos =
  (currentPage: number) => async (dispatch: any) => {
    if (localStorage.getItem("role") === "Surgeon") {
      try {
        const payload = {
          method: "GET",
          url: currentPage
            ? currentPage
            : apiEndPoint + "/videos/inprocess?page=" + 1 + "&page_size=5",
        };
        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.GET_PROCESSING_VIDEOS,
            payload: { data: response.data, currentPage: response.data.next },
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

export const setProcessingVideoSelect = (id: number) => (dispatch: any) => {
  dispatch({
    type: allActions.SET_PROCESSING_VIDEO_SELECT,
    payload: id,
  });
};

export const setInprogressData = (data: any) => async (dispatch: any) => {
  try {
    if (data.videoData.secondaryProcedureId === 123) {
      delete data.secondaryProcedureName;
      delete data.videoData.secondaryProcedureId;
    }
    const postData = JSON.stringify(data.videoData);
    const formData = new FormData();
    formData.append("data", postData);
    if (data.patientConsentForm) {
      formData.append("patientConcentForm", data.patientConsentForm);
    }

    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/manual/metadata",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        // authorization: localStorage.getItem("token"),
      },
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      data.uploadUrl = response.data.mainVideos.videosList[0].url;
      data.correlationId = response.data.correlationId;
      data.isSelected = false;
      dispatch({
        type: allActions.SET_INPROGRESS_VIDEOS,
        payload: data,
      });
    }
    return response.status;
  } catch (e) {
    console.error(e);
  }
};

export const uploadFile = (data: any) => async (dispatch: any) => {
  setWebWorker();
  const fileData: any = { file: data.file };
  delete data.file;

  set(data.id, data, customStore).then(() => {});

  set(data.id, fileData, fileStore).then(() =>
    FileUploadWorker.postMessage({ type: "start", id: data.id })
  );

  return true;
};

export const updateVideoProgress = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.FILE_UPLOADING_DETAILS,
    payload: data,
  });
};

export const setInprogressVideosListAfterRefresh =
  (userProfileId: any) => (dispatch: any) => {
    FileUploadWorker &&
      FileUploadWorker.postMessage &&
      FileUploadWorker.postMessage({
        type: "resume",
        userProfile: userProfileId,
      });
    keys(customStore).then((keys: any) => {
      keys.forEach((key: any) => {
        get(key, customStore).then((value: any) => {
          if (
            typeof value === "object" &&
            value.videoData &&
            value.videoData.uploaderId === userProfileId
          ) {
            dispatch({
              type: allActions.SET_INPROGRESS_VIDEOS_LIST_AFTER_REFRESH,
              payload: value,
            });
          }
        });
      });
    });
  };

export const getInprogressSelectedData = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.GET_INPROGRESS_SELECTED_DATA,
    payload: data,
  });
};

export const deleteInprogressItem =
  (videoId: any) => (dispatch: any, getState: any) => {
    const userProfile = getState().dashboardReducerData.loginUserProfile;
    terminateWebWorker();
    del(videoId, customStore).then(() => {});
    del(videoId, fileStore).then(() => {
      setWebWorker(userProfile.id);
    });

    dispatch({
      type: allActions.DELETE_INPROGRESS_ITEM,
      payload: videoId,
    });
  };

export const editInprogressUploadingMetaData =
  (data: any, editedFields: any) => (dispatch: any) => {
    dispatch({
      type: allActions.EDIT_INPROGRESS_UPLOADING_META_DATA,
      payload: {
        data: data,
        editedFields: editedFields,
      },
    });
  };

export const saveEditedMetaData =
  (data: any) => async (dispatch: any, getState: any) => {
    const obj = getState().inProgressReducerData.editedMetaDataFields;
    const formData: any = new FormData();

    Object.keys(obj).forEach((val: any) => {
      val !== "note" && formData.append(val, obj[val]);
    });
    try {
      const payload = {
        method: "PATCH",
        url: apiEndPoint + "/videos/" + data.correlationId + "/metadata",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          // authorization: localStorage.getItem("token"),
        },
      };
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        data.isSelected = false;
        set(data.id, data, customStore);
      }
      dispatch({ type: allActions.CLEAR_EDITED_METADATA_AFTER_SAVE });
    } catch (e) {
      console.error(e);
    }
  };

export const saveProcessingData =
  (editedData: any, id: number) => async (dispatch: any) => {
    const formData: any = new FormData();

    Object.keys(editedData).forEach((val: any) => {
      val !== "note" && formData.append(val, editedData[val]);
    });

    try {
      const payload = {
        method: "PATCH",
        url: apiEndPoint + "/videos/" + id + "/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          // authorization: localStorage.getItem("token"),
        },
      };
      const response = await doHttpCall(payload);
      return response.status;
    } catch (e) {
      console.error(e);
    }
  };

export const removeSelectAfterUnmount =
  () => (dispatch: any, getState: any) => {
    const inprogressData =
      getState().inProgressReducerData.inProgressVideosData;
    const processingData = getState().inProgressReducerData.processingData;

    const updatedInprogressData = inprogressData.map((item: any) => {
      return {
        ...item,
        isSelected: false,
      };
    });

    const updatedProcessingData = {
      ...processingData,
      results:
        processingData.count &&
        processingData.results.map((item: any) => {
          return {
            ...item,
            isSelected: false,
          };
        }),
    };

    dispatch({
      type: allActions.UNSELECT_SELECTED_INPROGRESS_DATA,
      payload: {
        updatedInprogressData: updatedInprogressData,
        updatedProcessingData: updatedProcessingData,
      },
    });
  };

export const clearInprogressDataOnLogout = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_INPROGRESS_DATA_ON_LOGOUT,
  });
};
