import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FontIcon,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { fonts, ThemeColorPalette } from "../../theme";
import {
  currentDate,
  getDateWithSlashInBetween,
  getFormattedDate,
} from "../../common/util";
import Loader from "../../ActiveAdminModule/Logs/components/Common/Loader";
import { useSelector } from "react-redux";
import { AppState } from "../../rootReducer";
import CheckPermission from "../../common/permissions/rolePermissions";
import * as constants from "../../Layout/constants/constants";
import * as allActions from "../../VideoPlayer/actions/actionConstants";

interface InprogressVideoDetailsProps {
  handleEdit?: any;
  data: any;
  userProfile: any;
  procedureName?: string;
  primarySurgeonName?: string;
  secondaryProcedureName?: string;
  userData: any;
  screen?: string;
  loaderFromViewBtn?: boolean;
  disabledCreate?: boolean;
  spinnerFunc?: any;
}

const InprogressVideoDetails: React.SFC<InprogressVideoDetailsProps> = ({
  handleEdit,
  data,
  userProfile,
  procedureName,
  primarySurgeonName,
  secondaryProcedureName,
  userData,
  screen,
  loaderFromViewBtn = false,
  disabledCreate = false,
  spinnerFunc,
}) => {
  const date =
    data && !data.videoData
      ? data.surgery_date
        ? new Date(data.surgery_date)
        : null
      : data.videoData.surgeryDate
      ? new Date(data.videoData.surgeryDate)
      : null;

  const loadingState = useSelector((state: AppState) => {
    return state.videoPlayerReducerData.showSpinner;
  });
  const [loading, isLoading] = useState(true);

  const dipatch = useDispatch();
  useEffect(() => {
    if (userProfile?.role_name === "HospitalAdmin") spinnerFunc(false);
    if (!loaderFromViewBtn) isLoading(false);
    setTimeout(() => {
      isLoading(false);
    }, 300);
  }, []);
  useEffect(() => {
    dipatch({ type: allActions.HIDE_COMMENT_LOADER });
  }, []);
  let uploadedUser = "";
  userData.results &&
    userData.results.forEach((val: any) => {
      if (data && data.uploaded_by && data.uploaded_by === val.id)
        uploadedUser = val.displayName;
    });

  const PatientConcentFile: any = data.patient_concent_forms
    ? data.patient_concent_forms
    : data.patientConsentForm
    ? data.patientConsentForm.name
    : data.patient_concent_form
    ? data.patient_concent_form
    : "File not attached";

  return (
    <Stack styles={{ root: { paddingTop: "2rem", paddingLeft: "2rem" } }}>
      {loading ? <Loader></Loader> : null}
      {/* {loadingState ? <Loader></Loader> : null} */}
      <StackItem styles={{ root: { paddingBottom: "3rem" } }}>
        <Text
          variant="xLarge"
          styles={{
            root: {
              fontFamily: fonts.DINPro_Regular,
              color: ThemeColorPalette.dashboardHeadingTxt,
              opacity: 0.8,
            },
          }}
        >
          Video Details
        </Text>
      </StackItem>
      <Stack tokens={{ childrenGap: 30 }}>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Primary Procedure Name
            </Text>
            <Text
              variant="medium"
              className="textEllipses_procedures"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
              title={data.procedure_name}
              block
            >
              {data && data.procedure_name
                ? data.procedure_name
                : procedureName
                ? procedureName
                  ? procedureName
                  : "N/A"
                : data.primaryProcedureName
                ? data.primaryProcedureName
                : "N/A"}
            </Text>
          </Stack>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Primary Surgeon Name
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {primarySurgeonName
                ? primarySurgeonName
                  ? primarySurgeonName
                  : "N/A"
                : data.primarySurgeonName
                ? data.primarySurgeonName
                : "N/A"}
            </Text>
          </Stack>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Secondary Procedure Name
            </Text>
            <Text
              className="textEllipses_procedures"
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
              block
              title={data.secondary_procedure_name}
            >
              {secondaryProcedureName && secondaryProcedureName?.length
                ? secondaryProcedureName
                  ? secondaryProcedureName
                  : "N/A"
                : data.secondary_procedure_name
                ? data.secondary_procedure_name
                : data.secondaryProcedureName
                ? data.secondaryProcedureName
                : "N/A"}
            </Text>
          </Stack>
          <Stack tokens={{ childrenGap: 5 }}>
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Date of Procedure
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {date ? getFormattedDate(date) : "N/A"}
            </Text>
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 5 }}>
          <Text
            variant="smallPlus"
            styles={{
              root: {
                color: ThemeColorPalette.changePasswordTxt,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            Notes
          </Text>
          <Text
            variant="medium"
            styles={{
              root: {
                color: ThemeColorPalette.changePasswordLabelTxt,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            {data && data.notes
              ? data.notes
              : (data.videoData && data.videoData.notes) || "N/A"}
          </Text>
        </Stack>
        <Stack tokens={{ childrenGap: 5 }}>
          <Text
            variant="smallPlus"
            styles={{
              root: {
                color: ThemeColorPalette.changePasswordTxt,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            Patient Consent Form
          </Text>
          <Text
            variant="medium"
            styles={{
              root: {
                color: ThemeColorPalette.themePrimary,
                fontFamily: fonts.HelveticaNeue_Medium,
              },
            }}
          >
            <FontIcon
              iconName="attach"
              style={{ color: ThemeColorPalette.themePrimary }}
            />
            {PatientConcentFile && Object.keys(PatientConcentFile).length
              ? PatientConcentFile
              : "File not attached"}
          </Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 50 }}>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Uploaded by
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {data.videoData || screen === "processing"
                ? userProfile && userProfile.displayName
                : data.uploaded_by
                ? uploadedUser
                  ? uploadedUser
                  : data.uploaded_by
                : "N/A"}
            </Text>
          </Stack>
          <Stack
            tokens={{ childrenGap: 5 }}
            styles={{ root: { width: "18rem" } }}
          >
            <Text
              variant="smallPlus"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              Uploaded Date
            </Text>
            <Text
              variant="medium"
              styles={{
                root: {
                  color: ThemeColorPalette.changePasswordLabelTxt,
                  fontFamily: fonts.HelveticaNeue_Medium,
                },
              }}
            >
              {data.videoData
                ? currentDate()
                : getFormattedDate(data.uploaded_date)
                ? getFormattedDate(data.uploaded_date)
                : "N/A"}
            </Text>
          </Stack>
        </Stack>
        {(data.created_by === userProfile.userId ||
          data.uploaded_by === userProfile.userId ||
          screen === "inprogressUpdateScreen" ||
          screen === "processing" ||
          // userProfile.role_name === "HospitalAdmin" ||
          (userProfile?.role_name === "Surgeon" &&
            !data.is_uploaded_by_user)) && (
          <CheckPermission {...constants.SCOPES.VIDEO_EDIT_ALL_MODULES}>
            <PrimaryButton
              text="Edit Details"
              styles={{
                root: {
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  fontFamily: fonts.HelveticaNeue_Bold,
                  height: "2rem",
                  width: "7rem",
                },
              }}
              onClick={() => handleEdit()}
            />
          </CheckPermission>
        )}
      </Stack>
    </Stack>
  );
};

export default InprogressVideoDetails;
