import {
  ERROR_IN_BULK_ONBOARD,
  GET_ALL_VIDEOS_BY_PRIMARY_SURGEON,
  GET_SURGEONS_FOR_DROPDOWN,
  GET_VIDEOS_BY_ID,
  GET_ALL_HOSPITAL_ADMINS,
  GET_HOSPITAL_ADMIN_DETAILS_BY_USERID,
} from "./../actions/actionConstants";
import {
  GET_HOSPITAL_INFO,
  GET_ALL_SURGEONS,
  GET_ALL_VIDEOS,
  ONBOARD_FACILITIES,
  ONBOARD_SPECIALITY,
  ONBOARD_PERMISSIONS,
  CREATE_SURGEON,
  SURGEON_SPINNER,
  DELETE_SURGEON,
  DELETE_VIDEO,
  BULK_ONBOARD_SURGEON,
  CREATE_SURGEON_ERROR,
  GET_SURGEON_BY_ID,
  DOWNLOAD_TEMPLATE,
  LOADER,
  STOPLOADER,
  CREATE_SURGEON_CLEAR_ERRORS,
} from "../actions/actionConstants";
import { CLEAR_ALL_SURGEONS } from "../../Activity/actions/actionConstants";

const initialState: any = {
  hospitalInfo: null,
  allSurgeons: [],
  allVideos: {
    results: [],
  },
  allVideosForPrimarySurgeons: {
    results: [],
  },
  onboardFacilities: [],
  onboardSpeciality: [],
  onboardPermissions: [],
  surgeonData: {},
  createSurgeonError: null,
  createSurgeonErroFieldName: null,
  closeRoles: false,
  deleteSurgeon: null,
  deleteVideo: null,
  bulkSurgeonOnboard: null,
  surgeonById: null,
  videosById: [],
  templateData: null,
  loadingState: false,
  bulkSurgeonOnboardError: null,
  activitySurgeons: null,
  loaderWithMessage: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageSurgeons: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  loaderWithMessageVideos: {
    loadingState: false,
    message: "",
    calledFrom: "",
    statusCode: 0,
  },
  allHospitalAdmins: [],
  hospitalAdminById: [],
};

export const hospitalHomeReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_HOSPITAL_INFO:
      return {
        ...state,
        hospitalInfo: action.payload?.data,
      };
    case GET_ALL_SURGEONS:
      return {
        ...state,
        allSurgeons: action.payload?.data,
        surgeonPage: action.payload.currentPage,
      };
    case GET_SURGEONS_FOR_DROPDOWN:
      const currentList = state.activitySurgeons?.results || [];
      const previousData = action.payload.isNext ? currentList || [] : [];
      const newData = action.payload;
      newData.data.results = previousData.concat(newData.data.results);
      return {
        ...state,
        activitySurgeons: newData.data,
      };
    case GET_ALL_VIDEOS:
      return {
        ...state,
        allVideos: action.payload?.data,
      };
    case GET_ALL_VIDEOS_BY_PRIMARY_SURGEON:
      return {
        ...state,
        allVideosForPrimarySurgeons: action.payload?.data,
      };
    case ONBOARD_FACILITIES:
      const currentFacilityTypesList = state.onboardFacilities?.results || [];
      const previousFacilityTypeData = action.payload.isNext
        ? currentFacilityTypesList || []
        : [];
      const updatedFacilityType = action.payload.data;
      updatedFacilityType.results = previousFacilityTypeData.concat(
        updatedFacilityType.results
      );
      return {
        ...state,
        onboardFacilities: updatedFacilityType,
      };
    case ONBOARD_SPECIALITY:
      const currentSpecialityTypesList = state.onboardSpeciality?.results || [];
      const previousSpecialityTypeData = action.payload.isNext
        ? currentSpecialityTypesList || []
        : [];
      const updatedSpecilaityType = action.payload.data;
      updatedSpecilaityType.results = previousSpecialityTypeData.concat(
        updatedSpecilaityType.results
      );
      return {
        ...state,
        onboardSpeciality: updatedSpecilaityType,
      };
    case ONBOARD_PERMISSIONS:
      const currentPermissionList = state.onboardPermissions?.results || [];
      const previousPermissionData = action.payload.isNext
        ? currentPermissionList || []
        : [];
      const updatedPermission = action.payload.data;
      updatedPermission.results = previousPermissionData.concat(
        updatedPermission.results
      );
      return {
        ...state,
        onboardPermissions: updatedPermission,
      };
    case SURGEON_SPINNER:
      return {
        ...state,
        closeRoles: false,
      };
    case CREATE_SURGEON:
      return {
        ...state,
        surgeonData: {
          data: {
            results: [
              {
                ...(action?.payload?.data || {}),
              },
              ...(state.surgeonData?.data?.results || []),
            ],
          },
        },
        showRoleSpinner: false,
        closeRoles: true,
      };
    case CREATE_SURGEON_ERROR:
      const fields = action.payload?.map((f: any) => f.field);
      const createSurgeonError = action.payload?.reduce(
        (f: any, c: any) => ({ ...f, [c.field]: c.details }),
        {}
      );
      return {
        ...state,
        createSurgeonError,
        createSurgeonErroFieldName: fields,
      };
    case CREATE_SURGEON_CLEAR_ERRORS:
      if (state.createSurgeonErroFieldName.indexOf(action.payload) != -1) {
        delete state.createSurgeonError[action.payload];
        delete state.createSurgeonErroFieldName[action.payload];
      }
      return {
        ...state,
        createSurgeonError: state.createSurgeonError,
        createSurgeonErroFieldName: state.createSurgeonErroFieldName,
      };
    case DELETE_SURGEON:
      return {
        ...state,
        deleteSurgeon: action.payload?.data,
      };
    case DELETE_VIDEO:
      return {
        ...state,
        deleteVideo: action.payload?.data,
      };
    case BULK_ONBOARD_SURGEON:
      return {
        ...state,
        bulkSurgeonOnboard: action.payload?.data,
        loadingState: false,
      };
    case ERROR_IN_BULK_ONBOARD:
      return {
        ...state,
        bulkSurgeonOnboardError: action.payload,
        loadingState: false,
      };
    case GET_SURGEON_BY_ID:
      return {
        ...state,
        surgeonById: action.payload.data,
      };
    case GET_VIDEOS_BY_ID:
      return {
        ...state,
        videosById: action.payload?.data,
      };
    case DOWNLOAD_TEMPLATE:
      return {
        ...state,
        templateData: action.payload,
      };
    case LOADER:
      if (action.payload.calledFrom === "GET_ALL_SURGEONS") {
        return {
          ...state,
          loaderWithMessageSurgeons: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else if (action.payload.calledFrom === "GET_ALL_VIDEOS") {
        return {
          ...state,
          loaderWithMessageVideos: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: true,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
          },
        };
      }
    case STOPLOADER:
      if (action.payload.calledFrom === "GET_ALL_SURGEONS") {
        return {
          ...state,
          loaderWithMessageSurgeons: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else if (action.payload.calledFrom === "GET_ALL_VIDEOS") {
        return {
          ...state,
          loaderWithMessageVideos: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      } else {
        return {
          ...state,
          loaderWithMessage: {
            loadingState: false,
            message: action.payload?.message,
            calledFrom: action.payload?.calledFrom,
            statusCode: action.payload?.statusCode,
          },
        };
      }
    case CLEAR_ALL_SURGEONS:
      return {
        ...state,
        surgeonById: null,
        allSurgeons: null,
      };
    case GET_ALL_HOSPITAL_ADMINS:
      return {
        ...state,
        allHospitalAdmins: action.payload?.data,
      };
    case GET_HOSPITAL_ADMIN_DETAILS_BY_USERID:
      return {
        ...state,
        hospitalAdminById: action.payload?.data,
      };
    default:
      return {
        ...state,
      };
  }
};
