import { apiEndPoint, doHttpCall } from "../../common/restApi";
import * as allActions from "./actionConstants";

export const getProcedureVideos = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/videos/procedures/chart",
  };
  const response = await doHttpCall(payload);
  if (response && response.status === 200) {
    dispatch({
      type: allActions.FETCH_PROCEDURE_VIDEOS,
      payload: response.data,
    });
  }
};

export const clearProceduresVideosDataWhenUnmount = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_DATA_ON_LOGOUT,
  });
};

export const getProcedureVideosRowData =
  (currentPage = 1) =>
  async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/videos/procedures/?page=${currentPage}&page_size=30`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.FETCH_PROCEDURE_ROW_VIDEOS,
        payload: response.data,
      });
    }
  };

export const setSelectedItem = (data: any) => (dispatch: any) => {
  dispatch({
    type: allActions.SET_SELECTED_PROCEDURE_VALUE,
    payload: data,
  });
};

export const setProcedureDateFilterType =
  (selectedOption: any) => (dispatch: any) => {
    dispatch({
      type: allActions.PROCEDURE_DATA_DATE_FILTER_TYPE,
      payload: selectedOption,
    });
  };

export const setProcedureDateCustomFilterDateStorage =
  (data: any, dateSelectType: string) => (dispatch: any) => {
    dispatch({
      type: allActions.PROCEDURE_CUSTOM_DATE_FILTER,
      payload: {
        date: data,
        dateType: dateSelectType,
      },
    });
  };

export const showSpinnerOnLoad = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER });
};

export const clearMyProceduresDataOnLogout = () => (dispatch: any) => {
  dispatch({ type: allActions.CLEAR_DATA_ON_LOGOUT });
};

// export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
//   return {
//     type: allActions.LOADER,
//     payload: {
//       loadingState: true,
//       message: message,
//       calledFrom: calledFrom,
//     },
//   };
// };

// export const hideLoaderWithMessage = (
//   message?: any,
//   calledFrom?: any,
//   statusCode?: any
// ) => {
//   return {
//     type: allActions.STOPLOADER,
//     payload: {
//       loadingState: false,
//       message: message,
//       calledFrom: calledFrom,
//       statusCode: statusCode,
//     },
//   };
// };
