import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  Spinner,
  SpinnerSize,
  Text,
} from "office-ui-fabric-react";
import React, { useCallback } from "react";
import { apiEndPoint, doHttpCall } from "../../common/restApi";
import InprogressVideoDetails from "../../InProgress/components/InProgressVideoDetails";
import InprogressVideoEdit from "../../InProgress/components/InprogressVideoEdit";
import { ThemeColorPalette } from "../../theme";
import { getAllVideos } from "../../HospitalAdminModule/Home/actions/homeAction";
import { useDispatch } from "react-redux";
import * as allActions from "../actions/actionConstants";
import { ROLE_TYPES } from "../../Layout/constants/constants";
import { getVideosById } from "../../HospitalAdminModule/Home/actions/homeAction";

function ViewEditVideoDetailsPanel({
  displayDetails,
  videoData,
  userProfile,
  userData,
  proceduresData,
  getVideoDetails,
  match,
  openPanel = false,
  showDetailsButton = false,
  hideFooter = false,
  onPanelClose = () => {},
  videoId,
  spinnerLoader,
  locationDetails,
  greenLoader,
  getOthersVideoForHospitalAdmin1,
}: any) {
  const [isPanelOpen, setIsPanelOpen] = React.useState(openPanel);
  const [enableSave, setEnableSave] = React.useState(false);
  const [handleEditDetails, setHandleEditDetails] = React.useState(false);
  const [editedFields, setEditedFields]: any = React.useState({});
  const [tempVideosData, setTempVideosData]: any = React.useState({});
  const [videosData, setVideosData]: any = React.useState({});
  const [largePdfFileError, setLargePdfFileError]: any = React.useState("");
  const [fileTypeError, setFileTypeError] = React.useState<any>();
  const [loaderFromViewBtn, setLoaderFromViewBtn] = React.useState<any>(false);
  const [disabledCreate, setDisabledCreate] = React.useState<any>(false);
  React.useEffect(() => {
    setVideosData(videoData);
    if (greenLoader) {
      setLoaderFromViewBtn(true);
    }
    setTempVideosData(videoData);
  }, [videoData]);

  const spinnerFunc = (dummySpinner = false) => {
    if (dummySpinner) {
      setDisabledCreate(true);
    } else {
      setDisabledCreate(false);
    }
    return dummySpinner;
  };
  const handleChange = (
    e: any,
    data: any,
    selectedOption?: any,
    selectedField?: string,
    selectType?: string
  ) => {
    if (selectedField === "procedureName") {
      const value = {
        ...data,
        procedure_name: selectedOption.text,
        primary_procedure_type: selectedOption.key,
      };
      const editedField = {
        ...editedFields,
        primary_procedure_type: selectedOption.key,
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(true);
    } else if (selectedField === "surgeonName") {
      const value = {
        ...data,
        primary_surgeon_name: selectedOption.text,
        primary_surgeon: selectedOption.key,
      };
      const editedField = {
        ...editedFields,
        primary_surgeon: selectedOption.key,
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(true);
    } else if (selectedField === "procedureName2") {
      const value = {
        ...data,
        secondary_procedure_name: selectedOption.text,
        secondary_procedure_type: selectedOption.key,
      };
      const editedField = {
        ...editedFields,
        secondary_procedure_type: selectedOption.key,
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(true);
    } else if (selectedOption === "date") {
      const value = {
        ...data,
        surgery_date: e,
      };
      const editedField = {
        ...editedFields,
        surgery_date: e,
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(true);
    } else {
      const value = {
        ...data,
        [e.target.name]: [e.target.value],
      };
      const editedField = {
        ...editedFields,
        [e.target.name]: [e.target.value],
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(true);
    }
  };

  const handlePatientConsentFormFileChange = async (evt: any, data: any) => {
    setLargePdfFileError("");
    setFileTypeError("");
    const fileObj = evt.target.files[0];
    const fileType = fileObj.type;
    const fileSize: any = fileObj.size
      ? (fileObj.size / (1024 * 1024)).toFixed(2)
      : 0;
    if (parseFloat(fileSize) > 5) {
      setLargePdfFileError("Please Upload File less than 5 Mb");
      return true;
    }
    if (
      fileType === "image/png" ||
      fileType === "image/PNG" ||
      fileType.includes("pdf")
    ) {
      const val = { ...data, patientConsentForm: evt.target.files[0] };
      const value = {
        ...data,
        patient_concent_forms: evt.target.files[0].name,
        patientConcentForm: evt.target.files[0],
      };
      const editedField = {
        ...editedFields,
        patientConcentForm: evt.target.files[0],
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(true);
    } else {
      const value = {
        ...data,
        patient_concent_forms: null,
        patientConcentForm: null,
      };
      const editedField = {
        ...editedFields,
        patientConcentForm: null,
      };
      setEditedFields(editedField);
      setVideosData(value);
      setEnableSave(false);
      setFileTypeError("File type not supported");
    }
  };
  const dismissPanel = useCallback(() => {
    onPanelClose();
    setEnableSave(false);
    setIsPanelOpen(false);
    setHandleEditDetails(false);
    setVideosData(tempVideosData);
    setLargePdfFileError("");
    setFileTypeError("");
  }, [onPanelClose]);
  const dispatch = useDispatch();
  const handleUpdatedVideoDetailsSave = async () => {
    // setEnableSave(false);
    // setIsPanelOpen(false);
    // setHandleEditDetails(false);
    setDisabledCreate(true);
    const formData: any = new FormData();

    Object.keys(editedFields).forEach((val: any) => {
      val !== "note" && formData.append(val, editedFields[val]);
    });
    try {
      const payload = {
        method: "PATCH",
        url: apiEndPoint + "/videos/" + videoData.id + "/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      dispatch({
        type: allActions.SHOW_SPINNER_VIDEO_DETAILS,
      });
      const response = await doHttpCall(payload);
      if (response.status === 200 || response.status === 201) {
        await dispatch(getVideosById(videoData?.primary_surgeon, 1));
        setTempVideosData(videosData);
        // dispatch(getAllVideos("", 1, ""));
        dispatch({
          type: allActions.SHOW_SPINNER_VIDEO_DETAILS,
        });
        dispatch(getAllVideos("", 1, ""));
        dispatch(getAllVideos("", 1, ""));
        setDisabledCreate(false);
        setEnableSave(false);
        setIsPanelOpen(false);
        setHandleEditDetails(false);
      } else {
        setDisabledCreate(false);
      }
      if (
        videosData.primary_procedure_type !== videoData.primary_procedure_type
      ) {
        const loadedFrom = locationDetails?.state?.loadedFrom;
        let uploadedByUser = false;
        // if (
        //   loadedFrom === ROLE_TYPES.ACTIVE_ADMIN ||
        //    loadedFrom === ROLE_TYPES.ACTIVE_DEVELOPER
        // ) {
        uploadedByUser = locationDetails?.state?.is_uploaded_by_user;
        getVideoDetails(
          match.id,
          false,
          { loadedFrom, uploadedByUser },
          videosData?.primary_surgeon
        );
      }
      setEditedFields({});
      dismissPanel();
    } catch (e) {
      console.error(e);
      setEditedFields({});
      setEnableSave(false);
      setIsPanelOpen(false);
      setHandleEditDetails(false);
      dismissPanel();
    }
  };

  const onRenderPanelBody = React.useCallback(() => {
    const [secondaryProcedureName] = proceduresData?.results
      ? proceduresData?.results?.filter(
          (item: any) => item.id === videosData.secondary_procedure_type
        )
      : [""];
    const screenName = locationDetails?.state?.screenType || "";
    return (
      <Stack
        horizontal
        horizontalAlign="start"
        tokens={{ childrenGap: 10 }}
        styles={{
          root: {
            padding: "0rem 1.2rem",
          },
        }}
      >
        {!handleEditDetails ? (
          <InprogressVideoDetails
            userData={userData}
            userProfile={userProfile}
            data={videosData}
            procedureName={videosData && videosData.procedure_name}
            secondaryProcedureName={
              secondaryProcedureName && secondaryProcedureName.name
            }
            primarySurgeonName={
              videosData.primary_surgeon_name
                ? videosData.primary_surgeon_name
                : displayDetails?.primary_surgeon_name
            }
            handleEdit={() => setHandleEditDetails(true)}
            screen={
              screenName === "sharedWithMe"
                ? "viewOnly"
                : (videoData && videoData.primary_surgeon === userProfile.id) ||
                  localStorage.getItem("role") === "Surgeon" ||
                  localStorage.getItem("role") === "HospitalAdmin"
                ? ""
                : "viewOnly"
            }
            loaderFromViewBtn={loaderFromViewBtn}
            disabledCreate={disabledCreate}
            spinnerFunc={spinnerFunc}
          />
        ) : (
          <InprogressVideoEdit
            data={videosData}
            dropdownVals={proceduresData}
            userData={userData}
            userProfile={userProfile}
            handlePatientConsentFormFileChange={
              handlePatientConsentFormFileChange
            }
            handleChange={handleChange}
            screen="allvideosedit"
            largePdfFileError={largePdfFileError}
            fileTypeError={fileTypeError}
          />
        )}
        {spinnerLoader && <div className="view-details-loader"></div>}
      </Stack>
    );
  }, [
    videosData,
    handleEditDetails,
    userProfile,
    userData,
    spinnerLoader,
    largePdfFileError,
    fileTypeError,
  ]);

  const onRenderPanelFooter = () => {
    return (
      <>
        {!hideFooter && (
          <Stack
            horizontal
            horizontalAlign="start"
            tokens={{ childrenGap: 10 }}
            styles={{
              root: {
                padding: "1rem 3rem",
                borderTop: "0.5px solid " + ThemeColorPalette.panelBorder,
              },
            }}
          >
            <PrimaryButton
              disabled={!enableSave || disabledCreate}
              onClick={handleUpdatedVideoDetailsSave}
            >
              Save
              <Text className="spinnerRight">
                {disabledCreate && <Spinner size={SpinnerSize.small} />}
              </Text>
            </PrimaryButton>
            <DefaultButton onClick={dismissPanel}>Cancel</DefaultButton>
          </Stack>
        )}
      </>
    );
  };

  return (
    <StackItem>
      {showDetailsButton && (
        <DefaultButton
          text="View Details"
          // onClick={() => setIsPanelOpen(true)}
          onClick={() => {
            setIsPanelOpen(true);
            const loadedFrom = locationDetails?.state?.loadedFrom;
            let uploadedByUser = false;
            // if (
            //   loadedFrom === ROLE_TYPES.ACTIVE_ADMIN ||
            //    loadedFrom === ROLE_TYPES.ACTIVE_DEVELOPER
            // ) {
            uploadedByUser = locationDetails?.state?.is_uploaded_by_user;
            //    }
            // getVideoDetails(videoId);
            userProfile.role_name === "HospitalAdmin"
              ? getVideoDetails(
                  videoId,
                  videosData?.is_uploaded_by_user,
                  { loadedFrom, uploadedByUser },
                  videosData?.primary_surgeon
                )
              : loadedFrom
              ? getVideoDetails(videoId, videosData?.is_uploaded_by_user, {
                  loadedFrom,
                  uploadedByUser,
                })
              : getVideoDetails(videoId, videosData?.is_uploaded_by_user, {
                  loadedFrom,
                  uploadedByUser,
                });
          }}
          iconProps={{
            iconName:
              localStorage.getItem("role") !== "Developer" &&
              localStorage.getItem("role") !== "ActivAdmin"
                ? "Edit"
                : "",
          }}
        />
      )}
      <Panel
        isOpen={isPanelOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={true}
        type={PanelType.custom}
        customWidth={"45rem"}
        styles={{
          footerInner: {
            padding: 0,
          },
        }}
        onRenderBody={onRenderPanelBody}
        onRenderFooterContent={onRenderPanelFooter}
      ></Panel>
    </StackItem>
  );
}

export default ViewEditVideoDetailsPanel;
