export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_LOGIN_USER_PROFILE = "GET_LOGIN_USER_PROFILE";
export const GET_PROCEDURE_DETAILS = "GET_PROCEDURE_DETAILS";
export const GET_SELECTED_VIDEO_INFO = "GET_SELECTED_VIDEO_INFO";
export const GET_VIDEO_BY_ID = "GET_VIDEO_BY_ID";
export const GET_SHARED_VIDEOS = "GET_SHARED_VIDEOS";
export const GET_OFFLINE_NOTIFICATIONS = "GET_OFFLINE_NOTIFICATIONS";

export const GET_NOTIFICATIONS_FROM_PUSHER = "GET_NOTIFICATIONS_FROM_PUSHER";
export const CLEAR_ALL_NOTIFICATIONS = "CLEAR_ALL_NOTIFICATIONS";
export const ACCEPT_NOTIFICATION = "ACCEPT_NOTIFICATION";

export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const CLEAR_DASHBOARD_DATA_ON_LOGOUT = "CLEAR_DASHBOARD_DATA_ON_LOGOUT";
export const CLEAR_LOGOUT = "SET_CLEAR_LOGOUT";
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";
export const RESET_USER_ERROR = "RESET_USER_ERROR";
export const SPINNER_STOP_LAYOUT = "SPINNER_STOP_LAYOUT";
export const SHOW_VIDEO_UPLOAD_ERROR = "SHOW_VIDEO_UPLOAD_ERROR";
export const HIDE_VIDEO_UPLOAD_ERROR = "HIDE_VIDEO_UPLOAD_ERROR";
export const GET_PROCEDURE_DETAILS_VIDEO_UPLOAD =
  "GET_PROCEDURE_DETAILS_VIDEO_UPLOAD";
