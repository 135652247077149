export const FETCH_VIDEOS_FAVORITES = "FETCH_VIDEOS_FAVORITES";

export const SELECT_FAVOURITE_VIDEO = "SELECT_FAVOURITE_VIDEO";
export const SET_SELECTED_FAVORITE_VIDEO_UNFAVOURITE =
  "SET_SELECTED_FAVORITE_VIDEO_UNFAVOURITE";
export const EDIT_FAVORITE_VIDEO_DETAILS = "EDIT_FAVORITE_VIDEO_DETAILS";

export const RESET_EDITED_DATA_ON_CANCEL_FAVORITES =
  "RESET_EDITED_DATA_ON_CANCEL_FAVORITES";

export const SHOW_FAVORITE_SPINNER = "SHOW_FAVORITE_SPINNER";

export const CLEAR_FAVORITES_DATA = "CLEAR_FAVORITES_DATA";
