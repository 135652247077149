import * as allActions from "../actions/actionConstants";

const initialState = {
  isLoggedIn: Boolean(localStorage.getItem("token")),
  loggedInUserData: localStorage.getItem("loggedInProfile")
    ? JSON.parse(localStorage.getItem("loggedInProfile") as any)
    : {},
  loggedInError: false,
  loggedInErrorData: null,
  showLoginPage: true,
  changePasswordStatus: {},
  showLoginAlert: false,
  ChangePasswordSpinner: false,
  logout: false,
};

export const authReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case allActions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        loggedInError: false,
        loggedInUserData: action.payload,
        showLoginAlert: true,
        loggedInErrorData: null,
        logout: false,
      };
    case allActions.LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loggedInError: true,
        loggedInErrorData: action.payload,
      };
    case allActions.SHOW_LOGIN_PAGE:
      return {
        ...state,
        showLoginPage: action.payload,
      };
    case allActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordStatus: action.payload,
        ChangePasswordSpinner: true,
      };
    case allActions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordStatus: action.payload,
        // errorResponsePassword: action.payload?.error,
      };
    case allActions.SET_LOGIN_ALERT_FALSE:
      return {
        ...state,
        showLoginAlert: false,
      };
    case allActions.CLEAR_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        loggedInUserData: {},
      };
    case allActions.SHOW_CHANGE_PASSWORD:
      return {
        ...state,
        ChangePasswordSpinner: false,
      };
    case allActions.CLOSE_ERROR_PASSWORD:
      return {
        ...state,
        errorResponsePassword: "",
      };
    case "LOGOUT":
      return {
        ...state,
        logout: true,
      };
    default:
      return state;
  }
};
