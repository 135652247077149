import React, { FormEvent } from "react";
import {
  Stack,
  TextField,
  Label,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import ToggleCard from "../../../../Home/components/Wizard/ToggleCard";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { roleIncrement } from "../../../actions/managamentActions";
import { useState, useEffect } from "react";
import {
  updateRolesPermissionActionData,
  updateSelectedRoleName,
  updateSelectedRoleComments,
  updateSelectedRoleLocation,
  updateIsPermissionListFetched,
} from "../../../actions/managamentActions";
import { AppState } from "../../../../../rootReducer";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndPoint, doHttpCall } from "../../../../../common/restApi";
import LoaderWithMessage from "../../../../../common/customLoader/LoaderWithMessage";
import { default as CreateRoleStrings } from "../activ-admin-module-management-components-roles.string.json";
import { default as ManagementStrings } from "../../../activeadmin-management.string.json";

const RoleCreation = ({
  showCreateRole,
  rolesPermissionData,
  roleChangeHandler,
  userTypeChangeHandler,
  roleLocationChangeHandler,
  showUserType,
  showUserTypeClient,
}: {
  showCreateRole: any;
  rolesPermissionData: any;
  roleChangeHandler: any;
  userTypeChangeHandler: any;
  roleLocationChangeHandler: any;
  showUserType: any;
  showUserTypeClient: any;
}) => {
  const [rolechange, setRolechange] = useState("");
  const permissionsUserTypes = useSelector((state: AppState) => {
    return state.managementReducerData.permissionsUserTypes;
  });
  const permissionsUserTypesClient = useSelector((state: AppState) => {
    return state.managementReducerData.permissionsUserTypesClient;
  });
  const role_locations = useSelector((state: AppState) => {
    return state.managementReducerData.roleLocations;
  });
  const selectedRoleName = useSelector((state: AppState) => {
    return state.managementReducerData.selectedNameForRole;
  });
  const selectedRoleLocation = useSelector((state: AppState) => {
    return state.managementReducerData.selectedRoleLocation;
  });
  const selectedPermissionUserType = useSelector((state: AppState) => {
    return state.managementReducerData.selectedPermissionUserType;
  });
  const selectedCommentsForRole = useSelector((state: AppState) => {
    return state.managementReducerData.selectedCommentsForRole;
  });
  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessage;
  });
  const isRoleChanged = rolesPermissionData?.name
    ? rolesPermissionData?.name
    : "" !== rolechange;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(CreateRoleStrings.RoleNameRequiredValidationMessage)
      .test({
        message: CreateRoleStrings.RoleNameAlreadyTakenMessage,
        test: async (value) => {
          if (rolechange !== "" && isRoleChanged && !rolesPermissionData) {
            const checkFieldName = `/user/activadmin/roles?search=${value}`;
            const payload = {
              method: "GET",
              url: `${apiEndPoint}${checkFieldName}`,
            };
            const response = await doHttpCall(payload);
            const returnStatus = response.data.results >= 0 ? true : false;
            return returnStatus;
          } else {
            return true;
          }
        },
      }),
    role_location: Yup.string().required(
      CreateRoleStrings.RoleLocationRequiredValidationMessage
    ),
    user_type: Yup.string().when("role_location", {
      is: (role_location: any) =>
        !role_location ||
        role_location === ManagementStrings.ActivSurgicalRoleLocation,
      then: Yup.string().required(
        CreateRoleStrings.UserTypeRequiredValidationMessage
      ),
    }),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    setValue,
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm(formOptions);
  const dispatch = useDispatch();
  useEffect(() => {
    if (rolesPermissionData && Object.keys(rolesPermissionData).length) {
      setValue("name", rolesPermissionData.name);
      setValue("role_location", rolesPermissionData.role_location);
      setValue("user_type", rolesPermissionData.user_type);
      setValue("comments", rolesPermissionData.comments);
    }
  }, [rolesPermissionData]);
  const onSubmit = (data: any) => {
    const updateRole = { ...rolesPermissionData, ...data };
    const isRoleNameChanged =
      rolesPermissionData?.name !==
      (rolechange === "" ? rolesPermissionData?.name : rolechange);
    dispatch(updateRolesPermissionActionData(updateRole));
    dispatch(roleIncrement());
    roleChangeHandler(!isRoleNameChanged);
  };
  const renderErrorMessage = (errorType: any, errorMessage: any) => {
    return (
      <>
        <Label className="errorMessage">{errorType ? errorMessage : ""}</Label>
      </>
    );
  };
  const renderNameAndLocation = () => {
    const roleLocationsDropdownOptions: IDropdownOption[] = role_locations;
    const handleNameChange = (event: any) => {
      setValue("name", event?.target?.value);
      setRolechange(event?.target?.value);
      dispatch(updateSelectedRoleName(event?.target?.value));
      errors.name = "";
    };
    const handleRoleLocationChange = (
      event: FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
      index?: number | undefined
    ): void => {
      setValue("role_location", option?.key);
      roleLocationChangeHandler(option?.key);
      dispatch(updateSelectedRoleLocation(option?.key));
      dispatch(updateIsPermissionListFetched(false));
      if (option && option?.key === "ACTIVSURGICAL") {
        setValue("user_type", "");
      }
      errors.role_location = "";
    };
    return (
      <>
        <Stack className="deviceInputSection ms-Grid-row">
          <Stack.Item className="ms-Grid-row">
            <Stack.Item className="ms-Grid-col ms-lg12">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg3 ms-lgOffset2">
                  <label className="ms-Label root-custom">
                    {CreateRoleStrings.RoleNameLabel}
                  </label>
                  <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                    <input
                      defaultValue={
                        showCreateRole
                          ? rolesPermissionData?.name
                          : selectedRoleName
                      }
                      className="ms-TextField-field field-custom"
                      type="text"
                      autoComplete="off"
                      {...register("name")}
                      onChange={(e: any) => handleNameChange(e)}
                    ></input>
                  </Stack>
                  {renderErrorMessage(errors?.name, errors?.name?.message)}
                </Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg3 ms-lgOffset2 ">
                  <Label className="addFacilityHeading">
                    {CreateRoleStrings.RoleLocationLabel}
                  </Label>
                  <Controller
                    control={control}
                    defaultValue={
                      showCreateRole
                        ? rolesPermissionData?.role_location
                        : selectedRoleLocation
                    }
                    {...register("role_location")}
                    render={() => (
                      <Dropdown
                        {...register("role_location")}
                        placeholder={`${CreateRoleStrings.RoleLocationPlaceHolder}`}
                        options={roleLocationsDropdownOptions}
                        defaultSelectedKey={
                          showCreateRole
                            ? rolesPermissionData?.role_location
                            : selectedRoleLocation
                        }
                        onChange={handleRoleLocationChange}
                        disabled={!!showCreateRole}
                        defaultValue={
                          showCreateRole
                            ? rolesPermissionData?.role_location
                            : selectedRoleLocation
                        }
                      />
                    )}
                  />
                  {renderErrorMessage(
                    errors?.role_location,
                    errors?.role_location?.message
                  )}
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack>
      </>
    );
  };
  const renderUserType = () => {
    const userTypesDropdownOptions: IDropdownOption[] = showUserTypeClient
      ? permissionsUserTypesClient
      : permissionsUserTypes;
    const onChange = (
      event: FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined,
      index?: number | undefined
    ): void => {
      setValue("user_type", option?.key);
      userTypeChangeHandler(option?.key);
      errors.user_type = "";
    };
    return (
      <>
        <Stack className="deviceInputSection ms-Grid-row">
          <Stack.Item className="ms-Grid-row">
            <Stack.Item className="ms-Grid-col ms-lg12">
              <Stack.Item className="ms-Grid-row">
                {showUserType ? (
                  <Stack.Item className="ms-Grid-col ms-lg3 ms-lgOffset2 ">
                    <Label className="addFacilityHeading">
                      {CreateRoleStrings.UserTypeLabel}
                    </Label>
                    <Controller
                      {...register("user_type")}
                      control={control}
                      defaultValue={
                        showCreateRole
                          ? rolesPermissionData?.user_type
                          : selectedPermissionUserType
                      }
                      render={() => (
                        <Dropdown
                          {...register("user_type")}
                          placeholder={`${CreateRoleStrings.UserTypePlaceHolder}`}
                          options={userTypesDropdownOptions}
                          defaultSelectedKey={
                            showCreateRole
                              ? rolesPermissionData?.user_type
                              : selectedPermissionUserType
                          }
                          onChange={onChange}
                          disabled={!!showCreateRole}
                        />
                      )}
                    />
                    {renderErrorMessage(
                      errors?.user_type,
                      errors?.user_type?.message
                    )}
                  </Stack.Item>
                ) : (
                  <></>
                )}
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack>
      </>
    );
  };
  const handleCommentsChange = (e: any) => {
    setValue("comments", e?.target?.value);
    dispatch(updateSelectedRoleComments(e?.target?.value));
  };
  const renderComments = () => {
    return (
      <>
        <Stack className="deviceInputSection ms-Grid-row">
          <Stack.Item className="ms-Grid-row">
            <Stack.Item className="ms-Grid-col ms-lg12">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg3 ms-lgOffset2 except">
                  <label className="ms-Label root-custom">
                    {CreateRoleStrings.CommentsLabel}
                  </label>
                  <Stack>
                    <TextField
                      className="ms-TextField-field field-custom"
                      autoComplete="off"
                      {...register("roles[0].comments")}
                      multiline
                      defaultValue={
                        showCreateRole
                          ? rolesPermissionData?.comments
                          : selectedCommentsForRole
                      }
                      onChange={(e: any) => handleCommentsChange(e)}
                    />
                  </Stack>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack>
      </>
    );
  };

  return (
    <>
      {rolesPermissionData || !showCreateRole ? (
        <form id="roleForm" onSubmit={handleSubmit(onSubmit)}>
          <ToggleCard heading="" index="" removeFormFields="">
            {renderNameAndLocation()}
            {renderUserType()}
            {renderComments()}
          </ToggleCard>
        </form>
      ) : (
        <></>
      )}
      {loaderWithMessage &&
        loaderWithMessage?.calledFrom === "GET_ROLES_VIEW" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>
        )}
    </>
  );
};
export default RoleCreation;
