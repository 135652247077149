import React from "react";
import {
  Stack,
  Modal,
  Text,
  IconButton,
  IIconProps,
  DefaultButton,
  PrimaryButton,
} from "@fluentui/react";
import { useDispatch } from "react-redux";
import { deleteHospital } from "../../actions/homeAction";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const DeleteModal = ({
  isModalOpen,
  showModal,
  id,
  deleteHandle,
  name,
  disabledCreate,
  zIndex,
}: {
  isModalOpen: any;
  showModal: any;
  id: any;
  deleteHandle: any;
  name: any;
  disabledCreate?: any;
  zIndex?: any;
}) => {
  const dispatch = useDispatch();

  const _alertClicked = () => {};
  return (
    <Modal
      className={zIndex ? "deleteModal z-index" : "deleteModal"}
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={() => showModal()}
      containerClassName={"smallModalStyle"}
      isBlocking={false}
    >
      <Stack className="deleteSection">
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <Stack.Item className="heading">
            <Text variant="xLarge">Delete</Text>
          </Stack.Item>
          <Stack.Item align="end">
            <IconButton
              className=""
              iconProps={cancelIcon}
              onClick={() => showModal()}
            />
          </Stack.Item>
        </Stack>
        <Stack.Item className="subHeading">
          <Text variant="small">Do you want to Delete this {name}?</Text>
        </Stack.Item>
        <Stack horizontal horizontalAlign="end" dir="ltr">
          <PrimaryButton
            disabled={disabledCreate}
            text="Delete"
            className="btnDelete"
            onClick={() => deleteHandle()}
            allowDisabledFocus
          >
            <Text className="spinnerRight">
              {disabledCreate && <Spinner size={SpinnerSize.small} />}
            </Text>
          </PrimaryButton>
          <DefaultButton
            text="Cancel"
            className="btnCancel"
            onClick={() => showModal()}
            allowDisabledFocus
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
export default DeleteModal;
