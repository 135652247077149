import React, { Fragment, useCallback } from "react";
import {
  Stack,
  Text,
  Modal,
  IconButton,
  IIconProps,
  Label,
} from "@fluentui/react";
import Wizard from "../Wizard";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import { useState, useEffect } from "react";
// import SurgeonCreation from "./SurgeonCreation";
import "../../../../../HospitalAdminModule/Home/components/CreateSurgeon/CreateSurgeon.scss";
// import { createSurgeon } from "../../actions/homeAction";../VideoPlayer/containers/VideoPlayerNew1
import CompareVideo from "../CompareVideo";
// import CompareVideo from "../CompareVideoNew";
import { StackItem } from "office-ui-fabric-react";
import CompareRating from "../rating";
import { clearCommentError } from "../../../actions/managementAction";
import {
  roleDecrement,
  roleIncrement,
} from "../../../../../ActiveAdminModule/Management/actions/managamentActions";
import ComparisonVideo from "../ComparisonVideo";
import LoaderWithMessage from "../../../../../common/customLoader/LoaderWithMessage";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const CompareModel = ({
  isModalOpen,
  hideModal,
  videoData,
  algorithm,
  video,
  models,
  versions,
  modelOptions,
  modelVersionOptions,
  handleChangeModelComparision,
  pageLoadder,
  versionErrors,
  handleModalChange,
}: {
  isModalOpen: any;
  hideModal: any;
  videoData?: any;
  algorithm: any;
  video: any;
  models: any;
  versions: any;
  modelOptions: any;
  modelVersionOptions: any;
  handleChangeModelComparision: any;
  pageLoadder: any;
  versionErrors: any;
  handleModalChange: any;
}) => {
  const dispatch = useDispatch();
  const videoRef = React.createRef();
  const [ratingData, setRatingData] = useState<any>({});
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [isVersionChange, setIsVersionChange] = useState(false);
  const [isVersionCh, setVersionCH] = useState(true);
  const [customLoadder, setCustomLoadder] = useState({
    calledFrom: "customLoadder",
    loadingState: false,
    message: "",
    statusCode: 200,
  });

  const counts = useSelector((state: AppState) => {
    return state.managementReducerData.roleCount;
  });

  // const spinner = useSelector((state: AppState) => {
  //   return state.managementReducerData.showRoleSpinner;
  // });

  const closeWizard = useCallback(() => {
    hideModal("close");
    dispatch(clearCommentError());
    // dispatch(clearPrivilageError());
    // dispatch(updateRolesPermissionActionData({}));
  }, [hideModal]);
  const getVideoSrc = useCallback(() => {
    const { streaming_endpoint } = videoData || {};
    const { dash, cdn } = streaming_endpoint || {};
    return [
      {
        src: (dash ? dash : cdn?.dash) || "",
        type: "application/dash+xml",
      },
    ];
  }, [videoData]);

  useEffect(() => {
    setDisabledCreate(false);
    const video: any = videoRef.current;
    if (video && typeof video.play == "function") {
      // video.play();
    }
  }, []);
  const spinnerFunc = (dummySpinner = false) => {
    if (dummySpinner) {
      setDisabledCreate(true);
    } else {
      setDisabledCreate(false);
    }
    return dummySpinner;
  };
  const onToggleScreen = (fullScreen: boolean) => {
    setIsFullScreen(fullScreen);
  };

  // const removeLocalItem = () => {
  //   localStorage.removeItem("commentData");
  //   localStorage.removeItem("commentData1");
  //   localStorage.removeItem("ratting");
  //   localStorage.removeItem("ratting1");
  // };

  const toggleVersionChanged = () => {
    setVersionCH(false);
    setIsVersionChange(true);
    setCustomLoadder({
      calledFrom: "customLoadder",
      loadingState: true,
      message: "",
      statusCode: 200,
    });
    setTimeout(() => {
      setVersionCH(true);
      setCustomLoadder({
        calledFrom: "customLoadder",
        loadingState: false,
        message: "",
        statusCode: 200,
      });
    }, 200);
  };

  return (
    <Modal
      className={`createHospital ${isFullScreen && "comparison-full-screen"}`}
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
      styles={{ root: { height: "calc(100vh - 72px)" } }}
    >
      {pageLoadder && <div className="view-details-loader"></div>}
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large">Model Comparison</Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton
            className=""
            iconProps={cancelIcon}
            onClick={() => {
              hideModal("close");
              //removeLocalItem();
            }}
          />
        </Stack.Item>
      </Stack>

      <Wizard
        counts={counts}
        form="ratingForm"
        back={roleDecrement}
        // spinner={spinner}
        disabledCreate={disabledCreate}
        hideFooter={isFullScreen}
        onCancel={() => {
          dispatch(clearCommentError());
          hideModal("close");
        }}
        goNext={() => {
          dispatch(roleIncrement());
        }}
        submitData={() => {}}
      >
        <Fragment>
          <div className="custom-fixed-height-div-compare-video-page">
            {isVersionCh ? (
              <ComparisonVideo
                algorithm={algorithm}
                video={video}
                models={models}
                versions={versions}
                videoUrl={videoData?.streaming_endpoint?.cdn?.dash}
                toggleFullScreen={onToggleScreen}
                modelVersionOptions={modelVersionOptions}
                modelOptions={modelOptions}
                handleChangeModelComparision={handleChangeModelComparision}
                versionErrors={versionErrors}
                handleModalChange={handleModalChange}
                isVersionChange={isVersionChange}
                toggleVersionChanged={toggleVersionChanged}
              />
            ) : (
              <LoaderWithMessage
                loaderWithMessage={customLoadder}
              ></LoaderWithMessage>
            )}
          </div>
          {!isFullScreen && (
            <CompareRating
              algorithm={algorithm}
              video={video}
              models={models}
              versions={versions}
              spinnerFunc={spinnerFunc}
              closeModal={() => {
                dispatch(clearCommentError());
                closeWizard();
              }}
              activeStep={counts}
              onDataUpdate={(data: any) => {}}
            />
          )}
        </Fragment>
      </Wizard>
    </Modal>
  );
};

export default CompareModel;
