import { doHttpCall, apiEndPoint } from "../../common/restApi";
import * as allActions from "./actionConstants";

export const getVideos =
  (videosData: string, currentPage: number) => async (dispatch: any) => {
    try {
      if (videosData === "sharedWithMe") {
        const payload = {
          method: "GET",
          url:
            apiEndPoint +
            "/videos/shared-videos?page=" +
            currentPage +
            "&page_size=8",
        };
        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.FETCH_VIDEOS,
            payload: { data: response.data, currentPage: currentPage },
          });
        }
      } else if (videosData === "sharedByMe") {
        const payload = {
          method: "GET",
          url:
            apiEndPoint +
            "/videos/sharedbyme?page=" +
            currentPage +
            "&page_size=8",
        };
        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.FETCH_VIDEOS,
            payload: { data: response.data, currentPage: currentPage },
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setSelectedSharedVideo = (data: any) => async (dispatch: any) => {
  dispatch({
    type: allActions.SET_SELECTED_SHARED_VIDEO,
    payload: data,
  });
};

export const setSelectedVideoUnfavourite =
  (data: any) => async (dispatch: any) => {
    try {
      if (data.favorite) {
        const payload = {
          method: "DELETE",
          url: apiEndPoint + "/videos/" + data.id + "/favorite",
        };

        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.SET_SELECTED_VIDEO_UNFAVOURITE,
            payload: data,
          });
        }
      } else {
        const payload = {
          method: "POST",
          url: apiEndPoint + "/videos/" + data.id + "/favorite",
          data: {
            user_id: data.primary_surgeon,
            video_id: data.id,
          },
        };

        const response = await doHttpCall(payload);
        if (response && response.status === 200) {
          dispatch({
            type: allActions.SET_SELECTED_VIDEO_UNFAVOURITE,
            payload: data,
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

export const editSharedVideoDetails =
  (data: any, videoId: number) => (dispatch: any) => {
    dispatch({
      type: allActions.EDIT_VIDEO_DETAILS,
      payload: { data, videoId },
    });
  };

export const resetEditedDataonCancel = (type: string) => (dispatch: any) => {
  dispatch({
    type: allActions.RESET_EDITED_DATA_ON_CANCEL_SHARED,
    payload: type,
  });
};

export const postSharedDetails = (data: any) => async (dispatch: any) => {
  try {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/share",
      data: data,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 201) {
      dispatch({
        type: allActions.POST_SHARED_DETAILS,
        payload: data,
      });
    }
    return response;
  } catch (e) {
    console.error(e);
  }
};

export const showSpinnerOnLoad = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER });
};

export const clearSharedVideoData = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_SHARED_DATA,
  });
};
