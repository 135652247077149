import * as React from "react";
import {
  Stack,
  StackItem,
  Image,
  Text,
  IStackItemStyles,
  IStackProps,
  ITextStyles,
} from "@fluentui/react";
import activSurgicalLogo from "../../../assets/activesurgical_logo.svg";
import doctorImg from "../../../assets/image.png";
import icon1 from "../../../assets/icon1.svg";
import icon2 from "../../../assets/icon2.svg";
import icon3 from "../../../assets/icon3.svg";
import { ThemeColorPalette, fonts } from "../../../theme";
import "./Home.scss";
import Login from "../Login/Login";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import { connect } from "react-redux";

const containerStyles: React.CSSProperties = {
  minHeight: "100vh",
  maxHeight: "100%",
};

const stackItemLeftStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.pureBlack,
    color: ThemeColorPalette.pureWhite,
    flex: 2,
  },
};

const stackItemRightStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.white,
    flex: 1,
  },
};

const leftColumnProps: Partial<IStackProps> = {
  tokens: { padding: "0", maxHeight: "100%" },
};

const leftFooterItems: Partial<IStackProps> = {
  tokens: {
    childrenGap: "10",
    maxWidth: "90%",
    padding: "0 0 0 10%",
  },
};

const footerItemsTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    fontFamily: fonts.DINPro_Bold,
  },
};

const gradientTextStyles: Partial<ITextStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Bold,
  },
};

export interface HomeProps {
  showLoginPage?: boolean;
}

export interface HomeState {}

class AuthContainerComponent extends React.Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {};
  }
  public render(): JSX.Element {
    const { showLoginPage } = this.props;
    return (
      <Stack horizontal style={containerStyles}>
        <StackItem styles={stackItemLeftStyles}>
          <Stack
            className="leftPanel"
            verticalFill
            {...leftColumnProps}
            verticalAlign="start"
          >
            <Stack verticalFill verticalAlign="center" horizontalAlign="center">
              <StackItem align="start" tokens={{ padding: "0 0 0 3rem " }}>
                <Image
                  height={100}
                  width={100}
                  src={activSurgicalLogo}
                  alt="Activ Edge logo"
                />
              </StackItem>
            </Stack>
            <Stack verticalFill verticalAlign="center" horizontalAlign="center">
              <StackItem align="center">
                <Image
                  src={doctorImg}
                  alt="Doctor logo"
                  height={"22rem"}
                  width={"19rem"}
                />
              </StackItem>
            </Stack>
            <Stack verticalFill verticalAlign="center" horizontalAlign="center">
              <StackItem align="center" tokens={{ padding: "1rem 0 0" }}>
                <Text
                  className="gradientTextStyles"
                  styles={gradientTextStyles}
                  variant="xxLarge"
                >
                  The platform that powers
                </Text>
              </StackItem>
              <StackItem align="center" tokens={{ padding: "0 0 2rem" }}>
                <Text
                  className="gradientTextStyles"
                  styles={gradientTextStyles}
                  variant="xxLarge"
                >
                  surgical innovation.
                </Text>
              </StackItem>
            </Stack>
            <Stack verticalFill verticalAlign="center" horizontalAlign="center">
              <StackItem align="center">
                <Stack horizontal {...leftFooterItems}>
                  <StackItem>
                    <Image src={icon1} alt="Doctor logo" />
                  </StackItem>
                  <StackItem tokens={{ padding: "0.2rem 0 0 0" }}>
                    <Text styles={footerItemsTextStyles} variant="medium">
                      Advanced Computer Vision
                    </Text>
                  </StackItem>
                  <StackItem>
                    <Image src={icon2} alt="Doctor logo" />
                  </StackItem>
                  <StackItem tokens={{ padding: "0.2rem 0 0 0" }}>
                    <Text styles={footerItemsTextStyles} variant="medium">
                      Autonomous Robotics
                    </Text>
                  </StackItem>
                  <StackItem>
                    <Image src={icon3} alt="Doctor logo" />
                  </StackItem>
                  <StackItem tokens={{ padding: "0.2rem 0 0 0" }}>
                    <Text styles={footerItemsTextStyles} variant="medium">
                      Artificial Intelligence
                    </Text>
                  </StackItem>
                </Stack>
              </StackItem>
            </Stack>
          </Stack>
        </StackItem>
        <StackItem styles={stackItemRightStyles}>
          {showLoginPage ? <Login /> : <ForgotPassword />}
        </StackItem>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    showLoginPage: state.authReducerData.showLoginPage,
  };
};

export default connect(mapStateToProps, null)(AuthContainerComponent);
