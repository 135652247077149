import {
  Stack,
  StackItem,
  Text,
  Image,
  IImageProps,
  ImageFit,
  IStackTokens,
  IconButton,
  IIconProps,
  SearchBox,
  Icon,
  Modal,
  IStackItemStyles,
  IPivotItemProps,
  Pivot,
  PivotItem,
  IContextualMenuProps,
  CommandButton,
  FontIcon,
  Link,
} from "@fluentui/react";
import upload_icon from "../../../assets/upload-buttonforhospitalAdmin.svg";
import no_data from "../../../assets/no_data.svg";
import no_surgeon from "../../../assets/no_surgeon.svg";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import Papa from "papaparse";
import React, { useState, useEffect, useCallback, useRef } from "react";

import surgeon from "../../../assets/Home/surgeon.svg";
import DeleteModal from "../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import { AppState } from "../../../rootReducer";
import SuccessMessage from "../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
// import  getHospitals  from "../actions/homeAction";
import {
  getAllSurgeons,
  getAllVideos,
  getHospitalInfo,
  showSurgeonSpinner,
  deleteSurgeon,
  bulkOnboardSurgeon,
  getDownloadTemplate,
  getSurgeonById,
  getAllHospitalAdmins,
} from "../actions/homeAction";

import { DefaultButton, ActionButton } from "@fluentui/react/lib/Button";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import { useSelector, useDispatch } from "react-redux";
import "./Home.scss";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import moment from "moment";
import Paging from "../../../common/paging/Paging";
import Videos from "../components/VideoSection/Video";
import CreateSurgeon from "../components/CreateSurgeon/CreateSurgeon";
import BulkSurgeonUpload from "../components/BulkSurgeonUpload/BulkSurgeonUpload";
import {
  setLoginAlertFalse,
  showChangePassword,
} from "../../../auth/actions/authAction";
import UploadVideoModel from "../../../MyProcedures/containers/uploadVideoModel/uploadVideoModel";
import { uuid } from "uuidv4";
import { useNavigate } from "react-router-dom";
import { useCSVDownloader } from "react-papaparse";
import { GET_SURGEON_BY_ID } from "../actions/actionConstants";
import { clearAllSurgeons } from "../../Activity/actions/activityAction";
import { debounce } from "lodash";
import { showSpinnerLayout } from "../../../Layout/actions/layoutActions";
import vector1 from "../../../assets/vector1.svg";
import vector2 from "../../../assets/vector2.svg";
import CheckPermission from "../../../common/permissions/rolePermissions";
import * as constants from "../../../Layout/constants/constants";
import LoaderWithMessage from "../../../common/customLoader/LoaderWithMessage";

const GridViewIcon: IIconProps = { iconName: "GridViewMedium" };
const ListViewIcon: IIconProps = { iconName: "List" };
const options: IChoiceGroupOption[] = [
  { key: "-created_date", text: "Last Created" },
  { key: "-modified_date", text: "Last Modified" },
  { key: "primary_procedure_type__name", text: "A-Z" },
  { key: "-primary_procedure_type__name", text: "Z-A" },
];

const surgeonOptions: IChoiceGroupOption[] = [
  { key: "newest", text: "Newest" },
  { key: "oldest", text: "Oldest" },
  { key: "video_count", text: "Video Count" },
];

const imageProps: IImageProps = {
  imageFit: ImageFit.none,
  width: "100%",
  height: 103,
  // Show a border around the image (just for demonstration purposes)
  styles: (props) => ({ root: { borderRadius: "2px" } }),
};
const mainStackTokens: IStackTokens = {
  padding: "20px",
  childrenGap: 10,
};

const topContainerStackTokens: IStackTokens = { maxHeight: 96, childrenGap: 5 };

const bottomContainerStackTokens: IStackTokens = { childrenGap: 27 };

const surgeonCardStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 12,
  maxWidth: 266,
  maxHeight: 110,
};

const topStackItemCard1Styles: IStackItemStyles = {
  root: {
    // background: "blue",
    color: "white",
    // padding: 5,
    height: 96,
    maxHeight: 96,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
};

const topStackItemCard2Styles: IStackItemStyles = {
  root: {
    background: "#013984",
    color: "white",
    paddingLeft: 8,
    paddingRight: 8,
    height: 96,
    maxHeight: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative",
    backgroundImage: `url(${vector2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom left",
  },
};

const topStackItemCard3Styles: IStackItemStyles = {
  root: {
    background: "blue",
    color: "white",
    // padding: 5,
    height: 96,
    maxHeight: 96,
    display: "flex",
  },
};

const topStackItemCard4Styles: IStackItemStyles = {
  root: {
    background: "blue",
    color: "white",
    // padding: 5,
    height: 96,
    maxHeight: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const topMainStackItem: IStackItemStyles = {
  root: {
    height: 96,
    marginBottom: 10,
  },
};

const bottomMainStackItem: IStackItemStyles = {
  root: {
    background: "#101112",
    height: "100%",
  },
};

interface SurgeonCardProps {
  comments: string;
  facility: string;
  first_name: string;
  last_name: string;
  photo: string;
  mail: string;
  speciality: string;
  total_videos: number;
  userId: string;
  created_date?: any;
  history: any;
  currentPage: any;
  searchValue: any;
  sortSurgeonValue: any;
  setShowRole: (type: boolean) => void;
  setIsEdit: (type: boolean) => void;
}

const addIcon: IIconProps = { iconName: "MoreVertical" };
const getMenuItems = () => {
  return [
    {
      key: "edit",
      text: "Edit",
      title: constants.getPermissionsbyItem("management.surgeon.edit")
        ? ""
        : constants.permission,
    },
    {
      key: "delete",
      title: constants.getPermissionsbyItem("management.surgeon.delete")
        ? ""
        : constants.permission,
      text: "Delete",
    },
  ];
};

const SurgeonCard: React.FC<SurgeonCardProps> = (props) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [surgeonId, setSurgeonId] = useState<string | any>("1");
  const [currentPage, setcurrentpage] = useState(props?.currentPage);
  const [searchValue, setSearchValue] = useState(props?.searchValue);
  const [sortSurgeonValue, setsortSurgeonValue] = useState(
    props?.sortSurgeonValue
  );
  const [disabledCreate, setDisabledCreate] = useState(false);

  const name = `${props.first_name} ${props.last_name}`.trim();
  const date = props.created_date
    ? moment(props.created_date).format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY");
  const videosCount = `${props.total_videos} Videos`;

  const dispatch = useDispatch();

  const deleteSurgeonsData = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.deleteSurgeon;
  });

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);
  useEffect(() => {
    setcurrentpage(props?.currentPage);
    setSearchValue(props?.searchValue);
    setsortSurgeonValue(props?.sortSurgeonValue);
  }, [props]);
  const allSurgeons = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.allSurgeons;
  });
  const [allSurgeonsLength, setAllSurgeonsLength] = useState(
    allSurgeons?.results
  );
  useEffect(() => {
    setAllSurgeonsLength(allSurgeons?.results?.length);
  }, [allSurgeons]);
  const deleteHandle = () => {
    setDisabledCreate(true);
    // dispatch(deleteSurgeon(surgeonId));
    dispatch(
      deleteSurgeon(
        surgeonId,
        searchValue,
        allSurgeonsLength > 1 ? currentPage : currentPage - 1,
        sortSurgeonValue
      )
    );
    setOpenDeleteModal((current) => !current);
  };

  const _onMenuClick = useCallback((text: string, uuid: string | number) => {
    if (
      constants.getPermissionsbyItem("management.surgeon.delete") &&
      text.toLowerCase() === "delete"
    ) {
      // dispatch(deleteSurgeon(uuid));
      setSurgeonId(uuid);
      showDeleteModal();
    }
    if (
      constants.getPermissionsbyItem("management.surgeon.edit") &&
      text.toLowerCase() === "edit"
    ) {
      props.setShowRole(true);
      props.setIsEdit(true);
      dispatch(getSurgeonById(uuid));
    }
  }, []);
  const navigate = useNavigate();

  const viewSurgeon = () => {
    navigate(`/${props.userId}`, { state: { photo: props?.photo } });
  };

  return (
    <Stack
      horizontal
      verticalFill
      tokens={surgeonCardStackTokens}
      className="surgeonCard"
      onClick={viewSurgeon}
    >
      {props?.photo ? (
        <Image
          style={{ borderRadius: "50%" }}
          src={props?.photo}
          width="40px"
          height="32px"
          alt="user image"
        ></Image>
      ) : (
        <StackItem
          className="circle"
          styles={{
            root: {
              height: "100%",
              width: "60px",
              cursor: "pointer",
            },
          }}
        >
          {props?.first_name?.charAt(0) + props?.last_name?.charAt(0)}
        </StackItem>
      )}

      <StackItem>
        <Stack tokens={{ childrenGap: 8 }}>
          <StackItem>
            <abbr className="surgeonCardTitle ms-bb-0 " title={name}>
              {name.length > 0
                ? name.length > 20
                  ? name.substring(0, 20) + "....."
                  : name
                : " "}
            </abbr>
          </StackItem>
          <StackItem>
            <Stack tokens={{ childrenGap: 2 }} className="surgeonCardDetails">
              <StackItem>{props.speciality || " "}</StackItem>
              <StackItem>{props.facility || " "}</StackItem>
              <StackItem>{videosCount}</StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <IconButton
        className="buttonIcon"
        menuIconProps={addIcon}
        menuProps={{
          items: getMenuItems(),
          onItemClick: (e: any) =>
            _onMenuClick(e?.target?.innerText, props?.userId),
        }}
        ariaLabel="New item"
      />
      <div className="surgeonCardDate">{date}</div>
      <DeleteModal
        disabledCreate={disabledCreate}
        isModalOpen={openDeleteModal}
        deleteHandle={deleteHandle}
        showModal={showDeleteModal}
        id={surgeonId}
        name="Surgeon"
      ></DeleteModal>
    </Stack>
  );
};

const HospitalAdminCard = (props: any) => {
  const name = `${props.displayName}`.trim();
  const date = props.created_date
    ? moment(props.created_date).format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY");
  const navigate = useNavigate();
  const viewAdminProfile = () => {
    navigate(`/admin/${props.userId}`, { state: { photo: props?.photo } });
  };
  return (
    <Stack
      horizontal
      verticalFill
      tokens={surgeonCardStackTokens}
      className="surgeonCard"
      onClick={viewAdminProfile}
    >
      {props?.photo ? (
        <Image
          style={{ borderRadius: "50%" }}
          src={props?.photo}
          width="40px"
          height="32px"
          alt="user image"
        ></Image>
      ) : (
        <StackItem className="circle" style={{ width: "40px", height: "32px" }}>
          {props?.first_name?.charAt(0) + props?.last_name?.charAt(0)}
        </StackItem>
      )}
      <StackItem>
        <Stack tokens={{ childrenGap: 8 }}>
          <StackItem>
            <abbr className="surgeonCardTitle ms-bb-0 " title={name}>
              {name.length > 0
                ? name.length > 20
                  ? name.substring(0, 20) + "....."
                  : name
                : " "}
            </abbr>
          </StackItem>
          <StackItem>
            <Stack tokens={{ childrenGap: 2 }} className="surgeonCardDetails">
              <StackItem>{props.role_name || " "}</StackItem>
            </Stack>
          </StackItem>
        </Stack>
      </StackItem>
      <div className="surgeonCardDate">{date}</div>
    </Stack>
  );
};

// const sortMenuProps = {
//   items: [
//     {
//       key: 'emailMessage',
//       text: 'Email message',
//       iconProps: { iconName: 'Mail' },
//     },
//     {
//       key: 'calendarEvent',
//       text: 'Calendar event',
//       iconProps: { iconName: 'Calendar' },
//     },
//   ],
// };

const Home = (props: any) => {
  const [showRole, setShowRole] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showPage, setshowPage] = useState<any>(true);

  const [modalOpen, setModalOpen] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const { CSVDownloader, Type } = useCSVDownloader();

  const closeRoles = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.closeRoles;
  });

  const templateData = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData.templateData?.data;
  });

  const userProfile = useSelector((state: AppState) => {
    return state.dashboardReducerData.loginUserProfile;
  });
  const userId = userProfile.id || userProfile.userId;
  const [selectedTabKey, setSelectedTabKey] = React.useState<any>(0);

  const mainInnerRef = useRef<any>();
  const [isInitialLanding, setIsInitialLanding] = React.useState(false);

  // const downloadBlobUrl = window.URL.createObjectURL(new Blob([templateData]));

  const showModal = () => {
    setShowRole(true);
  };

  const hideUploadModal = () => {
    setModalOpen(false);
    dispatch({
      type: GET_SURGEON_BY_ID,
      payload: { data: null },
    });
  };

  const downloadTemplate = () => {
    dispatch(getDownloadTemplate());
  };
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [videoCurrentPage, setVideoCurrentPage] = useState<number>(1);
  const dispatch = useDispatch();
  const [gridLayout, setGridLayout] = useState<boolean>(true);
  const bulkUpload = useRef<HTMLInputElement | null>(null);
  const [sortOptions, setSortOptions] = useState(false);
  const [sortSurgeonOptions, setSortSurgeonOptions] = useState(false);
  const [sortVideoValue, setSortVideoValue] = useState("-created_date");
  const [sortSurgeonValue, setSortSurgeonValue] = useState("newest");

  const ref = useRef<any>();

  const [lastHeader, setLastHeader] = useState<
    { props: IPivotItemProps } | undefined
  >(undefined);

  const hospitalInfo = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.hospitalInfo;
  });

  const UserProfileSpinner = useSelector((state: AppState) => {
    return state.dashboardReducerData.UserProfileSpinner;
  });

  const allSurgeons = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.allSurgeons;
  });
  const allVideos = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.allVideos;
  });

  const allVideosForPrimarySurgeons = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.allVideosForPrimarySurgeons;
  });

  const ChangePasswordSpinner = useSelector((state: AppState) => {
    return state.authReducerData.ChangePasswordSpinner;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loaderWithMessage;
  });

  const loaderWithMessageSurgeons = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loaderWithMessageSurgeons;
  });

  const loaderWithMessageVideos = useSelector((state: AppState) => {
    return state.hospitalHomeReducerData?.loaderWithMessageVideos;
  });

  const allHospitalAdmins = useSelector((state: AppState) => {
    return state?.hospitalHomeReducerData?.allHospitalAdmins;
  });

  const paginationCount =
    allVideosForPrimarySurgeons?.count > 12
      ? true
      : allVideos?.count > 12
      ? true
      : false;

  useEffect(() => {
    downloadTemplate();
    // getAllSurgeonsOnDebounce();
    dispatch(getHospitalInfo());
    dispatch(getAllHospitalAdmins(1));
  }, []);

  // useEffect(() => {
  //   dispatch(getAllVideos());
  // }, [lastHeader]);

  const getAllSurgeonsOnDebounce = useCallback(
    debounce((search?, page?, sort?) => {
      dispatch(getAllSurgeons(search, page, sort));
    }, 1000),
    []
  );

  const videosByYou = (allVideos.results || []).filter(
    (v: any) => v.created_by === userId
  );

  const videosBySurgeon = (allVideos.results || []).filter(
    (v: any) => v.created_by !== userId
  );

  // useEffect(() => {
  //   setSearchValue("");
  //   lastHeader?.props.headerText === "All Surgeons" &&
  //     allSurgeons?.results <= 0 &&
  //     getAllSurgeonsOnDebounce("", currentPage, sortSurgeonValue);
  //   lastHeader?.props.headerText === "All Videos" &&
  //     dispatch(getAllVideos("", videoCurrentPage, sortVideoValue));
  // }, [lastHeader?.props.headerText]);
  // useEffect(() => {
  //setSearchValue("");
  // lastHeader?.props.headerText === "All Surgeons" &&
  //   lastHeader?.props.headerText === "All Surgeons" &&
  //   allSurgeons?.results <= 0 &&
  //   allSurgeons?.results <= 0 &&
  //   getAllSurgeonsOnDebounce("", currentPage, sortSurgeonValue);
  // getAllSurgeonsOnDebounce("", currentPage, sortSurgeonValue);
  // lastHeader?.props.headerText === "All Videos" &&
  //   dispatch(getAllVideos("", videoCurrentPage, sortVideoValue));
  // lastHeader?.props.headerText === "All Videos" &&
  //   dispatch(getAllVideos("", videoCurrentPage, sortVideoValue));
  // }, [lastHeader?.props.headerText]);

  useEffect(() => {
    if (
      (lastHeader?.props.headerText === "All Surgeons" ||
        typeof lastHeader?.props.headerText === "undefined") &&
      (searchValue?.length > 3 || searchValue?.length === 0)
    ) {
      getAllSurgeonsOnDebounce(searchValue, currentPage, sortSurgeonValue);
    } else if (
      lastHeader?.props.headerText === "All Videos" &&
      (searchValue?.length > 3 || searchValue?.length === 0)
    ) {
      // dispatch(getAllVideos(searchValue, videoCurrentPage, sortVideoValue));
    }
  }, [searchValue]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu

      if (sortOptions && ref.current && !ref.current.contains(e.target)) {
        setSortOptions(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      // debugger;
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [sortOptions]);

  const {
    facility_surgeons = 0,
    main_branch_surgeons = 0,
    total_surgeons = 0,
    total_videos = 0,
  } = hospitalInfo || {};

  const getTwoDigitnumber = (val: number) => (val < 10 ? `0${val}` : val);

  const onPageChange = (pageNo: number) => {
    setCurrentPage(pageNo);
    dispatch(getAllSurgeons(searchValue, pageNo, sortSurgeonValue));
  };

  const onHospitalAdminPageChange = (pageNo: number) => {
    setCurrentPage(pageNo);
    dispatch(getAllHospitalAdmins(pageNo));
  };

  // const onVideoPageChange = useCallback(
  //   (pageNo) => {
  //     setVideoCurrentPage(pageNo);
  //     dispatch(getAllVideos(searchValue, pageNo, sortVideoValue));
  //   },
  //   [sortVideoValue, searchValue]
  // );
  const onVideoPageChange = useCallback(
    (
      pageNo,
      searchValue?: any,
      sortVideoValue?: any,
      allVideos?,
      allVideosForPrimarySurgeons?
    ) => {
      setVideoCurrentPage(pageNo);
      const searchText = searchValue ? searchValue : "";
      const sortValue = sortVideoValue ? sortVideoValue : "";
      const allVideosPage = allVideos?.next ? true : false;
      const allVideosSurgeonPage = allVideosForPrimarySurgeons?.next
        ? true
        : false;
      if (allVideos?.next && allVideosForPrimarySurgeons?.next) {
        dispatch(
          getAllVideos(
            searchText,
            pageNo,
            sortValue,
            allVideosPage,
            allVideosSurgeonPage
          )
        );
      } else {
        dispatch(getAllVideos(searchText, pageNo, sortValue));
      }
    },
    [sortVideoValue]
  );
  // React.useEffect(() => {
  //   if (
  //     !isInitialLanding &&
  //     (searchValue.length >= 3 || searchValue.length == 0)
  //   ) {
  //     const getSearchData = setTimeout(() => {
  //       dispatch(getAllVideos(searchValue, 1, sortVideoValue));

  //       setSearchValue(searchValue);

  //       setVideoCurrentPage(1);
  //     }, 2000);

  //     return () => clearTimeout(getSearchData);
  //   }
  // }, [searchValue]);
  const getSelectedTab = (props?: any) => {
    setSearchValue("");
    setLastHeader(props);
    if (props?.props?.headerText === "All Videos") {
      localStorage.setItem("selectedActiveTab", "allVideos");
      onVideoPageChange(1, searchValue, sortVideoValue);
    } else if (props?.props?.headerText === "All Surgeons") {
      localStorage.setItem("selectedActiveTab", "allSurgeons");
      setSearchValue("");
      setSortVideoValue("-created_date");
      setVideoCurrentPage(1);
    } else if (props?.props?.headerText === "All Admins") {
      localStorage.setItem("selectedActiveTab", "allAdmins");
      dispatch(getAllHospitalAdmins(1));
    }
    setSortVideoValue("-created_date");
    setSearchValue("");
    const getSelectedIndex = props?.key?.split(".")[1];
    setSelectedTabKey(Number(getSelectedIndex));
  };
  useEffect(() => {
    const isCurrentTab = localStorage.getItem("selectedActiveTab");
    const currentVideoPage = localStorage.getItem("currentVideoPage") || 1;
    const searchValue: any = localStorage.getItem("searchValue") || "";
    const sortVideoValue: any =
      localStorage.getItem("sortVideoValue") || "-created_date";
    if (isCurrentTab === "allAdmins") {
      setSelectedTabKey(0);
    }
    if (isCurrentTab === "allSurgeons") {
      setSelectedTabKey(1);
      setLastHeader({ props: { headerText: "All Surgeons" } });
    }
    if (isCurrentTab === "allVideos") {
      setSelectedTabKey(2);
      setSearchValue(searchValue);
      setSortVideoValue(sortVideoValue);
      setVideoCurrentPage(Number(currentVideoPage));
      setLastHeader({ props: { headerText: "All Videos" } });
      onVideoPageChange(currentVideoPage, searchValue, sortVideoValue);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("currentVideoPage", String(videoCurrentPage));
    localStorage.setItem("sortVideoValue", String(sortVideoValue));
    localStorage.setItem("searchValue", String(searchValue));
  }, [videoCurrentPage, sortVideoValue, searchValue]);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        mainInnerRef?.current &&
        !mainInnerRef?.current?.contains(e?.target)
      ) {
        localStorage.removeItem("selectedActiveTab");
        localStorage.removeItem("currentVideoPage");
        localStorage.removeItem("searchValue");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);
  const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
  function handleDebounceFn(inputValue: any) {
    setVideoCurrentPage(1);
    dispatch(dispatch(getAllVideos(inputValue, 1, sortVideoValue)));
  }
  const handleChange = (event: any) => {
    debounceFn(event);
  };
  const handleUpload = (event: React.MouseEvent<HTMLAnchorElement>): any => {
    event.preventDefault();
    dispatch(setLoginAlertFalse());

    setModalOpen(true);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    localStorage.removeItem("selectedActiveTab");
  };

  const hideModal = (ev?: React.MouseEvent<HTMLButtonElement>): any => {
    dispatch({
      type: GET_SURGEON_BY_ID,
      payload: { data: null },
    });
    setShowRole(false);
    setIsEdit(false);
    setModalOpen(false);
  };

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          dispatch(bulkOnboardSurgeon(results.data));
        },
      });
    }
  };

  const handleSortOptions = () => {
    setSortOptions((prevValue) => !prevValue);
  };

  const handleSortSurgeonOptions = () => {
    setSortSurgeonOptions(!sortSurgeonOptions);
  };

  return (
    <div ref={mainInnerRef}>
      <Stack verticalFill tokens={mainStackTokens} className="homeMain">
        {/* Top card section */}
        <StackItem className="ms-Grid" dir="ltr" styles={topMainStackItem}>
          <Stack
            horizontal
            tokens={topContainerStackTokens}
            className="homeTopCardSection ms-Grid-row"
          >
            {" "}
            {/* <StackItem
            className=" ms-Grid-col ms-lg5"
            style={{
              position: "relative",
              top: "-59px",
            }}
          >
            <img
              src={vector1}
              style={{
                position: "relative",
                width: "434px",
                top: "99px",
                left: "opx",
              }}
            /> */}
            <StackItem
              verticalFill
              grow={5}
              styles={topStackItemCard1Styles}
              className="firstGrid ms-Grid "
              dir="ltr"
            >
              {/* <Image
              className="onBoard"
              src={onBoardSurgeon}
              alt="create hospital"
              onClick={showModal}
            /> */}
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ padding: 12 }}
                styles={{
                  root: {
                    width: "100%",
                    backgroundImage: `url(${vector1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "bottom right",
                  },
                }}
                className="ms-Grid-row"
              >
                <CheckPermission {...constants.SCOPES.CREATE_SURGEON}>
                  <StackItem
                    className="onboardButton ms-Grid-col ms-lg5"
                    onClick={showModal}
                    styles={{
                      root: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000000",
                      },
                    }}
                  >
                    <FontIcon
                      style={{ color: "#000000", marginRight: 8 }}
                      iconName="Add"
                      aria-label="onboard surgeon"
                    />{" "}
                    <Text style={{ color: "#000000", marginRight: 8 }}>
                      Onboard Surgeon
                    </Text>
                  </StackItem>
                </CheckPermission>
                <StackItem
                  styles={{
                    root: {
                      borderRadius: "50%",
                      background: "rgba(32, 58, 72, 1)",
                      height: 25,
                      width: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 4,
                    },
                  }}
                  className="ms-Grid-col ms-lg1"
                >
                  or
                </StackItem>
                <StackItem className="ms-Grid-col ms-lg6">
                  <Stack verticalFill verticalAlign="space-between">
                    <StackItem className="text1">
                      Bulk Surgeons Onboarding
                    </StackItem>
                    <StackItem className="text2 ">
                      Download Template from here and Add your Data to the
                      Template file{" "}
                      <CSVDownloader
                        type={Type.Link}
                        filename={"bulk_surgeon"}
                        bom={true}
                        data={templateData}
                      >
                        <Link>Download</Link>
                      </CSVDownloader>
                    </StackItem>
                    <CheckPermission {...constants.SCOPES.BULK_IMPORT_SURGEONS}>
                      <StackItem
                        className="text3"
                        styles={{
                          root: {
                            paddingTop: 6,
                          },
                        }}
                        onClick={() =>
                          // bulkUpload.current && bulkUpload.current.click()
                          setShowBulkUpload(true)
                        }
                      >
                        {/* <input
                      type="file"
                      accept=".csv,.xlsx,.xls"
                      style={{ display: "none" }}
                      ref={bulkUpload}
                      onChange={handleFileChange}
                    /> */}
                        <Text
                          className="text3"
                          styles={{
                            root: {
                              paddingTop: 6,
                            },
                          }}
                        >
                          Upload Filled-in Template here
                        </Text>
                      </StackItem>
                    </CheckPermission>
                  </Stack>
                </StackItem>
              </Stack>
            </StackItem>
            {(showRole || isEdit) && (
              <CreateSurgeon
                isModalOpen={showRole}
                isEdit={isEdit}
                hideModal={hideModal}
                currentPage={currentPage}
              />
            )}
            <BulkSurgeonUpload
              isModalOpen={showBulkUpload}
              hideModal={() => setShowBulkUpload(false)}
            />
            {/* </StackItem> */}
            <CheckPermission {...constants.SCOPES.VIDEO_UPLOAD}>
              <StackItem
                verticalFill
                className="ms-Grid-col ms-lg2"
                styles={topStackItemCard2Styles}
                onClick={(evt: React.MouseEvent<HTMLAnchorElement>) =>
                  handleUpload(evt)
                }
              >
                <Link style={{ color: "#BFBFBF", textDecoration: "none" }}>
                  {" "}
                  <Stack style={{ flexFlow: "row nowrap" }}>
                    <Image
                      src={upload_icon}
                      style={{ height: 30, width: 30 }}
                    />
                    <Stack style={{ marginTop: "3px", marginLeft: "5px" }}>
                      Upload Video{" "}
                    </Stack>
                  </Stack>
                </Link>
                {/* <img
              src={vector2}
              style={{ position: "absolute", left: "0px", top: "23px" }}
            /> */}
              </StackItem>
            </CheckPermission>
            <UploadVideoModel
              isModalOpen={modalOpen}
              hideModal={hideUploadModal}
            ></UploadVideoModel>
            <StackItem
              verticalFill
              grow={1.5}
              styles={topStackItemCard3Styles}
              className="thirdGrid thirdGridWidth ms-Grid-col ms-lg3"
            >
              <Stack
                className="ms-Grid-row"
                grow
                tokens={{ childrenGap: 8, padding: 12 }}
              >
                <StackItem align="start" className="ms-Grid-row">
                  Total No.of Surgeon Onboarded
                </StackItem>
                <StackItem className="ms-Grid-row">
                  <Stack horizontal horizontalAlign="space-between">
                    <StackItem className="ms-lg6">
                      <Text className="textData">
                        {getTwoDigitnumber(total_surgeons)}
                      </Text>
                    </StackItem>
                    <StackItem className="ms-lg6">
                      <Stack
                        styles={{
                          root: {
                            alignItems: "end",
                          },
                        }}
                      >
                        <StackItem>
                          Main Hospital -{" "}
                          {getTwoDigitnumber(main_branch_surgeons)}
                        </StackItem>
                        <StackItem>
                          Other Facilities -{" "}
                          {getTwoDigitnumber(facility_surgeons)}
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem
              verticalFill
              grow
              styles={topStackItemCard4Styles}
              className="thirdGrid ms-Grid-col ms-lg2"
            >
              <Stack>
                <StackItem>Total No.of Videos</StackItem>
                <StackItem>
                  <Text className="textData">
                    {getTwoDigitnumber(total_videos)}
                  </Text>
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        {/* Bottom card section */}
        <StackItem styles={bottomMainStackItem}>
          <Stack
            horizontal
            tokens={bottomContainerStackTokens}
            className="homeBottomCardSection"
          >
            <Pivot
              className="width-100-percentage"
              onLinkClick={(props: any = lastHeader) => {
                getSelectedTab(props);
              }}
              selectedKey={String(selectedTabKey)}
            >
              <PivotItem headerText="All Admins">
                <Stack
                  tokens={{ padding: 10, childrenGap: 10 }}
                  wrap
                  styles={{ root: { width: "100%", cursor: "pointer" } }}
                  className="surgeonCardWrapper textNowrap"
                  horizontal
                >
                  {allHospitalAdmins &&
                    allHospitalAdmins?.results?.map(
                      (admin: any, index: any) => {
                        return (
                          <HospitalAdminCard
                            {...admin}
                            key={index}
                          ></HospitalAdminCard>
                        );
                      }
                    )}
                  {!loaderWithMessage?.loadingState &&
                    allHospitalAdmins &&
                    allHospitalAdmins?.results?.length === 0 && (
                      <div className="no-data-img">
                        <img
                          style={{ height: "320px", width: "82vw" }}
                          src={no_data}
                        />
                        {!loaderWithMessage?.loadingState &&
                          allHospitalAdmins &&
                          allHospitalAdmins?.results?.length === 0 &&
                          "No Admins available "}
                      </div>
                    )}
                </Stack>
                <Stack.Item style={{ width: "80vw" }}>
                  {allHospitalAdmins &&
                  allHospitalAdmins?.count > 12 &&
                  allHospitalAdmins?.page_size ? (
                    <Paging
                      currentPage={currentPage}
                      totalPageCount={Math.ceil(allHospitalAdmins?.count / 12)}
                      handlePageChange={onHospitalAdminPageChange}
                    />
                  ) : null}
                </Stack.Item>
              </PivotItem>
              <PivotItem headerText="All Surgeons">
                <Stack
                  tokens={{ padding: 10, childrenGap: 10 }}
                  wrap
                  styles={{ root: { width: "100%", cursor: "pointer" } }}
                  className="surgeonCardWrapper textNowrap"
                  horizontal
                >
                  {allSurgeons?.results?.map((surgeon: SurgeonCardProps) => (
                    <SurgeonCard
                      key={surgeon?.userId}
                      {...surgeon}
                      currentPage={currentPage}
                      searchValue={searchValue}
                      sortSurgeonValue={sortSurgeonValue}
                      setShowRole={setShowRole}
                      setIsEdit={setIsEdit}
                    />
                  ))}
                  {!loaderWithMessageSurgeons?.loadingState &&
                    allSurgeons?.results?.length === 0 && (
                      <div className="no-data-img">
                        <img
                          style={{ height: "320px", width: "82vw" }}
                          src={no_surgeon}
                        />
                        {/* {!loadingState &&
                    allSurgeons?.results?.length === 0 &&
                    "Surgeon profiles are not yet added"} */}
                        {/* {videosByYou?.length === 0 && "Videos are not available "} */}
                      </div>
                    )}
                </Stack>
                <Stack.Item style={{ width: "80vw" }}>
                  {allSurgeons?.count > 12 && allSurgeons?.page_size ? (
                    <Paging
                      currentPage={currentPage}
                      totalPageCount={Math.ceil(allSurgeons?.count / 12)}
                      handlePageChange={onPageChange}
                    />
                  ) : null}
                </Stack.Item>
              </PivotItem>
              <PivotItem headerText="All Videos">
                {allVideosForPrimarySurgeons?.results?.length ? (
                  <div style={{ marginLeft: "32px", marginTop: "16px" }}>
                    <text>Videos Uploaded by You!</text>
                  </div>
                ) : null}
                <Stack
                  horizontal
                  wrap
                  verticalFill
                  tokens={{ childrenGap: 10, padding: 10 }}
                  className="allVideoStackContainer"
                >
                  {allVideosForPrimarySurgeons?.results?.length &&
                    allVideosForPrimarySurgeons?.results?.map(
                      (video: any, index: any) => {
                        return (
                          <StackItem
                            key={index}
                            className="allVideosVideoCard all-videos-card-width"
                          >
                            <Videos
                              searchValue={searchValue}
                              videoCurrentPage={
                                allVideosForPrimarySurgeons?.results?.length >
                                  0 || allVideos?.results?.length > 1
                                  ? videoCurrentPage
                                  : videoCurrentPage > 1
                                  ? videoCurrentPage - 1
                                  : videoCurrentPage
                              }
                              sortVideoValue={sortVideoValue}
                              video={video}
                              key={index}
                              isMenuEnabled={true}
                            ></Videos>
                          </StackItem>
                        );
                      }
                    )}
                </Stack>
                {allVideos?.results?.length ? (
                  <text style={{ marginLeft: "32px" }}>
                    Videos Uploaded by Surgeons
                  </text>
                ) : null}
                <Stack
                  horizontal
                  wrap
                  verticalFill
                  tokens={{ childrenGap: 10, padding: 10 }}
                  className="allVideoStackContainer"
                >
                  {allVideos?.results?.length &&
                    allVideos?.results?.map((video: any, index: any) => {
                      return (
                        <StackItem
                          key={index}
                          className="allVideosVideoCard all-videos-card-width"
                        >
                          <Videos
                            searchValue={searchValue}
                            videoCurrentPage={
                              allVideosForPrimarySurgeons?.results?.length >
                                0 || allVideos?.results?.length > 1
                                ? videoCurrentPage
                                : videoCurrentPage > 1
                                ? videoCurrentPage - 1
                                : videoCurrentPage
                            }
                            sortVideoValue={sortVideoValue}
                            video={video}
                            key={index}
                            isMenuEnabled={false}
                          ></Videos>
                        </StackItem>
                      );
                    })}
                  {!loaderWithMessageVideos?.loadingState &&
                    videosByYou?.length === 0 &&
                    allVideosForPrimarySurgeons?.results?.length === 0 &&
                    videosBySurgeon?.length === 0 && (
                      <div className="no-data-img">
                        <img
                          style={{ height: "320px", width: "82vw" }}
                          src={no_data}
                        />
                        {!loaderWithMessageVideos?.loadingState &&
                          allVideosForPrimarySurgeons?.results?.length === 0 &&
                          "Videos are not available "}
                        {/* {videosByYou?.length === 0 && "Videos are not available "} */}
                      </div>
                    )}{" "}
                </Stack>

                {/* {paginationCount > 12 && allVideos?.page_size ? ( */}
                {paginationCount ? (
                  <Stack.Item style={{ width: "80vw" }}>
                    <Paging
                      currentPage={videoCurrentPage}
                      totalPageCount={
                        allVideosForPrimarySurgeons?.count > 12
                          ? Math.ceil(allVideosForPrimarySurgeons?.count / 12)
                          : Math.ceil(allVideos?.count / 12)
                      }
                      handlePageChange={(selectedItem: any) => {
                        onVideoPageChange(
                          selectedItem,
                          searchValue,
                          sortVideoValue
                        );
                      }}
                    />
                  </Stack.Item>
                ) : null}
              </PivotItem>
            </Pivot>
            <Stack
              horizontal
              tokens={{ childrenGap: 5 }}
              className="filterOptions"
            >
              {lastHeader && lastHeader?.props.headerText !== "All Admins" && (
                <StackItem className="searchBoxSection">
                  <SearchBox
                    className="searchBox"
                    placeholder="Search"
                    underlined={true}
                    value={searchValue}
                    onChange={(_, newValue: any) => {
                      setshowPage(false);
                      setSearchValue(newValue);
                      setCurrentPage(1);
                      handleChange(newValue);
                      setCurrentPage(1);
                    }}
                  />
                </StackItem>
              )}
              {lastHeader?.props.headerText !== "All Admins" &&
                lastHeader?.props.headerText === "All Videos" && (
                  <div ref={ref}>
                    <Stack.Item className="allSectionButtons">
                      <Stack styles={{ root: { height: 32 } }}>
                        <ActionButton
                          className="sortAction"
                          onClick={handleSortOptions}
                          allowDisabledFocus
                          id="sortButton"
                          styles={{
                            root: { height: 32 },
                            flexContainer: {
                              justifyContent: "space-between",
                              width: 100,
                            },
                          }}
                        >
                          <Text className="sortDropdownHeading">Sort</Text>
                          {sortOptions ? (
                            <Icon
                              iconName="ChevronUp"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          ) : (
                            <Icon
                              iconName="ChevronDown"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          )}
                        </ActionButton>
                      </Stack>

                      {sortOptions && (
                        <Stack
                          className="sortOptionsDropdown sortOptionsUserDropdown"
                          styles={{ root: { bacground: "#121212" } }}
                        >
                          <Stack.Item className="sortOptionsDropdownHeading">
                            Sort By
                          </Stack.Item>
                          <ChoiceGroup
                            defaultSelectedKey={sortVideoValue}
                            options={options}
                            onChange={(
                              evt: any,
                              option?: IChoiceGroupOption
                            ) => {
                              const value: any = option;
                              setSortVideoValue(value.key);
                              setVideoCurrentPage(1);
                              dispatch(getAllVideos(searchValue, 1, value.key));
                            }}
                          />
                        </Stack>
                      )}
                    </Stack.Item>
                  </div>
                )}

              {lastHeader &&
                lastHeader?.props.headerText !== "All Admins" &&
                lastHeader?.props.headerText !== "All Videos" && (
                  <div ref={ref}>
                    <Stack.Item className="allSectionButtons">
                      <Stack styles={{ root: { height: 32 } }}>
                        <ActionButton
                          className="sortAction"
                          onClick={handleSortSurgeonOptions}
                          allowDisabledFocus
                          id="sortButton"
                          styles={{
                            root: { height: 32 },
                            flexContainer: {
                              justifyContent: "space-between",
                              width: 100,
                            },
                          }}
                        >
                          <Text className="sortDropdownHeading">Sort</Text>
                          {sortSurgeonOptions ? (
                            <Icon
                              iconName="ChevronUp"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          ) : (
                            <Icon
                              iconName="ChevronDown"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          )}
                        </ActionButton>
                      </Stack>

                      {sortSurgeonOptions && (
                        <Stack
                          className="sortOptionsDropdown sortOptionsUserDropdown"
                          styles={{ root: { bacground: "#121212" } }}
                        >
                          <Stack.Item className="sortOptionsDropdownHeading">
                            Sort By
                          </Stack.Item>
                          <ChoiceGroup
                            defaultSelectedKey={sortSurgeonValue}
                            // selectedKey={selectedKey}
                            options={surgeonOptions}
                            // onChange={onChangeSortBy}
                            onChange={(
                              evt: any,
                              option?: IChoiceGroupOption
                            ) => {
                              // handleChange(evt, data, option, "procedureName");
                              setCurrentPage(1);
                              const value: any = option;
                              setSortSurgeonValue(value.key);
                              dispatch(
                                getAllSurgeons(searchValue, 1, value.key)
                              );
                            }}
                          />
                        </Stack>
                      )}
                    </Stack.Item>
                  </div>
                )}
            </Stack>
          </Stack>
        </StackItem>
        {lastHeader?.props.headerText === "All Surgeons" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageSurgeons}
          ></LoaderWithMessage>
        )}
        {lastHeader?.props.headerText === "All Videos" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageVideos}
          ></LoaderWithMessage>
        )}
        {lastHeader?.props.headerText === undefined && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageSurgeons}
          ></LoaderWithMessage>
        )}

        <Modal isOpen={closeRoles} containerClassName={"modalStyle"}>
          <SuccessMessage
            title={isEdit ? "Surgeon Updated" : "Surgeon Creation"}
          />
        </Modal>

        <Modal isOpen={UserProfileSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage title="User Profile Updated" />
        </Modal>
        {UserProfileSpinner &&
          setTimeout(() => {
            dispatch(showSpinnerLayout());
          }, 2000) && <></>}
        {useEffect(() => {
          onPageChange(currentPage);
        }, [closeRoles])}

        <Modal isOpen={ChangePasswordSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage
            title="Password Changed"
            subText="To Continue Please Login Again..."
          />
        </Modal>
        {ChangePasswordSpinner &&
          setTimeout(() => {
            dispatch(showChangePassword());
          }, 2000) && <></>}

        {closeRoles &&
          setTimeout(() => {
            setShowRole(false);
            dispatch(showSurgeonSpinner());
          }, 1000) && <></>}
        {useEffect(() => {
          onPageChange(currentPage);
        }, [closeRoles])}
        {closeRoles &&
          setTimeout(() => {
            setIsEdit(false);
          }, 2000) && <></>}
      </Stack>
    </div>
  );
};

export default Home;
