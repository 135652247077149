import axios from "axios";
import { apiEndPoint, doHttpCall } from "../../common/restApi";
import * as allActions from "./actionConstants";

export const getUserProfile = () => async (dispatch: any) => {
  try {
    const payload = {
      method: "GET",
      url: apiEndPoint + "/user/profile",
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      localStorage.setItem("userProfileId", response.data.userId);
      dispatch({
        type: allActions.GET_LOGIN_USER_PROFILE,
        payload: response.data,
      });
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const updatePhoto =
  (extension = "jpg", dataURL: any) =>
  async (dispatch: any) => {
    try {
      const payload = {
        method: "POST",
        url: apiEndPoint + "/user/profile/photo",
        data: { extension },
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        const blob = await (await fetch(dataURL)).blob();
        const config = {
          headers: {
            "Content-Type": "image/jpeg",
            "x-ms-blob-type": "BlockBlob",
          },
        };
        await axios.put(response.data.url, blob, config);
        dispatch({
          type: allActions.EDIT_USER_PROFILE,
          payload: {},
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const editUser =
  (userId: any, userData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: `${apiEndPoint}/user/profile`,
      data: userData,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_USER_PROFILE,
          payload: { data: userData },
        });
        dispatch(getUserProfile());
      }
      if (response && (response.status === 404 || response.status === 400)) {
        const errMsg = response?.data?.phone_number
          ? response.data.phone_number.message
          : "Error while updating";
        dispatch({
          type: allActions.EDIT_USER_ERROR,
          payload: {
            data: response.data?.permissions
              ? response.data?.permissions[0]
              : errMsg.replace(/_/g, " "),
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const createUserProfile =
  (userDetails: any) => async (dispatch: any) => {
    try {
      const payload = {
        method: "POST",
        url: apiEndPoint + "/user/profile",
        data: userDetails,
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        localStorage.setItem("userProfileId", response.data.userId);
        dispatch({
          type: allActions.GET_LOGIN_USER_PROFILE,
          payload: response.data,
        });
        return response.data;
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getUserDetails = () => async (dispatch: any) => {
  if (
    localStorage.getItem("role") === "Surgeon" ||
    localStorage.getItem("role") === "HospitalAdmin"
  ) {
    try {
      const payload = {
        method: "GET",
        url: apiEndPoint + "/user/list?page=1&page_size=500&photo=true",
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_USER_DETAILS,
          payload: response.data,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const getProcedureDetails =
  (nextURL = "") =>
  async (dispatch: any) => {
    try {
      const payload = {
        method: "GET",
        url: nextURL ? nextURL : apiEndPoint + "/procedures/?page_size=1000",
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_PROCEDURE_DETAILS,
          payload: response.data,
          isNext: !!nextURL,
        });
        dispatch({
          type: allActions.GET_PROCEDURE_DETAILS_VIDEO_UPLOAD,
          payload: response.data,
          isNext: !!nextURL,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
export const getProcedureDetailsForVideos =
  (nextURL = "") =>
  async (dispatch: any) => {
    try {
      const payload = {
        method: "GET",
        url: nextURL ? nextURL : apiEndPoint + "/procedures/",
      };
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_PROCEDURE_DETAILS_VIDEO_UPLOAD,
          payload: response.data,
          isNext: !!nextURL,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getVideoDetailsById = (id: any) => async (dispatch: any) => {};

export const getOfflineNotifications = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/notify/pusher/",
  };

  try {
    const response = await doHttpCall(payload);
    if (response && response.status === 200 && response.data.length) {
      const notificationData = response.data;
      dispatch({
        type: allActions.GET_OFFLINE_NOTIFICATIONS,
        payload: notificationData,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const getNotificationsFromPusher =
  (notificationData: any) => (dispatch: any) => {
    dispatch({
      type: allActions.GET_NOTIFICATIONS_FROM_PUSHER,
      payload: notificationData,
    });
  };

export const clearAllNotifications =
  (notificationType: string) => (dispatch: any) => {
    dispatch({
      type: allActions.CLEAR_ALL_NOTIFICATIONS,
      payload: notificationType,
    });
  };

export const acceptNotification =
  (videoId: any) => async (dispatch: any, getState: any) => {
    const data = {
      video_id: videoId,
      shared_with: getState().dashboardReducerData.loginUserProfile.userId,
      accepted: true,
    };
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/share/accept",
      data: data,
    };
    try {
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        dispatch({
          type: allActions.ACCEPT_NOTIFICATION,
          payload: videoId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const approveTaggedVideo =
  (videoId: number) => async (dispatch: any, getState: any) => {
    const userProfle = getState().dashboardReducerData.loginUserProfile;
    const data = {
      surgeon_id: userProfle.id || userProfle.userId,
      video_id: videoId,
      approved: true,
    };
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/approval/accept",
      data: data,
    };
    try {
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        dispatch({
          type: allActions.ACCEPT_NOTIFICATION,
          payload: videoId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const rejectTaggedVideo =
  (videoId: number) => async (dispatch: any, getState: any) => {
    const userProfle = getState().dashboardReducerData.loginUserProfile;
    const data = {
      surgeon_id: userProfle.id || userProfle.userId,
      video_id: videoId,
      approved: false,
    };
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/approval/accept",
      data: data,
    };
    try {
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        dispatch({
          type: allActions.ACCEPT_NOTIFICATION,
          payload: videoId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const rejectNotification =
  (videoId: any) => async (dispatch: any, getState: any) => {
    const data = {
      video_id: videoId,
      shared_with: getState().dashboardReducerData.loginUserProfile.userId,
      accepted: false,
    };
    const payload = {
      method: "POST",
      url: apiEndPoint + "/videos/share/accept",
      data: data,
    };
    try {
      const response = await doHttpCall(payload);
      if (response.status === 200) {
        dispatch({
          type: allActions.ACCEPT_NOTIFICATION,
          payload: videoId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const logoutFromCurrentUser = () => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/auth/logout",
  };
  try {
    const response = await doHttpCall(payload);
    if (response.status === 200) {
      dispatch({ type: allActions.CLEAR_LOGOUT });
      dispatch({ type: "LOGOUT" });
      localStorage.clear();
      // window.location.reload()
      // let url = window.location.hostname + '/login';
      // window.location.href = url
    } else {
      const remmberme = localStorage.getItem("isremember") === "true";
      const role: any = localStorage.getItem("role");
      const isSwitchRole = localStorage.getItem("switch_account") === "true";
      localStorage.clear();
      localStorage.setItem("isremember", JSON.stringify(remmberme));
      localStorage.setItem("role", role);
      localStorage.setItem("dualRole", JSON.stringify(isSwitchRole));
      window.location.href = "/login";
      dispatch({ type: "LOGOUT" });
    }
  } catch (e) {
    console.error(e);
  }
};

export const clearDashboardDataOnLogout = () => (dispatch: any) => {
  dispatch({ type: allActions.CLEAR_DASHBOARD_DATA_ON_LOGOUT });
};

export const showSpinnerLayout = () => {
  return {
    type: allActions.SPINNER_STOP_LAYOUT,
  };
};
