import {
  FontIcon,
  IButtonStyles,
  Image,
  IStackItemStyles,
  IStackProps,
  ITextStyles,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import React from "react";
import activEdgeLogo from "../../assets/activeEdge_logo.svg";
import { fonts, ThemeColorPalette } from "../../theme";
import AuthContainerComponent from "../containers/authContainer/authContainer";
import HomeLeftScreen from "./HomeLeftScreen";

export interface ResetSuccessProps {
  valid: string;
}

export interface ResetSuccessState {
  loginTrigger: boolean;
}

const rightColumnProps: Partial<IStackProps> = {
  tokens: { padding: "0% 15%", childrenGap: 10 },
};

const stackItemRightStyles: IStackItemStyles = {
  root: {
    background: ThemeColorPalette.white,
    flex: 1,
  },
};

const headerTextStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    fontFamily: fonts.DINPro_Bold,
  },
};

const footerStyles: Partial<ITextStyles> = {
  root: {
    color: ThemeColorPalette.neutralPrimary,
    opacity: "0.5",
  },
};

const messageText: Partial<ITextStyles> = {
  root: {
    fontFamily: fonts.HelveticaNeue_Medium,
    color: ThemeColorPalette.neutralPrimary,
    letterSpacing: "0.3",
  },
};

const buttonStyles: Partial<IButtonStyles> = {
  root: {
    width: "100%",
    fontFamily: fonts.HelveticaNeue_Bold,
  },
  rootHovered: { backgroundColor: ThemeColorPalette.themePrimary },
};

const containerStyles: React.CSSProperties = {
  minHeight: "100vh",
  maxHeight: "100%",
};

class ResetSuccess extends React.Component<
  ResetSuccessProps,
  ResetSuccessState
> {
  state = {
    loginTrigger: true,
  };
  render() {
    const { loginTrigger } = this.state;
    const { valid } = this.props;

    return loginTrigger ? (
      <Stack horizontal style={containerStyles}>
        <HomeLeftScreen />
        <StackItem styles={stackItemRightStyles}>
          <Stack verticalFill {...rightColumnProps} verticalAlign="start">
            <StackItem tokens={{ padding: "40% 0% 10% 0%" }}>
              <Image
                src={activEdgeLogo}
                alt="Activ Edge logo"
                width={121}
                height={50}
              />
            </StackItem>
            <StackItem>
              <Text styles={headerTextStyles} variant="xLarge" nowrap block>
                Reset Password
              </Text>
            </StackItem>
            <Stack
              horizontal
              tokens={{ padding: "8% 0% 4%" }}
              horizontalAlign="start"
            >
              <StackItem align="center">
                <FontIcon
                  iconName="Completed"
                  style={{
                    fontSize: "1.8rem",
                    paddingRight: "0.5rem",
                    color: "green",
                  }}
                />
              </StackItem>
              <StackItem align="center">
                <Text styles={messageText} variant="medium">
                  {valid}
                </Text>
              </StackItem>
            </Stack>

            <StackItem tokens={{ padding: "3% 0% 0% 0%" }}>
              <PrimaryButton
                type="submit"
                styles={buttonStyles}
                text="Login"
                onClick={() => {
                  window.location.href = "/";
                  this.setState({ loginTrigger: false });
                }}
              />
            </StackItem>
            <Stack verticalFill verticalAlign="end" horizontalAlign="center">
              <StackItem tokens={{ padding: "0 0 4% 0" }}>
                <Text styles={footerStyles} variant="xSmall" block nowrap>
                  Copyright © 2020 Activ Surgical - All Rights Reserved.
                </Text>
              </StackItem>
            </Stack>
          </Stack>
        </StackItem>
      </Stack>
    ) : (
      <AuthContainerComponent />
    );
  }
}

export default ResetSuccess;
