import {
  DefaultButton,
  ILabelStyles,
  Label,
  Panel,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
  Text,
  Image,
  Modal,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../rootReducer";
import { ThemeColorPalette } from "../../theme";
import {
  editUser,
  getUserProfile,
  showSpinnerLayout,
  updatePhoto,
} from "../actions/layoutActions";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import userImage from "../../assets/Home/userProfile_1.png";
import ShowErrorMessageBar from "../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import { RESET_USER_ERROR } from "../actions/actionConstants";
import ImageUploading from "react-images-uploading";
import SuccessMessage from "../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import CheckPermission from "../../common/permissions/rolePermissions";
import * as constants from "../constants/constants";

const UserProfile = ({
  isPanelOpen,
  dismiss,
}: {
  isPanelOpen: any;
  dismiss: any;
}) => {
  const phoneValidationRegex = RegExp(
    /^(\+?\d{0,5})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  );
  const emailValidationRegex = RegExp(
    /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  );
  const lastNameRegex = RegExp(/^\S*$/i);
  const formAdminSchema = {
    first_name: Yup.string().required("Name is required"),
    // .matches(lastNameRegex, "name cannot have space")
    permissions: Yup.string().required("Role is required"),
    mail: Yup.string()
      .required("Mail is required")
      .matches(emailValidationRegex, "Invalid email"),
    // last_name: Yup.string().required("Last name is required"),
    last_name: Yup.string().required("Last Name is required"),
    // .matches(lastNameRegex, "last name cannot have space"),
    phone_number: Yup.string()
      .min(6, "Invalid phone number")
      .max(15, "Invalid phone number"),
    // .matches(phoneValidationRegex, "Invalid phone number"),
  };
  const validationSchema = Yup.object().shape({
    user: Yup.object().shape(formAdminSchema),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    clearErrors,
    reset,
    setError,
    trigger,
    formState: { errors, isSubmitSuccessful },
  } = useForm(formOptions);
  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = React.useState([]);
  const [disabledCreate, setDisabledCreate] = React.useState(false);
  const [globalImageTypeExt, setGlobalImageTypeExt] = React.useState();
  const [globalImageList, setGlobalImageList] = React.useState<any>();
  const [photeprofilePermission, setPhoteprofilePermission] =
    React.useState<any>(false);
  const labelStyles: Partial<ILabelStyles> = {
    root: {
      color: ThemeColorPalette.changePasswordLabelTxt,
      opacity: 0.7,
    },
  };
  const loginUserProfile = useSelector((state: AppState) => {
    return state.dashboardReducerData.loginUserProfile;
  });
  const UserProfileSpinner = useSelector((state: AppState) => {
    return state.dashboardReducerData.UserProfileSpinner;
  });

  const profileUpdateError = useSelector((state: AppState) => {
    return state.dashboardReducerData.profileUpdateError;
  });

  const clearProfileEditError = () => {
    dispatch({
      type: RESET_USER_ERROR,
    });
  };

  useEffect(() => {
    setTimeout(clearProfileEditError, 2000);
  }, [profileUpdateError]);
  useEffect(() => {
    setValue("user.first_name", loginUserProfile?.first_name || "");
    setValue("user.last_name", loginUserProfile?.last_name || "");
    setValue(
      "user.permissions",
      loginUserProfile.permissions_name?.join() || ""
    );
    setValue("user.mail", loginUserProfile?.mail || "");
    setValue("user.phone_number", loginUserProfile?.phone_number || "");
  }, [setValue, loginUserProfile]);

  useEffect(() => {
    const permission = constants.getPermissionsbyItem(
      "management.profile.edit"
    );
    setPhoteprofilePermission(!permission);
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [isSubmitSuccessful]);

  useEffect(() => {
    const values = getValues();
    if (!isPanelOpen) {
      for (const prop in values?.user) {
        delete values?.user[prop];
      }
      dispatch(getUserProfile());
    }
  }, [isPanelOpen]);

  useEffect(() => {
    setDisabledCreate(false);
    return () => {
      for (const prop in errors?.user) {
        delete errors?.user[prop];
      }
    };
  });

  const onProfileChange = (imageList: any, addUpdateIndex: any) => {
    setProfileImage(imageList);
    const imageType = imageList[0]?.file?.type || "/png";
    const imageTypeExtIndex = imageType.lastIndexOf("/");
    const imageTypeExt = imageType.substring(
      imageTypeExtIndex + 1,
      imageType.length
    );
    setGlobalImageTypeExt(imageTypeExt);
    setGlobalImageList(imageList);
  };

  const role = localStorage.getItem("role")
    ? localStorage.getItem("role")
    : loginUserProfile?.role_name;

  const onSubmit = (data: any, onProfileChange: any) => {
    setDisabledCreate(true);
    const removeUnusedKey = (obj: any, arr: any) =>
      Object.keys(obj)
        .filter((k) => !arr.includes(k))
        .reduce((acc: any, key) => ((acc[key] = obj[key]), acc), {});
    let userData = data?.user?.phone_number
      ? removeUnusedKey(data?.user, ["mail"])
      : removeUnusedKey(data?.user, ["phone_number", "mail"]);
    userData = {
      ...userData,
      permissions: loginUserProfile?.permissions_name,
    };
    dispatch(editUser("", userData));
    if (globalImageList) {
      dispatch(updatePhoto(globalImageTypeExt, globalImageList[0]?.data_url));
    }
  };
  return (
    <Panel
      isOpen={isPanelOpen}
      onDismiss={() => {
        setProfileImage(loginUserProfile?.photo);
        dismiss();
      }}
      className="userProfile"
      headerText="User Profile1"
      closeButtonAriaLabel="Close"
      isFooterAtBottom={true}
      styles={{
        footerInner: {
          padding: 0,
        },
      }}
      onRenderFooterContent={() => {
        return (
          <Stack
            horizontal
            horizontalAlign="start"
            tokens={{ childrenGap: 10 }}
            styles={{
              root: {
                borderTop: "1px solid " + ThemeColorPalette.panelBorder,
                textAlign: "right",
                paddingTop: "0.7rem",
                paddingBottom: "0.7rem",
                paddingLeft: "1.5rem",
              },
            }}
          >
            <CheckPermission {...constants.SCOPES.PHOTO_AND_PROFILE_EDIT}>
              <PrimaryButton
                type="submit"
                form="userProfile"
                className="btn-next"
                disabled={disabledCreate}
              >
                Update
                <Text className="spinnerRight">
                  {disabledCreate && <Spinner size={SpinnerSize.small} />}
                </Text>
              </PrimaryButton>
            </CheckPermission>
            <DefaultButton
              onClick={() => {
                setProfileImage(loginUserProfile?.photo);
                dismiss();
              }}
            >
              Cancel
            </DefaultButton>
          </Stack>
        );
      }}
      onRenderHeader={() => {
        return (
          <Stack horizontal horizontalAlign="start" verticalAlign="center">
            <Stack>
              <div className="imageEdit">
                <ImageUploading
                  value={profileImage}
                  onChange={onProfileChange}
                  dataURLKey="data_url"
                >
                  {({ imageList, onImageUpload }) => (
                    <CheckPermission {...constants.SCOPES.PHOTO_UPDATE}>
                      <div
                        onClick={onImageUpload}
                        style={{ cursor: "pointer" }}
                      >
                        <Image
                          src={
                            imageList[0]?.data_url ||
                            loginUserProfile?.photo ||
                            userImage
                          }
                          width="40px"
                          height="40px"
                          alt="user image"
                        ></Image>
                      </div>
                    </CheckPermission>
                  )}
                </ImageUploading>
              </div>
            </Stack>
            <Stack.Item>
              <Text
                block
                className="hospitalCardHeading textNoWrap userProfile"
              >
                <div
                  className="textNoWrap"
                  style={{
                    width: "200px",
                    textTransform: "none",
                  }}
                  title={loginUserProfile?.displayName}
                >
                  {loginUserProfile?.displayName}
                </div>
              </Text>
              <Stack className="userRole">{role}</Stack>
            </Stack.Item>
          </Stack>
        );
      }}
    >
      <Stack
        verticalFill
        verticalAlign="start"
        tokens={{ childrenGap: 15, padding: "1rem 0 0 0" }}
      >
        <form id="userProfile" onSubmit={handleSubmit(onSubmit)}>
          <StackItem>
            <Label htmlFor={"name"} styles={labelStyles}>
              First Name
            </Label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
              <input
                id="name"
                className="ms-TextField-field field-custom"
                type="text"
                placeholder="Enter here"
                {...register(`user.first_name`)}
                key={loginUserProfile?.first_name}
                // errorMessage={
                //   errors?.user ? errors?.user?.first_name?.message : ""
                // }
                disabled={photeprofilePermission}
                onKeyDown={(e: any) => {
                  const values = getValues();
                  values.user.first_name = e?.target?.value;
                }}
                onChange={(e: any) => {
                  setValue("user.first_name", e.target.value);
                  if (e?.target.value?.trim().length) {
                    //clearErrors('user.first_name');
                  }
                }}
              />
            </Stack>
            <Label className="errorMessage">
              {errors?.user?.first_name?.message || ""}
            </Label>
            <Label htmlFor={"name"} styles={labelStyles}>
              Last Name
            </Label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
              <input
                id="name"
                className="ms-TextField-field field-custom"
                type="text"
                placeholder="Enter here"
                {...register(`user.last_name`)}
                defaultValue={loginUserProfile?.last_name}
                // errorMessage={
                //   errors?.user ? errors?.user?.last_name?.message : ""
                // }
                disabled={photeprofilePermission}
                onKeyDown={(e: any) => {
                  const values = getValues();
                  values.user.last_name = e?.target?.value;
                }}
                onChange={(e: any) => {
                  setValue("user.last_name", e.target.value);
                  if (e?.target.value?.trim().length) {
                    //clearErrors('user.last_name')
                  }
                }}
              />
            </Stack>
            <Label className="errorMessage">
              {errors?.user?.last_name?.message || ""}
            </Label>

            <Label htmlFor={"role"} styles={labelStyles}>
              Roles
            </Label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
              <input
                id="role"
                className="ms-TextField-field field-custom"
                type="text"
                placeholder="Enter here"
                disabled
                defaultValue={loginUserProfile.permissions_name?.join()}
                {...register(`user.permissions`)}
                // errorMessage={
                //   errors?.user ? errors?.user?.permissions?.message : ""
                // }
                //   defaultValue={currentPassword}
                onChange={(e: any) =>
                  setValue("user.permissions", e.target.value)
                }
              />
            </Stack>
            <Label htmlFor={"email"} styles={labelStyles}>
              Email ID
            </Label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
              <input
                id="email"
                disabled
                className="ms-TextField-field field-custom"
                type="text"
                placeholder="Enter here"
                {...register(`user.mail`)}
                defaultValue={loginUserProfile?.mail}
                // errorMessage={errors?.user ? errors?.user?.mail?.message : ""}
                onChange={(e: any) => setValue("user.mail", e.target.value)}
              />
            </Stack>
            <Label htmlFor={"phone"} styles={labelStyles}>
              Phone number
            </Label>
            <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
              <input
                id="phone"
                className="ms-TextField-field field-custom"
                type="text"
                placeholder="Enter here"
                {...register(`user.phone_number`)}
                defaultValue={loginUserProfile?.phone_number}
                // errorMessage={
                //   errors?.user ? errors?.user?.phone_number?.message : ""
                // }
                disabled={photeprofilePermission}
                onKeyPress={(event) => {
                  if (!/[0-9\d\-]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e: any) => {
                  setValue("user.phone_number", e.target.value);
                  if (e?.target.value?.trim().length) {
                    //clearErrors(`user.phone_number`);
                  }
                }}
              />
            </Stack>
            <Label className="errorMessage">
              {errors?.user ? errors?.user?.phone_number?.message : ""}
            </Label>
          </StackItem>
          {profileUpdateError && (
            <Label className="errorMessage">{profileUpdateError}</Label>
          )}
        </form>
      </Stack>
      <Modal isOpen={UserProfileSpinner} containerClassName={"modalStyle"}>
        <SuccessMessage title="User Profile Updated" />
      </Modal>
      {UserProfileSpinner &&
        setTimeout(() => {
          dispatch(showSpinnerLayout());
          dismiss();
          reset();
        }, 2000) && <></>}
    </Panel>
  );
};
export default React.memo(UserProfile);
