import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const history = useNavigate();
    const location = useLocation();
    const params = useParams();
    return (
      <Component
        history={history}
        location={location}
        params={params}
        {...props}
      />
    );
  };

  return Wrapper;
};
