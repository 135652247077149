export interface ProcessEnv {
  [key: string]: string | undefined;
}

// const API_URL =
//   process.env.REACT_APP_ACTIV_END_POINT ||
//   "https://qaapi.activeservicedemo.com/api/v1";

// const PUSHER_KEY =
//   process.env.REACT_APP_ACTIV_PUSHER_APP_KEY || "6b546575815143f87dab";

const API_URL =
  process.env.REACT_APP_ACTIV_END_POINT || "https://api.activedge.io/api/v1";

const PUSHER_KEY =
  process.env.REACT_APP_ACTIV_PUSHER_APP_KEY || "6adc26064c1b0c3921fd";

export { API_URL, PUSHER_KEY };
