import React, { useEffect, useState } from "react";
import { Stack, Text, IStackItemStyles, SearchBox } from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from "react-simple-maps";
import { geoCentroid } from "d3-geo";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Label,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getLogHospitals, getServiceLogs } from "../../actions/logsActions";
import moment from "moment";
import FilterDropdown from "../Common/FilterDropdown";
import { AppState } from "../../../../rootReducer";
import MapChart from "../Common/MapChart";
import ReactTooltip from "react-tooltip";
import no_data from "./../../../../assets/no_data.svg";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const COLORS = [
  "#01B1FF",
  "#8AC8FE",
  "#CCE7FF",
  "#FDB913",
  "#4EAE4D",
  "#A8E7D0",
  "#33E1DB",
  "#7E7E81",
  "#EB4747",
  "#F198F6",
  "#A020F0",
];

const markers = [
  {
    markerOffset: -30,
    name: "",
    coordinates: [-100.0, 31.0],
  },
  {
    markerOffset: 15,
    name: "",
    coordinates: [-100.0, 31.0],
  },
];
let tooltip: any;
const CustomTooltip = ({ active, payload }: any) => {
  if (!active || !tooltip) return null;
  for (const bar of payload)
    if (bar.dataKey === tooltip)
      return (
        <div
          style={{
            border: "1px solid",
            borderColor: "gray",
            background: "#000",
            padding: 5,
            width: 150,
          }}
        >
          {" "}
          {bar?.payload?.name} : {bar?.payload?.hours}
        </div>
      );
  return null;
};

const ServiceDashboard = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

  const [dateDefault, setDateDefault] = useState<Array<string>>([]);
  const [content, setContent] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [mapPointerSearch, setMapPointerSearch] = useState<Array<string>>([]);
  const [room, setRoom] = useState("");

  const dispatch = useDispatch();

  const serviceLogs = useSelector((state: AppState) => {
    return state.logsReducerData?.serviceLogs?.data;
  });

  const initialServiceLogs = useSelector((state: AppState) => {
    return state.logsReducerData?.initialServiceLogs;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.logsReducerData?.loaderWithMessageService;
  });

  const roomsCheck = (val: any) => {
    //setDateDefault([startDate, endDate]);
    setRoom(val);
  };
  const dateCheck = (startDate: any, endDate: any) => {
    setDateDefault([startDate, endDate]);
    //  setRoom(val);
  };
  const deviceClickHandler = (
    hospital = "",
    facility = "",
    room = "",
    region = "",
    country = "",
    speciality = "",
    startDate = "",
    endDate = ""
  ) => {
    setMapPointerSearch([
      hospital,
      facility,
      room,
      region,
      country,
      speciality,
      startDate,
      endDate,
    ]);
  };

  const allProcedureDurationValues = [
    serviceLogs?.widgets?.procedureDuration?.setupTime,
    // ...salesLogs?.procedureDuration?.overlay
  ];

  // console.log(serviceLogs, allProcedureDurationValues, "serviceLogs");

  Object.entries(
    serviceLogs?.widgets?.procedureDuration
      ? serviceLogs?.widgets?.procedureDuration
      : {}
  ).forEach(([key, value]: [key: any, value: any]) => {
    if (key === "overlay" || key == "callibrations") {
      allProcedureDurationValues.push(...value);
    }
  });

  const toFixed2Digits = (val: any) => {
    return val ? Math.round((val + Number.EPSILON) * 100) / 100 : "";
  };

  const checkErrors = () => {
    if (
      !serviceLogs?.widgets?.softwareVersion &&
      !(serviceLogs?.widgets?.totalErrors > 0) &&
      !serviceLogs?.widgets?.lightGuideON &&
      !(serviceLogs?.widgets?.totalLasersON > 0) &&
      !(serviceLogs?.widgets?.lightGudiePluggedIn > 0) &&
      !(
        serviceLogs?.widgets?.timesOverlayUsed &&
        serviceLogs?.widgets?.overlayDuration
      )
    ) {
      return false;
    }
    return true;
  };
  const checknoData = () => {
    if (
      !serviceLogs?.widgets?.totalDuration &&
      !serviceLogs?.widgets?.overlayTotalDuration &&
      !(room && (ErrorsLG?.length > 0 || ErrorsIM?.length > 0)) &&
      !serviceLogs?.widgets?.softwareVersion &&
      !(serviceLogs?.widgets?.totalErrors > 0) &&
      !serviceLogs?.widgets?.lightGuideON &&
      !(serviceLogs?.widgets?.totalLasersON > 0) &&
      !(serviceLogs?.widgets?.lightGudiePluggedIn > 0) &&
      !(
        serviceLogs?.widgets?.timesOverlayUsed &&
        serviceLogs?.widgets?.overlayDuration
      )
    ) {
      return true;
    }
    return false;
  };

  const overlay = serviceLogs?.widgets?.procedureDuration?.overlay?.map(
    (overlay: any) => ({
      name: overlay.name,
      Min: toFixed2Digits(overlay.value),
    })
  );

  const ErrorsLG = serviceLogs?.widgets?.errors?.LG?.map((error: any) => ({
    name: error.name,
    hours: toFixed2Digits(error.value),
  }));

  const ErrorsIM = serviceLogs?.widgets?.errors?.IM?.map((error: any) => ({
    name: error.name,
    hours: toFixed2Digits(error.value),
  }));

  // console.log(dateDefault, "demoRoomdemoRoomdemoRoom");

  useEffect(() => {
    // dispatch(getLogHospitals());
    // if (mapPointerSearch?.length) {
    //   dispatch(
    //     getServiceLogs(
    //       dateDefault[0] ? dateDefault[0] : mapPointerSearch[6],
    //       dateDefault[1] ? dateDefault[1] : mapPointerSearch[7],
    //       mapPointerSearch[0],
    //       mapPointerSearch[1],
    //       mapPointerSearch[2],
    //       mapPointerSearch[4],
    //       mapPointerSearch[3],
    //       mapPointerSearch[5],
    //       deviceId,
    //       !initialServiceLogs?.locations?.length
    //     )
    //   );
    // }
  }, [dateDefault, mapPointerSearch]);

  return (
    <Stack className="serviceDashboard">
      <Stack.Item className="totalSection ms-Grid" dir="ltr">
        <Stack.Item className="chartSection ms-Grid-row">
          {/* ---------------map section------------------- */}
          <Stack.Item className=" ms-Grid-col ms-lg4">
            <SearchBox
              placeholder="Search"
              onSearch={(newValue) => newValue}
              value={deviceId}
              onChange={(_, newValue: any) => {
                // (newValue?.length > 3 || newValue?.length === 0) &&
                //   dispatch(
                //     getServiceLogs(
                //       dateDefault[0] ? dateDefault[0] : mapPointerSearch[6],
                //       dateDefault[1] ? dateDefault[1] : mapPointerSearch[7],
                //       mapPointerSearch[0],
                //       mapPointerSearch[1],
                //       mapPointerSearch[2],
                //       mapPointerSearch[4],
                //       mapPointerSearch[3],
                //       mapPointerSearch[5],
                //       newValue
                //     )
                //   );
                setDeviceId(newValue);
              }}
            />
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg4">
            <Text>Total Devices {serviceLogs?.widgets?.deviceCount}</Text>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
      {/* ---------------filter section------------------- */}
      <FilterDropdown
        action={getServiceLogs}
        logs={initialServiceLogs}
        roomsCheck={roomsCheck}
        deviceId={deviceId}
        selectFilters={mapPointerSearch}
        setMapPointerSearch={setMapPointerSearch}
        filteredLogs={serviceLogs}
        dateCheck={dateCheck}
        loaderWithMessageService={loaderWithMessage}
      />

      <Stack.Item className="ms-Grid" dir="ltr">
        <Stack.Item className="chartSection ms-Grid-row">
          {/* ---------------map section------------------- */}
          <Stack.Item className=" ms-Grid-col ms-lg6">
            {loaderWithMessage?.statusCode != 403 && (
              <Stack.Item className="map start bgColor">
                <MapChart
                  logs={serviceLogs}
                  setTooltipContent={setContent}
                  initialData={initialServiceLogs}
                  deviceSearch=""
                  deviceClickHandler={deviceClickHandler}
                />
                {content.length > 0 && (
                  <ReactTooltip multiline={true}>
                    <div className="chartToolTip">
                      <Text block>
                        Hospital:{" "}
                        <span className="toolTipContent">{content[1]}</span>
                      </Text>
                      <Text block>
                        Facility:{" "}
                        <span className="toolTipContent">{content[2]}</span>
                      </Text>
                      <Text block>
                        Region:{" "}
                        <span className="toolTipContent">{content[0]}</span>
                      </Text>
                      <Text block>
                        Room:{" "}
                        <span className="toolTipContent">{content[4]}</span>
                      </Text>
                      <Text block>
                        Device:{" "}
                        <span className="toolTipContent">{content[3]}</span>
                      </Text>
                    </div>
                  </ReactTooltip>
                )}
              </Stack.Item>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg6">
            {/* ---------------Procedure and Overlay section------------------- */}
            <Stack.Item className="ms-Grid-row">
              <Stack.Item className=" ms-Grid-col ms-lg6">
                {loaderWithMessage?.statusCode != 403 &&
                serviceLogs?.widgets?.totalDuration ? (
                  <Stack.Item className="procedureDuration bgColor">
                    <Text className="heading">
                      Average Procedure Duration (Min)
                    </Text>
                    <Stack.Item className="ms-Grid-row flex-center inheritHeight">
                      <Stack.Item className=" ms-Grid-col ms-lg4">
                        {allProcedureDurationValues.map((entry, index) => (
                          <Stack
                            className="colorCircleMain"
                            horizontal
                            dir="ltr"
                            horizontalAlign="center"
                            verticalAlign="center"
                            key={index}
                          >
                            <Stack.Item
                              className="colorCircle flex-center"
                              style={{
                                backgroundColor: COLORS[index % COLORS.length],
                              }}
                            >
                              {" "}
                            </Stack.Item>
                            <Text className="text">{entry?.name}</Text>
                          </Stack>
                        ))}
                      </Stack.Item>
                      <Stack.Item className=" ms-Grid-col ms-lg8">
                        <PieChart width={166} height={166}>
                          <Pie
                            stroke="#101112"
                            data={allProcedureDurationValues}
                            innerRadius={50}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            <Label
                              // value="Procedure Duration"
                              position="centerBottom"
                              fill="#7E7E81"
                              className="centerLabel"
                              fontSize="10px"
                            />

                            <Label
                              value={`${serviceLogs?.widgets?.totalDuration?.toFixed(
                                2
                              )} Min`}
                              position="centerTop"
                              fill="#7E7E81"
                              className="centerLabel"
                              fontSize="14px"
                            />
                            {allProcedureDurationValues.map((entry, index) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                ) : (
                  <Text> </Text>
                )}
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg6">
                {loaderWithMessage?.statusCode != 403 &&
                serviceLogs?.widgets?.overlayTotalDuration ? (
                  <Stack.Item className="overlayMode bgColor">
                    <Text className="heading">
                      Average Overlay Mode Usage (Min)
                    </Text>
                    <ResponsiveContainer
                      width="100%"
                      height="80%"
                      className="placement"
                    >
                      <BarChart
                        layout="vertical"
                        width={350}
                        height={300}
                        data={overlay}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={25}
                      >
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" scale="band" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Min" barSize={20} fill="#D9EDFF" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Stack.Item>
                ) : (
                  <Text> </Text>
                )}
              </Stack.Item>
            </Stack.Item>

            {/* ---------------Device Health  section------------------- */}
            {room && (
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className=" ms-Grid-col ms-lg12">
                  {ErrorsLG?.length > 0 || ErrorsIM?.length > 0 ? (
                    <Stack.Item className="workflow bgColor">
                      <Stack.Item>
                        <Text className="heading">Device Health</Text>
                      </Stack.Item>

                      {ErrorsLG.length > 0 ? (
                        <Stack.Item className="height-150 ms-Grid-col ms-lg6 text-center">
                          <Text className="placement" variant="small">
                            Errors in Light Guide (LG)
                          </Text>
                          <ResponsiveContainer width="100%" height="80%">
                            <BarChart
                              layout="vertical"
                              width={500}
                              height={300}
                              data={ErrorsLG}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              barSize={50}
                            >
                              <XAxis
                                type="number"
                                tickCount={3}
                                allowDecimals={false}
                              />
                              <YAxis
                                dataKey="name"
                                type="category"
                                scale="band"
                              />
                              <Tooltip content={<CustomTooltip />} />

                              <Legend />
                              <Bar
                                dataKey="hours"
                                barSize={20}
                                fill="#D77879"
                                onMouseOver={() => (tooltip = "hours")}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </Stack.Item>
                      ) : (
                        ""
                      )}

                      {ErrorsIM.length > 0 ? (
                        <Stack.Item className="height-150 ms-Grid-col ms-lg6 text-center">
                          <Text className="placement" variant="small">
                            Errors in Image Module (IM)
                          </Text>
                          <ResponsiveContainer width="100%" height="80%">
                            <BarChart
                              layout="vertical"
                              width={500}
                              height={300}
                              data={ErrorsIM}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                              barSize={25}
                            >
                              <XAxis
                                type="number"
                                tickCount={3}
                                allowDecimals={false}
                              />
                              <YAxis
                                dataKey="name"
                                type="category"
                                scale="band"
                              />
                              <Tooltip content={<CustomTooltip />} />
                              <Legend />
                              <Bar
                                dataKey="hours"
                                barSize={20}
                                fill="#D77879"
                                onMouseOver={() => (tooltip = "hours")}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </Stack.Item>
                      ) : (
                        ""
                      )}
                    </Stack.Item>
                  ) : (
                    ""
                  )}
                </Stack.Item>
              </Stack.Item>
            )}
          </Stack.Item>
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessage}
          ></LoaderWithMessage>

          {loaderWithMessage?.statusCode != 403 && checknoData() && (
            <Stack.Item className=" ms-Grid-col ms-lg6">
              <div className="no-data-img">
                <img
                  style={{ height: "320px", width: "280px" }}
                  src={no_data}
                />
                <Text className="no-data-img">No Data Available.</Text>
              </div>
            </Stack.Item>
          )}
        </Stack.Item>
      </Stack.Item>

      {/* ---------------bottom  section------------------- */}
      <Stack.Item className="ms-Grid bottomSection" dir="ltr">
        <Stack.Item className=" ms-Grid-row">
          <Stack.Item className=" ms-Grid-col ms-lg2">
            {loaderWithMessage?.statusCode != 403 &&
            serviceLogs?.widgets?.softwareVersion ? (
              <Stack.Item className="btmHeight bgColor start">
                <Text className="heading">Software Revision</Text>
                <Stack
                  horizontal
                  verticalAlign="center"
                  className="inheritHeight"
                >
                  <Stack.Item>
                    <Text className="number" block variant="small">
                      v {serviceLogs?.widgets?.softwareVersion}
                    </Text>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg2">
            {loaderWithMessage?.statusCode != 403 && checkErrors() ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">Total Errors (LG + IM)</Text>
                <Stack
                  horizontal
                  verticalAlign="center"
                  className="inheritHeight"
                >
                  <Stack.Item className=" ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg6">
                      <Text className="number" variant="small">
                        {toFixed2Digits(serviceLogs?.widgets?.totalErrors) || 0}
                      </Text>
                    </Stack.Item>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg2">
            {loaderWithMessage?.statusCode != 403 &&
            serviceLogs?.widgets?.lightGuideON ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">Light Guide ON (Hours)</Text>
                <Stack
                  horizontal
                  verticalAlign="center"
                  className="inheritHeight"
                >
                  <Stack.Item className=" ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg6">
                      <Text className="number" variant="small">
                        {toFixed2Digits(serviceLogs?.widgets?.lightGuideON)}
                      </Text>
                    </Stack.Item>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg2">
            {loaderWithMessage?.statusCode != 403 &&
            serviceLogs?.widgets?.totalLasersON > 0 ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">Total Lasers ON (Hours)</Text>
                <Stack
                  horizontal
                  verticalAlign="center"
                  className="inheritHeight"
                >
                  <Stack.Item className=" ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg6">
                      <Text className="number" variant="small">
                        {toFixed2Digits(serviceLogs?.widgets?.totalLasersON)}
                      </Text>
                    </Stack.Item>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg2">
            {loaderWithMessage?.statusCode != 403 &&
            serviceLogs?.widgets?.lightGudiePluggedIn > 0 ? (
              <Stack.Item className="btmHeight bgColor">
                <Text className="heading">Light Guide Plugged-in (Hours)</Text>

                {serviceLogs?.widgets?.lightGudiePluggedIn >= 0 ? (
                  <Stack horizontal verticalAlign="center">
                    <Stack.Item className=" ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg6">
                        <Text className="number" variant="small">
                          {serviceLogs?.widgets?.lightGudiePluggedIn?.toFixed(
                            2
                          )}
                        </Text>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack>
                ) : (
                  <Text> </Text>
                )}

                <Stack.Item className="mt-15">
                  {serviceLogs?.widgets?.imageModulePluggedIn >= 0 ? (
                    <>
                      <Text className="heading">
                        Image Module Plugged-in (Hours)
                      </Text>
                      <Stack horizontal verticalAlign="center">
                        <Stack.Item className=" ms-Grid-row">
                          <Stack.Item className="ms-Grid-col ms-lg6">
                            <Text className="number" variant="small">
                              {serviceLogs?.widgets?.imageModulePluggedIn?.toFixed(
                                2
                              )}
                            </Text>
                          </Stack.Item>
                        </Stack.Item>
                      </Stack>
                    </>
                  ) : (
                    <Text> </Text>
                  )}
                </Stack.Item>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg2">
            {loaderWithMessage?.statusCode != 403 &&
            serviceLogs?.widgets?.timesOverlayUsed &&
            serviceLogs?.widgets?.overlayDuration ? (
              <Stack.Item className="btmHeight bgColor">
                {serviceLogs?.widgets?.timesOverlayUsed ? (
                  <>
                    <Text className="heading">Times Overlay Used / Case</Text>
                    <Stack horizontal verticalAlign="center">
                      <Stack.Item className=" ms-Grid-row ">
                        <Stack.Item className="ms-Grid-col ms-lg6">
                          <Text className="number " variant="small">
                            {serviceLogs?.widgets?.timesOverlayUsed?.toFixed(2)}
                          </Text>
                        </Stack.Item>
                      </Stack.Item>
                    </Stack>
                  </>
                ) : (
                  <Text> </Text>
                )}

                <Stack.Item className=" mt-15">
                  {loaderWithMessage?.statusCode != 403 &&
                  serviceLogs?.widgets?.overlayDuration ? (
                    <>
                      <Text className="heading ">
                        Overlay Duration / Case (Mins)
                      </Text>
                      <Stack horizontal verticalAlign="center">
                        <Stack.Item className=" ms-Grid-row">
                          <Stack.Item className="ms-Grid-col ms-lg6">
                            <Text className="number" variant="small">
                              {serviceLogs?.widgets?.overlayDuration?.toFixed(
                                2
                              )}
                            </Text>
                          </Stack.Item>
                        </Stack.Item>
                      </Stack>
                    </>
                  ) : (
                    <Text> </Text>
                  )}
                </Stack.Item>
              </Stack.Item>
            ) : (
              <Text> </Text>
            )}
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};
export default ServiceDashboard;
