import { Text } from "@fluentui/react";
import React from "react";
import { convertDurationOfVideoToSec } from "../../common/util";
import { fonts, ThemeColorPalette } from "../../theme";
import "./SurgeryBreakUpChart.scss";

export interface SurgeryBreakUpChartProps {
  videoPhases: any;
  onSurgeryBarClick: any;
  totalPhasesTime: any;
  phasesLegendData: any;
}

export interface SurgeryBreakUpChartState {
  currentHoverItem: any;
}

class SurgeryBreakUpChart extends React.Component<
  SurgeryBreakUpChartProps,
  SurgeryBreakUpChartState
> {
  state = {
    currentHoverItem: null,
  };

  render() {
    return (
      this.props.videoPhases &&
      this.props.videoPhases.length && (
        <div className="container1">
          {/* <div className="surgery_break_up_bar">
            {this.props.videoPhases.map((data: any, i: number) =>
              Object.keys(data).map((val: any) => {
                return (
                  <div
                    key={i}
                    className="surgery_break_up_bar_styles"
                    style={{
                      height: "0.7rem",
                      backgroundColor: data[val].color,
                      display: "flex",
                      justifyContent: "center",
                      marginRight: "1.5px",
                      cursor: "pointer",
                      padding: "0.5rem 0 0.5rem 0",
                      opacity: this.state.currentHoverItem === i ? "0.5" : "1",
                      width:
                        Math.round(
                          (convertDurationOfVideoToSec(data[val].duration) /
                            this.props.totalPhasesTime) *
                            100
                        ) + "%",
                    }}
                    onMouseOver={() => {
                      this.setState({ ...this.state, currentHoverItem: i });
                    }}
                    onMouseOut={() =>
                      this.setState({
                        ...this.state,
                        currentHoverItem: null,
                      })
                    }
                    onClick={() => this.props.onSurgeryBarClick(data[val].from)}
                  ></div>
                );
              })
            )}
          </div> */}
          {/* <div className="surgery_break_up_legend">
            {Object.keys(this.props.phasesLegendData)
              .sort(
                (a: any, b: any) =>
                  +this.props.phasesLegendData[a].totalTime.replace(/:/g, "") -
                  +this.props.phasesLegendData[b].totalTime.replace(/:/g, "")
              )
              .map((val: any, i: number) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      cursor: "pointer",
                      paddingRight: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: "1rem",
                        width: "1rem",
                        margin: "0rem 0.5rem 0 0",
                        backgroundColor: this.props.phasesLegendData[val].color,
                        borderRadius: "15%",
                      }}
                    ></div>
                    <div
                      style={{ display: "flex", flexDirection: "column" }}
                      onClick={() =>
                        this.props.onSurgeryBarClick(
                          this.props.phasesLegendData[val].totalTime
                        )
                      }
                    >
                      <Text
                        variant="smallPlus"
                        styles={{
                          root: { fontFamily: fonts.HelveticaNeue_Light },
                        }}
                      >
                        {this.props.phasesLegendData[val].name}
                      </Text>
                      <Text
                        variant="tiny"
                        styles={{
                          root: {
                            paddingTop: "0.2rem",
                            color: ThemeColorPalette.uploadVideoBtnTxt,
                            fontFamily: fonts.HelveticaNeue_Light,
                          },
                        }}
                      >
                        {this.props.phasesLegendData &&
                          this.props.phasesLegendData[val].totalTime}
                      </Text>
                    </div>
                  </div>
                );
              })}
          </div> */}

          <div className="surgery_break_up_legend">
            {this.props.phasesLegendData.map((data: any, i: number) => {
              const val = Object.keys(data)[0];
              // Object.keys(data)
              //   .map((val: any, i: number) => {
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "center",
                    cursor: "pointer",
                    paddingRight: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      height: "1rem",
                      width: "1rem",
                      margin: "0rem 0.5rem 0 0",
                      backgroundColor: data[val].color,
                      borderRadius: "15%",
                    }}
                  ></div>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    onClick={() =>
                      this.props.onSurgeryBarClick(data[val].totalTime)
                    }
                  >
                    <Text
                      variant="smallPlus"
                      styles={{
                        root: { fontFamily: fonts.HelveticaNeue_Light },
                      }}
                    >
                      {data[val].name}
                    </Text>
                    <Text
                      variant="tiny"
                      styles={{
                        root: {
                          paddingTop: "0.2rem",
                          color: ThemeColorPalette.uploadVideoBtnTxt,
                          fontFamily: fonts.HelveticaNeue_Light,
                        },
                      }}
                    >
                      {data && data[val].totalTime}
                    </Text>
                  </div>
                </div>
              );
              // })
            })}
          </div>
        </div>
      )
    );
  }
}

export default SurgeryBreakUpChart;
