export const GET_HOSPITAL_INFO = "GET_HOSPITAL_INFO";
export const GET_ALL_SURGEONS = "GET_ALL_SURGEONS";
export const GET_SURGEONS_FOR_DROPDOWN = "GET_SURGEONS_FOR_DROPDOWN";
export const GET_ALL_VIDEOS = "GET_ALL_VIDEOS";
export const GET_ALL_VIDEOS_BY_PRIMARY_SURGEON =
  "GET_ALL_VIDEOS_BY_PRIMARY_SURGEON";
export const ONBOARD_FACILITIES = "ONBOARD_FACILITIES";
export const ONBOARD_SPECIALITY = "ONBOARD_SPECIALITY";
export const ONBOARD_PERMISSIONS = "ONBOARD_PERMISSIONS";
export const CREATE_SURGEON = "CREATE_SURGEON";
export const CREATE_SURGEON_ERROR = "CREATE_SURGEON_ERROR";
export const SURGEON_SPINNER = "SURGEON_SPINNER";
export const DELETE_SURGEON = "DELETE_SURGEON";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const BULK_ONBOARD_SURGEON = "BULK_ONBOARD_SURGEON";
export const ERROR_IN_BULK_ONBOARD = "ERROR_IN_BULK_ONBOARD";
export const GET_SURGEON_BY_ID = "GET_SURGEON_BY_ID";
export const GET_VIDEOS_BY_ID = "GET_VIDEOS_BY_ID";
export const DOWNLOAD_TEMPLATE = "DOWNLOAD_TEMPLATE";
export const LOADER = "LOADER";
export const CREATE_SURGEON_CLEAR_ERRORS = "CREATE_SURGEON_CLEAR_ERRORS";
export const STOPLOADER = "STOPLOADER";

export const GET_ALL_HOSPITAL_ADMINS = "GET_ALL_HOSPITAL_ADMINS";
export const GET_HOSPITAL_ADMIN_DETAILS_BY_USERID =
  "GET_HOSPITAL_ADMIN_DETAILS_BY_USERID";
