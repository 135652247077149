import axios from "axios";
import { API_URL } from "../common/configUtils";
// export const apiEndPoint = "https://api.activeservicedemo.com/api/v1";
// export const apiEndPoint = "https://devapi.activeservicedemo.com/api/v1";
// export const apiEndPoint = "https://uat.activeservicedemo.com/api/v1";
// export const apiEndPoint = "https://qaapi.activeservicedemo.com/api/v1";
// export const apiEndPoint = "https://tftk8qa.activeservicedemo.com/api/v1";
// export const apiEndPoint = "https://stagingapi.activeservicedemo.com/api/v1";

// export const apiEndPoint = "https://srinivas.activeservicedemo.com/api/v1";

export const apiEndPoint = API_URL;
// console.log(API_URL);
const setHeaders = (options) => {
  let accessToken = "";
  if (localStorage.getItem("token")) {
    accessToken = localStorage.getItem("token");
  }
  let AuthHeaders = {};
  if (accessToken) {
    AuthHeaders = {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    };
  } else {
    AuthHeaders = { "Content-Type": "application/json" };
  }
  options.headers = Object.assign({}, AuthHeaders, options.headers || {});
  return options;
};

export const doHttpCall = async (options) => {
  options = setHeaders(options);
  try {
    return await axios(options);
  } catch (e) {
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403) &&
      localStorage.getItem("token")
    ) {
      return await getNewRefreshToken(options);
    } else {
      return e.response;
    }
  }
};

const getNewRefreshToken = async (options) => {
  const refreshToken = localStorage.getItem("refreshToken");
  const payload = {
    method: "POST",
    url: apiEndPoint + "/auth/token/refresh",
    data: {
      refresh_token: refreshToken,
    },
  };
  let response = await doHttpCall(payload);
  if (response && response.status === 200) {
    localStorage.setItem(
      "loggedInProfile.access_token",
      JSON.stringify(response.data.access_token)
    );
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("refreshToken", response.data.refresh_token);
    delete options.headers;
    options = setHeaders(options);
    return await axios(options);
  } else {
    const remmberme = localStorage.getItem("isremember") === "true";
    const role = localStorage.getItem("role");
    const isSwitchRole = localStorage.getItem("switch_account") === "true";
    localStorage.clear();
    localStorage.setItem("isremember", JSON.stringify(remmberme));
    localStorage.setItem("role", role);
    localStorage.setItem("dualRole", JSON.stringify(isSwitchRole));
    window.location.href = "/login";
  }
};
