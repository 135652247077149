import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Devices/Devices.scss";
import {
  closeRoleModal,
  getRolesList,
  getRolesView,
  RolesPermissionDelete,
  showCreateRole,
} from "../../actions/managamentActions";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import CreateRole from "./CreateRole/CreateRole";
import moment from "moment";
import SuccessMessage from "../../../Home/components/Common/SuccessMessage";
import { showRoleSpinner } from "../../actions/managamentActions";
import DeleteModal from "../../../Home/components/Common/DeleteModal";
import Paging from "../../../../common/paging/Paging";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Role = () => {
  const [showRole, setShowRole] = useState(false);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [openDeleteRoleModal, setOpenDeleteRoleModal] = useState(false);
  const [currentRolePage, setCurrentRolePage] = useState<number>(1);

  const onVideoPageChange = useCallback((pageNo) => {
    setCurrentRolePage(pageNo);
    dispatch(getRolesList(pageNo));
  }, []);

  const closeRoles = useSelector((state: AppState) => {
    return state.managementReducerData.closeRoles;
  });
  const closeWhenErrors = useSelector((state: AppState) => {
    return state.managementReducerData.closeWhenErrors;
  });

  const showDeleteRoleModal = useCallback(() => {
    setOpenDeleteRoleModal((current) => !current);
    setDisabledCreate(false);
  }, []);
  const deleteRoleHandle = () => {
    setDisabledCreate(true);
    dispatch(RolesPermissionDelete(roleId));
    setOpenDeleteRoleModal((current) => !current);
  };
  const showModal = () => {
    setRoleId(null);
    setShowRole((current) => !current);
    if (showRole) {
      dispatch(closeRoleModal());
    }
  };

  const showEditModal = () => {
    dispatch(getRolesView(roleId));
    dispatch(showCreateRole());
    setShowRole((current) => !current);
    if (showRole) {
      dispatch(closeRoleModal());
    }
  };
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "edit",
        text: "Edit",
        onClick: constants.getPermissionsbyItem("management.role.edit")
          ? showEditModal
          : void 0,
        title: constants.getPermissionsbyItem("management.role.edit")
          ? ""
          : constants.permission,
      },
      {
        key: "delete",
        text: "Delete",
        onClick: constants.getPermissionsbyItem("management.role.delete")
          ? showDeleteRoleModal
          : void 0,
        title: constants.getPermissionsbyItem("management.role.delete")
          ? ""
          : constants.permission,
      },
    ],
  };
  const _onMenuClickSetId = (id: any) => {
    setRoleId(id);
  };

  const dispatch = useDispatch();

  const roles = useSelector((state: AppState) => {
    return state.managementReducerData.rolesList.data;
  });
  const deleteRolePersmission = useSelector((state: AppState) => {
    return state.managementReducerData.deleteRolePersmission.data;
  });

  const showCreateRoleSuccess = useSelector((state: AppState) => {
    return state.managementReducerData.showCreateRole;
  });

  useEffect(() => {
    dispatch(getRolesList(currentRolePage));
  }, [deleteRolePersmission]);
  useEffect(() => {
    if (closeRoles || closeWhenErrors) {
      dispatch(getRolesList(currentRolePage));
    }
  }, [closeRoles, closeWhenErrors]);

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.managementReducerData.loaderWithMessageRoles;
  });
  const [visibleRoleList, setVisibleRoleList] = useState(false);

  useEffect(() => {
    if (
      loaderWithMessage &&
      loaderWithMessage?.calledFrom === "GET_ROLES_LIST" &&
      loaderWithMessage?.statusCode !== 403 &&
      !loaderWithMessage?.loadingState
    ) {
      setVisibleRoleList(true);
    }
    if (
      loaderWithMessage?.calledFrom === "GET_ROLES_LIST" &&
      loaderWithMessage?.statusCode == 403
    ) {
      setVisibleRoleList(false);
    }
  }, [loaderWithMessage]);
  return (
    <Stack className="deviceManagement">
      <Stack.Item className="heading">
        <Text>User Roles & Permissions</Text>
      </Stack.Item>
      <Stack.Item className="addDevice">
        <CheckPermission {...constants.SCOPES.CREATE_ROLE}>
          <DefaultButton
            onClick={showModal}
            text="Create New Role"
            iconProps={addIcon}
            allowDisabledFocus
          />
        </CheckPermission>
      </Stack.Item>
      {showRole && (
        <CreateRole
          isModalOpen={showRole}
          showModal={() => showModal()}
          roleId={roleId}
        ></CreateRole>
      )}
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">Available Roles</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text>Name of the Role</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Role Location</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg3">
              <Text variant="smallPlus">Assigned Date</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col  ms-lg3">
              <Text variant="smallPlus">Assigned by</Text>
            </Stack.Item>
          </Stack.Item>
          {visibleRoleList &&
            roles &&
            roles?.results?.map((role: any) => (
              <Stack.Item key={role?.id} className="ms-Grid-row tableText">
                <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                  <Text block variant="smallPlus" title={role?.name}>
                    {role?.name}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg2">
                  <Text variant="smallPlus">{role?.role_location}</Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3">
                  <Text variant="smallPlus">
                    {moment(role?.created_date).format("MMM D YYYY HH:mm")}
                  </Text>
                </Stack.Item>
                <Stack.Item className="ms-Grid-col ms-lg3 textEllipses">
                  <Text block variant="smallPlus" title={role?.assigned_by}>
                    {role?.assigned_by}
                  </Text>
                </Stack.Item>

                <Stack.Item className="ms-Grid-col ms-lg1">
                  <Text variant="smallPlus">
                    <IconButton
                      className="buttonIcon"
                      menuIconProps={moreIcon}
                      menuProps={menuProps}
                      ariaLabel="New item"
                      onMenuClick={() => _onMenuClickSetId(role?.uuid)}
                    />
                  </Text>
                </Stack.Item>
              </Stack.Item>
            ))}
        </Stack.Item>
        {visibleRoleList && roles && roles?.count > 10 && roles?.page_size ? (
          <Paging
            currentPage={currentRolePage}
            totalPageCount={Math.ceil(roles?.count / 10)}
            handlePageChange={onVideoPageChange}
          />
        ) : null}
      </Stack>
      <Modal isOpen={closeRoles} containerClassName={"modalStyle"}>
        <SuccessMessage
          title={`Roles & Permissions ${!roleId ? "Creation" : "Updated"} `}
        />
      </Modal>
      {(closeRoles || closeWhenErrors) &&
        setTimeout(() => {
          setShowRole(false);
          // dispatch(getRolesList(currentRolePage));
          dispatch(showRoleSpinner());
        }, 1000) && <></>}
      <DeleteModal
        isModalOpen={openDeleteRoleModal}
        deleteHandle={deleteRoleHandle}
        showModal={showDeleteRoleModal}
        id={roleId}
        name="Role & Permission"
        disabledCreate={disabledCreate}
      ></DeleteModal>
      <LoaderWithMessage
        loaderWithMessage={loaderWithMessage}
      ></LoaderWithMessage>
    </Stack>
  );
};

export default Role;
