import { uuid } from "uuidv4";
import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getActivity =
  (
    currentPage = 1,
    activity_type_id = "",
    activity_by = "",
    activityDate = ""
  ) =>
  async (dispatch: any) => {
    const currentUserPage = `?page=${currentPage}&page_size=20`;
    const activityTypeSearch = activity_type_id
      ? `&activity_type=${activity_type_id}`
      : "";
    const activityBySearch = activity_by ? `&activity_by=${activity_by}` : "";
    const activitySearchFilter = activityDate
      ? `&from_date=${activityDate}`
      : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/activities/developer${currentUserPage}${activityTypeSearch}${activityBySearch}${activitySearchFilter}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ACTIVITY));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ACTIVITY,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ACTIVITY, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ACTIVITY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const resetActivity = () => (dispatch: any) => {
  dispatch({ type: allActions.RESET_ACTIVITY });
};

export const getActivityType =
  (count?: any, activity_type_name = "") =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : "";
    let defaultUrl =
      apiEndPoint + `/activities/activitylogtypes?page=1${count}`;
    if (activity_type_name) {
      defaultUrl = `${defaultUrl}&search=${activity_type_name}`;
    }
    const payload = {
      method: "GET",
      url: defaultUrl,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ACTIVITY_TYPES));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ACTIVITY_TYPES,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ACTIVITY_TYPES,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ACTIVITY_TYPES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getActivityBy =
  (count?: any, activity_by_name = "") =>
  async (dispatch: any) => {
    count = count ? `page_size=${count}` : "";
    let defaultUrl = apiEndPoint + `/user/developers/list?${count}`;
    if (activity_by_name) {
      defaultUrl = `${defaultUrl}search=${activity_by_name}${count}`;
    }
    const payload = {
      method: "GET",
      url: defaultUrl,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ACTIVITY_BY));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ACTIVITY_BY,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ACTIVITY_BY, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ACTIVITY_BY,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
