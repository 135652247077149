import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../rootReducer";
interface IExampleProps {
  resetChoice?: () => void;
  errorResponse: any;
}
const ShowErrorMessageBar = (p: IExampleProps) => {
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  return (
    <MessageBar
      delayedRender={false}
      isMultiline={false}
      messageBarType={MessageBarType.error}
      dismissButtonAriaLabel="Close"
      onDismiss={p.resetChoice}
      className="errorMessages"
    >
      {p?.errorResponse && p?.errorResponse?.data
        ? p?.errorResponse?.data?.details
          ? p?.errorResponse?.data?.details
          : p?.errorResponse?.data?.length
          ? p?.errorResponse?.data[0]?.details
          : ""
        : p?.errorResponse?.length
        ? p?.errorResponse[0]?.details
        : ""}
      {p?.errorResponse?.message !== "" ? p?.errorResponse?.message : ""}
    </MessageBar>
  );
};

export default ShowErrorMessageBar;
