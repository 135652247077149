import React from "react";
import "./App.scss";
import AppRoutes from "./routes";
import { initializeIcons } from "@uifabric/icons";
import MainWrapper from "./MainWrapper/MainWrapper";
import { ErrorBoundary } from "react-error-boundary";

initializeIcons();

const getUrl = window.location;
const baseUrl =
  getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split("/")[1];

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={() => (window.location.href = baseUrl)}>
        Try again
      </button>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <MainWrapper />
        <AppRoutes />
      </ErrorBoundary>
    </div>
  );
}

export default App;
