import React from "react";
import { Stack, TextField, Text, Label } from "@fluentui/react";
import ToggleCard from "../../../../../ActiveAdminModule/Home/components/Wizard/ToggleCard";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { roleIncrement } from "../../../../../ActiveAdminModule/Management/actions/managamentActions";
import Select from "react-select";
import { useState, useCallback, useEffect } from "react";
import { updateRolesPermissionActionData } from "../../../actions/managementAction";
import { AppState } from "../../../../../rootReducer";
import {
  CLEAR_PRIVILAGE_ERROR,
  GET_PRIVILEGE_BY_ID,
} from "../../../actions/actionConstants";
import Loader from "../../../../../ActiveAdminModule/Logs/components/Common/Loader";

const options = [
  { value: "CLIENT", label: "CLIENT" },
  { value: "ACTIVSURGICAL", label: "ACTIVSURGICAL" },
];

const PrivilegeCreation = ({
  isEdit,
  data,
  editMode,
}: {
  isEdit: any;
  data: any;
  editMode: any;
}) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: {},
  } = useForm();

  const [errors, setErrors] = useState<{
    name?: string;
  }>({});
  const [tempName, setTempName] = useState<string>("");
  const [isLoader, setIsLoader] = useState(true);

  const originalName = data.name;

  const [priviligeId, setPriviligeId] = useState({
    name: data?.name ? data?.name : "",
    comments: data?.comments ? data?.comments : "",
    logs: [],
    management: [],
    role_location: "",
    vps: [],
  });

  const priviligeById = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.privilegeById;
  });

  const rolesPermissionData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.rolesPermissionData;
  });
  const privilegesList = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.privilegesList;
  });

  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch({
      type: CLEAR_PRIVILAGE_ERROR,
    });
    const errors: any = {};
    let name: any = "";
    //const { name } = priviligeById || {};
    //    const {name} = data?.name ? data.name : priviligeById?.name ? priviligeById.name : '';
    if (data?.name) {
      name = data?.name;
    }
    if (priviligeById?.name) {
      name = data?.name;
    }

    if (!name || name.trim().length === 0) {
      errors.name = "Name should not be empty.";
    }
    if (priviligeById && !isEdit && !editMode) {
      // new previleges...
      privilegesList.results?.map((privilageItem: any) => {
        if (privilageItem.name === name) {
          errors.name = "role name exists for the role location";
        }
      });
    } else if (priviligeById && isEdit && editMode) {
      // edit previleges...
      privilegesList.results?.map((privilageItem: any, i: any) => {
        if (name !== originalName && privilageItem.name === name) {
          errors.name = "Privilage name already exists.";
        }
      });
    }

    // debugger;
    if (Object.keys(errors).length === 0) {
      if (!rolesPermissionData?.management) {
        dispatch(updateRolesPermissionActionData(priviligeById));
      } else {
        const updatedDetails = {
          ...rolesPermissionData,
          name,
          comments: priviligeById.comments,
        };
        dispatch(updateRolesPermissionActionData(updatedDetails));
      }
      dispatch(roleIncrement());
    }
    setErrors(errors);
  };

  useEffect(() => {
    if (priviligeById) {
      setPriviligeId({
        name: priviligeById?.name,
        comments: priviligeById?.comments,
        logs: priviligeById?.logs,
        management: priviligeById?.management,
        role_location: priviligeById?.role_location,
        vps: priviligeById?.vps,
      });
      !tempName && setTempName(priviligeById?.name);
    }
    setErrors({});
  }, [priviligeById]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoader ? (
        <Loader></Loader>
      ) : (
        <form id="roleForm" onSubmit={handleSubmit(onSubmit)}>
          <ToggleCard heading="Inventory Details" index="" removeFormFields="">
            <Stack className="deviceInputSection ms-Grid-row">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg12">
                  <Stack.Item className="ms-Grid-row">
                    <Stack.Item className="ms-Grid-col ms-lg3 ms-lgOffset2">
                      <TextField
                        label="Privilege Group Name*"
                        {...register("name")}
                        value={priviligeId.name}
                        onKeyDown={(e: any) => {
                          if (e.keyCode == 13) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                        onChange={(e: any) =>
                          dispatch({
                            type: GET_PRIVILEGE_BY_ID,
                            payload: {
                              data: {
                                ...priviligeById,
                                name: e.target.value,
                              },
                            },
                          })
                        }
                        errorMessage={errors?.name}
                      ></TextField>
                    </Stack.Item>

                    <Stack.Item className="ms-Grid-col ms-lg8 ms-lgOffset2 except">
                      <TextField
                        label="Comments"
                        {...register("comments")}
                        multiline
                        rows={3}
                        value={priviligeId.comments}
                        onChange={(e: any) =>
                          dispatch({
                            type: GET_PRIVILEGE_BY_ID,
                            payload: {
                              data: {
                                ...priviligeById,
                                comments: e.target.value,
                              },
                            },
                          })
                        }
                      />
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack>
          </ToggleCard>
        </form>
      )}
    </>
  );
};

export default PrivilegeCreation;
