import {
  FontIcon,
  Image,
  Stack,
  StackItem,
  Text,
  TooltipHost,
  IconButton,
  IIconProps,
} from "@fluentui/react";
import * as React from "react";
import { fonts, ThemeColorPalette } from "../../theme";
import thumbnail from "../../assets/thumbnail.png";
import { getDateWithSlashInBetween, getTime } from "../util";
import play_button from "../../assets/play_image_button.svg";
import "../procedureVideos/ProcedureVideos.scss";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  deleteProcedureVideo,
  getVideosForProcedures,
  showSpinnerOnLoad,
  showSpinnerForVideo,
} from "../../MyProcedures/actions/proceduresAction";
import DeleteModal from "../../ActiveAdminModule/Home/components/Common/DeleteModal";
import CheckPermission from "../permissions/rolePermissions";
import * as constants from "../../Layout/constants/constants";
import {
  getProcedureVideos,
  getProcedureVideosRowData,
} from "../../MyProcedures/actions/landingPageAction";

export interface ProcedureVideoCardProps {
  item: any;
  handleClick?: any;
  screenType?: string;
  history: any;
  procedure_names?: string;
  deleteVideoSuccess?: any;
}

const ProcedureVideoCard: React.SFC<ProcedureVideoCardProps> = ({
  item,
  screenType,
  handleClick,
  history,
  procedure_names,
  deleteVideoSuccess,
}) => {
  const videoContainerStyles: React.CSSProperties = {
    color: ThemeColorPalette.pureWhite,
    backgroundColor: ThemeColorPalette.gridBG,
    boxShadow: "0 3px 7px 0 rgba(0,0,0,0.27)",
    padding:
      screenType !== "myProcedures" ? "1.3rem" : "0.5rem 1.3rem 1.3rem 1.3rem",
    flex: "0 0 23.2%",
  };

  const addIcon: IIconProps = { iconName: "MoreVertical" };
  const menuItems = [
    {
      key: "delete",
      title: !constants.getPermissionsbyItem("vps.video.delete")
        ? constants.permission
        : "",
      text: "Delete",
    },
  ];

  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [videoId, setVideoId] = React.useState<string | any>("1");
  const [disabledCreate, setDisabledCreate] = React.useState(false);

  const _onMenuClick = useCallback((text: string, id: number) => {
    if (
      constants.getPermissionsbyItem("vps.video.delete") &&
      text.toLowerCase() === "delete"
    ) {
      setVideoId(id);
      showDeleteModal();
    }
  }, []);

  // const deleteHandle = () => {
  //   console.log(item);
  //   setDisabledCreate(true);
  //   dispatch(deleteProcedureVideo(videoId));
  //   setOpenDeleteModal((current) => !current);
  //   dispatch(showSpinnerForVideo());
  //   dispatch(showSpinnerOnLoad());
  //   dispatch(getVideosForProcedures(item.primary_procedure_type, 1));
  //   dispatch(getProcedureVideos());
  //   dispatch(getProcedureVideosRowData());
  // };
  const deleteHandle = async () => {
    setDisabledCreate(true);
    const response: any = await dispatch(deleteProcedureVideo(videoId));
    if ((response && response.status === 200) || response.status === 204) {
      setOpenDeleteModal((current) => !current);
      if (deleteVideoSuccess) deleteVideoSuccess(true);
      const page = localStorage.getItem("currentVideoPage")
        ? Number(localStorage.getItem("currentVideoPage"))
        : 1;
      dispatch(getVideosForProcedures(item.primary_procedure_type, page));
    }
  };

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);

  return (
    item &&
    item.id && (
      <Stack
        key={item.id}
        verticalFill
        style={videoContainerStyles}
        onClick={(evt: React.MouseEvent) =>
          screenType !== "myProcedures" ? handleClick(evt, item) : null
        }
      >
        <StackItem styles={{ root: { marginBottom: "1rem" } }}>
          <Stack horizontal horizontalAlign="space-between">
            <StackItem>
              {screenType !== "myProcedures" && (
                <Text
                  variant="medium"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Bold,
                      color: ThemeColorPalette.videoHeadingTxt,
                    },
                  }}
                >
                  {screenType !== "myProcedures" ? (
                    procedure_names && procedure_names.length > 20 ? (
                      <TooltipHost content={procedure_names}>
                        {procedure_names.slice(0, 20) + "..."}
                      </TooltipHost>
                    ) : (
                      procedure_names
                    )
                  ) : (
                    ""
                  )}
                </Text>
              )}
            </StackItem>
            <StackItem>
              {
                <FontIcon
                  iconName="SkypeCircleCheck"
                  style={{
                    color: ThemeColorPalette.themePrimary,
                    visibility: item.isSelected ? "visible" : "hidden",
                  }}
                />
              }
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <StackItem>
            {item.thumbnail ? (
              <Image
                src={"data:image/png;base64," + item.thumbnail}
                width="100%"
                height="100%"
              ></Image>
            ) : (
              <Image src={thumbnail} width="100%" height="100%"></Image>
            )}
          </StackItem>
          <Text
            variant="small"
            styles={{
              root: {
                bottom: "1.5rem",
                position: "relative",
                color: ThemeColorPalette.pureWhite,
                left: "0.5rem",
                padding: "0.2rem 0.2rem 0.2rem 0",
                fontFamily: fonts.HelveticaNeue_Bold,
                backgroundColor: ThemeColorPalette.pureBlack,
              },
            }}
          >
            {item && item.duration ? item.duration.slice(0, 8) : ""}
          </Text>
        </StackItem>
        {screenType === "myProcedures" && (
          <StackItem>
            <Stack horizontal horizontalAlign="space-between">
              <Stack tokens={{ childrenGap: 8 }}>
                <Text></Text>
              </Stack>
              <Stack>
                <IconButton
                  className="buttonIcon"
                  menuIconProps={addIcon}
                  menuProps={{
                    items: menuItems,
                    onItemClick: (e: any) =>
                      _onMenuClick(e?.target?.innerText, item.id),
                  }}
                  ariaLabel="New item"
                />
              </Stack>
            </Stack>
          </StackItem>
        )}
        {screenType === "procedures" && (
          <StackItem>
            <Stack horizontal horizontalAlign="space-between">
              <Stack tokens={{ childrenGap: 8 }}>
                <Text></Text>
              </Stack>
              <Stack>
                <IconButton
                  className="buttonIcon"
                  menuIconProps={addIcon}
                  menuProps={{
                    items: menuItems,
                    onItemClick: (e: any) =>
                      _onMenuClick(e?.target?.innerText, item.id),
                  }}
                  ariaLabel="New item"
                />
              </Stack>
            </Stack>
          </StackItem>
        )}
        <StackItem>
          <Stack horizontal horizontalAlign="space-between">
            <Stack tokens={{ childrenGap: 7 }}>
              <StackItem>
                <Text
                  variant="tiny"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Bold,
                      color: ThemeColorPalette.videoUploadDateTxt,
                    },
                  }}
                >
                  {item && item.uploaded_date
                    ? "Uploaded on " +
                      (getTime(item.uploaded_date) +
                        "," +
                        getDateWithSlashInBetween(item.uploaded_date))
                    : "Uploaded on: N/A"}
                </Text>
              </StackItem>
              <StackItem>
                <Text
                  variant="small"
                  styles={{
                    root: {
                      fontFamily: fonts.HelveticaNeue_Regular,
                      letterSpacing: "1px",
                    },
                  }}
                >
                  Video in compliance
                </Text>
                <FontIcon
                  iconName="RibbonSolid"
                  style={{
                    color: ThemeColorPalette.ribbon,
                    fontSize: "80%",
                    marginLeft: "0.2rem",
                  }}
                />
              </StackItem>
            </Stack>
            <CheckPermission {...constants.SCOPES.PLAY_VIDEO}>
              <Stack
                styles={{ root: { cursor: "pointer" } }}
                // className="play_button"
                verticalAlign="center"
                onClick={(evt: React.MouseEvent) => {
                  switch (screenType) {
                    case "sharedWithMe":
                      return history("/video/" + item.id, {
                        state: {
                          sharedWithMe: true,
                          is_uploaded_by_user: item.is_uploaded_by_user,
                          screenType: screenType,
                          location: screenType,
                        },
                      });
                    case "sharedByMe":
                      return history("/video/" + item.id, {
                        state: {
                          sharedByMe: true,
                          location: screenType,
                          is_uploaded_by_user: item.is_uploaded_by_user,
                        },
                      });
                    case "myProcedures ":
                      return history("/video/" + item.id, {
                        state: {
                          myProcedures: true,
                          location: screenType,
                          is_uploaded_by_user: item.is_uploaded_by_user,
                        },
                      });
                    case "favourite":
                      return history("/video/" + item.id, {
                        state: {
                          favourite: true,
                          location: screenType,
                          is_uploaded_by_user: item.is_uploaded_by_user,
                        },
                      });
                    default:
                      return history("/video/" + item.id, {
                        state: {
                          is_uploaded_by_user: item.is_uploaded_by_user,
                          location: "other",
                          surgeonId: item.primary_surgeon,
                        },
                      });
                  }
                  // if (screenType !== "sharedWithMe") history("/video/" + item.id);
                  // else {
                  //   history("/video/" + item.id, {
                  //     state: { sharedWithMe: true },
                  //   });
                  // }
                  evt.preventDefault();
                }}
              >
                <Image
                  src={play_button}
                  width="75%"
                  height="75%"
                  style={{ marginLeft: "1px" }}
                ></Image>
              </Stack>
            </CheckPermission>
          </Stack>
        </StackItem>
        <DeleteModal
          disabledCreate={disabledCreate}
          isModalOpen={openDeleteModal}
          deleteHandle={deleteHandle}
          showModal={showDeleteModal}
          id={videoId}
          name="Video"
        ></DeleteModal>
      </Stack>
    )
  );
};

export default ProcedureVideoCard;
