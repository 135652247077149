import {
  Stack,
  Image,
  Text,
  IconButton,
  IIconProps,
  IContextualMenuProps,
  StackItem,
} from "@fluentui/react";
import React from "react";
import "./User.scss";
import userImage from "../../../../assets/Home/user.png";
import { ThemeColorPalette } from "../../../../theme";
import moment from "moment";
import * as constants from "../../../../Layout/constants/constants";
const addIcon: IIconProps = { iconName: "MoreVertical" };
const editDeletePermission = (type: any, user: any) => {
  if (type === "edit") {
    // const loggedInProfile: any = JSON.parse((localStorage.getItem("loggedInProfile") as any))
    if (user?.role_name === "ActivAdmin") {
      return "management.activadmin.edit";
    } else if (user?.role_name === "Developer") {
      return "management.developer.edit";
    } else if (user?.role_name === "Dual") {
      return "management.dual_role.edit";
    } else if (user?.role_name === "HospitalAdmin") {
      return "management.hospitaladmin.edit";
    }
  }
  return false;
};
const deletePermission = (type: any, user: any) => {
  if (type === "edit") {
    // const loggedInProfile: any = JSON.parse((localStorage.getItem("loggedInProfile") as any))
    if (user?.role_name === "ActivAdmin") {
      return "management.activadmin.delete";
    } else if (user?.role_name === "Developer") {
      return "management.developer.delete";
    } else if (user?.role_name === "Dual") {
      return "management.dual_role.delete";
    } else if (user?.role_name === "HospitalAdmin") {
      return "management.hospitaladmin.delete";
    }
  }
  return false;
};
const getRolePermissionToEdit = (accounts: any, items: any) => {
  const acc = accounts.split(",").length == 0 ? accounts : "dual_role";
  const res = items.map((item: any) => {
    if (item.indexOf(acc.toLowerCase()) !== -1) {
      return constants.getPermissionsbyItem(item);
    }
    return false;
  });
  return res;
};
const User = ({
  user,
  _onMenuClickSetUserId,
  showEditUserModal,
  showDeleteUserModal,
}: {
  user: any;
  _onMenuClickSetUserId: any;
  showEditUserModal: any;
  showDeleteUserModal: any;
}) => {
  const menuProps: IContextualMenuProps =
    user?.role_name === "Surgeon"
      ? {
          items: [
            {
              key: "edit",
              text: "Edit",
              title: constants.getPermissionsbyItem("management.surgeon.edit")
                ? ""
                : constants.permission,
              onClick: constants.getPermissionsbyItem("management.surgeon.edit")
                ? showEditUserModal
                : null,
            },
          ],
        }
      : {
          items: [
            {
              key: "edit",
              //dual,hospital,developer
              text: "Edit",
              title: constants.getPermissionsbyItem(
                editDeletePermission("edit", user)
              )
                ? ""
                : constants.permission,
              onClick: constants.getPermissionsbyItem(
                editDeletePermission("edit", user)
              )
                ? showEditUserModal
                : null,
              // getRolePermissionToEdit()
            },
            {
              key: "delete",
              text: "Delete",
              // onClick: showDeleteUserModal,
              title: constants.getPermissionsbyItem(
                deletePermission("edit", user)
              )
                ? ""
                : constants.permission,
              onClick: constants.getPermissionsbyItem(
                deletePermission("edit", user)
              )
                ? showDeleteUserModal
                : null,
              // getRolePermissionToEdit()
            },
          ],
        };
  return (
    <Stack>
      <Stack.Item id="userCard">
        <Stack.Item>
          <Stack.Item className="userCardTextSection">
            <Stack horizontal horizontalAlign="space-between" dir="ltr">
              <Stack horizontal dir="ltr">
                {user?.photo ? (
                  <Image
                    style={{ borderRadius: "50%" }}
                    src={user?.photo}
                    width="40px"
                    height="40px"
                    alt="user image"
                  ></Image>
                ) : (
                  <StackItem
                    className="circle"
                    styles={{
                      root: {
                        height: "100%",
                        width: "60px",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {user?.first_name?.charAt(0) + user?.last_name?.charAt(0)}
                  </StackItem>
                )}
                <Stack.Item className="userTextSection">
                  <Text block className="hospitalCardText userName textNoWrap">
                    <abbr className="ms-bb-0" title={user?.displayName}>
                      {user?.displayName}
                    </abbr>
                  </Text>
                  {/* <Text block className="userName">
                    {user?.displayName}
                    {user?.first_name} {user?.last_name} 
                  </Text> */}
                  {/* margin-bottom: 19px; */}
                  <Text
                    block
                    variant="xSmall"
                    className={`userText ${
                      user?.hospital_name ? "" : "ms-mb-20"
                    }`}
                  >
                    {user?.accounts}
                  </Text>
                  <Stack>
                    <Text variant="xSmall" className="userText textNoWrap">
                      <abbr className="ms-bb-0" title={user?.hospital_name}>
                        {user?.hospital_name}
                      </abbr>
                    </Text>
                  </Stack>
                </Stack.Item>
              </Stack>
              {/* {user?.role_name === "Developer" ||
                user?.role_name === "Developer"} */}
              <IconButton
                className="buttonIcon"
                menuIconProps={addIcon}
                menuProps={menuProps}
                ariaLabel="New item"
                onMenuClick={() =>
                  _onMenuClickSetUserId(user?.role_name, user?.userId)
                }
              />
            </Stack>
            <Stack
              className="userCardFooter"
              horizontalAlign="end"
              dir="ltr"
              horizontal
            >
              <Text variant="xSmall" className="userText">
                {moment(user?.created_date).format("MM/DD/YY")}
              </Text>
            </Stack>
          </Stack.Item>
        </Stack.Item>
      </Stack.Item>
    </Stack>
  );
};
export default React.memo(User);
