import {
  Stack,
  Text,
  TextField,
  Toggle,
  Icon,
  IconButton,
  IContextualMenuProps,
  IIconProps,
} from "@fluentui/react";
import * as constants from "../../../../Layout/constants/constants";
import React, { useState, useEffect } from "react";
const moreIcon: IIconProps = { iconName: "MoreVertical" };
const ToggleCard = ({
  children,
  heading,
  removeFormFields,
  index,
}: {
  children: any;
  heading: String;
  removeFormFields: any;
  index: any;
}) => {
  const prefixZero = (num: Number) => {
    return ("0" + num).slice(-2);
  };
  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: "delete",
        text: "Delete",
        onClick: constants.getPermissionsbyItem("management.facility.delete")
          ? removeFormFields && removeFormFields(index)
          : null,
        title: constants.getPermissionsbyItem("management.facility.delete")
          ? ""
          : constants.permission,
      },
    ],
  };
  const [showSection, setShowSection] = useState(true);
  // const pages = React.Children.toArray(children);
  // const currentPage = pages[activePageIndex];
  const handletoggle = () => {
    // console.log(showSection, 'showSection');
    setShowSection(!showSection);
  };
  useEffect(() => {}, []);
  return (
    <Stack tokens={{ childrenGap: 15 }} key={index}>
      <Stack.Item className="createHospitalWizardSection">
        <Stack
          className="WizardSectionHeading"
          onClick={handletoggle}
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
        >
          <Text className="circleNumber" variant="mediumPlus">
            {heading} {index !== "" ? "-" + prefixZero(+index + 1) : ""}
          </Text>
          <Stack.Item>
            {removeFormFields !== "" ? (
              <IconButton
                className="buttonIcon"
                menuProps={menuProps}
                menuIconProps={moreIcon}
                title="Delete"
                ariaLabel="Delete"
                key={index}
                data={index}
                id={index}
                // onMenuClick={_onMenuClick}
              />
            ) : (
              ""
            )}
            {showSection ? (
              <Icon iconName="ChevronUp" className=" ms-fontSize-12" />
            ) : (
              <Icon iconName="ChevronDown" className=" ms-fontSize-12" />
            )}
          </Stack.Item>
        </Stack>
        <Stack
          tokens={{ childrenGap: 10 }}
          className={` ms-Grid ${showSection ? "open" : "close"}`}
          dir="ltr"
        >
          {children}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default ToggleCard;
