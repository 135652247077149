import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import { GET_HOSPITALS_COUNT } from "../../Home/actions/actionConstants";
import * as allActions from "./actionConstants";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const deviceIncrement = () => {
  return {
    type: allActions.DEVICE_INCREMENT,
  };
};

export const deviceDecrement = () => {
  return {
    type: allActions.DEVICE_DECREMENT,
  };
};

export const getDeviceList =
  (currentPage = 1, search?: any) =>
  async (dispatch: any) => {
    const currentUserPage = currentPage > 1 ? "?page=" + currentPage : "";
    const searchInput = search
      ? currentUserPage
        ? "&search=" + search
        : "?search=" + search
      : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint + `/hospitals/devices-list${currentUserPage}${searchInput}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_DEVICES_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_DEVICES_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_DEVICES_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_DEVICES_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getDeviceView = (serial_number: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/hospitals/devices/${serial_number}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_DEVICE_VIEW));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_DEVICE_VIEW,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_DEVICE_VIEW, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_DEVICE_VIEW, response.status)
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_DEVICE_VIEW, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_DEVICE_VIEW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const createDevice = (deviceData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + `/hospitals/devices`,
    data: deviceData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_DEVICES));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_DEVICES,
        payload: { data: deviceData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_DEVICES, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_DEVICES,
          403
        )
      );
    }
    console.error(response);
  }
};

export const editDevice =
  (serial_number: any, deviceData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/hospitals/devices/${serial_number}`,
      data: deviceData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_DEVICES));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_DEVICES,
          payload: { data: deviceData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_DEVICES, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_DEVICES,
            403
          )
        );
      }
      console.error(response);
    }
  };
export const deleteDeviceAction =
  (deviceSerialNumber: any) => async (dispatch: any, getState: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + `/hospitals/devices/${deviceSerialNumber}`,
      data: deviceSerialNumber,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_DEVICES));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.DELETE_DEVICES,
          payload: { data: deviceSerialNumber },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.DELETE_DEVICES, response.status)
        );
        let hospitalCountData = getState().homeReducerData.hospitalsCount.data;
        hospitalCountData = {
          ...hospitalCountData,
          devices: {
            ...hospitalCountData.devices,
            total: hospitalCountData.devices.total - 1,
          },
        };
        dispatch({
          type: GET_HOSPITALS_COUNT,
          payload: { data: hospitalCountData },
        });
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_DEVICES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateDeviceActionData =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.DEVICE_DATA,
      payload: { data: { ...payload } },
    });
  };

// export const getWorkFlowList = () => async (dispatch: any) => {
//   const payload = {
//     method: "GET",
//     url: apiEndPoint + "/hospitals/devices-list",
//   };
//   const response = await doHttpCall(payload);
//   if (response && response.status === 200) {
//     dispatch({
//       type: allActions.GET_DEVICES_LIST,
//       payload: { data: response.data },
//     });
//   }
// };

//---------------------------Roles----------------------------------

export const getRolesList =
  (
    currentPage = 1,
    role_location = "",
    search = "",
    pageCount?: any,
    user_type = ""
  ) =>
  async (dispatch: any) => {
    const roleLocationFilter =
      role_location !== "" ? `&role_location=${role_location}` : "";
    const searchRole = search && search !== "" ? `&search=${search}` : "";
    const page =
      pageCount && pageCount ? `&page_size=${pageCount}` : `&page_size=10`;
    const userTypeSet =
      user_type && user_type !== "" && user_type !== null
        ? `&user_type=${user_type}`
        : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/user/activadmin/roles?page=${currentPage}${roleLocationFilter}${searchRole}${page}${userTypeSet}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ROLES_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ROLES_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_ROLES_LIST, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ROLES_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getRolesView = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/user/activadmin/roles/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_ROLES_VIEW));
    const response = await doHttpCall(payload);
    const results = response.data;
    const resData = Object.keys(results)?.forEach(function (key, index) {
      if (results[key] && typeof results[key] === "object") {
        if (results[key].length === 0) {
          delete results[key];
        }
      }
    });
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_ROLES_VIEW,
        payload: { data: results },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_ROLES_VIEW, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_ROLES_VIEW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getPermissionsList =
  (user_type?: any) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: apiEndPoint + `/hospitals/permissions?user_type=${user_type}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_PERMISSION_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_PERMISSION_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_PERMISSION_LIST,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_PERMISSION_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateRolesPermissionActionData =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.ROLESPERMISSION_DATA,
      payload: { data: { ...payload } },
    });
  };

export const saveRolePremissionFirstStepData =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.SAVE_FIRST_STEP_DATA,
      payload: { payload },
    });
  };

export const createRolesPermission =
  (rolesPermissionData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/user/activadmin/roles",
      data: rolesPermissionData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_ROLESPERMISSION));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_ROLESPERMISSION,
          payload: { data: rolesPermissionData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_ROLESPERMISSION,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_ROLESPERMISSION,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editRole =
  (id: any, rolesPermissionData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/user/activadmin/roles/${id}`,
      data: rolesPermissionData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_ROLES));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_ROLES,
          payload: { data: rolesPermissionData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_ROLES, response.status)
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
      return response;
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_ROLES,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const RolesPermissionDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/user/activadmin/roles/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_ROLE_PERMISSION));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_ROLE_PERMISSION,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.DELETE_ROLE_PERMISSION,
          response.status
        )
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_ROLE_PERMISSION,
          403
        )
      );
    }
    console.error(response);
  }
};
export const roleIncrement = () => {
  return {
    type: allActions.ROLE_INCREMENT,
  };
};

export const roleReset = () => {
  return {
    type: allActions.ROLE_RESET,
  };
};

export const roleDecrement = () => {
  return {
    type: allActions.ROLE_DECREMENT,
  };
};

export const showCreateDevice = () => {
  return {
    type: allActions.SHOW_CREATE_DEVICES,
  };
};

export const showCreateRole = () => {
  return {
    type: allActions.SHOW_CREATE_ROLE,
  };
};

export const showCreateAlgorithm = () => {
  return {
    type: allActions.SHOW_CREATE_ALGORITHM,
  };
};

export const showCreateAlgorithmVersion = () => {
  return {
    type: allActions.SHOW_CREATE_ALGORITHM_VERSION,
  };
};
export const showCreateWorkflow = () => {
  return {
    type: allActions.SHOW_CREATE_WORKFLOW,
  };
};

export const showRoleSpinner = () => {
  return {
    type: allActions.SPINNER_ROLE,
  };
};

export const showDeviceSpinner = () => {
  return {
    type: allActions.SPINNER_DEVICE,
  };
};

export const closeDeviceModal = () => {
  return {
    type: allActions.CLOSE_DEVICE_MODAL,
  };
};
export const closeWorkflowModal = () => {
  return {
    type: allActions.CLOSE_WORKFLOW_MODAL,
  };
};

export const closeRoleModal = () => {
  return {
    type: allActions.CLOSE_ROLE_MODAL,
  };
};

export const closeAlgorithmModal = () => {
  return {
    type: allActions.CLOSE_ALGORITHM_MODAL,
  };
};

export const closeAlgorithmVersionModal = () => {
  return {
    type: allActions.CLOSE_ALGORITHM_VERSION_MODAL,
  };
};

//---------------------------Procedure----------------------------------

export const getProcedureList =
  (currentPage = 1, count?: any) =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : "";
    const currentUserPage = "?page=" + currentPage;
    const payload = {
      method: "GET",
      url: apiEndPoint + `/procedures/types${currentUserPage}${count}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_PROCEDURE_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_PROCEDURE_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_PROCEDURE_LIST,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_PROCEDURE_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getProcedureView = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/procedures/types/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_PROCEDURE_VIEW));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_PROCEDURE_VIEW,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_PROCEDURE_VIEW,
          response.status
        )
      );
      return response;
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
      return response;
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
      return response;
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_PROCEDURE_VIEW,
          403
        )
      );
      return response;
    }
    console.error(response);
  }
};

export const createProcedure =
  (procedureData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + `/procedures/types`,
      data: procedureData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_PROCEDURE));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_PROCEDURE,
          payload: { data: procedureData },
        });
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_PROCEDURE,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editProcedure =
  (id: any, procedureData: any) => async (dispatch: any) => {
    // debugger;
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/procedures/types/${id}`,
      data: procedureData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_PROCEDURE));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_PROCEDURE,
          payload: { data: procedureData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_PROCEDURE, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_PROCEDURE,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const procedureDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/procedures/types/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_PROCEDURE));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_PROCEDURE,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.DELETE_PROCEDURE, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_PROCEDURE,
          403
        )
      );
    }
    console.error(response);
  }
};

export const clearProcedureData = () => {
  return {
    type: allActions.CLEAR_PROCEDURE_DATA,
  };
};
//---------------------------Specialty----------------------------------

export const getSpecialtyList = (count?: any) => async (dispatch: any) => {
  count = count ? `&page_size=${count}` : "";
  const payload = {
    method: "GET",
    url: apiEndPoint + `/hospitals/activ-speciality?page_size=2000${count}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_SPECIALTY_LIST));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_SPECIALTY_LIST,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_SPECIALTY_LIST,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_SPECIALTY_LIST,
          403
        )
      );
    }
    console.error(response);
  }
};
export const createSpecialty =
  (specialtyData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + `/hospitals/activ-speciality`,
      data: specialtyData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_SPECIALTY));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch(getSpecialtyList());
        dispatch({
          type: allActions.CREATE_SPECIALTY,
          payload: { data: specialtyData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_SPECIALTY,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_SPECIALTY,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_SPECIALTY,
            response.status
          )
        );
        return response;
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_SPECIALTY,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_SPECIALTY,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_SPECIALTY,
            403
          )
        );
        return response;
      }
      console.error(response);
    }
  };

export const updateSpaceialtyActionData =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.SPECIALTY_DATA,
      payload: { data: { ...payload } },
    });
  };

export const updateOutputArguments =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.UPDATE_OUTPUT_ARGUMENTS,
      payload: [...payload],
    });
  };

//--------------------Algorithm--------------------------------

export const getAlgorithmList =
  (algorithm_name = "", currentPage = 1, count?: any) =>
  async (dispatch: any) => {
    count = count ? `?page_size=${count}` : "";
    const algorithmSearch = algorithm_name ? `?search=${algorithm_name}` : "";
    const currentUserPage = currentPage > 1 ? "?page=" + currentPage : "";

    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/algorithms/${algorithmSearch}${currentUserPage}${count}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ALGORITHM_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALGORITHM_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALGORITHM_LIST,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALGORITHM_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const createAlgorithm =
  (algorithmData: any) => async (dispatch: any) => {
    // debugger;
    const payload = {
      method: "POST",
      url: apiEndPoint + `/algorithms/`,
      data: algorithmData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_ALGORITHM));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_ALGORITHM,
          payload: { data: algorithmData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_ALGORITHM,
            response.status
          )
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_ALG,
          payload: response.data[0],
        });
        dispatch(
          hideLoaderWithMessage("", allActions.ERROR_ALG, response.status)
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_ALGORITHM,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const editAlogrithm =
  (id: any, algorithmData: any) => async (dispatch: any) => {
    // debugger;
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/algorithms/${id}`,
      data: algorithmData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_ALOGRITHM));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_ALOGRITHM,
          payload: { data: algorithmData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_ALOGRITHM, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_ALG,
          payload: response.data[0],
        });
        dispatch(
          hideLoaderWithMessage("", allActions.ERROR_ALG, response.status)
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_ALOGRITHM,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const algorithmDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/algorithms/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_ALGORITHAM));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_ALGORITHAM,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.DELETE_ALGORITHAM, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_ALGORITHAM,
          403
        )
      );
    }
    console.error(response);
  }
};
export const updateProcedureActionData =
  (payload: any = "") =>
  async (dispatch: any) => {
    dispatch({
      type: allActions.PROCEDURE_DATA,
      payload: { data: { ...payload } },
    });
  };

export const closeErrorRsponse = () => {
  return {
    type: allActions.CLOSE_ERRORS,
  };
};

export const getAlgorithmView = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/algorithms/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_ALGORITHM_VIEW));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_ALGORITHM_VIEW,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_ALGORITHM_VIEW,
          response.status
        )
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_ALGORITHM_VIEW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const algorithmView = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/algorithms/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.ALGORITHAM_VIEW));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.ALGORITHAM_VIEW,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.ALGORITHAM_VIEW, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.ALGORITHAM_VIEW,
          403
        )
      );
    }
    console.error(response);
  }
};

//--------------------Algorithm Version--------------------------------

export const getAlgorithmVersionList =
  (currentPage = 1) =>
  async (dispatch: any) => {
    const currentUserPage = currentPage > 1 ? "?page=" + currentPage : "";

    const payload = {
      method: "GET",
      url: apiEndPoint + `/algorithms/versions${currentUserPage}`,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.GET_ALGORITHM_VERSION_LIST)
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALGORITHM_VERSION_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALGORITHM_VERSION_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALGORITHM_VERSION_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getAlgorithmVersionView = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/algorithms/versions/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_ALGORITHM_VERSION_VIEW));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_ALGORITHM_VERSION_VIEW,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_ALGORITHM_VERSION_VIEW,
          response.status
        )
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_ALGORITHM_VERSION_VIEW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const createAlgorithmVersion =
  (algorithmData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + `/algorithms/versions`,
      data: algorithmData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_ALGORITHM_VERSION));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_ALGORITHM_VERSION,
          payload: { data: algorithmData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_ALGORITHM_VERSION,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_ALGORITHM_VERSION,
            403
          )
        );
        return response;
      }
      console.error(response);
    }
  };

export const editAlogrithmVersion =
  (id: any, algorithmVersionData: any) => async (dispatch: any) => {
    // debugger;
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/algorithms/versions/${id}`,
      data: algorithmVersionData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.EDIT_ALOGRITHM_VERSION));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_ALOGRITHM_VERSION,
          payload: { data: algorithmVersionData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.EDIT_ALOGRITHM_VERSION,
            response.status
          )
        );
        return response;
      }
      if (response && (response.status === 400 || response.status === 404)) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
      if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_ALOGRITHM_VERSION,
            403
          )
        );
        return response;
      }
      console.error(response);
    }
  };

export const algorithmVersionDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/algorithms/versions/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_ALGORITHAM_VERSION));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_ALGORITHAM_VERSION,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.DELETE_ALGORITHAM_VERSION,
          response.status
        )
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_ALGORITHAM_VERSION,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getAlgorithmModelImageList =
  (algorithm_id: number) => async (dispatch: any) => {
    const role = localStorage.getItem("role");
    let isDeveloper = false;
    if (role === "Developer") {
      isDeveloper = true;
    }
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/algorithms/models/image?algorithm=${algorithm_id}&developer=${isDeveloper}`,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.GET_ALGORITHM_MODEL_IMAGE_LIST)
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALGORITHM_MODEL_IMAGE_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALGORITHM_MODEL_IMAGE_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALGORITHM_MODEL_IMAGE_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

//--------------------Workflow--------------------------------

export const getWorkflowList =
  (currentPage = 1, is_business: any, workflowName = "", count?: any) =>
  async (dispatch: any) => {
    count = count ? `&page_size=${count}` : "";
    const WorkflowTypeSearch =
      workflowName !== "" ? `&search=${workflowName}` : "";
    const currentUserPage = "?page=" + currentPage;
    const business = is_business !== "" ? `&business=${is_business}` : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/algorithms/workflows${currentUserPage}${business}${WorkflowTypeSearch}${count}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_WORKFLOW_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_WORKFLOW_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_WORKFLOW_LIST,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_WORKFLOW_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getProcedureDetailsById = (uuid: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/algorithms/workflows/${uuid}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.PROCEDURE_DATA_ID));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.PROCEDURE_DATA_ID,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.PROCEDURE_DATA_ID, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.PROCEDURE_DATA_ID,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getAlgorithmTypesList = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + "/algorithms/workflows/types",
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_ALGORITHM_TYPE_LIST));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_ALGORITHM_TYPE_LIST,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_ALGORITHM_TYPE_LIST,
          response.status
        )
      );
      return response;
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_ALGORITHM_TYPE_LIST,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getAlgorithmBusinessList =
  (is_business: any, algorithmName = "", nextUrl?: string) =>
  async (dispatch: any) => {
    const algorithmSearch =
      algorithmName !== "" ? `&search=${algorithmName}` : "";
    const payload = {
      method: "GET",
      url:
        nextUrl ||
        apiEndPoint +
          `/algorithms/?is_business=${is_business}${algorithmSearch}`,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          nextUrl
            ? allActions.GET_BUSINESS_ALGORITHM_LIST
            : allActions.REFRESH_BUSINESS_ALGORITHM_LIST
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: nextUrl
            ? allActions.GET_BUSINESS_ALGORITHM_LIST
            : allActions.REFRESH_BUSINESS_ALGORITHM_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            nextUrl
              ? allActions.GET_BUSINESS_ALGORITHM_LIST
              : allActions.REFRESH_BUSINESS_ALGORITHM_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            nextUrl
              ? allActions.GET_BUSINESS_ALGORITHM_LIST
              : allActions.REFRESH_BUSINESS_ALGORITHM_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateInputParams = (payload: any) => async (dispatch: any) => {
  dispatch({
    type: allActions.UPDATE_INPUT_PARAMETER_DATA,
    payload: [...payload],
  });
};

export const getInputParameterList =
  (algorithm_ids = "", is_business = false) =>
  async (dispatch: any) => {
    const withAlgorithm = algorithm_ids ? `&algorithms=${algorithm_ids}` : "";
    const payload = {
      method: "GET",
      url:
        apiEndPoint +
        `/algorithms/outputs?business=${is_business}${withAlgorithm}`,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_ALGORITHM_OUTPUT_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_ALGORITHM_OUTPUT_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_ALGORITHM_OUTPUT_LIST,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_ALGORITHM_OUTPUT_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const removeErrorWorkflow = () => async (dispatch: any) => {
  dispatch({
    type: allActions.ERROR_WORKFLOW,
    payload: "",
  });
};
export const removeErrorALG = () => async (dispatch: any) => {
  dispatch({
    type: allActions.ERROR_ALG,
    payload: "",
  });
};

export const createWorkflow = (workflowData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + `/algorithms/workflows`,
    data: workflowData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_WORKFLOW));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_WORKFLOW,
        payload: { data: workflowData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_WORKFLOW, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_WORKFLOW,
        payload: response.data[0],
      });
      dispatch(
        hideLoaderWithMessage("", allActions.ERROR_WORKFLOW, response.status)
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_WORKFLOW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const editWorkflow =
  (id: any, workflowData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/algorithms/workflows/${id}`,
      data: workflowData,
    };
    try {
      // debugger;
      dispatch(showLoaderWithMessage("", allActions.EDIT_WORKFLOW));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.EDIT_WORKFLOW,
          payload: { data: workflowData },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.EDIT_WORKFLOW, response.status)
        );
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_WORKFLOW,
          payload: { details: response?.data?.details?.name },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.ERROR_WORKFLOW, response.status)
        );
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.EDIT_WORKFLOW,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const updateWorkflowActionData =
  (payload: any) => async (dispatch: any) => {
    dispatch({
      type: allActions.WORKFLOW_DATA,
      payload: { ...payload },
    });
  };

//---------------------Workflow Mapping--------------------
// export const getWorkflowMappingList =
// (algorithm_name = "", currentPage = 1) =>
// async (dispatch: any) => {
//   const algorithmSearch = algorithm_name ? `?search=${algorithm_name}` : "";
//   const currentUserPage = currentPage > 1 ? "?page=" + currentPage : "";

//   const payload = {
//     method: "GET",
//     url: apiEndPoint + `/algorithms${algorithmSearch}${currentUserPage}`,
//   };
//   const response = await doHttpCall(payload);
//   if (response && response.status === 200) {
//     dispatch({
//       type: allActions.GET_ALGORITHM_LIST,
//       payload: { data: response.data },
//     });
//   }
// };

export const getWorkflowInputTypeList = () => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/algorithms/workflows/input-types`,
  };
  const response = await doHttpCall(payload);
  if (response && response.status === 200) {
    dispatch({
      type: allActions.GET_WORKFLOW_INPUT_TYPE_LIST,
      payload: { data: response.data },
    });
  }
};

export const getWorkflowTypeList =
  (workflowName = "") =>
  async (dispatch: any) => {
    const WorkflowTypeSearch =
      workflowName !== "" ? `?search=${workflowName}` : "";
    const payload = {
      method: "GET",
      url: apiEndPoint + `/algorithms/workflows/types${WorkflowTypeSearch}`,
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_WORKFLOW_TYPE_LIST,
        payload: { data: response.data },
      });
    }
  };

export const getWorkflowView = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "GET",
    url: apiEndPoint + `/algorithms/workflows/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_WORKFLOW_VIEW));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.GET_WORKFLOW_VIEW,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_WORKFLOW_VIEW, response.status)
      );
      return response;
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_WORKFLOW_VIEW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const createWorkflowMapping =
  (workflowMappingData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + `/algorithms/workflows/mapping`,
      data: workflowMappingData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_WORKFLOW_MAPPING));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_WORKFLOW_MAPPING,
          payload: { data: workflowMappingData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_WORKFLOW_MAPPING,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_WORKFLOW_MAPPING,
            403
          )
        );
        return response;
      }
      console.error(response);
    }
  };

export const createWorkflowProcedureMapping =
  (workflowMappingData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + `/algorithms/workflows/mapping/business`,
      data: workflowMappingData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_WORKFLOW_MAPPING));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_WORKFLOW_MAPPING,
          payload: { data: workflowMappingData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_WORKFLOW_MAPPING,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_WORKFLOW_MAPPING,
            403
          )
        );
        return response;
      }
      console.error(response);
    }
  };

export const getProcedures = (count?: any) => async (dispatch: any) => {
  count = count ? `?page_size=${count}` : "";
  const payload = {
    method: "GET",
    url: apiEndPoint + `/procedures/types${count}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.GET_PROCEDURE));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.GET_PROCEDURE,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.GET_PROCEDURE, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.GET_PROCEDURE,
          403
        )
      );
    }
    console.error(response);
  }
};

export const createSingleProcedure =
  (procedureData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + `/procedures/types`,
      data: procedureData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.CREATE_SINGLE_PROCEDURE));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_SINGLE_PROCEDURE,
          payload: { data: procedureData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_SINGLE_PROCEDURE,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        // dispatch({
        //   type: allActions.ERROR_IN_PROCEDURE,
        //   payload: response,
        // });
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE_CREATE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      } else if (response && response.status === 500) {
        // dispatch({
        //   type: allActions.ERROR_IN_PROCEDURE,
        //   payload: response,
        // });
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE_CREATE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_SINGLE_PROCEDURE,
            403
          )
        );
      }
      return response;
      // console.log(response);
    }
  };

export const workflowDelete = (id: any) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + `/algorithms/workflows/${id}`,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_WORKFLOW));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.DELETE_WORKFLOW,
        payload: { data: id },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.DELETE_WORKFLOW, response.status)
      );
    } else if (
      response &&
      (response.status === 400 || response.status === 404)
    ) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    } else if (response && response.status === 500) {
      dispatch({
        type: allActions.ERROR_IN_PROCEDURE,
        payload: response,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.ERROR_IN_PROCEDURE,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_WORKFLOW,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getUserProfileManagement = () => async (dispatch: any) => {
  try {
    const payload = {
      method: "GET",
      url: apiEndPoint + "/user/profile",
    };
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      localStorage.setItem("userProfileId", response.data.id);
      dispatch({
        type: allActions.GET_LOGIN_USER_PROFILE_MANAGAMENT,
        payload: response.data,
      });
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const createProcedureSpecialtyMapping =
  (id: any, procedureData: any) => async (dispatch: any) => {
    const payload = {
      method: "PATCH",
      url: apiEndPoint + `/procedures/types/${id}`,
      data: procedureData,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.CREATE_PROCEDURE_SPACIALTY)
      );
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.CREATE_PROCEDURE_SPACIALTY,
          payload: { data: procedureData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.CREATE_PROCEDURE_SPACIALTY,
            response.status
          )
        );
        return response;
      } else if (
        response &&
        (response.status === 400 || response.status === 404)
      ) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      } else if (response && response.status === 500) {
        dispatch({
          type: allActions.ERROR_IN_PROCEDURE,
          payload: response,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.ERROR_IN_PROCEDURE,
            response.status
          )
        );
        return response;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.CREATE_PROCEDURE_SPACIALTY,
            403
          )
        );
      }
      return response;
      //console.log(response);
    }
  };

export const clearBusinessAlgorithmList = () => {
  return {
    type: allActions.CLEAR_BUSINESS_ALGORITHM_LIST,
  };
};

export const updateSelectedRoleName = (role_name: any) => {
  return {
    type: allActions.SELECTED_ROLE_NAME,
    payload: { selectedNameForRole: role_name },
  };
};

export const updateSelectedRoleLocation = (role_location: any) => {
  return {
    type: allActions.SELECTED_ROLE_LOCATION,
    payload: { selectedRoleLocation: role_location },
  };
};

export const updateSelectedPermissionUserType = (user_type: any) => {
  return {
    type: allActions.SELECTED_PERMISSION_USER_TYPE,
    payload: { selectedPermissionUserType: user_type },
  };
};

export const updateSelectedRoleComments = (role_comments: any) => {
  return {
    type: allActions.SELECTED_ROLE_COMMENTS,
    payload: { selectedCommentsForRole: role_comments },
  };
};

export const updateIsPermissionListFetched = (
  isPermissionListFetched: boolean
) => {
  return {
    type: allActions.IS_PERMISSION_LIST_FETCHED,
    payload: { isPermissionListFetched: isPermissionListFetched },
  };
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
