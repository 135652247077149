import {
  VIDEOS,
  VIDEOS_PROCEDURE,
  REFRESH_VIDEOS_PROCEDURE,
  SHOW_LOADER,
} from "../actions/actionConstants";
const initialState: any = {
  videos: [],
  videosCount: [],
  loadingVideos: false,
};
export const developerHomeReducer = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case VIDEOS:
      return {
        ...state,
        loadingVideos: false,
        videos: action.payload,
      };
    case VIDEOS_PROCEDURE:
      const previousData = state.videosCount?.data?.results || [];
      state.videosCount = action.payload;
      state.videosCount.data.results = [
        ...previousData,
        ...state.videosCount.data.results,
      ];
      return {
        ...state,
        loadingState: false,
      };
    case REFRESH_VIDEOS_PROCEDURE:
      return {
        ...state,
        videosCount: action.payload,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loadingVideos: true,
      };
    default:
      return state;
  }
};
