import icon1 from "../../assets/icon_1.svg";
import icon2 from "../../assets/icon_2.png";
import icon5 from "../../assets/icon_5.svg";
import icon6 from "../../assets/icon_6.svg";
import icon7 from "../../assets/icon_7.svg";
import icon8 from "../../assets/icon_8.svg";
import home from "../../assets/home.svg";
import favourite from "../../assets/favourite.svg";
import management from "../../assets/management.svg";
import logs from "../../assets/logs.svg";
import dataset from "../../assets/test_dataset.svg";

export const NAV_LINK_GROUPS = {
  ActivAdmin: [
    {
      links: [
        { name: "", url: "/", key: "home" },
        { name: "", url: "/management/roles", key: "management" },
        { name: "", url: "/logs", key: "logs" },
        { name: "", url: "/activity", key: "activity" },
      ],
    },
  ],
  Surgeon: [
    {
      links: [
        { name: "", url: "/", key: "myProcedures" },
        { name: "", url: "/inprogress", key: "inprogress" },
        { name: "", url: "/shared", key: "shared" },
        { name: "", url: "/favorites", key: "favorites" },
        { name: "", url: "/recentActivities", key: "recentActivities" },
        { name: "", url: "/help", key: "help" },
      ],
    },
  ],
  HospitalAdmin: [
    {
      links: [
        { name: "", url: "/", key: "home" },
        { name: "", url: "/inprogress", key: "inprogress" },
        // { name: "", url: "/favorites", key: "favorites" },
        { name: "", url: "/management/privileges", key: "management" },
        { name: "", url: "/logs", key: "logs" },
        { name: "", url: "/activity", key: "activity" },
        { name: "", url: "/help", key: "help" },
      ],
    },
  ],
  Developer: [
    {
      links: [
        { name: "", url: "/", key: "home" },
        { name: "", url: "/testdataset", key: "testdataset" },
        // { name: "", url: "/favorites", key: "favorites" },
        { name: "", url: "/management/model", key: "management" },
        { name: "", url: "/logs", key: "logs" },
        { name: "", url: "/activity", key: "activity" },
      ],
    },
  ],
};

export const NAV_OPTIONS = {
  ActivAdmin: [
    { key: "home", imgsrc: home, text: "Home" },
    { key: "management", imgsrc: management, text: "Management" },
    { key: "logs", imgsrc: logs, text: "Logs" },
    { key: "favorites", imgsrc: favourite, text: "Favorites" },
    { key: "activity", imgsrc: icon7, text: "Activity" },
  ],
  Surgeon: [
    { key: "myProcedures", imgsrc: icon2, text: "Procedures" },
    { key: "inprogress", imgsrc: icon6, text: "In progress" },
    { key: "shared", imgsrc: icon5, text: "Shared" },
    { key: "favorites", imgsrc: icon1, text: "Favorites" },
    { key: "recentActivities", imgsrc: icon7, text: "Recent Activity" },
    { key: "help", imgsrc: icon8, text: "FAQ" },
  ],
  HospitalAdmin: [
    { key: "home", imgsrc: home, text: "Home" },
    { key: "inprogress", imgsrc: icon6, text: "In progress" },
    // { key: "favorites", imgsrc: favourite, text: "Favorites" },
    { key: "management", imgsrc: management, text: "Management" },
    { key: "logs", imgsrc: logs, text: "Logs" },
    { key: "activity", imgsrc: icon7, text: "Activity" },
    { key: "help", imgsrc: icon8, text: "FAQ" },
  ],
  Developer: [
    { key: "home", imgsrc: home, text: "Home" },
    { key: "testdataset", imgsrc: dataset, text: "Dataset Mapping" },
    // { key: "favorites", imgsrc: favourite, text: "Favorites" },
    { key: "management", imgsrc: management, text: "Model Management" },
    { key: "logs", imgsrc: logs, text: "Logs" },
    { key: "activity", imgsrc: icon7, text: "Activity" },
  ],
};

export enum ROLE_TYPES {
  ACTIVE_ADMIN = "ActivAdmin",
  ACTIVE_SURGEON = "Surgeon",
  ACTIVE_HOSPITAL_ADMIN = "HospitalAdmin",
  ACTIVE_DEVELOPER = "Developer",
  ACTIVE_DUAL = "Dual",
}

export const SCOPES = {
  DEVELOPER_CREATE_CONTAINER: {
    scopes: ["algorithm.container.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DEVELOPER_EDIT_CONTAINER: {
    scopes: ["algorithm.container.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DEVELOPER_DELETE_CONTAINER: {
    scopes: ["algorithm.container.delete"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  DEVELOPER_CREATE_MODEL: {
    scopes: ["algorithm.model.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  // DEVELOPER_EDIT_MODEL: {
  //   scopes: ["model.edit"],
  //   childrenProps: {
  //     disabled: true,
  //     title: "You do not have permission",
  //   },
  // },
  DEVELOPER_DELETE_MODEL: {
    scopes: ["algorithm.model.delete"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  DEVELOPER_ADD_DATASET_MAPPING: {
    scopes: ["algorithm.datasetmapping.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DEVELOPER_EDIT_DATASET_MAPPING: {
    scopes: ["algorithm.datasetmapping.edit"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  DEVELOPER_DELETE_DATASET_MAPPING: {
    scopes: ["algorithm.datasetmapping.delete"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  DEVELOPER_ADD_COMPARISION_RATING: {
    scopes: ["algorithm.model_comparison_rating.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DEVELOPER_LOGS_DOWNLOAD: {
    scopes: ["logs.download"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permissions",
    },
  },
  VIDEO_UPLOAD: {
    scopes: ["vps.videos.upload"],
    childrenProps: {
      // disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  VIDEO_EDIT_ALL_MODULES: {
    scopes: ["vps.video.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIDEO_DELETE: {
    scopes: ["vps.video.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  SURGEON_VIDEOS_SHARE: {
    scopes: ["vps.video.share"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIDEOS_ACCEPT: {
    scopes: ["vps.video.accept"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  SURGEON_COMMENT_ADD: {
    scopes: ["vps.comment.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
      onClick: () => null,
      style: {
        cursor: "auto",
        opacity: 0.7,
      },
    },
  },
  SURGEON_COMMENT_EDIT: {
    scopes: ["vps.comment.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  SURGEON_COMMENT_DELETE: {
    scopes: ["vps.comment.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  ACTIVADMIN_ALGORITHM_CREATE: {
    scopes: ["algorithm.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  ACTIVADMIN_ALGORITHM_EDIT: {
    scopes: ["algorithm.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  ACTIVADMIN_ALGORITHM_DELETE: {
    scopes: ["algorithm.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  PHOTO_AND_PROFILE_EDIT: {
    scopes: ["management.photo.edit", "management.profile.edit"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  PHOTO_UPDATE: {
    scopes: ["management.photo.edit"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  EDIT_PROFILE: {
    scopes: ["management.profile.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_HOSPITALS: {
    scopes: ["management.hospital.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_VIDEOS: {
    scopes: ["vps.videos.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  PLAY_VIDEO: {
    scopes: ["vps.video.play"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_USERS: {
    scopes: ["management.users.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_ROLES: {
    scopes: ["management.roles.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_DEVICES: {
    scopes: ["management.devices.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_ALGORITHMS: {
    scopes: ["algorithm.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_ALGORITHMVERSIONS: {
    scopes: ["algorithm.algorithm_version.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_PROCEDURES: {
    scopes: ["management.procedures.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ALL_WORKFLOWS: {
    scopes: ["algorithm.workflows.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  LOG_ANALYTICS: {
    scopes: ["logs.analytics"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_LOGS: {
    scopes: ["logs.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_ACTIVITIES: {
    scopes: ["logs.activities.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_HOSPITAL: {
    scopes: ["management.hospital.create"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  EDIT_HOSPITAL_ACTIVADMIN: {
    scopes: ["management.hospital.edit"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  DELETE_HOSPITAL: {
    scopes: ["management.hospital.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_ACTIVADMIN: {
    scopes: ["management.activadmin.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_ACTIVADMIN: {
    scopes: ["management.activadmin.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_ACTIVADMIN: {
    scopes: ["management.activadmin.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_DEVELOPER: {
    scopes: ["management.developer.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_DEVELOPER: {
    scopes: ["management.developer.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_DEVELOPER: {
    scopes: ["management.developer.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_DUALROLE: {
    scopes: ["management.dual_role.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_DUALROLE: {
    scopes: ["management.dual_role.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_DUALROLE: {
    scopes: ["management.dual_role.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_HOSPITALADMIN: {
    scopes: ["management.hospitaladmin.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_HOSPITALADMIN: {
    scopes: ["management.hospitaladmin.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_HOSPITALADMIN: {
    scopes: ["management.hospitaladmin.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_SURGEON: {
    scopes: ["management.surgeon.create"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  EDIT_SURGEON: {
    scopes: ["management.surgeon.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_ROLE: {
    scopes: ["management.role.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_ROLE: {
    scopes: ["management.role.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_ROLE: {
    scopes: ["management.role.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_DEVICE: {
    scopes: ["management.device.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_DEVICE: {
    scopes: ["management.device.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_DEVICE: {
    scopes: ["management.device.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  ASSIGN_DEVICE_TO_HOSPITAL: {
    scopes: ["management.assign_device.hospital"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_ALGORITHM_VERSION: {
    scopes: ["algorithm.algorithm_version.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_ALGORITHM_VERSION: {
    scopes: ["algorithm.algorithm_version.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_ALGORITHM_VERSION: {
    scopes: ["algorithm.algorithm_version.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_PROCEDURE: {
    scopes: ["management.procedure.create"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  CREATE_SPECIALTY: {
    scopes: ["management.specilty.create"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  MAP_PROCEDURES_TO_SPECIALTIES: {
    scopes: ["management.procedure_speciality.map"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  ADD_PROCEDURE_TYPE: {
    scopes: ["management.procedure.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_PROCEDURE: {
    scopes: ["management.procedure.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_PROCEDURE: {
    scopes: ["management.procedure.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_WORKFLOW: {
    scopes: ["algorithm.workflow.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_WORKFLOW: {
    scopes: ["algorithm.workflow.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_WORKFLOW: {
    scopes: ["algorithm.workflow.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_WORKFLOW_MAPPING: {
    scopes: ["algorithm.workflowmapping.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DOWNLOAD_LOGS: {
    scopes: ["logs.download"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  DOWNLOAD_ACTIVITIES: {
    scopes: ["logs.activity.download"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  ACTIVSIGHT_VIDEO_PROCESSING: {
    scopes: ["vps.activsight_video_processing"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  UPLOAD_LOG_MAPPING_DOCUMENT: {
    scopes: ["logs.log_mapping_document.upload"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIDEO_PLAYBACK_WITH_PHASES_INSIGHTS_AND_TOOLS: {
    scopes: ["management.corebussiness.view"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  BULK_IMPORT_SURGEONS: {
    scopes: ["management.surgeons.import"],
    childrenProps: {
      disabled: true,
      style: {
        opacity: 0.8,
      },
      onClick: () => null,
      title: "You do not have permission",
    },
  },
  UPDATE_SURGEON_DETAILS: {
    scopes: ["management.surgeon.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  MAP_VIDEOS_TO_SURGEON: {
    scopes: ["vps.videos.surgeon.map"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  VIEW_VIDEO_COMMENTS: {
    scopes: ["vps.video_comments.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_PRIVILEGE_GROUP: {
    scopes: ["management.previlage_group.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_PRIVILEGE_GROUP: {
    scopes: ["management.previlage_group.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_PRIVILEGE_GROUP: {
    scopes: ["management.previlage_group.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_SPECIALTY: {
    scopes: ["management.specialty.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_SPECIALTY: {
    scopes: ["management.specialty.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  CREATE_FACILITY: {
    scopes: ["management.facility.create"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  EDIT_FACILITY: {
    scopes: ["management.facility.edit"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  DELETE_FACILITY: {
    scopes: ["management.facility.delete"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  LIST_ALL_MODELS: {
    scopes: ["algorithm.model.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  LIST_ALL_DATASETS: {
    scopes: ["algorithm.dataset.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
  LIST_ALL_CONTAINER: {
    scopes: ["algorithm.container.list"],
    childrenProps: {
      disabled: true,
      title: "You do not have permission",
    },
  },
};

export const permission = "You do not have permission";

export const getPermissionsbyItem = (item: any) => {
  const permissions = JSON.parse(
    localStorage.getItem("loggedInProfile") as any
  )?.permissions;
  if (permissions && permissions.indexOf(item) !== -1) {
    return true;
  }
  return false;
};
