import {
  Stack,
  StackItem,
  Text,
  Image,
  IImageProps,
  ImageFit,
  IStackTokens,
  IIconProps,
  SearchBox,
  Icon,
  Modal,
  Pivot,
  PivotItem,
  Label,
  IPivotItemProps,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import no_data from "../../../assets/no_data.svg";

import React, {
  FormEvent,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useBoolean } from "@fluentui/react-hooks";
import icon1 from "../../../assets/Home/create_hospital_bg.png";
import icon4 from "../../../assets/Home/total_hospitals_bg.svg";
import icon3 from "../../../assets/Home/total_devices_bg.svg";
import { ReactComponent as CreateHospitalIcon } from "../../../assets/Home/create_hospital.svg";
import TotalCard from "../components/TotalCard/TotalCard";
import HospitalCard from "../components/HospitalCard/HospitalCard";
import HospitalList from "../components/HospitalList/HospitalList";
import CreateHospital from "../components/CreateHospital/CreateHospital";
import { useNavigate } from "react-router-dom";
import SuccessMessage from "../components/Common/SuccessMessage";
import { AppState } from "../../../rootReducer";
import User from "../components/UserSection/User";
import Paging from "../../../common/paging/Paging";
// import  getHospitals  from "../actions/homeAction";
import {
  getHospitals,
  getHospitalsCount,
  showSpinner,
  getVideos,
  getUsers,
  getHospitalsWithCount,
  closeHospitalModal,
  closeDeleteHospital,
  getHospitalsById,
  showCreateHospital,
  updateActionData,
  getUserById,
  showCreateUser,
  closeUserModal,
  getUserTypeById,
  userDelete,
  videoDelete,
  hospitalDelete,
  clearHospitalCountData,
} from "../actions/homeAction";

import { DefaultButton, ActionButton } from "@fluentui/react/lib/Button";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react/lib/ChoiceGroup";
import Videos from "../components/VideoSection/Video";
import CreateUser from "../components/UserSection/CreateUserModal";
import ReactPaginate from "react-paginate";
// import DeleteModal from "../components/Common/DeleteModal";
import "./Home.scss";
import ShowErrorMessageBar from "../components/Common/ShowErrorMessageBar";
import { showSpinnerLayout } from "../../../Layout/actions/layoutActions";
import DeleteModal from "../components/Common/DeleteModal";
import { showChangePassword } from "../../../auth/actions/authAction";
import { debounce } from "lodash";
import * as constants from "../../../Layout/constants/constants";
import { AnyActions } from "little-state-machine/dist/types";
import CheckPermission from "../../../common/permissions/rolePermissions";
import LoaderWithMessage from "../../../common/customLoader/LoaderWithMessage";

const imageProps: IImageProps = {
  imageFit: ImageFit.none,
  width: "100%",
  height: 103,
  // Show a border around the image (just for demonstration purposes)
  styles: (props) => ({ root: { borderRadius: "2px" } }),
};

const containerStackTokens: IStackTokens = { childrenGap: 27 };

const GridViewIcon: IIconProps = { iconName: "GridViewMedium" };
const ListViewIcon: IIconProps = { iconName: "List" };
const ChevronDownIcon: IIconProps = { iconName: "ChevronDown" };

const options: IChoiceGroupOption[] = [
  { key: "all", text: "Last Created" },
  { key: "modified_date", text: "Last Modified" },
  { key: "a-z", text: "A-Z" },
  { key: "z-a", text: "Z-A" },
];

const optionsUser: IChoiceGroupOption[] = [
  { key: "", text: "All" },
  { key: "admin", text: "Hospital Admin" },
  { key: "surgeon", text: "Surgeon" },
  { key: "developer", text: "Developer" },
  { key: "activ", text: "Activ Admin" },
  { key: "dual", text: "Dual" },
];

const optionsUserWhenHospitalSelected: IChoiceGroupOption[] = [
  { key: "", text: "All" },
  { key: "admin", text: "Hospital Admin" },
  { key: "surgeon", text: "Surgeon" },
];

const devices = [
  { value: 1, label: "Device" },
  { value: 2, label: "Non-Device" },
];

const Home = (props: any) => {
  const {
    register,
    control,
    formState: { errors },
  } = useForm();
  const hospitalPage = useSelector((state: AppState) => {
    return state?.homeReducerData?.hospitalPage;
  });

  const [visibleHospitalList, setVisibleHospitalList] = useState(false);
  const [visibleVideosList, setVisibleVideosList] = useState(false);
  const [visibleUserList, setVisibleUserList] = useState(false);

  const [gridLayout, setgridLayout] = useState(true);
  const [sortOptions, setSortOptions] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<any>(devices[1]);
  const [selectedSortUserKey, setSelectedSortUserKey] = useState<any>("");

  // const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  const [isModalOpen1, { setTrue: showModal1, setFalse: hideModal1 }] =
    useBoolean(false);
  const [openCreateHospital, setOpenCreateHospital] = useState(false);
  const [openDeleteHospitalModal, setOpenDeleteHospitalModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openDeleteVideoModal, setOpenDeleteVideoModal] = useState(false);
  const [hospitalId, setHospitalId] = useState();
  const [userId, setUserId] = useState();
  const [videoId, setVideoId] = useState();
  const selectedHospital = useRef("");

  const [userType, setUserType] = useState<string>();
  const [showPage, setshowPage] = useState<any>(true);

  const [openUserModel, setOpenUserModel] = useState(false);
  const [selectedHospitalWithCount, setSelectedHospitalWithCount] =
    useState("");

  // const [userSpinner, setUserSpinner] = useState(false);
  const [selectedKey, setSelectedKey] = useState("all");
  const [currentUserPage, setCurrentUserPage] = useState<number>(1);
  const [currentHospitalPage, setCurrentHospitalPage] = useState<number>(1);
  const [currentVideoPage, setCurrentVideoPage] = useState<number>();
  const innerRef = useRef<any>();
  const mainInnerRef = useRef<any>();
  const dropDownRef = useRef<any>();
  const [selectedTabKey, setSelectedTabKey] = React.useState<any>(0);
  const [isInitialLanding, setIsInitialLanding] = React.useState(false);
  const [selectedActiveTab, setSelectedActiveTab] = React.useState<any>("");
  const [lastHeader, setLastHeader] = useState<
    { props: IPivotItemProps } | undefined
  >(undefined);

  useEffect(() => {
    if (localStorage.getItem("selectedActiveTab") !== "allVideos") {
      setLastHeader({ props: { headerText: "All Hospitals" } });
    }
  }, []);

  //  console.log(lastHeader, "lastHeader221");
  const [choice, setChoice] = React.useState<boolean | undefined>(false);

  const [searchValue, setSearchValue] = useState("");
  const [
    hospitalsWithCountUsersOptionsState,
    sethospitalsWithCountUsersOptionsState,
  ] = useState<any>();
  const [videoListPermission, setVideoListPermission] =
    React.useState<any>(false);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        sortOptions &&
        innerRef?.current &&
        !innerRef?.current?.contains(e?.target)
      ) {
        setSortOptions(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      // debugger;
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [sortOptions]);

  const showCreateUserModal = useCallback(() => {
    setUserType("");
    setOpenUserModel((current) => !current);
    if (!openUserModel) {
      dispatch(closeUserModal());
    }
  }, []);

  const showModal = useCallback(() => {
    // dispatch(showCreateHospital(false));
    setOpenCreateHospital((current) => !current);
    if (!openCreateHospital) {
      dispatch(closeHospitalModal());
    }
  }, []);

  const _onMenuClickSetId = (id: any) => {
    setHospitalId(id);
  };

  const _onMenuClickSetUserId = useCallback((type: any, id: any) => {
    setUserType(type);
    setUserId(id);
  }, []);

  const _onMenuClickSetVideoId = useCallback((id: any) => {
    setVideoId(id);
  }, []);

  const showEditUserModal = () => {
    setOpenUserModel((current) => !current);
    dispatch(showCreateUser());
    dispatch(getUserTypeById(userType, userId));
    if (openUserModel) {
      dispatch(closeUserModal());
    }
  };

  const showEditModal = () => {
    // const permissionToItem =  constants.getPermissionsbyItem("management.hospital.edit1")
    //  if(permissionToItem){
    setOpenCreateHospital((current) => !current);
    dispatch(showCreateHospital());
    dispatch(getHospitalsById(hospitalId));
    if (openCreateHospital) {
      dispatch(closeHospitalModal());
    }
    // }
  };
  const showDeleteHospitalModal = useCallback((id = 0) => {
    setOpenDeleteHospitalModal((current) => !current);
  }, []);

  const showDeleteUserModal = useCallback(() => {
    setOpenDeleteUserModal((current) => !current);
  }, []);

  const showDeleteVideoModal = useCallback(() => {
    setOpenDeleteVideoModal((current) => !current);
  }, []);

  const deleteHospitalHandle = () => {
    dispatch(hospitalDelete(hospitalId));

    setOpenDeleteHospitalModal((current) => !current);
  };

  const deleteUserHandle = () => {
    // debugger;
    dispatch(userDelete(userId, userType));
    setOpenDeleteUserModal((current) => !current);
  };

  const deleteVideoHandle = () => {
    dispatch(videoDelete(videoId));

    setOpenDeleteVideoModal((current) => !current);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeToGridView = (bool: boolean) => {
    setgridLayout(bool);
  };
  const handleSortOptions = () => {
    setSortOptions((prevValue) => !prevValue);
  };
  const hospitalWithCountLoader = useSelector((state: AppState) => {
    return state?.homeReducerData?.hospitalWithCountLoader;
  });
  // console.log(hospitalWithCountLoader, "hospitalWithCountLoader");
  const hospitals: any = useSelector((state: AppState) => {
    return state?.homeReducerData?.hospitals?.data;
  });

  const successHospital = useSelector((state: AppState) => {
    return state?.homeReducerData?.successHospital;
  });
  const errorHospital = useSelector((state: AppState) => {
    return state?.homeReducerData?.errorHospital;
  });

  const hospitalSpinner = useSelector((state: AppState) => {
    return state.homeReducerData.showSpinner;
  });
  const userSpinner = useSelector((state: AppState) => {
    return state.homeReducerData.showUserSpinner;
  });
  const errorDataProcess = useSelector((state: AppState) => {
    return state.homeReducerData.errorDataProcess;
  });

  const dataProcessSpinner = useSelector((state: AppState) => {
    return state.homeReducerData.dataProcessSpinner;
  });

  const UserProfileSpinner = useSelector((state: AppState) => {
    return state.dashboardReducerData.UserProfileSpinner;
  });

  const ChangePasswordSpinner = useSelector((state: AppState) => {
    return state.authReducerData.ChangePasswordSpinner;
  });

  const hospitalsCount = useSelector((state: AppState) => {
    return state?.homeReducerData?.hospitalsCount?.data;
  });

  const videos = useSelector((state: AppState) => {
    return state?.homeReducerData?.videos?.data;
  });
  const users = useSelector((state: AppState) => {
    return state?.homeReducerData?.users?.data;
  });

  const hospitalsWithCounts = useSelector((state: AppState) => {
    return state?.homeReducerData?.hospitalsWithCount?.data;
  });
  const createHospitalError = useSelector((state: AppState) => {
    return state.homeReducerData.createHospitalError;
  });

  const errorUser = useSelector((state: AppState) => {
    return state.homeReducerData.errorUser;
  });

  const deleteVideo = useSelector((state: AppState) => {
    return state.homeReducerData.deleteVideo?.data;
  });

  const deleteUser = useSelector((state: AppState) => {
    return state.homeReducerData.deleteUser?.data;
  });

  const deleteHospital = useSelector((state: AppState) => {
    return state.homeReducerData.deleteHospital?.data;
  });

  const showCreateHospitalSuccess = useSelector((state: AppState) => {
    return state.homeReducerData.showCreateHospital;
  });

  const showCreateUserSuccess = useSelector((state: AppState) => {
    return state.homeReducerData.showCreateUser;
  });

  const loaderWithMessageHospitals = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessageHospitals;
  });

  const loaderWithMessageVideos = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessageVideos;
  });

  const loaderWithMessageUsers = useSelector((state: AppState) => {
    return state.homeReducerData.loaderWithMessageUsers;
  });

  const hospitalsWithCountsCount = hospitalsWithCounts?.count;

  function getUniqueListBy(arr: any, key: any) {
    return (
      arr &&
      arr.filter(
        (ele: any, ind: any) =>
          ind === arr.findIndex((elem: any) => elem[key] === ele[key])
      )
    );
  }

  const hospitalsWithCountOptions = getUniqueListBy(
    hospitalsWithCounts?.results,
    "id"
  )?.map((hospitalsWithCount: any) => ({
    value: hospitalsWithCount.id,
    label: hospitalsWithCount.name,
    count: `(${hospitalsWithCount.total_videos})`,
  }));

  const hospitalsWithCountUsersOptions = useCallback(() => {
    const values = getUniqueListBy(hospitalsWithCounts?.results, "id")?.map(
      (hospitalsWithCount: any) => ({
        value: hospitalsWithCount.id,
        label: hospitalsWithCount.name,
        count: `(${hospitalsWithCount.total_users})`, // count:hospitalsWithCount.total_videos
      })
    );
    sethospitalsWithCountUsersOptionsState(values);
  }, [hospitalsWithCounts]);

  useEffect(() => {
    videos?.results === 0 &&
      dispatch(getVideos(selectedDevice.value, selectedHospitalWithCount));
    hospitalsWithCountUsersOptions();
  }, [hospitalsWithCounts]);

  useEffect(() => {
    dispatch(getHospitalsCount());
  }, []);

  useEffect(() => {
    onPageChange(currentUserPage);
  }, [deleteUser]);

  useEffect(() => {
    if (deleteVideo) {
      dispatch(getVideos(selectedDevice.value));
    }
  }, [deleteVideo]);

  useEffect(() => {
    dispatch(getHospitals(searchValue, currentHospitalPage, selectedKey));
    hospitalsCount?.length <= 0 && dispatch(getHospitalsCount());
  }, [deleteHospital]);

  useEffect(() => {
    setshowPage(true);
  }, [searchValue]);

  const resetChoice = React.useCallback(
    () => setChoice((current) => !current),
    []
  );
  const onPageChange = (page: any) => {
    setCurrentUserPage(page);
    dispatch(
      getUsers(
        page,
        selectedSortUserKey,
        searchValue,
        selectedHospitalWithCount
      )
    );
  };
  useEffect(() => {
    localStorage.setItem("currentUserPage", String(currentUserPage));
  }, [currentUserPage]);
  useEffect(() => {
    const permission = constants.getPermissionsbyItem("vps.videos.list");
    setVideoListPermission(permission);
    const currentUserPage = localStorage.getItem("currentUserPage") || 1;
    setCurrentUserPage(Number(currentUserPage));
    onPageChange(currentUserPage);
  }, []);
  const onHospitalPageChange = useCallback(
    (pageNo, nsearchValue?: any) => {
      setCurrentHospitalPage(pageNo);
      dispatch(getHospitals(nsearchValue || searchValue, pageNo, selectedKey));
      setTimeout(() => {
        setshowPage(true);
      }, 1000);
    },
    [selectedKey, searchValue]
  );

  const onVideoPageChange = useCallback(
    (pageNo) => {
      setCurrentVideoPage(pageNo);
      dispatch(
        getVideos(
          selectedDevice.value,
          selectedHospital.current,
          pageNo,
          searchValue
        )
      );
      setTimeout(() => {
        setshowPage(true);
      }, 1000);
    },
    [searchValue, selectedDevice]
  );
  const getSelectedTab = (props?: any) => {
    localStorage.removeItem("nextVideoUrl");
    dispatch(clearHospitalCountData());
    dispatch(getHospitalsWithCount(2)); // while change tab
    setSearchValue("");
    setSelectedKey("");
    setSelectedActiveTab(props?.props?.headerText);
    setLastHeader(props);
    if (props?.props?.headerText === "All Videos") {
      localStorage.setItem("selectedActiveTab", "allVideos");
      setCurrentVideoPage(1);
      dispatch(getVideos(2, "", 1, ""));
    } else {
      localStorage.setItem("selectedActiveTab", props?.props?.headerText);
      selectedHospital.current = "";
      setSelectedDevice(devices[1]);
      setCurrentVideoPage(Number(currentVideoPage));
      dispatch(getHospitalsCount());
    }
    const getSelectedIndex = props?.key?.split(".")[1];
    setSelectedTabKey(Number(getSelectedIndex));
  };
  // on page load
  useEffect(() => {
    dispatch(getHospitalsWithCount(2));
  }, []);
  useEffect(() => {
    const isVideoTab = localStorage.getItem("selectedActiveTab");
    const currentVideoPage: any = localStorage.getItem("currentVideoPage");
    const currentSelectedHospital: any = localStorage.getItem(
      "currentSelectedHospital"
    );
    const selectedDevice: any = localStorage.getItem("currentSelectedDevice");
    const searchValue: any = localStorage.getItem("searchValue") || "";
    if (isVideoTab === "allVideos" || isVideoTab === "All Videos") {
      selectedHospital.current = JSON.parse(currentSelectedHospital)?.current;
      setSelectedDevice(JSON.parse(selectedDevice));
      setSelectedTabKey(1);
      setCurrentVideoPage(Number(currentVideoPage));
      // setLastHeader({props:{headerText: "All Videos"}});
      setSearchValue(searchValue);
      setIsInitialLanding(true);
      dispatch(
        getVideos(
          JSON.parse(selectedDevice)?.value,
          selectedHospital?.current,
          currentVideoPage,
          searchValue
        )
      );
      setTimeout(() => {
        setIsInitialLanding(false);
      }, 3000);
      // onVideoPageChange(currentVideoPage,JSON.parse(selectedDevice),selectedHospital);
    }
  }, []);
  useEffect(() => {
    const selectedHospitalVal: any = selectedHospital;
    localStorage.setItem("currentVideoPage", String(currentVideoPage));
    localStorage.setItem(
      "currentSelectedHospital",
      JSON.stringify(selectedHospitalVal)
    );
    localStorage.setItem(
      "currentSelectedDevice",
      JSON.stringify(selectedDevice)
    );
    localStorage.setItem("searchValue", String(searchValue));
    if (selectedTabKey === 1) {
      localStorage.setItem("selectedActiveTab", "allVideos");
    }
  }, [
    currentVideoPage,
    selectedHospital?.current,
    selectedDevice,
    searchValue,
  ]);
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        mainInnerRef?.current &&
        !mainInnerRef?.current?.contains(e?.target)
      ) {
        localStorage.removeItem("selectedActiveTab");
        localStorage.removeItem("currentVideoPage");
        localStorage.removeItem("currentSelectedHospital");
        localStorage.removeItem("currentSelectedDevice");
        localStorage.removeItem("searchValue");
        localStorage.removeItem("nextVideoUrl");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, []);
  // hospital search in All video tab
  const hospitalInputHandler = useCallback(
    debounce((evt: any, value: any) => {
      dispatch(getHospitalsWithCount(value, evt));
    }, 2000),
    []
  );
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    localStorage.removeItem("selectedActiveTab");
    localStorage.removeItem("currentVideoPage");
    localStorage.removeItem("currentSelectedHospital");
    localStorage.removeItem("currentSelectedDevice");
    localStorage.removeItem("searchValue");
  };
  const hospitalInputChangeHandler = (evt: any) => {
    const value = evt;
    if (value.length >= 3) hospitalInputHandler(value, selectedDevice.value);
  };
  //dropdown scroll to bottom load data for All video and All Users Tab
  // const handleScrollAlg = (evt: any) => {
  //   if (hospitalsWithCounts?.next) {
  //     dispatch(
  //       getHospitalsWithCount(
  //         selectedDevice.value,
  //         "",
  //         "",
  //         hospitalsWithCounts?.next
  //       )
  //     );
  //   }
  // };

  const handleScrollAlg = useCallback(
    debounce((evt: any) => {
      dispatch(getHospitalsWithCount(selectedDevice.value, "", "", evt));
    }, 1000),
    []
  );

  const debounceFnVideos = useCallback(
    debounce(handleDebounceFnVideos, 1000),
    []
  );
  function handleDebounceFnVideos(
    selectedDevice?: any,
    selectedHospital?: any,
    page?: any,
    searchText?: any
  ) {
    setCurrentVideoPage(1);
    dispatch(
      getVideos(selectedDevice.value, selectedHospital.current, 1, searchText)
    );
  }
  const debounceFnUser = useCallback(debounce(handleDebounceFnUser, 1000), []);
  function handleDebounceFnUser(
    page?: any,
    selectedSortUserKey?: any,
    searchValue?: any,
    selectedHospitalWithCount?: any
  ) {
    setCurrentUserPage(1);
    dispatch(
      getUsers(1, selectedSortUserKey, searchValue, selectedHospitalWithCount)
    );
  }
  const debounceFnHospitals = useCallback(
    debounce(handleDebounceFnHospitals, 1000),
    []
  );
  function handleDebounceFnHospitals(
    searchValue?: any,
    page?: any,
    selectedKey?: any
  ) {
    setCurrentUserPage(1);
    dispatch(getHospitals(searchValue, page, selectedKey));
  }
  // search data base on active tab
  const handleChange = (searchText: any) => {
    if (searchText.length > 3 || searchText.length === 0)
      if (selectedActiveTab === "All Users" || selectedTabKey === 2) {
        debounceFnUser(
          1,
          selectedSortUserKey,
          searchText,
          selectedHospitalWithCount
        );
      }
    if (selectedActiveTab === "All Videos" || selectedTabKey === 1) {
      debounceFnVideos(selectedDevice, selectedHospital, 1, searchText);
    }
    if (selectedActiveTab === "All Hospitals" || selectedTabKey === 0) {
      debounceFnHospitals(searchText, 1, selectedKey);
    }
  };

  const onChangeSortBy = React.useCallback(
    (ev: React.FormEvent, option: IChoiceGroupOption) => {
      setSelectedKey(option.key);
    },
    []
  );
  useEffect(() => {
    setCurrentHospitalPage(1);
    setCurrentUserPage(1);
    setSelectedHospitalWithCount("");
    setSelectedSortUserKey("");
    if (lastHeader?.props.headerText === "All Users") {
      dispatch(getUsers(1));
    }
    if (lastHeader?.props.headerText === "All Hospitals") {
      onHospitalPageChange(1);
      dispatch(getHospitalsCount());
    }
  }, [lastHeader?.props.headerText]);
  // useEffect(() => {
  //   if (
  //     lastHeader?.props.headerText === "All Hospitals" &&
  //     (searchValue.length > 3 || searchValue.length == 0)
  //   ) {
  //     onHospitalPageChange(1, searchValue);
  //   }
  // }, [searchValue]);
  // const listener = (event:any) => {
  //   setSortOptions(false);
  //   console.log(event,'event');
  // };

  // document.addEventListener("mousedown", listener);
  const VideoOption = (props: any) => {
    return (
      <components.Option {...props} className="justify-space-between">
        <span>{props.data.label}</span>
        <span>{props.data.count}</span>
      </components.Option>
    );
  };

  useEffect(() => {
    if (
      loaderWithMessageHospitals &&
      loaderWithMessageHospitals?.calledFrom === "GET_HOSPITALS" &&
      loaderWithMessageHospitals?.statusCode !== 403 &&
      !loaderWithMessageHospitals?.loadingState
    ) {
      setVisibleHospitalList(true);
    }
    if (
      loaderWithMessageHospitals?.calledFrom === "GET_HOSPITALS" &&
      loaderWithMessageHospitals?.statusCode == 403
    ) {
      setVisibleHospitalList(false);
    }

    if (
      loaderWithMessageVideos &&
      loaderWithMessageVideos?.calledFrom === "GET_VIDEOS" &&
      loaderWithMessageVideos?.statusCode !== 403 &&
      !loaderWithMessageVideos?.loadingState
    ) {
      setVisibleVideosList(true);
    }
    if (
      loaderWithMessageVideos?.calledFrom === "GET_VIDEOS" &&
      loaderWithMessageVideos?.statusCode == 403
    ) {
      setVisibleVideosList(false);
    }

    if (
      loaderWithMessageUsers &&
      loaderWithMessageUsers?.calledFrom === "GET_USERS" &&
      loaderWithMessageUsers?.statusCode !== 403 &&
      !loaderWithMessageUsers?.loadingState
    ) {
      setVisibleUserList(true);
    }
    if (
      loaderWithMessageUsers?.calledFrom === "GET_USERS" &&
      loaderWithMessageUsers?.statusCode == 403
    ) {
      setVisibleUserList(false);
    }
  }, [
    loaderWithMessageHospitals,
    loaderWithMessageVideos,
    loaderWithMessageUsers,
  ]);

  const trackScrolling = () => {
    handleScrollAlg(localStorage.getItem("nextVideoUrl"));
  };

  useEffect(() => {
    localStorage.setItem("nextVideoUrl", hospitalsWithCounts?.next);
  }, [hospitalsWithCounts?.next]);

  return (
    <div ref={mainInnerRef}>
      <Stack>
        {/* Top card section */}
        <Stack
          horizontal
          tokens={containerStackTokens}
          className="homeTopCardSection"
        >
          <CheckPermission {...constants.SCOPES.CREATE_HOSPITAL}>
            <StackItem
              onClick={showModal}
              styles={{
                root: {
                  flex: 1,
                  position: "relative",
                  borderRadius: "2px",
                  cursor: "pointer",
                },
              }}
            >
              <Stack horizontal>
                <Image
                  className="createHospitalCard"
                  {...imageProps}
                  src={icon1}
                  alt="create hospital"
                />
                <Stack.Item className="HospitalCardTextOverlay">
                  <CreateHospitalIcon />
                  <Text>Create Hospital</Text>
                </Stack.Item>
              </Stack>
            </StackItem>
          </CheckPermission>
          <TotalCard
            Imgsrc={icon4}
            cname="totalHospitals"
            name={"Hospitals"}
            hospitalsCount={hospitalsCount?.hospitals}
          />
          <TotalCard
            Imgsrc={icon3}
            cname="totalDevices"
            name={"Devices"}
            hospitalsCount={hospitalsCount?.devices}
          />
        </Stack>
        {openCreateHospital && (
          <CreateHospital
            isModalOpen={openCreateHospital}
            showModal={showModal}
            hospitalId={hospitalId}
          ></CreateHospital>
        )}

        <Stack.Item className="allHospitalsSection">
          {/*----------------- All Hospitals Section----------------- */}

          <Stack.Item className="searchBoxSection">
            <SearchBox
              className="searchBox"
              placeholder="Search"
              underlined={true}
              onSearch={(newValue: any) => {}}
              value={searchValue}
              // onClear={(ev:any) => {
              //   console.log(ev);
              //   // setSearchValue(newValue);

              // }}
              onChange={(_, newValue: any) => {
                setshowPage(false);
                setSearchValue(newValue);
                setCurrentHospitalPage(1);
                if (newValue.length > 3 || newValue.length === 0) {
                  handleChange(newValue);
                }
              }}
            />
          </Stack.Item>
          <Pivot
            aria-label="Basic Pivot Example"
            onLinkClick={(props: any = lastHeader) => {
              getSelectedTab(props);
            }}
            selectedKey={String(selectedTabKey)}
          >
            {/*----------------- Hospital Card and List--------------- */}
            <PivotItem headerText="All Hospitals">
              <Stack.Item className="allHospitalsSectionHeading">
                <Stack
                  tokens={{ padding: "7.5px 24px" }}
                  className="positionRelative"
                >
                  {/* Sort and layout shift buttons*/}
                  <Stack.Item className="allHospitalsSectionButtons">
                    <div ref={innerRef}>
                      <Stack>
                        <ActionButton
                          className="sortDropdown"
                          onClick={handleSortOptions}
                          allowDisabledFocus
                        >
                          <Text className="sortDropdownHeading" key="sort">
                            Sort
                          </Text>
                          {sortOptions ? (
                            <Icon
                              iconName="ChevronUp"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          ) : (
                            <Icon
                              iconName="ChevronDown"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          )}
                        </ActionButton>
                      </Stack>

                      {sortOptions ? (
                        <Stack className="sortOptionsDropdown">
                          <Stack.Item className="sortOptionsDropdownHeading">
                            Sort By
                          </Stack.Item>
                          <ChoiceGroup
                            defaultSelectedKey=""
                            selectedKey={selectedKey}
                            options={options}
                            // onChange={onChangeSortBy}
                            onChange={(
                              evt: any,
                              option?: IChoiceGroupOption
                            ) => {
                              // handleChange(evt, data, option, "procedureName");
                              const value: any = option;
                              setshowPage(false);
                              setCurrentHospitalPage(1);
                              value.key !== "" &&
                                dispatch(
                                  getHospitals(searchValue, 1, value.key)
                                );
                              setSelectedKey(value.key);
                              setTimeout(() => {
                                setshowPage(true);
                              }, 1000);
                            }}
                          />
                        </Stack>
                      ) : (
                        ""
                      )}
                    </div>
                  </Stack.Item>
                  <Stack tokens={{ padding: "8px 0" }} dir="ltr" horizontal>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 24 }}
                      className="width-100"
                    >
                      <Stack.Item className="ms-Grid width-100" dir="ltr">
                        <Stack.Item className="ms-Grid-row">
                          {visibleHospitalList &&
                            hospitals &&
                            hospitals?.results &&
                            hospitals?.results.map((hospital: any) => (
                              <Stack.Item
                                key={hospital?.id}
                                className="ms-Grid-col ms-lg3"
                              >
                                <HospitalCard
                                  hospital={hospital}
                                  key={hospital?.id}
                                  showDeleteHospitalModal={
                                    showDeleteHospitalModal
                                  }
                                  showEditModal={showEditModal}
                                  _onMenuClickSetId={_onMenuClickSetId}
                                />
                              </Stack.Item>
                            ))}
                        </Stack.Item>
                        {visibleHospitalList &&
                        hospitals?.count > 12 &&
                        hospitals?.page_size &&
                        showPage ? (
                          <Paging
                            currentPage={currentHospitalPage}
                            totalPageCount={Math.ceil(hospitals?.count / 12)}
                            handlePageChange={onHospitalPageChange}
                          />
                        ) : null}
                      </Stack.Item>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack.Item>
            </PivotItem>

            {/*-----------------All Videos Card and List--------------- */}
            <PivotItem headerText="All Videos">
              <Stack.Item className="allHospitalsSectionHeading">
                <Stack
                  tokens={{ padding: "7.5px 24px" }}
                  className="positionRelative"
                >
                  <Stack className="ms-Grid" dir="ltr">
                    <Stack.Item className="ms-Grid-row">
                      <Stack.Item className="ms-Grid-col ms-lg3">
                        <Controller
                          control={control}
                          defaultValue={devices[1].value}
                          {...register("system")}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              classNamePrefix="addl-class"
                              options={devices}
                              value={selectedDevice}
                              onChange={(val) => {
                                // selectedHospital.current = "";
                                onChange(val?.value);
                                setSelectedDevice(val);
                                setCurrentVideoPage(1);
                                setshowPage(false);
                                dispatch(clearHospitalCountData());
                                dispatch(getHospitalsWithCount(val?.value));
                                selectedHospital.current = "";
                                // not required below APi
                                // dispatch(getVideos(val?.value, ""));
                                dispatch(
                                  getVideos(
                                    val?.value,
                                    selectedHospital.current,
                                    1,
                                    searchValue
                                  )
                                );
                                setTimeout(() => {
                                  setshowPage(true);
                                }, 1000);
                              }}
                            />
                          )}
                        />
                      </Stack.Item>
                      <Stack.Item className="ms-Grid-col ms-lg3">
                        <Controller
                          control={control}
                          // defaultValue=""
                          {...register("videos")}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              isDisabled={!visibleHospitalList}
                              ref={dropDownRef}
                              className="custm-input"
                              // clearValue={valueClear}
                              components={{ Option: VideoOption }}
                              isClearable
                              isSearchable
                              isLoading={hospitalWithCountLoader}
                              onMenuOpen={() => {
                                function getNextPage() {
                                  const da = document.getElementsByClassName(
                                    "addl-class__menu-list"
                                  )[0];
                                  da &&
                                    da.addEventListener("scroll", (e: any) => {
                                      // if (e?.target?.scrollTop >= 3190) {
                                      //     console.log("Reached bottom ---------------");
                                      //     trackScrolling()
                                      // }
                                      const bottom =
                                        Math.round(e.target.scrollHeight) -
                                          Math.round(e.target.scrollTop) ===
                                        e.target.clientHeight;
                                      if (bottom) {
                                        // console.log("Reached bottom");
                                        trackScrolling();
                                      }
                                    });
                                }
                                setTimeout(() => {
                                  getNextPage();
                                }, 1000);
                                // setTimeout(()=> {
                                //   let da = document.getElementsByClassName('addl-class__menu-list')[0]
                                //   da && da.addEventListener("scroll", (e:any) => {
                                //     console.log(e?.target?.scrollTop,'topppp',nextUrl)
                                //     if (e?.target?.scrollTop >= 3190) {
                                //         console.log("Reached bottom ---------------");
                                //        // trackScrolling()
                                //         handleScrollAlg(nextUrl)
                                //     }
                                // });
                                // },1000)

                                //setTimeout(trackScrolling,10,a);
                                // setTimeout(trackScrolling, 2000, hospitalsWithCounts?.next);
                              }}
                              onInputChange={(e) => {
                                hospitalInputChangeHandler(e);
                              }}
                              onMenuScrollToBottom={(e) =>
                                handleScrollAlg(hospitalsWithCounts?.next)
                              }
                              classNamePrefix="addl-class"
                              options={hospitalsWithCountOptions}
                              placeholder={
                                !visibleHospitalList
                                  ? "You do not have permission"
                                  : "All Videos"
                              }
                              // value={hospitalsWithCountOptions?.find(
                              //   (c: any) => c.value === value
                              // )}
                              value={
                                selectedHospital.current &&
                                hospitalsWithCountOptions?.find(
                                  (c: any) =>
                                    c.value === selectedHospital.current
                                )
                              }
                              // components={{ Option }}
                              onChange={(val) => {
                                selectedHospital.current = val?.value;
                                setSelectedHospitalWithCount(val?.value);
                                setshowPage(false);
                                onVideoPageChange(1);
                                // if (!val?.value) {
                                //   dispatch(
                                //     getHospitalsWithCount(selectedDevice.value)
                                //   );
                                // }
                                // dispatch(
                                //   getVideos(selectedDevice.value, val?.value)
                                // );
                              }}
                            />
                          )}
                        />
                        {/* <Dropdown
                      
                        placeholder="Select an option"
                        label="Custom label"
                        ariaLabel="Custom dropdown label example"
                        options={hospitalsWithCountOptions1}
                        onChange={(evt: FormEvent, option?: IDropdownOption) => {
                          // handleChange(evt, data, option, "procedureName");
                          let value: any = option;
                          setSelectedHospitalWithCount(value.key);
                          dispatch(getVideos(value.key));
                          console.log(value.key,option);
                        }}
                      /> */}
                      </Stack.Item>
                    </Stack.Item>
                  </Stack>
                  {videoListPermission ? (
                    <Stack horizontal tokens={{ childrenGap: 24 }}>
                      <Stack.Item className="ms-Grid width-100" dir="ltr">
                        <Stack.Item className="ms-Grid-row">
                          {videos?.results.map((video: any) => (
                            <Stack.Item
                              key={video.id}
                              className="ms-Grid-col ms-lg3"
                            >
                              <Videos
                                video={video}
                                key={video.id}
                                videoId={video.id}
                                _onMenuClickSetVideoId={_onMenuClickSetVideoId}
                                showDeleteVideoModal={showDeleteVideoModal}
                                is_uploaded_by_user={selectedDevice?.value == 2}
                                loadedFrom={constants.ROLE_TYPES.ACTIVE_ADMIN}
                              ></Videos>
                            </Stack.Item>
                          ))}
                        </Stack.Item>
                        {videos?.count > 12 && videos?.page_size ? (
                          <Paging
                            currentPage={currentVideoPage}
                            totalPageCount={Math.ceil(videos?.count / 12)}
                            handlePageChange={onVideoPageChange}
                          />
                        ) : null}
                      </Stack.Item>
                    </Stack>
                  ) : (
                    <Stack style={{ padding: "10%", alignItems: "center" }}>
                      <Text>
                        You do not have permission to perform this action.
                      </Text>
                    </Stack>
                  )}
                </Stack>
              </Stack.Item>
            </PivotItem>

            {/*----------------- User Card and List--------------- */}
            <PivotItem headerText="All Users" id="userSection">
              <Stack.Item className="allHospitalsSectionHeading">
                <Stack
                  tokens={{ padding: "7.5px 24px" }}
                  className="positionRelative "
                >
                  <Stack.Item className="allHospitalsSectionButtons space-between">
                    <Stack
                      horizontal
                      verticalAlign="center"
                      dir="ltr"
                      horizontalAlign="center"
                      className="createUserButton"
                      onClick={showCreateUserModal}
                    >
                      <CreateHospitalIcon />
                      <Text> New User</Text>
                    </Stack>

                    <Stack dir="ltr" horizontal>
                      <Stack.Item
                        className=""
                        style={{
                          width: "230px",
                          marginRight: "10px",
                        }}
                      >
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register("videos")}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              // clearValue={valueClear}
                              isDisabled={!visibleHospitalList}
                              isClearable
                              isSearchable
                              classNamePrefix="addl-class"
                              //onKeyDown={(e) => hospitalInputChangeHandler(e)}
                              onInputChange={(e) => {
                                hospitalInputChangeHandler(e);
                              }}
                              onMenuOpen={() => {
                                function getNextPage() {
                                  const da = document.getElementsByClassName(
                                    "addl-class__menu-list"
                                  )[0];
                                  da &&
                                    da.addEventListener("scroll", (e: any) => {
                                      // if (e?.target?.scrollTop >= 3190) {
                                      //     console.log("Reached bottom ---------------");
                                      //     trackScrolling()
                                      // }
                                      const bottom =
                                        Math.round(e.target.scrollHeight) -
                                          Math.round(e.target.scrollTop) ===
                                        e.target.clientHeight;
                                      if (bottom) {
                                        // console.log("Reached bottom");
                                        trackScrolling();
                                        // handleScrollAlg(localStorage.getItem('nextUserUrl'))
                                      }
                                    });
                                }
                                setTimeout(() => {
                                  getNextPage();
                                }, 1000);
                                // setTimeout(()=> {
                                //   let da = document.getElementsByClassName('addl-class__menu-list')[0]
                                //   da && da.addEventListener("scroll", (e:any) => {
                                //     console.log(e?.target?.scrollTop,'topppp',nextUrl)
                                //     if (e?.target?.scrollTop >= 3190) {
                                //         console.log("Reached bottom ---------------");
                                //        // trackScrolling()
                                //         handleScrollAlg(nextUrl)
                                //     }
                                // });
                                // },1000)

                                //setTimeout(trackScrolling,10,a);
                                // setTimeout(trackScrolling, 2000, hospitalsWithCounts?.next);
                              }}
                              onMenuScrollToBottom={(e) =>
                                handleScrollAlg(hospitalsWithCounts?.next)
                              }
                              options={hospitalsWithCountUsersOptionsState}
                              components={{ Option: VideoOption }}
                              placeholder={
                                !visibleHospitalList
                                  ? "You do not have permission"
                                  : "All Users"
                              }
                              value={hospitalsWithCountUsersOptionsState?.find(
                                (c: any) => c.value === value
                              )}
                              // components={{ Option }}
                              onChange={(val) => {
                                setSelectedHospitalWithCount(val?.value);
                                setCurrentUserPage(1);
                                setshowPage(false);
                                if (!val?.value) {
                                  setSelectedHospitalWithCount("");
                                  dispatch(getHospitalsWithCount(2));
                                }
                                dispatch(
                                  getUsers(
                                    1,
                                    selectedSortUserKey,
                                    searchValue,
                                    val?.value || ""
                                  )
                                );
                                //  dispatch(getUsers(1, "", "", val?.value));
                              }}
                            />
                          )}
                        />
                      </Stack.Item>
                      <div ref={innerRef}>
                        <ActionButton
                          className="sortDropdown"
                          onClick={handleSortOptions}
                          allowDisabledFocus
                        >
                          <Text className="sortDropdownHeading">Filter</Text>
                          {sortOptions ? (
                            <Icon
                              iconName="ChevronUp"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          ) : (
                            <Icon
                              iconName="ChevronDown"
                              className=" ms-fontSize-12 sortDropdownIcons"
                            />
                          )}
                        </ActionButton>
                        {sortOptions ? (
                          <Stack className="sortOptionsDropdown sortOptionsUserDropdown">
                            <Stack.Item className="sortOptionsDropdownHeading">
                              Filter By
                            </Stack.Item>
                            <ChoiceGroup
                              defaultSelectedKey=""
                              options={
                                selectedHospitalWithCount
                                  ? optionsUserWhenHospitalSelected
                                  : optionsUser
                              }
                              selectedKey={selectedSortUserKey}
                              onChange={(
                                evt: any,
                                option?: IChoiceGroupOption
                              ) => {
                                // handleChange(evt, data, option, "procedureName");
                                const value: any = option;
                                setshowPage(false);
                                setSelectedSortUserKey(value?.key);
                                setCurrentUserPage(1);
                                dispatch(
                                  getUsers(
                                    1,
                                    value?.key,
                                    searchValue,
                                    selectedHospitalWithCount
                                  )
                                );
                              }}
                            />
                            {/* <ChoiceGroup
                        defaultSelectedKey="A"
                        // selectedKey={selectedSortUserKey}
                        options={optionsUser}
                      // selectedKey={optionsUser?.find((c: any) => c.key === value)}
                      // onChange={(evt: FormEvent, option?: IChoiceGroupOption) => {
                      //   handleChange(evt, data, option, "procedureName");
                      //   let value: any = option;
                      // }} */}

                            {/* /> */}
                            {/* <Controller
                        name={`sort`}
                        control={control}
                        // defaultValue={false}
                        render={({ field: { onChange, value, name, ref } }) => {
                          return (
                            <Stack horizontal dir="ltr">
                              <ChoiceGroup
                                defaultSelectedKey="A"
                                // selectedKey={selectedSortUserKey}
                                options={optionsUser}
                                // selectedKey={optionsUser?.find((c: any) => c.key === value)}
                                onChange={(val) => {
                                  onChangeUser(val);
                                }}
                              />
                            </Stack>
                          );
                        }}
                      /> */}
                          </Stack>
                        ) : (
                          ""
                        )}
                      </div>
                    </Stack>
                  </Stack.Item>
                  <Stack horizontal tokens={{ childrenGap: 24 }}>
                    <Stack.Item className="ms-Grid width-100" dir="ltr">
                      <Stack.Item className="ms-Grid-row">
                        {visibleUserList && users?.results?.length === 0 ? (
                          <div className="no-data-img">
                            <img
                              style={{ height: "320px", width: "280px" }}
                              src={no_data}
                            />
                            <Text className="no-data-img">
                              No User Data Available.
                            </Text>
                          </div>
                        ) : (
                          <>
                            {visibleUserList &&
                              users?.results?.map((user: any, index: any) => (
                                <Stack.Item
                                  key={user?.userId}
                                  className="ms-Grid-col ms-lg3"
                                >
                                  <User
                                    user={user}
                                    key={index}
                                    _onMenuClickSetUserId={
                                      _onMenuClickSetUserId
                                    }
                                    showEditUserModal={showEditUserModal}
                                    showDeleteUserModal={showDeleteUserModal}
                                  ></User>
                                </Stack.Item>
                              ))}
                          </>
                        )}
                      </Stack.Item>
                      {visibleUserList &&
                      users?.count > 12 &&
                      users?.page_size ? (
                        <Paging
                          currentPage={currentUserPage}
                          totalPageCount={Math.ceil(users?.count / 12)}
                          handlePageChange={onPageChange}
                        />
                      ) : null}
                    </Stack.Item>
                  </Stack>
                  {/* <Modal
                  isOpen={hospitalSpinner}
                  onDismiss={hideModal1}
                  containerClassName={"modalStyle"}
                >
                  <SuccessMessage title="Hospital Creation" />
                </Modal>
                {hospitalSpinner &&
                  setTimeout(() => {
                    setOpenCreateHospital(false);
                    dispatch(showSpinner());
                  }, 3000)} */}
                </Stack>
              </Stack.Item>
            </PivotItem>
          </Pivot>
          <CreateUser
            isModalOpen={openUserModel}
            showModal={showCreateUserModal}
            userId={userId}
            userType={userType}
            visibleHospitalList={visibleHospitalList}
          ></CreateUser>
        </Stack.Item>
        <Modal
          isOpen={hospitalSpinner && !errorHospital}
          containerClassName={"modalStyle"}
        >
          <SuccessMessage
            title={`Hospital ${
              !showCreateHospitalSuccess ? "Creation" : "Updated"
            }`}
          />
        </Modal>
        {(hospitalSpinner || createHospitalError) &&
          setTimeout(() => {
            setOpenCreateHospital(false);
            openCreateHospital &&
              dispatch(
                getHospitals(searchValue, currentHospitalPage, selectedKey)
              );
            openCreateHospital && dispatch(getHospitalsCount());
            dispatch(showSpinner());
          }, 2000) && <></>}

        <Modal isOpen={userSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage
            title={`User ${!showCreateUserSuccess ? "Creation" : "Updated"}`}
          />
        </Modal>

        {(userSpinner || errorUser) &&
          setTimeout(() => {
            setOpenUserModel(false);
            // openUserModel && onPageChange(currentUserPage);
            dispatch(showSpinner());
          }, 2000) && <></>}
        {useEffect(() => {
          openUserModel && onPageChange(currentUserPage);
          dispatch(clearHospitalCountData());
          dispatch(getHospitalsWithCount(2));
        }, [userSpinner, errorUser])}
        {/* 
      <Modal isOpen={dataProcessSpinner} containerClassName={"modalStyle"}>
        <SuccessMessage title="Initiated process" />
      </Modal>

      {(dataProcessSpinner || errorDataProcess) &&
        setTimeout(() => {
          dispatch(showSpinner());
        }, 2000) && <></>} */}

        <Modal isOpen={UserProfileSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage title="User Profile Updated" />
        </Modal>
        {UserProfileSpinner &&
          setTimeout(() => {
            dispatch(showSpinnerLayout());
          }, 2000) && <></>}

        <Modal isOpen={ChangePasswordSpinner} containerClassName={"modalStyle"}>
          <SuccessMessage
            title="Password Changed"
            subText="To Continue Please Login Again..."
          />
        </Modal>
        {ChangePasswordSpinner &&
          setTimeout(() => {
            dispatch(showChangePassword());
          }, 2000) && <></>}

        {/* {(errorHospital && !choice) && (
        <ErrorMessageBar resetChoice={resetChoice}></ErrorMessageBar>
      )} */}
        <DeleteModal
          isModalOpen={openDeleteHospitalModal}
          showModal={showDeleteHospitalModal}
          deleteHandle={deleteHospitalHandle}
          id={hospitalId}
          name="Hospital"
        ></DeleteModal>

        <DeleteModal
          isModalOpen={openDeleteUserModal}
          showModal={showDeleteUserModal}
          deleteHandle={deleteUserHandle}
          id={userId}
          name="User"
        ></DeleteModal>

        <DeleteModal
          isModalOpen={openDeleteVideoModal}
          deleteHandle={deleteVideoHandle}
          showModal={showDeleteVideoModal}
          id={videoId}
          name="Video"
        ></DeleteModal>
        {/* <OutsideClickHandler
        onOutsideClick={() => {
          sortOptions && setSortOptions(false);
        }}
      >
      </OutsideClickHandler> */}
        {lastHeader?.props.headerText === "All Users" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageUsers}
          ></LoaderWithMessage>
        )}
        {lastHeader?.props.headerText === "All Videos" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageVideos}
          ></LoaderWithMessage>
        )}
        {lastHeader?.props.headerText === "All Hospitals" && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageHospitals}
          ></LoaderWithMessage>
        )}
        {/* {lastHeader?.props.headerText === undefined && (
          <LoaderWithMessage
            loaderWithMessage={loaderWithMessageHospitals}
          ></LoaderWithMessage>
        )} */}
      </Stack>
    </div>
  );
};

export default React.memo(Home);
