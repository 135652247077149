import {
  FontIcon,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ThemeColorPalette } from "../../../theme";
import InprogressVideoDetails from "./InProgressVideoDetails";
import InprogressVideoEdit from "./InprogressVideoEdit";

export interface ViewEditVideoDataProps {
  inProgressVideosData: any;
  selectedVideoLength?: number;
  isEditable: boolean;
  handleChange: any;
  handlePatientConsentFormFileChange: any;
  proceduresData: any;
  userData: any;
  enableSave: boolean;
  handleSaveClick: any;
  handleEdit: any;
  userProfile: any;
  isSelected: boolean;
  screen?: string;
  errorObject: any;
}

export interface ViewEditVideoDataState {
  editedFields: any;
  videoData: any;
  enableSave: boolean;
}

const ViewEditVideoData: React.FC<ViewEditVideoDataProps> = ({
  inProgressVideosData,
  selectedVideoLength,
  isEditable,
  proceduresData,
  userData,
  enableSave,
  handleSaveClick,
  handleEdit,
  userProfile,
  isSelected,
  screen,
  handlePatientConsentFormFileChange,
  handleChange,
  errorObject,
}) => {
  const [editedFields, setEditedFields] = useState<any>();
  const [videoData, setVideoData] = useState<any>();
  const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(enableSave);

  const handleChangeLcl = useCallback(
    (
      e: any,
      data: any,
      selectedOption?: any,
      selectedField?: string,
      selectType?: string
    ) => {
      let value = null;
      let editedField = null;
      if (selectedField === "procedureName") {
        value = {
          ...data,
          procedure_name: selectedOption.text,
          primary_procedure_type: selectedOption.key,
        };
        editedField = {
          ...editedFields,
          primary_procedure_type: selectedOption.key,
        };
      } else if (selectedField === "procedureName2") {
        value = {
          ...data,
          secondary_procedure_name: selectedOption.text,
          secondary_procedure_type: selectedOption.key,
        };
        editedField = {
          ...editedFields,
          secondary_procedure_type: selectedOption.key,
        };
      } else if (selectedOption === "date") {
        value = {
          ...data,
          surgery_date: e,
        };
        editedField = {
          ...editedFields,
          surgery_date: e,
        };
      } else {
        value = {
          ...data,
          [e.target.name]: [e.target.value],
        };
        editedField = {
          ...editedFields,
          [e.target.name]: [e.target.value],
        };
      }
      setEditedFields(editedField);
      setVideoData(value);
      setEnableSaveBtn(true);
    },
    [editedFields, videoData, enableSaveBtn]
  );

  const handlePatientConsentFormFileChangeLcl = useCallback(
    (evt: any, data: any) => {
      const editedField = {
        ...editedFields,
        patientConcentForm: evt.target.files[0],
      };
      const value = {
        ...data,
        patient_concent_forms: evt.target.files[0].name,
      };
      setEditedFields(editedField);
      setVideoData(value);
      setEnableSaveBtn(true);
    },
    [editedFields, videoData, enableSaveBtn]
  );

  useEffect(() => {
    if (videoData !== inProgressVideosData) {
      setVideoData(inProgressVideosData);
    }
  }, [inProgressVideosData]);

  const primarySurgeonName = useMemo(() => {
    return (
      videoData &&
      userData.results &&
      userData.results
        .map((item: any) => {
          if (item.id === videoData.primary_surgeon) return item.displayName;
          else return null;
        })
        .filter((val: any) => val)
    );
  }, [videoData, userData]);

  return !inProgressVideosData ? (
    <StackItem
      styles={{
        root: {
          flex: 8,
          padding: "1.5rem",
          borderLeft: "1px solid black",
        },
      }}
    >
      <Stack verticalFill verticalAlign="center">
        <StackItem align="center">
          <FontIcon
            iconName="MSNVideos"
            style={{
              fontSize: "3rem",
              color: ThemeColorPalette.uploadMsgTxt,
              opacity: 0.25,
              fontWeight: 1,
            }}
          />
        </StackItem>
        <StackItem align="center">
          <Text
            variant="small"
            styles={{
              root: {
                color: ThemeColorPalette.uploadMsgTxt,
                opacity: 0.25,
              },
            }}
          >
            Select any video to view details
          </Text>
        </StackItem>
        <StackItem align="center">
          <Text
            variant="small"
            styles={{
              root: {
                color: ThemeColorPalette.uploadMsgTxt,
                opacity: 0.25,
              },
            }}
          >
            You can also select to edit details
          </Text>
        </StackItem>
      </Stack>
    </StackItem>
  ) : (
    <StackItem
      styles={{
        root: {
          flex: 8,
          padding: "1.5rem",
          borderLeft: "1px solid black",
        },
      }}
    >
      {isEditable
        ? videoData &&
          videoData.id && (
            <StackItem>
              <InprogressVideoEdit
                handleEdit={handleEdit}
                handlePatientConsentFormFileChange={
                  isSelected
                    ? handlePatientConsentFormFileChange
                    : handlePatientConsentFormFileChangeLcl
                }
                handleChange={isSelected ? handleChange : handleChangeLcl}
                data={isSelected ? inProgressVideosData : videoData}
                dropdownVals={proceduresData}
                userData={userData}
                userProfile={userProfile}
                screen={screen}
                errorObject={errorObject}
              />
              <StackItem styles={{ root: { padding: "2rem" } }}>
                <PrimaryButton
                  disabled={isSelected ? !enableSave : !enableSaveBtn}
                  onClick={() =>
                    screen === "processing"
                      ? handleSaveClick(editedFields, videoData.id)
                      : handleSaveClick(videoData)
                  }
                >
                  Save
                </PrimaryButton>
              </StackItem>
            </StackItem>
          )
        : videoData &&
          videoData.id && (
            <InprogressVideoDetails
              userData={userData}
              handleEdit={handleEdit}
              userProfile={userProfile}
              data={isSelected ? inProgressVideosData : videoData}
              primarySurgeonName={primarySurgeonName && primarySurgeonName[0]}
              secondaryProcedureName={videoData.secondary_procedure_name}
              screen={screen}
            />
          )}
    </StackItem>
  );
};

export default React.memo(ViewEditVideoData);
