import { Stack, TextField, Label } from "@fluentui/react";
import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { apiEndPoint, doHttpCall } from "../../../../../../common/restApi";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import * as allActions from "../../../../actions/actionConstants";
import {
  hideLoaderWithMessage,
  showLoaderWithMessage,
} from "../../../../actions/homeAction";
const regions = [
  { value: "Northeast", label: "Northeast" },
  { value: "Ohio", label: "Ohio Valley" },
  { value: "Southeast", label: "Southeast" },
  { value: "South Central", label: "South Central" },
  { value: "Upper Midwest", label: "Upper Midwest" },
  { value: "West", label: "West" },
  { value: "Mountain", label: "Mountain" },
  { value: "Pacific Northwest", label: "Pacific Northwest" },
];
const AddFacility = ({
  errors,
  fieldName,
  register,
  i,
  hospitalData,
  control,
  setValue,
  getValues,
  isChangeFacilities,
  setZipDataAvailable,
  setIsChangeFacilities,
  setCheckValidZipCode,
  checkValidZipCode,
  clearErrors,
  showCreateHospital,
}: {
  errors: any;
  fieldName: any;
  register: any;
  i: number;
  hospitalData: any;
  control: any;
  setValue: any;
  getValues: any;
  isChangeFacilities: any;
  setZipDataAvailable: any;
  setIsChangeFacilities: any;
  setCheckValidZipCode: any;
  checkValidZipCode: any;
  clearErrors: any;
  showCreateHospital?: any;
}) => {
  const dispatch = useDispatch();
  const latestFacilitiesValues = isChangeFacilities
    ? getValues()
    : hospitalData;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [zipcodeOnchange, setZipcodeOnchange] = useState("");
  const [uniqueIdentiferOnchange, setUniqueIdentiferOnchange] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailOnchange, setemailOnchange] = useState("");
  const [phoneNumberchange, setPhoneNumberchange] = useState("");
  const [facilityZipcode, setFacilityZipcode] = useState("");
  const [notFound, setNotFound] = useState("");
  const [facilityLocation, setFacilityLocation] = useState<any>({});
  const cityNameDefault = facilityLocation?.city_name
    ? facilityLocation?.city_name
    : latestFacilitiesValues?.hospitals &&
      latestFacilitiesValues?.hospitals[i + 1]?.city;
  const stateNameDefault = facilityLocation?.state_name
    ? facilityLocation?.state_name
    : latestFacilitiesValues?.hospitals &&
      latestFacilitiesValues?.hospitals[i + 1]?.state;
  const regionNameDefault = facilityLocation?.region_name
    ? facilityLocation?.region_name
    : latestFacilitiesValues?.hospitals &&
      latestFacilitiesValues?.hospitals[i + 1]?.region;
  const countryNameDefault = facilityLocation?.country_name
    ? facilityLocation?.country_name
    : latestFacilitiesValues?.hospitals &&
      latestFacilitiesValues?.hospitals[i + 1]?.country;
  useEffect(() => {
    setValue(`hospitals.${i + 1}.region`, regionNameDefault);
    setValue(`hospitals.${i + 1}.state`, stateNameDefault);
    setValue(`hospitals.${i + 1}.country`, countryNameDefault);
    setValue(`hospitals.${i + 1}.city`, cityNameDefault);
  }, [facilityLocation]);
  // useEffect(() => {
  //   // setValue(`hospitals.${i + 1}.region`, regionNameDefault);
  //   setValue(`hospitals.${i + 1}.state`, stateNameDefault);
  //   // setValue(`hospitals.${i + 1}.country`, countryNameDefault);
  //   // setValue(`hospitals.${i + 1}.city`, cityNameDefault);
  // }, [facilityLocation?.state_name]);
  const addressNameFacility =
    hospitalData?.hospitals &&
    hospitalData?.hospitals[i + 1]?.address?.split("--");
  const addressNameSplit = addressNameFacility && addressNameFacility[0];
  const hospitalName =
    hospitalData?.hospitals && hospitalData?.hospitals[i + 1]?.name;
  const getLocationWithDebounce = useCallback(
    debounce(async (e: any, index: any) => {
      !showCreateHospital && setIsChangeFacilities(true);
      const checkFieldName = `/hospitals/location/${e}`;
      const payload = {
        method: "GET",
        url: `${apiEndPoint}${checkFieldName}`,
      };
      dispatch(showLoaderWithMessage("", allActions.GET_LOCATION_WITH_ZIPCODE));
      const responseLocationFacility = await doHttpCall(payload);
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_LOCATION_WITH_ZIPCODE,
          responseLocationFacility.status
        )
      );
      if (responseLocationFacility && responseLocationFacility.status === 200) {
        const obj: any = checkValidZipCode || {};
        obj[i + 1] = { isvalid: true, message: "" };
        setCheckValidZipCode(obj);
        setZipDataAvailable(true);
        setNotFound("");
        setFacilityLocation(responseLocationFacility?.data);
      } else if (
        responseLocationFacility &&
        (responseLocationFacility.status === 404 ||
          responseLocationFacility.status === 400 ||
          401)
      ) {
        const obj: any = checkValidZipCode || {};
        obj[i + 1] = {
          isvalid: false,
          message: `${responseLocationFacility?.data[0]?.details}`,
        };
        setCheckValidZipCode(obj);
        setZipDataAvailable(false);
        setNotFound(
          responseLocationFacility?.data &&
            responseLocationFacility?.data[0]?.details
        );
        setValue(`hospitals.${i + 1}.region`, "");
        setValue(`hospitals.${i + 1}.state`, "");
        setValue(`hospitals.${i + 1}.country`, "");
        setValue(`hospitals.${i + 1}.city`, "");
        setFacilityLocation({});
      }
      if (responseLocationFacility && responseLocationFacility.status === 500) {
        const obj: any = checkValidZipCode || {};
        obj[i + 1] = { isvalid: false, message: "Invalid zip code" };
        setCheckValidZipCode(obj);
        setZipDataAvailable(false);
        setValue(`hospitals.${i + 1}.region`, "");
        setValue(`hospitals.${i + 1}.state`, "");
        setValue(`hospitals.${i + 1}.country`, "");
        setValue(`hospitals.${i + 1}.city`, "");
        setFacilityLocation({});
      }
    }, 2000),
    []
  );
  return (
    <>
      <Stack className="ms-Grid-row" key={hospitalData}>
        <Stack className="InputSection">
          <Stack.Item className="ms-Grid-row">
            <Stack.Item className="ms-Grid-col ms-lg6">
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset3">
                  {/* <TextField
                    autoComplete="off"
                    key={hospitalName}
                    label="Name*"
                    {...register(`${fieldName}.name`)}
                    errorMessage={
                      errors?.hospitals
                        ? errors?.hospitals[i + 1]?.name?.message
                        : ""
                    }
                    defaultValue={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[i + 1]?.name
                    }
                    // onChange={(e) =>
                    //   handleValidateForm(e, "name")
                    // }
                  /> */}
                  <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom display-none ">
                    <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                      <input
                        className="ms-TextField-field field-custom"
                        type="text"
                        autoComplete="off"
                        {...register(`${fieldName}.id`)}
                        defaultValue={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.id
                        }
                      />
                    </Stack>
                  </Stack>

                  <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom display-none ">
                    <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                      <input
                        className="ms-TextField-field field-custom"
                        type="text"
                        autoComplete="off"
                        {...register(`${fieldName}.fid`)}
                        defaultValue={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.fid
                        }
                      />
                    </Stack>
                  </Stack>
                  <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                    <label className="ms-Label root-custom">Name*</label>
                    <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                      <input
                        className="ms-TextField-field field-custom"
                        type="text"
                        autoComplete="off"
                        key={hospitalName}
                        label="Name*"
                        {...register(`${fieldName}.name`)}
                        onChange={(e: any) => {
                          setName(e?.target?.value);
                          // errors.hospitals[i + 1].name.message = "";
                          if (e?.target.value?.trim().length) {
                            clearErrors(`hospitals.${i + 1}.name`);
                          }
                        }}
                        defaultValue={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.name
                        }
                      />
                    </Stack>
                    <Label className="errorMessage">
                      {errors?.hospitals
                        ? errors?.hospitals[i + 1]?.name?.message
                        : ""}
                    </Label>
                  </Stack>
                  {/* <TextField
                    autoComplete="off"
                    key={addressNameSplit}
                    label="Address 1*"
                    {...register(`hospitals.${i + 1}.address`)}
                    errorMessage={
                      errors?.hospitals
                        ? errors?.hospitals[i + 1]?.address?.message
                        : ""
                    }
                    defaultValue={addressNameSplit}
                  /> */}
                  <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                    <label className="ms-Label root-custom">Address 1*</label>
                    <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                      <input
                        className="ms-TextField-field field-custom"
                        type="text"
                        autoComplete="off"
                        key={addressNameSplit}
                        label="Address 1*"
                        {...register(`hospitals.${i + 1}.address`)}
                        onChange={(e: any) => {
                          setAddress(e?.target?.value);
                          // errors.hospitals[i + 1].address.message = "";
                          if (e?.target.value?.trim().length) {
                            clearErrors(`hospitals.${i + 1}.address`);
                          }
                        }}
                        defaultValue={addressNameSplit}
                      />
                    </Stack>
                    <Label className="errorMessage">
                      {errors?.hospitals
                        ? errors?.hospitals[i + 1]?.address?.message
                        : ""}
                    </Label>
                  </Stack>
                  <Stack.Item
                    key={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[i + 1]?.address2
                    }
                  >
                    {/* <TextField
                      autoComplete="off"
                      key={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[i + 1]?.address2
                      }
                      label="Address 2"
                      {...register(`hospitals.${i + 1}.address2`)}
                      errorMessage={
                        errors?.hospitals
                          ? errors?.hospitals[i + 1]?.address2?.message
                          : ""
                      }
                      defaultValue={
                        hospitalData?.hospitals &&
                        hospitalData?.hospitals[i + 1]?.address2
                      }
                    /> */}
                    <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                      <label className="ms-Label root-custom">Address 2</label>
                      <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                        <input
                          className="ms-TextField-field field-custom"
                          type="text"
                          autoComplete="off"
                          key={
                            hospitalData?.hospitals &&
                            hospitalData?.hospitals[i + 1]?.address2
                          }
                          label="Address 2"
                          {...register(`hospitals.${i + 1}.address2`)}
                          defaultValue={
                            hospitalData?.hospitals &&
                            hospitalData?.hospitals[i + 1]?.address2
                          }
                        />
                      </Stack>
                      <Label className="errorMessage">
                        {errors?.hospitals
                          ? errors?.hospitals[i + 1]?.address2?.message
                          : ""}
                      </Label>
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    {/* <TextField
                      autoComplete="off"
                      label="City"
                      disabled
                      // {...register(`hospitals.${i + 1}.city`)}
                      name="city"
                      errorMessage={
                        errors?.hospitals
                          ? errors?.hospitals[i + 1]?.city?.message
                          : ""
                      }
                      value={cityNameDefault}
                    /> */}
                    <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                      <label className="ms-Label root-custom">City</label>
                      <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                        <input
                          className="ms-TextField-field field-custom"
                          autoComplete="off"
                          disabled
                          // {...register(`hospitals.${i + 1}.city`)}
                          name="city"
                          value={cityNameDefault || ""}
                        />
                      </Stack>
                      <Label className="errorMessage">
                        {errors?.hospitals
                          ? errors?.hospitals[i + 1]?.city?.message
                          : ""}
                      </Label>
                    </Stack>
                  </Stack.Item>
                  <Stack.Item key={stateNameDefault}>
                    {/* <TextField
                      autoComplete="off"
                      label="State"
                      // {...register(`hospitals.${i + 1}.state`)}
                      name="state"
                      disabled
                      errorMessage={
                        errors?.hospitals
                          ? errors?.hospitals[i + 1]?.state?.message
                          : ""
                      }
                      defaultValue={stateNameDefault}
                    /> */}
                    <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                      <label className="ms-Label root-custom">State</label>
                      <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                        <input
                          className="ms-TextField-field field-custom"
                          type="text"
                          autoComplete="off"
                          // {...register(`hospitals.${i + 1}.state`)}
                          name="state"
                          disabled
                          value={stateNameDefault || ""}
                        />
                      </Stack>
                      <Label className="errorMessage">
                        {errors?.hospitals
                          ? errors?.hospitals[i + 1]?.state?.message
                          : ""}
                      </Label>
                    </Stack>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
            <Stack.Item
              className="ms-Grid-col ms-lg6"
              key={hospitalData?.hospitals}
            >
              <Stack.Item className="ms-Grid-row">
                <Stack.Item className="ms-Grid-col ms-lg7 ms-lgOffset1">
                  {/* <TextField
                    autoComplete="off"
                    key={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[i + 1]?.unique_identifier
                    }
                    label="NetSuite Customer Number*"
                    {...register(`hospitals.${i + 1}.unique_identifier`)}
                    errorMessage={
                      errors?.hospitals
                        ? errors?.hospitals[i + 1]?.unique_identifier?.message
                        : ""
                    }
                    // disabled={}
                    defaultValue={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[i + 1]?.unique_identifier
                    }
                    // onChange={(e) =>
                    //   handleValidateForm(e, "unique_identifier")
                    // }
                  /> */}
                  <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                    <label className="ms-Label root-custom">
                      NetSuite Customer Number*
                    </label>
                    <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                      <input
                        className="ms-TextField-field field-custom"
                        type="text"
                        autoComplete="off"
                        key={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.unique_identifier
                        }
                        label="NetSuite Customer Number*"
                        {...register(`hospitals.${i + 1}.unique_identifier`)}
                        onChange={(e: any) => {
                          setUniqueIdentiferOnchange(e?.target?.value);
                          // errors.hospitals[i + 1].unique_identifier.message =
                          //   "";
                          if (e?.target.value?.trim().length) {
                            clearErrors(`hospitals.${i + 1}.unique_identifier`);
                          }
                        }}
                        defaultValue={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.unique_identifier
                        }
                      />
                    </Stack>
                    <Label className="errorMessage">
                      {errors?.hospitals
                        ? errors?.hospitals[i + 1]?.unique_identifier?.message
                        : ""}
                    </Label>
                  </Stack>
                  <Stack.Item>
                    {/* <TextField
                      autoComplete="off"
                      key={regionNameDefault}
                      label="Region"
                      disabled
                      errorMessage={
                        errors?.hospitals
                          ? errors?.hospitals[i + 1]?.region?.message
                          : ""
                      }
                      // {...register(`hospitals.${i + 1}.country`)}
                      name="region"
                      defaultValue={regionNameDefault}
                      // onChange={(e) => handleValidateForm(e, "zipcode")}
                    /> */}
                    <Stack
                      className="ms-TextField-wrapper ms-TextField-wrapper-custom"
                      key={regionNameDefault}
                    >
                      <label className="ms-Label root-custom">Region</label>
                      <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                        <input
                          className="ms-TextField-field field-custom"
                          type="text"
                          autoComplete="off"
                          key={regionNameDefault}
                          disabled
                          name="region"
                          value={regionNameDefault || ""}
                        />
                      </Stack>
                      <Label className="errorMessage">
                        {errors?.hospitals
                          ? errors?.hospitals[i + 1]?.region?.message
                          : ""}
                      </Label>
                    </Stack>
                  </Stack.Item>
                  {/* <TextField
                    autoComplete="off"
                    key={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[i + 1]?.zipcode
                    }
                    label="Zip Code*"
                    errorMessage={
                      errors?.hospitals
                        ? errors?.hospitals[i + 1]?.zipcode?.message
                        : ""
                    }
                    {...register(`hospitals.${i + 1}.zipcode`)}
                    defaultValue={
                      hospitalData?.hospitals &&
                      hospitalData?.hospitals[i + 1]?.zipcode
                    }
                    onChange={async (e: any) => {
                      // async (value) => {
                      if (e?.target?.value?.length >= 3) {
                        const checkFieldName = `/hospitals/location/${e?.target?.value}`;
                        const payload = {
                          method: "GET",
                          url: `${apiEndPoint}${checkFieldName}`,
                        };
                        const responseLocationFacility = await doHttpCall(
                          payload
                        );
                        setFacilityLocation(responseLocationFacility?.data);
                        console.log(
                          facilityLocation,
                          "responseLocationFacility"
                        );
                      }
                    }}
                  /> */}
                  <Stack className="ms-TextField-wrapper ms-TextField-wrapper-custom">
                    <label className="ms-Label root-custom">Zipcode*</label>
                    <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                      <input
                        className="ms-TextField-field field-custom"
                        type="text"
                        key={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.zipcode
                        }
                        {...register(`hospitals.${i + 1}.zipcode`)}
                        defaultValue={
                          hospitalData?.hospitals &&
                          hospitalData?.hospitals[i + 1]?.zipcode
                        }
                        onChange={(e: any) => {
                          if (e?.target.value?.trim().length) {
                            clearErrors(`hospitals.${i + 1}.zipcode`);
                          }
                          if (e?.target?.value?.length >= 3) {
                            getLocationWithDebounce(e?.target?.value, i);
                          }
                          // errors.hospitals[i + 1].zipcode.message = "";
                          // async (value) => {
                          // if (e?.target?.value?.length >= 3) {
                          //   const checkFieldName = `/hospitals/location/${e?.target?.value}`;
                          //   const payload = {
                          //     method: "GET",
                          //     url: `${apiEndPoint}${checkFieldName}`,
                          //   };
                          //   const responseLocationFacility = await doHttpCall(
                          //     payload
                          //   );
                          //   setFacilityLocation(responseLocationFacility?.data);
                          //   console.log(
                          //     facilityLocation,
                          //     "responseLocationFacility"
                          //   );
                          // }
                        }}
                        onKeyPress={(event) => {
                          if (event.key.replace(/[0-9]/, "")) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Stack>
                    <Label className="errorMessage">
                      {errors?.hospitals
                        ? errors?.hospitals[i + 1]?.zipcode?.message
                        : ""}
                    </Label>
                    <Label className="errorMessage">
                      {checkValidZipCode &&
                      Object.keys(checkValidZipCode).length &&
                      !checkValidZipCode[i + 1]?.isValid
                        ? checkValidZipCode[i + 1]?.message
                        : " "}
                    </Label>
                    {/* <Label className="errorMessage">{notFound}</Label> */}
                  </Stack>
                  <Stack.Item key={countryNameDefault}>
                    {/* <TextField
                      autoComplete="off"
                      key={countryNameDefault}
                      label="Country"
                      disabled
                      errorMessage={
                        errors?.hospitals
                          ? errors?.hospitals[i + 1]?.country?.message
                          : ""
                      }
                      // {...register(`hospitals.${i + 1}.country`)}
                      name="country"
                      defaultValue={countryNameDefault}
                      // onChange={(e) => handleValidateForm(e, "zipcode")}
                    /> */}
                    <Stack
                      className="ms-TextField-wrapper ms-TextField-wrapper-custom"
                      key={countryNameDefault}
                    >
                      <label className="ms-Label root-custom">Country</label>
                      <Stack className="ms-TextField-fieldGroup fieldGroup-custom">
                        <input
                          className="ms-TextField-field field-custom"
                          type="text"
                          key={countryNameDefault}
                          disabled
                          name="country"
                          value={countryNameDefault || ""}
                        />
                      </Stack>
                      <Label className="errorMessage">
                        {errors?.hospitals
                          ? errors?.hospitals[i + 1]?.country?.message
                          : ""}
                      </Label>
                    </Stack>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
};
export default AddFacility;
