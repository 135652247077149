import {
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import React from "react";
import { connect } from "react-redux";
import { getProcedureDetails } from "../../../Layout/actions/layoutActions";
import { fonts, ThemeColorPalette } from "../../../theme";
import { clearProceduresDataWhenUnmount } from "../../actions/proceduresAction";
import Select from "react-select";

export interface MultiSelectVideoDetailsProps {
  handleChange?: any;
  data?: any;
  dropdownVals: any;
  userData: any;
  handleSave: any;
  proceduresData: any;
  getProcedureDetails: any;
}

export interface MultiSelectVideoDetailsState {
  enableSave: boolean;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

class MultiSelectVideoDetails extends React.Component<
  MultiSelectVideoDetailsProps,
  MultiSelectVideoDetailsState
> {
  state = {
    enableSave: false,
  };

  render() {
    const { dropdownVals, userData, handleSave, data } = this.props;

    // const procedureDropDownOptions = [
    //   { value: 123, label: "Select Procedure Name" },
    // ];
    const procedureDropDownOptions: any = [];
    const module = localStorage.getItem("role");
    dropdownVals &&
      dropdownVals?.results?.forEach((val: any) => {
        procedureDropDownOptions.push({ value: val.id, label: val.name });
      });

    const userVal = [{ key: 123, text: "Enter Surgeon Name" }];
    userData.results.forEach((val: any) => {
      userVal.push({ key: val.id, text: val.displayName });
    });

    const handleScrollProcedures = () => {
      if (this.props.proceduresData?.next) {
        this.props.getProcedureDetails(this.props.proceduresData?.next);
      }
    };

    return (
      <Stack
        verticalFill
        styles={{ root: { paddingLeft: "2rem", paddingTop: "0.5rem" } }}
        tokens={{ childrenGap: 30 }}
      >
        <Text
          variant="xLarge"
          styles={{
            root: {
              fontFamily: fonts.DINPro_Regular,
              color: ThemeColorPalette.dashboardHeadingTxt,
              opacity: 0.8,
            },
          }}
        >
          Video Details
        </Text>
        <Stack tokens={{ childrenGap: 30 }}>
          <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack.Item>
              <Stack.Item className="label">
                <Text>Procedure Name*</Text>
              </Stack.Item>
              <Select
                placeholder={
                  <div className="text-white">Select Procedure Name</div>
                }
                defaultValue={data.videoData.primaryProcedureId}
                classNamePrefix="addl-class"
                className="procedure_select"
                options={procedureDropDownOptions}
                value={procedureDropDownOptions?.find(
                  (c: any) => c.value === data.videoData.primaryProcedureId
                )}
                onMenuScrollToBottom={(e) => handleScrollProcedures()}
                onChange={(val) => {
                  this.setState({ ...this.state, enableSave: true });
                  const option = { key: val.value, text: val.label };
                  this.props.handleChange(
                    {},
                    data,
                    option,
                    "procedureName",
                    "multi"
                  );
                }}
              />
            </Stack.Item>
            <Dropdown
              placeholder="Enter Surgeon Name"
              label="Primary Surgeon Name"
              options={userVal}
              styles={dropdownStyles}
              onChange={(evt: React.FormEvent, option?: IDropdownOption) => {
                this.setState({ ...this.state, enableSave: true });
                this.props.handleChange(
                  evt,
                  data,
                  option,
                  "surgeonName",
                  "multi"
                );
              }}
              calloutProps={{
                directionalHintFixed: true,
                calloutMaxHeight: 300,
              }}
              disabled={module === "HospitalAdmin" ? false : true}
              defaultSelectedKey={data.videoData.primarySurgeonId}
            />
          </Stack>

          <PrimaryButton
            text="Save"
            allowDisabledFocus
            disabled={
              (data.videoData.primarySurgeonId &&
                data.videoData.primarySurgeonId !== 123 &&
                data.videoData.primaryProcedureId &&
                data.videoData.primaryProcedureId !== 123) ||
              this.state.enableSave
                ? false
                : true
            }
            styles={{
              root: {
                fontSize: "0.9rem",
                fontWeight: "bold",
                fontFamily: fonts.HelveticaNeue_Bold,
                height: "2rem",
                width: "6rem",
                flex: 1,
              },
            }}
            onClick={() => handleSave(data)}
          />
        </Stack>
      </Stack>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    proceduresData: state.dashboardReducerData.procedureDetails,
  };
};

const dispatchActionToProps = (dispatch: any) => {
  return {
    getProcedureDetails: (data: any) => dispatch(getProcedureDetails(data)),
    clearProceduresDataWhenUnmount: () =>
      dispatch(clearProceduresDataWhenUnmount()),
  };
};

// export default MultiSelectVideoDetails;
export default connect(
  mapStateToProps,
  dispatchActionToProps
)(MultiSelectVideoDetails);
