import {
  Stack,
  Text,
  PrimaryButton,
  Icon,
  ActionButton,
  StackItem,
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { roleIncrement } from "../../../../ActiveAdminModule/Management/actions/managamentActions";
const Wizard = ({
  children,
  counts,
  form,
  back,
  // spinner,
  disabledCreate,
  onCancel,
  hideFooter = false,
  goNext,
  submitData,
}: {
  children: any;
  counts: any;
  form: any;
  back: any;
  // spinner: boolean;
  disabledCreate: boolean;
  onCancel: any;
  hideFooter?: boolean;
  goNext: () => void;
  submitData: () => void;
}) => {
  const [activePageIndex, setActivePageIndex] = useState(0);

  const dispatch = useDispatch();

  const pages = React.Children.toArray(children);

  const currentPage = pages[counts];

  const goPrevPage = () => {
    dispatch(back());
    setActivePageIndex(counts);
  };

  const ButtonPrev = () =>
    counts > 0 ? (
      <ActionButton
        type="button"
        onClick={goPrevPage}
        allowDisabledFocus
        className="btn-c"
      >
        Back
      </ActionButton>
    ) : null;
  const ButtonNext = () =>
    counts < pages.length - 1 ? (
      <PrimaryButton
        type="submit"
        form={form}
        className="btn-next"
        onClick={goNext}
      >
        Continue
      </PrimaryButton>
    ) : (
      <PrimaryButton
        type="submit"
        form={form}
        className="btn-next"
        disabled={disabledCreate}
        onClick={submitData}
      >
        Submit
        <Text className="spinnerRight">
          {disabledCreate && <Spinner size={SpinnerSize.small} />}
        </Text>
      </PrimaryButton>
    );
  useEffect(() => {}, []);
  return (
    <Stack>
      <Stack className="createHospitalMain">{currentPage}</Stack>
      {!hideFooter && (
        <Stack
          className="createHospitalWizardFooter"
          horizontal
          hidden={hideFooter}
          horizontalAlign="end"
          tokens={{ childrenGap: "m" }}
        >
          <Stack.Item className="nextCancel">
            <ButtonNext />
            <ActionButton
              text="Cancel"
              className="btn-c"
              onClick={onCancel}
              allowDisabledFocus
            />
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};

export default Wizard;
