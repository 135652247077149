import React from "react";
import { Stack, Text, Modal, DefaultButton } from "@fluentui/react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../rootReducer";
import "./Conformation.scss";
import moment from "moment";
import { useBoolean } from "@fluentui/react-hooks";
import SuccessMessage from "../../../Common/SuccessMessage";
const Conformation = ({
  hospitalData,
  showCreateHospital,
}: {
  hospitalData: any;
  showCreateHospital: any;
}) => {
  // debugger;
  const prefixZero = (num: Number) => {
    return ("0" + num).slice(-2);
  };
  const dateFormat = (date: any) => {
    if (date) {
      return moment(date).format("MM/DD/YYYY");
    } else {
      return "";
    }
  };
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const devices = useSelector((state: AppState) => {
    return state.homeReducerData.devices.data;
  });
  const allSerialNumbers = devices?.results?.map((device: any) => ({
    value: device?.id,
    label: device?.serial_number,
  }));
  const findSerialNumberById = (id: any) => {
    const serialNumberObject = allSerialNumbers?.find(
      (allSerialNumber: any) => allSerialNumber?.value == id
    );
    return serialNumberObject?.label;
  };

  return (
    <Stack className="conformation ms-Grid" dir="ltr">
      {(hospitalData?.hospitals || !showCreateHospital) && (
        <>
          <Text variant="mediumPlus" className="conformationHeading">
            Summary for Confirmation! {isModalOpen ? isModalOpen : isModalOpen}
          </Text>
          {hospitalData?.hospitals?.map((hospital: any, index: number) => {
            const address1 = hospital?.address?.split("--");
            const address = address1.filter(function (el: any) {
              return el != "";
            });

            return (
              <Stack
                key={hospital?.unique_identifier}
                className={`conformationMain ${
                  hospital?.name ? "show" : "hide"
                }`}
              >
                <Stack.Item className="conformationMainTop ms-Grid-row">
                  <Stack.Item className=" ms-Grid-col ms-lg6">
                    <Stack.Item className="ms-Grid-row conformationMainLeft">
                      <Stack.Item className=" ms-Grid-col ms-lg2 ">
                        <Stack.Item className="circle">
                          {hospital?.name.charAt(0)}
                          {/* {hospitalData?.admin?.lastname.charAt(0)} */}
                        </Stack.Item>
                      </Stack.Item>
                      <Stack.Item className=" ms-Grid-col ms-lg8">
                        <Text block variant="large" className="heading">
                          {!hospital.is_main_branch
                            ? `Facility${
                                index.toString().length < 2
                                  ? `0${index}`
                                  : `${index}`
                              }- `
                            : ""}{" "}
                          {hospital?.name}
                        </Text>
                        <Text
                          block
                          variant="small"
                          className="ms-fontWeight-light"
                        >
                          Address
                        </Text>
                        <Text block variant="small">
                          {address?.length >= 0 && address[0]}
                          {address?.length > 1 && "," + address[1]}
                        </Text>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className=" ms-Grid-col ms-lg6">
                    <Stack.Item className="ms-Grid-row conformationMainRight">
                      <Stack.Item className=" ms-Grid-col ms-lg3">
                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light textNoWrap"
                            block
                          >
                            NetSuite Customer Number
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold textNoWrap"
                            block
                          >
                            {hospital?.unique_identifier}
                          </Text>
                        </Stack.Item>

                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light"
                            block
                          >
                            City
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                          >
                            {hospital?.city}
                          </Text>
                        </Stack.Item>

                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light"
                            block
                          >
                            State
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                          >
                            {hospital?.state}
                          </Text>
                        </Stack.Item>
                      </Stack.Item>
                      <Stack.Item className=" ms-Grid-col ms-lg3">
                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light"
                            block
                          >
                            Region
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                          >
                            {hospital?.region}
                          </Text>
                        </Stack.Item>

                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light"
                            block
                          >
                            Zip Code
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                          >
                            {hospital?.zipcode}
                          </Text>
                        </Stack.Item>
                      </Stack.Item>
                      <Stack.Item className=" ms-Grid-col ms-lg4">
                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light"
                            block
                          >
                            Devices
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                          >
                            {hospital?.devices?.length}
                          </Text>
                        </Stack.Item>

                        <Stack.Item>
                          <Text
                            variant="small"
                            className="ms-fontWeight-light"
                            block
                          >
                            Hospital Admin Details
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                            block
                          >
                            {hospitalData?.admin.firstname}{" "}
                            {hospitalData?.admin.lastname}
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                            block
                          >
                            {hospitalData?.admin.email}
                          </Text>
                          <Text
                            variant="small"
                            className="ms-fontWeight-semibold"
                            block
                          >
                            {hospitalData?.admin.phone_number}
                          </Text>
                        </Stack.Item>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
                {hospital?.devices?.length > 0 && (
                  <Stack.Item className="conformationMainBottom ms-Grid-row">
                    <Stack.Item className=" ms-Grid-col ms-lg1">
                      <Text variant="small" className="ms-fontWeight-semibold">
                        Device Details
                      </Text>
                    </Stack.Item>
                    {hospital?.devices.map((device: any, i: any) => {
                      return (
                        <>
                          <Stack.Item
                            key={i}
                            className="ms-Grid-col ms-lg3"
                            style={{ width: "30%" }}
                          >
                            <Stack.Item className="ms-Grid-row">
                              <Stack.Item className="deviceHeading ms-Grid-col ms-lg4">
                                <Text>Device - {prefixZero(i + 1)}</Text>
                              </Stack.Item>
                            </Stack.Item>
                            <Stack.Item className="ms-Grid-row deviceDetails">
                              <Stack.Item className="deviceHeading ms-Grid-col ms-lg4">
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-light"
                                  block
                                >
                                  Serial No
                                </Text>
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-light textNoWrap"
                                  block
                                >
                                  Device Location
                                </Text>
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-light textNoWrap"
                                  block
                                >
                                  Allocation Type
                                </Text>
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-light"
                                  block
                                >
                                  Duration
                                </Text>
                              </Stack.Item>
                              <Stack.Item className="ms-Grid-col ms-lg7">
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-semibold textNoWrap"
                                  block
                                >
                                  {device?.serial_number
                                    ? device?.serial_number
                                    : device?.id
                                    ? findSerialNumberById(device?.id)
                                    : "NA"}
                                </Text>
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-semibold textNoWrap"
                                  block
                                >
                                  {device?.room ? device?.room : "NA"}
                                </Text>
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-semibold textNoWrap"
                                  block
                                >
                                  {device?.allocation_type
                                    ? device?.allocation_type
                                    : "NA"}
                                </Text>
                                <Text
                                  variant="small"
                                  className="ms-fontWeight-semibold textNoWrap"
                                  block
                                >
                                  {device?.allocation_start &&
                                  device?.allocation_end
                                    ? dateFormat(device?.allocation_start) +
                                      " to " +
                                      dateFormat(device?.allocation_end)
                                    : "NA"}
                                </Text>
                              </Stack.Item>
                            </Stack.Item>
                          </Stack.Item>
                          {(i + 1) % 3 == 0 && (
                            <Stack.Item className=" ms-Grid-col ms-lg1">
                              {" "}
                            </Stack.Item>
                          )}
                        </>
                      );
                    })}
                  </Stack.Item>
                )}
              </Stack>
            );
          })}
        </>
      )}
    </Stack>
  );
};

export default Conformation;
