import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Text,
  Modal,
  DefaultButton,
  IconButton,
  IIconProps,
} from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../rootReducer";
import "../CreateHospital/CreateHospitalWizard/Conformation/Conformation.scss";
import moment from "moment";
import { useBoolean } from "@fluentui/react-hooks";
import SuccessMessage from "../Common/SuccessMessage";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Logs/components/Common/Loader";
import {
  clearHospitalData,
  closeHospitalModal,
  getHospitalsById,
  showCreateHospital,
  showSpinner,
} from "../../actions/homeAction";
import CreateHospital from "../CreateHospital/CreateHospital";
import CheckPermission from "../../../../common/permissions/rolePermissions";
import * as constants from "../../../../Layout/constants/constants";

const cancelIcon: IIconProps = { iconName: "Cancel" };

const HospitalFullView = ({ hospital }: { hospital: any }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openCreateHospital, setOpenCreateHospital] = useState(false);
  const prefixZero = (num: Number) => {
    return ("0" + num).slice(-4);
  };
  const dateFormat = (date: any) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  };
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const alertClicked = () => {
    // navigate(`/`);
    // dispatch(clearHospitalData());
    localStorage.setItem("panelNavigation", "true");
    window.history.back();
  };
  const showModalScreen = useCallback(() => {
    setOpenCreateHospital((current) => !current);
  }, []);

  const showEditModal = () => {
    setOpenCreateHospital((current) => !current);
    dispatch(showCreateHospital());
    if (openCreateHospital) {
      dispatch(closeHospitalModal());
    }
  };
  const loadingState = useSelector((state: AppState) => {
    return state.homeReducerData.loadingState;
  });
  const errorHospital = useSelector((state: AppState) => {
    return state?.homeReducerData?.errorHospital;
  });

  const hospitalSpinner = useSelector((state: AppState) => {
    return state.homeReducerData.showSpinner;
  });
  const showCreateHospitalSuccess = useSelector((state: AppState) => {
    return state.homeReducerData.showCreateHospital;
  });
  const createHospitalError = useSelector((state: AppState) => {
    return state.homeReducerData.createHospitalError;
  });

  const address = hospital?.data?.address.split("--");
  const edit_button = {
    color: "#01B1FF",

    cursor: "pointer",
  };
  const formatPhoneNumber = (phoneNumberString: any) => {
    const slicedArray = phoneNumberString
      ? phoneNumberString.slice(0, 10)
      : null;
    const cleaned = ("" + slicedArray).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "+91 ", match[2], match[3], match[4]].join("");
    }
    return null;
  };
  return (
    <Stack className="conformation ms-Grid" dir="ltr">
      <Stack
        className="createHospitalHeading"
        dir="ltr"
        horizontal
        horizontalAlign="space-between"
      >
        <Stack.Item>
          <Text variant="large" className="muted">
            All Hospitals
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          {/* <Text variant="large" className="muted">
                    All users
                </Text>
                <Text variant="large" className="lessthan muted">
                    &gt;
                </Text> */}
          <Text variant="large">{hospital?.data?.name}</Text>
        </Stack.Item>

        <Stack.Item align="end">
          <IconButton
            className=""
            iconProps={cancelIcon}
            onClick={alertClicked}
          />
        </Stack.Item>
      </Stack>
      {/**

       * MY CODE

       */}

      <Stack>
        <Stack
          className="createHospitalHeading"
          dir="ltr"
          horizontal
          horizontalAlign="space-between"
        >
          <Stack.Item>
            <Text variant="mediumPlus" className="conformationHeading">
              Hospital Profile (Main Hospital){" "}
              {isModalOpen ? isModalOpen : isModalOpen}{" "}
            </Text>
          </Stack.Item>
          <CheckPermission {...constants.SCOPES.EDIT_HOSPITAL_ACTIVADMIN}>
            <Stack.Item align="end" style={edit_button} onClick={showEditModal}>
              Edit
            </Stack.Item>
          </CheckPermission>
        </Stack>
      </Stack>

      <Stack
        className={`conformationMain ${hospital?.data?.name ? "show" : "hide"}`}
      >
        <Stack.Item className="conformationMainTop ms-Grid-row">
          <Stack.Item className=" ms-Grid-col ms-lg6">
            <Stack.Item className="ms-Grid-row conformationMainLeft">
              <Stack.Item className=" ms-Grid-col ms-lg2 ">
                <Stack.Item className="circle">
                  {hospital?.data?.name?.charAt(0)}
                </Stack.Item>
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg8">
                <Text block variant="large" className="heading">
                  {hospital?.data?.name}
                </Text>
                <Text block variant="small" className="ms-fontWeight-light">
                  Address
                </Text>
                <Text block variant="small">
                  {address?.length >= 0 && address[0]}
                  {address?.length > 0 && address[1] && "," + address[1]}
                </Text>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
          <Stack.Item className=" ms-Grid-col ms-lg6">
            <Stack.Item className="ms-Grid-row conformationMainRight">
              <Stack.Item className=" ms-Grid-col ms-lg3">
                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    NetSuite Customer Number
                  </Text>
                  <Text variant="small" className="ms-fontWeight-semibold">
                    {hospital?.data?.unique_identifier}
                  </Text>
                </Stack.Item>

                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    City
                  </Text>
                  <Text variant="small" className="ms-fontWeight-semibold">
                    {hospital?.data?.city}
                  </Text>
                </Stack.Item>

                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    State
                  </Text>
                  <Text variant="small" className="ms-fontWeight-semibold">
                    {hospital?.data?.state}
                  </Text>
                </Stack.Item>
              </Stack.Item>
              <Stack.Item className=" ms-Grid-col ms-lg3">
                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    Region
                  </Text>
                  <Text variant="small" className="ms-fontWeight-semibold">
                    {hospital?.data?.region}
                  </Text>
                </Stack.Item>

                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    Zip Code
                  </Text>
                  <Text variant="small" className="ms-fontWeight-semibold">
                    {hospital?.data?.zipcode}
                  </Text>
                </Stack.Item>
              </Stack.Item>

              <Stack.Item className=" ms-Grid-col ms-lg4">
                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    Devices
                  </Text>
                  <Text variant="small" className="ms-fontWeight-semibold">
                    {hospital?.data?.devices?.length}
                  </Text>
                </Stack.Item>

                <Stack.Item>
                  <Text variant="small" className="ms-fontWeight-light" block>
                    Hospital Admin Details
                  </Text>
                  <Text
                    variant="small"
                    className="ms-fontWeight-semibold"
                    block
                  >
                    {hospital?.data?.hospital_admin}
                  </Text>
                  <Text
                    variant="small"
                    className="ms-fontWeight-semibold"
                    block
                  >
                    {hospital?.data?.admin_email}
                  </Text>
                  <Text
                    variant="small"
                    className="ms-fontWeight-semibold"
                    block
                  >
                    {hospital?.data?.admin_phone}
                  </Text>
                </Stack.Item>
              </Stack.Item>
            </Stack.Item>
          </Stack.Item>
        </Stack.Item>
        {hospital?.data?.devices?.length > 0 && (
          <Stack.Item className="conformationMainBottom ms-Grid-row">
            <Stack.Item className=" ms-Grid-row ms-lg1">
              <Text variant="small" className="ms-fontWeight-semibold">
                Device Details
              </Text>
            </Stack.Item>
            <br></br>
            {hospital?.data?.devices?.map((device: any, i: any) => (
              <>
                <Stack.Item className=" ms-Grid-col ms-lg4" key={i}>
                  <Stack.Item className="ms-Grid-row">
                    <Stack.Item className="deviceHeading ms-Grid-row ms-lg3">
                      <Text>Device - {prefixZero(i + 1)}</Text>
                    </Stack.Item>
                  </Stack.Item>
                  <Stack.Item className="ms-Grid-row deviceDetails">
                    <Stack.Item className="deviceHeading ms-Grid-col ms-lg4">
                      <Text
                        variant="small"
                        className="ms-fontWeight-light"
                        block
                      >
                        Serial No
                      </Text>
                      <Text
                        variant="small"
                        className="ms-fontWeight-light textNoWrap"
                        block
                      >
                        Device Location
                      </Text>
                      <Text
                        variant="small"
                        className="ms-fontWeight-light textNoWrap"
                        block
                      >
                        Allocation Type
                      </Text>
                      <Text
                        variant="small"
                        className="ms-fontWeight-light"
                        block
                      >
                        Duration
                      </Text>
                    </Stack.Item>
                    <Stack.Item className="ms-Grid-col ms-lg7">
                      <Text
                        variant="small"
                        className="ms-fontWeight-semibold"
                        block
                      >
                        {device?.serial_number}
                      </Text>
                      <Text
                        variant="small"
                        className="ms-fontWeight-semibold"
                        block
                      >
                        {device?.room}
                      </Text>
                      <Text
                        variant="small"
                        className="ms-fontWeight-semibold"
                        block
                      >
                        {device?.allocation_type}
                      </Text>
                      <Text
                        variant="small"
                        className="ms-fontWeight-semibold"
                        block
                      >
                        {dateFormat(device?.allocation_start)} to{" "}
                        {dateFormat(device?.allocation_end)}
                      </Text>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </>
            ))}
          </Stack.Item>
        )}
      </Stack>
      {openCreateHospital && (
        <CreateHospital
          isModalOpen={openCreateHospital}
          showModal={showModalScreen}
          hospitalId={hospital?.data?.hid}
        ></CreateHospital>
      )}
      {loadingState && (
        <>
          <Loader></Loader>
        </>
      )}
      <Modal
        isOpen={hospitalSpinner && !errorHospital}
        containerClassName={"modalStyle"}
      >
        <SuccessMessage
          title={`Hospital ${
            !showCreateHospitalSuccess ? "Creation" : "Updated"
          }`}
        />
      </Modal>
      {(hospitalSpinner || createHospitalError) &&
        setTimeout(() => {
          setOpenCreateHospital(false);
          if (!openCreateHospital) {
            dispatch(getHospitalsById(hospital?.data?.hid));
          }
          dispatch(showSpinner());
        }, 2000) && <></>}
      {/* {hospitalSpinner || createHospitalError && (
      
        setTimeout(() => {
          setOpenCreateHospital(false);
          dispatch(showSpinner());
        }, 2000))
        
        &&  !openCreateHospital && (
            dispatch(getHospitalsById(hospital?.data?.hid))
          )
        
      }
        */}
      {hospital?.data?.facilities.length !== 0 ? (
        <Text>Other Facilities</Text>
      ) : null}
      {hospital?.data?.facilities
        // .sort(function (a: any, b: any) {
        //   return a?.name?.localeCompare(b?.name);
        // })
        .map((facilitie: any, index: any) => {
          const facilitiyAddress = facilitie?.address.split("--");
          return (
            <Stack
              key={facilitie?.id}
              className={`conformationMain ${
                hospital?.data?.name ? "show" : "hide"
              }`}
            >
              <Stack.Item className="conformationMainTop ms-Grid-row">
                <Stack.Item className=" ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row conformationMainLeft">
                    <Stack.Item className=" ms-Grid-col ms-lg2 ">
                      <Stack.Item className="circle">
                        {/* {hospital?.data?.name.charAt(0)} */}
                        {facilitie?.name.charAt(0)}
                      </Stack.Item>
                    </Stack.Item>
                    <Stack.Item className=" ms-Grid-col ms-lg8">
                      <Text block variant="large" className="heading">
                        Facility{prefixZero(index + 1)}-
                        {/* {hospital?.data?.name} */}
                        {facilitie?.name}
                      </Text>
                      <Text
                        block
                        variant="small"
                        className="ms-fontWeight-light"
                      >
                        Address
                      </Text>
                      <Text block variant="small">
                        {/* {facilitiyAddress.length > 0
                        ? facilitiyAddress.join()
                        : facilitie?.address} */}
                        {facilitiyAddress?.length > 0 && facilitiyAddress?.[0]}
                        {facilitiyAddress?.[1] &&
                          facilitiyAddress?.length > 0 &&
                          "," + facilitiyAddress?.[1]}
                      </Text>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
                <Stack.Item className=" ms-Grid-col ms-lg6">
                  <Stack.Item className="ms-Grid-row conformationMainRight">
                    <Stack.Item className=" ms-Grid-col ms-lg3">
                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          NetSuite Customer Number
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                        >
                          {facilitie?.unique_identifier}
                        </Text>
                      </Stack.Item>

                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          City
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                        >
                          {facilitie?.city}
                        </Text>
                      </Stack.Item>

                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          State
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                        >
                          {facilitie?.state}
                        </Text>
                      </Stack.Item>
                    </Stack.Item>
                    <Stack.Item className=" ms-Grid-col ms-lg3">
                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          Region
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                        >
                          {facilitie?.region}
                        </Text>
                      </Stack.Item>

                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          Zip Code
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                        >
                          {facilitie?.zipcode}
                        </Text>
                      </Stack.Item>
                    </Stack.Item>

                    <Stack.Item className=" ms-Grid-col ms-lg4">
                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          Devices
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                        >
                          {facilitie?.devices.length}
                        </Text>
                      </Stack.Item>

                      <Stack.Item>
                        <Text
                          variant="small"
                          className="ms-fontWeight-light"
                          block
                        >
                          Hospital Admin Details
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                          block
                        >
                          {hospital?.data?.hospital_admin}
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                          block
                        >
                          {hospital?.data?.admin_email}
                        </Text>
                        <Text
                          variant="small"
                          className="ms-fontWeight-semibold"
                          block
                        >
                          {hospital?.data?.admin_phone}
                        </Text>
                      </Stack.Item>
                    </Stack.Item>
                  </Stack.Item>
                </Stack.Item>
              </Stack.Item>
              {facilitie?.devices?.length > 0 && (
                <Stack.Item className="conformationMainBottom ms-Grid-row">
                  <Stack.Item className=" ms-Grid-col ms-lg1">
                    <Text variant="small" className="ms-fontWeight-semibold">
                      Device Details
                    </Text>
                  </Stack.Item>
                  {facilitie?.devices?.map((device: any, i: any) => (
                    <>
                      <Stack.Item className=" ms-Grid-col ms-lg3">
                        <Stack.Item className="ms-Grid-row">
                          <Stack.Item className="deviceHeading ms-Grid-col ms-lg4">
                            <Text>Device - {prefixZero(i + 1)}</Text>
                          </Stack.Item>
                        </Stack.Item>
                        <Stack.Item className="ms-Grid-row deviceDetails">
                          <Stack.Item className="deviceHeading ms-Grid-col ms-lg4">
                            <Text
                              variant="small"
                              className="ms-fontWeight-light"
                              block
                            >
                              Serial No
                            </Text>
                            <Text
                              variant="small"
                              className="ms-fontWeight-light textNoWrap"
                              block
                            >
                              Device Location
                            </Text>
                            <Text
                              variant="small"
                              className="ms-fontWeight-light"
                              block
                            >
                              Allocation Type
                            </Text>
                            <Text
                              variant="small"
                              className="ms-fontWeight-light"
                              block
                            >
                              Duration
                            </Text>
                          </Stack.Item>
                          <Stack.Item className="ms-Grid-col ms-lg7">
                            <Text
                              variant="small"
                              className="ms-fontWeight-semibold"
                              block
                            >
                              {device?.serial_number}
                            </Text>
                            <Text
                              variant="small"
                              className="ms-fontWeight-semibold"
                              block
                            >
                              {device?.room}
                            </Text>
                            <Text
                              variant="small"
                              className="ms-fontWeight-semibold"
                              block
                            >
                              {device?.allocation_type}
                            </Text>
                            <Text
                              variant="small"
                              className="ms-fontWeight-semibold"
                              block
                            >
                              {dateFormat(device?.allocation_start)} to{" "}
                              {dateFormat(device?.allocation_end)}
                            </Text>
                          </Stack.Item>
                        </Stack.Item>
                      </Stack.Item>
                    </>
                  ))}
                </Stack.Item>
              )}
            </Stack>
          );
        })}
      <Text style={{ color: "#968e93" }}>
        Profile Created by {"    "}
        {hospital?.data?.created_by} {"     "}{" "}
        {moment(hospital?.data?.created_date).format("MM/DD/YY HH:mm")}
      </Text>
    </Stack>
  );
};

export default HospitalFullView;
