import * as pako from "pako";
import moment from "moment";

export const doAuthentication = (options: any): Boolean => {
  return true;
};

export const getParameterByName = (
  name: string,
  url = window.location.href
) => {
  name = name.replace(/[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const emailPattern =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const currentDate = () => {
  let today: any = new Date();
  let dd: any = today.getDate();
  let mm: any = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = mm + "/" + dd + "/" + yyyy;
  return today;
};

export function formatDate(date: any) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getRandomColor() {
  const colors = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  return colors;
}

export function getTime(data: any) {
  const time = `${new Date(data).getHours()}:${
    new Date(data).getMinutes() < 10 ? 0 : ""
  }${new Date(data).getMinutes()}`;
  return time;
}

export function getDateWithSlashInBetween(data: any) {
  const date = `${new Date(data).getMonth() + 1}/${new Date(
    data
  ).getDate()}/${new Date(data).getFullYear()}`;
  return date;
}

export const getFormattedDate = (date = new Date()) => {
  return moment(date).format("MM/DD/YYYY");
};

export function convertDurationOfVideoToSec(duration: string) {
  const time: any = duration.split(":");
  return time[0] * 3600 + time[1] * 60 + time[2] * 1;
}

export function secToHMS(duration: any) {
  const hours = Math.floor(duration / 3600);
  duration %= 3600;
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  let result =
    Math.round(hours) < 10 ? "0" + Math.round(hours) : Math.round(hours);
  result +=
    ":" +
    (Math.round(minutes) < 10
      ? "0" + Math.round(minutes)
      : Math.round(minutes));
  result +=
    ":" +
    (Math.round(seconds) < 10
      ? "0" + Math.round(seconds)
      : Math.round(seconds));
  return result;
}

export function unZipFile(val: string) {
  const b64Data = val;
  let strData = atob(b64Data);
  const charData = strData.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  const binData = new Uint8Array(charData);
  const data: any = pako.inflate(binData);
  strData = "";
  const chunk = 8 * 1024;
  let i;
  for (i = 0; i < data.length / chunk; i++) {
    strData += String.fromCharCode.apply(
      null,
      data.slice(i * chunk, (i + 1) * chunk)
    );
  }
  strData += String.fromCharCode.apply(null, data.slice(i * chunk));
  const jsonResult = decodeURIComponent(escape(strData));
  return jsonResult;
}

export function timeSince(date: any) {
  const x = new Date(date);
  const now_utc = Date.UTC(
    x.getUTCFullYear(),
    x.getUTCMonth(),
    x.getUTCDate(),
    x.getUTCHours(),
    x.getUTCMinutes(),
    x.getUTCSeconds(),
    x.getMilliseconds()
  );
  const y = moment(now_utc - x.getTimezoneOffset() * 60 * 1000).fromNow();
  // const currentDate: any = new Date();
  // const seconds: any = Math.floor((currentDate - date) / 1000);

  // let interval = seconds / 31536000;

  // if (interval > 1) {
  //   return Math.floor(interval) + " years ago";
  // }
  // interval = seconds / 2592000;
  // if (interval > 1) {
  //   return Math.floor(interval) + " months ago";
  // }
  // interval = seconds / 86400;
  // if (interval > 1) {
  //   return Math.floor(interval) === 1
  //     ? Math.floor(interval) + " day ago"
  //     : Math.floor(interval) + " days ago";
  // }
  // interval = seconds / 3600;
  // if (interval > 1) {
  //   return Math.floor(interval) + " hours ago";
  // }
  // interval = seconds / 60;
  // if (interval > 1) {
  //   return Math.floor(interval) + " minutes ago";
  // }
  // return Math.abs(Math.floor(seconds)) + " seconds ago";
  return y;
}
