import React, { useEffect, useState } from "react";
import multiactive from "../../../src/assets/multiactiv.svg";
import multidev from "../../../src/assets/multidev.svg";
import { ROLE_TYPES } from "../constants/constants";
import { Image } from "@fluentui/react";

const MultiRoleConfirm = ({ onRoleSelect }: any) => {
  const [selectedRole, setSelectedRole] = useState("ActivAdmin");

  localStorage.setItem("isEditAdminAccessOpen", JSON.stringify(false));
  localStorage.setItem("panelNavigation", "false");
  const role = localStorage.getItem("role");
  const password = localStorage.getItem("refreshToken");

  // useEffect(() => {
  //   setSelectedRole(role || "");
  // }, [role]);
  const remmberme = localStorage.getItem("isremember") === "true";
  const [remember_me, setRemember] = useState(remmberme);

  const rememberme = (value: any) => {
    const rem = !value;
    setRemember(!value);
    localStorage.setItem("isremember", JSON.stringify(rem));
  };
  return (
    <>
      {password && (
        <div className="multiRoleDialog">
          <div>
            <div className="roleDialogheader">
              Hey! You have multiple roles in this Application.
            </div>
            <div className="subheader">Please select role and continue</div>
          </div>
          <div className="boxHeader">
            <div
              className="firstBox"
              onClick={() => setSelectedRole(ROLE_TYPES.ACTIVE_ADMIN)}
            >
              <div style={{ display: "flex-end" }}>
                <Image src={multiactive} className="multi" />
                <input
                  className="boxCheck"
                  type="checkbox"
                  id="activAdmin"
                  value={ROLE_TYPES.ACTIVE_ADMIN}
                  checked={
                    selectedRole === ROLE_TYPES.ACTIVE_ADMIN ? true : false
                  }
                  //  onChange={() => setSelectedRole(ROLE_TYPES.ACTIVE_ADMIN)}
                />
              </div>
              <div className="boxText">Activ Admin</div>
            </div>
            <div
              className="secondBox"
              onClick={() => setSelectedRole(ROLE_TYPES.ACTIVE_DEVELOPER)}
            >
              <div>
                <Image src={multidev} className="multi" />
                <input
                  className="boxCheck"
                  type="checkbox"
                  id="developer"
                  value={ROLE_TYPES.ACTIVE_DEVELOPER}
                  checked={
                    selectedRole === ROLE_TYPES.ACTIVE_DEVELOPER ? true : false
                  }
                  //    onChange={() => setSelectedRole(ROLE_TYPES.ACTIVE_DEVELOPER)}
                />
              </div>
              <div className="boxText">Developer</div>
            </div>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                checked={remember_me}
                onChange={() => rememberme(remember_me)}
              />
              <label htmlFor="scales">Remember Choice</label>
            </div>
            <button
              className="continue"
              onClick={() => onRoleSelect(selectedRole)}
            >
              Continue
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiRoleConfirm;
