import React from "react";
import { connect } from "react-redux";
import videojs from "video.js";
import { Stack, StackItem, Text } from "@fluentui/react";
import {
  getVideoAnnotations,
  clearVideoComparisonData,
} from "../../actions/managementAction";
import { withRouter } from "../../../../withRouter";
import playButton from "../../../../assets/play-new-icon.png";
import pause from "../../../../assets/pause.png";
import soundOff from "../../../../assets/sound-off.png";
import soundOn from "../../../../assets/sound-on.png";
import fullScreen from "../../../../assets/full-screen.png";
import next from "../../../../assets/next-icon-new.png";
import previous from "../../../../assets/previous-icon-new.png";
import "./Comparison.scss";
import { getVideoOverlays } from "../../actions/managementAction";
import * as allActions from "../../actions/actionConstants";
import { version } from "process";
import Loader from "../../../../ActiveAdminModule/Logs/components/Common/Loader";
import { getAnnotationData } from "../../../../VideoPlayer/actions/videoPlayerAction";
import { number } from "yup";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";

interface VideoPlayerProps {
  videoUrl: string;
  algorithm: any;
  video: any;
  models: any;
  versions: any;
  firstVideoOverlayUrls: any;
  secondVideoOverlayUrls: any;
  firstVideoAnnotations: any;
  secondVideoAnnotations: any;
  getAnnotations: any;
  clearComparisonData: any;
  modelOptions: any;
  modelVersionOptions: any;
  getVideoOverlays: any;
  handleChangeModelComparision: any;
  toggleFullScreen: (fullScreen: boolean) => void;
  videoPlayButtonEnable: boolean;
  versionErrors: any;
  handleModalChange: any;
  isVersionChange: any;
  toggleVersionChanged: any;
}
interface VideoPlayerState {
  play: boolean;
  mute: boolean;
  videoTime: string;
  currentTime: string;
  progress: number;
  fullScreen: boolean;
  currentDuration: number;
  totalDuration: number;
  loader: boolean;
  videoVersion1: number;
  videoVersion2: number;
  sameVersionError: boolean;
}
const errorMessages = {
  sameModel: {
    message: "Please select different version to compare!",
  },
};
class ComparisonVideo extends React.Component<
  VideoPlayerProps,
  VideoPlayerState
> {
  player1: videojs.Player = {} as videojs.Player;
  player2: videojs.Player = {} as videojs.Player;
  setTimeOutId: any;
  videoRef1: React.RefObject<HTMLVideoElement>;
  videoRef2: React.RefObject<HTMLVideoElement>;
  animationId1: any = 0;
  animationId2: any = 0;

  constructor(props: VideoPlayerProps) {
    super(props);
    this.state = {
      play: false,
      mute: false,
      videoTime: "0:00",
      currentTime: "0:00",
      progress: 0,
      fullScreen: false,
      currentDuration: 0,
      totalDuration: 0,
      loader: true,
      videoVersion1: this.props.versions[0]?.id,
      videoVersion2: this.props.versions[1]?.id,
      sameVersionError: false,
    };
    this.videoRef1 = React.createRef();
    this.videoRef2 = React.createRef();
  }
  componentDidMount = () => {
    this.props.getAnnotations(0);
    this.player1 = videojs("video1", {}).player();
    this.player2 = videojs("video2", {}).player();
    this.player1.on("ready", () => {});
    this.player1.on("play", () => {
      this.setState({ ...this.state, play: true });
    });
    this.player2.on("play", () => {
      this.setState({ ...this.state, play: true });
    });
    this.player1.on("pause", () => {
      const video = this.videoRef2.current;
      this.setState({ ...this.state, play: false });
      video?.cancelVideoFrameCallback(this.animationId1);
    });
    this.player2.on("pause", () => {
      const video = this.videoRef2.current;
      this.setState({ ...this.state, play: false });
      video?.cancelVideoFrameCallback(this.animationId2);
    });
    this.player1.on("ended", () => {
      const video = this.videoRef2.current;
      this.setState({ ...this.state, play: false });
      video?.cancelVideoFrameCallback(this.animationId1);
    });
    this.player1.on("ended", () => {
      const video = this.videoRef2.current;
      this.setState({ ...this.state, play: false });
      video?.cancelVideoFrameCallback(this.animationId2);
    });
    if (this.props.isVersionChange) {
      setTimeout(() => {
        this.onPauseClick();
      }, 200);
    }
  };

  pauseVideoIfOverlayNotAvailable = (duration: number) => {
    //const val = duration % 10;
    //const result = duration - val;
    const result = Math.floor(duration / 10);
    const overlayUrls1: any = this.props.firstVideoOverlayUrls;
    const overlayUrls2: any = this.props.secondVideoOverlayUrls;
    const pauseDuration = duration;
    if (
      (overlayUrls1[result] && !overlayUrls1[result]["downloadStatus"]) ||
      (overlayUrls2[result] && !overlayUrls2[result]["downloadStatus"])
    ) {
      this.player1.pause();
      this.player2.pause();
      this.player1.addClass("vjs-waiting");
      this.player2.addClass("vjs-waiting");
      // set timer to check if overlay available and then play
      const pauseTimer = setInterval(() => {
        const overlayUrls1: any = this.props.firstVideoOverlayUrls;
        const overlayUrls2: any = this.props.secondVideoOverlayUrls;
        if (
          overlayUrls1[result] &&
          overlayUrls1[result]["downloadStatus"] &&
          overlayUrls2[result] &&
          overlayUrls2[result]["downloadStatus"]
        ) {
          this.player1.currentTime(pauseDuration);
          this.player2.currentTime(pauseDuration);
          this.videoPlay(
            "active-canvas-one",
            this.videoRef1,
            this.props.firstVideoAnnotations
          );
          this.videoPlay(
            "active-canvas-two",
            this.videoRef2,
            this.props.secondVideoAnnotations
          );
          if (
            document.getElementsByClassName("vjs-waiting") &&
            document.getElementsByClassName("vjs-waiting")?.length
          ) {
            this.player1.removeClass("vjs-waiting");
            this.player2.removeClass("vjs-waiting");
          }
          clearTimeout(pauseTimer);
        } else {
          this.player1.addClass("vjs-waiting");
          this.player2.addClass("vjs-waiting");
        }
      }, 500);
    }
  };

  renderFrame1 = () => {
    const video = this.videoRef1.current;
    const canvas = document.getElementById("active-canvas-one") as any;
    const ctx = canvas && canvas.getContext("2d");

    if (video) {
      this.pauseVideoIfOverlayNotAvailable(video?.currentTime);
    }
    // Get the current frame of the video

    if (
      this.props.firstVideoAnnotations &&
      canvas.width != this.props.firstVideoAnnotations?.width
    ) {
      canvas.width = this.props.firstVideoAnnotations?.width;
      canvas.height = this.props.firstVideoAnnotations?.height;
    }

    this.drawCanvas(canvas, ctx, video, null);
    // Request the next frame
    if (video) {
      this.animationId1 = video.requestVideoFrameCallback(this.renderFrame1);
    }
  };

  renderFrame2 = () => {
    const video = this.videoRef2.current;
    const canvas = document.getElementById("active-canvas-two") as any;
    const ctx = canvas && canvas.getContext("2d");

    if (video) {
      this.pauseVideoIfOverlayNotAvailable(video.currentTime);
    }

    // Get the current frame of the video

    if (
      this.props.secondVideoAnnotations &&
      canvas.width != this.props.secondVideoAnnotations?.width
    ) {
      canvas.width = this.props.secondVideoAnnotations?.width;
      canvas.height = this.props.secondVideoAnnotations?.height;
    }

    this.drawCanvas(canvas, ctx, video, null);
    if (video) {
      // Request the next frame
      this.animationId2 = video.requestVideoFrameCallback(this.renderFrame2);
    }
  };

  videoPlay = (canvasId: string, videoRef: any, annotations: any) => {
    const video = videoRef.current;
    //const canvas = document.getElementById(canvasId) as any;
    //const ctx = canvas && canvas.getContext("2d");

    // const renderFrame = () => {
    //   // console.log(videoAnnotations);

    //   this.pauseVideoIfOverlayNotAvailable(video.currentTime);
    //   // Get the current frame of the video

    //   if (videoAnnotations && canvas.width != videoAnnotations?.width) {
    //     canvas.width = videoAnnotations?.width;
    //     canvas.height = videoAnnotations?.height;
    //   }

    //   this.drawCanvas(canvas, ctx, video, videoAnnotations);
    //   // Request the next frame
    //   animationId = video.requestVideoFrameCallback(renderFrame);
    // };

    // Start rendering frames when the video is playing
    // video?.addEventListener("play", renderFrame);

    // video?.addEventListener("pause", () => {
    //   // To stop the animation loop:
    //   video.cancelVideoFrameCallback(animationId);
    // });
    // video?.addEventListener("ended", () => {
    //   // To stop the animation loop:
    //   video.cancelVideoFrameCallback(animationId);
    // });

    video?.play();
    if (canvasId === "active-canvas-one") {
      video?.requestVideoFrameCallback(this.renderFrame1);
    } else {
      video?.requestVideoFrameCallback(this.renderFrame2);
    }
  };

  drawCanvas = (canvas: any, ctx: any, video: any, annotationsObj: any) => {
    // console.time("draw canvas " + video.id);
    if (canvas.id === "active-canvas-one") {
      annotationsObj = this.props.firstVideoAnnotations;
    }
    if (canvas.id === "active-canvas-two") {
      annotationsObj = this.props.secondVideoAnnotations;
    }

    if (ctx && annotationsObj) {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const frame = Math.floor(video.currentTime * annotationsObj.fps);
      const frameData = annotationsObj.annotations[frame] || [];
      const width = canvas.width;
      const height = canvas.height;
      frameData.forEach((element: any) => {
        ctx.beginPath();
        element.forEach((cords: any) => {
          if (ctx) {
            let x = cords.x;
            let y = cords.y;

            x = (cords.x / annotationsObj.width) * width;
            y = (cords.y / annotationsObj.height) * height;
            ctx.lineTo(x, y);
          }
        });
        ctx.closePath();
        ctx.globalAlpha = 0.6;
        ctx.strokeStyle = "green";
        ctx.stroke();
        ctx.fillStyle = "#009933";
        ctx.fill();
      });
      //console.timeEnd("drawCanvas");
    }
    // console.timeEnd("draw canvas " + video.id);
  };
  componentWillUnmount() {
    clearTimeout(this.setTimeOutId);
    if (this.player1) {
      this.player1.ended();
      this.player2.ended();
      this.player1.bufferedEnd();
      this.player2.bufferedEnd();

      this.player1.dispose();
      this.player2.dispose();
      this.player1 = {} as videojs.Player;
      this.player2 = {} as videojs.Player;
    }
    this.props.clearComparisonData();
  }
  componentDidUpdate(
    prevProps: Readonly<VideoPlayerProps>,
    prevState: Readonly<VideoPlayerState>,
    snapshot?: any
  ): void {
    if (this.player1?.duration() && !this.state.totalDuration) {
      this.setState({ totalDuration: this.player1?.duration() });
    }

    // if (
    //   JSON.stringify(prevProps?.firstVideoAnnotations) !==
    //     JSON.stringify(this.props.firstVideoAnnotations) &&
    //     JSON.stringify(prevProps?.secondVideoAnnotations) !==
    //     JSON.stringify(this.props.secondVideoAnnotations)
    // ) {
    //   this.setState({ loader: false });
    // }
  }
  onNextClick = () => {
    const timer = this.player1.currentTime() + 10;
    this.player1.currentTime(timer);
    this.player2.currentTime(timer);
  };
  onPreviousClick = () => {
    const timer = this.player1.currentTime() - 10;
    this.player1.currentTime(timer);
    this.player2.currentTime(timer);
  };
  onPauseClick = () => {
    if (this.player1.paused()) {
      this.videoPlay(
        "active-canvas-one",
        this.videoRef1,
        this.props.firstVideoAnnotations
      );
      this.videoPlay(
        "active-canvas-two",
        this.videoRef2,
        this.props.secondVideoAnnotations
      );
    } else {
      this.player1.pause();
      this.player2.pause();
    }
  };

  nextButtonDisabled = () => {
    const currentProgress =
      (this.state.totalDuration * this.state.progress) / 100;
    if (currentProgress === this.state.totalDuration) {
      return true;
    } else if (this.state.totalDuration - currentProgress < 10) {
      return true;
    } else {
      return false;
    }
  };
  previousButtonDisabled = () => {
    const currentProgress =
      (this.state.totalDuration * this.state.progress) / 100;
    if (currentProgress === 0) {
      return true;
    } else if (currentProgress < 11) {
      return true;
    } else return false;
  };
  onMuteClick = () => {
    if (this.player1.muted()) {
      this.player1.muted(false);
      this.player2.muted(false);
      this.setState({ ...this.state, mute: false });
    } else {
      this.player1.muted(true);
      this.player2.muted(true);
      this.setState({ ...this.state, mute: true });
    }
  };
  getTime = (timer: number) => {
    return (
      Math.floor(timer / 60) + ":" + ("0" + Math.floor(timer % 60)).slice(-2)
    );
  };
  pauseVideoToGetDownloadOverlays = () => {
    this.setTimeOutId = setTimeout(() => {
      clearTimeout(this.setTimeOutId);
      this.player1.play();
      this.player2.play();
    }, 10000);
    this.player1.pause();
    this.player2.pause();
    this.player1.addClass("vjs-waiting");
    this.player2.addClass("vjs-waiting");
  };
  onProgress = (event: any) => {
    const duration = event.target.duration;
    const currentTime = event.target.currentTime;
    if (isNaN(duration)) return;
    const val = currentTime % 10;
    const result = currentTime - val;
    const time = this.getTime(currentTime);
    const progress = (currentTime / duration) * 100;
    this.setState({
      ...this.state,
      currentTime: time,
      progress: progress,
    });
    // this.drawCanvas(
    //   currentTime,
    //   "active-canvas-one",
    //   this.props.firstVideoAnnotations
    // );
    // this.drawCanvas(
    //   currentTime,
    //   "active-canvas-two",
    //   this.props.secondVideoAnnotations
    // );
  };
  onLoad = (event: any) => {
    const duration = this.getTime(event.target?.duration);
    const time = this.getTime(event.target?.currentTime);
    this.setState({
      ...this.state,
      currentTime: time,
      videoTime: duration,
    });
  };
  onFullScreen = () => {
    this.props.toggleFullScreen(!this.state.fullScreen);
    this.setState({
      ...this.state,
      fullScreen: !this.state.fullScreen,
    });
  };
  onseeking = (event: any) => {
    const value = event.target?.value;
    const duration = this.player1?.duration();
    const timer = (duration / 100) * value;
    this.setState({
      ...this.state,
      progress: value,
    });
    this.player1.currentTime(timer);
    this.player2.currentTime(timer);
  };
  changeVideoversion = async (value: any, index: any) => {
    this.player1.pause();
    this.player2.pause();
    this.props.clearComparisonData();
    if (
      this.state.videoVersion1 &&
      this.state.videoVersion2 &&
      +this.state.videoVersion1 === +this.state.videoVersion2
    ) {
      this.setState({ sameVersionError: true });
    } else {
      this.setState({ sameVersionError: false });
      const getSeletedversion: any = this.props?.modelVersionOptions[
        index
      ].find((item: any) => {
        return item.id === Number(value);
      });
      this.props.handleChangeModelComparision(index, getSeletedversion, () => {
        this.player1.currentTime(0);
        this.player2.currentTime(0);
        this.props.getAnnotations(0);
        this.props.toggleVersionChanged();
      });
    }
  };

  getFilterModalVersion1() {
    const options = this.props.modelVersionOptions[0].filter((item: any) => {
      return item.id !== +this.state.videoVersion2;
    });
    return options;
  }
  getFilterModalVersion2() {
    const options = this.props.modelVersionOptions[1].filter((item: any) => {
      return item.id !== +this.state.videoVersion1;
    });
    return options;
  }

  render() {
    return (
      <Stack
        className={`rc-video-comparison ${
          this.state.fullScreen && "full-screen"
        }`}
      >
        {/* {!this.props.videoPlayButtonEnable && <Loader></Loader>}{" "} */}
        <StackItem
          styles={{ root: { backgroundColor: "rgb(18 17 17)", padding: 25 } }}
        >
          {" "}
          {!this.state.fullScreen && (
            <StackItem
              align="center"
              className="error-displying"
              style={{ marginBottom: 20, fontSize: 16, color: "#b6afaf" }}
            >
              {(this.props.versionErrors.includes(true) ||
                this.state.sameVersionError) && (
                <ShowErrorMessageBar
                  //resetChoice={resetChoice}
                  errorResponse={errorMessages["sameModel"]}
                ></ShowErrorMessageBar>
              )}{" "}
              {`${this.props.algorithm?.label} / ${this.props.video?.label}`}
            </StackItem>
          )}
          <Stack horizontal>
            {" "}
            <StackItem styles={{ root: { width: "50%" } }}>
              {" "}
              {!this.state.fullScreen && (
                <Stack
                  horizontal
                  style={{ marginBottom: 0, background: "rgb(31, 31, 31)" }}
                  tokens={{ childrenGap: 10 }}
                >
                  {" "}
                  {/* <Text
                    variant="mediumPlus"
                    styles={{ root: { marginBottom: 5, marginTop: 5 } }}
                  >{`${this.props.models[0]?.label}`}</Text> */}
                  <select
                    defaultValue={this.props.models[0]?.value}
                    name="model0"
                    id="model0"
                    style={{
                      width: 180,
                      background: "#1f1f1f",
                      color: "#6e6b6b",
                      height: 30,
                      borderColor: "#343232",
                      textAlign: "center",
                    }}
                    onChange={(e: any) => {
                      const getSeletedModal: any =
                        this.props?.modelOptions.find((item: any) => {
                          return item.value === Number(e.target.value);
                        });
                      this.props.handleModalChange(0, getSeletedModal);
                    }}
                  >
                    {" "}
                    {this.props.modelOptions.map((model: any) => (
                      <option value={model.value} key={model.value}>
                        {model?.label}
                      </option>
                    ))}
                  </select>{" "}
                  {/* <Text
                    variant="mediumPlus"
                    styles={{ root: { marginBottom: 5, marginTop: 5 } }}
                  >                    {`${Number(this.props.versions[0]?.label)} `}--
                  </Text> */}
                  <select
                    name="version0"
                    id="version0"
                    defaultValue={
                      this.props.versions &&
                      this.props.versions[0] &&
                      this.props.versions[0]?.id
                        ? this.props.versions[0]?.id
                        : "Select Version"
                    }
                    style={{
                      width: 90,
                      height: 30,
                      background: "#1f1f1f",
                      color: "#6e6b6b",
                      marginLeft: 0,
                      borderColor: "#343232",
                      textAlign: "center",
                    }}
                    onChange={(e: any) => {
                      this.setState({ videoVersion1: e?.target?.value }, () => {
                        this.changeVideoversion(this.state.videoVersion1, 0);
                      });
                      this.props.clearComparisonData();
                    }}
                  >
                    {" "}
                    <option key="Select Version" value="">
                      Select Version
                    </option>
                    {this.getFilterModalVersion1().map((modelVersion: any) => (
                      <option
                        key={modelVersion.value}
                        value={modelVersion.value}
                      >
                        {" "}
                        {modelVersion?.label}
                      </option>
                    ))}
                  </select>{" "}
                </Stack>
              )}
              <StackItem
                styles={{ root: { position: "relative", marginRight: 1 } }}
                grow
              >
                {" "}
                <video
                  className="rc-player"
                  id="video1"
                  width="120"
                  onLoadedData={this.onLoad}
                  onTimeUpdate={this.onProgress}
                  ref={this.videoRef1}
                >
                  {" "}
                  <source
                    src={this.props.videoUrl}
                    type="application/dash+xml"
                  />{" "}
                  <source src={this.props.videoUrl} type="video/mp4" />{" "}
                </video>{" "}
                <canvas
                  id="active-canvas-one"
                  style={{
                    position: "absolute",
                    zIndex: "1024",
                    width: "100%",
                    height: "100%",
                    top: 0,
                  }}
                  onClick={this.onPauseClick}
                ></canvas>{" "}
              </StackItem>{" "}
            </StackItem>{" "}
            <StackItem styles={{ root: { width: "50%" } }}>
              {" "}
              {!this.state.fullScreen && (
                <Stack
                  horizontal
                  style={{ marginBottom: 0, background: "rgb(31, 31, 31)" }}
                  tokens={{ childrenGap: 10 }}
                >
                  {" "}
                  {/* <Text
                    variant="mediumPlus"
                    styles={{ root: { marginBottom: 5, marginTop: 5 } }}
                  >{`${this.props.models[1]?.label}`}</Text> */}
                  <select
                    name="model1"
                    id="model1"
                    defaultValue={this.props.models[1]?.value}
                    style={{
                      width: 180,
                      background: "#1f1f1f",
                      color: "#6e6b6b",
                      height: 30,
                      borderColor: "#343232",
                      textAlign: "center",
                    }}
                    onChange={(e: any) => {
                      const getSeletedModal: any =
                        this.props?.modelOptions.find((item: any) => {
                          return item.value === Number(e.target.value);
                        });
                      this.props.handleModalChange(1, getSeletedModal);
                    }}
                  >
                    {" "}
                    {this.props.modelOptions.map((model: any) => (
                      <option value={model.value} key={model.value}>
                        {model?.label}
                      </option>
                    ))}
                  </select>{" "}
                  {/* <Text
                    variant="mediumPlus"
                    styles={{ root: { marginBottom: 5, marginTop: 5 } }}
                  >                    {`${Number(this.props.versions[1]?.label)} `}
                  </Text> */}
                  <select
                    name="vesion1"
                    id="version1"
                    defaultValue={
                      this.props.versions &&
                      this.props.versions[1] &&
                      this.props.versions[1]?.id
                        ? this.props.versions[1]?.id
                        : "Select Version"
                    }
                    style={{
                      width: 90,
                      height: 30,
                      background: "#1f1f1f",
                      color: "#6e6b6b",
                      marginLeft: 0,
                      borderColor: "#343232",
                      textAlign: "center",
                    }}
                    onChange={(e: any) => {
                      this.setState({ videoVersion2: e?.target?.value }, () => {
                        this.changeVideoversion(this.state.videoVersion2, 1);
                      });
                      this.props.clearComparisonData();
                    }}
                  >
                    {" "}
                    <option key="Select Version" value="">
                      Select Version
                    </option>
                    {this.getFilterModalVersion2().map((modelVersion: any) => (
                      <option
                        key={modelVersion.value}
                        value={modelVersion.value}
                      >
                        {" "}
                        {modelVersion?.label}
                      </option>
                    ))}
                  </select>{" "}
                </Stack>
              )}
              <StackItem styles={{ root: { position: "relative" } }} grow>
                {" "}
                <video
                  className="rc-player"
                  id="video2"
                  width="120"
                  ref={this.videoRef2}
                >
                  {" "}
                  <source
                    src={this.props.videoUrl}
                    type="application/dash+xml"
                  />{" "}
                  <source src={this.props.videoUrl} type="video/mp4" />{" "}
                </video>{" "}
                <canvas
                  id="active-canvas-two"
                  style={{
                    position: "absolute",
                    zIndex: "1024",
                    width: "100%",
                    height: "100%",
                    top: 0,
                  }}
                  onClick={this.onPauseClick}
                ></canvas>{" "}
              </StackItem>{" "}
            </StackItem>{" "}
          </Stack>{" "}
          <div
            className="rc-video-controls"
            style={{ background: "rgb(18 17 17)" }}
          >
            {" "}
            <input
              className="rc-progress"
              type="range"
              min="1"
              max="100"
              value={this.state.progress}
              onChange={this.onseeking}
            ></input>{" "}
            <div className="rc-controls" style={{ marginTop: 20 }}>
              {" "}
              <div className="play-pause">
                {" "}
                <span
                  className="rc-timer"
                  style={{ position: "relative", top: 5, left: 5 }}
                >
                  {" "}
                  <span>{this.state.currentTime}</span> /{" "}
                  <span>{this.state.videoTime}</span>{" "}
                </span>{" "}
              </div>{" "}
              <div style={{ marginLeft: 40 }}>
                {" "}
                <div className="tooltip">
                  {" "}
                  <img
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: 20,
                      cursor: "pointer",
                    }}
                    src={previous}
                    alt="previous"
                    onClick={this.onPreviousClick}
                    className={
                      this.previousButtonDisabled()
                        ? "disabled-previous-icon"
                        : ""
                    }
                  />{" "}
                  <span className="tooltiptext">Backward 10 sec.</span>{" "}
                </div>{" "}
                {this.state.play ? (
                  <div className="tooltip">
                    {" "}
                    <img
                      src={pause}
                      alt="pause"
                      style={{ width: 30, height: 30, cursor: "pointer" }}
                      onClick={this.onPauseClick}
                    />{" "}
                    <span className="tooltiptext">Pause</span>{" "}
                  </div>
                ) : (
                  <div className="tooltip">
                    {" "}
                    <img
                      className={"cursor"}
                      style={{ width: 30, height: 30 }}
                      src={playButton}
                      alt="play"
                      onClick={this.onPauseClick}
                    />{" "}
                    <span className="tooltiptext">Play</span>{" "}
                  </div>
                )}
                <div className="tooltip">
                  {" "}
                  <img
                    style={{ width: 30, height: 30, marginLeft: 20 }}
                    src={next}
                    alt="next"
                    onClick={this.onNextClick}
                    className={
                      this.nextButtonDisabled() ? "disabled-previous-icon" : ""
                    }
                  />{" "}
                  <span className="tooltiptext">Forward 10 sec.</span>{" "}
                </div>{" "}
              </div>{" "}
              <div className="mute-volume">
                {" "}
                {this.state.mute ? (
                  <img src={soundOff} alt="on" onClick={this.onMuteClick} />
                ) : (
                  <img src={soundOn} alt="off" onClick={this.onMuteClick} />
                )}
                <img src={fullScreen} alt="full" onClick={this.onFullScreen} />{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </StackItem>{" "}
      </Stack>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    firstVideoOverlayUrls:
      state.developerManagementReducerData.firstVideoOverlayUrls,
    secondVideoOverlayUrls:
      state.developerManagementReducerData.secondVideoOverlayUrls,
    firstVideoAnnotations:
      state.developerManagementReducerData.firstVideoAnnotations,
    secondVideoAnnotations:
      state.developerManagementReducerData.secondVideoAnnotations,
    videoPlayButtonEnable:
      state.developerManagementReducerData.videoPlayButtonEnable,
  };
};
const dispatchActionToProps = (dispatch: any) => {
  return {
    getAnnotations: (duration: any) => {
      dispatch(getVideoAnnotations(duration));
    },
    clearComparisonData: () => {
      dispatch(clearVideoComparisonData());
    },
    getVideoOverlays: (videoId: any, modelId: any, type: any) => {
      dispatch(getVideoOverlays(videoId, modelId, type));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, dispatchActionToProps)(ComparisonVideo)
);
