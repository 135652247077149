export const LOGIN_SUCCESS = "LOGIN_SUCCESS_CASE";
export const LOGIN_FAILURE = "LOGIN_FAILURE_CASE";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS_CASE";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE_CASE";

export const SHOW_LOGIN_PAGE = "SHOW_LOGIN_PAGE";

export const SET_LOGIN_ALERT_FALSE = "SET_LOGIN_ALERT_FALSE";

export const CLEAR_LOGOUT = "SET_CLEAR_LOGOUT";
export const SHOW_CHANGE_PASSWORD = "SHOW_CHANGE_PASSWORD";
export const CLOSE_ERROR_PASSWORD = "CLOSE_ERROR_PASSWORD";
