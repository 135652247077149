import React, { useCallback } from "react";
import { Stack, Text, Modal, IconButton, IIconProps } from "@fluentui/react";
import Wizard from "../Wizard";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../../rootReducer";
import { useState, useEffect } from "react";
import {
  createModel,
  createLocalModel,
} from "../../../actions/managementAction";

import ModelCreation from "./ModelCreation";
import "../../../../../HospitalAdminModule/Home/components/CreateSurgeon/CreateSurgeon.scss";
const cancelIcon: IIconProps = { iconName: "Cancel" };

const AddModel = ({
  isModalOpen,
  hideModal,
}: {
  isModalOpen: any;
  hideModal: any;
}) => {
  const [disabledCreate, setDisabledCreate] = useState(false);
  const dispatch = useDispatch();

  const spinner = useSelector((state: AppState) => {
    return state.managementReducerData.showRoleSpinner;
  });

  useEffect(() => {
    setDisabledCreate(false);
    // dispatch(showRoleSpinner());
  }, []);

  const [errors, setErrors] = useState<{
    name?: string;
    version?: string;
    description?: string;
    algorithm?: any;
    model_path?: any;
    parameters?: any;
    config_path?: any;
    localBlob?: any;
    modelId?: any;
  }>({});

  const [data, setData] = useState<{
    name: string;
    version: string;
    description: string;
    algorithm: any;
    model_path: any;
    parameters: any;
    config_path: any;
    localBlob?: string;
    storageType?: string;
    model?: string;
    modelFile?: any;
    config?: string;
    configFile?: any;
    correlation_id?: string;
    modelId?: any;
  }>({
    name: "",
    version: "",
    description: "",
    algorithm: null,
    model_path: "",
    parameters: [],
    config_path: "",
    localBlob: "",
    configFile: "",
    modelId: "",
  });

  const resetForm = () => {
    setErrors({});
  };

  useEffect(() => {
    setErrors({});
  }, [isModalOpen]);

  const onCreate = useCallback(() => {
    const {
      name,
      model_path,
      description,
      algorithm,
      parameters,
      version,
      config_path,
      modelId,
    } = data;
    const errors: any = {};
    if (
      data.parameters.length === 0 ||
      data.parameters[0] != "Overall Rating"
    ) {
      data.parameters.unshift("Overall Rating");
    }

    if (!name || name.trim().length === 0) {
      errors.name = "Model Name should not be empty.";
    }
    if (
      algorithm == null ||
      (algorithm && algorithm.toString().trim().length === 0)
    ) {
      errors.algorithm = "Algorithm should not be empty.";
    }
    if (version.toString().trim().length === 0) {
      errors.version = "Model version should not be empty.";
    }
    if (data.storageType === "blobStorage") {
      if (model_path.toString().trim().length === 0) {
        errors.model_path = "Model path should not be empty.";
      }
      if (model_path.toString().includes("\\")) {
        errors.model_path = "Invalid Model Path";
      }
      if (
        (!data.config_path ||
          data.config_path?.toString().trim().length === 0) &&
        !data.modelId &&
        name
      ) {
        errors.config_path = "Config Path should not be empty.";
      }
      if (config_path.toString().includes("\\")) {
        errors.config_path = "Invalid Config Path";
      }
      if (Object.keys(errors).length === 0) {
        setDisabledCreate(true);
        dispatch(createModel(data));
      }
    } else {
      if (!data.modelFile || data.modelFile?.toString().trim().length === 0) {
        errors.localBlob = "Model ONNX File should not be empty.";
      }
      if (
        (!data.configFile || data.configFile?.toString().trim().length === 0) &&
        !data.modelId &&
        name
      ) {
        errors.confingfile = "Model Config File should not be empty.";
      }
      if (Object.keys(errors).length === 0) {
        setDisabledCreate(true);
        dispatch(createLocalModel(data));
      }
    }

    setErrors(errors);
  }, [data]);

  const createModelError = useSelector((state: AppState) => {
    return state.developerManagementReducerData.createModelError;
  });
  useEffect(() => {
    createModelError && setDisabledCreate(false);
  }, [createModelError]);

  return (
    <Modal
      className="createHospital addModel"
      isModeless={true}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      containerClassName={"modalStyle"}
      isBlocking={false}
    >
      <Stack
        className="createHospitalSection"
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
      >
        <Stack.Item className="createHospitalHeading">
          <Text variant="large" className="muted pointer" onClick={hideModal}>
            Model Management
          </Text>
          <Text variant="large" className="lessthan muted">
            &gt;
          </Text>
          <Text variant="large">Add Model</Text>
        </Stack.Item>
        <Stack.Item align="end">
          <IconButton className="" iconProps={cancelIcon} onClick={hideModal} />
        </Stack.Item>
      </Stack>

      <Wizard
        // spinner={spinner}
        disabledCreate={disabledCreate}
        onCreate={onCreate}
        onCancel={hideModal}
      >
        <ModelCreation
          updateData={setData}
          data={data}
          errors={errors}
          resetForm={resetForm}
        />
      </Wizard>
    </Modal>
  );
};

export default AddModel;
