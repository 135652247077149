import { apiEndPoint, doHttpCall } from "../../../common/restApi";
import * as allActions from "./actionConstants";
import axios from "axios";
import {
  convertDurationOfVideoToSec,
  getRandomColor,
  secToHMS,
  unZipFile,
} from "../../../common/util";

const permissionIssueMessage =
  "You do not have permission to perform this action.";

export const getAllContainers =
  (currentPage = 1, searchText?: string) =>
  async (dispatch: any) => {
    const role = localStorage.getItem("role");
    let isDeveloper = false;
    if (role === "Developer") {
      isDeveloper = true;
    }
    let url = `${apiEndPoint}/algorithms/models/image?page=${currentPage}&developer=${isDeveloper}`;
    url = searchText ? `${url}&search=${searchText}` : `${url}`;
    const payload = {
      method: "GET",
      url: url,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_CONTAINERS_LIST));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_CONTAINERS_LIST,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_CONTAINERS_LIST,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_CONTAINERS_LIST,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getModelListForDropdown = async (searchVal: string) => {
  const searchValue = searchVal ? `?search=${searchVal}` : "";
  const url = `${apiEndPoint}/algorithms/models${searchValue}`;
  const payload = {
    method: "GET",
    url: url,
  };
  const response = await doHttpCall(payload);
  return response.data;
};

export const getModelName =
  (currentPage = 1, search: any = "", nextUrl = "") =>
  async (dispatch: any) => {
    const page = "page=" + currentPage;
    const searchValue = search ? `search=${search}` : "";
    const params = [searchValue, page].join("&").replace(/^&+|&+$/g, "");

    const url = `${apiEndPoint}/algorithms/models?${params}`;
    const payload = {
      method: "GET",
      url: nextUrl || url,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_MODEL_NAME));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_MODEL_NAME,
          payload: { data: response.data, isNextURL: nextUrl },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.GET_MODEL_NAME, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_MODEL_NAME,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getModelWithAlgId =
  (algId: number, searchText = "", nextUrl = "") =>
  async (dispatch: any) => {
    dispatch({ type: allActions.ERASE_VERSIONS_DATA });
    let defaultUrl = `${apiEndPoint}/algorithms/models?algorithm=${algId}&page=1`;
    if (searchText) {
      defaultUrl = `${defaultUrl}&search=${searchText}`;
    }
    const payload = {
      method: "GET",
      url: nextUrl || defaultUrl,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          nextUrl
            ? allActions.GET_MODELS_SELECTED_ALGID
            : allActions.REFRESH_MODELS_SELECTED_ALGID
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: nextUrl
            ? allActions.GET_MODELS_SELECTED_ALGID
            : allActions.REFRESH_MODELS_SELECTED_ALGID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            nextUrl
              ? allActions.GET_MODELS_SELECTED_ALGID
              : allActions.REFRESH_MODELS_SELECTED_ALGID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            nextUrl
              ? allActions.GET_MODELS_SELECTED_ALGID
              : allActions.REFRESH_MODELS_SELECTED_ALGID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const eraseContainerError = () => {
  return {
    type: allActions.ERASE_CONTAINER_ERROR,
  };
};
export const disableDeleteModel = (value: any) => (dispatch: any) => {
  dispatch({
    type: allActions.DELETE_MODEL_DISABLE,
    payload: value,
  });
  // return {
  //   type: allActions.DELETE_MODEL_DISABLE,
  // };
};
export const getModelVersionsByAlg =
  (algId: number, model: string, searchText = "") =>
  async (dispatch: any) => {
    let defaultUrl = `${apiEndPoint}/algorithms/${algId}/models/${model}/versions`;
    if (searchText) {
      defaultUrl = `${defaultUrl}&search=${searchText}`;
    }
    const payload = {
      method: "GET",
      url: defaultUrl,
    };
    try {
      dispatch(
        showLoaderWithMessage("", allActions.GET_VERSIONS_SELECTED_MODEL)
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_VERSIONS_SELECTED_MODEL,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_VERSIONS_SELECTED_MODEL,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_VERSIONS_SELECTED_MODEL,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const createContainer = (surgeonData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/algorithms/models/image",
    data: surgeonData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_CONTAINER));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_CONTAINER,
        payload: { data: surgeonData, createOrUpdate: "Container create" },
      });
    } else {
      dispatch({
        type: allActions.CREATE_CONTAINER_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_CONTAINER, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_CONTAINER,
          403
        )
      );
    }
    console.error(response);
  }
};

export const updateContainer = (data: any) => async (dispatch: any) => {
  const { name, tag, description, is_developer } = data;
  const payload = {
    method: "PATCH",
    url: apiEndPoint + "/algorithms/models/image/" + data.uuid,
    data: { name, tag, description, is_developer },
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_CONTAINER));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_CONTAINER,
        payload: { data, createOrUpdate: "Container update" },
      });
    } else {
      dispatch({
        type: allActions.CREATE_CONTAINER_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_CONTAINER, response.status)
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_CONTAINER,
          403
        )
      );
    }
    console.error(response);
  }
};

export const deleteContainer =
  (id: number | string) => async (dispatch: any) => {
    const payload = {
      method: "DELETE",
      url: apiEndPoint + "/algorithms/models/image/" + id,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.DELETE_CONTAINER));
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: allActions.DELETE_CONTAINER,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_CONTAINER,
            response.status
          )
        );
      } else if (response && response.status === 400) {
        dispatch({
          type: allActions.DELETE_CONTAINER_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.DELETE_CONTAINER_ERROR,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.DELETE_CONTAINER,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const eraseDeleteContainerData = () => {
  return {
    type: allActions.ERASE_DELETE_CONTAINER_RESPONSE,
  };
};

export const deleteModel = (id: number | string) => async (dispatch: any) => {
  const payload = {
    method: "DELETE",
    url: apiEndPoint + "/algorithms/models/" + id,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.DELETE_MODEL));
    const response = await doHttpCall(payload);
    if (response && response.status === 200) {
      dispatch({
        type: allActions.DELETE_MODEL,
        payload: { data: response.data },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.DELETE_MODEL, response.status)
      );
    } else if (response && response.status === 400) {
      dispatch({
        type: allActions.DELETE_MODEL_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.DELETE_MODEL_ERROR,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.DELETE_MODEL,
          403
        )
      );
    }
    console.error(response);
  }
};

export const getModelById =
  (algId: any, modelId: any, modelVersion: any, currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/developer/algorithm/${algId}/model/${modelId}/version/${modelVersion}${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.MODEL_VIEW));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.MODEL_VIEW,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage("", allActions.MODEL_VIEW, response.status)
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.MODEL_VIEW,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const createModel = (modelData: any) => async (dispatch: any) => {
  const payload = {
    method: "POST",
    url: apiEndPoint + "/algorithms/models",
    data: modelData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_MODEL));
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_MODEL,
        payload: { data: modelData },
      });
      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_MODEL, response.status)
      );
    } else {
      dispatch({
        type: allActions.CREATE_MODEL_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.CREATE_MODEL_ERROR,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_MODEL,
          403
        )
      );
    }
    console.error(response);
  }
};

export const clearDeveloperModalAlert = () => {
  return {
    type: "LOGOUT_DEVELOPER_ALERT",
  };
};

export const createLocalModel = (modelData: any) => async (dispatch: any) => {
  const url = `${apiEndPoint}/algorithms/local/models`;
  const payload = {
    method: "POST",
    url: url,
    data: modelData,
  };
  try {
    dispatch(showLoaderWithMessage("", allActions.CREATE_MODEL));
    dispatch(clearDeveloperModalAlert());
    const response = await doHttpCall(payload);
    if (response && (response.status === 200 || response.status === 201)) {
      dispatch({
        type: allActions.CREATE_MODEL,
        payload: { data: modelData },
      });

      dispatch(
        hideLoaderWithMessage("", allActions.CREATE_MODEL, response.status)
      );
      const config = {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "x-ms-blob-content-type": "application/octet-stream",
        },
      };
      const pathsData = response.data;
      const responseUrl = await axios.put(
        pathsData.model,
        modelData.modelFile,
        config
      );
      let configRes;
      if (pathsData.config) {
        configRes = await axios.put(
          pathsData.config,
          modelData.configFile,
          config
        );
      }
      if (
        responseUrl?.status === 201 &&
        ((pathsData.config && configRes?.status === 201) || !pathsData.config)
      ) {
        const payloadResponseUrl = {
          method: "PATCH",
          url:
            apiEndPoint +
            `/algorithms/local/models/${pathsData.correlation_id}`,
          data: { status: "UPLOADED" },
        };
        dispatch(clearDeveloperModalAlert());
        try {
          await doHttpCall(payloadResponseUrl);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      dispatch({
        type: allActions.CREATE_MODEL_ERROR,
        payload: response.data,
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.CREATE_MODEL_ERROR,
          response.status
        )
      );
    }
  } catch (response) {
    if (response) {
      dispatch(
        hideLoaderWithMessage(
          permissionIssueMessage,
          allActions.CREATE_MODEL,
          403
        )
      );
    }
    console.error(response);
  }
};

export const showModelSpinner = () => {
  return {
    type: allActions.SHOW_MODEL_SPINNER,
  };
};

export const showContainerSpinner = () => {
  return {
    type: allActions.SHOW_CONTAINER_SPINNER,
  };
};

export const showDatasetSpinner = () => {
  return {
    type: allActions.SHOW_DATASET_SPINNER,
  };
};

export const eraseModelError = () => {
  return {
    type: allActions.ERASE_MODEL_ERROR,
  };
};

export const getReadyDatasets =
  (algId: any, datasetName = "", nextUrl = "") =>
  async (dispatch: any) => {
    let defaultUrl = `${apiEndPoint}/developer/algorithm/${algId}/datasets?page=1`;
    if (datasetName) {
      defaultUrl = `${defaultUrl}&search=${datasetName}`;
    }
    const payload = {
      method: "GET",
      url: nextUrl ? nextUrl : defaultUrl,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          nextUrl
            ? allActions.GET_DATASETS_BY_ID
            : allActions.REFRESH_DATASETS_BY_ID
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: nextUrl
            ? allActions.GET_DATASETS_BY_ID
            : allActions.REFRESH_DATASETS_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            nextUrl
              ? allActions.GET_DATASETS_BY_ID
              : allActions.REFRESH_DATASETS_BY_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            nextUrl
              ? allActions.GET_DATASETS_BY_ID
              : allActions.REFRESH_DATASETS_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getDataSetsById =
  (algId: any, currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/developer/datasets/${algId}${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_DATASETS_BY_ID));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_DATASETS_BY_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_DATASETS_BY_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_DATASETS_BY_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getUniqueModels =
  (data: any, modelName = "", nextUrl = "") =>
  async (dispatch: any) => {
    let defaultUrl = `${apiEndPoint}/developer/algorithm/${data.algorithm}/dataset/${data.video}/models?page=1`;
    if (modelName) {
      defaultUrl = `${defaultUrl}&search=${modelName}`;
    }
    const payload = {
      method: "GET",
      url: nextUrl ? nextUrl : defaultUrl,
    };
    try {
      dispatch(
        showLoaderWithMessage(
          "",
          nextUrl
            ? allActions.GET_MODEL_NAMES_BY_ALG_ID
            : allActions.GET_MODEL_NAMES_BY_ALG_ID
        )
      );
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        dispatch({
          type: nextUrl
            ? allActions.GET_MODEL_NAMES_BY_ALG_ID
            : allActions.GET_MODEL_NAMES_BY_ALG_ID,
          payload: { data: response.data },
        });
        const responses: any = await Promise.allSettled(
          response.data.map(async (modelName: string) => {
            const url = `${apiEndPoint}/algorithms/${data.algorithm}/models/${modelName}/versions`;
            const payload = {
              method: "GET",
              url: url,
            };
            return doHttpCall(payload);
          })
        );
        const formattedData: any[] = [];
        response.data.forEach((item: string, index: number) => {
          if (responses[index].value && responses[index].value.status === 200) {
            responses[index].value.data.forEach((element: any) => {
              element.name = item;
              element.label = element.version;
              element.value = element.id;
              formattedData.push(element);
            });
          }
        });
        dispatch({
          type: nextUrl
            ? allActions.GET_MODELS_BY_ALG_ID
            : allActions.GET_MODELS_BY_ALG_ID,
          payload: { data: formattedData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            nextUrl
              ? allActions.GET_MODEL_NAMES_BY_ALG_ID
              : allActions.GET_MODEL_NAMES_BY_ALG_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            nextUrl
              ? allActions.GET_MODEL_NAMES_BY_ALG_ID
              : allActions.GET_MODEL_NAMES_BY_ALG_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getModelsByAlgId =
  (algId: any, currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/algorithms/${algId}/models${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_MODELS_BY_ALG_ID));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_MODELS_BY_ALG_ID,
          payload: { data: response.data },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_MODELS_BY_ALG_ID,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_MODELS_BY_ALG_ID,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getModelVersion =
  (algId: any, mdlName: any, currentPage = 1) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/algorithms/${algId}/models/${mdlName}/versions${
      currentPage > 1 ? "?page=" + currentPage : ""
    }`;
    const payload = {
      method: "GET",
      url: url,
    };
    // debugger;
    try {
      dispatch(showLoaderWithMessage("", allActions.GET_MODEL_VERSION));
      const response = await doHttpCall(payload);
      // debugger;
      if (response && response.status === 200) {
        dispatch({
          type: allActions.GET_MODEL_VERSION,
          payload: { data: { [mdlName]: response.data } },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.GET_MODEL_VERSION,
            response.status
          )
        );
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_MODEL_VERSION,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const getModelComparisonParameters =
  (data: Array<{ mdlId: any; mdlVersion: any; datasetId: any }>) =>
  async (dispatch: any) => {
    try {
      dispatch(
        showLoaderWithMessage("", allActions.GET_MODEL_COMPARISON_PARAMETERS)
      );
      const responses: any = await Promise.allSettled(
        data.map(async ({ mdlId, mdlVersion, datasetId }) => {
          const url = `${apiEndPoint}/developer/model/${mdlId}/version/${mdlVersion}/dataset/${datasetId}/parameters`;
          const payload = {
            method: "GET",
            url: url,
          };
          return doHttpCall(payload);
        })
      );
      // .map((res:any) => res?.data?.results)
      const formattedData: any[] = [];
      responses.forEach((res: any, i: number) => {
        formattedData[i] = [];
        if (res.value && res.value.status === 200) {
          formattedData[i] = [...res.value.data.results];
        }
      });

      dispatch({
        type: allActions.GET_MODEL_COMPARISON_PARAMETERS,
        payload: { data: formattedData },
      });
      dispatch(
        hideLoaderWithMessage(
          "",
          allActions.GET_MODEL_COMPARISON_PARAMETERS,
          responses.status
        )
      );
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.GET_MODEL_COMPARISON_PARAMETERS,
            403
          )
        );
      }
      console.error(response);
    }
  };
// async (dispatch: any) => {
//   let url = `${apiEndPoint}/developer/model/${mdlId}/${mdlVersion}/parameters`;
//   const payload = {
//     method: "GET",
//     url: url,
//   };
//   // debugger;
//   try {
//     const response = await doHttpCall(payload);
//     // debugger;
//     if (response && response.status === 200) {
//       dispatch({
//         type: allActions.GET_MODEL_COMPARISON_PARAMETERS,
//         payload: { data: response.data},
//       });
//     }
//   } catch (e) {
//     console.error(e);
//   }
// };

export const submitModelComparison =
  (modelData: any) => async (dispatch: any) => {
    const payload = {
      method: "POST",
      url: apiEndPoint + "/developer/models/comparison",
      data: modelData,
    };
    try {
      dispatch(showLoaderWithMessage("", allActions.SUBMIT_MODEL_COMPARISON));
      const response = await doHttpCall(payload);
      if (response && (response.status === 200 || response.status === 201)) {
        dispatch({
          type: allActions.SUBMIT_MODEL_COMPARISON,
          payload: { data: modelData },
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.SUBMIT_MODEL_COMPARISON,
            response.status
          )
        );
        return true;
      } else if (response && response.status === 400) {
        dispatch({
          type: allActions.SUBMIT_COMMENT_ERROR,
          payload: response.data,
        });
        dispatch(
          hideLoaderWithMessage(
            "",
            allActions.SUBMIT_COMMENT_ERROR,
            response.status
          )
        );
        return true;
      }
    } catch (response) {
      if (response) {
        dispatch(
          hideLoaderWithMessage(
            permissionIssueMessage,
            allActions.SUBMIT_MODEL_COMPARISON,
            403
          )
        );
      }
      console.error(response);
    }
  };

export const clearCommentError = () => {
  return {
    type: allActions.CLEAR_COMMENT_ERROR,
  };
};

export const getVideoOverlays =
  (videoId: number, modelId: number, type: allActions.VideoOverLayType) =>
  async (dispatch: any) => {
    const url = `${apiEndPoint}/developer/videos/${videoId}/models/${modelId}/outputs`;
    const payload = {
      method: "GET",
      url: url,
    };
    try {
      const response = await doHttpCall(payload);
      if (response && response.status === 200) {
        const overlayUrls = response.data;
        let data = {};
        overlayUrls.forEach((val: any, index: any) => {
          data = {
            ...data,
            [index]: {
              url: val.path,
              downloadStatus: false,
              duration: val.duration,
            },
          };
        });
        dispatch({
          type,
          payload: data,
        });
        return response.status;
      } else {
        return response;
      }
    } catch (e) {
      console.error(e);
    }
  };

export const fetchAnnotations = async (
  currentOverlay: any,
  dispatch: any,
  setNumber: number,
  annotationType: allActions.AnnotationType
  // counter:number
) => {
  if (
    currentOverlay &&
    currentOverlay["url"]
    // && !currentOverlay["downloadStatus"]
  ) {
    return axios.get(currentOverlay["url"]).then((response) => {
      let annotationData: any = unZipFile(response.data);
      annotationData = JSON.parse(annotationData);
      if (annotationData && annotationData.fps) {
        dispatch({
          type: annotationType,
          payload: {
            annotationData: annotationData,
            duration: annotationData.duration,
            index: setNumber,
          },
        });
      }
    });
  }
};

export const getVideoAnnotations =
  (duration: number) => async (dispatch: any, getState: any) => {
    try {
      const firstVideoOverlayUrls =
        getState().developerManagementReducerData.firstVideoOverlayUrls || {};
      const secondVideoOverlayUrls =
        getState().developerManagementReducerData.secondVideoOverlayUrls || {};
      // const counter = 0;
      for (
        let i = duration / 10;
        i < Object.keys(firstVideoOverlayUrls).length;
        i++
      ) {
        // counter = counter+1;
        // console.log(counter,'counter221')
        const firstOverlay = firstVideoOverlayUrls[i];
        const secondOverlay = secondVideoOverlayUrls[i];
        fetchAnnotations(
          firstOverlay,
          dispatch,
          i,
          allActions.GET_FIRST_VIDEO_ANNOTATION
          // counter
        );
        fetchAnnotations(
          secondOverlay,
          dispatch,
          i,
          allActions.GET_SECOND_VIDEO_ANNOTATION
          // counter
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

export const clearVideoComparisonData = () => {
  return {
    type: allActions.CLEAR_VIDEO_COMPARISON_DATA,
  };
};

export const showLoaderWithMessage = (message?: any, calledFrom?: any) => {
  return {
    type: allActions.LOADER,
    payload: {
      loadingState: true,
      message: message,
      calledFrom: calledFrom,
    },
  };
};

export const hideLoaderWithMessage = (
  message?: any,
  calledFrom?: any,
  statusCode?: any
) => {
  return {
    type: allActions.STOPLOADER,
    payload: {
      loadingState: false,
      message: message,
      calledFrom: calledFrom,
      statusCode: statusCode,
    },
  };
};
