import * as React from "react";
import { Route, Routes } from "react-router-dom";
import InProgress from "../../InProgress/containers/InProgress";
// import Video from "../../VideoPlayer/containers/VideoPlayer";
import MyProcedures from "../../MyProcedures/containers/MyProcedures/MyProcedures";
import SharedVideos from "../../Shared/containers/SharedVideos";
import FavouriteVideos from "../../Favorites/containers/FavouriteVideos";
import RecentActivities from "../../RecentActivities/containers/RecentActivities";
import Procedures from "../../MyProcedures/containers/ProceduresScreen/Procedures";
import FAQs from "../../FAQ/containers/FAQ";
import VideoPlayerNew1 from "../../VideoPlayer/containers/VideoPlayerNew1";
// import VideoPlayerNew from "../../VideoPlayer/containers/VideoPlayerNew";

export interface Props {}
const SurgeonRoutes: React.FunctionComponent<Props> = () => {
  return (
    <Routes>
      <Route path="procedures/:id" element={<Procedures />} />
      <Route index element={<MyProcedures />} />
      <Route path="favorites" element={<FavouriteVideos />} />
      {/* <Route exact path="/video/:id" element={Video} /> */}
      {/* <Route exact path="/video/:id" element={VideoPlayerNew} /> */}
      <Route path="video/:id" element={<VideoPlayerNew1 />} />
      <Route path="inprogress" element={<InProgress />} />
      <Route path="shared" element={<SharedVideos />} />
      <Route path="recentActivities" element={<RecentActivities />} />
      <Route path="help" element={<FAQs />} />
    </Routes>
  );
};

export default SurgeonRoutes;
