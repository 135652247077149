import React, { useCallback } from "react";
import {
  Stack,
  Text,
  Modal,
  IconButton,
  IIconProps,
  Label,
  Rating,
  TextField,
} from "@fluentui/react";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../rootReducer";
import { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  getModelComparisonParameters,
  submitModelComparison,
} from "../../actions/managementAction";
import { StackItem } from "office-ui-fabric-react";

interface Props {
  algorithm: any;
  video: any;
  models: any;
  versions: any;
  closeModal: any;
  activeStep: any;
  onDataUpdate: (data: any) => void;
  spinnerFunc: any;
}

const CompareRating: React.FC<Props> = ({
  algorithm,
  video,
  models,
  versions,
  closeModal,
  activeStep,
  onDataUpdate,
  spinnerFunc,
}) => {
  const dispatch = useDispatch();

  const [ratingValues, setRatingValues] = useState<any>([{}, {}]);
  const [comments, setComments] = useState<any>([]);

  const [createError, setErrors] = useState<{ [key: string]: boolean }>({});
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setErrors({});
  }, [activeStep]);

  useEffect(() => {
    const callData = models?.map((model: any, i: number) => ({
      mdlId: versions[i] ? versions[i].value : null,
      mdlVersion: versions[i] ? versions[i].label : null,
      datasetId: video.value,
    }));
    dispatch(getModelComparisonParameters(callData));
  }, []);
  useEffect(() => {
    const callData = models?.map((model: any, i: number) => ({
      mdlId: versions[i] ? versions[i].value : null,
      mdlVersion: versions[i] ? versions[i].label : null,
      datasetId: video.value,
    }));
    dispatch(getModelComparisonParameters(callData));
  }, [versions]);
  const ratings =
    useSelector((state: AppState) => {
      return state.developerManagementReducerData?.modelComparisonParameters;
    }) || [];

  useEffect(() => {
    if (ratings && ratings.length) {
      const ratingPreVals = ratings?.map((paramRating: any, index: number) => {
        const obj: any = {};
        paramRating.forEach((item: any) => {
          obj[item.id] = item.rating;
        });
        return obj;
      });
      setRatingValues([...ratingPreVals]);
      setComments([
        ratings[0].length ? ratings[0][0].comments : null,
        ratings[1].length ? ratings[1][0].comments : null,
      ]);
    }
  }, [ratings]);

  const submitError = useSelector((state: AppState) => {
    return state.developerManagementReducerData?.submitCommentError;
  });

  const payloadData = React.useMemo(() => {
    const data = {
      algorithm: algorithm?.value,
      video: video.value,
      models: models.map((model: any, i: number) => ({
        model: versions[i] ? versions[i].value : null,
        model_version: versions[i] ? Number(versions[i].label) : null,
        parameters: Object.keys(ratingValues[i])?.map((key: any) => ({
          parameter: Number(key),
          rating: Number(ratingValues[i][key]),
        })),
        comments: comments[i] || "",
      })),
    };
    onDataUpdate(data);
    return data;
  }, [
    algorithm,
    video,
    models,
    versions,
    comments,
    ratingValues,
    onDataUpdate,
  ]);

  useEffect(() => {
    if (submitError) {
      spinnerFunc(false);
    }
  }, [submitError]);
  const onSubmit = async (data: any) => {
    spinnerFunc(true);
    const responce: any = await dispatch(submitModelComparison(payloadData));
    if (responce) {
      closeModal();
    }
  };
  const commentData = React.useMemo(() => {
    if (ratings && ratings.length) {
      setComments([
        ratings[0].length ? ratings[0][0].comments : null,
        ratings[1].length ? ratings[1][0].comments : null,
      ]);
    }
    return ratings;
  }, [ratings]);
  return (
    <Stack
      tokens={{ childrenGap: 50 }}
      styles={{ root: { marginTop: 50, marginBottom: 100 } }}
    >
      <form id="ratingForm" onSubmit={handleSubmit(onSubmit)}>
        <StackItem style={{ marginTop: 20 }}>
          <Stack horizontal tokens={{ childrenGap: 50 }}>
            <StackItem>
              <Stack>
                <StackItem
                  style={{ color: "#9e9898", marginBottom: 5, fontSize: 13 }}
                >
                  Algorightm Name
                </StackItem>
                <StackItem>{algorithm.label}</StackItem>
              </Stack>
            </StackItem>
            <StackItem>
              <Stack>
                <StackItem
                  style={{ color: "#9e9898", marginBottom: 5, fontSize: 13 }}
                >
                  Video Mapped to
                </StackItem>
                <StackItem>{video.label}</StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem styles={{ root: { marginTop: 30 } }}>
          <Text variant="large" style={{ fontSize: 20 }}>
            Compared Models
          </Text>
        </StackItem>
        <StackItem verticalFill>
          <Stack horizontal tokens={{ childrenGap: 250 }}>
            <StackItem
              styles={{
                root: {
                  maxWidth: 350,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  marginTop: 20,
                },
              }}
            >
              <Text
                variant="mediumPlus"
                styles={{ root: { marginBottom: 20 } }}
              >{`${models[0]?.label} : ${Number(versions[0]?.label)}`}</Text>
              <Stack>
                {Array.isArray(ratings) &&
                  ratings[0]?.map((rating: any, i: number) => (
                    <StackItem
                      key={i}
                      styles={{
                        root: {
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        },
                      }}
                    >
                      <div>{rating?.parameter_name}</div>
                      <Rating
                        allowZeroStars
                        key={i}
                        max={5}
                        rating={rating?.rating}
                        onChange={(e: any, value?: number) => {
                          const allRatings = ratingValues[0];
                          rating.rating = value;
                          allRatings[rating?.id] = value;
                          ratingValues[0] = allRatings;
                          setRatingValues([...ratingValues]);
                        }}
                      />
                    </StackItem>
                  ))}
                <StackItem styles={{ root: { marginTop: "20px" } }}>
                  {/* <TextField
                    placeholder="Comments if any"
                    defaultValue={comments[0]}
                    multiline
                    rows={4}
                    onChange={(_: any, text?: string) => {
                      comments[0] = text;
                      setComments([...comments]);
                    }}
                  /> */}
                  <textarea
                    placeholder="Comments if any"
                    onChange={(e: any) => {
                      comments[0] = e.target.value;
                      setComments([...comments]);
                    }}
                    style={{
                      width: 338,
                      background: "#151515",
                      color: "#cbcbcb",
                      padding: 5,
                    }}
                    rows={4}
                    value={comments[0]}
                    // defaultValue={comments[0]}
                  ></textarea>
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem
              verticalFill
              styles={{
                root: {
                  maxWidth: 350,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  marginTop: 20,
                },
              }}
            >
              <Text
                variant="mediumPlus"
                styles={{ root: { marginBottom: 20 } }}
              >{`${models[1]?.label} : ${Number(versions[1]?.label)}`}</Text>
              <Stack>
                {Array.isArray(ratings) &&
                  ratings[1]?.map((rating: any, i: number) => (
                    <StackItem
                      key={i}
                      styles={{
                        root: {
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        },
                      }}
                    >
                      <div>{rating?.parameter_name}</div>
                      <Rating
                        allowZeroStars
                        max={5}
                        key={i}
                        rating={rating?.rating}
                        onChange={(e: any, value?: number) => {
                          const allRatings = ratingValues[1];
                          allRatings[rating?.id] = value || 0;
                          rating.rating = value;
                          ratingValues[1] = allRatings;
                          setRatingValues([...ratingValues]);
                        }}
                      />
                    </StackItem>
                  ))}
                {/* <TextField
                  placeholder="Comments if any"
                  defaultValue={comments[1]}
                  multiline
                  rows={4}
                  onChange={(_: any, text?: string) => {
                    comments[1] = text;
                    setComments([...comments]);
                  }}
                /> */}
                <textarea
                  placeholder="Comments if any"
                  style={{
                    width: 338,
                    background: "#151515",
                    color: "#cbcbcb",
                    padding: 5,
                  }}
                  rows={4}
                  // defaultValue={comments[1]}
                  value={comments[1] ? comments[1] : ""}
                  onChange={(e: any) => {
                    comments[1] = e.target.value;
                    setComments([...comments]);
                  }}
                ></textarea>
              </Stack>
            </StackItem>
          </Stack>
          {submitError && (
            <Text
              className="errorMessage"
              styles={{ root: { display: "flex", justifyContent: "end" } }}
            >
              {submitError}
            </Text>
          )}
        </StackItem>
      </form>
    </Stack>
  );
};

export default CompareRating;
