import {
  Stack,
  Text,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  Modal,
  TextField,
  Label,
  StackItem,
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import "../Devices/Devices.scss";
import {
  getSpecialityList,
  createSpeciality,
  getSpecialityById,
  editSpecialityById,
  deleteSpeciality,
  cancelPreviousErrorMessage,
} from "../../actions/managementAction";
import { AppState } from "../../../../rootReducer";
import { IconButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import "./Speciality.scss";
import SuccessMessage from "../../../../ActiveAdminModule/Home/components/Common/SuccessMessage";
import { showSpinner } from "../../../../ActiveAdminModule/Home/actions/homeAction";
import { ReactComponent as AddIcon } from "../../../../assets/Home/create_hospital.svg";
import DeleteModal from "../../../../ActiveAdminModule/Home/components/Common/DeleteModal";
import Paging from "../../../../common/paging/Paging";
import {
  DELETE_SPECIALITY_ERROR,
  GET_SPECIALITY_BY_ID,
} from "../../actions/actionConstants";
import ShowErrorMessageBar from "../../../../ActiveAdminModule/Home/components/Common/ShowErrorMessageBar";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as constants from "../../../../Layout/constants/constants";
import LoaderWithMessage from "../../../../common/customLoader/LoaderWithMessage";

const getMenuItems = () => {
  return [
    {
      key: "edit",
      text: "Edit",
      title: constants.getPermissionsbyItem("management.specialty.edit")
        ? ""
        : constants.permission,
      // scope: [SCOPES.EDIT_SPECIALTY],
    },
    {
      key: "delete",
      text: "Delete",
      title: constants.getPermissionsbyItem("management.specialty.delete")
        ? ""
        : constants.permission,
      // scope: [SCOPES.DELETE_SPECIALTY],
    },
  ];
};

const addIcon: IIconProps = { iconName: "Add" };
const moreIcon: IIconProps = { iconName: "MoreVertical" };

const Speciality = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: {},
  } = useForm();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [disabledCreate, setDisabledCreate] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDevics, setShowDevice] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tempName, setTempName] = useState<string>("");
  const [specialityId, setSpecialityId] = useState<string | any>("1");
  const [showFacilityDeleteError, setshowFacilityDeleteError] = useState(false);
  const [specialityCreationPermission, setSpecialityCreationPermission] =
    React.useState<any>(false);

  const [errors, setErrors] = useState<
    Array<{
      name?: string;
      description?: string;
    }>
  >([{}]);
  const [fields, setFields] = useState([
    {
      name: "",
      description: "",
    },
  ]);

  const closeRoles = useSelector((state: AppState) => {
    return state.managementReducerData.closeRoles;
  });

  const showModal = () => {
    setShowDevice((current) => !current);
  };
  const dispatch = useDispatch();

  const specialityList = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.specialityList;
  });

  const allSpecialitiesList = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.allSpecialitiesList;
  });

  const deleteSpecialityData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.deleteSpeciality;
  });

  const specialityData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.specialityData;
  });

  const editSpecialityData = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.editSpecialityById;
  });
  const createSpecialityError = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.createSpecialityError;
  });

  const loaderWithMessage = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.loaderWithMessageSpeciality;
  });

  const deleteSpecialityError = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData?.deleteSpecialityError;
  });

  useEffect(() => {
    if (createSpecialityError && showMessage) {
      setDisabledCreate(false);
      setShowError(true);
      setShowSuccess(false);
      setShowUpdate(false);
      setTimeout(() => {
        setShowError(false);
        setShowMessage(false);
      }, 3000);
    }
  }, [createSpecialityError, showMessage, errors]);

  useEffect(() => {
    let interval: any = null;
    if (deleteSpecialityError) {
      setshowFacilityDeleteError(true);
      interval = setTimeout(() => clearSpecialityError(), 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [deleteSpecialityError]);

  const clearSpecialityError = () => {
    setshowFacilityDeleteError(false);
    dispatch({
      type: DELETE_SPECIALITY_ERROR,
      payload: null,
    });
  };
  const updatePermission = constants.getPermissionsbyItem(
    "management.specialty.edit"
  );
  useEffect(() => {
    dispatch(getSpecialityList(1, 1000));
    const permission = constants.getPermissionsbyItem(
      "management.specilty.create"
    );
    setSpecialityCreationPermission(permission);
  }, [disabledCreate]);
  useEffect(() => {
    setDisabledCreate(false);
  }, [openDeleteModal]);
  useEffect(() => {
    setDisabledCreate(false);
    if (specialityData && showMessage) {
      setShowError(false);
      setShowSuccess(true);
      setErrors([{}]);
      setTimeout(() => {
        setShowSuccess(false);
        setShowMessage(false);
      }, 3000);
    }
    dispatch(getSpecialityList());
  }, [specialityData]);
  // }, [specialityData, deleteSpecialityData]);
  const specialityById = useSelector((state: AppState) => {
    return state.hospitalManagementReducerData.specialityById;
  });

  /*
    When particular field is edited. specialityById is replaced with selected field details
  */
  useEffect(() => {
    setFields([
      { name: specialityById?.name, description: specialityById?.description },
    ]);
    setTempName(specialityById?.name);
  }, [specialityById]);

  useEffect(() => {
    setFields([
      {
        name: "",
        description: "",
      },
    ]);
    dispatch(getSpecialityList());
    // }, [specialityData, deleteSpecialityData]);
  }, [specialityData]);

  useEffect(() => {
    dispatch(getSpecialityList());
    clearSpecialityError();
    // }, [deleteSpecialityData]);
  }, []);

  useEffect(() => {
    if (editSpecialityData && showMessage) {
      setDisabledCreate(false);
      setShowError(false);
      setShowUpdate(true);
      setErrors([{}]);
      setTimeout(() => {
        setShowUpdate(false);
        setShowMessage(false);
      }, 3000);
    }
    dispatch(getSpecialityList());
  }, [editSpecialityData]);

  const onPageChange = useCallback((pageNo) => {
    setCurrentPage(pageNo);
    dispatch(getSpecialityList(pageNo));
  }, []);

  const showDeleteModal = useCallback(() => {
    setDisabledCreate(false);
    setOpenDeleteModal((current) => !current);
  }, []);

  const deleteHandle = () => {
    setDisabledCreate(true);
    // dispatch(deleteSpeciality(specialityId));
    dispatch(
      deleteSpeciality(
        specialityId,
        specialityList?.results?.length === 1 ? currentPage - 1 : currentPage
      )
    );
    setOpenDeleteModal((current) => !current);
  };

  const _onMenuClick = useCallback((text: string, id: string | number) => {
    if (
      constants.getPermissionsbyItem("management.specialty.delete") &&
      text.toLowerCase() === "delete"
    ) {
      // dispatch(deleteSpeciality(id));
      setSpecialityId(id);
      showDeleteModal();
    }
    if (
      constants.getPermissionsbyItem("management.specialty.edit") &&
      text.toLowerCase() === "edit"
    ) {
      dispatch(getSpecialityById(id));
      setSpecialityCreationPermission(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      setFields([
        {
          name: "",
          description: "",
        },
      ]);
      dispatch({
        type: GET_SPECIALITY_BY_ID,
        payload: { data: null },
      });
    };
  }, []);
  useEffect(() => {
    setFields([
      {
        name: "",
        description: "",
      },
    ]);
    setErrors([{}]);
  }, [deleteSpecialityData]);

  const onCreate = useCallback(() => {
    setShowError(false);
    const filled: Array<{
      name: string;
      description: string;
    }> = [];
    const newErrors: any = [];
    fields.forEach(({ name, description }, i: number) => {
      newErrors.push({});
      if (name && name.length) {
        filled.push({ name, description });
      } else if (name.length === 0) {
        newErrors[i].name = "Specialty name is required";
      } else if (name.length === 0 && description.length === 0) {
        // partiallyFilled.push({ name, description });
        newErrors[i].name = "Specialty name is required";
        newErrors[i].description = "Description name is required";
      }
      allSpecialitiesList.results?.map((specialityItem: any, index: any) => {
        if (
          specialityById
            ? i !== index &&
              specialityItem.name.toLowerCase() === name.toLowerCase() &&
              name !== tempName
            : specialityItem.name.toLowerCase() === name.toLowerCase()
        ) {
          newErrors[i].name = "Specialty name already exists for hospital.";
        }
      });
      fields.map((item, index) => {
        if (i !== index && name !== "" && name === item.name) {
          newErrors[i].name = "Specialty names should not be duplicate.";
        }
      });
    });
    setErrors(newErrors);
    if (filled.length > 0) {
      specialityById
        ? dispatch(editSpecialityById(specialityById?.hs_id, filled[0]))
        : dispatch(createSpeciality([...filled]));
      setShowMessage(true);
    }

    if (Object.keys(newErrors[0]).length === 0) {
      setDisabledCreate(true);
    }

    // setFields([...partiallyFilled]);
  }, [fields]);

  return (
    <Stack className="deviceManagement">
      <Stack.Item className="heading">
        <Text>Specialty</Text>
      </Stack.Item>
      <Stack.Item>
        <Stack>
          <StackItem
            className="specialityFormHead"
            styles={{ root: { display: "flex", alignItems: "center" } }}
          >
            <Text>Create list of Hospital Specialties</Text>
          </StackItem>
          <Stack.Item className="specialityForm">
            <Stack
              horizontal
              tokens={{ childrenGap: 15 }}
              verticalAlign="center"
              className="fieldsContainer"
            >
              <StackItem styles={{ root: { width: "52%" } }}>
                <Label>Specialty Name*</Label>
              </StackItem>
              <StackItem styles={{ root: { width: "35%" } }}>
                <Label>Comments</Label>
              </StackItem>
            </Stack>
            {fields.map((field: any, i) => (
              <>
                <Stack
                  key={i}
                  horizontal
                  tokens={{ childrenGap: 10 }}
                  verticalAlign="start"
                  className="fieldsContainer"
                >
                  <StackItem styles={{ root: { width: "52%" } }}>
                    <TextField
                      // label="Speciality Name*"
                      disabled={!specialityCreationPermission}
                      onChange={(e: any) => {
                        dispatch(cancelPreviousErrorMessage(null));
                        fields[i].name = e.target.value;
                        setFields([...fields]);
                        setErrors((prevErrors) => {
                          prevErrors[i].name = "";
                          return prevErrors;
                        });
                      }}
                      value={field.name}
                      errorMessage={errors[i]?.name}
                    />
                  </StackItem>
                  <StackItem styles={{ root: { width: "35%" } }}>
                    <Stack className="commentSection">
                      <StackItem>
                        <TextField
                          // label="Comments"
                          disabled={!specialityCreationPermission}
                          onChange={(e: any) => {
                            fields[i].description = e.target.value;
                            setFields([...fields]);
                          }}
                          value={field.description}
                          errorMessage={errors[i]?.description}
                        />
                      </StackItem>
                      {i === fields.length - 1 && specialityById === null && (
                        <StackItem
                          align="auto"
                          styles={{ root: { display: "flex" } }}
                          className="addAnotherFieldBtn"
                          onClick={() => {
                            setFields([
                              ...fields,
                              {
                                name: "",
                                description: "",
                              },
                            ]);
                            setErrors([...errors, {}]);
                          }}
                        >
                          <Text variant="smallPlus" className="anotherField">
                            Add Another Field
                          </Text>
                          <AddIcon className="addIcon" />
                        </StackItem>
                      )}
                    </Stack>
                  </StackItem>
                  <StackItem>
                    {i === fields.length - 1 ? (
                      specialityById ? (
                        <DefaultButton
                          disabled={disabledCreate}
                          className="createActiveBtn"
                          text={specialityById ? "Update" : "Create"}
                          title={
                            specialityById
                              ? ""
                              : constants.getPermissionsbyItem(
                                  "management.specialty.edit"
                                )
                              ? ""
                              : constants.permission
                          }
                          onClick={
                            constants.getPermissionsbyItem(
                              "management.specialty.edit"
                            )
                              ? onCreate
                              : void 0
                          }
                        >
                          <Text className="spinnerRight">
                            {disabledCreate && (
                              <Spinner size={SpinnerSize.small} />
                            )}
                          </Text>
                        </DefaultButton>
                      ) : (
                        <DefaultButton
                          disabled={disabledCreate}
                          className="createActiveBtn"
                          text={specialityById ? "Update" : "Create"}
                          title={
                            specialityById
                              ? ""
                              : constants.getPermissionsbyItem(
                                  "management.specilty.create"
                                )
                              ? ""
                              : constants.permission
                          }
                          onClick={
                            constants.getPermissionsbyItem(
                              "management.specilty.create"
                            )
                              ? onCreate
                              : void 0
                          }
                        >
                          <Text className="spinnerRight">
                            {disabledCreate && (
                              <Spinner size={SpinnerSize.small} />
                            )}
                          </Text>
                        </DefaultButton>
                      )
                    ) : (
                      <IconButton
                        iconProps={{ iconName: "Delete" }}
                        onClick={() => {
                          fields.splice(i, 1);
                          setFields([...fields]);
                          setErrors([{}]);
                        }}
                      />
                    )}
                  </StackItem>
                </Stack>
                <Stack styles={{ root: { marginLeft: "30px" } }}>
                  {errors.length > 0 && (
                    <Label className="errorMessage">
                      {errors?.map((error) => {
                        <Label>{error?.name}</Label>;
                      })}
                    </Label>
                  )}
                  {/* {showError && (
                    <Label className="errorMessage">
                      {createSpecialityError}
                    </Label>
                  )} */}
                  {showSuccess && (
                    // <Label className="errorMessage">
                    //   Speciality created successfully
                    // </Label>
                    <Modal
                      isOpen={showSuccess}
                      containerClassName={"modalStyle"}
                    >
                      <SuccessMessage title="Specialty Creation" />
                    </Modal>
                  )}
                  {showUpdate && (
                    // <Label className="errorMessage">
                    //   Speciality created successfully
                    // </Label>
                    <Modal
                      isOpen={showUpdate}
                      containerClassName={"modalStyle"}
                    >
                      <SuccessMessage title="Specialty Updation" />
                    </Modal>
                  )}
                </Stack>
              </>
            ))}
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {showFacilityDeleteError && (
        <ShowErrorMessageBar
          resetChoice={() => clearSpecialityError()}
          errorResponse={deleteSpecialityError}
        ></ShowErrorMessageBar>
      )}
      <Stack className="availableDeviceList">
        <Stack.Item className="deviceSubHeading">
          <Text variant="xLarge">Available Specialties</Text>
        </Stack.Item>
        <Stack.Item className="ms-Grid" dir="ltr">
          <Stack.Item className="ms-Grid-row tableHeading">
            <Stack.Item className=" ms-Grid-col ms-lg6">
              <Text>Specialty Name</Text>
            </Stack.Item>
            <Stack.Item className=" ms-Grid-col ms-lg2">
              <Text variant="smallPlus">Comments</Text>
            </Stack.Item>
          </Stack.Item>
          {specialityList?.results?.map((speciality: any, index: any) => (
            <Stack.Item key={index} className="ms-Grid-row tableText">
              <Stack.Item className="ms-Grid-col ms-lg6">
                <Text
                  variant="smallPlus"
                  className="textNoWrap"
                  title={speciality?.name}
                >
                  {speciality?.name}
                </Text>
              </Stack.Item>
              <Stack.Item className="ms-Grid-col ms-lg5">
                <Text
                  variant="smallPlus"
                  className="textNoWrap"
                  title={speciality?.description}
                >
                  {speciality?.description}
                </Text>
              </Stack.Item>

              <Stack.Item className="ms-Grid-col ms-lg1">
                <Text variant="smallPlus">
                  <IconButton
                    className="buttonIcon"
                    menuIconProps={moreIcon}
                    menuProps={{
                      items: getMenuItems(),
                      onItemClick: (e: any) =>
                        _onMenuClick(e?.target?.innerText, speciality?.hs_id),
                    }}
                    ariaLabel="New item"
                  />
                </Text>
              </Stack.Item>
            </Stack.Item>
          ))}
        </Stack.Item>
        <DeleteModal
          isModalOpen={openDeleteModal}
          deleteHandle={deleteHandle}
          showModal={showDeleteModal}
          id={specialityId}
          name="Specialty"
          disabledCreate={disabledCreate}
        ></DeleteModal>
        <LoaderWithMessage
          loaderWithMessage={loaderWithMessage}
        ></LoaderWithMessage>
        {specialityList?.count > 10 && specialityList?.page_size ? (
          <Paging
            currentPage={currentPage}
            totalPageCount={Math.ceil(specialityList?.count / 10)}
            handlePageChange={onPageChange}
          />
        ) : null}
      </Stack>
      {useEffect(() => {
        onPageChange(currentPage);
      }, [showSuccess || showUpdate])}
    </Stack>
  );
};

export default Speciality;
