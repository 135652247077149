import { uuid } from "uuidv4";
import { doHttpCall } from "../../common/restApi";
import * as allActions from "./actionConstants";

export const getRecentActivitiesOfUser =
  (url: string) => async (dispatch: any) => {
    const payload = {
      method: "GET",
      url: url,
    };
    const response = await doHttpCall(payload);
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.results &&
      response.data.results.length
    ) {
      const items = response.data.results.map((item: any, i: number) => {
        const uniqueKey = uuid();
        const time: any = new Date(item.date);

        const notificationTime: any = new Date(
          Date.UTC(
            time.getFullYear(),
            time.getMonth(),
            time.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds(),
            time.getMilliseconds()
          )
        );
        return {
          key: uniqueKey,
          name: item.log_type,
          value: new Date(notificationTime).toLocaleString(),
        };
      });
      dispatch({
        type: allActions.FETCH_RECENT_ACTIVITIES_DATA,
        payload: { currentPageData: items, next: response.data.next },
      });
      return response.status;
    }
  };

export const sortRecentActivities =
  (sortElement: string, sortType: boolean) =>
  (dispatch: any, getState: any) => {
    if (sortElement === "name") {
      const items = [...getState().recentActivities.recentActivitiesList];
      sortType
        ? items.sort((a: any, b: any) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          )
        : items.sort((a: any, b: any) =>
            a.name < b.name ? 1 : b.name < a.name ? -1 : 0
          );
      dispatch({
        type: allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT,
        payload: items,
      });
    } else {
      const items = [...getState().recentActivities.recentActivitiesList];
      sortType
        ? items.sort(
            (a: any, b: any) =>
              new Date(b.value).getTime() - new Date(a.value).getTime()
          )
        : items.sort(
            (a: any, b: any) =>
              new Date(a.value).getTime() - new Date(b.value).getTime()
          );
      dispatch({
        type: allActions.UPDATE_RECENT_ACTIVITIES_ON_SORT,
        payload: items,
      });
    }
  };

export const showSpinnerOnLoad = () => (dispatch: any) => {
  dispatch({ type: allActions.SHOW_SPINNER });
};

export const clearRecentActivitiesOnlogout = () => (dispatch: any) => {
  dispatch({
    type: allActions.CLEAR_ALL_RECENT_ACTIVITES,
  });
};
