import React from "react";
import {
  Stack,
  StackItem,
  Image,
  Nav,
  IStackItemStyles,
  IStackProps,
  INavStyles,
  INavLinkGroup,
  INavLink,
} from "@fluentui/react";
import { useState, useEffect } from "react";
import { ThemeColorPalette } from "../../../theme";
import { useNavigate } from "react-router-dom";
import "./Management.scss";
import DeveloperManagementNavBar from "./DeveloperManagementNavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const Management = () => {
  return (
    <Stack
      styles={{
        root: {
          padding: "25px 30px",
          childrenGap: 20,
        },
      }}
    >
      {/* <Routes> */}
      {/* <Route path="/" element={<Home />} /> */}
      {/* <Route path="/management/devices" element={<Devices />} /> */}

      {/* <Route path="/management/roles" element={<Role />} />
                <Route path="/management/procedures" element={<Procedures />} />
                <Route path="/management/algorithms" element={<Algorithm />} /> */}
      {/* </Routes> */}
      {/* sadsad----------------------------------------------------------------------------- */}
    </Stack>
  );
};
export default Management;
